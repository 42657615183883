import {Entity, EntityValue} from 'modules/data';
import {FieldType} from 'modules/metadata';
import {combineString} from 'shared/utils/combine-string';

export const getListValue = (
    fieldKey: string = '', type: FieldType, stringStructure: string = '', entity?: Entity,
): EntityValue => {
    if (type === FieldType.COMBINED_STRING) {
        if (stringStructure) {
            const list = combineString(stringStructure, entity);
            if (list && fieldKey === 'fullName') {
                const fullName = list.split(',');
                if (fullName[0].trim() === '' && fullName[1].trim() === '') {
                    return null;
                }
                if (fullName[0].trim() === '') {
                    return fullName[1];
                }
                if (fullName[1].trim() === '') {
                    return fullName[0];
                }
                return fullName.join(',');
            }
            if (list && fieldKey === 'timeWorkdays') {
                if (list.trim() === '-') {
                    return null;
                }
                return list;
            }
            if (list && fieldKey === 'timeWeekend') {
                if (list.trim() === '-') {
                    return null;
                }
                return list;
            }
            return combineString(stringStructure, entity);
        }
    }
    return entity ? entity[fieldKey] : undefined;
};
