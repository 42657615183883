import {createSelector} from '@reduxjs/toolkit';

import {AppState} from 'store/config/types';

import {tableReportSliceName} from '../table-report-slice-constants';
import {omitEmptyParameterFields} from './table-report-query-params-utils';

const rootSelector = (state: AppState) => state[tableReportSliceName];

export const selectTableReportQueryParameters = createSelector(
    rootSelector, ({queryParameters}) => queryParameters,
);

export const selectTableReportRawDefaultQueryParams = createSelector(
    selectTableReportQueryParameters,
    ({defaultParameters}) => defaultParameters,
);

export const selectTableReportRawQueryParams = createSelector(
    selectTableReportQueryParameters,
    ({parameters}) => parameters,
);

export const selectTableReportQueryParams = createSelector(
    selectTableReportRawQueryParams,
    parameters => omitEmptyParameterFields(parameters),
);

export const selectTableReportDefaultQueryParams = createSelector(
    selectTableReportRawDefaultQueryParams,
    defaultParameters => omitEmptyParameterFields(defaultParameters),
);
