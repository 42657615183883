import React from 'react';
import {Redirect} from 'react-router-dom';

import {AuthPage} from 'pages/auth-page/auth-page';
import {ChangePasswordForm} from 'pages/change-password-page/change-password-form';
import {useAppSelector} from 'store/config/hooks';
import {selectAuthPasswordSuccessfullyChanged} from 'store/slices/auth-slice';

export const ChangePasswordPage = () => {
    const isPasswordSuccessfullyChanged = useAppSelector(selectAuthPasswordSuccessfullyChanged);

    if (isPasswordSuccessfullyChanged) {
        return <Redirect to="/" />;
    }

    return (
        <AuthPage
            className="d-flex flex-direction-column justify-content-center align-items-center"
        >
            <ChangePasswordForm />
        </AuthPage>
    );
};
