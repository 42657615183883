import {createAsyncThunk} from '@reduxjs/toolkit';

import {
    GetUserFunctionsArgs,
    GetUserFunctionsByDocIdArgs,
    getUserFunctions,
    getUserFunctionsByDocId,
} from './user-slice-api';
import {userSliceName} from './user-slice-constants';
import {UserRoleFunction} from './user-slice-role-functions';

export const loadUserFunctions = createAsyncThunk<
    UserRoleFunction[], GetUserFunctionsArgs
>(
    `${userSliceName}/loadUserFunctions`,
    async (args, {rejectWithValue}) => {
        try {
            const response = await getUserFunctions(args);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const loadUserFunctionsByDocId = createAsyncThunk<
    UserRoleFunction[], GetUserFunctionsByDocIdArgs
>(
    `${userSliceName}/loadUserFunctionsByDocId`,
    async (args, {rejectWithValue}) => {
        try {
            const response = await getUserFunctionsByDocId(args);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);
