import {flatten} from 'lodash';

import {ColumnSortData, ColumnSortForRequest} from '../../table-column-menu-types';

export const adaptSortDataForRequest = (sort: ColumnSortData['sort']) => {
    const sortAdapted = flatten(Object.entries(sort)
        .filter(([, s]) => s?.order)
        .map((([columnKey, s]) => {
            const sortForRequest: ColumnSortForRequest = {
                columnName: columnKey,
                order: s?.order === 'ascend' ? 'ASC' : 'DESC',
            };
            if (s?.multiple) sortForRequest.index = s?.multiple;
            return sortForRequest;
        })));

    return sortAdapted;
};

export const adaptSortReqestDataToTableFormat = (sort: ColumnSortForRequest[]) => {
    const sortData: ColumnSortData['sort'] = {};

    sort.forEach(v => {
        if (v.order) {
            sortData[v.columnName] = {
                multiple: v.index,
                order: v.order === 'ASC' ? 'ascend' : 'descend',
            };
        }
    });

    return sortData;
};
