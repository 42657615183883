import {createAsyncThunk} from '@reduxjs/toolkit';

import {getMenuStructure} from './menu-slice-api';
import {menuSliceName} from './menu-slice-constants';

export const loadMenuStructure = createAsyncThunk(
    `${menuSliceName}/loadMenuStructure`,
    async (arg, {rejectWithValue}) => {
        try {
            const {data} = await getMenuStructure();
            return data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);
