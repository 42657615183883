export type JsonFormValue = undefined | boolean | number | string | Blob | File | Array<string | Blob> | File[];
type ConvertToFormDataOptions = {
    /**
     * Параметр для передачи массива фалов как key[index.ts]
     */
    shouldUsedFileIndexName?: boolean;
    normalizedShouldUsedFileIndexName?:boolean;
}

export const convertToFormData = (
    obj: { [key: string]: JsonFormValue },
    {
        shouldUsedFileIndexName = false,
        normalizedShouldUsedFileIndexName,
    }: ConvertToFormDataOptions = {},
): FormData => {
    const formData = new FormData();
    Object.keys(obj).forEach(key => {
        if (normalizedShouldUsedFileIndexName) {
            // @ts-ignore
            if (Array.isArray(obj[key]) && obj[key].some(item => item instanceof Blob)) {
                (obj[key] as Array<Blob>).forEach((entry, index) => {
                    const resolveKey = `${key}[${index}]`;
                    formData.append(resolveKey, entry);
                });
                return;
            }
        }
        // @ts-ignore
        if (Array.isArray(obj[key]) && obj[key].some(item => item instanceof Blob)) {
            (obj[key] as Array<Blob>).forEach((entry, index) => {
                if (entry) {
                    const resolveKey = shouldUsedFileIndexName ? `${key}[${index}]` : key;
                    formData.append(resolveKey, entry);
                }
            });
            return;
        }

        if (obj?.shouldUsedFileIndexName) {
            obj = {...obj, shouldUsedFileIndexName: undefined};
        }

        if (obj[key] !== undefined) {
            let formValue = obj[key];

            if (formValue === null) {
                formValue = '';
            }

            formData.append(key, formValue as string | Blob);
        }
    });
    return formData;
};
