import {DeleteOutlined} from '@ant-design/icons';
import {
    Button, Form, Input, Tooltip,
} from 'antd';
import cn from 'classnames';
import React, {useImperativeHandle, useState} from 'react';

import {isConditionValueUnnecessary} from 'components/@common/widgets/custom-table/table-column-menu/utils';
import {CustomSelect} from 'components/form/inputs/custom-select';
import {CustomSelectValueTriggerEvent} from 'components/form/inputs/custom-select/custom-select';
import {
    FormatItemConditionsProps,
    FormListOperationsRef,
} from 'components/report-configuration/report-configuration.types';
import {disableModalFormatValueInput} from 'components/report-configuration/report-configuration.utils';
import {useAppSelector} from 'store/config/hooks';
import {selectReportConfigurationTableAttributesAsSelectEntries} from 'store/slices/report-configuration-slice/report-configuration-slice';

export const IconFormatItemConditions = React.forwardRef<FormListOperationsRef, FormatItemConditionsProps>(({
    form,
    currentFieldPath,
    conditionsFields,
    conditionsListOperations,
    setFieldsCount,
}: FormatItemConditionsProps, ref) => {
    const {add, remove} = conditionsListOperations;
    const [disabledValueInputs, setDisabledValueInputs] = useState<Record<string, boolean>>({});

    useImperativeHandle(ref, () => ({
        add,
        remove,
    }));

    const tableAttributesEntries = useAppSelector(selectReportConfigurationTableAttributesAsSelectEntries);

    return (
        <>{conditionsFields.map(conditionField => (
            <div
                className={cn(
                    'icon-format-modal-content__form__condition-row',
                )}
                key={conditionField.name}
            >
                <Form.Item
                    style={{width: 235}}
                    label="Атрибут"
                    name={[conditionField.name, 'columnName']}
                    rules={[{required: true}]}
                >
                    <CustomSelect
                        entries={tableAttributesEntries}
                        settings={{
                            placeholder: 'Выберите колонку',
                        }}
                    />
                </Form.Item>
                <Form.Item
                    style={{width: 240}}
                    label="Условие"
                    name={[conditionField.name, 'operator']}
                    rules={[{required: true}]}
                >
                    <CustomSelect
                        settings={{
                            url: 'lookupValue/TABLE_REPORT_FILTER_VIEW',
                            labelPath: 'meaning',
                            valuePath: 'lookupCode',
                            placeholder: 'Выберите условие',
                        }}
                        handleTriggerEvent={(value, event) => {
                            if (event === CustomSelectValueTriggerEvent.valueHasBeenChangedManually) {
                                disableModalFormatValueInput({
                                    value,
                                    name: conditionField.name,
                                    form,
                                    valueInputFieldPath: [...currentFieldPath, conditionField.name, 'value'],
                                    disabledValueInputs,
                                    setDisabledValueInputs,
                                });
                            }
                        }}
                    />
                </Form.Item>
                <Form.Item
                    style={{width: 278}}
                    label="Значение"
                    name={[conditionField.name, 'value']}
                    rules={[{
                        required: !isConditionValueUnnecessary(
                            form.getFieldValue([...currentFieldPath, conditionField.name, 'operator']),
                        ),
                    }]}
                >
                    <Input
                        disabled={
                            disabledValueInputs[conditionField.name]
                            || isConditionValueUnnecessary(
                                form.getFieldValue([...currentFieldPath, conditionField.name, 'operator']),
                            )
                        }
                        placeholder={
                            !disabledValueInputs[conditionField.name]
                            && !isConditionValueUnnecessary(
                                form.getFieldValue([...currentFieldPath, conditionField.name, 'operator']),
                            )
                                ? 'Введите значение' : 'Недоступно для заполнения'
                        }
                    />
                </Form.Item>
                <Form.Item
                    label={<>&nbsp;</>}
                >
                    <Tooltip
                        title="Удалить условие"
                        placement="right"
                        mouseEnterDelay={0.8}
                    >
                        <Button
                            type="default"
                            className="btn-only-icon"
                            onClick={() => {
                                remove(conditionField.name);
                                setFieldsCount(p => (p ?? 1) - 1);
                            }}
                        >
                            <DeleteOutlined />
                        </Button>
                    </Tooltip>
                </Form.Item>
            </div>
        ))}
        </>
    );
});
