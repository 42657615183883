import moment from 'moment';
import React from 'react';

import {DATE_WITH_TIME_DOTS_SECONDS_24} from 'shared/constants/date-format';

export interface DateListItemProps {
    date?: string | null;
}

export const DateListItem = ({date}: DateListItemProps) => (
    <div>{date ? moment(date).format(DATE_WITH_TIME_DOTS_SECONDS_24) : '-'}</div>
);
