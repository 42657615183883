import {
    Button, Col, Divider, Form, Input, Row, Space, Tooltip, Typography,
} from 'antd';
import {FormInstance} from 'antd/es/form';
import {FormListFieldData} from 'antd/es/form/FormList';
import {ColProps} from 'antd/es/grid';
import React, {ChangeEvent, useState} from 'react';

import {DynamicIcon} from 'components/dynamic-icon';
import {FieldMeta} from 'modules/metadata';
import {isObject} from 'shared/utils';

import {Field} from '../../field/field';

const DEFAULT_OFFSET = 2;

const DEFAULT_SPAN = 3;

const BUTTON_WRAPPER = {
    DEFAULT: {
        ADD: {
            span: DEFAULT_SPAN, offset: 0,
        },
        DEL: {
            span: DEFAULT_SPAN, offset: 0,
        },
    },
    INNER_LIST: {
        ADD: {
            span: DEFAULT_SPAN, offset: 2,
        },
        DEL: {
            span: DEFAULT_SPAN, offset: 0,
        },
    },
};

const LIST_LAYOUT = {
    DEFAULT: {
        TITLE: {
            offset: 0,
        },
        FORM: {
            offset: DEFAULT_OFFSET,
            span: 22,
        },
    },
    INNER_LIST: {
        TITLE: {
            offset: 2,
        },
        FORM: {
            offset: 4,
            span: 20,
        },
    },
};

type TRenderItemsOptions = {
    form?: FormInstance;
    rows?: number;
    fieldLayout: ColProps;
    buttonsLayout: {
        wrapperCol?: ColProps;
        labelCol?: ColProps;
    };
};

const renderFieldItems = (list: FieldMeta[], fieldProps: FormListFieldData, options: TRenderItemsOptions) => list
    ?.map(formItem => {
        const {form} = options;
        const name = [fieldProps.name, formItem.key];
        const fieldKey = [fieldProps.fieldKey, formItem.key];

        return (
            <Field
                {...fieldProps}
                fieldMeta={formItem}
                name={name}
                fieldKey={fieldKey}
                key={formItem.key}
                form={form}
            />
        );
    });

type TRenderFieldsOptions = {
    form?: FormInstance;
    onRemove: (fieldProps: FormListFieldData) => void;
} & TRenderItemsOptions;

const renderFields = (
    fieldMeta: FieldMeta, fieldsData: FormListFieldData[], options: TRenderFieldsOptions,
) => {
    const {
        customItems, defaultItems = [], dataKeyForFilter,
    } = fieldMeta;
    const {
        onRemove, form, buttonsLayout, fieldLayout,
    } = options;
    let visibleItems = defaultItems;

    return fieldsData.map((fieldProps, index) => {
        if (dataKeyForFilter) {
            const formValue = form?.getFieldValue([fieldMeta.key, fieldProps.key, dataKeyForFilter]);

            const valueForCustomItems = isObject(formValue)
                ? (formValue as Record<string, any>)?.value
                : formValue;
            const additionalItems = customItems?.[valueForCustomItems];

            visibleItems = additionalItems
                ? [...defaultItems, ...additionalItems]
                : defaultItems;
        }
        return (
            <Row key={fieldProps.key}>
                <Col
                    {...fieldLayout}
                >
                    <Divider orientation="left">{`Позиция ${index + 1}`}</Divider>
                    {renderFieldItems(visibleItems, fieldProps, options)}
                    {fieldsData.length > 0 ? (
                        <Form.Item
                            {...buttonsLayout}
                        >
                            <Button
                                danger
                                onClick={() => onRemove(fieldProps)}
                                block
                            >
                                Удалить
                            </Button>
                        </Form.Item>
                    ) : null}
                </Col>
            </Row>
        );
    });
};

interface IOwnProps {
    field: FieldMeta;
    form?: FormInstance;
    rows?: number;
    name?: string | string[];
}

type TProps = IOwnProps;

export const FormList: React.FunctionComponent<TProps> = ({
    field, rows, form, name,
}: TProps) => {
    const [count, setCount] = useState<undefined|number>();
    const {
        key: listName, label: listLabel, isHidden, isDisabled: formListDisabled,
    } = field;
    const showField = !isHidden;
    const formListName = name || listName;
    let formListKey = typeof name === 'string' ? name : listName;
    let fieldLayout = LIST_LAYOUT.DEFAULT.FORM;
    let buttonsLayout = {
        wrapperCol: BUTTON_WRAPPER.DEFAULT.DEL,
    };
    let buttonsWrapperProps = BUTTON_WRAPPER.DEFAULT.ADD;
    let titleLayoutProps = LIST_LAYOUT.DEFAULT.TITLE;

    if (Array.isArray(name)) {
        formListKey = name.toString();
        fieldLayout = LIST_LAYOUT.INNER_LIST.FORM;
        buttonsWrapperProps = BUTTON_WRAPPER.INNER_LIST.ADD;
        buttonsLayout = {wrapperCol: BUTTON_WRAPPER.INNER_LIST.DEL};
        titleLayoutProps = LIST_LAYOUT.INNER_LIST.TITLE;
    }

    return showField
        ? (
            <Form.List
                key={formListKey}
                name={formListName}
            >
                {(fields: FormListFieldData[], {add, remove}) => {
                    if (typeof count === 'undefined') setCount(+fields.length + 1);

                    return (
                        <>
                            <Row style={{marginBottom: 8}}>
                                <Col
                                    {...titleLayoutProps}
                                >
                                    <Space>
                                        <Typography.Text>{listLabel}</Typography.Text>
                                        {field?.hint && (
                                            <Tooltip title={field.hint}>
                                                <DynamicIcon type="QuestionCircleOutlined" />
                                            </Tooltip>
                                        )}
                                    </Space>
                                </Col>
                            </Row>
                            {renderFields(field, fields, {
                                form,
                                rows,
                                fieldLayout,
                                buttonsLayout,
                                onRemove: (fieldProps: FormListFieldData) => {
                                    setCount(+fields.length);
                                    remove(fieldProps.name);
                                },
                            })}
                            <Form.Item
                                noStyle
                                wrapperCol={buttonsWrapperProps}
                            >
                                <Space
                                    style={{marginBottom: 24}}
                                >
                                    <Button
                                        onClick={() => {
                                            setCount(+fields.length + 2);
                                            add(undefined, count ?? +fields.length + 1);
                                        }}
                                        block
                                        disabled={formListDisabled}
                                    >
                                        Добавить
                                    </Button>
                                    <Typography.Text>на позицию</Typography.Text>
                                    <Input
                                        value={count}
                                        onChange={
                                            (event: ChangeEvent<HTMLInputElement>) => setCount(
                                                event.target?.value ? +event.target.value : +fields.length + 1,
                                            )
                                        }
                                    />
                                </Space>
                            </Form.Item>
                        </>
                    );
                }}
            </Form.List>
        ) : null;
};
