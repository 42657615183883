import {CloseOutlined} from '@ant-design/icons';
import Modal, {ModalProps} from 'antd/es/modal';
import cn from 'classnames';
import React, {
    CSSProperties, ReactElement, useRef, useState,
} from 'react';
import Draggable, {DraggableProps} from 'react-draggable';

import {handleStartDragging} from 'shared/utils/draggable';
import {useAppDispatch} from 'store/config/hooks';
import {draggableFramesActions} from 'store/slices/draggable-frames-slice/draggable-frames-slice';

import {DraggableFrameComponentProps} from '../draggable-frame-types';

import './draggable-frame.less';

interface DraggableFrameProps extends DraggableFrameComponentProps {
    classNames?: {
        wrapClassNames?: cn.Argument;
    };
    modalProps?: ModalProps;
    draggableProps?: Partial<DraggableProps>;
    children?: ReactElement;
    modalStyle?: CSSProperties;
}

export const DraggableFrame: React.FC<DraggableFrameProps> = ({
    classNames = {},
    modalProps = {},
    draggableProps = {},
    frameEntity,
    children,
    modalStyle,
}: DraggableFrameProps) => {
    const {isOpen} = frameEntity;
    const dispatch = useAppDispatch();

    const {closeDraggableFrame} = draggableFramesActions;

    const [bounds, setBounds] = useState({
        left: 0, top: 0, bottom: 0, right: 0,
    });
    const draggableRef = useRef<HTMLDivElement>(null);

    const {wrapClassNames} = classNames;

    return (
        <div className={cn('draggable-frame')}>
            <Modal
                getContainer=".draggable-frame"
                visible={isOpen}
                destroyOnClose
                mask={false}
                forceRender={false}
                wrapClassName={cn(
                    'draggable-frame__wrap',
                    wrapClassNames,
                )}
                style={modalStyle}
                closeIcon={(
                    <CloseOutlined onClick={() => dispatch(closeDraggableFrame({
                        type: frameEntity.type,
                        id: frameEntity.id,
                    }))}
                    />
                )}
                footer={null}
                title={(
                    <div>
                        Frame Title
                    </div>
                )}
                modalRender={(modal: React.ReactNode) => (
                    <Draggable
                        nodeRef={draggableRef}
                        bounds={bounds}
                        handle=".ant-modal-header"
                        onStart={handleStartDragging({
                            draggableRef,
                            setBounds,
                        })}
                        {...draggableProps}
                    >
                        <div ref={draggableRef}>
                            {modal}
                        </div>
                    </Draggable>
                )}
                {...modalProps}
            >
                {children ?? 'Frame Body'}
            </Modal>
        </div>
    );
};
