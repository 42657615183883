import {EntityValue} from 'modules/data';
import {FieldType} from 'modules/metadata';

export interface UseFieldInitialValueProps {
    type: FieldType;
    defaultValue?: EntityValue;
}

export const getFieldInitialValue = ({type, defaultValue}: UseFieldInitialValueProps) => {
    const isFieldWithoutDefaultValue = [
        FieldType.REFERENCE,
        FieldType.INPUT_WITH_DEFAULT_VALUE_SETTER,
    ].includes(type);

    return !isFieldWithoutDefaultValue ? defaultValue : undefined;
};
