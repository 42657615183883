import React from 'react';
import {DraggableData, DraggableEvent} from 'react-draggable';

export interface handleStartDraggingArgs {
    draggableRef: React.RefObject<HTMLDivElement>;
    setBounds: React.Dispatch<React.SetStateAction<{
        left: number;
        top: number;
        bottom: number;
        right: number;
    }>>;
}

export const handleStartDragging = ({
    draggableRef,
    setBounds,
}: handleStartDraggingArgs) => (_: DraggableEvent, uiData: DraggableData) => {
    const {clientWidth, clientHeight} = window.document.documentElement;
    const targetRect = draggableRef.current?.getBoundingClientRect();
    if (!targetRect) {
        return;
    }
    setBounds({
        left: -targetRect.left + uiData.x,
        right: clientWidth - (targetRect.right - uiData.x),
        top: -targetRect.top + uiData.y,
        bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
};
