import {Form} from 'antd';
import {useForm} from 'antd/es/form/Form';
import cn from 'classnames';
import React, {
    useEffect,
    useRef,
    useState,
    FC,
} from 'react';

import {SimpleActionButton} from 'components/form/components';
import {CustomSelect, CustomSelectMode} from 'components/form/inputs/custom-select';
import {ModalOpenerComponent, ModalOpenerComponentRef} from 'components/modal-opener-component';
import {setFieldsValueUntouched} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {
    selectTableReportConfigurationData,
} from 'store/slices/report-configuration-slice/report-configuration-slice';
import {
    updateTableReportConfiguration,
} from 'store/slices/report-configuration-slice/report-configuration/report-configuration-thunks';

const ACTIONS_SETTINGS_PREFIX = 'actions' as const;

interface ModalReportActionsSettingsProps {
    templateCode?: string;
    isDisabled: boolean;
}

export const ModalReportActionsSettings: FC<ModalReportActionsSettingsProps> = ({
    templateCode,
    isDisabled,
}: ModalReportActionsSettingsProps) => {
    const dispatch = useAppDispatch();
    const [form] = useForm();
    const modalOpenerRef = useRef<ModalOpenerComponentRef>(null);

    const reportConfigurationData = useAppSelector(
        selectTableReportConfigurationData,
    );

    const [initialValues, setInitialValues] = useState<{[ACTIONS_SETTINGS_PREFIX]:(string | undefined)[] | undefined}>({
        [ACTIONS_SETTINGS_PREFIX]: reportConfigurationData?.[ACTIONS_SETTINGS_PREFIX],
    });

    useEffect(() => {
        const actions = reportConfigurationData?.[ACTIONS_SETTINGS_PREFIX];
        if (actions) {
            setInitialValues({
                actions,
            });
        }
    }, [reportConfigurationData]);

    useEffect(() => {
        if (initialValues) setFieldsValueUntouched(form, initialValues);
    }, [initialValues]);

    const handleFinish = (values: {[ACTIONS_SETTINGS_PREFIX]: string[]}) => {
        if (templateCode) {
            dispatch(updateTableReportConfiguration({
                templateCode,
                data: {
                    actionsEnabled: values?.[ACTIONS_SETTINGS_PREFIX].length > 0,
                    [ACTIONS_SETTINGS_PREFIX]: values?.[ACTIONS_SETTINGS_PREFIX],
                },
            }));
        }
        modalOpenerRef.current?.setIsModalOpen(false);
    };

    return (
        <ModalOpenerComponent
            shouldConfirm={() => form.isFieldsTouched()}
            ref={modalOpenerRef}
            handleCancel={setIsModalOpen => {
                setIsModalOpen(false);
                setFieldsValueUntouched(form, initialValues);
            }}
            handleSave={() => {
                form.submit();
            }}
            component={(
                <SimpleActionButton
                    type="primary"
                    title="Настройка действий"
                    disabled={isDisabled}
                />
            )}
            modalProps={{
                title: 'Настройка действий',
                width: '85%',
                style: {maxWidth: '700px'},
                forceRender: false,
                destroyOnClose: true,
                centered: true,
            }}
        >
            <div className={cn('grouping-settings-modal-opener')}>
                <Form
                    onFinish={handleFinish}
                    form={form}
                    layout="vertical"
                    className={cn('grouping-settings-modal-opener__form')}
                >
                    <Form.Item
                        label="Действия"
                        name={ACTIONS_SETTINGS_PREFIX}
                    >
                        <CustomSelect
                            settings={{
                                url: 'lookupValue/TABLE_REPORT_ACTIONS',
                                valuePath: 'lookupCode',
                                labelPath: 'meaning',
                                placeholder: 'Выберите действие',
                                useFirstOptionAfterEntriesChanged: true,
                                noUseFirstOptionWhenValueIsSet: true,
                                isClearable: true,
                                showSearch: true,
                                mode: CustomSelectMode.multiple,
                            }}
                        />
                    </Form.Item>
                </Form>
            </div>
        </ModalOpenerComponent>
    );
};
