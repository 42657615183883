import React from 'react';
import ReactQuill from 'react-quill';

import {QuillToolbar} from '../toolbar/rich-text-toolbar';

export interface RichTextEditorProps {
    placeholder?: string;
    value?: string;
    onChange?: (value: any) => void;
    disabled?: boolean;
    toolbarId?: string;
}

export const RichTextEditor = ({disabled, ...props}: RichTextEditorProps) => {
    const modules = {
        toolbar: {
            container: `#toolbar-${props.toolbarId}`,
        },
    };
    const theme = 'snow';
    return (
        <>
            <ReactQuill
                theme={theme}
                readOnly={disabled}
                modules={modules}
                {...props}
            />
            <QuillToolbar
                toolbarHtmlId={`toolbar-${props.toolbarId}`}
                disabled={disabled}
            />
        </>
    );
};
