import {createAsyncThunk} from '@reduxjs/toolkit';

import {RpcDeleteResponse} from 'shared/types/rpc';

import {reportConfigurationSliceName} from '../report-configuration-constants';

import {
    deleteDrillDownReferenceRulesRequest,
    DrillDownReferenceRuleDeleteRequestArgs,
    DrillDownReferenceRulePostRequestArgs,
    DrillDownReferenceRulePutRequestArgs,
    fetchDrillDownReferenceRules,
    postDrillDownReferenceRule,
    putDrillDownReferenceRule,
    ReportDdReferenceRule,
    ReportDdReferenceRulesFilter,
} from '.';

export const createDrilldownReferenceRule = createAsyncThunk<
    ReportDdReferenceRule,
    DrillDownReferenceRulePostRequestArgs
>(
    `${reportConfigurationSliceName}/createDrilldownReferenceRule`, async (args, {rejectWithValue}) => {
        try {
            const result = await postDrillDownReferenceRule(args);
            return result.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const editDrilldownReferenceRule = createAsyncThunk<ReportDdReferenceRule, DrillDownReferenceRulePutRequestArgs>(
    `${reportConfigurationSliceName}/editDrilldownReferenceRule`, async (args, {rejectWithValue}) => {
        try {
            const result = await putDrillDownReferenceRule(args);
            return result.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const deleteDrilldownReferenceRules = createAsyncThunk<
    RpcDeleteResponse, DrillDownReferenceRuleDeleteRequestArgs>(
        `${reportConfigurationSliceName}/deleteDrilldownReferenceRule`, async (args, {rejectWithValue}) => {
            try {
                const result = await deleteDrillDownReferenceRulesRequest(args);
                return result.data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

export const loadDrilldownReferenceRules = createAsyncThunk<{
    ddReferenceRules: ReportDdReferenceRule[];
    recordsTotal: number;
}, ReportDdReferenceRulesFilter>(
    `${reportConfigurationSliceName}/loadDrilldownReferenceRules`, async (args, {rejectWithValue}) => {
        try {
            const {data} = await fetchDrillDownReferenceRules(args);
            const {dataTable: ddReferenceRules, recordsTotal} = data;
            return {
                ddReferenceRules,
                recordsTotal,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);
