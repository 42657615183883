import {Input} from 'antd';
import {FormInstance} from 'antd/lib/form';
import React from 'react';

import {FormEntityData} from 'modules/data/data-types';
import {FieldMeta} from 'modules/metadata';

import {Field} from '../../field/field';

import './inline-fields.less';

interface InlineFieldsProps {
    fields?: FieldMeta[];
    formData?: FormEntityData;
    form?: FormInstance;
    disabled?: boolean;
}

export const InlineFields: React.FunctionComponent<InlineFieldsProps> = ({
    fields,
    form,
    formData,
    disabled,
}: InlineFieldsProps) => (
    <Input.Group className="inline-fields">
        {
            fields?.map(fieldMeta => (
                <Field
                    formData={formData}
                    key={fieldMeta.key}
                    fieldMeta={{...fieldMeta, isDisabled: disabled || fieldMeta.isDisabled}}
                    form={form}
                    className="inline-field"
                />
            ))
        }
    </Input.Group>
);
