import {
    Button, Form,
} from 'antd';
import {FormInstance} from 'antd/es/form';
import cn from 'classnames';
import {
    get, set,
} from 'lodash';
import React, {useContext, useState} from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {ModalOpenerComponent} from 'components/modal-opener-component';
import {FormItemWidthInput} from 'components/report-configuration/components/form-item-width-input';
import {TypeGroup} from 'components/report-configuration/report-configuration.constants';
import {ReportConfigurationContext} from 'components/report-configuration/report-configuration.context';
import {ReactComponent as SquareInnerX} from 'shared/assets/square-inner-x.svg';
import {Defined} from 'shared/types/generics';
import {PostgresStringType} from 'shared/types/postgres';
import {ReportConfigurationDataConverted} from 'store/slices/report-configuration-slice/report-configuration-types';

interface FormatSettingsModalOpenerProps {
    name: number;
    disabled: boolean;
    typeGroup: string;
    isFormatRequired: boolean;
    formInstance: FormInstance;
    entry: Defined<ReportConfigurationDataConverted['reportColumns']>[0];
}

export const FormatSettingsModalOpener: React.FC<FormatSettingsModalOpenerProps> = ({
    name,
    disabled,
    typeGroup,
    isFormatRequired,
    formInstance,
    entry,
}: FormatSettingsModalOpenerProps) => {
    const {validationErrors} = useContext(ReportConfigurationContext);
    const formInsideModalHasErrors = !!get(validationErrors, ['reportTableColumns', 'reportColumns', name]);

    const {fieldType} = entry ?? {};
    const isFieldTypeOfString = (() => {
        if (!fieldType) return false;
        return Object.values(PostgresStringType).includes(fieldType as PostgresStringType);
    })();

    const [rowValuesOnModalOpen, setRowValuesOnModalOpen] = useState<any>();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <ModalOpenerComponent
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            controlLabels={{
                save: 'Подтвердить',
            }}
            onShow={() => {
                setRowValuesOnModalOpen(formInstance.getFieldValue('reportTableColumns')?.reportColumns[name]);
            }}
            handleCancel={setIsModalOpen => {
                const {
                    style, align, format, width, enabledTextWrapping,
                } = rowValuesOnModalOpen ?? {};
                const reportTableColumns = formInstance.getFieldValue('reportTableColumns');
                const reportColumnRow = get(reportTableColumns, ['reportColumns', name]);

                set(reportTableColumns, ['reportColumns', name], {
                    ...reportColumnRow,
                    style,
                    align,
                    format,
                    width,
                    enabledTextWrapping,
                });
                formInstance.setFieldsValue({
                    reportTableColumns,
                });
                setIsModalOpen(false);
            }}
            handleSave={setIsModalOpen => {
                formInstance.validateFields();
                setIsModalOpen(false);
            }}
            tooltip={{title: 'Правила форматирования'}}
            component={(
                <>
                    <Button
                        type="primary"
                        className={cn('button-only-icon')}
                        disabled={disabled}
                    >
                        <SquareInnerX className={cn('path-stroke-change-color')} />
                    </Button>

                    {formInsideModalHasErrors && (
                        <div
                            style={{marginLeft: -110, marginTop: 8}}
                            className={cn('ant-form-item-explain ant-form-item-explain-error')}
                        >
                            Некорректные данные
                        </div>
                    )}
                </>
            )}
            modalProps={{
                title: 'Правила форматирования',
                forceRender: true,
                destroyOnClose: false,
                centered: true,
            }}
        >
            <div
                className={cn('format-settings-modal-opener')}
            >
                <div className={cn('format-settings-modal-opener__form', 'form-flex')}>
                    <div className={cn('form-flex-row')}>

                        <Form.Item
                            rules={[{required: true}]}
                            label="Размещение значения"
                            name={[name, 'align']}
                        >
                            <CustomSelect
                                settings={{
                                    url: 'lookupValue/TABLE_REPORT_CELL_ALIGN',
                                    valuePath: 'lookupCode',
                                    labelPath: 'meaning',
                                    placeholder: 'Выберите вариант',
                                    isClearable: true,
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Стиль"
                            name={[name, 'style']}
                        >
                            <CustomSelect
                                settings={{
                                    url: 'lookupValue/TABLE_REPORT_CELL_STYLE',
                                    valuePath: 'lookupCode',
                                    labelPath: 'meaning',
                                    placeholder: 'Выберите стиль оформления',
                                    isClearable: true,
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Формат"
                            name={[name, 'format']}
                            rules={[{required: isFormatRequired}]}
                        >
                            <CustomSelect
                                settings={{
                                    url: 'valueLists/FLEX_VALUE',
                                    referenceUrlQueryParams: {
                                        sublistCode: typeGroup === TypeGroup.DATE
                                            ? 'TABLE_REPORT_FORMAT_DATE'
                                            : 'TABLE_REPORT_FORMAT_NUMBER',
                                    },
                                    valuePath: 'lookupCode',
                                    labelPath: 'meaning',
                                    placeholder: 'Укажите формат',
                                    showSearch: true,
                                    isClearable: true,
                                }}
                            />
                        </Form.Item>
                    </div>
                    {isFieldTypeOfString && (
                        <div className={cn('form-flex-row')}>
                            <FormItemWidthInput
                                name={[name, 'width']}
                            />
                            <Form.Item
                                label="Перенос текста"
                                name={[name, 'enabledTextWrapping']}
                            >
                                <CustomSelect
                                    entries={[
                                        {label: 'Да', value: true},
                                        {label: 'Нет', value: false},
                                    ]}
                                    settings={{
                                        placeholder: 'Значение не выбрано',
                                        showNullValueAsUndefined: true,
                                        isClearable: true,
                                    }}
                                />
                            </Form.Item>
                        </div>
                    )}
                </div>
            </div>
        </ModalOpenerComponent>
    );
};
