import {
    Table,
    Card,
    Checkbox,
    Form,
    Button,
} from 'antd';
import {useForm} from 'antd/es/form/Form';
import React, {useEffect, useState} from 'react';

import './dashboards.less';
import {showMessage} from 'shared/utils';

import {useUserRoleFunctions} from '../../../../shared/hooks/use-user-role-functions';
import {DashboardAPI} from '../../../../store/api/settings-dashboards/settings-dashboards.api';
import {UserRoleFunction} from '../../../../store/slices/user-slice/user-slice-role-functions';
import {ConfirmModal} from '../../../confirm-modal/confirm-modal';
import {DynamicIcon} from '../../../dynamic-icon';
import {CustomSelect, CustomSelectMode} from '../../../form/inputs/custom-select';
import {Spinner} from '../../../spinner';
import {
    CheckboxItem,
    DashboardFilterEntity,
    DashboardFilterSelectItem,
    DashboardFilterSelectEntity,
    DashboardEntity,
    DashColumnItem,
    OptionItem,
    Dictionary,
    DashboardDictionaryType,
} from './dashboards-types';

const columns = [
    {
        title: 'Дашборд',
        dataIndex: 'dashboardName',
        key: 'dashboardName',
    },
    {
        title: 'Описание',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Параметр по умолчанию',
        dataIndex: 'dashboardSelects',
        key: 'dashboardSelects',
        render: (opts: DashboardFilterEntity | undefined) => {
            if (!opts) {
                return (
                    <Spinner />
                );
            }
            return (
                <div>
                    {
                        opts?.selects?.map(selects => {
                            const selectList: OptionItem[] | undefined = selects.selectValues.map(selVal => ({
                                value: selVal.value.id,
                                label: selVal.value.meaning,
                            } as OptionItem));
                            return (
                                <div>
                                    <span>{selects.selectTitle}:</span>
                                    <Form.Item
                                        name={selects.selectCode}
                                    >
                                        <CustomSelect
                                            entries={selectList}
                                            settings={{
                                                placeholder: `Выберите ${selects.selectTitle.toLowerCase()}`,
                                                mode: CustomSelectMode.multiple,
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                            );
                        })
                    }
                </div>
            );
        },
    },
    {
        title: 'Активность',
        dataIndex: 'active',
        key: 'active',
        render: (item:CheckboxItem) => (
            <Form.Item
                name={item.name}
                valuePropName="checked"
            >
                <Checkbox defaultChecked={item.value} />
            </Form.Item>
        ),
    },
];

export const Dashboards = () => {
    const {data: dashboards} = DashboardAPI.useFetchAllDashboardsQuery(null);
    const {data: dashboardsSelects} = DashboardAPI.useFetchAllDashboardsSelectsQuery(null);
    const [uploadDashboard] = DashboardAPI.useFetchAllDashboardsPostMutation();
    const [uploadDashboardList] = DashboardAPI.useFetchAllDashboardsSelectsPostMutation();
    const [initialData, setInitialData] = useState<Dictionary<DashboardDictionaryType>>();
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [values, setValues] = useState<Dictionary<DashboardDictionaryType>>();

    const [form] = useForm();
    const handleSave = (submitData:Dictionary<DashboardDictionaryType>) => {
        if (form.isFieldsTouched()) {
            setValues(submitData);
            setConfirmVisible(true);
        }
    };
    const {hasUserRoleFunction} = useUserRoleFunctions({
        sections: ['app.app.settings.user.settings.dashboards.title'],
    });

    const save = () => {
        if (values) {
            const promises: Promise<any>[] = [];
            const dashPost = dashboards?.map(item => (
                {
                    menuItem: {
                        itemName: item.menuItem.itemName,
                    },
                    isActive: values[item.menuItem.itemName],
                }
            ) as DashboardEntity);
            promises.push(uploadDashboard(dashPost));
            const dashSelectsPost = dashboardsSelects?.map(item => (
                {
                    entityName: item.entityName,
                    selects: item.selects?.map((selItem: DashboardFilterSelectEntity) => {
                        const initialFilter: number[] = values[selItem.selectCode] as number[] ?? [];
                        return (
                            {
                                selectTitle: selItem.selectTitle,
                                selectCode: selItem.selectCode,
                                selectValues: selItem.selectValues?.map(selValItem => (
                                    {
                                        value: selValItem.value,
                                        isActive: initialFilter.includes(selValItem.value.id),
                                    }
                                ) as DashboardFilterSelectItem),
                            }
                        ) as DashboardFilterSelectEntity;
                    }),
                }
            ) as DashboardFilterEntity);
            promises.push(uploadDashboardList(dashSelectsPost));
            Promise.all(promises).then(() => { showMessage({message: 'Данные успешно сохранены'}); });
            setInitialData(values);
        }
        setConfirmVisible(false);
    };

    const handleCancel = () => {
        form.resetFields();
    };

    const handleCloseConfirmModal = () => {
        setConfirmVisible(false);
        form.resetFields();
    };

    useEffect(() => {
        const initialCheckBox: Dictionary<DashboardDictionaryType> = {};
        dashboards?.forEach(item => { initialCheckBox[item.menuItem.itemName] = item.isActive; });
        const initialSelects: Dictionary<DashboardDictionaryType> = {};
        dashboardsSelects?.forEach(item => {
            item?.selects?.forEach(select => {
                const optItems: number[] = [];
                select.selectValues.forEach(selVal => {
                    if (selVal.isActive) {
                        optItems.push(selVal.value.id);
                    }
                });
                initialSelects[select.selectCode] = optItems;
            });
        });
        setInitialData({...initialCheckBox, ...initialSelects});
    }, [dashboards, dashboardsSelects]);

    useEffect(() => {
        form.resetFields();
    }, [initialData]);

    const isDashboardLoaded = dashboards !== undefined;
    const dashSource: DashColumnItem[] | undefined = dashboards?.map((item, i) => {
        const selectsItems = dashboardsSelects?.find(itemSel => itemSel.entityName === item.menuItem.itemName);
        const {description, menuItem, isActive} = item;
        return ({
            key: i,
            dashboardName: menuItem.itemTitle,
            description,
            dashboardSelects: selectsItems,
            active: {
                name: menuItem.itemName,
                value: isActive,
            },
        } as DashColumnItem);
    });
    return (
        <Card
            className="dashboards"
            title="Дашборды"
        >
            <Form
                form={form}
                onFinish={submitData => {
                    handleSave(submitData);
                }}
                initialValues={initialData}
            >
                <div
                    className="dashboards__buttons"
                >
                    {hasUserRoleFunction(UserRoleFunction.CREATE) && (
                        <Button
                            type="primary"
                            htmlType="submit"
                        >
                            <DynamicIcon type="SaveOutlined" />
                            Сохранить
                        </Button>
                    )}
                    {hasUserRoleFunction(UserRoleFunction.CANCEL) && (
                        <Button
                            type="default"
                            onClick={handleCancel}
                        >
                            <DynamicIcon type="CloseXOutlined" />
                            Отменить
                        </Button>
                    )}
                </div>
                {isDashboardLoaded ? (
                    <Table
                        dataSource={dashSource}
                        columns={columns}
                    />
                )
                    : <Spinner />}
            </Form>
            <ConfirmModal
                title="Подтверждение"
                visible={confirmVisible || false}
                content="Требуется подтвердить изменения"
                onCancel={handleCloseConfirmModal}
                cancelText="Нет"
                onConfirm={save}
                approveText="Да"
                useWrapperDivElementAsContainer
            />
        </Card>
    );
};
