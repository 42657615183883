import {
    FileFilled, FileOutlined, LoadingOutlined, MessageFilled, MessageOutlined, PushpinOutlined, PushpinFilled,
} from '@ant-design/icons';
import {Badge, Button, Popover} from 'antd';
import {FormInstance} from 'antd/lib/form';
import cn from 'classnames';
import React, {useRef, FC} from 'react';
import {useDispatch} from 'react-redux';

import {ModalOpenerComponent} from 'components/modal-opener-component';
import {useAppSelector} from 'store/config/hooks';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';
import {tableReportSliceActions} from 'store/slices/table-report-slice';
import {tableReportRowAttachmentsSelectors} from 'store/slices/table-report-slice/table-report-row-attachments';
import {selectTableReportRowAttachmentsGroupedById, selectTableReportTemplateConfig} from 'store/slices/table-report-slice/table-report-slice-selectors';
import {loadTableReportPageData} from 'store/slices/table-report-slice/table-report-slice-thunks';

import {LoadTableReportPageDataFunction} from '../hooks/use-control';
import {TableRowModalOpenerContent} from './table-row-attachments-modal-opener-content';
import {formatDocId} from './table-row-attachments-utils';

import './table-row-attachments.less';

interface TableRowAttachementsProps {
    rowRecord?: any;
    docId: string | null;
    loadReportPageData: LoadTableReportPageDataFunction;
}

export const TableRowAttachements: FC<TableRowAttachementsProps> = (
    {
        rowRecord,
        docId,
        loadReportPageData,
    }: TableRowAttachementsProps,
) => {
    const dispatch = useDispatch();
    const {updateDocIdsForRequest} = tableReportSliceActions;
    const rowCommentsGroupedByRowId = useAppSelector(selectTableReportRowAttachmentsGroupedById);

    const {selectDocIdsToRequest} = tableReportRowAttachmentsSelectors;
    const docIdsToRequest = useAppSelector(selectDocIdsToRequest);

    const templateConfig = useAppSelector(selectTableReportTemplateConfig);

    const isRefreshingData = useAppSelector(s => selectIsThunkPending(s, loadTableReportPageData.typePrefix));

    const rowIdColumnName = templateConfig?.reservedColumns?.rowIdColumnName;

    const {selectTableReportUploadAttachmentsProgressData} = tableReportRowAttachmentsSelectors;

    const {
        withDocumentId,
        templateCode,
        enabledRowAttachments,
        enabledRowComments,
        enabledRowRequests,
    } = templateConfig ?? {};

    const rowId: string | undefined = (() => {
        if (!rowIdColumnName) return undefined;
        return rowRecord[rowIdColumnName];
    })();

    const rowComment = (() => {
        if (!rowId) return null;
        return rowCommentsGroupedByRowId?.[rowId] ?? null;
    })();

    const formRef = useRef<FormInstance>();

    const handleSave = () => {
        formRef.current?.submit();
    };

    const handleSelectDoc = () => {
        dispatch(updateDocIdsForRequest(rowId));
    };

    const attachedFilesAmount = rowComment?.attachments.length;

    const currentProgressData = useAppSelector((() => {
        if (templateCode && rowId) {
            return selectTableReportUploadAttachmentsProgressData({
                reportRowId: rowId,
                templateCode,
                docId: formatDocId({docId, withDocumentId}),
            });
        }
        return () => null;
    })());

    const isAlreadyUploading = currentProgressData?.isRequestPending;

    if (!rowId || !templateCode) return null;
    return (
        <div className={cn('table-row-attachments')}>
            <ModalOpenerComponent
                modalProps={{
                    centered: true,
                    title: 'Вложения',
                    destroyOnClose: true,
                    forceRender: false,
                }}
                controlLabels={{
                    save: (
                        isAlreadyUploading ? (
                            <div style={{marginLeft: -21}}>
                                <LoadingOutlined style={{marginRight: 12}} />
                                Загрузка...
                            </div>
                        ) : 'Сохранить'
                    ),
                    cancel: 'Отмена',
                }}
                disabledControls={{
                    save: isRefreshingData || isAlreadyUploading,
                }}
                shouldConfirm={() => formRef.current?.isFieldsTouched() ?? false}
                handleSave={setIsModalOpen => {
                    handleSave();
                    setIsModalOpen(false);
                }}
                componentWrapperClassNames="d-inline-flex align-items-center"
                component={(
                    <>
                        <Popover
                            placement="bottomRight"
                            mouseEnterDelay={1}
                            content={(
                                <div>
                                    Добавить вложение
                                </div>
                            )}
                        >
                            <div className={cn(
                                'table-row-attachments__row-controls',
                                {
                                    'table-row-attachments__row-controls_is-uploading': isAlreadyUploading,
                                },
                            )}
                            >
                                {enabledRowAttachments && (!attachedFilesAmount
                                    ? <FileOutlined />
                                    : (
                                        <Badge
                                            offset={[-2, 14]}
                                            count={attachedFilesAmount}
                                            size="small"
                                        >
                                            <FileFilled />
                                        </Badge>
                                    ))}
                                {enabledRowComments && (!rowComment?.commentText
                                    ? <MessageOutlined /> : <MessageFilled />)}
                            </div>
                        </Popover>
                        {isAlreadyUploading && <LoadingOutlined style={{marginLeft: 10}} />}
                    </>
                )}
            >
                <TableRowModalOpenerContent
                    templateCode={templateCode}
                    loadReportPageData={loadReportPageData}
                    ref={formRef}
                    rowId={rowId}
                    docId={docId}
                    rowComment={rowComment}
                    isRefreshingData={isRefreshingData}
                    isAlreadyUploading={isAlreadyUploading}
                />
            </ModalOpenerComponent>
            {enabledRowRequests
            && (
                <Button
                    onClick={handleSelectDoc}
                    type="link"
                    className="table-row-attachments__pin-requests"
                >
                    {docIdsToRequest.includes(rowId) ? <PushpinFilled /> : <PushpinOutlined />}
                </Button>
            )}
        </div>
    );
};
