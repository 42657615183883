import {
    Button, Form, Spin,
} from 'antd';
import {useForm} from 'antd/es/form/Form';
import {isEqual} from 'lodash';
import React, {
    useContext, useEffect, useRef, useState,
} from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {ModalOpenerComponent} from 'components/modal-opener-component';
import {ModalOpenerComponentRef} from 'components/modal-opener-component/modal-opener-component';
import {ReportConfigurationFormName} from 'components/report-configuration/report-configuration.constants';
import {ReportConfigurationContext} from 'components/report-configuration/report-configuration.context';
import {selectReferenceEntityData} from 'modules/data/data-selectors';
import {LookupEntry} from 'shared/types/lookups';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {selectIsThunkPending} from 'store/slices/loading-state-slice/loading-state-slice';
import {ReportConfigurationDataConverted} from 'store/slices/report-configuration-slice';
import {
    selectTableReportConfigurationData,
} from 'store/slices/report-configuration-slice/report-configuration-slice';
import {
    loadTableReportConfiguration,
    updateTableReportConfiguration,
} from 'store/slices/report-configuration-slice/report-configuration/report-configuration-thunks';

import {FORMAT_REFERENCE_URL, PROGRAMS_REFERENCE_URL} from './report-download-settings.constants';
import {getExportDataValues, initialExportToData} from './report-download-settings.utils';

import './report-download-settings.less';

interface ModalReportDownloadSettingsProps {
    isDisabled?: boolean;
}

type DownloadSettingsFormData = Pick<ReportConfigurationDataConverted, 'exportTo'>;

export const ModalReportDownloadSettings: React.FC<ModalReportDownloadSettingsProps> = ({
    isDisabled,
}: ModalReportDownloadSettingsProps) => {
    const dispatch = useAppDispatch();

    const [form] = useForm();
    const {
        isCreatingNewTemplate,
        templateCode,
    } = useContext(ReportConfigurationContext);

    const reportConfigurationData = useAppSelector(
        selectTableReportConfigurationData,
    );
    const formatReferenceOptions = useAppSelector(
        selectReferenceEntityData(FORMAT_REFERENCE_URL),
    ) as any as LookupEntry[]; // todo: изменить селекторы, исправить

    const programsReferenceOptions = useAppSelector(
        selectReferenceEntityData(PROGRAMS_REFERENCE_URL),
    )as any as LookupEntry[]; // todo: изменить селекторы, исправить

    const [initialValues, setInitialValues] = useState<DownloadSettingsFormData>(
        {exportTo: initialExportToData},
    );

    const modalRef = useRef<ModalOpenerComponentRef>(null);

    const handleFinish = (values: DownloadSettingsFormData) => {
        if (templateCode) {
            dispatch(updateTableReportConfiguration({
                data: values,
                templateCode,
            }));
        }
        modalRef.current?.setIsModalOpen(false);
    };

    useEffect(() => {
        const exportToValues = (() => {
            if (reportConfigurationData?.exportTo?.length) return reportConfigurationData.exportTo;
            if (!formatReferenceOptions?.length) return initialExportToData;
            return getExportDataValues(formatReferenceOptions, programsReferenceOptions);
        })();

        if (!isEqual(initialValues.exportTo, exportToValues)) {
            setInitialValues({
                exportTo: exportToValues,
            });
        }
    }, [reportConfigurationData, formatReferenceOptions, programsReferenceOptions]);

    useEffect(() => {
        if (initialValues) form.resetFields();
    }, [initialValues]);

    const isLoading = useAppSelector(s => selectIsThunkPending(s, loadTableReportConfiguration.typePrefix));

    return (
        <ModalOpenerComponent
            ref={modalRef}
            shouldConfirm={() => form.isFieldsTouched()}
            handleCancel={setIsModalOpen => {
                form.resetFields();
                setIsModalOpen(false);
            }}
            handleSave={() => {
                form.submit();
            }}
            component={(
                <Button
                    type="primary"
                    disabled={isCreatingNewTemplate || isDisabled}
                >Настройка скачивания
                </Button>
            )}
            modalProps={{
                title: 'Настройка скачивания',
                destroyOnClose: true,
                forceRender: false,
            }}
        >
            <Spin spinning={isLoading}>
                <div className="report-download-settings">
                    <Form<DownloadSettingsFormData>
                        initialValues={initialValues}
                        layout="vertical"
                        form={form}
                        onFinish={values => handleFinish(values)}
                    >
                        <div>
                            <Form.List name={ReportConfigurationFormName.REPORT_DOWNLOAD_SETTINGS}>
                                {(fields, operations: {}, {errors}) => (
                                    <>
                                        <Form.ErrorList errors={errors} />
                                        {fields.map(field => (
                                            <div
                                                key={field.fieldKey}
                                                className="row"
                                            >
                                                <Form.Item
                                                    name={[field.name, 'format']}
                                                    style={{width: 198}}
                                                    label="Формат"
                                                >
                                                    <CustomSelect
                                                        settings={{
                                                            placeholder: 'Выберите формат',
                                                            url: FORMAT_REFERENCE_URL,
                                                            labelPath: 'description',
                                                            valuePath: 'attribute1',
                                                            isDisabled: true,
                                                        }}
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    name={[field.name, 'monitorProgramCode']}
                                                    style={{width: 667}}
                                                    label="Программа"
                                                >
                                                    <CustomSelect
                                                        settings={{
                                                            placeholder: 'Выберите программу',
                                                            url: PROGRAMS_REFERENCE_URL,
                                                            labelPath: 'meaning',
                                                            valuePath: 'lookupCode',
                                                        }}
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    name={[field.name, 'enabled']}
                                                    style={{width: 90}}
                                                    label="Активно"
                                                >
                                                    <CustomSelect
                                                        entries={[
                                                            {label: 'Да', value: true},
                                                            {label: 'Нет', value: false},
                                                        ]}
                                                        settings={{
                                                            placeholder: 'Да/нет',
                                                            isClearable: true,
                                                        }}
                                                    />
                                                </Form.Item>
                                            </div>
                                        ))}

                                    </>
                                )}
                            </Form.List>
                        </div>
                    </Form>
                </div>
            </Spin>

        </ModalOpenerComponent>
    );
};
