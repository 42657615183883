import {TVisibleMetaActionProp} from './metadata-types';

export const MetadataActions = {
    SET_ALL_METADATA: 'SET_ALL_METADATA',
    SET_FORM_DRAFT_METADATA: 'SET_FORM_DRAFT_METADATA',
    SET_METADATA: 'SET_METADATA',
    SET_IS_FETCHED_WITH_CONTEXT: 'SET_IS_FETCHED_WITH_CONTEXT',
    RESET_METADATA: 'RESET_METADATA',
};

export const METADATA_MODULE = 'METADATA_MODULE';

export const ActionVisibility: {
    CREATION: TVisibleMetaActionProp;
    EDITING: TVisibleMetaActionProp;
    VIEWING: TVisibleMetaActionProp;
    DEFAULT: TVisibleMetaActionProp;
} = {
    DEFAULT: 'default',
    VIEWING: 'viewing',
    EDITING: 'editing',
    CREATION: 'creating',
};
