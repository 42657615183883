import {Input, Form, Popover} from 'antd';
import {Rule} from 'antd/es/form';
import cn from 'classnames';
import omit from 'lodash/omit';
import React from 'react';

import {EditableTableInput} from 'components/editable-table/editable-table-constants';
import {EditableTableContext} from 'components/editable-table/editable-table-context';
import {cellValidationResolver} from 'components/editable-table/editable-table-utils';
import {useFormWatcher} from 'components/form/hooks/use-form-watcher';
import {Entity} from 'modules/data';
import {FieldMeta, ValidationRules} from 'modules/metadata/metadata-types';
import './editable-cell.less';

import {EditableInput, EditableTableInputProps, FieldStateType} from '../../editable-table-types';

export interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    alwaysEditable: boolean;
    children: React.ReactNode;
    dataIndex: keyof Entity;
    record: Entity;
    handleSave: (record: Entity) => void;
    key?: string;
    inputType?: EditableInput;
    inputProps?: EditableTableInputProps;
    generateInputProps?: (fieldKey: string, record?: Entity) => EditableTableInputProps;
    getFieldState?: (fieldKey: string, record: Entity) => FieldStateType;
    entityName?: string;
    required?: boolean;
    disabled: boolean;
    placeholder?: string;
    defaultValue?: string | number;
    field?: FieldMeta;
    validationRules?: ValidationRules[];
    rules?: Rule[];
    width?: string | number;
    className?: string;
    format?: string;
}

const DEFAULT_STATE_CELL = {
    activated: true,
    required: false,
} as FieldStateType;

const DEFAULT_FIELD_STATE: Record<string, FieldStateType> = {
    uploadDate: {
        activated: false,
        required: false,
    },
};

const getDefaultFieldState = (fieldKey: string) => DEFAULT_FIELD_STATE[fieldKey] || DEFAULT_STATE_CELL;

export const EditableCell: React.FunctionComponent<EditableCellProps> = ({
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    inputType,
    inputProps = {},
    alwaysEditable,
    generateInputProps,
    entityName,
    title,
    getFieldState,
    disabled,
    placeholder,
    required,
    validationRules,
    width,
    field,
    className,
    format,
    ...restProps
}: EditableCellProps) => {
    const [editing, setEditing] = React.useState(false);
    const filterInputProps = inputProps;
    const form = React.useContext(EditableTableContext)!;
    const stateCell = getFieldState ? {
        ...getDefaultFieldState(dataIndex),
        ...getFieldState(dataIndex, record),
        required,
    } : DEFAULT_STATE_CELL;
    const generatedProps = generateInputProps ? generateInputProps(dataIndex, record) : {};
    const fieldErrors = form.getFieldsError([dataIndex])[0].errors;

    const {
        isRequired: isRequiredByWatcher,
    } = useFormWatcher({fieldMeta: field || {key: '', label: ''} as FieldMeta, form});

    React.useEffect(() => {
        if (editable && stateCell.activated) {
            const newFieldsValue = {[dataIndex]: record?.[dataIndex]};
            form.setFieldsValue(newFieldsValue);
        }
    }, [record]);

    React.useEffect(() => {
        if (!stateCell.activated) {
            form.resetFields([dataIndex]);
            form.setFieldsValue({[dataIndex]: ''});
        }
    }, [stateCell.activated, stateCell.required]);
    /**
     * Для сброса отображения ошибки при изменении состояния поля
     */
    React.useEffect(() => {
        if (!stateCell.required && form.getFieldsError([dataIndex])[0].errors.length) {
            form.resetFields([dataIndex]);
        }
    }, [stateCell.required]);

    React.useEffect(() => {
        if (!form.getFieldValue(dataIndex) && generatedProps.defaultValue) {
            form.setFieldsValue({[dataIndex]: generatedProps.defaultValue});
        }
    }, [generatedProps]);

    const toggleEdit = () => {
        setEditing(!editing);
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            handleSave({...record, ...values, isError: false});
            setTimeout(() => toggleEdit(), 0);
        } catch (errInfo) {
            console.error('Save failed:', errInfo);
            handleSave({...record, ...(errInfo.values || {}), isError: true});
        }
    };
    const omitGeneratedProps = (keysToOmit: (keyof EditableCellProps)[]) => omit(generatedProps, keysToOmit);
    let childNode = children;
    if (editable) {
        const InputComponent = inputType ? EditableTableInput[inputType] : Input;
        childNode = editing || alwaysEditable ? (
            <Popover
                placement="bottomLeft"
                content={fieldErrors.join('\n')}
                // hidden через css потому что по другому ломается логика сбрасывания ошибки у поля
                overlayClassName={cn('editable-cell__popover', (field?.isHidden || !fieldErrors.length) && 'hidden')}
            >
                <Form.Item
                    name={dataIndex}
                    rules={cellValidationResolver(
                        form,
                        stateCell,
                        title,
                        record,
                        validationRules,
                        isRequiredByWatcher || generatedProps.required,
                    )}
                    className={cn('editable-cell__value', field?.isHidden && 'hidden')}
                >
                    <InputComponent
                        onChange={alwaysEditable ? save : () => {
                        }}
                        onPressEnter={save}
                        onBlur={!alwaysEditable ? save : undefined}
                        disabled={!stateCell.activated || disabled || stateCell.disabled}
                        {...filterInputProps}
                        {...omitGeneratedProps(['defaultValue'])}
                        entityName={entityName}
                        fixedDropdown={false}
                        isRequired={isRequiredByWatcher}
                        autoComplete="off"
                        placeholder={placeholder}
                        isFilterable
                        width={width}
                        valueId={record?.id}
                        format={format}
                    />
                </Form.Item>
            </Popover>
        ) : (
            <div
                role="presentation"
                className={cn('editable-cell__value', field?.isHidden && 'hidden')}
                style={{paddingRight: 24}}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }
    return (
        <td
            {...restProps}
            width={width}
            className={cn(className, dataIndex === 'type' && 'type-width-limit')}
        >{childNode}
        </td>
    );
};
