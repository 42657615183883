import {unwrapResult} from '@reduxjs/toolkit';
import {DatePicker, Form, Table} from 'antd';
import Spin from 'antd/es/spin';
import Title from 'antd/lib/typography/Title';
import cn from 'classnames';
import {Moment} from 'moment';
import moment from 'moment/moment';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import {UIBlocker} from 'components/ui-blocker';
import {DATE_DMY} from 'shared/constants/date-format';
import {showRequestErrorMessage} from 'shared/utils/show-message-from-response';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {FetchAisDashboardDataResponseDto} from 'store/slices/ais-slice/ais-dashboards/ais-dashboards-api';
import {selectThunkStatus} from 'store/slices/loading-state-slice';
import {loadRequestsData, selectWidgetsRequestsData} from 'store/slices/widgets-slice';

import './request-table.less';

import {
    convertAisDashboardElementDtoToRequestStatusesData,
    convertMomentToDate,
} from '../../../../pages/ais/ais-main-page/dashboards/ais-request-statuses-dashboard/ais-request-statuses-dashboard.utils';
import {Card} from '../../components/card';

export interface RequestsTableData extends FetchAisDashboardDataResponseDto {}

interface RequestTableProps {
    title?: string;
    backLink?: {
        url: string;
        title: string;
    };
}

export const RequestTable: React.FunctionComponent<RequestTableProps> = ({
    title = 'Запросы',
    backLink = {
        title: 'Все запросы →',
        url: 'requests',
    },
}: RequestTableProps) => {
    const dispatch = useAppDispatch();
    const {elements} = useAppSelector(selectWidgetsRequestsData) || {};
    const [startDate, setStartDate] = useState<Moment | null>(
        moment(`${new Date().getFullYear()}-01-01`),
    );
    const [endDate, setEndDate] = useState<Moment | null>(moment());

    const loadData = () => {
        dispatch(loadRequestsData({
            startDate: convertMomentToDate(startDate),
            endDate: convertMomentToDate(endDate),
        })).then(unwrapResult).catch(showRequestErrorMessage);
    };

    useEffect(() => {
        loadData();
    }, [startDate, endDate]);

    const fetchRequestsDataStatus = useAppSelector(s => selectThunkStatus(s, loadRequestsData.typePrefix));
    const [isLoading, requestsError] = [fetchRequestsDataStatus.isPending, fetchRequestsDataStatus.error];
    const renderMeaningCell = (value: string, record: any) => (
        <div className={cn('title', {parent: record?.isParentElement})}>
            {value}
        </div>
    );

    return (
        <Card className="request-table-widget">
            <div className="request-table-widget__header-wrapper">
                <Title
                    className="request-table-widget__title"
                    level={2}
                >
                    {title}
                </Title>
                <Link to={backLink.url}>
                    {backLink.title}
                </Link>
            </div>
            <div>
                <Form
                    layout="horizontal"
                    colon={false}
                    className="d-flex gap-2"
                >
                    <Form.Item label="Период с">
                        <DatePicker
                            className="date-picker"
                            value={startDate}
                            placeholder="Дата"
                            onChange={v => setStartDate(v)}
                            format={DATE_DMY}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Период по"
                    >
                        <DatePicker
                            className="date-picker"
                            value={endDate}
                            placeholder="Дата"
                            onChange={v => setEndDate(v)}
                            format={DATE_DMY}
                        />
                    </Form.Item>
                </Form>
            </div>
            <UIBlocker
                thunkError={{
                    error: requestsError,
                    retryCallback: () => {
                        loadData();
                    },
                }}
                label="Во время работы произошла ошибка получения данных"
            >
                <Spin
                    spinning={isLoading}
                    tip="Загрузка..."
                >
                    <Table
                        pagination={{hideOnSinglePage: true}}
                        className={cn('request-table-widget__table')}
                        scroll={{y: 300}}
                        columns={[
                            {
                                title: 'Тип запроса',
                                dataIndex: 'meaning',
                                key: 'meaning',
                                width: 230,
                                render: (v, r) => renderMeaningCell(v, r),
                            },
                            {title: 'Новые', dataIndex: 'new', key: 'new'},
                            {title: 'В работе', dataIndex: 'inProgress', key: 'inProgress'},
                            {title: 'Завершено', dataIndex: 'completed', key: 'completed'},
                            {title: 'Всего', dataIndex: 'result', key: 'result'},
                        ]}
                        dataSource={convertAisDashboardElementDtoToRequestStatusesData(elements)}
                    />
                </Spin>
            </UIBlocker>
        </Card>
    );
};
