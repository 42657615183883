import React from 'react';

import {TableHeaderCell} from '../../excel-header-structure-loader-modal.types';
import {getFirstHiddenNodeWithVisibleChildrens} from '../../excel-header-structure-loader-modal.utils';
import {HeaderTreeMapperParentNode} from '../header-tree-mapper-parent-node';
import {HeaderTreeMapperRow} from '../header-tree-mapper-row/header-tree-mapper-row';

interface HeaderTreeMapperNodeProps {
    node: TableHeaderCell;
}

export const HeaderTreeMapperNode = ({node}: HeaderTreeMapperNodeProps) => {
    let nodeToPickDataFrom: TableHeaderCell | null = node;

    // если узел спрятан, значит внутри должен быть вложен видимый элемент(или их массив)
    if (Number(nodeToPickDataFrom?.cellHeight) === -1) {
        nodeToPickDataFrom = getFirstHiddenNodeWithVisibleChildrens(node);
    }

    return (
        <div className="header-tree-mapper-node">
            {(() => {
                const showOnlyChildrens = Number(nodeToPickDataFrom?.cellHeight) === -1;

                if (showOnlyChildrens && nodeToPickDataFrom?.children?.length) {
                    return nodeToPickDataFrom?.children?.map(ch => {
                        if (ch?.children?.length) {
                            return <HeaderTreeMapperParentNode node={ch} />;
                        }
                        return (
                            <HeaderTreeMapperRow
                                key={ch.key}
                                node={ch}
                            />
                        );
                    });
                }

                if (nodeToPickDataFrom?.children?.length) {
                    return (
                        <HeaderTreeMapperParentNode
                            node={nodeToPickDataFrom}
                        />
                    );
                }
                return <HeaderTreeMapperRow node={nodeToPickDataFrom} />;
            })()}
        </div>
    );
};
