import React, {createContext} from 'react';

export interface DocumentUploadTemplateContextArgs {
    entityName: string;
    selectedTabKey: React.Key | null;
    setSelectedTabKey: React.Dispatch<React.SetStateAction<React.Key>> | null;
    isCreatingNewTemplate: boolean;
    templateCode?: string;
}

export const DocumentUploadTemplateContext = createContext<DocumentUploadTemplateContextArgs>({
    entityName: '',
    selectedTabKey: null,
    setSelectedTabKey: null,
    isCreatingNewTemplate: false,
});
