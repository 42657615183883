import {Spin} from 'antd';
import {RadioChangeEvent} from 'antd/lib/radio';
import classNames from 'classnames';
import React, {useContext, useMemo} from 'react';
import {useLocation} from 'react-router-dom';

import {selectEntityNamesFetchedWithContext} from 'modules/context/context-selector';
import {TableEntityMeta} from 'modules/metadata/metadata-types';
import {useAppSelector} from 'store/config/hooks';

import {SubsectionSwitcher} from '../../subsection-switcher/subsection-switcher';
import {TableAction} from '../action';
import {TableMode, TableModeContext} from '../context';

export interface TableActionsProps {
    entityName: string;
    url: string;
    metadata?: TableEntityMeta;
    willFetchMetaWithContext?: boolean;
    metaIsBeingFetched?: boolean;
    handleSubsectionChange?: (e: RadioChangeEvent) => void;
    checkedSubsectionValue?: string;
}

export const TableActions = ({
    entityName,
    metadata,
    url,
    metaIsBeingFetched,
    handleSubsectionChange,
    checkedSubsectionValue,
}: TableActionsProps) => {
    const location = useLocation();

    const entityNamesFetchedWithContext = useAppSelector(selectEntityNamesFetchedWithContext);
    const isFetchedWithContext = entityNamesFetchedWithContext.includes(entityName);

    const loading = metaIsBeingFetched || (metadata?.useContext && !isFetchedWithContext);
    const {tableMode} = useContext(TableModeContext);

    const entityClassName = entityName?.split('.').join('-');
    const actionsClassNames = classNames(
        {
            'table-container__actions': true,
            [String(entityClassName)]: entityName,
            'edit-interface-actions': metadata?.allowEditTableInterface && tableMode === TableMode.VIEW,
            'edit-filter-buttons': tableMode === TableMode.EDIT,
        },
    );

    const actions = useMemo(() => metadata?.actions?.sort(
        (a, b) => (a.order && b.order ? a.order - b.order : 1),
    ), [metadata?.actions]);
    return actions?.length || metadata?.subsections ? (
        <div className={actionsClassNames}>
            {!loading && actions && actions.map(action => (
                <TableAction
                    meta={action}
                    key={action.actionTitle}
                    entityName={entityName}
                    url={url}
                />
            ))}
            {loading && <Spin className="spin" />}
            {metadata?.subsections && Boolean(metadata?.subsections?.length) && (
                <SubsectionSwitcher
                    entityName={entityName}
                    location={location.pathname}
                    subsections={metadata.subsections}
                    changeSubsection={handleSubsectionChange}
                    checkedSubsectionValue={checkedSubsectionValue}
                />
            )}
        </div>
    ) : null;
};
