import React from 'react';

import {MonitorServicesModal} from 'components/ais/monitor-services-card/monitor-services-modal';
import {Table} from 'components/table';
import {TableScroll} from 'components/table/table';
import {EntityType} from 'shared/constants/entities';

export interface MonitorServicesPageProps {
    url: string;
    hideTitle?: boolean;
    entityType?: EntityType;
    entityName: string;
    scroll?: TableScroll;
    hasEntity?: boolean;
    handleFormClose: () => void;
}

export const MonitorServicesPage = ({
    hasEntity, scroll, url, hideTitle, entityType, entityName, handleFormClose,
}: MonitorServicesPageProps) => (
    <>
        <Table
            hideTitle={hideTitle}
            entityName={entityName}
            url={url}
            entityType={entityType}
            scroll={scroll}
        />
        <MonitorServicesModal
            entityName={entityName}
            visible={!!hasEntity}
            onFormClose={handleFormClose}
        />
    </>
);
