import {Button, Menu} from 'antd';
import Spin from 'antd/es/spin';
import cn from 'classnames';
import React, {useEffect, useRef, useState} from 'react';

import {ActionBar} from 'components/@common/widgets/action-bar';
import {CustomCard} from 'components/@common/widgets/custom-card';
import {CustomRadio} from 'components/@common/widgets/custom-radio';
import {KebabMenu} from 'components/@common/widgets/kebab-menu';
import {ParametersFilter} from 'components/@common/widgets/parameters-filter';
import {ParametersFilterRef} from 'components/@common/widgets/parameters-filter/parameters-filter';
import {AIS_ROUTES} from 'components/ais/ais.constants';
import {IconsMap} from 'components/dynamic-icon';
import {FormMode} from 'components/form';
import {ModalOpenerComponentRef} from 'components/modal-opener-component';
import {UIBlocker} from 'components/ui-blocker';
import {createActionGetFile} from 'modules/data/data-actions';
import {RequestType} from 'modules/metadata';
import {ReactComponent as ReloadOutlined} from 'shared/assets/reload.svg';
import {LocationStateKey, useAppHistory} from 'shared/hooks/use-app-history';
import {useUserRoleFunctions} from 'shared/hooks/use-user-role-functions';
import {omitEmptyLines} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {aisSelectors, aisSliceActions} from 'store/slices/ais-slice/ais-slice';
import {transferAisDocumentsRegister} from 'store/slices/ais-slice/ais-slice-api';
import {
    loadAisDocumentsRegister,
    loadAisDocumentsRegisterFields,
    loadAisDocumentsRegisterSettings,
} from 'store/slices/ais-slice/ais-slice-thunks';
import {selectIsThunkPending, selectThunkError} from 'store/slices/loading-state-slice';
import {openModal} from 'store/slices/modals-slice';
import {ModalType} from 'store/slices/modals-slice/modals-slice-types';
import {UserRoleFunction} from 'store/slices/user-slice/user-slice-role-functions';

import {AisDocumentsParametersFilter} from './ais-documents-parameters-filter';
import {AIS_REGISTER_ROLE_SECTION, URL_DOWNLOAD_EXCEL} from './ais-documents-register-constants';
import {AisDocumentsRegisterTable, AisDocumentsRegisterTableRef} from './ais-documents-register-table';
import {
    DocumentsRegisterParametersFilter,
    DocumentsRegisterParametersFilterForm,
    DocumentsRegisterRequestQuery,
} from './ais-documents-register-types';
import {extractFilterValuesFromRequestData} from './ais-documents-register-utils';
import {DeleteConfirmationModal} from './modals/delete-confirmation-modal';
import {DocumentCardModal} from './modals/document-card-modal';
import {SetActiveFlagModalConfirmation} from './modals/set-active-flag-modal-confirmation';

import './ais-documents-register.less';

interface AisDocumentsRegisterProps {}

export const AisDocumentsRegister: React.FC<AisDocumentsRegisterProps> = () => {
    const dispatch = useAppDispatch();
    const history = useAppHistory();

    const isLoadingRegisterTableData = useAppSelector(s => selectIsThunkPending(
        s, loadAisDocumentsRegister.typePrefix,
    ));

    const registerTableLoadError = useAppSelector(s => selectThunkError(
        s, loadAisDocumentsRegister.typePrefix,
    ));

    const aisLocationState = history.currentState?.[LocationStateKey.AIS_REGISTER];
    const linkFilterLocationState = history.currentState?.[LocationStateKey.LINK_FILTER_PARAMETERS];

    const [documentsParametersFilter, setDocumentsParametersFilter] = useState<DocumentsRegisterParametersFilter>();
    const [documentsRequestQuery, setDocumentsRequestQuery] = useState<DocumentsRegisterRequestQuery>();

    const createDocumentModalRef = useRef<ModalOpenerComponentRef>(null);
    const deleteConfirmationModalRef = useRef<ModalOpenerComponentRef>(null);
    const setActiveFlagModalConfirmationRef = useRef<ModalOpenerComponentRef>(null);
    const aisDocumentsRegisterTableRef = useRef<AisDocumentsRegisterTableRef>(null); // достаём метод изменения
    // сортировок и фильтров из компонента таблицы

    const {hasUserRoleFunction} = useUserRoleFunctions({
        sections: [AIS_REGISTER_ROLE_SECTION],
    });

    const resetPage = () => {
        if (documentsParametersFilter) setDocumentsParametersFilter(p => ({...p})); // сброс страницы на 1-ю
        aisDocumentsRegisterTableRef?.current?.setSortData(p => ({...p, noFetch: true})); // Реакт
        // сбатчит изменения стейта и отправит только 1 запрос, в котором сортировки и фильтры таблицы будут сброшены.
    };

    const handleRefresh = () => {
        aisDocumentsRegisterTableRef?.current?.setSortData(() => ({
            filters: {},
            sort: {},
            noFetch: true,
        }));
        resetPage();
    };

    const parametersFilterRef = useRef<ParametersFilterRef<DocumentsRegisterParametersFilterForm>>(null);

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const pageSettings = useAppSelector(aisSelectors.selectAisRegisterDocumentsSettings);
    const isSettingsLoading = useAppSelector(s => selectIsThunkPending(
        s, loadAisDocumentsRegisterSettings.typePrefix,
    ));

    const fields = useAppSelector(aisSelectors.selectAisRegisterDocumentsFields);
    const isFieldsLoading = useAppSelector(s => selectIsThunkPending(
        s, loadAisDocumentsRegisterFields.typePrefix,
    ));

    useEffect(() => {
        if (!pageSettings) dispatch(loadAisDocumentsRegisterSettings());
        if (!fields) dispatch(loadAisDocumentsRegisterFields());
    }, []);

    const isLoading = isSettingsLoading || isFieldsLoading;

    useEffect(() => {
        if (!fields || !pageSettings) return;
        if (aisLocationState && aisLocationState.requests.requestId !== undefined) {
            parametersFilterRef.current?.setInitialFormValues({
                ...extractFilterValuesFromRequestData({
                    ...linkFilterLocationState,
                    ...aisLocationState?.requests?.requestData,
                }),
            });
        }
    }, [isLoading]);

    const handleTransfer = () => {
        transferAisDocumentsRegister();
        handleRefresh();
    };

    return (
        <div className={cn('ais-documents-register')}>
            <CustomCard
                isLoading={isLoading}
                accordionTransitionType="1"
                title="Реестр документов, размещённых в информационной системе организации"
                accordionContent={pageSettings?.useParameters && (
                    <ParametersFilter<DocumentsRegisterParametersFilterForm>
                        ref={parametersFilterRef}
                        onChange={values => {
                            setDocumentsParametersFilter(omitEmptyLines(values));
                        }}
                        onClear={() => {
                            dispatch(aisSliceActions.resetRegisterDocuments());
                        }}
                        className={cn('ais-documents-register__filters')}
                    >
                        {AisDocumentsParametersFilter}
                    </ParametersFilter>
                )}
                isAccordionContentVisibleByDefault={!aisLocationState?.requests?.requestData}
            >
                <ActionBar>
                    <CustomRadio
                        defaultValue="reg"
                        items={[
                            {label: 'Реестр документов', value: 'reg'},
                            {
                                label: 'Реестр документов по налогам',
                                value: 'tax_reg',
                                onClick: () => {
                                    history.push(`${AIS_ROUTES.DOCUMENTS_BY_TAX_REGISTER}`);
                                },
                            }]}
                    />
                    <KebabMenu placeholder={[
                        UserRoleFunction.CREATE,
                        UserRoleFunction.DELETE,
                        UserRoleFunction.AIS_PUBLISH,
                        UserRoleFunction.DOWNLOAD_EXCEL,
                        UserRoleFunction.XX_EXT_UPLOAD_DOC_JOURNAL,
                        UserRoleFunction.DOWNLOAD_TEMPLATE,
                        UserRoleFunction.UPLOAD_EXCEL,
                        UserRoleFunction.XX_NALMON_UPLOAD_DOC_JOURNAL,
                    ].every(f => !hasUserRoleFunction(f)) ? <>Нет доступных действий</> : undefined}
                    >
                        <Menu>
                            {hasUserRoleFunction(UserRoleFunction.CREATE) && (
                                <Menu.Item onClick={() => {
                                    createDocumentModalRef.current?.showModal();
                                }}
                                >Добавить запись
                                </Menu.Item>
                            )}

                            {hasUserRoleFunction(UserRoleFunction.DELETE) && (
                                <Menu.Item
                                    disabled={!selectedRowKeys.length}
                                    onClick={() => {
                                        deleteConfirmationModalRef.current?.showModal();
                                    }}
                                >Удалить запись
                                </Menu.Item>
                            )}

                            {hasUserRoleFunction(UserRoleFunction.AIS_PUBLISH) && (
                                <Menu.Item
                                    disabled={!selectedRowKeys.length}
                                    onClick={() => {
                                        setActiveFlagModalConfirmationRef.current?.showModal();
                                    }}
                                >Сделать доступным для отправки в АИС
                                </Menu.Item>
                            )}

                            {hasUserRoleFunction(UserRoleFunction.DOWNLOAD_EXCEL) && (
                                <Menu.Item
                                    onClick={() => {
                                        dispatch(openModal({
                                            entityName: AIS_ROUTES.DOCUMENTS_REGISTER,
                                            modalName: ModalType.documentsFileUpload,
                                        }));
                                    }}
                                >
                                    Загрузить Excel
                                </Menu.Item>
                            )}

                            {hasUserRoleFunction(UserRoleFunction.XX_EXT_UPLOAD_DOC_JOURNAL)
                                    && <Menu.Item>Выгрузить из внешней системы</Menu.Item>}

                            {hasUserRoleFunction(UserRoleFunction.DOWNLOAD_TEMPLATE)
                                    && <Menu.Item>Скачать шаблон</Menu.Item>}

                            {hasUserRoleFunction(UserRoleFunction.UPLOAD_EXCEL)
                                    && (
                                        <Menu.Item
                                            onClick={() => {
                                                dispatch(
                                                    createActionGetFile({
                                                        referenceUrl: URL_DOWNLOAD_EXCEL,
                                                        requestType: RequestType.GET,
                                                        data: documentsRequestQuery,
                                                    }),
                                                );
                                            }}
                                        >
                                            Выгрузить отчёт в Excel
                                        </Menu.Item>
                                    )}

                            {hasUserRoleFunction(UserRoleFunction.XX_NALMON_UPLOAD_DOC_JOURNAL) && (
                                <Menu.Item
                                    title="Поиск и перенос данных из Витрины"
                                    onClick={() => {
                                        handleTransfer();
                                    }}
                                >
                                    Выгрузить документы из витрины
                                </Menu.Item>
                            )}

                            <DocumentCardModal
                                ref={createDocumentModalRef}
                                resetPage={resetPage}
                                initialFormMode={FormMode.CREATE}
                            />
                            <DeleteConfirmationModal
                                ref={deleteConfirmationModalRef}
                                selectedRowKeys={selectedRowKeys}
                                handleRefresh={handleRefresh}
                                setSelectedRowKeys={setSelectedRowKeys}
                            />
                            <SetActiveFlagModalConfirmation
                                ref={setActiveFlagModalConfirmationRef}
                                documentIds={selectedRowKeys}
                                setDocumentIds={setSelectedRowKeys}
                            />
                        </Menu>
                    </KebabMenu>
                    <Button
                        disabled={!documentsParametersFilter || isLoadingRegisterTableData}
                        type="default"
                        onClick={() => {
                            handleRefresh();
                        }}
                        icon={<ReloadOutlined />}
                    >
                        Обновить
                    </Button>
                    <>
                        {aisLocationState && (
                            <Button
                                type="default"
                                onClick={() => {
                                    const {requestId} = aisLocationState?.requests ?? {};
                                    if (requestId) {
                                        history.push({
                                            pathname: `/requests/${requestId}`,
                                        });
                                    }
                                }}
                            >
                                <IconsMap.ArrowBackOutlined />
                                Вернуться к запросу
                            </Button>
                        )}
                    </>
                </ActionBar>
                <Spin
                    spinning={isLoadingRegisterTableData}
                    tip="Загрузка данных реестра..."
                >
                    <UIBlocker
                        thunkError={{
                            error: registerTableLoadError,
                            verbose: true,
                        }}
                        extra={(
                            <>
                                <Button
                                    type="default"
                                    onClick={() => {
                                        handleRefresh();
                                    }}
                                    icon={<ReloadOutlined />}
                                >
                                    Обновить
                                </Button>
                            </>
                        )}
                    >
                        <AisDocumentsRegisterTable
                            documentsRequestQuery={documentsRequestQuery}
                            setDocumentsRequestQuery={setDocumentsRequestQuery}
                            documentsParametersFilter={documentsParametersFilter}
                            selectedRowKeys={selectedRowKeys}
                            setSelectedRowKeys={setSelectedRowKeys}
                            ref={aisDocumentsRegisterTableRef}
                        />
                    </UIBlocker>
                </Spin>
            </CustomCard>
        </div>
    );
};
