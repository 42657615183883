import {
    Button, Form as AntForm, Modal, Space,
} from 'antd';
import {useForm} from 'antd/es/form/Form';
import cn from 'classnames';
import React from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {CustomSelectEntry} from 'components/form/inputs/custom-select/custom-select';
import {ReactComponent as CloseIcon} from 'shared/assets/close.svg';
import {useAppSelector} from 'store/config/hooks';
import {
    selectReportConfigurationMenuAsEntriesList,
} from 'store/slices/report-configuration-slice/report-configuration-slice';

interface TableReportCreateSheetModalProps {
    visible: boolean;
    closeModal: () => void;
    addNewSheets: (entry: CustomSelectEntry<string, any>) => void;
    selectedSheets: CustomSelectEntry<string, any>[];
    clearCustomColumns?: () => void;
}

export const TableReportCreateSheetModal = ({
    visible,
    closeModal,
    addNewSheets,
    selectedSheets,
    clearCustomColumns,
}: TableReportCreateSheetModalProps) => {
    const [form] = useForm();

    const reportMenuAsEntriesList = useAppSelector(selectReportConfigurationMenuAsEntriesList);

    const handleSubmit = (values: any) => {
        clearCustomColumns?.();
        const sheet = reportMenuAsEntriesList?.find(v => v.value === values?.sheet);
        const {value, label} = sheet ?? {};
        if (sheet) {
            addNewSheets({label: label as string, value});
        }
        closeModal();
    };

    return (
        <Modal
            centered
            width={980}
            footer={null}
            title="Создание настройки табличной части"
            destroyOnClose
            visible={visible}
            maskClosable={false}
            onCancel={closeModal}
            className="set-input-value-modal modal-header"
            closeIcon={<CloseIcon className={cn('modal-close-icon', 'with-title')} />}
        >
            <AntForm
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
            >
                <AntForm.Item
                    label="Лист"
                    name="sheet"
                    required
                    rules={[{required: true}, () => ({
                        validator: (_, value) => {
                            if (selectedSheets.find(sheet => sheet.value === value)) {
                                return Promise.reject(new Error('Такой лист уже существует'));
                            }
                            return Promise.resolve();
                        },
                    })]}
                >
                    <CustomSelect
                        entries={reportMenuAsEntriesList}
                        settings={{
                            showSearch: true,
                            isClearable: true,
                            placeholder: 'Выберите лист',
                        }}
                    />
                </AntForm.Item>
                <Space
                    className="buttons_as-row"
                    direction="horizontal"
                >
                    <Button
                        htmlType="submit"
                        type="primary"
                    >Добавить
                    </Button>
                    <Button
                        onClick={closeModal}
                        type="default"
                    >Назад
                    </Button>
                </Space>
            </AntForm>
        </Modal>
    );
};
