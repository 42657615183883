import React, {ReactElement} from 'react';

import {ModalOpenerComponent, ModalOpenerComponentRef} from 'components/modal-opener-component';
import {StateSetter} from 'shared/types/generics';
import {showMessage} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {updateAisDocumentsActiveFlagInRegister} from 'store/slices/ais-slice/ais-slice-thunks';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

interface SetActiveFlagModalConfirmationProps {
    documentIds: React.Key[];
    setDocumentIds: StateSetter<React.Key[]>;
    component?: ReactElement;
}

export const SetActiveFlagModalConfirmation = React.forwardRef<
ModalOpenerComponentRef, SetActiveFlagModalConfirmationProps>((
    {documentIds, setDocumentIds, component}: SetActiveFlagModalConfirmationProps,
    ref,
) => {
    const dispatch = useAppDispatch();

    const isDeleting = useAppSelector(s => selectIsThunkPending(s, updateAisDocumentsActiveFlagInRegister.typePrefix));

    const handleDelete = (setIsModalOpen: StateSetter<boolean>) => {
        dispatch(updateAisDocumentsActiveFlagInRegister({
            ids: documentIds as number[],
        })).unwrap().then(() => {
            showMessage({message: 'Выбранные документы отмечены как доступные для отправки в АИС'});
            setDocumentIds([]);
            setIsModalOpen(false);
        }, () => {
            showMessage({message: 'Ошибка изменения доступности файлов', isError: true});
        });
    };

    return (
        <ModalOpenerComponent
            ref={ref}
            modalProps={{
                forceRender: false,
                destroyOnClose: true,
                title: 'Подтверждение',
                centered: true,
            }}
            component={component}
            controlLabels={{
                save: 'Подтвердить',
            }}
            loadingControls={{
                save: isDeleting,
            }}
            disabledControls={{
                save: isDeleting,
            }}
            handleSave={setIsModalOpen => {
                handleDelete(setIsModalOpen);
            }}
        >
            Вы действительно хотите отметить выбранные документы как доступные для отправки в АИС?
        </ModalOpenerComponent>
    );
});
