import React, {
    Dispatch, SetStateAction,
} from 'react';

import {
    DocumentsUploadMonitoring,
} from 'modules/documents/documents-upload-monitoring-types';

import {DocumentsUploadExtensionIcon} from './documents-upload-extension-icon';
import {DocumentsUploadStage} from './documents-upload-stage/documents-upload-stage';
import {useDocumentsUploadMonitoring} from './hooks/use-documents-upload-monitoring';

export interface DocumentsUploadStageGeneratorProps {
    fileName: string;
    monitoring: DocumentsUploadMonitoring;
    visibleStagePopoverIndex: string;
    setVisibleStagePopoverIndex: Dispatch<SetStateAction<string>>;
}

export const DocumentsUploadStageGenerator = ({
    fileName,
    monitoring,
    setVisibleStagePopoverIndex,
    visibleStagePopoverIndex,
}: DocumentsUploadStageGeneratorProps) => {
    const {
        monitoringStages,
        stageMonitoringResults,
    } = useDocumentsUploadMonitoring(monitoring);
    return (
        <div className="document">
            <div className="document-list">
                {monitoringStages.map((stage, index) => (
                    <DocumentsUploadStage
                        key={stage.id}
                        index={index + stage.id + fileName}
                        stage={stage}
                        stageMonitoringResults={stageMonitoringResults[stage.id]}
                        popoverIsVisible={visibleStagePopoverIndex === index + stage.id + fileName}
                        setPopoverVisible={setVisibleStagePopoverIndex}
                    />
                ))}
            </div>
            <div className="document-icon">
                <div className="document-name">{fileName}</div>
                <DocumentsUploadExtensionIcon fileName={fileName} />
            </div>
        </div>
    );
};
