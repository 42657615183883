import {Tooltip} from 'antd';
import cn from 'classnames';
import React from 'react';

import {ReactComponent as CloseX} from 'shared/assets/forms/delete-x.svg';
import {ReactComponent as File} from 'shared/assets/forms/file.svg';
import {ReactComponent as LinkIcon} from 'shared/assets/link.svg';

import './file-field-info.less';

export interface FileFieldInfoProps {
    fileKey?: string;
    onFileDelete: () => void;
    onFileDownload: () => void;
    infoField: any;
    name?: string;
    disabled?: boolean;
    settings?: {
        textStyle: 'link' | 'text';
    };
}

/**
 * Поле реализовано на основе текущих нужд серверной части
 */
export const FileFieldInfo: React.FunctionComponent<FileFieldInfoProps> = (
    {
        onFileDelete,
        onFileDownload,
        infoField,
        name,
        disabled,
        settings,
        fileKey,
    }: FileFieldInfoProps,
) => {
    const {textStyle} = settings || {};
    if (!infoField) return null;
    if (infoField?.removedFile) return <></>;
    return (
        <div
            className="file-field-info"
            key={fileKey}
        >
            <File />
            <span className={cn('file-name', textStyle)}>{name || infoField?.signName}</span>
            <Tooltip
                className="download-icon"
                title={name || infoField?.signName}
            >
                <LinkIcon onClick={disabled ? onFileDownload : undefined} />
            </Tooltip>
            {!disabled && (
                <CloseX
                    className="delete-icon"
                    onClick={onFileDelete}
                />
            )}
        </div>
    );
};
