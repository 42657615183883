import {AxiosError, AxiosResponse} from 'axios';

import {CommonMessages, ErrorMessages} from 'shared/constants/messages';

import {RpcDeleteResponse} from '../../types/rpc';
import {
    isArray,
    isString,
    isObject,
    isEmptyObject,
} from '../index';
import {showMessage} from '../notifications';
import {
    DeleteResponseSummaryStatus,
    ResponseEntityCode,
} from './show-message-from-response-constants';

interface MessageFromResponse {
    response?: AxiosResponse;
    isError?: boolean;
    customMessage?: string;
}

interface ProcessingResultMessage{
    id: number | string;
    message: string;
    isError: boolean;
    descriptionList: string[];
}
interface RpcResponse extends RpcDeleteResponse {}

const joinFieldsFromArray = (array: any[], fieldName: string = '') => array
    .reduce((acc, item, index) => {
        const isFirstItem = index === 0;
        const separator = isFirstItem ? ' ' : ', ';
        const string = isString(item) ? item : item[fieldName];
        return acc + separator + string;
    }, '');

export const showMessageFromResponse = ({response, isError, customMessage}: MessageFromResponse) => {
    const {data, status} = response || {};
    const resultMessage: {[key: string]: ProcessingResultMessage} = {};
    if (customMessage) {
        return showMessage({message: customMessage, isError});
    }
    if (!isError) {
        let text = '';
        if (data?.summaryStatus === DeleteResponseSummaryStatus.ERROR) {
            return showMessage({message: data?.summaryMessage, isError: true});
        }
        if (data?.resultMessage && data?.resultMessage !== '') text = data.resultMessage;
        if (data?.summaryMessage) text = data.summaryMessage;
        if (isString(data)) text = data;
        if (isArray(data)) {
            text = joinFieldsFromArray(data, 'message');
        }
        if (!isArray(data) && isObject(data)) {
            const {message, messages, processingResultList} = (data as Record<string, any>);
            if (message) text = message;
            if (messages) text = messages.join('.\n');
            if (processingResultList && isArray((processingResultList))) {
                processingResultList?.forEach(list => {
                    text += `\n${list?.resultMessage}`;
                    if (list?.entityCode === ResponseEntityCode.FNS_REFRESH_TOKEN_TIME) {
                        text += `\n${list?.resultMessage}`;
                    } else {
                        const key = list?.entityId;
                        if (!resultMessage[key]) {
                            resultMessage[key] = {
                                id: list?.entityId || list?.entityCode,
                                message: list?.entityCode,
                                isError: list?.status === DeleteResponseSummaryStatus.ERROR,
                                descriptionList: [],
                            };
                        }
                        resultMessage[key].descriptionList.push(list?.resultMessage);
                    }
                });
            }
        }
        if (!isEmptyObject(resultMessage)) {
            Object.values(resultMessage)
                .forEach(list => showMessage({
                    message: list?.message,
                    isError: list?.isError,
                    description: list?.descriptionList,
                }));
            return null;
        }
        return showMessage({message: text, isError});
    }
    if (data?.summaryMessage) {
        return showMessage({message: data.summaryMessage, isError});
    }
    if (isString(data)) {
        return showMessage({message: data, isError});
    }
    if (data?.message) {
        return showMessage({message: data.message, isError: true});
    }
    if (response?.statusText) {
        return showMessage({message: response.statusText, isError});
    }
    if (status === 404 && isString(data) && data.includes('<!doctype html>')) { // tomcat not found error
        return showMessage({message: ErrorMessages.NOT_FOUND, isError});
    }
    return showMessage({message: ErrorMessages.UNEXPECTED_ERROR_OCCURRED, isError: true});
};

export const showRequestErrorMessage = (e: AxiosError) => showMessageFromResponse({
    response: e.response,
    isError: true,
});

const joinFieldsFromErrorArray = (array: any[], fieldName: string = '') => array
    .reduce((acc, item, index) => {
        const isFirstItem = index === 0;
        const separator = isFirstItem ? ' ' : ', ';
        const string = isString(item) ? item : item[fieldName];
        return acc + separator + string;
    }, '');

export const showMessageFromResponseList = (response?: AxiosResponse, isError?: boolean) => {
    const {data, status} = response || {};
    if (Array.isArray(data?.processingResultList) && data.processingResultList.length > 0) {
        data.processingResultList.forEach((res: any) => {
            const entityCode = !res.entityCode ? '' : `: ${res.entityCode}`;
            const text = `${res.resultMessage || CommonMessages.ENTRY_WAS_REMOVED}${entityCode}\n`;
            const isItemError = res.status === DeleteResponseSummaryStatus.ERROR;
            showMessage({message: text, isError: isItemError});
        });
    } else if (status === 404 && isString(data) && data.includes('<!doctype html>')) { // tomcat not found error
        showMessage({message: ErrorMessages.NOT_FOUND, isError});
    } else if (status === 403) {
        showMessage({message: 'Forbidden', isError: true});
    } else if (data?.summaryStatus === DeleteResponseSummaryStatus.ERROR) {
        showMessage({message: data?.summaryMessage, isError: true});
    } else {
        showMessage({
            message: data?.summaryMessage || ErrorMessages.UNEXPECTED_ERROR_OCCURRED, isError,
        });
    }
};

export const showAggregatedMessage = ({response, isError}: MessageFromResponse) => {
    const {data, status} = response || {};
    const {summaryMessage, summaryStatus, processingResultList} = data as RpcResponse;
    if (Array.isArray(processingResultList) && processingResultList.length > 0) {
        const aggregatedMessage: Omit<ProcessingResultMessage, 'id' | 'isError'> = {
            message: summaryMessage,
            descriptionList: processingResultList
                .filter(res => res.status !== DeleteResponseSummaryStatus.ERROR)
                .map(res => res?.resultMessage),
        };

        processingResultList
            .filter(res => res.status === DeleteResponseSummaryStatus.ERROR)
            .forEach(res => {
                showMessage({message: res?.resultMessage, isError: true});
            });

        showMessage({
            message: aggregatedMessage.message,
            description: aggregatedMessage.descriptionList,
        });
    } else if (status === 404 && isString(data) && data.includes('<!doctype html>')) { // tomcat not found error
        showMessage({message: ErrorMessages.NOT_FOUND, isError});
    } else if (status === 403) {
        showMessage({message: 'Forbidden', isError: true});
    } else if (summaryStatus === DeleteResponseSummaryStatus.ERROR) {
        showMessage({message: data?.summaryMessage, isError: true});
    }
};

export const showMessageFromDeleteRows = (response?: AxiosResponse, isError?: boolean) => {
    const {data, status} = response || {};
    let resultMessage = '';
    if (Array.isArray(data?.processingResultList) && data.processingResultList.length > 0) {
        data.processingResultList.forEach((res: any) => {
            const entityCode = !res.entityCode
            || res.resultMessage.includes(res.entityCode) ? '' : `: ${res.entityCode}`;
            resultMessage += `${res.resultMessage || CommonMessages.ENTRY_WAS_REMOVED}${entityCode}\n`;
        });
    }

    if (data?.summaryStatus && data?.summaryStatus !== DeleteResponseSummaryStatus.OK) isError = true;
    let text = '';

    if (isString(data)) text = data;
    if (!isArray(data) && isObject(data)) {
        const {summaryMessage} = (data as Record<string, any>);
        text += `${summaryMessage}\n`;
    }
    if (isArray(data)) {
        text += joinFieldsFromErrorArray(data, 'status');
    }
    if (status === 404 && isString(data) && data.includes('<!doctype html>')) { // tomcat not found error
        text = ErrorMessages.NOT_FOUND;
    }
    if (text === '') {
        text = ErrorMessages.UNEXPECTED_ERROR_OCCURRED;
    }
    showMessage({
        message: text,
        isError,
        className: 'delete-rows-notification',
        description: resultMessage,
    });
};
