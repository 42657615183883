import {
    Alert, Button, Card, Form, Input, Spin,
} from 'antd';
import {isEqual} from 'lodash';
import React, {useEffect, useState} from 'react';

import {ConfirmModal} from 'components/confirm-modal/confirm-modal';
import {IconsMap} from 'components/dynamic-icon';
import {constructLengthErrorMessage} from 'components/form/utils/field-validation-resolver';
import {convertToFormData} from 'shared/utils';
import {GeneralSettingsAPI} from 'store/api/general-settings/general-settings.api';

import {useUserRoleFunctions} from '../../../../shared/hooks/use-user-role-functions';
import {UserRoleFunction} from '../../../../store/slices/user-slice/user-slice-role-functions';
import {GeneralSettingsEntity} from './general-settings-types';

import './general-settings.less';

const GENERAL_WIDTH_BIG = 635;
const GENERAL_WIDTH = 418;

type GeneralData = Omit<GeneralSettingsEntity, 'currentPassword'> & {
    inputCurrentPassword: string;
}

export const GeneralSettings = () => {
    const [form] = Form.useForm();
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [uploadGeneralSettings] = GeneralSettingsAPI.usePutGeneralSettingsMutation();
    const [isPasswordChanged, setIsPasswordChanged] = useState<boolean>(false);
    const {data: generalSettings} = GeneralSettingsAPI.useFetchGeneralSettingsQuery(null);
    const [initialData, setInitialData] = useState<GeneralData>();
    const [isConfirmVisible, setIsConfirmVisible] = useState<boolean>(false);

    useEffect(() => {
        setInitialData({
            userName: generalSettings?.userName ?? '',
            firstName: generalSettings?.firstName ?? '',
            lastName: generalSettings?.lastName ?? '',
            middleName: generalSettings?.middleName ?? '',
            email: generalSettings?.email ?? '',
            inputCurrentPassword: '',
            password: '',
            confirmPassword: '',
        } as GeneralData);
    }, [generalSettings]);

    useEffect(() => {
        form.resetFields();
    }, [initialData]);

    const handleAlertClose = () => {
        setAlertMessage('');
    };

    const handleConfirm = () => {
        const values = form.getFieldsValue();
        setIsConfirmVisible(false);
        handleAlertClose();
        const formValuesConverted: FormData = convertToFormData(values);
        uploadGeneralSettings(formValuesConverted)
            .then((response: any) => {
                if (response?.data) {
                    setAlertMessage(response.data);
                }
                setInitialData(values);
            })
            .catch(err => setAlertMessage(err));
    };

    const handleReset = () => {
        form.resetFields();
        setIsPasswordChanged(false);
        form.validateFields();
    };

    const handlePasswordChange = () => {
        if (form.getFieldValue('password').length > 0) {
            setIsPasswordChanged(true);
        } else {
            setIsPasswordChanged(false);
        }
    };

    const handleCloseConfirmModal = () => {
        setIsConfirmVisible(false);
        handleReset();
    };

    const {hasUserRoleFunction} = useUserRoleFunctions({
        sections: ['app.settings.user.settings.general.title'],
    });

    return (
        <Card
            className="general-settings"
            title="Общие"
        >
            <Spin
                spinning={!generalSettings}
            >
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={initialData}
                    onFinish={values => {
                        if (!isEqual(values as GeneralData, initialData as GeneralData)) {
                            setIsConfirmVisible(true);
                        }
                    }}
                    className="general-settings-form"
                >
                    {alertMessage && (
                        <Alert
                            message={alertMessage || null}
                            type="warning"
                            showIcon
                            closable
                            onClose={handleAlertClose}
                        />
                    )}
                    <div className="general-settings-form__buttons">
                        {hasUserRoleFunction(UserRoleFunction.CREATE) && (
                            <Button
                                type="primary"
                                onClick={() => form.submit()}
                                icon={<IconsMap.SaveOutlined />}
                            >
                                Сохранить
                            </Button>
                        )}
                        {hasUserRoleFunction(UserRoleFunction.CANCEL) && (
                            <Button
                                type="default"
                                onClick={handleReset}
                                icon={<IconsMap.CloseXOutlined />}
                            >
                                Отменить
                            </Button>
                        )}
                    </div>
                    <div className="general-settings-form__fields">
                        <Form.Item
                            name="userName"
                            label="Имя пользователя"
                            style={{width: GENERAL_WIDTH_BIG}}
                        >
                            <Input
                                disabled
                                placeholder="Введите имя пользователя"
                            />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            label="Адрес электронной почты"
                            style={{width: GENERAL_WIDTH_BIG}}
                            rules={[
                                {
                                    type: 'email',
                                    message: 'Значение не является корректным почтовым адресом',
                                },
                                {
                                    min: 3,
                                    max: 70,
                                    message: constructLengthErrorMessage({min: 3, max: 70}),
                                },
                            ]}
                        >
                            <Input
                                placeholder="Введите адрес электронной почты"
                            />
                        </Form.Item>
                        <Form.Item
                            name="lastName"
                            label="Фамилия"
                            required
                            style={{width: GENERAL_WIDTH}}
                            rules={[
                                {
                                    required: true,
                                    message: 'Поле \'Фамилия\' должно быть заполнено',
                                },
                                {
                                    min: 2,
                                    max: 70,
                                    message: constructLengthErrorMessage({min: 2, max: 70}),
                                },
                                {
                                    pattern: /^[a-zA-ZА-Яа-яЁё\\s,.@_—-]+$/,
                                    message: 'Данное поле должно содержать только буквы и спец символы: _-,.@—',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="firstName"
                            label="Имя"
                            required
                            style={{width: GENERAL_WIDTH}}
                            rules={[
                                {
                                    required: true,
                                    message: 'Поле \'Имя\' должно быть заполнено',
                                },
                                {
                                    min: 2,
                                    max: 70,
                                    message: constructLengthErrorMessage({min: 2, max: 70}),
                                },
                                {
                                    pattern: /^[a-zA-ZА-Яа-яЁё\\s,.@_—-]+$/,
                                    message: 'Данное поле должно содержать только буквы и спец символы: _-,.@—',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="middleName"
                            label="Отчество"
                            style={{width: GENERAL_WIDTH}}
                            rules={[
                                {
                                    min: 2,
                                    max: 70,
                                    message: constructLengthErrorMessage({min: 2, max: 70}),
                                },
                                {
                                    pattern: /^[a-zA-ZА-Яа-яЁё\\s,.@_—-]+$/,
                                    message: 'Данное поле должно содержать только буквы и спец символы: _-,.@—',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="inputCurrentPassword"
                            label="Текущий пароль"
                            dependencies={['password']}
                            style={{width: GENERAL_WIDTH}}
                            rules={[
                                {
                                    required: isPasswordChanged,
                                    message: 'Поле \'Текущий пароль\' должно быть заполнено',
                                },
                            ]}
                        >
                            <Input.Password
                                placeholder="Введите текущий пароль"
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            label="Новый пароль"
                            style={{width: GENERAL_WIDTH}}
                        >
                            <Input.Password
                                placeholder="Введите новый пароль"
                                onChange={handlePasswordChange}
                            />
                        </Form.Item>
                        <Form.Item
                            name="confirmPassword"
                            label="Подтверждение пароля"
                            dependencies={['password']}
                            hasFeedback
                            style={{width: GENERAL_WIDTH}}
                            rules={[
                                {
                                    required: isPasswordChanged,
                                    message: 'Поле \'Подтверждение пароля\' должно быть заполнено',
                                },
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Пароли должны совпадать'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password
                                placeholder="Введите подтверждение пароля"
                            />
                        </Form.Item>
                    </div>
                </Form>
                <ConfirmModal
                    title="Подтверждение"
                    visible={isConfirmVisible || false}
                    content="Требуется подтвердить изменения"
                    onCancel={handleCloseConfirmModal}
                    cancelText="Нет"
                    onConfirm={handleConfirm}
                    approveText="Да"
                    useWrapperDivElementAsContainer
                />
            </Spin>
        </Card>
    );
};
