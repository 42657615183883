import cn from 'classnames';
import React, {ReactElement} from 'react';

import './ais-single-value-widget.less';

export interface AisSingleValueWidgetProps {
    icon: ReactElement;
    title: string;
    mainValue: string;
    supValue: string;
}

export const AisSingleValueWidget = ({
    icon,
    title,
    mainValue,
    supValue,
}: AisSingleValueWidgetProps) => (
    <div className={cn('ais-single-value-widget')}>
        <div style={{minHeight: 90}}>{icon}</div>
        <div className={cn('ais-single-value-widget__title')}>
            {title}
        </div>
        <div className={cn('ais-single-value-widget__values')}>
            <div className={cn('ais-single-value-widget__values__main')}>
                {mainValue}
            </div>
            <div className={cn('ais-single-value-widget__values__sup')}>
                {supValue}
                <sup>*</sup>
            </div>
        </div>
        <div className={cn('ais-single-value-widget__footer')}>
            <sup>*</sup> - к предыдущему периоду
        </div>
    </div>
);
