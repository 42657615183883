import {Form} from 'antd';
import {useForm} from 'antd/es/form/Form';
import cn from 'classnames';
import React, {useState} from 'react';

import {UploadDropzone} from 'components/@common/inputs/upload-dropzone';
import {UploadDropzoneMode} from 'components/@common/inputs/upload-dropzone/upload-dropzone';
import {CustomSelect, getCustomSelectDefaultPreset} from 'components/form/inputs/custom-select';
import {CustomSelectValueTriggerEvent} from 'components/form/inputs/custom-select/custom-select';
import {ModalOpenerComponentRef} from 'components/modal-opener-component';
import {useApiRequestProgressFrame} from 'shared/hooks/use-api-request-progress-frame';
import {setFieldsValueUntouched, showMessage} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {uploadPUDFileToDocument} from 'store/slices/ais-slice/ais-slice-thunks';
import {AisArchiveSystemCode, AisRegisterDocumentDto} from 'store/slices/ais-slice/ais-slice-types';
import {apiRequestProgressSelectors} from 'store/slices/api-request-progress-slice/api-request-progress-slice';

export enum PudUploadModalMode {
    inModal = 'in-modal',
    inDocumentCard = 'in-document-card'
}

export interface UsePudUploadArgs {
    registerDocument: AisRegisterDocumentDto | undefined;
    PUDModalRef?: React.RefObject<ModalOpenerComponentRef>;
    className?: cn.Argument;
    mode: PudUploadModalMode;
}

export const usePudUpload = (args: UsePudUploadArgs) => {
    const {
        registerDocument,
        PUDModalRef,
        className,
        mode,
    } = args;

    const dispatch = useAppDispatch();

    const [archiveSystem, setArchiveSystem] = useState<AisArchiveSystemCode>();

    const [form] = useForm();

    const isUploadingPUD = !!useAppSelector(apiRequestProgressSelectors.selectAll).filter(
        progressEntity => progressEntity.extra?.documentId === registerDocument?.id
        && progressEntity.progressPercent !== 100,
    )?.length;

    const {createProgressFrame} = useApiRequestProgressFrame();

    const handleFormFinish = async (values: any) => {
        if (!registerDocument || !archiveSystem) return undefined;

        const {aisDocId} = registerDocument;
        const [file] = values.files ?? [];

        if (archiveSystem === AisArchiveSystemCode.Showcase) {
            const {handleProgress, rollbackProgress} = await createProgressFrame({
                title: `ПУД - ${aisDocId} - ${file?.name}`,
                extra: {
                    documentId: registerDocument.id,
                },
            });

            setTimeout(() => {
                if (PUDModalRef?.current) PUDModalRef.current?.setIsModalOpen(false);
            }, 0);

            return dispatch(uploadPUDFileToDocument({
                arg: {
                    requestData: {
                        aisDocId,
                        archiveSystem,
                        file,
                    },
                    handleProgress,
                },
                id: registerDocument.id,
            })).unwrap().then(res => {
                showMessage({message: 'ПУД успешно размещён'});
                return res;
            }, error => {
                rollbackProgress();
                showMessage({message: 'Ошибка размещения ПУД', isError: true});
                return error;
            });
        }

        return undefined;
    };

    const PUDUploadFormJSX = (
        <Form
            form={form}
            layout="vertical"
            className={cn('form-flex', className)}
            onFinish={handleFormFinish}
        >
            <Form.Item
                name="archiveSystem"
                required={mode === PudUploadModalMode.inModal}
                label={mode === PudUploadModalMode.inDocumentCard ? 'Система хранения' : undefined}
                rules={[{
                    required: mode === PudUploadModalMode.inModal,
                    message: 'Пожалуйста, выберите систему хранения',
                }]}
            >
                <CustomSelect
                    value={archiveSystem}
                    onChange={value => {
                        setArchiveSystem(value);
                    }}
                    settings={{
                        ...getCustomSelectDefaultPreset('/valueLists/ARCHIVE_SYSTEM_CODES'),
                        placeholder: 'Выберите систему хранения скан-образа ПУД',
                        allowTriggerWhenNoValueFound: true,
                        isClearable: true,
                    }}
                    handleTriggerEvent={(v, ev, entries) => {
                        if (ev === CustomSelectValueTriggerEvent.receivedDataFromFetcher
                                && mode === PudUploadModalMode.inModal) {
                            if (entries?.length) {
                                const [firstEntry] = entries;
                                const value = firstEntry?.value?.lookupCode;
                                if (value) {
                                    setArchiveSystem(value);
                                    setFieldsValueUntouched(form, {archiveSystem: value});
                                }
                            }
                        }
                    }}
                />
            </Form.Item>

            {archiveSystem === AisArchiveSystemCode.Showcase && (
                <Form.Item
                    name="files"
                    rules={[{required: true, message: 'Пожалуйста, выберите файл'}]}
                >
                    <UploadDropzone mode={UploadDropzoneMode.single} />
                </Form.Item>
            )}
        </Form>
    );

    return {
        PUDUploadFormJSX,
        isUploadingPUD,
        handleFormFinish,
        setArchiveSystem,
        form,
    };
};
