import {FieldType} from 'modules/metadata';

import {COMMON_OPTIONS} from '../common/common.options';

const FIELD_TYPES = [
    ...COMMON_OPTIONS.FIELD_TYPES,
    {
        label: 'COUNTER',
        value: FieldType.COUNTER,
    },
    {
        label: 'ICON',
        value: FieldType.ICON,
    },
    {
        label: 'COMBINED_STRING',
        value: FieldType.COMBINED_STRING,
    },
];

const DEFAULT_ACTION_TYPES = [
    {
        label: 'OPEN_MODAL',
        value: 'OPEN_MODAL',
    },
    {
        label: 'FILTER',
        value: 'FILTER',
    },
    {
        label: 'DELETE_TABLE_ROWS',
        value: 'DELETE_TABLE_ROWS',
    },
];

const ACTION_TYPES = [
    {
        label: 'SEARCH',
        value: 'SEARCH',
    },
    ...DEFAULT_ACTION_TYPES,
];

export const TABLE_OPTIONS = {
    FIELD_TYPES,
    ACTION_TYPES,
};
