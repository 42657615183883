export const ACCEPTABLE_FILE_EXTENSIONS = '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,'
    + ' application/vnd.ms-excel';

export const HIDDEN_CELL_VALUE = -1;

export enum StepTitle {
    FILE_LOADING = 'Загрузка файла',
    ROW_SETTING = 'Настройка строк',
    PREVIEW_AND_TREE_MAPPING = 'Проверка результата',
}

export const stepIndex = {
    [StepTitle.FILE_LOADING]: 0,
    [StepTitle.ROW_SETTING]: 1,
    [StepTitle.PREVIEW_AND_TREE_MAPPING]: 2,
};

export const XLSX_OPTIONS = {
    header: 1,
    defval: null,
};

// header preview
export const CREATING_TABLE_PREVIEW_DESCRIPTION = 'Проверьте правильность вывода шапки таблицы:';
export const CREATING_TREE_PREVIEW_DESCRIPTION = 'Назначьте атрибуты табличной части нужным ячейкам шапки таблицы:';
export const EDITING_TABLE_PREVIEW_DESCRIPTION = 'Текущая шапка таблицы:';
export const EDITING_TREE_PREVIEW_DESCRIPTION = 'Дерево шапки таблицы:';
export const NO_DATA_FOR_CURRENT_SHEET = 'Структура шапки для данного листа отсутсвует';

// header tree
export const TITLE_FROM_ATTRIBUTE_MESSAGE = 'наименование ячейки будет взято из настроенного атрибута табличной части';
export const ATTRIBUTE_CANNOT_BE_ASSIGNED = 'нельзя назначить атрибут ячейке с количеством потомков более 1';
export const ATTRIBUTE_IS_NOT_UNIQUE = 'данный атрибут уже назначен другой ячейке';
