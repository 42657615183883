import React, {FunctionComponent} from 'react';
import {useDispatch} from 'react-redux';

import {initializeMetadataAndData, resetData} from 'modules/data/data-actions';
import {EntityType} from 'shared/constants/entities';

import {SimpleActionButton} from '../../components';

interface ButtonRemoveFilterProps {
    actionTitle?: string;
    parentEntityName?: string;
}

export const ButtonRemoveFilter: FunctionComponent<ButtonRemoveFilterProps> = (
    {
        actionTitle = 'Отчистить фильтры',
        parentEntityName,
        ...props
    }: ButtonRemoveFilterProps,
) => {
    const dispatch = useDispatch();
    const handleClick = () => {
        if (parentEntityName) {
            dispatch(resetData({
                entityName: parentEntityName,
                entityType: EntityType.FILTER,
            }));
            dispatch(initializeMetadataAndData(EntityType.FILTER)(parentEntityName));
        }
    };

    return (
        <SimpleActionButton
            onClick={handleClick}
            title={actionTitle}
            {...props}
        />
    );
};
