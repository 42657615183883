import React from 'react';

import {FormMode} from 'components/form/form-types';
import {
    CustomFormEntityName,
    CustomFormModal,
    customFormsMapping,
} from 'pages/table-page/query-params-aware-table/custom-form-modal';
import {useAppSelector} from 'store/config/hooks';
import {controlRatioSelectors} from 'store/slices/control-ratio-slice';
import {ModalComponentInterface} from 'store/slices/modals-slice';

import {FormContainer} from '../form-container/form-container';
import {FormModalWrapper} from './form-modal-wrapper';

import './form-modal.less';

interface FormModalProps extends ModalComponentInterface {
    entityName: string;
}

export const FormModal: React.FunctionComponent<FormModalProps> = ({
    onClose,
    entityName,
}: FormModalProps) => {
    const modalParameters = useAppSelector(controlRatioSelectors.selectFormAdditionalParameters);
    if (customFormsMapping[entityName as CustomFormEntityName]) {
        return (
            <CustomFormModal
                mode={FormMode.CREATE}
                entityName={entityName as CustomFormEntityName}
                handleClose={onClose}
            />
        );
    }
    return (
        <FormModalWrapper
            entityName={entityName}
            onClose={onClose}
        >
            {handleClose => (
                <FormContainer
                    entityName={entityName}
                    onClose={handleClose}
                    additionalValuesForRequest={modalParameters || undefined}
                />
            )}
        </FormModalWrapper>
    );
};
