import {
    Button,
    Modal,
    Upload,
    Space, Alert,
} from 'antd';
import cn from 'classnames';
import React from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

import {EditFormArgs} from 'components/documents/file-modal/documents-file-upload-modal-utils';
import {EditableTable} from 'components/editable-table';
import {EditableColumn} from 'components/editable-table/editable-table-types';
import {TableAction} from 'components/table/action';
import {Entity} from 'modules/data';
import {
    DocumentUploadedSignature,
    DocumentUploadFile,
} from 'modules/documents';
import {resetFileList} from 'modules/documents/documents-actions';
import {MetaActionInfo} from 'modules/metadata';
import {ReactComponent as CloseIcon} from 'shared/assets/close.svg';
import {ReactComponent as ErrorAlert} from 'shared/assets/error-alert.svg';
import {ReactComponent as PlusCircleOutlined} from 'shared/assets/plus-outlined.svg';
import {ModalComponentInterface} from 'store/slices/modals-slice';

import './file-uplad-modal.less';

export interface FileUploadModalProps extends ModalComponentInterface {
    onUpload: (file: File, files: File[]) => void;
    onClear?: () => void;
    handleEdit: (row: Entity, args?: EditFormArgs) => void;
    columns?: EditableColumn[];
    fileList: DocumentUploadFile[] | DocumentUploadedSignature[];
    onSubmit: () => void;
    onClose: () => void;
    modalTitle?: string;
    clearText?: string;
    closeText?: string;
    submitText?: string;
    buttonText?: string;
    errorMsg?: string | null;
    rowKey?: string;
    columnsFiltered?: boolean;
    accept?: string;
    actions?: MetaActionInfo[];
    isPageJumperEnabled?: boolean;
    isPageSizeChangerEnabled?: boolean;
    isSubmitButtonDisabled?: boolean;
}

export const FileUploadModal: React.FunctionComponent<FileUploadModalProps> = ({
    onClose,
    onSubmit,
    onUpload,
    onClear,
    handleEdit,
    columns,
    fileList,
    modalTitle = 'Загрузка файлов',
    clearText = 'Очистить таблицу',
    closeText = 'Отменить',
    submitText = 'Загрузить добавленные документы',
    buttonText = 'Добавить файл',
    errorMsg,
    rowKey,
    columnsFiltered,
    actions,
    accept,
    isPageJumperEnabled,
    isPageSizeChangerEnabled,
    isSubmitButtonDisabled,
}: FileUploadModalProps) => {
    const uploadButtonRef = React.useRef<HTMLButtonElement>(null);
    const {entityName} = useParams<{ entityName: string }>();
    const dispatch = useDispatch();
    React.useEffect(() => {
        const shouldClick = fileList.length === 0;

        if (shouldClick) uploadButtonRef.current?.click();
    }, []);

    const handleUpload = (file: File, files: File[]) => {
        onUpload(file, files);
        return false;
    };

    const clearAll = () => {
        dispatch(() => {
            resetFileList({entityName});
        });
        if (onClear) onClear();
    };

    return (
        <Modal
            title={modalTitle}
            className="modal-header"
            visible
            onCancel={onClose}
            width="95vw"
            maskClosable={false}
            closeIcon={<CloseIcon className="modal-close-icon with-title" />}
            footer={null}
        >
            <div className="table-container__actions">
                <Upload
                    beforeUpload={handleUpload as (file: File, fileList: File[]) => boolean}
                    showUploadList={false}
                    accept={accept}
                    multiple
                >
                    <Button
                        icon={<PlusCircleOutlined />}
                        ref={uploadButtonRef}
                        type="primary"
                    >
                        {buttonText}
                    </Button>
                </Upload>
                {actions?.map(action => (
                    <TableAction
                        meta={action}
                        key={action.actionTitle}
                        entityName={entityName}
                    />
                ))}
                {errorMsg && (
                    <Alert
                        className="file-upload-table__alert"
                        message={errorMsg}
                        type="error"
                        icon={<ErrorAlert />}
                        showIcon
                    />
                )}
            </div>
            <div className="file-upload-table">
                {columns && columnsFiltered
                    && (
                        <EditableTable
                            scroll={{
                                x: 'max-content',
                            }}
                            handleSave={handleEdit}
                            columns={columns}
                            dataSource={fileList}
                            rowKey={rowKey}
                            isPageJumperEnabled={isPageJumperEnabled}
                            isPageSizeChangerEnabled={isPageSizeChangerEnabled}
                        />
                    )}
                <Space
                    className={cn(
                        'file-upload-table__actions',
                        (!fileList.length || !columns) && 'relative',
                    )}
                    size="small"
                >
                    <Button
                        key={submitText}
                        type="primary"
                        onClick={onSubmit}
                        disabled={isSubmitButtonDisabled}
                    >
                        {submitText}
                    </Button>
                    {onClear && (
                        <Button
                            key={clearText}
                            onClick={clearAll}
                            type="default"
                        >
                            {clearText}
                        </Button>
                    )}
                    <Button
                        key={closeText}
                        onClick={onClose}
                        type="default"
                    >
                        {closeText}
                    </Button>
                </Space>
            </div>
        </Modal>
    );
};
