import FileDownload from 'js-file-download';

import {ErrorMessages} from 'shared/constants/messages';
import {performRequest, showMessage} from 'shared/utils';

import {TableReportRowCommentDto} from './table-report-row-attachments-types';

export type TableReportAddCommentToRowRequestData = Partial<
    Omit<TableReportRowCommentDto, 'attachments' | 'reportRowId'>> & {
    reportRowId: TableReportRowCommentDto['reportRowId'];
};

export type TableReportAddCommentToRowRequest = {
    requestData: TableReportAddCommentToRowRequestData;
    handleProgress?: (event: ProgressEvent) => void;
}

export type TableReportDownloadCommentRequest = {
    templateCode: string;
    reportRowId: string;
    attachmentId: string;
    fileName: string;
}

export const tableReportAttachmentsApi = {
    downloadFile: async ({
        attachmentId,
        templateCode,
        fileName,
    }: TableReportDownloadCommentRequest) => {
        const fileResponse = await performRequest({
            method: 'GET',
            url: `table-reports/${templateCode}/data/comment/${attachmentId}`,
            responseType: 'blob',
        });

        try {
            FileDownload(fileResponse.data, fileName);
        } catch {
            showMessage({message: ErrorMessages.FILE_DOWNLOAD, isError: true});
        }

        return fileResponse;
    },
    postAddCommentToRow: (
        {
            requestData,
            handleProgress,
        }: TableReportAddCommentToRowRequest,
    ) => {
        const {
            templateCode,
            commentText,
            docId,
            files,
            filesOnDelete,
            reportRowId,
        } = requestData;

        const requestFormData = new FormData();

        if (commentText) requestFormData.append('commentText', commentText);
        if (docId) requestFormData.append('docId', docId);
        if (files) files.forEach(file => requestFormData.append('files', file));
        if (filesOnDelete) filesOnDelete.forEach(file => requestFormData.append('filesOnDelete', file));
        if (reportRowId) requestFormData.append('reportRowId', reportRowId);
        if (templateCode) requestFormData.append('templateCode', templateCode);

        return performRequest({
            url: `table-reports/${templateCode}/data/comment`,
            method: 'POST',
            headers: {'Content-Type': 'multipart/form-data'},
            data: requestFormData,
            onUploadProgress: progressEvent => {
                handleProgress?.(progressEvent);
            },
        });
    },
};
