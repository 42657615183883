import Tooltip from 'antd/es/tooltip';
import cn from 'classnames';
import React from 'react';

import {CustomLink} from 'components/@common/widgets/custom-link';
import {DynamicIcon} from 'components/dynamic-icon';
import {Overflown} from 'components/overflown';
import {PushWithBreadcrumbsFunction} from 'components/table-report/hooks/use-breadcrumbs-controller';
import {FormatSettingsApplicationResult, tableReportColumnContentUtils} from 'components/table-report/utils';
import {getCustomColumnConditionalValue} from 'components/table-report/utils/table-report-utils';
import {StateSetter} from 'shared/types/generics';
import {TABLE_REPORT_URL_FIELD_NAME} from 'store/slices/table-report-slice/table-report-slice-constants';
import {TableReportColumn, TableReportDrillDown} from 'store/slices/table-report-slice/table-report-slice-types';

import {TableReportColumnUrl} from './components/table-report-column-url';
import {useColumnDrilldown, useSeachHighlight} from './hooks';
import {formatLinkToPass} from './table-report-column-content-utils';

const {
    getFormatClassNames,
    isInteger,
    isValuePositive,
    replaceSpaces,
} = tableReportColumnContentUtils;

interface ReportColumnContentProps {
    record: any;
    value: any;
    column?: TableReportColumn;
    rowIndex: number;
    columnIndex: number;
    formatSettingsApplicationResult?: FormatSettingsApplicationResult;
    tableFontSize: string;
    drillDowns?: TableReportDrillDown[];
    pushWithBreadcrumbs: PushWithBreadcrumbsFunction;
    setIsDDModalVisible: StateSetter<boolean>;
    setDDModalEntries: StateSetter<TableReportDrillDown[] | undefined>;
}

export const ReportColumnContent: React.FC<ReportColumnContentProps> = (
    {
        record,
        value: rawValue,
        columnIndex,
        rowIndex,
        formatSettingsApplicationResult,
        column,
        tableFontSize,
        pushWithBreadcrumbs,
        drillDowns,
        setDDModalEntries,
        setIsDDModalVisible,
    }: ReportColumnContentProps,
) => {
    const {
        type,
        dataIndex,
        fieldFunction,
        align: columnAlign,
        style,
        linksProperties,
    } = column ?? {};

    const {
        useTableValueAsTitle: useTableValueAsTitleInLink,
    } = linksProperties ?? {};

    const {
        color: colorSetByFormatSettings,
        style: fontClassNameSetByFormatSettings,
        align: formatSettingsAlign,
    } = formatSettingsApplicationResult ?? {};

    const align = formatSettingsAlign || columnAlign;

    const customColumnValue = column?.type && column?.cases
        ? getCustomColumnConditionalValue({cases: column?.cases, record})
        : undefined;

    const value = (() => {
        if (!rawValue) return rawValue;
        let val = replaceSpaces(String(rawValue), '\u00a0\u00a0');
        if (isInteger(val)) {
            val = replaceSpaces(String(rawValue), '\u00a0');
        }
        return val;
    })();

    const {
        isWithDrilldown,
        drilldownLinkClassName,
        drilldownTooltipTitle,
        handleDrilldownClick,
    } = useColumnDrilldown({
        column,
        rowIndex,
        tableFontSize,
        value,
        drillDowns,
        pushWithBreadcrumbs,
        setDDModalEntries,
        setIsDDModalVisible,
    });

    const {highlightedValueJSX} = useSeachHighlight({
        value,
        indexes: {column: columnIndex, row: rowIndex},
    });

    if (value == null && !type) return null;

    const dataIndexEndsWithDelta = dataIndex?.endsWith('_cr_delta') || dataIndex?.endsWith('_comparison_delta');
    const formatClassNames = getFormatClassNames(align, style);

    const isDrilldown = fieldFunction === 'DD' && isWithDrilldown;

    return (
        <span
            style={{
                color: (() => {
                    if (customColumnValue?.color) return customColumnValue?.color;
                    return colorSetByFormatSettings;
                })(),
                fontSize: tableFontSize,
            }}
            onClick={() => {
                if (isDrilldown) handleDrilldownClick();
            }}
            className={cn(
                formatClassNames,
                fontClassNameSetByFormatSettings,
                {
                    'text-positive': dataIndexEndsWithDelta && isValuePositive(value),
                    'text-negative': dataIndexEndsWithDelta && !isValuePositive(value),
                },
                {'custom-column-icon': type === 'ICON'},
            )}
        >
            {(() => {
                // ссылки
                if (dataIndex?.startsWith(TABLE_REPORT_URL_FIELD_NAME)) {
                    const link = formatLinkToPass({
                        value: rawValue,
                        record,
                        useTableValueAsTitleInLink,
                    });
                    return (
                        <CustomLink link={link} />
                    );
                }

                // для кастомных аттрибутов ↴
                if (type === 'ICON') {
                    return (
                        <DynamicIcon
                            type={customColumnValue?.icon}
                            style={{color: customColumnValue?.color}}
                        />
                    );
                }
                if (type === 'SWITCH') return customColumnValue?.value;

                // для основных аттрибутов ↴
                if (fieldFunction === 'URL') {
                    return (
                        <TableReportColumnUrl
                            rawValue={rawValue}
                        />
                    );
                }

                if (isDrilldown) {
                    return (
                        <Tooltip
                            title={drilldownTooltipTitle}
                            placement="bottom"
                        >
                            <span className={cn(drilldownLinkClassName)}>
                                {highlightedValueJSX}
                            </span>
                        </Tooltip>
                    );
                }

                return (
                    <Overflown>
                        {highlightedValueJSX}
                    </Overflown>
                );
            })()}
        </span>
    );
};
