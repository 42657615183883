import {ReportConfigurationDataConverted} from 'store/slices/report-configuration-slice';

export const omitUndefinedValues = (
    valuesFromSubmit: Pick<ReportConfigurationDataConverted, 'crColumns'>,
) => {
    const {crColumns: values} = valuesFromSubmit;

    if (!values) return values;

    const withCleanValuesFiltered = values.filter(cr => {
        if (!cr?.columnName && !cr?.sql) return false;
        return true;
    });

    return withCleanValuesFiltered;
};
