import {Card, Form as AntForm} from 'antd';
import cn from 'classnames';
import get from 'lodash/get';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {CONTEXT_RESET_EVENT_NAME} from 'components/context-filter/context-filter.constants';
import {useAdditionalOptions} from 'components/form/hooks/use-additional-options/use-additional-options-hook';
import {selectContextRawData} from 'modules/context/context-selector';
import {EntityValue, selectEntityData} from 'modules/data';
import {
    initBlankForm as initBlankFormAction,
    loadAdditionalFormData,
    setFormInstance,
    setFormMode,
} from 'modules/data/data-actions';
import {Entity, FormEntityData, TableEntityData} from 'modules/data/data-types';
import {convertParamsForRequest} from 'modules/data/utils/data.utils';
import {setFormDraftMetadata} from 'modules/metadata/metadata-actions';
import {selectFormDraftMetadata} from 'modules/metadata/metadata-selectors';
import {EntityMeta, MetaActionType, RequestType} from 'modules/metadata/metadata-types';
import {getActionByType} from 'modules/metadata/metadata-utils';
import {EntityType} from 'shared/constants/entities';
import {ConfirmContent} from 'shared/constants/form';
import {URL_REQUESTS} from 'shared/constants/urls';
import {StateSetter} from 'shared/types/generics';
import {showMessage, showMessageFromResponse} from 'shared/utils';
import {useQueryParams} from 'shared/utils/query-params/use-query-params.hook';
import {useAppSelector} from 'store/config/hooks';
import {AppState} from 'store/config/types';
import {selectUserInfo} from 'store/slices/auth-slice';
import {loadRequestData} from 'store/slices/request-slice/request-slice-thunks';
import {tableReportSliceActions} from 'store/slices/table-report-slice';
import {
    selectDocumentUploadInfo,
} from 'store/slices/table-report-slice/table-report-download-documents/table-report-download-documents-selectors';
import {
    selectTableReportQueryParams,
} from 'store/slices/table-report-slice/table-report-query-params/table-report-query-params-selectors';
import {tableReportRowAttachmentsSelectors} from 'store/slices/table-report-slice/table-report-row-attachments';
import {TableReportDataRequestParams} from 'store/slices/table-report-slice/table-report-slice-types';

import {ConfirmModal} from '../confirm-modal/confirm-modal';
import {Spinner} from '../spinner';
import {callRedirect} from '../table/utils/table.utils';
import {AlertErrorMessage} from './alert-error-message/alert-error-message';
import {Buttons} from './buttons';
import {CustomCardTitle, Grade, GradeValue} from './components';
import {Fields} from './fields/fields';
import {FieldsWithTabs} from './fields/fields-with-tabs';
import {FormMode, ReportAdditionalValues} from './form-types';
import {extractPathName} from './form.utils';
import {useFormElementsAvailability} from './hooks/check-form-elements-availability.hook';
import {useDisableFormFields} from './hooks/disable-form-fields.hook';
import {dispatchFormWatcherEvent} from './hooks/use-form-watcher';
import {getFormModalTitle, isDataChanged} from './utils/form.utils';

import './form.less';

interface FormProps {
    parentEntityName?: string;
    cardTitle?: string;
    entityName?: string;
    tableFilterData?: Entity;
    formData?: FormEntityData;
    meta?: EntityMeta;
    onClose: () => void;
    actionForForm: (
        entityName: string,
        referenceUrl: string,
        requestType: RequestType,
        newData: Record<string, any>,
        isJsonRequest?: boolean,
        urlParamKey?: string,
        additionalData?: Entity,
    ) => any;
    additionalValuesForRequest?: Record<string, any>;
    contextData?: Record<string, any>;
    hideTitle?: boolean;
    formStyle?: React.CSSProperties;
    mode?: FormMode;
    useGrade?: boolean;
    setShouldResetTable?: StateSetter<boolean>;
}

export const Form: React.FunctionComponent<FormProps> = (
    {
        formData,
        tableFilterData,
        meta,
        parentEntityName,
        onClose,
        actionForForm,
        additionalValuesForRequest,
        cardTitle,
        contextData,
        entityName,
        hideTitle,
        formStyle,
        mode = FormMode.CREATE,
        useGrade,
        setShouldResetTable,
    }: FormProps,
) => {
    const dispatch = useDispatch();
    const {updateQueryParams} = useQueryParams();
    const {isEditable, currentActionsVisibleState} = useFormElementsAvailability(mode, entityName);
    const {getDisableFormFields} = useDisableFormFields(entityName);

    const [form] = AntForm.useForm();

    const user = useAppSelector(selectUserInfo);
    const {selectedRowKeys = []} = useAppSelector((state: AppState) => (
        selectEntityData(parentEntityName || entityName || '',
            EntityType.TABLE)(state) || {}
    ) as TableEntityData);
    const contextRawData = useAppSelector(selectContextRawData);
    const getConfirmAction = getActionByType(MetaActionType.BUTTON_SAVE_FORM_PERSONAL_ACCOUNT)(meta?.actions)
        || getActionByType(MetaActionType.BUTTON_SEND_FOR_APPROVAL_REQUEST)(meta?.actions);
    const selectedRowId = selectedRowKeys[0];
    const isDraft = !!(useAppSelector(selectFormDraftMetadata(String(entityName))));
    const {selectDocIdsToRequest} = tableReportRowAttachmentsSelectors;
    const docIdsToRequest = useAppSelector(selectDocIdsToRequest);

    const [isConfirmVisible, setConfirmVisible] = useState(false);
    const [errorFormMessage, setErrorFormMessage] = useState<string | null>(null);
    const [isSubmitDisabled, setSubmitDisabled] = useState(false);
    const tableEntityName = meta?.tableEntityName;
    const isFormWithTabs = !!meta?.tabs?.length;
    const templateConfig: TableReportDataRequestParams | undefined = useAppSelector(
        selectDocumentUploadInfo,
    )?.reportRequestData;
    const history = useHistory();
    const queryParametersInStore = useAppSelector(selectTableReportQueryParams);

    const pathName = history.location.pathname;
    const reportSectionName = extractPathName(pathName);

    const entityClassName = entityName?.split('.').join('-');
    const formClassNames = cn(
        'form-body',
        {
            [String(entityClassName)]: entityName,
        },
    );

    /**
     * отправка о прочтении открытого элемента.
     * */
    const sendReadNotification = async () => {
        if (meta?.isUpdateNotificationStatus && formData?.data?.readFlag === false && tableEntityName) {
            await callRedirect(formData?.data,
                updateQueryParams, meta?.linkField, undefined, meta?.tableEntityName, user?.userId);
            if (setShouldResetTable) {
                setShouldResetTable(true);
            }
        }
    };

    useAdditionalOptions({
        entityName,
        form,
        meta,
        id: selectedRowId || formData?.id,
        setErrorFormMessage,
    });

    useEffect(() => {
        dispatch(setFormMode({entityName: entityName || '', entityType: EntityType.FORM, formMode: mode}));
        dispatch(setFormInstance({entityName: entityName || '', form}));
        sendReadNotification();
    }, []);

    useEffect(() => {
        if (!formData?.formUsedAdditionalOptionId) {
            form.setFieldsValue(
                formData?.data?.propertyValue || formData?.initialData || formData?.data || formData,
            );
        }
    }, [formData]);

    useEffect(() => {
        if (meta?.additionalOptions?.optionsField?.referenceUrl
            && !meta?.additionalOptions?.optionsField?.isChangingFormStructure) {
            const addReferenceUrl = meta.additionalOptions?.optionsField.referenceUrl;
            dispatch(loadAdditionalFormData(entityName || parentEntityName || '', addReferenceUrl));
        }
    }, [meta, isDraft]);

    if (!meta) {
        return null;
    }

    /**
     * Нужно для отправки проверенных значений.
     * Срабатывает при нажатии кнопки, если у нее тип submit.
     * Работает с BUTTON_SAVE_FORM.
     */
    const handleFinish = () => {
        if (isSubmitDisabled) return;
        const saveAction = isDraft
            ? getActionByType(MetaActionType.BUTTON_SEND_FOR_APPROVAL_REQUEST_DRAFT)(meta.actions)
            : getActionByType(MetaActionType.BUTTON_SAVE_FORM)(meta.actions)
            || getActionByType(MetaActionType.BUTTON_SAVE_OR_EDIT_FORM)(meta.actions)
            || getActionByType(MetaActionType.BUTTON_SEND_FOR_APPROVAL_REQUEST)(meta.actions)
            || getActionByType(MetaActionType.BUTTON_SEND_COMMENT_ON_THE_REQUEST)(meta.actions)
            || getActionByType(MetaActionType.BUTTON_SAVE_FORM_PERSONAL_ACCOUNT)(meta.actions)
            || getActionByType(MetaActionType.LAUNCH_PROGRAM)(meta.actions);
        const {
            requestType,
            referenceUrl,
            neededParamsForForm,
            shouldResetContext,
            shouldPickAdditionalInfoKeysFromFilter,
            additionalInfoKeys,
        } = saveAction || {};

        if (
            (saveAction?.actionType !== MetaActionType.BUTTON_SEND_FOR_APPROVAL_REQUEST_DRAFT)
            && getConfirmAction
            && !isConfirmVisible
        ) {
            setConfirmVisible(true);
            return;
        }
        // строчка нужна для корректной работы поля с файлом,
        // так как там дополнительно используется FileInstance
        const allFormData = form.getFieldsValue(true);
        const isEmptyData = (data: any) => !data || !Object.keys(data).length;

        const additionalValues: Record<string, any> = {};
        if (neededParamsForForm && tableFilterData) {
            neededParamsForForm.forEach((currentKeys: any) => {
                const value = convertParamsForRequest(tableFilterData)[currentKeys.from];
                if (currentKeys.to && currentKeys.from && value) {
                    additionalValues[currentKeys.to] = value;
                }
            });
        }
        const isJsonRequest = meta.actions?.find(action => action?.isJsonRequest === true)?.isJsonRequest;
        const urlParamKey = meta.actions?.find(action => action?.urlParamKey)?.urlParamKey;
        const reportAdditionalValue: ReportAdditionalValues = {};

        if (allFormData?.topicRequestTypeId && allFormData.topicRequestTypeId.lookupCode === 'requestPUD'
            && templateConfig !== undefined) {
            reportAdditionalValue.reportRowIds = docIdsToRequest;
            reportAdditionalValue.docId = templateConfig.docId || '-1';
            reportAdditionalValue.templateCode = templateConfig.templateCode;
            reportAdditionalValue.reportSectionName = reportSectionName;
            if (Object.keys(queryParametersInStore ?? {}).length > 0) {
                reportAdditionalValue.reportParameters = JSON.stringify(queryParametersInStore);
            }
        }

        if (requestType && referenceUrl && meta.name && !isEmptyData(allFormData)) {
            const filterData: Entity = {};
            if (!meta.resetFormAfterSubmit) {
                setSubmitDisabled(true);
            }
            if (shouldPickAdditionalInfoKeysFromFilter && additionalInfoKeys) {
                const dataToPickFrom: {[p: string]: EntityValue} = {
                    ...(shouldPickAdditionalInfoKeysFromFilter ? contextRawData : {}),
                };
                additionalInfoKeys
                    ?.filter((key: string) => Object.keys(dataToPickFrom).includes(key))
                    ?.forEach((key: string) => {
                        filterData[key] = typeof dataToPickFrom?.[key] === 'object'
                            ? get(dataToPickFrom[key], 'value')
                            : dataToPickFrom[key];
                    });
            }
            actionForForm(
                meta.name,
                referenceUrl,
                requestType,
                {
                    ...contextData,
                    ...allFormData,
                    ...additionalValues,
                    ...additionalValuesForRequest,
                },
                isJsonRequest,
                urlParamKey,
                {...filterData, ...reportAdditionalValue},
            ).then((response: any) => {
                if (response?.status !== 200) {
                    setErrorFormMessage(response?.data);
                    setSubmitDisabled(false);
                } else if (response?.status === 200) {
                    if (saveAction?.actionType === MetaActionType.LAUNCH_PROGRAM) {
                        const {
                            setReadReportDownloadDocuments,
                            setModalReportDownloadDocuments,
                        } = tableReportSliceActions;
                        showMessage({
                            message: 'Программа запущена\n',
                            modalData: {
                                text: 'Посмотреть результат\n',
                                onClick: () => {
                                    dispatch(setModalReportDownloadDocuments({
                                        isOpen: true,
                                        wereDocumentsRead: true,
                                    }));
                                },
                            },
                        });
                        dispatch(
                            setReadReportDownloadDocuments({wereDocumentsRead: false, isAnimationActive: true}),
                        );
                    } else if (response.data.summaryMessage) {
                        showMessageFromResponse({response, isError: false});
                    }
                    setErrorFormMessage(null);
                    if (meta.resetFormAfterSubmit) {
                        dispatch(initBlankFormAction(entityName || ''));
                    } else {
                        // todo:изменить как появится отдельный запрос на получение комментариев в запросах
                        if (
                            saveAction?.actionType === MetaActionType.BUTTON_SEND_COMMENT_ON_THE_REQUEST
                            && entityName
                            && allFormData?.id
                        ) {
                            dispatch(loadRequestData({id: allFormData.id, url: URL_REQUESTS}));
                        }

                        onClose();
                        if (shouldResetContext) window.dispatchEvent(new CustomEvent(CONTEXT_RESET_EVENT_NAME));
                        setSubmitDisabled(false);
                    }
                }
            });
            if (isDraft && entityName) {
                dispatch(setFormDraftMetadata({entityName, isDraft: false}));
            }
        }
    };

    const handleOpenConfirmModal = () => {
        if (formData && isDataChanged(form.getFieldsValue(), formData?.initialData)) {
            setConfirmVisible(true);
        }
    };

    const handleCloseConfirmModal = () => {
        setConfirmVisible(false);
    };

    const handleFinishFailed = () => {
        if (isFormWithTabs) {
            setErrorFormMessage('Введите значения полей корректно во всех вкладках');
        }
        console.error('Форма не прошла проверку');
    };

    const handleFinishConfirm = () => {
        setConfirmVisible(false);
        handleFinish();
    };

    const buttons = (
        <Buttons
            onClose={onClose}
            actions={meta.actions}
            form={form}
            setFormErrorMessage={setErrorFormMessage}
            currentVisibleState={currentActionsVisibleState}
            formMode={mode}
            handleOpenConfirmModal={handleOpenConfirmModal}
        />
    );
    const confirmMessage = (
        <ConfirmModal
            title="Подтверждение"
            visible={isConfirmVisible || false}
            content={getConfirmAction && ConfirmContent?.[getConfirmAction?.actionType]}
            onCancel={handleCloseConfirmModal}
            cancelText="Нет"
            onConfirm={handleFinishConfirm}
            approveText="Да"
            useWrapperDivElementAsContainer
        />
    );
    const fields = (
        <Fields
            formData={formData}
            parentEntityName={parentEntityName}
            entityName={entityName}
            list={meta.fields}
            isEditable={isEditable}
            formMode={mode}
            form={form}
            isDisableFields={getDisableFormFields()}
        />
    );
    const fieldsTabs = (
        <FieldsWithTabs
            form={form}
            formData={formData}
            parentEntityName={parentEntityName}
            entityName={entityName}
            meta={meta}
            mode={mode}
        />
    );

    const title = (
        <CustomCardTitle
            text={getFormModalTitle(hideTitle, cardTitle, meta?.titleStructure, formData?.data, meta?.title)}
            extra={useGrade ? (
                <Grade
                    value={formData?.data?.rating as GradeValue}
                    form={form}
                    data={formData}
                />
            ) : undefined}
        />
    );

    const disabledFormContent = formData?.data?.disabledFormFlag && (
        <>
            <AlertErrorMessage
                message={formData.data.disabledFormMessage || 'Error'}
            />
            {buttons}
        </>
    );

    return (
        <>
            <Card
                style={formStyle}
                className={cn(isFormWithTabs && 'form-with-tabs')}
                title={!hideTitle ? title : null}
            >
                {isConfirmVisible && confirmMessage}
                <AntForm
                    layout="vertical"
                    form={form}
                    onFinish={handleFinish}
                    onFinishFailed={handleFinishFailed}
                    className="form"
                    onValuesChange={(changed: any, values: any) => {
                        dispatchFormWatcherEvent({values, targetForm: form});
                    }}
                >
                    {isSubmitDisabled
                        ? <Spinner />
                        : disabledFormContent || (
                            <>
                                <AlertErrorMessage
                                    message={errorFormMessage}
                                    closeErrorMessage={() => setErrorFormMessage(null)}
                                />
                                <div className={formClassNames}>
                                    <div className="form-fields">
                                        {isFormWithTabs ? fieldsTabs : fields}
                                    </div>
                                    {buttons}
                                </div>
                            </>
                        )}
                </AntForm>
            </Card>
        </>
    );
};
