import {Card, Spin} from 'antd';
import cn from 'classnames';
import React, {useEffect} from 'react';

import {BreadcrumbItem, Breadcrumbs} from 'components/breadcrumbs';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {selectJasperReportInfo} from 'store/slices/jasper-report-slice/jasper-report-slice';
import {loadJasperReportInfo} from 'store/slices/jasper-report-slice/jasper-report-slice-thunks';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

import './jasper-report.less';

interface JasperReportProps {
    entityName: string;
    docId?: string;
}

export const JasperReport: React.FC<JasperReportProps> = ({
    entityName,
    docId,
}: JasperReportProps) => {
    const dispatch = useAppDispatch();

    const jasperReportInfo = useAppSelector(s => selectJasperReportInfo(s, {entityName, docId}));
    const isReportInfoLoading = useAppSelector(s => selectIsThunkPending(s, loadJasperReportInfo.typePrefix));

    const {
        reportBreadcrumbs = [],
        reportUrl,
    } = jasperReportInfo ?? {};

    useEffect(() => {
        if (!reportUrl) dispatch(loadJasperReportInfo({docId, entityName}));
    }, []);

    return (
        <Card className="jasper-report">
            <Breadcrumbs breadcrumbs={reportBreadcrumbs as BreadcrumbItem[]} />

            {isReportInfoLoading && (
                <div className={cn('jasper-report-spinner')}>
                    <Spin tip="Загрузка информации об отчёте..." />
                </div>
            )}

            <iframe
                className="jasper-report-frame"
                title={`${entityName}${docId}-iframe`}
                src={reportUrl}
                loading="lazy"
            />
        </Card>
    );
};
