import {FieldType} from 'modules/metadata';

export const COMMON_FIELDS_ITEMS = {
    DATE_FORMAT: {
        key: 'dateFormat',
        label: 'Формат даты',
        type: FieldType.STRING,
        hint: 'Формат даты для отображения. Не обязательно поле. По умолчанию YYYY-MM-DD',
        isRequired: false,
    },
    FIELDS: {
        KEY: {
            key: 'key',
            label: 'Ключ',
            type: FieldType.STRING,
            hint: `Ключ, соответствующий приходящем значениям.
            Для типа STRING можно писать ключ через точку если строка хранится в объекте.`,
            isRequired: true,
        },
        LABEL: {
            key: 'label',
            label: 'Название поля',
            type: FieldType.STRING,
            hint: 'Название поля, которое будет отображаться',
            isRequired: false,
        },
        IS_HIDDEN: {
            key: 'isHidden',
            label: 'Скрыть',
            type: FieldType.BOOLEAN_CLASSIC,
            hint: 'Скрывает поле',
            isRequired: false,
        },
        IS_REQUIRED: {
            key: 'isRequired',
            label: 'Обязательное',
            type: FieldType.BOOLEAN_CLASSIC,
            hint: 'Делает поле обязательным для заполнения',
            isRequired: false,
        },
        EDIT_OPTIONS: {
            key: 'editOptions',
            label: 'Свойства поля при редактировании',
            type: FieldType.GROUP,
            items: [
                {
                    key: 'disabled',
                    label: 'Не активно',
                    type: FieldType.BOOLEAN_CLASSIC,
                    hint: 'Делает поле не активным при редактировании',
                    isRequired: false,
                },
            ],
        },
    },
    ACTIONS: {
        REFERENCE_URL: {
            key: 'referenceUrl',
            label: 'Относительный url запроса',
            type: FieldType.STRING,
            hint: `Путь к api необходимый для работы действия.
            Нужна часть url поле '{location.origin}/nalmon/api/'`,
        },
        ACTION_TYPE: {
            key: 'actionType',
            label: 'Тип действия',
            isRequired: true,
            type: FieldType.STATIC_SELECT,
        },
        ACTION_TITLE: {
            key: 'actionTitle',
            label: 'Заголовок, отображаемый для действия',
            type: FieldType.STRING,
            isRequired: false,
        },
        ACTION_ICON: {
            key: 'actionIcon',
            label: 'Название отображаемой иконки',
            type: FieldType.STRING,
            isRequired: false,
        },
    },
};
