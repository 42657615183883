import {
    BarChartOutlined,
    CalculatorOutlined,
    ControlOutlined,
    PercentageOutlined,
    QuestionCircleOutlined,
    SearchOutlined,
    SettingOutlined,
    UnorderedListOutlined,
    LinkOutlined,
    InfoCircleOutlined,
    NumberOutlined,
    UploadOutlined,
    TableOutlined,
    CloseOutlined,
    CloseCircleOutlined,
    CheckCircleOutlined,
    EditOutlined,
    FormOutlined,
    CopyOutlined,
    MailOutlined,
    DownloadOutlined,
    PaperClipOutlined,
    DownOutlined,
    UpOutlined,
    RollbackOutlined,
    PlusSquareOutlined,
    SaveOutlined,
    MessageOutlined,
    CodeOutlined,
    CalendarOutlined,
    DislikeOutlined,
    LikeOutlined,
    CommentOutlined,
    DeleteOutlined,
    MinusOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    VerticalAlignBottomOutlined,
    ExclamationCircleFilled,
    ClockCircleOutlined,
    FilePdfOutlined,
    KeyOutlined,
    FolderOpenOutlined,
    SafetyCertificateOutlined,
    PlusCircleOutlined,
    MinusSquareOutlined,
    MinusCircleOutlined,
    QuestionOutlined,
    PauseCircleOutlined,
    PauseOutlined,
    InfoOutlined,
    ExclamationOutlined,
    CheckSquareOutlined,
    CheckOutlined,
    WarningOutlined,
    IssuesCloseOutlined,
    StopOutlined,
    CloseCircleFilled,
    ExportOutlined,
    CloudUploadOutlined,
    ReadOutlined,
    SolutionOutlined,
    ContainerOutlined,
    LoadingOutlined,
    FileTextOutlined,
    ProfileOutlined,
} from '@ant-design/icons';
import {AntdIconProps} from '@ant-design/icons/lib/components/AntdIcon';
import React from 'react';

import {ReactComponent as AccordionIcon} from 'shared/assets/accordion-icon.svg';
import {ReactComponent as AddUser} from 'shared/assets/add-user.svg';
import {ReactComponent as ArrowBackOutlined} from 'shared/assets/arrow-back-outlined.svg';
import {ReactComponent as ArrowDownFilled} from 'shared/assets/arrow-down-filled.svg';
import {ReactComponent as ArrowLeft} from 'shared/assets/arrow-left.svg';
import {ReactComponent as ArrowRightIcon} from 'shared/assets/arrow-right.svg';
import {ReactComponent as BurgerIcon} from 'shared/assets/burger.svg';
import {ReactComponent as CircleFilled} from 'shared/assets/circle.svg';
import {ReactComponent as clip, ReactComponent as PaperClip} from 'shared/assets/clip.svg'; // todo: убрать clip, поправить метаданные
import {ReactComponent as ClockOutlined} from 'shared/assets/clock-outlined.svg';
import {ReactComponent as CloseXOutlined} from 'shared/assets/close-x-outlined.svg';
import {ReactComponent as CloseX} from 'shared/assets/close.svg';
import {ReactComponent as Comparison} from 'shared/assets/comparison.svg';
import {ReactComponent as Confirm} from 'shared/assets/confirm.svg';
import {ReactComponent as Copy} from 'shared/assets/copy.svg';
import {ReactComponent as diadoc} from 'shared/assets/diadoc.svg';
import {ReactComponent as DocumentNew} from 'shared/assets/document-new.svg';
import {ReactComponent as Document} from 'shared/assets/document.svg';
import {ReactComponent as DownloadCircle} from 'shared/assets/download-circle.svg';
import {ReactComponent as download} from 'shared/assets/download.svg';
import {ReactComponent as DynamicValue} from 'shared/assets/dynamic-value.svg';
import {ReactComponent as edisoft} from 'shared/assets/edisoft.svg';
import {ReactComponent as EditWithLine} from 'shared/assets/edit-with-line.svg';
import {ReactComponent as EditPenOutlined} from 'shared/assets/edit.svg';
import {ReactComponent as Envelop} from 'shared/assets/envelope.svg';
import {ReactComponent as Excel} from 'shared/assets/excel.svg';
import {ReactComponent as EyeClosed} from 'shared/assets/eye-closed.svg';
import {ReactComponent as Eye} from 'shared/assets/eye.svg';
import {ReactComponent as FileTransparent} from 'shared/assets/file-transparent.svg';
import {ReactComponent as File} from 'shared/assets/file.svg';
import {ReactComponent as Filter, ReactComponent as filter} from 'shared/assets/filter.svg'; // todo: убрать filter, поправить метаданные
import {ReactComponent as FileOutlined} from 'shared/assets/forms/file.svg';
import {ReactComponent as Library} from 'shared/assets/library.svg';
import {ReactComponent as AccountingLogo} from 'shared/assets/menu/accounting.svg';
import {ReactComponent as AdministrationLogo} from 'shared/assets/menu/administration.svg';
import {ReactComponent as Ais3Logo} from 'shared/assets/menu/ais3.svg';
import {ReactComponent as AnalysisLogo} from 'shared/assets/menu/analysis.svg';
import {ReactComponent as Analysis2Logo} from 'shared/assets/menu/analysis2.svg';
import {ReactComponent as AuditLogo} from 'shared/assets/menu/audit.svg';
import {ReactComponent as DirectoriesLogo} from 'shared/assets/menu/directories.svg';
import {ReactComponent as InfoLogo} from 'shared/assets/menu/info.svg';
import {ReactComponent as QuestionLogo} from 'shared/assets/menu/question.svg';
import {ReactComponent as ReportsLogo} from 'shared/assets/menu/reports.svg';
import {ReactComponent as TaxAccountingLogo} from 'shared/assets/menu/tax-accounting.svg';
import {ReactComponent as MoreIcon} from 'shared/assets/more.svg';
import {ReactComponent as OpposingArrows} from 'shared/assets/opposing-arrows.svg';
import {ReactComponent as RectangleOutlined} from 'shared/assets/rectangle.svg';
import {ReactComponent as ReloadOutlined} from 'shared/assets/reload.svg';
import {ReactComponent as Save} from 'shared/assets/save.svg';
import {ReactComponent as Selection} from 'shared/assets/selection.svg';
import {ReactComponent as SettingsGear} from 'shared/assets/settings-gear.svg';
import {ReactComponent as Settings} from 'shared/assets/settings.svg';
import {ReactComponent as SquareInnerX} from 'shared/assets/square-inner-x.svg';
import {ReactComponent as Subtract} from 'shared/assets/subtract.svg';
import {ReactComponent as TickOutlined} from 'shared/assets/tick-outlined.svg';
import {ReactComponent as TrashXOutlined} from 'shared/assets/trash-x.svg';
import {ReactComponent as UpdateOutlined} from 'shared/assets/update.svg';
import {ReactComponent as Xml} from 'shared/assets/xml.svg';

export const IconsMap = {
    clip,
    PaperClip,
    download,
    Filter,
    CloseX,
    PercentageOutlined,
    CalculatorOutlined,
    BarChartOutlined,
    UnorderedListOutlined,
    SettingOutlined,
    SearchOutlined,
    ControlOutlined,
    QuestionCircleOutlined,
    InfoCircleOutlined,
    LinkOutlined,
    NumberOutlined,
    UploadOutlined,
    PlusCircleOutlined,
    TableOutlined,
    CloseOutlined,
    CloseCircleOutlined,
    CheckCircleOutlined,
    EditOutlined,
    FormOutlined,
    CopyOutlined,
    MailOutlined,
    DownloadOutlined,
    PaperClipOutlined,
    DownOutlined,
    UpOutlined,
    RollbackOutlined,
    PlusSquareOutlined,
    SaveOutlined,
    MessageOutlined,
    CodeOutlined,
    CalendarOutlined,
    DislikeOutlined,
    LikeOutlined,
    CommentOutlined,
    DeleteOutlined,
    MinusOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    VerticalAlignBottomOutlined,
    EditPenOutlined,
    ReloadOutlined,
    EditWithLine,
    DocumentNew,
    TrashXOutlined,
    Xml,
    Excel,
    DownloadCircle,
    Save,
    AddUser,
    TickOutlined,
    Subtract,
    ExclamationCircleFilled,
    Copy,
    ArrowBackOutlined,
    ClockOutlined,
    CloseXOutlined,
    RectangleOutlined,
    UpdateOutlined,
    FileTransparent,
    Library,
    SquareInnerX,
    Settings,
    BurgerIcon,
    SettingsGear,
    Eye,
    EyeClosed,
    Confirm,
    ClockCircleOutlined,
    FilePdfOutlined,
    MinusCircleOutlined,
    ArrowDownFilled,
    OpposingArrows,
    Comparison,
    KeyOutlined,
    FolderOpenOutlined,
    Envelop,
    Selection,
    SafetyCertificateOutlined,
    MinusSquareOutlined,
    QuestionOutlined,
    PauseCircleOutlined,
    PauseOutlined,
    InfoOutlined,
    ExclamationOutlined,
    CheckSquareOutlined,
    CheckOutlined,
    WarningOutlined,
    IssuesCloseOutlined,
    StopOutlined,
    CloseCircleFilled,
    ExportOutlined,
    CloudUploadOutlined,
    CircleFilled,
    MoreIcon,
    filter,
    AccordionIcon,
    Document,
    ArrowRightIcon,
    AccountingLogo,
    AdministrationLogo,
    Ais3Logo,
    AnalysisLogo,
    Analysis2Logo,
    AuditLogo,
    DirectoriesLogo,
    ReportsLogo,
    TaxAccountingLogo,
    InfoLogo,
    QuestionLogo,
    ReadOutlined,
    SolutionOutlined,
    ContainerOutlined,
    diadoc,
    edisoft,
    FileOutlined,
    DynamicValue,
    File,
    LoadingOutlined,
    FileTextOutlined,
    ProfileOutlined,
} as const;

export interface IconWithProps {
    component: React.ComponentType;
    props: Record<string, any>;
}

export const IconAsComponentMap = {
    ArrowLeft: {component: ArrowLeft, props: {className: 'stroke-change-color'}} as IconWithProps,
} as const;

export type TIconType = keyof typeof IconsMap | keyof typeof IconAsComponentMap;
interface DynamicIconProps extends AntdIconProps {
    type?: TIconType | string;
}

const getIcon = (type: string) => {
    const iconsMapping = IconsMap as {[index: string]: React.ComponentType};

    if (iconsMapping[type]) {
        return iconsMapping[type];
    }

    // eslint-disable-next-line no-console
    console.warn(`Icon "${type}" is not imported, using fallback icon`);

    return QuestionCircleOutlined as React.ComponentType;
};

export const DynamicIcon: React.FunctionComponent<DynamicIconProps> = ({type, ...props}: DynamicIconProps) => {
    if (!type || !type.length) {
        return null;
    }

    if (type in IconAsComponentMap) {
        const componentWithProps = IconAsComponentMap[type as keyof typeof IconAsComponentMap];
        const Component = componentWithProps.component;
        return (
            <Component
                {...componentWithProps.props}
                {...props}
            />
        );
    }

    const IconComponent = getIcon(type);

    return <IconComponent {...props} />;
};
