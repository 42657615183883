import {DocJournalData} from 'components/widget/items/documents-publication-pie-chart/doc-journal-pie-chart';
import {NewsData} from 'components/widget/items/news/news';
import {CorrNum, NobComponent} from 'components/widget/items/nob-components-bar-chart/nob-components-bar-chart';
import {RequestsTableData} from 'components/widget/items/request-table/request-table';
import {ContextRawData} from 'modules/context/context-types';
import {RequestTableResponseWithPagination} from 'shared/types/requests';
import {performRequest} from 'shared/utils';

export type FetchRequestsDataArgs = Pick<ContextRawData, 'startDate' | 'endDate'>;
export const fetchRequestsData = ({
    startDate,
    endDate,
}: FetchRequestsDataArgs) => (
    performRequest<RequestsTableData>({
        url: '/widgets/requests-statistics',
        method: 'GET',
        params: {
            startDate,
            endDate,
        },
    }));

export type FetchDocJournalDataArgs = Required<Pick<ContextRawData, 'organizationId' | 'taxPeriodId'>>;
export const fetchDocJournalData = ({
    organizationId,
    taxPeriodId,
}: FetchDocJournalDataArgs) => (
    performRequest<DocJournalData[]>({
        url: '/widgets/doc-journal',
        method: 'GET',
        params: {
            taxPeriodId,
            organizationId,
        },
    })
);

export type FetchNobComponentsCorrNumListArgs = Omit<FetchDocJournalDataArgs, 'userId'>;
export const fetchNobComponentsCorrNumList = ({
    organizationId,
    taxPeriodId,
}: FetchNobComponentsCorrNumListArgs) => (
    performRequest<CorrNum[]>({
        url: '/widgets/nob-components/corr-num',
        method: 'GET',
        params: {
            taxPeriodId,
            organizationId,
        },
    })
);

export type FetchNobComponentsData = {docId: number}
export const fetchNobComponentsData = ({
    docId,
}: FetchNobComponentsData) => (
    performRequest<NobComponent[]>({
        url: '/widgets/nob-components',
        method: 'GET',
        params: {
            docId,
        },
    })
);

export const fetchNews = () => (
    performRequest<RequestTableResponseWithPagination<NewsData>>({
        url: '/widgets/news',
        method: 'GET',
    })
);
