import {Tooltip} from 'antd';
import cn from 'classnames';
import React, {ReactElement} from 'react';

import './vertical-bar-chart.less';

export interface VerticalBarChartEntry {
    value: number;
    color?: string;
}

export interface VerticalBarChartProps {
    entries: VerticalBarChartEntry[];
    title?: string;
    tooltip?: ReactElement;
}

export const VerticalBarChart = ({
    entries,
    title,
    tooltip,
}: VerticalBarChartProps) => (
    <div className={cn('vertical-bar-chart')}>
        <Tooltip
            placement="right"
            title={tooltip}
        >
            <div className={cn('vertical-bar-chart__body')}>
                {entries.map(entry => {
                    const {value, color} = entry;

                    return (
                        <div className={cn('vertical-bar-chart__entry')}>
                            <div
                                className={cn('vertical-bar-chart__entry__value', {
                                    'vertical-bar-chart__entry__value_is-zero': value === 0,
                                })}
                                style={{
                                    height: `${value}%`,
                                    backgroundColor: color,
                                }}
                            />
                        </div>
                    );
                })}
            </div>
        </Tooltip>
        <div className={cn('vertical-bar-chart__footer')}>
            {title}
        </div>
    </div>
);
