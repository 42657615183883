import {UploadFile} from 'antd/es/upload/interface';
import axios from 'axios';
import React from 'react';
import {useDispatch} from 'react-redux';

import {FileFieldInfo} from 'components/form/inputs/upload-file-field/file-field-info';
import {createActionGetFile} from 'modules/data/data-actions';
import './file-list-uploader.less';
import {FileDto} from 'shared/types/files';

import {getFileName, getHandleFileRemove} from '../../uploader-list.utils';

export interface FileListUploaderProps {
    fileList?: UploadFile<any>[];
    onChange?: (fileList: UploadFile<Blob>[]) => void;
    disabled?: boolean;
}

export const FileListUploader = ({
    fileList = [], disabled, onChange,
}: FileListUploaderProps) => {
    const dispatch = useDispatch();
    const onDelete = (attachment: FileDto) => {
        if (attachment.attachmentId) {
            axios.delete(`/nalmon/api/delete-attached/${attachment.attachmentId}`);
        } else if (attachment.id) {
            axios.delete(`/nalmon/api/delete-attached/${attachment.id}`);
        }
    };

    const handleFileRemove = getHandleFileRemove(fileList, onChange, onDelete);

    const handleClickDownloadFile = (file: UploadFile) => {
        dispatch(
            createActionGetFile({
                referenceUrl: file?.url,
                name: file?.name,
            }),
        );
    };
    return (
        <div className="file-list-uploader">
            {fileList.map(file => {
                const fileName = getFileName(file);
                return fileName && (
                    <FileFieldInfo
                        fileKey={file?.uid}
                        name={fileName}
                        infoField={file}
                        onFileDelete={() => handleFileRemove(file)}
                        onFileDownload={() => handleClickDownloadFile(file)}
                        settings={{textStyle: 'text'}}
                        disabled={disabled}
                    />
                );
            })}
        </div>
    );
};
