import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';

import {AlertErrorMessage} from 'components/form/alert-error-message/alert-error-message';
import {Spinner} from 'components/spinner';
import {AuthPage} from 'pages/auth-page/auth-page';
import {LoginForm} from 'pages/login-page/login-form';
import {urlRegExp} from 'shared/regular-expressions';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {
    getTwoFAConfig,
    getAuthProfile,
    selectAuthToken,
    selectAuthPasswordExpired,
    selectAuthProfile,
    AuthRoutes,
    EXTERNAL_AUTHENTICATION_PROFILES,
} from 'store/slices/auth-slice';
import 'pages/login-page/login-page.less';
import {checkAuthStatus} from 'store/slices/auth-slice/auth-slice-thunks';
import {AuthProfile} from 'store/slices/auth-slice/auth-slice-types';
import {selectIsThunkPending, selectThunkError} from 'store/slices/loading-state-slice';

export const LoginPage: React.FC = () => {
    const dispatch = useAppDispatch();

    const token = useAppSelector(selectAuthToken);
    const passwordExpiredFlag = useAppSelector(selectAuthPasswordExpired);
    const profile = useAppSelector(selectAuthProfile);

    const profileError = useAppSelector(s => selectThunkError(s, getAuthProfile.typePrefix));
    const isProfileLoading = useAppSelector(s => selectIsThunkPending(s, getAuthProfile.typePrefix));
    const isAuthenticationCheckLoading = useAppSelector(s => selectIsThunkPending(s, checkAuthStatus.typePrefix));

    const [redirectUrl, setRedirectUrl] = useState<string | undefined>();

    const isAuthorized = profile && !!token;

    useEffect(() => {
        dispatch(getAuthProfile());
    }, []);

    useEffect(() => {
        if (!profile) {
            return;
        }

        if (EXTERNAL_AUTHENTICATION_PROFILES.includes(profile)) {
            dispatch(checkAuthStatus())
                .unwrap()
                .catch(response => {
                    if (urlRegExp.test(response)) {
                        setRedirectUrl(response);
                    }
                });
        }
        if (profile === AuthProfile.LDAP) {
            dispatch(getTwoFAConfig());
        }
    }, [profile]);

    return (
        <AuthPage
            className="d-flex flex-direction-column justify-content-center align-items-center"
        >
            {(() => {
                if (isAuthorized) {
                    return <Redirect to="/" />;
                }

                if (passwordExpiredFlag) {
                    return <Redirect to={AuthRoutes.CHANGE_PASSWORD} />;
                }

                return (
                    <>
                        {(() => {
                            if (isProfileLoading) {
                                return <Spinner tip="Загрузка профиля" />;
                            }

                            if (!profile || profileError) {
                                return (
                                    <div
                                        className="login-page__alert-error"
                                    >
                                        <AlertErrorMessage
                                            message="Ошибка получения профиля"
                                            description="Не удалось загрузить профиль пользователя.
                                             Обратитесь к администратору"
                                            type="error"
                                        />
                                    </div>
                                );
                            }

                            if (isAuthenticationCheckLoading) {
                                return <Spinner tip="Аутентификация пользователя..." />;
                            }

                            return (
                                <LoginForm
                                    profile={profile}
                                    redirectUrl={redirectUrl}
                                />
                            );
                        })()}
                    </>
                );
            })()}
        </AuthPage>
    );
};
