export enum CommonMessages {
  SAVE_SUCCESS = 'Данные успешно сохранены',
  UPLOAD_FILE_SUCCESS = 'Файл успешно загружен',
  DOWNLOAD_FILE_SUCCESS = 'Файл успешно скачан',
  UPLOAD_FILES = 'Пожалуйста добавьте файлы для загрузки.',
  SELECT_DOCUMENT = 'Выберите документ',
  SELECT_QUERY = 'Выберите запрос',
  FILE_DELETED = 'Файл удален',
  REQUEST_STATUS_CHANGED = 'Статус запроса изменен',
  ENTRY_WAS_REMOVED = 'Запись удалена',
}

export enum ErrorMessages {
  FILE_DOWNLOAD = 'Во время скачивания файла(ов) произошла ошибка',
  FILE_UPLOAD = 'Во время загрузки файла(ов) произошла ошибка',
  NOT_FOUND = 'По вашему запросу ничего не найдено',
  UNEXPECTED_ERROR_OCCURRED = 'Произошла непредвиденная ошибка',
  QUERY_EXECUTION = 'Произошла ошибка во время выполнения запроса',
  HTTP_NOT_FOUND = 'HTTP метод не найден',
  OPTIONS_CONTEXT_FILTER_ERR = 'Ошибка при загрузке опций контекстного фильтра'
}
