import React, {CSSProperties} from 'react';

export const customTableUtils = {
    getDefaultRender: (v: any) => (
        <div className="text-center">
            {v ?? 'Не указан'}
        </div>
    ),
    setHeaderStyles: (styles: CSSProperties) => {
        const headerCellCfg = {
            onHeaderCell: () => ({
                style: styles,
            }),
        };
        return headerCellCfg;
    },
    createIndexChild: (dataIndex?: string, title?: string, skip?: boolean, extra?: any) => {
        if (skip) return undefined;
        return {
            children: [{
                dataIndex,
                title,
                ...customTableUtils.setHeaderStyles({
                    textAlign: 'center',
                }),
                ...extra,
            }],
        };
    },
};
