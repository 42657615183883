import {Tooltip} from 'antd';
import FileDownload from 'js-file-download';
import React, {useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {DynamicIcon} from 'components/dynamic-icon';
import {showConfirmModal} from 'components/modal/confirmation-modal/confirmation-modal';
import {ATTORNEY_UPLOAD_EXTENSIONS} from 'components/table/table-constants';
import {FileList, resetLoadedData} from 'modules/data';
import {DOCUMENTS_MODULE} from 'modules/documents';
import {selectDocumentsSigningMethod} from 'modules/documents/documents-selectors';
import {DocumentsSigningMethod} from 'modules/documents/documents-types';
import {FieldType, selectMetadata} from 'modules/metadata';
import {FieldAction, FieldActionType} from 'modules/metadata/metadata-types';
import {EntityType} from 'shared/constants/entities';
import {CommonMessages} from 'shared/constants/messages';
import {
    downloadFile,
    performRequest,
    showMessage,
    showMessageFromResponse,
} from 'shared/utils';
import {useAppSelector} from 'store/config/hooks';
import {AppState} from 'store/config/types';

import {ColumnFormatterProps} from '../../table-types';
import {getAllowedActions} from '../../utils/table.utils';
import {CryptoProSigningModal} from './crypto-pro-signing-modal';
import {
    attachUpload,
    attorneyUpload,
    deleteFile,
    generateAttachmentLink,
    generateAttorneyLink,
    generateSignatureLink,
    signatureUpload,
} from './file-column-utils';
import './file-column.less';

interface RowFileProps extends ColumnFormatterProps {
    type: FieldType;
    fieldType: string;
    entityName: string;
    entityType: EntityType | undefined;
    actions: FieldAction[] | undefined;
}

enum TypeFile {
    FILE = 'file',
    SIGNATURE = 'signature',
    ATTORNEY = 'attorney',
}

export const FileColumn: React.FunctionComponent<RowFileProps> = ({
    fieldType, record, type, entityName, entityType, actions,
}: RowFileProps) => {
    const allowedActions = getAllowedActions(actions);
    const fileList = record.fileList as FileList;
    const refSignature = useRef<HTMLInputElement>(null);
    const refAttorney = useRef<HTMLInputElement>(null);
    const refAttach = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();

    const acceptSign = useSelector((state: AppState) => state[DOCUMENTS_MODULE]?.signatureExtensions?.accept);
    const tableMeta = useAppSelector(selectMetadata(entityName, EntityType.TABLE));
    const documentsSigningMethod = useAppSelector(selectDocumentsSigningMethod);

    const isSigningAllowed = !!tableMeta?.actions?.map(action => action.actionCode).includes('SIGN_FILE_DOC');

    if (fileList && fileList.length) {
        const file = fileList[0];
        const fileName = type === FieldType.SIGNATURE ? file.signatureFileName : file.fileName;
        const fileSize = type === FieldType.FILE ? file.outputFileSize : '';
        const link = generateAttachmentLink(file.attachmentId);
        const signatureLink = generateSignatureLink(file.attachmentId);
        const attorneyLink = generateAttorneyLink(file.attachmentId);

        const onFileDownload = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            downloadFile(link, fileName).then(data => {
                if (data.status === 200) {
                    showMessageFromResponse({
                        response: data,
                        isError: false,
                        customMessage: CommonMessages.DOWNLOAD_FILE_SUCCESS,
                    });
                }
            }).catch(error => {
                showMessageFromResponse({response: error?.response, isError: true});
            });
        };

        const onSignatureDownload = async (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();

            try {
                const signatureDataResponse = await performRequest({
                    url: signatureLink,
                });

                if (signatureDataResponse.status === 200) {
                    const {data: signatureDataBase64} = signatureDataResponse;
                    const signatureData = atob(signatureDataBase64);

                    FileDownload(signatureData, fileName);
                    showMessage({message: 'Подпись скачана'});
                }
            } catch (error) {
                showMessageFromResponse({response: error?.response, isError: true});
            }
        };

        const onAttorneyDownload = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            downloadFile(attorneyLink, file.loaSignFileName).then(data => {
                if (data.status === 200) {
                    showMessageFromResponse({
                        response: data,
                        isError: false,
                        customMessage: 'Доверенность скачана',
                    });
                }
            }).catch(error => {
                if (error.response.status === 404) {
                    showMessageFromResponse({
                        response: error,
                        isError: true,
                        customMessage: 'Не удалось найти файл доверенности для скачивания, попробуйте позже',
                    });
                } else {
                    showMessageFromResponse({response: error?.response, isError: true});
                }
            });
        };

        const onDeleteAttach = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            showConfirmModal({
                title: 'Вы действительно хотите удалить файл?',
                onSubmit: () => {
                    deleteFile(link).then(data => {
                        if (data.status === 200) {
                            dispatch(resetLoadedData(entityName, entityType));
                            showMessageFromResponse({
                                response: data,
                                isError: false,
                                customMessage: CommonMessages.FILE_DELETED,
                            });
                        }
                    }).catch(error => {
                        showMessageFromResponse({
                            response: error?.response,
                            isError: true,
                        });
                    });
                },
                okText: 'Удалить',
            });
        };

        const onDeleteSignature = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            showConfirmModal({
                title: 'Вы действительно хотите удалить подпись?',
                onSubmit: () => {
                    deleteFile(signatureLink).then(data => {
                        if (data.status === 200) {
                            dispatch(resetLoadedData(entityName, entityType));
                            showMessageFromResponse({
                                response: data,
                                isError: false,
                                customMessage: 'Подпись удалена',
                            });
                        }
                    }).catch(error => {
                        showMessageFromResponse({response: error?.response, isError: true});
                    });
                },
                okText: 'Удалить',
            });
        };

        const onDeleteAttorney = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            showConfirmModal({
                title: 'Вы действительно хотите удалить доверенность?',
                onSubmit: () => {
                    deleteFile(attorneyLink).then(data => {
                        if (data.status === 200) {
                            dispatch(resetLoadedData(entityName, entityType));
                            showMessageFromResponse({
                                response: data,
                                isError: false,
                                customMessage: 'Доверенность удалена',
                            });
                        }
                    }).catch(error => {
                        if (error.response.status === 405) {
                            showMessageFromResponse({
                                response: error,
                                isError: true,
                                customMessage: 'Удалить файл доверенности не удалось, попробуйте позже',
                            });
                        } else {
                            showMessageFromResponse({
                                response: error?.response,
                                isError: true,
                            });
                        }
                    });
                },
                okText: 'Удалить',
            });
        };

        const onSignatureUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
            const files = e.target.files?.[0];
            if (files) {
                signatureUpload(files, file.attachmentId).then(data => {
                    if (data.status === 200) {
                        dispatch(resetLoadedData(entityName, entityType));
                        showMessageFromResponse({
                            response: data,
                            isError: false,
                            customMessage: 'Подпись загружена',
                        });
                    }
                }).catch(error => {
                    showMessageFromResponse({response: error?.response, isError: true});
                });
            }
        };

        const onAttorneyUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
            const files = e.target.files?.[0];
            if (files) {
                attorneyUpload(files, file.attachmentId).then(data => {
                    if (data.status === 200) {
                        dispatch(resetLoadedData(entityName, entityType));
                        showMessageFromResponse({
                            response: data,
                            isError: false,
                            customMessage: 'Доверенность загружена',
                        });
                    }
                }).catch(error => {
                    if (error.response.status === 404) {
                        showMessageFromResponse({
                            response: error,
                            isError: true,
                            customMessage: 'Не удалось загрузить файл '
                        + 'доверенности, попробуйте позже',
                        });
                    } else {
                        showMessageFromResponse({response: error?.response, isError: true});
                    }
                });
            }
        };

        if (file.fileName && fieldType === TypeFile.FILE) {
            return (
                <div className="file-column file">
                    <span className="file-column__title">{fileSize}</span>
                    <div className="line-break" />
                    <div className="file-column__icons">
                        {allowedActions.includes(FieldActionType.DELETE)
                            && (
                                <DynamicIcon
                                    className="file-column__icon"
                                    type="CloseOutlined"
                                    onClick={onDeleteAttach}
                                />
                            )}
                        {allowedActions.includes(FieldActionType.DOWNLOAD)
                            && (
                                <Tooltip title={fileName}>
                                    <DynamicIcon
                                        className="file-column__icon"
                                        type="LinkOutlined"
                                        onClick={onFileDownload}
                                    />
                                </Tooltip>
                            )}
                        <Tooltip title={file.fileHashCode}>
                            <DynamicIcon
                                className="file-column__icon"
                                type="NumberOutlined"
                            />
                        </Tooltip>
                    </div>
                </div>
            );
        }
        if (file.signatureFileName && fieldType === TypeFile.SIGNATURE) {
            return (
                <div className="file-column signature">
                    {allowedActions.includes(FieldActionType.DELETE)
                        && (
                            <DynamicIcon
                                className="file-column__icon"
                                type="CloseOutlined"
                                onClick={onDeleteSignature}
                            />
                        )}
                    {allowedActions.includes(FieldActionType.DOWNLOAD)
                        && (
                            <Tooltip title={fileName}>
                                <DynamicIcon
                                    className="file-column__icon"
                                    type="LinkOutlined"
                                    onClick={onSignatureDownload}
                                />
                            </Tooltip>
                        )}
                </div>
            );
        }
        if (file.loaSignFileName && fieldType === TypeFile.ATTORNEY) {
            return (
                <div className="file-column signature">
                    {allowedActions.includes(FieldActionType.DELETE)
                        && (
                            <DynamicIcon
                                className="file-column__icon"
                                type="CloseOutlined"
                                onClick={onDeleteAttorney}
                            />
                        )}
                    {allowedActions.includes(FieldActionType.DOWNLOAD)
                        && (
                            <Tooltip title={file.loaSignFileName}>
                                <DynamicIcon
                                    className="file-column__icon"
                                    type="LinkOutlined"
                                    onClick={onAttorneyDownload}
                                />
                            </Tooltip>
                        )}
                </div>
            );
        }

        if (fieldType === TypeFile.SIGNATURE
            && documentsSigningMethod === DocumentsSigningMethod.CRYPTO_PRO_PLUGIN && isSigningAllowed) {
            return (
                <CryptoProSigningModal
                    documentIds={record?.id ? [record.id as string] : []}
                    onFinish={() => {
                        dispatch(resetLoadedData(entityName, entityType));
                    }}
                    entityName={entityName}
                />
            );
        }

        if (fieldType === TypeFile.SIGNATURE) {
            return (
                <div
                    className="file-column file-column__empty"
                    onClick={() => refSignature?.current?.click()}
                >
                    {allowedActions.includes(FieldActionType.UPLOAD) && (
                        <>
                            <DynamicIcon
                                className="file-column__icon empty"
                                type="download"
                            />
                            <span>Загрузить</span>
                            <input
                                ref={refSignature}
                                id="input"
                                type="file"
                                onChange={onSignatureUpload}
                                className="file-column__input"
                                accept={acceptSign}
                            />
                        </>
                    )}
                </div>
            );
        }
        if (fieldType === TypeFile.ATTORNEY) {
            return (
                <div
                    className="file-column file-column__empty"
                    onClick={() => refAttorney?.current?.click()}
                >
                    {allowedActions.includes(FieldActionType.UPLOAD) && (
                        <>
                            <DynamicIcon
                                className="file-column__icon empty"
                                type="download"
                            />
                            <span>Загрузить</span>
                            <input
                                ref={refAttorney}
                                id="input"
                                type="file"
                                onChange={onAttorneyUpload}
                                className="file-column__input"
                                accept={ATTORNEY_UPLOAD_EXTENSIONS}
                            />
                        </>
                    )}
                </div>
            );
        }
    }
    const onAttachUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files?.[0];
        if (files) {
            attachUpload(files, record.id as number)
                .then(data => {
                    if (data.status === 200) {
                        dispatch(resetLoadedData(entityName, entityType));
                        showMessageFromResponse({response: data, isError: false});
                    }
                }).catch(error => {
                    showMessageFromResponse({response: error?.response, isError: true});
                });
        }
    };
    if (fieldType === TypeFile.FILE) {
        return (
            <div
                className="file-column file-column__empty"
                onClick={() => refAttach?.current?.click()}
            >
                {allowedActions.includes(FieldActionType.UPLOAD) && (
                    <>
                        <DynamicIcon
                            className="file-column__icon empty"
                            type="UploadOutlined"

                        />
                        <span>Отсутствует</span>
                        <input
                            ref={refAttach}
                            id="input"
                            type="file"
                            onChange={onAttachUpload}
                            className="file-column__input"
                        />
                    </>
                )}
            </div>
        );
    }

    return (
        <div className="file-column file-column__empty">
            {/* Значение подписи когда файл отсутствует */}
        </div>
    );
};
