import {Card} from 'antd';
import cn from 'classnames';
import React, {useState} from 'react';

import {CustomTabs} from 'components/custom-tabs';
import {URL_REQUESTS_REQUIREMENT_RATING} from 'shared/constants/urls';
import {useAppSelector} from 'store/config/hooks';
import {selectThunkStatus} from 'store/slices/loading-state-slice';
import {
    loadRequestData,
    loadRequestFieldsMetadata, loadRequestStatusesCommentData,
    loadRequestStatusesData,
} from 'store/slices/request-slice/request-slice-thunks';
import {RequestData, RequestStatus} from 'store/slices/request-slice/request-slice-type';

import {selectRequestStatus} from '../../../store/slices/request-slice/request-selectors';
import {
    BASIC_INFORMATION_ON_REQUEST_NAME,
    PROLONGATION_REQUEST,
    RESPONSE_PROLONGATION, RESPONSE_REQUEST_ADDITIONAL_PLACEMENT_NAME,
    RESPONSE_REQUEST_NAME, RESPONSE_REQUEST_REJECTION_NAME,
} from '../constants/requests.constants';
import {RequestHeader} from './components/request-header/request-header';
import {
    RequestFormFormKey,
    RequestFormTitles,
    RequestHeaderStyle,
} from './request-form.constants';
import {RequestFormContext} from './request-form.context';
import {RequestAdditionalPlacement} from './tabs/request-additional-placement';
import {RequestInformation} from './tabs/request-information';
import {ResponseRequest} from './tabs/response-request';

import './request-form.less';

interface RequestFormProps {
  entityName: string;
  requestsData?: RequestData;
  onClose: () => void;
}

export const RequestForm = ({
    entityName, requestsData, onClose,
}: RequestFormProps) => {
    const [selectedTabKey, setSelectedTabKey] = useState<RequestFormFormKey | React.Key>(RequestFormFormKey.REQUESTS);
    const cardTitle = requestsData?.requests?.topicRequestType?.description;
    const requestType = requestsData?.requests?.requestType || requestsData?.requests?.topicRequestType?.typeCode;
    const isRequestAdditionalPlacementType = requestType === RequestFormFormKey.REQUEST_ADDITIONAL_PLACEMENT;
    const statuses = useAppSelector(selectRequestStatus(selectedTabKey.toString()))?.statuses || [];
    const {
        isPending: isLoadingRequestData,
    } = useAppSelector(s => selectThunkStatus(s, loadRequestData.typePrefix));
    const {
        isPending: isLoadingRequestStatusesData,
    } = useAppSelector(s => selectThunkStatus(s, loadRequestStatusesData.typePrefix));
    const {
        isPending: isLoadRequestStatusesCommentData,
    } = useAppSelector(s => selectThunkStatus(s, loadRequestStatusesCommentData.typePrefix));
    const {
        isPending: isLoadingRequestFieldsMetadata,
    } = useAppSelector(s => selectThunkStatus(s, loadRequestFieldsMetadata.typePrefix));
    const shouldDisplayRating = statuses?.find(status => status === RequestStatus.RATE_REQUEST);
    return (
        <Card
            title={cardTitle}
            className={cn('request-form')}
        >
            <RequestFormContext.Provider
                value={{
                    selectedTabKey,
                    setSelectedTabKey,
                    entityName,
                }}
            >
                <CustomTabs
                    selectedTabKey={selectedTabKey}
                    setSelectedTabKey={setSelectedTabKey}
                    contentSpinner={{
                        isSpinning: isLoadingRequestData
                            || isLoadingRequestStatusesData
                            || isLoadingRequestFieldsMetadata
                        || isLoadRequestStatusesCommentData,
                        tip: isLoadingRequestStatusesData
                        || isLoadRequestStatusesCommentData
                        || isLoadingRequestData ? 'Сохранение...' : 'Загрузка...',
                    }}
                    tabs={[{
                        key: RequestFormFormKey.REQUESTS,
                        title: RequestFormTitles[RequestFormFormKey.REQUESTS],
                        header: (shouldDisplayRating)
                            ? (
                                <RequestHeader
                                    customCardStyleProps={{
                                        titleClassName: RequestHeaderStyle.CUSTOM_TITLE,
                                        cardClassName: RequestHeaderStyle.CUSTOM_CARD_TITLE,
                                    }}
                                    text={RequestFormTitles[RequestFormFormKey.REQUESTS]}
                                    value={requestsData?.requests?.rating}
                                    commentId={requestsData?.requests?.idRequestComment}
                                    requestFormKey={RequestFormFormKey.REQUESTS}
                                />
                            ) : undefined,
                        content: isRequestAdditionalPlacementType
                            ? (
                                <RequestAdditionalPlacement
                                    requestFormKey={RequestFormFormKey.REQUESTS}
                                    entityName={entityName}
                                    onClose={onClose}
                                    data={requestsData?.requests}
                                />
                            )
                            : (
                                <RequestInformation
                                    requestFormKey={RequestFormFormKey.REQUESTS}
                                    requestMetaName={BASIC_INFORMATION_ON_REQUEST_NAME}
                                    entityName={entityName}
                                    onClose={onClose}
                                    requestType={requestType}
                                    enableLinkToReport={requestsData?.enabledLink}
                                    topicReportInfo={requestsData?.topicReportInfo}
                                    data={requestsData?.requests}
                                />
                            ),
                    },
                    {
                        key: RequestFormFormKey.REQUEST_PROLONGATION,
                        title: (requestsData?.prolongationRequest
                                && RequestFormTitles[RequestFormFormKey.REQUEST_PROLONGATION]
                        ),
                        content: requestsData?.prolongationRequest && (
                            <ResponseRequest
                                requestFormKey={RequestFormFormKey.REQUEST_PROLONGATION}
                                requestCommentId={requestsData?.prolongationRequest?.idRequestComment}
                                entityName={PROLONGATION_REQUEST}
                                onClose={onClose}
                                data={requestsData?.prolongationRequest}
                            />
                        ),
                        noRenderWhileOnAnotherTab: true,
                    },
                    {
                        key: RequestFormFormKey.RESPONSE_PROLONGATION,
                        title: (requestsData?.prolongationResponse
                                && RequestFormTitles[RequestFormFormKey.RESPONSE_PROLONGATION]) || '',
                        content: <ResponseRequest
                            requestFormKey={RequestFormFormKey.RESPONSE_PROLONGATION}
                            requestCommentId={requestsData?.prolongationResponse?.idRequestComment}
                            entityName={RESPONSE_PROLONGATION}
                            onClose={onClose}
                            data={requestsData?.prolongationResponse}
                        />,
                    },
                    {
                        key: RequestFormFormKey.REQUEST_RESPONSE,
                        title: (requestsData?.requestResponse
                            && RequestFormTitles[RequestFormFormKey.REQUEST_RESPONSE]),
                        header: (shouldDisplayRating || requestsData?.requestResponse?.rating != null)
                            ? (
                                <RequestHeader
                                    customCardStyleProps={{
                                        titleClassName: RequestHeaderStyle.CUSTOM_TITLE,
                                        cardClassName: RequestHeaderStyle.CUSTOM_CARD_TITLE,
                                    }}
                                    text={RequestFormTitles[RequestFormFormKey.REQUEST_RESPONSE]}
                                    value={requestsData?.requestResponse?.rating}
                                    commentId={requestsData?.requestResponse?.idRequestComment}
                                    requestFormKey={RequestFormFormKey.REQUEST_RESPONSE}
                                    url={URL_REQUESTS_REQUIREMENT_RATING}
                                />
                            ) : undefined,
                        content: <ResponseRequest
                            requestFormKey={RequestFormFormKey.REQUEST_RESPONSE}
                            requestCommentId={requestsData?.requestResponse?.idRequestComment}
                            isRequestAdditionalPlacementType={isRequestAdditionalPlacementType}
                            entityName={
                                isRequestAdditionalPlacementType
                                    ? RESPONSE_REQUEST_ADDITIONAL_PLACEMENT_NAME
                                    : RESPONSE_REQUEST_NAME
                            }
                            onClose={onClose}
                            data={requestsData?.requestResponse}
                        />,
                        noRenderWhileOnAnotherTab: true,
                    },
                    {
                        key: RequestFormFormKey.REQUEST_REJECTION,
                        title: (requestsData?.requestRejection
                                && RequestFormTitles[RequestFormFormKey.REQUEST_REJECTION]),
                        content: <ResponseRequest
                            requestFormKey={RequestFormFormKey.REQUEST_REJECTION}
                            requestCommentId={requestsData?.requestRejection?.idRequestComment}
                            entityName={RESPONSE_REQUEST_REJECTION_NAME}
                            onClose={onClose}
                            data={requestsData?.requestRejection}
                            isRequestAdditionalPlacementType={isRequestAdditionalPlacementType}
                        />,
                    },
                    ]}
                />
            </RequestFormContext.Provider>
        </Card>
    );
};
