import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';

import {ConfirmModal} from 'components/confirm-modal/confirm-modal';
import {URL_AIS3_FILE_SHARING} from 'shared/constants/urls';
import {useGetContainerByDocIdQuery} from 'store/api/transport-container/transport-container.api';

import {OpenOriginalTCCardMessage, OpenOriginalTCCardTitle} from '../constants/generate-tc-response.constants';

export interface OpenTransportContainerCardModalProps {
    docId: string;
    setShowRedirectToTcModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const OpenTransportContainerCardModal = ({
    docId,
    setShowRedirectToTcModal,
}: OpenTransportContainerCardModalProps) => {
    const history = useHistory();
    const [visible, setIsVisible] = useState(true);

    const {data: originalTransportContainer} = useGetContainerByDocIdQuery(
        {mainDocId: docId}, {skip: !docId},
    );
    const handleRedirect = () => {
        if (originalTransportContainer?.id) {
            setIsVisible(false);
            history.push(`${URL_AIS3_FILE_SHARING}?entity=${originalTransportContainer.id}`);
        }
    };
    return (
        <ConfirmModal
            visible={visible}
            onConfirm={handleRedirect}
            onCancel={() => {
                setIsVisible(false);
                setShowRedirectToTcModal(false);
            }}
            content={OpenOriginalTCCardMessage}
            title={OpenOriginalTCCardTitle}
            cancelText="Закрыть"
            approveText="Перейти"
        />
    );
};
