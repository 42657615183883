import {Tabs} from 'antd';
import React from 'react';

import {DirectoryEntityData} from 'modules/data';
import {DirectoryEntityMeta} from 'modules/metadata';

import {DirectoryItem} from './directory-item';

import './directory.less';

interface DirectoryProps {
    url: string;
    metadata: DirectoryEntityMeta;
    data: DirectoryEntityData;
    currentTab: string | undefined;
    setCurrentTab: (tab: string) => void;
}

export const Directory: React.FunctionComponent<DirectoryProps> = ({
    url,
    metadata,
    data,
    currentTab,
    setCurrentTab,
}: DirectoryProps) => (
    <div className="directory-tabs">
        <Tabs
            tabPosition="left"
            onChange={tab => {
                setCurrentTab(tab);
            }}
            destroyInactiveTabPane
            defaultActiveKey={currentTab}
        >
            {data.rows?.map(entry => (
                <Tabs.TabPane
                    tab={entry[metadata.titleField]}
                    key={entry[metadata.linkField || 'id'] as string}
                >
                    <DirectoryItem
                        url={url}
                        data={entry}
                        metadata={metadata}
                        referenceUrl={`${metadata.name}/${currentTab}`}
                    />
                </Tabs.TabPane>
            ))}
        </Tabs>
    </div>
);
