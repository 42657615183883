import {Form, Input, InputNumber} from 'antd';
import {useForm} from 'antd/es/form/Form';
import React, {useEffect, useState} from 'react';

import {FormMode} from 'components/form';
import {BoolField} from 'components/form/inputs/bool-field';
import {CustomSelect} from 'components/form/inputs/custom-select';
import {CustomSelectValueTriggerEvent} from 'components/form/inputs/custom-select/custom-select';
import {fieldValidationResolver} from 'components/form/utils/field-validation-resolver';
import {ModalOpenerComponent} from 'components/modal-opener-component';
import {Protected, ProtectedGroup} from 'components/protected';
import {loadEditFormData, performActionForForm, resetFormData} from 'modules/data/data-actions';
import {selectFormEntityData} from 'modules/data/data-selectors';
import {FieldType, RequestType, loadMetadata} from 'modules/metadata';
import {selectFormEntityMetadata} from 'modules/metadata/metadata-selectors';
import {EntityType} from 'shared/constants/entities';
import {useFormInitializer} from 'shared/hooks/use-form-initializer';
import {showMessage} from 'shared/utils';
import {useQueryParams} from 'shared/utils/query-params/use-query-params.hook';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';

import {CustomFormModalProps} from '../custom-form-modal';

interface MenuSettingsModalProps extends CustomFormModalProps {}

export const MenuSettingsModal: React.FC<MenuSettingsModalProps> = ({
    entityName,
    entityId,
    mode,
    handleClose,
}: MenuSettingsModalProps) => {
    const dispatch = useAppDispatch();
    const {removeQueryParams} = useQueryParams();

    const [isParentDisabled, setIsParentDisabled] = useState(false);
    const [isSubmenuDisabled, setIsSubmenuDisabled] = useState(false);
    const [isParentRequired, setIsParentRequired] = useState(false);
    const [isTemplateTypeVisible, setIsTemplateTypeVisible] = useState(true);
    const [isTableReportTemplateVisible, setIsTableReportTemplateVisible] = useState(false);

    const [isDashboardTypeSelected, setIsDashboardTypeSelected] = useState(false);

    const metadata = useAppSelector(selectFormEntityMetadata(entityName));
    const {data} = useAppSelector(selectFormEntityData(entityName)) ?? {};

    const metaFormFieldsKeys = metadata?.fields.map(field => field.key) ?? [];

    const [form] = useForm();

    const apply = () => {
        if (form.getFieldValue('submenuFlag') === 'Y') setIsParentRequired(() => true);
        else setIsParentRequired(() => false);
    };

    const {initialValues} = useFormInitializer({
        data,
        form,
        onReset: () => {
            apply();
        },
        onDataSetFormatter: (initialData: any) => (
            (mode === 'create') ? {...initialData, enabledFlag: 'Y'} : initialData
        ),
    });

    const [isLoading, setIsLoading] = useState(false);

    const isInEditMode = mode === FormMode.EDIT;

    const isSavingDisabled = !metadata || (isInEditMode && !data) || isLoading
        || !(metadata?.actions ?? []).map(act => act.actionCode).includes('CREATE');

    useEffect(() => {
        if (!metadata) { dispatch(loadMetadata(entityName, EntityType.FORM)); }
        if (entityId) {
            setIsLoading(true);
            dispatch(loadEditFormData(entityName, `${entityName}/${entityId}`)).finally(() => {
                setIsLoading(false);
            });
        }
        return () => {
            dispatch(resetFormData({entityName}));
        };
    }, []);

    const closeModal = () => {
        if (handleClose) { handleClose(); return; }
        removeQueryParams(['entity']);
    };

    return (
        <ModalOpenerComponent
            isOpen
            hideControls={{cancel: true}}
            shouldConfirm={() => !!form.isFieldsTouched()}
            handleCancel={() => {
                closeModal();
            }}
            handleSave={() => {
                form.submit();
            }}
            controlLabels={{
                save: 'Сохранить',
            }}
            disabledControls={{
                save: isSavingDisabled,
            }}
            modalProps={{
                centered: true,
                title: 'Настройка меню',
                forceRender: false,
            }}
            isLoading={isLoading}
        >
            <Form
                layout="vertical"
                form={form}
                initialValues={initialValues}
                className="form-flex"
                onFinish={values => {
                    const valuesToSend = (() => {
                        let valuesModified = values;
                        if (isDashboardTypeSelected) {
                            valuesModified = {
                                ...valuesModified,
                                flagTaxPeriod: 'N',
                            };
                        }
                        if (isInEditMode) return {id: data.id, ...valuesModified};
                        return valuesModified;
                    })();

                    setIsLoading(true);
                    dispatch(performActionForForm()(
                        entityName,
                        `${entityName}${isInEditMode ? `/${data.id}` : ''}`,
                        isInEditMode ? RequestType.PUT : RequestType.POST,
                        valuesToSend,
                    )).then(result => {
                        if (result?.status !== 200) {
                            showMessage({message: result?.data, isError: true});
                        } else {
                            closeModal();
                        }
                    }).finally(() => {
                        setIsLoading(false);
                    });
                }}
                onValuesChange={() => {
                    apply();
                }}
            >
                <Protected included={metaFormFieldsKeys}>
                    <ProtectedGroup className="form-flex-row">
                        <Form.Item
                            name="itemTypeId"
                            label="Тип"
                            rules={[{required: true}]}
                            style={{maxWidth: 400}}
                        >
                            <CustomSelect
                                settings={{
                                    placeholder: 'Выберите тип',
                                    url: 'valueLists/MENU_ITEM_TYPE',
                                    valuePath: 'id',
                                    labelPath: 'meaning',
                                    isDisabled: isInEditMode,
                                    useFirstOptionAfterReceivedFromFetcher: !isInEditMode,
                                    setFirstOptionUntouched: true,
                                    formFieldKey: 'itemTypeId',
                                    formInstance: form,
                                }}
                                handleTriggerEvent={(val, ev) => {
                                    if (val?.lookupCode === 'DASHBOARD_ITEM') {
                                        setIsTemplateTypeVisible(false);
                                        setIsParentDisabled(false);
                                        setIsSubmenuDisabled(false);
                                        setIsDashboardTypeSelected(true);
                                    } else {
                                        setIsDashboardTypeSelected(false);
                                        if (val?.lookupCode === 'FUNCTION_ITEM') {
                                            if (ev === CustomSelectValueTriggerEvent.valueHasBeenChangedManually) {
                                                form.setFieldsValue({
                                                    submenuFlag: 'N',
                                                    parentId: null,
                                                });
                                            }
                                            setIsParentDisabled(true);
                                            setIsSubmenuDisabled(true);
                                            setIsParentRequired(false);
                                            setIsTemplateTypeVisible(false);
                                        } else {
                                            setIsTemplateTypeVisible(true);
                                            setIsParentDisabled(false);
                                            setIsSubmenuDisabled(false);
                                        }
                                    }
                                }}
                            />
                        </Form.Item>
                        {!(isInEditMode && !data?.parentId) && (
                            <Form.Item
                                name="parentId"
                                label="Родитель"
                                rules={[{required: isParentRequired}]}
                            >
                                <CustomSelect
                                    settings={{
                                        placeholder: 'Выберите родителя',
                                        url: 'valueLists/FILTER_PARENT_MENU',
                                        valuePath: 'id',
                                        labelPath: 'meaning',
                                        isDisabled: isParentDisabled || isInEditMode,
                                        showSearch: true,
                                    }}
                                />
                            </Form.Item>
                        )}
                    </ProtectedGroup>
                    <Form.Item
                        name="submenuFlag"
                    >
                        <BoolField
                            label="Субменю"
                            disabled={isSubmenuDisabled || isInEditMode}
                        />
                    </Form.Item>
                    <ProtectedGroup className="form-flex-row">
                        <Form.Item
                            style={{maxWidth: 120}}
                            name="itemRn"
                            label="№ п/п"
                            rules={[
                                {
                                    required: true,
                                    message: 'Поле \'№ п/п\' должно быть заполнено',
                                },
                                {
                                    pattern: /^-?\d+$/,
                                    message: 'Только целые числа',
                                },
                                {
                                    validator: (_, value) => {
                                        if (value > 2147483647) {
                                            return Promise.reject(new Error('Значение должно быть меньше '
                                                + 'или равно 2147483647'));
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <InputNumber
                                placeholder="Введите номер"
                                onKeyDown={e => {
                                    if (e.key === '-') {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="itemTitle"
                            label="Заголовок элемента меню"
                            rules={[{
                                required: true,
                                message: 'Поле \'Заголовок элемента меню\' должно быть заполнено',
                            }]}
                        >
                            <Input placeholder="Введите заголовок" />
                        </Form.Item>
                        <Form.Item
                            name="itemName"
                            label="Код элемента меню"
                            rules={[
                                {
                                    required: true,
                                    message: 'Поле \'Код элемента меню\' должно быть заполнено',
                                },
                                {
                                    pattern: /^[a-z0-9_.-]+$/,
                                    message: 'Значение должно содержать только строчные латинские буквы и цифры',
                                },
                            ]}
                        >
                            <Input
                                placeholder="Введите код"
                                disabled={isInEditMode}
                            />
                        </Form.Item>
                    </ProtectedGroup>
                    {isTemplateTypeVisible && (
                        <ProtectedGroup className="form-flex-row">
                            <Form.Item
                                name="templateTypeId"
                                label="Тип шаблона"
                                rules={[{
                                    required: true,
                                    message: 'Поле \'Тип шаблона\' должно быть заполнено',
                                }]}
                                style={{maxWidth: 360}}
                            >
                                <CustomSelect
                                    settings={{
                                        placeholder: 'Выберите тип шаблона',
                                        url: 'valueLists/REPORT_TYPE',
                                        valuePath: 'id',
                                        labelPath: 'meaning',
                                        showSearch: true,
                                    }}
                                    handleTriggerEvent={val => {
                                        if (val?.lookupCode === 'TABLE_REPORT') {
                                            setIsTableReportTemplateVisible(true);
                                        } else {
                                            setIsTableReportTemplateVisible(false);
                                        }
                                    }}
                                />
                            </Form.Item>
                            {isTableReportTemplateVisible && (
                                <Form.Item
                                    name="tableReportTemplateId"
                                    label="Отчёт"
                                    rules={[{
                                        required: true,
                                        message: 'Поле \'Отчет\' должно быть заполнено',
                                    }]}
                                >
                                    <CustomSelect
                                        settings={{
                                            placeholder: 'Выберите отчёт',
                                            url: 'valueLists/PORTAL_REPORTS',
                                            valuePath: 'id',
                                            labelPath: 'meaning',
                                            showSearch: true,
                                        }}
                                    />
                                </Form.Item>
                            )}
                        </ProtectedGroup>
                    )}
                    <Form.Item
                        name="itemUrl"
                        label="URL (пример /documents для документов)"
                        rules={fieldValidationResolver({
                            fieldMeta: {
                                label: 'URL',
                                type: FieldType.STRING,
                            },
                            validationRules: [{
                                mask: 'shortUrl',
                            }],
                        })}
                    >
                        <Input placeholder="Введите URL" />
                    </Form.Item>
                    {!isDashboardTypeSelected && (
                        <Form.Item
                            name="itemClass"
                            label="Класс элемента"
                        >
                            <Input placeholder="Введите класс элемента" />
                        </Form.Item>
                    )}
                    {!isDashboardTypeSelected && (
                        <Form.Item
                            name="itemIcon"
                            label="Отображение"
                        >
                            <Input placeholder="Выберите отображение" />
                        </Form.Item>
                    )}

                    {!isDashboardTypeSelected && (
                        <Form.Item
                            name="additionalFields"
                        >
                            <BoolField label="Выводить дополнительные поля (Налоговый период, ИФНС)" />
                        </Form.Item>
                    )}

                    {!isDashboardTypeSelected && (
                        <Form.Item
                            name="additionalRegNum"
                        >
                            <BoolField label="Выводить поле Регистрационный номер" />
                        </Form.Item>
                    )}

                    <Form.Item
                        hidden={isDashboardTypeSelected}
                        name="flagTaxPeriod"
                    >
                        <BoolField label="Раздел без привязки к налоговому периоду" />
                    </Form.Item>

                    <Form.Item
                        name="enabledFlag"
                    >
                        <BoolField
                            label="Активно"
                        />
                    </Form.Item>
                </Protected>
            </Form>
        </ModalOpenerComponent>
    );
};
