import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {FieldMeta, selectMetadata, loadMetadata} from 'modules/metadata';
import {EntityType} from 'shared/constants/entities';
import {useAppSelector} from 'store/config/hooks';

import {EditableColumn} from '../../editable-table/editable-table-types';
import {generateEditableMetaData} from '../editable-table-modal-utils';

export const useEditableTableColumns = (
    entityName: string,
) => {
    const dispatch = useDispatch();
    const [columnsMeta, setColumnsMeta] = React.useState<EditableColumn[] | undefined>();
    const metadata = useAppSelector(state => selectMetadata(entityName, EntityType.EDITABLE_TABLE)(state));

    useEffect(() => {
        const shouldLoadMetadata = !metadata;
        const fields = metadata?.fields;
        if (shouldLoadMetadata) {
            dispatch(loadMetadata(entityName, EntityType.EDITABLE_TABLE));
            return;
        }
        if (fields) {
            const arr = (fields as FieldMeta[])?.map(item => generateEditableMetaData(item));
            setColumnsMeta(arr);
        }
    }, [metadata]);

    return {columnsMeta};
};
