import {Skeleton} from 'antd';
import cn from 'classnames';
import React, {useEffect, useMemo, useState} from 'react';

import {loadDashboardData} from 'modules/data/actions/dashboard-data-actions';
import {DashboardEntry, EntityData} from 'modules/data/data-types';
import {FieldMeta} from 'modules/metadata';
import {useAppDispatch} from 'store/config/hooks';

import {dashboardTypeResolver} from '../utils/dashboard-type-resolver';

export interface DashboardComponentProps {
    entityName: string;
    meta: FieldMeta;
    data?: DashboardEntry;
    context?: EntityData;
}

export const DashboardComponent = ({
    entityName, meta, data, context,
}: DashboardComponentProps) => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            setLoading(true);
            if (meta.referenceUrl && context && !data) {
                await dispatch(loadDashboardData(entityName, meta.key, meta.referenceUrl, context, meta?.useContext));
            }
            setLoading(false);
        })();
    }, [meta, context, data]);

    const Component = useMemo(() => {
        if (data) {
            return dashboardTypeResolver(meta, data);
        }
        return null;
    }, [data]);

    return !loading ? Component : (
        <Skeleton
            round
            active
            paragraph={{rows: 5}}
            className={cn(
                'dashboard__skeleton',
                meta?.type && meta.type.toLowerCase(),
            )}
        />
    );
};
