// import {PayloadAction} from '@reduxjs/toolkit';

import {ActionReducerMapBuilder} from '@reduxjs/toolkit';

import {AisSliceState} from '../ais-slice-types';
import {
    getAisS4S5DashboardData,
    getAisRegisterDocuments,
    getAisSummaryTransactionsProtocolDashboardData,
    getAisSystemAvailabilityStatusDashboardData,
    getAisTokenStatusDashboardData,
    getAisRequestStatisticS3S6S12,
    getAisSystemUnavailabilityDashboardData,
} from './ais-dashboards-thunks';

export const createAisDashboardsReducer = <S extends AisSliceState>() => ({
    // resetAisDashboards(state: S, {payload}: PayloadAction<any>) {
    resetAisDashboards(state: S) {
        state.aisDashboards = {
            availabilityStatus: undefined,
            summaryTransactionsProtocol: undefined,
            register: undefined,
            s4s5RequestsStatistics: undefined,
            s3s6s12requestStatistic: undefined,
            tokenStatus: undefined,
            unavailabilityChart: undefined,
        };
    },
});

export const createAisDashboardsExtraReducer = (builder: ActionReducerMapBuilder<AisSliceState>) => {
    builder.addCase(getAisS4S5DashboardData.fulfilled, (state, {payload}) => {
        state.aisDashboards.s4s5RequestsStatistics = payload;
    });
    builder.addCase(getAisS4S5DashboardData.rejected, state => {
        state.aisDashboards.s4s5RequestsStatistics = undefined;
    });
    builder.addCase(getAisTokenStatusDashboardData.fulfilled, (state, {payload}) => {
        state.aisDashboards.tokenStatus = payload;
    });
    builder.addCase(getAisTokenStatusDashboardData.rejected, state => {
        state.aisDashboards.tokenStatus = undefined;
    });
    builder.addCase(getAisSystemAvailabilityStatusDashboardData.fulfilled, (state, {payload}) => {
        state.aisDashboards.availabilityStatus = payload;
    });
    builder.addCase(getAisSystemAvailabilityStatusDashboardData.rejected, state => {
        state.aisDashboards.availabilityStatus = undefined;
    });
    builder.addCase(getAisSummaryTransactionsProtocolDashboardData.fulfilled, (state, {payload}) => {
        state.aisDashboards.summaryTransactionsProtocol = payload;
    });
    builder.addCase(getAisSummaryTransactionsProtocolDashboardData.rejected, state => {
        state.aisDashboards.summaryTransactionsProtocol = undefined;
    });
    builder.addCase(getAisRegisterDocuments.fulfilled, (state, {payload}) => {
        state.aisDashboards.register = payload;
    });
    builder.addCase(getAisRequestStatisticS3S6S12.fulfilled, (state, {payload}) => {
        state.aisDashboards.s3s6s12requestStatistic = payload;
    });
    builder.addCase(getAisSystemUnavailabilityDashboardData.fulfilled, (state, {payload}) => {
        state.aisDashboards.unavailabilityChart = payload;
    });
};
