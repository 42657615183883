import {createContext} from 'react';

import {DDFilterExpression} from './dd-filter-expression-creator/dd-filter-expression-creator-types';

export interface ReportDdFilterRulesContextArgs {
    childReportTemplateCode: string | null | undefined;
    filterInitialExpression: DDFilterExpression | undefined;
}

export const ReportDdFilterRulesContext = createContext<ReportDdFilterRulesContextArgs>({
    childReportTemplateCode: undefined,
    filterInitialExpression: undefined,
});
