import {TableReportDrillDown} from 'store/slices/table-report-slice/table-report-slice-types';

import {PushWithBreadcrumbsFunction} from '../hooks/use-breadcrumbs-controller';

interface PerformDrilldownPushArgs {
    pushWithBreadcrumbs: PushWithBreadcrumbsFunction;
    ddEntry: TableReportDrillDown;
}

export const performDrilldownPush = ({
    ddEntry,
    pushWithBreadcrumbs,
}: PerformDrilldownPushArgs) => {
    const ddPath = [
        '/table-reports',
        ...(ddEntry?.docId ? [`/${ddEntry?.docId}`] : []),
        `/${ddEntry?.templateCode}`,
    ].join('');

    pushWithBreadcrumbs({
        name: ddEntry.templateName ?? ddEntry?.docTypeName ?? 'Неизвестно',
        path: ddPath,
        extraData: {
            tableReportDrilldownParams: {
                drillId: ddEntry.drillId,
                drillParameters: ddEntry.parameters,
            },
            tableReportLocationDefaults: {
                locationPage: undefined,
                locationSize: undefined,
            },
        },
    });
};
