import {FormButtonWatcher, FormButtonWatcherConnective} from './use-form-button-watcher-types';

export const createConnective = (
    connective: FormButtonWatcher['conditions'][0]['withConnective'],
) => (a: boolean, b: boolean) => {
    if (!connective) return a && b;
    if (connective === FormButtonWatcherConnective.AND) return a && b;
    if (connective === FormButtonWatcherConnective.AND_NOT) return a && !b;
    if (connective === FormButtonWatcherConnective.OR_NOT) return a || !b;
    return a || b;
};
