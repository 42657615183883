import {InfoCircleOutlined} from '@ant-design/icons';
import {Popover} from 'antd';
import cn from 'classnames';
import React, {useEffect} from 'react';

import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {selectDDContextSegmentsGroupedByAttribute} from 'store/slices/descriptive-flexfields-slice/descriptive-flexfields-slice';
import {loadDDContextSegments} from 'store/slices/descriptive-flexfields-slice/descriptive-flexfields-thunks';
import {ReportDdReferenceRule} from 'store/slices/report-configuration-slice/dd-references-configuration';

import './drill-down-rule-info.less';

interface DrillDownRuleInfoProps {
    drillDownRule: ReportDdReferenceRule;
}

export const DrillDownRuleInfo: React.FC<DrillDownRuleInfoProps> = (
    {drillDownRule}: DrillDownRuleInfoProps,
) => {
    const dispatch = useAppDispatch();
    const contextCode = drillDownRule.context;

    const groupedSegments = useAppSelector(s => selectDDContextSegmentsGroupedByAttribute(
        s, {contextCode, toLowerCase: true},
    ));

    const attributes = Object.entries(drillDownRule)
        .filter(([key, value]) => key.includes('attribute') && value && groupedSegments?.[key])
        .map(([key, value]) => {
            const attributeName = groupedSegments?.[key]?.segmentUserName ?? key;
            return [attributeName, value];
        });

    useEffect(() => {
        if (!groupedSegments) {
            dispatch(loadDDContextSegments({contextCode}));
        }
    }, []);

    return (
        <div className={cn('drill-down-rule-info')}>
            <div>{drillDownRule.parentDocumentType?.meaning ?? 'Неизвестно'}</div>
            <div className={cn('drill-down-rule-info__middle')}>
                <div className={cn('drill-down-rule-info__middle__arrow')} />
                <div className={cn('drill-down-rule-info__middle__link')}>
                    <div>«{drillDownRule.linkColumnName}»</div>
                    <Popover
                        className={cn('drill-down-rule-info__middle__link__popover')}
                        placement="bottom"
                        destroyTooltipOnHide
                        content={(
                            !attributes?.length ? <div>Нет данных</div> : (
                                <div className={cn('drill-down-rule-info__middle__link__popover__body')}>
                                    {attributes.map(([attributeName, value]) => (
                                        <div key={attributeName}>
                                            <b>{attributeName}</b>: {value}
                                        </div>
                                    ))}
                                </div>
                            )
                        )}
                    >
                        <InfoCircleOutlined />
                    </Popover>
                </div>
            </div>
            <div>{drillDownRule.childDocumentType?.meaning ?? 'Неизвестно'}</div>
        </div>
    );
};
