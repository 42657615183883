import {Checkbox} from 'antd';
import React from 'react';

import {EntityValue} from 'modules/data';
import {FieldMeta} from 'modules/metadata';

export interface BoolFieldProps {
    value: EntityValue;
    onChange?: (value: any) => void;
    disabled?: boolean;
    entityName: string;
    handleChangeActiveKey?: (key: string, id: number) => void;
    checkKey?: any;
    field: FieldMeta;
    record: any;

}

export const CheckboxField: React.FunctionComponent<BoolFieldProps> = ({
    value,
    onChange,
    disabled,
    field,
    record,
    handleChangeActiveKey,
    checkKey,
    ...props
}: BoolFieldProps) => {
    const {key} = field;
    const isCurrentChecked = checkKey[record.id] === key;

    const handleChange = async () => {
        if (onChange) onChange(value);
        if (handleChangeActiveKey) handleChangeActiveKey(key, record);
    };

    return (
        <Checkbox
            {...props}
            onChange={handleChange}
            checked={isCurrentChecked}
            value={value}
            disabled={disabled}
        />
    );
};
