import {ColumnType} from 'antd/es/table';

import {Entity, EntityValue} from 'modules/data';
import {FieldMeta} from 'modules/metadata';
import {ValidationRules} from 'modules/metadata/metadata-types';

interface EditableColumEntity extends Omit<FieldMeta, 'inputProps' | 'getFieldState' | 'label' | 'type' | 'align'> {
    editable?: boolean;
    alwaysEditable?: boolean;
    dataIndex: string;
    inputType?: EditableInput;
    inputProps?: EditableTableInputProps;
    /**
     * Метод для создания динамических пропсов, на тот случай если возможностей inputProps не хватает
     * @param fieldKey
     * @param record
     */
    generateInputProps?: (fieldKey: string, record?: Entity) => EditableTableInputProps;
    getFieldState?: (fieldKey: string, record: Entity) => FieldStateType;
    required?: boolean;
    defaultValue?: EntityValue;
    validationRules?: ValidationRules[];
    width?: number | string;
}

export type EditableColumn = ColumnType<any> & EditableColumEntity;

export type FieldStateType = {
    activated?: boolean;
    required?: boolean;
    dependencies?: string | string[];
    disabled?: boolean;
};

export enum EditableInput {
    TEXT = 'TEXT',
    SELECT = 'SELECT',
    TEMPLATE_SELECT = 'TEMPLATE_SELECT',
    DATE = 'DATE',
    INPUT_NUMBER = 'INPUT_NUMBER',
    TEXT_MODAL = 'TEXT_MODAL',
    FILES_MODAL = 'FILES_MODAL'
}

export interface EditableTableInputProps {
    options?: {
        label: string;
        value: string;
    }[];

    [key: string]: any;
}
