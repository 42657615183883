import React from 'react';

import {Directory} from 'components/directory';
import {PageTemplateProps} from 'pages';

interface DirectoryPageProps extends PageTemplateProps {
}

export const DirectoryPage: React.FunctionComponent<DirectoryPageProps> = ({
    entityName,
    url,
}: DirectoryPageProps) => (
    <Directory
        entityName={entityName}
        url={url}
    />
);
