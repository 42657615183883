import React, {useContext} from 'react';

import {FormsFormTableContext} from '../form-table/form-table-context';
import {TABLE_ACTION_MAP} from './table-action-constants';
import {TableActionProps} from './table-action-types';

import './table-action.less';

export const TableAction: React.FunctionComponent<TableActionProps> = (
    {
        meta, entityName, wrapper, url, parentEntityType, onClose, ...props
    }: TableActionProps,
) => {
    const ActionComponent = TABLE_ACTION_MAP[meta.actionType];
    const {form} = useContext(FormsFormTableContext);
    const handleClick = form ? () => form?.resetFields() : undefined;

    return ActionComponent ? (
        <ActionComponent
            meta={meta}
            parentEntityType={parentEntityType}
            url={url}
            entityName={entityName}
            wrapper={wrapper}
            onClose={onClose}
            onClick={handleClick}
            buttonType={meta?.buttonType}
            {...props}
        />
    ) : null;
};
