import {
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
import {groupBy, set} from 'lodash';

import {AppState} from 'store/config/types';

import {DRILLDOWN_DFF_TABLE_NAME, descriptiveFlexfieldsSliceName} from './descriptive-flexfields-slice-constants';
import {
    DescriptiveFlexfieldSegment,
    DescriptiveFlexfieldsSliceState,
    SelectDescriptiveFlexfieldSegmentsArgs,
    SelectDrillDownDFFSegmentsArgs,
} from './descriptive-flexfields-slice-types';
import {loadContextSegments, loadDDContextSegments} from './descriptive-flexfields-thunks';

export const descriptiveFlexfieldsSlice = createSlice({
    initialState: {
        descriptiveFlexfields: {},
    } as DescriptiveFlexfieldsSliceState,
    name: descriptiveFlexfieldsSliceName,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(loadDDContextSegments.fulfilled, (state, {meta, payload}) => {
            const {arg} = meta;
            const {contextCode} = arg;
            set(state, ['descriptiveFlexfields', 'contextSegments', DRILLDOWN_DFF_TABLE_NAME, contextCode], payload);
        });
        builder.addCase(loadContextSegments.fulfilled, (state, {meta, payload}) => {
            const {arg} = meta;
            const {contextCode, dffName} = arg.params;
            set(state, ['descriptiveFlexfields', 'contextSegments', dffName, contextCode], payload);
        });
    },
});

const selectDescriptiveFlexfieldsSliceState = (state: AppState) => state[descriptiveFlexfieldsSliceName];

export const selectContextSegments = createSelector(
    [
        selectDescriptiveFlexfieldsSliceState,
        (_, args: SelectDescriptiveFlexfieldSegmentsArgs) => args,
    ],
    (state, {contextCode, dffName}) => state.descriptiveFlexfields.contextSegments?.[dffName]?.[contextCode],
);

export const selectDDContextSegments = createSelector(
    [
        selectDescriptiveFlexfieldsSliceState,
        (_, args: SelectDrillDownDFFSegmentsArgs) => args,
    ],
    (state, {contextCode}) => state.descriptiveFlexfields.contextSegments?.[DRILLDOWN_DFF_TABLE_NAME]?.[contextCode],
);

export const selectDDContextSegmentsGroupedByAttribute = createSelector(
    [
        selectDDContextSegments,
        (_, args: SelectDrillDownDFFSegmentsArgs & {toLowerCase?: boolean}) => args,
    ],
    (segments, {toLowerCase}) => {
        if (!segments) return segments;
        const groupedSegments = groupBy(segments, 'attributeColumnName');
        const groupedSingleSegments = Object.fromEntries(
            Object.entries(groupedSegments).map(([attributeName, values]) => {
                const atrName = toLowerCase ? attributeName.toLocaleLowerCase() : attributeName;
                const firstValue = values?.[0];
                return [atrName, firstValue];
            }).filter(([, value]) => !!value),
        );
        return groupedSingleSegments as {[attributeName: string]: DescriptiveFlexfieldSegment};
    },
);

export const descriptiveFlexfieldsSelectors = {
    selectContextSegments,
};

export const {
    reducer: descriptiveFlexfieldsReducer,
    actions: descriptiveFlexfieldsActions,
} = descriptiveFlexfieldsSlice;
