import {RequestType} from 'modules/metadata';
import {performRequest} from 'shared/utils';

import {NetDirectoryFileData} from './net-directory-file-slice-type';

export type NetDirectoryFileArgs = {
    filename: string;
};
export const fetchNetDirectoryFileNameListData = () => (
    performRequest<string[]>({
        url: '/fileupload/external',
        method: RequestType.GET,
    })
);

export const postDocumentUploadTemplateConfiguration = async (args: NetDirectoryFileArgs) => {
    const {filename} = args;
    return performRequest<NetDirectoryFileData>({
        url: '/fileupload/external',
        method: RequestType.POST,
        params: {filename},
        data: filename,
    });
};
