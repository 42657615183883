import cn from 'classnames';
import React, {useState} from 'react';

import {TableHeaderCell} from '../../excel-header-structure-loader-modal.types';
import {HeaderTreeMapperNode} from '../header-tree-mapper-node/header-tree-mapper-node';
import {HeaderTreeMapperRow} from '../header-tree-mapper-row/header-tree-mapper-row';

import './header-tree-mapper-parent-node.less';

interface HeaderTreeMapperParentNodeProps {
    node: TableHeaderCell;
}

export const HeaderTreeMapperParentNode = ({node}: HeaderTreeMapperParentNodeProps) => {
    const [areChildrenHidden, setChildrenHidden] = useState(true);

    return (
        <>
            <div className="header-tree-mapper-parent-node">
                <HeaderTreeMapperRow
                    node={node}
                    hasChildren
                    areChildrenHidden={areChildrenHidden}
                    handleHideChildrenClick={setChildrenHidden}
                />
            </div>
            <div
                className={cn(
                    'header-tree-mapper-parent-node__children',
                    {hidden: areChildrenHidden},
                )}
            >
                {node?.children?.map(ch => (
                    <HeaderTreeMapperNode
                        key={ch.key}
                        node={ch}
                    />
                ))}
            </div>
        </>
    );
};
