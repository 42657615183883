import {DatePicker} from 'antd';
import locale from 'antd/lib/locale-provider/ru_RU';
import moment, {Moment} from 'moment';
import React, {
    ForwardedRef,
    useEffect,
    useMemo, useRef,
    useState,
} from 'react';

import {ReactComponent as CloseXOutlined} from 'shared/assets/close-x-outlined.svg';
import {ReactComponent as LockIcon} from 'shared/assets/forms/lock.svg';
import {ReactComponent as CalendarIcon} from 'shared/assets/forms/time/calendar.svg';
import {
    DATE,
    DATE_FOR_REQUEST,
    DATE_FORMATS_PLACEHOLDERS_MAP,
    DEFAULT_FORMAT_PLACEHOLDER,
    RESOLVED_FORMATS,
} from 'shared/constants/date-format';

import './date-cell.less';

export const DateCell = React.forwardRef(({
    value,
    format,
    disabled,
    defaultValue,
    requestFormat,
    showTime,
    datePickerStates,
    ...props
}: any, ref: ForwardedRef<any>) => {
    const datePickerRef = useRef<any>(null);
    const [isError, setIsError] = useState(false);
    const dateFormat = format || DATE;
    const convertedValue = value
        ? moment(value, RESOLVED_FORMATS)
        : defaultValue ? moment(defaultValue, RESOLVED_FORMATS) : null;

    const onChangeWrapper = (newDate: Moment) => {
        props.onChange?.(newDate?.format(requestFormat ?? DATE_FOR_REQUEST));
    };

    const placeholder = useMemo(() => {
        if (typeof format === 'string' && DATE_FORMATS_PLACEHOLDERS_MAP.has(format)) {
            return DATE_FORMATS_PLACEHOLDERS_MAP.get(format);
        }
        return DEFAULT_FORMAT_PLACEHOLDER;
    }, [format]);

    const handleEnterPress = (event: any) => {
        if (event.type === 'keyup' && event.key === 'Enter'
            && datePickerRef.current && datePickerStates && datePickerStates[datePickerRef.current.props.id]) {
            setIsError(!event.target.value);
        }
    };

    const handleOnFocus = () => {
        if (datePickerRef.current && datePickerRef.current.props.id && datePickerStates) {
            datePickerStates[datePickerRef.current.props.id] = true;
        }
    };

    const handleOnBlur = () => {
        if (datePickerRef.current && datePickerRef.current.props.id && datePickerStates) {
            datePickerStates[datePickerRef.current.props.id] = false;
        }
    };

    const handleOpenChange = () => {
        if (datePickerRef.current && datePickerRef.current.props.id && datePickerStates) {
            const date: HTMLElement | null = document.getElementById(datePickerRef.current.props.id);
            setIsError((!moment(date?.getAttribute('value'), format, true).isValid() && !!date?.getAttribute('value')));
        }
    };

    useEffect(() => {
        if (!datePickerRef.current) {
            return undefined;
        }
        if (datePickerRef.current.props.id && datePickerStates) {
            datePickerStates[datePickerRef.current.props.id] = false;
        }
        const picker = document.getElementById(datePickerRef.current.props.id);
        if (picker) {
            picker.addEventListener('keyup', handleEnterPress);
        }
        return () => {
            if (picker) {
                picker.removeEventListener('keyup', handleEnterPress);
            }
        };
    }, []);

    return (
        <>
            <DatePicker
                {...props}
                format={[dateFormat, dateFormat.replace(/[-.:,/]/g, '')]}
                onChange={onChangeWrapper}
                onSelect={showTime ? onChangeWrapper : undefined}
                value={convertedValue}
                showTime={showTime}
                ref={ref || datePickerRef}
                locale={locale}
                disabled={disabled}
                className="date-picker"
                suffixIcon={disabled ? <LockIcon /> : <CalendarIcon />}
                clearIcon={<CloseXOutlined className="clear-icon" />}
                dropdownClassName="time-picker-dropdown" // somehow it applies className to dropdown
                placeholder={placeholder}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                onOpenChange={handleOpenChange}
            />
            {isError && (
                <div
                    role="alert"
                    className="alert"
                >Формат должен быть {dateFormat}
                </div>
            )}
        </>
    );
});
