import {useState} from 'react';

type FieldErrors<T> = Partial<Record<keyof T, string>>;

export const useLoginFormStateErrors = <T extends Record<string, any>>(initialErrors: FieldErrors<T> = {}) => {
    const [fieldsErrors, setFieldsErrors] = useState<FieldErrors<T>>(initialErrors);

    const resetErrorByFieldName = (fieldName: keyof T) => {
        if (fieldsErrors[fieldName]) {
            setFieldsErrors(p => ({
                ...p,
                [fieldName]: '',
            }));
        }
    };

    const setErrorByFieldName = (fieldName: keyof T, error: string) => {
        if (fieldsErrors[fieldName] === error) {
            return;
        }
        setFieldsErrors(p => ({
            ...p,
            [fieldName]: error,
        }));
    };

    return {
        fieldsErrors,
        resetErrorByFieldName,
        setErrorByFieldName,
    };
};
