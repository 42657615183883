import React from 'react';

import './counter.less';

export interface CounterProps {
        count: number;
        color?: string;
}

export const Counter: React.FC<CounterProps> = ({count, color = 'gray'}: CounterProps) => {
    const className = `counter ${color}`;
    return (
        <div className={className}>
            {count}
        </div>
    );
};
