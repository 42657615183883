import {Tooltip} from 'antd';
import React from 'react';

import {DynamicIcon} from 'components/dynamic-icon';
import {BLOCKED_HREF} from 'components/table-report/components/table-report-column-content/components/table-report-column-url/table-report-column-url.constants';

import {extractUrlInfo} from './table-report-column-url-utils';

interface TableReportColumnUrlProps {
    rawValue?: string;
}

export const TableReportColumnUrl: React.FC<TableReportColumnUrlProps> = (
    {rawValue}: TableReportColumnUrlProps,
) => {
    const urlInfo = extractUrlInfo(rawValue);

    if (!urlInfo) return <>{rawValue}</>;
    const {
        href,
        text,
        icon,
    } = urlInfo;

    const isLinkBlocked = href === BLOCKED_HREF;

    return href ? (
        <Tooltip
            mouseEnterDelay={isLinkBlocked ? 0.1 : 1}
            title={isLinkBlocked ? (text ?? 'Переход запрещен') : 'Перейти по ссылке'}
            placement="bottom"
        >
            {isLinkBlocked ? (
                <DynamicIcon
                    className="default-icon-color"
                    type="QuestionCircleOutlined"
                />
            ) : (
                <a
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-underline d-flex gap-1 align-items-center"
                >
                    {icon && <DynamicIcon type={icon} />}
                    {text}
                </a>
            )}
        </Tooltip>
    ) : <>{text}</>;
};
