import React from 'react';

import {IconsMap} from 'components/dynamic-icon';
import {ProgressCell} from 'components/editable-table/columns/progress-cell/progress-cell';
import {EditableColumn} from 'components/editable-table/editable-table-types';
import {EntityValue} from 'modules/data';
import {DocumentUploadScan, FileUploadStatus} from 'modules/documents/documents-types';

interface GetUploadScanFilesColumnsMetaArgs {
    progress?: {
        percent: number;
        status?: FileUploadStatus;
    };
    handleDelete: (record: Record<string, EntityValue>) => void;
    documentsForUploadAttach: any;
}

export const SCAN_UPLOAD_UID = Symbol('uid');

export const getUploadScanFilesColumnsMeta = (args?: GetUploadScanFilesColumnsMetaArgs) => {
    const {
        progress,
        handleDelete,
        documentsForUploadAttach,
    } = args ?? {};

    return [{
        key: 'actions',
        title: '',
        dataIndex: 'actions',
        width: '35',
        order: 1,
        fixed: 'left',
        render: (_: any, record: Record<string, DocumentUploadScan>) => (
            <IconsMap.CloseX
                style={{cursor: 'pointer', marginTop: 5}}
                onClick={() => handleDelete?.(record)}
            />
        ),
    }, {
        key: 'progress',
        title: 'Загрузка',
        dataIndex: 'progress',
        align: 'center',
        width: '87',
        order: 2,
        fixed: 'left',
        render: value => (
            <ProgressCell
                percent={value?.percent ?? progress?.percent ?? 0}
                status={value?.status ?? progress?.status}
            />
        ),
    }, {
        key: 'fileName',
        title: 'Файл документа',
        dataIndex: 'fileName',
        width: '250',
        order: 3,
        fixed: 'left',

    }, {
        required: true,
        key: 'documentFileId',
        isRequired: true,
        title: 'Имя файла документа',
        dataIndex: 'documentFileId',
        inputType: 'SELECT',
        width: '372',
        alwaysEditable: true,
        editable: true,
        placeholder: 'Выберите имя документа',
        order: 4,
        fixed: 'left',
        generateInputProps: () => ({
            options: (documentsForUploadAttach ?? []).map((doc: any) => ({
                value: doc.id,
                label: doc.meaning,
            })),
        }),
        getFieldState: (_, record) => ({required: true, disabled: record.isSubmitted}),
    },
    {
        key: 'documentName',
        isDisabled: true,
        title: 'Наименование документа',
        dataIndex: 'documentName',
        inputType: 'SELECT',
        width: '472',
        alwaysEditable: true,
        editable: true,
        placeholder: 'Выберите наименование документа',
        order: 5,
    }, {
        key: 'type',
        isDisabled: true,
        title: 'Тип',
        dataIndex: 'documentTypeName',
        inputType: 'SELECT',
        width: '252',
        alwaysEditable: true,
        editable: true,
        placeholder: 'Тип',
        order: 6,
    }, {
        key: 'sectionCode',
        isDisabled: true,
        title: 'Раздел',
        dataIndex: 'sectionCode',
        inputType: 'SELECT',
        width: '235',
        alwaysEditable: true,
        editable: true,
        placeholder: 'Выберите раздел',
        order: 7,
    }] as EditableColumn[];
};
