import {createAsyncThunk} from '@reduxjs/toolkit';

import {
    FetchDDContextDFFColumnsRequestParams,
    fetchDFFColumns,
    FetchDFFColumnsArgs,
} from './descriptive-flexfields-slice-api';
import {
    DRILLDOWN_DFF_TABLE_NAME,
    descriptiveFlexfieldsSliceName,
} from './descriptive-flexfields-slice-constants';
import {DescriptiveFlexfieldSegment} from './descriptive-flexfields-slice-types';

export const loadDDContextSegments = createAsyncThunk<
    DescriptiveFlexfieldSegment[], FetchDDContextDFFColumnsRequestParams>(
        `${descriptiveFlexfieldsSliceName}/loadDDContextSegments`, async (args, {rejectWithValue}) => {
            try {
                const {contextCode} = args;
                const dffName = DRILLDOWN_DFF_TABLE_NAME;
                const {data} = await fetchDFFColumns({params: {contextCode, dffName}});
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

export const loadContextSegments = createAsyncThunk<
    DescriptiveFlexfieldSegment[], FetchDFFColumnsArgs>(
        `${descriptiveFlexfieldsSliceName}/loadContextSegments`, async (args, {rejectWithValue}) => {
            try {
                const {data} = await fetchDFFColumns(args);
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );
