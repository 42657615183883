import {Button, Form} from 'antd';
import {useForm} from 'antd/lib/form/Form';
import cn from 'classnames';
import React, {ReactElement, useImperativeHandle, useState} from 'react';

import {CustomCard} from 'components/@common/widgets/custom-card';
import {useAfterEffect} from 'shared/hooks/use-after-effect';
import {StateSetter} from 'shared/types/generics';

import './parameters-filter.less';

interface ParametersOnClearCallbackArgs {
    setIsFilterApplied: StateSetter<boolean>;
}

interface ParametersFilterProps<FormValues> {
    onChange: (values?: FormValues) => void;
    onClear?: (args: ParametersOnClearCallbackArgs) => void;
    isEmpty?: boolean;
    children: ((props: {isFilterApplied: boolean}) => ReactElement);
    isFilterAppliedByDefault?: boolean;
    className?: cn.Argument;
}

export interface ParametersFilterRef<FormValues> {
    setInitialFormValues: StateSetter<FormValues | undefined>;
}

export const ParametersFilterRaw = <FormValues extends object>(
    {
        onChange,
        onClear,
        isEmpty,
        children: Children,
        isFilterAppliedByDefault,
        className,
    }: ParametersFilterProps<FormValues>,
    ref: React.ForwardedRef<ParametersFilterRef<FormValues>>,
) => {
    const [form] = useForm();

    const [isFilterApplied, setIsFilterApplied] = useState(!!isFilterAppliedByDefault);
    const [initialFormValues, setInitialFormValues] = useState<FormValues>();

    const handleFinish = (values: FormValues) => {
        setInitialFormValues(values);
    };

    useImperativeHandle(ref, () => ({
        setInitialFormValues,
    }));

    useAfterEffect(() => {
        onChange(initialFormValues);
        setIsFilterApplied(true);
        form.resetFields();
    }, [initialFormValues]);

    return (
        <CustomCard
            title="Параметры отчёта"
            className={cn('parameters-filter')}
            titleClassName={cn('parameters-filter__title')}
        >
            <div>
                <Form<FormValues>
                    layout="vertical"
                    form={form}
                    onFinish={handleFinish}
                    initialValues={initialFormValues}
                >
                    <div className={cn('parameters-filter__filters', className)}>
                        {!isEmpty ? <Children isFilterApplied={isFilterApplied} /> : 'Нет доступных фильтров'}
                    </div>
                </Form>
                <div className={cn('parameters-filter__buttons')}>
                    {isFilterApplied ? (
                        <Button
                            type="primary"
                            onClick={() => {
                                setIsFilterApplied(false);
                            }}
                        >Скорректировать параметры отчёта
                        </Button>
                    ) : (
                        <>
                            <Button
                                disabled={isEmpty}
                                type="primary"
                                onClick={() => {
                                    form.submit();
                                }}
                            >Применить
                            </Button>
                            <Button
                                disabled={isEmpty}
                                type="default"
                                onClick={() => {
                                    onClear?.({
                                        setIsFilterApplied,
                                    });
                                    setInitialFormValues(undefined);
                                    setTimeout(() => {
                                        form.resetFields();
                                    }, 0);
                                }}
                            >Очистить
                            </Button>
                        </>
                    )}
                </div>
            </div>
        </CustomCard>
    );
};

export const ParametersFilter = React.forwardRef(ParametersFilterRaw) as <FormValues extends object>(
    props: ParametersFilterProps<FormValues> & { ref?: React.ForwardedRef<ParametersFilterRef<FormValues>> }
  ) => ReturnType<typeof ParametersFilterRaw>;
