import React from 'react';

import {ReactComponent as AuthorIcon} from 'shared/assets/user.svg';

import './reply-message.less';

interface MessageReplyAuthorProps {
    author: string;
    addresses: string;
}

export const ReplyMessage = ({author, addresses}: MessageReplyAuthorProps) => (
    <div className="message-reply-author">
        <span className="author">
            <AuthorIcon
                width={25}
                height={20}
            />
            <div className="name">
                {author}
            </div>
        </span>
        <div> {addresses}</div>
    </div>
);
