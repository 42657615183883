import {EntityType} from 'shared/constants/entities';
import {Dispatch} from 'store/config/types';

import {fetchDashboardData} from '../api/dashboard-api';
import {setData} from '../data-actions';
import {EntityData} from '../data-types';

export const loadDashboardData = (
    entityName: string,
    fieldKey: string,
    referenceUrl: string,
    context?: EntityData,
    useContext?: boolean,
) => async (dispatch: Dispatch) => {
    try {
        const {data} = await fetchDashboardData(referenceUrl, useContext ? context : undefined);
        dispatch(setData({
            entityName,
            entityType: EntityType.DASHBOARD,
            data: {
                [fieldKey]: {
                    dataTable: data?.dataTable.map(v => ({
                        key: v.entityName,
                        label: v.entityDescription,
                        value: v.count,
                    })) || [],

                    recordsTotal: data?.recordsTotal,
                },
            },
        }));
    } catch (e) {
        console.error(e);
    }
};
