import {Button, Modal} from 'antd';
import cn from 'classnames';
import React, {useRef, useState} from 'react';
import Draggable from 'react-draggable';
import {useDispatch, useSelector} from 'react-redux';

import {resetLoadedData} from 'modules/data';
import {resetData} from 'modules/data/data-actions';
import {selectDocumentsUploadMonitoring} from 'modules/documents/documents-selectors';
import {METADATA_MODULE} from 'modules/metadata';
import {ReactComponent as ArrowIcon} from 'shared/assets/arrow-left.svg';
import {ReactComponent as CloseIcon} from 'shared/assets/close.svg';
import {EntityType} from 'shared/constants/entities';
import {handleStartDragging} from 'shared/utils/draggable';
import {useAppSelector} from 'store/config/hooks';
import {AppState} from 'store/config/types';
import {selectModalData} from 'store/slices/modals-slice';

import {DocumentsUploadStageGenerator} from './documents-upload-stage-generator';
import './documents-upload-stage-modal.less';
import '../document.less';

export const DocumentsUploadStageModal = () => {
    const entityName = useSelector(
        selectModalData,
    )?.entityName;
    const metaData = useSelector(
        (state: AppState) => state[METADATA_MODULE]?.[entityName || '']?.[EntityType.TABLE],
    );
    const {fileList, headers} = useAppSelector(selectDocumentsUploadMonitoring()) || {};
    const dispatch = useDispatch();

    const draggableRef = useRef<HTMLDivElement>(null);
    const [disabled, setDisabled] = useState(false);
    const [bounds, setBounds] = useState({
        left: 0, top: 0, bottom: 0, right: 0,
    });
    const [visible, setVisible] = useState(true);
    const [contentVisible, setContentVisible] = useState(visible);
    const [visibleStagePopoverIndex, setVisibleStagePopoverIndex] = useState<string>('');

    const handleClose = () => {
        if (entityName && metaData) {
            dispatch(resetData({
                entityName,
                entityType: EntityType.MODAL,
            }));
            dispatch(resetLoadedData(entityName, EntityType.TABLE));
        }

        setVisible(false);
    };
    if (!fileList) return null;

    return (
        <div className={cn('documents-upload-modal', !contentVisible && 'hidden-content')}>
            <Modal
                className="stage-modal"
                getContainer=".documents-upload-modal"
                visible={visible}
                destroyOnClose
                closeIcon={(
                    <>
                        <ArrowIcon
                            className="stage-modal-title-icon arrow-icon"
                            style={{transform: `rotate(${contentVisible ? '-90' : '90'}deg)`}}
                            onClick={() => setContentVisible(v => !v)}
                        />
                        <CloseIcon
                            className="stage-modal-title-icon"
                            onClick={() => setVisible(false)}
                        />
                    </>
                )}
                title={(
                    <div
                        className="documents-upload-title"
                        onMouseOver={() => {
                            if (disabled) {
                                setDisabled(false);
                            }
                        }}
                        onMouseOut={() => {
                            setDisabled(true);
                        }}
                        onFocus={() => {
                        }}
                        onBlur={() => {
                        }}
                    >
                        Процесс загрузки файлов из раздела {headers?.toLowerCase()}
                    </div>
                )}
                modalRender={(modal: React.ReactNode) => (
                    <Draggable
                        disabled={disabled}
                        bounds={bounds}
                        onStart={handleStartDragging({draggableRef, setBounds})}
                    >
                        <div ref={draggableRef}>{modal}</div>
                    </Draggable>
                )}
                footer={contentVisible && (
                    <Button
                        type="default"
                        onClick={handleClose}
                        className="close-button"
                    >
                        Закрыть
                    </Button>
                )}
            >
                <div className="documents-container">
                    {fileList.map(file => (
                        file.saveResult ? (
                            <DocumentsUploadStageGenerator
                                fileName={file.fileName}
                                monitoring={file.saveResult}
                                visibleStagePopoverIndex={visibleStagePopoverIndex}
                                setVisibleStagePopoverIndex={setVisibleStagePopoverIndex}
                            />
                        ) : <div>Произошла ошибка при загрузке файла</div>
                    ))}
                </div>
            </Modal>
        </div>
    );
};
