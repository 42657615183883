import {CloseOutlined} from '@ant-design/icons';
import {Button, Modal} from 'antd';
import {ModalProps} from 'antd/es/modal';
import cn from 'classnames';
import React, {
    ReactElement, useEffect, useRef, useState,
} from 'react';

import './click-confirmation.less';

interface ClickConfirmationProps {
   children: ReactElement;
   skip?: () => boolean;
   apply?: () => boolean;
   handlers?: {
    onConfirm?: () => void | Promise<void>;
    // onCancel?: Promise<void>;
   };
   modalProps?: ModalProps;
   labels?: {
    main?: string;
    confirmButtonLabel?: string;
    cancelButtonLabel?: string;
    title?: string;
   };
}

export const ClickConfirmation: React.FC<ClickConfirmationProps> = (
    {
        children,
        skip,
        apply,
        handlers = {},
        modalProps,
        labels = {},
    }: ClickConfirmationProps,
) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const {
        cancelButtonLabel = 'Отменить',
        confirmButtonLabel = 'Подтвердить',
        main = 'Вы действительно хотите продолжить?',
        title = 'Продолжить?',
    } = labels;

    const {onConfirm} = handlers;

    const childRef = useRef<HTMLElement>(null);
    const clickIsAllowedRef = useRef<boolean>(false);
    const isMountedRef = useRef<boolean>(false);

    useEffect(() => () => {
        isMountedRef.current = false;
    }, []);

    const closeModal = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Modal
                className={cn('click-confirmation')}
                closeIcon={<div onClick={() => setIsModalVisible(p => !p)}><CloseOutlined /></div>}
                visible={isModalVisible}
                centered
                footer={null}
                width={750}
                onCancel={() => setIsModalVisible(false)}
                title={title}
                {...modalProps}
            >
                <div className={cn('click-confirmation__label')}>{main}</div>
                <div className={cn('click-confirmation__footer')}>
                    <Button
                        disabled={isSubmitting}
                        type="primary"
                        onClick={async () => {
                            setIsSubmitting(true);
                            try {
                                await onConfirm?.();
                                clickIsAllowedRef.current = true;
                                childRef?.current?.click();
                                clickIsAllowedRef.current = false;
                            } catch {
                                // pass
                            } finally {
                                setIsSubmitting(false);
                                closeModal();
                            }
                        }}
                    >{confirmButtonLabel}
                    </Button>
                    <Button
                        type="default"
                        onClick={() => setIsModalVisible(false)}
                    >{cancelButtonLabel}
                    </Button>
                </div>

            </Modal>
            <div onClickCapture={e => {
                if (skip?.()) return;
                if (apply && !apply()) return;
                if (!clickIsAllowedRef.current) {
                    e.stopPropagation();
                    if (!isModalVisible) {
                        setIsModalVisible(true);
                    }
                }
            }}
            >
                {React.cloneElement(children, {ref: childRef})}
            </div>
        </>
    );
};
