import {
    LoadingOutlined, SafetyCertificateOutlined,
} from '@ant-design/icons';
import {message} from 'antd';
import {FormInstance} from 'antd/lib/form';
import cn from 'classnames';
import {createDetachedSignature} from 'crypto-pro';
import React, {useImperativeHandle, useRef, useState} from 'react';

import {ModalOpenerComponent, ModalOpenerComponentRef} from 'components/modal-opener-component';
import {fetchCryptoProAttachmentHash} from 'modules/documents/documents-api';
import {performRequest, showMessage} from 'shared/utils';
import {CertificateExtended} from 'shared/utils/crypto-pro';
import {CADESCOM_HASH_ALGORITHM_CP_SHA1} from 'shared/utils/crypto-pro/crypto-pro-constants';

import {CryptoProSignatureModalContent} from './crypto-pro-signature-modal-content';
import {CryptoProSignedDoc} from './crypto-pro-signing-modal-types';

import './crypto-pro-signing-modal.less';

interface CryptoProSigningModalProps {
    documentIds: string[]; // todo: нужна типизация
    onFinish?: () => void;
    onStart?: () => void;
    entityName?: string;
    className?: cn.Argument;
}

interface CryptoProFileInfo {
    docId: string;
    fileName: string;
    signContent: string;
    publishAllowed: boolean;
}

const SIGNING_MESSAGE_KEY = 'SIGNING_MESSAGE_KEY';

export const CryptoProSigningModal = React.forwardRef<any, CryptoProSigningModalProps>((
    {
        documentIds, onFinish, onStart, entityName, className,
    }: CryptoProSigningModalProps,
    ref,
) => {
    const [selectedCertificate, setSelectedCertificate] = useState<CertificateExtended>();

    const [isWithPluginInitializationError, setIsWithPluginInitializationError] = useState(false);
    const [isSignButtonTemporarilyInactive, setIsSignButtonTemporarilyInactive] = useState(false);

    const [signedDocs, setSignedDocs] = useState<CryptoProSignedDoc[]>([]);

    const formRef = useRef<FormInstance>(null);
    const modalRef = useRef<ModalOpenerComponentRef | null>(null);

    const [isWithResign, setIsWithResign] = useState(true);

    const documentIdsToSign = isWithResign ? documentIds : documentIds.filter(
        id => !signedDocs.map(doc => doc.docId).includes(id),
    );

    useImperativeHandle(ref, () => modalRef.current);

    const handleFinish = () => {
        const certificateThumbprint = selectedCertificate?.thumbprint;
        const certificateCadescomAlgorithm = selectedCertificate?.algorithmCadescomConstant;

        if (certificateThumbprint && certificateCadescomAlgorithm !== undefined) {
            (async () => {
                onStart?.();

                message.loading({
                    content: documentIdsToSign.length === 1
                        ? 'Формирование подписи документа...' : (
                            <span className="ml-1">
                                Формирование подписей документов...
                                <div style={{fontSize: 12}}>
                                    Файлов для подписания: {documentIdsToSign.length}
                                </div>
                            </span>
                        ),
                    key: SIGNING_MESSAGE_KEY,
                    duration: 0,
                });

                modalRef.current?.closeModal();

                const signDocument = async (docId: string) => {
                    const fileInfoRequest = performRequest<CryptoProFileInfo>({
                        url: `documents/${entityName}/get-content-param-cryptopro/${docId}`,
                    });

                    const fileHashRequest = fetchCryptoProAttachmentHash({
                        entityName,
                        docId,
                        algorithmCode: certificateCadescomAlgorithm,
                    });

                    const [fileHashResponse, fileInfoResponse] = await Promise.all([fileHashRequest, fileInfoRequest]);

                    const fileHash = (fileHashResponse?.data as string)?.toUpperCase();
                    const fileInfo = fileInfoResponse.data;

                    const {fileName, publishAllowed} = fileInfo;

                    const privateKey = await selectedCertificate.certificate.getCadesProp('PrivateKey');

                    if (certificateCadescomAlgorithm !== CADESCOM_HASH_ALGORITHM_CP_SHA1) {
                        await privateKey.propset_CachePin(true); // кэшируем пароль от
                        // контейнера, чтобы он не запрашивался каждый раз
                    }

                    const detachedSignature = await createDetachedSignature(certificateThumbprint, fileHash, {
                        hashedAlgorithm: certificateCadescomAlgorithm,
                    });

                    const fileFormData = new FormData();
                    const signatureFile = new File([detachedSignature], `${fileName}.sig`, {type: 'text/plain'});

                    fileFormData.set('signContent', signatureFile);
                    fileFormData.set('fileName', `${fileName}.sig`);
                    fileFormData.set('docId', `${docId}`);
                    fileFormData.set('publishAllowed', `${publishAllowed}`);

                    const signResult = await performRequest({
                        url: `documents/${entityName}/save-sign-cryptopro`,
                        method: 'POST',
                        data: fileFormData,
                    });

                    return signResult;
                };

                const signDocumentsPromise = Promise.all(documentIdsToSign.map(id => {
                    const signPromise = signDocument(id).catch(() => {
                        showMessage({
                            message: 'Ошибка формирования подписи в одном из выбранных документов',
                            isError: true,
                        });
                        return {noSign: true};
                    });
                    return signPromise;
                }));

                try {
                    const signResult = await signDocumentsPromise;

                    const successfulySignedAmount = signResult.filter((entry: any) => entry?.noSign !== true).length;

                    const notify = successfulySignedAmount === 0 ? message.error : message.success;
                    notify({
                        key: SIGNING_MESSAGE_KEY,
                        content: (() => {
                            if (successfulySignedAmount === 0) return 'Формирование подписи завершено с ошибкой';
                            if (successfulySignedAmount === 1) return 'Формирование подписи завершено';
                            return (
                                <span className="ml-1">
                                    Формирование подписей завершено
                                    <div style={{fontSize: 12}}>
                                        Подписано: {successfulySignedAmount} из {documentIdsToSign.length}
                                    </div>
                                </span>
                            );
                        })(),
                        duration: 2,
                    });

                    onFinish?.();
                } catch {
                    message.error({
                        key: SIGNING_MESSAGE_KEY,
                        content: 'Ошибка формирования подписи в одном из выбранных документов',
                        duration: 2,
                    });
                }
            })();
        }
    };

    return (
        <>
            <ModalOpenerComponent
                ref={modalRef}
                componentWrapperClassNames={className}
                modalProps={{
                    centered: true,
                    forceRender: false,
                    destroyOnClose: true,
                    title: 'Настройка подписи',
                }}
                hideControls={{
                    save: isWithPluginInitializationError,
                }}
                disabledControls={{
                    save: !selectedCertificate || isSignButtonTemporarilyInactive || !documentIdsToSign.length,
                }}
                controlLabels={{
                    save: isSignButtonTemporarilyInactive ? (
                        <div className="">
                            <LoadingOutlined className="mr-1" />
                            Подписать
                        </div>
                    ) : 'Подписать',
                    cancel: isWithPluginInitializationError ? 'Закрыть' : undefined,
                }}
                handleSave={() => {
                    setIsSignButtonTemporarilyInactive(true);
                    setTimeout(() => {
                        setIsSignButtonTemporarilyInactive(false);
                    }, 1000);
                    formRef.current?.submit();
                }}
                component={(
                    <div className={cn(
                        'crypto-pro-signing-modal__sign-component',
                        'd-flex align-items-center cursor-pointer gap-0-5',
                    )}
                    >
                        <SafetyCertificateOutlined />
                        <span>Подписать</span>
                    </div>
                )}
                afterModalClose={() => {
                    setSelectedCertificate(undefined);
                    setIsWithPluginInitializationError(false);
                    setIsSignButtonTemporarilyInactive(false);
                    setSignedDocs([]);
                    setIsWithResign(true);
                }}
            >
                <CryptoProSignatureModalContent
                    ref={formRef}
                    selectedCertificate={selectedCertificate}
                    setSelectedCertificate={setSelectedCertificate}
                    isWithPluginInitializationError={isWithPluginInitializationError}
                    setIsWithPluginInitializationError={setIsWithPluginInitializationError}
                    handleFinish={handleFinish}
                    documentIds={documentIds}
                    setSignedDocs={setSignedDocs}
                    signedDocs={signedDocs}
                    isWithResign={isWithResign}
                    setIsWithResign={setIsWithResign}
                />
            </ModalOpenerComponent>
        </>
    );
});
