import {Card, List, Space} from 'antd';
import {ListItemMetaProps} from 'antd/lib/list';
import cn from 'classnames';
import React, {ReactNode} from 'react';

import 'components/@common/specific/list-array-factory/list-array-factory.less';

export interface ListArrayItem {
    listTitle: string;
    list: ListItemMetaProps[];
    useButtonsBottomMargin?: boolean;
    buttons?: ReactNode[];
}

export interface ListArrayFactoryProps {
    listArrayDescription: ListArrayItem[];
}

export const ListArrayFactory = ({
    listArrayDescription,
}: ListArrayFactoryProps) => (
    <div className="list-array-factory">
        {listArrayDescription.map(({
            listTitle,
            list,
            buttons,
            useButtonsBottomMargin,
        }) => (
            <>
                <Card
                    bordered={false}
                    title={listTitle}
                    key={listTitle}
                >
                    <List
                        itemLayout="vertical"
                        size="small"
                        pagination={false}
                        dataSource={list}
                        renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                    title={item.title}
                                    description={item.description}
                                />
                            </List.Item>
                        )}
                    />
                </Card>
                {Boolean(buttons?.length) && (
                    <Space
                        size={16}
                        className={cn('buttons', useButtonsBottomMargin && 'with-bottom-margin')}
                    >
                        {buttons}
                    </Space>
                )}
            </>
        ))}
    </div>
);
