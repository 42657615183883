import {
    createSelector,
    Selector,
} from '@reduxjs/toolkit';

import {EntityType} from 'shared/constants/entities';
import {AppState} from 'store/config/types';

import {METADATA_MODULE} from './metadata-constants';
import {
    MetadataState,
} from './metadata-types';

const rootSelector: Selector<AppState, MetadataState> = (state: AppState) => state[METADATA_MODULE];

export const selectMetadata = (entityName: string, entityType: EntityType) => createSelector(
    rootSelector,
    (metadataState: MetadataState) => metadataState[entityName]?.[entityType],
);

export const selectEntityMetadata = (entityName: string) => createSelector(
    rootSelector,
    (metadataState: MetadataState) => metadataState[entityName],
);

export const selectTableEntityMetadata = (entityName: string) => createSelector(
    rootSelector,
    (metadataState: MetadataState) => metadataState[entityName]?.[EntityType.TABLE],
);

export const selectFilterEntityMetadata = (entityName: string) => createSelector(
    rootSelector,
    (metadataState: MetadataState) => metadataState[entityName]?.[EntityType.FILTER],
);

export const selectFormEntityMetadata = (entityName: string) => createSelector(
    rootSelector,
    (metadataState: MetadataState) => metadataState[entityName]?.[EntityType.FORM],
);
export const selectFormDraftMetadata = (entityName: string) => createSelector(
    rootSelector,
    (metadataState: MetadataState) => metadataState[entityName]?.[EntityType.FORM]?.isDraft,
);
