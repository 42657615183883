import {
    DocumentUploadTemplateTypeLookupData,
    ExcelMappingData,
} from 'store/slices/document-upload-template/document-upload-template-slice-type';

export interface SettingTemplateTransferTargetData{
    targetField: string;
    sourceField?: string;
    sourceFieldDynamicSql?: string;
    sourceFieldConstant?: string;
    description?: string;
    fieldTypeId: string;
    fieldFormat?: string;
    fieldRegex?: string;
}
export const convertSettingTemplateTransferTargetFieldsData = (
    lookupData?: DocumentUploadTemplateTypeLookupData[],
    mappingData?: ExcelMappingData[],
): SettingTemplateTransferTargetData[] => {
    const resultData:SettingTemplateTransferTargetData[] = [];
    lookupData?.forEach((el, index) => {
        resultData.push({
            targetField: el?.lookupCode,
            sourceField: mappingData?.[index]?.sourceField,
            sourceFieldDynamicSql: mappingData?.[index]?.sourceFieldDynamicSql,
            sourceFieldConstant: mappingData?.[index]?.sourceFieldConstant,
            description: mappingData?.[index]?.description,
            fieldTypeId: el?.meaning,
            fieldFormat: mappingData?.[index]?.fieldFormat,
            fieldRegex: mappingData?.[index]?.fieldRegex,
        });
    });
    return resultData;
};
