import React from 'react';

export const ChangesJournal: React.FC = () => (
    <div style={{
        maxHeight: 'calc(min(600px, 64vh))',
        height: 600,
        overflow: 'auto',
        padding: '8px 12px 8px 0',
    }}
    >
        {/* todo: inline-стили переписать на классы */}
        <h4>Создание записи</h4>
        <div
            className="d-flex gap-1-5"
        >
            <div
                className="text-gray-600"
                style={{
                    minWidth: 120,
                }}
            >01.01.2000 9:59:59
            </div>
            <div>Иванов Иван Иванович, Работник</div>
        </div>

        <h4 className="mt-2-5">Изменение записи</h4>
        <div className="d-flex gap-1-5">
            <div
                className="text-gray-600"
                style={{
                    minWidth: 120,
                }}
            >01.01.2000 9:59:59
            </div>
            <div>Иванов Иван Иванович, Работник</div>
        </div>
        <div className="d-flex gap-1-5 mt-2">
            <div
                className="text-gray-600"
                style={{
                    minWidth: 120,
                }}
            >Изменение
            </div>
            <div>Запись доступна для отправки в ФНС</div>
        </div>
    </div>
);
