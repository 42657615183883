import get from 'lodash/get';
import React from 'react';
import {Link} from 'react-router-dom';

import {FieldMeta} from 'modules/metadata';

export interface RequestLinkProps {
    field: FieldMeta;
    record: any;
    url: string;
    entityName: string;
    cellClassName?: string;
}

export const RequestLink = ({
    field, record, cellClassName, url,
}: RequestLinkProps) => {
    const {id} = record;
    const reportUrl = `${url}/${id}`;
    return (
        <div
            style={{color: field?.customColor || ''}}
            className={cellClassName}
        >
            <Link
                to={reportUrl}
            >
                {get(record, field.key)}
            </Link>
        </div>
    );
};
