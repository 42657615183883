import {Checkbox} from 'antd';
import React from 'react';

import {EntityValue} from 'modules/data';
import {getMatchesBoolValue} from 'modules/data/utils/data.utils';
import {normalizeBoolValue} from 'utils/common';

import './bool-field.less';

export interface BoolFieldProps {
    value?: EntityValue;
    onChange?: (value: any) => void;
    disabled?: boolean;
    referenceUrl?: string;
    entityName?: string;
    defaultValue?: string;
    label?: string;
}

export const BoolField: React.FunctionComponent<BoolFieldProps> = ({
    value,
    onChange,
    disabled,
    defaultValue,
    label,
    ...props
}: BoolFieldProps) => {
    const handleChange = async () => {
        if (onChange) onChange(getMatchesBoolValue(value as string));
    };

    return (
        <>
            <Checkbox
                {...props}
                onChange={handleChange}
                checked={normalizeBoolValue(value || defaultValue)}
                disabled={!onChange || disabled}
            >{label}
            </Checkbox>
        </>
    );
};
