import {Empty} from 'antd';
import React from 'react';

import {ReportConfigurationQueryParameterDto} from 'store/slices/report-configuration-slice/report-configuration-dto';

import {TableReportFilterField} from './filter-field/filter-field';

import './table-report-filter.less';

export interface TableReportFilterProps {
    filterKeyPath: 'paramName' | 'columnName';
    queryParameters?: ReportConfigurationQueryParameterDto[];
    areParametersApplied: boolean;
}

export const TableReportFilter: React.FC<TableReportFilterProps> = ({
    filterKeyPath,
    queryParameters,
    areParametersApplied,
}: TableReportFilterProps) => {
    if (!queryParameters?.length) {
        return <Empty description="Для отчета отсутствуют параметры фильтрации в настройке отчетных форм" />;
    }

    return (
        <>
            {queryParameters?.map(queryParameter => (
                queryParameter.visible && (
                    <TableReportFilterField
                        filterKeyPath={filterKeyPath}
                        queryParameter={queryParameter}
                        areParametersApplied={areParametersApplied}
                    />
                )
            ))}
        </>
    );
};
