import {QuestionCircleOutlined} from '@ant-design/icons';
import {Tooltip} from 'antd';
import Form, {FormItemProps} from 'antd/es/form';
import Input from 'antd/es/input';
import React from 'react';

import {isNumberRegExp, widthRegExp} from 'shared/regular-expressions';
import {FormItemProps as AntFormItemProps} from 'shared/types';

type FormItemWidthInputProps = FormItemProps;
type WidthInputProps = AntFormItemProps;

const WidthInput: React.FC<WidthInputProps> = ({
    onChange,
    value,
}: WidthInputProps) => (
    <Input
        value={value}
        placeholder="Введите значение"
        onChange={e => onChange?.(e.target.value, undefined)}
        onBlur={e => {
            const {value: v} = e.target;
            if (isNumberRegExp.test(v)) {
                onChange?.(`${v}px`, undefined);
            }
        }}
    />
);

export const FormItemWidthInput: React.FC<FormItemWidthInputProps> = (
    props: FormItemWidthInputProps,
) => (
    <Form.Item
        label={(
            <span
                className="d-inline-flex align-items-center"
                style={{gap: 6}}
            >
                Ширина столбца
                <Tooltip
                    title={'Доступные единицы: px, vw (от ширины экрана), cm, mm (например, "100px")'}
                    placement="right"
                >
                    <QuestionCircleOutlined style={{
                        color: '#B7C1D5',
                    }}
                    />
                </Tooltip>
            </span>

        )}
        {...props}
        rules={[{
            validator: (_, value) => {
                if (!value || widthRegExp.test(value)) return Promise.resolve();
                return Promise.reject(new Error('Некорректное значение ширины'));
            },
        }, {}]}
    >
        <WidthInput />
    </Form.Item>
);
