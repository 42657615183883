import {FieldMeta, FieldType} from 'modules/metadata';

import {COMMON_FIELDS_ITEMS} from '../common/common.fields-items';
import {COMMON_OPTIONS} from '../common/common.options';

const OPEN_MODAL: FieldMeta[] = [
    {
        key: 'modalName',
        label: 'Название модального окна',
        type: FieldType.STATIC_SELECT,
        options: COMMON_OPTIONS.MODULE_NAMES,
    },
];

const FILTER: FieldMeta[] = [
    {
        key: 'isIntegratedFilter',
        label: 'Сделать фильтр встроенным',
        type: FieldType.BOOLEAN_CLASSIC,
        hint: 'Если выбрано, то отображает фильтр не в модальном окне',
    },
];

const DEL_TABLE_ROWS: FieldMeta[] = [
    COMMON_FIELDS_ITEMS.ACTIONS.REFERENCE_URL,
    {
        key: 'requestType',
        label: 'Тип запрос',
        type: FieldType.STATIC_SELECT,
        isRequired: true,
        options: [
            {
                label: 'DELETE',
                value: 'DELETE',
            },
        ],
    },
];

export const TABLE_ACTIONS = {
    OPEN_MODAL,
    FILTER,
    DEL_TABLE_ROWS,
};
