import React from 'react';

import {FormEditModal} from 'components/form-edit-modal/form-edit-modal';
import {Table} from 'components/table';
import {TableScroll} from 'components/table/table';
import {PageTemplateProps} from 'pages';

interface IOwnProps extends PageTemplateProps {
    hideTitle?: boolean;
    referenceUrl: string;
    onClose: () => void;
    scroll?: TableScroll;
}

export const TableWithOpenForm = ({
    url, hideTitle, entityType, entityName, onClose, referenceUrl, scroll,
}: IOwnProps) => (
    <>
        <Table
            hideTitle={hideTitle}
            entityName={entityName}
            url={url}
            entityType={entityType}
            scroll={scroll}
        />
        <FormEditModal
            entityName={entityName}
            onClose={onClose}
            referenceUrl={referenceUrl}
        />
    </>
);
