import {createAsyncThunk} from '@reduxjs/toolkit';
import {union} from 'lodash';

import {selectContextDataWithLookups} from 'modules/context/context-selector';
import {AppState} from 'store/config/types';
import {selectUserInfo} from 'store/slices/auth-slice';

import {ReportConfigurationAttribute} from '../../report-configuration-slice';
import {getTableAttributes} from '../../report-configuration-slice/report-dependencies';
import {createFilterDefaultValues, omitEmptyParameterFields} from '../table-report-query-params/table-report-query-params-utils';
import {tableReportSliceActions} from '../table-report-slice';
import {
    FetchTableReportTemplateConfigArgs,
    fetchTableReportData,
    fetchTableReportTemplateConfig,
} from '../table-report-slice-api';
import {TABLE_REPORT_URL_FIELD_NAME, tableReportSliceName} from '../table-report-slice-constants';
import {selectLinkDtoToColumnMapping, selectTableReportTemplateConfig} from '../table-report-slice-selectors';
import {
    LoadTableReportPageDataArgs,
    TableReportData,
    TableReportExtendedConfiguration,
    TableReportQueryParameters,
} from '../table-report-slice-types';

export const loadTableReportTemplateConfig = createAsyncThunk<
    TableReportExtendedConfiguration, FetchTableReportTemplateConfigArgs
>(
    `${tableReportSliceName}/loadTableReportTemplateConfig`,
    async (args, {rejectWithValue}) => {
        try {
            const response = await fetchTableReportTemplateConfig(args);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const loadTableReportAttributes = createAsyncThunk<
    ReportConfigurationAttribute[], {templateCode: string}
>(
    `${tableReportSliceName}/loadTableReportAttributes`,
    async (args, {rejectWithValue}) => {
        try {
            const response = await getTableAttributes(args);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export interface LoadTableReportPageThunkData {
    data: TableReportData;
    config: TableReportExtendedConfiguration;
    everyRequiredParameterIsFilled: boolean;
    defaultQueryParameters: TableReportQueryParameters | undefined;
}

export const loadTableReportPageData = createAsyncThunk<
    LoadTableReportPageThunkData, LoadTableReportPageDataArgs
>(
    `${tableReportSliceName}/loadTableReportPageData`,
    async (args, {rejectWithValue, dispatch, getState}) => {
        try {
            let extendedConfigFetchError: any;

            const {
                dataParams: rawDataParams,
                configParams,
                commonConfigParams,
                extendedConfigParams,
                templateCode,
                useConfigCache,

                extraDefaultParams,
                isInitialRequest,
            } = args;

            const linkDtoToColumnMapping = selectLinkDtoToColumnMapping(getState() as AppState);
            const sortWithLinks = rawDataParams.sort?.map(item => {
                if (!item.columnName.startsWith(TABLE_REPORT_URL_FIELD_NAME)) return item;
                return {
                    ...item,
                    columnName: linkDtoToColumnMapping?.[item.columnName] ?? item.columnName,
                };
            });

            const dataParams: typeof rawDataParams = {
                ...rawDataParams,
                sort: sortWithLinks,
            };

            const {
                setReportRequestData,
            } = tableReportSliceActions;
            // solution for starting report downloading, backend need the same params for execute endpoint. ↴
            dispatch(setReportRequestData({
                templateCode,
                ...dataParams,
            }));

            const getTemplateConfig = async () => {
                let config: TableReportExtendedConfiguration | undefined;
                let everyRequiredParameterIsFilled = true;
                let defaultQueryParameters: TableReportQueryParameters | undefined;

                const checkIfEveryParameterIsFilled = ( // проверить, все ли обязательные параметры заполнены
                    cfg: TableReportExtendedConfiguration | undefined,
                    extraParams: {[key: string]: any} = {},
                ) => {
                    const requiredVisibleParams = cfg?.queryParameters
                        ?.filter?.(p => p.required && p.visible) ?? [];

                    const requiredParams = union([
                        ...requiredVisibleParams.map(p => p.columnName),
                        ...requiredVisibleParams.map(p => p.paramName),
                    ].filter(v => v !== undefined));

                    const isEveryRequiredParameterFilled = (() => {
                        if (!requiredParams?.length) return true;
                        if (!dataParams.parameters && !extraParams) return false;

                        const parameterKeysToSend = Object.keys({
                            ...extraParams,
                            ...dataParams.parameters ?? {},
                        });

                        if (requiredParams.every(paramKey => parameterKeysToSend.includes(paramKey))) return true;

                        return false;
                    })();

                    return isEveryRequiredParameterFilled;
                };

                const getDefaultParameters = (templateConfig: TableReportExtendedConfiguration | undefined) => {
                    const context = selectContextDataWithLookups(getState() as AppState);
                    const userInfo = selectUserInfo(getState() as AppState);

                    const {queryParameters, tableDatasourceType} = templateConfig ?? {};

                    const filterKeyPath = tableDatasourceType === 'TABLE' ? 'columnName' : 'paramName';

                    const defaultParameters = omitEmptyParameterFields({
                        ...createFilterDefaultValues({
                            context,
                            filterKeyPath,
                            queryParameters,
                            userInfo,
                        }),
                        ...extraDefaultParams,
                    }) as TableReportQueryParameters | undefined;

                    return defaultParameters;
                };

                const currentTemplateConfig = selectTableReportTemplateConfig(getState() as AppState);

                if (currentTemplateConfig
                        && checkIfEveryParameterIsFilled(currentTemplateConfig)
                        && useConfigCache) { // если используем кэш - возвращаем из стора
                    defaultQueryParameters = getDefaultParameters(currentTemplateConfig);
                    everyRequiredParameterIsFilled = checkIfEveryParameterIsFilled(
                        currentTemplateConfig, !isInitialRequest
                            ? undefined
                            : {...defaultQueryParameters, ...extendedConfigParams.parameters},
                    );
                    config = currentTemplateConfig;
                } else {
                    const templateConfig = await dispatch(loadTableReportTemplateConfig({
                        templateCode,
                        params: {
                            ...commonConfigParams,
                            ...configParams,
                        },
                        isExtended: false,
                    })).unwrap(); // сначала получаем не Extended конфиг

                    defaultQueryParameters = getDefaultParameters(templateConfig);
                    config = templateConfig;
                    everyRequiredParameterIsFilled = checkIfEveryParameterIsFilled(
                        config, !isInitialRequest
                            ? undefined
                            : {...defaultQueryParameters, ...extendedConfigParams.parameters},
                    );

                    if (everyRequiredParameterIsFilled) { // если все параметры заполнены, можем получить Extended
                        const extendedTemplateConfig = await dispatch(loadTableReportTemplateConfig({
                            templateCode,
                            params: {
                                ...commonConfigParams,
                                ...extendedConfigParams,
                                ...(!isInitialRequest ? {} : {
                                    parameters: {...defaultQueryParameters, ...extendedConfigParams.parameters},
                                }),
                            },
                            isExtended: true,
                        })).unwrap();

                        config = extendedTemplateConfig;
                    }
                }

                return {
                    config,
                    everyRequiredParameterIsFilled,
                    defaultQueryParameters,
                };
            };

            const {config, everyRequiredParameterIsFilled, defaultQueryParameters} = await getTemplateConfig();

            const getReportData = async () => {
                let data;
                if (everyRequiredParameterIsFilled && !extendedConfigFetchError) {
                    // Запрашиваем данные, если обязательных параметров нет, или они все заполнены
                    const fetchResponse = await fetchTableReportData({
                        templateCode,
                        ...dataParams,
                        ...(!isInitialRequest ? {} : {
                            parameters: {...defaultQueryParameters, ...dataParams.parameters},
                        }),
                    });
                    data = fetchResponse.data;
                }
                return data;
            };

            const reportData = await getReportData();

            return {
                data: reportData,
                config,
                everyRequiredParameterIsFilled,
                defaultQueryParameters,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);
