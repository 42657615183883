import React from 'react';

import {SimpleActionButton} from 'components/form/components';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {tableReportSliceActions} from 'store/slices/table-report-slice/table-report-slice';
import {selectTableReportComparisonOptions} from 'store/slices/table-report-slice/table-report-slice-selectors';

interface ComparisonButtonProps {}

export const ComparisonButton: React.FC<ComparisonButtonProps> = () => {
    const tableReportComparisonOptions = useAppSelector(selectTableReportComparisonOptions);
    const {isComparisonFormVisible} = tableReportComparisonOptions;

    const {
        updateComparisonOptions,
    } = tableReportSliceActions;

    const dispatch = useAppDispatch();

    return (
        <SimpleActionButton
            key={1}
            type="primary"
            onClick={() => {
                if (isComparisonFormVisible) {
                    dispatch(updateComparisonOptions({
                        isComparisonFormVisible: !isComparisonFormVisible,
                        includeComparison: !isComparisonFormVisible,
                        comparedToDocId: undefined,
                        includeCR: false,
                    }));
                    return;
                }

                dispatch(updateComparisonOptions({
                    isComparisonFormVisible: !isComparisonFormVisible,
                }));
            }}
            title={isComparisonFormVisible ? 'Отменить сравнение' : 'Добавить к сравнению'}
            icon={isComparisonFormVisible ? 'CloseXOutlined' : 'Comparison'}
        />
    );
};
