import {ToolOutlined} from '@ant-design/icons';
import {Alert, Button, Card} from 'antd';
import React from 'react';
import {useHistory} from 'react-router-dom';

import {ThunkRejectionError} from 'store/slices/loading-state-slice';

interface ThunkErrorScreenProps {
    error?: ThunkRejectionError;
}

export const ThunkErrorScreen: React.FC<ThunkErrorScreenProps> = ({error}: ThunkErrorScreenProps) => {
    const history = useHistory();

    return (
        <div>
            <div style={{margin: '0 0 12px 0'}}>
                <Button
                    type="primary"
                    onClick={() => {
                        history.go(-1);
                    }}
                >
                    Вернуться
                </Button>
            </div>
            <Alert
                type="error"
                message={(
                    <div>
                        <div className="d-flex">
                            <ToolOutlined style={{fontSize: 18, marginRight: 10}} />
                            Во время работы системы произошла ошибка.
                        </div>

                        <h4 style={{marginTop: 10}}>Информация об ошибке:</h4>
                        <div>Код ошибки: <b>{error?.status ?? 'неизвестно'}</b></div>
                        <div>Сообщение: {error?.statusText ?? 'неизвестно'}</div>

                        {error?.data && (
                            <Card style={{marginTop: 10}}>
                                <div>
                                    <pre>
                                        {error?.data}
                                    </pre>
                                </div>
                            </Card>
                        )}
                    </div>
                )}
            />
        </div>
    );
};
