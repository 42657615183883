import {Button, Spin} from 'antd';
import cn from 'classnames';
import moment from 'moment/moment';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {AIS_ROUTES} from 'components/ais/ais.constants';
import {CustomSelect} from 'components/form/inputs/custom-select';
import {AisMainPageDashboardProps} from 'pages/ais/ais-main-page/ais-main-page-types';
import {ReactComponent as FileFolderSvg} from 'shared/assets/ais/file-folder.svg';
import {DATE_DMY} from 'shared/constants/date-format';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {AisDashboardCode} from 'store/slices/ais-slice/ais-dashboards/ais-dashboards-constants';
import {
    selectRegisterDocumentsDashboardData,
} from 'store/slices/ais-slice/ais-dashboards/ais-dashboards-selectors';
import {
    getAisRegisterDocuments,
} from 'store/slices/ais-slice/ais-dashboards/ais-dashboards-thunks';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

import {formatNumberWithSpaces, generateYearSelectionForDashboard} from '../../ais-main-page.utils';
import {AisDashboardCard} from '../@common/ais-dashboard-card';

import './ais-register-dashboard.less';

interface AisRegisterDashboardState {
    year?: number;
    counterparty?: string;
    hasAvailableFNS?: boolean;
}

export const AisRegisterDashboard: React.FunctionComponent<AisMainPageDashboardProps> = ({
    context,
}: AisMainPageDashboardProps) => {
    const dispatch = useAppDispatch();
    const history = useHistory();

    const [register, setRegister] = useState<AisRegisterDashboardState>({});
    const {elements} = useAppSelector(selectRegisterDocumentsDashboardData) || {};
    const currentDate = moment()?.format(DATE_DMY);
    const {taxPeriodId, organizationId} = context || {};

    useEffect(() => {
        if (!taxPeriodId && !organizationId) return;
        const {year, counterparty, hasAvailableFNS} = register || {};
        dispatch(getAisRegisterDocuments({
            code: AisDashboardCode.DOC_JOURNAL,
            year,
            counterparty,
            hasAvailableFNS,
            taxPeriodId,
            organizationId,
        }));
    }, [register, taxPeriodId, organizationId]);

    const isLoading = useAppSelector(s => selectIsThunkPending(s, getAisRegisterDocuments.typePrefix));

    return (
        <AisDashboardCard
            title="Реестр документов"
            style={{flexBasis: 0}}
            footer={(
                <div className={cn('ais-register-dashboard__footer')}>
                    <div>
                        По состоянию на:
                        <span className={cn('text-gray-600')}>
                            {' '}{currentDate}
                        </span>
                    </div>
                    <div>
                        <Button
                            size="small"
                            type="default"
                            onClick={() => history.push(AIS_ROUTES.DOCUMENTS_REGISTER)}
                        >Перейти
                        </Button>
                    </div>
                </div>
            )}
        >
            <div className={cn('ais-register-dashboard')}>
                <div className={cn('ais-register-dashboard__body')}>
                    <div className="ais-register-dashboard__body__svg">
                        <FileFolderSvg className="ais-register-dashboard__body__svg__file-folder-svg" />
                    </div>
                    <div>
                        <div className="d-flex gap-1">
                            <div>
                                <div className="text-size-14 d-inline mb-1">Год</div>
                                <CustomSelect
                                    value={register?.year}
                                    onChange={v => {
                                        setRegister({
                                            ...register,
                                            year: v,
                                        });
                                    }}
                                    entries={generateYearSelectionForDashboard().map(year => ({
                                        value: year,
                                        label: `${year}`,
                                    }))}
                                    settings={{
                                        placeholder: 'Год',
                                        isClearable: true,
                                    }}
                                />
                            </div>
                            <div>
                                <div className="text-size-14 d-inline mb-1">Контрагент</div>
                                <CustomSelect
                                    value={register?.counterparty}
                                    settings={{
                                        showSearch: true,
                                        url: '/ais/valueLists/REGISTER_DOCUMENTS_COUNTERPARTY',
                                        labelPath: 'meaning',
                                        valuePath: 'lookupCode',
                                        placeholder: 'Контрагент',
                                        isClearable: true,
                                    }}
                                    onChange={v => {
                                        setRegister({
                                            ...register,
                                            counterparty: v,
                                        });
                                    }}
                                />
                            </div>
                            <div>
                                <div className="text-size-14 d-inline mb-1">Доступно ФНС</div>
                                <CustomSelect
                                    entries={[
                                        {label: 'Не выбрано', value: undefined},
                                        {label: 'Да', value: 'Y'},
                                        {label: 'Нет', value: 'N'},
                                    ]}
                                    value={register?.hasAvailableFNS}
                                    onChange={v => {
                                        setRegister({
                                            ...register,
                                            hasAvailableFNS: v,
                                        });
                                    }}
                                    settings={{
                                        placeholder: 'Не выбрано',
                                        isClearable: true,
                                    }}
                                />
                            </div>
                        </div>
                        <Spin
                            spinning={isLoading}
                            tip="Загрузка..."
                        >
                            <div
                                className={cn('d-flex mt-2 ml-2 gap-2')}
                                style={{height: 90}}
                            >
                                {elements?.map((el => (
                                    <div className={cn('ais-register-dashboard__stats')}>
                                        <div className={cn('ais-register-dashboard__stats__title')}>
                                            {el?.meaning}
                                        </div>
                                        <div className={cn('ais-register-dashboard__stats__value')}>
                                            {formatNumberWithSpaces(el?.count)}
                                        </div>
                                    </div>
                                )))}
                            </div>
                        </Spin>
                    </div>
                </div>
            </div>
        </AisDashboardCard>
    );
};
