import React, {useContext} from 'react';

import {TIconType} from 'components/dynamic-icon/dynamic-icon';
import {
    SimpleActionButton,
} from 'components/form/components';
import {RequestCardContext} from 'components/request/replies/context/request-card-context';
import {ButtonType} from 'modules/metadata/metadata-types';

interface EditingFormButtonProps {
    buttonType?: ButtonType;
    actionIcon?: TIconType;
    text?: string;
    onSubmit?: () => void;
}

export const EditingAndSaveFormButton = ({
    actionIcon,
    buttonType,
    text,
    onSubmit,
}: EditingFormButtonProps) => {
    const {isEditing, setEditing} = useContext(RequestCardContext);
    const handleClick = () => {
        if (onSubmit) onSubmit();
    };
    return (
        <>
            <SimpleActionButton
                disabled={!isEditing}
                type={buttonType || 'primary'}
                htmlType="submit"
                icon="Save"
                onClick={handleClick}
                title="Сохранить"
            />
            <SimpleActionButton
                disabled={isEditing}
                type={buttonType || 'primary'}
                onClick={setEditing}
                icon={actionIcon}
                title={text || 'Редактировать'}
            />
        </>

    );
};
