import {Card, Modal, Popover} from 'antd';
import React, {useState} from 'react';

import {DynamicIcon} from 'components/dynamic-icon';

import './open-report-configuration-history-modal.less';

const mockData = [
    {
        changedBy: 'Ананасий Ананасов',
        changedAt: '12.04.2023 12:41:44',
    },
    {
        changedBy: 'Администратор',
        changedAt: '12.04.2023 12:41:44',
    },
];

interface OpenReportConfigurationHistoryModalProps {
    templateName?: string;
}

export const OpenReportConfigurationHistoryModal = ({templateName}: OpenReportConfigurationHistoryModalProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <Popover
                overlayClassName="open-report-configuration-history-modal__popover"
                placement="left"
                content={() => 'Просмотреть историю изменений'}
            >
                <DynamicIcon
                    type="ClockOutlined"
                    className="open-report-configuration-history-modal__icon"
                    onClick={() => setIsModalOpen(true)}
                />
            </Popover>
            <Modal
                title={(
                    <>
                        История изменений шаблона отчета&nbsp;
                        <span style={{color: '#A9A9A9'}}>
                            &quot;{templateName}&quot;
                        </span>
                    </>
                )}
                visible={isModalOpen}
                footer={null}
                onOk={() => setIsModalOpen(false)}
                onCancel={() => setIsModalOpen(false)}
                width={900}
            >
                <Card bordered={false} >
                    {
                        mockData.map(entry => (
                            <React.Fragment key={entry.changedBy}>
                                <div className="history-modal__line">
                                    <span>Кто создал:</span>
                                    <span>{entry.changedBy}</span>
                                </div>
                                <div className="history-modal__line">
                                    <span>Когда изменил:</span>
                                    <span>{entry.changedAt}</span>
                                </div>
                            </React.Fragment>
                        ))
                    }
                </Card>
            </Modal>
        </>
    );
};
