import {Input} from 'antd';
import React from 'react';

import {FieldMeta} from 'modules/metadata';
import {ReactComponent as Lock} from 'shared/assets/forms/lock.svg';
import {useGetAppPropertyQuery} from 'store/api/app-properties/app-properties.api';

interface InputStringProps {
    disabled: boolean;
    autoComplete: string;
    field?: FieldMeta;
    value: any;
    defaultValue?: string;
    propertyCodeValue?: string;
}

export const InputString: React.FunctionComponent<InputStringProps> = (
    {
        disabled,
        autoComplete,
        propertyCodeValue,
        field,
        value,
        ...props
    }: InputStringProps,
) => {
    const {data: valuePropertyCode} = useGetAppPropertyQuery(
        {propertyCode: propertyCodeValue}, {skip: !propertyCodeValue},
    );

    const suffix = disabled ? <Lock /> : null;
    return (
        <Input
            disabled={disabled}
            suffix={suffix}
            autoComplete={autoComplete}
            defaultValue={field?.defaultValue?.toString()}
            value={value || valuePropertyCode}
            {...props}
        />
    );
};
