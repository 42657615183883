import {SaveOutlined, UndoOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import {FormInstance} from 'antd/es/form';
import cn from 'classnames';
import React, {useContext} from 'react';

import {ButtonChangeHistory} from 'components/report-configuration/components/button-change-history';
import {ReportConfigurationMessages} from 'components/report-configuration/constants/report-configuration-constants';
import {WatcherFieldFlag} from 'components/report-configuration/hooks/use-form-fields-manager';
import {ReportConfigurationTabKey} from 'components/report-configuration/report-configuration.constants';
import {ReportConfigurationContext} from 'components/report-configuration/report-configuration.context';
import {ActionConfirmModal} from 'components/table/action/action-confirm-modal/action-confirm-modal';
import {ReactComponent as ArrowBackOutlined} from 'shared/assets/arrow-back-outlined.svg';
import {ReactComponent as CloseXOutlined} from 'shared/assets/close-x-outlined.svg';
import {ReactComponent as EditWithLine} from 'shared/assets/edit-with-line.svg';
import {setFieldsValueUntouched} from 'shared/utils';

import {ReportTableOfContentsFormState} from '../report-table-of-contents.types';

export interface ReportTableOfContentsButtonsProps {
    form: FormInstance;
    formState: ReportTableOfContentsFormState;
    setFormState: React.Dispatch<React.SetStateAction<ReportTableOfContentsFormState>>;
    checkArrayIndependentWatcherFlag?: (key: string, flag: WatcherFieldFlag) => boolean;
    isClearButtonDisabled?: boolean;
    name: string;
}

export const ReportTableOfContentsButtons = ({
    form,
    formState,
    setFormState,
    checkArrayIndependentWatcherFlag,
    name,
}: ReportTableOfContentsButtonsProps) => {
    const {setSelectedTabKey, isCreatingNewTemplate} = useContext(ReportConfigurationContext);
    return (
        <div
            className={cn('report-properties-settings__buttons-bar')}
            style={{marginBottom: 0}}
        >
            <Button
                disabled={!formState?.isEditingForm}
                type="primary"
                htmlType="submit"
                onClick={() => {
                    form.submit();
                }}
                icon={<SaveOutlined />}
            >Сохранить
            </Button>
            {
                !formState.isEditingForm && (
                    <Button
                        type="primary"
                        icon={<EditWithLine />}
                        onClick={() => setFormState(v => ({...v, isEditingForm: !formState.isEditingForm}))}
                    >Редактировать
                    </Button>
                )
            }
            {formState.isEditingForm && (
                <Button
                    icon={<UndoOutlined />}
                    type="default"
                    onClick={() => {
                        form.resetFields();
                        setFormState(v => ({...v, isEditingForm: false}));
                    }}
                >Отменить
                </Button>
            )}
            {!isCreatingNewTemplate && (
                <ButtonChangeHistory />
            )}
            <ActionConfirmModal
                cancelText="Отменить"
                approveText="Очистить"
                modalTitle="Предупреждение"
                confirmModalContent={ReportConfigurationMessages.CLEAR_FORM_FIELDS_CONFIRM_TEXT}
                confirmAction={() => {
                    form.setFields([{name, value: []}]);
                    setFormState(v => ({...v, isDirty: true}));
                }}
                isNotInContainer
            >
                {
                    handleClick => (
                        <Button
                            type="default"
                            onClick={handleClick}
                            disabled={(checkArrayIndependentWatcherFlag && checkArrayIndependentWatcherFlag(
                                'buttonClearFormFields',
                                WatcherFieldFlag.isDisabled,
                            )) || !formState.isEditingForm}
                            icon={<CloseXOutlined />}
                        >
                            Очистить форму
                        </Button>
                    )
                }
            </ActionConfirmModal>
            <ActionConfirmModal
                cancelText="Вернуться к редактированию"
                approveText="Закрыть форму"
                modalTitle="Предупреждение"
                confirmModalContent={ReportConfigurationMessages.CLOSE_FORM_CONFIRM_TEXT}
                confirmAction={() => {
                    setFormState(v => ({...v, isEditingForm: false}));
                    setSelectedTabKey?.(ReportConfigurationTabKey.REPORT_CONFIGURATOR);
                    form.resetFields();
                    setFieldsValueUntouched(form, {[name]: formState.initialValues.reportMenu});
                }}
                skipToConfirmCallback={(() => !form.isFieldsTouched())}
                isNotInContainer
            >
                {
                    handleClick => (
                        <Button
                            type="default"
                            icon={<ArrowBackOutlined />}
                            onClick={handleClick}
                        >
                            Вернуться на главную вкладку
                        </Button>
                    )
                }
            </ActionConfirmModal>
        </div>
    );
};
