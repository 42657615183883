import {SvcButtons} from 'modules/metadata/metadata-types';

import {createLinkButtonPropsWithPreset} from '../../link-button';

export const createButtonProps = (buttons?: SvcButtons[]) => {
    const buttonsTop: any = [];
    const buttonsBottom: any = [];
    buttons?.forEach(button => {
        if (button.position === 'top') {
            buttonsTop.push(createLinkButtonPropsWithPreset({link: button.link, preset: button.preset}));
        } else buttonsBottom.push(createLinkButtonPropsWithPreset({link: button.link, preset: button.preset}));
    });
    return {buttonsTop, buttonsBottom};
};
