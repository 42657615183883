import {Button, Form, message} from 'antd';
import {useForm} from 'antd/lib/form/Form';
import cn from 'classnames';
import React, {
    useContext, useEffect, useRef, useState,
} from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {CustomSelectEntry} from 'components/form/inputs/custom-select/custom-select';
import {ButtonsBar} from 'components/report-configuration/components/buttons-bar';
import {ClickConfirmation} from 'components/report-configuration/components/click-confirmation';
import {
    ReportConfigurationFormName,
    ReportConfigurationTabKey,
    reportConfigurationFormTitles,
} from 'components/report-configuration/report-configuration.constants';
import {ReportConfigurationContext} from 'components/report-configuration/report-configuration.context';
import {transformSingleValidationResultWithError} from 'components/report-configuration/report-configuration.utils';
import {ReportTableCustomFields} from 'components/report-configuration/tabs/report-table-settings/components/report-table-custom-fields/report-table-custom-fields';
import {ReactComponent as TrashXOutlined} from 'shared/assets/trash-x.svg';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {
    selectReportConfigurationMenuReportAndCustomColumnsBySheetCode,
    selectReportConfigurationTableAttributesAsSelectEntries,
    selectTableReportConfigurationData,
} from 'store/slices/report-configuration-slice/report-configuration-slice';
import {
    ReportConfigurationAttribute,
    ReportConfigurationDataConverted,
} from 'store/slices/report-configuration-slice/report-configuration-types';
import {
    updateTableReportConfiguration,
} from 'store/slices/report-configuration-slice/report-configuration/report-configuration-thunks';

import {TableReportCreateSheetButton} from './components/create-sheet-button';
import {ReportTableSettingsFields} from './components/report-table-settings-fields/report-table-settings-fields';
import {TableSettingsFunctionalButtons} from './components/table-settings-functional-buttons';
import {useTableReportSettingsSheets} from './hooks/use-table-report-settings-sheets';
import {
    joinReportTableDataToMenuConfigurationData,
    mergeTableAttributesAndReportColumns,
} from './utils';

import './report-table-settings.less';

interface ReportTableSettingsProps {}

export type TableAttributesCustomSelectEntry = CustomSelectEntry<string, any> & Partial<ReportConfigurationAttribute>;

export const ReportTableSettings: React.FC<ReportTableSettingsProps> = () => {
    const dispatch = useAppDispatch();
    const [form] = useForm();

    const customColumnsRef = useRef<any>(null);

    const {
        setSelectedTabKey, entityName, templateCode, isCreatingNewTemplate,
        setValidationErrors,
    } = useContext(ReportConfigurationContext);

    const [isEditingForm, setIsEditingForm] = useState(false);

    const tableAttributesEntries = useAppSelector(
        selectReportConfigurationTableAttributesAsSelectEntries,
    );

    const reportConfigurationData = useAppSelector(selectTableReportConfigurationData);

    const {enabledMenu: isMenuEnabled} = reportConfigurationData ?? {};

    const [hiddenFields, setHiddenFields] = useState<Record<string, boolean>>({});

    const {
        sheets,
        setSheets,
        setSelectedSheet,
        selectedSheet,
        removeSheet,
    } = useTableReportSettingsSheets();

    const {
        reportColumns: reportColumnsForSelectedSheetCode,
        customColumns: customColumnsForSelectedSheetCode,
    } = useAppSelector(
        s => selectReportConfigurationMenuReportAndCustomColumnsBySheetCode(s, selectedSheet),
    ) ?? {};

    const reportColumns = (() => {
        if (!selectedSheet) {
            return mergeTableAttributesAndReportColumns(
                tableAttributesEntries ?? [],
                (reportConfigurationData?.reportColumns ?? []),
            );
        }
        return mergeTableAttributesAndReportColumns(
            tableAttributesEntries ?? [],
            reportColumnsForSelectedSheetCode ?? [],
        );
    })();

    // custom columns
    const customColumns = (() => {
        if (!selectedSheet) {
            return reportConfigurationData?.customColumns;
        }
        return customColumnsForSelectedSheetCode;
    })();

    const [initialValues, setInitialValues] = useState<
        Pick<ReportConfigurationDataConverted, 'reportTableColumns'>>({
            reportTableColumns: {
                reportColumns,
                customColumns,
            },
        });

    const [isCustomColumnsDisplaying, setCustomColumnsDisplaying] = useState<boolean>(false);

    useEffect(() => {
        if (reportConfigurationData && tableAttributesEntries) {
            setInitialValues({
                reportTableColumns: {
                    reportColumns,
                    customColumns,
                },
            });
        }
    }, [tableAttributesEntries, reportConfigurationData, selectedSheet]);

    const hideFields = () => {
        if (initialValues?.reportTableColumns?.reportColumns?.length
            || initialValues?.reportTableColumns?.customColumns?.length) {
            const reportColumnsHiddenFields = initialValues?.reportTableColumns?.reportColumns
                ?.map(columnReport => (columnReport?.hidden));
            const customColumnsHiddenFields = initialValues?.reportTableColumns?.customColumns
                ?.map(columnReport => (columnReport?.hidden));
            const initialHiddenFields = [
                ...(reportColumnsHiddenFields ?? []),
                ...(customColumnsHiddenFields ?? []),
            ];
            const hiddenColumnsObject = (() => {
                const object: Record<number, boolean> = {};
                initialHiddenFields.forEach((value, index) => {
                    if (index && value) object[index] = value;
                });
                return object;
            })();
            setHiddenFields(hiddenColumnsObject);
        }
    };

    useEffect(() => {
        if (initialValues) {
            form.resetFields();
        }
        hideFields();
    }, [initialValues]);

    const isDataPresent = !!(reportColumns?.length);
    const isDisabled = isDataPresent && !isEditingForm;

    const handleCancelEdit = () => {
        form.resetFields();
        hideFields();
        setValidationErrors?.({});
        setIsEditingForm(false);
    };

    const handleBack = async () => {
        setSelectedTabKey?.(ReportConfigurationTabKey.REPORT_CONFIGURATOR);
    };

    const handleFinish = async (values: Pick<ReportConfigurationDataConverted, 'reportTableColumns'>) => {
        setValidationErrors?.({});
        if (templateCode) {
            const reportMenu = reportConfigurationData?.reportMenu;

            if (selectedSheet) {
                const customColumnsValues = (values?.reportTableColumns?.customColumns?.length ?? -1) > 0
                    ? values?.reportTableColumns?.customColumns
                    : undefined;
                const reportMenuToBeUpdated = joinReportTableDataToMenuConfigurationData({
                    dataToJoin: {
                        reportColumns: values?.reportTableColumns?.reportColumns,
                        customColumns: customColumnsValues,
                    },
                    reportMenu,
                    sheetCode: selectedSheet,
                });
                dispatch(updateTableReportConfiguration({
                    templateCode,
                    data: {
                        reportMenu: reportMenuToBeUpdated,
                    },
                }));
            } else {
                dispatch(updateTableReportConfiguration({
                    templateCode,
                    data: {
                        reportColumns: values?.reportTableColumns?.reportColumns,
                        customColumns: values?.reportTableColumns?.customColumns,
                    },
                }));
            }
        }
        setIsEditingForm(false);
    };

    const handleFinishFailed = (error: any) => {
        setValidationErrors?.(transformSingleValidationResultWithError(error));
        message.error(`Пожалуйста, проверьте корректность ввода данных в таблице
        «${reportConfigurationFormTitles[ReportConfigurationFormName.TABLE_SETTINGS]}»`, 2);
    };

    return (
        <div className={cn('report-table-settings')}>
            <ButtonsBar
                entityName={entityName}
                templateCode={templateCode}
                isCreating={isCreatingNewTemplate}
                isCreatingDisabled={(!isEditingForm && isDataPresent)}
                isEditing={isEditingForm}
                setIsEditing={setIsEditingForm}
                hideEditButton={!isDataPresent}
                hideDeleteButton
                onSubmit={() => {
                    form.submit();
                }}
                onBack={handleBack}
                onCancelEdit={handleCancelEdit}
                extraButtons={{
                    position: 'left',
                    buttons: [
                        <TableReportCreateSheetButton
                            disabled={!isEditingForm}
                            selectedSheets={sheets}
                            addNewSheets={entry => {
                                setSheets(v => ([...v, {...entry}]));
                                setSelectedSheet(entry.value);
                            }}
                            clearCustomColumns={() => customColumnsRef?.current?.removeAllAndHide()}
                        />,
                    ],
                }}
            />
            <div className={cn('report-table-settings__section-title')} />
            <div className={cn('report-table-settings__body')}>
                <div className={cn('report-table-settings__controls')}>
                    {isMenuEnabled && !!sheets.length && (
                        <div className={cn('report-table-settings__controls__select')}>
                            <CustomSelect
                                value={selectedSheet}
                                onChange={value => {
                                    customColumnsRef?.current?.removeAllAndHide();
                                    setSelectedSheet(value);
                                }}
                                entries={sheets}
                                settings={{
                                    isClearable: true,
                                    showSearch: true,
                                    placeholder: 'Выберите лист',
                                }}
                            />
                        </div>
                    )}

                    {selectedSheet && (
                        <ClickConfirmation labels={{
                            main: 'Вы действительно хотите удалить этот лист?',
                        }}
                        >
                            <Button
                                disabled={!isEditingForm}
                                type="primary"
                                onClick={() => {
                                    if (templateCode) {
                                        const reportMenuToBeUpdated = joinReportTableDataToMenuConfigurationData({
                                            dataToJoin: {
                                                reportColumns: undefined,
                                                customColumns: undefined,
                                            },
                                            reportMenu: reportConfigurationData?.reportMenu,
                                            sheetCode: selectedSheet,
                                        });
                                        dispatch(updateTableReportConfiguration({
                                            templateCode,
                                            data: {
                                                reportMenu: reportMenuToBeUpdated,
                                            },
                                        }));
                                        if (selectedSheet) removeSheet(selectedSheet);
                                        setSelectedSheet(undefined);
                                    }
                                }}
                                icon={<TrashXOutlined />}
                            >Удалить лист
                            </Button>
                        </ClickConfirmation>

                    )}

                    <TableSettingsFunctionalButtons
                        form={form}
                        disabled={isDisabled}
                        customColumnsRef={customColumnsRef}
                    />

                </div>
                <Form
                    initialValues={initialValues}
                    layout="vertical"
                    form={form}
                    onFinish={handleFinish}
                    onFinishFailed={handleFinishFailed}
                >
                    <div
                        className={cn(
                            'report-table-settings__body__section',
                            'main-columns-section',
                        )}
                    >
                        <div className="report-table-settings__body__section-title">
                            Основные атрибуты
                        </div>
                        <ReportTableSettingsFields
                            form={form}
                            isDisabled={isDisabled}
                            name={['reportTableColumns', 'reportColumns']}
                            isHidden={!!selectedSheet}
                            hiddenFields={hiddenFields}
                            setHiddenFields={setHiddenFields}
                        />
                    </div>
                    <div
                        className={cn(
                            'report-table-settings__body__section',
                            'custom-columns-section',
                        )}
                        style={{display: isCustomColumnsDisplaying ? 'block' : 'none'}}
                    >
                        <div className="report-table-settings__body__section-title">
                            Кастомные атрибуты
                        </div>
                        <ReportTableCustomFields
                            ref={customColumnsRef}
                            form={form}
                            isDisabled={isDisabled}
                            name={['reportTableColumns', 'customColumns']}
                            isHidden={!!selectedSheet}
                            hiddenFields={hiddenFields}
                            setHiddenFields={setHiddenFields}
                            isCustomColumnsDisplaying={isCustomColumnsDisplaying}
                            setCustomColumnsDisplaying={setCustomColumnsDisplaying}
                        />
                    </div>
                </Form>
            </div>
        </div>
    );
};
