import {FormInstance} from 'antd/es/form';
import {Moment} from 'moment';
import {ReactText} from 'react';

import {FormMode} from 'components/form';
import {ICheckboxOption} from 'components/form/inputs/checkbox-group';
import {EntityType} from 'shared/constants/entities';

import {ContextRawData} from '../context/context-types';
import {DocumentSaveInfoFile} from '../documents';
import {IDefaultFilters, IReferencePath, RequestType} from '../metadata/metadata-types';

export interface DataState {
    [key: string]: EntityDataState;
}

export interface EntityDataState {
    selectedSubsection: string;
    loading?: boolean;
    isError?: boolean;
    isEditable?: boolean;
    FORM_FIELDS_DATA?: AdditionalOptionsData;
    PROPERTY_DATA?: PropertyData;
    [EntityType.TABLE]: TableEntityData;
    [EntityType.FORM_TABLE]: FormTableEntityData;
    [EntityType.FORM]: FormEntityData;
    [EntityType.EDIT_FORM]: FormEntityData;
    [EntityType.TABS]: DirectoryEntityData;
    [EntityType.TABS_WITH_FORM]: DirectoryFormEntityData;
    [EntityType.FILTER]: FilterEntityData;
    [EntityType.CONTEXT_FILTER]: ContextRawData;
    [EntityType.TABLE_STATE]: TableStateEntityData;
    [EntityType.SEARCH]: SearchEntityData;
    [EntityType.TABS_WITH_REFERENCES]: EntityData;
    [EntityType.REFERENCE]: ReferenceEntityData;
    [EntityType.NUMBER]: NumberEntityData;
    [EntityType.EDITABLE_TABLE]: ModalEntityData;
    [EntityType.REPORT]: ReportEntityData;
    [EntityType.JASPER_REPORT]: JasperReportEntityData;
    [EntityType.LIST]: ListEntityData;
    [EntityType.LIST_GRID]: ListGridEntityData;
    [EntityType.MODAL]: { fileList: DocumentSaveInfoFile[]; headers: string };
    [EntityType.SUBSECTION_TABLE]: SubsectionTableEntityData;
    [EntityType.DASHBOARD]: DashboardEntityData;
    [EntityType.TABLE_REPORT]: EntityData;
    [EntityType.WIDGETS]: EntityData;
}

export interface EntityData {
}

export interface ReferenceEntityData extends EntityData {
    rows?: Entity[];
    placeholder?: string;
}

export interface NumberEntityData extends EntityData {
    [key: string]: number;
}
export interface IComparison {
    sheet: string;
    addition: string;
    comparisonAttributes: string[];
    differenceAttributes: string[];

}

export interface TableEntityData extends EntityData {
    limit: number;
    offset: number;
    query?: string;
    page: number;
    ids?: string[];
    rows?: Entity[];
    initialRows?: Entity[];
    editRows?: Entity[];
    params?: Record<string, any>;
    contextParameters?: TableEntityBodyParameters;
    selectedRows?: Entity[];
    selectedRowKeys?: (string | number)[];
    publishDocumentIds?: string[];
    isConfirmed?: boolean;
}

export interface ModalEntityData extends EntityData {
    limit: number;
    offset: number;
    query?: string;
    page: number;
    rows?: Entity[];
    params?: Record<string, any>;
    contextParameters?: TableEntityBodyParameters;
    selectedRows?: Entity[];
    selectedRowKeys?: (string | number)[];
}

export interface DefaultTableState {
    sorter: {
        field?: ReactText;
        order?: ReactText;
    };
    pagination: {
        current?: number;
        pageSize?: number;
        // Приходит с сервера
        total?: number;
    };
}

export interface DefaultTableStateFlat {
    paginationPageSize?: number;
    paginationCurrent?: number;
    paginationTotal?: number;
    sorterField?: ReactText;
    sorterOrder?: ReactText;
}

export interface FormTableEntityData extends TableEntityData {
}

export interface SubsectionTableEntityData extends TableEntityData {
}

export interface ReportEntityData extends TableEntityData {
}

export interface JasperReportEntityData extends TableEntityData {
}

export interface DirectoryEntityData extends EntityData {
    tab?: string;
    rows?: Entity[];
}

export interface DirectoryFormEntityData extends EntityData {
    tab?: string;
    tabs?: Entity[];
}

export type TableEntityBodyParameters = Record<string, any>;

export interface FormEntityData extends EntityData {
    id: string;
    data: Entity & {
        settingDocumentTemplateMappingDtoList?: ICheckboxOption[];
        propertyValue: {
            id: number;
            levelCode: string;
            levelValue: number;
            propertyCode: string;
            propertyValue: string;
            version: number;
        };
        disabledFormFlag: boolean;
        disabledFormMessage: string;
    };
    initialData: Entity & {
        settingDocumentTemplateMappingDtoList?: ICheckboxOption[];
        propertyValue: {
            id: number;
            levelCode: string;
            levelValue: number;
            propertyCode: string;
            propertyValue: string;
            version: number;
        };
        disabledFormFlag: boolean;
        disabledFormMessage: string;
    };
    formInstance?: FormInstance;
    isEditable?: boolean;
    formMode?: FormMode;
    entityId?: string;
    formUsedAdditionalOptionId?: number;
    programSetPrograms?: [];
}

export interface ListEntityData {
    id: string;
    data: Entity;
}

export interface ListGridEntityData extends ListEntityData {
    lists: Record<string, Entity>[];
}

export interface StatisticsEntry {
    key: string;
    value: number;
    label?: string;
}

export interface DashboardEntry {
    dataTable: StatisticsEntry[];
    recordsTotal?: number;
}

export interface DashboardEntityData extends EntityData {
    [key: string]: DashboardEntry;
}

export interface FilterEntityData extends EntityData {
    data: Entity;
    loading?: boolean;
}

export interface SearchEntityData extends EntityData {
    data: Entity;
}

export interface TableStateEntityData extends EntityData {
    data: Entity;
}

export interface PropertyData {
    notificationDuration?: number;
}

export interface AdditionalOptionsData {
    options: {
        [key: string]: any[] | undefined;
    };

    [key: string]: {
        formUsedAdditionalOptionId?: number;
    };
}

export interface CreateActionGetFile {
    referenceUrl?: string;
    requestType?: RequestType;
    data?: any;
    isMessage?: boolean;
    linkedEntityName?: string;
    body?: Record<string, any>;
    name?: string;

}

export type Entity = Record<string, EntityValue>;

export type EntityValue =
    string
    | number
    | boolean
    | Date
    | Moment
    | File
    | FileList
    | IDefaultFilters[]
    | IComparison[]
    | undefined
    | null
    | Record<string, any>

export type FileList = FileInfo[];

export type FileInfo = {
    attachmentId: number;
    objectId: number;
    fileName: string;
    signatureFileName: string;
    loaSignFileName: string;
    fileSize: number;
    outputFileSize: string;
    fileHashCode: string;
}

export type DataSetPayload = {
    data: EntityData;
    entityName: string;
    entityType: EntityType;
    loading?: boolean;
    isEditable?: boolean;
    entityId?: string;
    isError?: boolean;
};

export type DataSetFormModePayload = {
    entityName: string;
    entityType: EntityType;
    formMode?: FormMode;
}

export interface SetDocumentScansDataPayload {
    entityName: string;
    entityType: EntityType | undefined;
    docId: string;
    scans: FileInfo[];
}

export interface RemoveDocumentScansPayload {
    entityName: string;
    entityType: EntityType | undefined;
    docId: string;
    attachmentsIds: string[];
}

export type DataSetIsEditablePayload = {
    entityName: string;
    entityType: EntityType;
    isEditable?: boolean;
}

export type SetFilterLoadingPayload = {
    entityName: string;
    loading: boolean;
}

export type DataResetPayload = {
    entityName: string;
    entityType: string;
    loading?: boolean;
}
export type ResetInitialTableDataArgs = {
    entityName: string;
    entityType: string;
    loading?: boolean;
}

export type DataSetInitialDataPayload = {
    entityName: string;
    isEditable?: boolean;
}

export type UpdateFormDataPayload = {
    entityName: string;
    data?: Record<string, any>;
}

export type ResetFormDataPayload = {
    entityName: string;
}

export type SetSelectedSubsection = {
    selectedSubsection: string;
}

export type SetFormInstancePayload = {
    entityName: string;
    form: FormInstance;
}

export type SetUsedAdditionalOptionIdPayload = {
    entityName: string;
    entityNameChild: string;
    optionId: string | number;
}

export type SetUsedAdditionalOptionsPayload = {
    data: EntityData | undefined;
    entityName: string;
    entityType: string;
    loading?: boolean;
};

export type ResetUsedAdditionalOptionIdPayload = {
    entityName: string;
}

export type LookupValue<LookupCode = string> = {
    id: number;
    meaning: string;
    lookupCode?: LookupCode;
    lookupType?: string;
    attribute1?: string;
}

export type DataRequestTableParams = Partial<{}>

export type DataResponseTable = Entity[];

export type DataResponseDirectory = Entity[];

export type optionsTableType = {
    useContext?: boolean;
    useSearch?: boolean;
    paramsToBeConverted?: DataRequestTableParams;
    params?: DataRequestTableParams;
    filterParamKey?: string;
    disabledPagination?: boolean;
    sectionCode?: string;
    docTypeId?: string;
}

export type optionsTabsType = {
    useContext?: boolean;
}

export interface IReferenceResolverParams {
    referenceUrl?: string;
    path?: IReferencePath;
}

export interface IDeleteFormValue {
    isDelete: boolean;
    referenceUrl?: string;
    ids?: Entity;
}

export enum userIdUrl {
    news = 'news',
    settingsUser = 'settings.user.settings.mailing',
    rolesAndOrganizations = 'settings.user.settings.roles.and.organizations',
}

export enum PropertyCode {
    REQUIRED_EDS_WHEN_DOC_PUBLISH = 'REQUIRED_EDS_WHEN_DOC_PUBLISH',
    REQUIRED_LOA_SGN_WHEN_DOC_PUBLISH = 'REQUIRED_LOA_SGN_WHEN_DOC_PUBLISH',
    PUBLIC = 'PUBLIC',
    DELAYED_PUBLICATION = 'DELAYED_PUBLICATION'
}

export interface DatePickerState {
    [id: string]: boolean;
}
