import {createSelector} from '@reduxjs/toolkit';
import {get} from 'lodash';

import {AppState} from 'store/config/types';

import {tableReportSliceName} from '../table-report-slice-constants';
import {
    SelectTableReportUploadAttachmentsProgressDataArgs,
    TableReportUploadAttachmentsFilesProgressItem,
} from './table-report-row-attachments-types';

const rootSelector = (state: AppState) => state[tableReportSliceName];

export const selectTableReportUploadAttachmentsProgressDataState = createSelector(rootSelector,
    ({uploadAttachmentsProgress}) => uploadAttachmentsProgress);

export const selectTableReportUploadAttachmentsProgressData = (
    {
        reportRowId,
        templateCode,
        docId,
    }: SelectTableReportUploadAttachmentsProgressDataArgs,
) => createSelector(rootSelector,
    ({uploadAttachmentsProgress}) => {
        if (docId) return get(uploadAttachmentsProgress, ['documents', templateCode, docId, reportRowId]);
        return get(uploadAttachmentsProgress, ['templates', templateCode, reportRowId]);
    });

export const selectTableReportUploadAttachmentsFilesProgress = createSelector(rootSelector,
    /*
        Комментарий:
        -- Селектор превращает весь объект uploadAttachmentsProgress в массив,
        где каждый элемент это прогресс отправки POST-запроса на редактирование комментария к строке.
        При этом это только такие запросы, в которых были отправлены на загрузку файлы.
        (Фактически можно трекать прогресс любого запроса (даже без файлов) и отображать пользователю,
            но поскольку запросы без файлов выполняются быстро, можно этим пренебречь.)
    */
    ({uploadAttachmentsProgress}) => {
        const filesUploadProgressList: TableReportUploadAttachmentsFilesProgressItem[] = [];

        const templates = uploadAttachmentsProgress?.templates;
        if (templates) {
            Object.keys(templates).forEach(templateCode => {
                const rows = templates[templateCode];
                if (rows) {
                    Object.keys(rows).forEach(reportRowId => {
                        const progressData = uploadAttachmentsProgress.templates[templateCode][reportRowId];
                        if (progressData && progressData.files?.length) {
                            filesUploadProgressList.push({
                                templateCode,
                                reportRowId,
                                uploadData: progressData,
                            });
                        }
                    });
                }
            });
        }

        const templatesWithDocuments = uploadAttachmentsProgress?.documents;
        if (templatesWithDocuments) {
            Object.keys(templatesWithDocuments).forEach(templateCode => {
                const documents = templatesWithDocuments[templateCode];
                if (documents) {
                    Object.keys(documents).forEach(docId => {
                        const rows = documents[docId];
                        if (rows) {
                            Object.keys(rows).forEach(reportRowId => {
                                const progressData = rows[reportRowId];
                                if (progressData && progressData.files?.length) {
                                    filesUploadProgressList.push({
                                        templateCode,
                                        reportRowId,
                                        docId,
                                        uploadData: progressData,
                                    });
                                }
                            });
                        }
                    });
                }
            });
        }

        return filesUploadProgressList;
    });

export const selectDocIdsToRequest = createSelector(
    rootSelector,
    ({selectedDocIds}) => selectedDocIds,
);

export const tableReportRowAttachmentsSelectors = {
    selectTableReportUploadAttachmentsFilesProgress,
    selectTableReportUploadAttachmentsProgressData,
    selectTableReportUploadAttachmentsProgressDataState,
    selectDocIdsToRequest,
};
