import cn from 'classnames';
import React, {CSSProperties, ReactElement} from 'react';

import './ais-dashboard-card.less';

interface AisDashboardCardProps {
    children: any;
    title: string;
    style?: CSSProperties;
    className?: cn.Argument;
    bodyClassName?: cn.Argument;
    footer?: ReactElement;
}

export const AisDashboardCard: React.FC<AisDashboardCardProps> = (
    {
        title, children, style, bodyClassName, className, footer,
    }: AisDashboardCardProps,
) => (
    <div
        className={cn('ais-dashboard-card', className)}
        style={style}
    >
        <div className={cn('ais-dashboard-card__title')}>{title}</div>
        <div className={cn('ais-dashboard-card__body', bodyClassName)}>{children}</div>
        {footer && <div className={cn('ais-dashboard-card__footer')}>{footer}</div>}
    </div>
);
