import React, {useEffect} from 'react';

import {
    loadContextData,
    loadContextLookups,
    resetContext,
} from 'modules/context/context-actions';
import {useAppDispatch} from 'store/config/hooks';

import {ContextFilterPreview} from './context-filter-preview';
import {CONTEXT_RESET_EVENT_NAME} from './context-filter.constants';

interface ContextFilterProps {
    onChange?: (values: Record<string, any>) => any;
    isDisabled?: boolean;
}

export const ContextFilter: React.FC<ContextFilterProps> = ({
    onChange,
    isDisabled,
}: ContextFilterProps) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const resetHandler = () => {
            dispatch(resetContext());
            dispatch(loadContextLookups());
            dispatch(loadContextData());
        };

        window.addEventListener(CONTEXT_RESET_EVENT_NAME, resetHandler);
        return (() => {
            window.removeEventListener(CONTEXT_RESET_EVENT_NAME, resetHandler);
        });
    }, []);

    return (
        <ContextFilterPreview
            onChange={onChange}
            isDisabled={isDisabled}
        />
    );
};
