import {FormInstance} from 'antd/es/form';
import {FormListFieldData} from 'antd/es/form/FormList';
import {
    useContext, useEffect, useRef, useState,
} from 'react';

import {ReportConfigurationContext} from 'components/report-configuration/report-configuration.context';
import {FormListOperationsRef} from 'components/report-configuration/report-configuration.types';
import {FUNCTION_CODES} from 'components/report-configuration/tabs/report-table-settings/modals/security-settings-modal/constants/security-settings-modal-constants';

interface UseFormatFormItemProps {
    name: number;
    field: FormListFieldData;
    fields: FormListFieldData[];
    form: FormInstance;
}

export const useFormatFormItem = ({
    name, field, fields, form,
}: UseFormatFormItemProps) => {
    const conditionsRef = useRef<FormListOperationsRef | null>(null);
    const {templateCode} = useContext(ReportConfigurationContext);

    const currentFieldPath = [
        'reportTableColumns', 'reportColumns', name, 'blockedForRoleAndFunctionNames', field.name, 'conditions',
    ];
    const functionFieldPath = [
        'reportTableColumns', 'reportColumns', name, 'blockedForRoleAndFunctionNames', field.name, 'functionName',
    ];

    const [areConditionsHidden, setAreConditionsHidden] = useState(true);
    const [fieldsCount, setFieldsCount] = useState<number | undefined>(
        form.getFieldValue(currentFieldPath)?.length,
    );

    const shouldDisplayDescriptionField = form.getFieldValue(
        functionFieldPath,
    ) === FUNCTION_CODES.FORBID_TO_FOLLOW_LINK;
    const shouldDisplayConnectionRules = form.getFieldValue(
        functionFieldPath,
    ) === FUNCTION_CODES.FORBID_TO_WATCH_DD;

    useEffect(() => {
        setFieldsCount(form.getFieldValue(currentFieldPath)?.length);
    }, [fields]);

    useEffect(() => {
        setAreConditionsHidden(true);
        setFieldsCount(0);
    }, [shouldDisplayConnectionRules]);

    return {
        fieldsCount,
        templateCode,
        conditionsRef,
        setFieldsCount,
        currentFieldPath,
        functionFieldPath,
        areConditionsHidden,
        setAreConditionsHidden,
        shouldDisplayConnectionRules,
        shouldDisplayDescriptionField,
    };
};
