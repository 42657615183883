import cn from 'classnames';
import React from 'react';

import {Breadcrumb, BreadcrumbItem} from './breadcrumb';
import {useLocationStateBreadcrumbs} from './hooks';

import './breadcrumbs.less';

interface BreadcrumbsProps {
    breadcrumbs?: BreadcrumbItem[];
    className?: string;
    onClickCallback?: BreadcrumbItem['onClickCallback'];
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
    breadcrumbs: initialBreadcrumbs,
    className,
    onClickCallback,
}: (BreadcrumbsProps)) => {
    const breadcrumbs = useLocationStateBreadcrumbs({breadcrumbs: initialBreadcrumbs});

    if (!breadcrumbs) return null;

    const isLast = (index: number) => index === breadcrumbs?.length - 1;

    return (breadcrumbs?.length > 0 ? (
        <div className={cn('breadcrumbs', className)}>
            {breadcrumbs?.map((breadcrumb, index) => (
                <Breadcrumb
                    key={breadcrumb.id}
                    id={breadcrumb.id}
                    name={breadcrumb.name}
                    path={breadcrumb.path}
                    extraData={breadcrumb.extraData}
                    isLast={isLast(index)}
                    onClickCallback={onClickCallback}
                />
            ))}
        </div>
    ) : null);
};

export const MemoizedBreadcrumbs = React.memo(Breadcrumbs);
