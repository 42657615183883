import {FieldType} from 'modules/metadata';
import {TMetaOption} from 'modules/metadata-constructor/metadata-constructor.types';

import {COMMON_FIELDS_ITEMS} from '../common/common.fields-items';
import {TABS_FIELDS} from './tabs.fields-for-type-fields';
import {TABS_OPTIONS} from './tabs.options';

export const METADATA_TABS: TMetaOption = {
    fields: [
        {
            key: 'titleField',
            label: 'Заголовок вкладки',
            type: FieldType.STRING,
            hint: 'Название поля, значение которого используется как заголовок для вкладки.',
            isRequired: false,
        },
        {
            key: 'fields',
            label: 'Поля формы',
            type: FieldType.LIST,
            dataKeyForFilter: 'type',
            defaultItems: [
                COMMON_FIELDS_ITEMS.FIELDS.KEY,
                COMMON_FIELDS_ITEMS.FIELDS.LABEL,
                COMMON_FIELDS_ITEMS.FIELDS.IS_HIDDEN,
                {
                    key: 'type',
                    label: 'Тип',
                    type: FieldType.STATIC_SELECT,
                    options: TABS_OPTIONS.FIELD_TYPES,
                    isRequired: true,
                },
            ],
            customItems: TABS_FIELDS,
        },
    ],
};
