import {SafetyCertificateOutlined} from '@ant-design/icons';
import cn from 'classnames';
import moment from 'moment';
import React from 'react';

import {DATE_WITH_TIME_DOTS_SECONDS_12} from 'shared/constants/date-format';
import {CertificateExtended} from 'shared/utils/crypto-pro';
import './crypto-pro-signature.less';

interface CryptoProSignatureProps {
    certificate?: CertificateExtended;
}

export const CryptoProSignature: React.FC<CryptoProSignatureProps> = (
    {certificate}: CryptoProSignatureProps,
) => {
    const {validFrom, validTo} = certificate?.certificate ?? {};

    const validFromDate = validFrom && new Date(validFrom);
    const validToDate = validTo && new Date(validTo);

    const validFromFormatted = validFrom ? moment(validFromDate).format(DATE_WITH_TIME_DOTS_SECONDS_12) : '--';
    const validToFormatted = validTo ? moment(validToDate).format(DATE_WITH_TIME_DOTS_SECONDS_12) : '--';

    const isValid = (() => {
        if (!validFromDate || !validToDate) return undefined;
        return new Date() <= validToDate && new Date() >= validFromDate;
    })();

    return (
        <div className={cn('crypto-pro-signature')}>
            <div className={cn('fw-500')}>
                <SafetyCertificateOutlined className="mr-1" />
                Информация о сертификате
            </div>

            <div>
                Статус:
                <span className={cn('crypto-pro-signature__status', {
                    'crypto-pro-signature__status_is-valid': isValid,
                })}
                >
                    {isValid ? 'Действителен' : 'Недействителен'}
                </span>
            </div>

            <div className="mt-1">
                <div>Криптопровайдер: {certificate?.providerName}</div>
                <div>Алгоритм ключа: {certificate?.algorithmName}</div>
                <div>Издатель: {certificate?.certificate.issuerName}</div>
                <div>Выдан: {validFromFormatted}</div>
                <div>Действителен до: {validToFormatted}</div>
                {certificate?.owner.map(tag => (
                    <div
                        key={tag.title}
                    >
                        {tag.title}: {tag.description}
                    </div>
                ))}
            </div>
        </div>
    );
};
