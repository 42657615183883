import {Menu} from 'antd';
import React from 'react';

export const SCALES = [
    75,
    100,
    125,
    150,
    175,
    200,
];

export const BREADCRUMBS = [
    {
        id: '1',
        contextId: '12',
        name: 'Налог на прибыль: Декларация',
        path: '/tax.accounting.income.declaration',
        currpage: '0',
        params: '',
        isLast: false,
    },
    {
        id: '2',
        contextId: '13',
        name: 'Декларация по налогу на прибыль (версия 5.11)',
        path: '/tax.accounting.income.declaration/101/dm_profit_decl_511',
        currpage: '0',
        params: '',
        isLast: true,
    },
];

export const MENU = (
    <Menu>
        <Menu.Item>item 1</Menu.Item>
        <Menu.Item>item 2</Menu.Item>
    </Menu>
);

// pagination
export const defaultPageSizeOptions = ['5', '10', '20', '50'];
export const defaultPageSize = 20;
export const defaultPageSizeParameters = {page: 0, size: defaultPageSize, lookupType: 'MENU_KEY'};
