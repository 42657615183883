import {List as AntList} from 'antd';
import React from 'react';

import {Entity} from 'modules/data';
import {FieldType} from 'modules/metadata';
import {EntityMeta} from 'modules/metadata/metadata-types';

import {ListItem} from '../../list/list-item';
import {getListValue} from '../../list/utils/list-utils';

export interface ListGridItemProps {
    data: Entity;
    meta: EntityMeta;
    showTitle?: boolean;
}

export const ListGridItem = ({data, meta, showTitle = true}: ListGridItemProps) => {
    const title = showTitle ? data[meta.titleField || 'id'] : undefined;
    return (
        <div className="list-grid__item">
            {title && <div className="list-grid__item-title">{title}</div>}
            <AntList>
                {meta?.fields.map(fieldMeta => {
                    const {key, type, stringStructure} = fieldMeta;
                    if (fieldMeta.type === FieldType.ICON_LINK) {
                        return (
                            <div key={key}>
                                <ListItem
                                    meta={fieldMeta}
                                />
                            </div>
                        );
                    }
                    const value = getListValue(key, type, stringStructure, data);
                    if (!value) return null;
                    return (
                        <div key={key}>
                            <ListItem
                                meta={fieldMeta}
                                value={value}
                            />
                        </div>
                    );
                })}
            </AntList>
        </div>
    );
};
