import React from 'react';
import {useSelector} from 'react-redux';

import {ActionButton} from 'components/table/action/action-button';
import {TableActionProps} from 'components/table/action/table-action-types';
import {selectEntityData} from 'modules/data';
import {TableEntityData} from 'modules/data/data-types';
import {EntityType} from 'shared/constants/entities';
import {showMessageFromResponse} from 'shared/utils';
import {useLazyGetAdditionalFieldsQuery} from 'store/api/form-additional-options/form-additional-options';
import {useAppDispatch} from 'store/config/hooks';
import {AppState} from 'store/config/types';
import {openModal} from 'store/slices/modals-slice';

import {EMPTY_FIELD_MESSAGE} from './open-document-xml-modal.constants';

interface OpenDocumentXmlModalProps extends TableActionProps {
}

export const OpenDocumentXmlModal: React.FunctionComponent<OpenDocumentXmlModalProps> = ({
    meta,
    wrapper,
    entityName,
    ...props
}: OpenDocumentXmlModalProps) => {
    const {modalName, referenceUrl} = meta;
    const {selectedRowKeys = []} = useSelector((state: AppState) => (
        selectEntityData(entityName,
            EntityType.TABLE)(state) || {}
    ) as TableEntityData);

    const id = selectedRowKeys.length && selectedRowKeys[0];

    const [trigger] = useLazyGetAdditionalFieldsQuery();

    const dispatch = useAppDispatch();
    const onClick = async () => {
        try {
            if (id && referenceUrl) {
                const response = await trigger({referenceUrl, id}).unwrap();
                if (modalName && response && response.length) {
                    dispatch(openModal({
                        modalName,
                        entityName: meta?.modalEntityName || entityName,
                        parentEntityName: entityName,
                    }));
                } else if ((response && !response.length) || !response) {
                    showMessageFromResponse({response: undefined, isError: true, customMessage: EMPTY_FIELD_MESSAGE});
                }
            }
        } catch (e) {
            console.error(e);
        }
    };
    const Component = wrapper || ActionButton;
    return (
        <Component
            {...props}
            onClick={onClick}
            actionIcon={meta.actionIcon}
            disabled={!id}
        >
            {meta.actionTitle}
        </Component>
    );
};
