import isNumber from 'lodash/isNumber';

import {REFERENCE_TYPE_MENU_ITEM, ReferenceTypeMenuItemSectionCode} from 'shared/constants/layout';
import {MenuStructure} from 'store/slices/menu-slice/menu-slice-types';

export interface MenuNavLink{
    url: string;
    icon?: string;
}
export const splitUrl = (urlToSplit: string, separator: string, currentEntityName: string): string | null => {
    const arrayOfPaths = urlToSplit.split(separator);
    let pathLocation = null;
    arrayOfPaths.forEach((path: string) => {
        if (path !== currentEntityName && !isNumber(path) && path !== '') {
            pathLocation = path;
        }
    });
    return pathLocation;
};
export const removeFileExtension = (filename?: string) => {
    if (!filename) return undefined;
    return filename.replace(/\.\w+$/, '');
};

export const getMenuNavLinks = (menuStructure: MenuStructure | null) => {
    const headersNavLinkLists: MenuNavLink[] = [];
    if (!menuStructure) return [];
    menuStructure.forEach(menuItem => {
        if (
            REFERENCE_TYPE_MENU_ITEM === menuItem?.itemType
            && (ReferenceTypeMenuItemSectionCode.DIADOC_CODE === menuItem?.parameters?.sectionCode
            || ReferenceTypeMenuItemSectionCode.EDISOFT_CODE === menuItem?.parameters?.sectionCode
            )
        ) {
            headersNavLinkLists.push({
                url: menuItem?.url,
                icon: removeFileExtension(menuItem?.icon?.toString()),
            });
        }
    });
    return headersNavLinkLists;
};

const ENTITY_NAMES_TO_PICK_LAST_FROM_URL = [
    'settings.user.settings',
    'directories.common.topic.settings',
    'administration.settings.programs',
    'administration.security',
    'administration.settings.drill.down',
];

export const TABLE_REPORT_ENTITY_NAME = 'table-report';

export const defineEntityName = (entityName: string, currentLocation: string | null, url: string) => {
    if (ENTITY_NAMES_TO_PICK_LAST_FROM_URL.includes(entityName)) return currentLocation || entityName;
    if (url?.match(RegExp(`^\\/${entityName}\\/(-?\\d+)`))) return TABLE_REPORT_ENTITY_NAME;
    return entityName;
};
