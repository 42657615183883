export enum ERequestStatuses {
    ANSWER_PROLONGATION = 'ANSWER_PROLONGATION_REQUEST',
    APPROVE = 'APPROVE_REQUEST',
    CLOSE_REQUEST = 'CLOSE_REQUEST',
    MARK_AS_READ = 'MARK_AS_READ_REQUEST',
    REJECT = 'REJECT_REQUEST',
    /**
     * Ответить
     */
    REPLY = 'REQUEST_RESPONSE_SEND',
    SEND_FOR_APPROVAL = 'SEND_FOR_APPROVAL_REQUEST',
    TAKE_TO_WORK = 'TAKE_TO_WORK_REQUEST',
}
