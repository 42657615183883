import {Button} from 'antd';
import {ButtonType} from 'antd/lib/button';
import React from 'react';

import {DynamicIcon, TIconType} from '../../../dynamic-icon';

interface ButtonConfirmSaveOwnProps {
    actionTitle: string;
    actionIcon?: TIconType;
    buttonType: ButtonType;
    handleOpenConfirmModal?: () => void;
}
export const ButtonConfirmSave = ({
    actionTitle,
    actionIcon,
    buttonType,
    handleOpenConfirmModal,
    ...props
}: ButtonConfirmSaveOwnProps) => {
    const handleClick = () => {
        if (handleOpenConfirmModal) {
            handleOpenConfirmModal();
        }
    };

    return (
        <>
            <Button
                {...props}
                onClick={handleClick}
                type={buttonType}
                className="save-action-btn"
            >
                {actionIcon && <DynamicIcon type={actionIcon} />}
                {actionTitle}
            </Button>
        </>
    );
};
