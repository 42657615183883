import {message} from 'antd';
import Form from 'antd/es/form';
import {useForm} from 'antd/es/form/Form';
import {UploadChangeParam} from 'antd/lib/upload';
import {UploadFile} from 'antd/lib/upload/interface';
import {debounce} from 'lodash';
import React from 'react';
import {v4 as uuid} from 'uuid';

import {UploadDropzone} from 'components/@common/inputs/upload-dropzone';
import {ColumnFormatterProps} from 'components/table/table-types';
import {FileInfo} from 'modules/data';
import {setDocumentScans} from 'modules/data/data-actions';
import {fetchDocumentScansById, uploadAttachedToDocument} from 'modules/documents/documents-api';
import {EntityType} from 'shared/constants/entities';
import {useAppDispatch} from 'store/config/hooks';
import {UserRoleFunction} from 'store/slices/user-slice/user-slice-role-functions';

import {UploadScanFilesItem} from './upload-scan-files-item';

interface UploadScanFilesModalProps extends ColumnFormatterProps {
    entityName: string;
    entityType: EntityType | undefined;
    hasUserRoleFunction: (func: UserRoleFunction) => boolean;
}

export const UploadScanFilesModal: React.FC<UploadScanFilesModalProps> = (
    {
        record,
        entityType,
        entityName,
        hasUserRoleFunction,
    }: UploadScanFilesModalProps,
) => {
    const dispatch = useAppDispatch();
    const [form] = useForm();

    const scanDocumentAttachments = (record?.scanDocumentAttachments ?? []) as FileInfo[];

    const handleUploadFiles = debounce(async (files: UploadChangeParam<UploadFile<any>>) => {
        if (files.fileList && record.id) {
            const uploadAttachedPromises = files.fileList
                .filter((file: UploadFile) => !!file.originFileObj)
                .map((file: UploadFile) => uploadAttachedToDocument({
                    docId: record.id as string,
                    file: file.originFileObj as File,
                }));

            const messageKey = uuid();

            message.loading({
                content: <span className="ml-1">Загрузка файлов-приложений...</span>,
                key: messageKey,
                duration: 0,
            });

            try {
                await Promise.all(uploadAttachedPromises);

                const scans = (await fetchDocumentScansById({docId: record.id as string})).data;

                dispatch(setDocumentScans({
                    entityName,
                    entityType,
                    scans,
                    docId: record.id as string,
                }));

                message.success({
                    content: <span className="ml-1">Файлы успешно загружены</span>,
                    key: messageKey,
                    duration: 2,
                });
            } catch (e) {
                message.error({
                    content: <span className="ml-1">Ошибка загрузки файлов</span>,
                    key: messageKey,
                    duration: 2,
                });
            }
        }
    }, 0);

    return (
        <div className="d-flex gap-2 flex-column">
            <div className="d-flex gap-1 flex-column">
                {scanDocumentAttachments.map(scan => (
                    <UploadScanFilesItem
                        key={scan.attachmentId}
                        scan={scan}
                        docId={record.id as string}
                        {...{entityName, entityType, hasUserRoleFunction}}
                    />
                ))}
            </div>

            {hasUserRoleFunction(UserRoleFunction.UPLOAD_PDF) && (
                <Form
                    layout="vertical"
                    form={form}
                >
                    <Form.Item name="files">
                        <UploadDropzone
                            clearAfterChange
                            handleOnChange={handleUploadFiles}
                        />
                    </Form.Item>
                </Form>
            )}
        </div>
    );
};
