import {createAsyncThunk} from '@reduxjs/toolkit';

import {DocJournalData} from 'components/widget/items/documents-publication-pie-chart/doc-journal-pie-chart';
import {NewsData} from 'components/widget/items/news/news';
import {CorrNum, NobComponent} from 'components/widget/items/nob-components-bar-chart/nob-components-bar-chart';
import {RequestsTableData} from 'components/widget/items/request-table/request-table';

import {
    FetchDocJournalDataArgs,
    FetchNobComponentsCorrNumListArgs,
    FetchNobComponentsData,
    FetchRequestsDataArgs,
    fetchDocJournalData,
    fetchNews,
    fetchNobComponentsCorrNumList,
    fetchNobComponentsData,
    fetchRequestsData,
} from './widgets-slice-api';
import {widgetsSliceName} from './widgets-slice-constants';

export const loadRequestsData = createAsyncThunk<
    RequestsTableData, FetchRequestsDataArgs
    >(`${widgetsSliceName}/loadRequestData`, async (args, {rejectWithValue}) => {
        try {
            const response = await fetchRequestsData(args);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    });

export const loadDocJournalData = createAsyncThunk<
    DocJournalData[], FetchDocJournalDataArgs
    >(`${widgetsSliceName}/loadDocJournalData`, async (args, {rejectWithValue}) => {
        try {
            const response = await fetchDocJournalData(args);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    });

export const loadNobComponentsCorrNumList = createAsyncThunk<
    CorrNum[], FetchNobComponentsCorrNumListArgs
    >(`${widgetsSliceName}/loadNobComponentsCorrNumList`, async (args, {rejectWithValue}) => {
        try {
            const response = await fetchNobComponentsCorrNumList(args);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    });

export const loadNobComponentsData = createAsyncThunk<
    NobComponent[], FetchNobComponentsData
    >(`${widgetsSliceName}/loadNobComponentsData`, async (args, {rejectWithValue}) => {
        try {
            const response = await fetchNobComponentsData(args);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    });

export const loadNews = createAsyncThunk<
    NewsData[], void
    >(`${widgetsSliceName}/loadNews`, async (_, {rejectWithValue}) => {
        try {
            const response = await fetchNews();
            return response.data.dataTable;
        } catch (e) {
            return rejectWithValue(e);
        }
    });
