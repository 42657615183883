import {Button} from 'antd';
import React from 'react';
import {Link} from 'react-router-dom';

import {DynamicIcon} from 'components/dynamic-icon';
import {TableActionProps} from 'components/table/action';

interface ButtonRedirectOwnProps extends TableActionProps {
}

export const ButtonRedirect = ({meta}: ButtonRedirectOwnProps) => {
    const {actionIcon, actionTitle, referenceUrl} = meta;
    return (
        <Link
            className="button-redirect"
            to={referenceUrl}
        >
            <Button className="button-redirect__table-action">
                <DynamicIcon
                    className="table-action__svg-icon"
                    type={actionIcon}
                />
                {actionTitle}
            </Button>
        </Link>
    );
};
