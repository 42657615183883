import Form, {FormInstance} from 'antd/es/form';
import {FormListOperation} from 'antd/es/form/FormList';
import React, {useContext} from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {URL_TABLE_REPORT_MENU_ITEMS} from 'components/report-configuration/report-configuration.constants';
import {ReportConfigurationContext} from 'components/report-configuration/report-configuration.context';
import {StateSetter} from 'shared/types/generics';
import {useAppSelector} from 'store/config/hooks';
import {selectTableReportConfigurationData} from 'store/slices/report-configuration-slice';

import {ReportComparision} from '../report-comparison-types';

interface ReportComparisonSelectSheetProps {
    add: FormListOperation['add'];
    form: FormInstance;
    selectedSheetCode: string | undefined;
    setSelectedSheetCode: StateSetter<string | undefined>;
}

export const ReportComparisonSelectSheet: React.FC<ReportComparisonSelectSheetProps> = (
    {
        add,
        form,
        selectedSheetCode,
        setSelectedSheetCode,
    }: ReportComparisonSelectSheetProps,
) => {
    const reportConfigurationData = useAppSelector(
        selectTableReportConfigurationData,
    );

    const {
        templateCode,
    } = useContext(ReportConfigurationContext);

    return (
        <Form.Item
            style={{width: 362}}
        >
            <CustomSelect
                optionsLabelFormatter={(label, value) => {
                    if (reportConfigurationData
                        ?.reportComparisons
                        ?.map(rc => rc?.sheetCode)
                        .includes(value?.lookupCode)) {
                        return (
                            <span className="fw-500">
                                {label}
                            </span>
                        );
                    }
                    return label;
                }}
                value={selectedSheetCode}
                settings={{
                    showSearch: true,
                    url: URL_TABLE_REPORT_MENU_ITEMS,
                    referenceUrlQueryParams: {
                        sublistCode: templateCode || '',
                    },
                    labelPath: 'meaning',
                    valuePath: 'lookupCode',
                    placeholder: 'Выберите лист',
                    isClearable: true,
                }}
                onChange={value => {
                    setSelectedSheetCode(value);
                    if (value) {
                        const comparisions = form
                            .getFieldValue('reportComparisons');
                        if (!comparisions
                            .map((c: ReportComparision) => c
                                ?.sheetCode)
                            .includes(value)) {
                            const rc: ReportComparision = {
                                sheetCode: value,
                            };
                            add(rc);
                        }
                    }
                }}
            />
        </Form.Item>
    );
};
