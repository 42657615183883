import {
    TCDemandKndCode,
    TCProcessingFileNames,
    TCProcessingKndCode,
} from 'components/ais/transport-container-card/constants/transport-container-card.contants';
import {TransportContainerDescription} from 'store/api/transport-container/transport-container.api';

export const getCreateTransportContainerModalInitialValues = (
    tc: TransportContainerDescription,
    modalType: 'processing' | 'demand',
) => {
    const processingResultDocument = tc.processingResultDocument?.fileList[0];
    if (modalType === 'processing' && processingResultDocument?.fileName.startsWith(TCProcessingFileNames.processing)) {
        return {
            mainDocumentName: tc.processingResultDocument?.fileList[0]?.fileName,
            mainDocumentId: tc.processingResultDocument?.id,
            controlSupervisoryActivities: TCProcessingKndCode.toString(),
        };
    }
    if (modalType === 'processing' && processingResultDocument?.fileName.startsWith(TCProcessingFileNames.error)) {
        return {
            errorDocumentName: tc.processingResultDocument?.fileList[0]?.fileName,
            errorDocumentId: tc.processingResultDocument?.id,
            controlSupervisoryActivities: TCProcessingKndCode.toString(),
        };
    }

    // в качестве ответа на требование
    // нельзя отправить errorDocument поэтому есть смысл всегда подставлять документ требования
    if (modalType === 'demand' && tc.responseRequirementDocument) {
        return {
            mainDocumentName: tc.responseRequirementDocument.fileList[0]?.fileName,
            mainDocumentId: tc.responseRequirementDocument?.id,
            controlSupervisoryActivities: TCDemandKndCode.toString(),
        };
    }

    return undefined;
};

export const resolveProcessingResultCreateTCMode = (transportContainer: TransportContainerDescription) => {
    const processingFile = transportContainer.processingResultDocument?.fileList[0];
    if (processingFile?.fileName.startsWith(TCProcessingFileNames.error)) {
        return 'errorFileUpload';
    }
    return 'processingFileUpload';
};
