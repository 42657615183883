import React from 'react';
import {useDispatch} from 'react-redux';

import {useAppSelector} from 'store/config/hooks';
import {
    closeModal, selectModalData,
} from 'store/slices/modals-slice';

import {AppModalsMapping} from './modal-constants';

import './modal-root.less';

export const ModalRoot: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const modalData = useAppSelector(selectModalData);
    const onClose = () => dispatch(closeModal());
    if (!modalData.modalName) {
        return null;
    }
    const ModalComponent = AppModalsMapping[modalData.modalName];
    return (
        <ModalComponent
            onClose={onClose}
            parameters={modalData.modalParameters}
        />
    );
};
