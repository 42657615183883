import {PlusCircleOutlined} from '@ant-design/icons';
import {
    Badge, Button, Form, Input, Tooltip,
} from 'antd';
import {FormInstance} from 'antd/es/form';
import {FormListFieldData} from 'antd/es/form/FormList';
import cn from 'classnames';
import {xor} from 'lodash';
import React from 'react';

import {CheckboxWithToggle} from 'components/@common/specific/сheckbox-with-toggle/checkbox-with-toggle';
import {CustomSelect, CustomSelectMode} from 'components/form/inputs/custom-select';
import {StateSetter} from 'shared/types/generics';

import {FormatItemConditions} from '../../../row-formats-modal-opener/row-formats-modal-content/format-item-conditions';
import {useFormatFormItem} from './hooks/use-format-form-item';
import {getLookupCodeValue} from './utils/format-form.item.utils';

interface FormatFormItemProps {
    name: number;
    fields: FormListFieldData[];
    field: FormListFieldData;
    selectedRows: number[];
    setSelectedRows: StateSetter<number[]>;
    form: FormInstance;
}

export const FormatFormItem = ({
    setSelectedRows,
    selectedRows,
    field,
    fields,
    form,
    name,
}: FormatFormItemProps) => {
    const {
        fieldsCount,
        templateCode,
        conditionsRef,
        setFieldsCount,
        currentFieldPath,
        areConditionsHidden,
        setAreConditionsHidden,
        shouldDisplayConnectionRules,
        shouldDisplayDescriptionField,
    } = useFormatFormItem({
        name, field, fields, form,
    });

    return (
        <div className={cn('security-settings-modal__form__main')}>
            <div className={cn('security-settings-modal__form__row__top')}>
                <CheckboxWithToggle
                    selected={selectedRows.includes(field.name)}
                    onToggleSelect={() => setSelectedRows(xor(selectedRows, [field.name]))}
                    fieldsCount={fieldsCount}
                    areConditionsHidden={areConditionsHidden}
                    setAreConditionsHidden={setAreConditionsHidden}
                />
                <Form.Item
                    rules={[{required: true}]}
                    label="Выберите роль"
                    name={[field.name, 'roleNames']}
                    style={{width: 392}}
                >
                    <CustomSelect
                        settings={{
                            url: 'lookupValue/ALL_ROLES',
                            valuePath: 'lookupCode',
                            labelPath: 'meaning',
                            placeholder: 'Выберите роль',
                            mode: CustomSelectMode.multiple,
                            isClearable: true,
                            formInstance: form,
                        }}
                    />
                </Form.Item>
                <Form.Item
                    rules={[{required: true}]}
                    label="Выберите функцию"
                    name={[field.name, 'functionName']}
                    style={{width: 392}}
                >
                    <CustomSelect
                        settings={{
                            url: 'lookupValue/LIMIT_FUNCTIONS',
                            valuePath: 'lookupCode',
                            labelPath: 'meaning',
                            placeholder: 'Выберите функцию',
                        }}
                    />
                </Form.Item>
                <Form.Item label={<>&nbsp;</>}>
                    <Tooltip
                        title="Добавить условие"
                        placement="right"
                        mouseEnterDelay={0.8}
                    >
                        <Badge
                            count={fieldsCount}
                            size="small"
                        >
                            <Button
                                type="primary"
                                className="btn-only-icon"
                                disabled={shouldDisplayConnectionRules}
                                onClick={() => {
                                    conditionsRef.current?.add();
                                    setFieldsCount(prev => (prev ?? 0) + 1);
                                    setAreConditionsHidden(false);
                                }}
                            >
                                <PlusCircleOutlined />
                            </Button>
                        </Badge>
                    </Tooltip>
                </Form.Item>
            </div>
            <div className={cn('security-settings-modal__form__row__bottom')}>
                <div className={cn('security-settings-modal__form__row__bottom__line')} />
                <div className={cn(
                    'security-settings-modal__form__row__bottom__conditions',
                    {'security-settings-modal__form__row__bottom__conditions_hidden': areConditionsHidden},
                )}
                >
                    <Form.List
                        name={[field.name, 'conditions']}
                    >
                        {(conditionsFields, conditionsListOperations) => (
                            <FormatItemConditions
                                form={form}
                                currentFieldPath={currentFieldPath}
                                ref={conditionsRef}
                                conditionsFields={conditionsFields}
                                conditionsListOperations={conditionsListOperations}
                                setFieldsCount={setFieldsCount}
                            />
                        )}
                    </Form.List>
                </div>
            </div>
            <div className={cn('security-settings-modal__form__row__top')}>
                {
                    shouldDisplayConnectionRules && (
                        <Form.Item
                            rules={[{required: true}]}
                            label="Правила связи"
                            name={[field.name, 'drillDownIds']}
                            style={{width: 837, marginLeft: 'auto'}}
                        >
                            <CustomSelect
                                settings={{
                                    url: getLookupCodeValue(templateCode),
                                    valuePath: 'id',
                                    labelPath: 'meaning',
                                    placeholder: 'Выберите правило',
                                    isClearable: true,
                                    mode: CustomSelectMode.multiple,
                                }}
                            />
                        </Form.Item>
                    )
                }
                {
                    !shouldDisplayConnectionRules && shouldDisplayDescriptionField && (
                        <Form.Item
                            rules={[{required: true}]}
                            label="Сообщение в подсказке"
                            name={[field.name, 'description']}
                            style={{width: 837, marginLeft: 'auto'}}
                        >
                            <Input placeholder="Введите сообщение в подсказке" />
                        </Form.Item>
                    )
                }
            </div>
        </div>
    );
};
