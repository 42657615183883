import {createSelector} from '@reduxjs/toolkit';

import {AppState} from 'store/config/types';
import {authSliceName} from 'store/slices/auth-slice/auth-slice-constants';

const selectAuthSliceState = (state: AppState) => state[authSliceName];

export const selectAuthToken = createSelector(
    selectAuthSliceState,
    ({token}) => token,
);

export const selectAuthError = createSelector(
    selectAuthSliceState,
    ({error}) => error,
);

export const selectAuthErrorUser = createSelector(
    selectAuthSliceState,
    ({userName}) => userName,
);

export const selectAuthPasswordExpired = createSelector(
    selectAuthSliceState,
    ({isPasswordExpired}) => isPasswordExpired,
);

export const selectAuthPasswordSuccessfullyChanged = createSelector(
    selectAuthSliceState,
    ({isPasswordSuccessfullyChanged}) => isPasswordSuccessfullyChanged,
);

export const selectUserInfo = createSelector(
    selectAuthSliceState,
    ({userInfo}) => userInfo,
);

export const selectUserId = createSelector(
    selectAuthSliceState,
    ({userInfo}) => userInfo?.userId,
);

export const selectUseTwoFAVerificationFlag = createSelector(
    selectAuthSliceState,
    ({useTwoFAVerificationFlag}) => useTwoFAVerificationFlag,
);

export const selectAuthProfile = createSelector(
    selectAuthSliceState,
    ({profile}) => profile,
);

export const authSelectors = {
    selectAuthSliceState,

    selectAuthToken,
    selectAuthError,
    selectAuthErrorUser,
    selectAuthPasswordSuccessfullyChanged,
    selectUserInfo,
    selectUserId,
    selectUseTwoFAVerificationFlag,
    selectAuthProfile,
};
