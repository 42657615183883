export enum FormButtonWatcherActions {
    disable = 'disable', // сделать кнопку disabled
    hide = 'hide', // скрыть кнопку
}

export enum FormButtonWatcherConditions {
    unset = 'unset', // если target-поле имеет falsy-значение (false, undefined, null, "", 0 и т.д.)
}

export interface FormButtonWatcherCondition {
    target?: string; // ключ поля, которое отслеживается watcher'ом
    when: FormButtonWatcherConditions; // событие для срабатывания условия
    withValue?: string; // значение target поля, чтобы watcher сработал
    withKey?: string; // используется, если when === ("equalsToValue" или "fieldEqualsToValue")
    withConnective?: FormButtonWatcherConnective; // принцип, по которому условие добавляется к предыдущему
    withValueAsNumber?: boolean;

}

export enum FormButtonWatcherConnective {
    OR = 'OR',
    AND = 'AND',
    NOT = 'NOT',
    AND_NOT = 'AND_NOT',
    OR_NOT = 'OR_NOT'
}

export interface FormButtonWatcher {
    conditions: FormButtonWatcherCondition[]; // настройка условий, при которых срабатывает watcher
    perform: FormButtonWatcherActions[]; // действия при срабатывании
}
