import {createAsyncThunk} from '@reduxjs/toolkit';

import {RpcDeleteResponse} from 'shared/types/rpc';

import {reportConfigurationSliceName} from '../report-configuration-constants';
import {ReportConfigurationDataDto} from '../report-configuration-dto';
import {
    deleteDrillDownParameterRequest,
    DeleteDrillDownParameterRequestArgs,
    DrillDownParameterPostRequestArgs,
    fetchDDChildReportQueryParameters,
    FetchDDChildReportQueryParametersArgs,
    fetchDrillDownParameters,
    FetchDrillDownParametersArgs,
    postDrillDownOarameter,
} from './dd-parameters-configuration-api';
import {ReportDdParameter} from './dd-parameters-configuration-types';

export const updateDrilldownParameter = createAsyncThunk<
    ReportDdParameter,
    DrillDownParameterPostRequestArgs
>(
    `${reportConfigurationSliceName}/updateDrilldownParameter`, async (args, {rejectWithValue}) => {
        try {
            const result = await postDrillDownOarameter(args);
            return result.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const deleteDrilldownParameter = createAsyncThunk<
    RpcDeleteResponse, DeleteDrillDownParameterRequestArgs>(
        `${reportConfigurationSliceName}/deleteDrilldownParameter`, async (args, {rejectWithValue}) => {
            try {
                const result = await deleteDrillDownParameterRequest(args);
                return result.data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

export const loadDrilldownParameters = createAsyncThunk<ReportDdParameter[], FetchDrillDownParametersArgs>(
    `${reportConfigurationSliceName}/loadDrilldownParameters`, async (args, {rejectWithValue}) => {
        try {
            const {data: reportDdParameters} = await fetchDrillDownParameters(args);
            return reportDdParameters;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const loadDDChildReportQueryParameters = createAsyncThunk<{
    queryParameters: ReportConfigurationDataDto['queryParameters'];
}, FetchDDChildReportQueryParametersArgs>(
    `${reportConfigurationSliceName}/loadDDChildReportQueryParameters`, async (args, {rejectWithValue}) => {
        try {
            const {data: reportDdParameters} = await fetchDDChildReportQueryParameters(args);
            return reportDdParameters;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);
