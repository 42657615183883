import {Modal} from 'antd';
import React from 'react';

import {ReactComponent as CloseIcon} from 'shared/assets/close.svg';
import {ModalComponentInterface} from 'store/slices/modals-slice';

import {List} from '../list';

import './list-modal.less';

interface FormModalProps extends ModalComponentInterface {
    entityName: string;
}

export const ListModal: React.FunctionComponent<FormModalProps> = ({
    onClose,
    entityName,
}: FormModalProps) => (
    <Modal
        className="list-modal"
        footer={null}
        visible
        centered
        width={680}
        onCancel={onClose}
        maskClosable={false}
        closeIcon={<CloseIcon className="list-modal__close-icon" />}
    >
        <List
            entityName={entityName}
        />
    </Modal>
);
