import {Dispatch} from 'redux';

import {
    CONTEXT_FILTER_LOOKUPS,
} from 'components/context-filter/context-filter.constants';
import {ErrorMessages} from 'shared/constants/messages';
import {showMessageFromResponse} from 'shared/utils';
import {createAction} from 'store/config/creators';

import {fetchContextData, fetchContextLookupValues, postContextData} from './context-api';
import {CONTEXT_ACTIONS} from './context-constants';
import {
    ContextRawData,
    ContextSetLookupsPayload,
    ContextSetRawDataPayload,
    ContextUpdateRawDataPayload,
} from './context-types';

export const setContextRawData = createAction<ContextSetRawDataPayload>(
    CONTEXT_ACTIONS.SET_CONTEXT_RAW_DATA,
);

export const updateContextRawData = createAction<ContextUpdateRawDataPayload>(
    CONTEXT_ACTIONS.UPDATE_CONTEXT_RAW_DATA,
);

export const setContextLookups = createAction<ContextSetLookupsPayload>(
    CONTEXT_ACTIONS.SET_CONTEXT_LOOKUPS,
);

export const addToFetchedWithContext = createAction<string>(
    CONTEXT_ACTIONS.ADD_TO_FETCHED_WITH_CONTEXT,
);

export const setEntityNamesFetchedWithContext = createAction<string[]>(
    CONTEXT_ACTIONS.SET_FETCHED_WITH_CONTEXT,
);

export const resetContext = createAction(CONTEXT_ACTIONS.RESET_CONTEXT);

export const loadContextLookups = (
    keysToLoad?: (keyof ContextRawData)[],
) => async (dispatch: Dispatch) => {
    const lookupFetchRequests: Promise<any>[] = [];

    Object.entries(CONTEXT_FILTER_LOOKUPS).forEach(([key, lookupType]) => {
        const keyToPickIdFromContextRawData = key as keyof ContextRawData;
        if (keysToLoad && !keysToLoad.includes(keyToPickIdFromContextRawData)) return;
        const fetchRequest = async () => {
            try {
                const {data: lookupValue} = await fetchContextLookupValues({
                    lookupType,
                });
                return [
                    key,
                    lookupValue,
                ];
            } catch (error: any) {
                return Promise.reject(new Error(error));
            }
        };
        lookupFetchRequests.push(fetchRequest());
    });

    try {
        const fetchResults = await Promise.allSettled(lookupFetchRequests);
        const fetchSuccessfulResults = fetchResults
            .filter(result => result.status === 'fulfilled')
            .map((result: any) => result.value);
        const keyToLookupValuesMapping = Object.fromEntries(fetchSuccessfulResults);

        dispatch(setContextLookups(keyToLookupValuesMapping));

        if (fetchResults.filter(entry => entry.status === 'rejected').length) {
            showMessageFromResponse({
                response: undefined,
                isError: true,
                customMessage: ErrorMessages.OPTIONS_CONTEXT_FILTER_ERR,
            });
        }

        return keyToLookupValuesMapping;
    } catch (error: any) {
        showMessageFromResponse({
            response: error,
            isError: true,
            customMessage: ErrorMessages.OPTIONS_CONTEXT_FILTER_ERR,
        });
    }

    return {};
};

export const loadContextData = () => async (dispatch: Dispatch) => {
    try {
        const contextRawData = (await fetchContextData())?.data;
        dispatch(setContextRawData({
            rawData: contextRawData,
        }));
        return contextRawData;
    } catch (e) {
        console.error(e);
        return null;
    }
};

export const saveContextData = (
    data?: Record<string, any>,
) => async () => postContextData(data);
