import {DocJournalPieChart} from 'components/widget/items/documents-publication-pie-chart';
import {News} from 'components/widget/items/news';
import {NobComponentsBarChart} from 'components/widget/items/nob-components-bar-chart';
import {RequestTable} from 'components/widget/items/request-table';

export const WIDGET_HALF_PADDING = 4;

export enum WidgetPageComponents {
    NEWS = 'NEWS',
    REQUEST_TABLE = 'REQUEST_TABLE',
    DOC_JOURNAL_PIE_CHART = 'DOC_JOURNAL_PIE_CHART',
    NOB_COMPONENTS_BAR_CHART = 'NOB_COMPONENTS_BAR_CHART',
}

export const widgetDraggableComponentsInitialState: WidgetComponentProps[][] = [
    [
        {component: WidgetPageComponents.REQUEST_TABLE, entityName: 'widgets.requests'},
        {component: WidgetPageComponents.NOB_COMPONENTS_BAR_CHART},
    ],
    [
        {component: WidgetPageComponents.DOC_JOURNAL_PIE_CHART},
        {component: WidgetPageComponents.NEWS},
    ],
];

export const widgetPageComponentMap = {
    [WidgetPageComponents.REQUEST_TABLE]: RequestTable,
    [WidgetPageComponents.DOC_JOURNAL_PIE_CHART]: DocJournalPieChart,
    [WidgetPageComponents.NEWS]: News,
    [WidgetPageComponents.NOB_COMPONENTS_BAR_CHART]: NobComponentsBarChart,
};

export interface WidgetComponentProps {
    component: WidgetPageComponents;
    entityName?: string;
}

export const getNumberOfWidgetColumns = (widgets: WidgetComponentProps[][]) => widgets.reduce(
    (acc, curr) => (curr.length ? acc + 1 : acc),
    0,
);

export const getNumberOfMaxWidgetsPerColumn = (widgets: WidgetComponentProps[][]) => widgets.reduce(
    (acc, curr) => (curr.length > acc ? curr.length : acc),
    0,
);

export const checkIfAllColumnsHaveOneWidget = (widgets: WidgetComponentProps[][]) => widgets.reduce(
    (acc, curr) => (curr.length === 1 ? acc : false),
    true,
);
