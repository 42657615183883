import {
    Card, Modal, Space, Descriptions,
} from 'antd';
import cn from 'classnames';
import React, {useEffect, useState} from 'react';

import {createStringDate} from 'components/table/utils/table.utils';
import {ReactComponent as CloseIcon} from 'shared/assets/close.svg';
import {DATE_WITH_TIME_DOTS_SECONDS_12} from 'shared/constants/date-format';
import {useAppSelector} from 'store/config/hooks';
import {
    selectTableReportConfigurationData,
} from 'store/slices/report-configuration-slice/report-configuration-slice';

import './modal-change-history.less';

interface ModalChangeHistoryProps {
    visible?: boolean;
    changeHistoryData?: ChangeHistoryData;
    onClose?: () => void;
}
export interface ChangeHistoryData {
    createdBy?: string;
    createdDate?: string;
    updatedDate?: string;
    updatedBy?: string;
}
export const ModalChangeHistory : React.FC<ModalChangeHistoryProps> = ({
    visible,
    onClose,
    changeHistoryData,
}: ModalChangeHistoryProps) => {
    const [data, setData] = useState<ChangeHistoryData>({});

    const reportConfigurationData = useAppSelector(
        selectTableReportConfigurationData,
    );

    useEffect(() => {
        const {
            updatedBy, updatedDate, createdBy, createdDate,
        } = reportConfigurationData ?? {};
        setData({
            createdBy:
                changeHistoryData?.createdBy
                || `${createdBy?.meaning || ''}${createdBy?.lookupCode ? ` (${createdBy?.lookupCode})` : ''}`,
            createdDate:
                changeHistoryData?.createdDate
                || createStringDate(createdDate, DATE_WITH_TIME_DOTS_SECONDS_12) as string,
            updatedDate:
                changeHistoryData?.updatedDate
                || createStringDate(updatedDate, DATE_WITH_TIME_DOTS_SECONDS_12) as string,
            updatedBy:
                changeHistoryData?.updatedBy
                || `${updatedBy?.meaning || ''}${updatedBy?.lookupCode ? ` (${updatedBy?.lookupCode})` : ''}`,
        });
    }, [reportConfigurationData, changeHistoryData]);

    return (
        <Modal
            className={cn('modal-change-history')}
            onCancel={onClose}
            centered
            visible={visible}
            footer={null}
            width={680}
            closeIcon={<CloseIcon className="modal-close-icon" />}
        >
            <Card title="История изменений">
                <Space
                    size={16}
                    direction="vertical"
                >
                    <Descriptions column={1}>
                        <Descriptions.Item label="Кто создал">{data?.createdBy || 'неизвестно'}</Descriptions.Item>
                        <Descriptions.Item label="Когда создал">{data?.createdDate || 'неизвестно'}</Descriptions.Item>
                        <Descriptions.Item label="Кто изменил">{data?.updatedBy || 'неизвестно'}</Descriptions.Item>
                        <Descriptions.Item label="Когда изменил">{data?.updatedDate || 'неизвестно'}</Descriptions.Item>
                    </Descriptions>
                </Space>
            </Card>
        </Modal>
    );
};
