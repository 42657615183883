export enum AuthRoutes {
    LOGOUT = '/logout',
    USER_INFO = '/userInfo',
    GET_TWO_FA_CODE = '/get2faCode',
    GET_TWO_FA_CONFIG = '/use2fa',
    CHANGE_PASSWORD = '/changePassword',
    CHANGE_PASSWORD_SETTINGS = '/change-password-settings',
    PROFILE = '/security/profile',
    AUTHENTICATION = '/security/authentication',
}
