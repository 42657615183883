import {createAsyncThunk} from '@reduxjs/toolkit';

import {reportConfigurationSliceName} from '../report-configuration-constants';
import {ReportConfigurationAttribute, ReportConfigurationDataConverted} from '../report-configuration-types';
import {getHeaderAttributes, getTableAttributes} from '../report-dependencies';
import {
    DeleteTableReportConfigurationArgs,
    deleteTableReportConfiguration,
    getTableReportConfigurationData,
    patchTableReportConfiguration,
    postTableReportConfiguration,
} from './report-configuration-api';
import {convertConfigurationDataForRequest, convertConfigurationDataFromResponse} from './report-configuration-utils';

interface LoadTableReportConfiguration {
    reportConfigurationConvertedData: ReportConfigurationDataConverted;
    tableAttributes: ReportConfigurationAttribute[];
    headerAttributes: ReportConfigurationAttribute[];
}
export const loadTableReportConfiguration = createAsyncThunk<
    LoadTableReportConfiguration, {templateCode: string}>(
        `${reportConfigurationSliceName}/loadTableReportConfigurationData`,
        async ({templateCode}, {rejectWithValue}) => {
            try {
                const [
                    templateConfigurationData,
                    tableAttributes,
                    headerAttributes,
                ] = await Promise.all([
                    getTableReportConfigurationData({templateCode}),
                    getTableAttributes({templateCode}),
                    getHeaderAttributes({templateCode}),
                ]);

                const configurationDataConverted = convertConfigurationDataFromResponse({
                    dataToConvert: templateConfigurationData,
                });

                return {
                    reportConfigurationConvertedData: configurationDataConverted as ReportConfigurationDataConverted,
                    tableAttributes: tableAttributes.data,
                    headerAttributes,
                };
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

// Для обновления используется один общий thunk. Если нужны экстра-редьюсеры
// для обновления какой-то конкретной вкладки, то можно создать ещё один аналогичный thunk
export const updateTableReportConfiguration = createAsyncThunk<
    ReportConfigurationDataConverted, {
        templateCode: string;
        data: ReportConfigurationDataConverted;
    }>(
        `${reportConfigurationSliceName}/updateTableReportConfiguration`,
        async ({templateCode, data}, {rejectWithValue}) => {
            try {
                const dataToSend = convertConfigurationDataForRequest({convertedData: data});
                await patchTableReportConfiguration({
                    templateCode,
                    data: dataToSend,
                });
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

export const createTableReportConfiguration = createAsyncThunk<
    ReportConfigurationDataConverted, {
        data: ReportConfigurationDataConverted;
    }>(
        `${reportConfigurationSliceName}/createTableReportConfiguration`,
        async ({data}, {rejectWithValue}) => {
            try {
                const dataToSend = convertConfigurationDataForRequest({convertedData: data});
                await postTableReportConfiguration({
                    data: {
                        ...dataToSend,
                    },
                });
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

export const removeTableReportConfiguration = createAsyncThunk<
    string, DeleteTableReportConfigurationArgs>(
        `${reportConfigurationSliceName}/removeTableReportConfiguration`,
        async (args, {rejectWithValue}) => {
            try {
                const {data} = await deleteTableReportConfiguration(args);
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );
