import React from 'react';

import {selectDashboardEntityData} from 'modules/data/data-selectors';
import {EntityData} from 'modules/data/data-types';
import {EntityMeta} from 'modules/metadata/metadata-types';
import {useAppSelector} from 'store/config/hooks';

import {DashboardComponent} from './dashboard-component/dashboard-component';

import './dashboard.less';

export interface DashboardProps {
    entityName: string;
    meta?: EntityMeta;
    context?: EntityData;
}

export const Dashboard = ({entityName, meta, context}: DashboardProps) => {
    const data = useAppSelector(selectDashboardEntityData(entityName));

    return meta?.fields ? (
        <div className="dashboard">
            {meta?.fields.map(field => (
                <DashboardComponent
                    key={field.key}
                    entityName={entityName}
                    meta={field}
                    data={data ? data[field.key] : undefined}
                    context={context}
                />
            ))}
        </div>
    ) : <></>;
};
