import cn from 'classnames';
import React from 'react';
import {Draggable} from 'react-beautiful-dnd';

export interface WidgetDraggableComponentProps {
    componentProps?: any;
    draggableId: string;
    component: React.FunctionComponent<any>;
    index: number;
    autoAdjustHeight?: boolean;
}

export const WidgetDraggableComponent = ({
    index,
    componentProps,
    component,
    draggableId,
    autoAdjustHeight,
}: WidgetDraggableComponentProps) => {
    const DragComponent = component;
    return (
        <Draggable
            key={draggableId}
            draggableId={draggableId}
            index={index}
        >
            {(provided, snapshot) => (
                <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    className={cn(
                        'widget-component',
                        snapshot.isDragging && 'dragging',
                        autoAdjustHeight && 'auto-adjust-height',
                    )}
                >
                    <DragComponent {...componentProps} />
                </div>
            )}
        </Draggable>
    );
};
