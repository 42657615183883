import {
    Button,
    Empty,
    Form,
    message,
} from 'antd';
import {FormListFieldData} from 'antd/es/form/FormList';
import {useForm} from 'antd/lib/form/Form';
import cn from 'classnames';
import React, {useContext, useEffect, useState} from 'react';

import {CustomSelectEntry} from 'components/form/inputs/custom-select';
import {ModalCloseConfirmation} from 'components/modal-close-confirmation';
import {ClickConfirmation} from 'components/report-configuration/components/click-confirmation';
import {useTableReportSettingsSheets} from 'components/report-configuration/tabs/report-table-settings/hooks/use-table-report-settings-sheets';
import {ReactComponent as TrashXOutlined} from 'shared/assets/trash-x.svg';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {ReportConfigurationDataConverted} from 'store/slices/report-configuration-slice';
import {
    selectReportConfigurationTableAttributesAsSelectEntries,
    selectTableReportConfigurationData,
} from 'store/slices/report-configuration-slice/report-configuration-slice';
import {
    updateTableReportConfiguration,
} from 'store/slices/report-configuration-slice/report-configuration/report-configuration-thunks';

import {ButtonsBar} from '../../components/buttons-bar';
import {
    ReportConfigurationFormName,
    ReportConfigurationTabKey,
} from '../../report-configuration.constants';
import {ReportConfigurationContext} from '../../report-configuration.context';
import {ReportComparisonList} from './report-comparision-list';
import {ReportComparisonSelectSheet} from './report-comparison-select-sheet';
import {omitEmptyAndUndefinedValues} from './report-comparison-utils';

import './report-comparision.less';

interface ReportTableSettingsProps {}

export interface TableAttributesCustomSelectEntry extends CustomSelectEntry<string, any> {
    type?: string;
}

const REPORT_COMPARISONS_FORM_KEY = 'reportComparisons';

export const ReportComparison: React.FC<ReportTableSettingsProps> = () => {
    const dispatch = useAppDispatch();

    const [form] = useForm();

    const getComparisonsFormValue = () => form
        .getFieldValue('reportComparisons') as ReportConfigurationDataConverted['reportComparisons'];

    const sheetCodeExistsInForm = (sheetCode?: string) => {
        const comparisons = getComparisonsFormValue();
        return comparisons?.map(rc => rc?.sheetCode).includes(sheetCode);
    };

    const reportConfigurationData = useAppSelector(
        selectTableReportConfigurationData,
    );

    const {enabledMenu: isMenuEnabled} = reportConfigurationData ?? {};

    const getComparisonSettings = () => {
        const comparison = reportConfigurationData?.[REPORT_COMPARISONS_FORM_KEY];
        if (!comparison) return [{sheetCode: undefined}];
        if (!comparison?.map(rc => rc?.sheetCode).includes(undefined)) {
            return [
                ...comparison,
                {sheetCode: undefined},
            ];
        }
        return comparison;
    };

    const tableAttributesEntries = useAppSelector(selectReportConfigurationTableAttributesAsSelectEntries);

    const {
        setSelectedTabKey, entityName, templateCode, isCreatingNewTemplate,
    } = useContext(ReportConfigurationContext);

    const [isEditingForm, setIsEditingForm] = useState(isCreatingNewTemplate);
    const [initialValues, setInitialValues] = useState<
        ReportConfigurationDataConverted['reportComparisons']
    >(getComparisonSettings());
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

    const [selectedSheetCode, setSelectedSheetCode] = useState<string>();

    const {sheets} = useTableReportSettingsSheets();
    const hasSheets = !!sheets?.length;

    useEffect(() => {
        if (getComparisonSettings()) {
            const initialData = getComparisonSettings();
            setInitialValues(initialData);
        }
    }, [reportConfigurationData]);

    useEffect(() => {
        if (initialValues) form.resetFields();
        if (!sheetCodeExistsInForm(selectedSheetCode)) setSelectedSheetCode(undefined);
    }, [initialValues]);

    const isDataPresents = !!tableAttributesEntries?.length;
    const isDisabled = isDataPresents && !isEditingForm;

    const handleClose = () => {
        setIsOpenModal(false);
        setSelectedTabKey?.(ReportConfigurationTabKey.REPORT_CONFIGURATOR);
    };

    const handleBack = () => {
        if (form.isFieldsTouched() && isEditingForm) {
            setIsOpenModal(true);
        } else { setSelectedTabKey?.(ReportConfigurationTabKey.REPORT_CONFIGURATOR); }
    };

    const handleFinish = (values: Pick<ReportConfigurationDataConverted, 'reportComparisons'>) => {
        setIsEditingForm(false);

        const filteredValues = omitEmptyAndUndefinedValues({valuesFromSubmit: values, hasSheets});

        if (!filteredValues?.length) {
            message.error(
                'Необходимо заполнить массив ключей и атрибутов для каждой страницы!',
                2,
            );
            return;
        }

        if (templateCode) {
            dispatch(updateTableReportConfiguration({
                data: {
                    reportComparisons: filteredValues,
                },
                templateCode,
            }));
        }
    };

    return (
        <div className={cn('report-comparison-settings')}>
            <Form<Pick<ReportConfigurationDataConverted, 'reportComparisons'>>
                layout="vertical"
                form={form}
                initialValues={{[REPORT_COMPARISONS_FORM_KEY]: initialValues}}
                onFinish={handleFinish}
                onFinishFailed={() => {
                    message.error('Пожалуйста, проверьте корректность введённых данных');
                }}
            >
                <ButtonsBar
                    entityName={entityName}
                    templateCode={templateCode}
                    isCreating={isCreatingNewTemplate}
                    isCreatingDisabled={(!isEditingForm && isDataPresents) || !tableAttributesEntries?.length}
                    isEditing={isEditingForm}
                    setIsEditing={setIsEditingForm}
                    hideEditButton={!isDataPresents}
                    hideDeleteButton
                    onCancelEdit={() => {
                        form.resetFields();
                        setIsEditingForm(false);
                        if (!sheetCodeExistsInForm(selectedSheetCode)) setSelectedSheetCode(undefined);
                    }}
                    onBack={handleBack}
                    isChangeHistoryButton={false}
                    onSubmit={() => {}}
                    onClearForm={() => {
                        form.setFieldsValue({reportComparisons: [{sheetCode: undefined}]});
                        setSelectedSheetCode(undefined);
                    }}
                />
                <div className={cn('report-table-settings__section-title')} />
                <div className="scroll" >
                    {tableAttributesEntries?.length ? (
                        <Form.List name={ReportConfigurationFormName.REPORT_COMPARISON}>
                            {(fields, {add, remove}) => (
                                <>
                                    {fields.map((field: FormListFieldData) => (
                                        <div className={cn('report-table-settings__body')}>
                                            <div className={cn('body-container',
                                                (selectedSheetCode !== getComparisonsFormValue()?.[field.name]
                                                    ?.sheetCode) && 'no-visible')}
                                            >
                                                {field && (
                                                    <>
                                                        <div className="fields-container">
                                                            <div
                                                                className="section-list"
                                                                key={field.key}
                                                            >
                                                                {isMenuEnabled && (
                                                                    <ReportComparisonSelectSheet
                                                                        {...{
                                                                            add,
                                                                            form,
                                                                            selectedSheetCode,
                                                                            setSelectedSheetCode,
                                                                        }}
                                                                    />
                                                                )}

                                                                {isMenuEnabled && selectedSheetCode && (
                                                                    <Form.Item
                                                                        style={{width: 144}}
                                                                    >
                                                                        <ClickConfirmation labels={{
                                                                            title: 'Удалить лист?',
                                                                            main: 'Вы действительно'
                                                                            + 'хотите удалить этот лист?',
                                                                            confirmButtonLabel: 'Удалить',
                                                                        }}
                                                                        >
                                                                            <Button
                                                                                disabled={
                                                                                    !isEditingForm
                                                                                }
                                                                                type="default"
                                                                                onClick={() => {
                                                                                    remove(field.name);
                                                                                    setSelectedSheetCode(undefined);
                                                                                }}
                                                                                icon={<TrashXOutlined />}
                                                                            >
                                                                                Удалить лист
                                                                            </Button>
                                                                        </ClickConfirmation>
                                                                    </Form.Item>
                                                                )}
                                                                <Form.Item
                                                                    style={{width: 182}}
                                                                    name={[field.name, 'sheetCode']}
                                                                    hidden
                                                                />
                                                            </div>
                                                            <ReportComparisonList
                                                                className={{
                                                                    'section-list__add-key-button': !isMenuEnabled,
                                                                }}
                                                                parentFieldName={field.name}
                                                                isDisabled={isDisabled}
                                                                name="key"
                                                                nameCheckUniqueness="attr"
                                                                messageCheckUniqueness="Поле ключа сравнения не
                                                                        может использоваться в поле атрибута сравнения"
                                                                buttonTitle="Добавить ключ"
                                                                fieldTitle="Ключ сравнения"
                                                                fieldPlaceholder="Выберите ключ сравнения"
                                                                emptyFieldsLabel="Не задано ни одного ключа"
                                                            />
                                                            <ReportComparisonList
                                                                parentFieldName={field.name}
                                                                isDisabled={isDisabled}
                                                                name="attr"
                                                                nameCheckUniqueness="key"
                                                                messageCheckUniqueness="Поле атрибута сравнения не
                                                                        может использоваться в поле ключа сравнения"
                                                                buttonTitle="Добавить атрибут"
                                                                fieldTitle="Атрибут для сравнения"
                                                                fieldPlaceholder="Выберите атрибут для сравнения"
                                                                emptyFieldsLabel="Не задано ни одного атрибута"
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </Form.List>
                    ) : <Empty description="Атрибуты источника данных табличной части не найдены" />}
                    <ModalCloseConfirmation
                        isOpen={isOpenModal}
                        onClose={handleClose}
                        onBack={() => setIsOpenModal(false)}
                        closeTitle="Закрыть форму"
                        backTitle="Вернуться к редактированию"
                    />
                </div>
            </Form>
        </div>
    );
};
