import {FORM_ACTIONS} from './form/form.actions';
import {FORM_FIELDS} from './form/form.fields';
import {REQUEST_META, RESPONSE_META} from './form/form.meta';
import {ANSWER, EDIT_ANSWER, MODAL_META_FOR_ENTITY_NAME} from './modal/modal.meta';

export {REQUEST_META, RESPONSE_META} from './form/form.meta';

export {
    requestType,
    REQUESTS_RESOLVED_META,
    RESPONSES_RESOLVED_META,
    DATA_KEY_FOR_FILTER_FIELDS,
} from './metadata.constants';

export const DEFAULT_META_FOR_ENTITY_NAME = {
    // Основные формы
    [RESPONSE_META.name]: RESPONSE_META,
    [REQUEST_META.name]: REQUEST_META,
    // Модальные окна
    ...MODAL_META_FOR_ENTITY_NAME,
};

export const FORM_FIELDS_BY_ENTITY_NAME = {
    [REQUEST_META.name]: FORM_FIELDS.REQUESTS,
    [RESPONSE_META.name]: FORM_FIELDS.REQUESTS,
    [ANSWER.name]: FORM_FIELDS.ANSWER,
    [EDIT_ANSWER.name]: FORM_FIELDS.ANSWER,
};

export const FORM_ACTIONS_BY_ENTITY_NAME = {
    [REQUEST_META.name]: FORM_ACTIONS.REQUESTS,
    [RESPONSE_META.name]: FORM_ACTIONS.REQUESTS,
    [ANSWER.name]: FORM_ACTIONS.ANSWER,
    [EDIT_ANSWER.name]: FORM_ACTIONS.ANSWER,
};
