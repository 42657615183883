import {IconsMap} from 'components/dynamic-icon/dynamic-icon';
import {AppModalsMapping} from 'components/modal';
import {FieldType, ViewType} from 'modules/metadata/metadata-types';

const VIEW_TYPES = [
    {
        label: 'form',
        value: ViewType.FORM,
    },
    {
        label: 'table',
        value: ViewType.TABLE,
    },
    {
        label: 'tabs',
        value: ViewType.TABS,
    },
    {
        label: 'filter',
        value: ViewType.FILTER,
    },
    {
        label: 'form-table',
        value: ViewType.FORM_TABLE,
    },
];

const FIELD_TYPES = [
    {
        label: 'DATE',
        value: FieldType.DATE,
    },
    {
        label: 'STRING',
        value: FieldType.STRING,
    },
    {
        label: 'NUMBER',
        value: FieldType.NUMBER,
    },
    {
        label: 'FILE',
        value: FieldType.FILE,
    },
    {
        label: 'SIGNATURE',
        value: FieldType.SIGNATURE,
    },
    {
        label: 'BOOLEAN',
        value: FieldType.BOOLEAN,
    },
];

const getOptionsFromObjectKey = <T extends object = Record<string, any>>(object: T) => Object
    .keys(object)
    .map((key: string) => ({value: key, label: key}));

const MODULE_NAMES = getOptionsFromObjectKey(AppModalsMapping);
const ICON_NAMES = getOptionsFromObjectKey(IconsMap);

export const COMMON_OPTIONS = {
    VIEW_TYPES,
    FIELD_TYPES,
    MODULE_NAMES,
    ICON_NAMES,
};
