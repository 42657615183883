export enum DdParameterRuleItemSourceType {
    SQL = 'SQL',
    parameter = 'PARAMETER',
    row = 'ROW',
    filter = 'FILTER'
}

export enum DdParameterRuleItemType {
    child = 'CHILD',
    parent = 'PARENT'
}
