import React, {FunctionComponent} from 'react';

import {TIconType} from 'components/dynamic-icon/dynamic-icon';
import {SimpleActionButton} from 'components/form/components';
import {ButtonType} from 'modules/metadata/metadata-types';

interface ButtonBackProps {
    actionTitle: string;
    actionIcon: TIconType;
    entityName: string;
    onClose?: () => void;
    onBack?: () => void;
    showBackIcon?: true;
    type?: ButtonType;
}

interface IButtonProps extends ButtonBackProps {
}

export const ButtonBack: FunctionComponent<IButtonProps> = (
    {
        onClose,
        onBack,
        showBackIcon,
        actionTitle,
        actionIcon,
        type,
        ...props
    }: IButtonProps,
) => {
    const handleGoBack = () => {
        if (onClose) return onClose();
        return onBack?.();
    };

    const ActionButton = ({onClick}: { onClick?: () => void }) => (
        <SimpleActionButton
            type={type || 'default'}
            className="button-back"
            onClick={onClick ?? handleGoBack}
            title={actionTitle || 'Назад'}
            icon={actionIcon || (showBackIcon && 'RollbackOutlined')}
            style={{width: '100%'}}
            {...props}
        />
    );

    return (
        <ActionButton />
    );
};
