import {useMemo} from 'react';

import {TableColumnFilterExpression} from '../table-column-filter-types';
import {TableColumnFilters} from './use-table-column-filter-types';
import {adaptFiltersForRequest} from './use-table-column-filter-utils';

export type UseNewTableColumnFiltersArgs = {
    tableFiltersData?: TableColumnFilters | undefined;
    setTableFiltersData?: (data: TableColumnFilters | undefined) => void;
}

export const useTableColumnFilters = (args: UseNewTableColumnFiltersArgs) => {
    const {
        tableFiltersData,
        setTableFiltersData,
    } = args;

    const resetTableColumnFiltersData = () => {
        setTableFiltersData?.(undefined);
    };

    const createTableColumnFilterDataGetter = (columnKey: string) => () => tableFiltersData?.[columnKey];
    const createTableColumnFilterDataSetter = (columnKey: string) => (
        filterExp: TableColumnFilterExpression | undefined,
    ) => {
        if (filterExp) {
            setTableFiltersData?.({
                ...tableFiltersData,
                [columnKey]: filterExp,
            });
        }
    };

    const getTableColumnFilterInfo = (columnKey: string) => {
        const filterExp = tableFiltersData?.[columnKey];
        const isApplied = (filterExp?.length ?? 0) > 1;

        const countRules = (exp: TableColumnFilterExpression | undefined) => {
            if (!exp) return 0;
            const [, ...operands] = exp;
            const count: number = operands.map(operand => {
                if (!Array.isArray(operand)) return 1;
                return countRules(operand);
            }).reduce((acc, v) => acc + v, 0);
            return count;
        };

        return {
            isApplied,
            rulesCount: countRules(filterExp),
        };
    };

    const filtersAdaptedForRequest = useMemo(
        () => {
            const adaptedFilters = adaptFiltersForRequest(tableFiltersData);
            return adaptedFilters;
        },
        [tableFiltersData],
    );

    return {
        createTableColumnFilterDataGetter,
        createTableColumnFilterDataSetter,
        resetTableColumnFiltersData,
        getTableColumnFilterInfo,
        filtersAdaptedForRequest,
    };
};

export type SetTableColumnFilterDataFunction = ReturnType<
ReturnType<typeof useTableColumnFilters>['createTableColumnFilterDataSetter']>;
export type GetTableColumnFilterDataFunction = ReturnType<
ReturnType<typeof useTableColumnFilters>['createTableColumnFilterDataGetter']>;
export type GetTableColumnFilterInfoFunction = ReturnType<typeof useTableColumnFilters>['getTableColumnFilterInfo'];
