import React from 'react';

import {SimpleActionButton} from 'components/form/components';

interface ShowAsideButtonProps {
    handleMenuCollapse: () => void;
}

export const ShowAsideButton = ({handleMenuCollapse}: ShowAsideButtonProps) => (
    <SimpleActionButton
        key={1}
        type="primary"
        onClick={handleMenuCollapse}
        title="Оглавление"
        icon="BurgerIcon"
    />
);
