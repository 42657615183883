import {Descriptions} from 'antd';
import React from 'react';

import {Entity} from 'modules/data';
import {DirectoryEntityMeta} from 'modules/metadata';

import {directoryItemDescriptionTypeResolver} from './directory-item-description-type-resolver';

interface DirectoryItemDescriptionProps {
    data: Entity;
    metadata: DirectoryEntityMeta;
}

export const DirectoryItemDescription: React.FunctionComponent<DirectoryItemDescriptionProps> = ({
    data,
    metadata,
}: DirectoryItemDescriptionProps) => (
    <div>
        <Descriptions
            bordered
            size="middle"
            column={1}
        >
            {metadata.fields.map(field => (
                directoryItemDescriptionTypeResolver(field, data)
            ))}
        </Descriptions>
    </div>
);
