import {performRequest, showMessageFromResponse} from 'shared/utils';

export const getNewsData = (userId: number) => performRequest({
    url: '/news?search=',
    headers: {
        userId,
    },
});

export const checkReadFlag = (
    data: Record<string, any>,
    userId: number,
    ref: { needRead: boolean; prevState: boolean; firstRender: boolean },
    setNeedRead: (bol: boolean) => void,
) => {
    const readFlag = data?.dataTable?.find((news: Record<string, any>) => news?.notificationAssignments?.find(
        (notification: Record<string, any>) => notification?.assigneeId === userId && !notification.read,
    ));
    if (ref.firstRender && readFlag) {
        setNeedRead(true);
        ref.firstRender = false;
        ref.needRead = true;
    } else if (ref.firstRender && !readFlag) {
        setNeedRead(false);
        ref.firstRender = false;
        ref.needRead = false;
    } else if (readFlag) {
        ref.needRead = true;
        setNeedRead(true);
    } else if (!readFlag && ref.prevState !== ref.needRead) {
        ref.needRead = false;
        setNeedRead(false);
    }
};

export const findNews = async (
    userId: number,
    ref: { needRead: boolean; prevState: boolean; firstRender: boolean },
    setNeedRead: (bol: boolean) => void,
) => {
    try {
        const {data} = await getNewsData(userId);
        checkReadFlag(data, userId, ref, setNeedRead);
    } catch {
        // pass
    }
};

export const checkReadNews = async (
    userId: number,
    ref: { needRead: boolean; prevState: boolean; firstRender: boolean },
    setNeedRead: (bol: boolean) => void,
) => {
    try {
        const {data} = await getNewsData(userId);
        checkReadFlag(data, userId, ref, setNeedRead);
    } catch (e) {
        showMessageFromResponse({response: e.response, isError: true});
        console.error(e);
    }
};
