import {QuestionCircleOutlined} from '@ant-design/icons';
import {Tooltip} from 'antd';
import cn from 'classnames';
import React from 'react';

import {DynamicIcon} from 'components/dynamic-icon';
import {useAppHistory} from 'shared/hooks/use-app-history';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {
    selectSelectedMenuItem, menuIconResolver,
    selectMenuStructure,
    selectIsMenuOpen,
} from 'store/slices/menu-slice';
import {menuSliceActions} from 'store/slices/menu-slice/menu-slice';
import {MenuStructureItem} from 'store/slices/menu-slice/menu-slice-types';

import {checkIfIsTopLevelMenuItemSelected} from '../new-side-menu-utils';

interface NewSideMenuItemProps {
    item: MenuStructureItem;
}

export const NewSideMenuItem = (
    {item}: NewSideMenuItemProps,
) => {
    const dispatch = useAppDispatch();

    const history = useAppHistory();

    const menuStructure = useAppSelector(selectMenuStructure);

    const selectedMenuItem = useAppSelector(selectSelectedMenuItem);
    const {setSelectedMenuItem, setIsMenuOpen} = menuSliceActions;

    const icon = menuIconResolver(item.itemKey) ?? item.icon ?? undefined;

    const isMenuOpen = useAppSelector(selectIsMenuOpen);

    const isSelected = checkIfIsTopLevelMenuItemSelected({
        item,
        menuStructure,
        pathname: history.location.pathname,
    });

    const contentIcon = icon ? (
        <DynamicIcon
            type={icon}
        />
    ) : <QuestionCircleOutlined />;

    return (
        <div
            key={item.id}
            className={cn(
                'new-side-menu__content__item',
                {'new-side-menu__content__item_selected': selectedMenuItem?.id === item.id || isSelected},
            )}
            onClick={() => {
                if (item.id === selectedMenuItem?.id) {
                    dispatch(setIsMenuOpen(false));
                    dispatch(setSelectedMenuItem(undefined));
                } else {
                    dispatch(setIsMenuOpen(true));
                    dispatch(setSelectedMenuItem(item));
                }
            }}
        >
            {isMenuOpen ? contentIcon : (
                <Tooltip
                    placement="right"
                    title={item.itemTitle}
                    getPopupContainer={t => t.parentElement as HTMLElement}
                    overlayClassName="new-side-menu__tooltip"
                    mouseEnterDelay={0.4}
                >
                    {contentIcon}
                </Tooltip>
            )}
        </div>
    );
};
