import {createAsyncThunk} from '@reduxjs/toolkit';

import {reportConfigurationSliceName} from '../report-configuration-constants';
import {ReportConfigurationAttribute} from '../report-configuration-types';
import {getHeaderAttributes, getTableAttributes} from './report-dependencies-api';

export const loadTableAttributes = createAsyncThunk<
    ReportConfigurationAttribute[], {templateCode?: string}>(
        `${reportConfigurationSliceName}/loadTableAttributes`,
        async ({templateCode}, {rejectWithValue}) => {
            if (!templateCode) return [];
            try {
                const tableAttributes = await getTableAttributes({templateCode});
                return tableAttributes.data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

export const loadHeaderAttributes = createAsyncThunk<
    ReportConfigurationAttribute[], {templateCode?: string}>(
        `${reportConfigurationSliceName}/loadHeaderAttributes`,
        async ({templateCode}, {rejectWithValue}) => {
            if (!templateCode) return [];
            try {
                const tableAttributes = await getHeaderAttributes({templateCode});
                return tableAttributes;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );
