import {
    createSlice,
} from '@reduxjs/toolkit';

import {widgetsSliceName} from './widgets-slice-constants';
import {
    loadDocJournalData, loadNews, loadNobComponentsCorrNumList, loadNobComponentsData, loadRequestsData,
} from './widgets-slice-thunks';
import {WidgetsSliceState} from './widgets-slice-types';

export const widgetsSlice = createSlice({
    initialState: {
        docJournalData: undefined,
        news: undefined,
        nobComponentsCorrNumList: undefined,
        nobComponentsData: undefined,
        requestTableValues: undefined,
    } as WidgetsSliceState,
    name: widgetsSliceName,
    reducers: {
        resetRequestsData(state) {
            state.requestTableValues = undefined;
        },
        resetDocJournalData(state) {
            state.docJournalData = undefined;
        },
        resetNobComponentsCorrNumList(state) {
            state.nobComponentsCorrNumList = undefined;
        },
        resetNobComponentsData(state) {
            state.requestTableValues = undefined;
        },
        resetNewsData(state) {
            state.requestTableValues = undefined;
        },
    },
    extraReducers: builder => {
        builder.addCase(loadRequestsData.fulfilled, (state, action) => {
            state.requestTableValues = action.payload;
        });
        builder.addCase(loadDocJournalData.fulfilled, (state, action) => {
            state.docJournalData = action.payload;
        });
        builder.addCase(loadNobComponentsCorrNumList.fulfilled, (state, action) => {
            state.nobComponentsCorrNumList = action.payload;
        });
        builder.addCase(loadNobComponentsData.fulfilled, (state, action) => {
            state.nobComponentsData = action.payload;
        });
        builder.addCase(loadNews.fulfilled, (state, action) => {
            state.news = action.payload;
        });
    },
});

export const {
    reducer: widgetsSliceReducer,
    actions: widgetsSliceActions,
} = widgetsSlice;
