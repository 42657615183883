export const REPORT_CONFIGURATION_MODULE = 'REPORT_CONFIGURATION_MODULE';

export const REPORT_CONFIGURATION_API_URL_ENDPOINT = 'administration.report.configuration';

export const DD_REFERENCES_TABLE_DEFAULT_PAGINATION = {
    page: 1,
    pageSize: 10,
};

export const DD_DYNAMIC_VALUES_TABLE_DEFAULT_PAGINATION = {
    page: 1,
    pageSize: 10,
};

export const reportConfigurationSliceName = 'reportConfigurationSlice' as const;
