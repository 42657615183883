import {Spin} from 'antd';
import cn from 'classnames';
import React, {useEffect, useState} from 'react';

import {ModalOpenerComponent, ModalOpenerComponentRef} from 'components/modal-opener-component';
import {LinkDto, LinkMethod, LinkModalViewType} from 'shared/types/links';
import {performRequest} from 'shared/utils';

import {CustomLinkModalTableView} from './custom-link-modal-content-views/custom-link-modal-table-view/custom-link-modal-table-view';

interface CustomLinkModalContentProps {
    link?: LinkDto;
}

export const CustomLinkModalContent = ({
    link,
}: CustomLinkModalContentProps) => {
    const {
        modalViewProperties,
    } = link ?? {};

    const {
        type,
    } = modalViewProperties ?? {};

    const [modalContentData, setModalContentData] = useState<unknown>(undefined);
    const [isContentLoaded, setIsContentLoaded] = useState(false);
    const [isLoadedWithError, setIsLoadedWithError] = useState(false);

    useEffect(() => {
        const {
            url,
            method,
            bodyParameters,
            queryParameters,
            token,
        } = link ?? {};
        let isModalOpen = true;

        if (url) {
            (async () => {
                try {
                    const requestResponse = await performRequest({
                        url,
                        method: method === LinkMethod.GET ? 'GET' : 'POST', // todo: расширить
                        data: (() => {
                            if (bodyParameters) {
                                const formData = new FormData();
                                Object.entries(bodyParameters).forEach(([key, value]) => {
                                    formData.set(key, value);
                                });
                                return formData;
                            }
                            return undefined;
                        })(),
                        params: (() => {
                            if (method === LinkMethod.GET && queryParameters) return queryParameters;
                            return undefined;
                        })(),
                        headers: (() => {
                            if (token) {
                                return {
                                    Authorization: token,
                                };
                            }
                            return undefined;
                        })(),
                    }, true);
                    const {data} = requestResponse;

                    if (isModalOpen) {
                        setModalContentData(data);
                    }
                } catch {
                    setIsLoadedWithError(true);
                } finally {
                    setIsContentLoaded(true);
                }
            })();
        }

        return () => {
            isModalOpen = false;
        };
    }, []);

    if (!isContentLoaded) {
        return (
            <div className={cn('d-flex justify-content-center')}>
                <Spin tip="Загрузка данных..." />
            </div>
        );
    }

    if (isLoadedWithError) {
        return (
            <div className={cn('d-flex justify-content-center')}>
                Ошибка загрузки данных.
            </div>
        );
    }

    if (type === LinkModalViewType.TABLE) {
        return (
            <CustomLinkModalTableView
                link={link}
                tableData={modalContentData as Record<string, string | number>[] | undefined}
            />
        );
    }

    return null;
};

interface CustomLinkModalProps {
    link?: LinkDto;
}

export const CustomLinkModal = React.forwardRef<ModalOpenerComponentRef, CustomLinkModalProps>((
    {link}: CustomLinkModalProps, ref,
) => {
    const {
        modalViewProperties,
    } = link ?? {};

    const {name: modalTitle} = modalViewProperties ?? {};

    return (
        <ModalOpenerComponent
            ref={ref}
            modalProps={{
                forceRender: false,
                destroyOnClose: true,
                centered: true,
                width: 1480,
                title: modalTitle ?? link?.position?.title ?? 'Просмотр данных',
            }}
            controlLabels={{
                cancel: 'Закрыть',
            }}
        >
            <CustomLinkModalContent link={link} />
        </ModalOpenerComponent>
    );
});
