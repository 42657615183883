import {MinusCircleOutlined, PlusCircleOutlined} from '@ant-design/icons';
import {Checkbox, Form, Tooltip} from 'antd';
import React from 'react';

interface CheckboxWithToggleProps {
    selected: boolean;
    onToggleSelect: () => void;
    fieldsCount: number | undefined;
    areConditionsHidden: boolean;
    setAreConditionsHidden: (value: boolean) => void;
}

export const CheckboxWithToggle = ({
    selected,
    onToggleSelect,
    fieldsCount,
    areConditionsHidden,
    setAreConditionsHidden,
}: CheckboxWithToggleProps) => (
    <>
        <Form.Item
            label={<>&nbsp;</>}
            colon={false}
        >
            <Checkbox
                checked={selected}
                onClick={onToggleSelect}
            />
        </Form.Item>
        <Form.Item
            label={<>&nbsp;</>}
            style={{width: 32, marginLeft: 11}}
        >
            {(() => {
                if (!fieldsCount) {
                    return (
                        <Tooltip
                            title="Нет добавленных условий"
                            mouseEnterDelay={0.8}
                        >
                            <MinusCircleOutlined
                                className="no-select"
                                style={{color: '#CED1D7', cursor: 'default'}}
                            />
                        </Tooltip>
                    );
                }
                if (areConditionsHidden) {
                    return (
                        <Tooltip
                            title="Показать условия"
                            mouseEnterDelay={0.8}
                        >
                            <PlusCircleOutlined
                                className="no-select"
                                onClick={() => setAreConditionsHidden(!areConditionsHidden)}
                                style={{color: '#176DB7', cursor: 'pointer'}}
                            />
                        </Tooltip>
                    );
                }
                return (
                    <MinusCircleOutlined
                        className="no-select"
                        onClick={() => setAreConditionsHidden(!areConditionsHidden)}
                        style={{color: '#176DB7', cursor: 'pointer'}}
                    />
                );
            })()}
        </Form.Item>
    </>
);
