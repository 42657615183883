import {PayloadAction} from '@reduxjs/toolkit';

import {IRegistry} from 'shared/types';

import {TableReportDataRequestParams, TableReportDocumentUploadInfo, TableReportSliceState} from '../table-report-slice-types';
import {
    AnimationReportDownloadDocumentsParams,
    ModalReportDownloadDocumentType,
    PaginationType, ReadReportDocumentsParams,
    TableReportDownloadDocumentType,
} from './table-report-download-documents-types';

export const createTableReportDownloadDocumentsReducer = <S extends TableReportSliceState>() => ({
    setReportDownloadsData(state: S, {payload}: PayloadAction<IRegistry<TableReportDownloadDocumentType>>) {
        state.reportDownloadDocuments = {
            ...payload,
            modalReportDownloadDocument: {
                isOpen: false,
                wereDocumentsRead: true,
            },
        };
    },
    resetReportDownloadsData(state: S) {
        state.reportDownloadDocuments = undefined;
    },
    removeReportDownloads(state: S, {payload: ids}: PayloadAction<number[]>) {
        const changedReportDownloadDocuments = state.reportDownloadDocuments
            ?.content?.filter((report: any) => !ids.includes(report.requestId));
        if (changedReportDownloadDocuments && state.reportDownloadDocuments) {
            state.reportDownloadDocuments = {
                ...state.reportDownloadDocuments,
                content: changedReportDownloadDocuments,
            };
        }
    },
    setReportDocUploadInfo(state: S, {payload}: PayloadAction<Partial<TableReportDocumentUploadInfo>>) {
        state.documentUploadInfo = {
            ...(state.documentUploadInfo),
            ...payload,
        };
    },
    setReportRequestData(state: S, {payload}: PayloadAction<TableReportDataRequestParams>) {
        state.documentUploadInfo = {
            ...state.documentUploadInfo,
            reportRequestData: payload,
        };
    },
    updateReportDownloadsPagination(state: S, {payload: pagination}: PayloadAction<Partial<PaginationType>>) {
        if (state.reportDownloadDocuments) {
            state.reportDownloadDocuments = {
                ...state.reportDownloadDocuments,
                ...pagination,
            };
        }
    },
    setReadReportDownloadDocuments(state: S, {payload}: PayloadAction<ReadReportDocumentsParams>) {
        if (state.reportDownloadDocuments) {
            state.reportDownloadDocuments = {
                ...state.reportDownloadDocuments,
                modalReportDownloadDocument: {
                    ...state.reportDownloadDocuments?.modalReportDownloadDocument,
                    wereDocumentsRead: payload?.wereDocumentsRead,
                    isAnimationActive: payload?.isAnimationActive,
                },
            };
        }
    },
    setAnimationReportDownloadDocuments(state: S, {payload}: PayloadAction<AnimationReportDownloadDocumentsParams>) {
        if (state.reportDownloadDocuments) {
            state.reportDownloadDocuments = {
                ...state.reportDownloadDocuments,
                modalReportDownloadDocument: {
                    ...state.reportDownloadDocuments?.modalReportDownloadDocument,
                    isAnimationActive: payload?.isAnimationActive,
                },
            };
        }
    },
    setModalReportDownloadDocuments(state: S, {payload}: PayloadAction<ModalReportDownloadDocumentType>) {
        if (state.reportDownloadDocuments) {
            state.reportDownloadDocuments = {
                ...state.reportDownloadDocuments,
                modalReportDownloadDocument: {
                    ...state.reportDownloadDocuments?.modalReportDownloadDocument,
                    isOpen: payload.isOpen,
                    wereDocumentsRead: payload?.wereDocumentsRead,
                },
            };
        }
    },
});
