import {Button} from 'antd';
import {ButtonType} from 'antd/es/button';
import {ButtonHTMLType} from 'antd/lib/button/button';
import cn from 'classnames';
import React, {CSSProperties} from 'react';

import {DynamicIcon} from 'components/dynamic-icon';
import {TIconType} from 'components/dynamic-icon/dynamic-icon';

import './simple-action-button.less';

export interface SimpleActionButtonProps {
    icon?: TIconType | '';
    type?: ButtonType;
    title?: string;
    style?: CSSProperties;
    disabled?: boolean;
    htmlType?: ButtonHTMLType;
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    className?: string;
}

interface ISimpleActionButtonProps extends SimpleActionButtonProps {
}

export const SimpleActionButton: React.FunctionComponent<ISimpleActionButtonProps> = ({
    title,
    icon,
    type,
    onClick,
    className,
    ...props
}: ISimpleActionButtonProps) => (
    <Button
        onClick={onClick}
        type={type}
        className={cn(className, 'simple-action-btn')}
        {...props}
    >
        {icon && <DynamicIcon type={icon} />}
        {!!title && title}
    </Button>
);
