import {Selector, createSelector} from '@reduxjs/toolkit';

import {AppState} from 'store/config/types';

import {tableReportSliceName} from '../table-report-slice-constants';
import {TableReportSliceState} from '../table-report-slice-types';

const rootSelector: Selector<AppState, TableReportSliceState> = (state: AppState) => state[tableReportSliceName];

export const selectTableReportLocalSearch = createSelector(rootSelector, ({localSearch}) => localSearch);

export const selectTableReportSelectedCellIndexes = createSelector(rootSelector, ({localSearch}) => {
    const {currentSelection, results, count} = localSearch;
    if (currentSelection === null || count === null) return null;
    return results?.[currentSelection] ?? null;
});

export const selectTableReportGlobalSearchIsLoading = createSelector(
    rootSelector, ({globalSearch}) => globalSearch.isLoading,
);
export const selectTableReportGlobalSearch = createSelector(rootSelector, ({globalSearch}) => globalSearch);
export const selectTableReportGlobalPages = createSelector(rootSelector, ({globalSearch}) => globalSearch.pages);
export const selectTableReportGlobalSearchLine = createSelector(rootSelector, ({globalSearch}) => globalSearch.line);
export const selectTableReportGlobalSearchCurrentPage = createSelector(rootSelector, (
    {globalSearch},
) => globalSearch.currentPage);
