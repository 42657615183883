import {ActionReducerMapBuilder, PayloadAction} from '@reduxjs/toolkit';

import {ReportConfigurationSliceState} from '../report-configuration-types';
import {loadHeaderAttributes, loadTableAttributes} from './report-dependencies-thunks';

export const createTableReportDepenceniesExtraReducer = (
    builder: ActionReducerMapBuilder<ReportConfigurationSliceState>,
) => {
    builder.addCase(loadTableAttributes.fulfilled, (state, {payload}) => {
        state.reportDependencies.tableAttributes = payload;
    });
    builder.addCase(loadHeaderAttributes.fulfilled, (state, {payload}) => {
        state.reportDependencies.headerAttributes = payload;
    });
};

export const createTableReportDependenciesReducer = <S extends ReportConfigurationSliceState>() => ({
    setReportConfigurationTemplateBlocks(
        state: S, {payload}: PayloadAction<number>,
    ) {
        state.reportDependencies.templateBlocks = payload;
    },
});
