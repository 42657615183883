import {Upload} from 'antd';
import {RcFile, UploadFile} from 'antd/es/upload/interface';
import React from 'react';
import {useDispatch} from 'react-redux';

import {
    pickDataFromQueryByAdditionalFields,
    shouldPickDataFromQueryByAdditionalInfoKeys,
} from 'components/table/action/table-action.utils';
import {selectContextRawData} from 'modules/context/context-selector';
import {uploadFileAction} from 'modules/data/data-actions';
import {selectMetadata} from 'modules/metadata';
import {EntityType} from 'shared/constants/entities';
import {useQueryParams} from 'shared/utils/query-params';
import {useAppSelector} from 'store/config/hooks';

import {ActionButton} from '../action-button';
import {TableActionProps} from '../table-action-types';

const ButtonActionUploadFileComponent: React.FunctionComponent<TableActionProps> = ({
    entityName,
    meta,
    wrapper,
    ...props
}: TableActionProps) => {
    const dispatch = useDispatch();
    const contextData = useAppSelector(selectContextRawData);
    const filterMeta = useAppSelector(selectMetadata(entityName, EntityType.FILTER));
    const {getQueryParam} = useQueryParams();

    const {
        referenceUrl,
        additionalInfoKeys,
        actionTitle,
        actionIcon,
    } = meta;

    const handleUpload = (file: UploadFile<Blob>) => {
        const formData = new FormData();
        formData.append('file', file as RcFile);
        if (referenceUrl && contextData) {
            if (additionalInfoKeys && shouldPickDataFromQueryByAdditionalInfoKeys({filterMeta})) {
                const additionalData = pickDataFromQueryByAdditionalFields({additionalInfoKeys, getQueryParam});
                if (additionalData) {
                    Object
                        ?.keys(additionalData)
                        ?.forEach(key => {
                            const value = additionalData?.[key];
                            if (value) {
                                formData.append(key, value);
                            }
                        });
                }
            }

            dispatch(uploadFileAction(
                entityName,
                referenceUrl,
                formData,
                contextData,
            ));
        }
        return false;
    };

    const Component = wrapper || ActionButton;

    return (

        <Upload
            showUploadList={false}
            beforeUpload={handleUpload}
        >
            <Component
                {...props}
                actionIcon={actionIcon}
            >
                {actionTitle}
            </Component>
        </Upload>
    );
};
export const ButtonActionUploadFile = ButtonActionUploadFileComponent;
