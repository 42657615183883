import {MinusCircleOutlined, PlusCircleOutlined} from '@ant-design/icons';
import cn from 'classnames';
import React from 'react';

import {DashboardEntry} from 'modules/data/data-types';
import {useClickAwayListener} from 'shared/hooks/use-clickaway-listener.hook';

import {WidgetWrapper} from '../../components/widget-wrapper';
import {useStatisticsInitialization} from '../../hooks/use-statistics-initialization';
import {StatisticsEmptyPlaceholder} from '../statistics-empty-placeholder';
import {ProgressListItem} from './progress-list-item';
import {ProgressListStatisticsSettings} from './progress-list-statistics-types';

import './progress-list-statistics.less';

interface ProgressListStatisticsProps {
    entries: DashboardEntry;
    title?: string;
    settings?: ProgressListStatisticsSettings;
}

export const ProgressListStatistics: React.FC<ProgressListStatisticsProps> = ({
    title,
    entries,
    settings = {},
}: ProgressListStatisticsProps) => {
    const {displayCount = 10, displayAsOneColumnCount = 5} = settings;
    const {isVisible: isOpen, setIsVisible, popupRef} = useClickAwayListener();

    const {statisticsData} = useStatisticsInitialization({
        entries,
        shouldSortByValue: 'desc',
        slice: isOpen ? undefined : displayCount,
    });

    const showResizer = entries.dataTable.length > displayCount;
    const displayAsOneColumn = !(entries.dataTable.length > displayAsOneColumnCount);

    return (
        <WidgetWrapper
            className={['progress-list-wrapper', {'progress-list-wrapper_open': isOpen}]}
            title={title}
        >
            <div className="progress-list-anchor">
                <div
                    ref={popupRef}
                    className={cn(
                        'progress-list-statistics',
                        {
                            'progress-list-statistics_open': isOpen,
                            'display-as-one-column': displayAsOneColumn,
                        },
                    )}
                >
                    <div className={cn('progress-list-statistics__up-bar')}>
                        <div className={cn('progress-list-statistics__up-bar__title')}>
                            {isOpen ? 'Все пункты' : `ТОП-${displayCount} пунктов`}
                        </div>
                        <div className={cn('progress-list-statistics__up-bar__bar')} />
                        {showResizer && (
                            <div
                                className={cn('progress-list-statistics__up-bar__plus')}
                                onClick={() => {
                                    setIsVisible(v => !v);
                                }}
                            >
                                {isOpen ? <MinusCircleOutlined /> : <PlusCircleOutlined />}
                            </div>
                        )}
                    </div>
                    <div
                        className={cn('progress-list-statistics__body',
                            {
                                'progress-list-statistics__body_open': isOpen,
                                'display-as-one-column': displayAsOneColumn,
                            })}
                        style={{
                            gridTemplateRows: isOpen
                                ? `repeat(${Math.ceil(statisticsData.length / 2)}, 18px)` : undefined,
                        }}
                    >
                        {statisticsData.length
                            ? statisticsData.map(entry => (
                                <ProgressListItem
                                    settings={settings}
                                    key={entry.key}
                                    {...{entry}}
                                />
                            )) : <StatisticsEmptyPlaceholder />}
                    </div>
                </div>
            </div>
        </WidgetWrapper>
    );
};
