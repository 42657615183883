import {unwrapResult} from '@reduxjs/toolkit';
import {List} from 'antd';
import Spin from 'antd/es/spin';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

import {showRequestErrorMessage} from 'shared/utils/show-message-from-response';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';
import {loadNews, selectWidgetsNews} from 'store/slices/widgets-slice';
import {useWebsocketSubscriber} from 'websockets';

import {Card} from '../../components/card';

import './news.less';

export interface NewsData {
    id: number | string;
    title: string;
    date: string;
}

interface NewsProps {
    entityName?: string;
}

const getNewsURL = (
    entityName: string, id: React.ReactText,
) => `/${entityName}?entity=${id}&entityType=EDIT_FORM`;

export const News: React.FunctionComponent<NewsProps> = ({
    entityName = 'news',
}: NewsProps) => {
    const dispatch = useAppDispatch();
    const news = useAppSelector(selectWidgetsNews);

    useEffect(() => {
        dispatch(loadNews()).then(unwrapResult).catch(showRequestErrorMessage);
    }, []);

    useWebsocketSubscriber({
        newsAdded: () => {
            dispatch(loadNews()).then(unwrapResult).catch(showRequestErrorMessage);
        },
    });

    const isLoadingNews = useAppSelector(s => selectIsThunkPending(s, loadNews.typePrefix));

    return (
        <Card className="news-widget">
            <div className="news-widget__header-wrapper">
                <Title
                    className="news-widget__title"
                    level={2}
                >
                    Новости
                </Title>
                <Link to={`/${entityName}`}>
                    Все новости →
                </Link>
            </div>

            <Spin
                style={{marginTop: 60}}
                tip="Загрузка..."
                spinning={isLoadingNews}
            >
                <List
                    itemLayout="horizontal"
                    dataSource={news ?? []}
                    renderItem={item => (
                        <List.Item key={item.id}>
                            <List.Item.Meta
                                title={(
                                    <Text strong>
                                        <Link to={getNewsURL(entityName, item.id)}>
                                            {item.title}
                                        </Link>
                                    </Text>
                                )}
                                description={<Text type="secondary">{item.date}</Text>}
                            />
                        </List.Item>
                    )}
                />
            </Spin>
        </Card>
    );
};
