import {FunctionComponent} from 'react';

import {TabsWithReferences} from 'components/tabs-with-references';
import {DirectoryFormPage} from 'pages/directory-form-page';
import {DirectoryPage} from 'pages/directory-page';
import {FormPage} from 'pages/form-page';
import {FormTablePage} from 'pages/form-table-page';
import {JasperReportPage} from 'pages/jasper-report-page';
import {SubsectionTablePage} from 'pages/subsection-table-page';
import {SvcMainPage} from 'pages/svc-widget-page/svc-main-page';
import {TablePage} from 'pages/table-page';

import {AisMainPage} from './ais/ais-main-page';
import {PageTemplateProps} from './pages-types';

export enum JASPER_REPORT_ENTITIES {
    REPORT = 'REPORT',
    JASPER_REPORT = 'JASPER_REPORT',
    JASPER = 'JASPER',
}

export const PAGE_TEMPLATE_TYPE = {
    TABLE: 'TABLE',
    FORM: 'FORM',
    TABS: 'TABS',
    TABS_WITH_FORM: 'TABS_WITH_FORM',
    TABS_WITH_REFERENCES: 'TABS_WITH_REFERENCES',
    FORM_TABLE: 'FORM_TABLE',
    REPORT: JASPER_REPORT_ENTITIES.REPORT,
    // todo: сущность для jasper отчетов должна называться REPORT, убрать после изменений на бэке
    JASPER_REPORT: JASPER_REPORT_ENTITIES.REPORT,
    SUBSECTION_TABLE: 'SUBSECTION_TABLE',
    TABLE_REPORT: 'TABLE_REPORT',
    WIDGETS: 'WIDGETS',
    AIS_MAIN_PAGE: 'AIS_MAIN_PAGE',
};

export const PAGE_TEMPLATES: { [key: string]: FunctionComponent<PageTemplateProps> } = {
    [PAGE_TEMPLATE_TYPE.TABLE]: TablePage,
    [PAGE_TEMPLATE_TYPE.TABS]: DirectoryPage,
    [PAGE_TEMPLATE_TYPE.TABS_WITH_FORM]: DirectoryFormPage,
    [PAGE_TEMPLATE_TYPE.TABS_WITH_REFERENCES]: TabsWithReferences,
    [PAGE_TEMPLATE_TYPE.FORM]: FormPage,
    [PAGE_TEMPLATE_TYPE.FORM_TABLE]: FormTablePage,
    [PAGE_TEMPLATE_TYPE.REPORT]: JasperReportPage,
    [PAGE_TEMPLATE_TYPE.JASPER_REPORT]: JasperReportPage,
    [PAGE_TEMPLATE_TYPE.SUBSECTION_TABLE]: SubsectionTablePage,
    [PAGE_TEMPLATE_TYPE.WIDGETS]: SvcMainPage,
    [PAGE_TEMPLATE_TYPE.AIS_MAIN_PAGE]: AisMainPage,
};
