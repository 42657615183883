import {TimePicker} from 'antd';
import moment, {Moment} from 'moment';
import React, {ForwardedRef} from 'react';

import {ReactComponent as Clock} from 'shared/assets/clock.svg';
import {ReactComponent as CloseXOutlined} from 'shared/assets/close-x-outlined.svg';
import {ReactComponent as LockIcon} from 'shared/assets/forms/lock.svg';
import {TIME} from 'shared/constants/date-format';

import './time-picker.less';

export const TimePickerField = React.forwardRef(({
    value, format, disabled, ...props
}: any, ref: ForwardedRef<any>) => {
    const convertedValue = value ? moment(value, TIME) : null;

    const onSelectWrapper = (newDate: Moment) => {
        props.onChange(newDate?.format(TIME));
    };

    return (
        <TimePicker
            {...props}
            disabled={disabled}
            format={format || TIME}
            onSelect={onSelectWrapper}
            onChange={onSelectWrapper}
            value={convertedValue}
            className="time-picker"
            suffixIcon={disabled ? <LockIcon /> : <Clock />}
            clearIcon={<CloseXOutlined className="clear-icon" />}
            popupClassName="time-picker-dropdown" // somehow it applies className to dropdown
            ref={ref}
        />
    );
});
