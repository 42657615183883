import {
    FieldMeta, IRequestFormEntityMeta, MetaActionInfo,
} from '../metadata/metadata-types';
import {
    REQUESTS_RESOLVED_META,
    RESPONSES_RESOLVED_META,
    DEFAULT_META_FOR_ENTITY_NAME,
    REQUEST_META,
    RESPONSE_META,
    FORM_FIELDS_BY_ENTITY_NAME,
    FORM_ACTIONS_BY_ENTITY_NAME,
} from './metadata';
import {FORM_ACTIONS} from './metadata/form/form.actions';
import {FORM_FIELDS} from './metadata/form/form.fields';
import {IResolvedMetaItem} from './request-form.types';

const getAllFieldsForEntityType = (type: string = '') => FORM_FIELDS_BY_ENTITY_NAME[type];

const getAllActionsForEntityType = (type: string = '') => FORM_ACTIONS_BY_ENTITY_NAME[type];

const getItemFromMetaProp = <T = any>(allItems: Record<string, T>, itemList?: string[]) => itemList
    ?.map(itemKey => allItems[itemKey])
    .filter(item => item) || [];

export const getEntityNameFromMetadata = (metadata: IRequestFormEntityMeta): string => metadata.name;

/**
 * Необходимо для инициализации формы запросов
 * так как там несколько сущностей на одну страницу
 * @param entityName
 */
const getDefaultByEntityName = (entityName: string): IRequestFormEntityMeta => {
    const meta = DEFAULT_META_FOR_ENTITY_NAME[entityName] as IRequestFormEntityMeta;
    const allFieldsForEntityType = getAllFieldsForEntityType(entityName);

    if (!meta) throw new Error(`Нет метаданных для сущности ${entityName}`);

    let {fields} = meta;
    const isEmptyFields = !meta.fields?.length;
    const defaultFields = meta.dataKeyForFilterFields
        && [allFieldsForEntityType?.[meta.dataKeyForFilterFields]];

    if (isEmptyFields && defaultFields) {
        fields = defaultFields;
    }

    return {
        ...meta,
        fields,
    };
};

const createGetMetaByType = (
    resolvedMeta: Record<string, IResolvedMetaItem>,
    defaultMeta: IRequestFormEntityMeta,
    allFields: Record<string, FieldMeta>,
    allActions: Record<string, MetaActionInfo>,
) => (type?: string, fieldsList: string[] = [], dynamicActionsList: string[] = []) => {
    if (type) {
        const {actions: actionsList = []} = resolvedMeta?.[type] || {};
        const dynamicActions = dynamicActionsList || [];

        return ({
            ...defaultMeta,
            fields: getItemFromMetaProp(allFields, [...fieldsList]),
            actions: getItemFromMetaProp(allActions, [...dynamicActions, ...actionsList]),
        });
    }

    return defaultMeta;
};

const createGetMetaByTypeForAnyForms = (
    resolvedMeta: Record<string, IResolvedMetaItem>,
    defaultMeta: IRequestFormEntityMeta,
    allFields: Record<string, FieldMeta>,
    allActions: Record<string, MetaActionInfo>,
) => (
    entityName: string, type?: string, fieldsList: string[] = [], dynamicActionsList: string[] = [],
) => {
    const allFieldsForEntityType = getAllFieldsForEntityType(entityName) || allFields;
    const allActionsForEntityType = getAllActionsForEntityType(entityName) || allActions;
    const defaultMetaForEntityType = getDefaultByEntityName(entityName);

    return createGetMetaByType(
        resolvedMeta, defaultMetaForEntityType, allFieldsForEntityType, allActionsForEntityType,
    )(type, fieldsList, dynamicActionsList);
};

export const requestMeta = {
    getDefaultByEntityName,
    /**
     * Получение метаданных в зависимости от типа
     */
    getRequestByType: createGetMetaByTypeForAnyForms(
        REQUESTS_RESOLVED_META, REQUEST_META, FORM_FIELDS.REQUESTS, FORM_ACTIONS.REQUESTS,
    ),
    getResponseByType: createGetMetaByType(
        RESPONSES_RESOLVED_META, RESPONSE_META, FORM_FIELDS.REQUESTS, FORM_ACTIONS.REQUESTS,
    ),
};
