import {Empty, Modal, Spin} from 'antd';
import cn from 'classnames';
import React from 'react';

import {MonitorServiceContentDisplayerType} from 'components/table/columns/monitor-services-content-displayer/monitor-services-content-displayer.types';
import {ReactComponent as ErrorAlert} from 'shared/assets/error-alert.svg';
import {
    useGetRequestContentByMonitorServiceIdQuery,
    useGetResponseContentByMonitorServiceIdQuery,
} from 'store/api/monitor-services/monitor-services.api';

import './monitor-service-content-displayer-modal.less';

interface MonitorServiceContentDisplayerModalProps {
    type: MonitorServiceContentDisplayerType;
    entityId: string | undefined;
    visible: boolean;
    onClose: () => void;
    modalTitle: string;
}

export const MonitorServiceContentDisplayerModal = ({
    type,
    entityId,
    visible,
    onClose,
    modalTitle,
}: MonitorServiceContentDisplayerModalProps) => {
    const {
        data: requestData,
        isLoading: isRequestDataLoading,
        isError: isRequestDataError,
    } = useGetRequestContentByMonitorServiceIdQuery(
        {id: entityId},
        {skip: !visible || !entityId || type === MonitorServiceContentDisplayerType.response},
    );

    const {
        data: responseData,
        isLoading: isResponseDataLoading,
        isError: isResponseDataError,
    } = useGetResponseContentByMonitorServiceIdQuery(
        {id: entityId},
        {skip: !visible || !entityId || type === MonitorServiceContentDisplayerType.request},
    );

    const isError = isRequestDataError || isResponseDataError;
    const data = requestData || responseData;

    return (
        <Modal
            visible={visible}
            centered
            width="980px"
            title={modalTitle}
            onCancel={onClose}
            className={cn('form-modal', 'monitor-service-content-displayer-modal')}
            footer={null}
        >
            <Spin
                spinning={isRequestDataLoading || isResponseDataLoading}
            >
                <div
                    className="monitor-service-content-displayer-modal__content"
                >
                    {!isError
                        ? data
                        : (
                            <Empty
                                className="monitor-service-content-displayer-modal__content-error"
                                image={<ErrorAlert />}
                                description="Во время получения данных произошла ошибка"
                            />
                        )}
                </div>
            </Spin>
        </Modal>
    );
};
