import React, {ReactNode} from 'react';

import {FieldTooltipMetadata, FieldTooltipType} from 'modules/metadata/tooltip-metadata-types';

import {TooltipAsStringStructure} from './tooltip-content/tooltip-as-string-structure/tooltip-as-string-structure';
import {CurrentUserInfoTooltip} from './tooltip-content/tooltip-current-user-info/tooltip-current-user-info';
import {TooltipList} from './tooltip-content/tooltip-list/tooltip-list';

export const tooltipTypeResolver = (meta: FieldTooltipMetadata): ReactNode => {
    switch (meta.type) {
    case FieldTooltipType.LIST:
        return (
            <TooltipList
                entityName={meta.contentEntityName}
                url={meta.requestUrl}
            />
        );

    case FieldTooltipType.DYNAMIC_STRING:
        return (
            <TooltipAsStringStructure
                {...meta}
            />
        );

    case FieldTooltipType.CURRENT_USER_INFO_LIST:
        return <CurrentUserInfoTooltip entityName={meta.contentEntityName} />;
    default:
        return meta.contentStructure;
    }
};
