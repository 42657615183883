import React from 'react';

import {FormTable} from 'components/table/form-table';
import {PageTemplateProps} from 'pages';

interface FormTablePageProps extends PageTemplateProps {}

export const FormTablePage: React.FunctionComponent<FormTablePageProps> = ({
    entityName,
    url,
    hideTitle,
    entityType,
}: FormTablePageProps) => (
    <FormTable
        entityName={entityName}
        entityType={entityType}
        url={url}
        hideTitle={hideTitle}
    />
);
