import React from 'react';

import {CollapsibleHeader} from 'components/table-report/components/table-report-header/hocs/collapsible-header';
import {useAppSelector} from 'store/config/hooks';
import {
    selectTableReportHeaders,
    selectTableReportTemplateName,
} from 'store/slices/table-report-slice/table-report-slice-selectors';

import {DesignTemplates} from '../../table-report-types';
import {DefaultHeader} from './headers/default-header/default-header';
import {FilterHeader} from './headers/filter-header';
import {RowOutputHeader} from './headers/row-output-header/row-output-header';
import {shouldRenderHeader} from './table-report-header.utils';

interface TableReportHeaderProps {
    designTemplate?: string;
}

export const TableReportHeader = ({designTemplate}: TableReportHeaderProps) => {
    const headers = useAppSelector(selectTableReportHeaders);
    const templateName = useAppSelector(selectTableReportTemplateName);

    const name = headers?.name?.value ?? templateName;

    if (!name) return null;

    return (
        <CollapsibleHeader
            reportName={name}
            designTemplate={designTemplate}
        >
            {(() => {
                if (!shouldRenderHeader(designTemplate, headers)) return null;

                switch (designTemplate) {
                case DesignTemplates.DECLARATION:
                case DesignTemplates.REGISTERS:
                    return (
                        <DefaultHeader
                            headers={headers}
                        />
                    );
                case DesignTemplates.TABLE_REPORT_DESIGN_TEMPLATE_WITH_PARAMETER:
                    return <FilterHeader />;
                case DesignTemplates.POSTING_CARD:
                case DesignTemplates.REGISTER_WITH_ROW_HEADERS:
                    return (
                        <RowOutputHeader
                            headers={headers}
                        />
                    );
                default:
                    return null;
                }
            })()}
        </CollapsibleHeader>
    );
};
