import {CheckCircleOutlined} from '@ant-design/icons';
import {
    Button,
} from 'antd';
import Spin from 'antd/es/spin';
import cn from 'classnames';
import {uniq} from 'lodash';
import React, {useEffect, useRef, useState} from 'react';

import {ActionBar} from 'components/@common/widgets/action-bar';
import {CustomCard} from 'components/@common/widgets/custom-card';
import {CustomRadio} from 'components/@common/widgets/custom-radio';
import {ParametersFilter} from 'components/@common/widgets/parameters-filter';
import {ParametersFilterRef} from 'components/@common/widgets/parameters-filter/parameters-filter';
import {AIS_ROUTES} from 'components/ais/ais.constants';
import {IconsMap} from 'components/dynamic-icon';
import {UIBlocker} from 'components/ui-blocker';
import {ReactComponent as ReloadOutlined} from 'shared/assets/reload.svg';
import {ReactComponent as SettingsGear} from 'shared/assets/settings-gear.svg';
import {LocationStateKey, useAppHistory} from 'shared/hooks/use-app-history';
import {omitEmptyLines} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {aisSliceActions} from 'store/slices/ais-slice/ais-slice';
import {loadAisDocumentsByTaxRegister, loadAisDocumentsRegister} from 'store/slices/ais-slice/ais-slice-thunks';
import {selectIsThunkPending, selectThunkError} from 'store/slices/loading-state-slice';

import {extractFilterValuesFromRequestData} from '../documents-register-page/ais-documents-register-utils';
import {SetActiveFlagModalConfirmation} from '../documents-register-page/modals/set-active-flag-modal-confirmation';
import {AisDocumentsByTaxParametersFilter} from './ais-documents-by-tax-parameters-filter';
import {AisDocumentsByTaxRegisterTable, AisDocumentsByTaxRegisterTableRef} from './ais-documents-by-tax-register-table';
import {DocumentsByTaxRegisterParametersFilter, DocumentsByTaxRegisterParametersFilterForm} from './ais-documents-by-tax-register-types';

// import './ais-documents-by-tax-register.less';

interface AisDocumentsByTaxRegisterProps {}

export const AisDocumentsByTaxRegister: React.FC<AisDocumentsByTaxRegisterProps> = () => {
    const history = useAppHistory();

    const dispatch = useAppDispatch();

    const [
        documentsByTaxParametersFilter,
        setDocumentsByTaxParametersFilter] = useState<
     DocumentsByTaxRegisterParametersFilter | undefined
    >();

    const aisLocationState = history.currentState?.[LocationStateKey.AIS_REGISTER];

    const parametersFilterRef = useRef<ParametersFilterRef<DocumentsByTaxRegisterParametersFilterForm>>(null);

    const isLoadingRegisterByTaxTableData = useAppSelector(s => selectIsThunkPending(
        s, loadAisDocumentsByTaxRegister.typePrefix,
    ));

    const registerByTaxTableLoadError = useAppSelector(s => selectThunkError(
        s, loadAisDocumentsRegister.typePrefix,
    ));

    const aisDocumentsByTaxRegisterTableRef = useRef<AisDocumentsByTaxRegisterTableRef>(null);

    const resetPage = () => {
        if (documentsByTaxParametersFilter) setDocumentsByTaxParametersFilter(p => ({...p}));
        aisDocumentsByTaxRegisterTableRef?.current?.setSortData(p => ({...p, noFetch: true}));
    };

    const handleRefresh = () => {
        aisDocumentsByTaxRegisterTableRef?.current?.setSortData(() => ({
            filters: {},
            sort: {},
            noFetch: true,
        }));
        resetPage();
    };

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const selectedDocumentIds = (() => uniq(selectedRowKeys.map(rowKey => {
        const [, documentId] = (rowKey as string).split('_');
        return documentId;
    })))();

    useEffect(() => {
        if (aisLocationState && aisLocationState.requests.requestId !== undefined) {
            const {
                reporting_year: reportingYear,
                corr_num: corrNum,
                report_form: kndCode,
                tax_period: taxPeriod,
                tax_value_id: taxCode,
            } = aisLocationState?.requests.requestData ?? {};
            parametersFilterRef.current?.setInitialFormValues({
                ...extractFilterValuesFromRequestData(aisLocationState.requests.requestData, false),
                reportingYear,
                corrNum,
                kndCode,
                taxPeriod,
                taxCode,
            });
        }
    }, []);

    return (
        <div className={cn('ais-documents-by-tax-register')}>
            <CustomCard
                accordionTransitionType="1"
                title="Реестр документов, размещённых в информационной системе организации"
                accordionContent={(
                    <ParametersFilter<DocumentsByTaxRegisterParametersFilter>
                        ref={parametersFilterRef}
                        onChange={values => {
                            setDocumentsByTaxParametersFilter(omitEmptyLines(values));
                        }}
                        onClear={() => {
                            dispatch(aisSliceActions.resetRegisterDocumentsByTax());
                        }}
                        isFilterAppliedByDefault={false}
                    >

                        {AisDocumentsByTaxParametersFilter}
                    </ParametersFilter>
                )}
                isAccordionContentVisibleByDefault={!aisLocationState?.requests?.requestData}
            >
                <ActionBar>
                    <CustomRadio
                        defaultValue="tax_reg"
                        items={[
                            {
                                label: 'Реестр документов',
                                value: 'reg',
                                onClick: () => {
                                    history.push(AIS_ROUTES.DOCUMENTS_REGISTER);
                                },
                            },
                            {
                                label: 'Реестр документов по налогам',
                                value: 'tax_reg',
                            }]}
                    />

                    <SetActiveFlagModalConfirmation
                        documentIds={selectedDocumentIds}
                        setDocumentIds={setSelectedRowKeys}
                        component={(
                            <Button
                                type="primary"
                                disabled={!selectedRowKeys.length}
                            >
                                <CheckCircleOutlined />
                                Сделать доступным для отправки в АИС «Налог-3»
                            </Button>
                        )}
                    />

                    <Button
                        type="default"
                        icon={<SettingsGear />}
                    >
                        Настройки полей
                    </Button>
                    <Button
                        disabled={!documentsByTaxParametersFilter || isLoadingRegisterByTaxTableData}
                        type="default"
                        onClick={() => {
                            handleRefresh();
                        }}
                        icon={<ReloadOutlined />}
                    >
                        Обновить
                    </Button>
                    <>
                        {aisLocationState && (
                            <Button
                                type="default"
                                onClick={() => {
                                    const {requestId} = aisLocationState?.requests ?? {};
                                    if (requestId) {
                                        history.push({
                                            pathname: `/requests/${requestId}`,
                                        });
                                    }
                                }}
                            >
                                <IconsMap.ArrowBackOutlined />
                                Вернуться к запросу
                            </Button>
                        )}
                    </>
                </ActionBar>
                <Spin
                    spinning={isLoadingRegisterByTaxTableData}
                    tip="Загрузка данных реестра..."
                >
                    <UIBlocker
                        thunkError={{
                            error: registerByTaxTableLoadError,
                            verbose: true,
                        }}
                        extra={(
                            <>
                                <Button
                                    type="default"
                                    onClick={() => {
                                        handleRefresh();
                                    }}
                                    icon={<ReloadOutlined />}
                                >
                                    Обновить
                                </Button>
                            </>
                        )}
                    >
                        <AisDocumentsByTaxRegisterTable
                            ref={aisDocumentsByTaxRegisterTableRef}
                            selectedRowKeys={selectedRowKeys}
                            setSelectedRowKeys={setSelectedRowKeys}
                            documentsByTaxParametersFilter={documentsByTaxParametersFilter}
                        />
                    </UIBlocker>
                </Spin>
            </CustomCard>
        </div>
    );
};
