import {createApi} from '@reduxjs/toolkit/query/react';

import {
    GeneralSettingsEntity,
} from 'components/personal-account/tabs/general-settings/general-settings-types';

import {axiosBaseQuery} from '../base-query';

const generalSettingsApiUrl = '/settings.user.settings/settings.user.settings.general';

export const GeneralSettingsAPI = createApi({
    reducerPath: 'generalSettingsAPI',
    baseQuery: axiosBaseQuery(),
    endpoints: build => ({
        fetchGeneralSettings: build.query<GeneralSettingsEntity, null>({
            query: () => ({
                url: generalSettingsApiUrl,
                method: 'GET',
            }),
        }),
        putGeneralSettings: build.mutation({
            query: post => ({
                url: generalSettingsApiUrl,
                method: 'PUT',
                data: post,
            }),
        }),
    }),
});
