import {Input} from 'antd';

import {CustomSelect} from 'components/form/inputs/custom-select';

import {StaticSelect} from '../form/inputs/static-select';
import {DateCell} from '../table/columns/date-cell/date-cell';
import {FilesModalCell} from '../table/columns/file-modal-cell';
import {InputNumberCell} from '../table/columns/input-number-cell';
import {TextModalCell} from '../table/columns/text-modal-cell';
import {EditableInput} from './editable-table-types';

export const EditableTableInput = {
    [EditableInput.TEXT]: Input,
    [EditableInput.SELECT]: StaticSelect,
    [EditableInput.TEMPLATE_SELECT]: CustomSelect,
    [EditableInput.DATE]: DateCell,
    [EditableInput.INPUT_NUMBER]: InputNumberCell,
    [EditableInput.TEXT_MODAL]: TextModalCell,
    [EditableInput.FILES_MODAL]: FilesModalCell,
};
