import {FieldType} from 'modules/metadata';
import {TMetaOption} from 'modules/metadata-constructor/metadata-constructor.types';

import {COMMON_FIELDS_ITEMS} from '../common/common.fields-items';
import {TABLE_ACTIONS} from './table.actions';
import {TABLE_FIELDS} from './table.fields-for-type-fields';
import {TABLE_OPTIONS} from './table.options';

export const METADATA_TABLE: TMetaOption = {
    fields: [
        {
            key: 'useContext',
            label: 'Использовать контекстный фильтр',
            type: FieldType.BOOLEAN_CLASSIC,
            hint: 'Дает доступ к контекстному фильтру для страницы',
        },
        {
            key: 'isSelectable',
            label: 'Возможность выбора',
            type: FieldType.BOOLEAN_CLASSIC,
            hint: 'Добавляет возможность выбора записи таблицы.',
            isRequired: false,
        },
        {
            key: 'isSearchable',
            label: 'Добавление поиска на страницу',
            type: FieldType.BOOLEAN_CLASSIC,
            isHidden: true,
            hint: `
            На основе этого параметра будет добавляться поиск на страницу.
            Сейчас это немного по-другому сделано, через actions.`,
            isRequired: false,
        },
        {
            key: 'fields',
            label: 'Поля таблицы',
            type: FieldType.LIST,
            dataKeyForFilter: 'type',
            defaultItems: [
                COMMON_FIELDS_ITEMS.FIELDS.KEY,
                COMMON_FIELDS_ITEMS.FIELDS.LABEL,
                COMMON_FIELDS_ITEMS.FIELDS.IS_HIDDEN,
                {
                    key: 'type',
                    label: 'Тип',
                    type: FieldType.STATIC_SELECT,
                    options: TABLE_OPTIONS.FIELD_TYPES,
                    isRequired: true,
                },
            ],
            customItems: TABLE_FIELDS,
        },
        {
            key: 'actions',
            label: 'Действия таблицы',
            type: FieldType.LIST,
            dataKeyForFilter: 'actionType',
            defaultItems: [
                {
                    ...COMMON_FIELDS_ITEMS.ACTIONS.ACTION_TYPE,
                    options: TABLE_OPTIONS.ACTION_TYPES,
                },
                COMMON_FIELDS_ITEMS.ACTIONS.ACTION_TITLE,
                COMMON_FIELDS_ITEMS.ACTIONS.ACTION_ICON,
                {
                    key: 'isSubmenu',
                    label: 'Отображать в подменю',
                    type: FieldType.BOOLEAN_CLASSIC,
                },
            ],
            customItems: TABLE_ACTIONS,
        },
    ],
};
