import React from 'react';
import {Redirect} from 'react-router-dom';
import SwaggerUI from 'swagger-ui-react';

import {isProduction} from 'shared/utils/is-production';

import 'swagger-ui-react/swagger-ui.css';

export const SwaggerPage = () => {
    const swaggerUrl = isProduction() ? process.env.REACT_APP_SWAGGER_URL
        : `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SWAGGER_URL}`;

    if (!swaggerUrl) {
        return <Redirect to="/" />;
    }

    return <SwaggerUI url={swaggerUrl} />;
};
