import {
    createSelector,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';

import {AppState} from 'store/config/types';

import {modalsSliceName} from './modals-slice-constants';
import {ModalData, ModalsSliceState} from './modals-slice-types';

export const modalsSlice = createSlice({
    initialState: {
        modalData: {},
    } as ModalsSliceState,
    name: modalsSliceName,
    reducers: {
        setModalData(state, {payload}: PayloadAction<ModalData>) {
            state.modalData = payload;
        },
        closeModal(state) {
            state.modalData = {};
        },
    },
});

const selectModalsSliceState = (state: AppState) => state[modalsSliceName];

export const selectModalData = createSelector(selectModalsSliceState, state => state.modalData);

export const modalsSelectors = {
    selectModalsSliceState,
    selectModalData,
};

export const {
    reducer: modalsSliceReducer,
    actions: modalsSliceActions,
} = modalsSlice;

export const openModal = modalsSliceActions.setModalData;
export const {closeModal} = modalsSliceActions;
