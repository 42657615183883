import {Input} from 'antd';
import cn from 'classnames';
import React, {useState} from 'react';

import {ReactComponent as EditIcon} from 'shared/assets/edit-with-line.svg';

import {SetInputValueModal} from './set-input-value-modal/set-input-value-modal';

import './large-string-input.less';

export interface LargeStringInputProps {
    name: string;
    label?: string;
    value?: string;
    defaultValue?: string;
    disabled?: boolean;
    placeholder?: string;
    onChange?: (value: any) => void;
    saveButtonLabel?: string;
}

export const LargeStringInput = ({
    name, value, label, defaultValue, disabled, placeholder, onChange, saveButtonLabel,
}: LargeStringInputProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <Input
                disabled={disabled}
                className={cn('large-string-input', 'fake-input', !disabled && 'fake-disable')}
                value={value || defaultValue}
                placeholder={placeholder}
                suffix={<EditIcon />}
                onClick={() => {
                    setIsModalOpen(true);
                }}
            />
            {isModalOpen && label && (
                <SetInputValueModal
                    name={name}
                    value={value || defaultValue}
                    label={label}
                    visible={isModalOpen}
                    onChange={onChange}
                    closeModal={() => setIsModalOpen(false)}
                    saveButtonLabel={saveButtonLabel}
                />
            )}
        </>
    );
};
