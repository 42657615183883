import {FileDto} from 'shared/types/files';

export const convertDataFiles = (values: FileDto[]) => {
    const filesName: string[] = [];
    const fileList: any[] = [];
    values?.forEach((file: Record<string, any>) => (
        file?.file ? fileList.push(file?.file) : filesName.push(file?.name as string)));

    return fileList.length ? {filesName, fileList} : {filesName};
};
