import React, {useContext} from 'react';

import {TIconType} from 'components/dynamic-icon/dynamic-icon';
import {SimpleActionButton} from 'components/form/components';
import {RequestCardContext} from 'components/request/replies/context/request-card-context';
import {ButtonType} from 'modules/metadata/metadata-types';
import {URL_REQUESTS} from 'shared/constants/urls';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {selectRequestData} from 'store/slices/request-slice/request-selectors';
import {requestSliceActions} from 'store/slices/request-slice/request-slice';
import {createRequestStatus} from 'store/slices/request-slice/request-slice-api';
import {loadRequestData, loadRequestStatusesData} from 'store/slices/request-slice/request-slice-thunks';
import {RequestStatus} from 'store/slices/request-slice/request-slice-type';

export interface RequestSaveButtonProps {
    status: RequestStatus;
    requestFormKey: string;
    url: string;
    actionTitle?: string;
    actionIcon?: string;
    buttonType?: ButtonType;
    entityName?: string;
}

export const RequestSaveButton = ({
    actionTitle,
    actionIcon,
    buttonType,
    status,
    url,
    requestFormKey,
}: RequestSaveButtonProps) => {
    const dispatch = useAppDispatch();
    const {isEditing, setEditing} = useContext(RequestCardContext);
    const requestData: any = useAppSelector(selectRequestData);
    const requestId = requestData?.requests?.id;
    const requestCommentId = requestData?.[requestFormKey]?.idRequestComment;
    const {resetRequestStatusesData} = requestSliceActions;

    const handleChange = async () => {
        if (RequestStatus.SEND_MESSAGE === status) {
            if (isEditing && setEditing) setEditing();
        }
        if (requestCommentId) {
            dispatch(resetRequestStatusesData);
            await createRequestStatus()?.changeStatus(status, requestCommentId, url);
            dispatch(loadRequestStatusesData({commentId: requestCommentId, requestFormKey}));
        }

        if (requestId) {
            dispatch(loadRequestData({id: requestId, url: URL_REQUESTS}));
        }
    };
    return (
        <SimpleActionButton
            type={buttonType || 'text'}
            title={actionTitle}
            icon={actionIcon as TIconType}
            onClick={handleChange}
        />
    );
};
