import React from 'react';

import {HeaderCommonProps} from 'components/table-report/components/table-report-header/table-report-header.types';

import {GET_VALUE_FROM_MENU_INDICATOR} from '../../table-report-header.utils';
import './default-header.less';

export const DefaultHeader: React.FC<HeaderCommonProps> = (
    {headers}: HeaderCommonProps,
) => (
    <div className="default-header">
        <div className="default-header__content">
            <div className="default-header__caption">
                {headers?.caption && headers?.caption?.value !== '#MENU' && (
                    <div className="header-column">
                        <span className="header-column__name">Наименование:</span>
                        <span className="header-column__value">{headers.caption.value}</span>
                    </div>
                )}
            </div>
            <div className="default-header__info">
                {headers?.others?.map(header => header?.value !== null && header?.value !== undefined
                    && header.value !== GET_VALUE_FROM_MENU_INDICATOR
                    && (
                        <div
                            key={`info_${header.reportTitle}_${header.value}`}
                            className="header-column"
                        >
                            <span className="header-column__name">{header.reportTitle}:</span>
                            <span className="header-column__value">{header.value}</span>
                        </div>
                    ))}
            </div>
        </div>
    </div>
);
