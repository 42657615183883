import {FieldMeta, FieldType} from 'modules/metadata';
import {COMMON_OPTIONS} from 'modules/metadata-constructor/metadata/types/common/common.options';

export const getDataForFilter = (entityNameList: string[]): {key: number|string; fields: FieldMeta[]} => {
    const entityNameOptions = entityNameList?.map((name: string) => ({
        value: name,
        label: name,
    }));

    return ({
        key: Date.now(),
        fields: [
            {
                key: 'name',
                type: FieldType.STATIC_SELECT,
                label: 'Название сущности',
                options: entityNameOptions,
            },
            {
                key: 'viewType',
                type: FieldType.STATIC_SELECT,
                label: 'Тип сущности',
                options: COMMON_OPTIONS.VIEW_TYPES,
            },
        ],
    });
};
