import {Nullable} from './generics';

export type LinkParameters = {[index: string]: string};

export enum LinkMethod {
    GET = 'GET',
    POST = 'POST'
}

export enum LinkType {
    'INTERNAL' = 'INTERNAL',
    'EXTERNAL' = 'EXTERNAL',
    'FILE' = 'FILE',
    'FILE_WITH_TOKEN' = 'FILE_WITH_TOKEN',
    'EXTERNAL_WITH_TOKEN' = 'EXTERNAL_WITH_TOKEN',
    'MODAL' = 'MODAL',
    'MODAL_WITH_TOKEN' = 'MODAL_WITH_TOKEN'
}

export enum LinkModalViewType {
    'TABLE' = 'TABLE'
}

export type LinkDto<T = {
    url : string;
    urlType : LinkType;
    modalViewProperties: {
        type: LinkModalViewType;
        name: string;
        tableColumns: Record<string, string>;
    };
    method : LinkMethod;
    queryParameters : LinkParameters;
    bodyParameters : LinkParameters;
    filterParameters : LinkParameters;
    token?: string;
    tokenProperties: LinkTokenPropertiesDto;
    ignoreRequiredParameters: boolean;
    linkProperties: {
        title?: string;
        type?: 'button' | 'link';
        icon?: string;
        useTableValueAsTitle?: boolean;
        passBreadcrumbs?: boolean;
    };
    position: {
        column?: string;
        placement?: string;
        ordinal?: number;
        title?: string;
    };
  }> = Partial<Nullable<T, keyof T>>;

export type LinkTokenPropertiesDto<T = {
    url: string;
    method: LinkMethod;
    tokenKey: string;
    queryParemeters: LinkParameters;
    bodyParameters: LinkParameters;
}> = Partial<Nullable<T, keyof T>>;

export type LinkTableReportPositionInfo = {
    fieldName?: string;
    position?: LinkDto['position'];
    useTableValueAsTitle?: boolean;
}

export type LinkMeta = Pick<LinkDto, 'ignoreRequiredParameters'>; // вспомогательные данные для переходов
