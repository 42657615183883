import cn from 'classnames';
import React from 'react';

import {selectContextRawData} from 'modules/context/context-selector';
import {useAppSelector} from 'store/config/hooks';

import {AisAvailabilityStatusDashboard} from './dashboards/ais-availability-status-dashboard';
import {AisOperationsProtocolDashboard} from './dashboards/ais-operations-protocol-dashboard';
import {AisRegisterDashboard} from './dashboards/ais-register-dashboard';
import {AisRequestStatisticsDashboard} from './dashboards/ais-request-statistics-dashboard';
import {AisRequestStatusesDashboard} from './dashboards/ais-request-statuses-dashboard';
import {AisTokenStatusDashboard} from './dashboards/ais-token-status-dashboard';
import {AisUnavailabilityChartDashboard} from './dashboards/ais-unavailability-chart-dashboard';

import './ais-main-page.less';

export const AisMainPage = (
) => {
    const context = useAppSelector(selectContextRawData);

    return (
        <div className={cn('ais-main-page')}>
            <div
                className={cn('ais-main-page__column')}
                style={{flexBasis: '35%'}}
            >
                <AisRegisterDashboard
                    context={context}
                />
                <AisRequestStatusesDashboard
                    context={context}
                />
            </div>
            <div
                className={cn('ais-main-page__column')}
                style={{flexBasis: '30%'}}
            >
                <AisRequestStatisticsDashboard
                    context={context}
                />
                <AisOperationsProtocolDashboard
                    context={context}
                />
            </div>
            <div
                className={cn('ais-main-page__column')}
                style={{minWidth: 466}}
            >
                <AisAvailabilityStatusDashboard
                    context={context}
                />
                <AisTokenStatusDashboard
                    context={context}
                />
                <AisUnavailabilityChartDashboard
                    context={context}
                />
            </div>
        </div>
    );
};
