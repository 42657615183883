import {FieldType, MetaActionInfo} from 'modules/metadata';
import {TableEntityMeta} from 'modules/metadata/metadata-types';

interface PickDataFromQueryByAdditionalFieldsArgs {
    additionalInfoKeys: MetaActionInfo['additionalInfoKeys'];
    getQueryParam: (name: string) => string | null;
}

export const pickDataFromQueryByAdditionalFields = ({
    additionalInfoKeys,
    getQueryParam,
}: PickDataFromQueryByAdditionalFieldsArgs): Record<string, string> => {
    const valuesFromQuery: Record<string, any> = {};

    if (getQueryParam) {
        additionalInfoKeys
            ?.filter((key: string) => getQueryParam(key) != null)
            ?.forEach((key: string) => {
                valuesFromQuery[key] = getQueryParam(key);
            });
    }

    return valuesFromQuery;
};

interface ShouldPickDataFromQueryByAdditionalInfoKeysArgs {
    filterMeta: TableEntityMeta;
}

export const shouldPickDataFromQueryByAdditionalInfoKeys = (
    {filterMeta}: ShouldPickDataFromQueryByAdditionalInfoKeysArgs,
) => filterMeta?.fields?.some(f => f?.type === FieldType.QUERY_PARAMS_BOUND_SELECT);
