import {Modal} from 'antd';
import React, {useEffect} from 'react';

import {selectEntityData, resetLoadedData} from 'modules/data';
import {loadDummyData} from 'modules/data/data-actions';
import {ListGridEntityData} from 'modules/data/data-types';
import {selectMetadata, loadMetadata} from 'modules/metadata';
import {resetMetadata} from 'modules/metadata/metadata-actions';
import {ReactComponent as CloseIcon} from 'shared/assets/close.svg';
import {EntityType} from 'shared/constants/entities';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {selectModalData, ModalComponentInterface} from 'store/slices/modals-slice';

import {ListGrid} from '../list-grid';
import {Spinner} from '../spinner';

import './list-grid-modal.less';

export interface ListGridProps extends ModalComponentInterface {
}

export const ListGridModal = ({onClose}: ListGridProps) => {
    const dispatch = useAppDispatch();
    const modalData = useAppSelector(state => selectModalData(state));
    const entityName = modalData?.entityName || '';

    const data = useAppSelector(
        state => selectEntityData(entityName, EntityType.LIST_GRID)(state) as ListGridEntityData,
    );
    const meta = useAppSelector(state => selectMetadata(entityName, EntityType.LIST_GRID)(state));
    const [loading, setIsLoading] = React.useState(false);

    useEffect(() => {
        (async () => {
            if (!meta) {
                setIsLoading(true);
                await dispatch(loadMetadata(entityName, EntityType.LIST_GRID));
                setIsLoading(false);
            }

            if (!data) {
                setIsLoading(true);
                await dispatch(loadDummyData(entityName, EntityType.LIST_GRID, 'lists'));
                setIsLoading(false);
            }
        })();
        return () => {
            dispatch(resetLoadedData(entityName, EntityType.LIST_GRID));
            dispatch(resetMetadata({
                entityType: EntityType.LIST_GRID,
                loading: false,
                entityName: entityName || '',
            }));
        };
    }, []);

    return (
        <Modal
            className="list-grid-modal"
            footer={null}
            visible
            centered
            width={meta?.width || 680}
            onCancel={onClose}
            maskClosable={false}
            title={meta?.title}
            closeIcon={<CloseIcon className="list-modal__close-icon" />}
        > {!loading && data?.lists
                ? (
                    <ListGrid
                        data={data?.lists}
                        meta={meta}
                    />
                ) : <div className="list-modal__spinner"><Spinner /></div>}
        </Modal>
    );
};
