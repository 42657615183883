import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';

import {TableContainer, TableContainerProps} from 'components/table/table-container';
import {EntityType} from 'shared/constants/entities';

import './document-types-codes.less';

export const DocumentTypeCodes = ({entityName, url}: TableContainerProps) => {
    const history = useHistory();
    const entityUrl = `${url}/${entityName}`;

    useEffect(() => {
        history.replace(entityUrl);
    }, [entityName, history]);

    return (
        <div className="document-type-codes">
            <TableContainer
                entityName={entityName}
                entityType={EntityType.TABLE}
                hideTitle
                url={entityUrl}
            />
        </div>
    );
};
