import {ActionReducerMapBuilder} from '@reduxjs/toolkit';

import {transformFilterExpressionDtoToExpressionArray} from 'components/report-configuration/tabs/report-dd-filters/dd-filter-expression-creator/dd-filter-expression-creator-utils';

import {ReportConfigurationSliceState} from '../report-configuration-types';
import {loadDrilldownFilter, updateDrilldownFilter} from './dd-filters-configuration-thunks';

export const createDdFiltersConfigurationExtraReducer = (
    builder: ActionReducerMapBuilder<ReportConfigurationSliceState>,
) => {
    builder.addCase(loadDrilldownFilter.fulfilled, (state, {payload: ddFilterData, meta}) => {
        const {
            ddFilterExpressionDto,
            tableReportFilterLookups,
            childReportColumns,
        } = ddFilterData ?? {};

        const {childReportTemplateCode} = meta.arg ?? {};

        if (ddFilterExpressionDto) {
            state.drilldownSettings.ddFilter = transformFilterExpressionDtoToExpressionArray(
                ddFilterExpressionDto,
            );
        }

        state.ddLookups.filters = tableReportFilterLookups;
        if (childReportColumns && childReportTemplateCode) {
            state.ddLookups.tableColumns[childReportTemplateCode] = childReportColumns;
        }
    });
    builder.addCase(updateDrilldownFilter.fulfilled, (state, {meta}) => {
        const {filter} = meta.arg;
        state.drilldownSettings.ddFilter = transformFilterExpressionDtoToExpressionArray(filter);
    });
};

export const createDdFiltersConfigurationReducer = <S extends ReportConfigurationSliceState>() => ({
    purgeDrilldownFilter(
        state: S,
    ) {
        state.drilldownSettings.ddFilter = undefined;
    },
});
