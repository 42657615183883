import {
    Modal,
} from 'antd';
import React from 'react';

import {ModalComponentInterface} from 'store/slices/modals-slice';

import {IntegratedFilter} from '../table/action/filters';

interface FormModalProps extends ModalComponentInterface {
    entityName: string;
}

export const FilterModal: React.FunctionComponent<FormModalProps> = ({
    onClose,
    entityName,
}: FormModalProps) => (
    <Modal
        footer={null}
        visible
        width="100vw"
        onCancel={onClose}
        maskClosable={false}
    >
        <IntegratedFilter
            isIntegratedFilter={false}
            entityName={entityName}
            onClose={onClose}
        />
    </Modal>
);
