import {Button, Space} from 'antd';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {DATA_MODULE} from 'modules/data';
import {setData} from 'modules/data/data-actions';
import {EntityType} from 'shared/constants/entities';
import {AppState} from 'store/config/types';

interface ActionDeleteCellProps {
    entityName: string;
    record: any;
    text?: string;
}

export const ActionDeleteCell: React.FunctionComponent<ActionDeleteCellProps> = ({
    entityName,
    record,
    text = 'Удалить',
}: ActionDeleteCellProps) => {
    const dispatch = useDispatch();
    const data = useSelector(
        (state: AppState) => state[DATA_MODULE]?.[entityName]?.[EntityType.TABLE].rows,
    );
    const handlerClick = () => {
        const newData = data?.filter(item => record.id !== item?.id);
        dispatch(setData({
            entityName,
            entityType: EntityType.TABLE,
            loading: false,
            data: {
                rows: newData,
            },
        }));
    };

    return (
        <Space size="middle">
            <Button
                type="link"
                onClick={handlerClick}
            >
                {text}
            </Button>
        </Space>
    );
};
