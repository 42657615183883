import React from 'react';

import {TabbedForm} from 'components/@common/widgets/tabbed-form';
import {Changelog} from 'components/@common/widgets/tabbed-form/common-tabs/changelog';
import {ChangelogEntity} from 'components/@common/widgets/tabbed-form/common-tabs/changelog/changelog.types';
import {
    TCFileExchangeTabs,
    TCFileExchangeTabTitles,
    TCNotFoundMessage,
} from 'components/ais/transport-container-card/constants/transport-container-card.contants';
import {TransportContainerInformation} from 'components/ais/transport-container-card/tabs/transport-container-information/transport-container-information';
import {useGetTransportContainerQuery} from 'store/api/transport-container/transport-container.api';

export interface TransportContainerCardProps {
    id: string;
    entityName: string;
}

export const TransportContainerCard = ({
    id,
    entityName,
}: TransportContainerCardProps) => {
    const {
        data: transportContainer,
        isLoading,
    } = useGetTransportContainerQuery({id}, {skip: !id});

    const changelogData: ChangelogEntity[] | undefined = transportContainer?.audits?.map(a => ({
        id: a.id,
        stage: a.stage,
        statusCode: a.statusCode,
        statusText: a.statusText,
        createdDate: a.createdDate,
        updatedDate: a.updatedDate,
    }));

    return (
        <TabbedForm
            title={transportContainer ? `Карточка ${transportContainer.name}` : ' '}
            defaultTabKey={TCFileExchangeTabs.main}
            isLoading={isLoading}
            modalWrapped
            emptyProps={{
                isEmpty: !transportContainer,
                description: TCNotFoundMessage,
            }}
            tabs={transportContainer && (
                [
                    {
                        key: TCFileExchangeTabs.main,
                        title: TCFileExchangeTabTitles.main,
                        content: (
                            <TransportContainerInformation
                                transportContainerId={id}
                                transportContainer={transportContainer}
                                entityName={entityName}
                            />
                        ),
                    },
                    {
                        key: TCFileExchangeTabs.changes,
                        title: TCFileExchangeTabTitles.changes,
                        content: changelogData && (
                            <Changelog
                                changelogData={changelogData}
                            />
                        ),
                    },
                ]
            )}
        />
    );
};
