import {ActionReducerMapBuilder, PayloadAction} from '@reduxjs/toolkit';

import {
    ddParametersAdapter,
    selectDdParametersState,
} from '../report-configuration-slice';
import {ReportConfigurationSliceState} from '../report-configuration-types';
import {
    updateDrilldownParameter,
    loadDrilldownParameters,
    deleteDrilldownParameter,
    loadDDChildReportQueryParameters,
} from './dd-parameters-configuration-thunks';
import {ReportDdParameter} from './dd-parameters-configuration-types';

export const createDdParametersExtraReducer = (
    builder: ActionReducerMapBuilder<ReportConfigurationSliceState>,
) => {
    builder.addCase(loadDDChildReportQueryParameters.fulfilled, (state, {payload}) => {
        state.drilldownSettings.ddChildReportQueryParameters = payload.queryParameters;
    });
    builder.addCase(deleteDrilldownParameter.fulfilled, (state, {meta}) => {
        const {id} = meta.arg;
        ddParametersAdapter.removeOne(selectDdParametersState(state), id);
    });
    builder.addCase(updateDrilldownParameter.fulfilled, (state, {payload: newDdDynamicValue}) => {
        ddParametersAdapter.upsertOne(selectDdParametersState(state), newDdDynamicValue);
    });
    builder.addCase(loadDrilldownParameters.fulfilled, (state, {payload}) => {
        ddParametersAdapter.setAll(selectDdParametersState(state), payload);
    });
};

export const createDdDynamicValuesReducer = <S extends ReportConfigurationSliceState>() => ({
    setDdParameter(
        state: S, {payload: ddDynamicValue}: PayloadAction<ReportDdParameter>,
    ) {
        ddParametersAdapter.setOne(selectDdParametersState(state), ddDynamicValue);
    },
    addDdParameters(
        state: S, {payload: newDdDynamicValue}: PayloadAction<ReportDdParameter>,
    ) {
        ddParametersAdapter.addOne(selectDdParametersState(state), newDdDynamicValue);
    },
    setDdParameters(state: S, {payload: newDdDynamicValues}: PayloadAction<ReportDdParameter[]>) {
        ddParametersAdapter.setAll(selectDdParametersState(state), newDdDynamicValues);
    },
});
