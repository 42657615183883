import {FormInstance} from 'antd/es/form';
import {useEffect, useState} from 'react';

export interface WatcherCallbackArg {
    value: any;
    formValues: ReturnType<FormInstance['getFieldsValue']>;
    formInstance: FormInstance;
}

export interface FieldWatchersApplicationResult {
    [key: string]: { [key: string]: boolean };
}

export enum WatcherFieldFlag {
    isHidden = 'isHidden',
    isDisabled = 'isDisabled',
    isRequired = 'isRequired',
    isLoading = 'isLoading',
    shouldFilterLookup = 'shouldFilterLookup',
}

export interface Watcher {
    isNotInput?: boolean;
    condition: (arg: WatcherCallbackArg) => boolean;
    perform?: (arg: WatcherCallbackArg) => void;
    flag?: WatcherFieldFlag;
}

export interface UseFormFieldsManager {
    formInstance: FormInstance;
    watchers: { [fieldKey: string]: Watcher[] };
    triggerDeps?: any[];
}

export type FieldWatcher = UseFormFieldsManager['watchers'][string][0];

export const useFormFieldsManager = ({
    formInstance,
    watchers,
    triggerDeps = [],
}: UseFormFieldsManager) => {
    const [watchersApplicationResult, setWatchersApplicationResult] = useState<FieldWatchersApplicationResult>(
        Object.keys(watchers).reduce((prev, cur) => {
            prev[cur] = {};
            return prev;
        }, {} as FieldWatchersApplicationResult),
    );

    const applyFieldWatchers = () => {
        const formValues = formInstance.getFieldsValue();
        const applicationResult: FieldWatchersApplicationResult = {};
        Object.entries(watchers).forEach(([fieldKey, watchersList]) => {
            const applyWatcher = (watcher: FieldWatcher) => {
                const {
                    condition, flag, perform, isNotInput,
                } = watcher;
                const watcherCallbackArg = {
                    value: isNotInput ? undefined : formInstance.getFieldValue(fieldKey),
                    formValues,
                    formInstance,
                };

                const isTriggered = condition(watcherCallbackArg);

                (() => { // put application result
                    if (!flag) return;
                    if (!applicationResult[fieldKey]) applicationResult[fieldKey] = {};
                    applicationResult[fieldKey][flag] = isTriggered;
                })();

                if (isTriggered && perform) perform(watcherCallbackArg);
            };
            watchersList.forEach(watcher => {
                applyWatcher(watcher);
            });
        });
        setWatchersApplicationResult(applicationResult);
        return applicationResult;
    };

    useEffect(() => {
        if (triggerDeps.length) applyFieldWatchers();
    }, [...triggerDeps]);

    const checkWatcherFlag = (fieldKey: string, flag: WatcherFieldFlag) => {
        if (!watchersApplicationResult[fieldKey]) {
            throw new Error(`Нет обработчика для поля ${fieldKey} в useFormFieldsManager.`);
        }
        return watchersApplicationResult[fieldKey][flag] === true;
    };

    return {
        watchersApplicationResult,
        applyFieldWatchers,
        checkWatcherFlag,
    };
};

interface CreateDisableFieldOnValueWatcherArgs {
    value: any;
    setTo: any;
}

export const createDisableFieldOnValueWatcher = (
    {
        value,
        setTo,
    }: CreateDisableFieldOnValueWatcherArgs,
) => ({
    condition: () => value === setTo,
    flag: WatcherFieldFlag.isDisabled,
});
