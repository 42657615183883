import {Button} from 'antd';
import cn from 'classnames';
import React from 'react';

import {ModalOpenerComponent} from 'components/modal-opener-component';
import {DefaultModalOpenerFormListProps} from 'components/report-configuration/report-configuration.types';
import {IconFormatSettingsModalContent} from 'components/report-configuration/tabs/report-table-settings/modals/icon-format-settings-modal-opener/icon-format-settings-modal-content/icon-format-settings-modal-content';
import {ReactComponent as SquareInnerX} from 'shared/assets/square-inner-x.svg';

export const IconFormatSettingsModalOpener = ({
    form,
    name,
    disabled,
}: DefaultModalOpenerFormListProps) => (
    <ModalOpenerComponent
        controlLabels={{
            save: 'Подтвердить',
        }}
        handleCancel={setIsModalOpen => {
            setIsModalOpen(false);
        }}
        handleSave={setIsModalOpen => {
            form.validateFields();
            setIsModalOpen(false);
        }}
        component={(
            <Button
                type="primary"
                className={cn('button-only-icon')}
                disabled={disabled}
            >
                <SquareInnerX className={cn('path-stroke-change-color')} />
            </Button>
        )}
        modalProps={{
            forceRender: false,
            destroyOnClose: true,
            centered: true,
            title: 'Правила форматирования иконки',
        }}
    >
        <IconFormatSettingsModalContent
            form={form}
            name={name}
            disabled={disabled}
        />
    </ModalOpenerComponent>
);
