import {PlusCircleOutlined} from '@ant-design/icons';
import {Button, Checkbox, Popconfirm} from 'antd';
import cn from 'classnames';
import {omitBy} from 'lodash';
import React, {
    createRef, useContext, useEffect, useImperativeHandle, useRef, useState,
} from 'react';
import {v4 as uid} from 'uuid';

import {CustomRadio} from 'components/@common/widgets/custom-radio';
import {IconsMap} from 'components/dynamic-icon';
import {isEmptyObject} from 'shared/utils';

import {ReportDdFilterRulesContext} from '../report-dd-filters-utils';
import {DDFilterExpessionGroupIdentificator, DDFilterExpression, DDFilterOperand} from './dd-filter-expression-creator-types';
import {DDFilterExpressionOperand, DDFilterExpressionOperandRef} from './dd-filter-expression-operand';

interface DDFilterExpressionGroupProps {
    initialExpression: DDFilterExpression;
    className?: cn.Argument;
    isChild?: boolean;
    remove?: () => void;
    isInEditMode: boolean;
    depth: number;
    itemIndex: string;
}

export interface DDFilterExpressionGroupRef {
    getValues: (skipValidation?: boolean) => any;
    setExpression: (exp: DDFilterExpression) => void;
}

export const DDFilterExpressionGroup = React.forwardRef<DDFilterExpressionGroupRef, DDFilterExpressionGroupProps>((
    {
        initialExpression,
        className,
        isChild,
        remove,
        isInEditMode,
        depth,
        itemIndex,
    }: DDFilterExpressionGroupProps,
    ref,
) => {
    const fillOperandsWithUid = (operands: DDFilterOperand[]) => operands.map(oper => {
        if (!Array.isArray(oper)) {
            return {
                uid: oper.uid ?? uid(),
                ...oper,
            };
        }

        return oper;
    });

    const [identificator, setIdentificator] = useState({
        uid: uid(),
        enabled: true,
        ...initialExpression[0],
    } as DDFilterExpessionGroupIdentificator);

    const [operands, setOperands] = useState<DDFilterOperand[]>(
        fillOperandsWithUid(initialExpression.slice(1) as DDFilterOperand[]),
    );

    const {
        filterInitialExpression,
    } = useContext(ReportDdFilterRulesContext);

    useEffect(() => {
        setOperands(fillOperandsWithUid(initialExpression.slice(1) as DDFilterOperand[]));
        setIdentificator(p => ({...p, ...initialExpression[0]}));
    }, [filterInitialExpression]);

    const operandRefs = useRef<{current: DDFilterExpressionOperandRef}[]>([]);
    operandRefs.current = operands.map((element, i) => operandRefs.current[i] ?? createRef());

    const getValues = async (skipValidation?: boolean) => {
        const list = operandRefs.current ?? [];

        const valuesFromOperand = await Promise.all(list.map(item => item.current.getValues(skipValidation)));

        const values = valuesFromOperand.filter(vals => {
            if (typeof vals === 'object' && isEmptyObject(omitBy(vals, v => !v))) return false;
            if (Array.isArray(vals) && vals.length === 1) return false;
            return true;
        });

        return [
            {connectBy: identificator.connectBy, enabled: identificator.enabled},
            ...values,
        ];
    };

    const setExpression = (exp: DDFilterExpression) => {
        setIdentificator({
            uid: exp[0]?.uid ?? uid(),
            ...exp[0],
        });
        setOperands(fillOperandsWithUid(exp.slice(1) as DDFilterOperand[]));
    };

    useImperativeHandle(ref, () => ({getValues, setExpression}));

    return (
        <div
            className={cn('dd-filter-expression-group', {
                'dd-filter-expression-group_main': !isChild,
            }, className)}
        >
            <div className={cn('fw-500 mb-1-5 d-flex align-items-center gap-2')}>
                {!depth && itemIndex === '1' ? 'Фильтр' : `Группа №${itemIndex}`}
                <div>
                    <Checkbox
                        disabled={!isInEditMode}
                        checked={identificator.enabled}
                        onChange={e => {
                            setIdentificator(p => ({
                                ...p,
                                enabled: e.target.checked,
                            }));
                        }}
                    >Активно
                    </Checkbox>
                </div>
            </div>
            <div
                className={cn('dd-filter-expression-group__upbar', {
                    'dd-filter-expression-group__upbar_sticky': !depth,
                })}
            >
                <div className="d-flex gap-1-5 align-items-center">
                    <CustomRadio
                        disabled={!isInEditMode}
                        value={identificator.connectBy}
                        onChange={e => {
                            setIdentificator(p => ({
                                ...p,
                                connectBy: e.target.value,
                            }));
                        }}
                        style={{background: 'white'}}
                        items={[{
                            label: 'Оператор «ИЛИ»',
                            value: 'OR',
                        }, {
                            label: 'Оператор «И»',
                            value: 'AND',
                        }]}
                    />
                </div>
            </div>
            <div
                className={cn(
                    'dd-filter-expression-group__operands',
                )}
            >
                <div className={cn({
                    'dd-filter-expression-group__operands__border': operands.length,
                })}
                />
                <div className={cn('dd-filter-expression-group__operands__body')}>
                    {operands.map((oper, index) => (
                        <DDFilterExpressionOperand
                            isInEditMode={isInEditMode}
                            ref={operandRefs.current[index]}
                            operand={oper}
                            key={(() => {
                                const key = !Array.isArray(oper) ? oper.uid : (oper?.[0].uid ?? uid());
                                return key;
                            })()}
                            remove={() => {
                                setOperands(() => {
                                    const newOpers = operands.filter((v, i) => i !== index);
                                    return newOpers;
                                });
                            }}
                            depth={depth + 1}
                            itemIndex={!depth && itemIndex === '1' ? `${index + 1}` : `${itemIndex}.${index + 1}`}
                        />
                    ))}
                    {!operands.length && (
                        <div className={cn('dd-filter-expression-group__operands__body__no-rules')}>
                            Необходимо добавить правило или группу
                        </div>
                    )}
                </div>
            </div>
            <div
                className={cn('d-flex gap-1 align-items-center justify-content-end', {
                    'mt-2': !!operands.length,
                    'mt-5': !operands.length,
                })}
            >
                <Button
                    disabled={!isInEditMode}
                    type="default"
                    onClick={async () => {
                        const lastOperand = operandRefs.current[operandRefs?.current?.length - 1];
                        const lastOperandValues = await lastOperand?.current?.getValues?.(true);

                        const prefilledData = (() => {
                            if (!lastOperandValues || Array.isArray(lastOperandValues)) return {};
                            const {
                                operatorCode,
                                fieldName,
                            } = lastOperandValues;
                            return {
                                operatorCode,
                                fieldName,
                            };
                        })();

                        setOperands(p => [...p, {
                            fieldName: prefilledData?.fieldName,
                            operatorCode: prefilledData?.operatorCode,
                            parametrized: false,
                            enabled: true,
                            comparisonValue: '',
                            uid: uid(),
                        }]);
                    }}
                >
                    <PlusCircleOutlined />
                    Добавить правило
                </Button>
                <Button
                    disabled={!isInEditMode}
                    type="default"
                    onClick={() => {
                        setOperands(p => [...p, [{
                            connectBy: 'OR',
                            uid: uid(),
                            enabled: true,
                        }]]);
                    }}
                >
                    <PlusCircleOutlined />
                    Добавить группу
                </Button>
                <Popconfirm
                    title={(
                        <span>Вы действительно хотите удалить группу?</span>
                    )}
                    placement="bottomLeft"
                    okText="Удалить"
                    disabled={!operands.length || !isChild}
                    onConfirm={() => {
                        remove?.();
                    }}
                >
                    <div>
                        <Button
                            disabled={!isChild || !isInEditMode}
                            type="default"
                            className="btn-only-icon"
                            onClick={() => {
                                if (!operands.length) remove?.();
                            }}
                        >
                            <IconsMap.TrashXOutlined />
                        </Button>
                    </div>
                </Popconfirm>
            </div>
        </div>
    );
});
