import React from 'react';

import {SubsectionTable} from 'components/table/subsection-table';
import {PageTemplateProps} from 'pages';

export const SubsectionTablePage: React.FunctionComponent<PageTemplateProps> = ({
    entityName,
    url,
    hideTitle,
    entityType,
}: PageTemplateProps) => (
    <SubsectionTable
        entityName={entityName}
        entityType={entityType}
        url={url}
        hideTitle={hideTitle}
    />
);
