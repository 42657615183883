import {DffSegmentValues} from 'shared/types/lookups';
import {
    DescriptiveFlexFieldData,
    DescriptiveFlexfieldSegmentMinimizedEntity,
} from 'store/slices/descriptive-flexfields-slice/descriptive-flexfields-slice-types';

import {FlexFieldInitValues} from './document-card-modal-types';

interface ConvertAdditionalFieldsTabValuesArgs {
    values: Record<string, string>;
}

export const convertAdditionalFieldsTabValues = (
    {values}: ConvertAdditionalFieldsTabValuesArgs,
): DescriptiveFlexfieldSegmentMinimizedEntity => {
    const additionalFieldsConverted: DescriptiveFlexfieldSegmentMinimizedEntity = {};

    Object
        .entries(values)
        .forEach(([key, value]) => {
            additionalFieldsConverted[key] = {
                segmentColumnName: key,
                segmentValue: value,
            };
        });

    return additionalFieldsConverted;
};

export const transformDtoToFlexFieldInitValues = (
    flexFieldDataDto: DescriptiveFlexFieldData,
): FlexFieldInitValues => Object.keys(flexFieldDataDto).reduce((acc, key) => {
    const segment = flexFieldDataDto[key];
    acc[segment.segmentColumnName] = segment.segmentValue;
    return acc;
}, {} as FlexFieldInitValues);

export const transformSegmentToInitialValues = (dffSegmentValues: DffSegmentValues | null | undefined) => {
    if (dffSegmentValues === null || dffSegmentValues === undefined) return null;
    return Object.keys(dffSegmentValues).reduce((acc: { [key: string]: string | null }, key: string) => {
        const segment = dffSegmentValues[key];
        acc[segment.segmentColumnName] = segment.segmentValue;
        return acc;
    }, {});
};
