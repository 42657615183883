import {unwrapResult} from '@reduxjs/toolkit';
import React, {useContext} from 'react';
import {useHistory} from 'react-router-dom';

import {ButtonType} from '../../../../modules/metadata/metadata-types';
import {showMessage} from '../../../../shared/utils';
import {useAppDispatch, useAppSelector} from '../../../../store/config/hooks';
import {selectRequestData} from '../../../../store/slices/request-slice/request-selectors';
import {removeRequest} from '../../../../store/slices/request-slice/request-slice-thunks';
import {
    RequestData,
    RequestStatus,
} from '../../../../store/slices/request-slice/request-slice-type';
import {TIconType} from '../../../dynamic-icon/dynamic-icon';
import {SimpleActionButton} from '../../../form/components';
import {
    RequestFormFormKey,
    RequestFormTitles,
} from '../../request-form/request-form.constants';
import {RequestFormContext} from '../../request-form/request-form.context';

export interface DeleteButtonProps {
  status: RequestStatus;
  requestFormKey: string;
  actionTitle?: string;
  actionIcon?: string;
  buttonType?: ButtonType;
  entityName?: string;
}

export const DeleteButton = ({
    actionTitle,
    actionIcon,
    buttonType,
    requestFormKey,
}: DeleteButtonProps) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const {selectedTabKey, entityName, setSelectedTabKey} = useContext(RequestFormContext);
    const requestData = useAppSelector(selectRequestData);
    const handleChange = () => {
        const commentId = requestData?.[requestFormKey as keyof RequestData]?.idRequestComment;
        dispatch(removeRequest({
            commentId,
        })).then(unwrapResult)
            .then(() => {
                if (selectedTabKey === RequestFormFormKey.REQUESTS) {
                    showMessage({message: `«${RequestFormTitles?.[selectedTabKey]}» успешно удалён`});
                    history.push(`/${entityName}`);
                    return;
                }
                if (setSelectedTabKey) {
                    setSelectedTabKey(RequestFormFormKey.REQUESTS);
                }
            }, error => {
                showMessage({message: error, isError: true});
            });
    };

    return (
        <SimpleActionButton
            type={buttonType || 'text'}
            title={actionTitle}
            icon={actionIcon as TIconType}
            onClick={handleChange}
        />
    );
};
