import React from 'react';

import {useUserRoleFunctions} from 'shared/hooks/use-user-role-functions';
import {UserRoleFunction} from 'store/slices/user-slice/user-slice-role-functions';

import {EditableField, EditableFieldProps} from '../editable-field/editable-field';

export interface ReportLinkFieldProps extends EditableFieldProps {
    record: any;
}

// Обертка над EditableField; используется для отображения ссылки только в случае наличия отчета jasperReport
// или portalReport(он же tableReport)
export const ReportLinkField = (props: ReportLinkFieldProps) => {
    const {value, record, entityName} = props;

    const {
        hasUserRoleFunction,
    } = useUserRoleFunctions({sections: [`app.${entityName}.title`]});

    return (
        <EditableField
            {...props}
            reportType={value?.documentType?.reportTypeCode}
            editProperties={{
                hide: record.docStatusCode === 'PUBLISHED',
                disable: !hasUserRoleFunction(UserRoleFunction.CREATE),
            }}
        />
    );
};
