import {LinkDto} from 'shared/types/links';

type FormatLinkToPassFunctionArgs = {
   value: any;
   record: Record<string, any>;
   useTableValueAsTitleInLink?: boolean;
}

export const formatLinkToPass = ({
    record,
    value: rawValue,
    useTableValueAsTitleInLink,
}: FormatLinkToPassFunctionArgs) => {
    let linkToPass: LinkDto = {...rawValue};
    if (useTableValueAsTitleInLink && linkToPass.position?.column) {
        linkToPass = {
            ...rawValue,
            linkProperties: {
                ...rawValue.linkProperties,
                title: record[linkToPass.position.column],
            },
        };
    }
    return linkToPass;
};
