import {createAsyncThunk} from '@reduxjs/toolkit';
import {AxiosResponse} from 'axios';

import {GetJasperReportInfoData, getJasperReportInfo, getJasperReportUrl} from './jasper-report-slice-api';
import {jasperReportSliceName} from './jasper-report-slice-constants';

export interface LoadJasperReportInfoArgs {
    docId?: string;
    entityName: string;
}

export const loadJasperReportInfo = createAsyncThunk<AxiosResponse<GetJasperReportInfoData>, LoadJasperReportInfoArgs>(
    `${jasperReportSliceName}/loadJasperReportInfo`,
    async (args, {rejectWithValue}) => {
        try {
            const {
                docId,
                entityName,
            } = args;

            const reportUrl = docId ? `/report/?docId=${docId}` : (await getJasperReportUrl(entityName)).data as string;
            const response = await getJasperReportInfo(reportUrl);

            return response;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);
