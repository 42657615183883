import {get} from 'lodash';
import {useEffect, useState} from 'react';

import {
    FieldWatcher,
    FieldWatchersApplicationResult,
    UseFormFieldsManager, Watcher,
    WatcherFieldFlag,
} from './use-form-fields-manager';

export interface FieldArrayWatchersApplicationResult {
    [key: string]: FieldWatchersApplicationResult; // key here is array index
}

export interface UseFormListFieldsManagerProps<T, FormArrayKey> extends UseFormFieldsManager {
    arrayIndependentWatchers?: { [key: string]: Watcher[] };
    formArrayKey: FormArrayKey;
    modifiers?: {
        trackFormDirtyState: boolean;
        isDirty: boolean;
        setIsDirty: (v: boolean) => void;
    };
    performanceModifiers?: {
        // skips watchers reassign, потому что некоторые поля могут не быть триггером изменения watchers
        checkIfShouldSkip: (changedValues: T) => boolean;
    };
}

export const useFormListFieldsManager = <
    FormArrayKey extends string, RowType, T extends Record<FormArrayKey, RowType[]>
>({
        formInstance,
        watchers,
        triggerDeps = [],
        formArrayKey,
        arrayIndependentWatchers,
        modifiers,
        performanceModifiers,
    }: UseFormListFieldsManagerProps<T, FormArrayKey>) => {
    const [
        watchersApplicationResult, setWatchersApplicationResult,
    ] = useState<FieldArrayWatchersApplicationResult>({});
    const [
        arrayIndependentWatcherResult, setArrayIndependentWatcherResult,
    ] = useState<FieldWatchersApplicationResult>({});

    const {trackFormDirtyState, setIsDirty, isDirty} = modifiers || {};

    const applyWatcher = (
        watcher: FieldWatcher, formValues: any, fieldKey: string, applicationResult: FieldWatchersApplicationResult,
    ) => {
        const {
            condition, flag, perform, isNotInput,
        } = watcher;
        const watcherCallbackArg = {
            value: isNotInput ? undefined : get(formValues, fieldKey),
            formValues,
            formInstance,
        };

        const isTriggered = condition(watcherCallbackArg);

        (() => { // put application result
            if (!flag) return;
            if (!applicationResult[fieldKey]) applicationResult[fieldKey] = {};
            applicationResult[fieldKey][flag] = isTriggered;
        })();

        if (isTriggered && perform) perform(watcherCallbackArg);
    };

    const applyFieldWatchers = (changedValues?: T, shouldSetDirty = true) => {
        const formValues = formInstance.getFieldsValue();

        if (trackFormDirtyState && isDirty !== true && changedValues && changedValues[formArrayKey]) {
            if (shouldSetDirty) setIsDirty?.(true);
        }

        if (performanceModifiers && changedValues) {
            if (performanceModifiers.checkIfShouldSkip(changedValues)) return;
        }

        const watchedArray: [] = formValues[formArrayKey];
        if (!watchedArray || !Array.isArray(watchedArray)) {
            return;
        }
        const collectedWatchersResultArray: FieldArrayWatchersApplicationResult = {};
        watchedArray.forEach((arrayItemValues: any, index) => {
            const applicationResult: FieldWatchersApplicationResult = {};

            Object.entries(watchers).forEach(([fieldKey, watchersList]) => {
                watchersList.forEach(watcher => {
                    applyWatcher(watcher, arrayItemValues, fieldKey, applicationResult);
                });
            });
            collectedWatchersResultArray[index] = applicationResult;
        });
        setWatchersApplicationResult(collectedWatchersResultArray);

        if (arrayIndependentWatchers) {
            const applicationResult: FieldWatchersApplicationResult = {};
            Object.entries(arrayIndependentWatchers).forEach(([fieldKey, watchersList]) => {
                watchersList.forEach(watcher => {
                    applyWatcher(watcher, formValues, fieldKey, applicationResult);
                });
            });
            setArrayIndependentWatcherResult(applicationResult);
        }
    };
    useEffect(() => {
        if (triggerDeps.length) applyFieldWatchers(formInstance.getFieldsValue());
    }, [...triggerDeps]);

    const checkWatcherFlag = (
        index: number, fieldKey: string, flag: WatcherFieldFlag,
    ) => watchersApplicationResult[index.toString()]?.[fieldKey][flag];

    const checkArrayIndependentWatcherFlag = (
        fieldKey: string, flag: WatcherFieldFlag,
    ) => arrayIndependentWatcherResult[fieldKey]?.[flag];

    return {
        watchersApplicationResult,
        applyFieldWatchers,
        checkWatcherFlag,
        checkArrayIndependentWatcherFlag,
    };
};
