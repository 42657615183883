import React from 'react';
import ReactDOM from 'react-dom';

import 'shared/assets/_fonts/open-sans.ttf';
import 'shared/styles/index.less'; // !side-effect, не перемещать после импорта {App}

import {App} from './app';

ReactDOM.render(
    <App />,
    document.getElementById('root'),
);
