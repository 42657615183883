import {CloseOutlined} from '@ant-design/icons';
import {Button, Form, Input} from 'antd';
import {FormListFieldData} from 'antd/es/form/FormList';
import {FormInstance} from 'antd/lib/form';
import cn from 'classnames';
import React from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {fieldValidationResolver} from 'components/form/utils/field-validation-resolver';
import {WatcherFieldFlag} from 'components/report-configuration/hooks/use-form-fields-manager';
import {FieldType} from 'modules/metadata';

import {
    SETTING_TEMPLATE_TARGET_FORM_DATA_KEY,
    SettingTemplateTargetFormFields,
} from '../../constants/setting-template-target-table-constats';

export interface SettingTemplateTargetTableFieldsProps {
    form: FormInstance;
    fields: FormListFieldData[];
    remove: (index: (number | number[])) => void;
    checkWatcherFlag: (index: number, key: string, flag: WatcherFieldFlag) => boolean;
    checkArrayIndependentWatcherFlag: (fieldKey: string, flag: WatcherFieldFlag) => boolean;
}

export const SettingTemplateTargetTableFields: React.FC<SettingTemplateTargetTableFieldsProps> = ({
    form,
    fields,
    remove,
    checkWatcherFlag,
    checkArrayIndependentWatcherFlag,
}: SettingTemplateTargetTableFieldsProps) => {
    const sort = fields.sort((a, b) => (
        form?.getFieldValue([SETTING_TEMPLATE_TARGET_FORM_DATA_KEY, a.name, SettingTemplateTargetFormFields.numberId])
        > form?.getFieldValue([SETTING_TEMPLATE_TARGET_FORM_DATA_KEY, b.name, SettingTemplateTargetFormFields.numberId])
            ? 1 : -1));
    return (
        <div className={cn('form-list')}>
            {sort?.map(field => (
                <div
                    key={field.key}
                    className={cn('form-list__items')}
                >
                    <Form.Item
                        style={{width: 245}}
                        label="Номер"
                        name={[field.name, SettingTemplateTargetFormFields.numberId]}
                        rules={fieldValidationResolver({
                            validationRules: [{
                                regExp: '^.{0,2048}$',
                                errorMessage: 'Превышено число символов',
                            }],
                            fieldMeta: {
                                type: FieldType.STRING,
                                label: 'Номер',
                                isRequired: true,
                            },
                        })}
                    >
                        <Input
                            autoComplete="off"
                            placeholder="Введите номер"
                            disabled={checkWatcherFlag(
                                field.name,
                                SettingTemplateTargetFormFields.numberId,
                                WatcherFieldFlag.isDisabled,
                            )}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Имя"
                        name={[field.name, SettingTemplateTargetFormFields.name]}
                        style={{width: 245}}
                        rules={fieldValidationResolver({
                            validationRules: [{
                                regExp: '^.{0,2048}$',
                                errorMessage: 'Превышено число символов',
                            }],
                            fieldMeta: {
                                type: FieldType.STRING,
                                label: 'Имя',
                                isRequired: true,
                            },
                        })}
                    >
                        <Input
                            autoComplete="off"
                            placeholder="Введите имя"
                            disabled={checkWatcherFlag(
                                field.name,
                                SettingTemplateTargetFormFields.name,
                                WatcherFieldFlag.isDisabled,
                            )}
                        />
                    </Form.Item>
                    <Form.Item
                        style={{width: 245}}
                        label="Описание"
                        name={[field.name, SettingTemplateTargetFormFields.description]}
                        rules={fieldValidationResolver({
                            validationRules: [{
                                regExp: '^.{0,2048}$',
                                errorMessage: 'Превышено число символов',
                            }],
                            fieldMeta: {
                                type: FieldType.STRING,
                                label: 'Описание',
                                isRequired: true,
                            },
                        })}
                    >
                        <Input
                            autoComplete="off"
                            placeholder="Введите описание"
                            disabled={checkWatcherFlag(
                                field.name,
                                SettingTemplateTargetFormFields.description,
                                WatcherFieldFlag.isDisabled,
                            )}
                        />
                    </Form.Item>
                    <Form.Item
                        style={{width: 245}}
                        label="Тип поля"
                        rules={[{required: true}]}
                        name={[field.name, SettingTemplateTargetFormFields.typeId]}
                    >
                        <CustomSelect
                            settings={{
                                showSearch: true,
                                placeholder: 'Выберите тип поля',
                                url: '/lookupValue/DATA_TYPE',
                                labelPath: 'description',
                                isDisabled: checkWatcherFlag(
                                    field.name,
                                    SettingTemplateTargetFormFields.typeId,
                                    WatcherFieldFlag.isDisabled,
                                ),
                                valuePath: 'id',
                                triggerOnValueSet: true,
                                formFieldKey: 'typeId',
                                formInstance: form,
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        style={{width: 245}}
                        label="Буква Excel"
                        required
                        name={[field.name, SettingTemplateTargetFormFields.excelColumn]}
                        rules={fieldValidationResolver({
                            validationRules: [{
                                regExp: '^[a-zA-Z]+$',
                                errorMessage: 'Данное поле должно содержать только латинские буквы',
                            }],
                            fieldMeta: {
                                type: FieldType.STRING,
                                label: 'Имя',
                                isRequired: true,
                            },
                        })}

                    >
                        <Input
                            disabled={checkWatcherFlag(
                                field.name,
                                SettingTemplateTargetFormFields.excelColumn,
                                WatcherFieldFlag.isDisabled,
                            )}
                            autoComplete="off"
                            placeholder="Введите номер"
                        />
                    </Form.Item>
                    <Form.Item label={<>&nbsp;</>}>
                        <Button
                            className="button-only-icon"
                            type="primary"
                            icon={<CloseOutlined className="svg-icon-size-16" />}
                            disabled={checkArrayIndependentWatcherFlag('removeButton',
                                WatcherFieldFlag.isDisabled)}
                            onClick={() => remove(field.name)}
                        />
                    </Form.Item>
                </div>
            ))}
        </div>
    );
};
