import {FieldType, IInformationFieldMeta} from 'modules/metadata';
import {DATE_WITH_TIME, DATE_WITH_TIME_DOTS} from 'shared/constants/date-format';

const TOPIC_REQUEST_TYPE = {
    key: 'topicRequestType',
    label: 'Тип запроса',
    type: FieldType.REFERENCE,
    referenceUrl: '/lookupValue/REQUEST_TYPE',
    isRequired: true,
    path: {
        label: 'description',
        value: 'id',
    },
};

const CATEGORY = {
    key: 'category',
    label: 'Категория',
    type: FieldType.REFERENCE,
    referenceUrl: '/lookupValue/CATEGORY',
    path: {
        reserveLabel: 'categoryName',
    },
    isRequired: true,
};

const SUBJECT = {
    key: 'subject',
    label: 'Тема',
    type: FieldType.STRING,
    isRequired: true,
};
const HELPER_FIELDS = {
    parentId: {
        key: 'parentId',
        label: 'parentId',
        type: FieldType.STRING,
        isHidden: true,
    },
    idRequestComment: {
        key: 'idRequestComment ',
        label: 'idRequestComment ',
        type: FieldType.STRING,
        isHidden: true,
    },
    prolongRequest: {
        key: 'prolongRequest',
        label: 'Продлить запрос',
        type: FieldType.BOOLEAN,
        isHidden: true,
    },
};

const ALL_REQUESTS_FIELDS = {
    ...HELPER_FIELDS,
    requestType: TOPIC_REQUEST_TYPE,
    topicRequestType: TOPIC_REQUEST_TYPE,
    lbResponsibleUser: {
        key: 'lbResponsibleUser',
        label: 'Отвественный',
        type: FieldType.STRING,
    },
    author: {
        key: 'author',
        label: 'Кто',
        type: FieldType.STRING,
    },
    createDate: {
        key: 'createDate',
        label: 'Когда',
        type: FieldType.DATE,
        dateFormat: DATE_WITH_TIME,
    },
    from: {
        key: 'fromUser',
        label: 'От кого',
        type: FieldType.STRING,
    },
    responseTime: {
        key: 'responseTime',
        label: 'Срок ответа',
        type: FieldType.STRING,
    },
    dateUpdUser: {
        key: 'updateDate',
        label: 'Дата обновления',
        type: FieldType.DATE,
        dateFormat: DATE_WITH_TIME_DOTS,
    },
    requestInitiator: {
        key: 'requestInitiator',
        label: 'ФИО инициатора запроса',
        type: FieldType.STRING,
    },
    requestCheckboxFns: {
        key: 'requestCheckboxFns',
        label: 'Запрос от ФНС',
        type: FieldType.BOOLEAN,
    },
    requestCheckClarificationFlag: {
        key: 'requestCheckClarificationFlag',
        label: 'Пояснение',
        type: FieldType.BOOLEAN,
    },
    requestNum: {
        key: 'requestNum',
        label: 'Номер запроса',
        type: FieldType.STRING,
        isRequired: true,
    },
    requestDate: {
        key: 'requestDate',
        label: 'Дата получения',
        type: FieldType.DATE,
        isRequired: true,
    },
    subject: SUBJECT,
    messageSubject: SUBJECT,
    period: {
        key: 'period',
        label: 'Период',
        type: FieldType.REFERENCE,
        referenceUrl: '/lookupValue/PERIOD_SOURCE',
        isRequired: true,

    },
    categoryCode: CATEGORY,
    category: CATEGORY,
    taxType: {
        key: 'taxType',
        label: 'Налог',
        type: FieldType.REFERENCE,
        referenceUrl: '/lookupValue/TAX_TYPE',
        isRequired: true,
    },
    reportForm: {
        key: 'reportForm',
        label: 'Форма отчета',
        type: FieldType.STRING,
    },
    requestDocument: {
        key: 'requestDocument',
        label: 'Перечень документов',
        type: FieldType.STRING,
    },
    addressees: {
        key: 'toUser',
        label: 'Кому',
        type: FieldType.STRING,
    },
    content: {
        key: 'content',
        isRequired: true,
        label: '',
        type: FieldType.TEXTAREA,
        displayAfterActions: true,
    },
    files: {
        key: 'attachmentList',
        label: '',
        type: FieldType.FILE_LIST_WITH_SIGNS,
        displayAfterActions: true,
        uploadUrl: 'request/attach',
        dataKeyForDownload: 'attachmentId',
        uploadSignUrl: 'request/signature',
        downloadAllUrl: 'url', // TODO добавить url
    },
    reason: {
        key: 'reason',
        label: 'Причина',
        type: FieldType.STRING,
    },
    prolongDate: {
        key: 'updateDate',
        label: 'Запрашиваемый срок для предоставления ответа',
        type: FieldType.DATE,
        dateFormat: DATE_WITH_TIME_DOTS,
    },
};

const ALL_ANSWER_FIELDS = {
    ...ALL_REQUESTS_FIELDS,
    topicRequestType: {
        ...ALL_REQUESTS_FIELDS.topicRequestType,
        editableViewField: false,
        disabled: true,
    },
    requestInitiator: {
        ...ALL_REQUESTS_FIELDS.requestInitiator,
        editableViewField: false,
        disabled: true,
    },
    requestCheckboxFns: {
        ...ALL_REQUESTS_FIELDS.requestCheckboxFns,
        editableViewField: false,
        disabled: true,
    },
    subject: {
        ...ALL_REQUESTS_FIELDS.subject,
        editableViewField: false,
        disabled: true,
    },
    period: {
        ...ALL_REQUESTS_FIELDS.period,
        editableViewField: false,
        disabled: true,
    },
    category: {
        ...ALL_REQUESTS_FIELDS.category,
        editableViewField: false,
        disabled: true,
    },
    taxType: {
        ...ALL_REQUESTS_FIELDS.taxType,
        editableViewField: false,
        disabled: true,
    },
    reportForm: {
        ...ALL_REQUESTS_FIELDS.reportForm,
        editableViewField: false,
        disabled: true,
    },
    requestDocument: {
        ...ALL_REQUESTS_FIELDS.requestDocument,
        editableViewField: false,
        disabled: true,
    },
    requestDate: {
        ...ALL_REQUESTS_FIELDS.requestDate,
        editableViewField: false,
        disabled: true,
    },
    addressees: {
        ...ALL_REQUESTS_FIELDS.addressees,
        editableViewField: true,
    },
    content: {
        ...ALL_REQUESTS_FIELDS.content,
        editableViewField: true,
    },
    files: {
        ...ALL_REQUESTS_FIELDS.files,
        editableViewField: true,
    },
};

export type TFormFieldsRequests = Record<string, IInformationFieldMeta>;

export const FORM_FIELDS = {
    REQUESTS: ALL_REQUESTS_FIELDS as TFormFieldsRequests,
    ANSWER: ALL_ANSWER_FIELDS as TFormFieldsRequests,
};
