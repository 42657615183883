import React from 'react';
import {useDispatch} from 'react-redux';

import {performSimpleActionForTable} from 'modules/data/data-actions';
import {selectTableSelectedRowKeys} from 'modules/data/data-selectors';
import {RequestType} from 'modules/metadata';
import {useAppSelector} from 'store/config/hooks';

import {ActionButton} from '../action-button';
import {TableActionProps} from '../table-action-types';

export const ButtonActionForRows: React.FunctionComponent<TableActionProps> = ({
    wrapper,
    meta,
    ...props
}: TableActionProps) => {
    const entityName = meta?.entityName || '';
    const referenceUrl = meta?.referenceUrl || '';
    const {requestType, isJsonRequest} = meta;
    const dispatch = useDispatch();
    const selectedRowKeys = useAppSelector(selectTableSelectedRowKeys(entityName)) || [];
    const handleClick = () => {
        dispatch(
            performSimpleActionForTable(
                entityName,
                referenceUrl,
                requestType || RequestType.DELETE,
                {selectedRowKeys},
                isJsonRequest,
            ),
        );
    };
    const Component = wrapper || ActionButton;

    return (
        <Component
            {...props}
            onClick={handleClick}
            actionIcon={meta.actionIcon}
            disabled={!selectedRowKeys.length}
        >
            {meta.actionTitle}
        </Component>
    );
};
