export const BREADCRUMBS_MODULE = 'BREADCRUMBS_MODULE';

export const BreadcrumbsActions = {
    SET_BREADCRUMBS: 'SET_BREADCRUMBS',
    REPLACE_BREADCRUMBS: 'REPLACE_BREADCRUMBS',
    RESET_BREADCRUMBS: 'RESET_BREADCRUMBS',
    PUSH_BREADCRUMBS: 'PUSH_BREADCRUMBS',
};

export const BreadcrumbsSections = {
    MAIN_PAGE: 'Главная страница',
    REPORTS: 'Отчеты',
    REPORT: 'Отчет',
};
