import {Tabs} from 'antd';
import {FormInstance} from 'antd/es/form';
import classNames from 'classnames';
import React, {useState} from 'react';

import {FormEntityData} from 'modules/data/data-types';
import {EntityMeta} from 'modules/metadata/metadata-types';

import {FormMode} from '../../form-types';
import {useFormElementsAvailability} from '../../hooks/check-form-elements-availability.hook';
import {Fields} from '../fields';

import './fields-with-tabs.less';

export interface FieldsWithTabsProps {
    parentEntityName?: string;
    entityName?: string;
    formData?: FormEntityData;
    meta?: EntityMeta;
    mode?: FormMode;
    form?: FormInstance;
}

export const FieldsWithTabs = ({
    formData,
    meta,
    parentEntityName,
    entityName,
    mode = FormMode.CREATE,
    form,
}: FieldsWithTabsProps) => {
    const [currentTab, setCurrentTab] = useState<string | undefined>(undefined);
    const {isEditable} = useFormElementsAvailability(mode, entityName);

    React.useEffect(() => {
        setCurrentTab(meta?.tabs && meta?.tabs[0]?.key);
    }, [meta?.tabs]);

    return (
        <div className="fields-with-tabs">
            <Tabs
                onChange={tab => {
                    setCurrentTab(tab);
                }}
                destroyInactiveTabPane
                defaultActiveKey={currentTab}
                type="card"
                className={classNames({'first-is-active': meta?.tabs?.length && meta.tabs[0].key === currentTab})}
            >
                {meta?.tabs?.map(entry => (
                    <Tabs.TabPane
                        tab={entry.title}
                        key={entry.key}
                        forceRender
                    >
                        <div className="form-fields">
                            <Fields
                                formMode={mode}
                                formData={formData}
                                parentEntityName={parentEntityName}
                                entityName={entityName}
                                list={meta.fields.filter(field => entry?.fieldKeys.includes(field.key))}
                                isEditable={isEditable}
                                form={form}
                            />
                        </div>
                    </Tabs.TabPane>
                ))}
            </Tabs>
        </div>
    );
};
