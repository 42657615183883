import {combineString} from 'shared/utils/combine-string';

export const getFormModalTitle = (
    hideTitle?: boolean,
    editOrCreateTitle?: string,
    titleAsStructure?: string,
    data?: any,
    titleFromMeta?: string,
) => {
    if (editOrCreateTitle) {
        return editOrCreateTitle;
    }
    if (titleAsStructure) {
        return combineString(titleAsStructure, data);
    }
    if (hideTitle) {
        return undefined;
    }
    return titleFromMeta;
};

// Получение регулярного выражения по заданным символам, используется для валидации пароля
export const getRegExpPassword = (symbols?: string) => {
    const specialSymbols = !symbols ? symbols?.toString() : symbols.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    return `^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[${specialSymbols}])[0-9a-zA-Z${specialSymbols}]{1,}$`;
};

export const isDataChanged = (data: any, initialData: any) => {
    const dataKeys = Object.keys(data);
    const result: boolean[] = [];
    dataKeys.forEach(key => {
        result.push(
            JSON.stringify(data?.[key]) === JSON.stringify(initialData?.[key])
            || key === 'inputCurrentPassword',
        );
    });
    return result.includes(false);
};
