const INITIAL_YEAR = 2018;

export const generateYearSelectionForDashboard = (initialYear = INITIAL_YEAR) => {
    const nowYear = new Date().getFullYear();

    const options = Array(nowYear + 1 - initialYear).fill(0).map((_, i) => initialYear + i);

    return options;
};
export const formatNumberWithSpaces = (number?: number) => number?.toLocaleString('ru-Ru');
