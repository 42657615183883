import {Divider, Form} from 'antd';
import {FormListFieldData} from 'antd/es/form/FormList';
import {FormInstance} from 'antd/lib/form';
import React from 'react';

import {FormEntityData} from 'modules/data/data-types';
import {FieldMeta} from 'modules/metadata';

import {FieldsTreeGenerator} from '../fields-tree-generator';

import '../fields-tree.less';

interface FieldsTreeChildProps {
    fieldMeta: FieldMeta;
    parentField: FormListFieldData;
    formData?: FormEntityData;
    form?: FormInstance;
}

export const FieldTreeChild: React.FunctionComponent<FieldsTreeChildProps> = ({
    fieldMeta,
    form,
    formData,
    parentField,
}: FieldsTreeChildProps) => (
    <div className="fields-tree">
        <Form.List
            {...parentField}
            name={[parentField.name, fieldMeta.key]}
        >
            {(fields: FormListFieldData[]) => (
                <div>
                    <div className="fields-tree__header">{fieldMeta.label}</div>
                    <div className="fields-tree__wrapper">
                        {((!fields.length || fields.length === 1) ? [{fieldKey: 0, key: 0, name: 0}] : fields)
                            ?.map((childField: FormListFieldData, index) => (
                                <div key={childField.key}>
                                    <Form.Item
                                        className="row"
                                        {...childField}
                                        name={[childField.name, fieldMeta.key]}
                                    >
                                        <FieldsTreeGenerator
                                            fieldData={childField}
                                            meta={fieldMeta}
                                            form={form}
                                            formData={formData}
                                        />
                                    </Form.Item>
                                    {index < fields.length - 1 && <Divider />}
                                </div>

                            ))}
                    </div>
                </div>
            )}
        </Form.List>
    </div>
);
