import {get, isObject} from 'lodash';

export const createFieldFormatterForSelect = (path?: string) => (
    fieldValue: any,
) => {
    if (isObject(fieldValue) && path) return get(fieldValue, path) ?? undefined;
    if (Array.isArray(fieldValue) && path) return fieldValue.map(item => get(item, path)) ?? undefined;
    return fieldValue;
};

/**
 * Дефолтные настройки для кастом селекта для работы с valuesList и др. лукапами.
 * @param {string} url - url для получения данных лукапа.
 */
export const getCustomSelectDefaultPreset = (url: string) => ({
    url,
    valuePath: 'lookupCode',
    labelPath: 'meaning',
    isClearable: true,
    showSearch: true,
});
