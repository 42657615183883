import {unwrapResult} from '@reduxjs/toolkit';
import {Card, Form as AntForm} from 'antd';
import React, {useEffect, useState} from 'react';

import {dispatchFormWatcherEvent} from 'components/form/hooks/use-form-watcher';
import {
    SignAndFileListUploader,
} from 'components/form/inputs/uploader-list/sign-and-file-list-uploader/sign-and-file-list-uploader';
import {REQUESTS_DISPLAY_FIELDS_URL, REQUESTS_DISPLAY_FILES_URL} from 'components/request/constants/requests.constants';
import {RequestCardContext} from 'components/request/replies/context/request-card-context';
import {Replies} from 'components/request/replies/replies';
import {RequestFormFields} from 'components/request/request-form/components/request-from-fields/request-form-fields';
import {
    RequestResponsible,
} from 'components/request/request-form/components/request-responsible/request-responsible';
import {StatusModelAwareActions} from 'components/request/status-model-aware-actions/status-model-aware-actions';
import {convertRequestData} from 'components/request/utils/request-api-utils';
import {RichTextEditor} from 'components/rich-text';
import {fetchPermissionDisplayFileComponent} from 'modules/data/data-api';
import {normalizeFormDataForRequest} from 'modules/data/utils/data.utils';
import {CommonMessages, ErrorMessages} from 'shared/constants/messages';
import {
    URL_REQUESTS, URL_REQUESTS_REQUIREMENT_PROLONGATION_UPDATE,
} from 'shared/constants/urls';
import {LocationStateKey, useAppHistory} from 'shared/hooks/use-app-history';
import {FileDto} from 'shared/types/files';
import {showMessage} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {
    selectRequestData,
    selectRequestFields,
    selectRequestReplies,
} from 'store/slices/request-slice/request-selectors';
import {requestSliceActions} from 'store/slices/request-slice/request-slice';
import {
    loadRequestData,
    loadRequestFieldsMetadata, loadRequestStatusesData,
    saveRequestData,
} from 'store/slices/request-slice/request-slice-thunks';
import {RequestInfo, TopicReportInfo} from 'store/slices/request-slice/request-slice-type';
import {normalizeBoolValue} from 'utils/common';

import {
    REQUEST_FIELD_CONTENT, requestMainFields, RequestStatus, RequestTypeCode,
} from '../../request-form.constants';
import './request-information.less';

interface RequestInformationProps {
    entityName: string;
    requestFormKey: string;
    requestMetaName: string;
    data?: RequestInfo;
    requestType?: string;
    onClose?: () => void;
    enableLinkToReport?: boolean;
    topicReportInfo?: TopicReportInfo;
}

export const RequestInformation: React.FC<RequestInformationProps> = ({
    entityName,
    data,
    onClose,
    requestMetaName,
    requestFormKey,
    requestType,
    enableLinkToReport = false,
    topicReportInfo,
}:RequestInformationProps) => {
    const history = useAppHistory();
    const [form] = AntForm.useForm();
    const dispatch = useAppDispatch();
    const [messageCollapseOpen, setMessageCollapseOpen] = useState(false);
    const [isEditingForm, setEditingForm] = useState(false);
    const [fileList, setFileList] = useState<FileDto[] | undefined>(data?.attachmentList);
    const requestData = useAppSelector(selectRequestData);
    const fields = useAppSelector(selectRequestFields);
    const {resetRequestStatusesData} = requestSliceActions;
    const requestReplies = useAppSelector(selectRequestReplies);
    const hasReplies = requestReplies && !!requestReplies?.length;
    const content = data?.content;
    const typeCode = data?.topicRequestType?.typeCode || data?.requestType;
    const attachmentList = !!data?.attachmentList?.length;
    const isHidden = data?.topicStatus?.lookupCode === RequestStatus.IN_PREPARATION_RESPONSE;
    const isResponsibleVisible = data?.topicStatus?.lookupCode === RequestStatus.DRAFT
        || typeCode === RequestTypeCode.REQUEST || typeCode === RequestTypeCode.REQUEST_PUD;
    const topicRequestId = data?.topicRequestType?.id;
    const requestCommentId = data?.idRequestComment;
    const requestId = data?.id;
    const formData = convertRequestData(requestData, data);
    const [hasPermissionDisplayFileComponent, setPermissionDisplayFileComponent] = useState(false);
    const hasDisplayFileListUploader = hasPermissionDisplayFileComponent && (attachmentList || isEditingForm);

    const reportLink = topicReportInfo?.docId === '-1'
        ? `/table-reports/${topicReportInfo.templateCode}`
        : `/table-reports/${topicReportInfo?.docId}/${topicReportInfo?.templateCode}`;

    useEffect(() => {
        const checkPermissionDisplayFileComponent = async () => {
            const hasDisplayFiles = await fetchPermissionDisplayFileComponent(
                REQUESTS_DISPLAY_FILES_URL,
                topicRequestId,
            );
            setPermissionDisplayFileComponent(hasDisplayFiles?.data);
        };
        checkPermissionDisplayFileComponent().catch(console.error);
    }, []);

    React.useEffect(() => {
        form.setFieldsValue({...data, ...formData});
        setFileList(data?.attachmentList);
    }, [form, data]);

    useEffect(() => {
        if (!fields && topicRequestId) {
            dispatch(loadRequestFieldsMetadata({
                entityName: requestMetaName,
                referenceUrl: REQUESTS_DISPLAY_FIELDS_URL,
                id: topicRequestId,
            }));
        }
    }, [fields, topicRequestId]);
    const handleFinish = (values: any) => {
        if (requestCommentId && requestId) {
            form.validateFields().then(() => {
                dispatch(saveRequestData({
                    commentId: requestCommentId,
                    url: URL_REQUESTS_REQUIREMENT_PROLONGATION_UPDATE,
                    data: normalizeFormDataForRequest(values, [...fields || [], ...requestMainFields]),
                })).then(unwrapResult)
                    .then(() => {
                        form.resetFields();
                        setEditingForm(!isEditingForm);
                        showMessage({message: CommonMessages.SAVE_SUCCESS});
                        dispatch(resetRequestStatusesData);
                        dispatch(loadRequestStatusesData({commentId: requestCommentId, requestFormKey}));
                        dispatch(loadRequestData({id: requestId, url: URL_REQUESTS}));
                    }, error => {
                        showMessage({
                            message: error?.response?.data ?? ErrorMessages.UNEXPECTED_ERROR_OCCURRED,
                            isError: true,
                        });
                    });
            });
        }
    };
    return (
        <>
            <div className="request-form-container__actions">
                <RequestCardContext.Provider
                    value={{
                        text: messageCollapseOpen
                            ? 'Свернуть всю переписку'
                            : 'Развернуть всю переписку',
                        isEditing: isEditingForm || false,
                        handleCollapse: () => setMessageCollapseOpen(v => !v),
                        setEditing: () => setEditingForm(v => !v),
                    }}
                >
                    <StatusModelAwareActions
                        requestFormKey={requestFormKey}
                        removeControlCollapseAction={hasReplies}
                        requestCommentId={requestCommentId}
                        onClose={onClose}
                        entityName={entityName}
                        isHidden={isHidden}
                        requestType={requestType}
                        onSubmit={() => {
                            form.submit();
                        }}
                        requestInfo={requestData?.requests}
                    />
                </RequestCardContext.Provider>
            </div>
            <AntForm
                layout="vertical"
                form={form}
                className="form"
                onFinish={handleFinish}
                onValuesChange={(_, values: any) => {
                    dispatchFormWatcherEvent({values, targetForm: form});
                }}
            >
                {fields && (
                    <>
                        {isResponsibleVisible && (
                            <RequestResponsible
                                isVisible={normalizeBoolValue(isResponsibleVisible)}
                            />
                        )}
                        <RequestFormFields
                            entityName={entityName}
                            data={formData}
                            fields={[...(fields || [])]}
                            form={form}
                            isEditable={isEditingForm}
                        />
                        {content && (
                            <AntForm.Item
                                name="content"
                                label={REQUEST_FIELD_CONTENT.label}
                            >
                                <RichTextEditor
                                    key="content"
                                    disabled={!isEditingForm}
                                    value={content}
                                />
                            </AntForm.Item>
                        )}
                        <AntForm.Item
                            name="files"
                        >
                            {hasDisplayFileListUploader && (
                                <SignAndFileListUploader
                                    disabled={!isEditingForm}
                                    fileList={fileList}
                                    onChange={setFileList}
                                    requestCommentId={requestCommentId}
                                    dataKeyForDownload="attachmentId"
                                    url={entityName}
                                    signUrl={entityName}
                                    isDownloadDocumentList={!!data?.attachmentList?.length}
                                />
                            )}
                        </AntForm.Item>
                    </>
                )}
            </AntForm>
            {
                enableLinkToReport && (
                    <section>
                        <h4 className="section__title">
                            Предмет запроса
                        </h4>
                        <button
                            type="button"
                            role="link"
                            className="section__link"
                            onClick={() => {
                                if (topicReportInfo?.reportParameters === null && topicReportInfo?.docId !== '-1') {
                                    history.pushWithStateUpdate(reportLink, {
                                        [LocationStateKey.TABLE_REPORT_REQUEST_TOPIC_PARAM]: {
                                            TOPIC_REQUEST_ID: requestId,
                                        },
                                    });
                                } else {
                                    const reportParameters = JSON.parse(topicReportInfo?.reportParameters || '');
                                    history.pushWithStateUpdate(reportLink, {
                                        [LocationStateKey.TABLE_REPORT_REQUEST_TOPIC_PARAM]: {
                                            TOPIC_REQUEST_ID: requestId,
                                            TOPIC_REQUEST_PARAMS: reportParameters,
                                        },
                                    });
                                }
                            }}
                        >
                            Просмотреть строки отчета, по которым сформирован запрос
                        </button>
                    </section>
                )
            }
            {fields && hasReplies && (
                <Card
                    className="replies-card"
                >
                    <Replies
                        entityName={entityName}
                        data={requestReplies}
                        collapseIsOpen={messageCollapseOpen}
                    />
                </Card>
            )}
        </>
    );
};
