import cn from 'classnames';
import React from 'react';

import './dd-parameters-rule.less';
import {DdParametersRuleItem} from './dd-parameters-rule-item/dd-parameters-rule-item';
import {DdParameterRuleItemSourceType, DdParameterRuleItemType} from './dd-parameters-rule-item/dd-parameters-rule-item-types';

interface DdParametersRuleProps {
    isInEditMode?: boolean;
    layout?: 'vertical' | 'horizontal';
    childSourceType?: DdParameterRuleItemSourceType;
    parentSourceType?: DdParameterRuleItemSourceType;
}

export const DdParametersRule: React.FC<DdParametersRuleProps> = (
    {
        isInEditMode, layout = 'horizontal', childSourceType, parentSourceType,
    }: DdParametersRuleProps,
) => (
    <div className={cn('dd-parameters-rule')}>
        <DdParametersRuleItem
            type={DdParameterRuleItemType.parent}
            isInEditMode={isInEditMode}
            layout={layout}
            sourceType={parentSourceType}
        />
        <div className={cn('report-dd-parameters-arrow')} />
        <DdParametersRuleItem
            type={DdParameterRuleItemType.child}
            isInEditMode={isInEditMode}
            layout={layout}
            sourceType={childSourceType}
        />
    </div>
);
