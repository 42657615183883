import {RcFile} from 'antd/es/upload';
import {UploadFile} from 'antd/lib/upload/interface';

export type {UploadFile};
export interface UploadFileExtended<T=any> extends UploadFile<T> {
    attachmentId?: string;
    sign?: File;
    signRemovedFile?: boolean;
    signName?: string;
    file?: RcFile;
    id?: number;
}

export interface FileDto {
    id?: number;
    uid: string;
    name: string;
    url?: string;
    status?: string;
    signName?: string;
    signUrl?: string;
    file?: File;
    sign?: Blob;
    attachmentId?: string;
    objectId?: string;
    removedFile?: boolean;
    signRemovedFile?: boolean;
}

export interface Attachment {
    attachmentId: string;
    fileName: string;
    fileSize: number;
    fileHashCode: string;
    objectId: number;
    outputFileSize: string;
    signatureFileName: string;
}

export type FileList = UploadFile<Blob>|FileDto|Attachment

// export interface FileDto {
//     objectType: string;
//     objectId: number;
//     fileName: string;
//     filePath: string | null;
//     fileSize: number;
//     outputFileSize: string | null;
//     fileExtension: string;
//     fileHashCode: string;
//     signatureFileName: string | null;
//     xmlValidateStatusCode: string;
//     xmlValidateStatusName: string;
//     version: string | null;
// }

export enum FileType {
    FILE = 'file',
    SIGNATURE = 'signature',
    ATTORNEY = 'attorney',
}
