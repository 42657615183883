import {Space, Tooltip} from 'antd';
import get from 'lodash/get';
import React from 'react';
import {useDispatch} from 'react-redux';

import {DynamicIcon} from 'components/dynamic-icon';
import {TIconType} from 'components/dynamic-icon/dynamic-icon';
import {createActionGetFile} from 'modules/data/data-actions';
import {FieldMeta, RequestType} from 'modules/metadata';
import {fieldStructureType} from 'modules/metadata/metadata-types';

interface IconsCellProps {
    field: FieldMeta;
    record: Record<string, any>;
}

export const IconsDownloadCell: React.FunctionComponent<IconsCellProps> = ({
    field, record,
}: IconsCellProps) => {
    const {fieldStructure} = field;
    const dispatch = useDispatch();

    const handleClick = () => {
        const attachId = fieldStructure?.map(({fieldKey}) => get(record, fieldKey) as string | number);
        const referenceUrl = `${field?.referenceUrl}/${attachId || record.id}`;
        dispatch(
            createActionGetFile({referenceUrl, requestType: RequestType.GET}),
        );
    };
    const renderIcons = () => fieldStructure?.map(({
        fieldKey, iconName, prefixForField = '',
    }: fieldStructureType) => (
        <Tooltip
            key={fieldKey}
            placement="top"
            title={prefixForField}
        >
            <DynamicIcon
                type={iconName as TIconType}
                onClick={handleClick}
            />
        </Tooltip>
    ));
    if (!record[field.key]) {
        return null;
    }
    return (
        <Space>
            {renderIcons()}
        </Space>
    );
};
