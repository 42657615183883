import {Button, Form} from 'antd';
import {FormInstance} from 'antd/es/form';
import cn from 'classnames';
import React from 'react';

import {ReactComponent as AddLine} from 'shared/assets/add-line.svg';

import {ChildList} from '../report-table-of-contents-child';

import '../../report-table-of-content.less';

interface ChildrenListData {
    isEditingForm: boolean;
    fieldName: number;
    isOpen: boolean;
    handleIsOpen: (isOpen: boolean) => void;
    handleChildLength: (length: number) => void;
    isNestedChild?: boolean;
    path: Array<number>;
    fullPath: React.Key[];
    childStructure: Record<string, any>;
    handleChildStructure: () => void;
    form: FormInstance;
}

export const ChildrenList = ({
    isEditingForm,
    fieldName,
    isOpen,
    handleIsOpen,
    handleChildLength,
    isNestedChild,
    path,
    fullPath,
    childStructure,
    handleChildStructure,
    form,
}: ChildrenListData) => (
    <Form.List name={[fieldName, 'children']}>
        {
            (children, {add, remove}) => (
                <>
                    <div className="button-container">
                        <Button
                            className="button-only-icon add-action-btn"
                            type="primary"
                            disabled={!isEditingForm}
                            icon={<AddLine />}
                            onClick={() => {
                                add();
                                handleIsOpen(true);
                                handleChildLength(children.length + 1);
                                handleChildStructure();
                            }}
                            key={`${fieldName} button`}

                        />
                    </div>
                    <div
                        className={cn('contents-child', !isOpen && 'no-visible')}
                        key={fieldName}
                    >
                        <div className="form-list__item">
                            {children.map(child => (
                                <ChildList
                                    remove={remove}
                                    fieldKey={child.key}
                                    fieldName={child.name}
                                    isEditingForm={isEditingForm}
                                    handleChildLength={handleChildLength}
                                    parentChildrenLength={children.length}
                                    path={path}
                                    childStructure={childStructure}
                                    handleChildStructure={handleChildStructure}
                                    isNestedChild={isNestedChild}
                                    form={form}
                                    fullPath={[...fullPath, child.name]}
                                />
                            ))}
                        </div>
                    </div>

                </>
            )
        }
    </Form.List>
);
