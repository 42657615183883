import {convertToFormData, performRequest, RequestPromise} from 'shared/utils';

import {fetchEntityMetadata, getAllMetadata} from '../metadata/metadata-api';
import {EntityMeta} from '../metadata/metadata-types';

export const saveMetadata = <T extends EntityMeta>(
    data: Record<string, any>,
): RequestPromise<T> => performRequest({
        url: 'meta',
        method: 'POST',
        data,
    });

export const saveMetadataFile = <T extends EntityMeta>(
    file: File,
): RequestPromise<T> => performRequest({
        url: '/meta/table',
        method: 'POST',
        data: convertToFormData({file}),
    });

export const metadataConstructorApi = (() => ({
    getMeta: fetchEntityMetadata,
    getAllMeta: getAllMetadata,
    saveMetadata,
    saveMetadataFile,
}))();
