import {createSelector} from '@reduxjs/toolkit';

import {AppState} from 'store/config/types';

import {widgetsSliceName} from './widgets-slice-constants';

const selectWidgetsSliceState = (state: AppState) => state[widgetsSliceName];

export const selectWidgetsRequestsData = createSelector(
    selectWidgetsSliceState,
    ({requestTableValues}) => requestTableValues,
);

export const selectWidgetsDocJournalData = createSelector(
    selectWidgetsSliceState,
    ({docJournalData}) => docJournalData,
);

export const selectWidgetsNOBComponentsCorrNumList = createSelector(
    selectWidgetsSliceState,
    ({nobComponentsCorrNumList}) => nobComponentsCorrNumList,
);

export const selectWidgetsNobComponentsData = createSelector(
    selectWidgetsSliceState,
    ({nobComponentsData}) => nobComponentsData,
);

export const selectWidgetsNews = createSelector(
    selectWidgetsSliceState,
    ({news}) => news,
);

export const widgetsSelectors = {
    selectWidgetsSliceState,

    selectWidgetsDocJournalData,
    selectWidgetsNOBComponentsCorrNumList,
    selectWidgetsNews,
    selectWidgetsNobComponentsData,
    selectWidgetsRequestsData,
};
