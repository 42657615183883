import {Checkbox} from 'antd';
import React, {useEffect} from 'react';

import {EntityValue, LookupValue} from 'modules/data/data-types';
import {getMatchesBoolValue} from 'modules/data/utils/data.utils';
import {normalizeBoolValue, normalizeBoolValueString} from 'utils/common';
import '../bool-field/bool-field.less';

export interface ExternalEmployeeBoolFieldProps {
    value?: EntityValue;
    label?: string;
    onChange?: (value: any) => void;
    disabled?: boolean;
    defaultValue?: string;
    dependentFieldData?: LookupValue[];
}

export const ExternalEmployeeBoolField: React.FunctionComponent<ExternalEmployeeBoolFieldProps> = ({
    value,
    label,
    onChange,
    disabled,
    defaultValue,
    dependentFieldData,
    ...props
}: ExternalEmployeeBoolFieldProps) => {
    const handleChange = async () => {
        if (onChange) onChange(getMatchesBoolValue(value as string));
    };

    useEffect(() => {
        if (Array.isArray(dependentFieldData)) {
            const resultValue = dependentFieldData?.slice(1).reduce((res: boolean, currentValue: any) => {
                res = res && normalizeBoolValue(currentValue?.attribute1);
                return res;
            }, normalizeBoolValue(dependentFieldData?.[0]?.attribute1));
            if (onChange && !disabled) onChange(normalizeBoolValueString(resultValue));
        }
    }, [dependentFieldData]);

    return (
        <Checkbox
            {...props}
            disabled={!onChange || disabled}
            onChange={handleChange}
            checked={normalizeBoolValue(value || defaultValue)}
        >{label}
        </Checkbox>
    );
};
