import {unwrapResult} from '@reduxjs/toolkit';
import {
    Button,
    Checkbox,
    Form,
    Input,
    Spin,
} from 'antd';
import {useForm} from 'antd/es/form/Form';
import cn from 'classnames';
import {pick} from 'lodash';
import React, {
    useContext, useEffect, useRef, useState,
} from 'react';
import {useHistory} from 'react-router-dom';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {
    CustomSelectValueTriggerEvent,
} from 'components/form/inputs/custom-select/custom-select';
import {LargeStringInput} from 'components/form/inputs/large-string-input';
import {ThunkErrorScreen} from 'components/thunk-error-screen';
import {ErrorMessages} from 'shared/constants/messages';
import {setFieldsValueUntouched, showMessage} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {
    selectThunkStatus,
} from 'store/slices/loading-state-slice/loading-state-slice';
import {ReportConfigurationDataConverted} from 'store/slices/report-configuration-slice';
import {
    loadTableReportConfiguration,
} from 'store/slices/report-configuration-slice/report-configuration';
import {
    reportConfigurationActions,
    selectTableReportConfigurationData,
} from 'store/slices/report-configuration-slice/report-configuration-slice';
import {
    ReportConfiguratorMainFields,
    reportConfiguratorMainFields,
} from 'store/slices/report-configuration-slice/report-configuration/report-configuration-constants';
import {
    createTableReportConfiguration,
    updateTableReportConfiguration,
} from 'store/slices/report-configuration-slice/report-configuration/report-configuration-thunks';

import {ButtonsBar} from '../../components/buttons-bar/buttons-bar';
import {ClickConfirmation} from '../../components/click-confirmation';
import {useFormFieldsManager, WatcherFieldFlag} from '../../hooks/use-form-fields-manager';
import {
    REPORT_CONFIGURATION_NAME,
    ReportConfigurationTabKey,
} from '../../report-configuration.constants';
import {ReportConfigurationContext} from '../../report-configuration.context';
import {ModalReportActionsSettings} from './actions-settings-modal-opener';
import {ModalReportDownloadSettings} from './download-settings-modal-opener';
import {createReportConfiguratorWatchers} from './report-configurator-watchers';
import {reportConfiguratorFetchMapping, switchTabConfirmationLabel} from './report-configurator.constants';
import {TableReservedColumnsModalOpener} from './table-reserved-columns-modal-opener';

import './report-configurator.less';

interface ReportConfiguratorProps {}

export const ReportConfigurator: React.FC<ReportConfiguratorProps> = () => {
    const [form] = useForm();
    const history = useHistory();

    const dispatch = useAppDispatch();

    const {
        setSelectedTabKey,
        entityName,
        templateCode,
        isCreatingNewTemplate,
    } = useContext(ReportConfigurationContext);

    const {setReportConfigurationTemplateBlocks} = reportConfigurationActions;

    const [isEditingForm, setIsEditingForm] = useState(isCreatingNewTemplate);

    const [valuesReferenceUrls, setValuesReferenceUrls] = useState<{ [index: string]: string | undefined }>({
        headerDatasourceValue: undefined,
        tableDatasourceValue: undefined,
    });

    const [shouldUpdateSelectUrlsTrigger, setShouldUpdateSelectUrlsTrigger] = useState(0);

    const shouldSetFirstValueToHeaderValueRef = useRef<boolean>(false);
    const shouldSetFirstValueToTableValueRef = useRef<boolean>(false);

    const reportConfigurationData = useAppSelector(selectTableReportConfigurationData);

    const {checkWatcherFlag, applyFieldWatchers} = useFormFieldsManager({
        formInstance: form,
        watchers: createReportConfiguratorWatchers({
            isEditingForm,
            valuesReferenceUrls,
        }),
        triggerDeps: [isEditingForm, valuesReferenceUrls],
    });

    const [formInitialValues, setFormInitialValues] = useState<
        Pick<ReportConfigurationDataConverted, ReportConfiguratorMainFields> | undefined
    >(pick(reportConfigurationData, reportConfiguratorMainFields));

    useEffect(() => {
        if (reportConfigurationData) {
            const dataToSetToForm = pick(reportConfigurationData, reportConfiguratorMainFields);
            setFormInitialValues(dataToSetToForm);
            form.setFieldsValue(dataToSetToForm);
        }
    }, [reportConfigurationData]);

    useEffect(() => {
        if (formInitialValues) {
            form.resetFields();
            applyFieldWatchers();
            setShouldUpdateSelectUrlsTrigger(p => p + 1);
        }
    }, [formInitialValues]);

    const {
        isRejected: isLoadingReportConfigurationFailed,
        error: reportConfigurationLoadingError,
    } = useAppSelector(s => selectThunkStatus(s, loadTableReportConfiguration.typePrefix));

    const {
        isPending: isUpdatingReportConfiguration,
    } = useAppSelector(s => selectThunkStatus(s, updateTableReportConfiguration.typePrefix));

    const handleFinish = (values: Pick<ReportConfigurationDataConverted, ReportConfiguratorMainFields>) => {
        if (!templateCode) return;

        if (isCreatingNewTemplate) {
            const {templateCode: newTemplateCode} = values;
            dispatch(createTableReportConfiguration({
                data: values,
            }))
                .then(unwrapResult)
                .then(() => {
                    history.replace(`/${REPORT_CONFIGURATION_NAME}?entity=${newTemplateCode}`);
                    showMessage({message: `Шаблон «${newTemplateCode}» успешно создан`});
                }, error => {
                    showMessage({
                        message: error?.response?.data ?? ErrorMessages.UNEXPECTED_ERROR_OCCURRED,
                        isError: true,
                    });
                });
            return;
        }

        setIsEditingForm(false);
        dispatch(updateTableReportConfiguration({
            templateCode,
            data: values,
        })).then(() => {
            dispatch(loadTableReportConfiguration({
                templateCode,
            }));
        });
    };

    const getLeaveTabConfirmationSkipCondition = () => !form.isFieldsTouched();

    if (!isCreatingNewTemplate && isLoadingReportConfigurationFailed) {
        return (
            <ThunkErrorScreen
                error={reportConfigurationLoadingError}
            />
        );
    }
    return (
        <>
            <Spin
                spinning={false}
            >
                <div className={cn('report-configurator')}>
                    <ButtonsBar
                        entityName={entityName}
                        templateCode={templateCode}
                        isCreating={isCreatingNewTemplate}
                        isCreatingDisabled={isUpdatingReportConfiguration
                    || (!isCreatingNewTemplate && (!isEditingForm
                        || (!reportConfigurationData && !isCreatingNewTemplate)))}
                        isEditing={isEditingForm}
                        setIsEditing={setIsEditingForm}
                        titleBack="Назад"
                        isEditingDisabled={!reportConfigurationData && !isCreatingNewTemplate}
                        onSubmit={() => {
                            form.submit();
                        }}
                        onCancelEdit={() => {
                            form.resetFields();
                            if (!isCreatingNewTemplate) { setIsEditingForm(false); }
                        }}
                        onBack={() => history.push(REPORT_CONFIGURATION_NAME)}
                    />
                    <Form<Pick<ReportConfigurationDataConverted, ReportConfiguratorMainFields>>
                        form={form}
                        initialValues={formInitialValues}
                        onFinish={handleFinish}
                        layout="vertical"
                        onValuesChange={() => {
                            applyFieldWatchers();
                        }}
                    >
                        <div className={cn('report-configurator__section')}>
                            <div className={cn('report-configurator__section__title')}>Основные параметры</div>
                            <div className={cn('report-configurator__section__body')}>
                                <div className={cn(
                                    'report-configurator__section__body__row',
                                )}
                                >
                                    <Form.Item
                                        style={{width: 555}}
                                        name="templateName"
                                        label="Наименование шаблона"
                                        rules={[{required: true}]}
                                    >
                                        <Input
                                            disabled={checkWatcherFlag('templateName', WatcherFieldFlag.isDisabled)}
                                            placeholder="Наименование шаблона"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        style={{width: 160}}
                                        name="templateCode"
                                        label="Код"
                                        rules={[{required: true}]}
                                    >
                                        <Input
                                            disabled={!isCreatingNewTemplate
                                            || checkWatcherFlag('templateCode', WatcherFieldFlag.isDisabled)}
                                            placeholder="Код"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        style={{width: 555}}
                                        name="reportName"
                                        label="Наименование формы отчетности"
                                        rules={[{required: true}]}
                                    >
                                        <Input
                                            disabled={checkWatcherFlag('reportName', WatcherFieldFlag.isDisabled)}
                                            placeholder="Наименование формы отчетности"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        style={{width: 555}}
                                        name="designTemplate"
                                        label="Выбор макета"
                                    >
                                        <CustomSelect
                                            settings={{
                                                showSearch: true,
                                                isDisabled: checkWatcherFlag('designTemplate',
                                                    WatcherFieldFlag.isDisabled),
                                                placeholder: 'Выберите макет',
                                                url: '/lookupValue/TABLE_REPORT_DESIGN_TEMPLATE',
                                                labelPath: 'meaning',
                                                valuePath: 'lookupCode',
                                                formFieldKey: 'designTemplate',
                                                formInstance: form,
                                                triggerOnValueSet: true,
                                            }}
                                            handleTriggerEvent={(val, event) => {
                                                if (event === CustomSelectValueTriggerEvent.valueHasBeenChangedManually
                                                    || event === CustomSelectValueTriggerEvent.valueWasSet) {
                                                    dispatch(setReportConfigurationTemplateBlocks(
                                                        Number(val?.attribute1),
                                                    ));
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        style={{width: 160}}
                                        name="paginationPosition"
                                        label="Позиция пагинации"
                                        initialValue="TOP"
                                    >
                                        <CustomSelect
                                            settings={{
                                                showSearch: true,
                                                isDisabled: checkWatcherFlag(
                                                    'paginationPosition', WatcherFieldFlag.isDisabled,
                                                ),
                                                placeholder: 'Выберите позицию',
                                                url: '/lookupValue/TABLE_REPORT_PAGINATION_POSITION',
                                                labelPath: 'meaning',
                                                valuePath: 'lookupCode',
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                <div className={cn('report-configurator__section__body__row')}>
                                    <Form.Item
                                        name="enabledMenu"
                                        valuePropName="checked"
                                        extra={reportConfigurationData?.enabledMenu
                                            && !reportConfigurationData.reservedColumns?.sheetCode
                                            && (
                                                <span className={cn('report-configurator__section__body__row__extra')}>
                                                    Не заполнено поле «Столбец для определения раздела»
                                                </span>
                                            )}
                                    >
                                        <Checkbox
                                            disabled={checkWatcherFlag('enabledMenu', WatcherFieldFlag.isDisabled)}
                                        >
                                            Использовать оглавление
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name="withDocumentId"
                                        valuePropName="checked"
                                    >
                                        <Checkbox disabled={checkWatcherFlag(
                                            'withDocumentId', WatcherFieldFlag.isDisabled,
                                        )}
                                        >
                                            Связь с документом
                                        </Checkbox>
                                    </Form.Item>
                                </div>
                                {!isCreatingNewTemplate && (
                                    <div className={cn('report-configurator__section__body__row')}>
                                        <ClickConfirmation
                                            skip={getLeaveTabConfirmationSkipCondition}
                                            labels={{main: switchTabConfirmationLabel}}
                                        >
                                            <Button
                                                type="primary"
                                                onClick={() => {
                                                    setSelectedTabKey?.(
                                                        ReportConfigurationTabKey.REPORT_PROPERTIES_SETTINGS,
                                                    );
                                                }}
                                                disabled={isCreatingNewTemplate}
                                            >Параметры отчёта
                                            </Button>
                                        </ClickConfirmation>
                                        <ClickConfirmation
                                            skip={getLeaveTabConfirmationSkipCondition}
                                            labels={{main: switchTabConfirmationLabel}}
                                        >
                                            <Button
                                                onClick={() => {
                                                    setSelectedTabKey?.(
                                                        ReportConfigurationTabKey.REPORT_TABLE_OF_CONTENTS,
                                                    );
                                                }}
                                                disabled={
                                                    !reportConfigurationData?.enabledMenu
                                        || isCreatingNewTemplate
                                        || checkWatcherFlag('buttonHeaderSettings',
                                            WatcherFieldFlag.isDisabled)
                                                }
                                                type="primary"
                                            >Настройка оглавления
                                            </Button>
                                        </ClickConfirmation>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={cn('report-configurator__section')}>
                            <div className={cn('report-configurator__section__title')}>
                                Источник данных заголовка отчёта
                            </div>
                            <div className={cn('report-configurator__section__body')}>
                                <div
                                    className={cn(
                                        'report-configurator__section__body__row',
                                    // 'report-configurator__section__body__row_grow',
                                    )}
                                >
                                    <Form.Item
                                        style={{width: 555}}
                                        name="headerDatasourceType"
                                        label="Тип источника"
                                    >
                                        <CustomSelect
                                            settings={{
                                                showSearch: true,
                                                isDisabled: checkWatcherFlag('headerDatasourceType',
                                                    WatcherFieldFlag.isDisabled),
                                                placeholder: 'Выберите тип',
                                                url: '/lookupValue/TABLE_REPORT_SOURCE_TYPE',
                                                labelPath: 'meaning',
                                                valuePath: 'lookupCode',
                                                formFieldKey: 'headerDatasourceType',
                                                depsToCallCustomTrigger: [shouldUpdateSelectUrlsTrigger],
                                            }}
                                            handleTriggerEvent={(val, event) => {
                                                if (val?.lookupCode && val?.attribute1) {
                                                    const fetchUrl = `${reportConfiguratorFetchMapping[
                                                        val.lookupCode]}/${val.attribute1}`;
                                                    setValuesReferenceUrls(p => {
                                                        if (p.headerDatasourceValue === fetchUrl) return p;
                                                        if (event === CustomSelectValueTriggerEvent
                                                            .valueHasBeenChangedManually) {
                                                            shouldSetFirstValueToHeaderValueRef.current = true;
                                                        }
                                                        return {
                                                            ...p,
                                                            headerDatasourceValue: fetchUrl,
                                                        };
                                                    });
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                    {!checkWatcherFlag('headerDatasourceValue', WatcherFieldFlag.isHidden) && (
                                        <Form.Item
                                            style={{width: 555}}
                                            name="headerDatasourceValue"
                                            label="Значение"
                                        >
                                            <CustomSelect
                                                handleTriggerEvent={(value, event, entries) => {
                                                    if (event === CustomSelectValueTriggerEvent.firstOptionWasSet) {
                                                        if (value) applyFieldWatchers();
                                                    }
                                                    if (event === CustomSelectValueTriggerEvent
                                                        .receivedDataFromFetcher) {
                                                        if (shouldSetFirstValueToHeaderValueRef.current) {
                                                            shouldSetFirstValueToHeaderValueRef.current = false;
                                                            if (entries?.length) {
                                                                const [{value: firstEntryValue}] = entries;
                                                                const {lookupCode} = firstEntryValue ?? {};
                                                                setFieldsValueUntouched(form, {
                                                                    headerDatasourceValue: lookupCode,
                                                                });
                                                            }
                                                        }
                                                    }
                                                }}
                                                settings={{
                                                    showSearch: true,
                                                    isDisabled: checkWatcherFlag(
                                                        'headerDatasourceValue', WatcherFieldFlag.isLoading,
                                                    ) || checkWatcherFlag(
                                                        'headerDatasourceValue', WatcherFieldFlag.isDisabled,
                                                    ),
                                                    isLoading: checkWatcherFlag(
                                                        'headerDatasourceValue', WatcherFieldFlag.isLoading,
                                                    ),
                                                    url: valuesReferenceUrls.headerDatasourceValue,
                                                    placeholder: 'Выберите значение',
                                                    labelPath: 'meaning',
                                                    valuePath: 'lookupCode',
                                                    formFieldKey: 'headerDatasourceValue',
                                                    formInstance: form,
                                                    useFirstOptionAfterReceivedFromFetcher: true,
                                                    noUseFirstOptionWhenValueIsSet: true,
                                                    allowTriggerWhenNoValueFound: true,
                                                }}
                                            />
                                        </Form.Item>
                                    )}
                                    {!checkWatcherFlag('headerDatasourceSqlValue', WatcherFieldFlag.isHidden) && (
                                        <Form.Item
                                            style={{width: 555}}
                                            name="headerDatasourceSqlValue"
                                            label="Динамический SQL"
                                        >
                                            <LargeStringInput
                                                saveButtonLabel="Подтвердить"
                                                disabled={checkWatcherFlag('headerDatasourceSqlValue',
                                                    WatcherFieldFlag.isDisabled)}
                                                name="headerDatasourceSqlValue"
                                                label="Динамический SQL"
                                                placeholder="Динамический SQL"
                                            />
                                        </Form.Item>
                                    )}
                                </div>
                                <div className={cn('report-configurator__section__body__row')}>
                                    <Form.Item
                                        name="enabledColumnTitleOrdinals"
                                        valuePropName="checked"
                                    >
                                        <Checkbox disabled={checkWatcherFlag('enabledColumnTitleOrdinals',
                                            WatcherFieldFlag.isDisabled)}
                                        >
                                            Вывод шапки отчёта с порядковым номером столбцов
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name="enabledComparisonBtn"
                                        valuePropName="checked"
                                    >
                                        <Checkbox disabled={checkWatcherFlag('enabledComparisonBtn',
                                            WatcherFieldFlag.isDisabled)}
                                        >
                                            Сравнение
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name="enabledCrBtn"
                                        valuePropName="checked"
                                    >
                                        <Checkbox disabled={checkWatcherFlag('enabledCrBtn',
                                            WatcherFieldFlag.isDisabled)}
                                        >
                                            Показать КС
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name="enabledColumnsSettingsBtn"
                                        valuePropName="checked"
                                    >
                                        <Checkbox disabled={checkWatcherFlag('enabledColumnsSettingsBtn',
                                            WatcherFieldFlag.isDisabled)}
                                        >
                                            Настройка полей
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name="enabledRefreshBtn"
                                        valuePropName="checked"
                                    >
                                        <Checkbox disabled={checkWatcherFlag('enabledRefreshBtn',
                                            WatcherFieldFlag.isDisabled)}
                                        >
                                            Кнопка &quot;Обновить&quot;
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name="enabledSearch"
                                        valuePropName="checked"
                                    >
                                        <Checkbox disabled={checkWatcherFlag('enabledSearch',
                                            WatcherFieldFlag.isDisabled)}
                                        >
                                            Поиск
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name="enabledDownloadBtn"
                                        valuePropName="checked"
                                    >
                                        <Checkbox disabled={checkWatcherFlag('enabledDownloadBtn',
                                            WatcherFieldFlag.isDisabled)}
                                        >
                                            Скачивание
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name="actionsEnabled"
                                        valuePropName="checked"
                                    >
                                        <Checkbox
                                            disabled={checkWatcherFlag('actionsEnabled',
                                                WatcherFieldFlag.isDisabled)}
                                            checked={reportConfigurationData?.actionsEnabled}
                                        >
                                            Действия
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name="enabledGroupingSettingsBtn"
                                        valuePropName="checked"
                                    >
                                        <Checkbox
                                            disabled={checkWatcherFlag('enabledGroupingSettingsBtn',
                                                WatcherFieldFlag.isDisabled)}
                                        >
                                            Кнопка &quot;Настройка группировки&quot;
                                        </Checkbox>
                                    </Form.Item>
                                </div>
                                {!isCreatingNewTemplate && (
                                    <div className={cn('report-configurator__section__body__row')}>
                                        <ClickConfirmation
                                            skip={getLeaveTabConfirmationSkipCondition}
                                            labels={{main: switchTabConfirmationLabel}}
                                        >
                                            <Button
                                                onClick={() => {
                                                    setSelectedTabKey?.(
                                                        ReportConfigurationTabKey.REPORT_HEADER_SETTINGS,
                                                    );
                                                }}
                                                disabled={isCreatingNewTemplate}
                                                type="primary"
                                            >Настройка заголовка отчёта
                                            </Button>
                                        </ClickConfirmation>
                                        <ModalReportDownloadSettings
                                            isDisabled={
                                                !reportConfigurationData?.enabledDownloadBtn
                                            || checkWatcherFlag('buttonEnabledDownloadBtn',
                                                WatcherFieldFlag.isDisabled)
                                            }
                                        />
                                        <ModalReportActionsSettings
                                            templateCode={templateCode}
                                            isDisabled={
                                                checkWatcherFlag('buttonActionsEnabled', WatcherFieldFlag.isDisabled)
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={cn('report-configurator__section')}>
                            <div className={cn('report-configurator__section__title')}>
                                Источник данных табличной части отчета
                            </div>
                            <div className={cn('report-configurator__section__body')}>
                                <div className={cn(
                                    'report-configurator__section__body__row',
                                // 'report-configurator__section__body__row_grow',
                                )}
                                >
                                    <Form.Item
                                        style={{width: 555}}
                                        name="tableDatasourceType"
                                        label="Тип источника"
                                    >
                                        <CustomSelect
                                            settings={{
                                                showSearch: true,
                                                isDisabled: checkWatcherFlag(
                                                    'tableDatasourceType', WatcherFieldFlag.isDisabled,
                                                ),
                                                placeholder: 'Выберите тип',
                                                url: '/lookupValue/TABLE_REPORT_SOURCE_TYPE',
                                                labelPath: 'meaning',
                                                valuePath: 'lookupCode',
                                                formFieldKey: 'tableDatasourceType',
                                                depsToCallCustomTrigger: [shouldUpdateSelectUrlsTrigger],
                                            }}
                                            handleTriggerEvent={(val, event) => {
                                                if (val?.lookupCode && val?.attribute1) {
                                                    const fetchUrl = `${reportConfiguratorFetchMapping[
                                                        val.lookupCode]}/${val.attribute1}`;
                                                    setValuesReferenceUrls(p => {
                                                        if (p.tableDatasourceValue === fetchUrl) return p;
                                                        if (event === CustomSelectValueTriggerEvent
                                                            .valueHasBeenChangedManually) {
                                                            shouldSetFirstValueToTableValueRef.current = true;
                                                        }
                                                        return {
                                                            ...p,
                                                            tableDatasourceValue: fetchUrl,
                                                        };
                                                    });
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                    {!checkWatcherFlag('tableDatasourceValue', WatcherFieldFlag.isHidden) && (
                                        <Form.Item
                                            style={{width: 555}}
                                            name="tableDatasourceValue"
                                            label="Значение"
                                        >
                                            <CustomSelect
                                                handleTriggerEvent={(value, event, entries) => {
                                                    if (event === CustomSelectValueTriggerEvent.firstOptionWasSet) {
                                                        if (value) applyFieldWatchers();
                                                    }
                                                    if (event === CustomSelectValueTriggerEvent
                                                        .receivedDataFromFetcher) {
                                                        if (shouldSetFirstValueToTableValueRef.current) {
                                                            shouldSetFirstValueToTableValueRef.current = false;
                                                            if (entries?.length) {
                                                                const [{value: firstEntryValue}] = entries;
                                                                const {lookupCode} = firstEntryValue ?? {};
                                                                setFieldsValueUntouched(form, {
                                                                    tableDatasourceValue: lookupCode,
                                                                });
                                                            }
                                                        }
                                                    }
                                                }}
                                                settings={{
                                                    showSearch: true,
                                                    isDisabled: checkWatcherFlag(
                                                        'tableDatasourceValue', WatcherFieldFlag.isLoading,
                                                    )
                                                    || checkWatcherFlag(
                                                        'tableDatasourceValue', WatcherFieldFlag.isDisabled,
                                                    ),
                                                    isLoading: checkWatcherFlag(
                                                        'tableDatasourceValue', WatcherFieldFlag.isLoading,
                                                    ),
                                                    url: valuesReferenceUrls.tableDatasourceValue,
                                                    placeholder: 'Выберите значение',
                                                    labelPath: 'meaning',
                                                    valuePath: 'lookupCode',
                                                    useFirstOptionAfterReceivedFromFetcher: true,
                                                    noUseFirstOptionWhenValueIsSet: true,
                                                    allowTriggerWhenNoValueFound: true,
                                                    formFieldKey: 'tableDatasourceValue',
                                                }}
                                            />
                                        </Form.Item>
                                    )}
                                    {!checkWatcherFlag('tableDatasourceSqlValue', WatcherFieldFlag.isHidden) && (
                                        <Form.Item
                                            style={{width: 555}}
                                            name="tableDatasourceSqlValue"
                                            label="Динамический SQL"
                                        >
                                            <LargeStringInput
                                                saveButtonLabel="Подтвердить"
                                                disabled={checkWatcherFlag('tableDatasourceSqlValue',
                                                    WatcherFieldFlag.isDisabled)}
                                                name="tableDatasourceSqlValue"
                                                label="Динамический SQL"
                                                placeholder="Динамический SQL"
                                            />
                                        </Form.Item>
                                    )}
                                </div>
                                <div className={cn('report-configurator__section__body__row')}>
                                    <Form.Item
                                        name="enabledRowComments"
                                        valuePropName="checked"
                                    >
                                        <Checkbox disabled={
                                            checkWatcherFlag('enabledRowComments', WatcherFieldFlag.isDisabled)
                                        }
                                        >
                                            Комментарии к строкам
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name="enabledRowAttachments"
                                        valuePropName="checked"
                                        extra={reportConfigurationData?.enabledRowAttachments
                                            && !reportConfigurationData.reservedColumns?.rowIdColumnName
                                            && (
                                                <span className={cn('report-configurator__section__body__row__extra')}>
                                                    Не заполнено поле «Уникальный идентификатор строки»
                                                </span>
                                            )}
                                    >
                                        <Checkbox disabled={
                                            checkWatcherFlag('enabledRowAttachments', WatcherFieldFlag.isDisabled)
                                        }
                                        >
                                            Вложения к строкам
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name="enabledRowRequests"
                                        valuePropName="checked"
                                    >
                                        <Checkbox disabled={
                                            checkWatcherFlag('enabledRowRequests', WatcherFieldFlag.isDisabled)
                                        }
                                        >
                                            Создание запроса по строке
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name="enabledComparison"
                                        valuePropName="checked"
                                    >
                                        <Checkbox disabled={
                                            checkWatcherFlag('enabledComparison', WatcherFieldFlag.isDisabled)
                                        }
                                        >
                                            Добавить к сравнению
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name="enabledCr"
                                        valuePropName="checked"
                                    >
                                        <Checkbox disabled={
                                            checkWatcherFlag('enabledCr', WatcherFieldFlag.isDisabled)
                                        }
                                        >
                                            Настройка КС
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name="enabledPrint"
                                        valuePropName="checked"
                                    >
                                        <Checkbox disabled={
                                            checkWatcherFlag('enabledPrint', WatcherFieldFlag.isDisabled)
                                        }
                                        >
                                            Печать
                                        </Checkbox>
                                    </Form.Item>
                                </div>
                                {!isCreatingNewTemplate && (
                                    <div className={cn('report-configurator__section__body__row')}>
                                        <ClickConfirmation
                                            skip={getLeaveTabConfirmationSkipCondition}
                                            labels={{main: switchTabConfirmationLabel}}
                                        >
                                            <Button
                                                disabled={isCreatingNewTemplate}
                                                type="primary"
                                                onClick={() => {
                                                    setSelectedTabKey?.(
                                                        ReportConfigurationTabKey.REPORT_TABLE_SETTINGS,
                                                    );
                                                }}
                                            >Настройка табличной части
                                            </Button>
                                        </ClickConfirmation>
                                        <TableReservedColumnsModalOpener
                                            isSheetCodeRequired={checkWatcherFlag('paginationSheetCode',
                                                WatcherFieldFlag.isRequired)}
                                            isDisabled={
                                                checkWatcherFlag('buttonPaginationSettings',
                                                    WatcherFieldFlag.isDisabled)
                                            }
                                        />
                                        <ClickConfirmation
                                            skip={getLeaveTabConfirmationSkipCondition}
                                            labels={{main: switchTabConfirmationLabel}}
                                        >
                                            <Button
                                                disabled={
                                                    isCreatingNewTemplate
                                            || !reportConfigurationData?.enabledComparison
                                            || checkWatcherFlag('buttonEnabledComparison', WatcherFieldFlag.isDisabled)
                                                }
                                                type="primary"
                                                onClick={() => {
                                                    setSelectedTabKey?.(ReportConfigurationTabKey.REPORT_COMPARISON);
                                                }}
                                            >Настройка сравнения
                                            </Button>
                                        </ClickConfirmation>
                                        <ClickConfirmation
                                            skip={getLeaveTabConfirmationSkipCondition}
                                            labels={{main: switchTabConfirmationLabel}}
                                        >
                                            <Button
                                                disabled={
                                                    !reportConfigurationData?.enabledCr
                                                || isCreatingNewTemplate
                                                || checkWatcherFlag('buttonEnabledCrSettings',
                                                    WatcherFieldFlag.isDisabled)
                                                }
                                                type="primary"
                                                onClick={() => {
                                                    setSelectedTabKey?.(ReportConfigurationTabKey.REPORT_KS_SETTINGS);
                                                }}
                                            >Настройка КС
                                            </Button>
                                        </ClickConfirmation>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Form>
                </div>
            </Spin>
        </>
    );
};
