import {Divider, Form as AntForm} from 'antd';
import React, {
    useEffect,
    useMemo, useRef, useState,
} from 'react';

import {SimpleActionButton} from 'components/form/components';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';
import {tableReportSliceActions} from 'store/slices/table-report-slice';
import {selectTableReportDefaultQueryParams, selectTableReportRawQueryParams} from 'store/slices/table-report-slice/table-report-query-params/table-report-query-params-selectors';
import {
    selectTableReportTemplateConfig,
} from 'store/slices/table-report-slice/table-report-slice-selectors';
import {loadTableReportPageData} from 'store/slices/table-report-slice/table-report-slice-thunks';
import {TableReportQueryParameters} from 'store/slices/table-report-slice/table-report-slice-types';

import {TableReportFilter} from './filters/table-report-filter';
import {convertFilterFormValuesToQueryParameters, convertQueryParametersToFilterFormValues} from './filters/utils/table-report-filter.utils';

import './filter-header.less';

export const FilterHeader: React.FC = () => {
    const [form] = AntForm.useForm<Record<string, any>>();
    const [areParametersApplied, setAreParametersApplied] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    const isLoadingPageData = useAppSelector(s => selectIsThunkPending(s, loadTableReportPageData.typePrefix));

    const {
        queryParameters: queryParametersInConfig,
        tableDatasourceType,
    } = useAppSelector(selectTableReportTemplateConfig) || {};

    // ключ (name), для каждого поля в фильтрах
    const filterKeyPath = tableDatasourceType === 'TABLE' ? 'columnName' : 'paramName';

    const defaultQueryParameters = useAppSelector(selectTableReportDefaultQueryParams);
    const queryParameters = useAppSelector(selectTableReportRawQueryParams);

    const [initialValues, setInitialValues] = useState<TableReportQueryParameters>();

    const {setTableReportQueryParams} = tableReportSliceActions;

    const areLocationParametersSetRef = useRef(false);

    useEffect(() => {
        const defaultParametersConverted = convertQueryParametersToFilterFormValues({
            filterKeyPath,
            queryParameters: queryParametersInConfig,
            values: defaultQueryParameters,
        });
        setInitialValues(defaultParametersConverted);
    }, [defaultQueryParameters]);

    useEffect(() => {
        form.resetFields();

        if (queryParameters) {
            // на случай, если в стейте страницы есть уже какие-то параметры
            if (!areLocationParametersSetRef.current) {
                areLocationParametersSetRef.current = true;

                setTimeout(() => {
                    form.setFieldsValue(convertQueryParametersToFilterFormValues({
                        filterKeyPath,
                        queryParameters: queryParametersInConfig,
                        values: queryParameters,
                    }));
                }, 0);
            }
        }
    }, [initialValues]);

    const handleResetFilter = () => {
        form.resetFields();
        dispatch(setTableReportQueryParams({
            params: defaultQueryParameters,
        }));
        setAreParametersApplied(true);
    };

    const handleSubmitFilter = (values: Record<string, any>) => {
        // скрытые параметры не попадают в values,
        // однако их значения надо отправлять
        const pickedHiddenDefaultValuesFromForm = Object.fromEntries(Object.entries(defaultQueryParameters ?? {})
            .filter(([queryParamKey]) => {
                const param = queryParametersInConfig?.find(p => p[filterKeyPath] === queryParamKey);
                return param?.visible === false;
            })
            .map(([queryParamKey]) => [queryParamKey, form.getFieldValue(queryParamKey)]));

        const convertedValues = convertFilterFormValuesToQueryParameters({
            ...pickedHiddenDefaultValuesFromForm,
            ...values,
        },
        queryParametersInConfig,
        filterKeyPath);

        dispatch(setTableReportQueryParams({params: convertedValues}));
        setAreParametersApplied(true);
    };

    const sortedQueryParameters = useMemo(() => [...queryParametersInConfig ?? []]
        ?.sort((a, b) => a.ordinal - b.ordinal),
    [queryParametersInConfig]);

    return (
        <div className="table-report-check-header">
            <div className="filters">
                <div className="title">
                    <h2 className="text">Параметры отчета</h2>
                    <Divider className="divider" />
                </div>
                <AntForm
                    form={form}
                    initialValues={initialValues}
                    onFinish={handleSubmitFilter}
                    layout="vertical"
                    className="table-report-filter-form"
                >
                    <div className="header-filters">
                        <TableReportFilter
                            filterKeyPath={filterKeyPath}
                            queryParameters={sortedQueryParameters}
                            areParametersApplied={areParametersApplied}
                        />
                    </div>
                </AntForm>
                {areParametersApplied ? (
                    <SimpleActionButton
                        type="default"
                        className="button_active"
                        onClick={() => setAreParametersApplied(false)}
                        title="Скорректировать текущие параметры"
                    />
                ) : (
                    <div className="buttons">
                        <SimpleActionButton
                            type="primary"
                            onClick={() => form.submit()}
                            title="Применить"
                            disabled={isLoadingPageData}
                        />
                        <SimpleActionButton
                            type="default"
                            onClick={handleResetFilter}
                            title="Сбросить"
                            disabled={isLoadingPageData}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
