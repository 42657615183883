import {useContext} from 'react';

import {selectContextRawData, selectEntityNamesFetchedWithContext} from 'modules/context/context-selector';
import {
    selectFilterEntityData, selectIsEntityDataLoading,
    selectTableEntityData,
    selectTableStateEntityData,
} from 'modules/data/data-selectors';
import {DefaultTableStateFlat} from 'modules/data/data-types';
import {selectTableEntityMetadata} from 'modules/metadata/metadata-selectors';
import {LookupEntry} from 'shared/types/lookups';
import {useAppSelector} from 'store/config/hooks';

import {TableModeContext} from '../../table/context';
import {convertFlatObjectToTableState} from '../../table/utils/table.utils';
import {MONITOR_PROGRAM_KEY} from '../constants/setting-program.constants';

interface UseSettingProgramMappingArgs {
    entityName: string;
}

export const useSettingProgramMapping = ({
    entityName,
}: UseSettingProgramMappingArgs) => {
    const entityNamesFetchedWithContext = useAppSelector(selectEntityNamesFetchedWithContext);
    const wasFetchedWithContext = entityNamesFetchedWithContext.includes(entityName);
    const storedTableState = (useAppSelector(selectTableStateEntityData(entityName)));
    const contextRawData = useAppSelector(selectContextRawData);
    const metadata = useAppSelector(selectTableEntityMetadata(entityName));
    const loading = useAppSelector(selectIsEntityDataLoading(entityName));
    const filter = useAppSelector(selectFilterEntityData(entityName));
    const data = useAppSelector(selectTableEntityData(entityName));

    const {resetTableMode} = useContext(TableModeContext);
    const tableState = convertFlatObjectToTableState(data?.params as DefaultTableStateFlat);

    const paramMonitorProgram: LookupEntry = filter?.data?.paramMonitorProgramId as LookupEntry;
    const isMonitorParsingCsv = paramMonitorProgram?.attribute1 === MONITOR_PROGRAM_KEY;
    const tableDataOptions = {
        useContext: metadata?.useContext,
        useSearch: metadata?.isSearchable,
        disabledPagination: metadata?.disabledPagination,
    };

    return {
        wasFetchedWithContext,
        isMonitorParsingCsv,
        tableDataOptions,
        storedTableState,
        contextRawData,
        tableState,
        metadata,
        loading,
        filter,
        data,
        resetTableMode,
    };
};
