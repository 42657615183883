import {UserRoleFunction} from 'store/slices/user-slice/user-slice-role-functions';

import {RoleModelSection} from './form-types';

export enum ROLE_MODEL_SECTION_CODES {
    NEWS = 'app.news.title',
    USER_SETTINGS = 'app.settings.user.settings.title'
}

export const RoleModelSections: RoleModelSection[] = [
    {
        functionName: UserRoleFunction.CREATE,
        section: ROLE_MODEL_SECTION_CODES.NEWS,
    },
    {
        functionName: UserRoleFunction.CREATE,
        section: ROLE_MODEL_SECTION_CODES.USER_SETTINGS,
    },
];
