import {Descriptions} from 'antd';
import React from 'react';

import {FieldMeta, FieldType} from 'modules/metadata';

import {createStringDate} from '../../table/utils/table.utils';

export const directoryItemDescriptionTypeResolver = (
    field: FieldMeta,
    data: any,
) => {
    if (field.type === FieldType.DATE) {
        return (
            <Descriptions.Item
                label={field.label}
                key={field.key}
            >
                {createStringDate(data[field.key], field.dateFormat)}
            </Descriptions.Item>
        );
    }
    return (
        <Descriptions.Item
            label={field.label}
            key={field.key}
        >
            {data[field.key]}
        </Descriptions.Item>

    );
};
