import {useMemo} from 'react';

import {maskValidation} from 'components/form/utils/field-validation-resolver';
import {ValidationRules} from 'modules/metadata/metadata-types';
import {useGetAppPropertyQuery, useGetAppPropertySettingsListQuery} from 'store/api/app-properties/app-properties.api';

export interface UseFieldPropertySettingsProps {
    validationRules?: ValidationRules[];
    propertyCode?: string;
    propertyCodeList?: string[];
}

// Маски валидации для которых дополнительно нужны свойства propertySettings из "Настройки приложения"
const PropertySettingsValidation: Record<string, string> = {
    fromPropertyListPassword: maskValidation.fromPropertyListPassword,
    fromPropertyListPasswordLength: maskValidation.fromPropertyListPasswordLength,
};

export const useFieldPropertySettings = (
    {validationRules, propertyCode, propertyCodeList}: UseFieldPropertySettingsProps,
) => {
    // Запрос на получение propertySettings произойдет, если свойства используются для валидации
    const isPropertySettingsValidation = useMemo(() => validationRules?.find(
        rule => (rule.mask ? PropertySettingsValidation[rule.mask] : false),
    ), [validationRules]);
    const {data: regularFromProperty} = useGetAppPropertyQuery(
        {propertyCode}, {skip: !propertyCode},
    );
    // propertySettings - объект свойств настройки приложения
    // propertyCodeList - массив кодов свойств, которые нужно получить, задается в метаданных
    const {data: propertySettings} = useGetAppPropertySettingsListQuery({propertyCodeList},
        {skip: !propertyCodeList || !isPropertySettingsValidation});

    return {regularFromProperty, propertySettings};
};
