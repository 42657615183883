import {createSelector} from '@reduxjs/toolkit';

import {AppState} from '../../config/types';
import {requestSliceName} from './request-slice-constants';
import {RequestSliceState} from './request-slice-type';

const selectRequestSliceState = (state: AppState) => state[requestSliceName];

export const selectRequestData = createSelector(
    selectRequestSliceState,
    state => state?.requestData,
);
export const selectRequestReplies = createSelector(
    selectRequestSliceState,
    state => state?.requestData?.requestReplies,
);
export const selectRequestStatus = (requestKey: string) => createSelector(
    selectRequestSliceState,
    (state: RequestSliceState) => state?.requestStatuses?.[requestKey],
);
export const selectRequestFields = createSelector(
    selectRequestSliceState,
    (state: RequestSliceState) => state?.requestFormFields,
);
export const selectRequestAdditionalFields = createSelector(
    selectRequestSliceState,
    state => state?.requestData?.requests?.flexFieldList,
);
