import {useEffect, useRef, useState} from 'react';

export const useClickAwayListener = () => {
    const popupRef = useRef<HTMLDivElement>(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const outsideClickHandler = (e: MouseEvent) => {
            if (e.target && !popupRef.current?.contains(e.target as Node)) {
                setIsVisible(false);
            }
        };
        document.addEventListener('mouseup', outsideClickHandler);

        const closeOnEscHandler = (e: KeyboardEvent) => {
            if (e.key === 'Escape') setIsVisible(false);
        };
        document.addEventListener('keydown', closeOnEscHandler);

        return () => {
            document.removeEventListener('mouseup', outsideClickHandler);
            document.removeEventListener('keydown', closeOnEscHandler);
        };
    }, []);

    return {isVisible, setIsVisible, popupRef};
};
