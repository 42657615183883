import {isUndefined, omitBy} from 'lodash';

import {QueryOptions} from './query-params-types';

export const extractQueryParam = (name: string, query?: URLSearchParams) => {
    const urlQuery = query ?? new URLSearchParams(window.location.search);
    const param = urlQuery.get(name);
    return param;
};

export const extractQueryParamJSON = (name: string, query?: URLSearchParams) => {
    try {
        const param = extractQueryParam(name, query);
        if (param) return JSON.parse(param);
        return undefined;
    } catch {
        return extractQueryParam(name, query);
    }
};

export const setUrlPath = (history: any, newQuery?: URLSearchParams, options: QueryOptions = {}) => {
    if (!newQuery) return;

    const {action = 'push'} = options;
    const {pathname} = window.location;

    if (action === 'push') {
        history.push({location: pathname, search: newQuery.toString()});
    } else history.replace({location: pathname, search: newQuery.toString()});
};

interface SetQueryParamAsJSONArgs {
    name: string;
    value: any;
    options?: QueryOptions;
    query?: URLSearchParams;
    history: any;
}
export const setQueryParamAsJSON = ({
    name,
    value: rawValue,
    options,
    query,
    history,
}: SetQueryParamAsJSONArgs) => {
    const urlQuery = query ?? new URLSearchParams(window.location.search);
    if (!rawValue) return;

    const value = omitBy(rawValue, isUndefined);
    try {
        if (!Object.keys(value).length) {
            urlQuery.delete(name);
        } else {
            const stringifiedValue = JSON.stringify(value);
            urlQuery.set(name, stringifiedValue);
        }
        if (history) { setUrlPath(history, query, options); }
    } catch {
        // pass
    }
};
