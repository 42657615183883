import React from 'react';
import {Route, Switch} from 'react-router-dom';

import {JasperReport} from 'components/jasper-report/jasper-report';
import {PageTemplateProps} from 'pages';

export const JasperReportPage: React.FunctionComponent<PageTemplateProps> = ({
    url,
    entityName,
}: PageTemplateProps) => (
    <Switch>
        <Route
            path={url}
            render={() => (
                <JasperReport entityName={entityName} />
            )}
        />
    </Switch>
);
