import React from 'react';

import {DefaultModalOpenerFormListProps} from 'components/report-configuration/report-configuration.types';
import {IconFormatSettingsModalOpener} from 'components/report-configuration/tabs/report-table-settings/modals/icon-format-settings-modal-opener';
import {SwitchFormatSettingsModalOpener} from 'components/report-configuration/tabs/report-table-settings/modals/switch-format-settings-modal-opener';
import {CustomColumnType} from 'store/slices/report-configuration-slice/report-configuration-dto';

interface TypeDependentFormatSettingsModalOpenerProps extends DefaultModalOpenerFormListProps {
    type: CustomColumnType | undefined;
}

export const TypeDependentFormatSettingsModalResolver = (props: TypeDependentFormatSettingsModalOpenerProps) => {
    const {type} = props;

    return (
        <>
            {
                (() => {
                    switch (type) {
                    case 'ICON': {
                        return (
                            <IconFormatSettingsModalOpener
                                {...props}
                            />
                        );
                    }
                    case 'SWITCH': {
                        return (
                            <SwitchFormatSettingsModalOpener
                                {...props}
                            />
                        );
                    }
                    default:
                        return null;
                    }
                })()
            }
        </>
    );
};
