import {pick} from 'lodash';
import {v4 as uid} from 'uuid';

import {LookupEntry} from 'shared/types/lookups';
import {ReportTableColumn} from 'store/slices/report-configuration-slice/dd-lookups';

import {
    DDFilterExpessionGroupIdentificator,
    DDFilterExpression,
    DDFilterExpressionDto,
    DDFilterOperand,
    DDFilterOperandObject,
} from './dd-filter-expression-creator-types';

type TransformFilterExpressionDtoToArrayFunction = (exp: DDFilterExpressionDto) => DDFilterExpression;
export const transformFilterExpressionDtoToExpressionArray:
    TransformFilterExpressionDtoToArrayFunction = (expressionDto: DDFilterExpressionDto) => {
        const identifier = {
            ...pick(expressionDto, [
                'connectBy', 'enabled', 'id', 'ordinal',
            ]),
            uid: uid(),
        } as DDFilterExpessionGroupIdentificator;

        const {groups, rules} = expressionDto;

        const operands: DDFilterOperand[] = rules?.length ? rules.map(
            rule => ({...rule, uid: uid()}),
        ) as DDFilterOperand[] : [];

        if (groups?.length) {
            groups.forEach(groupDto => {
                operands.push(transformFilterExpressionDtoToExpressionArray(groupDto));
            });
        }

        const operandsSorted = operands.sort((a, b) => {
            const aOrdinal = (Array.isArray(a) ? a[0].ordinal : a.ordinal) ?? 0;
            const bOrdinal = (Array.isArray(b) ? b[0].ordinal : b.ordinal) ?? 0;

            return aOrdinal === bOrdinal ? 0 : aOrdinal - bOrdinal < 0 ? -1 : 1;
        });

        return [
            identifier,
            ...operandsSorted,
        ] as DDFilterExpression;
    };

type TransformExpressionArrayToFilterExpressionDtoFunction = (exp: DDFilterExpression) => DDFilterExpressionDto;
export const transformExpressionArrayToFilterExpressionDtoFunction:
    TransformExpressionArrayToFilterExpressionDtoFunction = (expression: DDFilterExpression) => {
        const expressionWithOrdinals = expression.map((exp, index) => {
            if (!Array.isArray(exp)) {
                return {
                    ...exp,
                    ordinal: exp?.ordinal ?? index,
                };
            }

            const [identifier, ...operands] = exp;
            const identifierWithOrdinal = {...identifier, ordinal: identifier?.ordinal ?? index};
            return [
                identifierWithOrdinal,
                ...operands,
            ];
        }) as DDFilterExpression;

        const [identifier, ...operands] = expressionWithOrdinals;

        const expressionArrays = operands.filter(oper => Array.isArray(oper)) as DDFilterExpression[];
        const rules = operands.filter(oper => !Array.isArray(oper)) as DDFilterOperandObject[];

        const groups: DDFilterExpressionDto[] = [];

        (expressionArrays ?? []).forEach(expArray => {
            groups.push(transformExpressionArrayToFilterExpressionDtoFunction(expArray));
        });

        return {
            ...identifier,
            groups,
            rules,
        };
    };

export interface FindTableReportConditionByOperatorCodeArgs {
    operatorCode: string | undefined;
    filterLookups: LookupEntry[] | undefined;
}
export const findTableReportConditionByOperatorCode = ({
    filterLookups,
    operatorCode,
}: FindTableReportConditionByOperatorCodeArgs) => {
    if (!filterLookups || !operatorCode) return undefined;
    return filterLookups.find(lookup => lookup.lookupCode === operatorCode);
};

export interface GetValueFieldTypeBySelectedFieldArgs {
    fieldName: string | undefined;
    childReportColumns: ReportTableColumn[] | undefined;
}
export const findReportColumnByFieldName = ({
    fieldName,
    childReportColumns,
}: GetValueFieldTypeBySelectedFieldArgs) => {
    if (!fieldName || !childReportColumns) return undefined;
    return childReportColumns.find(col => col.name === fieldName);
};
