import {LookupEntry} from 'shared/types/lookups';
import {performRequest} from 'shared/utils';

import {ReportTableColumn} from './dd-lookups-types';

export interface FetchTableReportColumnsRequestArgs {
    templateCode: string;
}
export const fetchTableReportColumns = (
    params?: FetchTableReportColumnsRequestArgs,
) => performRequest<ReportTableColumn[]>({
    url: '/table-reports/lookup/columns',
    method: 'GET',
    params: {
        ...params,
        sourceForBlock: 'TABLE',
    },
});

export const fetchTableReportFilterConditions = () => performRequest<LookupEntry[]>({
    url: '/lookupValue/TABLE_REPORT_FILTER_VIEW',
    method: 'GET',
});
