import {Select} from 'antd';
import {FormInstance} from 'antd/es/form';
import React, {useEffect, useState} from 'react';

import {Entity} from 'modules/data';
import {GenerateOptionsRulesProps} from 'modules/metadata/metadata-types';
import {combineString} from 'shared/utils/combine-string';

interface useGenerateOptionsArgs {
    options?: Entity[];
    generateOptionsRules?: GenerateOptionsRulesProps;
    form?: FormInstance;
}

export const useGenerateOptions = ({
    options,
    generateOptionsRules,
    form,
}: useGenerateOptionsArgs) => {
    const [isGenerating, setGenerating] = useState<boolean>(!options?.length);
    const [generatedOptions, setGeneratedOptions] = useState<Entity[] | undefined>(undefined);

    const dynamicToValue = generateOptionsRules?.dynamicTo
        && combineString(generateOptionsRules.dynamicTo, form?.getFieldsValue());

    useEffect(() => {
        if (!generateOptionsRules) {
            return;
        }
        const {
            from, to, dynamicTo,
        } = generateOptionsRules;
        if (from && to) {
            setGeneratedOptions(generateOptions(from, to));
            setGenerating(false);
        }
        if (from && dynamicTo) {
            const newOptions = generateOptions(1, Number(dynamicToValue));
            if (newOptions.length) {
                setGeneratedOptions(newOptions);
            }
            setGenerating(false);
        }
    }, [form, dynamicToValue]);

    function generateOptions(from: number, to: number): any[] {
        const genOptions = [];
        for (let i = from; i <= to; i += 1) {
            genOptions?.push(
                <Select.Option
                    value={i}
                    key={i}
                    className="select-field__option"
                >
                    {i}
                </Select.Option>,
            );
        }
        return genOptions;
    }

    return {
        isGenerating,
        generatedOptions,
    };
};
