import {isArray, isEmpty} from 'lodash';

import {Properties} from '../../propperty-settings.types';
import {PropertyType} from '../property-settings-field/property-settings-field.constants';

const stringToArray = (value?: string | null) => {
    if (isEmpty(value)) return [];
    return value?.split(',')?.map(element => Number(element));
};

const arrayToString = (values: Array<number>) => values.join(',') || null;

export const getInitialValues = (formData: Array<Properties>) => {
    const initialData: Record<string, any> = {};
    formData?.forEach(data => {
        data?.properties.forEach(property => {
            initialData[property.propertyCode] = property.propertyValue?.propertyValue;
            if (property.propertyType === PropertyType.MULTIPLE_SELECT) {
                initialData[property.propertyCode] = stringToArray(property.propertyValue?.propertyValue as string);
            }
        });
    });
    return initialData;
};

export const getPropertyDifferentValues = (formData: Record<string, any>, initialValues: Record<string, any>) => {
    const differentData: Record<string, any> = {};
    Object.keys(formData)?.forEach(key => {
        if (formData[key] !== initialValues[key]) {
            differentData[key] = isArray(formData[key]) ? arrayToString(formData[key]) : formData[key];
        }
    });
    return differentData;
};
