import {Button} from 'antd';
import {FormInstance} from 'antd/es/form';
import {AxiosResponse} from 'axios';
import React, {Dispatch, SetStateAction} from 'react';
import {useDispatch} from 'react-redux';

import {DynamicIcon} from 'components/dynamic-icon';
import {TIconType} from 'components/dynamic-icon/dynamic-icon';
import {createActionGetFile} from 'modules/data/data-actions';
import {RequestType} from 'modules/metadata';
import {showMessageFromResponse} from 'shared/utils';
import {closeModal} from 'store/slices/modals-slice';

interface ButtonDownloadFileOwnProps {
    actionTitle: string;
    referenceUrl: string;
    requestType: RequestType;
    actionIcon?: TIconType;
    form?: FormInstance;
    onClose?: () => void;
    setFormErrorMessage?: Dispatch<SetStateAction<string | null>>;
    linkedEntityName?: string;
}

interface ButtonDownloadFileProps extends ButtonDownloadFileOwnProps {
}

export const ButtonDownloadFile: React.FunctionComponent<ButtonDownloadFileProps> = ({
    actionTitle,
    referenceUrl,
    requestType,
    actionIcon,
    form,
    onClose,
    setFormErrorMessage,
    linkedEntityName,
    ...props
}: ButtonDownloadFileProps) => {
    const dispatch = useDispatch();
    const handleClick = () => {
        if (!form || !onClose) {
            return;
        }
        form.validateFields().then(async data => {
            if (setFormErrorMessage) {
                setFormErrorMessage(null);
            }
            const response = await dispatch(
                createActionGetFile({
                    referenceUrl, requestType, data, isMessage: true, linkedEntityName,
                }),
            ) as unknown as AxiosResponse;
            const blob = response?.data;
            if (response?.status === 200) {
                dispatch(closeModal());
            } else if (blob instanceof Blob && blob.type === 'text/plain' && setFormErrorMessage) {
                setFormErrorMessage(await blob.text());
            } else {
                showMessageFromResponse({response, isError: true});
            }
        });
    };

    return (
        <Button
            {...props}
            type="primary"
            style={{width: '100%'}}
            onClick={handleClick}
        >
            {actionIcon && <DynamicIcon type={actionIcon} />}
            {actionTitle}
        </Button>
    );
};
