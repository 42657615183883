import cn from 'classnames';
import React, {useMemo} from 'react';

import {TCAuditStatusCode} from 'components/ais/transport-container-card/constants/transport-container-card.contants';
import {ReactComponent as Status} from 'shared/assets/circle.svg';

import 'components/ais/transport-container-card/components/tc-status-list-item/tc-status-list-item.less';

export interface TransportContainerStatusListItemProps {
    meaning: string;
    code?: number;
}

export const TransportContainerStatusListItem = ({
    meaning,
    code,
}: TransportContainerStatusListItemProps) => {
    const statusColor = useMemo(() => {
        if (!code) {
            return undefined;
        }
        if (TCAuditStatusCode.error.includes(code)) {
            return 'red';
        }
        if (TCAuditStatusCode.success.includes(code)) {
            return 'green';
        }
        return undefined;
    }, [code]);

    return (
        <div className="tc-status-list-item">
            <span>{meaning}</span>
            {code && <Status className={cn('status', statusColor)} />}
        </div>
    );
};
