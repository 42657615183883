import Button, {ButtonProps} from 'antd/es/button';
import React from 'react';
import {Link} from 'react-router-dom';

export interface LinkButtonProps {
    title: string;
    link?: string;
    type?: ButtonProps['type'];
}
export const LinkButton: React.FC<LinkButtonProps> = ({link = '', title, type = 'primary'}: LinkButtonProps) => (
    <Link to={link}>
        <Button type={type}>{title}</Button>
    </Link>
);

const linkButtonPropsPresets : Record<string, LinkButtonProps> = {
    PASS_TO_SECTION: {type: 'default', title: 'Перейти в раздел'},
    RISKS_REGISTRY: {type: 'default', title: 'Общий реестр рисков'},
    KP_REGISTRY: {type: 'default', title: 'Общий реестр КП'},
    RISKS_LIBRARY: {type: 'default', title: 'Библиотека рисков'},
    KP_LIBRARY: {type: 'default', title: 'Библиотека контрольных процедур'},
} as const;
type TLinkButtonsPropsPreset = keyof typeof linkButtonPropsPresets;

interface createLinkButtonWithPresetArgs {
    link: string;
    preset: TLinkButtonsPropsPreset;
}

export const createLinkButtonPropsWithPreset = ({link, preset}: createLinkButtonWithPresetArgs) => (
    {
        link,
        ...linkButtonPropsPresets[preset],
    }
);
