import {Table} from 'antd';
import {get} from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';

import {Entity, loadTableData, resetLoadedData} from 'modules/data';
import {initBlankFilter} from 'modules/data/actions/filter';
import {loadMetadata} from 'modules/metadata';
import {ReactComponent as ArrowRightIcon} from 'shared/assets/arrow-right.svg';
import {EntityType} from 'shared/constants/entities';
import {openModal} from 'store/slices/modals-slice';
import {ModalType} from 'store/slices/modals-slice/modals-slice-types';

import {useTableFeatures} from '../../../table/hooks/use-table-features';
import {TableActions} from '../../../table/table-actions/table-actions';
import {SettingMappingHeaderCsvField} from '../../constants/setting-program.constants';
import {useSettingProgramMapping} from '../../hooks/use-setting-program-mapping';

const {Column} = Table;

interface SettingProgramMappingProps{
    entityName: string;
    url: string;
}
export const SettingProgramMapping: React.FC<SettingProgramMappingProps> = (
    {entityName, url}: SettingProgramMappingProps,
) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const entityUrl = `${url}/${entityName}`;
    const [isMetaInitialized, setIsMetaInitialized] = useState(false);

    const {
        wasFetchedWithContext,
        storedTableState,
        tableDataOptions,
        contextRawData,
        isMonitorParsingCsv,
        metadata,
        tableState,
        loading,
        filter,
        data,
        resetTableMode,
    } = useSettingProgramMapping({entityName});

    const {handleTableChange, rowSelection} = useTableFeatures({
        entityName,
        url: entityUrl,
        metadata,
        data,
    });

    useEffect(() => {
        history.replace(entityUrl);
    }, [entityName]);

    useEffect(() => {
        if (!metadata) {
            dispatch(loadMetadata(entityName, EntityType.TABLE));
            dispatch(initBlankFilter(entityName));
            setIsMetaInitialized(true);
        }
    }, []);

    useEffect(() => {
        const isFilterLoaded = filter && filter.loading === false;
        const shouldLoadData = !data || !data.rows;
        const shouldFilterData = !filter?.data && metadata?.isFilterable;
        const shouldLoadFilteredData = contextRawData && shouldLoadData && !shouldFilterData;

        if (shouldFilterData) dispatch(initBlankFilter(entityName));
        const paramsToBeConverted = {...filter?.data, ...storedTableState?.data};

        if (shouldLoadFilteredData && isFilterLoaded) {
            dispatch(loadTableData(entityName, {...tableDataOptions, paramsToBeConverted}, entityUrl));
        }
    }, [filter, entityName, data, contextRawData, storedTableState]);

    useEffect(() => {
        if (contextRawData && metadata?.useContext && !wasFetchedWithContext) {
            dispatch(loadMetadata(entityName, EntityType.TABLE, true));
        }
    }, [metadata?.useContext, contextRawData, isMetaInitialized]);

    useEffect(() => () => {
        dispatch(resetLoadedData(entityName, EntityType.TABLE_STATE));
        dispatch(resetLoadedData(entityName, EntityType.TABLE));
    }, [entityName]);

    const handleChange = (record: any) => {
        dispatch(openModal({
            modalName: ModalType.settingProgramMappingModal,
            entityName,
            modalParameters: {
                entityName,
                url: entityUrl,
                recordId: record?.id,
            },
        }));
    };

    const fields = useMemo(() => {
        if (isMonitorParsingCsv) {
            return [...metadata?.fields?.slice(0, -1), SettingMappingHeaderCsvField];
        }
        return metadata?.fields;
    }, [isMonitorParsingCsv, metadata?.fields]);

    return (
        <>
            <TableActions
                url={entityUrl}
                entityName={entityName}
                metadata={metadata}
            />
            <Table
                rowKey="id"
                dataSource={(data?.rows as Record<string, Entity[]>[])}
                showSorterTooltip={false}
                onChange={handleTableChange}
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                }}
                loading={loading}
                pagination={{
                    ...(tableState.pagination),
                    position: ['bottomLeft'],
                    locale: {items_per_page: 'на странице', jump_to: ''},
                    showQuickJumper: {
                        goButton: <ArrowRightIcon className="pagination_jumper" />,
                    },
                    showSizeChanger: metadata?.isPageSizeChangerEnabled,
                    onChange: resetTableMode,
                }}
            >
                {fields?.map(field => (
                    <Column
                        key={field.key}
                        title={field.label}
                        dataIndex={field.key}
                        sorter
                        render={(_, record) => (
                            <Link
                                to={entityUrl}
                                onClick={() => handleChange(record)}
                            >
                                {get(record, field.key)}
                            </Link>
                        )}
                    />
                ))}
            </Table>
        </>
    );
};
