import {Pagination} from 'antd';
import {PaginationProps} from 'antd/es/pagination';
import React, {useState} from 'react';
import ReactQuill from 'react-quill';

import {NetDirectoryFileData} from 'store/slices/net-directory-file-slice/net-directory-file-slice-type';

import {QuillToolbar} from '../../rich-text';
import {convertNetDirectoryFileDataToString} from '../net-directory-file-uploader-modal-utils';
import './net-directory-file.less';

export interface NetDirectoryFileProps {
    data: NetDirectoryFileData[];
    placeholder?: string;
    value?: string;
}
export const NetDirectoryFile = ({data, ...props}: NetDirectoryFileProps) => {
    const modules = {
        toolbar: {
            container: '#toolbar',
        },
    };
    const theme = 'snow';
    const [pageConfig, setPageConfig] = useState({
        currentPage: 1,
        itemsPerPage: 5,
    });
    const {currentPage, itemsPerPage} = pageConfig;
    const displayData = data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePageChange: PaginationProps['onChange'] = pageNumber => {
        setPageConfig(prevState => ({
            ...prevState,
            currentPage: pageNumber,
        }));
    };

    const handleShowSizeChange: PaginationProps['onShowSizeChange'] = (current, pageSize) => {
        setPageConfig({
            currentPage: current,
            itemsPerPage: pageSize,
        });
    };

    return (
        <div className="net-directory-file">
            <ReactQuill
                theme={theme}
                readOnly
                value={convertNetDirectoryFileDataToString(displayData)}
                modules={modules}
                {...props}
            />
            <QuillToolbar
                toolbarHtmlId="toolbar"
                disabled
            />
            <Pagination
                showSizeChanger
                defaultCurrent={1}
                onChange={handlePageChange}
                onShowSizeChange={handleShowSizeChange}
                total={data.length}
                pageSize={itemsPerPage}
            />
        </div>
    );
};
