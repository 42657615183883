import {EntityType} from 'shared/constants/entities';
import {performRequest, RequestPromise} from 'shared/utils';
import {entityTypeForRequest} from 'shared/utils/entity-type-for-request';

import {EntityMeta} from './metadata-types';

export const fetchEntityMetadata = <T extends EntityMeta>(
    entityName: string,
    entityType: EntityType,
    taxPeriodId?: number | null,
): RequestPromise<T> => performRequest({
        url: `meta/${entityName}/${entityTypeForRequest(entityType)}/`,
        params: {
            ...(taxPeriodId && entityType === EntityType.TABLE && {taxPeriodId}),
        },
        method: 'GET',
    });

export const getAllMetadata = <T extends EntityMeta[]>(): RequestPromise<T> => performRequest({
    url: 'meta/table',
    method: 'GET',
});

export const fetchAllMetadata = () => performRequest({
    url: 'meta',
    method: 'GET',
});
