import {
    createSelector,
    Selector,
} from '@reduxjs/toolkit';

import {AppState} from 'store/config/types';

import {METADATA_CONSTRUCTOR_MODULE} from './metadata-constructor.constants';
import {IMetadataConstructorState} from './metadata-constructor.types';

const rootSelector: Selector<AppState, IMetadataConstructorState> = (
    state: AppState,
) => state[METADATA_CONSTRUCTOR_MODULE];

export const selectConstructorMetadata = () => createSelector(
    rootSelector,
    (metadataState: IMetadataConstructorState) => metadataState.ALL_META,
);

export const selectConstructorAllEntityName = () => createSelector(
    rootSelector,
    (metadataState: IMetadataConstructorState) => metadataState.ALL_ENTITY_NAME,
);
