import {performRequest} from 'shared/utils';

export const uploadAttachment = (url: string, data: FormData) => performRequest({
    method: 'POST',
    url,
    headers: {'Content-Type': 'multipart/form-data'},
    data,
});

export const deleteAttachment = (url: string) => performRequest({
    method: 'DELETE',
    url,
});
