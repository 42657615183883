import {createAsyncThunk} from '@reduxjs/toolkit';

import {tableReportSliceName} from '../table-report-slice-constants';
import {TableReportDataRequestParams} from '../table-report-slice-types';
import {searchTableReportData} from './table-report-search-api';

export const performGlobalSearch = createAsyncThunk<
    number[], TableReportDataRequestParams
>(
    `${tableReportSliceName}/performGlobalSearch`,
    async (args, {rejectWithValue}) => {
        try {
            const {data: pages} = await searchTableReportData<number[]>(args);
            return pages;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);
