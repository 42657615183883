import {sortBy} from 'lodash';

import {DashboardEntry} from 'modules/data/data-types';

import {useRandomBlueGenerator} from './use-random-blue-generator';

const reducedAvailableBarColors = ['#6BAAFF', '#1E72D7', '#1F487E'];
const availableBarColors = ['#6BAAFF', '#3083FF', '#1E72D7', '#1054B8', '#1F487E'];

interface useStatisticsInitializationArgs {
    entries: DashboardEntry;
    shouldSortByValue?: 'asc' | 'desc';
    slice?: number;
    settings?: {
        lowercaseEntityKeys: boolean;
        useReducedAvailableBarColors?: boolean;
    };
}

export const useStatisticsInitialization = ({
    entries,
    shouldSortByValue,
    slice,
    settings,
}: useStatisticsInitializationArgs) => {
    const {useReducedAvailableBarColors, lowercaseEntityKeys} = settings || {};

    const colorGenerator = useRandomBlueGenerator();
    const resolveColor = (index: number, key: string) => {
        const availableColor = useReducedAvailableBarColors
            ? reducedAvailableBarColors[index] : availableBarColors[index];
        return availableColor || colorGenerator.generate(key);
    };

    const entriesGrouped = entries.dataTable.reduce((acc: Record<string, number>, cur) => {
        acc[cur.key] = (acc[cur.key] ?? 0) + cur.value;
        return acc;
    }, {});

    const entriesTotal = Object.entries(entriesGrouped).reduce((acc, cur) => {
        const [, value] = cur;
        acc += value;
        return acc;
    }, 0);

    const statisticsData = ((() => {
        let data = Object.entries(entriesGrouped).map(([key, value], index) => ({
            key: lowercaseEntityKeys ? key.toLowerCase() : key,
            value,
            label: entries.dataTable[index].label,
            percent: (value / entriesTotal) * 100,
            color: resolveColor(index, key),
        }));
        if (shouldSortByValue === 'asc') data = sortBy(data, 'value');
        else if (shouldSortByValue === 'desc') data = sortBy(sortBy(data, 'value')).reverse();

        if (slice) data = data.slice(0, slice);

        return data;
    })());

    return {
        entriesGrouped,
        entriesTotal,
        statisticsData,
        statisticsLength: Object.keys(statisticsData).length,
    };
};

export type StatisticsDataObject = ReturnType<typeof useStatisticsInitialization>['statisticsData'];
export type StatisticsDataEntryObject = StatisticsDataObject[number];
