import React from 'react';
import {useDispatch} from 'react-redux';

import {performSimpleActionForTable} from 'modules/data/data-actions';
import {RequestType} from 'modules/metadata';

import {ActionButton} from '../action-button';
import {TableActionProps} from '../table-action-types';

export const ButtonActionForTable: React.FunctionComponent<TableActionProps> = ({
    wrapper,
    meta,
    ...props
}: TableActionProps) => {
    const dispatch = useDispatch();
    const entityName = meta?.entityName || '';
    const referenceUrl = meta?.referenceUrl || '';
    const requestType = meta?.requestType || RequestType.DELETE;
    const handleClick = () => {
        dispatch(performSimpleActionForTable(entityName, referenceUrl, requestType));
    };
    const Component = wrapper || ActionButton;

    return (
        <Component
            {...props}
            actionIcon={meta.actionIcon}
            onClick={handleClick}
        >
            {meta.actionTitle}
        </Component>
    );
};
