import {Button, notification} from 'antd';
import cn from 'classnames';
import {isArray} from 'lodash';
import React from 'react';

import {ButtonType} from 'modules/metadata/metadata-types';
import {store} from 'store/config';

import {ReactComponent as Close} from '../../assets/close.svg';
import {ReactComponent as Fail} from '../../assets/fail.svg';
import {ReactComponent as Success} from '../../assets/success.svg';

import './notifications.less';

interface ModalDataNotify {
    text: string;
    onClick: () => void;
}

interface Notify {
    message: string;
    isError?: boolean;
    className?: string;
    description?: string | string[];
    modalData?: ModalDataNotify;
}

export const notify = ({
    message,
    isError,
    className,
    description,
    modalData,
}: Notify) => {
    const duration = store.getState().DATA_MODULE.notificationDuration;
    const resultDescription = isArray(description)
        ? description.map(item => JSON.stringify(item)).join('\n')
        : description;

    const resultMessage = modalData ? (
        <div>
            <span>{message}</span>
            <Button
                className="button-link"
                type={ButtonType.link}
                onClick={modalData?.onClick}
            >
                {modalData.text}
            </Button>
        </div>
    ) : (
        message
    );

    if (!isError) {
        notification.success({
            message: resultMessage,
            className: cn(className, 'app-notification', 'notification-success'),
            description: resultDescription,
            icon: <Success />,
            closeIcon: <Close />,
            duration,
        });
    } else {
        notification.error({
            message: resultMessage,
            className: cn(className, 'app-notification', 'notification-error'),
            description: resultDescription,
            icon: <Fail />,
            closeIcon: <Close />,
            duration,
        });
    }
};
