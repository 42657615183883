import {Button, Modal, Space} from 'antd';
import React from 'react';

import {AlertErrorMessage} from 'components/form/alert-error-message/alert-error-message';
import {Table} from 'components/table/table';
import {TableEntityData} from 'modules/data/data-types';
import {TableEntityMeta} from 'modules/metadata/metadata-types';
import {ModalComponentInterface} from 'store/slices/modals-slice';

interface FormModalProps extends ModalComponentInterface {
    entityName: string;
    title?: string;
    metaData?: TableEntityMeta;
    tableData?: TableEntityData;
    onClear: () => void;
    clearText?: string;
    submitText?: string;
    handleSubmit?: () => void;
    errorMessage?: string;

}

export const DocumentComparisonModal: React.FunctionComponent<FormModalProps> = ({
    onClose,
    entityName,
    metaData,
    tableData,
    title,
    onClear,
    clearText = 'Очистить таблицу',
    submitText = 'Сравнить',
    handleSubmit,
    errorMessage,

}: FormModalProps) => {
    if (metaData && tableData) {
        return (
            <Modal
                className="list-modal"
                footer={[
                    <Space size="small">
                        {
                            onClear && (
                                <Button
                                    key={clearText}
                                    onClick={onClear}
                                >
                                    {clearText}
                                </Button>
                            )
                        }
                        {
                            handleSubmit && (
                                <Button
                                    key={submitText}
                                    onClick={handleSubmit}
                                >
                                    {submitText}
                                </Button>
                            )
                        }
                    </Space>,

                ]}
                visible
                title={title}
                width="50vw"
                onCancel={onClose}
            >
                <AlertErrorMessage
                    message={errorMessage || null}
                />
                <Table
                    entityName={entityName}
                    metadata={metaData}
                    tableData={tableData}
                    url={entityName}
                />
            </Modal>
        );
    }
    return null;
};
