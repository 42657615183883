import React from 'react';

export type State = {
    text: string;
    setEditing?: () => void;
    handleCollapse?: () => void;
    isEditing?: boolean;

}

const InitialState = {
    text: '',
    isEditing: false,
    setEditing: () => {},
    handleContextCollapse: () => {},
};

export const RequestCardContext = React.createContext<State>(InitialState);
