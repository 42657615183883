import {editorAvailableColors} from './constants/toolbar-constants';

export interface FormatData {
    className: string;
    options?: string[];
    placement?: string;
    content?: string;
    value?: string;
}

export const toolbarFormats = [
    [
        {
            className: 'ql-bold',
            placement: 'bottom',
            content: 'Жирный',
        },
        {
            className: 'ql-italic',
            placement: 'bottom',
            content: 'Курсив',
        },
        {
            className: 'ql-underline',
            placement: 'bottom',
            content: 'Подчеркнутый',
        },
    ],
    [
        {
            className: 'ql-color',
            options: editorAvailableColors,
        },
    ],
    [
        {
            className: 'ql-strike',
            placement: 'bottom',
            content: 'Зачеркнутый',
        },
    ],
    [
        {
            className: 'ql-list',
            value: 'bullet',
            content: 'Маркеры',

        },
        {
            className: 'ql-list',
            value: 'ordered',
            content: 'Нумерация',
        },
    ],
];
