import {Empty} from 'antd';
import Form from 'antd/es/form';
import useForm from 'antd/lib/form/hooks/useForm';
import cn from 'classnames';
import React, {
    useContext, useEffect, useState,
} from 'react';

import {setFieldsValueUntouched} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {
    selectDocumentUploadTemplateData, selectSettingTemplateTargetColumns,
} from 'store/slices/document-upload-template/document-upload-template-selectors';
import {
    loadDocumentUploadTemplateData, loadSettingTemplateTargetColumns,
    updateDocumentUploadTemplateConfiguration,
} from 'store/slices/document-upload-template/document-upload-template-slice-thunks';
import {
    DocumentUploadTemplateTypeLookupData,
} from 'store/slices/document-upload-template/document-upload-template-slice-type';

import {WatcherFieldFlag} from '../../../report-configuration/hooks/use-form-fields-manager';
import {useFormListFieldsManager} from '../../../report-configuration/hooks/use-form-list-fields-manager';
import {
    SettingTemplateTargetTableButtons,
} from '../../components/setting-template-target-table-buttons/setting-template-target-table-buttons';
import {DocumentUploadTemplateContext} from '../../document-upload-template-context';
import {
    SettingTemplateTransferTargetFields,
} from './components/setting-template-transfer-target-filds/setting-template-transfer-target-fields';
import {
    SettingTemplateTransferTargetFilter,
} from './components/setting-template-transfer-target-filter/setting-template-transfer-target-filter';
import {
    SETTING_TEMPLATE_TRANSFER_TARGET_FORM_DATA_KEY,
    SettingTemplateTransferTargetFormFields,
} from './constants/setting-template-transfer-target/setting-template-transfer-target';
import {
    convertSettingTemplateTransferTargetFieldsData,
} from './setting-template-transfer-target-utils';

interface SettingTemplateTransferTargetProps{
    entityName: string;
}
export const SettingTemplateTransferTarget: React.FC<SettingTemplateTransferTargetProps> = (
    {entityName} : SettingTemplateTransferTargetProps,
) => {
    const [form] = useForm();
    const [formState, setFormState] = useState(
        {initialValues: {}, isEditingForm: false, isDirty: false},
    );
    const documentUploadTemplate = useAppSelector(selectDocumentUploadTemplateData);
    const lookupData = useAppSelector(selectSettingTemplateTargetColumns) || [];
    const [initColumns, setColumns] = useState<DocumentUploadTemplateTypeLookupData[] | undefined>();
    const dispatch = useAppDispatch();
    const {templateCode} = useContext(DocumentUploadTemplateContext);
    const disabledByEditingModeCondition = {
        condition: () => !formState.isEditingForm,
        flag: WatcherFieldFlag.isDisabled,
    };
    const {
        applyFieldWatchers,
        checkWatcherFlag,
        checkArrayIndependentWatcherFlag,
    } = useFormListFieldsManager({
        formInstance: form,
        watchers: {
            [SettingTemplateTransferTargetFormFields.description]: [
                disabledByEditingModeCondition,
            ],
            [SettingTemplateTransferTargetFormFields.fieldTypeId]: [
                disabledByEditingModeCondition,
            ],
            [SettingTemplateTransferTargetFormFields.sourceField]: [
                {
                    condition: ({formValues}) => {
                        const sql = formValues?.[SettingTemplateTransferTargetFormFields?.sourceFieldDynamicSql];
                        const constant = formValues?.[SettingTemplateTransferTargetFormFields?.sourceFieldConstant];
                        return (!formState.isEditingForm)
                            || (sql !== '' && sql)
                            || (constant !== '' && constant);
                    },
                    flag: WatcherFieldFlag.isDisabled,
                },
            ],
            [SettingTemplateTransferTargetFormFields.sourceFieldDynamicSql]: [
                {
                    condition: ({formValues}) => {
                        const source = formValues?.[SettingTemplateTransferTargetFormFields?.sourceField];
                        const constant = formValues?.[SettingTemplateTransferTargetFormFields?.sourceFieldConstant];
                        return (!formState.isEditingForm)
                            || (source !== '' && source) || (constant !== '' && constant);
                    },
                    flag: WatcherFieldFlag.isDisabled,
                },
            ],
            [SettingTemplateTransferTargetFormFields.sourceFieldConstant]: [
                {
                    condition: ({formValues}) => {
                        const source = formValues?.[SettingTemplateTransferTargetFormFields?.sourceField];
                        const sql = formValues?.[SettingTemplateTransferTargetFormFields?.sourceFieldDynamicSql];
                        return (!formState.isEditingForm)
                            || (source !== '' && source) || (sql !== '' && sql);
                    },
                    flag: WatcherFieldFlag.isDisabled,
                },
            ],
        },
        arrayIndependentWatchers: {
            attribute1: [
                disabledByEditingModeCondition,
            ],
            attribute2: [
                disabledByEditingModeCondition,
            ],
            formatSetting: [
                {
                    condition: () => !formState.isEditingForm
                    || !(documentUploadTemplate?.attribute2 === form.getFieldValue(
                        SettingTemplateTransferTargetFormFields.attribute2,
                    )),
                    flag: WatcherFieldFlag.isDisabled,
                },
            ],
        },
        formArrayKey: SETTING_TEMPLATE_TRANSFER_TARGET_FORM_DATA_KEY,
        triggerDeps: [formState.isEditingForm],
        modifiers: {
            trackFormDirtyState: true,
            isDirty: formState.isDirty,
            setIsDirty: isDirty => setFormState(v => ({...v, isDirty})),
        },
    });

    useEffect(() => {
        if (documentUploadTemplate?.attribute2 && !initColumns) {
            dispatch(loadSettingTemplateTargetColumns({sublistCode: documentUploadTemplate?.attribute2})).then(
                res => setColumns(
                    res?.payload as DocumentUploadTemplateTypeLookupData[] || [],
                ),
            );
        }
    }, [documentUploadTemplate]);

    useEffect(() => {
        if (lookupData.length) {
            if (documentUploadTemplate?.attribute2 === form.getFieldValue(
                SettingTemplateTransferTargetFormFields.attribute2,
            )) {
                form.setFieldsValue({
                    [SETTING_TEMPLATE_TRANSFER_TARGET_FORM_DATA_KEY]: convertSettingTemplateTransferTargetFieldsData(
                        lookupData,
                        documentUploadTemplate?.excelDataMappings,
                    ),
                });
                return;
            }
            form.setFieldsValue({
                [SETTING_TEMPLATE_TRANSFER_TARGET_FORM_DATA_KEY]: convertSettingTemplateTransferTargetFieldsData(
                    lookupData,
                    [],
                ),
            });
        }
        applyFieldWatchers();
    }, [lookupData]);

    useEffect(() => {
        if (initColumns && documentUploadTemplate) {
            const {excelDataMappings} = documentUploadTemplate ?? [];
            const dataToSetToForm = convertSettingTemplateTransferTargetFieldsData(
                initColumns,
                excelDataMappings,
            );
            form.resetFields();
            setFieldsValueUntouched(form, {
                attribute1: documentUploadTemplate?.attribute1,
                attribute2: documentUploadTemplate?.attribute2,
                [SETTING_TEMPLATE_TRANSFER_TARGET_FORM_DATA_KEY]: dataToSetToForm,
            });
            setFormState(
                v => (
                    {
                        ...v,
                        initialValues: {
                            attribute1: documentUploadTemplate?.attribute1,
                            attribute2: documentUploadTemplate?.attribute2,
                            [SETTING_TEMPLATE_TRANSFER_TARGET_FORM_DATA_KEY]: dataToSetToForm,
                        },
                    }
                ),
            );
        }
        applyFieldWatchers();
    }, [documentUploadTemplate, initColumns]);

    const handleFinish = (values: any) => {
        if (templateCode) {
            dispatch(
                updateDocumentUploadTemplateConfiguration({
                    entityName,
                    templateCode,
                    data: values,
                }),
            ).then(() => {
                dispatch(loadDocumentUploadTemplateData({
                    entityName,
                    templateCode,
                }));
            });
        }
    };
    return (
        <div className={cn('setting-template-transfer-target')} >
            <Form
                layout="vertical"
                form={form}
                name={SETTING_TEMPLATE_TRANSFER_TARGET_FORM_DATA_KEY}
                onValuesChange={changedValues => {
                    applyFieldWatchers(changedValues);
                }}
                onFinish={handleFinish}
                className={cn('setting-template-transfer-target__form')}
            >
                <SettingTemplateTargetTableButtons {...{
                    formState,
                    setFormState,
                    form,
                    name: SETTING_TEMPLATE_TRANSFER_TARGET_FORM_DATA_KEY,
                }}
                />
                <SettingTemplateTransferTargetFilter
                    form={form}
                    checkArrayIndependentWatcherFlag={checkArrayIndependentWatcherFlag}
                />
                <div className={cn('setting-template-target__body')}>
                    <Form.List name={SETTING_TEMPLATE_TRANSFER_TARGET_FORM_DATA_KEY}>{fields => (
                        <>
                            {!fields.length && <Empty />}
                            <SettingTemplateTransferTargetFields
                                form={form}
                                fields={fields}
                                checkWatcherFlag={checkWatcherFlag}
                                checkArrayIndependentWatcherFlag={checkArrayIndependentWatcherFlag}
                                documentUploadTemplate={documentUploadTemplate}
                            />
                        </>
                    )}
                    </Form.List>
                </div>
            </Form>
        </div>
    );
};
