import React, {CSSProperties, ReactElement} from 'react';

import {TableHeaderCell, TableHeaderStructure} from 'components/report-configuration/tabs/report-table-settings/modals/excel-header-structure-loader-modal/excel-header-structure-loader-modal.types';
import {PostgresStringType} from 'shared/types/postgres';
import {TABLE_REPORT_URL_FIELD_NAME} from 'store/slices/table-report-slice/table-report-slice-constants';
import {TableReportColumn} from 'store/slices/table-report-slice/table-report-slice-types';

interface NonbreakingSpacesProps {
    children?: string | number;
}

export const NonbreakingSpaces: React.FC<NonbreakingSpacesProps> = ({
    children,
}) => {
    if (!children) return null;
    if (typeof children === 'object') return children;

    const lineWithNonbreakingSpaces = children.toString().split('&nbsp;');
    const lineComponent = (() => {
        const ln: (ReactElement | string)[] = [];
        lineWithNonbreakingSpaces.forEach((l, i) => {
            if (i !== lineWithNonbreakingSpaces.length - 1) {
                ln.push(l);
                ln.push(<>&nbsp;</>);
            } else ln.push(l);
        });
        return <>{ln}</>;
    })();

    return lineComponent;
};

export const isColumnTypeOfString = (
    tableReportAttributes: Record<string, string> | undefined,
    column: TableReportColumn | undefined,
) => {
    if (!column) return false;
    const fieldType = tableReportAttributes?.[column.dataIndex];
    if (!fieldType) return false;
    return Object.values(PostgresStringType).includes(fieldType as PostgresStringType);
};

export const getColumnHeaderWidthStyles = (
    column: TableReportColumn | undefined,
) => {
    const {width = '30vw'} = column ?? {};

    return {
        minWidth: 'min-content',
        maxWidth: width,
        width: column?.width ? width : undefined,
    } as CSSProperties;
};

export const getColumnWidthStyles = (
    tableReportAttributes: Record<string, string> | undefined,
    column: TableReportColumn | undefined,
) => {
    const styles: CSSProperties = {};
    const {width, enabledTextWrapping} = column ?? {};

    if (width) {
        styles.width = width;
        styles.minWidth = width;
        styles.maxWidth = width;
    }
    if (!enabledTextWrapping) {
        styles.wordBreak = 'unset';
        styles.maxWidth = 'unset';
        styles.whiteSpace = 'nowrap';
    } else {
        styles.wordBreak = 'break-word';
    }

    if (isColumnTypeOfString(tableReportAttributes, column)) {
        styles.maxWidth = 'max(350px, calc(30vw))';
        if (width) {
            styles.maxWidth = `min(${width}, calc(30vw))`;
        }
    }
    return styles;
};

export const isCustomColumn = (dataIndex: string) => dataIndex.startsWith('custom_attribute');

export const extractIndexesFromKey = (key: string) => {
    const indexesRegexp = /^(\d*)-(\d*)-(.*)$/;
    const execResult = indexesRegexp.exec(key.replace(/[\r\n]/gm, ''));
    if (!execResult) return undefined;
    if (execResult.length === 4) {
        const l = execResult.length;
        return {
            row: Number(execResult[l - 3]),
            column: Number(execResult[l - 2]),
            title: execResult[l - 1],
        };
    }
    return undefined;
};

export const getTreeMaxColumnIndex = (tree: TableHeaderStructure): number | undefined => {
    let maxColIndex = 0;

    JSON.stringify(tree, (_, nestedValue) => {
        if (nestedValue?.key) {
            const {column: colIndex} = extractIndexesFromKey(nestedValue?.key) ?? {};
            if (colIndex && colIndex > maxColIndex) {
                maxColIndex = colIndex;
            }
        }
        return nestedValue;
    });

    return maxColIndex > 0 ? maxColIndex : undefined;
};

interface MultiRowHeaderCellStyleEntity {
    borderClassname: string;
    style?: CSSProperties;
}

interface GetMultiRowHeaderCellStyleArgs {
    cellRowIndex: number | undefined;
    cellColumnIndex: number | undefined;
    firstRowLastColumnIndex: number | undefined;
    maxColumnIndex: number | undefined;
    borderColor?: string;
}

export const getMultiRowHeaderCellStyle = ({
    cellRowIndex,
    cellColumnIndex,
    firstRowLastColumnIndex,
    maxColumnIndex,
    borderColor,
}: GetMultiRowHeaderCellStyleArgs): MultiRowHeaderCellStyleEntity => {
    const borderClassname = cellColumnIndex !== undefined && firstRowLastColumnIndex !== undefined
        && (cellColumnIndex === firstRowLastColumnIndex || cellColumnIndex === maxColumnIndex)
        ? 'own-borders' : 'no-radius';

    const borderStyle = `1px solid ${borderColor ?? '#E0E4F0'}`;
    const isTopRightCell = cellRowIndex === 0 && cellColumnIndex === firstRowLastColumnIndex;

    return {
        borderClassname,
        style: {
            borderRight: borderStyle,
            borderBottom: borderStyle,
            borderTopRightRadius: isTopRightCell ? '5px' : '0',
        },
    };
};

interface ColumnToPutInHeaderRule {
    isLast?: boolean;
    isFirst?: boolean;
    putAfterAttribute?: string;
    dataIndex?: string;
}

export const addLinkColumnsToHeaderStructure = (
    headerStructure: TableHeaderStructure | undefined,
    columns: TableReportColumn[] | undefined,
) => {
    const CELL_HEIGHT = 2; // todo: надо определять динамически в зависимости от самой глубокой вложенности

    const columnsToPutInHeaderRules: ColumnToPutInHeaderRule[] = [];
    columns?.forEach((col, index) => {
        if (col.dataIndex.startsWith(TABLE_REPORT_URL_FIELD_NAME)) {
            const previousColumn = columns[index - 1];
            columnsToPutInHeaderRules.push({
                dataIndex: col.dataIndex,
                isFirst: !index,
                isLast: index === columns.length - 1,
                putAfterAttribute: !previousColumn ? undefined : previousColumn.dataIndex,
            });
        }
    });

    const headerStructureWithLinks = [
        ...columnsToPutInHeaderRules.filter(rule => rule.isFirst).map(rule => ({
            key: `0-0-${rule.dataIndex}`,
            title: rule.dataIndex,
            attribute: rule.dataIndex,
            cellHeight: CELL_HEIGHT,
        } as TableHeaderCell)),
        ...headerStructure ?? [],
        ...columnsToPutInHeaderRules.filter(rule => rule.isLast).map(rule => ({
            key: `0-0-${rule.dataIndex}`,
            title: rule.dataIndex,
            attribute: rule.dataIndex,
            cellHeight: CELL_HEIGHT,
        } as TableHeaderCell)),
    ];

    const rulesToPut = columnsToPutInHeaderRules.filter( // Справа от заданного столбца
        rule => !!rule.putAfterAttribute,
    );

    rulesToPut.forEach(rule => {
        const columnIndex = headerStructureWithLinks.findIndex(item => item.attribute === rule.putAfterAttribute);
        if (columnIndex !== -1) {
            headerStructureWithLinks.splice(columnIndex + 1, 0, {
                key: `0-0-${rule.dataIndex}`,
                title: rule.dataIndex,
                attribute: rule.dataIndex,
                cellHeight: CELL_HEIGHT,
            } as TableHeaderCell);
        }
    });
    return headerStructureWithLinks;
};
