import React from 'react';
import {useSelector} from 'react-redux';

import {selectEntityData} from 'modules/data';
import {selectTableEntityData} from 'modules/data/data-selectors';
import {TableEntityData} from 'modules/data/data-types';
import {RequestType} from 'modules/metadata';
import {EntityType} from 'shared/constants/entities';
import {CommonMessages} from 'shared/constants/messages';
import {performRequest, showMessage} from 'shared/utils';
import {showMessageFromResponseList} from 'shared/utils/show-message-from-response';
import {useAppSelector} from 'store/config/hooks';
import {AppState} from 'store/config/types';

import {ActionButton} from '../action-button';
import {TableActionProps} from '../table-action-types';

const ButtonActionControlRatioComponent: React.FunctionComponent<TableActionProps> = ({
    wrapper,
    meta,
    entityName,
    ...props
}: TableActionProps) => {
    const rows = useAppSelector(selectTableEntityData(entityName || ''))?.rows;
    const sectionCodeContext: string = String(rows?.slice(0, 1).shift()?.sectionCode);

    const {selectedRowKeys = []} = useSelector(
        (state: AppState) => (
            selectEntityData(entityName, EntityType.TABLE)(state) || {}
        ) as TableEntityData,
    );
    const handleClick = () => {
        if (selectedRowKeys?.length === 0) {
            showMessage({message: CommonMessages.SELECT_DOCUMENT, isError: true});
        } else if (meta?.referenceUrl) {
            (async () => {
                try {
                    const response = await performRequest({
                        url: meta?.referenceUrl,
                        method: meta?.requestType || RequestType.POST,
                        params: {sectionCodeContext},
                        data: [...selectedRowKeys],
                    });
                    showMessageFromResponseList(response);
                } catch (e) {
                    console.error(e);
                    showMessageFromResponseList(e.response, true);
                }
            })();
        }
    };

    const Component = wrapper || ActionButton;
    return (
        <Component
            {...props}
            onClick={handleClick}
            actionIcon={meta.actionIcon}
            disabled={!selectedRowKeys.length}
        >
            {meta.actionTitle}
        </Component>
    );
};

export const ButtonActionControlRatio = ButtonActionControlRatioComponent;
