import {Button, Modal} from 'antd';
import cn from 'classnames';
import React from 'react';

import './modal-close-confirmation.less';

interface ModalCloseConfirmationProps {
    isOpen?: boolean;
    onClose: () => void;
    onBack: () => void;
    message?: string;
    closeTitle?: string;
    backTitle?: string;
}

export const ModalCloseConfirmation: React.FC<ModalCloseConfirmationProps> = (
    {
        isOpen,
        onBack,
        onClose,
        message,
        closeTitle,
        backTitle,
    }: ModalCloseConfirmationProps,
) => (
    <Modal
        className={cn('modal-close-confirmation')}
        destroyOnClose
        title="Предупреждение"
        centered
        visible={isOpen}
        footer={null}
        width={750}
        closeIcon={<div />}
    >
        {message || 'У вас есть несохраненные изменения, при закрытии'
            + ' формы изменения не будут сохранены.\nЗакрыть форму?'}

        <div className={cn('modal-opener-component__controls')}>
            <Button
                onClick={() => {
                    onBack();
                }}
                type="primary"
            >{backTitle || 'Вернуться к редактированию'}
            </Button>
            <Button
                onClick={() => {
                    onClose();
                }}
                type="default"
            >{closeTitle || 'Закрыть форму'}
            </Button>
        </div>
    </Modal>
);
