import {ActionReducerMapBuilder, PayloadAction} from '@reduxjs/toolkit';

import {reportConfigurationSliceName} from '../report-configuration-constants';
import {
    ddReferenceRulesAdapter,
    selectDdReferenceRulesState,
} from '../report-configuration-slice';
import {ReportConfigurationSliceState} from '../report-configuration-types';
import {
    createDrilldownReferenceRule,
    editDrilldownReferenceRule,
    loadDrilldownReferenceRules,
} from './dd-references-configuration-thunks';
import {ReportDdReferenceRule, ReportDdReferenceRulesFilter} from './dd-references-configuration-types';

export const createDdReferenceRulesExtraReducer = (
    builder: ActionReducerMapBuilder<ReportConfigurationSliceState>,
) => {
    builder.addCase(createDrilldownReferenceRule.fulfilled, (state, {payload: newDdReferenceRule}) => {
        ddReferenceRulesAdapter.addOne(selectDdReferenceRulesState(state), newDdReferenceRule);
        if (state.drilldownSettings.ddReferenceRulesTotalCount) {
            state.drilldownSettings.ddReferenceRulesTotalCount += 1;
        }
    });
    builder.addCase(editDrilldownReferenceRule.fulfilled, (state, {payload: newDdReferenceRule}) => {
        ddReferenceRulesAdapter.setOne(selectDdReferenceRulesState(state), newDdReferenceRule);
    });
    builder.addCase(loadDrilldownReferenceRules.fulfilled, (state, {payload}) => {
        ddReferenceRulesAdapter.setAll(selectDdReferenceRulesState(state), payload.ddReferenceRules);
        state.drilldownSettings.ddReferenceRulesTotalCount = payload.recordsTotal;
    });
    builder.addCase(`${reportConfigurationSliceName}/addDdReferenceRule`, state => {
        if (state.drilldownSettings.ddReferenceRulesTotalCount) {
            state.drilldownSettings.ddReferenceRulesTotalCount += 1;
        }
    });
};

export const createDdReferenceRulesReducer = <S extends ReportConfigurationSliceState>() => ({
    setDdReferenceRule(
        state: S, {payload: ddReferenceRule}: PayloadAction<ReportDdReferenceRule>,
    ) {
        ddReferenceRulesAdapter.setOne(selectDdReferenceRulesState(state), ddReferenceRule);
    },
    addDdReferenceRule(
        state: S, {payload: newDdReferenceRule}: PayloadAction<ReportDdReferenceRule>,
    ) {
        ddReferenceRulesAdapter.addOne(selectDdReferenceRulesState(state), newDdReferenceRule);
    },
    setDdReferenceRules(state: S, {payload: newDdReferenceRules}: PayloadAction<ReportDdReferenceRule[]>) {
        ddReferenceRulesAdapter.setAll(selectDdReferenceRulesState(state), newDdReferenceRules);
    },
    setDdReferenceRulesFilter(state: S, {
        payload: ddReferenceRulesFilter,
    }: PayloadAction<ReportDdReferenceRulesFilter>) {
        state.drilldownSettings.ddReferenceRulesFilter = ddReferenceRulesFilter;
    },
    updateDdReferenceRulesFilter(state: S, {
        payload: newDdReferenceRulesFilter,
    }: PayloadAction<ReportDdReferenceRulesFilter>) {
        const currentDdReferenceRulesFilter = state.drilldownSettings.ddReferenceRulesFilter;
        state.drilldownSettings.ddReferenceRulesFilter = {
            ...currentDdReferenceRulesFilter,
            ...newDdReferenceRulesFilter,
        };
    },
    setDdReferenceRulesTotalCount(state: S, {
        payload: newTotalCount,
    }: PayloadAction<number>) {
        state.drilldownSettings.ddReferenceRulesTotalCount = newTotalCount;
    },
});
