import {FormInstance} from 'antd/es/form';
import React, {Dispatch, SetStateAction} from 'react';

export type TFormsFileModal = {
    forms: FormInstance<any>[]; setForms?: Dispatch<SetStateAction<FormInstance<any>[]>>;
}

const FormsFileModalInitState = {
    forms: [],
    setForms: undefined,
};

export const FormsFileModalContext = React.createContext<TFormsFileModal>(FormsFileModalInitState);
