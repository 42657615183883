import {Table} from 'antd';
import {TableProps} from 'antd/es/table';
import cn from 'classnames';
import React from 'react';

import {Entity} from 'modules/data';
import {DefaultTableStateFlat} from 'modules/data/data-types';
import {setDocumentsPagination} from 'modules/documents/documents-actions';
import {selectDocumentsPagination} from 'modules/documents/documents-selectors';
import {ReactComponent as ArrowRightIcon} from 'shared/assets/arrow-right.svg';
import {usePagination} from 'shared/hooks/use-pagination';
import {convertWidthData} from 'shared/utils/convert-width-data';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';

import {EditableCell, EditableCellProps} from './components/editable-cell';
import {EditableRow} from './components/editable-row';
import {EditableColumn} from './editable-table-types';

interface EditableTableProps extends Exclude<TableProps<any>, ['columns']> {
    columns: EditableColumn[];
    handleSave: (row: Entity) => void;
    entityName?: string;
    params?: DefaultTableStateFlat;
    isPageJumperEnabled?: boolean;
    isPageSizeChangerEnabled?: boolean;
    disabledPagination?: boolean;
}

export const EditableTable: React.FunctionComponent<EditableTableProps> = ({
    columns,
    dataSource,
    handleSave,
    entityName,
    disabledPagination,
    isPageJumperEnabled,
    isPageSizeChangerEnabled,
    ...props
}: EditableTableProps) => {
    const dispatch = useAppDispatch();
    const pagination = useAppSelector(selectDocumentsPagination);
    const totalElements = dataSource?.length;
    const entityClassName = entityName?.split('.').join('-');
    const editableClassNames = cn(
        {
            [String(entityClassName)]: entityName,
        },
    );

    const {current, pageSize, handleChange} = usePagination({
        pageSizeDefault: pagination?.paginationPageSize,
        pageDefault: pagination?.paginationCurrent,
        onChange: (paginationCurrent, paginationPageSize) => {
            dispatch(setDocumentsPagination({
                paginationCurrent,
                paginationPageSize,
            }));
        },
    });

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const resultColumns = columns?.map(column => ({
        ...column,
        onCell: (record: Entity) => ({
            record,
            editable: column.editable,
            alwaysEditable: column.alwaysEditable,
            required: column.isRequired,
            defaultValue: column.defaultValue,
            dataIndex: column.dataIndex,
            title: column.title,
            inputType: column.inputType,
            inputProps: column.inputProps,
            getFieldState: column.getFieldState,
            disabled: column.isDisabled,
            placeholder: column.placeholder,
            validationRules: column?.validationRules,
            handleSave,
            generateInputProps: column.generateInputProps,
            entityName,
            width: convertWidthData(column.width),
            format: column?.dateFormat,
        } as EditableCellProps),
    }));

    return (
        <Table
            className={editableClassNames}
            components={components}
            columns={resultColumns as EditableColumn[]}
            dataSource={dataSource}
            pagination={!disabledPagination && {
                position: ['bottomLeft'],
                total: totalElements,
                current,
                pageSize,
                onChange: handleChange,
                locale: {items_per_page: 'на странице', jump_to: ''},
                showQuickJumper: isPageJumperEnabled
                    && {goButton: <ArrowRightIcon className="pagination_jumper" />},
                showSizeChanger: isPageSizeChangerEnabled,
            }}
            {...props}
        />
    );
};
