import {
    Modal, Form as AntForm, Button, Space, Form,
} from 'antd';
import {useForm} from 'antd/es/form/Form';
import cn from 'classnames';
import {isArray} from 'lodash';
import isString from 'lodash/isString';
import React, {useMemo} from 'react';

import {UploaderList} from 'components/form/inputs/uploader-list';
import {actionRequest} from 'modules/data/data-api';
import {RequestType} from 'modules/metadata';
import {ReactComponent as CloseIcon} from 'shared/assets/close.svg';
import {FileDto} from 'shared/types/files';
import {showMessageFromResponse} from 'shared/utils';
import {useAppSelector} from 'store/config/hooks';
import {selectUserId} from 'store/slices/auth-slice';

import {convertDataFiles} from './modal.utils';
import '../files-modal.less';

export interface SetInputValueModalProps {
    name: string;
    value?: any;
    visible: boolean;
    closeModal: () => void;
    onChange?: (value: any) => void;
    label?: string;
    entityName?: string;
    id?: string;
}

export const ModalFilesValue = ({
    name, visible, closeModal, onChange, value, label, entityName, id,
}: SetInputValueModalProps) => {
    const [form] = useForm();
    const userId = useAppSelector(selectUserId);

    const files = useMemo(() => (
        isArray(value) ? value?.map(item => (isString(item) ? {name: item} : item)) : value), [value]);

    const handleSubmit = (values: any) => {
        (async () => {
            try {
                if (entityName && id && values) {
                    const data = convertDataFiles(values[name]);
                    await actionRequest(
                        `${entityName}/rpc/upload-files/${id}`,
                        RequestType.POST,
                        data,
                        {
                            isJsonRequest: false,
                        },
                        userId,
                    );
                }
                onChange?.(values[name]);
            } catch (e) {
                showMessageFromResponse({response: e.response});
            }
            closeModal();
        })();
    };

    return (
        <Modal
            centered
            width={980}
            footer={null}
            title={label}
            destroyOnClose
            visible={visible}
            maskClosable={false}
            onCancel={() => {
                closeModal();
            }}
            className="set-input-value-modal modal-header files-cell"
            closeIcon={<CloseIcon className={cn('modal-close-icon', 'with-title')} />}
        >
            <AntForm
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
                initialValues={{
                    files: files as FileDto[],
                }}
            >
                <Form.Item
                    name={name}
                    valuePropName="fileList"
                >
                    <UploaderList
                        buttonText="Выбрать файлы"
                    />
                </Form.Item>
                <Space className="buttons">
                    <Button
                        htmlType="submit"
                        type="primary"
                    >Сохранить
                    </Button>
                </Space>
            </AntForm>
        </Modal>
    );
};
