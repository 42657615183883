import {useUserRoleFunctions} from 'shared/hooks/use-user-role-functions';

import {RoleModelSections} from '../form-constants';

export const useDisableFormFields = (
    entityName?: string,
) => {
    const itemKey = `app.${entityName}.title`;
    const {hasUserRoleFunction, isLoadingUserFunctions} = useUserRoleFunctions({
        sections: [itemKey],
    });
    const getDisableFormFields = () => {
        const sectionUrlData = RoleModelSections.find(item => item.section === itemKey);
        if (sectionUrlData && !isLoadingUserFunctions) {
            const {functionName} = sectionUrlData || {};
            return !hasUserRoleFunction(functionName);
        }
        return false;
    };

    return {
        getDisableFormFields,
    };
};
