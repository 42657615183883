import {Form as AntForm, Space} from 'antd';
import {FormInstance} from 'antd/es/form';
import {ColProps} from 'antd/lib/grid/col';
import React, {Dispatch, SetStateAction} from 'react';

import {MetaActionInfo} from 'modules/metadata';
import {TVisibleMetaActionProp} from 'modules/metadata/metadata-types';
import {isVisibleAction} from 'modules/metadata/metadata-utils';

import {FormMode} from '../form-types';
import {ActionsItem} from './actions-item';

interface ButtonsProps {
    form?: FormInstance;
    actions?: MetaActionInfo[];
    onClose?: () => void;
    onBack?: () => void;
    onClearForm?: () => void;
    wrapperCol?: ColProps;
    currentVisibleState?: TVisibleMetaActionProp;
    setFormErrorMessage?: Dispatch<SetStateAction<string | null>>;
    formMode?: FormMode;
    handleOpenConfirmModal?: () => void;
}

export const Buttons: React.FunctionComponent<ButtonsProps> = (
    {
        form,
        actions,
        onClose,
        onBack,
        onClearForm,
        wrapperCol,
        setFormErrorMessage,
        currentVisibleState = 'default',
        formMode,
        handleOpenConfirmModal,
    }: ButtonsProps,
) => {
    const showButtons = onClose || onBack || actions?.length;
    if (!showButtons) return null;

    const actionsList = actions?.map(
        action => (
            isVisibleAction(currentVisibleState, action.visible || 'default', formMode) && (
                <ActionsItem
                    key={action.actionTitle}
                    action={action}
                    form={form}
                    onClose={onClose}
                    onBack={onBack}
                    onClearForm={onClearForm}
                    setFormErrorMessage={setFormErrorMessage}
                    formMode={formMode}
                    handleOpenConfirmModal={handleOpenConfirmModal}
                />
            )),
    );

    return actionsList?.length ? (
        <AntForm.Item
            className="form-actions buttons"
            wrapperCol={wrapperCol}
        >
            <Space>
                {actionsList}
            </Space>
        </AntForm.Item>
    ) : null;
};
