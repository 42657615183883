import React from 'react';
import {useDispatch} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';

import {
    loadTableData,
    loadTableData as loadTableDataAction,
} from 'modules/data';
import {resetData, setData} from 'modules/data/data-actions';
import {selectTableEntityData} from 'modules/data/data-selectors';
import {
    loadMetadata as loadMetadataAction, MetaActionType,
} from 'modules/metadata';
import {selectTableEntityMetadata} from 'modules/metadata/metadata-selectors';
import {getActionByType} from 'modules/metadata/metadata-utils';
import {EntityType} from 'shared/constants/entities';
import {performRequest} from 'shared/utils';
import {useAppSelector} from 'store/config/hooks';
import {selectModalData, ModalComponentInterface} from 'store/slices/modals-slice';

import {DocumentComparisonModal} from './document-comparison-modal';

interface IErrorMessage {
    status: boolean;
    message?: string;
}

export const DocumentComparisonModalContainer: React.FunctionComponent<ModalComponentInterface> = ({
    onClose,
}: ModalComponentInterface) => {
    const history = useHistory();
    const location = useLocation();
    const [errorMessage, setErrorMessage] = React.useState<IErrorMessage>({status: false});
    const dispatch = useDispatch();
    const entityName = useAppSelector(state => selectModalData(state)?.entityName) || '';
    const metaData = useAppSelector(selectTableEntityMetadata(entityName));
    const tableData = useAppSelector(selectTableEntityData(entityName));

    const handleClear = () => {
        dispatch(setData({
            entityName,
            entityType: EntityType.TABLE,
            data: {
                rows: [],
            },
        }));
    };
    const handleSubmit = async () => {
        const ids: number[] = [];
        const saveAction = getActionByType(MetaActionType.BUTTON_SAVE_FORM)(metaData.actions);
        tableData.rows?.map((row: any) => ids.push(row.id));
        if (ids.length < 2) {
            setErrorMessage({
                status: true,
                message: 'Выбрано недостаточно документов для сравнения',
            });
            return;
        }
        try {
            const url = saveAction?.referenceUrl;
            const response = await performRequest({
                url,
                method: 'POST',
                data: ids,
            });
            if (response.status === 200 && metaData?.parentEntityName) {
                dispatch(resetData({
                    entityName: metaData.parentEntityName,
                    entityType: EntityType.TABLE,
                }));
                dispatch(
                    setData({
                        entityName: metaData.parentEntityName,
                        entityType: EntityType.TABLE,
                        loading: false,
                        data: {
                            ids,
                        },
                    }),
                );
                history.push(`${location.pathname}/${metaData.parentEntityName}`);
                onClose();
            }
        } catch (e) {
            console.error(e);
            setErrorMessage({
                status: true,
                message: e.response.data,
            });
        }
    };

    React.useEffect(() => {
        if (entityName && !metaData) {
            dispatch(loadMetadataAction(entityName, EntityType.TABLE));
        }
        if (metaData && (!tableData || !tableData?.rows)) {
            const tableDataOptions = {
                useContext: !!metaData?.useContext,
                useSearch: !!metaData?.isSearchable,
                disabledPagination: metaData?.disabledPagination,
            };
            dispatch(loadTableDataAction(entityName, {...tableDataOptions}, entityName, metaData?.prefix));
        }
    }, [tableData, entityName, loadTableData, metaData]);
    React.useEffect(() => {
        if (tableData?.rows && tableData?.rows.length > 2) {
            const newData = tableData?.rows.slice(0, 2);
            dispatch(setData({
                entityName,
                entityType: EntityType.TABLE,
                loading: false,
                data: {
                    rows: newData,
                },
            }));
            setErrorMessage({
                status: true,
                message: 'Нельзя сравнить больше двух документов',
            });
        }
    }, [tableData, errorMessage]);

    return (
        <DocumentComparisonModal
            errorMessage={errorMessage?.message}
            entityName={entityName}
            onClose={onClose}
            metaData={metaData}
            tableData={tableData}
            title={metaData?.title}
            onClear={handleClear}
            handleSubmit={handleSubmit}
        />
    );
};
