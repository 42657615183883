import React from 'react';

import {AisPageResolver, isAisPage} from 'components/ais/ais.utils';
import {
    CONTROL_RATIO_RULES_ENTITY_NAME,
    ControlRatioSettingsRules,
    DOCUMENT_UPLOAD_TEMPLATES_NAME,
} from 'components/control-ratio-settings';
import {DirectoriesCompanyForm, DIRECTORIES_COMPANY_NAME} from 'components/directories-company-form';
import {DocumentUploadTemplate} from 'components/document-upload-template/document-upload-template';
import {FormMode} from 'components/form';
import {FormEditModal} from 'components/form-edit-modal/form-edit-modal';
import {ReportConfiguration} from 'components/report-configuration';
import {REPORT_CONFIGURATION_NAME} from 'components/report-configuration/report-configuration.constants';
import {Table} from 'components/table';
import {TableScroll} from 'components/table/table';
import {PageTemplateProps} from 'pages/pages-types';
import {EntityType} from 'shared/constants/entities';
import {useQueryParams} from 'shared/utils/query-params/use-query-params.hook';

import {FORM_QUERY_PARAMS} from './constants/table-query-params';
import {CustomFormModal, customFormsMapping, CustomFormEntityName} from './custom-form-modal';

export interface FormAwareTableProps extends PageTemplateProps {
    hideTitle?: boolean;
    referenceUrl: string;
    scroll?: TableScroll;
}

export const QueryParamsAwareTable = ({
    url, hideTitle, entityType, entityName, referenceUrl, scroll,
}: FormAwareTableProps) => {
    const {removeQueryParams, getQueryParams} = useQueryParams();
    const {
        entity,
        entityType: queryEntityType,
    } = getQueryParams(Object.values(FORM_QUERY_PARAMS));

    const referenceUrlWithoutSlash = referenceUrl
        .replace(/(?:\/+(\?))/, '$1')
        .replace(/\/+$/, '');

    const handleFormClose = () => {
        removeQueryParams(Object.values(FORM_QUERY_PARAMS));
    };

    // ais pages
    if (isAisPage({entityName})) {
        return (
            <AisPageResolver
                entity={entity}
                entityName={entityName}
                entityType={entityType}
                url={url}
                scroll={scroll}
                hideTitle={hideTitle}
                handleFormClose={handleFormClose}
            />
        );
    }

    if (entity && entityName === REPORT_CONFIGURATION_NAME) {
        return (
            <ReportConfiguration
                entityName={entityName}
                templateCode={entity}
            />
        );
    }

    if (entity && entityName === DIRECTORIES_COMPANY_NAME) {
        return (
            <DirectoriesCompanyForm
                entityName={entityName}
                entity={entity}
            />
        );
    }

    if (entityName === CONTROL_RATIO_RULES_ENTITY_NAME) {
        return (
            <ControlRatioSettingsRules
                entity={entity}
                handleFormClose={handleFormClose}
                referenceUrlWithoutSlash={referenceUrl}
                entityName={entityName}
            />
        );
    }
    if (entity && entityName === DOCUMENT_UPLOAD_TEMPLATES_NAME) {
        return (
            <DocumentUploadTemplate
                entityName={entityName}
                templateCode={entity}
            />
        );
    }

    return (
        <>
            <Table
                hideTitle={hideTitle}
                entityName={entityName}
                url={url}
                entityType={entityType}
                scroll={scroll}
            />
            {(() => {
                if (!(entity && !Number.isNaN(parseInt(entity, 10)))) return null;
                if (customFormsMapping[entityName as CustomFormEntityName]) {
                    return (
                        <CustomFormModal
                            mode={FormMode.EDIT}
                            entityId={entity}
                            entityName={entityName as CustomFormEntityName}
                        />
                    );
                }
                return (
                    <FormEditModal
                        entityName={entityName}
                        entityType={queryEntityType as EntityType || EntityType.FORM}
                        onClose={handleFormClose}
                        referenceUrl={`${referenceUrlWithoutSlash}/${entity}`}
                    />
                );
            })()}
        </>
    );
};
