import {FormEntityData} from '../../../modules/data/data-types';
import {RequestData} from '../../../store/slices/request-slice/request-slice-type';
import {RequestTypeCode} from '../request-form/request-form.constants';

export const convertRequestFormData = (
    requestData?: RequestData,
    requestCommentId?: string,
    prolongationDate?: Date,
    isRequestAdditionalPlacementType?: boolean,
    numberRequest?:string,
): FormEntityData => ({
    data: {
        topicRequestType: requestData?.requests?.topicRequestType,
        requestId: requestData?.requests?.id,
        commentType: requestData?.requests?.topicRequestType?.typeCode || requestData?.requests?.requestType,
        requests: requestData?.requests,
        commentId: requestCommentId,
        topicStatus: requestData?.requests?.topicStatus,
        prolongationDate: prolongationDate || requestData?.prolongationRequest?.prolongationDate,
        numberRequest: isRequestAdditionalPlacementType ? requestData?.requests?.numberRequest : numberRequest,
        requestAddressesType: RequestTypeCode.REQUEST,
        responseAddressesType: RequestTypeCode.RESPONSE,
        commentAddressesType: RequestTypeCode.COMMENT_REQUEST,
    } || {},
    id: requestData?.requests?.id || '-1',
}) as unknown as FormEntityData;

export const convertRequestData = (
    requestData?: RequestData,
    data?: any,
    requestCommentId?: string,
    requestType?: string,
): FormEntityData => ({
    data: {
        ...data,
    } || {},
    id: data?.id || '-1',
    commentId: requestCommentId,
    topicRequestType: requestData?.requests?.topicRequestType,
    requestAddressesType: requestType !== RequestTypeCode.MESSAGE
        ? RequestTypeCode.REQUEST
        : RequestTypeCode?.COMMENT_REQUEST,
    responseAddressesType: RequestTypeCode.RESPONSE,
    commentAddressesType: RequestTypeCode.COMMENT_REQUEST,
}) as unknown as FormEntityData;
