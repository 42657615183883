import {useMemo} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import {extractQueryParamJSON, setQueryParamAsJSON} from './query-params';
import {
    QueryOptions, QueryParam, QueryParams,
} from './query-params-types';

export const useQueryParams = () => {
    const history = useHistory();
    const {search, pathname} = useLocation();

    const query = useMemo(() => new URLSearchParams(search), [search]);

    const setPath = (newQuery: URLSearchParams, options: QueryOptions = {}) => {
        const {action = 'push'} = options;

        if (action === 'push') {
            history.push({location: pathname, search: newQuery.toString()});
        } else history.replace({location: pathname, search: newQuery.toString()});
    };

    const updateQueryParams = (params: QueryParam[], options?: QueryOptions) => {
        params.forEach(({name, value}) => {
            query.set(name, value);
        });
        setPath(query, options);
    };

    const setQueryParam = (param: QueryParam, options?: QueryOptions) => {
        const {name, value} = param;
        query.set(name, value);
        setPath(query, options);
    };

    const removeQueryParams = (params: string[], options?: QueryOptions) => {
        params.forEach(param => query.delete(param));
        setPath(query, options);
    };

    const removeQueryParam = (param: string, options?: QueryOptions) => {
        query.delete(param);
        setPath(query, options);
    };

    const getQueryParams = (
        params: string[],
    ): QueryParams => params.reduce((acc, param) => {
        acc[param] = query.get(param);
        return acc;
    }, {} as QueryParams);

    const getQueryParam = (name: string) => {
        const param = query.get(name);
        return param;
    };

    const extractQueryParamAsJSON = (name: string) => extractQueryParamJSON(name, query);
    const setParamAsJSON = (name: string, value: object, options?: QueryOptions) => setQueryParamAsJSON({
        history,
        name,
        value,
        query,
        options,
    });

    return {
        query,
        updateQueryParams,
        removeQueryParams,
        removeQueryParam,
        getQueryParams,
        getQueryParam,
        setQueryParam,
        setQueryParamAsJSON: setParamAsJSON,
        extractQueryParamAsJSON,
    };
};
