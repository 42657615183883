import {Card} from 'antd';
import {FormInstance} from 'antd/lib/form';
import React from 'react';

import {FormEntityData} from 'modules/data/data-types';
import {FieldMeta} from 'modules/metadata';

import {Field} from '../../field/field';

import './field-block.less';

export interface FieldBlockProps {
    label?: string;
    fields?: FieldMeta[];
    formData?: FormEntityData;
    form?: FormInstance;
    disabled?: boolean;
}

export const FieldBlock = ({
    label, fields, form, formData, disabled,
}: FieldBlockProps) => (
    <Card
        className="field-block"
        title={label}
    >
        {
            fields?.map(fieldMeta => (
                <Field
                    formData={formData}
                    key={fieldMeta.key}
                    fieldMeta={{...fieldMeta, isDisabled: disabled || fieldMeta.isDisabled}}
                    form={form}
                />
            ))
        }
    </Card>
);
