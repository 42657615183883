import {EditOutlined} from '@ant-design/icons';
import {Input} from 'antd';
import cn from 'classnames';
import get from 'lodash/get';
import React, {useMemo, ChangeEvent, FocusEvent} from 'react';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';

import {editNameDocument} from 'modules/data/data-actions';
import {FieldMeta, RequestType} from 'modules/metadata';
import {JASPER_REPORT_ENTITIES, PAGE_TEMPLATE_TYPE} from 'pages/pages-constants';
import {EntityType} from 'shared/constants/entities';
import {REPORT_TYPE_NOT_FOUND} from 'shared/constants/urls';
import {LocationStateKey, useAppHistory, UseAppHistoryFunction} from 'shared/hooks/use-app-history';
import {useFieldAvailability} from 'shared/hooks/use-field-availability.hook';
import {isEnumInstance} from 'shared/utils';
import {useAppSelector} from 'store/config/hooks';
import {selectMenuStructure} from 'store/slices/menu-slice';

import './editable-field.less';

export interface EditableFieldProps {
    entityName: string;
    entityType?: EntityType;
    fieldMeta?: FieldMeta;
    value?: any;
    prefix?: string;
    url: string;
    params?: Record<string, any>;
    reportType?: string;
    editProperties?: {
        hide?: boolean;
        disable?: boolean;
    };
}

const renderTitle = (
    entityName: string,
    value: any,
    sectionKey: string,
    reportType?: string,
    isNeedToRedirect?: string,
    currentMenuTitle?: string,
    history?: UseAppHistoryFunction,
) => {
    const displayedValue = get(value, sectionKey, '');

    if (reportType) {
        const {id, documentType} = value;
        const {
            tableReportName,
            tableReportTemplateCode: templateCode,
        } = documentType;

        const isTableReportType = reportType === PAGE_TEMPLATE_TYPE.TABLE_REPORT;
        const tableReportPath = `/table-reports/${id}/${templateCode}`;

        const handleTableReportClick = (event: React.MouseEvent<HTMLElement>) => {
            event.preventDefault();

            const breadcrumbs = [
                {
                    id: '1',
                    name: 'Главная страница',
                    path: '/',
                },
                {
                    id: '2',
                    name: currentMenuTitle ?? 'Родительское меню не найдено',
                    path: `/${entityName}`,
                },
                {
                    id: '3',
                    name: tableReportName ?? 'Название отчёта не найдено',
                    path: tableReportPath,
                },
            ];
            history?.pushWithStateUpdate(tableReportPath, {
                [LocationStateKey.TABLE_REPORT_BREADCRUMBS]: breadcrumbs,
                [LocationStateKey.TABLE_REPORT_LOCATION_DEFAULTS]: {
                    locationPage: undefined,
                    locationSize: undefined,
                    locationFilters: undefined,
                    locationParameters: undefined,
                    locationSort: undefined,
                },
            });
        };

        const reportUrl = (() => {
            if (isEnumInstance(reportType, JASPER_REPORT_ENTITIES)) {
                return `${entityName}/reports/${id}/jasper`;
            }
            if (isTableReportType) {
                return tableReportPath;
            }
            return `${entityName}${REPORT_TYPE_NOT_FOUND}`;
        })();

        return (
            <Link
                to={reportUrl}
                onClick={e => (
                    isTableReportType
                        ? handleTableReportClick(e)
                        : undefined
                )}
            >
                {displayedValue}
            </Link>
        );
    }

    if (!reportType) {
        return <span>{displayedValue}</span>;
    }

    return (
        <Link
            to={`${entityName}/${isNeedToRedirect}`}
        >
            {displayedValue}
        </Link>
    );
};

export const EditableField: React.FunctionComponent<EditableFieldProps> = (
    {
        fieldMeta,
        value,
        entityName,
        entityType,
        prefix,
        url,
        params,
        reportType,
        editProperties,
    }: EditableFieldProps,
) => {
    const dispatch = useDispatch();
    const [editInputShow, setEditInputShow] = React.useState<boolean>(false);
    const [inputValue, setInputValue] = React.useState<string>();

    const {
        disable: disableEdit = false,
        hide: hideEdit = false,
    } = editProperties ?? {};

    const history = useAppHistory();
    const menuStructure = useAppSelector(selectMenuStructure);

    const currentMenuTitle = useMemo(
        () => menuStructure?.find(m => m.entityName === entityName)?.itemTitle,
        [menuStructure, entityName],
    );

    const stringStructure = fieldMeta?.stringStructure;
    const sections = stringStructure?.split('\n') || [];
    const stringPrefixes: Array<string> = [];
    sections.forEach((el, index) => {
        if (el.includes(' ')) {
            stringPrefixes[index] = (el.slice(0, el.indexOf(' ') + 1));
            sections[index] = (el.slice(el.indexOf(' ') + 1));
        }
    });
    const handleClick = (name: string) => {
        setInputValue(name);
        setEditInputShow(true);
    };
    const {available} = useFieldAvailability(fieldMeta?.isNotAvailableWhen || [], value);

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
        if (fieldMeta?.key && params) {
            dispatch(
                editNameDocument(
                    entityName,
                    prefix || url,
                    value.id,
                    RequestType.PUT,
                    {[fieldMeta.key]: event.target.value},
                    entityType || EntityType.TABLE,
                    params,
                ),
            );
        }

        setEditInputShow(false);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    return (
        <>
            {
                sections.map((sectionKey: string, index: number) => (
                    <React.Fragment key={sectionKey}>
                        {(() => {
                            if (editInputShow && index === 0) {
                                return (
                                    <Input
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={inputValue}
                                    />
                                );
                            }
                            if (index === 0) {
                                return (
                                    <div className={cn(
                                        'editable-field',
                                        {'editable-field_disabled': disableEdit},
                                    )}
                                    >
                                        {renderTitle(
                                            entityName,
                                            value,
                                            sectionKey?.trim(),
                                            reportType,
                                            fieldMeta?.isNeedToRedirect,
                                            currentMenuTitle,
                                            history,
                                        )}
                                        {available && !hideEdit && (
                                            <EditOutlined
                                                disabled={disableEdit}
                                                className="editable-field__icon"
                                                onClick={() => {
                                                    if (!disableEdit) handleClick(get(value, sectionKey, ''));
                                                }}
                                            />
                                        )}
                                    </div>
                                );
                            }
                            return (
                                <p className="editable-field">{stringPrefixes[sections.indexOf(sectionKey)]}
                                    {get(value, sectionKey, '')}
                                </p>
                            );
                        })()}
                    </React.Fragment>
                ))
            }
        </>
    );
};
