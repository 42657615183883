import {Space} from 'antd';
import {FormInstance} from 'antd/es/form';
import {SizeType} from 'antd/lib/config-provider/SizeContext';
import cn from 'classnames';
import React, {useMemo} from 'react';

import {FormEntityData, DatePickerState} from 'modules/data/data-types';
import {FieldMeta} from 'modules/metadata';

import {Field} from '../field/field';
import {FormMode} from '../form-types';

interface FieldsProps {
    formData?: FormEntityData;
    entityName?: string;
    parentEntityName?: string;
    list?: FieldMeta[];
    form?: FormInstance;
    spacingValue?: SizeType | number;
    isEditable?: boolean;
    editModeWidth?: boolean;
    formMode?: FormMode;
    isDisableFields?: boolean;
    fieldClassNames?: cn.Argument;
}

export const Fields: React.FunctionComponent<FieldsProps> = ({
    formData,
    list,
    parentEntityName,
    form,
    entityName,
    spacingValue,
    isEditable,
    editModeWidth,
    formMode,
    isDisableFields,
    fieldClassNames,
}: FieldsProps) => {
    const datePickerStates: DatePickerState = {};
    const fields = useMemo(() => list?.sort(
        (a, b) => (a.order && b.order ? a.order - b.order : 0),
    ).map(field => (
        <Field
            formData={formData}
            entityName={entityName}
            parentEntityName={parentEntityName}
            key={field.key}
            fieldMeta={{
                ...field,
                isDisabled: isEditable === false || field.isDisabled,
            }}
            form={form}
            editModeWidth={editModeWidth}
            placeholder={field.placeholder}
            formMode={formMode}
            datePickerStates={datePickerStates}
            isDisableFields={isDisableFields}
            fieldClassNames={fieldClassNames}
        />
    )), [list, isEditable, editModeWidth, formData]);
    return spacingValue ? <Space size={spacingValue}>{fields}</Space> : <>{fields}</>;
};
