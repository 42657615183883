import cn from 'classnames';
import React from 'react';

import {useAppSelector} from 'store/config/hooks';
import {DraggableFrameType} from 'store/slices/draggable-frames-slice';
import {
    draggableFramesSelectors,
} from 'store/slices/draggable-frames-slice/draggable-frames-slice';

import {DraggableFrameComponentProps} from './draggable-frame-types';
import {ApiRequestProgressFrame} from './frames/api-request-progress-frame';
import {TableReportAttachmentUploadFrame} from './frames/table-report-attachment-upload-frame';

interface DraggableFrameRootProps {}

const DraggableFrameComponent: React.FC<DraggableFrameComponentProps> = ({
    frameEntity,
}: DraggableFrameComponentProps) => {
    const {type} = frameEntity;
    const Component = {
        [DraggableFrameType.TableReportAttachmentUploadFrame]: TableReportAttachmentUploadFrame,
        [DraggableFrameType.ApiRequestProgressFrame]: ApiRequestProgressFrame,
    }[type];
    return <Component frameEntity={frameEntity} />;
};

export const DraggableFrameRoot: React.FC<DraggableFrameRootProps> = () => {
    const draggableFrames = useAppSelector(draggableFramesSelectors.selectAll);

    return (
        <div className={cn('draggable-frame-root')}>
            {draggableFrames.map(dFrame => (
                <DraggableFrameComponent
                    key={`${dFrame.type}_${dFrame?.id ?? 'no-id'}`}
                    frameEntity={dFrame}
                />
            ))}
        </div>
    );
};
