import {
    LinkOutlined, LoadingOutlined, StopOutlined, UndoOutlined,
} from '@ant-design/icons';
import {Button, Form, Popover} from 'antd';
import {useForm} from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';
import cn from 'classnames';
import {isEqual, xor} from 'lodash';
import React, {useRef, useState} from 'react';

import {DraggableFrameUniqueTypeIds} from 'components/draggable-frame';
import {UploaderList} from 'components/form/inputs/uploader-list';
import {ReactComponent as FileIcon} from 'shared/assets/forms/file.svg';
import {invalidateThunkCache} from 'shared/cache';
import {ThunkCacheTag} from 'shared/cache/thunk-cache';
import {ErrorMessages} from 'shared/constants/messages';
import {useAfterEffect} from 'shared/hooks/use-after-effect';
import {FileDto} from 'shared/types/files';
import {setFieldsValueUntouched, showMessage} from 'shared/utils';
import {store} from 'store/config';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {AppState} from 'store/config/types';
import {DraggableFrameType} from 'store/slices/draggable-frames-slice';
import {
    draggableFramesActions,
} from 'store/slices/draggable-frames-slice/draggable-frames-slice';
import {tableReportAttachmentsApi} from 'store/slices/table-report-slice/table-report-row-attachments/table-report-row-attachments-api';
import {attachCommentToRow} from 'store/slices/table-report-slice/table-report-row-attachments/table-report-row-attachments-thunks';
import {TableReportRowAttachmentDto, TableReportRowComment} from 'store/slices/table-report-slice/table-report-row-attachments/table-report-row-attachments-types';
import {tableReportSliceActions} from 'store/slices/table-report-slice/table-report-slice';
import {selectTableReportData, selectTableReportTemplateConfig} from 'store/slices/table-report-slice/table-report-slice-selectors';

import {LoadTableReportPageDataFunction} from '../hooks/use-control';
import {formatDocId} from './table-row-attachments-utils';

interface TableRowModalOpenerContentProps {
    templateCode: string;
    rowComment: TableReportRowComment | null;
    rowId: string;
    docId: string | null;
    loadReportPageData: LoadTableReportPageDataFunction;
    isRefreshingData: boolean;
    isAlreadyUploading?: boolean;
}

export const TableRowModalOpenerContent = React.forwardRef<any, TableRowModalOpenerContentProps>(({
    templateCode,
    rowComment,
    rowId,
    docId,
    loadReportPageData,
    isRefreshingData,
    isAlreadyUploading,
}: TableRowModalOpenerContentProps, ref) => {
    const [formInstance] = useForm();

    const dispatch = useAppDispatch();

    const {setUploadProgress, updateUploadProgress} = tableReportSliceActions;
    const {downloadFile} = tableReportAttachmentsApi;
    const {openDraggableFrame} = draggableFramesActions;

    const templateConfig = useAppSelector(selectTableReportTemplateConfig);
    const {
        withDocumentId,
        templateName,
        enabledRowAttachments,
        enabledRowComments,
    } = templateConfig ?? {};

    useAfterEffect(() => {
        setFieldsValueUntouched(formInstance, {
            commentText: rowComment?.commentText,
        });
    }, [rowComment]);

    const [attachmentsIdsToDelete, setAttachmentIdsToDelete] = useState<string[]>([]);
    const [attachedToFormFilesLength, setAttachedFilesLength] = useState(0);

    const [isFileDownloadInitiated, setIsFileDownloadInitiated] = useState<string | null>(null);
    const isFileInitiatedTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

    const handleToggleDeletionStatus = (attachmentId: string) => {
        setAttachmentIdsToDelete(xor(attachmentsIdsToDelete, [attachmentId]));
    };
    const checkIfIsInDeletionList = (attachmentId: string) => attachmentsIdsToDelete.includes(attachmentId);

    const handleFileDownload = (
        attachmentId: TableReportRowAttachmentDto['attachmentId'],
        fileName: TableReportRowAttachmentDto['fileName'],
    ) => {
        downloadFile({
            attachmentId,
            reportRowId: rowId,
            templateCode,
            fileName,
        }).catch(() => {
            showMessage({message: ErrorMessages.FILE_DOWNLOAD, isError: true});
        });
    };

    return (
        <div>
            <Form<{
                commentText: string;
                files: Required<FileDto>[];
            }>
                onValuesChange={changed => {
                    if (changed.files) {
                        setAttachedFilesLength(changed.files.length);
                    }
                }}
                ref={ref}
                layout="vertical"
                form={formInstance}
                initialValues={{
                    commentText: rowComment?.commentText,
                    files: [],
                }}
                onFinish={values => {
                    const {commentText, files = []} = values;
                    const fileBlobs = files.map(f => f.file as File);
                    dispatch(setUploadProgress({
                        templateCode,
                        reportRowId: rowId,
                        docId: formatDocId({docId, withDocumentId}),
                        uploadData: {
                            loadProgressPercent: 0,
                            isRequestPending: true,
                            files: files.map(f => ({
                                name: f.name,
                                size: f.file.size,
                            })),
                            templateName,
                        },
                    }));

                    if (files.length) {
                        dispatch(openDraggableFrame({
                            type: DraggableFrameType.TableReportAttachmentUploadFrame,
                            id: DraggableFrameUniqueTypeIds.TableReportAttachmentUploadFrame,
                        }));
                    }
                    const uploadRequestOriginLocation = {
                        url: window.location.pathname,
                        page: selectTableReportData(store.getState() as any)?.page.number,
                    };
                    dispatch(attachCommentToRow({
                        requestData: {
                            templateCode,
                            docId: formatDocId({docId, withDocumentId}),
                            commentText,
                            reportRowId: rowId,
                            files: fileBlobs,
                            filesOnDelete: attachmentsIdsToDelete,
                        },
                        handleProgress: (event => {
                            const {loaded, total} = event;
                            const loadProgressPercent = Math.trunc((loaded / total) * 100);
                            dispatch(updateUploadProgress({
                                templateCode,
                                reportRowId: rowId,
                                docId: formatDocId({docId, withDocumentId}),
                                uploadData: {
                                    loadProgressPercent,
                                },
                            }));
                        }),
                    }))
                        .unwrap()
                        .then(() => {
                        /* Запрос выполняется раньше, чем данные о переданных файлах сохраняются в БД,
                           поэтому нужна задержка.  */
                            setTimeout(() => {
                                dispatch(updateUploadProgress({
                                    templateCode,
                                    reportRowId: rowId,
                                    docId: formatDocId({docId, withDocumentId}),
                                    uploadData: {
                                        loadProgressPercent: 100,
                                        isRequestPending: false,
                                    },
                                }));
                                if (!files.length) {
                                    showMessage({message: 'Вложение для строки успешно обновлено'});
                                }
                                const uploadRequestCurrentLocation = {
                                    url: window.location.pathname,
                                    page: selectTableReportData(store.getState() as AppState)?.page.number,
                                };
                                if (uploadRequestCurrentLocation.url === uploadRequestOriginLocation.url
                                    && uploadRequestCurrentLocation.page === uploadRequestOriginLocation.page) {
                                /* Обновляем только в случае, если совпадают URL
                                    и страничка пагинации после окончания
                                    загрузки и в момент начала загрузки.
                                    Более корректно будет возвращать с бэка вместе с данными отчёта doc_id
                                    и templateCode, и сверять их, но пока это не реализовано. */
                                    invalidateThunkCache([ThunkCacheTag.TABLE_REPORT]);
                                    loadReportPageData?.({page: uploadRequestCurrentLocation.page});
                                }
                            }, 1000);
                        }).catch(() => {
                            showMessage({message: ErrorMessages.FILE_UPLOAD, isError: true});
                            dispatch(setUploadProgress({
                                templateCode,
                                reportRowId: rowId,
                                docId: formatDocId({docId, withDocumentId}),
                                uploadData: undefined,
                            }));
                        });
                }}
            >
                {enabledRowComments && (
                    <Form.Item
                        name="commentText"
                        label="Комментарий к строке"
                    >
                        <TextArea
                            disabled={isRefreshingData || isAlreadyUploading}
                            autoSize={{minRows: 5, maxRows: 10}}
                            placeholder="Введите комментарий"
                        />
                    </Form.Item>
                )}

                {enabledRowAttachments && (
                    <>
                        {!rowComment?.attachments.length && !attachedToFormFilesLength
                            ? <div>К строке не приложено ни одного файла</div>
                            : (
                                <div
                                    style={{marginBottom: 0}}
                                    className={cn('table-row-attachments__files-label')}
                                >
                                    <span>Файлы</span>
                                    {((rowComment?.attachments?.length ?? 0) > 1) && !isEqual(
                                        attachmentsIdsToDelete, (rowComment?.attachments ?? []).map(
                                            ({attachmentId}) => attachmentId,
                                        ),
                                    ) && (
                                        <Button
                                            onClick={() => {
                                                setAttachmentIdsToDelete((rowComment?.attachments ?? []).map(
                                                    ({attachmentId}) => attachmentId,
                                                ));
                                            }}
                                            type="link"
                                            style={{padding: 0}}
                                        >Открепить все файлы
                                        </Button>
                                    )}
                                </div>
                            )}

                        <div className={cn('table-row-attachments__attachments')}>
                            {(rowComment?.attachments ?? [])
                                .map(({attachmentId, fileName, outputFileSize}) => (
                                    <div
                                        key={attachmentId}
                                        className={cn('table-row-attachments__attachments__item')}
                                    >
                                        <div className={cn('table-row-attachments__attachments__item__file')}>
                                            <FileIcon />

                                            <div className={
                                                cn('table-row-attachments__attachments__item__file__name', {
                                                    ['table-row-attachments__attachments'
                                                    + '__item__file__name_in-deletion-list']:
                                                checkIfIsInDeletionList(attachmentId),
                                                })
                                            }
                                            >
                                                {fileName}
                                            </div>
                                        </div>
                                        <div className={cn('table-row-attachments__attachments__item__actions')}>
                                            <div
                                                onClick={() => {
                                                    if (isFileInitiatedTimeoutRef.current) {
                                                        clearTimeout(isFileInitiatedTimeoutRef.current);
                                                    }
                                                    if (isFileDownloadInitiated !== attachmentId) {
                                                        handleFileDownload(attachmentId, fileName);
                                                    }
                                                    setIsFileDownloadInitiated(attachmentId);
                                                    isFileInitiatedTimeoutRef.current = setTimeout(() => {
                                                        setIsFileDownloadInitiated(null);
                                                    }, 1000);
                                                }}
                                                className={cn(
                                                    'table-row-attachments__attachments__item__actions__action',
                                                    {
                                                        ['table-row-attachments__attachments'
                                                        + '__item__actions__action_loading']:
                                                        isFileDownloadInitiated === attachmentId,
                                                    },
                                                )}
                                            >
                                                {isFileDownloadInitiated === attachmentId ? (
                                                    <div >
                                                        <LoadingOutlined style={{marginRight: 10}} />
                                                        Загрузка начата...
                                                    </div>
                                                ) : (
                                                    <>
                                                        <LinkOutlined />
                                                        <Popover
                                                            mouseEnterDelay={0.5}
                                                            placement="bottom"
                                                            content={(
                                                                <div>
                                                                    Размер файла:{' '}
                                                                    {(() => {
                                                                        const tf = (n: number) => n.toFixed(2);
                                                                        if (outputFileSize > 1024) {
                                                                            return `${tf(outputFileSize / 1024)} КБ`;
                                                                        }
                                                                        if (outputFileSize > (1024 * 1024)) {
                                                                            return `${tf(
                                                                                outputFileSize / (1024 * 1024),
                                                                            )} МБ`;
                                                                        }
                                                                        return `${outputFileSize} байт`;
                                                                    })()}
                                                                </div>
                                                            )}
                                                        >
                                                            Скачать
                                                        </Popover>

                                                    </>
                                                )}
                                            </div>
                                            <div
                                                onClick={() => handleToggleDeletionStatus(attachmentId)}
                                                className={cn(
                                                    'table-row-attachments__attachments__item__actions__action',
                                                )}
                                            >
                                                {checkIfIsInDeletionList(attachmentId) ? (
                                                    <>
                                                        <UndoOutlined />
                                                        Отменить
                                                    </>
                                                ) : (
                                                    <>
                                                        <StopOutlined />
                                                        Открепить
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                        <Form.Item
                            name="files"
                            valuePropName="fileList"
                        >
                            <UploaderList
                                dropzoneVariant="dropzone"
                                buttonText="Выбрать файлы"
                                styles={{
                                    buttonStyle: {
                                        marginBottom: 6,
                                    },
                                }}
                            />
                        </Form.Item>
                    </>
                )}
            </Form>
        </div>
    );
});
