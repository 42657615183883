import Spin from 'antd/es/spin';
import React from 'react';

import {CustomTable, customTableUtils} from 'components/@common/widgets/custom-table';
import {ModalOpenerComponent, ModalOpenerComponentRef} from 'components/modal-opener-component';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {aisSelectors, aisSliceActions} from 'store/slices/ais-slice/ais-slice';
import {loadAisRequirements} from 'store/slices/ais-slice/ais-slice-thunks';
import {AisRegisterDocumentDto} from 'store/slices/ais-slice/ais-slice-types';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

export interface RequireRequestModalProps {
    registerDocument: AisRegisterDocumentDto;
}

export const RequireRequestModal = React.forwardRef<ModalOpenerComponentRef, RequireRequestModalProps>(({
    registerDocument,
}: RequireRequestModalProps, ref) => {
    const dispatch = useAppDispatch();

    const aisRequirements = useAppSelector(aisSelectors.requirementsSelectors.selectAll);

    const {aisDocId} = registerDocument ?? {};

    const isLoadingRequirements = useAppSelector(s => selectIsThunkPending(s, loadAisRequirements.typePrefix));

    const loadRequirements = () => {
        if (aisDocId) {
            dispatch(loadAisRequirements({
                aisDocId,
            }));
        }
    };

    return (
        <ModalOpenerComponent
            ref={ref}
            hideControls={{
                all: true,
            }}
            onShow={() => {
                loadRequirements();
            }}
            afterModalClose={() => {
                dispatch(aisSliceActions.resetRequirements());
            }}
            controlLabels={{
                cancel: 'Закрыть',
            }}
            modalProps={{
                title: 'Требования',
                width: '80vw',
                centered: true,
                forceRender: false,
                destroyOnClose: true,
            }}
        >
            <Spin spinning={isLoadingRequirements}>
                <CustomTable
                    onColumn={col => ({
                        ...customTableUtils.setHeaderStyles({
                            whiteSpace: 'pre',
                            textAlign: 'center',
                        }),
                        ...col,
                        render: col.render ?? (cellValue => <div style={{textAlign: 'center'}}>{cellValue}</div>),
                    })}
                    dataSource={aisRequirements ?? []}
                    columns={[
                        {title: 'Номер', dataIndex: 'requirementNumber'},
                        {title: 'Основание', dataIndex: 'basisRequirement'},
                        {
                            title: 'Дата и время\nполучения требования',
                            dataIndex: 'dateReceiptRequirement',
                            render: v => (
                                <div className="text-center">
                                    {v}
                                    {/* todo: форматирование {v && moment(v).format(DATE_DMY)} */}
                                </div>
                            ),
                        },
                        {title: 'Срок ответа', dataIndex: 'responseTime'},
                        {title: 'Статус ответа', dataIndex: 'responseStatus'},
                        {
                            title: 'Дата ответа',
                            dataIndex: 'responseDate',
                            render: v => (
                                <div
                                    className="text-center"
                                >
                                    {v}
                                    {/* todo: форматирование {v && moment(v).format(DATE_DMY)} */}
                                </div>
                            ),
                        },
                        {title: 'Ответственный', dataIndex: 'responseBy'},
                    ]}
                />
            </Spin>
        </ModalOpenerComponent>
    );
});
