import {
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';

import {generateRandomString} from 'shared/utils';
import {AppState} from 'store/config/types';

import {apiRequestProgressSliceName} from './api-request-progress-slice-constants';
import {ApiRequestProgressEntity, ApiRequestProgressSliceState} from './api-request-progress-slice-types';

const requestsProgressEntityAdapter = createEntityAdapter<ApiRequestProgressEntity>({
    selectId: model => model.id,
});

export const apiRequestProgressSlice = createSlice({
    initialState: {
        requestsProgress: requestsProgressEntityAdapter.getInitialState(),
        someData: 'data',
    } as ApiRequestProgressSliceState,
    name: apiRequestProgressSliceName,
    reducers: {
        purgeRequestProgress(state) {
            requestsProgressEntityAdapter.removeAll(state.requestsProgress);
        },
        setRequestProgress(state, {
            payload,
        }: PayloadAction<ApiRequestProgressEntity[]>) {
            requestsProgressEntityAdapter.setAll(state.requestsProgress, {
                ...payload,
            });
        },
        addRequestProgress(state, {
            payload,
        }: PayloadAction<Omit<ApiRequestProgressEntity, 'id'> & {
            id?: string;
        }>) {
            const nextId = payload.id ?? generateRandomString();

            requestsProgressEntityAdapter.upsertOne(state.requestsProgress, {
                id: nextId,
                ...payload,
            });
        },
        updateRequestProgressPercent(state, {payload}: PayloadAction<{
            id: ApiRequestProgressEntity['id'];
            progressPercent: number;
        }>) {
            const {id, progressPercent} = payload;
            if (state.requestsProgress.ids.includes(id)) {
                requestsProgressEntityAdapter.updateOne(state.requestsProgress, {
                    changes: {
                        progressPercent,
                    },
                    id,
                });
            }
        },
        removeRequestProgress(state, {payload}: PayloadAction<ApiRequestProgressEntity['id']>) {
            requestsProgressEntityAdapter.removeOne(state.requestsProgress, payload);
        },
    },
});

const selectApiRequestProgressSliceState = (state: AppState) => state[apiRequestProgressSliceName];

const requestsProgressEntityAdapterSelectors = requestsProgressEntityAdapter.getSelectors(
    (state: AppState) => selectApiRequestProgressSliceState(state).requestsProgress,
);

export const apiRequestProgressSelectors = {
    selectApiRequestProgressSliceState,
    ...requestsProgressEntityAdapterSelectors,
};

export const {
    reducer: apiRequestProgressSliceReducer,
    actions: apiRequestProgressSliceActions,
} = apiRequestProgressSlice;
