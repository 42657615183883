import {RequestType} from 'modules/metadata';
import {performRequest} from 'shared/utils';

import {
    DocumentUploadTemplateConfiguratorConverted,
    DocumentUploadTemplateData, DocumentUploadTemplateTypeLookupData,
} from './document-upload-template-slice-type';

export type FetchDocumentUploadTemplateArgs = {
  entityName: string;
  templateCode: string;
};
export const fetchDocumentUploadTemplateData = ({
    entityName,
    templateCode,
}: FetchDocumentUploadTemplateArgs) => (
    performRequest<DocumentUploadTemplateData>({
        url: `${entityName}/${templateCode}`,
        method: RequestType.GET,
    })
);

export type PostDocumentUploadTemplateConfigurationArgs = {
    url: string;
    data: DocumentUploadTemplateConfiguratorConverted;
}
export const postDocumentUploadTemplateConfiguration = async (args: PostDocumentUploadTemplateConfigurationArgs) => {
    const {data, url} = args;

    const response = await performRequest({
        url,
        method: RequestType.POST,
        data,
    });
    return response;
};

export type PatchDocumentUploadTemplateDataArgs = {
    templateCode: string;
    entityName: string;
    data: Partial<DocumentUploadTemplateData>;
}
export const patchDocumentUploadTemplate = async (args: PatchDocumentUploadTemplateDataArgs) => {
    const {templateCode, data, entityName} = args;

    const response = await performRequest({
        url: `${entityName}/${templateCode}`,
        method: RequestType.PATCH,
        data,
    });
    return response;
};

export type FetchSettingTemplateTransferTargetColumnsDataArgs = {
    sublistCode: string;
    sublistId?: number;
}
export const fetchSettingTemplateTransferTargetColumns = async (
    args: FetchSettingTemplateTransferTargetColumnsDataArgs,
) => {
    const {sublistCode, sublistId} = args;
    const response = await performRequest<DocumentUploadTemplateTypeLookupData[]>({
        url: 'valueLists/DATABASE_TABLE_COLUMNS',
        method: RequestType.GET,
        params: {sublistCode, sublistId},
    });
    return response;
};

export type DeleteDocumentUploadTemplateArgs = FetchDocumentUploadTemplateArgs;
export const deleteDocumentUploadTemplateConfiguration = async ({
    entityName,
    templateCode,
}: DeleteDocumentUploadTemplateArgs) => {
    const response = await performRequest<string>({
        url: `${entityName}/${templateCode}`,
        method: 'DELETE',
    });
    return response;
};
