import {Form} from 'antd';
import {FormInstance} from 'antd/es/form';
import React from 'react';

import {
    IGNORABLE_FIELDS_KEYS,
} from 'components/ais/monitor-services-card/monitor-service-dynamic-fields/monitor-service-dynamic-fields.constants';
import {
    dynamicFieldResolver,
    dynamicFieldsSorter,
} from 'components/ais/monitor-services-card/monitor-service-dynamic-fields/monitor-service-dynamic-fields.utils';
import {fieldValidationResolver} from 'components/form/utils/field-validation-resolver';
import {selectDocumentsSigningMethod} from 'modules/documents/documents-selectors';
import {FieldType} from 'modules/metadata';
import {DynamicInputField} from 'shared/types';
import {useAppSelector} from 'store/config/hooks';

import './monitor-service-dynamic-fields.less';

interface MonitorServiceDynamicFieldsProps {
    form: FormInstance;
    fields: DynamicInputField[] | undefined;
    entityName: string;
}

export const MonitorServiceDynamicFields = ({
    form, fields, entityName,
}: MonitorServiceDynamicFieldsProps) => {
    const filteredFields = fields
        ?.filter(f => !IGNORABLE_FIELDS_KEYS.includes(f?.key))
        ?.sort(dynamicFieldsSorter);

    const documentsSigningMethod = useAppSelector(selectDocumentsSigningMethod);

    if (!filteredFields?.length) {
        return null;
    }

    return (
        <div className="monitor-service-fields">
            <div className="monitor-service-fields__title">
                Дополнительные поля
            </div>
            <div className="monitor-service-fields__content">
                {filteredFields?.map(f => (
                    <Form.Item
                        key={f?.id || f?.key}
                        name={f?.key}
                        label={f?.label}
                        style={{width: f?.width ?? '100%'}}
                        rules={fieldValidationResolver({
                            validationRules: f.validationRules,
                            fieldMeta: {
                                type: f.type ?? FieldType.STRING,
                                label: f.label ?? '',
                                isRequired: f.isRequired,
                            },
                        })}
                    >
                        {dynamicFieldResolver({
                            form,
                            field: f,
                            documentsSigningMethod,
                            entityName,
                        })}
                    </Form.Item>
                ))}
            </div>
        </div>
    );
};
