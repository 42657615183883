import {createSelector, Selector} from '@reduxjs/toolkit';

import {EntityType} from 'shared/constants/entities';
import {AppState} from 'store/config/types';

import {DATA_MODULE} from './data-constants';
import {
    DataState,
    EntityDataState,
} from './data-types';

const rootSelector: Selector<AppState, DataState> = (state: AppState) => state[DATA_MODULE];

export const selectEntityData = (entityName: string, entityType: EntityType) => createSelector(
    rootSelector,
    (dataState: DataState) => dataState[entityName]?.[entityType],
);

export const selectIsEntityDataLoading = (entityName: string) => createSelector(
    rootSelector,
    (dataState: DataState) => !!dataState[entityName]?.loading,
);

export const selectIsFormEntityDataEditable = (entityName: string) => createSelector(
    rootSelector,
    (dataState: DataState) => dataState[entityName]?.[EntityType.FORM]?.isEditable,
);

export const selectFormEntityMode = (entityName: string) => createSelector(
    rootSelector,
    (dataState: DataState) => dataState[entityName]?.[EntityType.FORM]?.formMode,
);

export const selectTableEntityData = (entityName: string) => createSelector(
    rootSelector,
    (dataState: DataState) => dataState[entityName]?.[EntityType.TABLE],
);

export const selectTableStateEntityData = (entityName: string) => createSelector(
    rootSelector,
    (dataState: DataState) => dataState[entityName]?.[EntityType.TABLE_STATE],
);

export const selectFormEntityData = (entityName: string) => createSelector(
    rootSelector,
    (dataState: DataState) => dataState[entityName]?.[EntityType.FORM],
);

export const selectEditableTableEntityData = (entityName: string) => createSelector(
    rootSelector,
    (dataState: DataState) => dataState[entityName]?.[EntityType.EDITABLE_TABLE],
);

export const selectFilterEntityData = (entityName: string) => createSelector(
    rootSelector,
    (dataState: DataState) => dataState[entityName]?.[EntityType.FILTER],
);

export const selectReferenceEntityData = (entityName: string) => createSelector(
    rootSelector,
    (dataState: DataState) => dataState[entityName]?.[EntityType.REFERENCE]?.rows,
);

export const selectReferencePlaceholder = (entityName: string) => createSelector(
    rootSelector,
    (dataState: DataState) => dataState[entityName]?.[EntityType.REFERENCE]?.placeholder,
);

export const selectReferencesAsEntityDataArray = (referenceNames: string[]) => createSelector(
    rootSelector,
    (dataState: DataState) => referenceNames.reduce<{[p: string]: EntityDataState}>((acc, referenceName) => ({
        ...acc,
        [referenceName]: dataState[referenceName],
    }), {}),
);

export const selectReferenceDataLoading = (referenceName: string) => createSelector(
    rootSelector,
    (dataState: DataState) => {
        const loading = dataState[referenceName]?.loading;
        if (loading === undefined) return false;
        return loading;
    },
);

export const selectTableSelectedRowKeys = (
    entityName: string,
) => createSelector(rootSelector,
    (dataState: DataState) => dataState[entityName]?.[EntityType.TABLE]?.selectedRowKeys);

export const selectEntityDataFormInstance = (entityName: string) => createSelector(
    rootSelector,
    (dataState: DataState) => dataState[entityName]?.[EntityType.FORM]?.formInstance,
);

export const selectDashboardEntityData = (entityName: string) => createSelector(
    rootSelector,
    (dataState: DataState) => dataState[entityName]?.[EntityType.DASHBOARD],
);

export const selectUsedAdditionalOptionId = (entityName: string, entityNameChild: string) => createSelector(
    rootSelector,
    (dataState: DataState) => dataState[entityName]?.FORM_FIELDS_DATA?.[entityNameChild]?.formUsedAdditionalOptionId,
);

export const selectUsedAdditionalOptions = (entityName: string, entityNameChild: string) => createSelector(
    rootSelector,
    (dataState: DataState) => dataState[entityName].FORM_FIELDS_DATA?.options?.[entityNameChild],
);

export const selectFormDataWithEntityId = (entityName: string, id?: string) => createSelector(
    rootSelector,
    (dataState: DataState) => {
        const data = dataState[entityName]?.[EntityType.FORM];
        return data && data?.entityId === id ? data : undefined;
    },
);

export const selectNotificationDuration = () => createSelector(
    rootSelector,
    (dataState: DataState) => dataState.notificationDuration,
);

export const selectSelectedSubsection = createSelector(
    rootSelector,
    (dataState: DataState) => dataState.selectedSubsection,
);
