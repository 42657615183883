import {Input} from 'antd';
import React, {Dispatch, SetStateAction} from 'react';

interface DropdownRendererProps {
    inputValue: string;
    setInputValue: Dispatch<SetStateAction<string>>;
    menu: React.ReactElement;
}

export const SearchableDropdown: React.FunctionComponent<DropdownRendererProps> = ({
    menu,
    setInputValue,
    inputValue,
}: DropdownRendererProps) => (

    <div className="select-dropdown-content">
        <Input
            className="select-dropdown-input"
            placeholder="Поиск"
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
            // чтобы не удалять tags когда мы чистим этот input
            onKeyDown={event => {
                if (event.key === 'Backspace') {
                    event.stopPropagation();
                }
            }}
        />
        {menu}
    </div>
);
