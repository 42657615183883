import {omit} from 'lodash';

import {RpcDeleteResponse} from 'shared/types/rpc';
import {performRequest} from 'shared/utils';

import {REPORT_CONFIGURATION_API_URL_ENDPOINT} from '../report-configuration-constants';
import {ReportDdReferenceRule, ReportDdReferenceRulesFilter} from './dd-references-configuration-types';

const ddReferenceRulesApiEndpoint = `${REPORT_CONFIGURATION_API_URL_ENDPOINT}/table.report.drill.down`;

interface FetchDrillDownReferenceRulesResponse {
    dataTable: ReportDdReferenceRule[];
    recordsTotal: number;
}
export const fetchDrillDownReferenceRules = (
    params?: ReportDdReferenceRulesFilter,
) => performRequest<FetchDrillDownReferenceRulesResponse>({
    url: ddReferenceRulesApiEndpoint,
    method: 'GET',
    params,
});

export const fetchDrillDownReferenceRuleById = ({id}: {id: number}) => performRequest<ReportDdReferenceRule>({
    url: [ddReferenceRulesApiEndpoint, id].join('/'),
    method: 'GET',
});

export type DrillDownReferenceRulePostRequestArgs = Partial<Omit<ReportDdReferenceRule, 'id'>>;
export const postDrillDownReferenceRule = (
    args: DrillDownReferenceRulePostRequestArgs,
) => performRequest<ReportDdReferenceRule>({
    url: [ddReferenceRulesApiEndpoint].join('/'),
    method: 'POST',
    data: args,
});

export type DrillDownReferenceRulePutRequestArgs = Partial<ReportDdReferenceRule>;
export const putDrillDownReferenceRule = (
    args: DrillDownReferenceRulePutRequestArgs,
) => performRequest<ReportDdReferenceRule>({
    url: [ddReferenceRulesApiEndpoint, args.id].join('/'),
    method: 'PUT',
    data: omit(args, 'id'),
});

export type DrillDownReferenceRuleDeleteRequestArgs = {ids: number[]};
export const deleteDrillDownReferenceRulesRequest = (
    args: DrillDownReferenceRuleDeleteRequestArgs,
) => performRequest<RpcDeleteResponse>({
    url: [ddReferenceRulesApiEndpoint, 'rpc', 'delete-list'].join('/'),
    method: 'POST',
    data: args,
});
