import {omit} from 'lodash';

import {LinkDto, LinkTableReportPositionInfo} from 'shared/types/links';

import {TABLE_REPORT_URL_FIELD_NAME} from './table-report-slice-constants';
import {TableReportBodyItem, TableReportColumn, TableReportData} from './table-report-slice-types';

export const addCRColumns = (rColumns: TableReportColumn[], dSource: TableReportBodyItem[] | undefined) => {
    if (dSource?.length) {
        const [firstDataSourceEntry] = dSource;

        const reportColumnsWithCR: TableReportColumn[] = [...rColumns];

        Object.keys(firstDataSourceEntry)?.forEach(k => {
            if (k.endsWith('_cr_delta')) {
                const dataIndex = k.split('_cr_delta').join('');
                const matchedColumn = rColumns.find(c => c.dataIndex === dataIndex);
                reportColumnsWithCR?.push({
                    dataIndex: k,
                    title: 'Расхождение',
                    key: (matchedColumn?.key ?? 0),
                    permanentlyHidden: false,
                    enabledTextWrapping: false,
                });
            }
            if (k.endsWith('_cr')) {
                const dataIndex = k.split('_cr').join('');
                const matchedColumn = rColumns.find(c => c.dataIndex === dataIndex);

                reportColumnsWithCR?.push({
                    dataIndex: k,
                    title: `${matchedColumn?.title} КС`,
                    key: (matchedColumn?.key ?? 0),
                    permanentlyHidden: false,
                    style: 'text-bold',
                });
            }
        });

        return reportColumnsWithCR;
    }
    return undefined;
};

export const formatLinksInTableReportDataContent = (tableReportData: TableReportData) => {
    const {content} = tableReportData?.page;
    const contentWithLinks = content.map(row => {
        const links = row[TABLE_REPORT_URL_FIELD_NAME];
        if (links) {
            return {
                ...omit(row, TABLE_REPORT_URL_FIELD_NAME),
                ...Object.fromEntries(links.map((v, i) => ([`${TABLE_REPORT_URL_FIELD_NAME}_${i}`, v]))),
            };
        }
        return row;
    });

    const formattedTableReportData = {
        ...tableReportData,
        page: {
            ...tableReportData.page,
            content: contentWithLinks,
        },
    };

    return formattedTableReportData;
};

export const addLinkColumns = (rColumns: TableReportColumn[], dataSource: TableReportBodyItem[] | undefined) => {
    if (!dataSource?.length) return rColumns;

    const linkColumns = (() => {
        const columnsInfo: LinkTableReportPositionInfo[] = [];

        dataSource.forEach(rowItem => {
            Object.entries(rowItem).forEach(([fieldName, fieldValue]) => {
                if (fieldName.startsWith(TABLE_REPORT_URL_FIELD_NAME)) {
                    const alreadyAddedFieldNames = columnsInfo.map(c => c.fieldName);
                    if (!alreadyAddedFieldNames.includes(fieldName)) {
                        columnsInfo.push({
                            fieldName,
                            position: (fieldValue as LinkDto)?.position,
                            useTableValueAsTitle: (fieldValue as LinkDto)?.linkProperties?.useTableValueAsTitle,
                        });
                    }
                }
            });
        });

        return columnsInfo;
    })();

    const reportColumns = (() => {
        const columnsWithLinkOnTheLeft = linkColumns.filter( // Слева от всех столбцов
            lCol => lCol.position?.placement === 'LEFT' && !lCol.position.column,
        );

        const columnsWithLinkOnTheRight = linkColumns.filter( // Справа от всех столбцов
            lCol => lCol.position?.placement === 'RIGHT' && !lCol.position.column,
        );

        const columns: TableReportColumn[] = [
            ...columnsWithLinkOnTheLeft.map(c => ({
                dataIndex: c.fieldName as string,
                key: -1,
                permanentlyHidden: false,
                title: c.position?.title ?? 'Ссылка',
                useTableValueAsTitle: c.useTableValueAsTitle,
            })),
            ...rColumns,
            ...columnsWithLinkOnTheRight.map(c => ({
                dataIndex: c.fieldName as string,
                key: 9999,
                permanentlyHidden: false,
                title: c.position?.title ?? 'Ссылка',
                useTableValueAsTitle: c.useTableValueAsTitle,
            })),
        ];

        const columnsWithLinkToTheLeftOfColumn = linkColumns.filter( // Слева от заданного столбца
            lCol => lCol.position?.placement === 'LEFT' && !!lCol.position.column,
        );

        columnsWithLinkToTheLeftOfColumn.forEach(c => {
            const columnIndex = columns.findIndex(col => col.dataIndex === c.position?.column);
            if (columnIndex !== -1) {
                const foundColumn = columns[columnIndex];
                const indexToInsert = columnIndex - 1 < 0 ? 0 : columnIndex - 1;
                columns.splice(indexToInsert, 0, {
                    dataIndex: c.fieldName as string,
                    key: foundColumn?.key ?? 0,
                    title: c.position?.title ?? 'Ссылка',
                    permanentlyHidden: false,
                    linksProperties: {
                        useTableValueAsTitle: c.useTableValueAsTitle,
                    },
                });
            }
        });

        const columnsWithLinkToTheRightOfColumn = linkColumns.filter( // Справа от заданного столбца
            lCol => lCol.position?.placement === 'RIGHT' && !!lCol.position.column,
        );

        columnsWithLinkToTheRightOfColumn.forEach(c => {
            const columnIndex = columns.findIndex(col => col.dataIndex === c.position?.column);
            if (columnIndex !== -1) {
                const foundColumn = columns[columnIndex];
                columns.splice(columnIndex + 1, 0, {
                    dataIndex: c.fieldName as string,
                    key: (foundColumn?.key ?? 0) + 1,
                    title: c.position?.title ?? 'Ссылка',
                    permanentlyHidden: false,
                    linksProperties: {
                        useTableValueAsTitle: c.useTableValueAsTitle,
                    },
                });
            }
        });

        const columnsWithLinkToReplace = linkColumns.filter( // Замена заданного столбца на ссылку
            lCol => !lCol.position?.placement && !!lCol?.position?.column,
        );

        columnsWithLinkToReplace.forEach(c => {
            const columnIndex = columns.findIndex(col => col.dataIndex === c.position?.column);
            if (columnIndex !== -1) {
                const foundColumn = columns[columnIndex];
                columns.splice(columnIndex, 1, {
                    ...foundColumn,
                    dataIndex: c.fieldName as string,
                    title: c.useTableValueAsTitle ? foundColumn.title : c.position?.title ?? foundColumn.title,
                    linksProperties: {
                        useTableValueAsTitle: c.useTableValueAsTitle,
                    },
                });
            }
        });

        return columns;
    })();

    return reportColumns;
};

export const addComparisonColumns = (rColumns: TableReportColumn[], dataSource: TableReportBodyItem[] | undefined) => {
    if (dataSource?.length) {
        const [firstDataSourceEntry] = dataSource;
        const comparisionColumnKeys = Object.keys(
            firstDataSourceEntry,
        )?.filter(ds => ds.endsWith('_comparison'));

        const reportColumnsWithComparisions: TableReportColumn[] = [];

        rColumns.forEach(col => {
            if (comparisionColumnKeys.includes(`${col?.dataIndex}_comparison`)) {
                // add source column with modified title
                reportColumnsWithComparisions?.push({
                    ...col,
                    title: col?.title?.concat(' (1-й документ)'),
                    align: undefined,
                    style: 'text-blue',
                });
                // add column from compared document...
                reportColumnsWithComparisions?.push({
                    dataIndex: col?.dataIndex?.concat('_comparison'),
                    title: col?.title?.concat(' (2-й документ)'),
                    key: (col?.key ?? 0),
                    permanentlyHidden: false,
                    align: undefined,
                    style: 'text-blue',
                });
                // ...and delta column
                reportColumnsWithComparisions?.push({
                    dataIndex: col?.dataIndex?.concat('_comparison_delta'),
                    title: col?.title?.concat(' (расхождение)'),
                    key: (col?.key ?? 0),
                    permanentlyHidden: false,
                    align: undefined,
                    style: undefined,
                });
            } else {
                reportColumnsWithComparisions?.push({...col});
            }
        });
        return reportColumnsWithComparisions;
    }
    return undefined;
};
