import {FormInstance} from 'antd/es/form';
import {ReactText} from 'react';

import {EntityType} from 'shared/constants/entities';
import {
    convertArrayToTwoLevelObject, isObject, saveLocalFile, showMessage, showMessageFromResponse,
} from 'shared/utils';
import {createAction} from 'store/config/creators';
import {AppState, Dispatch} from 'store/config/types';
import {getBlankForm} from 'utils/common';

import {selectEntityData, Entity} from '../data';
import {resetData, setData} from '../data/data-actions';
import {MetaActionInfo, selectMetadata} from '../metadata';
import {setMetadata} from '../metadata/metadata-actions';
import {EntityMeta} from '../metadata/metadata-types';
import {METADATA_DEFAULT, METADATA_OPTIONS} from './metadata';
import {metadataConstructorApi} from './metadata-constructor.api';
import {MetadataConstructorActions} from './metadata-constructor.constants';
import {selectConstructorMetadata} from './metadata-constructor.selectors';
import {TEntityNameSetPayload, TMetadataConstructorSetPayload} from './metadata-constructor.types';
import {MetadataConstructor} from './metadata-constructor.utils';
import {convertActionFieldsForMeta, convertActionMetaForFields} from './metadata/types/table/table.utils';

export const setAllMeta = createAction<TMetadataConstructorSetPayload>(MetadataConstructorActions.SET_ALL_META);
export const setAllEntityName = createAction<TEntityNameSetPayload>(MetadataConstructorActions.SET_ALL_ENTITY_NAME);

const createFormActions = (entityType: EntityType = EntityType.FORM) => (dispatch: Dispatch) => {
    const getEntityNameFromMeta = (metadata: EntityMeta) => metadata.name;

    const setFormMeta = (metadata: EntityMeta) => (
        dispatch(setMetadata({
            entityName: getEntityNameFromMeta(metadata),
            entityType,
            metadata,
        }))
    );

    const setFormData = (metadata: EntityMeta, data: Record<string, any>) => (
        dispatch(setData({
            entityName: getEntityNameFromMeta(metadata),
            entityType,
            data,
        }))
    );

    const setMetaAndData = (metadata: EntityMeta, data: Record<string, any>) => {
        setFormMeta(metadata);
        setFormData(metadata, data);
    };

    const resetFormData = (entityName: string) => {
        dispatch(resetData({entityType, entityName, loading: false}));
    };

    return {
        setMeta: setFormMeta,
        setData: setFormData,
        resetData: resetFormData,
        setMetaAndData,
    };
};
const createFormSelectors = (entityType: EntityType = EntityType.FORM) => (
    state: AppState, defaultEntityName: string = '',
) => {
    const getCurrentMeta = (
        entityName: string = defaultEntityName,
    ) => selectMetadata(entityName, entityType)(state);

    const getCurrentData = (
        entityName: string = defaultEntityName,
    ) => selectEntityData(entityName, entityType)(state);

    const getAllMeta = () => selectConstructorMetadata()(state);

    const getExistingMetadata = (
        name: string,
        type: keyof ReturnType<typeof getAllMeta>,
    ) => {
        const allMeta = getAllMeta();
        return allMeta?.[type]?.[name];
    };
    return {
        getCurrentMeta,
        getCurrentData,
        getAllMeta,
        getExistingMetadata,
    };
};

export const metadataConstructorForm = (() => {
    const entityType = EntityType.FORM;
    const api = metadataConstructorApi;
    const createActions = createFormActions(entityType);
    const createSelectors = createFormSelectors(entityType);
    const formMeta = new MetadataConstructor(
        METADATA_DEFAULT,
        METADATA_OPTIONS,
    );

    const setEntityNameList = (data: EntityMeta[]) => async (dispatch: Dispatch) => {
        const entityNameList = Object.keys(data.reduce((acc: Record<string, string>, item: EntityMeta) => {
            acc[item.name] = item.name;
            return acc;
        }, {}));

        dispatch(setAllEntityName({allEntityName: entityNameList}));
    };

    const fetchInformationForPage = () => async (dispatch: Dispatch) => {
        const {data} = await api.getAllMeta();
        const allMetadata = convertArrayToTwoLevelObject(data, 'viewType', 'name');
        await dispatch(setEntityNameList(data));
        dispatch(setAllMeta({allMetadata}));
    };

    const downloadAllMeta = () => async (dispatch: Dispatch) => {
        const {data} = await api.getAllMeta();
        const allMetadata = convertArrayToTwoLevelObject(data, 'viewType', 'name');
        await dispatch(setEntityNameList(data));
        dispatch(setAllMeta({allMetadata}));
        const file = new File([JSON.stringify(data, null, 2)], 'metadata.json');
        saveLocalFile(file);
    };
    /**
     * Вызывается при создании.
     * @param form
     * @param defaultData
     */
    const initBlankForm = (form: FormInstance, defaultData?: Record<string, any>) => async (dispatch: Dispatch) => {
        const action = createActions(dispatch);
        const meta = formMeta.getDefaultMeta();

        action.setMeta(meta);
        form.setFieldsValue(defaultData || getBlankForm(meta.fields));
    };

    /**
     * Вызывается при редактировании.
     * @param params
     * @param form
     */
    const initEditForm = (params: {name: string; viewType: string}, form: FormInstance) => async (
        dispatch: Dispatch, getState: () => AppState,
    ) => {
        const {name, viewType} = params;
        const currentPageMeta = formMeta.getMetaByValue(viewType);
        const action = createActions(dispatch);
        const selectors = createSelectors(getState());

        const currentData = selectors
            .getExistingMetadata(name, viewType as keyof ReturnType<typeof selectors.getAllMeta>)
            || getBlankForm(currentPageMeta.fields);

        action.setMeta(currentPageMeta);
        form.resetFields();
        form.setFieldsValue({
            ...currentData,
            actions: convertActionMetaForFields(currentData?.actions as MetaActionInfo[]),
        });
    };

    const initPage = (form: FormInstance) => async (dispatch: Dispatch) => {
        try {
            await Promise.all([
                dispatch(initBlankForm(form)),
                dispatch(fetchInformationForPage()),
            ]);
        } catch (e) {
            console.error(e);
            showMessageFromResponse({response: e});
        }
    };
    /**
     * Изменяет значение полей и обновляет метаданные по необходимости
     * @param entityName
     * @param form
     * @param fieldName
     * @param fieldValue
     */
    const changeFields = (
        entityName: string, form: FormInstance, fieldName: ReactText | ReactText[], fieldValue: Entity,
    ) => async (
        dispatch: Dispatch, getState: () => AppState,
    ) => {
        const action = createActions(dispatch);
        const selectors = createSelectors(getState(), entityName);
        let currentMeta = selectors.getCurrentMeta();
        const shouldUpdateMetadata = currentMeta?.dataKeyForFilterFields === fieldName;

        if (shouldUpdateMetadata) {
            const newData = {[fieldName as string]: fieldValue};
            const valueForMeta = isObject(fieldValue) ? fieldValue.value : fieldValue;
            currentMeta = formMeta.getMetaByValue(String(valueForMeta));
            const data = {...getBlankForm(currentMeta.fields), ...newData};

            action.setMeta(currentMeta);
            form.setFieldsValue(data);
        }
    };

    const saveFormData = (
        entityName: string, data: Record<string, any> = {},
    ) => async (dispatch: Dispatch) => {
        try {
            await metadataConstructorApi.saveMetadata(
                {...data, actions: convertActionFieldsForMeta(data?.actions)},
            );
            showMessage({message: 'Метаданные сохранены успешно'});
            dispatch(fetchInformationForPage());
        } catch (e) {
            showMessageFromResponse({response: e.response, isError: true});
        }
    };

    return {
        initPage,
        initBlankForm,
        initEditForm,
        changeFields,
        saveFormData,
        downloadAllMeta,
    };
})();
