import {FieldMeta, FieldType} from 'modules/metadata';

import {COMMON_FIELDS_ITEMS} from '../common/common.fields-items';

export const DATE = [
    COMMON_FIELDS_ITEMS.DATE_FORMAT,
];

export const REFERENCE: FieldMeta[] = [
    {
        ...COMMON_FIELDS_ITEMS.ACTIONS.REFERENCE_URL,
        label: 'Ссылка, использующаяся для получения данных',
        hint: 'Ссылка для получения списка значений. Пример результата ввода: \'lookupValue/TAX_PERIOD_STATUS\'',
    },
    {
        key: 'isFilterable',
        label: 'Возможность фильтрации',
        type: FieldType.BOOLEAN_CLASSIC,
        isHidden: true,
        isRequired: false,
        hint: 'Параметр задаётся на фронте. И описывает наличие фильтра для таблицы.',
    },
    {
        key: 'multipleMode',
        label: 'Множественный выбор',
        type: FieldType.BOOLEAN_CLASSIC,
    },
];

const BOOLEAN: FieldMeta[] = [
    {
        key: 'defaultValue',
        label: 'Значение по умолчанию',
        type: FieldType.STRING,
    },
];

export const FORM_FIELDS = {
    DATE,
    REFERENCE,
    BOOLEAN,
};
