import {
    get, setWith, uniq,
} from 'lodash';
import {useEffect, useState} from 'react';

import {useAfterEffect} from 'shared/hooks/use-after-effect';

import {TABLE_SEARCH_NEW_HIDDEN_COLUMNS_SET_EVENT} from '../../components/table-report-search/table-report-search-constants';

export interface UseHiddenColumnsArgs {
    templateCode: string;
    docId: string | null;
}

export const HIDDEN_COLUMNS_LOCAL_STORAGE_KEY = 'table-report-hidden-columns';
export const SET_TABLE_REPORT_HIDDEN_COLUMNS_EVENT = 'set-table-report-hidden-columns-event';

export const setTableReportHiddenColumns = (keys: string[]) => {
    window.dispatchEvent(new CustomEvent(SET_TABLE_REPORT_HIDDEN_COLUMNS_EVENT, {detail: keys}));
};

export const useHiddenColumns = (args: UseHiddenColumnsArgs) => {
    const {
        docId,
        templateCode,
    } = args;

    const hiddenColumnKeysInStorage = (() => {
        const storageItem = localStorage.getItem(HIDDEN_COLUMNS_LOCAL_STORAGE_KEY) ?? '{}';
        const storageItemParsed = JSON.parse(storageItem);
        const hiddenColumnKeys: string[] = get(storageItemParsed, [templateCode, docId ?? 'document']);
        return hiddenColumnKeys;
    })();

    const saveInStorage = (keys: string[]) => {
        const storageItem = localStorage.getItem(HIDDEN_COLUMNS_LOCAL_STORAGE_KEY) ?? '{}';
        const storageItemParsed = JSON.parse(storageItem);
        setWith(storageItemParsed, [templateCode, docId ?? 'document'], keys, Object);
        localStorage.setItem(HIDDEN_COLUMNS_LOCAL_STORAGE_KEY, JSON.stringify(storageItemParsed));
    };

    const [hiddenColumnsKeys, setHiddenColumnsKeys] = useState<string[]>(
        hiddenColumnKeysInStorage,
    );

    useAfterEffect(() => {
        window.dispatchEvent(new Event(TABLE_SEARCH_NEW_HIDDEN_COLUMNS_SET_EVENT));
    }, [hiddenColumnsKeys]);

    const setHiddenColumns = (
        keys: string[],
    ) => {
        setHiddenColumnsKeys(keys);
        saveInStorage(keys);
    };

    const hideColumn = (key: string) => {
        setHiddenColumnsKeys(p => {
            const newColumns = uniq([...(p ?? []), key]);
            saveInStorage(newColumns);
            return newColumns;
        });
    };

    const showColumn = (key: string) => {
        setHiddenColumnsKeys(p => {
            const newColumns = p.filter(v => v !== key);
            saveInStorage(newColumns);
            return newColumns;
        });
    };

    useEffect(() => {
        const handler = ({detail}: any) => {
            setHiddenColumns(detail);
        };

        window.addEventListener(SET_TABLE_REPORT_HIDDEN_COLUMNS_EVENT, handler);
        return () => {
            window.removeEventListener(SET_TABLE_REPORT_HIDDEN_COLUMNS_EVENT, handler);
        };
    }, []);

    return {
        hiddenColumnsKeys,
        setHiddenColumns,
        hideColumn,
        showColumn,
    };
};
