import {Tooltip} from 'antd';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {ConfirmModal} from 'components/confirm-modal/confirm-modal';
import {SimpleActionButton} from 'components/form/components';
import {CustomSelect} from 'components/form/inputs/custom-select';
import {CustomSelectValueTriggerEvent} from 'components/form/inputs/custom-select/custom-select';
import {selectContextRawData} from 'modules/context/context-selector';
import {
    fetchDocumentsExecuteMonitoringData,
    fetchRequestListInfo,
} from 'modules/data/api/documents-upload-monitoring-api';
import {ExecuteMonitoringData} from 'modules/documents/documents-upload-monitoring-types';
import {showMessage, showMessageFromResponse} from 'shared/utils';
import {useAppSelector} from 'store/config/hooks';
import {selectDocumentUploadInfo} from 'store/slices/table-report-slice/table-report-download-documents/table-report-download-documents-selectors';
import {tableReportSliceActions} from 'store/slices/table-report-slice/table-report-slice';
import {selectTableReportTemplateConfig} from 'store/slices/table-report-slice/table-report-slice-selectors';

import {
    REPORT_GENERATION_PARAMETERS_CARD_MESSAGE,
    REPORT_GENERATION_PARAMETERS_CARD_TITLE,
} from './documents-download-button.constants';
import {updateTableReportPageRequestDto} from './documents-download-button.utils';

import './documents-download-button.less';

interface DocumentsDownloadButtonProps {
    title?: string;
    docId: string | null;
}

export const DocumentsDownloadButton: React.FC<DocumentsDownloadButtonProps> = ({
    title = 'Сформировать отчёт',
    docId,
}: DocumentsDownloadButtonProps) => {
    const dispatch = useDispatch();
    const [selectedDocExtension, setSelectedDocExtension] = useState<any>();
    const [selectedValue, setSelectedValue] = useState<string | null>(null);
    const [exportToOptions, setExportToOptions] = useState<any>([]);
    const [visible, setIsVisible] = useState(false);

    const templateConfig = useAppSelector(selectTableReportTemplateConfig);
    const contextRawData = useAppSelector(selectContextRawData);
    const documentUploadInfo = useAppSelector(selectDocumentUploadInfo);
    const {
        setReportDownloadsData, setModalReportDownloadDocuments, setReportDocUploadInfo, setReadReportDownloadDocuments,
    } = tableReportSliceActions;

    const data: ExecuteMonitoringData = {
        org_id: `${contextRawData?.organizationId}` || '',
        template_code: templateConfig?.templateCode || '',
        table_report_page_request_dto: JSON.stringify({
            page: documentUploadInfo?.reportRequestData?.page,
            size: documentUploadInfo?.reportRequestData?.size,
            sort: documentUploadInfo?.reportRequestData?.sort,
            includeCR: documentUploadInfo?.reportRequestData?.includeCR,
            parameters: documentUploadInfo?.reportRequestData?.parameters,
            includeComparison: documentUploadInfo?.reportRequestData?.includeComparison,
            DOC_ID: documentUploadInfo?.reportRequestData?.docId,
            DOC_ID_2: documentUploadInfo?.reportRequestData?.comparedDocId,
        }),
    };
    const createExecuteMonitoringData = (shouldBeFiltered: boolean) => {
        const tableReportPageRequestDto = JSON.parse(data.table_report_page_request_dto);
        const updateTableReportData = {
            ...tableReportPageRequestDto,
            filters: shouldBeFiltered ? documentUploadInfo?.reportRequestData?.filters : undefined,
        };
        setIsVisible(false);
        return updateTableReportPageRequestDto(data, updateTableReportData);
    };

    const handleUploadDocument = async (shouldBeFiltered : boolean) => {
        if (selectedDocExtension) {
            try {
                const monitoringData = await fetchDocumentsExecuteMonitoringData(
                    selectedDocExtension, createExecuteMonitoringData(shouldBeFiltered),
                );
                showMessageFromResponse({response: monitoringData});
            } catch (error) {
                showMessageFromResponse({response: error?.response, isError: true});
            }

            try {
                const requestListData = await fetchRequestListInfo();
                dispatch(setReportDownloadsData(requestListData.data));
                dispatch(setReadReportDownloadDocuments({wereDocumentsRead: false, isAnimationActive: true}));
                showMessage({
                    message: 'Программа запущена\n',
                    modalData: {
                        text: 'Посмотреть результат\n',
                        onClick: () => dispatch(
                            setModalReportDownloadDocuments({isOpen: true, wereDocumentsRead: true}),
                        ),
                    },
                });
            } catch (error) {
                showMessageFromResponse({response: error?.response, isError: true});
            }
        }
    };
    const onShowConfirm = () => {
        if (Object.keys(documentUploadInfo?.reportRequestData?.filters ?? {}).length) {
            setIsVisible(true);
            return;
        }
        handleUploadDocument(false);
    };

    useEffect(() => {
        const options = templateConfig?.exportTo
            ?.filter(exportToItem => !!exportToItem.enabled)
            .map(exportToItem => ({
                label: exportToItem.format,
                value: exportToItem.monitorProgramCode,
                ...exportToItem,
            }));
        const firstValue = options?.length ? options[0] : null;
        setExportToOptions(options);
        setSelectedValue(firstValue?.value || null);
        setSelectedDocExtension(firstValue?.monitorProgramId || null);
    }, [templateConfig]);

    return (
        <div className="documents-download-button">
            <Tooltip
                title={!exportToOptions?.length ? 'Форматы выгрузки не найдены' : undefined}
                placement="bottom"
            >
                <div>
                    <SimpleActionButton
                        type="primary"
                        onClick={onShowConfirm}
                        title={title}
                        icon="DownloadOutlined"
                        style={exportToOptions?.length ? {borderRadius: '5px 0 0 5px', borderRight: 0} : undefined}
                        disabled={!selectedValue}
                    />
                </div>
            </Tooltip>

            {!!exportToOptions?.length && (
                <CustomSelect
                    entries={exportToOptions}
                    handleTriggerEvent={(val, event) => {
                        if (val && event === CustomSelectValueTriggerEvent.valueHasBeenChangedManually) {
                            const selectedExportTo = templateConfig?.exportTo
                                ?.find(exp => exp.monitorProgramCode === val);
                            setSelectedDocExtension(selectedExportTo?.monitorProgramId);
                            setSelectedValue(val);
                            dispatch(setReportDocUploadInfo({
                                docExtensionId: selectedExportTo?.monitorProgramId,
                                docId,
                            }));
                        }
                    }}
                    value={selectedValue}
                />
            )}

            <ConfirmModal
                visible={visible}
                onConfirm={() => handleUploadDocument(true)}
                onCancelConfirm={() => handleUploadDocument(false)}
                onCancel={() => setIsVisible(false)}
                content={REPORT_GENERATION_PARAMETERS_CARD_MESSAGE}
                title={REPORT_GENERATION_PARAMETERS_CARD_TITLE}
                cancelText="Нет"
                approveText="Да"
            />
        </div>
    );
};
