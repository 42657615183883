import {DocumentUploadScan} from 'modules/documents/documents-types';

import {SCAN_UPLOAD_UID} from './button-upload-scan-files-constants';

export const updateFileInScanFilesList = (
    scans: DocumentUploadScan[],
    uploadId: string,
    data: Partial<DocumentUploadScan>,
) => scans.map(uploadedFile => {
    if (uploadedFile[SCAN_UPLOAD_UID] === uploadId) {
        return {
            ...uploadedFile,
            ...data,
        };
    }
    return uploadedFile;
});
