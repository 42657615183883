import {performRequest} from 'shared/utils';

import {ReportConfigurationDataDto} from '../report-configuration-dto';

export type GetTableReportConfigurationDataRequestArgs = {
    templateCode: string;
}
export const getTableReportConfigurationData = async (args: GetTableReportConfigurationDataRequestArgs) => {
    const {data: templateConfigurationData} = await performRequest<Partial<ReportConfigurationDataDto>>({
        url: `table-reports/${args.templateCode}`,
        method: 'GET',
    });
    return templateConfigurationData;
};

export type GetTableReportConfigurationFieldDataArgs = {
    fieldName: keyof ReportConfigurationDataDto;
} & GetTableReportConfigurationDataRequestArgs;

export const getTableReportConfigurationFieldData = async (args: GetTableReportConfigurationFieldDataArgs) => {
    const {fieldName, templateCode} = args;

    const {data: templateConfigurationData} = await performRequest<Partial<ReportConfigurationDataDto>>({
        url: `table-reports/${templateCode}/field/${fieldName}`,
        method: 'GET',
    });
    return templateConfigurationData;
};

export type GetTableReportConfigurationFieldsDataArgs = {
    fieldNames?: (keyof ReportConfigurationDataDto)[];
} & GetTableReportConfigurationDataRequestArgs;

export const getTableReportConfigurationFieldsData = async (args: GetTableReportConfigurationFieldsDataArgs) => {
    const {templateCode, fieldNames = []} = args;

    const {data: templateConfigurationData} = await performRequest<Partial<ReportConfigurationDataDto>>({
        url: `table-reports/${templateCode}/fields`,
        method: 'POST',
        data: {
            fieldNameList: fieldNames,
        },
    });
    return templateConfigurationData;
};

export type PatchTableReportConfigurationArgs = {
    templateCode: string;
    data: Partial<ReportConfigurationDataDto>;
}

export const patchTableReportConfiguration = async (args: PatchTableReportConfigurationArgs) => {
    const {templateCode, data} = args;

    const response = await performRequest<string>({
        url: `table-reports/${templateCode}`,
        method: 'PATCH',
        data,
    });
    return response;
};

export type PostTableReportConfigurationArgs = Omit<PatchTableReportConfigurationArgs, 'templateCode'>;
export const postTableReportConfiguration = async (args: PostTableReportConfigurationArgs) => {
    const {data} = args;

    const response = await performRequest<string>({
        url: 'table-reports',
        method: 'POST',
        data,
    });
    return response;
};

export type DeleteTableReportConfigurationArgs = {templateCode: string};
export const deleteTableReportConfiguration = async ({
    templateCode,
}: DeleteTableReportConfigurationArgs) => {
    const response = await performRequest<string>({
        url: `table-reports/${templateCode}`,
        method: 'DELETE',
    });
    return response;
};
