import {Optional} from 'shared/types/generics';
import {LookupEntry} from 'shared/types/lookups';
import {
    ReportConfigurationDocumentFormat,
    ReportConfigurationExportToDto,
} from 'store/slices/report-configuration-slice/report-configuration-dto';

export const getExportDataValues = (
    formatOptions: LookupEntry[] = [],
    programOptions: LookupEntry[] = [],
) => formatOptions?.map(format => {
    const program = programOptions
        .find(option => option?.lookupCode === format.attribute1);

    return ({
        format: format.lookupCode as ReportConfigurationDocumentFormat,
        monitorProgramCode: program?.lookupCode,
        enabled: true,
    } as Optional<ReportConfigurationExportToDto, 'monitorProgramCode' | 'monitorProgramId'>);
});

export const initialExportToData: Optional<ReportConfigurationExportToDto,
    'monitorProgramCode' | 'monitorProgramId'>[] = [
        {
            format: 'PDF',
            monitorProgramCode: undefined,
            enabled: false,
        },
        {
            format: 'DOCX',
            monitorProgramCode: undefined,
            enabled: false,
        },
        {
            format: 'XLSX',
            monitorProgramCode: undefined,
            enabled: false,
        },
        {
            format: 'CSV',
            monitorProgramCode: undefined,
            enabled: false,
        },
    ];
