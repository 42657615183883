import React from 'react';
import {useDispatch} from 'react-redux';

import {selectContextRawData} from 'modules/context/context-selector';
import {resetData} from 'modules/data/data-actions';
import {RequestType} from 'modules/metadata';
import {EntityType} from 'shared/constants/entities';
import {performRequest, showMessageFromResponse} from 'shared/utils';
import {useAppSelector} from 'store/config/hooks';
import {normalizeEntityName} from 'utils/common';

import {ActionButton} from '../action-button';
import {TableActionProps} from '../table-action-types';

const ButtonActionCreateComponent: React.FunctionComponent<TableActionProps> = ({
    wrapper,
    meta,
    entityName,
    ...props
}: TableActionProps) => {
    const dispatch = useDispatch();
    const contextData = useAppSelector(selectContextRawData);

    const handleClick = () => {
        (async () => {
            try {
                if (meta?.referenceUrl) {
                    const response = await performRequest({
                        method: meta.requestType || RequestType.POST,
                        url: meta?.referenceUrl,
                        params: {...contextData},
                    });
                    if (response.status === 200) {
                        showMessageFromResponse({response, isError: false});
                        [EntityType.TABLE, EntityType.FILTER].map(entityType => dispatch(resetData({
                            entityType,
                            entityName: normalizeEntityName(entityName),
                            loading: false,
                        })));
                    }
                }
            } catch (e) {
                showMessageFromResponse({response: e.response, isError: true});
            }
        })();
    };
    const Component = wrapper || ActionButton;
    return (
        <Component
            {...props}
            onClick={handleClick}
            actionIcon={meta.actionIcon}
        >
            {meta.actionTitle}
        </Component>
    );
};

export const ButtonActionCreate = ButtonActionCreateComponent;
