import {
    Button, Input, Upload,
} from 'antd';
import {FormInstance} from 'antd/es/form';
import {RcFile, UploadFile, UploadProps} from 'antd/es/upload/interface';
import React from 'react';
import {useSelector} from 'react-redux';

import {ActionConfirmModal} from 'components/table/action/action-confirm-modal/action-confirm-modal';
import {
    DEFAULT_DELETE_ELEMENT_CONFIRM_TEXT,
} from 'components/table/action/button-action-delete-tab/constants/button-delete-tab-constants';
import {deleteFile} from 'components/table/columns/file/file-column-utils';
import {DATA_MODULE} from 'modules/data';
import {FieldMeta, IAdditionalOptions} from 'modules/metadata/metadata-types';
import {ReactComponent as CloseX} from 'shared/assets/forms/delete-x.svg';
import {ReactComponent as File} from 'shared/assets/forms/file.svg';
import {EntityType} from 'shared/constants/entities';
import {CommonMessages} from 'shared/constants/messages';
import {downloadFile, showMessageFromResponse} from 'shared/utils';
import {AppState} from 'store/config/types';

import {FileFieldInfo} from './file-field-info';
import {generateDeleteLink, generateDownloadLink} from './upload-file-field-utils';

import './upload-file-field.less';

export interface FileFieldProps {
    onChange?: (value: any) => void;
    fieldKey: string;
    form?: FormInstance;
    entityName: string;
    fieldType: string;
    buttonText?: string;
    shouldFastUpdate?: boolean;
    accept?: string;
    fieldMeta: FieldMeta;
    parentEntityName: string;
    dependentsFieldKey: string;
    additionalOptions?: IAdditionalOptions;
}

/**
 * Поле реализовано на основе текущих нужд серверной части
 */

export const UploadFileField: React.FunctionComponent<FileFieldProps> = (
    {
        onChange,
        buttonText = 'Выбрать Файл',
        accept,
        dependentsFieldKey,
        fieldMeta,
        entityName,
        ...props
    }: FileFieldProps,
) => {
    const [fileList, setFileList] = React.useState<UploadFile<Blob>[]>([]);
    const linkDownload = generateDownloadLink(fieldMeta.key, fieldMeta.referenceUrl);
    const linkDelete = generateDeleteLink(fieldMeta.key, fieldMeta.referenceUrl);
    const [infoField, setInfoField] = React.useState<any>(
        useSelector(
            (state: AppState) => state[DATA_MODULE][entityName]?.[EntityType.FORM]?.data?.[dependentsFieldKey],
        ),
    );
    const handleChange: UploadProps['onChange'] = info => {
        const newFileList = [...info.fileList];
        if (newFileList.length > 1) newFileList.shift();
        if (onChange) onChange(newFileList[0]?.originFileObj);
        setFileList(newFileList);
    };
    const beforeUpload = (file: UploadFile<Blob>) => {
        const formData = new FormData();
        formData.append('file', file as RcFile);
        return false;
    };
    const onFileDownload = () => {
        downloadFile(linkDownload, infoField?.fileName).then(data => {
            if (data.status === 200) {
                showMessageFromResponse({
                    response: data,
                    isError: false,
                    customMessage: CommonMessages.UPLOAD_FILE_SUCCESS,
                });
            }
        }).catch(error => {
            showMessageFromResponse({response: error?.response, isError: true});
        });
    };
    const onFileDelete = () => {
        deleteFile(linkDelete).then(data => {
            if (data.status === 200) {
                showMessageFromResponse({response: data, isError: false});
                setInfoField(null);
            }
        }).catch(error => {
            showMessageFromResponse({response: error?.response, isError: true});
        });
    };

    const showFilePath = fileList.length ? fileList[0].name : '';

    return (
        infoField ? (
            <ActionConfirmModal
                cancelText="Отмена"
                approveText="Удалить"
                modalTitle="Удаление"
                confirmModalContent={DEFAULT_DELETE_ELEMENT_CONFIRM_TEXT}
                confirmAction={onFileDelete}
            >
                {handleClick => (
                    <FileFieldInfo
                        onFileDelete={handleClick}
                        onFileDownload={onFileDownload}
                        infoField={infoField}
                    />
                )}

            </ActionConfirmModal>
        ) : (
            <Upload
                {...props}
                accept={accept}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                showUploadList={false}
                fileList={fileList}
                className="upload-field"
            >
                <div className="upload-field-wrapper">
                    <Input
                        disabled
                        prefix={<File />}
                        className="ant-input--soft-disabled"
                        suffix={showFilePath && (
                            <CloseX
                                className="upload-field__clear-icon"
                                onClick={() => setFileList([])}
                            />
                        )}
                        value={showFilePath || 'Файл не прикреплен'}
                    />
                    <Button className="upload-field__btn">
                        {buttonText}
                    </Button>
                </div>
            </Upload>
        )
    );
};
