import {Drawer, Form as AntForm} from 'antd';
import React, {useContext} from 'react';

import {Entity} from 'modules/data';
import {createActionHandlerForTableChange, initializeMetadataAndData} from 'modules/data/data-actions';
import {selectFilterEntityData} from 'modules/data/data-selectors';
import {setMetadata} from 'modules/metadata/metadata-actions';
import {selectMetadata, selectTableEntityMetadata} from 'modules/metadata/metadata-selectors';
import {ReactComponent as CloseIcon} from 'shared/assets/close.svg';
import {EntityType} from 'shared/constants/entities';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {selectModalData, ModalComponentInterface} from 'store/slices/modals-slice';

import {Buttons} from '../form/buttons';
import {Fields} from '../form/fields/fields';
import {disabledFields} from '../table/action/button-action-edit-field/button-action-edit-fields';
import {generateActionsForFilter} from '../table/action/filters/integrated-filter/integrated-filter-utils';
import {TableModeContext} from '../table/context';

import './filter-drawer.less';

export const FilterDrawer: React.FunctionComponent<ModalComponentInterface> = ({
    onClose,
}: ModalComponentInterface) => {
    const {entityName = ''} = useAppSelector(selectModalData);
    const [form] = AntForm.useForm();
    const dispatch = useAppDispatch();
    const fieldsMeta = useAppSelector(selectTableEntityMetadata(entityName));
    const metadata = useAppSelector(selectMetadata(entityName, EntityType.FILTER));
    const filterSlice = useAppSelector(selectFilterEntityData(entityName));
    const {resetTableMode} = useContext(TableModeContext);

    const newMetaData = disabledFields(fieldsMeta, true);
    React.useEffect(() => {
        (async () => {
            await dispatch(initializeMetadataAndData(EntityType.FILTER)(entityName));
            form.setFieldsValue(filterSlice?.data);
        })();
    }, [filterSlice?.data]);

    const handleValuesChanges = (values: Entity) => {
        Object.entries(values).forEach(value => {
            if (!value[1]) {
                form.setFieldsValue({[value[0]]: null});
            }
            form.setFieldsValue(value);
        });
    };

    const onHandleFinish = () => {
        dispatch(createActionHandlerForTableChange(EntityType.FILTER)(
            entityName,
            form.getFieldsValue(),
            undefined, undefined,
            metadata?.prefix,
            metadata?.disabledPagination,
        )).then(() => {
            if (metadata?.checkForDisabledMetaFields) {
                dispatch(setMetadata({
                    entityName,
                    entityType: EntityType.TABLE,
                    metadata: newMetaData,
                }));
            }
            resetTableMode();
        });
        onClose?.();
    };

    const defaultFilterActionsMeta = generateActionsForFilter(metadata?.name);

    return (
        <Drawer
            title={metadata?.title}
            visible
            width={416}
            onClose={onClose}
            className="filter-drawer"
            closeIcon={<CloseIcon className="close-icon" />}
        >
            <AntForm
                layout="vertical"
                form={form}
                onValuesChange={handleValuesChanges}
                onFinish={onHandleFinish}
            >
                <Fields
                    entityName={entityName}
                    list={metadata?.fields}
                />
                <div className="drawer-footer">
                    <Buttons
                        actions={defaultFilterActionsMeta}
                        onClose={onClose}
                    />
                </div>
            </AntForm>
        </Drawer>
    );
};
