const extractNameAndIconForHref = (value: string) => {
    const hreg = /^(.*);;;(.*);;;(.*)$/;
    const result = hreg.exec(value);
    if (result && result?.length >= 4) {
        const rl = result.length;
        return {
            text: result[rl - 3],
            icon: result[rl - 2],
            href: result[rl - 1],
        };
    }
    return null;
};

const extractNameForHref = (value: string) => {
    const hreg = /^(.*);;;(.*)$/;
    const result = hreg.exec(value);
    if (result && result?.length >= 3) {
        const rl = result.length;
        return {
            text: result[rl - 2],
            href: result[rl - 1],
        };
    }
    return null;
};

interface ReportColumnURLInfo {
    text: string;
    href?: string;
    icon?: string;
}

export const extractUrlInfo = (rawValue: string | undefined) => {
    if (rawValue === undefined || rawValue === null) return undefined;

    const urlInfo: ReportColumnURLInfo = (() => {
        const iconAndNameExtraction = extractNameAndIconForHref(rawValue);
        if (iconAndNameExtraction) return iconAndNameExtraction;

        const nameExtraction = extractNameForHref(rawValue);
        if (nameExtraction) return nameExtraction;

        if (rawValue.startsWith('http')) {
            return {text: '', href: rawValue, icon: 'Document'};
        }

        return {text: rawValue};
    })();

    return urlInfo;
};
