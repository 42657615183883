import {History, Location, To} from 'history';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {HistoryLocationState} from './use-app-history-types';

type AppLocation = Omit<Location, 'state'> & {
    state: HistoryLocationState;
};

type HistoryListener = (v: AppLocation) => void;

// не весь тип приведён в соответствие, дописать при необходимости
type AppHistory = Omit<History, 'location' | 'listen'> & {
    location: AppLocation;
    listen: (listener: HistoryListener) => Function;
};

export const useAppHistory = () => {
    const history: AppHistory = useHistory();

    const getLocationState = () => history.location.state;

    const [currentState, setCurrentState] = useState<HistoryLocationState>(getLocationState());

    const getPathname = (withoutSearchParams = false) => {
        if (withoutSearchParams) return history.location.pathname;
        const searchParams = new URLSearchParams(history.location.search);
        const pathname = Array.from(searchParams).length
            ? `${history.location.pathname}?${searchParams.toString()}`
            : history.location.pathname;
        return pathname;
    };

    useEffect(() => {
        const unlisten = history.listen((v: AppLocation) => {
            setCurrentState(v?.state);
        });
        return () => {
            unlisten();
        };
    }, []);

    const getCurrentStateValue = <T extends keyof HistoryLocationState>(key: T) => currentState?.[key];
    const setLocationState = (state: HistoryLocationState, resetSearchParams = false) => {
        history.replace(getPathname(resetSearchParams), state);
    };
    const getLocationStateValue = <T extends keyof HistoryLocationState>(key: T) => getLocationState()?.[key];
    const setLocationStateValue = <T extends keyof HistoryLocationState>(
        key: T, value: HistoryLocationState[T], resetSearchParams = false) => {
        history.replace(getPathname(resetSearchParams), {
            ...getLocationState(),
            [key]: value,
        });
    };
    const updateLocationState = (state: Partial<HistoryLocationState>, resetSearchParams = false) => {
        history.replace(getPathname(resetSearchParams), {
            ...getLocationState(),
            ...state,
        });
    };
    const pushWithStateUpdate = (to: To, state: Partial<HistoryLocationState>) => {
        history.push(to, {
            ...getLocationState(),
            ...state,
        });
    };

    return {
        ...history,
        currentState,
        getCurrentStateValue,
        getLocationState,
        setLocationState, // (!) заменяет всё состояние
        getLocationStateValue,
        setLocationStateValue,
        updateLocationState,
        pushWithStateUpdate,
    };
};

export type UseAppHistoryFunction = ReturnType<typeof useAppHistory>;
