import React, {useImperativeHandle, useRef} from 'react';

import {ModalOpenerComponent, ModalOpenerComponentRef} from 'components/modal-opener-component';
import {AisRegisterDocumentDto} from 'store/slices/ais-slice/ais-slice-types';

import {PudUploadModalMode, usePudUpload} from '../../hooks/use-pud-upload/use-pud-upload';

export interface PUDModalProps {
    registerDocument: AisRegisterDocumentDto;
}

export const PUDModal = React.forwardRef<any, PUDModalProps>(({
    registerDocument,
}: PUDModalProps, ref) => {
    const PUDModalRef = useRef<ModalOpenerComponentRef>(null);

    useImperativeHandle(ref, () => PUDModalRef.current);

    const {
        form,
        setArchiveSystem,
        PUDUploadFormJSX,
        isUploadingPUD,
    } = usePudUpload({
        registerDocument,
        PUDModalRef,
        mode: PudUploadModalMode.inModal,
    });

    return (
        <ModalOpenerComponent
            ref={PUDModalRef}
            controlLabels={{
                cancel: 'Закрыть',
            }}
            handleSave={() => {
                form.submit();
            }}
            disabledControls={{
                save: isUploadingPUD,
            }}
            loadingControls={{
                save: isUploadingPUD,
            }}
            modalProps={{
                title: 'Размещение ПУД',
                width: 600,
                centered: true,
                forceRender: false,
                destroyOnClose: true,
            }}
            afterModalClose={() => {
                setArchiveSystem(undefined);
                form.resetFields();
            }}
        >
            {PUDUploadFormJSX}
        </ModalOpenerComponent>
    );
});
