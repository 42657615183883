import React from 'react';

import {ReferenceParamKey} from 'modules/metadata/metadata-types';

import {
    useCustomSelectReferenceOptionsFetcher,
} from '../custom-select/hooks/use-custom-select-reference-options-fetcher/use-custom-select-reference-options-fetcher';
import {StaticSelect, StaticSelectProps} from '../static-select/static-select';
import {getReferenceURLQueryParamsFromForm} from './utils/string-select.utils';

export interface StringSelectProps extends StaticSelectProps {
    referenceUrl?: string;
    referenceParamsKeys?: ReferenceParamKey[];
}

export const StringSelect = ({
    referenceUrl, referenceParamsKeys, form, ...props
}: StringSelectProps) => {
    const url = (() => {
        const querySuffix = new URLSearchParams(
            getReferenceURLQueryParamsFromForm(form, referenceParamsKeys),
        ).toString();
        return querySuffix ? `${referenceUrl}?${querySuffix}` : referenceUrl;
    })();

    const {entriesFromFetcher} = useCustomSelectReferenceOptionsFetcher({referenceUrl: url});

    return (
        <StaticSelect
            {...props}
            form={form}
            options={entriesFromFetcher}
        />
    );
};
