import {RightOutlined} from '@ant-design/icons';
import cn from 'classnames';
import React from 'react';

interface NewSideMenuExpandProps {
    onClick?: () => void;
    isOpen?: boolean;
}

export const NewSideMenuExpand: React.FC<NewSideMenuExpandProps> = (
    {onClick, isOpen}: NewSideMenuExpandProps,
) => (
    <div
        className={cn('new-side-menu__expand', {
            'new-side-menu__expand_open': isOpen,
        })}
        onClick={() => {
            onClick?.();
        }}
    >
        <RightOutlined />
    </div>
);
