import {isUndefined, omitBy} from 'lodash';

import {
    convertPropertiesFormDataToRequestData,
} from 'components/report-configuration/tabs/report-properties-settings/utils';
import {convertArrayToMap, convertMapToArray} from 'modules/data/utils/data.utils';

import {ReportConfigurationDataDto} from '../report-configuration-dto';
import {ReportConfigurationConvertedMenuItem, ReportConfigurationDataConverted} from '../report-configuration-types';

export type ConvertConfigurationDataFromResponseArgs = {
    dataToConvert: Partial<ReportConfigurationDataDto>;
}

const convertReportMenu = <T=any>(
    reportMenu: any[] | undefined,
    convertFunction: Function,
) => {
    if (!reportMenu) return reportMenu;
    const convert: (
        menu: any[]) => T[] = menu => {
            const menuConverted = menu?.map(menuItem => {
                const reportColumns = menuItem.reportColumns
                    ? convertFunction(menuItem.reportColumns) : undefined;
                const {children} = menuItem;
                return {
                    ...menuItem,
                    reportColumns,
                    children: children ? convert(children) : undefined,
                };
            });
            return menuConverted as T[];
        };
    return convert(reportMenu);
};

export const convertConfigurationDataFromResponse = ({
    dataToConvert,
}: ConvertConfigurationDataFromResponseArgs) => {
    const {
        reportHeaders,
        reportColumns,
        reportMenu,
        reportGroups,
        queryParameters,
        ...restConfigurationParams
    } = dataToConvert;
    const configurationDataConverted = {
        ...restConfigurationParams,
        reportGroups: convertMapToArray(reportGroups),
        reportHeaders: convertMapToArray(reportHeaders),
        reportColumns: convertMapToArray(reportColumns),
        queryParameters: convertMapToArray(queryParameters),
        reportMenu: convertReportMenu<
            ReportConfigurationConvertedMenuItem>(reportMenu, convertMapToArray), // рекурсивная конвертация
    };
    const configurationDataNoUndefined = omitBy(configurationDataConverted, v => isUndefined(v));

    return configurationDataNoUndefined as ReportConfigurationDataConverted;
};

export type ConvertConfigurationDataForRequest = {
    convertedData: Partial<ReportConfigurationDataConverted>;
}

export const convertConfigurationDataForRequest = ({
    convertedData,
}: ConvertConfigurationDataForRequest) => {
    const {
        reportHeaders,
        reportColumns,
        reportMenu,
        reportGroups,
        queryParameters,
        ...restConfigurationParams
    } = convertedData;

    const configurationDataConverted = {
        ...restConfigurationParams,
        reportGroups: convertArrayToMap(reportGroups),
        reportHeaders: convertArrayToMap(reportHeaders),
        reportColumns: convertArrayToMap(reportColumns),
        queryParameters: convertPropertiesFormDataToRequestData(queryParameters),
        reportMenu: convertReportMenu<any>(reportMenu, convertArrayToMap),
    };

    const configurationDataNoUndefined = omitBy(configurationDataConverted, v => isUndefined(v));
    return configurationDataNoUndefined as Partial<ReportConfigurationDataDto>;
};
