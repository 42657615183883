import {createSelector} from '@reduxjs/toolkit';

import {FilterValueToDffSegmentMapping, FilterValueType} from 'components/report-configuration/report-configuration.constants';
import {AppState} from 'store/config/types';

import {reportConfigurationSliceName} from '../report-configuration-constants';

const selectReportConfigurationSliceState = (state: AppState) => state[reportConfigurationSliceName];

export const selectTableReportDdLookups = (state: AppState) => selectReportConfigurationSliceState(
    state,
).ddLookups;

export const selectTableReportConfigurationFilterConditions = createSelector(
    selectTableReportDdLookups, lookups => lookups.filters,
);

export const selectTableReportTableColumnsDdLookup = createSelector(
    selectTableReportDdLookups,
    ddLookups => ddLookups.tableColumns,
);

export const selectReportTableColumnsByTemplateCode = createSelector(
    [
        selectTableReportDdLookups,
        (_, templateCode: string) => templateCode,
    ], (ddLookups, templateCode) => ddLookups.tableColumns[templateCode],
);

interface SelectReportTableColumnsByTemplateCodeFilteredByTypeArg {
    fieldType: FilterValueType;
}

export const selectTableReportConfigurationFiltersByFieldType = createSelector(
    [
        selectTableReportDdLookups,
        (_, arg: SelectReportTableColumnsByTemplateCodeFilteredByTypeArg) => arg,
    ], (ddLookups, {fieldType}) => {
        const {filters: filterConditions} = ddLookups ?? {};

        const filterConditionsFiltered = filterConditions?.filter(filterConditon => {
            const dffSegmentValue = FilterValueToDffSegmentMapping[fieldType];
            if (filterConditon?.dffSegmentValues?.[dffSegmentValue]?.segmentValue !== 'Y') return false;
            return true;
        });

        return filterConditionsFiltered;
    },
);
