import {
    DependencyList,
    EffectCallback,
    useEffect,
    useRef,
} from 'react';

export const useAfterEffect = (effect: EffectCallback, deps?: DependencyList | undefined) => {
    const firstTimeUseRef = useRef(false);

    useEffect(!firstTimeUseRef.current ? () => {
        firstTimeUseRef.current = true;
    } : effect, deps);
};
