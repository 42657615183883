import {PlusCircleOutlined} from '@ant-design/icons';
import {Button, Empty, Form} from 'antd';
import {useForm} from 'antd/lib/form/Form';
import cn from 'classnames';
import {pick} from 'lodash';
import React, {useContext, useEffect, useState} from 'react';

import {setFieldsValueUntouched} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {
    selectTableReportConfigurationData,
} from 'store/slices/report-configuration-slice/report-configuration-slice';
import {
    updateTableReportConfiguration,
} from 'store/slices/report-configuration-slice/report-configuration/report-configuration-thunks';

import {WatcherFieldFlag} from '../../hooks/use-form-fields-manager';
import {useFormListFieldsManager} from '../../hooks/use-form-list-fields-manager';
import {ReportConfigurationContext} from '../../report-configuration.context';
import {
    REPORT_PROPERTIES_SETTINGS_FORM_DATA_KEY,
    ReportPropertiesSettingsDefaultValueFields,
    ReportPropertiesSettingsDto,
    ReportPropertiesSettingsFormFields,
    ReportPropertySetting,
} from './constants/report-properties-settings';
import {ReportPropertiesSettingsButtons} from './properties-settings-buttons';
import {ReportPropertiesSettingFields} from './properties-settings-fields/report-properties-setting-fields';
import {getDataFromReportPropertiesSettingsRequest} from './utils/report-properties-settings.utils';

import './report-properties-settings.less';

export interface ReportPropertiesFormState {
    initialValues: Record<string, any>;
    isEditingForm: boolean;
    isDirty: boolean;
}

export interface ReportPropertiesProps {}

export const ReportPropertiesSettings: React.FC<ReportPropertiesProps> = () => {
    const [form] = useForm<ReportPropertiesSettingsDto>();
    const [formState, setFormState] = useState({initialValues: {}, isEditingForm: false, isDirty: false});
    const {
        templateCode,
    } = useContext(ReportConfigurationContext);

    const dispatch = useAppDispatch();

    const reportConfigurationData = useAppSelector(
        selectTableReportConfigurationData,
    );

    const {tableDatasourceType} = reportConfigurationData ?? {};

    const disabledByEditingModeCondition = {
        condition: () => !formState.isEditingForm,
        flag: WatcherFieldFlag.isDisabled,
    };

    const {
        applyFieldWatchers,
        checkWatcherFlag,
        checkArrayIndependentWatcherFlag,
    } = useFormListFieldsManager<'queryParameters',
        Record<ReportPropertiesSettingsFormFields, string | boolean>,
        ReportPropertiesSettingsDto
    >({
        formInstance: form,
        watchers: {
            [ReportPropertiesSettingsFormFields.paramName]: [
                disabledByEditingModeCondition, {
                    condition: () => tableDatasourceType === 'TABLE',
                    flag: WatcherFieldFlag.isHidden,
                },
                {
                    condition: () => ['FUNCTION', 'SQL'].includes(tableDatasourceType as string),
                    flag: WatcherFieldFlag.isRequired,
                }],
            [ReportPropertiesSettingsFormFields.columnName]: [
                disabledByEditingModeCondition, {
                    condition: () => tableDatasourceType === 'TABLE',
                    flag: WatcherFieldFlag.isRequired,
                }, {
                    condition: () => ['FUNCTION', 'SQL', undefined]
                        .includes(tableDatasourceType as string),
                    flag: WatcherFieldFlag.isHidden,
                }],
            [ReportPropertiesSettingsFormFields.valuesetLookupType]: [
                disabledByEditingModeCondition,
                {
                    condition: ({formValues}) => !(['VALUESET', 'MULTIVALUESET']
                        .includes(formValues?.[ReportPropertiesSettingsFormFields.type]))
                        || (formValues?.[ReportPropertiesSettingsFormFields.defaultType] !== 'CONST'
                            && formValues?.[ReportPropertiesSettingsFormFields.defaultType] !== 'UNSET'),
                    flag: WatcherFieldFlag.isHidden,
                },
            ],
            [ReportPropertiesSettingsFormFields.sqlValueset]: [
                disabledByEditingModeCondition,
                {
                    condition: ({formValues}) => !((formValues
                        ?.[ReportPropertiesSettingsFormFields.type] === 'SQLVALUESET' || formValues
                        ?.[ReportPropertiesSettingsFormFields.type] === 'SQLMULTIVALUESET')
                        && ['UNSET', 'CONST', undefined].includes(formValues
                            ?.[ReportPropertiesSettingsFormFields.defaultType])),
                    flag: WatcherFieldFlag.isHidden,
                },
            ],
            [ReportPropertiesSettingsFormFields.defaultValueSqlValueSet]: [
                disabledByEditingModeCondition,
                {
                    condition: ({formValues}) => !((formValues
                        ?.[ReportPropertiesSettingsFormFields.type] === 'SQLVALUESET' || formValues
                        ?.[ReportPropertiesSettingsFormFields.type] === 'SQLMULTIVALUESET')
                        && formValues?.[ReportPropertiesSettingsFormFields.defaultType] === 'CONST'
                    ),
                    flag: WatcherFieldFlag.isHidden,
                },
            ],
            [ReportPropertiesSettingsFormFields.defaultValueContext]: [
                disabledByEditingModeCondition,
                {
                    condition: (
                        {formValues},
                    ) => formValues?.[ReportPropertiesSettingsFormFields.defaultType] !== 'CONTEXT',
                    flag: WatcherFieldFlag.isHidden,
                },
            ],
            [ReportPropertiesSettingsFormFields.defaultValueDate]: [
                disabledByEditingModeCondition,
                {
                    condition: ({formValues}) => formValues?.[ReportPropertiesSettingsFormFields.type] !== 'DATE'
                        || formValues?.[ReportPropertiesSettingsFormFields.defaultType] !== 'CONST',
                    flag: WatcherFieldFlag.isHidden,
                },
            ],
            [ReportPropertiesSettingsFormFields.defaultValueDateTime]: [
                disabledByEditingModeCondition,
                {
                    condition: ({formValues}) => formValues?.[ReportPropertiesSettingsFormFields.type] !== 'DATETIME'
                        || formValues?.[ReportPropertiesSettingsFormFields.defaultType] !== 'CONST',
                    flag: WatcherFieldFlag.isHidden,
                },
            ],
            [ReportPropertiesSettingsFormFields.defaultValueInteger]: [
                disabledByEditingModeCondition,
                {
                    condition: ({formValues}) => (formValues?.[ReportPropertiesSettingsFormFields.type] !== 'INTEGER'
                            && formValues?.[ReportPropertiesSettingsFormFields.type] !== 'NUMBER')
                        || formValues?.[ReportPropertiesSettingsFormFields.defaultType] !== 'CONST',
                    flag: WatcherFieldFlag.isHidden,
                },
            ],
            [ReportPropertiesSettingsFormFields.defaultValueBoolean]: [
                disabledByEditingModeCondition,
                {
                    condition: ({formValues}) => formValues?.[ReportPropertiesSettingsFormFields.type] !== 'BOOLEAN'
                        || formValues?.[ReportPropertiesSettingsFormFields.defaultType] !== 'CONST',
                    flag: WatcherFieldFlag.isHidden,
                },
            ],
            [ReportPropertiesSettingsFormFields.defaultValueString]: [
                disabledByEditingModeCondition,
                {
                    condition: ({formValues}) => formValues?.[ReportPropertiesSettingsFormFields.type] !== 'STRING'
                        || formValues?.[ReportPropertiesSettingsFormFields.defaultType] !== 'CONST',
                    flag: WatcherFieldFlag.isHidden,
                },
            ],
            [ReportPropertiesSettingsFormFields.defaultValueValueSet]: [
                disabledByEditingModeCondition,
                {
                    condition: ({formValues}) => formValues?.[ReportPropertiesSettingsFormFields.type] !== 'VALUESET'
                        || formValues?.[ReportPropertiesSettingsFormFields.defaultType] !== 'CONST',
                    flag: WatcherFieldFlag.isHidden,
                },
            ],
            [ReportPropertiesSettingsFormFields.defaultValueMultiValueSet]: [
                disabledByEditingModeCondition,
                {
                    condition: (
                        {formValues},
                    ) => formValues?.[ReportPropertiesSettingsFormFields.type] !== 'MULTIVALUESET'
                        || formValues?.[ReportPropertiesSettingsFormFields.defaultType] !== 'CONST',
                    flag: WatcherFieldFlag.isHidden,
                },
            ],
            [ReportPropertiesSettingsFormFields.format]: [
                disabledByEditingModeCondition,
                {
                    condition: ({formValues}) => !['DATE', 'DATETIME', 'NUMBER', 'INTEGER']
                        .includes(formValues?.[ReportPropertiesSettingsFormFields.type]),
                    flag: WatcherFieldFlag.isHidden,
                },
            ],
            [ReportPropertiesSettingsFormFields.defaultType]: [
                disabledByEditingModeCondition,
                {
                    condition: ({formValues}) => !['DATE', 'DATETIME']
                        .includes(formValues?.[ReportPropertiesSettingsFormFields.type]),
                    flag: WatcherFieldFlag.shouldFilterLookup,
                },
            ],
            ...(() => Object.fromEntries([
                ...Object.values(pick(
                    ReportPropertiesSettingsFormFields, [
                        ReportPropertiesSettingsFormFields.type,
                        ReportPropertiesSettingsFormFields.visible,
                        ReportPropertiesSettingsFormFields.required,
                        ReportPropertiesSettingsFormFields.reportTitle,
                        ReportPropertiesSettingsFormFields.ordinal,
                    ],
                )),
            ].map(fieldName => [fieldName, [disabledByEditingModeCondition]])))(),
        },
        arrayIndependentWatchers: {
            buttonClearFormFields: [{
                isNotInput: true,
                condition: ({formValues}) => !formValues[REPORT_PROPERTIES_SETTINGS_FORM_DATA_KEY]
                    || !formValues[REPORT_PROPERTIES_SETTINGS_FORM_DATA_KEY]?.length
                    || !formState.isEditingForm,
                flag: WatcherFieldFlag.isDisabled,
            }],
            addFormItemButton: [
                {
                    isNotInput: true,
                    condition: () => !formState.isEditingForm,
                    flag: WatcherFieldFlag.isDisabled,
                },
            ],
            removeButton: [
                {
                    isNotInput: true,
                    condition: () => !formState.isEditingForm,
                    flag: WatcherFieldFlag.isDisabled,
                },
            ],
        },
        triggerDeps: [formState.isEditingForm, tableDatasourceType],
        formArrayKey: REPORT_PROPERTIES_SETTINGS_FORM_DATA_KEY,
        modifiers: {
            trackFormDirtyState: true,
            isDirty: formState.isDirty,
            setIsDirty: isDirty => setFormState(v => ({...v, isDirty})),
        },
        performanceModifiers: {
            checkIfShouldSkip: changedValues => {
                const skipApplyWatchersFields = [
                    ReportPropertiesSettingsFormFields.paramName,
                    ReportPropertiesSettingsFormFields.reportTitle,
                    ReportPropertiesSettingsFormFields.ordinal,
                    ReportPropertiesSettingsFormFields.format,
                    ReportPropertiesSettingsFormFields.visible,
                    ReportPropertiesSettingsFormFields.required,
                    ReportPropertiesSettingsFormFields.defaultValueString,
                    ReportPropertiesSettingsFormFields.defaultValueInteger,
                ];
                return changedValues.queryParameters?.some(row => row && Object.keys(row)
                    .every(key => skipApplyWatchersFields.includes(key as ReportPropertiesSettingsFormFields)));
            },
        },
    });

    useEffect(() => {
        if (reportConfigurationData) {
            const {queryParameters = []} = reportConfigurationData ?? {};

            const dataToSetToForm = getDataFromReportPropertiesSettingsRequest(
                queryParameters,
            );

            form.resetFields();
            setFieldsValueUntouched(form, {[REPORT_PROPERTIES_SETTINGS_FORM_DATA_KEY]: dataToSetToForm});
            setFormState(v => ({...v, initialValues: {[REPORT_PROPERTIES_SETTINGS_FORM_DATA_KEY]: dataToSetToForm}}));
        }
        applyFieldWatchers();
    }, [reportConfigurationData]);

    const handleFinish = (values: any) => {
        const valuesConvertedForRequest = values.queryParameters.map((value: ReportPropertySetting) => {
            const [defaultValueIndex] = ReportPropertiesSettingsDefaultValueFields.filter(v => !!value[v]);
            if (!defaultValueIndex) return value;
            return {
                ...value,
                defaultValue: value[defaultValueIndex],
            };
        });

        if (templateCode) {
            dispatch(updateTableReportConfiguration({
                templateCode,
                data: {
                    queryParameters: valuesConvertedForRequest,
                },
            }));
        }
    };

    return (
        <div className={cn('report-properties-settings')}>
            <Form
                layout="vertical"
                form={form}
                onFinish={handleFinish}
                // name={ReportConfigurationFormName.PROPERTIES_SETTINGS}
                onValuesChange={changedValues => {
                    applyFieldWatchers(changedValues);
                }}
                className={cn('report-properties-settings__form')}
            >
                <ReportPropertiesSettingsButtons {...{
                    formState,
                    setFormState,
                    form,
                    checkArrayIndependentWatcherFlag,
                    name: REPORT_PROPERTIES_SETTINGS_FORM_DATA_KEY,
                }}
                />
                <div className={cn('report-properties-settings__section-title')} />
                <div className={cn('report-properties-settings__body')}>
                    <Form.List name={REPORT_PROPERTIES_SETTINGS_FORM_DATA_KEY}>{(fields, {add, remove}) => (
                        <>
                            <Button
                                type="primary"
                                onClick={() => add()}
                                icon={(<PlusCircleOutlined className="svg-icon-size-16" />)}
                                disabled={checkArrayIndependentWatcherFlag('addFormItemButton',
                                    WatcherFieldFlag.isDisabled)}
                            >Добавить
                            </Button>
                            {!fields.length && <Empty />}
                            <ReportPropertiesSettingFields
                                form={form}
                                fields={fields}
                                remove={remove}
                                checkWatcherFlag={checkWatcherFlag}
                                applyFieldWatchers={applyFieldWatchers}
                                checkArrayIndependentWatcherFlag={checkArrayIndependentWatcherFlag}
                            />
                        </>
                    )}
                    </Form.List>
                </div>
            </Form>
        </div>
    );
};
