import {ListItemMetaProps} from 'antd/lib/list';
import moment from 'moment';
import React from 'react';

import {ListArrayFactory} from 'components/@common/specific/list-array-factory';
import {
    ChangelogStagesLookupCode,
    DemandAction,
    ProcessingAction,
    StatusMessage,
} from 'components/@common/widgets/tabbed-form/common-tabs/changelog/changelog.constants';
import {ChangelogEntity} from 'components/@common/widgets/tabbed-form/common-tabs/changelog/changelog.types';
import {TransportContainerStatusListItem} from 'components/ais/transport-container-card/components/tc-status-list-item';
import {DATE_WITH_TIME_DOTS_SECONDS_24} from 'shared/constants/date-format';

interface ChangelogProps {
    changelogData: ChangelogEntity[];
}

export const Changelog = ({changelogData}: ChangelogProps) => {
    const processingAudit = changelogData.filter(
        data => data.stage.lookupCode === ChangelogStagesLookupCode.processing && data.statusCode && data.statusText,
    );
    const demandAudit = changelogData.filter(
        data => (
            data.stage.lookupCode === ChangelogStagesLookupCode.responseRequirement
            && data.statusCode && data.statusText
        ),
    );

    return (
        <ListArrayFactory listArrayDescription={[{
            listTitle: 'Протокол обработки (сообщение об ошибке)',
            list: processingAudit.reduce((list, data) => ([...list,
                {
                    title: `${moment(data.createdDate).format(DATE_WITH_TIME_DOTS_SECONDS_24)}:`,
                    description: ProcessingAction,
                },
                {
                    title: `${moment(data.updatedDate).format(DATE_WITH_TIME_DOTS_SECONDS_24)}:`,
                    description: <TransportContainerStatusListItem
                        meaning={`${StatusMessage} «${data.statusCode} - ${data.statusText}»`}
                        code={data.statusCode}
                    />,
                },
            ]), [] as ListItemMetaProps[]),
        }, {
            listTitle: 'Ответ на требование',
            list: demandAudit.reduce((list, data) => ([...list,
                {
                    title: `${moment(data.createdDate).format(DATE_WITH_TIME_DOTS_SECONDS_24)}:`,
                    description: DemandAction,
                },
                {
                    title: `${moment(data.updatedDate).format(DATE_WITH_TIME_DOTS_SECONDS_24)}:`,
                    description: <TransportContainerStatusListItem
                        meaning={`${StatusMessage} «${data.statusCode} - ${data.statusText}»`}
                        code={data.statusCode}
                    />,
                },
            ]), [] as ListItemMetaProps[]),
        }]}
        />
    );
};
