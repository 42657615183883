import {Button, Input, Upload} from 'antd';
import {RcFile, UploadChangeParam, UploadProps} from 'antd/es/upload/interface';
import {UploadFile} from 'antd/lib/upload/interface';
import React from 'react';

import {ReactComponent as ClearIcon} from 'shared/assets/forms/delete-x.svg';
import {ReactComponent as FileIcon} from 'shared/assets/forms/file.svg';

export interface CustomFileFieldProps extends UploadProps {
    value?: UploadFile;
    buttonText?: string;
    onChange?: (value: any) => void;
}

export const CustomFileField = ({
    value,
    onChange,
    buttonText = 'Выбрать файл',
    ...props
}: CustomFileFieldProps) => {
    const handleChange = (info: UploadChangeParam<UploadFile<any>>) => {
        onChange?.(info.file);
    };

    const beforeUpload = (file: UploadFile<Blob>) => {
        const formData = new FormData();
        formData.append('file', file as RcFile);
        return false;
    };

    return (
        <Upload
            {...props}
            onChange={handleChange}
            beforeUpload={beforeUpload}
            showUploadList={false}
            className="upload-field"
        >
            <div className="upload-field-wrapper">
                <Input
                    disabled
                    prefix={<FileIcon />}
                    className="ant-input--soft-disabled"
                    suffix={value && (
                        <ClearIcon
                            className="upload-field__clear-icon"
                            onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
                                event.stopPropagation();
                                onChange?.(undefined);
                            }}
                        />
                    )}
                    value={value ? value.name : 'Файл не прикреплен'}
                />
                <Button className="upload-field__btn">
                    {buttonText}
                </Button>
            </div>
        </Upload>
    );
};
