import React, {createContext, Dispatch, SetStateAction} from 'react';

import {FormValidationErrors} from './report-configuration.types';

export type TableDatasourceType = 'SQL' | 'TABLE' | 'FUNCTION';

export interface ReportConfigurationContextArgs {
    selectedTabKey: React.Key | null;
    setSelectedTabKey: React.Dispatch<React.SetStateAction<React.Key>> | null;
    templateCode?: string;
    entityName: string;
    isCreatingNewTemplate: boolean;
    validationErrors: FormValidationErrors | undefined;
    setValidationErrors: Dispatch<SetStateAction<FormValidationErrors>> | undefined;
}

export const ReportConfigurationContext = createContext<ReportConfigurationContextArgs>({
    selectedTabKey: null,
    setSelectedTabKey: null,
    isCreatingNewTemplate: false,
    entityName: '',
    validationErrors: undefined,
    setValidationErrors: undefined,
});
