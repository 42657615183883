interface AuthCredentials {
    userName?: string;
    token?: string | null;
}

export interface AuthResponse {
    code?: number;
    type?: string;
    message?: string;
}

export type AuthResponseWithCredentials = AuthCredentials & AuthResponse;

export interface AuthError {
    message?: string;
}

export interface UserInfo extends AuthCredentials {
    fullName: string;
    externalEmployee: boolean | null;
    userId: number;
}

export interface AuthSliceState extends AuthCredentials {
    error?: AuthError | null;
    isPasswordExpired?: boolean;
    isPasswordSuccessfullyChanged?: boolean;
    userInfo?: UserInfo;
    useTwoFAVerificationFlag?: boolean;
    profile?: AuthProfile | null;
}

export interface TwoFactorAuthStatus {
    message?: string;
    isError?: boolean;
}

export enum TwoFactorAuthCode {
    GOOGLE = 'UseGoogle2fa',
    CUSTOM = 'UseCustom2fa',
}

export interface ChangePasswordSettings {
    minLengthPwd: string;
    maxLengthPwd: string;
    allowedCharactersInPassword: string;
}

export enum AuthProfile {
    LDAP = 'ldap',
    SAML = 'saml',
    KEYCLOAK = 'keycloak',
}
