import {Typography} from 'antd';
import React, {Dispatch, SetStateAction} from 'react';

export interface InputDefaultValueSetterProps {
    label: string;
    defaultValue?: string;
    onChange?: (value: any) => void;
    isDefaultValueSet: boolean;
    setIsDefaultValueSet: Dispatch<SetStateAction<boolean>>;
}

export const InputDefaultValueSetter = ({
    label,
    defaultValue,
    onChange,
    isDefaultValueSet,
    setIsDefaultValueSet,
}: InputDefaultValueSetterProps) => {
    const handleDefaultValueSet = () => {
        if (isDefaultValueSet) {
            onChange?.('');
        } else {
            onChange?.(defaultValue);
        }
        setIsDefaultValueSet(v => !v);
    };

    return (
        <div className="input-default-value-setter">
            <Typography.Text>{label}</Typography.Text>
            <Typography.Link
                underline
                onClick={handleDefaultValueSet}
            >{defaultValue}
            </Typography.Link>
        </div>
    );
};
