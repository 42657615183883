import React from 'react';
import {useDispatch} from 'react-redux';

import {selectContextRawData} from 'modules/context/context-selector';
import {createActionGetFile} from 'modules/data/data-actions';
import {selectTableSelectedRowKeys} from 'modules/data/data-selectors';
import {RequestType} from 'modules/metadata';
import {useAppSelector} from 'store/config/hooks';

import {ActionButton} from '../action-button';
import {TableActionProps} from '../index';

export const ButtonDownloadSelectedRows: React.FunctionComponent<TableActionProps> = ({
    wrapper,
    meta,
    entityName,
    ...props
}: TableActionProps) => {
    const dispatch = useDispatch();
    const data = useAppSelector(selectContextRawData);
    const selectedRowKeys = useAppSelector(selectTableSelectedRowKeys(entityName)) || [];
    const handleClick = () => {
        const {requestType = RequestType.GET, referenceUrl = entityName} = meta;
        if (requestType === RequestType.POST) {
            dispatch(createActionGetFile({
                referenceUrl,
                requestType,
                data,
                body: {ids: selectedRowKeys || []},
            }));
            return;
        }
        if (meta?.selectedReferenceUrl && !!selectedRowKeys.length) {
            const updatedUrl = `${meta?.selectedReferenceUrl}?${selectedRowKeys.map(ids => `ids=${ids}`).join('&')}`;
            dispatch(createActionGetFile({referenceUrl: updatedUrl, requestType}));
            return;
        }

        dispatch(createActionGetFile({
            referenceUrl,
            requestType,
            data,
        }));
    };
    const Component = wrapper || ActionButton;
    return (
        <Component
            {...props}
            onClick={handleClick}
            actionIcon={meta.actionIcon}
            disabled={!meta?.selectedReferenceUrl && !selectedRowKeys.length}
        >
            {meta.actionTitle}
        </Component>
    );
};
