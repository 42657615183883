import {
    Button, Checkbox, Form, Input, InputNumber,
} from 'antd';
import {FormListFieldData} from 'antd/es/form/FormList';
import {FormInstance} from 'antd/lib/form';
import cn from 'classnames';
import React, {useState} from 'react';

import {DateInput} from 'components/@common/inputs/date-input';
import {CustomSelect, CustomSelectMode} from 'components/form/inputs/custom-select';
import {CustomSelectValueTriggerEvent} from 'components/form/inputs/custom-select/custom-select';
import {LargeStringInput} from 'components/form/inputs/large-string-input';
import {fieldValidationResolver, maskValidation} from 'components/form/utils/field-validation-resolver';
import {WatcherFieldFlag} from 'components/report-configuration/hooks/use-form-fields-manager';
import {FieldType} from 'modules/metadata';
import {ReactComponent as TrashXOutlined} from 'shared/assets/trash-x.svg';
import {useAppSelector} from 'store/config/hooks';
import {
    selectReportConfigurationTableAttributesAsSelectEntries,
} from 'store/slices/report-configuration-slice/report-configuration-slice';

import {
    FormatLookupSublistCodesMap,
    REPORT_PROPERTIES_SETTINGS_FORM_DATA_KEY,
    ReportPropertiesSettingsDto,
    ReportPropertiesSettingsFormFields,
} from '../constants/report-properties-settings';
import {resetReportPropertiesSettingsFormFields} from '../utils/report-properties-settings.utils';

export interface ReportPropertiesSettingFieldsProps {
    form: FormInstance;
    fields: FormListFieldData[];
    remove: (index: (number | number[])) => void;
    checkWatcherFlag: (index: number, key: string, flag: WatcherFieldFlag) => boolean;
    checkArrayIndependentWatcherFlag: (fieldKey: string, flag: WatcherFieldFlag) => boolean;
    applyFieldWatchers: (changedVales?: (ReportPropertiesSettingsDto | undefined)) => void;
}

export const ReportPropertiesSettingFields: React.FC<ReportPropertiesSettingFieldsProps> = ({
    form,
    fields,
    remove,
    checkWatcherFlag,
    applyFieldWatchers,
    checkArrayIndependentWatcherFlag,
}: ReportPropertiesSettingFieldsProps) => {
    const [selectedLookupTypes, setSelectedLookupTypes] = useState<Record<string, string>>({});
    const [formatSublistCodes, setFormatSublistCodes] = useState<Record<string, string>>({});

    const tableAttributesEntries = useAppSelector(selectReportConfigurationTableAttributesAsSelectEntries);

    return (
        <div className={cn('form-list')}>
            {fields.map(field => (
                <div
                    key={field.key}
                    className={cn('form-list__items')}
                >
                    {!checkWatcherFlag(
                        field.name, ReportPropertiesSettingsFormFields.paramName, WatcherFieldFlag.isHidden,
                    ) && (
                        <Form.Item
                            style={{width: 220}}
                            label="Имя параметра"
                            required={checkWatcherFlag(field.name, ReportPropertiesSettingsFormFields.paramName,
                                WatcherFieldFlag.isRequired)}
                            name={[field.name, ReportPropertiesSettingsFormFields.paramName]}
                            rules={fieldValidationResolver({
                                validationRules: [{
                                    regExp: '^.{0,500}$',
                                    errorMessage: 'Длина данного поля должна быть не более 500 символов',
                                }, {
                                    regExp: '^[\\w]*$',
                                    errorMessage: 'Поле должно содержать только латинские символы',
                                }, {
                                    mask: maskValidation.checkFieldUniqueness,
                                    errorMessage: 'Такое имя параметра уже существует',
                                }],
                                settings: {
                                    checkFieldUniquenessArrayPath: REPORT_PROPERTIES_SETTINGS_FORM_DATA_KEY,
                                    checkFieldUniquenessFieldKey: ReportPropertiesSettingsFormFields.paramName,
                                },
                                fieldMeta: {
                                    type: FieldType.STRING,
                                    label: 'Имя параметра',
                                    isRequired: checkWatcherFlag(
                                        field.name, ReportPropertiesSettingsFormFields.paramName,
                                        WatcherFieldFlag.isRequired,
                                    ),
                                },
                            })}
                        >
                            <Input
                                autoComplete="off"
                                placeholder="Введите имя параметра"
                                disabled={checkWatcherFlag(
                                    field.name,
                                    ReportPropertiesSettingsFormFields.paramName,
                                    WatcherFieldFlag.isDisabled,
                                )}
                            />
                        </Form.Item>
                    )}
                    {!checkWatcherFlag(
                        field.name, ReportPropertiesSettingsFormFields.columnName, WatcherFieldFlag.isHidden,
                    ) && (
                        <Form.Item
                            style={{width: 187}}
                            label="Имя атрибута"
                            required={checkWatcherFlag(field.name, ReportPropertiesSettingsFormFields.columnName,
                                WatcherFieldFlag.isRequired)}
                            name={[field.name, ReportPropertiesSettingsFormFields.columnName]}
                            rules={fieldValidationResolver({
                                validationRules: [{
                                    mask: maskValidation.checkFieldUniqueness,
                                    errorMessage: 'Такое имя атрибута уже существует',
                                }],
                                settings: {
                                    checkFieldUniquenessArrayPath: REPORT_PROPERTIES_SETTINGS_FORM_DATA_KEY,
                                    checkFieldUniquenessFieldKey: ReportPropertiesSettingsFormFields.columnName,
                                },
                                fieldMeta: {
                                    type: FieldType.REFERENCE,
                                    label: 'Имя атрибута',
                                    isRequired: true,
                                },
                            })}
                        >
                            <CustomSelect
                                entries={tableAttributesEntries}
                                settings={{
                                    placeholder: 'Выберите атрибут',
                                    isDisabled: checkWatcherFlag(
                                        field.name,
                                        ReportPropertiesSettingsFormFields.columnName,
                                        WatcherFieldFlag.isDisabled,
                                    ),
                                    showSearch: true,
                                }}
                            />
                        </Form.Item>
                    )}
                    <Form.Item
                        required
                        style={{width: 240}}
                        label="Пользовательское наименование"
                        rules={fieldValidationResolver({
                            validationRules: [
                                {
                                    regExp: '^.{1,100}$',
                                    errorMessage: 'Длина данного поля должна быть не более 100 символов',
                                },
                            ],
                            fieldMeta: {
                                type: FieldType.STRING,
                                label: 'Пользовательское наименование',
                                isRequired: true,
                            },
                        })}
                        name={[field.name, ReportPropertiesSettingsFormFields.reportTitle]}
                    >
                        <Input
                            autoComplete="off"
                            placeholder="Введите наименование"
                            disabled={checkWatcherFlag(
                                field.name,
                                ReportPropertiesSettingsFormFields.reportTitle,
                                WatcherFieldFlag.isDisabled,
                            )}
                        />
                    </Form.Item>
                    <Form.Item
                        label={<>&nbsp;</>}
                        style={{width: 97}}
                        valuePropName="checked"
                        name={[field.name, ReportPropertiesSettingsFormFields.visible]}
                    >
                        <Checkbox disabled={checkWatcherFlag(
                            field.name,
                            ReportPropertiesSettingsFormFields.visible,
                            WatcherFieldFlag.isDisabled,
                        )}
                        >
                            Видимый
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        label={<>&nbsp;</>}
                        style={{width: 130}}
                        valuePropName="checked"
                        name={[field.name, ReportPropertiesSettingsFormFields.required]}
                    >
                        <Checkbox disabled={checkWatcherFlag(
                            field.name,
                            ReportPropertiesSettingsFormFields.required,
                            WatcherFieldFlag.isDisabled,
                        )}
                        >
                            Обязательный
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        style={{width: 220}}
                        label="Тип значения"
                        required
                        rules={fieldValidationResolver({
                            fieldMeta: {
                                type: FieldType.STRING,
                                label: 'Тип значения',
                                isRequired: true,
                            },
                        })}
                        name={[field.name, ReportPropertiesSettingsFormFields.type]}
                    >
                        <CustomSelect
                            settings={{
                                showSearch: true,
                                url: '/lookupValue/TABLE_REPORT_PARAMETER_TYPE',
                                labelPath: 'meaning',
                                valuePath: 'lookupCode',
                                placeholder: 'Выберите тип',
                                isDisabled: checkWatcherFlag(
                                    field.name,
                                    ReportPropertiesSettingsFormFields.type,
                                    WatcherFieldFlag.isDisabled,
                                ),
                            }}
                            handleTriggerEvent={(value, event) => {
                                if (value?.lookupCode) {
                                    setFormatSublistCodes(v => ({
                                        ...v,
                                        [field.name]: FormatLookupSublistCodesMap[
                                            value.lookupCode as keyof typeof FormatLookupSublistCodesMap
                                        ],
                                    }));
                                }
                                if (event === CustomSelectValueTriggerEvent.valueHasBeenChangedManually) {
                                    resetReportPropertiesSettingsFormFields(
                                        form, field.name,
                                        ReportPropertiesSettingsFormFields.type,
                                    );
                                    applyFieldWatchers(); // чтобы отререндерить форму
                                }
                            }}
                        />
                    </Form.Item>
                    {!checkWatcherFlag(
                        field.name,
                        ReportPropertiesSettingsFormFields.defaultType,
                        WatcherFieldFlag.isHidden,
                    ) && (
                        <Form.Item
                            style={{width: 270}}
                            label="Тип значения по умолчанию"
                            name={[field.name, ReportPropertiesSettingsFormFields.defaultType]}
                        >
                            <CustomSelect
                                additionalEntries={[{
                                    label: 'Не выбрано', // используется только на фронте как значение по умолчанию
                                    value: {lookupCode: 'UNSET', meaning: 'Не выбрано'},
                                }]}
                                optionsFilterCallback={({entry}) => {
                                    const shouldFilterLookup = checkWatcherFlag(
                                        field.name, ReportPropertiesSettingsFormFields.defaultType,
                                        WatcherFieldFlag.shouldFilterLookup,
                                    );
                                    return shouldFilterLookup ? entry.value?.lookupCode !== 'CURRENT_DATETIME'
                                        : true;
                                }}
                                settings={{
                                    formInstance: form,
                                    formFieldKey: REPORT_PROPERTIES_SETTINGS_FORM_DATA_KEY,
                                    formFieldPathName: [field.name, ReportPropertiesSettingsFormFields.defaultType],
                                    url: '/lookupValue/TABLE_REPORT_PARAMETER_DEFAULT_TYPE',
                                    labelPath: 'meaning',
                                    valuePath: 'lookupCode',
                                    placeholder: 'Выберите значение по умолчанию',
                                    isDisabled: checkWatcherFlag(
                                        field.name,
                                        ReportPropertiesSettingsFormFields.defaultType,
                                        WatcherFieldFlag.isDisabled,
                                    ),
                                    useFirstOptionAfterReceivedFromFetcher: true,
                                    noUseFirstOptionWhenValueIsSet: true,
                                    setFirstOptionUntouched: true,
                                }}
                                handleTriggerEvent={(value, event) => {
                                    if (event === CustomSelectValueTriggerEvent.valueHasBeenChangedManually) {
                                        resetReportPropertiesSettingsFormFields(
                                            form, field.name,
                                            ReportPropertiesSettingsFormFields.defaultType,
                                        );
                                    }
                                }}
                            />
                        </Form.Item>
                    )}
                    {!checkWatcherFlag(
                        field.name,
                        ReportPropertiesSettingsFormFields.sqlValueset,
                        WatcherFieldFlag.isHidden,
                    ) && (
                        <Form.Item
                            style={{width: 270}}
                            label="Динамический SQL"
                            rules={[{required: true}]}
                            name={[field.name, ReportPropertiesSettingsFormFields.sqlValueset]}
                        >
                            <LargeStringInput
                                disabled={checkWatcherFlag(field.name,
                                    ReportPropertiesSettingsFormFields.sqlValueset,
                                    WatcherFieldFlag.isDisabled)}
                                name={ReportPropertiesSettingsFormFields.sqlValueset}
                                label="Динамический SQL"
                                placeholder="Динамический SQL"
                            />
                        </Form.Item>
                    )}
                    {!checkWatcherFlag(
                        field.name,
                        ReportPropertiesSettingsFormFields.valuesetLookupType, WatcherFieldFlag.isHidden,
                    )
                        && (
                            <Form.Item
                                required
                                style={{width: 216}}
                                label="Набор значений"
                                rules={fieldValidationResolver({
                                    fieldMeta: {
                                        type: FieldType.REFERENCE,
                                        label: 'Набор значений',
                                        isRequired: true,
                                    },
                                })}
                                name={[field.name, ReportPropertiesSettingsFormFields.valuesetLookupType]}
                            >
                                <CustomSelect
                                    settings={{
                                        showSearch: true,
                                        url: '/valueLists/LOOKUP_TYPES',
                                        labelPath: 'meaning',
                                        valuePath: 'lookupCode',
                                        placeholder: 'Выберите набор значений',
                                        isDisabled: checkWatcherFlag(
                                            field.name,
                                            ReportPropertiesSettingsFormFields.valuesetLookupType,
                                            WatcherFieldFlag.isDisabled,
                                        ),
                                    }}
                                    handleTriggerEvent={(value, event) => {
                                        if (value?.lookupCode) {
                                            setSelectedLookupTypes(v => ({...v, [field.name]: value.lookupCode}));
                                        }
                                        if (event === CustomSelectValueTriggerEvent.valueHasBeenChangedManually) {
                                            resetReportPropertiesSettingsFormFields(
                                                form, field.name,
                                                ReportPropertiesSettingsFormFields.valuesetLookupType,
                                            );
                                        }
                                    }}
                                />
                            </Form.Item>
                        )}
                    {!checkWatcherFlag(
                        field.name,
                        ReportPropertiesSettingsFormFields.defaultValueSqlValueSet,
                        WatcherFieldFlag.isHidden,
                    ) && (
                        <Form.Item
                            style={{width: 270}}
                            label="Динамический SQL по умолчанию"
                            name={[field.name, ReportPropertiesSettingsFormFields.defaultValueSqlValueSet]}
                        >
                            <LargeStringInput
                                disabled={checkWatcherFlag(field.name,
                                    ReportPropertiesSettingsFormFields.defaultValueSqlValueSet,
                                    WatcherFieldFlag.isDisabled)}
                                name={ReportPropertiesSettingsFormFields.defaultValueSqlValueSet}
                                label="Динамический SQL по умолчанию"
                                placeholder="Динамический SQL по умолчанию"
                            />
                        </Form.Item>
                    )}
                    {!checkWatcherFlag(
                        field.name,
                        ReportPropertiesSettingsFormFields.defaultValueContext,
                        WatcherFieldFlag.isHidden,
                    )
                        && (
                            <Form.Item
                                style={{width: 270}}
                                label="Значение по умолчанию"
                                name={[field.name, ReportPropertiesSettingsFormFields.defaultValueContext]}
                            >
                                <CustomSelect settings={{
                                    url: '/lookupValue/TABLE_REPORT_PARAMETER_CONTEXT_TYPE',
                                    labelPath: 'meaning',
                                    valuePath: 'lookupCode',
                                    placeholder: 'Выберите значение по умолчанию',
                                    isDisabled: checkWatcherFlag(
                                        field.name,
                                        ReportPropertiesSettingsFormFields.defaultValueContext,
                                        WatcherFieldFlag.isDisabled,
                                    ),
                                }}
                                />
                            </Form.Item>
                        )}
                    {!checkWatcherFlag(
                        field.name,
                        ReportPropertiesSettingsFormFields.defaultValueDate,
                        WatcherFieldFlag.isHidden,
                    )
                        && (
                            <Form.Item
                                style={{width: 270}}
                                label="Значение по умолчанию"
                                name={[field.name, ReportPropertiesSettingsFormFields.defaultValueDate]}
                            >
                                <DateInput
                                    resetTimeOnSet
                                    disabled={checkWatcherFlag(
                                        field.name,
                                        ReportPropertiesSettingsFormFields.defaultValueDate,
                                        WatcherFieldFlag.isDisabled,
                                    )}
                                />
                            </Form.Item>
                        )}
                    {!checkWatcherFlag(
                        field.name,
                        ReportPropertiesSettingsFormFields.defaultValueDateTime,
                        WatcherFieldFlag.isHidden,
                    )
                        && (
                            <Form.Item
                                style={{width: 270}}
                                label="Значение по умолчанию"
                                name={[field.name, ReportPropertiesSettingsFormFields.defaultValueDateTime]}
                            >
                                <DateInput
                                    showTime
                                    disabled={checkWatcherFlag(
                                        field.name,
                                        ReportPropertiesSettingsFormFields.defaultValueDateTime,
                                        WatcherFieldFlag.isDisabled,
                                    )}
                                />
                            </Form.Item>
                        )}
                    {!checkWatcherFlag(
                        field.name,
                        ReportPropertiesSettingsFormFields.defaultValueInteger,
                        WatcherFieldFlag.isHidden,
                    )
                        && (
                            <Form.Item
                                style={{width: 270}}
                                label="Значение по умолчанию"
                                name={[field.name, ReportPropertiesSettingsFormFields.defaultValueInteger]}
                            >
                                <InputNumber
                                    autoComplete="off"
                                    placeholder="Введите значение по умолчанию"
                                    disabled={checkWatcherFlag(
                                        field.name,
                                        ReportPropertiesSettingsFormFields.defaultValueInteger,
                                        WatcherFieldFlag.isDisabled,
                                    )}
                                />
                            </Form.Item>
                        )}
                    {!checkWatcherFlag(
                        field.name,
                        ReportPropertiesSettingsFormFields.defaultValueBoolean,
                        WatcherFieldFlag.isHidden,
                    )
                        && (
                            <Form.Item
                                style={{width: 270}}
                                valuePropName="checked"
                                label={<>&nbsp;</>}
                                name={[field.name, ReportPropertiesSettingsFormFields.defaultValueBoolean]}
                            >
                                <Checkbox
                                    disabled={checkWatcherFlag(
                                        field.name,
                                        ReportPropertiesSettingsFormFields.defaultValueBoolean,
                                        WatcherFieldFlag.isDisabled,
                                    )}
                                >
                                    Значение по умолчанию
                                </Checkbox>
                            </Form.Item>
                        )}
                    {!checkWatcherFlag(
                        field.name,
                        ReportPropertiesSettingsFormFields.defaultValueString,
                        WatcherFieldFlag.isHidden,
                    )
                        && (
                            <Form.Item
                                style={{width: 270}}
                                label="Значение по умолчанию"
                                name={[field.name, ReportPropertiesSettingsFormFields.defaultValueString]}
                            >
                                <Input
                                    autoComplete="off"
                                    placeholder="Введите значение по умолчанию"
                                    disabled={checkWatcherFlag(
                                        field.name,
                                        ReportPropertiesSettingsFormFields.defaultValueString,
                                        WatcherFieldFlag.isDisabled,
                                    )}
                                />
                            </Form.Item>
                        )}
                    {!checkWatcherFlag(
                        field.name,
                        ReportPropertiesSettingsFormFields.defaultValueValueSet, WatcherFieldFlag.isHidden,
                    )
                        && selectedLookupTypes[field.name] && (
                        <Form.Item
                            required
                            style={{width: 270}}
                            label="Значение по умолчанию"
                            name={[field.name, ReportPropertiesSettingsFormFields.defaultValueValueSet]}
                        >
                            <CustomSelect settings={{
                                showSearch: true,
                                url: `/lookupValue/${selectedLookupTypes[field.name]}`,
                                labelPath: 'meaning',
                                valuePath: 'lookupCode',
                                placeholder: 'Выберите значение по умолчанию',
                                isDisabled: checkWatcherFlag(
                                    field.name,
                                    ReportPropertiesSettingsFormFields.defaultValueValueSet,
                                    WatcherFieldFlag.isDisabled,
                                ),
                            }}
                            />
                        </Form.Item>
                    )}
                    {!checkWatcherFlag(
                        field.name,
                        ReportPropertiesSettingsFormFields.defaultValueMultiValueSet, WatcherFieldFlag.isHidden,
                    )
                        && selectedLookupTypes[field.name] && (
                        <Form.Item
                            required
                            style={{width: 270}}
                            label="Значения по умолчанию"
                            name={[field.name, ReportPropertiesSettingsFormFields.defaultValueMultiValueSet]}
                        >
                            <CustomSelect settings={{
                                showSearch: true,
                                mode: CustomSelectMode.multiple,
                                url: `/lookupValue/${selectedLookupTypes[field.name]}`,
                                labelPath: 'meaning',
                                valuePath: 'lookupCode',
                                placeholder: 'Выберите значение по умолчанию',
                                isDisabled: checkWatcherFlag(
                                    field.name,
                                    ReportPropertiesSettingsFormFields.defaultValueMultiValueSet,
                                    WatcherFieldFlag.isDisabled,
                                ),
                            }}
                            />
                        </Form.Item>
                    )}
                    {!checkWatcherFlag(
                        field.name,
                        ReportPropertiesSettingsFormFields.format,
                        WatcherFieldFlag.isHidden,
                    )
                        && formatSublistCodes[field.name] && (
                        <Form.Item
                            required
                            style={{width: 'fit-content', minWidth: 190}}
                            label="Формат"
                            name={[field.name, ReportPropertiesSettingsFormFields.format]}
                        >
                            <CustomSelect settings={{
                                showSearch: true,
                                url: '/valueLists/FLEX_VALUE'
                                        + `?sublistCode=${formatSublistCodes[field.name]}`,
                                labelPath: 'meaning',
                                valuePath: 'lookupCode',
                                placeholder: 'Выберите формат',
                                isDisabled: checkWatcherFlag(
                                    field.name,
                                    ReportPropertiesSettingsFormFields.format,
                                    WatcherFieldFlag.isDisabled,
                                ),
                            }}
                            />
                        </Form.Item>
                    )}
                    <Form.Item
                        style={{width: 150}}
                        label="Порядковый номер"
                        name={[field.name, ReportPropertiesSettingsFormFields.ordinal]}
                    >
                        <InputNumber
                            autoComplete="off"
                            placeholder="Введите значение"
                            disabled={checkWatcherFlag(
                                field.name,
                                ReportPropertiesSettingsFormFields.ordinal,
                                WatcherFieldFlag.isDisabled,
                            )}
                        />
                    </Form.Item>
                    <Form.Item label={<>&nbsp;</>}> {/* Для выравнивания при ошибках */}
                        <Button
                            disabled={checkArrayIndependentWatcherFlag('removeButton',
                                WatcherFieldFlag.isDisabled)}
                            className="button-only-icon"
                            type="primary"
                            icon={<TrashXOutlined />}
                            onClick={() => remove(field.name)}
                        />
                    </Form.Item>
                </div>
            ))}
        </div>
    );
};
