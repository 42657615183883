import {
    DocumentUploadTemplateData,
} from 'store/slices/document-upload-template/document-upload-template-slice-type';

export const convertDocumentUploadTemplateData = (
    data?: Partial<DocumentUploadTemplateData>,
): Partial<DocumentUploadTemplateData> => ({
    ...data,
    documentTypesCodes: data?.documentTypes?.map(document => document?.docTypeCode) || [],
}) as Partial<DocumentUploadTemplateData>;
