import {websocketNewsStaticHandlers} from '../../modules/news';
import {useWebsocketClient} from '../use-websocket-client';

export const useWebsocket = () => {
    const wsInstance = useWebsocketClient({
        messageGlobalHandlers: {
            ...websocketNewsStaticHandlers,
        },
        options: {
            heartbeat: 60 * 1000,
            retryOnCloseDelay: 60000,
            connectionTimeout: 15000,
            connectionAttemptsLimit: 15,
        },
    });

    return {wsInstance};
};
