import React from 'react';

import {OpenFilterDrawerAction} from '../open-filter-drawer/open-filter-drawer';
import {TableActionProps} from '../table-action-types';
import {IntegratedFilter} from './integrated-filter';

export const Filter: React.FunctionComponent<TableActionProps> = ({
    meta,
    url,
    parentEntityType,
    entityName,
    ...props
}: TableActionProps) => {
    const {isIntegratedFilter} = meta;

    return (
        <>
            {isIntegratedFilter ? (
                <IntegratedFilter
                    url={url}
                    parentEntityType={parentEntityType}
                    entityName={entityName}
                    spacingValue="small"
                    {...props}
                />
            ) : (
                <OpenFilterDrawerAction
                    meta={meta}
                    entityName={entityName}
                    {...props}
                />
            )}
        </>
    );
};
