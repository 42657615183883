import {Button} from 'antd';
import {FormInstance} from 'antd/es/form';
import React from 'react';
import {useDispatch} from 'react-redux';

import {setFormDraftMetadata} from '../../../../modules/metadata/metadata-actions';

interface ButtonSaveDraftProps {
    actionTitle: string;
    entityName: string;
    form: FormInstance;
}
export const ButtonSaveDraft: React.FunctionComponent<ButtonSaveDraftProps> = ({
    actionTitle = 'Сохранить',
    entityName,
    form,
    ...props
}: ButtonSaveDraftProps) => {
    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch(setFormDraftMetadata({
            entityName,
            isDraft: true,
        }));
        form.submit();
    };
    return (
        <Button
            {...props}
            type="primary"
            className="save-action-btn"
            onClick={handleClick}
            htmlType="submit"
        >{actionTitle}
        </Button>
    );
};
