export enum EntityType {
    TABLE = 'TABLE',
    FORM_TABLE = 'FORM_TABLE',
    FORM = 'FORM',
    EDIT_FORM = 'EDIT_FORM',
    TABS = 'TABS',
    FILTER = 'FILTER',
    CONTEXT_FILTER = 'CONTEXT_FILTER',
    SEARCH = 'SEARCH',
    TABLE_STATE = 'TABLE_STATE',
    REPORT = 'REPORT',
    JASPER_REPORT = 'JASPER_REPORT',
    TABLE_REPORT = 'TABLE_REPORT',

    /**
     * Для этого типа не нужно описывать отдельные метадынные.
     * Его структура формируется на основе третьего
     * уровня меню.
     * Содержимое данного типа будет формироваться на основе сущностей,
     * которые будут дочерними по отношению к сущности с данным типом.
     */
    TABS_WITH_REFERENCES = 'TABS_WITH_REFERENCES',
    REFERENCE = 'REFERENCE',
    NUMBER = 'NUMBER',
    LIST = 'LIST',
    MODAL = 'MODAL',
    EDITABLE_TABLE = 'EDITABLETABLE',
    LIST_GRID = 'LIST_GRID',
    TABS_WITH_FORM = 'TABS_WITH_FORM',
    SUBSECTION_TABLE = 'SUBSECTION_TABLE',
    DASHBOARD = 'DASHBOARD',
    WIDGETS = 'WIDGETS'
}
