import isObject from 'lodash/isObject';

import {DescriptiveFlexfieldSegment} from 'store/slices/descriptive-flexfields-slice/descriptive-flexfields-slice-types';
import {FlexFieldList} from 'store/slices/request-slice/request-slice-type';

export const getRequestAdditionalFields = (fieldList: any) => {
    if (isObject(fieldList)) {
        const initArray: FlexFieldList[] = [];
        return Object.keys(fieldList).reduce(
            (acc, val) => [...acc, fieldList?.[val as keyof typeof fieldList]], initArray,
        ) as FlexFieldList[];
    }
    return [];
};

export const transformFlexFieldListToObject = (fieldList: {[index: string]: DescriptiveFlexfieldSegment}) => {
    const flexFieldListObject = Object.values(fieldList).reduce((prev, cur) => {
        const {segmentColumnName, segmentValue} = cur;
        if (segmentColumnName && segmentValue) {
            prev[segmentColumnName] = segmentValue;
        }
        return prev;
    }, {} as {[segName: string]: any});

    return flexFieldListObject;
};
