import {
    Checkbox,
    Empty,
    Form,
    InputNumber,
} from 'antd';
import {CheckboxChangeEvent} from 'antd/es/checkbox';
import {FormInstance} from 'antd/es/form';
import cn from 'classnames';
import React, {
    useContext, useEffect, useRef, useState,
} from 'react';

import {SimpleActionButton} from 'components/form/components';
import {CustomSelect} from 'components/form/inputs/custom-select';
import {ModalOpenerComponent, ModalOpenerComponentRef} from 'components/modal-opener-component';
import {ReportConfigurationContext} from 'components/report-configuration/report-configuration.context';
import {TableAttributesCustomSelectEntry} from 'components/report-configuration/tabs/report-comparison/report-comparison';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {
    selectTableReportConfigurationData,
} from 'store/slices/report-configuration-slice/report-configuration-slice';
import {
    updateTableReportConfiguration,
} from 'store/slices/report-configuration-slice/report-configuration/report-configuration-thunks';

const SORTING_SETTINGS_PREFIX = 'defaultSortColumns' as const;

interface SortingSettingsModalOpenerProps {
    form: FormInstance;
    attributesEntries?: TableAttributesCustomSelectEntry[];
    disabled: boolean;
}

export const SortingSettingsModalOpener = ({
    form,
    attributesEntries,
    disabled,
}: SortingSettingsModalOpenerProps) => {
    const dispatch = useAppDispatch();

    const {templateCode} = useContext(ReportConfigurationContext);

    const [checkedFields, setCheckedFields] = useState<number[]>([]);

    const handleCheck = (e: CheckboxChangeEvent) => {
        if (e.target.checked) {
            setCheckedFields(prev => [...prev, e.target.value]);
        } else {
            setCheckedFields(prev => prev?.filter(val => val !== e.target.value));
        }
    };

    const reportConfigurationData: any = useAppSelector(
        selectTableReportConfigurationData,
    );

    const [initialValues, setInitialValues] = useState<any>({
        [SORTING_SETTINGS_PREFIX]: reportConfigurationData?.[SORTING_SETTINGS_PREFIX],
    });

    useEffect(() => {
        const defaultSortColumns = reportConfigurationData?.[SORTING_SETTINGS_PREFIX];
        if (defaultSortColumns) {
            setInitialValues({
                [SORTING_SETTINGS_PREFIX]: defaultSortColumns,
            });
        }
    }, [reportConfigurationData]);

    useEffect(() => {
        if (initialValues) form.resetFields();
    }, [initialValues]);

    const modalOpenerRef = useRef<ModalOpenerComponentRef>(null);

    const handleFinish = (values: any) => {
        if (templateCode) {
            dispatch(updateTableReportConfiguration({
                templateCode,
                data: values,
            }));
            modalOpenerRef.current?.setIsModalOpen(false);
        }
    };

    return (
        <ModalOpenerComponent
            ref={modalOpenerRef}
            shouldConfirm={() => form.isFieldsTouched()}
            handleCancel={setIsModalOpen => {
                form.resetFields();
                setIsModalOpen(false);
            }}
            handleSave={() => {
                form.submit();
            }}
            component={(
                <SimpleActionButton
                    type="primary"
                    title="Настройки сортировки"
                    icon="OpposingArrows"
                    disabled={disabled}
                />
            )}
            modalProps={{
                title: 'Настройки сортировки',
                forceRender: false,
                destroyOnClose: true,
                centered: true,
            }}
        >
            <div className={cn('sorting-settings-modal-opener')}>
                <Form
                    onFinish={handleFinish}
                    form={form}
                    layout="vertical"
                    initialValues={initialValues}
                    className={cn('sorting-settings-modal-opener__form')}
                >
                    <Form.List name={SORTING_SETTINGS_PREFIX} >
                        {(fields, {add, remove}, {errors}) => (
                            <>
                                <Form.ErrorList errors={errors} />
                                <div className="form-list__header">
                                    <SimpleActionButton
                                        type="primary"
                                        onClick={() => add()}
                                        icon="PlusCircleOutlined"
                                        title="Добавить атрибут"
                                        disabled={
                                            !attributesEntries?.length
                                            || (
                                                !!(attributesEntries?.length
                                                        && fields?.length >= attributesEntries.length)
                                            ?? false)
                                        }
                                    />
                                    <SimpleActionButton
                                        type="primary"
                                        className="button-critic"
                                        onClick={() => {
                                            remove(checkedFields);
                                            setCheckedFields([]);
                                        }}
                                        icon="TrashXOutlined"
                                        title="Удалить выбранное"
                                    />
                                </div>
                                {(() => {
                                    if (!attributesEntries?.length) {
                                        return (
                                            <Empty
                                                description="Атрибуты источника данных табличной части не найдены"
                                            />
                                        );
                                    }
                                    if (!fields?.length) return <Empty description="Данные отсутствуют" />;
                                    return null;
                                })()}
                                <div className={cn('form-list')}>
                                    {fields?.map(field => (
                                        <div
                                            key={field.fieldKey}
                                            className={cn('form-list__items')}
                                        >
                                            <Checkbox
                                                name="field-index"
                                                value={field.name}
                                                onChange={handleCheck}
                                            />
                                            <Form.Item
                                                rules={[{required: true}]}
                                                style={{width: 282}}
                                                label="Атрибут"
                                                name={[field.name, 'columnName']}
                                            >
                                                <CustomSelect
                                                    entries={attributesEntries}
                                                    settings={{
                                                        placeholder: 'Выберите атрибут',
                                                    }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                style={{width: 274}}
                                                label="Порядок сортировки"
                                                name={[field.name, 'index']}
                                                rules={[
                                                    {required: true},
                                                    ({getFieldValue}) => ({
                                                        validator: (_, value) => {
                                                            const defaultSortColumnsValues = getFieldValue(
                                                                'defaultSortColumns',
                                                            );
                                                            const sameOrdinals = defaultSortColumnsValues
                                                                ?.filter((entry: any) => !!value
                                                                    && entry?.index === value);
                                                            if (sameOrdinals?.length > 1) {
                                                                return Promise.reject(new Error(
                                                                    'Уже существует',
                                                                ));
                                                            } return Promise.resolve();
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <InputNumber placeholder="Введите значение" />
                                            </Form.Item>
                                            <Form.Item
                                                rules={[{required: true}]}
                                                style={{width: 282}}
                                                label="Вид сортировки"
                                                name={[field.name, 'order']}
                                            >
                                                <CustomSelect
                                                    settings={{
                                                        url: 'lookupValue/TABLE_REPORT_SORT_DIRECTION',
                                                        valuePath: 'lookupCode',
                                                        labelPath: 'meaning',
                                                        placeholder: 'Выберите опцию',
                                                        useFirstOptionAfterEntriesChanged: true,
                                                        noUseFirstOptionWhenValueIsSet: true,
                                                    }}
                                                />
                                            </Form.Item>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </Form.List>
                </Form>
            </div>
        </ModalOpenerComponent>
    );
};
