import {ExclamationCircleOutlined} from '@ant-design/icons';
import {Modal} from 'antd';
import React from 'react';

import '../modal-root.less';

const {confirm} = Modal;

export const showConfirmModal = ({
    title,
    description,
    onSubmit,
    okText,

}: {
    title: string;
    description?: string;
    onSubmit: () => void;
    okText: string;
}) => {
    confirm({
        title,
        className: 'modal-confirm',
        icon: <ExclamationCircleOutlined />,
        content: description,
        onOk: onSubmit,
        onCancel() {
        },
        okText,
    });
};
