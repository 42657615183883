import {omit} from 'lodash';

export const convertArrayToTwoLevelObject = (
    array: Record<string, any>[],
    firstLevelKey: string,
    secondLevelKey: string,
) => array.reduce((acc: Record<string, any>, currentItem: Record<string, any>) => {
    const firstLevelName = currentItem[firstLevelKey];
    const secondLevelName = currentItem[secondLevelKey];

    acc[firstLevelName] = {
        ...(acc[firstLevelName] || {}),
        [secondLevelName]: currentItem,
    };

    return acc;
}, {});

export const replaceFirstElementInArray = <T = any> (
    predicate: (value: T, index: number, obj: any[]) => unknown,
    array: T[],
    replacement: T,
) => {
    const firstIndex = array.findIndex(predicate);
    if (firstIndex === -1) return array;
    const before = array.slice(0, firstIndex);
    const after = array.slice(firstIndex + 1, array.length);
    return [...before, replacement, ...after];
};

interface FlattenDeepArrayByFieldArgs<T extends object> {
    array: T[] | undefined | null;
    fieldName: keyof T;
    shouldOmit?: boolean;
}

export const flattenDeepArrayByField = <T extends object, O extends (keyof T | '') = ''>({
    array,
    fieldName,
    shouldOmit,
}: FlattenDeepArrayByFieldArgs<T>) => {
    type ArrayItem = O extends '' ? T : Omit<T, O>;

    if (!array) return array;

    const newArray: ArrayItem[] = [];

    const push = (item: ArrayItem) => {
        if (shouldOmit) newArray.push(omit(item, fieldName) as ArrayItem);
        else newArray.push(item);
    };

    array.forEach(item => {
        push(item as ArrayItem);

        const childrenArray = item[fieldName] as any as T[];
        if (childrenArray && Array.isArray(item[fieldName])) {
            const childrenItems = flattenDeepArrayByField<T>({
                array: childrenArray,
                fieldName,
            });
            childrenItems?.forEach(childItem => push(childItem as ArrayItem));
        }
    });
    return newArray;
};
