import {Modal} from 'antd';
import React from 'react';

import {PushWithBreadcrumbsFunction} from 'components/table-report/hooks/use-breadcrumbs-controller';
import {performDrilldownPush} from 'components/table-report/utils';
import {TableReportDrillDown} from 'store/slices/table-report-slice/table-report-slice-types';

import './dd-modal.less';

interface DrillDownModalProps{
    entries?: TableReportDrillDown[];
    visible: boolean;
    setVisible: (visibility: boolean) => void;
    pushWithBreadcrumbs: PushWithBreadcrumbsFunction;
}

export const DrillDownModal = ({
    entries,
    visible,
    setVisible,
    pushWithBreadcrumbs,
}: DrillDownModalProps) => (
    <Modal
        className="dd-modal"
        title="Переход на документ"
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
        width={660}
    >
        <span className="dd-modal__name">Для перехода на нужный документ нажмите на название из списка:</span>
        <div className="dd-modal__list">
            {entries?.map(entry => (
                <div
                    key={`${entry?.drillId}${entry?.rowIndex}`}
                    className="dd-modal__item"
                    onClick={() => {
                        performDrilldownPush({
                            ddEntry: entry,
                            pushWithBreadcrumbs,
                        });
                        setVisible(false);
                    }}
                >
                    {`${entry.docTypeName} (${entry.templateCode})`}
                </div>
            ))}
        </div>
    </Modal>
);
