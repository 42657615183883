import {InboxOutlined} from '@ant-design/icons';
import Upload from 'antd/es/upload';
import {UploadChangeParam, UploadFile} from 'antd/es/upload/interface';
import cn from 'classnames';
import React, {useImperativeHandle, useState} from 'react';

import {useAfterEffect} from 'shared/hooks/use-after-effect';
import {FormItemProps} from 'shared/types';
import {StateSetter} from 'shared/types/generics';

import {UploadDropzoneFile} from './upload-dropzone-file';

import './upload-dropzone.less';

export enum UploadDropzoneMode {
    single,
    multiple
}

interface UploadDropzoneProps extends FormItemProps {
    className?: cn.Argument;
    mode?: UploadDropzoneMode;
    clearAfterChange?: boolean;
    handleOnChange?: (info: UploadChangeParam<UploadFile<any>>) => void;
}

export interface UploadDropzoneRef {
    setFileList: StateSetter<UploadFile[]>;
}

export const UploadDropzone = React.forwardRef<UploadDropzoneRef, UploadDropzoneProps>((
    {
        className,
        mode = UploadDropzoneMode.multiple,
        onChange,
        clearAfterChange,
        handleOnChange,
    }: UploadDropzoneProps,
    ref,
) => {
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    useAfterEffect(() => {
        onChange?.(fileList, undefined);
    }, [fileList]);

    useImperativeHandle(ref, () => ({
        setFileList,
    }));

    return (
        <div className={cn('upload-dropzone', className)}>
            <Upload
                multiple={mode === UploadDropzoneMode.multiple}
                name="file"
                // accept={ACCEPTABLE_FILE_EXTENSIONS}
                beforeUpload={() => false}
                fileList={fileList}
                onChange={values => {
                    handleOnChange?.(values);
                    if (mode === UploadDropzoneMode.single) {
                        setFileList(clearAfterChange ? [] : [values.file]);
                        return;
                    }
                    setFileList(clearAfterChange ? [] : values.fileList);
                }}
            >
                {(() => {
                    const zoneJSX = (
                        <div className={cn('upload-dropzone__zone')}>
                            <div className={cn('upload-dropzone__zone__icon')}>
                                <InboxOutlined
                                    color="red"
                                    style={{lineHeight: '20px'}}
                                />
                            </div>
                            <div className={cn('upload-dropzone__zone__tip')}>
                                Перетащите файлы или нажмите, чтобы добавить вложение
                            </div>
                        </div>
                    );

                    if (mode === UploadDropzoneMode.single && fileList.length) return null;
                    return zoneJSX;
                })()}

            </Upload>

            {!!fileList.length && (
                <div className="upload-dropzone__file-list">
                    {fileList.map(file => (
                        <UploadDropzoneFile
                            file={file}
                            setFileList={setFileList}
                        />
                    ))}
                </div>
            )}
        </div>
    );
});
