import {Popover} from 'antd';
import cn from 'classnames';
import React, {useState} from 'react';

import {SimpleActionButton} from 'components/form/components';
import {CustomSelectEntry} from 'components/form/inputs/custom-select';
import {useAppSelector} from 'store/config/hooks';
import {
    selectTableReportConfigurationData,
} from 'store/slices/report-configuration-slice/report-configuration-slice';

import {TableReportCreateSheetModal} from './create-sheet-modal/create-sheet-modal';

interface TableReportCreateSheetButtonProps {
    disabled: boolean;
    addNewSheets: (entry: CustomSelectEntry<string, any>) => void;
    selectedSheets: CustomSelectEntry<string, any>[];
    clearCustomColumns?: () => void;
}

export const TableReportCreateSheetButton = ({
    disabled,
    addNewSheets,
    selectedSheets,
    clearCustomColumns,
}: TableReportCreateSheetButtonProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const reportConfigurationData = useAppSelector(
        selectTableReportConfigurationData,
    );
    const reportMenu = reportConfigurationData?.reportMenu ?? [];

    const {enabledMenu: isMenuEnabled} = reportConfigurationData ?? {};

    return (
        <>
            {isMenuEnabled && (
                <Popover
                    placement="bottom"
                    content={<div>Нет данных в разделе «Настройка оглавления»</div>}
                    overlayClassName={cn({'d-none': !!reportMenu.length})}
                >
                    <div>
                        <SimpleActionButton
                            className="create-sheet-button"
                            disabled={disabled}
                            type="primary"
                            icon="PlusCircleOutlined"
                            title="Новый лист"
                            onClick={() => setIsModalOpen(true)}
                        />
                    </div>
                </Popover>
            )}

            {isModalOpen
                && (
                    <TableReportCreateSheetModal
                        visible={isModalOpen}
                        addNewSheets={addNewSheets}
                        selectedSheets={selectedSheets}
                        closeModal={() => setIsModalOpen(false)}
                        clearCustomColumns={clearCustomColumns}
                    />
                )}
        </>
    );
};
