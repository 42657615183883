import {set} from 'lodash';

import {LocationStateKey, useAppHistory} from 'shared/hooks/use-app-history';

import {TableReportLocationDefaults} from '../table-report-types';

export const useLocationDefaultsManager = () => {
    const history = useAppHistory();

    const saveDefaultsToLocationState = (args: TableReportLocationDefaults) => {
        const {
            locationFilters,
            locationPage,
            locationParameters,
            locationSize,
            locationSort,
        } = args;

        const nextBreadcrumbs = (() => {
            const currentBreadcrumbs = [
                ...history.getLocationState()?.[LocationStateKey.TABLE_REPORT_BREADCRUMBS] ?? [],
            ];
            let breadcrumbs;
            if (currentBreadcrumbs.length) {
                const lastBreadcrumb = currentBreadcrumbs?.pop();
                if (lastBreadcrumb) set(lastBreadcrumb, ['extraData', 'tableReportLocationDefaults'], args);
                breadcrumbs = [...currentBreadcrumbs];
                if (lastBreadcrumb) breadcrumbs.push(lastBreadcrumb);
            }
            return breadcrumbs;
        })();

        history.updateLocationState({
            ...(() => {
                if (!nextBreadcrumbs) return {};
                return {[LocationStateKey.TABLE_REPORT_BREADCRUMBS]: nextBreadcrumbs};
            })(),
            [LocationStateKey.TABLE_REPORT_LOCATION_DEFAULTS]: {
                locationFilters,
                locationPage,
                locationParameters,
                locationSize,
                locationSort,
            },
        });
    };

    return {
        saveDefaultsToLocationState,
    };
};
