import moment from 'moment';

export const DATE = 'YYYY-MM-DD';
export const DATE_WITH_TIME = 'YYYY-MM-DD HH:mm:ss';
export const DATE_WITH_TIME_DOTS_SECONDS_12 = 'DD.MM.YYYY hh:mm:ss';
export const DATE_WITH_TIME_DOTS_SECONDS_24 = 'DD.MM.YYYY HH:mm:ss';
export const DATE_WITH_TIME_DOTS = 'DD.MM.YYYY HH:mm';
export const DATE_FOR_REQUEST = 'YYYY-MM-DDTHH:mm:ss.SSSZZ';
export const DATE_DMY = 'DD.MM.YYYY';
export const RESOLVED_FORMATS = [DATE_WITH_TIME, DATE_FOR_REQUEST, DATE_DMY, moment.ISO_8601];
export const TIME = 'HH:mm';
export const DATE_FORMATS_PLACEHOLDERS_MAP = new Map([
    [DATE, 'ГГГГ-ММ-ДД'],
    [DATE_WITH_TIME, 'ГГГГ-ММ-ДД ЧЧ:ММ:CC'],
    [DATE_WITH_TIME_DOTS, 'ГГГГ.ММ.ДД ЧЧ.ММ'],
    [DATE_DMY, 'ДД.ММ.ГГГГ'],
    [TIME, 'ЧЧ:ММ'],
    [DATE_WITH_TIME_DOTS_SECONDS_12, 'DD.MM.YYYY hh:mm:ss']]);
export const DEFAULT_FORMAT_PLACEHOLDER = 'ДД.MM.ГГГГ';

export const MONTHS = [
    {value: 1, label: 'Январь'},
    {value: 2, label: 'Февраль'},
    {value: 3, label: 'Март'},
    {value: 4, label: 'Апрель'},
    {value: 5, label: 'Май'},
    {value: 6, label: 'Июнь'},
    {value: 7, label: 'Июль'},
    {value: 8, label: 'Август'},
    {value: 9, label: 'Сентябрь'},
    {value: 10, label: 'Октябрь'},
    {value: 11, label: 'Ноябрь'},
    {value: 12, label: 'Декабрь'},
];
