import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {AppState} from 'store/config/types';

import {controlRatioSliceName} from './control-ratio-slice-constants';
import {ControlRatioSliceState} from './control-ratio-slice-types';

export const controlRatioSlice = createSlice({
    name: controlRatioSliceName,
    initialState: {
        formAdditionalParameters: null,
    } as ControlRatioSliceState,
    reducers: {
        setFormAdditionalParameters(state, {payload}: PayloadAction<Record<string, any>>) {
            state.formAdditionalParameters = payload;
        },
        resetFormAdditionalParameters(state) {
            state.formAdditionalParameters = null;
        },
    },
});

const selectControlRatioSliceState = (state: AppState) => state[controlRatioSliceName];

export const controlRatioSelectors = {
    selectFormAdditionalParameters: createSelector(
        selectControlRatioSliceState,
        state => state.formAdditionalParameters,
    ),
};

export const {
    reducer: controlRatioReducer,
    actions: controlRatioActions,
} = controlRatioSlice;
