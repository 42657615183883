import {createSelector, Selector} from '@reduxjs/toolkit';

import {AppState} from 'store/config/types';

import {REGIONS_MODULE} from './regions-constants';
import {RegionResponse, RegionsState} from './regions-types';

const rootSelector: Selector<AppState, RegionsState> = (state: AppState) => state[REGIONS_MODULE];

export const selectRegions: Selector<AppState, RegionResponse[]|undefined> = createSelector(
    rootSelector,
    ({data}: RegionsState) => data,
);
