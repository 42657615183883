import {createApi} from '@reduxjs/toolkit/query/react';

import {
    DashboardFilterEntity,
    DashboardEntity,
} from '../../../components/personal-account/tabs/dashboards/dashboards-types';
import {axiosBaseQuery} from '../base-query';

const dashboardApiUrl = '/settings.user.dashboards';

export const DashboardAPI = createApi({
    reducerPath: 'dashboardAPI',
    baseQuery: axiosBaseQuery(),
    endpoints: build => ({
        fetchAllDashboards: build.query<DashboardEntity[], null>({
            query: () => ({
                url: dashboardApiUrl,
            }),
        }),
        // селектором превратить в объект
        fetchAllDashboardsSelects: build.query<DashboardFilterEntity[], null>({
            query: () => ({
                url: `${dashboardApiUrl}/filters`,
            }),
        }),
        fetchAllDashboardsPost: build.mutation<DashboardEntity[], DashboardEntity[] | undefined>({
            query(post) {
                return {
                    url: dashboardApiUrl,
                    method: 'POST',
                    data: post,
                };
            },
        }),
        fetchAllDashboardsSelectsPost: build.mutation<DashboardFilterEntity[], DashboardFilterEntity[] | undefined>({
            query(post) {
                return {
                    url: `${dashboardApiUrl}/filters`,
                    method: 'POST',
                    data: post,
                };
            },
        }),
    }),
});
