import type {BaseQueryFn} from '@reduxjs/toolkit/query';
import type {AxiosRequestConfig, AxiosError} from 'axios';

import {performRequest} from 'shared/utils';

export const axiosBaseQuery = (): BaseQueryFn<AxiosRequestConfig> => async config => {
    try {
        const result = await performRequest(config);
        return {data: result.data};
    } catch (axiosError) {
        const err = axiosError as AxiosError;
        return {
            error: {
                status: err.response?.status,
                data: err.response?.data || err.message,
            },
        };
    }
};
