import {
    DeleteOutlined,
} from '@ant-design/icons';
import {Popover, Progress} from 'antd';
import cn from 'classnames';
import React from 'react';

import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {DraggableFrameEntity, DraggableFrameType} from 'store/slices/draggable-frames-slice';
import {
    draggableFramesActions,
} from 'store/slices/draggable-frames-slice/draggable-frames-slice';
import {ATTACHMENT_IS_LOADED_PERCENT, tableReportRowAttachmentsSelectors} from 'store/slices/table-report-slice/table-report-row-attachments';
import {TableReportUploadAttachmentsFilesProgressItem} from 'store/slices/table-report-slice/table-report-row-attachments/table-report-row-attachments-types';
import {tableReportSliceActions} from 'store/slices/table-report-slice/table-report-slice';

interface TableReportAttachmentUploadProgressItemProps {
    progressItem: TableReportUploadAttachmentsFilesProgressItem;
    frameEntity?: Partial<DraggableFrameEntity>;
}

export const TableReportAttachmentUploadProgressItem: React.FC<TableReportAttachmentUploadProgressItemProps> = (
    {progressItem, frameEntity}: TableReportAttachmentUploadProgressItemProps,
) => {
    const dispatch = useAppDispatch();

    const {setUploadProgress} = tableReportSliceActions;
    const {closeDraggableFrame} = draggableFramesActions;
    const {selectTableReportUploadAttachmentsFilesProgress} = tableReportRowAttachmentsSelectors;

    const uploadProgressList = useAppSelector(selectTableReportUploadAttachmentsFilesProgress);

    const {
        uploadData, templateCode, docId, reportRowId,
    } = progressItem;

    const {templateName} = uploadData;

    return (
        <div className={cn('table-report-attachment-upload-progress-item')}>
            <div className={cn('table-report-attachment-upload-progress-item__title')}>
                {
                    uploadData.loadProgressPercent === ATTACHMENT_IS_LOADED_PERCENT && (
                        <Popover
                            mouseEnterDelay={1}
                            placement="left"
                            content={(
                                <div>
                                    Удалить информацию о загрузке
                                </div>
                            )}
                        >
                            <DeleteOutlined
                                onClick={() => {
                                    if (uploadProgressList.length === 1) {
                                        dispatch(closeDraggableFrame({
                                            type: DraggableFrameType.TableReportAttachmentUploadFrame,
                                            id: frameEntity?.id,
                                        }));
                                    }
                                    dispatch(setUploadProgress({
                                        reportRowId,
                                        templateCode,
                                        uploadData: undefined,
                                        docId,
                                    }));
                                }}
                                className={cn('table-report-attachment-upload-progress-item__title__icon')}
                            />
                        </Popover>

                    )
                }

                <Popover
                    placement="bottom"
                    className="overflow-hidden"
                    content={(
                        <div>
                            {docId && <div style={{marginBottom: 8}}>ID документа: {docId}</div>}
                            <div>
                                <div>Переданные файлы:</div>
                                {uploadData.files?.map((file, index) => (
                                    <div>
                                        {index + 1}. {file.name}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                >
                    {templateName ?? templateCode} (Cтрока: {reportRowId}, Файлов: {uploadData.files?.length})
                </Popover>
            </div>
            <Progress percent={uploadData.loadProgressPercent} />
        </div>
    );
};
