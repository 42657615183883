import {FormInstance} from 'antd/es/form';
import React, {Dispatch, SetStateAction} from 'react';

import {EntityValue} from 'modules/data';

export type EditableFormTable = {
    form: FormInstance<any>[];
    initData?: Record<string, EntityValue>[];
    setForm?: Dispatch<SetStateAction<FormInstance<any>[]>>;
}

const EditableFormTableInitState = {
    initData: [],
    form: [],
    setForm: undefined,
};

export const EditableFormTableContext = React.createContext<EditableFormTable>(EditableFormTableInitState);
