import {Button, Form, Input} from 'antd';
import {QRCodeCanvas} from 'qrcode.react';
import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';

import {DynamicIcon} from 'components/dynamic-icon';
import {ReactComponent as UserIcon} from 'shared/assets/auth/user.svg';
import {URL_LOGIN} from 'shared/constants/urls';
import {useQueryParams} from 'shared/utils/query-params';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {getTwoFACode, TwoFactorAuthStatus} from 'store/slices/auth-slice';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

import {TwoFactorAuthFormType} from './two-factor-auth-page-types';

import 'pages/two-factor-auth-page/two-factor-auth-form.less';

export const TwoFactorAuthPageForm = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const {getQueryParam} = useQueryParams();

    const isLoading = useAppSelector(s => selectIsThunkPending(s, getTwoFACode.typePrefix));
    const [username, setUsername] = useState('');
    const [currentForm, setCurrentForm] = useState<TwoFactorAuthFormType>(TwoFactorAuthFormType.CONNECT_FORM);
    const [status, setStatus] = useState<TwoFactorAuthStatus | null>(null);
    const qrCodeValue = decodeURIComponent(getQueryParam('qr') || '');

    useEffect(() => {
        if (qrCodeValue) setCurrentForm(TwoFactorAuthFormType.QR_CODE_FORM);
    }, []);

    const handleGetCodeBtnClick = () => {
        setStatus(null);
        dispatch(getTwoFACode({username}))
            .then(data => {
                setStatus(data?.payload ?? null);
                if (!data?.payload?.isError) {
                    setCurrentForm(TwoFactorAuthFormType.MESSAGE_SENT_FORM);
                }
            });
    };

    const getFormData = () => {
        let header;
        let fields;
        switch (currentForm) {
        case TwoFactorAuthFormType.QR_CODE_FORM:
            header = (
                <>
                    <h1>Авторизация с 2fa</h1>
                    <div className="description qrcode-form__fields__description">
                        <h2>
                            Инструкция:
                        </h2>
                        <div className="description__composite">
                            <p>
                                1. Откройте приложение Google Authenticator<br />
                                2. Нажмите + в правом нижнем углу<br />
                                3. Выберите Сканировать QR-код<br />
                                4. Наведите камеру на QR-код<br />
                            </p>
                            <p>
                                В приложении добавится строка с наименованием TAX_MON: *почта,
                                Указанная в личном кабинете в Витрине*. Синим цветом
                                будет выделен 6-тизначный код, необходимый для входа.
                            </p>
                        </div>
                    </div>
                </>
            );
            fields = (
                <>
                    <Form.Item>
                        <Button
                            type="primary"
                            size="large"
                            block
                            onClick={() => history.push(URL_LOGIN)}
                        >
                            Ввести данные
                        </Button>
                    </Form.Item>
                </>
            );
            return {
                header,
                fields,
            };
        case TwoFactorAuthFormType.MESSAGE_SENT_FORM:
            header = (
                <>
                    <h1>
                        Подключение к 2fa
                    </h1>
                    <div className="description message-sent-form__header__description">
                        <p>
                            Сообщение было отправлено на email.<br />
                            Если ничего не пришло, попробуйте снова или обратитесь к администратору системы.
                        </p>
                    </div>
                </>
            );
            fields = (
                <>
                    <Form.Item>
                        <Link
                            to={URL_LOGIN}
                        >
                            <Button
                                type="primary"
                                size="large"
                                block
                                loading={isLoading}
                            >
                                Авторизоваться с помощью 2fa
                            </Button>
                        </Link>
                    </Form.Item>
                    <Form.Item name="try-again-button">
                        <Button
                            className="try-again-button"
                            type="link"
                            size="large"
                            block
                            onClick={() => setCurrentForm(TwoFactorAuthFormType.CONNECT_FORM)}
                            loading={isLoading}
                        >
                            Попробовать еще раз
                        </Button>
                    </Form.Item>
                </>
            );
            return {
                header,
                fields,
            };
        default:
            header = (
                <>
                    <h1>
                        Подключение к 2fa
                    </h1>
                    <div
                        className="description connect-form__header__description"
                    >
                        <h2>
                            Примечание:
                        </h2>
                        <p>
                            Для двухфакторной аутентификации необходимо установить<br />
                            мобильное приложение Google Authenticator на ваше устройство.
                            <br />
                            <br />
                            Пожалуйста, укажите имя пользователя и мы отправим ссылку
                            для подключения на email-почту
                        </p>
                    </div>
                </>
            );
            fields = (
                <>
                    {status && (
                        <div className="connect-form__fields__error">
                            <DynamicIcon
                                type="ExclamationCircleOutlined"
                            />
                            {status.message}
                        </div>
                    )}
                    <Form.Item
                        name="username"
                        rules={[{
                            required: true,
                            message: 'Введите имя пользователя',
                        }]}
                    >
                        <Input
                            disabled={isLoading}
                            size="large"
                            onChange={e => setUsername(e.target.value)}
                            prefix={<UserIcon />}
                            placeholder="Пользователь"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            size="large"
                            block
                            onClick={handleGetCodeBtnClick}
                            loading={isLoading}
                        >
                            Получить ссылку
                        </Button>
                    </Form.Item>
                </>
            );
            return {
                header,
                fields,
            };
        }
    };

    const {header, fields} = getFormData();

    return (
        <Form
            className={`two-fa-form ${currentForm}`}
        >
            <div className={`two-fa-form__container ${currentForm}__container`}>
                <div className={`two-fa-form__container__header ${currentForm}__header`}>
                    {header}
                </div>
                <div className={`two-fa-form__container__fields ${currentForm}__fields`}>
                    {fields}
                </div>
                {qrCodeValue && currentForm === TwoFactorAuthFormType.QR_CODE_FORM
                    && (
                        <QRCodeCanvas
                            className="two-fa-form__container__qr"
                            value={qrCodeValue || ''}
                            size={315}
                            bgColor="transparent"
                            fgColor="white"
                        />
                    )}
            </div>
        </Form>
    );
};
