import {FileExcelOutlined} from '@ant-design/icons';
import {Checkbox, Form} from 'antd';
import {FormInstance} from 'antd/es/form';
import Input from 'antd/es/input';
import {useForm} from 'antd/lib/form/Form';
import cn from 'classnames';
import React, {useEffect, useState} from 'react';

import {CryptoProSignature} from 'components/@common/specific/crypto-pro/crypto-pro-signature/crypto-pro-signature';
import {CustomSelect} from 'components/form/inputs/custom-select';
import {UIBlocker} from 'components/ui-blocker';
import {StateSetter} from 'shared/types/generics';
import {
    CertificateExtended, CryptoProData, getCryptoProData,
} from 'shared/utils/crypto-pro';

import {DocumentToSignInDatabase} from '../crypto-pro-signing-input-types';
import {getOverlappingFiles} from '../crypto-pro-signing-input-utils';

interface CryptoProSigningModalProps {
    selectedCertificate: CertificateExtended | undefined;
    setSelectedCertificate: StateSetter<CertificateExtended | undefined>;
    isWithPluginInitializationError: boolean;
    setIsWithPluginInitializationError: StateSetter<boolean>;
    handleFinish: () => void;
    isWithResign: boolean;
    setIsWithResign: StateSetter<boolean>;
    fileFieldNames?: string[];
    currentValue?: File[];
    mainForm?: FormInstance;
    documentsToSignInDB?: DocumentToSignInDatabase[];
}

export const CryptoProSigningInputModal = React.forwardRef<
FormInstance, CryptoProSigningModalProps>(({
    selectedCertificate,
    setSelectedCertificate,
    isWithPluginInitializationError,
    setIsWithPluginInitializationError,
    handleFinish,
    isWithResign,
    setIsWithResign,
    fileFieldNames,
    currentValue,
    mainForm,
    documentsToSignInDB,
}: CryptoProSigningModalProps, ref) => {
    const [cryptoProData, setCryptoProData] = useState<CryptoProData>();

    const [form] = useForm();

    useEffect(() => {
        (async () => {
            try {
                const data = await getCryptoProData();
                setCryptoProData(data);
            } catch (e) {
                console.warn(e);
                setIsWithPluginInitializationError(true);
            }
        })();
    }, []);

    const {overlappingFiles, overlappingFileNamesInDB} = getOverlappingFiles({
        form: mainForm,
        currentValue,
        fileFieldNames,
        documentsToSignInDB,
    });

    return (
        <div className={cn('crypto-pro-signature-modal-content')}>
            <UIBlocker content={isWithPluginInitializationError ? (
                <div className="d-flex flex-column align-items-center justify-content-center text-center">
                    <div className="fw-500">Произошла ошибка инициализации плагина ЭЦП</div>
                    <div
                        className="mt-1"
                        style={{fontSize: 12}}
                    >
                        Связь с &quot;КриптоПро ЭЦП Browser plug-in&quot; не установлена или не найдена
                        информация о криптопровайдере
                    </div>
                </div>
            ) : undefined}
            >
                <Form
                    ref={ref}
                    form={form}
                    layout="vertical"
                    className="form-flex"
                    onFinish={handleFinish}
                >
                    {!!(overlappingFiles?.length || overlappingFileNamesInDB.length) && (
                        <>
                            <div className={cn('crypto-pro-signature-modal-content__overlapping')}>
                                <div className={cn('crypto-pro-signature-modal-content__overlapping__warning')}>
                                    <FileExcelOutlined />
                                    Некоторые из выбранных документов уже имеют подпись
                                </div>
                                {overlappingFiles.map((file, index) => (
                                    <div key={file.name}>
                                        {index + 1}. {file.name}
                                    </div>
                                ))}
                                {overlappingFileNamesInDB.map((fileName, index) => (
                                    <div key={fileName}>
                                        {overlappingFiles.length + index + 1}. {fileName}
                                    </div>
                                ))}
                            </div>
                            <Checkbox
                                style={{marginTop: 2}}
                                checked={isWithResign}
                                onChange={e => setIsWithResign(e.target.checked)}
                            >Переподписать документы
                            </Checkbox>
                        </>
                    )}
                    <Form.Item label="Криптопровайдер">
                        <Input
                            value="Crypto-Pro GOST R 34.10-2012 Cryptographic Service Provider"
                            disabled
                        />
                    </Form.Item>
                    <Form.Item label="Версия криптопровайдера">
                        <Input
                            value={cryptoProData?.systemInfo?.cspVersion}
                            disabled
                            placeholder="Версия неизвестна"
                        />
                    </Form.Item>
                    <Form.Item label="Версия плагина">
                        <Input
                            value={cryptoProData?.systemInfo?.cadesVersion}
                            disabled
                            placeholder="Версия неизвестна"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Сертификат"
                        required
                        name="certificate"
                        rules={[{required: true, message: 'Пожалуйста, выберите сертификат'}]}
                    >
                        <CustomSelect
                            settings={{
                                placeholder: 'Выберите сертификат',
                                showSearch: true,
                            }}
                            onChange={value => {
                                setSelectedCertificate(cryptoProData?.certificatesExtended
                                    .find(cert => cert.thumbprint === value));
                            }}
                            entries={cryptoProData?.certificatesExtended.map(cert => ({
                                label: (() => {
                                    const {certificate} = cert;
                                    if (certificate.name) return `${certificate.name} (${certificate.issuerName})`;
                                    return certificate.issuerName;
                                })(),
                                value: cert.thumbprint,
                            }))}
                        />
                    </Form.Item>

                    {selectedCertificate && (
                        <div className="mt-2">
                            <CryptoProSignature certificate={selectedCertificate} />
                        </div>
                    )}
                </Form>
            </UIBlocker>
        </div>
    );
});
