import cn from 'classnames';
import React from 'react';

import {DashboardEntry} from 'modules/data/data-types';

import {WidgetWrapper} from '../../components/widget-wrapper';
import {useStatisticsInitialization} from '../../hooks/use-statistics-initialization';
import {StatisticsEmptyPlaceholder} from '../statistics-empty-placeholder';
import {CircleChart} from './circle-chart';

import './circle-statistics.less';

export interface CircleStatisticsProps {
    entries: DashboardEntry;
    title: string;
}

export const CircleStatistics: React.FC<CircleStatisticsProps> = ({title, entries}: CircleStatisticsProps) => {
    const {statisticsData} = useStatisticsInitialization({entries});
    return (
        <WidgetWrapper title={title}>
            <div className={cn('circle-statistics', {empty: !statisticsData.length})}>
                <div className={cn('circle-statistics__chart')}>
                    {statisticsData?.length
                        ? <CircleChart statisticsData={statisticsData} /> : <StatisticsEmptyPlaceholder />}
                </div>
                <div className={cn('circle-statistics__description')}>
                    {
                        statisticsData.map(entry => (
                            <div
                                key={entry.key}
                                className={cn('circle-statistics__description__item-row')}
                            >
                                <div
                                    style={{backgroundColor: entry.color}}
                                    className={cn('circle-statistics__description__item-row__border')}
                                />
                                <div className={cn('circle-statistics__description__item-row__item')}>
                                    <span className={cn('circle-statistics__description__item-row__item__title')}>
                                        {entry.key}
                                    </span>
                                    <span className={cn('circle-statistics__description__item-row__item__value')}>
                                        {entry.value}
                                    </span>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </WidgetWrapper>
    );
};
