import {createApi} from '@reduxjs/toolkit/query/react';

import {performRequest} from 'shared/utils';

import {axiosBaseQuery} from '../base-query';
import {createDataProperties} from './utils';

export interface GetPropertyRequest {
    propertyCode?: string;
}

export interface GetPropertyListRequest {
    propertyCodeList?: string[];
}

export const AppPropertiesApi = createApi({
    reducerPath: 'appProperties',
    baseQuery: axiosBaseQuery(),
    endpoints: builder => ({
        getAppProperty: builder.query<any, GetPropertyRequest>({
            query: ({propertyCode}) => ({
                url: `/property/${propertyCode}`,
                method: 'GET',
            }),
        }),
        getAppPropertySettingsList: builder.query<any, GetPropertyListRequest>({
            async queryFn({propertyCodeList = []}) {
                try {
                    const allProperties = await Promise.all(propertyCodeList.map(
                        async propertyItem => {
                            const response = await performRequest({
                                url: `/property/${propertyItem}`,
                                method: 'GET',
                            });
                            return {[propertyItem]: response.data};
                        },
                    ));
                    return {data: createDataProperties(allProperties)};
                } catch (error) {
                    return {error};
                }
            },
        }),
    }),
});

export const {useGetAppPropertyQuery, useGetAppPropertySettingsListQuery} = AppPropertiesApi;
