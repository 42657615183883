import {Button} from 'antd';
import cn from 'classnames';
import React from 'react';

import {EntityValue} from 'modules/data';
import './number-square.less';
import {FieldType} from 'modules/metadata';

interface IOwnProps {
    variant: NumberSquareVariant;
    value?: EntityValue;
}

export const NumberSquare = ({value, variant}: IOwnProps) => (
    value ? (
        <Button className={cn('number-square', variant)}>
            {value}
        </Button>
    ) : <></>
);

enum NumberSquareVariant { PRIMARY = 'primary', DEFAULT = 'default'}

export const FIELD_TYPE_TO_SQUARE_VARIANT_MAP = {
    [FieldType.NUMBER_SQUARE_PRIMARY]: NumberSquareVariant.PRIMARY,
    [FieldType.NUMBER_SQUARE_DEFAULT]: NumberSquareVariant.DEFAULT,
};
