import {createAsyncThunk} from '@reduxjs/toolkit';

import {
    fetchNetDirectoryFileNameListData,
    NetDirectoryFileArgs,
    postDocumentUploadTemplateConfiguration,
} from './net-directory-file-slice-api';
import {
    netDirectoryFileSliceName,
} from './net-directory-file-slice-constants';
import {NetDirectoryFileData} from './net-directory-file-slice-type';

export const loadNetDirectoryFileNameList = createAsyncThunk<string[]>(
    `${netDirectoryFileSliceName}/loadNetDirectoryFileNameList`,
    async (_, {rejectWithValue}) => {
        try {
            const response = await fetchNetDirectoryFileNameListData();
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const loadNetDirectoryFile = createAsyncThunk<
    NetDirectoryFileData, NetDirectoryFileArgs
>(
    `${netDirectoryFileSliceName}/loadNetDirectoryFile`,
    async (args, {rejectWithValue}) => {
        try {
            const response = await postDocumentUploadTemplateConfiguration(args);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);
