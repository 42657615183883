import {FieldType} from 'modules/metadata';

import {FORM_OPTIONS} from '../form/form.options';
import {TABLE_OPTIONS} from '../table/table.options';

const FIELD_TYPES = [
    ...TABLE_OPTIONS.FIELD_TYPES,
    {
        label: 'CHECKBOX',
        value: FieldType.CHECKBOX,
    },
];

export const FORM_TABLE_OPTIONS = {
    FIELD_TYPES,
    ACTION_TYPES: [
        {
            label: 'FILTER',
            value: 'FILTER',
        },
        ...FORM_OPTIONS.ACTION_TYPES,
    ],
};
