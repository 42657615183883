import cn from 'classnames';
import React, {
    useEffect, useRef, useState,
} from 'react';

import {ReactComponent as ArrowDown} from 'shared/assets/arrow-down-filled.svg';
import {useAppHistory} from 'shared/hooks/use-app-history';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {
    generateRoute,
    selectIsMenuOpen,
    selectMenuSearchLine,
    selectMenuStructure,
    selectSelectedMenuItem,
} from 'store/slices/menu-slice';
import {menuSliceActions} from 'store/slices/menu-slice/menu-slice';
import {MenuStructureItem} from 'store/slices/menu-slice/menu-slice-types';

import {checkIfIsTopLevelMenuItemSelected} from '../../new-side-menu-utils';
import {NewSideMenuList} from '../new-side-menu-list';

interface NewSideMenuListItemProps {
    item: MenuStructureItem;
    path?: string;
    depth: number;
}

// todo: все проверки на depth - костыли, так как на текущий момент
// умеем поддерживать только двухуровневое меню.
export const NewSideMenuListItem = ({
    item,
    path = '',
    depth,
}: NewSideMenuListItemProps) => {
    const dispatch = useAppDispatch();

    const history = useAppHistory();

    const [isOpen, setIsOpen] = useState(false);

    const menuStructure = useAppSelector(selectMenuStructure);

    const {setSelectedMenuItem, setIsMenuOpen, setSearchLine} = menuSliceActions;

    const hasChildren = !!item.children?.length;

    const searchLine = useAppSelector(selectMenuSearchLine);

    const isMenuOpen = useAppSelector(selectIsMenuOpen);
    const selectedMenu = useAppSelector(selectSelectedMenuItem);

    const isSelected = history.location.pathname === generateRoute(item);

    const isTopLevelSelected = checkIfIsTopLevelMenuItemSelected({
        item,
        menuStructure,
        pathname: history.location.pathname,
    });

    const itemRef = useRef<HTMLDivElement>(null);

    const handleItemClick = () => {
        if (!depth) {
            if (!isOpen) {
                dispatch(setSelectedMenuItem(item));
                setIsOpen(true);
            } else setIsOpen(false);
        } else {
            if (depth === 1) {
                // todo: проверка на глубину - костыль, нужно думать над поддержкой большой вложенности
                history.push(generateRoute(item));
                dispatch(setSearchLine(''));
                dispatch(setIsMenuOpen(false));
                return;
            }
            if (hasChildren) setIsOpen(p => !p);
            else {
                history.push(generateRoute(item));
                dispatch(setSearchLine(''));
                dispatch(setIsMenuOpen(false));
            }
        }
    };

    useEffect(() => {
        if (searchLine === '') {
            setIsOpen(item.id === selectedMenu?.id || isTopLevelSelected);
        } else if (!isOpen) setIsOpen(true);
    }, [searchLine]);

    useEffect(() => {
        if (!isMenuOpen) {
            setTimeout(() => {
                setIsOpen(false);
            }, 200);
        } else if (item.id === selectedMenu?.id || isTopLevelSelected) setIsOpen(true);
    }, [isMenuOpen]);

    useEffect(() => {
        if (isMenuOpen && isSelected && depth > 0) {
            itemRef.current?.scrollIntoView({block: 'center'});
        }
    }, [isMenuOpen]);

    useEffect(() => {
        if (isMenuOpen) setIsOpen(item.id === selectedMenu?.id);
    }, [selectedMenu]);

    return (
        <>
            <div
                ref={itemRef}
                className={cn(
                    'new-side-menu__list__item',
                    {
                        'new-side-menu__list__item_depth-1': depth === 1,
                        'new-side-menu__list__item_selected': isSelected && depth === 1,
                        'new-side-menu__list__item_child': depth > 0,
                    },
                    'no-select',
                )}
                onClick={() => {
                    handleItemClick();
                }}
            >
                <a
                    href={generateRoute(item)}
                    onClick={e => e.preventDefault()}
                >
                    <div
                        className={cn(
                            'new-side-menu__list__item__item-title',
                            {
                                'new-side-menu__list__item__item-title_selected':
                            item.id === selectedMenu?.id || isTopLevelSelected,
                            },
                        )}
                        style={{paddingLeft: depth * 24}}
                    >
                        {item.itemTitle}
                    </div>
                </a>
                <div>
                    {/* (depth < 1) - костыль, нужно думать над поддержкой большой вложенности */}
                    {hasChildren && (depth < 1) && (
                        <ArrowDown className={cn(
                            'new-side-menu__list__item__arrow',
                            {'new-side-menu__list__item__arrow_rotated': isOpen},
                        )}
                        />
                    )}
                </div>
            </div>
            {hasChildren && isOpen && (
                <div className={cn('new-side-menu__list__child')}>
                    <NewSideMenuList
                        items={item.children}
                        path={!depth ? '' : `${path}${item.entityName}/`}
                        depth={depth + 1}
                    />
                </div>
            )}
        </>
    );
};
