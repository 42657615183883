import React from 'react';

import './svc-main-page-section-title.less';

interface SvcMainPageSectionTitleProps {
    title: string;
}

export const SvcMainPageSectionTitle: React.FC<SvcMainPageSectionTitleProps> = (
    {
        title,
    }: SvcMainPageSectionTitleProps,
) => (
    <div className="svc-main-page-section-title">
        <div>{title}</div>
        <div className="svc-main-page-section-title__separator" />
    </div>
);
