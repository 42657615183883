import React from 'react';

import {FormMode} from 'components/form';

import {CustomFormEntityName} from './custom-form-modal-constants';
import {MenuSettingsModal} from './menu-settings';

export const customFormsMapping = {
    [CustomFormEntityName.menuSettings]: MenuSettingsModal,
};

export interface CustomFormModalProps {
    entityName: CustomFormEntityName;
    entityId?: string;
    mode: FormMode;
    handleClose?: () => void;
}

export const CustomFormModal: React.FC<CustomFormModalProps> = ({
    entityName,
    entityId,
    mode,
    handleClose,
}: CustomFormModalProps) => {
    const FormModalComponent = customFormsMapping[entityName as CustomFormEntityName];
    if (FormModalComponent) {
        return (
            <FormModalComponent
                entityName={entityName}
                entityId={entityId}
                mode={mode}
                handleClose={handleClose}
            />
        );
    }
    return null;
};
