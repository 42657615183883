import {Tabs} from 'antd';
import React from 'react';

import {DirectoryEntityMeta} from 'modules/metadata';

import {DirectoryEmptyDataPlaceholder} from '../empty-data-placeholder/empty-data-placeholder';
import {DirectoryFormItem} from './directory-form-item';

export interface DirectoryFormItemContainerProps {
    url: string;
    metadata: DirectoryEntityMeta;
    currentTab: string | undefined;
    data?: Record<string, any>[];
    setCurrentTab: (tab: string) => void;
}

export const DirectoryFormItemContainer = ({
    setCurrentTab, currentTab, metadata, url, data,
}: DirectoryFormItemContainerProps) => (
    <div className="directory-tabs">
        {data && data.length > 0
            ? (
                <Tabs
                    tabPosition="left"
                    onChange={tab => {
                        setCurrentTab(tab);
                    }}
                    destroyInactiveTabPane
                    defaultActiveKey={currentTab}
                >
                    {data.map(entry => (
                        <Tabs.TabPane
                            className="directory-form-tab-content"
                            tab={entry[metadata.titleField || 'id'] as string}
                            key={entry[metadata.linkField || 'id'] as string}
                        >
                            <DirectoryFormItem
                                url={url}
                                data={entry}
                                metadata={metadata}
                                referenceUrl={`${metadata.name}/${currentTab}`}
                            />
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            )
            : <DirectoryEmptyDataPlaceholder />}
    </div>
);
