import React from 'react';
import {useDispatch} from 'react-redux';

import {actionForRequests} from 'modules/data/data-actions';
import {selectTableSelectedRowKeys} from 'modules/data/data-selectors';
import {MetaActionType, RequestType} from 'modules/metadata';
import {CommonMessages} from 'shared/constants/messages';
import {showMessage} from 'shared/utils';
import {useAppSelector} from 'store/config/hooks';

import {ActionButton} from '../action-button';
import {TableActionProps} from '../table-action-types';

export const ButtonActionRequests: React.FunctionComponent<TableActionProps> = ({
    meta,
    wrapper,
    entityName,
    ...props
}: TableActionProps) => {
    const dispatch = useDispatch();
    const selectedRowKeys = useAppSelector(selectTableSelectedRowKeys(entityName)) || [];

    const handleClick = async () => {
        const {requestType, referenceUrl, actionType} = meta;
        const idsList: string[] = selectedRowKeys.map((row: number | string) => String(row));
        if (referenceUrl) {
            if (actionType === MetaActionType.BUTTON_MARK_AS_READ) {
                if (selectedRowKeys?.length === 0) {
                    showMessage({message: CommonMessages.SELECT_QUERY, isError: true});
                }
                dispatch(
                    actionForRequests(entityName, requestType || RequestType.POST, referenceUrl, idsList),
                );
            }
            if (meta?.actionType === MetaActionType.BUTTON_MARK_ALL_AS_READ) {
                dispatch(
                    actionForRequests(entityName, requestType || RequestType.POST, referenceUrl),
                );
            }
        }
    };
    const Component = wrapper || ActionButton;
    return (
        <Component
            {...props}
            onClick={handleClick}
            actionIcon={meta.actionIcon}
            disabled={!selectedRowKeys.length}
        >
            {meta.actionTitle}
        </Component>
    );
};
