import {Table} from 'antd';
import React from 'react';

import {LinkDto} from 'shared/types/links';

import './custom-link-modal-table-view.less';

interface CustomLinkModalTableViewProps {
    link?: LinkDto;
    tableData?: Record<string, string | number>[];
}

export const CustomLinkModalTableView = ({
    link,
    tableData,
}: CustomLinkModalTableViewProps) => {
    const {
        modalViewProperties,
    } = link ?? {};

    const {
        tableColumns,
    } = modalViewProperties ?? {};

    return (
        <Table
            className="custom-link-modal-table-view"
            dataSource={tableData}
            columns={Object.keys(tableData?.[0] ?? []).map(tableKey => ({
                dataIndex: tableKey,
                key: tableKey,
                title: tableColumns?.[tableKey] ?? tableKey,
                render: v => {
                    if (typeof v === 'string') {
                        if (v.startsWith('http://')) {
                            return (
                                <a
                                    target="_blank"
                                    href={v}
                                    rel="noopener noreferrer"
                                >{v}
                                </a>
                            );
                        }
                    }
                    return v;
                },
            }))}
        />
    );
};
