import {Form} from 'antd';
import {FormInstance} from 'antd/es/form';
import cn from 'classnames';
import {upperFirst} from 'lodash';
import React, {useMemo} from 'react';

import {Property} from '../../propperty-settings.types';
import {propertySettingsFieldResolver} from './property-settings-field-resolver';
import {
    getValidationDependencies,
    propertySettingsValidationResolver,
} from './property-settings-field-validation-resolver';
import {ADDITIONAL_FIELD_PROPERTIES, PROPERTY_WIDTH} from './property-settings-field.constants';

interface PropertySettingsFieldProps {
    property : Property;
    isEdit: boolean;
    form: FormInstance;
}

export const PropertySettingsField: React.FunctionComponent<PropertySettingsFieldProps> = (
    {property, isEdit, form}: PropertySettingsFieldProps,
) => {
    const {
        propertyCode,
        propertyName,
        validateRegexp,
        propertyValue,
        validateMessage,
        selectValue,
        propertyType,
        propertySource,
    } = property;

    const Component = useMemo(() => propertySettingsFieldResolver({
        defaultValue: propertyValue.propertyValue,
        selectValue,
        disabled: !isEdit,
        additional: ADDITIONAL_FIELD_PROPERTIES?.[propertyCode],
        validateRegexp,
        propertyCode,
        form,
        propertyType,
        propertySource,
    }), [isEdit, propertyCode]);

    return (
        <div className={cn('field')}>
            <Form.Item
                style={{width: PROPERTY_WIDTH[propertyCode] || 669}}
                label={upperFirst(propertyName)}
                name={propertyCode}
                dependencies={getValidationDependencies(propertyCode)}
                rules={propertySettingsValidationResolver(
                    {
                        form,
                        validateRegexp,
                        validateMessage,
                        propertyCode,
                    },
                )}
            >
                <Component />
            </Form.Item>
        </div>
    );
};
