import {FormInstance} from 'antd/es/form';
import {Store} from 'antd/lib/form/interface';
import {
    omit, omitBy, setWith,
} from 'lodash';

import {isConditionValueUnnecessary} from 'components/@common/widgets/custom-table/table-column-menu/utils';
import {FieldPath} from 'components/report-configuration/report-configuration.types';
import {StateSetter} from 'shared/types/generics';
import {PostgresDateType, PostgresNumberType, PostgresStringType} from 'shared/types/postgres';
import {ReportTableColumn} from 'store/slices/report-configuration-slice/dd-lookups';

import {FilterValueType} from './report-configuration.constants';

export const groupArrayByKey = (array: any[], key: string) => Object.fromEntries(
    array.map(element => [element[key], omit(element, key)]),
);

export const compactObject = (object?: Object) => {
    if (!object) return object;
    return omitBy(object, v => !v);
};

export const transformSingleValidationResultWithError = (errorResult: any) => {
    const errorsObject = {};
    const errorFields = errorResult?.errorFields ?? [];
    errorFields.forEach((fieldError: any) => {
        if (fieldError.name && fieldError.errors) {
            const {name, errors} = fieldError;
            setWith(errorsObject, name, errors, Object);
        }
    });
    return errorsObject;
};

// для подсветки формы в модалке, в которой ошибка
export const transformValidationResultsWithErrors = (errorsResults: PromiseSettledResult<{
    formName: string;
    result: Store;
}>[]) => {
    // Переформатирование стандартных результатов валидации к объекту
    // {[путь]: ошибка}
    const errorsObject = {};
    (errorsResults ?? [])
        .filter(res => res.status === 'rejected')
        .map((res: any) => res.reason?.errorFields)
        .forEach(fieldsErrors => {
            fieldsErrors.forEach((fieldError: any) => {
                if (fieldError.name && fieldError.errors) {
                    const {name, errors} = fieldError;
                    setWith(errorsObject, name, errors, Object);
                }
            });
        });
    return errorsObject;
};

interface DisableModalFormatValueInputArgs {
    value: any;
    name: string | number;
    form: FormInstance;
    valueInputFieldPath: FieldPath;
    disabledValueInputs: Record<string, boolean>;
    setDisabledValueInputs: StateSetter<Record<string, boolean>>;
}

export const disableModalFormatValueInput = ({
    value,
    name,
    form,
    valueInputFieldPath,
    disabledValueInputs,
    setDisabledValueInputs,
}: DisableModalFormatValueInputArgs) => {
    const shouldDisableValueInput = isConditionValueUnnecessary(value?.lookupCode);
    const isCurrentValueInputDisabled = Object.keys(disabledValueInputs).includes(String(name));

    if (shouldDisableValueInput && !isCurrentValueInputDisabled) {
        setDisabledValueInputs({
            ...disabledValueInputs,
            [name]: true,
        });
        form.setFields([
            {name: valueInputFieldPath, value: undefined},
        ]);
    } else if (isCurrentValueInputDisabled && !shouldDisableValueInput) {
        setDisabledValueInputs(prev => omit(prev, name));
        form.resetFields([valueInputFieldPath]);
    }
};

export const getFilterValueTypeByColumnType = (reportColumnType: ReportTableColumn['type'] | undefined) => {
    if (!reportColumnType) return FilterValueType.text;

    if (reportColumnType in PostgresNumberType) return FilterValueType.number;
    if (reportColumnType in PostgresDateType) return FilterValueType.date;
    if (reportColumnType in PostgresStringType) return FilterValueType.text;

    return FilterValueType.text;
};
