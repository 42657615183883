import {createReducer} from 'store/config/creators';
import {Action} from 'store/config/types';

import {MetadataConstructorActions} from './metadata-constructor.constants';
import {
    IMetadataConstructorState, TEntityNameSetPayload,
    TMetadataConstructorSetPayload,
} from './metadata-constructor.types';

const initialState = {};

export const metadataConstructorReducer = createReducer(initialState, {
    [MetadataConstructorActions.SET_ALL_META]: (
        state: IMetadataConstructorState,
        {payload}: Action<TMetadataConstructorSetPayload>,
    ) => ({
        ...state,
        ALL_META: {
            ...state.ALL_META,
            ...payload.allMetadata,
        },
    }),
    [MetadataConstructorActions.SET_ALL_ENTITY_NAME]: (
        state: IMetadataConstructorState,
        {payload}: Action<TEntityNameSetPayload>,
    ) => ({
        ...state,
        ALL_ENTITY_NAME: [...payload.allEntityName],
    }),
});
