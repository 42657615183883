import React from 'react';

import {RequestStatus} from 'store/slices/request-slice/request-slice-type';

import {RequestActionItem} from '../actions/request-action-item';

interface ButtonsProps {
    requestFormKey: string;
    requestCommentId?: string;
    actions?: RequestStatus[];
    onClose?: () => void;
    onBack?: () => void;
    entityName?: string;
    onSubmit?: () => void;
}

export const Buttons: React.FunctionComponent<ButtonsProps> = (
    {
        actions,
        onClose,
        onBack,
        entityName,
        requestFormKey,
        onSubmit,
        requestCommentId,
    }: ButtonsProps,
) => {
    const showButtons = onClose || onBack || actions?.length;
    if (!showButtons) return null;

    const actionsList = actions?.map(
        action => (
            <RequestActionItem
                requestCommentId={requestCommentId}
                requestFormKey={requestFormKey}
                key={action}
                action={action}
                entityName={entityName}
                onSubmit={onSubmit}
            />

        ),
    );

    return actionsList?.length ? (
        <>{actionsList}</>
    ) : null;
};
