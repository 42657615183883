import {Popover} from 'antd';
import cn from 'classnames';
import React, {useEffect, useRef, useState} from 'react';

import {isOverflown} from 'shared/utils';

import './overflown.less';

interface OverflownProps {
    children?: any;
    className?: cn.Argument;
}

export const Overflown = React.forwardRef<any, OverflownProps>(({
    children,
    className,
}: OverflownProps) => {
    const valueRef = useRef<HTMLSpanElement>(null);

    const [showPopover, setShowPopover] = useState(false);
    const [isPopoverVisible, setIsPopoverVisible] = useState(false);

    useEffect(() => {
        const handler = (e: ResizeObserverEntry[]) => {
            if (e.length) {
                const [firstEntry] = e;
                const {target} = firstEntry;
                const isTargetOverflown = isOverflown(target as HTMLElement);
                if (isTargetOverflown !== showPopover) {
                    setShowPopover(isTargetOverflown);
                }
            }
        };

        let observer: ResizeObserver;
        if (valueRef?.current) {
            try {
                observer = new ResizeObserver(handler);
                observer.observe(valueRef?.current);
            } catch {
                // pass
            }
        }

        return () => {
            observer?.disconnect();
        };
    }, [showPopover]);

    return (
        <Popover
            visible={isPopoverVisible}
            onVisibleChange={v => {
                if (!showPopover) return;
                setIsPopoverVisible(v);
            }}
            mouseEnterDelay={0.4}
            content={(
                <div className="overflown-component__content">
                    {children}
                </div>
            )}
            placement="bottom"
        >
            <span
                ref={valueRef}
                className={cn(
                    'overflown-component',
                    className,
                )}
            >
                {children}
            </span>
        </Popover>
    );
});
