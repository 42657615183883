import {CaretDownOutlined} from '@ant-design/icons';
import cn from 'classnames';
import React from 'react';

import {ReactComponent as LockIcon} from 'shared/assets/forms/lock.svg';
import {ReactComponent as SearchIcon} from 'shared/assets/forms/search.svg';

interface SelectSuffixIconProps {
    showSearch?: boolean;
    isSelectDisabled?: boolean;
    isDropdownOpen?: boolean;
    isClearable?: boolean;
}

export const SelectSuffixIcon: React.FC<SelectSuffixIconProps> = ({
    showSearch,
    isSelectDisabled,
    isDropdownOpen,
    isClearable,
}: SelectSuffixIconProps) => {
    if (isSelectDisabled) {
        return <LockIcon className="suffix-icon" />;
    }
    if (showSearch && isDropdownOpen && !isClearable) {
        return <SearchIcon className="suffix-icon" />;
    }
    return (
        <CaretDownOutlined
            className={cn('caret-icon', isDropdownOpen && 'open')}
        />
    );
};
