import {Form as AntForm, Input} from 'antd';
import debounce from 'lodash/debounce';
import React, {useEffect} from 'react';

import {Entity, selectEntityData} from 'modules/data';
import {performActionForSearch} from 'modules/data/data-actions';
import {FilterEntityData, SearchEntityData} from 'modules/data/data-types';
import {ReactComponent as SearchIcon} from 'shared/assets/search.svg';
import {EntityType} from 'shared/constants/entities';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';

import './search.less';

interface SearchProps {
    parentEntityType?: EntityType;
    entityName: string;
    data?: FilterEntityData;
    url?: string;
}

export const Search: React.FunctionComponent<SearchProps> = ({
    entityName,
    url,
    parentEntityType,
    ...props
}: SearchProps) => {
    const [form] = AntForm.useForm();
    const dispatch = useAppDispatch();
    const search = useAppSelector(selectEntityData(entityName, EntityType.SEARCH)) as SearchEntityData;

    // при переходе в другой раздел и обратно значение должно быть сохранено
    useEffect(() => {
        form.setFieldsValue(search?.data);
    }, []);

    const debouncedDispatch = debounce(dispatch, 300);

    const handleValuesChanges = (values: Entity) => {
        debouncedDispatch(performActionForSearch(entityName, values, url, parentEntityType));
    };

    return (
        <AntForm
            {...props}
            className="search"
            colon={false}
            layout="inline"
            form={form}
            onValuesChange={handleValuesChanges}
        >
            <AntForm.Item
                name="search"
                className="search__wrapper"
            >
                <Input
                    className="search__container"
                    placeholder="Поиск"
                    prefix={<SearchIcon className="search__icon" />}
                    bordered={false}
                />
            </AntForm.Item>
        </AntForm>
    );
};
