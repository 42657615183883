import {createAsyncThunk} from '@reduxjs/toolkit';

import {generateRandomString} from 'shared/utils';

import {apiRequestProgressSliceActions} from './api-request-progress-slice';
import {apiRequestProgressSliceName} from './api-request-progress-slice-constants';
import {ApiRequestProgressEntity} from './api-request-progress-slice-types';

export const createRequestProgress = createAsyncThunk<
    string, Omit<ApiRequestProgressEntity, 'id'> & {
        id?: string;
    } | void
>(
    `${apiRequestProgressSliceName}/createRequestProgress`,
    (args, {dispatch}) => {
        const id = generateRandomString();

        dispatch(apiRequestProgressSliceActions.addRequestProgress({
            id,
            ...(args ?? undefined),
        }));

        return id;
    },
);
