import React from 'react';

import {EntityValue} from 'modules/data';

import {
    StatusCode,
    StatusColor,
    StatusTitle,
    StatusMapping,
} from './status-cell.constants';

import './status-cell.less';

interface StatusProps {
    value?: EntityValue;
}

export const Status: React.FunctionComponent<StatusProps> = ({value}: StatusProps) => {
    const valueToString: string = String(value?.toString().toLowerCase());
    const status: StatusCode = StatusMapping[valueToString];

    return (
        <div
            className="status"
            style={{color: StatusColor[status]}}
        >
            <div
                className="icon"
                style={{backgroundColor: StatusColor[status]}}
            />
            <div className="title">{StatusTitle[status]}</div>
        </div>
    );
};
