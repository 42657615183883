import React from 'react';
import {Cell, Pie, PieChart} from 'recharts';

import {StatisticsDataObject} from 'pages/svc-widget-page/hooks/use-statistics-initialization';

interface CircleChartProps {
    statisticsData: StatisticsDataObject;
}

export const CircleChart: React.FC<CircleChartProps> = ({statisticsData}: CircleChartProps) => {
    const dataForChart = statisticsData.map(({key: name, value, color}) => (
        {
            name, value, color,
        }
    ));

    return (
        <PieChart
            width={172}
            height={172}
        >
            <Pie
                isAnimationActive={false}
                dataKey="value"
                data={dataForChart}
                innerRadius={55}
                cy="50%"
                outerRadius={80}
                stroke="none"
            >
                {dataForChart.map(entry => (
                    <Cell
                        key={`circle-cell-${entry.name}`}
                        fill={entry.color}
                    />
                ))}
            </Pie>
        </PieChart>
    );
};
