import {MenuUnfoldOutlined} from '@ant-design/icons';
import React from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {setData} from 'modules/data/data-actions';
import {FieldMeta} from 'modules/metadata';
import {EntityType} from 'shared/constants/entities';

interface FunctionButtonProps {
    url: string;
    field: FieldMeta;
    record: any;
}

export const FunctionButtonField: React.FunctionComponent<FunctionButtonProps> = ({
    field,
    record,
    url,
}: FunctionButtonProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch(setData({
            entityName: url,
            entityType: EntityType.TABLE,
            loading: false,
            data: {
                params: `/report/?reportUnit=${record[field.key]}`,
            },

        }));
        history.push(`${url}${record[field.key]}`);
    };
    if (!record[field.key]) return null;
    return (
        <MenuUnfoldOutlined onClick={handleClick} />
    );
};
