import {Card, List as AntList} from 'antd';
import React, {useMemo} from 'react';

import {ListEntityData} from 'modules/data/data-types';
import {FieldType, ListEntityMeta} from 'modules/metadata/metadata-types';
import {combineString} from 'shared/utils/combine-string';

import {ListItem} from './list-item';
import {getListValue} from './utils/list-utils';

import './list.less';

interface ListProps {
    listData?: ListEntityData;
    meta?: ListEntityMeta;
    showTitle?: boolean;
}

export const List: React.FunctionComponent<ListProps> = ({
    listData,
    meta,
    showTitle = true,
}: ListProps) => {
    const title = useMemo(() => {
        if (!meta?.titleStructure) {
            return meta?.title;
        }
        return combineString(meta?.titleStructure, listData?.data);
    }, [meta?.title, meta?.titleStructure]);

    return (
        <Card title={showTitle ? title : ''}>
            <AntList>
                {meta?.fields?.map(fieldMeta => {
                    const {key, type, stringStructure} = fieldMeta;
                    if (fieldMeta.type === FieldType.ICON_LINK) {
                        return (
                            <div key={key}>
                                <ListItem
                                    meta={fieldMeta}
                                />
                            </div>
                        );
                    }
                    const value = getListValue(key, type, stringStructure, listData?.data);
                    if (!value) return null;
                    return (
                        <div key={key}>
                            <ListItem
                                meta={fieldMeta}
                                value={value}
                            />
                        </div>
                    );
                })}
            </AntList>
        </Card>
    );
};
