import {
    Card,
    Empty,
    Form,
    Modal,
    Spin,
    Tabs,
} from 'antd';
import cn from 'classnames';
import React, {ReactElement, ReactNode, useState} from 'react';

import {SimpleActionButton} from 'components/form/components';
import {FORM_QUERY_PARAMS} from 'pages/table-page/query-params-aware-table/constants/table-query-params';
import {ReactComponent as CloseIcon} from 'shared/assets/close.svg';
import {FormProps} from 'shared/types';
import {useQueryParams} from 'shared/utils/query-params';

import './tabbed-form.less';

interface EmptyTabProps {
    isEmpty?: boolean;
    description?: string;
}

interface TabbedFormClassNames {
    card?: cn.Argument;
    modal?: cn.Argument;
    form?: cn.Argument;
    tabsWrapper?: cn.Argument;
}

interface Tab {
    key: string;
    title: string;
    content: ReactNode;
}

interface TabbedFormProps<FormValues> {
    title: string | ReactElement;
    tabs: Tab[] | undefined;
    isLoading?: boolean;
    tip?: string;
    formProps?: FormProps<FormValues>;
    emptyProps?: EmptyTabProps;
    defaultTabKey: string | undefined;
    onClose?: () => void;
    modalWrapped?: boolean;
    className?: cn.Argument;
    classNames?: TabbedFormClassNames;
}

export const TabbedForm = <FormValues extends object>({
    title,
    tabs,
    isLoading,
    tip,
    formProps,
    emptyProps,
    defaultTabKey,
    onClose,
    modalWrapped,
    className,
    classNames,
}: TabbedFormProps<FormValues>) => {
    const [currentTab, setCurrentTab] = useState<string | undefined>(defaultTabKey);

    const {removeQueryParams} = useQueryParams();

    const {isEmpty, description} = emptyProps ?? {};
    const {
        formInstance,
        initialValues,
        layout,
        onSubmit,
        onChange,
    } = formProps ?? {};

    const handleClose = onClose ?? (
        () => removeQueryParams([FORM_QUERY_PARAMS.entity])
    );

    const tabbedForm = (
        <Spin
            spinning={isLoading}
            tip={tip ?? 'Загрузка информации о записи...'}
        >
            <Card
                className={cn('form-with-tabs', className, classNames?.card)}
                title={title}
            >
                <Form
                    className={cn(classNames?.form)}
                    form={formInstance}
                    initialValues={initialValues}
                    layout={layout}
                    onFinish={onSubmit}
                    onValuesChange={onChange}
                >
                    <div className={cn('fields-with-tabs', classNames?.tabsWrapper)}>
                        {
                            !isEmpty ? (
                                <Tabs
                                    onChange={tab => {
                                        setCurrentTab(tab);
                                    }}
                                    destroyInactiveTabPane
                                    defaultActiveKey={currentTab}
                                    type="card"
                                    className={cn({'first-is-active': currentTab === tabs?.[0]?.key})}
                                >
                                    {tabs?.map(({key, title: tabTitle, content}) => (
                                        <Tabs.TabPane
                                            key={key}
                                            tab={tabTitle}
                                        >
                                            {content}
                                        </Tabs.TabPane>
                                    ))}
                                </Tabs>
                            ) : !isLoading && <Empty description={description ?? 'Данные не найдены'} />
                        }
                    </div>
                </Form>
            </Card>
        </Spin>
    );

    return (
        modalWrapped ? (
            <Modal
                visible
                centered
                width={1008}
                footer={!isLoading ? (
                    <SimpleActionButton
                        title="Назад"
                        type="default"
                        style={{width: '100%'}}
                        onClick={handleClose}
                    />
                ) : null}
                onCancel={handleClose}
                className={cn('form-modal', 'tabbed-form', classNames?.modal)}
                closeIcon={<CloseIcon className={cn('modal-close-icon', 'with-title')} />}
            >
                {tabbedForm}
            </Modal>
        ) : tabbedForm
    );
};
