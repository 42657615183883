import React from 'react';

import {TabbedForm} from 'components/@common/widgets/tabbed-form';
import {Changelog} from 'components/@common/widgets/tabbed-form/common-tabs/changelog';
import {ChangelogEntity} from 'components/@common/widgets/tabbed-form/common-tabs/changelog/changelog.types';
import {
    RIFormTabs,
    RIFormTabTitles,
} from 'components/ais/reclaiming-information-card/reclaiming-information-card.constants';
import {ReclaimingMainInfo} from 'components/ais/reclaiming-information-card/tabs/reclaiming-main-info/reclaiming-main-info';
import {FORM_QUERY_PARAMS} from 'pages/table-page/query-params-aware-table/constants/table-query-params';
import {useQueryParams} from 'shared/utils/query-params';
import {useGetReclaimingInformationQuery} from 'store/api/reclaiming-information/reclaiming-information.api';

export interface ReclaimingInformationCardProps {
    id: string;
}

export const ReclaimingInformationCard = ({id}: ReclaimingInformationCardProps) => {
    const {removeQueryParams} = useQueryParams();

    const {
        data: reclaimingInfo,
        isLoading,
    } = useGetReclaimingInformationQuery({id}, {skip: !id});

    const changelogData: ChangelogEntity[] | undefined = reclaimingInfo?.audits?.map(a => ({
        id: a.id,
        stage: a.stage,
        statusCode: a.statusCode,
        statusText: a.statusText,
        createdDate: a.createdDate,
        updatedDate: a.lastModifiedDate,
    }));

    const handleClose = () => removeQueryParams([FORM_QUERY_PARAMS.entity]);

    return (
        <TabbedForm
            title={reclaimingInfo ? `Карточка требования №${reclaimingInfo?.mainDocNumber}` : ' '}
            defaultTabKey={RIFormTabs.main}
            isLoading={isLoading}
            modalWrapped
            emptyProps={{
                isEmpty: !reclaimingInfo,
                description: 'Информация о требовании не найдена',
            }}
            onClose={handleClose}
            tabs={reclaimingInfo && (
                [
                    {
                        key: RIFormTabs.main,
                        title: RIFormTabTitles.main,
                        content: (
                            <ReclaimingMainInfo
                                id={id}
                                initialLoadedData={reclaimingInfo}
                                onClose={handleClose}
                            />
                        ),
                    },
                    {
                        key: RIFormTabs.changes,
                        title: RIFormTabTitles.changes,
                        content: changelogData && (
                            <Changelog
                                changelogData={changelogData}
                            />
                        ),
                    },
                ]
            )}
        />
    );
};
