export const CONTEXT_MODULE = 'CONTEXT_MODULE';

export const CONTEXT_ACTIONS = {
    SET_CONTEXT_LOOKUPS: 'SET_CONTEXT_LOOKUPS',
    SET_CONTEXT_RAW_DATA: 'SET_CONTEXT_RAW_DATA',
    UPDATE_CONTEXT_RAW_DATA: 'SET_CONTEXT_RAW_DATA',
    RESET_CONTEXT: 'RESET_CONTEXT',

    ADD_TO_FETCHED_WITH_CONTEXT: 'ADD_TO_FETCHED_WITH_CONTEXT',
    SET_FETCHED_WITH_CONTEXT: 'SET_FETCHED_WITH_CONTEXT',
};
