import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {netDirectoryFileSliceName} from './net-directory-file-slice-constants';
import {NetDirectoryFileSliceState, NetDirectoryFileData} from './net-directory-file-slice-type';
import {
    loadNetDirectoryFileNameList,
} from './net-directory-file-slice.thunk';

export const netDirectoryFileSlice = createSlice({
    name: netDirectoryFileSliceName,
    initialState: {
        fileNameList: [],
        netDirectoryFileData: [],
    } as NetDirectoryFileSliceState,
    reducers: {
        resetNetDirectoryFile(state) {
            state.fileNameList = [];
            state.netDirectoryFileData = [];
        },
        setNetDirectoryFileData(state, {payload}: PayloadAction<NetDirectoryFileData[]>) {
            state.netDirectoryFileData = [...payload];
        },
    },
    extraReducers: builder => {
        builder.addCase(loadNetDirectoryFileNameList.fulfilled, (state, action) => {
            state.fileNameList = [...action.payload];
        });
    },
});

export const {
    reducer: netDirectoryFileSliceReducer,
    actions: netDirectoryFileSliceActions,
} = netDirectoryFileSlice;
