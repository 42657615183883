import {AuthProfile} from 'store/slices/auth-slice';
import {CAPITAL_CASE_DISPLAYED_PROFILES} from 'store/slices/auth-slice/auth-slice-constants';

export const formatProfileName = (profile: AuthProfile) => (
    CAPITAL_CASE_DISPLAYED_PROFILES.includes(profile) ? profile.toUpperCase() : profile
);

interface CreateRequiredValidatorArgs {
    onResolve: () => void;
    onReject: () => void;
}
export const createRequiredValidator = ({onResolve, onReject}: CreateRequiredValidatorArgs) => (() => ({
    validator(_: any, value: string) {
        if (!value) {
            onReject();
            return Promise.reject();
        }
        onResolve();
        return Promise.resolve();
    },
}));
