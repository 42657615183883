import {max} from 'lodash';
import {useEffect} from 'react';
import {v4 as uid} from 'uuid';

import {BreadcrumbItem} from 'components/breadcrumbs';
import {BreadcrumbsSections} from 'modules/breadcrumbs/breadcrumbs-constants';
import {EntityType} from 'shared/constants/entities';
import {LocationStateKey, useAppHistory} from 'shared/hooks/use-app-history';
import {useAppSelector} from 'store/config/hooks';
import {selectMenuItemByEntityName, selectMenuStructureIdMap} from 'store/slices/menu-slice';
import {selectTableReportTemplateConfig} from 'store/slices/table-report-slice/table-report-slice-selectors';

interface UseBreadcrumbsControllerArgs {
    entityName?: string;
    docId: string | null;
    templateCode: string;
}

export const useBreadcrumbsController = ({
    entityName, docId, templateCode: templateCodeFromUrl,
}: UseBreadcrumbsControllerArgs) => {
    const templateConfig = useAppSelector(selectTableReportTemplateConfig);

    const {
        templateCode,
        reportName,
    } = templateConfig ?? {};

    const history = useAppHistory();

    const currentMenuItem = useAppSelector(s => (
        entityName ? selectMenuItemByEntityName(s, entityName) : null
    ));
    const currentEntityType = currentMenuItem?.entityType;

    const menuIdMap = useAppSelector(selectMenuStructureIdMap);
    const parentItemTitle = menuIdMap && currentMenuItem?.parentId
        ? menuIdMap[currentMenuItem?.parentId]?.itemTitle : '';

    const breadcrumbs = history.getLocationStateValue(LocationStateKey.TABLE_REPORT_BREADCRUMBS);

    const findNextId = () => {
        const maximum: number | undefined = max(breadcrumbs?.map((bc: any) => Number(bc.id)));
        if (maximum) return `${maximum + 1}`;
        return `${breadcrumbs?.length ?? 0 + 1}`;
    };

    const handleBreadcrumbClick: BreadcrumbItem['onClickCallback'] = ({id, path, extraData}) => {
        if (!path) return;
        if (id === '1') {
            history.push(path);
        } else {
            const newBreadcrumbs = breadcrumbs?.filter((bc: any) => Number(bc.id) <= Number(id)) ?? [];
            history.pushWithStateUpdate(path, {
                [LocationStateKey.TABLE_REPORT_BREADCRUMBS]: newBreadcrumbs,
                [LocationStateKey.TABLE_REPORT_PARAMS]: extraData?.tableReportDrilldownParams,
                [LocationStateKey.TABLE_REPORT_LOCATION_DEFAULTS]: extraData?.tableReportLocationDefaults,
                [LocationStateKey.TABLE_REPORT_PUSH_UNIQUE_ID]: extraData?.tableReportPushUniqueId,
            });
        }
    };

    const pushWithBreadcrumbs = ({name, path, extraData}: BreadcrumbItem) => {
        if (!path) return;

        const pushUniqueId = uid();

        const breadcrumb = {
            id: findNextId(),
            name,
            path,
            extraData: {
                ...extraData,
                tableReportPushUniqueId: pushUniqueId,
            },
        };

        const {
            tableReportDrilldownParams,
            tableReportLocationDefaults,
        } = extraData ?? {};

        history.pushWithStateUpdate(path, {
            [LocationStateKey.TABLE_REPORT_BREADCRUMBS]: [
                ...(breadcrumbs ?? []),
                breadcrumb,
            ],
            [LocationStateKey.TABLE_REPORT_PARAMS]: tableReportDrilldownParams,
            [LocationStateKey.TABLE_REPORT_LOCATION_DEFAULTS]: tableReportLocationDefaults,
            [LocationStateKey.TABLE_REPORT_PUSH_UNIQUE_ID]: pushUniqueId,
        });
    };

    const defaultBreadcrumbs = [
        {
            id: '1',
            name: BreadcrumbsSections.MAIN_PAGE,
            path: '/',
        },
        ...((() => {
            if ([
                EntityType.TABLE_REPORT,
                EntityType.JASPER_REPORT,
            ].some(v => v === currentEntityType && parentItemTitle
                && parentItemTitle === BreadcrumbsSections.REPORTS)) {
                return [{
                    id: '2',
                    name: BreadcrumbsSections.REPORTS,
                }];
            }
            return [];
        }
        )()),
        ...(entityName ? ([{
            id: '3',
            name: reportName ?? BreadcrumbsSections.REPORTS,
            path: `/${entityName}`,
        }]) : []),
    ];

    useEffect(() => {
        if (!breadcrumbs && templateConfig && templateCode === templateCodeFromUrl) {
            if (docId) {
                history.setLocationStateValue(LocationStateKey.TABLE_REPORT_BREADCRUMBS, [
                    ...defaultBreadcrumbs,
                    {
                        id: '4',
                        name: reportName ?? BreadcrumbsSections.REPORT,
                        path: `/table-reports/${docId}/${templateCode}`,
                    },
                ]);
            } else {
                history.setLocationStateValue(
                    LocationStateKey.TABLE_REPORT_BREADCRUMBS,
                    defaultBreadcrumbs,
                );
            }
        }
    }, [templateCode]);

    return {
        defaultBreadcrumbs,
        pushWithBreadcrumbs,
        handleBreadcrumbClick,
    };
};

export type PushWithBreadcrumbsFunction = ReturnType<typeof useBreadcrumbsController>['pushWithBreadcrumbs'];
