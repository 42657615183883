import {
    useEffect, useRef, useState,
} from 'react';

import {Interval, Timeout} from 'shared/types';

import {WS_BASE_URL} from '../websockets-constants';
import {
    WebsocketConnectionRequestData,
    WebsocketMessageHandlers,
    WebsocketRequestMessage,
    WebsocketRequestMessageType,
    WebsocketResponseMessage,
} from '../websockets-types';
import {WebsocketSubscriberInstance} from './use-websocket-subscriber';

interface UseWebsocketArgs {
    eventHandlers?: { // обработчики событий
        onOpen?: () => void;
        onClose?: () => void;
        onMessage?: (msg: MessageEvent<any>) => void;
    };
    options?: {
        retryOnCloseDelay?: number; // если соединение закрыто по любой причине, таймаут для повторного подключения.
        // если retryOnCloseDelay не задано - без переподключения.
        establishManually?: boolean; // без автоматического подключения во время mount.
        heartbeat?: number; // задержка для отправки ping.
        awaitedTimeout?: number; // доступное время ответа для awaited-сообщений, иначе - ошибка Timeout.
        heartbeatTimeoutErrorsLimit?: number; // количество heartbeat ошибок для закрытия соединения.
        retryOnAwaitedTimeoutErrorsExcess?: boolean; // пытаться переподключиться при переполнении лимита ошибок.
        // если задан retryOnCloseDelay - можно не указывать.
        connectionTimeout?: number; // максимальное время между establishConnection(),
        // т.е. попыткой установить соединение и onopen.
        // Если лимит превышен - соединение будет закрыто и сработает onerror.
        connectionAttemptsLimit?: number; // лимит на попытки подключения
    };
    messageGlobalHandlers?: WebsocketMessageHandlers; // глобальные обработчики сообщений, подробнее в README.md.
}

export const useWebsocketClient = ({
    eventHandlers = {},
    options = {},
    messageGlobalHandlers = {},
}: UseWebsocketArgs) => {
    const {onOpen, onClose, onMessage} = eventHandlers;
    const {
        retryOnCloseDelay,
        establishManually,
        heartbeat,
        awaitedTimeout = 5000,
        heartbeatTimeoutErrorsLimit = 5,
        retryOnAwaitedTimeoutErrorsExcess = true,
        connectionTimeout,
        connectionAttemptsLimit,
    } = options;

    const [isWithError, setIsWithError] = useState(false);

    const connectionAttemptsRef = useRef(0);
    const websocketRef = useRef<WebSocket>();
    const heartbeatRef = useRef<Interval>();
    const awaitedResolveRef = useRef<any>({});
    const reqId = useRef(0);
    const heartbeatErrorsCount = useRef(0);
    const closedWithUnmountRef = useRef<boolean>(false); // закрыто из-за unmount'a компонента - не переподключаться.
    const connectionTimeoutRef = useRef<Timeout>();
    const subscribers = useRef<WebsocketSubscriberInstance[]>([]);
    const readyResolveQueueRef = useRef<{resolve?: any; reject?: any}[]>(); // "очередь" из резолверов промисов,
    // ожидающих открытие соединения.

    const getWebsocket = () => websocketRef.current;

    const broadcastToSubscribers = (
        messageType: WebsocketResponseMessage<any>['type'],
        messageParsed: WebsocketResponseMessage<any>,
    ) => {
        subscribers.current.forEach(s => s.send(messageType, messageParsed));
    };
    const subscribe = (subscriber: WebsocketSubscriberInstance) => {
        subscribers.current.push(subscriber);
    };
    const unsubscribe = (subscriber: WebsocketSubscriberInstance) => {
        subscribers.current = subscribers.current.filter(s => s !== subscriber);
    };

    const nextId = () => {
        reqId.current += 1;
        if (reqId.current === Number.MAX_SAFE_INTEGER - 1) reqId.current = 1;
        return `${reqId.current}`;
    };

    const sendJSON = (msg: any) => {
        const ws = getWebsocket();
        if (!ws) return;
        ws.send(JSON.stringify(msg));
    };

    const send = <T extends WebsocketRequestMessageType> (
        msg: WebsocketRequestMessage<T, WebsocketConnectionRequestData[T]>,
    ) => {
        const ws = getWebsocket();
        const uid = msg?.uid ?? nextId();

        if (!ws) return;
        ws.send(JSON.stringify({...msg, uid}));
    };

    const sendAwaited = async <T extends WebsocketRequestMessageType> (
        msg: WebsocketRequestMessage<T, WebsocketConnectionRequestData[T]>,
    ) => {
        const ws = getWebsocket();
        const uid = msg?.uid ?? nextId();
        if (!ws) return undefined;

        ws.send(JSON.stringify({...msg, uid}));
        const response = await new Promise((resolve, reject) => {
            awaitedResolveRef.current[uid] = resolve;
            setTimeout(() => {
                awaitedResolveRef.current[uid] = undefined;
                reject(new Error('[WS] SendAwaited timeout.'));
            }, awaitedTimeout);
        });
        return response;
    };

    const ready = () => new Promise((resolve, reject) => {
        if (getWebsocket()?.readyState === WebSocket.OPEN) {
            resolve(true);
        }
        readyResolveQueueRef.current?.push({resolve, reject});
    });
    const resolveReady = () => {
        if (readyResolveQueueRef.current) {
            readyResolveQueueRef.current?.forEach(({resolve}) => resolve());
            readyResolveQueueRef.current = undefined;
        }
    };
    const rejectReady = () => {
        if (readyResolveQueueRef.current) {
            readyResolveQueueRef.current?.forEach(({reject}) => reject());
            readyResolveQueueRef.current = undefined;
        }
    };

    const closeConnection = () => {
        heartbeatErrorsCount.current = 0;
        if (heartbeatRef.current) clearInterval(heartbeatRef.current);
        getWebsocket()?.close();
    };

    const establishConnection = () => {
        if (heartbeat && awaitedTimeout && heartbeat < awaitedTimeout) {
            throw (new Error('[WS] Heartbeat должен быть больше, чем awaitedTimeout.'));
        }

        const ws = new WebSocket(WS_BASE_URL);
        connectionAttemptsRef.current += 1;

        if (connectionTimeout) {
            if (connectionTimeoutRef.current) clearTimeout(connectionTimeoutRef.current);
            connectionTimeoutRef.current = setTimeout(() => {
                closeConnection();
            }, connectionTimeout);
        }

        ws.onerror = err => {
            rejectReady();
            console.error(err);
            setIsWithError(true);
        };

        ws.onopen = () => {
            connectionAttemptsRef.current = 0;
            resolveReady();
            setIsWithError(false);
            if (connectionTimeout && connectionTimeoutRef.current) clearTimeout(connectionTimeoutRef.current);
            if (onOpen) onOpen?.();
            if (heartbeat) {
                heartbeatRef.current = setInterval(() => {
                    sendAwaited({
                        type: 'ping',
                    }).then(() => {
                        heartbeatErrorsCount.current = 0;
                    }, () => {
                        heartbeatErrorsCount.current += 1;
                        if (heartbeatErrorsCount.current >= heartbeatTimeoutErrorsLimit) {
                            closeConnection();
                            if (retryOnAwaitedTimeoutErrorsExcess && !retryOnCloseDelay) {
                                establishConnection();
                            }
                        }
                    });
                }, heartbeat);
            }
        };

        ws.onclose = () => {
            rejectReady();
            if (onClose) { onClose?.(); }
            if (retryOnCloseDelay && !closedWithUnmountRef.current) {
                if (connectionAttemptsLimit && connectionAttemptsRef.current >= connectionAttemptsLimit) return;
                setTimeout(() => {
                    establishConnection();
                }, retryOnCloseDelay);
            }
        };

        ws.onmessage = ev => {
            try {
                const responseMessage = ev.data;
                const messageParsed = JSON.parse(responseMessage) as WebsocketResponseMessage<any>;
                if (messageParsed.uid) { awaitedResolveRef.current[messageParsed.uid]?.(messageParsed); }
                const messageType = messageParsed.type;
                if (messageType) {
                    messageGlobalHandlers[messageType]?.(messageParsed);
                    broadcastToSubscribers(messageType, messageParsed);
                }
            } catch {
                console.error('[WS] Message read error.');
            }
            if (onMessage) onMessage?.(ev);
        };

        websocketRef.current = ws;
    };

    useEffect(() => {
        if (!establishManually) { establishConnection(); }
        return () => {
            closedWithUnmountRef.current = true;
            closeConnection();
        };
    }, []);

    return {
        isWithError,
        send,
        sendJSON,
        sendAwaited,
        closeConnection,
        establishConnection,
        subscribe,
        unsubscribe,
        ready,
    };
};

export type WebsocketClient = ReturnType<typeof useWebsocketClient>;
