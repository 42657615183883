import {getUserCertificates, getSystemInfo} from 'crypto-pro';

import {
    CADESCOM_HASH_ALGORITHM_CP_GOST_3411,
    CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256,
    CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512,
    CADESCOM_HASH_ALGORITHM_CP_SHA1,
} from './crypto-pro-constants';

const OID_TO_HASH_ALG_MAPPING = {
    '1.2.643.2.2.19': CADESCOM_HASH_ALGORITHM_CP_GOST_3411,
    '1.2.643.7.1.1.1.1': CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256,
    '1.2.643.7.1.1.1.2': CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512,
    '1.2.840.113549.1.1.1': CADESCOM_HASH_ALGORITHM_CP_SHA1,
} as {[index: string]: number};

export const getUserCertificatesExtended = async () => {
    const certificates = await getUserCertificates(true);

    const certificatesExtendedPromises = certificates.map(async cert => {
        const owner = await cert.getOwnerInfo();
        const issuer = await cert.getIssuerInfo();
        const algorithm = await cert.getAlgorithm();
        const privateKey = await cert.getCadesProp('PrivateKey');
        const providerName = await privateKey?.ProviderName;

        return {
            certificate: cert, // spread удалит прототип
            // extra fields ↴
            providerName,
            algorithmName: algorithm.algorithm,
            algorithmCadescomConstant: OID_TO_HASH_ALG_MAPPING[algorithm.oid],
            issuer,
            owner,
            thumbprint: cert?.thumbprint,
        };
    });

    const certificatesExtended = await Promise.all(certificatesExtendedPromises);

    return certificatesExtended;
};

export const getCryptoProData = async () => {
    const certificatesExtended = await getUserCertificatesExtended();
    const systemInfo = await getSystemInfo();

    return {
        certificatesExtended,
        systemInfo,
    };
};

export type CryptoProData = Awaited<ReturnType<typeof getCryptoProData>>;
export type CertificateExtended = Awaited<ReturnType<typeof getUserCertificatesExtended>>[0];
