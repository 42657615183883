import {CaretDownOutlined} from '@ant-design/icons';
import {Checkbox, Select} from 'antd';
import cn from 'classnames';
import _ from 'lodash';
import React, {useMemo, useState} from 'react';

import {Entity} from 'modules/data';
import {ReactComponent as LockIcon} from 'shared/assets/forms/lock.svg';
import {ReactComponent as SearchIcon} from 'shared/assets/forms/search.svg';
import {useAppSelector} from 'store/config/hooks';
import {tableReportRowAttachmentsSelectors} from 'store/slices/table-report-slice/table-report-row-attachments';

export const useReferenceSelectConfig = (
    options?: Entity[],
    disabled?: boolean,
    multipleMode?: boolean,
    isFilterable?: boolean,
    displayFieldKey?: string,
    fieldKeyName?: string,
) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownInputValue, setDropdownInputValue] = useState<string>('');
    const {selectDocIdsToRequest} = tableReportRowAttachmentsSelectors;
    const docIdsToRequest = useAppSelector(selectDocIdsToRequest);
    const fieldKey = displayFieldKey || 'label' || 'meaning';
    const renderOptions = useMemo(() => {
        if (options && Array.isArray(options)) {
            let filteredOptions = (docIdsToRequest.length > 0 && fieldKeyName === 'topicRequestTypeId')
                ? (options.filter(item => item.lookupCode === 'requestPUD')) : options;

            if (dropdownInputValue) {
                const filterValue = dropdownInputValue.toLowerCase();
                filteredOptions = options
                    .filter(entry => (_.isString(entry.label)) && (entry.label.toLowerCase().search(filterValue) >= 0));
            }

            return filteredOptions.map(entry => (entry.label || entry.value) && (
                <>
                    <Select.Option
                        value={entry.value as string | number}
                        key={entry.value as string | number}
                        className="select-field__option"
                    >
                        {entry[fieldKey]}
                    </Select.Option>
                </>
            ));
        }
        return <></>;
    }, [dropdownInputValue, options]);

    const suffixIcon = useMemo(() => {
        if (disabled) {
            return <LockIcon className="suffix-icon" />;
        }
        if (isFilterable && !multipleMode && dropdownOpen) {
            return <SearchIcon className="suffix-icon" />;
        }
        return (
            <CaretDownOutlined
                onClick={() => setDropdownOpen(v => !v)}
                className={cn('caret-icon', dropdownOpen && 'open')}
            />
        );
    }, [
        disabled, dropdownOpen,
    ]);

    const setDropdownSelectedIcon = (p: any) => (p?.isSelected ? <Checkbox checked />
        : <Checkbox checked={false} />);

    return {
        dropdownOpen,
        setDropdownOpen,
        dropdownInputValue,
        setDropdownInputValue,
        renderOptions,
        suffixIcon,
        setDropdownSelectedIcon,
    };
};
