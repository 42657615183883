import {Upload} from 'antd';
import {RcFile, UploadFile} from 'antd/es/upload/interface';
import React from 'react';
import {useDispatch} from 'react-redux';

import {ATTORNEY_UPLOAD_EXTENSIONS} from 'components/table/table-constants';
import {selectContextRawData} from 'modules/context/context-selector';
import {selectEntityData} from 'modules/data';
import {uploadFileAction} from 'modules/data/data-actions';
import {FileInfo, TableEntityData} from 'modules/data/data-types';
import {EntityType} from 'shared/constants/entities';
import {showMessage} from 'shared/utils';
import {useAppSelector} from 'store/config/hooks';

import {ActionButton} from '../action-button';
import {TableActionProps} from '../table-action-types';
import {hasAttorneyName, isDocsChosen} from './button-action-upload-xml.utils';

const ButtonActionUploadXmlComponent: React.FunctionComponent<TableActionProps> = ({
    entityName,
    meta,
    wrapper,
    ...props
}: TableActionProps) => {
    const dispatch = useDispatch();
    const tableData = useAppSelector(selectEntityData(entityName, EntityType.TABLE)) as TableEntityData;
    const selectedRows = tableData?.selectedRows || [];

    const data = useAppSelector(selectContextRawData);

    const handleUpload = (file: UploadFile<Blob>) => {
        if (!isDocsChosen(selectedRows)) return false;

        const formData = new FormData();
        formData.append('file', file as RcFile);

        selectedRows.forEach(row => {
            const listOfFiles: FileInfo[] = row.fileList as FileInfo[];
            if (!listOfFiles.length) return;
            formData.append('attachIds', `${listOfFiles[0].attachmentId}`);
        });

        if (meta.referenceUrl && data) {
            dispatch(uploadFileAction(
                entityName,
                '/loa-signature/upload',
                formData,
                '',
                'Файл доверенности подгружен к документам',
            ));
        }
        return false;
    };

    const onUploadButtonClick = () => {
        if (!isDocsChosen(selectedRows)) {
            showMessage({
                message: 'Необходимо выбрать хотя бы один документ',
                isError: true,
            });
        }
        if (hasAttorneyName(selectedRows)) {
            showMessage({
                message: 'Для одного или нескольких документов уже '
                + 'прикреплен файл доверенности, пожалуйста, выберите только те документы, '
                + 'для которых файл доверенности отсутствует',
                isError: true,
            });
        }
    };

    const Component = wrapper || ActionButton;

    return (
        <Upload
            showUploadList={false}
            beforeUpload={handleUpload}
            accept={ATTORNEY_UPLOAD_EXTENSIONS}
            disabled={!isDocsChosen(selectedRows) || hasAttorneyName(selectedRows)}
        >
            <Component
                {...props}
                onClick={onUploadButtonClick}
                actionIcon={meta.actionIcon}
                disabled={!selectedRows.length}
            >
                {meta.actionTitle}
            </Component>
        </Upload>
    );
};
export const ButtonActionUploadXml = ButtonActionUploadXmlComponent;
