import React from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';

import {FormEntityData} from 'modules/data/data-types';
import {EntityMeta} from 'modules/metadata/metadata-types';
import {EntityType} from 'shared/constants/entities';

import {FormMode} from '../../form';
import {FormEditModal} from '../../form-edit-modal/form-edit-modal';
import {Form} from '../../form/form';

import './directory-form-item.less';

export interface DirectoryFormItemProps {
    url: string;
    data: Record<string, unknown>;
    metadata: EntityMeta;
    referenceUrl: string;
}

export const DirectoryFormItem = ({
    url, referenceUrl, data, metadata,
}: DirectoryFormItemProps) => {
    const history = useHistory();

    const handleClose = () => {
        history.go(-1);
    };

    return (
        <Switch>
            <div className="directory-item">
                <Route
                    path={url}
                    render={() => (
                        <Form
                            onClose={() => {
                            }}
                            actionForForm={() => {
                            }}
                            formStyle={{width: metadata?.width}}
                            meta={{...metadata, actions: []}}
                            formData={{data} as FormEntityData}
                            // as form here is used to render only fields, I suppose it will be always disabled
                            mode={FormMode.REPRESENTATION}
                        />
                    )}
                />
                <Route
                    path={`${url}/:id`}
                    render={() => (
                        <FormEditModal
                            entityName={metadata.name}
                            onClose={handleClose}
                            referenceUrl={referenceUrl}
                            resetEntityName={metadata.name}
                            resetEntityType={EntityType.TABS_WITH_FORM}
                        />
                    )}
                />
            </div>
        </Switch>
    );
};
