import React from 'react';

import {
    MonitoringResultLog,
    ProgramSetProgram,
} from 'modules/documents/documents-upload-monitoring-types';

import {DocumentsUploadLog} from './documents-upload-log';

export interface DocumentsUploadLogContainerProps {
    ref?: React.RefObject<HTMLDivElement>;
    logs?: { programId: number; log?: MonitoringResultLog }[];
    programSetPrograms: ProgramSetProgram[];
}

export const DocumentsUploadLogs = ({logs, programSetPrograms, ref}: DocumentsUploadLogContainerProps) => (
    <div
        ref={ref}
        onClick={e => e.stopPropagation()}
        className="monitoring-logs"
    >
        {logs?.map(programLog => {
            const program = programSetPrograms
                .find(programSet => programSet?.program?.id === programLog.programId);
            return program && programLog.log && (
                <DocumentsUploadLog
                    log={programLog.log}
                    program={program.program}
                />
            );
        })}
    </div>
);
