import React, {createContext} from 'react';

export enum TableMode {
    EDIT = 'edit',
    VIEW = 'view',
}

export const invertTableMode = (mode: TableMode) => {
    switch (mode) {
    case TableMode.VIEW:
        return TableMode.EDIT;
    default:
        return TableMode.VIEW;
    }
};

export interface TableModeContextArgs {
    tableMode: TableMode;
    resetTableMode: () => void;
    setTableMode: React.Dispatch<React.SetStateAction<TableMode>>;
}

export const TableModeContext = createContext<TableModeContextArgs>({
    tableMode: TableMode.VIEW,
    setTableMode: () => {
    },
    resetTableMode: () => {
    },
});
