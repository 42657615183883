import React from 'react';

import {HeaderCommonProps} from 'components/table-report/components/table-report-header/table-report-header.types';

import {GET_VALUE_FROM_MENU_INDICATOR} from '../../table-report-header.utils';

import './row-output-header.less';

export const RowOutputHeader: React.FC<HeaderCommonProps> = ({headers}: HeaderCommonProps) => (
    <div className="row-output-header">
        <div className="row-output-header__others">
            {headers?.others?.length && headers?.others.map(header => (
                header?.value && header?.value !== GET_VALUE_FROM_MENU_INDICATOR && (
                    <div
                        className="others-row"
                        key={header.reportTitle}
                    >
                        <span className="others-row__title">
                            {header.reportTitle}:
                        </span>
                        <span className="others-row__value">
                            {header.value}
                        </span>
                    </div>
                )))}
        </div>
    </div>
);
