/** Метаданные по умолчанию */
import {IRequestFormEntityMeta} from 'modules/metadata/metadata-types';

import {
    DATA_KEY_FOR_FILTER_FIELDS,
    REQUESTS_RESOLVED_META,
    requestType, RESPONSES_RESOLVED_META,
} from '../metadata.constants';
import {FORM_FIELDS, TFormFieldsRequests} from './form.fields';

export const REQUEST_META: IRequestFormEntityMeta = {
    title: 'Заголовок',
    name: 'requests',
    dataKeyForFilterFields: DATA_KEY_FOR_FILTER_FIELDS,
    dataKeyForGrade: 'rating',
    dataKeyForGradeId: 'idRequestComment',
    dataKeyForTitle: 'topicRequestType.description',
    fields: REQUESTS_RESOLVED_META[requestType.default]
        .fields
        .map(fieldName => (FORM_FIELDS.REQUESTS as TFormFieldsRequests)[fieldName]),
    linkField: 'id',
    actions: [],
};

export const RESPONSE_META: IRequestFormEntityMeta = {
    title: 'Ответ',
    name: 'response',
    dataKeyForGrade: 'rating',
    dataKeyForGradeId: 'id',
    fields: RESPONSES_RESOLVED_META[requestType.default]
        .fields
        .map(fieldName => (FORM_FIELDS.REQUESTS as TFormFieldsRequests)[fieldName]),
    actions: [],
};
