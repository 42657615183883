export const CONTROL_RATIO_RULES_ENTITY_NAME = 'administration.settings.control.ratios.rules';
export const ControlRatioSettingsSection = 'settings/administration.settings.control.ratios.main';
export const CRIndicatorsSettingsSection = 'settings/administration.settings.control.ratios.object.item';
export const DOCUMENT_UPLOAD_TEMPLATES_NAME = 'administration.documentupload.templates';

export type ControlRatioSettingsRulesSection = typeof ControlRatioSettingsSection | typeof CRIndicatorsSettingsSection;
export const ControlRatiosSettingRuleSections = [
    {
        label: 'Контрольные соотношения',
        value: ControlRatioSettingsSection,
    }, {
        label: 'Показатели',
        value: CRIndicatorsSettingsSection,
    },
];

export const CrRulesTableDataUrl = {
    [ControlRatioSettingsSection]: 'settings/administration.settings.control.ratios.rules/by-control-ratio',
    [CRIndicatorsSettingsSection]: 'settings/administration.settings.control.ratios.rules/by-tax-object-item',
};
