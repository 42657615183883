import {CloseOutlined, LoadingOutlined} from '@ant-design/icons';
import {message} from 'antd';
import Popconfirm from 'antd/es/popconfirm';
import Tooltip from 'antd/es/tooltip';
import cn from 'classnames';
import React, {useState} from 'react';
import {v4 as uuid} from 'uuid';

import {IconsMap} from 'components/dynamic-icon';
import {FileInfo} from 'modules/data';
import {removeDocumentScans} from 'modules/data/data-actions';
import {deleteDocumentScan, downloadDocumentScan} from 'modules/documents/documents-api';
import {EntityType} from 'shared/constants/entities';
import {useAppDispatch} from 'store/config/hooks';
import {UserRoleFunction} from 'store/slices/user-slice/user-slice-role-functions';

import './upload-scan-files-item.less';

interface UploadScanFilesItemProps {
    scan?: FileInfo;
    docId: string;
    entityName: string;
    entityType?: EntityType;
    hasUserRoleFunction: (func: UserRoleFunction) => boolean;
}

export const UploadScanFilesItem: React.FC<UploadScanFilesItemProps> = (
    {
        scan, entityName, entityType, docId, hasUserRoleFunction,
    }: UploadScanFilesItemProps,
) => {
    const dispatch = useAppDispatch();

    const [isDeleting, setIsDeleting] = useState(false);
    const [isLoadingStarted, setIsLoadingStarted] = useState(false);

    const handleDownload = () => {
        if (isLoadingStarted) return;
        setIsLoadingStarted(true);
        setTimeout(() => {
            setIsLoadingStarted(false);
        }, 1000);

        downloadDocumentScan({
            attachmentId: `${scan?.attachmentId}`,
            fileName: `${scan?.fileName}`,
        });
    };

    return (
        <div className={cn('upload-scan-files-item')}>

            <div className={cn('upload-scan-files-item__description')}>
                <IconsMap.FileOutlined />
                {scan?.fileName}
            </div>

            <div className={cn('upload-scan-files-item__controls')}>
                {isLoadingStarted && <LoadingOutlined className="mr-0-5" />}
                {hasUserRoleFunction(UserRoleFunction.DOWNLOAD_PDF) && !isDeleting && (
                    <Tooltip
                        title="Скачать файл"
                        mouseEnterDelay={0.5}
                        placement="bottom"
                    >
                        <IconsMap.LinkOutlined
                            className={cn('upload-scan-files-item__controls__download')}
                            onClick={handleDownload}
                        />
                    </Tooltip>
                )}
                {hasUserRoleFunction(UserRoleFunction.UPLOAD_PDF) && !isDeleting && (
                    <Popconfirm
                        title="Вы действительно хотите удалить этот файл?"
                        placement="left"
                        okText="Удалить"
                        onConfirm={async () => {
                            if (!scan || isDeleting) return;
                            setIsDeleting(true);

                            const messageId = uuid();

                            message.loading({
                                content: `Удаление файла «${scan.fileName}»...`,
                                key: messageId,
                                duration: 0,
                            });

                            try {
                                await deleteDocumentScan({
                                    attachmentId: `${scan?.attachmentId}`,
                                });

                                dispatch(removeDocumentScans({
                                    attachmentsIds: [`${scan?.attachmentId}`],
                                    docId,
                                    entityName,
                                    entityType,
                                }));

                                message.success({
                                    content: `Файл «${scan.fileName}» успешно удалён`,
                                    key: messageId,
                                    duration: 2,
                                });
                            } catch (e) {
                                message.error({
                                    content: `Ошибка удаления файла «${scan.fileName}»`,
                                    key: messageId,
                                    duration: 2,
                                });
                            }
                        }}
                    >
                        <CloseOutlined
                            className={cn('upload-scan-files-item__controls__delete')}
                        />
                    </Popconfirm>
                )}
            </div>
        </div>
    );
};
