import {unwrapResult} from '@reduxjs/toolkit';
import {Badge, Button, Checkbox} from 'antd';
import {AxiosResponse} from 'axios';
import cn from 'classnames';
import React, {
    useCallback, useContext, useEffect, useState,
} from 'react';

import {ModalOpenerComponent} from 'components/modal-opener-component';
import {ReactComponent as ArrowBackOutlined} from 'shared/assets/arrow-back-outlined.svg';
import {ReactComponent as FilterIcon} from 'shared/assets/filter.svg';
import {ReactComponent as TrashIcon} from 'shared/assets/trash-x.svg';
import {useAfterEffect} from 'shared/hooks/use-after-effect';
import {usePagination} from 'shared/hooks/use-pagination';
import {showMessageFromResponse} from 'shared/utils';
import {useQueryParams} from 'shared/utils/query-params/use-query-params.hook';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {DD_REFERENCES_TABLE_DEFAULT_PAGINATION} from 'store/slices/report-configuration-slice';
import {
    deleteDrilldownReferenceRules,
    loadDrilldownReferenceRules,
} from 'store/slices/report-configuration-slice/dd-references-configuration';
import {
    ddReferenceRulesSelectors,
    reportConfigurationActions,
} from 'store/slices/report-configuration-slice/report-configuration-slice';

import {ButtonChangeHistory} from '../../components/button-change-history';
import {ReportConfigurationTabKey} from '../../report-configuration.constants';
import {ReportConfigurationContext} from '../../report-configuration.context';
import {DdReferencesEditModalOpener} from './dd-references-edit-modal-opener';
import {DdReferencesFilter} from './dd-references-filter/dd-references-filter';
import {DdReferencesTable} from './dd-references-table';
import {DD_LINK_COLUMN_KEY} from './report-dd-references-constants';
import {ReportDdReferenceRulesContext} from './report-dd-references-utils';

import './report-dd-references.less';

interface ReportDdReferencesProps {}

export const ReportDdReferences: React.FC<ReportDdReferencesProps> = () => {
    const dispatch = useAppDispatch();

    const ddReferenceRulesFilter = useAppSelector(ddReferenceRulesSelectors.selectDdReferenceRulesFilter);

    const {
        setDdReferenceRulesFilter,
        updateDdReferenceRulesFilter,
    } = reportConfigurationActions;

    const {templateCode, setSelectedTabKey} = useContext(ReportConfigurationContext);

    const [isLoading, setIsLoading] = useState(false);
    const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

    const {removeQueryParams, query} = useQueryParams();
    const linkColumn = query.get(DD_LINK_COLUMN_KEY) ?? undefined;

    const {
        current, pageSize, handleChange, setCurrentManually,
    } = usePagination({
        pageSizeDefault: ddReferenceRulesFilter.paginationPageSize,
        pageDefault: ddReferenceRulesFilter.paginationCurrent,
        onChange: (paginationCurrent, paginationPageSize) => {
            dispatch(updateDdReferenceRulesFilter(
                {
                    paginationCurrent,
                    paginationPageSize,
                },
            ));
        },
    });

    const loadDdReferenceRules = useCallback(() => {
        setIsLoading(true);
        dispatch(loadDrilldownReferenceRules({
            ...ddReferenceRulesFilter,
            parentDocumentTypeTemplateCode: templateCode,
        }))
            .then(unwrapResult)
            .then(() => {
                setCurrentManually(ddReferenceRulesFilter.paginationCurrent);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [setIsLoading, loadDrilldownReferenceRules, ddReferenceRulesFilter]);

    useEffect(() => {
        if (linkColumn && linkColumn !== ddReferenceRulesFilter.linkColumnName) {
            dispatch(setDdReferenceRulesFilter({
                paginationCurrent: DD_REFERENCES_TABLE_DEFAULT_PAGINATION.page,
                paginationPageSize: DD_REFERENCES_TABLE_DEFAULT_PAGINATION.pageSize,
                linkColumnName: linkColumn,
                enabled: true,
            }));
        }
    }, []);

    useAfterEffect(() => {
        loadDdReferenceRules();
    }, [ddReferenceRulesFilter]);

    return (
        <ReportDdReferenceRulesContext.Provider value={{
            isLoading,
        }}
        >
            <div className={cn('report-dd-references')}>
                <div className={cn('report-dd-references__buttons-bar')}>
                    <DdReferencesEditModalOpener />
                    <Button
                        disabled={isLoading}
                        type="primary"
                        icon={<FilterIcon />}
                        onClick={() => setIsFilterDrawerOpen(!isFilterDrawerOpen)}
                    >
                        Фильтры
                    </Button>
                    <DdReferencesFilter
                        isOpen={isFilterDrawerOpen}
                        setIsOpen={setIsFilterDrawerOpen}
                    />
                    <Badge
                        count={selectedRowKeys.length}
                        style={{zIndex: 1}}
                    >
                        <ModalOpenerComponent
                            modalProps={{
                                destroyOnClose: true,
                                title: 'Удаление правил связи',
                            }}
                            disabledControls={{
                                delete: isDeleting,
                            }}
                            handleDelete={setIsModalOpen => {
                                setIsDeleting(true);
                                dispatch(deleteDrilldownReferenceRules({
                                    ids: selectedRowKeys,
                                }))
                                    .then(unwrapResult)
                                    .then(res => {
                                        setSelectedRowKeys([]);
                                        setIsModalOpen(false);
                                        showMessageFromResponse({response: {data: res} as AxiosResponse});
                                        dispatch(updateDdReferenceRulesFilter({
                                            paginationCurrent: 1,
                                        }));
                                    }).catch(err => {
                                        showMessageFromResponse({response: err, isError: true});
                                    })
                                    .finally(() => {
                                        setIsDeleting(false);
                                    });
                            }}
                            component={(
                                <Button
                                    disabled={!selectedRowKeys.length}
                                    type="primary"
                                    className="button-critic"
                                    icon={<TrashIcon />}
                                >
                                    Удалить выбранное
                                </Button>
                            )}
                        >
                            Вы уверены, что хотите удалить выбранные правила <b>({selectedRowKeys.length} шт.)</b>?
                        </ModalOpenerComponent>
                    </Badge>
                    <ButtonChangeHistory />
                    <Button
                        type="default"
                        icon={<ArrowBackOutlined />}
                        onClick={() => {
                            removeQueryParams([DD_LINK_COLUMN_KEY], {action: 'replace'});
                            setSelectedTabKey?.(ReportConfigurationTabKey.REPORT_TABLE_SETTINGS);
                        }}
                    >
                        Вернуться на вкладку «Настройка табличной части»
                    </Button>
                    <Checkbox
                        disabled={isLoading}
                        checked={ddReferenceRulesFilter.enabled}
                        onChange={e => {
                            const {checked: isChecked} = e.nativeEvent.target as HTMLInputElement;
                            dispatch(updateDdReferenceRulesFilter({
                                enabled: isChecked,
                            }));
                        }}
                    >Скрыть неактивные
                    </Checkbox>
                </div>
                <div className={cn('report-dd-references__section-title')} />
                <div className={cn('report-dd-references__body')}>
                    <div className={cn('report-dd-references__attribute-label')}>
                        {/* <TagOutlined /> */}
                        <div className={cn('report-dd-references__attribute-label__label')}>
                            Поле для перехода:
                        </div>
                        <div className={cn('report-dd-references__attribute-label__link')}>{linkColumn}</div>
                    </div>
                    <div>
                        <DdReferencesTable
                            selection={{selectedRowKeys, setSelectedRowKeys}}
                            pagination={{current, pageSize, handleChange}}
                        />
                    </div>
                </div>
            </div>
        </ReportDdReferenceRulesContext.Provider>

    );
};
