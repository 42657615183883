export enum DeleteResponseSummaryStatus {
    ERROR = 'ERROR',
    OK = 'OK',
    UNDEFINED = 'UNDEFINED',
    WARNING = 'WARNING'
}

export type ResponseStatus = DeleteResponseSummaryStatus;

export enum ResponseEntityCode {
    FNS_REFRESH_TOKEN_TIME = 'FNS_REFRESH_TOKEN_TIME',
}
