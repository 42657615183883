import {createSelector} from '@reduxjs/toolkit';

import {AppState} from 'store/config/types';

import {userSliceName} from './user-slice-constants';
import {UserRoleFunction} from './user-slice-role-functions';

const selectUserSliceState = (state: AppState) => state[userSliceName];

export const selectAllUserFunctions = createSelector(selectUserSliceState, state => state.functions);
export const selectIsLoadingStatuses = createSelector(selectUserSliceState, state => state.loading);

export const selectIsLoadingUserFunctions = createSelector([
    selectIsLoadingStatuses,
    (_, sections: string[]) => sections,
], (loading, sections) => {
    const statuses = sections.reduce((prev, section) => {
        prev[section] = loading[section];
        return prev;
    }, {} as {[section: string]: boolean});
    return statuses;
});

export const selectUserFunctionsBySection = createSelector([
    selectAllUserFunctions,
    (_, section: string) => section,
], (functions, section) => functions[section]);

export const selectUserFunctions = createSelector([
    selectAllUserFunctions,
    (_, sections: string[]) => sections,
], (functions, sections) => {
    const funcs = sections.reduce((prev, section) => {
        prev[section] = functions[section];
        return prev;
    }, {} as {[section: string]: UserRoleFunction[]});
    return funcs;
});

export const selectUserFunctionsByDocId = createSelector([
    selectAllUserFunctions,
    (_, params: {tableReportDocId: string | null; templateCode: string | null}) => params,
], (functions, {tableReportDocId, templateCode}) => functions[`${tableReportDocId}${templateCode}`]);

export const userSelectors = {
    selectUserSliceState,
    selectAllUserFunctions,
};
