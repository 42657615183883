import {createSelector} from '@reduxjs/toolkit';

import {AppState} from '../../config/types';
import {netDirectoryFileSliceName} from './net-directory-file-slice-constants';

const selectNetDirectoryFileSliceState = (state: AppState) => state[netDirectoryFileSliceName];

export const selectNetDirectoryFileName = createSelector(
    selectNetDirectoryFileSliceState,
    state => state?.fileNameList,
);

export const selectNetDirectoryFileData = createSelector(
    selectNetDirectoryFileSliceState,
    state => state?.netDirectoryFileData,
);
