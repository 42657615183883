import {Empty} from 'antd';
import cn from 'classnames';
import React from 'react';

import {EntityMeta} from 'modules/metadata/metadata-types';

import {ListGridItem} from './list-grid-item/list-grid-item';

import './list-grid.less';

export interface ListGridProps {
    entityName?: string;
    data?: Record<string, any>[];
    meta?: EntityMeta;
}

export const ListGrid = ({data, meta}: ListGridProps) => (
    <div
        className={cn(
            'list-grid',
            data && data.length === 1 && 'list-with-one-item',
            data && data.length % 2 !== 0 && 'list-with-even-items',
            !data?.length && 'list-empty',
        )}
    >
        {meta && data && data.length > 0 && (
            data.map(item => (
                <ListGridItem
                    key={item?.id}
                    data={item}
                    meta={meta}
                />
            ))
        )}
        {(!data || !data?.length) && (
            <Empty className="list-grid__empty" />
        )}
    </div>
);
