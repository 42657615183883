import {
    Button, Form, Input,
    InputNumber,
} from 'antd';
import {useForm} from 'antd/lib/form/Form';
import cn from 'classnames';
import React, {
    useEffect,
    useImperativeHandle, useMemo, useRef, useState,
} from 'react';
import {v4 as uid} from 'uuid';

import {IconsMap} from 'components/dynamic-icon';
import {CustomSelect} from 'components/form/inputs/custom-select';
import {FilterValueType} from 'components/report-configuration/report-configuration.constants';
import {getFilterValueTypeByColumnType} from 'components/report-configuration/report-configuration.utils';
import {DateCell} from 'components/table/columns/date-cell/date-cell';
import {DATE, DATE_DMY} from 'shared/constants/date-format';
import {useAppSelector} from 'store/config/hooks';
import {selectTableReportAttributes, selectTableReportFiltersByFieldType} from 'store/slices/table-report-slice/table-report-slice-selectors';

import {TableColumnFilterExpressionGroup, TableColumnFilterExpressionGroupRef} from './table-column-filter-expression-group';
import {TableColumnFilterExpression, TableColumnFilterOperand} from './table-column-filter-types';

interface TableColumnFilterExpressionOperandProps {
    operand?: TableColumnFilterOperand;
    isInEditMode: boolean;
    remove: () => void;
    depth: number;
    itemIndex: string;
    columnName: string;
}

export interface TableColumnFilterExpressionOperandRef {
    getValues: (skipValidation?: boolean) => Promise<TableColumnFilterOperand | TableColumnFilterExpression>;
}

export const TableColumnFilterExpressionOperand = React.forwardRef<
    TableColumnFilterExpressionOperandRef,
    TableColumnFilterExpressionOperandProps>((
        {
            operand, remove, isInEditMode, depth, itemIndex, columnName,
        }: TableColumnFilterExpressionOperandProps,
        ref,
    ) => {
        const [form] = useForm();

        const expressionRef = useRef<TableColumnFilterExpressionGroupRef>(null);

        const [isValueAllowedEmpty, setIsComparisonValueAllowedEmpty] = useState(false);

        const tableReportAttributes = useAppSelector(selectTableReportAttributes);

        const filterValueType = useMemo(() => {
            const columnType = tableReportAttributes?.find(attr => attr.name === columnName)?.type;
            const valueType = getFilterValueTypeByColumnType(columnType);
            return valueType;
        }, [tableReportAttributes]);

        const tableReportFilterConditions = useAppSelector(
            s => selectTableReportFiltersByFieldType(s, {fieldType: filterValueType}),
        );

        const getValues = async (skipValidation = false) => {
            if (!Array.isArray(operand)) {
                const isValid = await (async () => {
                    let result = true;
                    try {
                        await form.validateFields();
                    } catch (e) {
                        if (e?.errorFields?.length) result = false;
                    }
                    return result;
                })();

                if (!isValid && !skipValidation) return Promise.reject(new Error('Form validation error'));

                const values: TableColumnFilterOperand = {
                    columnName,
                    value: form.getFieldValue('value'),
                    operator: form.getFieldValue('operator'),
                };

                return values;
            }
            return expressionRef.current?.getValues?.();
        };

        useImperativeHandle(ref, () => ({getValues}));

        useEffect(() => {
            form.resetFields();
            if (!Array.isArray(operand)) {
                setIsComparisonValueAllowedEmpty(
                    operand?.operator === 'EMPTY' || operand?.operator === 'NOT_EMPTY',
                );
            }
        }, [operand]);

        const groupIdentificator = useMemo(() => {
            if (!Array.isArray(operand)) return undefined;
            let ident = operand[0];
            if (!ident) return {connectBy: 'OR' as 'OR' | 'AND', enabled: false, uid: uid()};
            if (!ident.uid) ident = {...ident, uid: uid()};
            return ident;
        }, [operand]);

        if (!Array.isArray(operand)) {
            return (
                <Form
                    layout="vertical"
                    form={form}
                    className={cn('table-column-filter-expression-operand__form')}
                    onValuesChange={changed => {
                        if (changed.operator !== undefined) {
                            const nextReportCondition = tableReportFilterConditions
                                ?.find(lookup => lookup.lookupCode === changed.operator);

                            const {lookupCode: nextConditionLookupCode} = nextReportCondition ?? {};
                            if (nextConditionLookupCode === 'EMPTY' || nextConditionLookupCode === 'NOT_EMPTY') {
                                setIsComparisonValueAllowedEmpty(true);
                                form.setFieldsValue({value: undefined});
                            } else setIsComparisonValueAllowedEmpty(false);
                        }
                    }}
                >
                    <div
                        className={cn('table-column-filter-expression-operand__item')}
                    >
                        <div className={cn('table-column-filter-expression-operand__item__body')}>
                            <div className={cn('table-column-filter-expression-operand__item__body__form')}>
                                <Form.Item
                                    label="Условие"
                                    required
                                    name="operator"
                                    style={{width: 100}}
                                    rules={[{required: true, message: 'Пожалуйста, выберите условие'}]}
                                    initialValue={operand?.operator}
                                >
                                    <CustomSelect
                                        entries={(tableReportFilterConditions ?? [])
                                            .map(({lookupCode: value, meaning: label}) => ({
                                                value,
                                                label,
                                            }))}
                                        settings={{
                                            isDisabled: !isInEditMode,
                                            placeholder: 'Выберите условие',
                                            size: 'small',
                                        }}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Значение"
                                    required={!isValueAllowedEmpty}
                                    name="value"
                                    style={{maxWidth: 254}}
                                    rules={[{
                                        required: !isValueAllowedEmpty,
                                        message: 'Пожалуйста, введите значение',
                                    }]}
                                    initialValue={operand?.value}
                                >
                                    {(() => {
                                        const isDisabled = !isInEditMode || isValueAllowedEmpty;

                                        if (filterValueType === FilterValueType.number) {
                                            return (
                                                <InputNumber
                                                    size="small"
                                                    style={{width: '100%'}}
                                                    disabled={isDisabled}
                                                    placeholder="Введите значение"
                                                    decimalSeparator=","
                                                />
                                            );
                                        }
                                        if (filterValueType === FilterValueType.date) {
                                            return (
                                                <DateCell
                                                    size="small"
                                                    format={DATE_DMY}
                                                    requestFormat={DATE}
                                                    disabled={isDisabled}
                                                />
                                            );
                                        }

                                        return (
                                            <Input
                                                size="small"
                                                disabled={isDisabled}
                                                placeholder="Введите значение"
                                            />
                                        );
                                    })()}
                                </Form.Item>

                                <Form.Item
                                    style={{flexGrow: 0}}
                                    label={<>&nbsp;</>}
                                >
                                    <Button
                                        size="small"
                                        type="default"
                                        className="btn-only-icon"
                                        onClick={() => remove()}
                                        disabled={!isInEditMode}
                                    >
                                        <IconsMap.TrashXOutlined />
                                    </Button>
                                </Form.Item>

                            </div>
                        </div>
                    </div>
                </Form>
            );
        }

        return (
            <div className={cn('table-column-filter-expression-operand__group')}>
                <TableColumnFilterExpressionGroup
                    columnName={columnName}
                    isInEditMode={isInEditMode}
                    initialExpression={
                        [groupIdentificator ?? {
                            connectBy: 'OR', uid: uid(),
                        }, ...operand.slice(1) as TableColumnFilterOperand[]]
                    }
                    ref={expressionRef}
                    isChild
                    remove={() => {
                        remove?.();
                    }}
                    depth={depth + 1}
                    itemIndex={itemIndex}
                />
            </div>
        );
    });
