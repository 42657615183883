import {
    CloseOutlined,
    LoadingOutlined, SafetyCertificateOutlined,
} from '@ant-design/icons';
import {Button} from 'antd';
import {FormInstance} from 'antd/lib/form';
import cn from 'classnames';
import {createDetachedSignature, createHash} from 'crypto-pro';
import React, {useImperativeHandle, useRef, useState} from 'react';
import {v4 as uid} from 'uuid';

import {IconsMap} from 'components/dynamic-icon';
import {ModalOpenerComponent, ModalOpenerComponentRef} from 'components/modal-opener-component';
import {fetchCryptoProAttachmentHash} from 'modules/documents/documents-api';
import {FormItemProps} from 'shared/types';
import {showMessage} from 'shared/utils';
import {CertificateExtended} from 'shared/utils/crypto-pro';
import {CADESCOM_HASH_ALGORITHM_CP_SHA1} from 'shared/utils/crypto-pro/crypto-pro-constants';

import {CryptoProSigningInputModal} from './crypto-pro-signing-input-modal';
import {DocumentToSignInDatabase, FileWithId} from './crypto-pro-signing-input-types';
import {getOverlappingFiles} from './crypto-pro-signing-input-utils';

import './crypto-pro-signing-input.less';

interface CryptoProSigningInputProps extends FormItemProps {
    className?: cn.Argument;
    value?: FileWithId[];
    entityName?: string;
    showSignComponent?: boolean;

    // (!) 1-й вариант работы: указать форму и поля, из которых забирать файлы.
    form?: FormInstance;
    fileFieldNames?: string[]; // названия полей с файлами, которые нужно подписать

    // (!) 2-й вариант работы: передать напрямую файлы, которые надо подписать.
    fileList?: File[];

    // (!) 3-й вариант работы: передать id документов/аттачей на бэке
    documentsToSignInDB?: DocumentToSignInDatabase[];

    // ---
    isSignaturesListHidden?: boolean; // скрывать список подписей из value
    showIconOnly?: boolean; // отображать одну иконку вместо кнопки
    buttonLabel?: string; // надпись на кнопке
}

export const CryptoProSigningInput = React.forwardRef<any, CryptoProSigningInputProps>((
    {
        className,
        form,
        value,
        onChange,
        entityName,
        fileFieldNames,
        documentsToSignInDB,
        showSignComponent = true,
        fileList,
        isSignaturesListHidden,
        buttonLabel,
        showIconOnly,
    }: CryptoProSigningInputProps,
    ref,
) => {
    const [selectedCertificate, setSelectedCertificate] = useState<CertificateExtended>();

    const [isWithPluginInitializationError, setIsWithPluginInitializationError] = useState(false);
    const [isSignButtonTemporarilyInactive, setIsSignButtonTemporarilyInactive] = useState(false);
    const formRef = useRef<FormInstance>(null);
    const modalRef = useRef<ModalOpenerComponentRef | null>(null);

    const [isWithResign, setIsWithResign] = useState(true);

    const [isSigning, setIsSigning] = useState(false);

    useImperativeHandle(ref, () => modalRef.current);

    const getFilesToSign = () => {
        const {overlappingFiles, overlappingFileNamesInDB} = getOverlappingFiles({
            form,
            currentValue: value,
            fileFieldNames,
            documentsToSignInDB,
        });

        const filesFlat = (() => {
            let files: File[] = [];
            fileFieldNames?.forEach(fieldName => {
                const fieldValue = form?.getFieldValue(fieldName);
                if (Array.isArray(fieldValue)) {
                    files = [...files, ...fieldValue.map(f => {
                        if (f.file) return f.file;
                        return f;
                    })];
                } else files = [...files, fieldValue];
            });
            if (fileList) files = [...files, ...fileList];
            return files;
        })();

        const filesToSign = filesFlat.filter(v => {
            if (!isWithResign && overlappingFiles?.includes(v)) return false;
            return !!v;
        });

        return {filesToSign, overlappingFileNamesInDB};
    };

    const handleFinish = () => {
        const certificateThumbprint = selectedCertificate?.thumbprint;
        const certificateCadescomAlgorithm = selectedCertificate?.algorithmCadescomConstant;

        const {filesToSign, overlappingFileNamesInDB} = getFilesToSign();

        if (certificateThumbprint && certificateCadescomAlgorithm !== undefined) {
            (async () => {
                modalRef.current?.closeModal();

                const signFile = async (fileHash: string, fileName: string) => {
                    const privateKey = await selectedCertificate.certificate.getCadesProp('PrivateKey');

                    if (certificateCadescomAlgorithm !== CADESCOM_HASH_ALGORITHM_CP_SHA1) {
                        await privateKey.propset_CachePin(true); // кэшируем пароль от
                        // контейнера, чтобы он не запрашивался каждый раз
                    }

                    const detachedSignature = await createDetachedSignature(certificateThumbprint, fileHash, {
                        hashedAlgorithm: certificateCadescomAlgorithm,
                    });

                    const signatureFile = new File([detachedSignature], `${fileName}.sig`, {type: 'text/plain'});

                    return signatureFile;
                };

                const signDocument = async (file?: File) => {
                    if (!file) return undefined;

                    const fileArrayBuffer = await file.arrayBuffer();
                    const fileHash = await createHash(fileArrayBuffer, {
                        hashedAlgorithm: certificateCadescomAlgorithm,
                    });
                    const fileName = file.name ?? 'sgn';

                    const signatureFile = await signFile(fileHash, fileName);

                    return signatureFile;
                };

                const signDocumentById = async (documentName: string, documentId?: string, attachId?: string) => {
                    const fileHash = (await fetchCryptoProAttachmentHash({
                        entityName,
                        attachId,
                        docId: documentId,
                        algorithmCode: certificateCadescomAlgorithm,
                    })).data;

                    const signatureFile = await signFile(fileHash, documentName);

                    return signatureFile;
                };

                try {
                    setIsSigning(true);
                    const signatures = await Promise.all([
                        ...(filesToSign ?? []).map(file => signDocument(file)),
                        ...(documentsToSignInDB ?? [])
                            .filter(({documentId, documentName, attachId}) => {
                                if (!isWithResign && overlappingFileNamesInDB.includes(documentName)) return false;
                                return !!documentId || !!attachId;
                            })
                            .map(({documentId, documentName, attachId}) => signDocumentById(
                                documentName,
                                documentId,
                                attachId,
                            )),
                    ]);

                    const filesToSet = isWithResign ? signatures : [...(value ?? []), ...signatures];

                    filesToSet.filter(file => !!file)
                        .forEach((file: any) => {
                            file.uid = uid();
                        });

                    onChange?.(filesToSet, undefined);
                } catch (e) {
                    console.warn(e);
                    showMessage({message: 'Произошла ошибка формирования подписей', isError: true});
                } finally {
                    setIsSigning(false);
                }
            })();
        }
    };

    const isComponentClickPrevented = (files?: File[]) => !files?.length
        && !documentsToSignInDB?.length && !fileList?.length;

    return (
        <div className={cn('crypto-pro-signing-input')}>
            {!isSignaturesListHidden && (
                <div className={cn('crypto-pro-signing-input__files')}>
                    {(() => {
                        if (isSigning) {
                            return (
                                <div className={cn('crypto-pro-signing-input__files__signing')}>
                                    <LoadingOutlined />
                                    Формирование подписей...
                                </div>
                            );
                        }
                        if (!value?.length) {
                            return (
                                <div className={cn('crypto-pro-signing-input__files__empty')}>
                                    <IconsMap.FileOutlined />
                                    Файлы подписи не прикреплены
                                </div>
                            );
                        }
                        return value?.map(signatureFile => (
                            <div
                                key={signatureFile?.uid ?? signatureFile.name}
                                className={cn('crypto-pro-signing-input__files__file')}
                            >
                                <IconsMap.FileOutlined
                                    className={cn('crypto-pro-signing-input__files__file__icon')}
                                />
                                <span className={cn('crypto-pro-signing-input__files__file__name')}>
                                    {signatureFile.name}
                                </span>
                                <CloseOutlined
                                    className={cn('crypto-pro-signing-input__files__file__icon-close')}
                                    onClick={() => {
                                        onChange?.(value?.filter(v => v.uid !== signatureFile.uid), undefined);
                                    }}
                                />
                            </div>
                        ));
                    })()}
                </div>
            )}

            <ModalOpenerComponent
                ref={modalRef}
                componentWrapperClassNames={className}
                modalProps={{
                    centered: true,
                    forceRender: false,
                    destroyOnClose: true,
                    title: 'Настройка подписи',
                    style: {marginLeft: -14},
                    width: 800,
                }}
                hideControls={{
                    save: isWithPluginInitializationError,
                }}
                disabledControls={{
                    save: !selectedCertificate || isSignButtonTemporarilyInactive || isSigning,
                }}
                controlLabels={{
                    save: (isSignButtonTemporarilyInactive || isSigning) ? (
                        <div className="">
                            <LoadingOutlined className="mr-1" />
                            Подписать
                        </div>
                    ) : 'Подписать',
                    cancel: isWithPluginInitializationError ? 'Закрыть' : undefined,
                }}
                handleSave={() => {
                    setIsSignButtonTemporarilyInactive(true);
                    setTimeout(() => {
                        setIsSignButtonTemporarilyInactive(false);
                    }, 1000);
                    formRef.current?.submit();
                }}
                onComponentClick={() => {
                    const {filesToSign} = getFilesToSign();

                    if (isComponentClickPrevented(filesToSign)) {
                        showMessage({
                            message: 'Не выбрано ни одного файла для подписания',
                            isError: true,
                        });
                    }
                }}
                preventComponentClick={() => {
                    const {filesToSign} = getFilesToSign();
                    return isComponentClickPrevented(filesToSign);
                }}
                component={showSignComponent ? (
                    showIconOnly
                        ? (isSigning && isSignaturesListHidden)
                            ? (
                                <LoadingOutlined className={cn(
                                    'crypto-pro-signing-input__sign-icon',
                                    'crypto-pro-signing-input__sign-icon_loading',
                                )}
                                />
                            )
                            : <SafetyCertificateOutlined className={cn('crypto-pro-signing-input__sign-icon')} />
                        : (
                            <Button
                                type="primary"
                                style={{minWidth: 131}}
                                disabled={isSignButtonTemporarilyInactive || isSigning}
                            >
                                {(isSigning && isSignaturesListHidden)
                                    ? <LoadingOutlined className={cn('crypto-pro-signing-input__sign-icon_loading')} />
                                    : <SafetyCertificateOutlined />}
                                {buttonLabel ?? 'Подписать'}
                            </Button>
                        )
                ) : undefined}
                afterModalClose={() => {
                    setSelectedCertificate(undefined);
                    setIsWithPluginInitializationError(false);
                    setIsSignButtonTemporarilyInactive(false);
                    setIsWithResign(true);
                }}
            >
                <CryptoProSigningInputModal
                    ref={formRef}
                    selectedCertificate={selectedCertificate}
                    setSelectedCertificate={setSelectedCertificate}
                    isWithPluginInitializationError={isWithPluginInitializationError}
                    setIsWithPluginInitializationError={setIsWithPluginInitializationError}
                    handleFinish={handleFinish}
                    currentValue={value}
                    fileFieldNames={fileFieldNames}
                    isWithResign={isWithResign}
                    mainForm={form}
                    setIsWithResign={setIsWithResign}
                    documentsToSignInDB={documentsToSignInDB}
                />
            </ModalOpenerComponent>
        </div>
    );
});
