import {createSelector} from '@reduxjs/toolkit';
import {uniqBy} from 'lodash';

import {AppState} from 'store/config/types';

import {menuSliceName} from './menu-slice-constants';
import {MenuItemType, MenuStructureItem} from './menu-slice-types';
import {createNestedMenuStructure} from './menu-slice-utils';

const selectMenuSliceState = (state: AppState) => state[menuSliceName];

export const selectMenuStructure = createSelector(
    selectMenuSliceState,
    ({structure}) => structure,
);

export const selectMenuStructureObject = createSelector(
    selectMenuStructure,
    structure => {
        const menuItems = structure?.filter(item => item.itemType === MenuItemType.MENU_ITEM);
        const functionItems = structure?.filter(item => item.itemType === MenuItemType.FUNCTION_ITEM);

        const structureObject = {
            menuItems: createNestedMenuStructure(menuItems),
            functions: createNestedMenuStructure(functionItems),
        };

        return structureObject;
    },
);

export const selectMenuSearchLine = createSelector(
    selectMenuSliceState,
    ({searchLine}) => searchLine,
);

export const selectMenuItemsFilteredBySearchLine = createSelector([
    selectMenuStructure,
    selectMenuSearchLine,
], (menuStructure, searchLine = '') => {
    let foundItems: MenuStructureItem[] | undefined = [];
    const menuItems = menuStructure?.filter(item => item.itemType === MenuItemType.MENU_ITEM);

    if (!searchLine) foundItems = menuItems;
    else {
        menuItems?.forEach(item => {
            if (item.itemTitle.toLowerCase().includes(searchLine?.toLowerCase())) {
                const pushWithParent: (it: MenuStructureItem | undefined) => void = it => {
                    if (!it) return;
                    foundItems?.push(it);

                    if (it?.parentId) {
                        const parentItem = menuItems.find(mItem => mItem.id === it.parentId);
                        if (parentItem) {
                            pushWithParent(parentItem);
                        }
                    }
                };
                pushWithParent(item);
            }
        });
    }
    foundItems = uniqBy(foundItems, 'id');

    return createNestedMenuStructure(foundItems);
});

export const selectIsMenuOpen = createSelector(
    selectMenuSliceState,
    ({isMenuOpen}) => isMenuOpen,
);

export const selectMenuMeta = createSelector(
    selectMenuSliceState,
    ({meta}) => meta,
);

export const selectMenuOpenId = createSelector(
    selectMenuMeta,
    meta => meta.openId,
);

export const selectSelectedMenuItem = createSelector(
    selectMenuSliceState,
    ({selectedMenuItem}) => selectedMenuItem,
);

export const selectMenuStructureIdMap = createSelector(
    selectMenuSliceState,
    ({structureIdMap}) => structureIdMap,
);

export const selectMenuItemByEntityName = createSelector([
    selectMenuStructure,
    (_, entityName: string) => entityName,
], (structure, entityName) => structure?.find(menu => menu.entityName === entityName));
