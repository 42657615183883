import React from 'react';

import {performActionForForm} from 'modules/data/data-actions';
import {FormEntityData} from 'modules/data/data-types';
import {EntityMeta, RequestType} from 'modules/metadata/metadata-types';
import {useAppDispatch} from 'store/config/hooks';

import {Form} from '../form';

import './form-page.less';

interface FormPageProps {
    entityName: string;
    formData?: FormEntityData;
    metadata?: EntityMeta;
    onClose: () => void;
}

export const FormPage = ({
    entityName, formData, metadata, onClose,
}: FormPageProps) => {
    const dispatch = useAppDispatch();
    const handleFormSubmit = (
        // eslint-disable-next-line @typescript-eslint/no-shadow
        entityName: string,
        referenceUrl: string,
        requestType: RequestType,
        newData: Record<string, any>,
        isJsonRequest?: boolean,
    ) => dispatch(performActionForForm()(
        entityName, referenceUrl, requestType, newData, isJsonRequest,
    ));

    return (
        <div className="form-page">
            {formData?.data?.disabledFormFlag ? (
                <div className="form-placeholder">{formData.data?.disabledFormMessage || 'Error'}</div>
            ) : (
                <Form
                    formData={formData}
                    meta={metadata}
                    entityName={entityName}
                    onClose={onClose}
                    actionForForm={handleFormSubmit}
                />
            )}
        </div>
    );
};
