import {Checkbox} from 'antd';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {selectContextRawData} from 'modules/context/context-selector';
import {checkConfirm} from 'modules/data/data-actions';
import {selectTableEntityData} from 'modules/data/data-selectors';
import {useAppSelector} from 'store/config/hooks';

import {TableActionProps} from '../table-action-types';

import './checkbox-check-confirm.less';

export const CheckboxCheckConfirm: React.FunctionComponent<TableActionProps> = ({
    meta,
    entityName,
    ...props
}: TableActionProps) => {
    const dispatch = useDispatch();
    const checked: boolean | undefined = useAppSelector(selectTableEntityData(entityName))?.isConfirmed;
    const response = useAppSelector(selectContextRawData);
    const {referenceUrl} = meta;
    useEffect(() => {
        if (referenceUrl && response) {
            dispatch(checkConfirm(
                entityName,
                referenceUrl,
                response,
            ));
        }
    }, [response, checked]);

    return (
        <Checkbox
            className="checkbox-check-confirm"
            {...props}
            checked={checked}
        >
            {meta.actionTitle}
        </Checkbox>
    );
};
