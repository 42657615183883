import {FormFieldWatcher, FormFieldWatcherConnective} from './use-form-watcher-types';

export const createConnectiveJoiner = (
    connective: FormFieldWatcher['conditions'][0]['withConnective'],
) => (a: boolean, b: boolean) => {
    if (!connective) return a && b;
    if (connective === FormFieldWatcherConnective.AND) return a && b;
    if (connective === FormFieldWatcherConnective.AND_NOT) return a && !b;
    if (connective === FormFieldWatcherConnective.OR_NOT) return a || !b;
    if (connective === FormFieldWatcherConnective.OR) return a || b;
    return a || b;
};
