import {Card} from 'antd';
import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

import {selectEntityData} from 'modules/data';
import {loadDummyData, setData} from 'modules/data/data-actions';
import {DirectoryFormEntityData} from 'modules/data/data-types';
import {selectMetadata, DirectoryEntityMeta, loadMetadata} from 'modules/metadata';
import {EntityType} from 'shared/constants/entities';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';

import {Spinner} from '../spinner';
import {SubsectionSwitcher} from '../subsection-switcher/subsection-switcher';
import {TableAction} from '../table/action';
import {DirectoryFormItemContainer} from './directory-form-item/directory-form-item-container';

export interface DirectoryFormProps {
    entityName: string;
    url: string;
}

export const DirectoryForm = ({entityName, url}: DirectoryFormProps) => {
    const [viewingTab, setViewingTab] = useState<string>('');
    const dispatch = useAppDispatch();
    const location = useLocation();

    const metadata = useAppSelector(
        state => selectMetadata(entityName, EntityType.TABS_WITH_FORM)(state) as DirectoryEntityMeta,
    );

    const data = useAppSelector(state => selectEntityData(
        entityName, EntityType.TABS_WITH_FORM,
    )(state)) as DirectoryFormEntityData;

    const setCurrentTab = (tab: string) => {
        setViewingTab(prev => (tab !== prev ? tab : prev));
        dispatch(setData({entityName, entityType: EntityType.TABS_WITH_FORM, data: {tab}}));
    };

    useEffect(() => {
        (async () => {
            if (!metadata) {
                await dispatch(loadMetadata(entityName, EntityType.TABS_WITH_FORM));
            } else if (!data || !data?.tabs) {
                await dispatch(loadDummyData(entityName, EntityType.TABS_WITH_FORM, 'tabs'));
            }
        })();
    }, [data, metadata]);

    useEffect(() => {
        if (data?.tabs?.length) setCurrentTab(viewingTab);
    }, [data?.tabs]);

    const isLoaded = metadata?.fields && data?.tabs;
    return isLoaded ? (
        <Card
            title={metadata.title}
            className="directory-container"
        >
            <div className="directory-container__actions">
                {Boolean(metadata?.actions?.length) && metadata?.actions && metadata.actions.map(action => (
                    <TableAction
                        meta={action}
                        key={action.actionTitle}
                        entityName={entityName}
                        url={url}
                        link={`/${metadata.name}/${data?.tab || ''}`}
                    />
                ))}
                {metadata?.subsections && Boolean(metadata?.subsections?.length) && (
                    <SubsectionSwitcher
                        entityName={entityName}
                        location={location.pathname}
                        subsections={metadata.subsections}
                    />
                )}
            </div>
            <DirectoryFormItemContainer
                url={url}
                metadata={metadata}
                currentTab={viewingTab}
                data={data?.tabs?.sort((a, b) => (a.id as number) - (b.id as number))}
                setCurrentTab={setCurrentTab}
            />
        </Card>
    ) : <Spinner />;
};
