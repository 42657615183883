import {createSelector} from '@reduxjs/toolkit';

import {adaptSortDataForRequest} from 'components/@common/widgets/custom-table/table-column-menu';
import {AppState} from 'store/config/types';

import {tableReportSliceName} from '../table-report-slice-constants';

const rootSelector = (state: AppState) => state[tableReportSliceName];

export const selectTableReportSortData = createSelector(
    createSelector(rootSelector, ({reportOptions}) => reportOptions),
    reportOptions => {
        const {sort} = reportOptions;
        return sort;
    },
);

export const selectTableReportSortDataAdaptedForRequest = createSelector(
    selectTableReportSortData, sort => adaptSortDataForRequest(sort),
);
