import React, {useEffect, useState} from 'react';

import {performRequest} from 'shared/utils';
import {combineString} from 'shared/utils/combine-string';

export interface TooltipAsStringStructureProps {
    contentStructure?: string;
    requestUrl?: string;
}

export const TooltipAsStringStructure = ({
    contentStructure,
    requestUrl = '',
}: TooltipAsStringStructureProps) => {
    const [data, setData] = useState<Record<string, any>>({data: undefined});

    useEffect(() => {
        (async () => {
            try {
                const response = await performRequest({url: requestUrl, method: 'GET'});
                setData({data: response.data});
            } catch (e) {
                console.error(e);
            }
        })();
    }, []);

    return <div>{contentStructure ? combineString(contentStructure, data) : ''}</div>;
};
