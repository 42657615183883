import {createAsyncThunk} from '@reduxjs/toolkit';

import {tableReportSliceName} from '../table-report-slice-constants';
import {TableReportAddCommentToRowRequest, tableReportAttachmentsApi} from './table-report-row-attachments-api';

const {
    postAddCommentToRow,
} = tableReportAttachmentsApi;

export const attachCommentToRow = createAsyncThunk<
    any, TableReportAddCommentToRowRequest
>(
    `${tableReportSliceName}/attachCommentToRow`,
    async (args, {rejectWithValue}) => {
        try {
            const addResultResponse = await postAddCommentToRow(args);
            return addResultResponse.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);
