import React from 'react';
import {useHistory} from 'react-router-dom';

import {ActionButton} from '../action-button';
import {TableActionProps} from '../table-action-types';

export const OpenForm = ({
    meta,
    entityName,
    ...props
}: TableActionProps) => {
    const history = useHistory();
    const handleClick = () => {
        history.push(`/${entityName}?entity=-1`);
    };

    const Component = ActionButton;
    return (
        <Component
            {...props}
            onClick={handleClick}
            actionIcon={meta.actionIcon}
        >
            {meta.actionTitle}
        </Component>
    );
};
