import {Form, Input, Button} from 'antd';
import React, {useEffect, useState} from 'react';

import {WithTooltipError} from 'components/@common/specific/with-tooltip-error/with-tooltip-error';
import {DynamicIcon} from 'components/dynamic-icon';
import {getRegExpPassword} from 'components/form/utils/form.utils';
import {ChangePasswordPageForm} from 'pages/change-password-page/change-password-types';
import {createRequiredValidator} from 'pages/login-page/login-form/login-form.utils';
import {useLoginFormStateErrors} from 'pages/login-page/use-login-form-state-errors/use-login-form-state-errors';
import {ReactComponent as LockIcon} from 'shared/assets/auth/lock.svg';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {
    changePassword,
    getChangePasswordSettings,
    selectAuthError,
    ChangePasswordSettings,
    AuthErrors,
} from 'store/slices/auth-slice';
import {selectIsThunkPending, selectThunkError} from 'store/slices/loading-state-slice';

import 'pages/change-password-page/change-password-form/change-password-form.less';

export const ChangePasswordForm: React.FC = () => {
    const dispatch = useAppDispatch();

    const error = useAppSelector(selectAuthError);
    const isChangePasswordSettingsFailed = !!useAppSelector((
        s => selectThunkError(s, getChangePasswordSettings.typePrefix)
    ));
    const isChangePasswordRequestLoading = useAppSelector((
        s => selectIsThunkPending(s, changePassword.typePrefix)
    ));
    const isChangePasswordSettingsLoading = useAppSelector((
        s => selectIsThunkPending(s, getChangePasswordSettings.typePrefix)
    ));
    const isLoading = isChangePasswordRequestLoading || isChangePasswordSettingsLoading;

    const [passwordSettings, setPasswordSettings] = useState<ChangePasswordSettings | undefined>();
    const isDisabled = isLoading || !passwordSettings;
    const hasErrors = error?.message || isChangePasswordSettingsFailed;

    const {
        fieldsErrors,
        resetErrorByFieldName,
        setErrorByFieldName,
    } = useLoginFormStateErrors({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    });

    useEffect(() => {
        dispatch(getChangePasswordSettings())
            .unwrap()
            .then(data => setPasswordSettings(data));
    }, []);

    const passwordRegexp = getRegExpPassword(passwordSettings?.allowedCharactersInPassword);
    const validationMessage = 'Пароль должен содержать только латинские буквы,'
    + ' не менее одной заглавной, одной строчной, одной цифры'
    + ` и одного метасимвола ${passwordSettings?.allowedCharactersInPassword}`;

    const handleSubmit = (data: ChangePasswordPageForm) => {
        dispatch(changePassword(data));
    };

    return (
        <Form
            className="change-password-form"
            onFinish={handleSubmit}
        >
            <h1>
                Смена пароля
            </h1>
            <div className="change-password-form__fields">
                <WithTooltipError
                    className="change-password-form-tooltip"
                    errors={fieldsErrors.currentPassword}
                    visible={!!fieldsErrors.currentPassword}
                    withExclamation
                >
                    <Form.Item
                        name="currentPassword"
                        rules={[
                            createRequiredValidator({
                                onResolve: () => resetErrorByFieldName('currentPassword'),
                                onReject: () => setErrorByFieldName('currentPassword', 'Введите текущий пароль'),
                            }),
                        ]}
                    >
                        <Input.Password
                            size="large"
                            prefix={<LockIcon />}
                            type="password"
                            placeholder="Текущий пароль"
                            disabled={isDisabled}
                        />
                    </Form.Item>
                </WithTooltipError>
                <WithTooltipError
                    className="change-password-form-tooltip"
                    errors={fieldsErrors.newPassword}
                    visible={!!fieldsErrors.newPassword}
                    withExclamation
                >
                    <Form.Item
                        name="newPassword"
                        rules={[
                            () => ({
                                validator(_: any, value: string) {
                                    if (!value) {
                                        setErrorByFieldName('newPassword', 'Введите новый пароль');
                                        return Promise.reject();
                                    }

                                    if (!(value
                                            && passwordSettings?.maxLengthPwd
                                            && value.length <= +passwordSettings.maxLengthPwd
                                            && passwordSettings?.minLengthPwd
                                            && value.length >= +passwordSettings.minLengthPwd)) {
                                        setErrorByFieldName('newPassword', `Пароль должен быть длиной
                                    не менее ${passwordSettings?.minLengthPwd} и 
                                    не более ${passwordSettings?.maxLengthPwd} символов`);
                                        return Promise.reject();
                                    }

                                    if (!value.match(passwordRegexp)) {
                                        setErrorByFieldName('newPassword', validationMessage);
                                        return Promise.reject();
                                    }

                                    resetErrorByFieldName('newPassword');
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            size="large"
                            prefix={<LockIcon />}
                            type="password"
                            placeholder="Новый пароль"
                            disabled={isDisabled}
                        />
                    </Form.Item>
                </WithTooltipError>
                <WithTooltipError
                    className="change-password-form-tooltip"
                    errors={fieldsErrors.confirmNewPassword}
                    visible={!!fieldsErrors.confirmNewPassword}
                    withExclamation
                >
                    <Form.Item
                        name="confirmNewPassword"
                        dependencies={['newPassword']}
                        rules={[
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value) {
                                        setErrorByFieldName(
                                            'confirmNewPassword', 'Введите подтверждение пароля',
                                        );
                                        return Promise.reject();
                                    }
                                    if (getFieldValue('newPassword') !== value) {
                                        setErrorByFieldName(
                                            'confirmNewPassword', 'Пароль и его подтверждение должны совпадать',
                                        );
                                        return Promise.reject();
                                    }

                                    resetErrorByFieldName('confirmNewPassword');
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            size="large"
                            prefix={<LockIcon />}
                            type="password"
                            placeholder="Подтверждение пароля"
                            disabled={isDisabled}
                        />
                    </Form.Item>
                </WithTooltipError>
                {hasErrors && (
                    <div className="change-password-form__error">
                        <DynamicIcon
                            type="ExclamationCircleOutlined"
                        />
                        {error?.message && <>{error?.message}</>}
                        {isChangePasswordSettingsFailed && (
                            <div>{AuthErrors.CHANGE_PASSWORD_SETTINGS_REQUEST_FAILED}</div>
                        )}
                    </div>
                )}
                <Form.Item noStyle>
                    <Button
                        htmlType="submit"
                        type="primary"
                        size="large"
                        block
                        loading={isLoading}
                        disabled={isDisabled}
                    >
                        Войти
                    </Button>
                </Form.Item>
            </div>
        </Form>
    );
};
