import {camelCase} from 'lodash';
import isNumber from 'lodash/isNumber';
import {Moment} from 'moment/moment';

import {DATE} from 'shared/constants/date-format';
import {AisDashboardChildDto, AisDashboardElementDto} from 'store/slices/ais-slice/ais-dashboards/ais-dashboards-types';

export const convertMomentToDate = (moment: Moment | null) => {
    if (!moment) return undefined;
    return moment?.format(DATE);
};
const getChildItem = (childList: AisDashboardChildDto[]) => childList?.reduce(
    (acc, child) => {
        acc[camelCase(child.code)] = isNumber(child.count) && child.count > 0 ? child.count : '-';
        return acc;
    }, {} as Record<string, number | string>,
);
const checkingParentElement = (code: string) => code.split('_').length <= 3;
export const convertAisDashboardElementDtoToRequestStatusesData = (elements?: AisDashboardElementDto[]) => elements
    ?.map(item => {
        const {
            code,
            meaning,
            child,
            count,
        } = item || {};

        return {
            code,
            meaning,
            ...getChildItem(child),
            result: isNumber(count) && count > 0 ? count : '-',
            isParentElement: checkingParentElement(code),
        };
    });
