import {omit} from 'lodash';
import React, {ReactElement} from 'react';

type ChildrenType = ReactElement | boolean;

interface ProtectedProps {
    children?: ChildrenType[] | ChildrenType;
    excluded?: string[]; // по умолчанию работает в режиме "исключения":
                         // рендер всех элементов, кроме переданных в excluded.
    included?: string[]; // если не пусто, то работает в режиме "включения": рендер только того, что в included.
    by?: string; // атрибут компонента, из которого брать значение для проверки рендера, по умолчанию "name"
}

type ProtectedGroupHTMLAttributes = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export interface ProtectedGroupProps extends ProtectedGroupHTMLAttributes {
    children?: ChildrenType[] | ChildrenType;
}

// Защищённый div. Работает аналогично Protected, берёт правила из Protected выше.
export const ProtectedGroup: React.FC<ProtectedGroupProps> = ({
    children,
    ...props
}: ProtectedGroupProps) => (
    <div
        {...props}
    >
        {children}
    </div>
);

const toArray = (value: any) => {
    if (!Array.isArray(value)) return [value];
    return value;
};

export const Protected: React.FC<ProtectedProps> = (
    props: ProtectedProps,
) => {
    const {
        children,
        included = [],
        excluded = [],
        by = 'name',
    } = props;

    const elements = (() => {
        if (!children) return [];
        return toArray(children);
    })();

    const filterElements = (elementsToFilter: ChildrenType[]) => elementsToFilter.filter(el => {
        if (typeof el === 'boolean') return true;
        const propValue = el?.props?.[by];
        if (!propValue) return true;
        if (excluded.includes(propValue)) {
            return false;
        }
        if (included.length && !included.includes(propValue)) { return false; }
        return true;
    });

    const protectGroups = (elementsToProtect?: ChildrenType[] | ChildrenType) => {
        if (!elementsToProtect) return [];
        const elementsToProtectList = Array.isArray(elementsToProtect)
            ? elementsToProtect : [elementsToProtect];
        return elementsToProtectList.map(el => {
            if (typeof el === 'boolean') return el;

            if ((el?.type as any)?.name === Object.keys({Protected})[0]) return el;
            if ((el?.type as any)?.name === Object.keys({ProtectedGroup})[0]) {
                const propsNoChildren = omit(el.props, 'children');
                if (el?.props?.children) {
                    const filtered = filterElements(toArray(el.props.children));
                    if (!filtered.length) return <></>;
                }
                return (
                    <ProtectedGroup {...propsNoChildren}>
                        <Protected {...props}>
                            {el?.props?.children}
                        </Protected>
                    </ProtectedGroup>
                );
            }
            return el;
        });
    };

    const elementsWithGroups = protectGroups(elements);
    const elementsToShow = filterElements(elementsWithGroups);

    return (
        <>
            {elementsToShow}
        </>
    );
};
