import {createReducer} from 'store/config/creators';
import {Action} from 'store/config/types';

import {DEFAULT_TABLE_STATE} from '../data/data-constants';
import {DefaultTableStateFlat, LookupValue} from '../data/data-types';
import {
    BranchNamesActions,
    DocumentsActions,
    DocumentsForSignActions,
    FileListActions,
    FileUploadActions,
    SignatureUploadActions,
    SignListActions,
    SingExtensions,
} from './documents-constants';
import {
    FileActivityResetAllPayload,
    FileActivitySetPayload,
    DocumentsState,
    DocumentTypeResponse,
    FileListResetPayload,
    FileListSetPayload,
    SignListResetPayload,
    SignListSetPayload,
    SignActivityResetPayload, SignActivitySetPayload,
    FileActivityResetItemPayload,
    FileListDtoPayload,
    SingSetExtensionsPayload,
    DocumentsUploadMonitoringState,
    DocumentsSigningMethod,
    SetDocumentsForUploadAttachPayload,
    DocumentProperty,
} from './documents-types';

const documentsInitialState: DocumentsState = {
    fileUpload: {},
    signatureUpload: {},
    pagination: DEFAULT_TABLE_STATE,
    signingMethod: undefined,
};

export const documentsReducer = createReducer(documentsInitialState, {
    [DocumentsActions.SET_DOCUMENTS_FOR_UPLOAD_ATTACH]: (
        state: DocumentsState,
        {payload}: Action<SetDocumentsForUploadAttachPayload>,
    ) => ({
        ...state,
        documentsForUploadAttach: {
            ...state.documentsForUploadAttach ?? {},
            [payload.sublistCode]: payload.documents,
        },
    }),
    [DocumentsActions.SET_DOCUMENT_SIGNING_METHOD]: (
        state: DocumentsState,
        {payload: method}: Action<DocumentsSigningMethod>,
    ) => ({
        ...state,
        signingMethod: method,
    }),
    [DocumentsActions.SET_DOCUMENT_TYPES]: (
        state: DocumentsState,
        {payload: documentTypes}: Action<DocumentTypeResponse[]>,
    ) => ({
        ...state,
        documentTypes,
    }),
    [DocumentsActions.SET_DOCUMENT_TYPES_ON_UPLOAD]: (
        state: DocumentsState,
        {payload: documentTypesOnUpload}: Action<DocumentTypeResponse[]>,
    ) => ({
        ...state,
        documentTypesOnUpload,
    }),
    [DocumentsActions.SET_DOCUMENTS_UPLOAD_MONITORING]: (
        state: DocumentsState,
        {payload: documentsUploadMonitoring}: Action<DocumentsUploadMonitoringState>,
    ) => ({
        ...state,
        documentsUploadMonitoring,
    }),
    [DocumentsActions.SET_DOCUMENTS_PAGINATION]: (
        state: DocumentsState,
        {payload: tableState}: Action<DefaultTableStateFlat>,
    ) => ({
        ...state,
        pagination: tableState,
    }),
    [BranchNamesActions.SET_BRANCH_NAMES]: (
        state: DocumentsState,
        {payload: branchNames}: Action<DocumentTypeResponse[]>,
    ) => ({
        ...state,
        branchNames,
    }),
    [DocumentsActions.SET_DOCUMENT_PROPERTY]: (
        state: DocumentsState,
        {payload: property}: Action<DocumentProperty>,
    ) => ({
        ...state,
        documentProperties: {
            ...(state.documentProperties ?? {}),
            ...property,
        },
    }),
    [DocumentsForSignActions.SET_DOCUMENT_LIST]: (
        state: DocumentsState,
        {payload: documentList}: Action<LookupValue[]>,
    ) => ({
        ...state,
        documentList,
    }),
    [FileUploadActions.SET_FILE_ACTIVITY_STATE]: (
        state: DocumentsState,
        {payload: {entityName, uniqueValue, item}}: Action<FileActivitySetPayload>,
    ) => ({
        ...state,
        fileUpload: {
            ...state.fileUpload,
            [entityName]: {
                ...state.fileUpload[entityName],
                activityList: {
                    ...state.fileUpload[entityName]?.activityList,
                    [uniqueValue]: {
                        ...state.fileUpload[entityName]?.activityList?.[uniqueValue],
                        ...item,
                    },
                },
            },
        },
    }),
    [FileUploadActions.RESET_FILE_ACTIVITY_STATE]: (
        state: DocumentsState,
        {payload: {entityName, uniqueValue}}: Action<FileActivityResetItemPayload>,
    ) => ({
        ...state,
        fileUpload: {
            ...state.fileUpload,
            [entityName]: {
                ...state.fileUpload[entityName],
                activityList: {
                    ...state.fileUpload[entityName]?.activityList,
                    [uniqueValue]: null,
                },
            },
        },
    }),
    [FileUploadActions.RESET_ALL_ACTIVITY_STATE]: (
        state: DocumentsState,
        {payload: {entityName}}: Action<FileActivityResetAllPayload>,
    ) => ({
        ...state,
        fileUpload: {
            ...state.fileUpload,
            [entityName]: {
                ...state.fileUpload[entityName],
                activityList: undefined,
            },
        },
    }),
    [FileListActions.SET_FILE_LIST]: (
        state: DocumentsState,
        {payload: {entityName, fileList}}: Action<FileListSetPayload>,
    ) => ({
        ...state,
        fileUpload: {
            ...state.fileUpload,
            [entityName]: {
                ...state.fileUpload[entityName],
                fileList,
            },
        },
    }),
    [FileListActions.RESET_FILE_LIST]: (
        state: DocumentsState,
        {payload: {entityName}}: Action<FileListResetPayload>,
    ) => ({
        ...state,
        fileUpload: {
            ...state.fileUpload,
            [entityName]: {
                ...state.fileUpload[entityName],
                fileList: undefined,
            },
        },
    }),
    [FileListActions.DTO_FILE_LIST]: (
        state: DocumentsState,
        {payload: {fileList}}: Action<FileListDtoPayload>,
    ) => ({
        ...state,
        totalStage: {
            fileList,
        },
    }),
    [SignatureUploadActions.SET_SIGN_ACTIVITY_STATE]: (
        state: DocumentsState,
        {payload: {entityName, uniqueValue, item}}: Action<SignActivitySetPayload>,
    ) => ({
        ...state,
        signatureUpload: {
            ...state.signatureUpload,
            [entityName]: {
                ...state.signatureUpload[entityName],
                activityList: {
                    ...state.signatureUpload[entityName]?.activityList,
                    [uniqueValue]: {
                        ...state.signatureUpload[entityName]?.activityList?.[uniqueValue],
                        ...item,
                    },
                },
            },
        },
    }),
    [SignatureUploadActions.RESET_SIGN_ACTIVITY_STATE]: (
        state: DocumentsState,
        {payload: {entityName}}: Action<SignActivityResetPayload>,
    ) => ({
        ...state,
        signatureUpload: {
            ...state.signatureUpload,
            [entityName]: {
                ...state.signatureUpload[entityName],
                activityList: undefined,
            },
        },
    }),
    [SignListActions.SET_SIGN_LIST]: (
        state: DocumentsState,
        {payload: {entityName, signList}}: Action<SignListSetPayload>,
    ) => ({
        ...state,
        signatureUpload: {
            ...state.signatureUpload,
            [entityName]: {
                ...state.signatureUpload[entityName],
                signList,
            },
        },
    }),
    [SignListActions.RESET_SIGN_LIST]: (
        state: DocumentsState,
        {payload: {entityName}}: Action<SignListResetPayload>,
    ) => ({
        ...state,
        signatureUpload: {
            ...state.signatureUpload,
            [entityName]: {
                ...state.signatureUpload[entityName],
                signList: undefined,
            },
        },
    }),
    [SingExtensions.SET_SIGN_EXTENSIONS]: (
        state: DocumentsState,
        {payload: {accept}}: Action<SingSetExtensionsPayload>,
    ) => ({
        ...state,
        signatureExtensions: {
            accept,
        },
    }),

});
