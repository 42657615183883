import {useMemo} from 'react';

import {LocationStateKey, useAppHistory} from 'shared/hooks/use-app-history';
import {useAppSelector} from 'store/config/hooks';
import {
    selectTableReportQueryParams,
} from 'store/slices/table-report-slice/table-report-query-params/table-report-query-params-selectors';
import {TableReportChildDataSourceType} from 'store/slices/table-report-slice/table-report-slice-types';

export const useTableReportParameters = () => {
    const history = useAppHistory();

    const passedDrilldownParams = history.getLocationStateValue(LocationStateKey.TABLE_REPORT_PARAMS);
    const topicId = history.getLocationStateValue(LocationStateKey.TABLE_REPORT_REQUEST_TOPIC_PARAM);
    const passedDocJournalWidgetParams = history.getLocationStateValue(LocationStateKey.AIS_DOCUMENT_JOURNAL_PIE_CART);

    const {
        drillId,
        drillParameters,
    } = passedDrilldownParams ?? {};

    const drillParametersForFilter = useMemo(() => Object
        .fromEntries((drillParameters ?? [])
            .filter(param => param.childDataSourceType === TableReportChildDataSourceType.PARAMETER)
            .map(param => ([param.childParameterName, param.value]))),
    [drillParameters]);

    const queryParametersInStore = useAppSelector(selectTableReportQueryParams);

    const isDrillDownParameterUsedInFilter = (paramName: string) => Object.keys(
        drillParametersForFilter,
    ).includes(paramName);

    const parameters = useMemo(() => {
        const passedParameters = {
            DRILL_ID: drillId,
            TOPIC_REQUEST_ID: topicId?.TOPIC_REQUEST_ID,
        };

        return {
            parameters: queryParametersInStore,
            drillParameters, // параметры дрилла из родителя (не все применяются в фильтре в шапке)
            drillParametersForFilter, // параметры из дрила, которые нужно применить в фильтре в шапке
            passedParameters, // остальные полученные из LocationState параметры (id дрилла и другие при необходимости)
            topicRequestParameters: topicId?.TOPIC_REQUEST_PARAMS ?? {},
            passedDocJournalWidgetParams: passedDocJournalWidgetParams ?? {},
            isDrillDownParameterUsedInFilter,
        };
    }, [queryParametersInStore, passedDrilldownParams]);

    return {
        ...parameters,
    };
};
