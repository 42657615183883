import _ from 'lodash';
import moment from 'moment';

import {ContextData} from 'modules/context/context-types';
import {DATE_FOR_REQUEST} from 'shared/constants/date-format';
import {isEmptyArrayOrObject} from 'shared/utils';
import {UserInfo} from 'store/slices/auth-slice/auth-slice-types';
import {ReportConfigurationQueryParameterDefaultType, ReportConfigurationQueryParameterDto, ReportConfigurationQueryParameterVariableType} from 'store/slices/report-configuration-slice/report-configuration-dto';

import {TableReportFilterFieldDefaultType, TableReportQueryParameters} from '../table-report-slice-types';

export const omitEmptyParameterFields = (values?: {[key: string]: any}) => {
    if (!values) return undefined;
    return _(values).omitBy(_.isUndefined).omitBy(isEmptyArrayOrObject)
        .omitBy(v => v === '')
        .value() as TableReportQueryParameters | undefined;
};
export interface GetTableReportFilterDefaultValueArgs {
    type: ReportConfigurationQueryParameterVariableType;
    defaultType: ReportConfigurationQueryParameterDefaultType;
    context: ContextData | undefined;
    userInfo: UserInfo | undefined;
    defaultValue: unknown;
}

export const getTableReportFilterDefaultValue = (
    {
        context,
        userInfo,
        defaultType,
        defaultValue,
    }: GetTableReportFilterDefaultValueArgs,
) => {
    switch (defaultType) {
    case TableReportFilterFieldDefaultType.CURRENT_DATETIME:
        return moment(new Date()).format(DATE_FOR_REQUEST);
    case TableReportFilterFieldDefaultType.CONTEXT:
        if (defaultValue === 'TAX_PERIOD_ID') {
            return context?.taxPeriodId?.id;
        }
        if (defaultValue === 'ORG_ID') {
            return context?.organizationId?.id;
        }
        if (defaultValue === 'USER_ID') {
            return userInfo?.userId;
        }
        if (defaultValue === 'USER_IS_INSPECTOR') {
            return !!userInfo?.externalEmployee;
        }
        return defaultValue;
    default:
        return defaultValue;
    }
};

export interface CreateFilterDefaultValuesArgs {
    queryParameters: ReportConfigurationQueryParameterDto[] | undefined;
    filterKeyPath: 'paramName' | 'columnName';
    context: ContextData;
    userInfo: UserInfo | undefined;
}

export const createFilterDefaultValues = ({
    context,
    filterKeyPath,
    queryParameters,
    userInfo,
}: CreateFilterDefaultValuesArgs) => queryParameters?.reduce((acc, queryParameter) => {
    const {type, defaultType, defaultValue} = queryParameter;
    const queryParameterName = queryParameter[filterKeyPath];

    return {
        ...acc,
        [queryParameterName]: getTableReportFilterDefaultValue({
            context,
            userInfo,
            defaultType,
            defaultValue,
            type,
        }),
    };
}, {} as TableReportQueryParameters);
