import {Form, Input} from 'antd';
import React from 'react';

import {DateCell} from 'components/table/columns/date-cell/date-cell';
import {RequestTypesInfo} from 'store/slices/request-slice/request-slice-type';
import './request-responsible.less';

interface RequestResponsibleProps{
    isVisible: boolean;
    isRequestAdditionalPlacement?: boolean;
    requestType?: RequestTypesInfo;
}

export const RequestResponsible = ({
    isVisible, isRequestAdditionalPlacement, requestType,
}:RequestResponsibleProps) => (
    <div className="form-fields-responsible">
        {isVisible && (
            <>
                <Form.Item
                    style={{width: 137}}
                    name="responseDate"
                    label="Срок исполнения"
                >
                    <DateCell
                        showTime
                        format="DD.MM.YYYY"
                        disabled
                    />
                </Form.Item>
                <Form.Item
                    style={{width: 1300}}
                    name="responsibleUser"
                    label="Ответственный"
                >
                    <Input
                        disabled
                    />
                </Form.Item>
            </>
        )}
        {isRequestAdditionalPlacement && (
            <Form.Item
                style={{width: 1450}}
                label="Тип запроса на доразмещение"
                rules={[{required: true}]}
            >
                <Input
                    placeholder="Тип запроса на доразмещение"
                    disabled
                    defaultValue={requestType?.meaning}
                />
            </Form.Item>
        )}
    </div>
);
