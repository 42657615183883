import React, {useRef} from 'react';

import {Highlighted} from 'components/highlighted';
import {useAfterEffect} from 'shared/hooks/use-after-effect';
import {useAppSelector} from 'store/config/hooks';
import {selectTableReportLocalSearch, selectTableReportSelectedCellIndexes} from 'store/slices/table-report-slice/table-report-search/table-report-search-selectors';

import {createSearchRegex} from '../../../table-report-search/table-report-search-utils';

export interface UseSearchHighlightArgs {
    value: string;
    indexes: {
        column: number;
        row: number;
    };
}

export const useSeachHighlight = ({
    indexes,
    value,
}: UseSearchHighlightArgs) => {
    const {line: searchLine} = useAppSelector(selectTableReportLocalSearch);
    const searchSelectedCellIndexes = useAppSelector(selectTableReportSelectedCellIndexes);

    const {
        column: columnIndex,
        row: rowIndex,
    } = indexes;

    const highlightedSpanRef = useRef<HTMLSpanElement>(null);

    useAfterEffect(() => {
        if (value === null || !searchSelectedCellIndexes || !highlightedSpanRef.current) return;
        const {columnIndex: searchColumnIndex, rowIndex: searchRowIndex} = searchSelectedCellIndexes;

        if (searchColumnIndex === columnIndex && searchRowIndex === rowIndex) {
            const observer = new IntersectionObserver(([entry]) => {
                if (highlightedSpanRef.current && !entry.isIntersecting) {
                    highlightedSpanRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
                }
                observer.disconnect();
            }, {rootMargin: '-100px 0px -100px 0px'});
            observer.observe(highlightedSpanRef.current);
        }
    }, [searchSelectedCellIndexes]);

    const highlightedValueJSX = (() => {
        if (!value) return value;
        if (searchSelectedCellIndexes) {
            const {columnIndex: searchColumnIndex, rowIndex: searchRowIndex} = searchSelectedCellIndexes;
            if (searchColumnIndex === columnIndex && searchRowIndex === rowIndex) {
                return (
                    <Highlighted
                        ref={highlightedSpanRef}
                        pattern={searchLine}
                        matcher={createSearchRegex}
                    >
                        {value}
                    </Highlighted>
                );
            }
        } return (
            <>{value}</>
        );
    })();

    return {highlightedValueJSX};
};
