import {useEffect, useState} from 'react';

import {selectContextDataWithLookups} from 'modules/context/context-selector';
import {store} from 'store/config';
import {useAppSelector} from 'store/config/hooks';
import {AppState} from 'store/config/types';

export const useIsContextInitialized = () => {
    const context = useAppSelector(selectContextDataWithLookups);

    const [isContextInitialized, setIsContextInitialized] = useState(!!Object.keys(context).length);

    useEffect(() => {
        setTimeout(() => {
            const contextInStore = selectContextDataWithLookups(store.getState() as AppState);
            const isInitialized = !!Object.keys(contextInStore).length;
            if (!isContextInitialized && isInitialized) setIsContextInitialized(isInitialized);
        }, 0); // timeout необходим, так как в некоторых случаях
        // нужно сбрасывать контекст при переходе
    }, [context]);

    return {
        isContextInitialized,
    };
};
