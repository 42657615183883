import React, {useEffect, useState} from 'react';

import {List} from 'components/list/List';
import {Spinner} from 'components/spinner';
import {selectEntityData} from 'modules/data';
import {loadFormPageData} from 'modules/data/data-actions';
import {ListEntityData} from 'modules/data/data-types';
import {selectMetadata, loadMetadata as loadMetadataAction} from 'modules/metadata';
import {EntityType} from 'shared/constants/entities';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';

import './tooltip-list.less';

interface TooltipListProps {
    entityName?: string;
    url?: string;
}

export const TooltipList = ({entityName = '', url}: TooltipListProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();
    const listMeta = useAppSelector(state => selectMetadata(entityName, EntityType.LIST)(state));
    const listData = useAppSelector(state => selectEntityData(entityName, EntityType.FORM)(state) as ListEntityData);
    useEffect(() => {
        (async () => {
            if (!listMeta) {
                setIsLoading(true);
                await dispatch(loadMetadataAction(entityName, EntityType.LIST));
                setIsLoading(false);
            }
            if (!listData) {
                setIsLoading(true);
                await dispatch(loadFormPageData(entityName, url));
                setIsLoading(false);
            }
        })();
    }, []);

    return (
        isLoading
            ? <Spinner />
            : (
                <div className="tooltip-list">
                    <List
                        listData={listData}
                        meta={listMeta}
                        showTitle={false}
                    />
                </div>
            )
    );
};
