import React, {createContext} from 'react';

export interface DocumentCodesContextArgs {
    entityName: string;
    selectedTabKey: React.Key | null;
    setSelectedTabKey: React.Dispatch<React.SetStateAction<React.Key>> | null;
    templateCode?: string;
}

export const DocumentCodesTemplateContext = createContext<DocumentCodesContextArgs>({
    entityName: '',
    selectedTabKey: null,
    setSelectedTabKey: null,
});
