import {FormInstance} from 'antd/es/form';
import debounce from 'lodash/debounce';
import React, {SetStateAction, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {Entity} from 'modules/data';
import {fetchRequestListInfo} from 'modules/data/api/documents-upload-monitoring-api';
import {
    filterNonRenderableFieldsAndUpdateState as filterNonRenderableFieldsAndUpdateStateAction,
} from 'modules/data/data-actions';
import {
    DocumentTypeResponse,
    DocumentUploadFile, generateDefaultFileInfo,
    selectDocumentBranchNames,
    selectDocumentTypeOnUpload,
    selectDocumentFileUpload, getSubsectionFromLocation,
    uploadDocumentFiles,
    fetchFileAttachInfo, fetchFileUploadProperties, fetchTemplateTypesForFile,
} from 'modules/documents';
import {
    clearAllFilesInfo as clearAllFilesInfoAction,
    loadFileRowStateDataInEditableTable,
    loadBranchesLinkingFlag,
    resetRowStateDataForEditableTable,
    setFileList as setFileListAction,
} from 'modules/documents/documents-actions';
import {
    FILE_UNIQUE_FIELD_NAME,
    NO_FILES_MESSAGE,
    NO_HEADER_ORGANIZATION,
    NOT_FILLED_FIELDS_MESSAGE,
} from 'modules/documents/documents-constants';
import {selectDocumentProperties} from 'modules/documents/documents-selectors';
import {DocumentTemplatesTypeResponse, FileAttachInfoResponse, FileRowStateDataType} from 'modules/documents/documents-types';
import {filterFilesByExtensions, removeExtraPartFromEntityName} from 'modules/documents/documents-utils';
import {selectMetadata, loadMetadata as loadMetadataAction} from 'modules/metadata';
import {EntityMeta} from 'modules/metadata/metadata-types';
import {selectRegions} from 'modules/regions';
import {getRegionFromRegionCode} from 'modules/regions/regions-utils';
import {EntityType} from 'shared/constants/entities';
import {showMessageFromResponse} from 'shared/utils';
import {useAppDispatch} from 'store/config/hooks';
import {AppState} from 'store/config/types';
import {closeModal} from 'store/slices/modals-slice';
import {tableReportSliceActions} from 'store/slices/table-report-slice/table-report-slice';

import {EditFormArgs, hasHeaderOrganization} from '../documents-file-upload-modal-utils';

const getUniqueFieldValue = (file: DocumentUploadFile) => file[FILE_UNIQUE_FIELD_NAME];

export interface DocumentsUploadConfig {
    fileSizeFormat: string | null;
    unacceptedFileExtensions?: string[];
    useUploadDate?: boolean;
}

export const useDocumentUploadActions = (
    entityName: string,
) => {
    const dispatch = useAppDispatch();
    const loadFileRow = (
        tableRow: Record<string, any>,
        en: string,
    ) => dispatch(loadFileRowStateDataInEditableTable()(tableRow, en));
    const metadataSelector = selectMetadata(entityName, EntityType.EDITABLE_TABLE);
    const clearAllFilesInfo = (
        files: DocumentUploadFile[],
        // eslint-disable-next-line @typescript-eslint/no-shadow
        entityName: string,
    ) => dispatch(clearAllFilesInfoAction(files, entityName));
    const resetRowState = (tableRow: Record<string, any>) => dispatch(resetRowStateDataForEditableTable()(tableRow));

    const {
        setReportDownloadsData,
        setReadReportDownloadDocuments,
    } = tableReportSliceActions;

    const filterNonRenderableFieldsAndUpdateState = (
        meta: EntityMeta,
        name: string,
        entityType: EntityType,
        setFilteredFlag: React.Dispatch<SetStateAction<boolean>>,
        branchNames?: DocumentTypeResponse[],
        setDefaultBranchName?: React.Dispatch<React.SetStateAction<DocumentTypeResponse | undefined>>,
    ) => dispatch(filterNonRenderableFieldsAndUpdateStateAction(
        meta,
        name,
        entityType,
        setFilteredFlag,
        branchNames,
        setDefaultBranchName,
    ));
    const createFileListSetter = (
        // eslint-disable-next-line @typescript-eslint/no-shadow
        entityName: string,
    ) => (files: DocumentUploadFile[]) => dispatch(setFileListAction({entityName, fileList: files}));

    const {
        regions, documentTypesOnUpload, branchNames, fileUpload, metadata, hasBranchesLinkingFlag,

    } = useSelector((state: AppState) => ({
        regions: selectRegions(state),
        documentTypesOnUpload: selectDocumentTypeOnUpload(state),
        branchNames: selectDocumentBranchNames(state),
        fileUpload: selectDocumentFileUpload(state),
        hasBranchesLinkingFlag: selectDocumentProperties(state)?.hasBranchesLinkingFlag,
        metadata: metadataSelector(state) as EntityMeta,
    }));

    const [forms, setForms] = useState<FormInstance[]>([]);
    const currentLocation = useLocation<History>();
    const {fileList = []} = fileUpload?.[entityName] ?? {};
    const setFileList = createFileListSetter(entityName);
    const [defaultBranchName, setDefaultBranchName] = React.useState<DocumentTypeResponse | undefined>(undefined);
    const [fieldsFiltered, setFieldsFiltered] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);

    const {fields} = metadata ?? {};

    const [documentsUploadConfig, setDocumentsUploadConfig] = useState<DocumentsUploadConfig>({
        fileSizeFormat: null,
    });

    useEffect(() => {
        fetchFileUploadProperties(setDocumentsUploadConfig);
        dispatch(loadBranchesLinkingFlag());
    }, []);

    useEffect(() => {
        if (forms?.length && branchNames) {
            forms?.forEach(form => {
                const formFieldsValue = form.getFieldsValue();
                if (!branchNames.find(branch => branch.id === formFieldsValue.branchId)) {
                    const defaultBranch = branchNames.find(branch => branch?.attribute1 === 'MAIN');
                    setDefaultBranchName(defaultBranch);
                    formFieldsValue.branchId = defaultBranch?.id;
                    form.setFieldsValue(formFieldsValue);
                }
            });
        }
    }, [branchNames]);

    useEffect(() => {
        if (!metadata) {
            dispatch(loadMetadataAction(entityName, EntityType.EDITABLE_TABLE));
        }
    }, [entityName, metadata]);

    useEffect(() => {
        if (metadata?.fields && !fieldsFiltered) {
            filterNonRenderableFieldsAndUpdateState(
                metadata,
                metadata.name,
                EntityType.EDITABLE_TABLE,
                setFieldsFiltered,
                branchNames,
                setDefaultBranchName,
            );
        }
    }, [metadata?.fields]);

    const uploadFiles = (
        files: DocumentUploadFile[],
        useUploadDate?: boolean,
    ) => dispatch(uploadDocumentFiles(files, entityName, useUploadDate));

    const handleDelete = (tableRow: Entity) => {
        const fileEntry = tableRow as DocumentUploadFile;
        const resultFileList = fileList
            .filter((file: DocumentUploadFile) => (getUniqueFieldValue(file) !== getUniqueFieldValue(fileEntry)));
        setFileList(resultFileList);
        resetRowState(fileEntry);
    };

    const handleEdit = async (tableRow: Entity, args?: EditFormArgs) => {
        const params: FileRowStateDataType = {};
        const fileEntry = tableRow as DocumentUploadFile;
        const fileIndex = fileList.findIndex(file => getUniqueFieldValue(file) === getUniqueFieldValue(fileEntry));
        const previousFile = fileList[fileIndex];
        const regionData = getRegionFromRegionCode(fileEntry.sectionCode, regions);

        // todo временная заглушка
        // нужно посмотреть, зачем нам нужен type, если есть typeId
        previousFile.type = previousFile.typeId as string;

        if (fileEntry.sectionCode !== previousFile?.sectionCode) {
            fileEntry.subsectionCode = regionData?.subregionList[0]?.code;
            fileEntry.subsectionId = regionData?.subregionList[0]?.id;

            const newSection = regions?.find(type => type.regionCode === fileEntry.sectionCode);
            fileEntry.sectionId = newSection ? newSection.id : regionData?.id;
        }

        if (fileEntry.subsectionCode !== previousFile?.subsectionCode) {
            const newSubsection = regionData?.subregionList.find(
                subregion => subregion.code === fileEntry.subsectionCode,
            ) || regionData?.subregionList[0];

            fileEntry.subsectionId = newSubsection?.id;
        }

        if (args?.branchId) {
            fileEntry.branchId = args.branchId;
        }

        if (fileEntry.type !== args?.typeId && args?.typeId) {
            tableRow.type = documentTypesOnUpload?.find(type => type.id === args.typeId)?.lookupCode;
            tableRow.title = documentTypesOnUpload?.find(type => type.id === args.typeId)?.description as string
                || fileEntry.title;
            fileEntry.typeId = args.typeId;
            params.docTypeIdC = fileEntry.typeId as string;
            loadFileRow(tableRow, entityName);
        }

        if (fileEntry.type !== previousFile?.type) {
            tableRow.title = documentTypesOnUpload?.find(type => type.id === fileEntry.type)?.meaning as string;
            tableRow.typeId = documentTypesOnUpload?.find(type => type.id === fileEntry.type)?.id as string;
            tableRow.typeCode = documentTypesOnUpload?.find(type => type.id === fileEntry.type)?.lookupCode as string;
            tableRow.templatesTypeOptions = await fetchTemplateTypesForFile(tableRow.typeCode);
            tableRow.templateCode = tableRow.templatesTypeOptions.length === 1
                ? tableRow.templatesTypeOptions[0].lookupCode : undefined;
            loadFileRow(tableRow, entityName);
        }
        fileList[fileIndex] = {...fileEntry};

        setFileList([...fileList]);
    };

    const handleUpload = debounce(async (_: File, newFiles: File[]) => {
        const filesFilteredByExtensions = filterFilesByExtensions(
            newFiles, documentsUploadConfig.unacceptedFileExtensions,
        );

        const resolveFiles = filesFilteredByExtensions.filter(
            newFile => !fileList.some(oldFile => oldFile.fileName === newFile.name),
        );
        if (!resolveFiles.length) return;
        let fileAttachInfo: FileAttachInfoResponse[] = [];
        const subsection = getSubsectionFromLocation(currentLocation);
        try {
            const res = await fetchFileAttachInfo(
                removeExtraPartFromEntityName(subsection),
                subsection,
                resolveFiles.map(file => file.name),
            );
            fileAttachInfo = res.data;
        } catch (e) {
            console.error('Невозможно получить значения по умолчанию для загружаемых файлов', e);
        }

        const newFilesWithDefaultInfo = resolveFiles.map(file => {
            const fileInfo = fileAttachInfo.find(el => el.filename === file.name);
            const docType = documentTypesOnUpload?.find(el => (
                el.id === (fileInfo?.defaultDocTypeId as unknown as string)
            ));
            let templateTypesOptions: DocumentTemplatesTypeResponse[] = [];

            if (docType) fetchTemplateTypesForFile(docType.lookupCode).then(res => { templateTypesOptions = res; });

            return generateDefaultFileInfo(
                file, removeExtraPartFromEntityName(subsection),
                regions, fileInfo, defaultBranchName, docType, templateTypesOptions,
            );
        });
        if (errorMsg === NO_FILES_MESSAGE) {
            setErrorMsg(null);
        }

        setFileList([...fileList, ...newFilesWithDefaultInfo]);
        newFilesWithDefaultInfo.forEach(file => loadFileRow(file, entityName));
    }, 500);

    const handleClear = () => {
        setForms([]);
        setErrorMsg(null);
        clearAllFilesInfo(fileList, entityName);
    };

    const validateRequiredFieldsManually = () => {
        // при переключении страниц в forms попадают только формы на текущей странице
        // но нужно валидировать поля на всех страницах.
        // поскольку введённые данные попадают сразу в fileList, итерируемся по нему
        fileList.forEach(file => {
            ['correctionNumber', 'uploadDate'].forEach(fieldKey => {
                const fieldPermissions = fileUpload[entityName].activityList[file.fileName]?.editFieldsPermission ?? {};
                if (fieldPermissions?.[fieldKey]?.activated) {
                    const isFieldRequired = !!fields.find(f => f.key === fieldKey)?.required;
                    if (isFieldRequired && !file[fieldKey]) throw new Error('Required fields are not filled.');
                }
            });
        });
    };

    const handleSubmit = async () => {
        if (!hasHeaderOrganization(hasBranchesLinkingFlag, branchNames)) {
            setErrorMsg(NO_HEADER_ORGANIZATION);
            return;
        }
        try {
            if (fileList.length) {
                await Promise.all(forms.map(form => form.validateFields()));
                validateRequiredFieldsManually();
                setErrorMsg(null);
                await uploadFiles(fileList, documentsUploadConfig.useUploadDate);
                clearAllFilesInfo(fileList, entityName);
                dispatch(closeModal());
                await fetchRequestListInfo()
                    .then(result => {
                        dispatch(setReportDownloadsData(result.data));
                        dispatch(setReadReportDownloadDocuments(
                            {wereDocumentsRead: false, isAnimationActive: true},
                        ));
                    })
                    .catch(er => showMessageFromResponse({
                        response: er?.response,
                        isError: true,
                    }));
            } else {
                setErrorMsg(NO_FILES_MESSAGE);
            }
        } catch (e) {
            setErrorMsg(NOT_FILLED_FIELDS_MESSAGE);
            console.error(e);
        }
    };

    return {
        fileList,
        errorMsg,
        forms,
        fieldsFiltered,
        handleUpload,
        setForms,
        handleEdit,
        handleClear,
        handleDelete,
        handleSubmit,
        isPageJumperEnabled: metadata?.isPageJumperEnabled,
        isPageSizeChangerEnabled: metadata?.isPageSizeChangerEnabled,
        actions: metadata?.actions,
        useUploadDate: documentsUploadConfig.useUploadDate,
        fileSizeFormat: documentsUploadConfig.fileSizeFormat,
    };
};
