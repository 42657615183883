import {Form, Input} from 'antd';
import {FormListFieldData} from 'antd/es/form/FormList';
import {FormInstance} from 'antd/lib/form';
import cn from 'classnames';
import React from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {LargeStringInput} from 'components/form/inputs/large-string-input';
import {
    DocumentUploadTemplateData,
} from 'store/slices/document-upload-template/document-upload-template-slice-type';

import {WatcherFieldFlag} from '../../../../../report-configuration/hooks/use-form-fields-manager';
import {
    SETTING_TEMPLATE_TRANSFER_TARGET_FIELD_TYPE,
    SETTING_TEMPLATE_TRANSFER_TARGET_FORM_DATA_KEY,
    SettingTemplateTransferTargetFormFields,
} from '../../constants/setting-template-transfer-target/setting-template-transfer-target';
import {FormatSettingsModalOpener} from '../format-settings-modal-opener/format-settings-modal-opener';
import './setting-template-transfer-target-fields.less';

export interface SettingTemplateTransferTargetProps {
  form: FormInstance;
  fields: FormListFieldData[];
  checkWatcherFlag: (
    index: number,
    key: string,
    flag: WatcherFieldFlag
  ) => boolean;
  checkArrayIndependentWatcherFlag: (
    key: string,
    flag: WatcherFieldFlag
  ) => boolean;
  documentUploadTemplate?: DocumentUploadTemplateData;
}
export const SettingTemplateTransferTargetFields: React.FC<SettingTemplateTransferTargetProps> = ({
    form,
    fields,
    checkWatcherFlag,
    checkArrayIndependentWatcherFlag,
    documentUploadTemplate,
}: SettingTemplateTransferTargetProps) => (
    <div className={cn('form-list')}>
        {fields.map(field => (
            <div
                key={field.key}
                className={cn('form-list__items')}
            >
                <Form.Item
                    style={{width: 220}}
                    label="Атрибут"
                    rules={[{required: true}]}
                    name={[field.name, SettingTemplateTransferTargetFormFields.targetField]}
                >
                    <Input
                        disabled
                        autoComplete="off"
                        placeholder="Введите атрибут"
                    />
                </Form.Item>
                <Form.Item
                    label="Описание"
                    style={{width: 220}}
                    name={[field.name, SettingTemplateTransferTargetFormFields.description]}
                    rules={[{required: true}]}
                >
                    <Input
                        autoComplete="off"
                        placeholder="Введите описание"
                        disabled={checkWatcherFlag(
                            field.name,
                            SettingTemplateTransferTargetFormFields.description,
                            WatcherFieldFlag.isDisabled,
                        )}
                    />
                </Form.Item>
                <Form.Item
                    label="Поле исходной таблицы"
                    name={[field.name, SettingTemplateTransferTargetFormFields.sourceField]}
                    style={{width: 220}}
                    rules={[{
                        required: !checkWatcherFlag(
                            field.name,
                            SettingTemplateTransferTargetFormFields.sourceField,
                            WatcherFieldFlag.isDisabled,
                        ),
                    }]}
                    validateTrigger="onFocuse"
                >
                    <CustomSelect
                        settings={{
                            showSearch: true,
                            placeholder: 'Выберите тип поля',
                            url: `valueLists/DATABASE_TABLE_COLUMNS?sublistCode=${
                                form?.getFieldValue(SettingTemplateTransferTargetFormFields.attribute1)
                                || documentUploadTemplate?.attribute1
                            }&sublistId=1`,
                            labelPath: 'lookupCode',
                            isClearable: true,
                            isDisabled: checkWatcherFlag(field.name,
                                SettingTemplateTransferTargetFormFields.sourceField,
                                WatcherFieldFlag.isDisabled),
                            valuePath: 'lookupCode',
                            formFieldKey: SettingTemplateTransferTargetFormFields.fieldTypeId,
                            formInstance: form,
                        }}
                    />
                </Form.Item>
                <Form.Item
                    validateTrigger="onFocuse"
                    label="Динамический SQL"
                    name={[field.name, SettingTemplateTransferTargetFormFields.sourceFieldDynamicSql]}
                    style={{width: 220}}
                    rules={[{
                        required: !checkWatcherFlag(
                            field.name,
                            SettingTemplateTransferTargetFormFields.sourceFieldDynamicSql,
                            WatcherFieldFlag.isDisabled,
                        ),
                    }]}
                >
                    <LargeStringInput
                        disabled={checkWatcherFlag(field.name,
                            SettingTemplateTransferTargetFormFields.sourceFieldDynamicSql,
                            WatcherFieldFlag.isDisabled)}
                        name={SettingTemplateTransferTargetFormFields.sourceFieldDynamicSql}
                        label="Динамический SQL"
                        placeholder="Динамический SQL"
                    />
                </Form.Item>
                <Form.Item
                    label="Константа"
                    name={[field.name, SettingTemplateTransferTargetFormFields.sourceFieldConstant]}
                    style={{width: 220}}
                    validateTrigger="onFocuse"
                    rules={[{
                        required: !checkWatcherFlag(
                            field.name,
                            SettingTemplateTransferTargetFormFields.sourceFieldConstant,
                            WatcherFieldFlag.isDisabled,
                        ),
                    }]}
                >
                    <Input
                        autoComplete="off"
                        placeholder="Введите номер"
                        disabled={checkWatcherFlag(field.name,
                            SettingTemplateTransferTargetFormFields.sourceFieldConstant,
                            WatcherFieldFlag.isDisabled)}
                    />
                </Form.Item>
                <Form.Item
                    name={[field.name, SettingTemplateTransferTargetFormFields.fieldTypeId]}
                    style={{width: 220}}
                    label="Тип поля"
                    rules={[{required: true}]}
                >
                    <CustomSelect
                        settings={{
                            showSearch: true,
                            placeholder: 'Выберите тип поля',
                            url: `valueLists/DATABASE_TABLE_COLUMNS?sublistCode=${
                                form?.getFieldValue(SettingTemplateTransferTargetFormFields.attribute2)
                                || documentUploadTemplate?.attribute2
                            }`,
                            labelPath: 'description',
                            isDisabled: checkWatcherFlag(field.name,
                                SettingTemplateTransferTargetFormFields.fieldTypeId,
                                WatcherFieldFlag.isDisabled),
                            valuePath: 'meaning',
                            formFieldKey: SettingTemplateTransferTargetFormFields.fieldTypeId,
                            formInstance: form,
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label={<>&nbsp;</>}
                    style={{width: 320}}
                    rules={[{
                        required: form.getFieldValue([
                            SETTING_TEMPLATE_TRANSFER_TARGET_FORM_DATA_KEY,
                            field.name,
                            SettingTemplateTransferTargetFormFields.fieldTypeId,
                        ]) !== SETTING_TEMPLATE_TRANSFER_TARGET_FIELD_TYPE,
                        message: 'Ошибка в настроке правил форматирования',
                    }]}
                >
                    <FormatSettingsModalOpener
                        form={form}
                        name={field.name}
                        isRequired={form.getFieldValue([
                            SETTING_TEMPLATE_TRANSFER_TARGET_FORM_DATA_KEY,
                            field.name,
                            SettingTemplateTransferTargetFormFields.fieldTypeId,
                        ]) !== SETTING_TEMPLATE_TRANSFER_TARGET_FIELD_TYPE}
                        disabled={
                            checkArrayIndependentWatcherFlag('formatSetting', WatcherFieldFlag.isDisabled)
                        }
                    />
                </Form.Item>
            </div>
        ))}
    </div>
);
