/**
 * Возвращает строку, склоняя слово "час" в зависимости от переданного числительного.
 *
 * @param {number | undefined} hours - Количество часов.
 * @returns {'часов' | 'час' | 'часа'} Строка, содержащая правильное склонением слова "час".
 *
 * @example
 * Примеры использования
 * console.log(getHourString(undefined));   // "часов"
 * console.log(getHourString(1));   // "час"
 * console.log(getHourString(2));   // "часа"
 * console.log(getHourString(5));   // "часов"
 * console.log(getHourString(12));  // "часов"
 * console.log(getHourString(22));  // "часа"
 * console.log(getHourString(123)); // "часа"
 */
export const getHoursString = (hours: number | undefined): 'часов' | 'час' | 'часа' => {
    if (hours === undefined) return 'часов';
    const lastDigit = hours % 10;
    const lastTwoDigits = hours % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
        return 'часов';
    }

    switch (lastDigit) {
    case 1:
        return 'час';
    case 2:
    case 3:
    case 4:
        return 'часа';
    default:
        return 'часов';
    }
};

/**
     * Возвращает количество дней в указанном месяце и году.
     * Если переданный год и месяц совпадают с текущими, возвращает количество дней с начала месяца до текущей даты.
     *
     * @param {number} year - Год, для которого нужно получить количество дней в месяце.
     * @param {number} month - Месяц (от 1 до 12), для которого нужно получить количество дней.
     * @returns {number} Количество дней в месяце.
     * @throws {Error} Если месяц не в диапазоне от 1 до 12.
     *
     * @example
     * Получить количество дней в феврале 2024 года
     * const daysInFebruary2024 = getDaysInMonth(2024, 2);
     * console.log(daysInFebruary2024); // 29
     *
     * @example
     * Получить количество дней с начала текущего месяца до сегодняшнего дня, если текущий месяц - май и год - 2024
     * const currentDays = getDaysInMonth(2024, 5);
     * console.log(currentDays); // Если сегодня 12 мая 2024 года, результат будет 12
    */
export const getDaysInMonth = (year: number, month: number): number => {
    if (month < 1 || month > 12) {
        throw new Error('Месяц должен быть в диапазоне от 1 до 12.');
    }
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    if (year === currentYear && month === currentMonth) {
        const startDate = new Date(year, month - 1, 1);
        const daysPassed = Math.ceil((currentDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
        return daysPassed;
    }
    return new Date(year, month, 0).getDate();
};
