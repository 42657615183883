import React from 'react';
import {Cell, Pie, PieChart} from 'recharts';

import {StatisticsDataObject} from 'pages/svc-widget-page/hooks/use-statistics-initialization';

interface HalfCircleChartProps {
    statisticsData: StatisticsDataObject;
}
export const HalfCircleChart: React.FC<HalfCircleChartProps> = ({statisticsData}: HalfCircleChartProps) => {
    const dataForChart = statisticsData.map(({key: name, value, color}) => (
        {
            name, value, color,
        }
    ));
    return (
        <PieChart
            width={140}
            height={80}
        >
            <Pie
                isAnimationActive={false}
                dataKey="value"
                startAngle={180}
                endAngle={0}
                data={dataForChart}
                innerRadius={48}
                cy="100%"
                outerRadius={70}
                stroke="none"
            >
                {dataForChart.map(entry => (
                    <Cell
                        key={`cell-${entry.name}`}
                        fill={entry.color}
                    />
                ))}
            </Pie>
        </PieChart>
    );
};
