import {Card} from 'antd';
import {RadioChangeEvent} from 'antd/lib/radio';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {loadTableData as loadTableDataAction} from 'modules/data';
import {
    filterNonRenderableFieldsAndUpdateState,
    initializeMetadataAndData,
    setSelectedSubsection,
} from 'modules/data/data-actions';
import {selectFilterEntityData, selectSelectedSubsection, selectTableEntityData} from 'modules/data/data-selectors';
import {loadAcceptableExtensions} from 'modules/documents/documents-actions';
import {selectMetadata, loadMetadata as loadMetadataAction} from 'modules/metadata';
import {selectTableEntityMetadata} from 'modules/metadata/metadata-selectors';
import {SubsectionMenu, TableEntityMeta} from 'modules/metadata/metadata-types';
import {PageTemplateProps} from 'pages';
import {EntityType} from 'shared/constants/entities';
import {useAppSelector} from 'store/config/hooks';

import {Spinner} from '../../spinner';
import {Table} from '../table';
import {TableActions} from '../table-actions/table-actions';

export interface SubsectionTableComponentProps extends Pick<PageTemplateProps, 'entityName' | 'url' | 'hideTitle'> {
    entityName: string;
    entityType?: EntityType;
}

export const SubsectionTable: React.FunctionComponent<SubsectionTableComponentProps> = ({
    entityName,
    url,
    entityType,
    hideTitle,
}: SubsectionTableComponentProps) => {
    const dispatch = useDispatch();
    const [fieldsFiltered, setFieldsFiltered] = useState<boolean>(false);
    const metadata = useAppSelector(
        selectMetadata(entityName, entityType || EntityType.SUBSECTION_TABLE),
    );
    const storeSubsection = useSelector(selectSelectedSubsection);
    const [subsection, setSubsection] = React.useState<SubsectionMenu | undefined>(
        metadata?.subsections?.slice(0, 1).shift(),
    );
    const metadataSubsections = useAppSelector(selectTableEntityMetadata(subsection?.key || ''));
    const filterSubsections = useAppSelector(selectFilterEntityData(subsection?.key || ''));
    const dataSubsections = useAppSelector(selectTableEntityData(entityName));

    const tableDataOptions = {
        useContext: metadataSubsections?.useContext,
        useSearch: metadataSubsections?.isSearchable,
        disabledPagination: metadataSubsections?.disabledPagination,
    };

    React.useEffect(() => {
        const shouldLoadMetadata = !metadata;
        const shouldLoadSubsection = metadata && metadata?.subsections && !subsection && !storeSubsection;
        const shouldLoadMetadataSubsection = !metadataSubsections && subsection;
        const shouldLoadData = fieldsFiltered && (!dataSubsections || !dataSubsections?.rows);
        const shouldFilterData = metadataSubsections?.isFilterable && !filterSubsections?.data;
        const shouldLoadFilteredData = shouldLoadData && metadataSubsections?.isFilterable && filterSubsections?.data;
        const shouldLoadAllData = shouldLoadData && !metadataSubsections?.isFilterable;

        if (storeSubsection) {
            setSubsection(metadata?.subsections?.find(sub => sub.key === storeSubsection.selectedSubsection));
        }
        if (shouldLoadMetadata) {
            dispatch(loadMetadataAction(entityName, entityType || EntityType.SUBSECTION_TABLE));
            return;
        }
        if (shouldLoadSubsection) {
            setSubsection(metadata?.subsections?.slice(0, 1).shift());
            return;
        }
        if (shouldLoadMetadataSubsection) {
            dispatch(loadMetadataAction(String(subsection?.key), EntityType.TABLE));
            return;
        }
        if (shouldLoadFilteredData) {
            dispatch(loadTableDataAction(entityName, {
                ...tableDataOptions,
                paramsToBeConverted: filterSubsections?.data,
            }, `/settings.user.settings/${subsection?.key}`));
            return;
        }
        if (shouldLoadAllData) {
            dispatch(
                loadTableDataAction(entityName, {
                    ...tableDataOptions,
                    paramsToBeConverted: filterSubsections?.data,
                },
                `/settings.user.settings/${subsection?.key}`,
                metadataSubsections?.prefix),
            );
            return;
        }
        if (shouldFilterData) {
            dispatch(initializeMetadataAndData(EntityType.FILTER)(entityName));
        }
    }, [metadataSubsections, filterSubsections, dataSubsections, metadata, subsection]);

    const isTableLoaded = metadataSubsections?.fields && dataSubsections?.rows;
    const cardTitle = !hideTitle && metadata?.title;

    React.useEffect(() => {
        if (metadataSubsections?.shouldLoadExtensions) {
            dispatch(loadAcceptableExtensions());
        }
    }, [metadataSubsections?.shouldLoadExtensions]);

    React.useEffect(() => {
        if (metadataSubsections?.fields && !fieldsFiltered) {
            dispatch(
                filterNonRenderableFieldsAndUpdateState(
                    metadataSubsections, metadataSubsections.name, EntityType.TABLE, setFieldsFiltered,
                ),
            );
        }
    }, [metadataSubsections?.fields]);

    const handleSubsectionChange = ({target: {value}}: RadioChangeEvent) => {
        if (metadata.subsections && value) {
            setSubsection(metadata.subsections.find(sub => sub.key === value));
            dispatch(setSelectedSubsection({selectedSubsection: value}));
        }
    };

    return (
        <Card
            title={cardTitle}
        >
            <TableActions
                url={url}
                entityName={entityName}
                metadata={metadata}
                handleSubsectionChange={handleSubsectionChange}
                checkedSubsectionValue={subsection?.key}
            />
            {isTableLoaded ? (
                <Table
                    metadata={metadataSubsections as TableEntityMeta}
                    tableData={dataSubsections}
                    url={`${subsection?.key}`}
                    entityName={entityName}
                    entityType={entityType || EntityType.SUBSECTION_TABLE}
                />
            ) : <Spinner />}
        </Card>
    );
};
