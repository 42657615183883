import {Modal} from 'antd';
import React, {useEffect, useState} from 'react';

import {ReactComponent as CloseIcon} from 'shared/assets/close.svg';
import {ReactComponent as FileUpload} from 'shared/assets/header/file-upload.svg';
import {DEFAULT_FORM_MODAL_WIDTH} from 'shared/constants/layout';
import {showMessage} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {netDirectoryFileSliceActions} from 'store/slices/net-directory-file-slice/net-directory-file-slice';
import {
    NetDirectoryFileErrorMessages,
    netDirectoryFileLoadMessage,
} from 'store/slices/net-directory-file-slice/net-directory-file-slice-constants';
import {selectNetDirectoryFileData} from 'store/slices/net-directory-file-slice/net-directory-file-slice-selector';
import {
    loadNetDirectoryFile,
    loadNetDirectoryFileNameList,
} from 'store/slices/net-directory-file-slice/net-directory-file-slice.thunk';

import {Spinner} from '../spinner';
import {NetDirectoryFile} from './components/net-directory-file';

export const NetDirectoryFileUploaderModal: React.FC = () => {
    const dispatch = useAppDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const netDirectoryFileData = useAppSelector(selectNetDirectoryFileData);
    const {setNetDirectoryFileData, resetNetDirectoryFile} = netDirectoryFileSliceActions;

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleClose = () => {
        setLoading(false);
        setIsModalOpen(false);
        dispatch(resetNetDirectoryFile());
    };

    const executeFileUpload = async (fileNameList: string[]) => {
        const fileDataList = fileNameList.map(filename => dispatch(
            loadNetDirectoryFile({filename}),
        )
            .unwrap()
            .then(res => res));
        return Promise.all(fileDataList);
    };

    useEffect(() => {
        if (isModalOpen) {
            dispatch(loadNetDirectoryFileNameList())
                .unwrap()
                .then(fileNameList => {
                    executeFileUpload(fileNameList).then(res => {
                        dispatch(setNetDirectoryFileData(res));
                        setLoading(true);
                    }).catch(() => {
                        showMessage({message: NetDirectoryFileErrorMessages.LOAD_FILE, isError: true});
                        setIsModalOpen(false);
                        setLoading(false);
                    });
                }).catch(error => {
                    showMessage({message: NetDirectoryFileErrorMessages.LOAD_FILE_NAME, isError: true});
                    setIsModalOpen(false);
                    console.error(error);
                });
        }
    }, [isModalOpen]);

    return (
        <div className="modal-wrapper">
            <div
                className="alert"
                onClick={showModal}
            >
                <FileUpload className="header__menu__icon" />
            </div>
            <Modal
                className="modal"
                width={DEFAULT_FORM_MODAL_WIDTH}
                title="Загрузка файлов из внешней папки"
                closeIcon={<CloseIcon />}
                visible={isModalOpen}
                onCancel={
                    isLoading
                        ? handleClose
                        : () => showMessage({
                            message: NetDirectoryFileErrorMessages.DATA_LOADING,
                            isError: true,
                        })
                }
                footer={null}
            >
                {
                    isLoading
                        ? (
                            <NetDirectoryFile
                                data={netDirectoryFileData}
                            />
                        )
                        : <Spinner tip={netDirectoryFileLoadMessage} />
                }
            </Modal>
        </div>
    );
};
