import {LookupEntry} from 'shared/types/lookups';
import {performRequest} from 'shared/utils';

import {ExternalSystemUserMappingDto} from './external-systems-slice-types';

export interface GetExternalSystemsForUserArgs {
    userId: number;
}

export const getExternalSystemsForUser = ({
    userId,
}: GetExternalSystemsForUserArgs) => performRequest<ExternalSystemUserMappingDto[]>({
    url: 'external-systems/getExternalSystemsForUser',
    params: {
        userId,
    },
});

export interface GetAvailableExternalSystemsForUserArgs {
    userId: number;
}

export const getAvailableExternalSystemsForUser = ({
    userId,
}: GetAvailableExternalSystemsForUserArgs) => performRequest<LookupEntry[]>({
    url: 'external-systems/getAvailableExternalSystemsForUser',
    params: {
        userId,
    },
});

export interface PostExternalSystemsForUserArgs {
    userId: number;
    data: Omit<Partial<ExternalSystemUserMappingDto>, 'externalMappingId'>[];
}

export const postExternalSystemsForUser = ({
    data,
    userId,
}: PostExternalSystemsForUserArgs) => performRequest({
    url: `external-systems/save/${userId}`,
    data,
    method: 'POST',
});

export const getUseExternalSystemsPropertyFlag = () => performRequest<'Y' | 'N'>({
    url: '/property/USE_FLEX_VALUE_SET_DIRECTORY_OF_EXTERNAL_SYSTEMS',
    method: 'GET',
});
