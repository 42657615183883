import React from 'react';

import {SimpleActionButton} from 'components/form/components';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';
import {tableReportSliceActions} from 'store/slices/table-report-slice/table-report-slice';
import {selectTableReportComparisonOptions} from 'store/slices/table-report-slice/table-report-slice-selectors';
import {loadTableReportPageData} from 'store/slices/table-report-slice/table-report-slice-thunks';

interface ControlRatiosButtonProps {}

export const ControlRatiosButton: React.FC<ControlRatiosButtonProps> = () => {
    const tableReportComparisonOptions = useAppSelector(selectTableReportComparisonOptions);
    const {includeCR} = tableReportComparisonOptions;

    const isLoadingReportData = useAppSelector(s => selectIsThunkPending(s, loadTableReportPageData.typePrefix));

    const {
        updateComparisonOptions,
    } = tableReportSliceActions;

    const dispatch = useAppDispatch();

    return (
        <SimpleActionButton
            key={1}
            type="primary"
            disabled={isLoadingReportData}
            onClick={() => {
                if (!includeCR) {
                    dispatch(updateComparisonOptions({
                        includeCR: true,
                        includeComparison: false,
                        isComparisonFormVisible: false,
                        comparedToDocId: undefined,

                        noFetchWithComparison: true,
                    }));
                    return;
                }

                dispatch(updateComparisonOptions({
                    includeCR: false,
                }));
            }}
            title={includeCR ? 'Скрыть КС' : 'Показать КС'}
            icon={includeCR ? 'EyeClosed' : 'Eye'}
        />
    );
};
