import React, {useEffect} from 'react';

import {selectContextRawData} from 'modules/context/context-selector';
import {selectReferenceEntityData} from 'modules/data/data-selectors';
import {createQueryParams} from 'shared/utils';
import {useQueryParams} from 'shared/utils/query-params/use-query-params.hook';
import {useAppSelector} from 'store/config/hooks';

import {CustomSelect} from '../custom-select';
import {CustomSelectProps} from '../custom-select/custom-select';

export interface QueryParamsBoundReferenceProps extends CustomSelectProps<string, any> {
    name: string;
    useContext?: boolean;
}

export const QueryParamsBoundSelect = ({
    name,
    onChange,
    settings,
    useContext,
    ...props
}: QueryParamsBoundReferenceProps) => {
    const {
        query,
        getQueryParam,
        updateQueryParams,
        removeQueryParams,
    } = useQueryParams();
    const context = useAppSelector(selectContextRawData);

    // get reference url and data
    const {url} = settings || {};
    let referenceQueryParams = '';
    if (useContext && context && url) {
        referenceQueryParams = createQueryParams(context);
    }
    const referenceUrl = `${url || ''}${referenceQueryParams}`;
    const referenceData = useAppSelector(selectReferenceEntityData(referenceUrl));

    // handle query params change synchronously with custom select value
    useEffect(() => {
        if (!referenceData) return;
        const value = parseInt(getQueryParam(name) || '', 10);
        if (value) {
            const referenceValue = referenceData?.find(lookup => lookup.id === value);
            if (referenceValue) {
                onChange?.(value, referenceValue);
            } else {
                onChange?.(undefined, undefined);
            }
        } else {
            onChange?.(undefined, undefined);
        }
    }, [query, referenceData, context]);

    return (
        <CustomSelect
            {...props}
            onChange={(value, option) => {
                if (!value) {
                    removeQueryParams([name]);
                } else {
                    updateQueryParams([{
                        name,
                        value,
                    }]);
                }
                onChange?.(value, option);
            }}
            settings={{
                ...settings,
                url: referenceUrl,
            }}
        />
    );
};
