import React from 'react';

import {Entity, EntityValue} from 'modules/data';
import {LookupValue} from 'modules/data/data-types';
import {
    EntitySignatureUploadState,
} from 'modules/documents/documents-types';
import {FieldMeta} from 'modules/metadata';
import {RegionResponse} from 'modules/regions/regions-types';
import {ReactComponent as DeleteIcon} from 'shared/assets/close.svg';
import {createOptionItemsTransformer} from 'shared/utils';

import {ProgressCell} from '../../editable-table/columns/progress-cell/progress-cell';
import {EditableColumn, FieldStateType} from '../../editable-table/editable-table-types';

const createStateCell = (activityList?: any) => (): FieldStateType => activityList || {} as FieldStateType;

type OptionsType = {
    handleDelete: (tableRow: Entity) => void;
    documentList?: LookupValue[];
    progress?: {
        signUploadSlice: EntitySignatureUploadState;
        fieldKey: string;
    };
};

export enum SignatureUploadKeyCols {
    ACTIONS = 'actions',
    PROGRESS = 'progress',
    DOCUMENT_FILE_ID = 'documentFileId',
}

export const generateSignColumnsMetadata = (
    columnMeta: FieldMeta,
    regions: RegionResponse[],
    {handleDelete, progress, documentList = []}: OptionsType,
): EditableColumn => {
    let columnSpecificProps: Record<string, any> = {};
    // eslint-disable-next-line default-case
    switch (columnMeta.key) {
    case SignatureUploadKeyCols.PROGRESS:
        columnSpecificProps = {
            render: (_: any, record: Record<string, any>) => {
                const {fieldKey, signUploadSlice} = progress || {};
                const uniqueValue = record[fieldKey as string];
                const percent = signUploadSlice?.activityList?.[uniqueValue]?.uploadPercent ?? 0;
                const status = signUploadSlice?.activityList?.[uniqueValue]?.uploadStatus;

                return (
                    <ProgressCell
                        status={status}
                        percent={percent}
                    />
                );
            },
        };
        break;
    case SignatureUploadKeyCols.DOCUMENT_FILE_ID:
        columnSpecificProps = {
            inputProps: {
                options: createOptionItemsTransformer(
                    'meaning', 'id', 'description',
                )(documentList),
            },
        };
        break;
    case SignatureUploadKeyCols.ACTIONS:
        columnSpecificProps = {
            render: (_: any, record: Record<string, EntityValue>) => (
                <DeleteIcon
                    style={{cursor: 'pointer', marginTop: 5}}
                    onClick={() => handleDelete(record)}
                />
            ),
        };
        break;
    }

    return {
        ...columnMeta,
        dataIndex: columnMeta.dataIndex || columnMeta.key,
        getFieldState: createStateCell({
            required: columnMeta?.required,
        }),
        inputProps: undefined,
        ...columnSpecificProps,
    };
};
