import {Card, Modal} from 'antd';
import cn from 'classnames';
import React, {useEffect, useState} from 'react';

import {DynamicIcon} from 'components/dynamic-icon';
import {Form} from 'components/form/form';
import {Spinner} from 'components/spinner';
import {TableActionProps} from 'components/table/action';
import {ActionButton} from 'components/table/action/action-button/action-button';
import {selectDocumentFileUpload} from 'modules/documents';
import {setFileList} from 'modules/documents/documents-actions';
import {EntityFileUploadState} from 'modules/documents/documents-types';
import {selectMetadata, loadMetadata as loadMetadataAction} from 'modules/metadata';
import {ReactComponent as CloseIcon} from 'shared/assets/close.svg';
import {EntityType} from 'shared/constants/entities';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';

interface IOwnProps extends TableActionProps {
}

export const ButtonSetCorrectionNumber = ({
    wrapper, meta, entityName, ...props
}: IOwnProps) => {
    const dispatch = useAppDispatch();
    const metadata = useAppSelector(
        state => selectMetadata(meta?.modalEntityName || '', EntityType.FORM)(state),
    );
    const files = useAppSelector(
        state => selectDocumentFileUpload(state)[entityName] as EntityFileUploadState,
    )?.fileList;

    const Component = wrapper || ActionButton;
    const [visible, setVisible] = useState(false);

    const handleSubmit = (formData: Record<string, any>) => {
        if (formData?.correctionNumber !== undefined && files?.length) {
            dispatch(setFileList({
                entityName,
                fileList: files.map(file => ({...file, correctionNumber: formData.correctionNumber})),
            }));
            setVisible(false);
        }
    };
    useEffect(() => {
        if (!metadata) {
            dispatch(loadMetadataAction(meta?.modalEntityName || '', EntityType.FORM));
        }
    }, []);

    const isLoaded = !!metadata;
    const cardTitle = metadata?.title;
    return (
        <>
            <Component
                {...props}
                onClick={() => setVisible(true)}
                disabled={!files?.length}
            >
                {meta.actionIcon && ((
                    <DynamicIcon
                        className="table-action__svg-icon"
                        type={meta.actionIcon}
                    />
                ))}
                {meta.actionTitle}
            </Component>
            <Modal
                className="form-modal"
                footer={null}
                visible={visible}
                width={980}
                onCancel={() => setVisible(false)}
                maskClosable={false}
                closeIcon={<CloseIcon className={cn('modal-close-icon', 'with-title')} />}
                destroyOnClose
            >
                {isLoaded
                    ? (
                        <Form
                            meta={metadata}
                            cardTitle={cardTitle}
                            entityName={entityName}
                            onClose={() => setVisible(false)}
                            actionForForm={(...args) => handleSubmit(args[3])}
                        />
                    ) : <Card style={{paddingTop: 20}}> <Spinner /></Card>}
            </Modal>
        </>
    );
};
