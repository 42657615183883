import {WIDGET_PAGE_ENTITY_NAME} from 'pages/widget-page/widget-page';
import {EntityType} from 'shared/constants/entities';
import {showMessageFromResponse} from 'shared/utils';
import {createAction} from 'store/config/creators';
import {AppState, Dispatch} from 'store/config/types';

import {addToFetchedWithContext} from '../context/context-actions';
import {selectContextRawData} from '../context/context-selector';
import {fetchAllMetadata, fetchEntityMetadata} from './metadata-api';
import {MetadataActions} from './metadata-constants';
import {selectMetadata} from './metadata-selectors';
import {
    EntityMeta, FormDraftMetadataPayload,
    MetaActionType,
    MetadataResetPayload,
    MetadataSetAllPayload,
    MetadataSetPayload,
} from './metadata-types';
import {getActionByType} from './metadata-utils';

export const setAllMetadata = createAction<MetadataSetAllPayload>(MetadataActions.SET_ALL_METADATA);
export const setMetadata = createAction<MetadataSetPayload>(MetadataActions.SET_METADATA);
export const resetMetadata = createAction<MetadataResetPayload>(MetadataActions.RESET_METADATA);
export const setFormDraftMetadata = createAction<FormDraftMetadataPayload>(MetadataActions.SET_FORM_DRAFT_METADATA);

export const loadAllMetadata = () => async (dispatch: Dispatch) => {
    const response = await fetchAllMetadata();
    const data = response.data as EntityMeta[];
    dispatch(setAllMetadata({metadata: data}));
};

export const loadMetadata = (
    entityName: string,
    entityType: EntityType,
    reloadMeta?: boolean,
) => async (dispatch: Dispatch, getState: () => AppState) => {
    const existingMetadata = selectMetadata(entityName, entityType)(getState());
    const contextFilterData = selectContextRawData(getState());

    let metadataResponse = null;
    const taxPeriodId = !existingMetadata?.useContext ? null : contextFilterData?.taxPeriodId || null;

    if (!reloadMeta && existingMetadata) return null;
    if (entityName === WIDGET_PAGE_ENTITY_NAME) return null;

    try {
        if (entityType !== EntityType.CONTEXT_FILTER) {
            const {data, data: {actions}} = await fetchEntityMetadata(entityName, entityType, taxPeriodId);
            const metadata = {
                ...data,
                isFilterable: !!getActionByType(MetaActionType.FILTER)(actions),
                isSearchable: !!getActionByType(MetaActionType.SEARCH)(actions),
            };
            metadataResponse = metadata;
            dispatch(setMetadata({entityName, entityType, metadata}));

            if (taxPeriodId && existingMetadata?.useContext) {
                dispatch(addToFetchedWithContext(entityName));
            }
        }
    } catch (e: any) {
        console.error(e);
        showMessageFromResponse({response: e.response, isError: true});
    }

    return metadataResponse;
};
