import {FunctionComponent, SVGProps} from 'react';

import {ReactComponent as EvaluationIcon} from 'shared/assets/svc/evaluation.svg';
import {ReactComponent as EventsIcon} from 'shared/assets/svc/events.svg';
import {ReactComponent as InfoIcon} from 'shared/assets/svc/info-org.svg';
import {ReactComponent as MatrixIcon} from 'shared/assets/svc/matrix.svg';
import {ReactComponent as DirectionsIcon} from 'shared/assets/svc/risk-operation.svg';
import {ReactComponent as RiskIcon} from 'shared/assets/svc/risk.svg';

export const SvcDictionaryIcons : Record<string, FunctionComponent<SVGProps<SVGSVGElement>>> = {
    MATRIX: MatrixIcon,
    INFO: InfoIcon,
    DIRECTIONS: DirectionsIcon,
    EVALUATION: EvaluationIcon,
    EVENTS: EventsIcon,
    RISK: RiskIcon,
};

export type SvcDictionaryIconsMappingKey = keyof typeof SvcDictionaryIcons;
