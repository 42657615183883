import {DATE_DMY, DATE_WITH_TIME_DOTS} from 'shared/constants/date-format';

import {FieldMeta} from '../../../metadata';

export const getAdditionalFields = (
    serverFields: any[] | undefined,
    path: (string[] | undefined)[],
    keys: any[],
    contextData: any,
) => {
    let field: FieldMeta[] = [];
    const additionalFields: any[] = [];
    let additionalField : any = {};
    serverFields?.forEach(el => {
        path.forEach(node => {
            if (node?.length === 1) {
                field.push(el[node[0]]);
            }
            if (node?.length === 2) {
                field.push(el[node[0]][node[1]]);
            }
        });
        keys.forEach((curElement, index) => {
            additionalField[curElement] = field[index];
        });
        additionalField.isRequired = el.requiredFlag || false;
        switch (additionalField.type) {
        case 'DATE':
            additionalField.dateFormat = DATE_DMY;
            additionalField.requestDateFormat = DATE_DMY;
            if (!(new Date(additionalField.defaultValue) instanceof Date
                && Number(new Date(additionalField.defaultValue)))) {
                additionalField.defaultValue = '';
            }
            break;
        case 'DATETIME':
            additionalField.dateFormat = DATE_WITH_TIME_DOTS;
            break;
        case 'MULTIPART_FILE':
            additionalField.type = 'FILE';
            additionalField.defaultValue = '';
            break;
        case 'VALUESET':
            additionalField.type = 'REFERENCE';
            additionalField.onNotCreateId = true;
            additionalField.onNotCreateKey = true;
            if (el.valuesetLookupType.lookupType) {
                additionalField.referenceUrl = `lookupValue/${el.valuesetLookupType.lookupType}`;
            }
            break;
        case 'BOOLEAN':
            additionalField.defaultValue = additionalField.defaultValue || false;
            break;
        case 'NUMBER':
            if (!Number(additionalField.defaultValue)) additionalField.defaultValue = '';
            break;
        default:
            break;
        }

        if (el.defaultType?.lookupCode === 'CONTEXT') {
            if (additionalField.referenceUrl === 'lookupValue/TAX_PERIOD') {
                additionalField.defaultValue = contextData.taxPeriodId;
            }
            if (additionalField.referenceUrl === 'lookupValue/ORGANIZATION') {
                additionalField.defaultValue = contextData.organizationId;
            }
        }
        additionalFields.push(additionalField);
        field = [];
        additionalField = {};
    });
    return additionalFields;
};
