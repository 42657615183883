import {ColumnsType} from 'antd/es/table';

import {monitoringStatusCode} from 'modules/documents/documents-upload-monitoring-types';

export const UPLOAD_DOCUMENT_URL = 'monitor/download-attach-and-mark';
export const UPLOAD_LOG_URL = 'administration.monitor.programs/download/';
export const MODAL_UPDATE_DELAY = 20000;

export enum DownloadReportDocumentsFields {
    ProgramName = 'programName',
    UploadedFilename = 'uploadedFilename',
    UploadedDocTypeName = 'uploadedDocTypeName',
    InnerRequests = 'innerRequests',
    DownloadFiles = 'downloadFiles',
    Id = 'id',
    AttachId = 'attachId'
}

export const DownloadReportDocumentsFieldsWidth: Record<string, number> = {
    [DownloadReportDocumentsFields.ProgramName]: 384,
    [DownloadReportDocumentsFields.UploadedFilename]: 392,
    [DownloadReportDocumentsFields.UploadedDocTypeName]: 351,
    [DownloadReportDocumentsFields.InnerRequests]: 172,
    [DownloadReportDocumentsFields.DownloadFiles]: 256,
};

export const DownloadReportStatuses: Record<string, string> = {
    [monitoringStatusCode.normal]: 'success',
    [monitoringStatusCode.onHold]: 'onHold',
    [monitoringStatusCode.running]: 'running',
    [monitoringStatusCode.warning]: 'warning',
    [monitoringStatusCode.error]: 'error',
};

export const DownloadDocumentsColumns: ColumnsType<Record<string, DownloadReportDocumentsFields>> = [
    {
        title: 'Имя программы',
        dataIndex: DownloadReportDocumentsFields.ProgramName,
        key: DownloadReportDocumentsFields.ProgramName,
    },
    {
        title: 'Загружаемый файл',
        dataIndex: DownloadReportDocumentsFields.UploadedFilename,
        key: DownloadReportDocumentsFields.UploadedFilename,
    },
    {
        title: 'Тип документа',
        dataIndex: DownloadReportDocumentsFields.UploadedDocTypeName,
        key: DownloadReportDocumentsFields.UploadedDocTypeName,
    },
    {
        title: 'Статус',
        dataIndex: DownloadReportDocumentsFields.InnerRequests,
        key: DownloadReportDocumentsFields.InnerRequests,
    },
    {
        title: 'Сформированный файл',
        dataIndex: DownloadReportDocumentsFields.DownloadFiles,
        key: DownloadReportDocumentsFields.DownloadFiles,
    },
];
