/* eslint no-unreachable: 0 */
import {Typography, message} from 'antd';
import React, {useRef, useState} from 'react';

import {FormMode} from 'components/form';
import {ModalOpenerComponent, ModalOpenerComponentRef} from 'components/modal-opener-component';
import {selectContextRawData} from 'modules/context/context-selector';
import {convertAdditionalFieldsTabValues} from 'pages/ais/documents-register-page/modals/document-card-modal/document-card-modal-utils';
import {useUserRoleFunctions} from 'shared/hooks/use-user-role-functions';
import {StateSetter} from 'shared/types/generics';
import {makeFormFieldsUntouched, showMessage} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {aisSelectors, aisSliceActions} from 'store/slices/ais-slice/ais-slice';
import {
    createAisDocumentInRegister,
    updateAisDocumentInRegister,
    updateAisDocumentsAdditionalFields,
    updateAisIncludedDocumentsByTaxList,
} from 'store/slices/ais-slice/ais-slice-thunks';
import {AisRegisterDocumentDto} from 'store/slices/ais-slice/ais-slice-types';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';
import {UserRoleFunction} from 'store/slices/user-slice/user-slice-role-functions';

import {AIS_REGISTER_ROLE_SECTION} from '../../ais-documents-register-constants';
import {DocumentCardModalTabKey} from './document-card-modal-constants';
import {DocumentCardModalContent, DocumentCardModalContentRef} from './document-card-modal-content';

interface DocumentCardModalProps {
    cellValue?: string;
    initialRegisterDocument?: AisRegisterDocumentDto; // документ может поменяться
    // после добавления записи (в режиме создания)
    initialFormMode?: FormMode; // может поменяться после создания записи
    resetPage?: () => void;
}

export const DocumentCardModal = React.forwardRef<
    ModalOpenerComponentRef, DocumentCardModalProps>(({
        cellValue,
        initialRegisterDocument,
        initialFormMode = FormMode.EDIT,
        resetPage,
    }: DocumentCardModalProps, ref) => {
        const documentCardRef = useRef<DocumentCardModalContentRef>(null);

        const [formMode, setFormMode] = useState(initialFormMode);

        const dispatch = useAppDispatch();
        const {organizationId} = useAppSelector(selectContextRawData) ?? {};

        const includedDocumentsByTax = useAppSelector(aisSelectors.includedDocumentsByTaxSelectors.selectAll);

        const isCreatingDocument = useAppSelector(s => selectIsThunkPending(s, createAisDocumentInRegister.typePrefix));
        const isUpdatingDocument = useAppSelector(s => selectIsThunkPending(s, updateAisDocumentInRegister.typePrefix));
        const isSavingIncludedTaxes = useAppSelector(s => selectIsThunkPending(s,
            updateAisIncludedDocumentsByTaxList.typePrefix));

        const {hasUserRoleFunction} = useUserRoleFunctions({
            sections: [AIS_REGISTER_ROLE_SECTION],
        });

        const [selectedTabKey, setSelectedTabKey] = useState<DocumentCardModalTabKey>(DocumentCardModalTabKey.main);
        const handleFinish = async (setIsModalOpen: StateSetter<boolean>) => {
            const mainInfoTabForm = documentCardRef.current?.mainInfoTabForm;
            const includedTaxesTabForm = documentCardRef.current?.includedTaxesForm;
            const additionalFieldsTabForm = documentCardRef.current?.additionalFieldsForm;

            const mainInfoTabValues = mainInfoTabForm?.getFieldsValue();
            const includedTaxesTabValues = includedTaxesTabForm?.getFieldValue('taxes');
            const additionalFieldsTabRawValues = additionalFieldsTabForm?.getFieldsValue();

            const additionalFieldsTabValues = convertAdditionalFieldsTabValues({values: additionalFieldsTabRawValues});

            const mainInfoValidationResults = await mainInfoTabForm?.validateFields().catch(r => {
                message.error(
                    'Пожалуйста, проверьте корректность введённых данных на вкладке "Основная информация"',
                    1,
                );
                setSelectedTabKey(DocumentCardModalTabKey.main);
                return r;
            });
            if (mainInfoValidationResults?.errorFields?.length) return;

            const taxesValidationResults = await includedTaxesTabForm?.validateFields().catch(r => {
                message.error(
                    'Пожалуйста, проверьте корректность введённых данных на вкладке "Налоги"',
                    1,
                );
                setSelectedTabKey(DocumentCardModalTabKey.tax);
                return r;
            });
            if (taxesValidationResults?.errorFields?.length) return;

            if (formMode === FormMode.CREATE) {
                if (!(organizationId && mainInfoTabValues)) return;
                dispatch(createAisDocumentInRegister({
                    ...mainInfoTabValues,
                    orgId: organizationId,
                })).unwrap().then(document => {
                    documentCardRef.current?.setRegisterDocument(document);
                    showMessage({message: 'Карточка документа успешно создана'});
                    resetPage?.(); // сброс страницы на 1-ю
                    setFormMode(FormMode.VIEW);
                    if (mainInfoTabForm) makeFormFieldsUntouched(mainInfoTabForm);
                }, () => {
                    showMessage({message: 'Произошла ошибка создания карточки документа', isError: true});
                });
                return;
            }

            const pudUploadForm = documentCardRef.current?.pudUploadForm;
            const pudUploadValidationResult = await pudUploadForm?.validateFields().catch(r => {
                message.error(
                    'Пожалуйста, проверьте корректность полей, связанных с ПУД',
                    1,
                );
                setSelectedTabKey(DocumentCardModalTabKey.main);
                return r;
            });
            if (pudUploadValidationResult?.errorFields?.length) return;

            documentCardRef.current?.handlePudFormFinish(pudUploadForm?.getFieldsValue());

            const additionalFieldValidationResults = await additionalFieldsTabForm?.validateFields().catch(r => {
                message.error(
                    'Пожалуйста, проверьте корректность введённых данных на вкладке "Дополнительные поля"',
                    1,
                );
                setSelectedTabKey(DocumentCardModalTabKey.additional);
                return r;
            });
            if (additionalFieldValidationResults?.errorFields?.length) return;

            const includedTaxesUpdatePromise = dispatch(updateAisIncludedDocumentsByTaxList({
                baseDocumentId: documentCardRef.current?.registerDocument?.id,
                currentDocumentsByTax: includedDocumentsByTax,
                newDocumentsByTax: includedTaxesTabValues,
            })).unwrap();

            const documentUpdatePromise = dispatch(updateAisDocumentInRegister({
                id: documentCardRef.current?.registerDocument?.id,
                ...mainInfoTabValues,
            })).unwrap();

            const additionalFieldUpdatePromise = dispatch(updateAisDocumentsAdditionalFields({
                id: documentCardRef.current?.registerDocument?.id,
                values: additionalFieldsTabValues,
            })).unwrap();

            Promise.allSettled([
                includedTaxesUpdatePromise,
                documentUpdatePromise,
                additionalFieldUpdatePromise,
            ]).then(([
                taxesUpdateResult,
                documentUpdateResult,
                additionalFieldUpdateResult,
            ]) => {
                setIsModalOpen(false);
                if (
                    taxesUpdateResult?.status === 'fulfilled'
                    && documentUpdateResult?.status === 'fulfilled'
                    && additionalFieldUpdateResult?.status === 'fulfilled'
                ) {
                    showMessage({message: 'Карточка документа успешно обновлена'});
                }
                if (taxesUpdateResult?.status === 'rejected') {
                    showMessage({message: 'Во время обновления налогов произошла ошибка', isError: true});
                }
                if (documentUpdateResult?.status === 'rejected') {
                    showMessage({message: 'Во время обновления карточки произошла ошибка', isError: true});
                }
                if (additionalFieldUpdateResult?.status === 'rejected') {
                    showMessage({message: 'Во время обновления дополнительных полей произошла ошибка', isError: true});
                }
            });
        };

        const isDocumentDisabled = formMode === FormMode.EDIT && !hasUserRoleFunction(UserRoleFunction.UPDATE);

        return (
            <ModalOpenerComponent
                ref={ref}
                componentWrapperClassNames="d-inline w-100"
                component={initialFormMode === FormMode.EDIT ? (
                    <div className="text-center">
                        <Typography.Link>
                            {cellValue}
                        </Typography.Link>
                    </div>
                ) : undefined}
                shouldConfirm={() => !!documentCardRef.current?.mainInfoTabForm?.isFieldsTouched()
                    || !!documentCardRef.current?.includedTaxesForm.isFieldsTouched()}
                modalProps={{
                    centered: true,
                    title: 'Карточка документа',
                    forceRender: false,
                    destroyOnClose: true,
                }}
                disabledControls={{
                    save: isCreatingDocument || isUpdatingDocument || isSavingIncludedTaxes,
                }}
                loadingControls={{
                    save: isCreatingDocument || isUpdatingDocument || isSavingIncludedTaxes,
                }}
                controlLabels={{
                    save: formMode === FormMode.CREATE ? 'Создать' : 'Сохранить',
                }}
                handleCancel={setIsModalOpen => {
                    setIsModalOpen(false);
                }}
                hideControls={{
                    save: isDocumentDisabled,
                }}
                controlsStyle={{marginTop: selectedTabKey === DocumentCardModalTabKey.tax ? 12 : 20}}
                handleSave={handleFinish}
                afterModalClose={() => {
                    setSelectedTabKey(DocumentCardModalTabKey.main);
                    if (initialFormMode === FormMode.CREATE) setFormMode(FormMode.CREATE);
                    dispatch(aisSliceActions.resetIncludedDocumentsByTax());
                }}
            >
                <DocumentCardModalContent
                    formMode={formMode}
                    ref={documentCardRef}
                    initialRegisterDocument={initialRegisterDocument}
                    setSelectedTabKey={setSelectedTabKey}
                    selectedTabKey={selectedTabKey}
                    isDisabled={isDocumentDisabled}
                />
            </ModalOpenerComponent>
        );
    });
