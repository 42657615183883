import {Popover} from 'antd';
import cn from 'classnames';
import React, {useState} from 'react';

import {DynamicIcon} from 'components/dynamic-icon';

import {FormTooltip} from './components/form-tooltip';
import {TextTooltip} from './components/text-tooltip';

import './text-modal.less';

export const TextModalCell = React.forwardRef(({
    value, onChange, placeholder, isRequired,
}: any) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const type = value ? 'InfoCircleOutlined' : 'EditWithLine';

    const handleVisibleChange = (visible: boolean) => {
        setIsVisible(visible);
    };

    return (
        <div className="text-modal-cell">
            <Popover
                overlayClassName={cn('text-modal-popover')}
                onVisibleChange={handleVisibleChange}
                content={isEditing || !value ? (
                    <FormTooltip
                        name="name"
                        value={value}
                        onChange={onChange}
                        placeholder={placeholder}
                        onClose={() => {
                            setIsVisible(false);
                            setIsEditing(false);
                        }}
                        isRequired={isRequired}
                    />
                ) : (
                    <TextTooltip
                        value={value}
                        handleEdit={() => { setIsEditing(true); }}
                    />
                )}
                overlayStyle={{position: 'fixed'}}
                trigger={!value && !isVisible ? 'click' : 'hover'}
                color="white"
                placement="bottomLeft"
                visible={isVisible}
            >
                <DynamicIcon
                    type={type}
                    className={cn(isVisible && 'active', isRequired && !value && 'required')}
                />
            </Popover>
        </div>
    );
});
