import cn from 'classnames';
import React from 'react';

import {ReactComponent as EyeClosedIcon} from 'shared/assets/eye-closed.svg';

import {
    GetSortDataFunction,
    SetSortDataFunction,
} from './hooks/use-table-column-sort';

import './table-column-menu.less';

interface TableColumnFilterProps {
    setSortData: SetSortDataFunction;
    getSortData: GetSortDataFunction;

    handleHideColumnClick: () => void;

    hideOptions?: boolean;
}

export const TableColumnMenu: React.FC<TableColumnFilterProps> = (
    {
        getSortData,
        setSortData,
        handleHideColumnClick,
        hideOptions,
    }: TableColumnFilterProps,
) => {
    const sortData = getSortData();

    if (hideOptions) {
        return (
            <div className={cn('table-column-menu')}>
                <div className={cn('table-column-menu__triangle')} />
                <div className={cn('table-column-menu__body')}>
                    <div className={cn('d-flex align-items-center justify-content-center mt-1 mb-1')}>
                        Нет доступных действий
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={cn('table-column-menu')}>
            <div className={cn('table-column-menu__triangle')} />
            <div className={cn('table-column-menu__body')}>
                <div
                    onClick={() => {
                        if (sortData.order === 'ascend') setSortData({order: undefined});
                        else setSortData({order: 'ascend'});
                    }}
                    className={cn(
                        'table-column-menu__body__sort-option',
                        {
                            'table-column-menu__body__sort-option_selected': sortData.order === 'ascend',
                        },
                    )}
                >
                    <div className={cn('table-column-menu__body__sort-option__triangle-wrapper')}>
                        <div className={cn(
                            'table-column-menu__body__sort-option__triangle',
                            'table-column-menu__body__sort-option__triangle_desc',
                        )}
                        />
                    </div>
                    <div>Сортировать А-Я</div>
                </div>

                <div
                    onClick={() => {
                        if (sortData.order === 'descend') setSortData({order: undefined});
                        else setSortData({order: 'descend'});
                    }}
                    className={cn(
                        'table-column-menu__body__sort-option',
                        {
                            'table-column-menu__body__sort-option_selected': sortData.order === 'descend',
                        },
                    )}
                >
                    <div className={cn('table-column-menu__body__sort-option__triangle-wrapper')}>
                        <div className={cn(
                            'table-column-menu__body__sort-option__triangle',
                            'table-column-menu__body__sort-option__triangle_asc',
                        )}
                        />
                    </div>
                    <div>Сортировать Я-А</div>
                </div>

                <div
                    className="table-column-menu__body__hide-option"
                    onClick={handleHideColumnClick}
                >
                    <EyeClosedIcon
                        height={16}
                        width={16}
                        className="table-column-menu__body__icon"
                        fill="currentColor"
                    />
                    <div>Скрыть колонку</div>
                </div>
            </div>
        </div>
    );
};
