export const documentUploadTemplateConfiguratorMainFields: string[] = [
    'templateName',
    'templateCode',
    'templatePurpose',
    'startDate',
    'endDate',
    'active',
    'documentTypesCodes',
    'documentTypes',
];

export enum DocumentUploadTemplateConfiguratorFormFields {
    templateName = 'templateName',
    templateCode = 'templateCode',
    programCode = 'programCode',
    programSetCode = 'programSetCode',
    templatePurpose = 'templatePurpose',
    startDate= 'startDate',
    endDate = 'endDate',
    active = 'active',
    documentTypesCodes = 'documentTypesCodes',
}
