import {Empty} from 'antd';
import React from 'react';

import {ReactComponent as EmptySvg} from 'shared/assets/empty.svg';

export const StatisticsEmptyPlaceholder = () => (
    <Empty
        imageStyle={{height: 90}}
        image={<EmptySvg />}
    />
);
