import {
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import {v4 as uid} from 'uuid';

import {getMapOfValues} from 'shared/utils';

import {menuSliceName} from './menu-slice-constants';
import {loadMenuStructure} from './menu-slice-thunks';
import {
    MenuSliceState, MenuStructure, MenuStructureItem,
} from './menu-slice-types';

export const menuSlice = createSlice({
    initialState: {
        selectedMenuItem: undefined,

        isMenuOpen: false,
        searchLine: undefined,

        structure: null,
        structureIdMap: null,

        meta: {
            openId: undefined,
        },
    } as MenuSliceState,
    name: menuSliceName,
    reducers: {
        setSearchLine(state, {payload}: PayloadAction<string | undefined>) {
            state.searchLine = payload;
        },
        setSelectedMenuItem(state, {payload}: PayloadAction<MenuStructureItem | undefined>) {
            state.selectedMenuItem = payload;
        },
        setIsMenuOpen(state, {payload}: PayloadAction<boolean>) {
            state.isMenuOpen = payload;
            if (payload) state.meta.openId = uid();
        },
        setMenuData(state, {payload}: PayloadAction<MenuStructure>) {
            state.structure = payload;
            state.structureIdMap = getMapOfValues<MenuStructureItem>(payload);
        },
    },
    extraReducers: builder => {
        builder.addCase(loadMenuStructure.fulfilled, (state, {payload: structure}) => {
            const structureWithChildren = structure.map(item => {
                const children = structure.filter(child => child.parentId === item.id);
                return {
                    ...item,
                    children,
                };
            }) as MenuStructureItem[];

            state.structure = structureWithChildren;
            state.structureIdMap = getMapOfValues<MenuStructureItem>(structureWithChildren);
        });
    },
});

export const {
    reducer: menuSliceReducer,
    actions: menuSliceActions,
} = menuSlice;
