import get from 'lodash/get';
import React, {Suspense, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {
    Redirect, Route, Switch, useLocation,
} from 'react-router-dom';

import NewSideMenu from 'components/@common/specific/new-side-menu';
import {setEntityNamesFetchedWithContext, updateContextRawData} from 'modules/context/context-actions';
import {fetchRequestListInfo} from 'modules/data/api/documents-upload-monitoring-api';
import {
    createActionHandlerForTableChange,
    resetAllDataWithException,
    setNotificationDuration,
} from 'modules/data/data-actions';
import {selectNotificationDuration} from 'modules/data/data-selectors';
import {selectEntityMetadata, selectMetadata, selectTableEntityMetadata} from 'modules/metadata/metadata-selectors';
import {getPageTemplate} from 'pages';
import {TablePage} from 'pages/table-page';
import {TableReportPage} from 'pages/table-report-page';
import {WidgetPage} from 'pages/widget-page';
import {EntityType} from 'shared/constants/entities';
import {
    URL_AIS3_MAIN,
    URL_LOGIN,
    URL_SVC_CP,
    URL_SVC_CP_PERIOD,
    URL_SVC_RISK,
    URL_SVC_RISK_PERIOD,
    URL_SVC_WIDGETS,
    URL_WIDGETS,
} from 'shared/constants/urls';
import {showMessageFromResponse} from 'shared/utils';
import {useGetAppPropertyQuery} from 'store/api/app-properties/app-properties.api';
import {useAppSelector} from 'store/config/hooks';
import {loadUserInfo, selectAuthToken} from 'store/slices/auth-slice';
import {
    selectMenuStructure, selectMenuStructureIdMap, generateRoute, isItemTypeTabsWithReferences,
} from 'store/slices/menu-slice';
import {loadMenuStructure} from 'store/slices/menu-slice/menu-slice-thunks';
import {MenuStructure, MenuStructureItem} from 'store/slices/menu-slice/menu-slice-types';
import {tableReportSliceActions} from 'store/slices/table-report-slice';
import {useWebsocket} from 'websockets';
import {WebsocketsContext} from 'websockets/core';

import {Breadcrumbs} from '../breadcrumbs';
import {parseBreadcrumbsFromMenuItem} from '../breadcrumbs/utils/breadcrumb-generator';
import {ContextFilter} from '../context-filter';
import {DraggableFrameRoot} from '../draggable-frame';
import {Footer} from '../footer';
import {Header} from '../header';
import {ModalRoot} from '../modal';
import {Spinner} from '../spinner';
import {
    DownloadReportDocumentsModal,
} from '../table-report/components/table-report-modal/download-report-documents-modal';
import {
    defineEntityName,
    getMenuNavLinks,
    splitUrl,
    TABLE_REPORT_ENTITY_NAME,
} from './layout.utils';

import './layout.less';

interface LayoutProps {
    currentEntityName: string;
    contextFilterData?: Record<string, any>;
    loadStructure: () => void;
    changeData: (
        entityName: string,
        entityType: EntityType,
        data: Record<string, any>,
    ) => void;
    menuStructure: MenuStructure | null;
    menuStructureIdMap: Record<string, MenuStructureItem> | null;
    isAuthorized: boolean;
    match?: {
        params: { entityName: string };
    };
}

// const SideMenu = React.lazy(() => import('../@common/specific/new-side-menu'));

const LayoutComponent: React.FunctionComponent<LayoutProps> = ({
    match,
}: LayoutProps) => {
    const dispatch = useDispatch();
    const currentLocationUrl = useLocation<History>().pathname;
    const currentEntityName = get(match, 'params.entityName', '');
    const getCurrentEntityMetaSelector = selectEntityMetadata(currentEntityName);
    const currentEntityMeta = useAppSelector(state => getCurrentEntityMetaSelector(state));
    const currentEntityType = currentEntityMeta && Object.keys(currentEntityMeta)[0] as EntityType;
    const currentLocation = splitUrl(currentLocationUrl, '/', currentEntityName);

    const menuStructure = useAppSelector(selectMenuStructure);
    const menuStructureIdMap = useAppSelector(selectMenuStructureIdMap);
    const isAuthorized = !!useAppSelector(selectAuthToken);

    const menuEntityType = menuStructure && !currentEntityType
        && menuStructure?.find(menu => menu.entityName === currentEntityName)?.entityType;
    const locationOrEntityName = defineEntityName(currentEntityName, currentLocation, currentLocationUrl);
    const isPaginationDisabled = useAppSelector(selectTableEntityMetadata(locationOrEntityName))?.disabledPagination;
    const metadata = useAppSelector(selectMetadata(locationOrEntityName, currentEntityType));
    const isCurrentPathReport = menuEntityType as unknown as EntityType === EntityType.TABLE_REPORT
        || locationOrEntityName === TABLE_REPORT_ENTITY_NAME;
    const {setReportDownloadsData} = tableReportSliceActions;
    const menuNavLinks = getMenuNavLinks(menuStructure);
    const selectedNotificationData = useAppSelector(selectNotificationDuration());
    const {data: notificationData} = useGetAppPropertyQuery(
        {propertyCode: 'TIMEOUT_NOTY_SETTING'},
        {skip: false},
    );

    const loadStructure = () => dispatch(loadMenuStructure());
    const loadUser = () => dispatch(loadUserInfo());

    const [collapsed, setCollapsed] = useState(true);

    useEffect(() => {
        if (isAuthorized) {
            loadUser();
        }
    }, [isAuthorized]);

    useEffect(() => {
        if (!menuStructure) {
            loadStructure();
            fetchRequestListInfo().then(result => {
                dispatch(setReportDownloadsData(result.data));
            }).catch(er => showMessageFromResponse({response: er?.response, isError: true}));
        }
    }, [menuStructure]);

    useEffect(() => {
        if (!selectedNotificationData && notificationData && notificationData.TIMEOUT_NOTY_SETTING) {
            dispatch(setNotificationDuration({notificationDuration: notificationData.TIMEOUT_NOTY_SETTING}));
        }
    }, [selectedNotificationData]);

    const {wsInstance} = useWebsocket();

    if (!isAuthorized) {
        return <Redirect to={URL_LOGIN} />;
    }

    return (
        <Suspense fallback={(
            <div className="content-layout">
                <div className="layout__spinner">
                    <Spinner tip="Подождите..." />
                </div>
            </div>
        )}
        >
            <WebsocketsContext.Provider value={{
                wsInstance,
            }}
            >
                <div className="layout-wrapper">
                    <div className="layout-side-menu">
                        <NewSideMenu />
                    </div>
                    <div className="layout">
                        <Header
                            menuContent={<DownloadReportDocumentsModal />}
                            menuNavLinks={menuNavLinks}
                        >
                            <ContextFilter
                                onChange={values => {
                                    dispatch(setEntityNamesFetchedWithContext([]));
                                    dispatch(updateContextRawData({rawData: values}));
                                    dispatch(resetAllDataWithException({
                                        entityName: currentEntityName,
                                        entityType: currentEntityType,
                                    }));
                                    dispatch(createActionHandlerForTableChange(EntityType.FILTER)(
                                        currentEntityName,
                                        values,
                                        currentLocationUrl,
                                        currentEntityType || menuEntityType,
                                        metadata?.prefix,
                                        metadata?.disabledPagination || isPaginationDisabled,
                                    ));
                                }}
                                isDisabled={isCurrentPathReport}
                            />
                        </Header>
                        <section className="layout__content">
                            {!collapsed && (
                                <div
                                    className="layout__content darken"
                                    onClick={() => setCollapsed(true)}
                                />
                            )}
                            <Switch>
                                <Route
                                    path={URL_WIDGETS}
                                    exact
                                    component={WidgetPage}
                                />
                                <Route // todo: temporary solution
                                    path={URL_AIS3_MAIN}
                                    render={props => {
                                        const TemplateComponent = getPageTemplate('AIS_MAIN_PAGE');
                                        return (
                                            <>
                                                <Breadcrumbs
                                                    breadcrumbs={parseBreadcrumbsFromMenuItem(
                                                        menuStructureIdMap,
                                                        'Интеграционный модуль с АИС "Налог-3',
                                                'ais3.dashboard' as any,
                                                'ais3.dashboard',
                                                null as any,
                                                currentLocationUrl,
                                                    )}
                                                />
                                                <TemplateComponent
                                                    {...props}
                                                    url="ais3.dashboard"
                                                    entityName="ais3.dashboard"
                                                    entityType={'ais3.dashboard' as any}
                                                    parentItem={null as any}
                                                />
                                            </>
                                        );
                                    }}
                                />
                                <Route // todo: temporary solution
                                    path={URL_SVC_WIDGETS}
                                    render={props => {
                                        const TemplateComponent = getPageTemplate('WIDGETS');
                                        return (
                                            <>
                                                <Breadcrumbs
                                                    breadcrumbs={parseBreadcrumbsFromMenuItem(
                                                        menuStructureIdMap,
                                                        'Главная страница системы внутреннего контроля',
                                                'analysis2.main' as any,
                                                'analysis2.main',
                                                null as any,
                                                currentLocationUrl,
                                                    )}
                                                />
                                                <TemplateComponent
                                                    {...props}
                                                    url="analysis2.main"
                                                    entityName="analysis2.main"
                                                    entityType={'analysis2.main' as any}
                                                    parentItem={null as any}
                                                />
                                            </>
                                        );
                                    }}
                                />
                                <Route
                                    path="/table-reports/:id?/:templateCode"
                                    exact
                                    render={props => {
                                        const {id, templateCode} = props?.match.params || {};
                                        const templateCodeEntityName = menuStructure
                                            ?.find(m => m.templateCode === templateCode)?.entityName;
                                        return (
                                            <TableReportPage
                                                entityName={templateCodeEntityName}
                                                docId={id ?? null}
                                                templateCode={templateCode}
                                            />
                                        );
                                    }}
                                />
                                {menuStructure?.map(menuItem => {
                                    let actualItem = menuItem;
                                    if (menuItem.children?.length) {
                                        [actualItem] = menuItem.children;
                                    }
                                    const parentItem = menuStructureIdMap
                                        ? menuStructureIdMap[actualItem.parentId as string]
                                        : undefined;
                                    const useParentItem = isItemTypeTabsWithReferences(parentItem?.entityType);
                                    const entityType = useParentItem ? parentItem?.entityType : actualItem.entityType;
                                    const parentEntityName = useParentItem ? parentItem?.entityName : '';
                                    const url = generateRoute({...actualItem, parentEntityName});

                                    if (!url.length) {
                                        return null;
                                    }
                                    return (
                                        <Route
                                            key={url}
                                            path={url}
                                            render={props => {
                                                const TemplateComponent = getPageTemplate(entityType as string);
                                                const breadcrumbs = parseBreadcrumbsFromMenuItem(
                                                    menuStructureIdMap,
                                                    actualItem.itemTitle,
                                                    actualItem.entityType,
                                                    actualItem.entityName,
                                                    parentItem,
                                                    currentLocationUrl,
                                                );

                                                return (
                                                    <>
                                                        <Breadcrumbs
                                                            breadcrumbs={breadcrumbs}
                                                        />
                                                        <TemplateComponent
                                                            {...props}
                                                            url={url}
                                                            entityName={actualItem.entityName}
                                                            entityType={actualItem.entityType}
                                                            parentItem={parentItem}
                                                        />
                                                    </>
                                                );
                                            }}
                                        />
                                    );
                                })}
                                {menuStructure?.find(item => item.entityName === URL_SVC_CP.substring(1)) && (
                                    <Route
                                        path={URL_SVC_CP_PERIOD}
                                        render={props => (
                                            <TablePage
                                                {...props}
                                                entityName={URL_SVC_CP_PERIOD.substring(1)}
                                                url={URL_SVC_CP_PERIOD}
                                            />
                                        )}
                                    />
                                )}
                                {menuStructure?.find(item => item.entityName === URL_SVC_RISK.substring(1)) && (
                                    <Route
                                        path={URL_SVC_RISK_PERIOD}
                                        render={props => (
                                            <TablePage
                                                {...props}
                                                entityName={URL_SVC_RISK_PERIOD.substring(1)}
                                                url={URL_SVC_RISK_PERIOD}
                                            />
                                        )}
                                    />
                                )}
                            </Switch>
                        </section>
                        <Footer />
                        <ModalRoot />
                        <DraggableFrameRoot />
                    </div>
                </div>
            </WebsocketsContext.Provider>
        </Suspense>
    );
};

export const Layout = LayoutComponent;
