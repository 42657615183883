export enum ReportConfigurationTabKey {
    REPORT_CONFIGURATOR = 'reportConfigurator',
    REPORT_TABLE_OF_CONTENTS = 'reportTableOfContents',
    REPORT_PROPERTIES_SETTINGS = 'reportPropertiesSettings',
    REPORT_TABLE_SETTINGS = 'reportTableSettings',
    REPORT_HEADER_SETTINGS = 'reportHeaderSettings',
    REPORT_DD_REFERENCES = 'reportDdReferences',
    REPORT_DD_FILTERS = 'reportDdFilters',
    REPORT_DD_PARAMETERS = 'reportDdParameters',
    REPORT_COMPARISON = 'reportComparison',
    REPORT_KS_SETTINGS = 'reportKSSettings',
    REPORT_DOWNLOAD_SETTINGS ='reportDownloadSettings',
}

export enum ReportConfigurationFormName {
    CONFIGURATOR = 'configurator',
    PROPERTIES_SETTINGS = 'propertiesSettings',
    TABLE_SETTINGS = 'tableSettings',
    SORTING_SETTINGS = 'sortingSettings',
    GROUPING_SETTINGS = 'groupingSettings',
    FORMAT_SETTINGS = 'formatSettings',
    REPORT_MENU = 'reportMenu',
    HEADER_SETTINGS = 'headerSettings',
    RESERVED_COLUMNS_SETTINGS = 'reservedColumns',
    REPORT_COMPARISON = 'reportComparisons',
    REPORT_KS_SETTINGS = 'crColumns',
    REPORT_DOWNLOAD_SETTINGS = 'exportTo',
    EXCEL_TABLE_HEADER_STRUCTURE_LOADER = 'excelTableHeaderStructure',
}

export const reportConfigurationFormTitles = {
    [ReportConfigurationFormName.CONFIGURATOR]: 'Конфигуратор отчета',
    [ReportConfigurationFormName.TABLE_SETTINGS]: 'Настройка табличной части',
    [ReportConfigurationFormName.FORMAT_SETTINGS]: 'Правила форматирования',
    [ReportConfigurationFormName.SORTING_SETTINGS]: 'Настройки сортировки',
    [ReportConfigurationFormName.GROUPING_SETTINGS]: 'Настройки группировки',
    [ReportConfigurationFormName.REPORT_MENU]: 'Настройка оглавления',
    [ReportConfigurationFormName.HEADER_SETTINGS]: 'Настройка заголовка отчёта',
    [ReportConfigurationFormName.RESERVED_COLUMNS_SETTINGS]: 'Настройка служебных столбцов',
    [ReportConfigurationFormName.PROPERTIES_SETTINGS]: 'Настройка параметров отчета',
    [ReportConfigurationFormName.REPORT_KS_SETTINGS]: 'Настройка КС',
    [ReportConfigurationFormName.REPORT_COMPARISON]: 'Настройка сравнения',
    [ReportConfigurationFormName.REPORT_DOWNLOAD_SETTINGS]: 'Настройка скачивания',
    [ReportConfigurationFormName.EXCEL_TABLE_HEADER_STRUCTURE_LOADER]: 'Настройка шапки табличной части через Excel',
} as const;

export const REPORT_CONFIGURATION_NAME = 'administration.report.configuration';
export const PROPERTY_SETTINGS_ENTITY_NAME = 'administration.property';
export const SVC_RISK_ENTITY_NAME = 'analysis2.org.risk';
export enum TypeGroup {
    OTHER = 'OTHER',
    NUMBER = 'NUMBER',
    DATE = 'DATE',
}

export const ReportConfigurationNotNullableFormNames = [
    ReportConfigurationFormName.REPORT_COMPARISON,
    ReportConfigurationFormName.REPORT_KS_SETTINGS,
];

export const URL_TABLE_REPORT_MENU_ITEMS = '/valueLists/TABLE_REPORT_MENU_ITEMS_UNIQUE';

export type ReportTableColumnFunction = 'url' | 'dd';

export const DRILLDOWN_ID_PARAM_KEY = 'drillDownId';

// === Фильтрация внутри отчётов ===

export enum FilterValueType {
    text = 'text',
    number = 'number',
    date = 'date'
}

export enum FilterConditionsLookupSegmentValueKeys {
    enabledForNumeric = 'enabled_for_numeric',
    enabledForDate = 'enabled_for_date',
    enabledForText = 'enabled_for_text'
}

export const FilterValueToDffSegmentMapping = {
    [FilterValueType.date]: FilterConditionsLookupSegmentValueKeys.enabledForDate,
    [FilterValueType.number]: FilterConditionsLookupSegmentValueKeys.enabledForNumeric,
    [FilterValueType.text]: FilterConditionsLookupSegmentValueKeys.enabledForText,
};
