import React from 'react';

import {useAppSelector} from 'store/config/hooks';
import {selectModalData, ModalComponentInterface} from 'store/slices/modals-slice';

import {FilterModal} from './filter-modal';

export const FilterModalContainer: React.FunctionComponent<ModalComponentInterface> = ({
    onClose,
}: ModalComponentInterface) => {
    const entityName = useAppSelector(state => selectModalData(state)?.entityName) || '';
    return (
        <FilterModal
            entityName={entityName}
            onClose={onClose}
        />
    );
};
