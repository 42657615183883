import {EntityType} from 'shared/constants/entities';
import {MenuStructureItem} from 'store/slices/menu-slice/menu-slice-types';

import {BreadcrumbItem} from '../breadcrumb';

const SHARED_BREADCRUMBS = [
    {
        name: 'Главная страница',
        path: '/',
    },
];

export const parseBreadcrumbsFromMenuItem = (
    menuStructureIdMap: Record<string, MenuStructureItem> | null,
    menuItemName?: string,
    menuItemType?: EntityType,
    menuItemEntityName?: string,
    parentItem?: MenuStructureItem,
    url?: string,
): BreadcrumbItem[] => {
    let breadcrumbs: BreadcrumbItem[] = [...SHARED_BREADCRUMBS];

    // in case we on a page with crumbs support (e.g. TABLE) and should display crumbless component (e.g. TABLE_REPORT)
    const isUrlContainsId = url?.match(RegExp(`\\/${menuItemEntityName}\\/(.*)`));
    if (isUrlContainsId) {
        return [];
    }

    // add section breadcrumbs
    if (parentItem) {
        // as tabs are 3-rd level menu item their section is parent of parent
        if (menuStructureIdMap && parentItem.parentId && parentItem?.entityType === EntityType.TABS_WITH_REFERENCES) {
            breadcrumbs.push({name: menuStructureIdMap[parentItem.parentId]?.itemTitle});
        }
        breadcrumbs.push({name: parentItem.itemTitle});
    }
    switch (menuItemType) {
    case EntityType.JASPER_REPORT:
    case EntityType.TABLE_REPORT:
        breadcrumbs = [];
        break;
    default:
        if (menuItemName && parentItem?.entityType !== EntityType.TABS_WITH_REFERENCES) {
            breadcrumbs.push({name: menuItemName});
        }
    }

    return breadcrumbs;
};
