import {EntityType} from 'shared/constants/entities';
import {createReducer} from 'store/config/creators';
import {Action} from 'store/config/types';

import {DataState} from '../data';
import {MetadataActions} from './metadata-constants';
import {
    FormDraftMetadataPayload,
    MetadataResetPayload,
    MetadataSetAllPayload,
    MetadataSetPayload,
    MetadataState,
} from './metadata-types';

const initialState: MetadataState = {};

export const metadataReducer = createReducer(initialState, {
    [MetadataActions.SET_ALL_METADATA]: (state: MetadataState, {payload}: Action<MetadataSetAllPayload>) => {
        const metadata = (() => {
            const data = {} as any;
            payload.metadata.forEach(entry => {
                const {name, viewType = 'default'} = entry;
                if (name) {
                    if (!data[name]) data[name] = {};
                    data[name][viewType] = entry;
                }
            });
            return data;
        })();
        return {...state, ...metadata};
    },
    [MetadataActions.SET_METADATA]: (
        state: MetadataState,
        {payload}: Action<MetadataSetPayload>,
    ) => ({
        ...state,
        [payload.entityName]: {
            ...state[payload.entityName],
            [payload.entityType]: {
                ...payload.metadata,
            },
        },
    }),
    [MetadataActions.RESET_METADATA]: (
        state: DataState,
        {payload}: Action<MetadataResetPayload>,
    ) => ({
        ...state,
        [payload.entityName]: {
            ...(state[payload.entityName] || {}),
            loading: payload.loading,
            [payload.entityType]: undefined,
        },
    }),
    [MetadataActions.SET_FORM_DRAFT_METADATA]: (
        state: MetadataState,
        {payload}: Action<FormDraftMetadataPayload>,
    ) => ({
        ...state,
        [payload.entityName]: {
            ...state[payload.entityName],
            [EntityType.FORM]: {
                ...state[payload.entityName]?.[EntityType.FORM],
                isDraft: payload.isDraft,
            },
        },
    }),
});
