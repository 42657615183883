export const REPORT_PROPERTIES_SETTINGS_FORM_DATA_KEY = 'queryParameters';

export enum ReportPropertiesFieldTypes {
    INTEGER = 'INTEGER',
    NUMBER = 'NUMBER',
    STRING = 'STRING',
    DATE = 'DATE',
    DATETIME = 'DATETIME',
    BOOLEAN = 'BOOLEAN',
    VALUE_SET = 'VALUESET',
    MULTI_VALUE_SET = 'MULTIVALUESET',
    SQLVALUESET = 'SQLVALUESET',
    SQLMULTIVALUESET = 'SQLMULTIVALUESET',
}

export enum ReportPropertiesSettingsFormFields {
    paramName = 'paramName',
    columnName = 'columnName',
    reportTitle = 'reportTitle',
    visible = 'visible',
    required = 'required',
    type = 'type',
    // не трогать значение, так на бэке
    valuesetLookupType = 'valuesetLookupType',
    sqlValueset = 'sqlValueset',
    defaultType = 'defaultType',
    defaultValue = 'defaultValue',

    // доп поля, которые зависят от type, и в итоге трансформируются в defaultValue при сохранении
    defaultValueDate = 'defaultValueDate',
    defaultValueDateTime = 'defaultValueDateTime',
    defaultValueInteger = 'defaultValueInteger',
    defaultValueString = 'defaultValueString',
    defaultValueBoolean = 'defaultValueBoolean',
    defaultValueValueSet = 'defaultValueValueSet',
    defaultValueMultiValueSet = 'defaultValueMultiValueSet',
    defaultValueContext = 'defaultValueContext',
    defaultValueSqlValueSet = 'defaultValueSqlValueSet',

    format = 'format',
    ordinal = 'ordinal',
}

export interface ReportPropertiesSettingsDto {
    queryParameters: Record<ReportPropertiesSettingsFormFields, string | boolean>[];
}

export type ReportPropertySetting = ReportPropertiesSettingsDto['queryParameters'][0];

export const ReportPropertiesSettingsDefaultValueFields = [
    ReportPropertiesSettingsFormFields.defaultValueDate,
    ReportPropertiesSettingsFormFields.defaultValueDateTime,
    ReportPropertiesSettingsFormFields.defaultValueBoolean,
    ReportPropertiesSettingsFormFields.defaultValueInteger,
    ReportPropertiesSettingsFormFields.defaultValueString,
    ReportPropertiesSettingsFormFields.defaultValueContext,
    ReportPropertiesSettingsFormFields.defaultValueValueSet,
    ReportPropertiesSettingsFormFields.defaultValueMultiValueSet,
    ReportPropertiesSettingsFormFields.defaultValueSqlValueSet,
];

export const ReportPropertiesSettingsFieldsResetDependencies = {
    [ReportPropertiesSettingsFormFields.type]: [
        ...ReportPropertiesSettingsDefaultValueFields,
        ReportPropertiesSettingsFormFields.defaultType,
        ReportPropertiesSettingsFormFields.valuesetLookupType,
        ReportPropertiesSettingsFormFields.format,
    ],
    [ReportPropertiesSettingsFormFields.defaultType]:
        [
            ...ReportPropertiesSettingsDefaultValueFields,
            ReportPropertiesSettingsFormFields.valuesetLookupType,
        ],
    [ReportPropertiesSettingsFormFields.valuesetLookupType]:
        [ReportPropertiesSettingsFormFields.defaultValueValueSet,
            ReportPropertiesSettingsFormFields.defaultValueMultiValueSet,
        ],
};

export const FormatLookupSublistCodesMap = {
    [ReportPropertiesFieldTypes.DATE]: 'TABLE_REPORT_FORMAT_DATE',
    [ReportPropertiesFieldTypes.DATETIME]: 'TABLE_REPORT_FORMAT_DATE',
    [ReportPropertiesFieldTypes.INTEGER]: 'TABLE_REPORT_FORMAT_NUMBER',
    [ReportPropertiesFieldTypes.NUMBER]: 'TABLE_REPORT_FORMAT_NUMBER',
};
