import Spin from 'antd/es/spin';
import cn from 'classnames';
import moment from 'moment';
import React from 'react';
import {Link} from 'react-router-dom';

import {CustomTable, customTableUtils} from 'components/@common/widgets/custom-table';
import {ModalOpenerComponent, ModalOpenerComponentRef} from 'components/modal-opener-component';
import {DATE_WITH_TIME_DOTS_SECONDS_24} from 'shared/constants/date-format';
import {prettifyXml} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {aisSelectors, aisSliceActions} from 'store/slices/ais-slice/ais-slice';
import {loadAisRequests} from 'store/slices/ais-slice/ais-slice-thunks';
import {AisRegisterDocumentDto} from 'store/slices/ais-slice/ais-slice-types';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

import './ais-request-modal.less';

export interface RequestContentViewModalProps {
    content: string;
    title?: string;
    componentTitle?: string;
}
const RequestContentViewModal = ({
    content,
    title = 'Содержание запроса',
    componentTitle = 'Просмотреть содержание запроса',
}: RequestContentViewModalProps) => (
    <ModalOpenerComponent
        modalProps={{
            title,
            centered: true,
            destroyOnClose: true,
            forceRender: false,
        }}
        component={(
            <div className={cn('request-content-view-modal__link')}>
                {componentTitle}
            </div>
        )}
    >
        <div className={cn('request-content-view-modal__content')}>
            {content ? prettifyXml(content) : 'Содержание отсутствует'}
        </div>
    </ModalOpenerComponent>
);

export interface AisRequestModalProps {
    registerDocument: AisRegisterDocumentDto;
}

export const AisRequestModal = React.forwardRef<ModalOpenerComponentRef, AisRequestModalProps>(({
    registerDocument,
}: AisRequestModalProps, ref) => {
    const dispatch = useAppDispatch();

    const {aisDocId} = registerDocument;

    const {resetRequests} = aisSliceActions;
    const {requestsSelectors} = aisSelectors;

    const aisRequests = useAppSelector(requestsSelectors.selectAll);

    const isLoadingRequests = useAppSelector(s => selectIsThunkPending(s, loadAisRequests.typePrefix));

    return (
        <ModalOpenerComponent
            ref={ref}
            onShow={() => {
                if (aisDocId) {
                    dispatch(loadAisRequests({
                        aisDocId,
                    }));
                }
            }}
            afterModalClose={() => {
                dispatch(resetRequests());
            }}
            controlLabels={{
                cancel: 'Закрыть',
            }}
            hideControls={{
                all: true,
            }}
            modalProps={{
                title: 'Запросы по 4 сценарию',
                width: '80vw',
                centered: true,
                forceRender: false,
                destroyOnClose: true,
            }}
        >
            <Spin spinning={isLoadingRequests}>
                <CustomTable
                    dataSource={aisRequests}
                    onColumn={col => ({
                        ...customTableUtils.setHeaderStyles({
                            whiteSpace: 'pre',
                            textAlign: 'center',
                        }),
                        ...col,
                        render: col.render ?? (cellValue => <div style={{textAlign: 'center'}}>{cellValue}</div>),
                    })}
                    columns={[
                        {
                            title: 'Идентификатор',
                            dataIndex: 'ticketId',
                            render: v => <Link to="/ais3.monitor.services">{v}</Link>,
                        },
                        {
                            title: 'Дата и время запроса',
                            dataIndex: 'requestDate',
                            render: v => (
                                <div className={cn('text-center')}>
                                    {moment(v).format(DATE_WITH_TIME_DOTS_SECONDS_24)}
                                </div>
                            ),
                        },
                        {
                            title: 'Состав запроса',
                            dataIndex: 'requestBody',
                            render: v => <RequestContentViewModal content={v} />,
                        },
                        {
                            title: 'Состав ответа',
                            dataIndex: 'responseBody',
                            render: v => (
                                <RequestContentViewModal
                                    content={v}
                                    title="Содержание ответа"
                                    componentTitle="Просмотреть содержание ответа"
                                />
                            ),
                        },
                    ]}
                />
            </Spin>
        </ModalOpenerComponent>
    );
});
