import {MetaActionInfo, MetaActionType, RequestType} from 'modules/metadata';

import {ERequestStatuses} from '../../request-form.constants';
import {ACTIONS_NAMES} from '../metadata.constants';

export const DEFAULT_ACTIONS = {
    [ACTIONS_NAMES.BUTTON_SAVE]: {
        actionType: MetaActionType.BUTTON_SAVE_FORM,
        actionTitle: 'Сохранить',
        actionCode: 'SAVE_FORM',
        actionIcon: 'SaveOutlined',
        referenceUrl: 'requests',
        requestType: RequestType.POST,
        visible: 'creation',
        shouldDefaultRequestSave: true,
    },
};

export const STATUSES_ACTIONS = {
    [ERequestStatuses.REJECT]: {
        actionType: MetaActionType.BUTTON_CHANGE_REQUEST_STATUS,
        actionTitle: 'Отменить запрос',
        actionCode: 'REJECT_REQUEST',
        actionIcon: undefined,
        status: ERequestStatuses.REJECT,
        entityName: 'requests',
        linkField: 'id',
    },
    [ERequestStatuses.TAKE_TO_WORK]: {
        actionType: MetaActionType.BUTTON_CHANGE_REQUEST_STATUS,
        actionTitle: 'В работу',
        actionCode: 'TAKE_TO_WORK_REQUEST',
        actionIcon: undefined,
        status: ERequestStatuses.TAKE_TO_WORK,
        entityName: 'requests',
        linkField: 'id',
    },
    [ERequestStatuses.CLOSE_REQUEST]: {
        actionType: MetaActionType.BUTTON_CHANGE_REQUEST_STATUS,
        actionTitle: 'Закрыть',
        actionCode: 'CLOSE_REQUEST',
        actionIcon: undefined,
        status: ERequestStatuses.CLOSE_REQUEST,
        entityName: 'requests',
        linkField: 'id',
    },
    [ERequestStatuses.REPLY]: {
        actionType: MetaActionType.BUTTON_CHANGE_REQUEST_STATUS,
        actionTitle: 'Ответить',
        actionCode: 'REPLY_REQUEST',
        actionIcon: undefined,
        status: ERequestStatuses.REPLY,
        entityName: 'requests',
        linkField: 'id',
    },
    [ERequestStatuses.SEND_FOR_APPROVAL]: {
        actionType: MetaActionType.BUTTON_CHANGE_REQUEST_STATUS,
        actionTitle: 'Отправить на согласование',
        actionCode: 'SEND_FOR_APPROVAL_REQUEST',
        actionIcon: undefined,
        status: ERequestStatuses.SEND_FOR_APPROVAL,
        entityName: 'requests',
        linkField: 'id',
    },
    // DEV Запрос имеет два параметра id, answer.
    [ERequestStatuses.ANSWER_PROLONGATION]: {
        actionType: MetaActionType.BUTTON_CHANGE_REQUEST_STATUS,
        actionTitle: 'Согласование или отклонение нового срока',
        actionCode: 'NEW_DATE_PROLONGATION',
        actionIcon: undefined,
        status: ERequestStatuses.ANSWER_PROLONGATION,
        entityName: 'requests',
        linkField: 'id',
    },
    [ERequestStatuses.APPROVE]: {
        actionType: MetaActionType.BUTTON_CHANGE_REQUEST_STATUS,
        actionTitle: 'Согласовать',
        actionCode: 'APPROVE_REQUEST',
        actionIcon: undefined,
        status: ERequestStatuses.APPROVE,
        entityName: 'requests',
        linkField: 'id',
    },
};

export const CHANGE_PART_FORM_ACTIONS = {
    [ACTIONS_NAMES.ANSWER]: {
        actionType: MetaActionType.OPEN_MODAL,
        actionTitle: 'Сформировать ответ',
        actionCode: 'REPLY_REQUEST',
        actionIcon: undefined,
        entityName: 'requests',
        modalName: 'formModal',
        modalEntityName: 'answer',
        visible: 'viewing',
    },
    [ACTIONS_NAMES.EDIT_ANSWER]: {
        actionType: MetaActionType.OPEN_MODAL,
        actionTitle: 'Редактировать ответ',
        actionCode: 'EDIT_ANSWER',
        actionIcon: undefined,
        entityName: 'requests',
        modalName: 'formModal',
        modalEntityName: 'edit-answer',
        visible: 'viewing',
    },
    [ACTIONS_NAMES.EDIT_ANSWER_RESPONSE]: {
        actionType: MetaActionType.OPEN_MODAL,
        actionTitle: 'Редактировать ответ',
        actionCode: 'EDIT_ANSWER_RESPONSE',
        actionIcon: undefined,
        entityName: 'response',
        modalName: 'formModal',
        modalEntityName: 'edit-answer-response',
        visible: 'viewing',
    },
    [ACTIONS_NAMES.LEAVE_COMMENT]: {
        actionType: MetaActionType.OPEN_MODAL,
        actionTitle: 'Оставить комментарий',
        actionCode: 'LEAVE_COMMENT',
        actionIcon: 'CommentOutlined',
        entityName: 'requests',
        modalName: 'formModal',
        modalEntityName: 'leave-comment',
        visible: 'viewing',
    },
    [ACTIONS_NAMES.LEAVE_COMMENT_RESPONSE]: {
        actionType: MetaActionType.OPEN_MODAL,
        actionTitle: 'Оставить комментарий',
        actionCode: 'LEAVE_COMMENT_RESPONSE',
        actionIcon: 'CommentOutlined',
        entityName: 'response',
        modalName: 'formModal',
        modalEntityName: 'leave-comment-response',
        visible: 'viewing',
    },
    [ACTIONS_NAMES.EXTENSION_REQUEST]: {
        actionType: MetaActionType.OPEN_MODAL,
        actionTitle: 'Запрос на продление срока',
        actionCode: 'ASK_PROLONGATION',
        actionIcon: undefined,
        entityName: 'requests',
        modalName: 'formModal',
        modalEntityName: 'extension-request',
        visible: 'viewing',
    },
    [ACTIONS_NAMES.UPDATE_RESPONSE_DATE]: {
        actionType: MetaActionType.OPEN_MODAL,
        actionTitle: 'Исправление даты срока ответа',
        actionCode: 'CORRECT_DEADLINE_DATE',
        actionIcon: undefined,
        entityName: 'requests',
        modalName: 'formModal',
        modalEntityName: 'update-response-date',
        visible: 'viewing',
    },
    [ACTIONS_NAMES.APPOINTMENT]: {
        actionType: MetaActionType.OPEN_MODAL,
        actionTitle: 'Назначить ответственного',
        actionCode: 'APPOINTMENT',
        actionIcon: undefined,
        entityName: 'requests',
        modalName: 'formModal',
        modalEntityName: 'appointment-user-meta',
        visible: 'viewing',
    },
};

export const REQUESTS_ACTIONS = {
    ...DEFAULT_ACTIONS,
    ...STATUSES_ACTIONS,
    ...CHANGE_PART_FORM_ACTIONS,
};

export const ANSWER_ACTIONS = {
    ...REQUESTS_ACTIONS,
};

export const FORM_ACTIONS = {
    REQUESTS: REQUESTS_ACTIONS as Record<string, MetaActionInfo>,
    ANSWER: ANSWER_ACTIONS as Record<string, MetaActionInfo>,
};
