import crypto from 'crypto';

import {AsyncThunk, unwrapResult} from '@reduxjs/toolkit';
import {intersection, set} from 'lodash';

import {Dispatch} from 'store/config/types';

import {THUNK_CACHE_CONFIG} from './thunk-cache-constants';
import {
    ThunkCache, ThunkCacheItem, ThunkCacheOptions, ThunkDispatched, ThunkDispatchedResult,
} from './thunk-cache-types';

const thunkCache: ThunkCache = {};

const getArgHash = <TArg>(arg: TArg) => {
    const keyHash = crypto.createHash('md5').update(JSON.stringify(arg ?? {})).digest('hex');
    return keyHash;
};

export const invalidateThunkCache = (tags: string[]) => {
    Object.keys(thunkCache).forEach(typePrefix => {
        const hashesForTypePrefix = thunkCache[typePrefix];
        if (!hashesForTypePrefix) return;

        Object.keys(hashesForTypePrefix).forEach(hash => {
            const cacheItem = thunkCache[typePrefix][hash];
            if (cacheItem) {
                const {providesTags} = cacheItem.options ?? {};
                if (providesTags) {
                    const providedTags = Array.isArray(providesTags) ? providesTags : [providesTags];
                    if (intersection(providedTags, tags).length) {
                        delete thunkCache[typePrefix][hash];
                    }
                }
            }
        });
    });
};

export const removeExpiredThunkCache = () => {
    const now = new Date().getTime();

    Object.keys(thunkCache).forEach(typePrefix => {
        const hashesForTypePrefix = thunkCache[typePrefix];
        if (!hashesForTypePrefix) return;

        Object.keys(hashesForTypePrefix).forEach(hash => {
            const cacheItem = thunkCache[typePrefix][hash];
            if (cacheItem) {
                if (cacheItem?.options?.expire !== 'never') {
                    const {DEFAULT_EXPIRE} = THUNK_CACHE_CONFIG;
                    const expire = cacheItem?.options?.expire ?? DEFAULT_EXPIRE;
                    if (now - cacheItem.timestamp.getTime() > expire) {
                        delete thunkCache[typePrefix][hash];
                    }
                }
            }
        });
    });
};

export const setThunkCache = <TData, TArg>(
    thunk: AsyncThunk<TData, TArg, any>,
    arg: TArg | undefined,
    thunkDispatched: ThunkDispatched<TData, TArg | undefined>,
    action: ThunkDispatchedResult<TData, TArg | undefined>,
    options?: ThunkCacheOptions,
) => {
    const keyHash = getArgHash(arg);

    set(thunkCache, [thunk.typePrefix, keyHash], {
        action,
        thunk: thunkDispatched,
        timestamp: new Date(),
        options,
    } as ThunkCacheItem);
};

export const cachedThunk = <TData, TArg> (
    thunk: AsyncThunk<TData, TArg, any>,
    arg?: TArg,
    options?: ThunkCacheOptions,
) => (
        dispatch: Dispatch,
    ) => {
        removeExpiredThunkCache();

        const cacheValue = thunkCache[thunk.typePrefix]?.[getArgHash(arg)];

        if (!cacheValue) {
            const r = dispatch(thunk(arg as any));
            r.then(res => {
                if (!(res as any).error) {
                    setThunkCache<TData, TArg>(thunk, arg, r, res, options);
                }
            });
            return r;
        }

        dispatch({type: `${thunk.typePrefix}/pending`, meta: cacheValue.action.meta});

        const thunkInCache = cacheValue.thunk;

        const cachePromise = new Promise(resolve => {
            const r = {
                type: `${thunk.typePrefix}/fulfilled`,
                payload: cacheValue.action.payload as TData,
                meta: cacheValue.action.meta,
            };

            setTimeout(() => {
                dispatch(r);
                resolve(r);
            }, 100);
        });

        return Object.assign(cachePromise, {
            abort: thunkInCache.abort,
            requestId: thunkInCache.requestId,
            arg: thunkInCache.arg,
            unwrap: function unwrap() {
                return thunkInCache.then(unwrapResult);
            },
        }) as ThunkDispatched<TData, TArg>;
    };
