import {FormInstance} from 'antd/es/form';
import {useEffect, useState} from 'react';

export interface UseFormInitializerArgs<D> {
    data: D;
    form: FormInstance;
    onReset?: () => void;
    onDataSetFormatter?: (data: D) => any;
}

export const useFormInitializer = <V=any, D=any>(args: UseFormInitializerArgs<D>) => {
    const {
        data,
        form,
        onReset,
        onDataSetFormatter,
    } = args;

    const [initialValues, setInitialValues] = useState<V | undefined>(undefined);

    useEffect(() => {
        const formattedData = onDataSetFormatter ? onDataSetFormatter(data) : data;
        if (formattedData) {
            setInitialValues(formattedData);
        }
    }, [data]);

    useEffect(() => {
        form.resetFields();
        onReset?.();
    }, [initialValues]);

    return {
        initialValues,
    };
};
