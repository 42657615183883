import {Drawer} from 'antd';
import React from 'react';

import {selectMetadata} from 'modules/metadata';
import {ReactComponent as CloseIcon} from 'shared/assets/close.svg';
import {EntityType} from 'shared/constants/entities';
import {useAppSelector} from 'store/config/hooks';
import {selectModalData, ModalComponentInterface} from 'store/slices/modals-slice';

import {FormCloseWarning} from '../form-close-warning';
import {FormContainer} from '../form-container/form-container';

import './form-drawer.less';

interface IFormDrawerProps extends ModalComponentInterface {
}

export const FormDrawer = ({onClose}: IFormDrawerProps) => {
    const entityName = useAppSelector(state => selectModalData(state)?.entityName) || '';
    const metadata = useAppSelector(selectMetadata(entityName, EntityType.FORM));
    return (
        <FormCloseWarning
            entityName={entityName}
            metadata={metadata}
            onClose={onClose}
        >
            {(handleClose, visible) => (
                <Drawer
                    visible={visible}
                    onClose={handleClose}
                    title={metadata?.title}
                    className="form-drawer"
                    closeIcon={<CloseIcon className="close-icon" />}
                >
                    <FormContainer
                        entityName={entityName}
                        onClose={handleClose}
                        hideTitle
                    />
                </Drawer>
            )}
        </FormCloseWarning>
    );
};
