import {Card} from 'antd';
import cn from 'classnames';
import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';

import {Spinner} from 'components/spinner';
import {selectDashboardEntityData} from 'modules/data/data-selectors';
import {loadMetadata, selectMetadata} from 'modules/metadata';
import {mapDirectionLayout, mapWidgetsLayout} from 'pages/svc-widget-page/svc-main-page/svc-main-page.utils';
import {EntityType} from 'shared/constants/entities';
import {useAppSelector} from 'store/config/hooks';
import {selectMenuStructure} from 'store/slices/menu-slice';

import {DictionaryLayoutRow} from '../components/dictionaries-layout-row';
import {WidgetLayoutRow} from '../components/widget-layout-row';
import {SvcMainPageSectionTitle} from './svc-main-page-section-title/svc-main-page-section-title';

import './svc-main-page.less';

interface SvcMainPageProps {
    entityName: string;
}

export const SvcMainPage: React.FC<SvcMainPageProps> = ({entityName}: SvcMainPageProps) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const menuStructure = useAppSelector(selectMenuStructure);
    const metadata = useAppSelector(selectMetadata(entityName, EntityType.WIDGETS));
    const dashboardData = useAppSelector(selectDashboardEntityData(entityName));

    const directoriesLayout = useMemo(() => mapDirectionLayout(metadata?.svc?.dictionariesLayout, menuStructure),
        [metadata?.svc?.dictionariesLayout, menuStructure]);

    const widgetsLayout = useMemo(() => mapWidgetsLayout(metadata?.svc?.widgetsLayout, menuStructure),
        [metadata?.svc?.widgetsLayout, menuStructure]);

    useEffect(() => {
        (async () => {
            if (!metadata) {
                setIsLoading(true);
                dispatch(loadMetadata(entityName, EntityType.WIDGETS));
                setIsLoading(false);
            }
        })();
    });

    return (
        <div className={cn('svc-main-page')}>
            {!isLoading ? (
                <Card>
                    {metadata?.svc?.widgetsTitle && (<SvcMainPageSectionTitle title={metadata?.svc?.widgetsTitle} />)}
                    {widgetsLayout?.map(widgetLayout => (
                        <WidgetLayoutRow
                            widgetLayout={widgetLayout}
                            fields={metadata?.fields}
                            data={dashboardData}
                            entityName={entityName}
                        />
                    ))}
                    {metadata?.svc?.dictionariesTitle
                        && (<SvcMainPageSectionTitle title={metadata?.svc?.dictionariesTitle} />)}
                    {directoriesLayout?.map(dictionaryLayout => (
                        <DictionaryLayoutRow
                            dictionaryLayout={dictionaryLayout}
                        />
                    ))}
                </Card>
            ) : (
                <div className="svc-main-page-spinner">
                    <Spinner />
                </div>
            )}
        </div>
    );
};
