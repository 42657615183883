import React from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {resetLoadedData} from 'modules/data';
import {selectMetadata} from 'modules/metadata';
import {EntityType} from 'shared/constants/entities';
import {useAppSelector} from 'store/config/hooks';
import {ModalComponentInterface} from 'store/slices/modals-slice';

import {FormModalWrapper} from '../form-modal/form-modal-wrapper';
import {FormEditContent} from './form-edit-content';

import '../form-modal/form-modal.less';

interface FormEditEntityProps extends ModalComponentInterface {
    entityName: string;
    entityType?: EntityType;
    referenceUrl?: string;
    forceHasTitle?: boolean;
    resetEntityName?: string;
    resetEntityType?: EntityType;
}

export const FormEditModal: React.FunctionComponent<FormEditEntityProps> = ({
    onClose,
    entityName,
    entityType = EntityType.FORM,
    referenceUrl,
    forceHasTitle,
    resetEntityName,
    resetEntityType,
}: FormEditEntityProps) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const metadataSelector = selectMetadata(entityName, entityType);
    const [shouldResetTable, setShouldResetTable] = React.useState(false);

    const meta = useAppSelector(state => metadataSelector(state));

    const handleModalClose = () => {
        onClose();
        if (shouldResetTable && meta.tableEntityName) dispatch(resetLoadedData(meta.tableEntityName, EntityType.TABLE));
        setShouldResetTable(false);
    };

    return (
        <FormModalWrapper
            hasTitle={forceHasTitle ? true : !!meta?.title}
            onClose={handleModalClose}
            entityName={entityName}
        >
            {handleClose => (
                <FormEditContent
                    url={referenceUrl || ''}
                    entityName={entityName}
                    entityType={entityType}
                    onClose={handleClose}
                    resetEntityName={resetEntityName || location.pathname.slice(1)}
                    resetEntityType={resetEntityType}
                    setShouldResetTable={setShouldResetTable}
                />
            )}
        </FormModalWrapper>
    );
};
