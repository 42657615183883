import {Button as AntButton} from 'antd';
import cn from 'classnames';
import React, {ReactNode} from 'react';

import {DynamicIcon} from 'components/dynamic-icon';
import {ButtonType} from 'modules/metadata/metadata-types';

interface ActionButtonProps {
    children: ReactNode;
    actionIcon?: string;
    onClick?: React.MouseEventHandler<HTMLElement>;
    buttonType?: ButtonType;
}

/**
 * Добавляет любому action css стили и иконку
 * @param actionIcon назывние иконки
 * @param children текст в кнопке
 * @param buttonType тип кнопки primary(синяя), default(серая) и т. д.
 * @param props
 */
export const ActionButton: React.FunctionComponent<ActionButtonProps> = ({
    actionIcon,
    children,
    buttonType,
    ...props
}: ActionButtonProps) => (
    <AntButton
        className={cn('table-action', buttonType)}
        {...props}
    >
        {actionIcon && ((
            <div className="action-icon">
                <DynamicIcon
                    type={actionIcon}
                    style={{fontSize: 16}}
                />
            </div>
        ))}
        {children}
    </AntButton>
);
