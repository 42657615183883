import {
    ERROR_MAX_PAGE_NUMBER_EXCEEDED,
    ERROR_MIN_PAGE_LIMIT,
    ERROR_NOT_NUMERIC_VALUE,
} from 'components/@common/widgets/page-jumper/page-jumper.constants';
import {isNumeric} from 'shared/utils';

export const validatePageValue = (value: string, totalPages: number): string | null => {
    if (!isNumeric(value)) {
        return ERROR_NOT_NUMERIC_VALUE;
    }
    if (+value > totalPages) {
        return ERROR_MAX_PAGE_NUMBER_EXCEEDED;
    }
    if (+value < 1) {
        return ERROR_MIN_PAGE_LIMIT;
    }

    return null;
};
