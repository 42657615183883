import {useMemo} from 'react';

import {selectIsFormEntityDataEditable} from 'modules/data/data-selectors';
import {ActionVisibility} from 'modules/metadata/metadata-constants';
import {TVisibleMetaActionProp} from 'modules/metadata/metadata-types';
import {useAppSelector} from 'store/config/hooks';

import {FormMode} from '../form-types';

export const useFormElementsAvailability = (mode: FormMode, entityName?: string) => {
    const editableSelector = selectIsFormEntityDataEditable(entityName!);
    let isEditable = useAppSelector(state => editableSelector(state));

    const currentActionsVisibleState: TVisibleMetaActionProp = useMemo(
        () => {
            if (mode === FormMode.EDIT && isEditable === false) {
                return ActionVisibility.VIEWING;
            }
            if (mode === FormMode.EDIT && isEditable === true) {
                return ActionVisibility.EDITING;
            }
            if (mode === FormMode.CREATE) {
                return ActionVisibility.CREATION;
            }
            return ActionVisibility.DEFAULT;
        },
        [isEditable],
    );

    if (mode === FormMode.REPRESENTATION) {
        isEditable = false;
    }

    if (mode === FormMode.CREATE) {
        isEditable = true;
    }

    return {
        isEditable,
        currentActionsVisibleState,
    };
};
