import React, {useState} from 'react';
import ReactQuill from 'react-quill';

import {QuillToolbar} from '../toolbar/rich-text-toolbar';

interface IRichRendererProps {
    value?: string;
}

export const RichTextRenderer = ({value, ...props}: IRichRendererProps) => {
    const [text, setText] = useState(value);
    const handleChange = (html: string) => {
        setText(html);
    };
    return (
        <div className="rich-text-renderer">
            <ReactQuill
                theme="snow"
                modules={{
                    toolbar: {container: '#renderer-toolbar'},
                }}
                value={text}
                onChange={handleChange}
                {...props}
                readOnly

            />
            <QuillToolbar
                disabled
                toolbarHtmlId="renderer-toolbar"
            />
        </div>
    );
};
