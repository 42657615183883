import {History} from 'history';
import get from 'lodash/get';

import {EntityType} from 'shared/constants/entities';
import {CommonMessages} from 'shared/constants/messages';
import {showMessageFromResponse} from 'shared/utils';
import {AppState, Dispatch} from 'store/config/types';
import {getBlankForm} from 'utils/common';

import {selectEntityData} from '../data';
import {resetData, resetLoadedData, setData} from '../data/data-actions';
import {selectMetadata} from '../metadata';
import {setMetadata} from '../metadata/metadata-actions';
import {IRequestFormEntityMeta} from '../metadata/metadata-types';
import {requestFormApi} from './request-form.api';
import {ERequestStatuses} from './request-form.constants';
import {getEntityNameFromMetadata, requestMeta} from './request-form.utils';

export const REQUEST_STATUS_CHANGED_TEXT = 'Статус запроса изменен';
const createFormActions = (entityType: EntityType = EntityType.FORM) => (dispatch: Dispatch) => {
    const setFormMeta = (metadata: IRequestFormEntityMeta) => (
        dispatch(setMetadata({
            entityName: getEntityNameFromMetadata(metadata),
            entityType,
            metadata,
        }))
    );
    const setFormData = (metadata: IRequestFormEntityMeta, data: Record<string, any>) => (
        dispatch(setData({
            entityName: getEntityNameFromMetadata(metadata),
            entityType,
            data,
        }))
    );
    const setMetaAndData = (metadata: IRequestFormEntityMeta, data: Record<string, any>) => {
        setFormMeta(metadata);
        setFormData(metadata, data);
    };
    const resetFormData = (entityName: string) => {
        dispatch(resetData({entityType, entityName, loading: false}));
    };
    const resetAllFormData = (entityNames: string[]) => {
        entityNames.forEach(name => resetFormData(name));
    };

    return {
        setMeta: setFormMeta,
        setData: setFormData,
        resetData: resetFormData,
        resetAllFormData,
        setMetaAndData,
    };
};
const createFormSelectors = (entityType: EntityType = EntityType.FORM) => (
    state: AppState, defaultEntityName: string,
) => {
    const getCurrentMeta = (
        entityName: string = defaultEntityName,
    ) => selectMetadata(entityName, entityType)(state);
    const getCurrentData = (
        entityName: string = defaultEntityName,
    ) => selectEntityData(entityName, entityType)(state);
    return {
        getCurrentMeta,
        getCurrentData,
    };
};
export const formButtonsActions = (() => {
    const api = requestFormApi;
    const entityType = EntityType.FORM;
    const getDefaultFormMeta = (entityName: string) => requestMeta.getDefaultByEntityName(entityName);
    const createUrlForViewingForm = (entityName: string, id: string = '') => `/${entityName}/${id}`;
    const createSelectors = createFormSelectors(entityType);
    const createActions = createFormActions(entityType);

    const openFormById = (entityName: string, linkField: string, history: History) => async (
        getState: () => AppState,
    ) => {
        const selectors = createSelectors(getState(), entityName);
        const data = selectors.getCurrentData() as Record<string, any>;
        const newUrl = createUrlForViewingForm(entityName, data[String(linkField)]);

        history.push(newUrl);
    };
    /**
     * Открыть дочернюю форму.
     */
    const openChildFormForCreating = (
        entityNameForChild: string, history: History, params?: Record<string, any>,
    ) => async (dispatch: Dispatch) => {
        const actions = createActions(dispatch);
        const currentMeta = getDefaultFormMeta(entityNameForChild);
        const linkField = String(currentMeta.linkField) || 'id';
        const defaultData = await api.getDataForChildForm(params);
        const blankData = getBlankForm(currentMeta.fields);
        const currentData = {
            ...blankData,
            ...defaultData,
        };
        actions.setMetaAndData(currentMeta, currentData);

        const newUrl = createUrlForViewingForm(entityNameForChild, currentData[linkField]);
        // POSSIBLE_PROBLEM может нужен будет push
        history.replace(newUrl);
    };

    const changeStatusAndUpdateForm = (
        entityName: string, status: ERequestStatuses, linkField: string,
    ) => async (dispatch: Dispatch, getState: () => AppState) => {
        try {
            const selectors = createSelectors(getState(), entityName);
            const data = selectors.getCurrentData() as Record<string, any>;
            const id = get(data, linkField);

            const statusChangeMessage = await api.status.changeStatus(status, id);
            showMessageFromResponse({
                response: statusChangeMessage,
                isError: false,
                customMessage: CommonMessages.REQUEST_STATUS_CHANGED,
            });

            dispatch(resetLoadedData(entityName, EntityType.FORM));
        } catch (error) {
            showMessageFromResponse({response: error.response, isError: true});
        }
    };

    return {
        changeStatusAndUpdateForm,
        openFormById,
        openChildFormForCreating,
    };
})();
