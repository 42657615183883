import {FieldType} from 'modules/metadata';
import {TMetaOption} from 'modules/metadata-constructor/metadata-constructor.types';

import {COMMON_FIELDS_ITEMS} from '../common/common.fields-items';
import {FORM_OPTIONS} from '../form/form.options';
import {FORM_TABLE_ACTIONS} from './form-table.actions';
import {FORM_TABLE_FIELDS} from './form-table.fields-for-type-fields';
import {FORM_TABLE_OPTIONS} from './form-table.options';

export const METADATA_FORM_TABLE: TMetaOption = {
    fields: [
        {
            key: 'useContext',
            label: 'Использовать контекстный фильтр',
            type: FieldType.BOOLEAN_CLASSIC,
            hint: 'Дает доступ к контекстному фильтру для страницы',
        },
        {
            key: 'fields',
            label: 'Поля табличной формы',
            type: FieldType.LIST,
            dataKeyForFilter: 'type',
            defaultItems: [
                COMMON_FIELDS_ITEMS.FIELDS.KEY,
                COMMON_FIELDS_ITEMS.FIELDS.LABEL,
                COMMON_FIELDS_ITEMS.FIELDS.IS_HIDDEN,
                {
                    key: 'type',
                    label: 'Тип',
                    type: FieldType.STATIC_SELECT,
                    options: FORM_TABLE_OPTIONS.FIELD_TYPES,
                    isRequired: true,
                },
            ],
            customItems: FORM_TABLE_FIELDS,
        },
        {
            key: 'actions',
            label: 'Действия табличной формы',
            type: FieldType.LIST,
            dataKeyForFilter: 'actionType',
            hint: 'Действия для страницы',
            isRequired: false,
            defaultItems: [
                {
                    ...COMMON_FIELDS_ITEMS.ACTIONS.ACTION_TYPE,
                    options: FORM_OPTIONS.ACTION_TYPES,
                },
                COMMON_FIELDS_ITEMS.ACTIONS.ACTION_TITLE,
                COMMON_FIELDS_ITEMS.ACTIONS.ACTION_ICON,
            ],
            customItems: FORM_TABLE_ACTIONS,
        },
    ],
};
