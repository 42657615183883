import {RedoOutlined} from '@ant-design/icons';
import {
    Button, Checkbox, Form, Spin, Tooltip,
} from 'antd';
import Popconfirm from 'antd/es/popconfirm';
import {useForm} from 'antd/lib/form/Form';
import cn from 'classnames';
import React, {useEffect, useState} from 'react';

import {IconsMap} from 'components/dynamic-icon';
import {DRILLDOWN_ID_PARAM_KEY} from 'components/report-configuration/report-configuration.constants';
import {useFormInitializer} from 'shared/hooks/use-form-initializer';
import {showMessage} from 'shared/utils';
import {useQueryParams} from 'shared/utils/query-params';
import {showRequestErrorMessage} from 'shared/utils/show-message-from-response';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {deleteDrilldownParameter, updateDrilldownParameter} from 'store/slices/report-configuration-slice/dd-parameters-configuration/dd-parameters-configuration-thunks';
import {ReportDdParameter} from 'store/slices/report-configuration-slice/dd-parameters-configuration/dd-parameters-configuration-types';
import {selectUsedVariablesInDdFilterExpression} from 'store/slices/report-configuration-slice/report-configuration-slice';

import {DdParametersRule} from '../../dd-parameters-rule';
import {DdParameterRuleItemSourceType} from '../../dd-parameters-rule/dd-parameters-rule-item/dd-parameters-rule-item-types';

import './dd-parameters-list-item.less';

interface DdParametersListItemProps {
    ruleIndex: number;
    ddParameter: ReportDdParameter;
}

export const DdParametersListItem: React.FC<DdParametersListItemProps> = (
    {ruleIndex, ddParameter}: DdParametersListItemProps,
) => {
    const dispatch = useAppDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [isInEditMode, setIsInEditMode] = useState(false);

    const [form] = useForm<Partial<ReportDdParameter>>();

    const {initialValues} = useFormInitializer({
        form,
        data: ddParameter,
    });

    const [parentSourceType, setParentSourceType] = useState<DdParameterRuleItemSourceType>();
    const [childSourceType, setChildSourceType] = useState<DdParameterRuleItemSourceType>();

    useEffect(() => {
        setParentSourceType(form.getFieldValue('parentSourceType'));
        setChildSourceType(form.getFieldValue('childSourceType'));
    }, [initialValues]);

    const {query} = useQueryParams();

    const ddFilterUsedVariables = useAppSelector(selectUsedVariablesInDdFilterExpression);

    const drillDownId = Number(query.get(DRILLDOWN_ID_PARAM_KEY));

    const handleDelete = async () => {
        const childValue = `${form.getFieldValue('childValue')}`;

        if (ddFilterUsedVariables?.includes(childValue)) {
            showMessage({
                message: 'Ошибка удаления правила: данные контекста конечного документа'
                + ' используются в правилах фильтрации.'
                + ' Деактивируйте или удалите их и попробуйте еще раз',
                isError: true,
            });
            return;
        }

        setIsLoading(true);
        try {
            await dispatch(deleteDrilldownParameter({id: ddParameter.id}));
            setIsInEditMode(false);
        } catch (e) {
            showRequestErrorMessage(e);
        }
        setIsLoading(false);
    };

    const handleFinish = async (values: Partial<ReportDdParameter>) => {
        setIsLoading(true);
        try {
            await dispatch(updateDrilldownParameter({
                ...values,
                id: ddParameter.id,
                drillId: drillDownId,
            })).unwrap();
            setIsInEditMode(false);
        } catch (e) {
            showRequestErrorMessage(e);
        }
        setIsLoading(false);
    };

    return (
        <Spin
            spinning={isLoading}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={initialValues}
                onFinish={handleFinish}
                onValuesChange={changed => {
                    if (changed.parentSourceType) {
                        setParentSourceType(changed.parentSourceType);
                        form.setFieldsValue({
                            parentSourceValue: undefined,
                        });
                    }
                    if (changed.childSourceType) {
                        setChildSourceType(changed.childSourceType);
                        form.setFieldsValue({childValue: undefined});
                    }
                }}
            >
                <div className={cn('dd-parameters-list-item')}>
                    <div className={cn('dd-parameters-list-item__item-title')}>
                        <div>Правило №{ruleIndex + 1}</div>
                        <Form.Item
                            valuePropName="checked"
                            name="enabled"
                        >
                            <Checkbox disabled={!isInEditMode}>Активно</Checkbox>
                        </Form.Item>

                        <div className={cn('dd-parameters-list-item__item-title__buttons')}>
                            {!isInEditMode && (
                                <Button
                                    type="primary"
                                    className={cn('btn-only-icon')}
                                    onClick={() => {
                                        setIsInEditMode(true);
                                    }}
                                >
                                    <IconsMap.EditWithLine />
                                </Button>
                            )}
                            {isInEditMode && (
                                <Button
                                    type="primary"
                                    className={cn('btn-only-icon')}
                                    onClick={() => {
                                        form.submit();
                                    }}
                                >
                                    <IconsMap.SaveOutlined />
                                </Button>
                            )}
                            {isInEditMode && (
                                <Button
                                    type="default"
                                    className={cn('btn-only-icon')}
                                    onClick={() => {
                                        form.resetFields();
                                        setIsInEditMode(false);
                                    }}
                                >
                                    <RedoOutlined />
                                </Button>
                            )}
                            <Tooltip
                                title="Удалить запись"
                                mouseEnterDelay={0.7}
                            >
                                <Popconfirm
                                    placement="bottomLeft"
                                    title="Вы действительно хотите удалить правило?"
                                    okText="Удалить"
                                    onConfirm={handleDelete}
                                >
                                    <Button
                                        type="default"
                                        className={cn('btn-only-icon')}
                                    >
                                        <IconsMap.TrashXOutlined />
                                    </Button>
                                </Popconfirm>
                            </Tooltip>

                        </div>
                    </div>
                    <DdParametersRule
                        isInEditMode={isInEditMode}
                        childSourceType={childSourceType}
                        parentSourceType={parentSourceType}
                    />
                </div>
            </Form>
        </Spin>
    );
};
