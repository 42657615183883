import {FormInstance} from 'antd/es/form';

import {REFERENCE_RESOLVERS} from 'modules/data/data-constants';
import {IReferenceResolverParams} from 'modules/data/data-types';
import {getReferenceResolverFunction} from 'modules/metadata/metadata-utils';

export const getReferenceResolver = (
    params?: IReferenceResolverParams,
    stringStructure?: string,
) => {
    const {referenceUrl, path} = params || {};
    const labelPath = path?.label ?? 'meaning';
    const valuePath = path?.value ?? 'id';
    const lookupCode = path?.lookupCode ?? 'lookupCode';
    const reserveLabelPath = path?.reserveLabel ?? 'description';
    return REFERENCE_RESOLVERS[String(referenceUrl)]
        || getReferenceResolverFunction(labelPath, valuePath, reserveLabelPath, lookupCode, stringStructure);
};

export const getReferenceParamsValues = (fieldKeys?: Record<string, any>, form?: FormInstance) => {
    const values = JSON.parse(JSON.stringify(fieldKeys || ''));
    fieldKeys?.forEach((element: any, i: number) => {
        if (element && typeof element.to === 'string') {
            const keys = element.to.split('.');
            values[i].to = (keys[1] ? form?.getFieldValue(keys[0])?.[keys[1]] : form?.getFieldValue(keys[0])) || '';
        }
    });
    return values;
};
