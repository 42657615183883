import {
    ActionReducerMapBuilder,
    PayloadAction,
} from '@reduxjs/toolkit';

import {TableReportSliceState} from '../table-report-slice-types';
import {performGlobalSearch} from './table-report-search-thunks';
import {TableReportGlobalSearchCurrentPage, TableReportLocalSearch} from './table-report-search-types';

export const createTableReportSearchExtraReducer = (builder: ActionReducerMapBuilder<TableReportSliceState>) => {
    builder.addCase(performGlobalSearch.fulfilled, (state, {payload}) => {
        state.globalSearch.pages = payload;
    });
};

export const createTableReportSearchReducer = <S extends TableReportSliceState>() => ({
    // local search ↴
    setLocalSearchLine(state: S, {payload}: PayloadAction<{newLine: string}>) {
        state.localSearch.line = payload.newLine;
    },
    setLocalSearchData(state: S, {payload}: PayloadAction<{newData: TableReportLocalSearch}>) {
        state.localSearch = payload.newData;
    },
    updateLocalSearchData(state: S, {payload}: PayloadAction<{newData: Partial<TableReportLocalSearch>}>) {
        state.localSearch = {
            ...state.localSearch,
            ...payload.newData,
        };
    },
    // global search ↴
    setGlobalSearchPages(state: S, {payload}: PayloadAction<number[]>) {
        state.globalSearch.pages = payload;
    },
    setGlobalSearchCurrentPage(state: S, {payload}: PayloadAction<TableReportGlobalSearchCurrentPage>) {
        state.globalSearch.currentPage = payload;
    },
    setGlobalSearchLine(state: S, {payload}: PayloadAction<string>) {
        state.globalSearch.line = payload;
    },
    setGlobalSearchIsLoading(state: S, {payload}: PayloadAction<boolean>) {
        state.globalSearch.isLoading = payload;
    },
    // common ↴
    dropSearch(state: S) {
        state.localSearch = {
            count: null,
            line: '',
            results: [],
            currentSelection: 0,
        };
        state.globalSearch.line = '';
        state.globalSearch.pages = [];
        state.globalSearch.currentPage = {
            index: -1,
            page: -1,
        };
    },
});
