import {performRequest, RequestPromise} from 'shared/utils';

import {convertParamsForRequest} from '../utils/data.utils';

export interface DashboardDataResponse {
    dataTable: { entityName: string; entityDescription: string; count: number }[];
    recordsTotal: number;
}

export const fetchDashboardData = (
    referenceUrl: string, referenceParams?: Record<string, any>,
): RequestPromise<DashboardDataResponse> => performRequest({
    url: `${referenceUrl}`,
    method: 'GET',
    params: referenceParams && convertParamsForRequest(referenceParams),
});
