import {FormInstance} from 'antd/es/form';
import debounce from 'lodash/debounce';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {Entity, resetLoadedData} from 'modules/data';
import {DocumentUploadSignature} from 'modules/documents';
import {
    clearAllSignsInfo as clearAllSignsInfoAction,
    clearDocumentsForSign as clearDocumentsForSignAction,
    loadDefaultSignInfo as loadDefaultSignInfoAction,
    loadDocumentsForSign as loadDocumentsForSignAction,
    setSignList as setSignListAction,
    uploadSignatureFiles,
} from 'modules/documents/documents-actions';
import {fetchDocumentInfo} from 'modules/documents/documents-api';
import {
    NO_FILES_MESSAGE,
    NOT_FILLED_FIELDS_MESSAGE,
    SIGN_UNIQUE_FIELD_NAME,
    TOTAL_ENTITY,
} from 'modules/documents/documents-constants';
import {
    selectDocumentList,
    selectDocumentExtensions,
    selectDocumentSignatureUpload,
} from 'modules/documents/documents-selectors';
import {
    EntitySignatureUploadState,
} from 'modules/documents/documents-types';
import {
    generateSignatureInfoFromDocumentInfo,
    getEntityNameFromLocation,
    removeExtraPartFromEntityName,
} from 'modules/documents/documents-utils';
import {FieldMeta, selectMetadata, loadMetadata as loadMetadataAction} from 'modules/metadata';
import {selectRegions} from 'modules/regions';
import {EntityType} from 'shared/constants/entities';
import {useAppSelector} from 'store/config/hooks';
import {ModalComponentInterface, selectModalData} from 'store/slices/modals-slice';

import {EditableColumn} from '../../editable-table/editable-table-types';
import {FileUploadModal} from '../components/file-upload-modal';
import {FormsFileModalContext} from '../file-modal/file-modal.context';
import {generateSignColumnsMetadata} from './documents-signature-upload-modal-utils';

const getUniqueFieldValue = (file: DocumentUploadSignature) => file[SIGN_UNIQUE_FIELD_NAME];

export const DocumentsSignatureUploadModalContainer:
    React.FunctionComponent<ModalComponentInterface> = ({
        onClose,
    }: ModalComponentInterface) => {
        const currentLocation = useLocation<History>();
        const dispatch = useDispatch();
        const regions = useAppSelector(selectRegions);
        const signatureUpload = useAppSelector(selectDocumentSignatureUpload);
        const documentList = useAppSelector(selectDocumentList);
        const extensions = useAppSelector(selectDocumentExtensions);
        const parentEntityName = useAppSelector(selectModalData)?.entityName;
        const [forms, setForms] = useState<FormInstance[]>([]);
        const [columnsMeta, setColumnsMeta] = useState<EditableColumn[] | undefined>();
        const [errorMsg, setErrorMsg] = useState<string | null>(null);
        const entityNameFromLocation = getEntityNameFromLocation(currentLocation);
        const entityName = entityNameFromLocation ?? TOTAL_ENTITY;
        const signatureUploadSlice = signatureUpload?.[entityName];
        const {signList = []} = signatureUploadSlice ?? {};

        // использовать modalEntityName в action чтобы сделать тут другие метаданные
        const modalEntityName = parentEntityName === entityNameFromLocation || !parentEntityName
            ? 'document.signature.upload' : parentEntityName;

        const meta = useAppSelector(
            state => selectMetadata(modalEntityName, EntityType.EDITABLE_TABLE)(state),
        );
        const handleDelete = (tableRow: Entity) => {
            const signEntry = tableRow as DocumentUploadSignature;
            const resultFileList = signList
                .filter((sign: DocumentUploadSignature) => (
                    getUniqueFieldValue(sign) !== getUniqueFieldValue(signEntry)));
            dispatch(setSignListAction(
                {entityName, signList: [...resultFileList]},
            ));
        };

        useEffect(() => {
            dispatch(loadDocumentsForSignAction(entityNameFromLocation));
            if (!meta) {
                dispatch(loadMetadataAction(modalEntityName, EntityType.EDITABLE_TABLE));
            }
        }, []);

        useEffect(() => {
            if (regions && meta?.fields) {
                const columns = (meta.fields as FieldMeta[]).map(columnMeta => generateSignColumnsMetadata(
                    columnMeta,
                    regions,
                    {
                        documentList: documentList || [],
                        handleDelete,
                        progress: {
                            signUploadSlice: signatureUploadSlice as EntitySignatureUploadState,
                            fieldKey: SIGN_UNIQUE_FIELD_NAME,
                        },
                    },
                ));
                setColumnsMeta(columns);
            }
        }, [signatureUpload, documentList]);

        const handleUpload = debounce((_: File, newSignFiles: File[]) => {
            const resolveFiles = newSignFiles
                .filter(newSign => !signList.some(oldSign => oldSign.fileName === newSign.name));
            if (!resolveFiles.length) return;
            if (errorMsg === NO_FILES_MESSAGE) {
                setErrorMsg(null);
            }
            dispatch(loadDefaultSignInfoAction(entityNameFromLocation, resolveFiles));
        }, 500);

        const handleEdit = async (tableRow: Entity) => {
            const signatureInfo = tableRow as DocumentUploadSignature;
            const signatureIndex = signList.findIndex(sign => sign.fileName === signatureInfo.fileName);
            if (signatureInfo.documentFileId !== signList[signatureIndex].documentFileId) {
                const {data: chosenDocument} = await fetchDocumentInfo({
                    sgnFileName: signatureInfo.fileName,
                    attachId: signatureInfo.documentFileId,
                    sectionCode: `app.${removeExtraPartFromEntityName(entityNameFromLocation)}`,
                });

                if (chosenDocument) {
                    signList[signatureIndex] = {
                        ...signatureInfo,
                        ...generateSignatureInfoFromDocumentInfo(chosenDocument),
                    };
                    dispatch(setSignListAction(
                        {entityName, signList: [...signList]},
                    ));
                }
            } else {
                signList[signatureIndex] = {...signList[signatureIndex], ...signatureInfo};
                dispatch(setSignListAction(
                    {entityName, signList: [...signList]},
                ));
            }
        };

        const handleClear = () => {
            dispatch(clearAllSignsInfoAction(signList, entityName));
        };
        const uploadSignatureFileList = () => dispatch(uploadSignatureFiles(signList, entityName));
        const handleClose = () => {
            onClose();
            dispatch(clearDocumentsForSignAction());
        };
        const onSubmit = async () => {
            try {
                if (signList.length) {
                    await Promise.all(forms.map(form => form.validateFields()));
                    setErrorMsg(null);
                    await uploadSignatureFileList();
                    if (parentEntityName) {
                        dispatch(resetLoadedData(parentEntityName, EntityType.TABLE));
                    }
                    handleClear();
                    handleClose();
                } else {
                    setErrorMsg(NO_FILES_MESSAGE);
                }
            } catch (e) {
                setErrorMsg(NOT_FILLED_FIELDS_MESSAGE);
                console.error(e);
            }
        };
        return (
            <FormsFileModalContext.Provider
                value={{
                    forms,
                    setForms,
                }}
            >
                <FileUploadModal
                    onClose={handleClose}
                    onClear={handleClear}
                    onSubmit={onSubmit}
                    handleEdit={handleEdit}
                    onUpload={handleUpload}
                    columns={columnsMeta}
                    columnsFiltered
                    accept={extensions?.accept || ''}
                    fileList={signList}
                    errorMsg={errorMsg}
                    modalTitle={meta?.title}
                    isPageJumperEnabled={meta?.isPageJumperEnabled}
                    isPageSizeChangerEnabled={meta?.isPageJumperEnabled}
                    rowKey="fileName"
                    submitText="Загрузить добавленные ЭП"
                />
            </FormsFileModalContext.Provider>
        );
    };
