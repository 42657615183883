import {Input, Tooltip} from 'antd';
import React from 'react';

import {DynamicIcon} from 'components/dynamic-icon';
import {TIconType} from 'components/dynamic-icon/dynamic-icon';
import {DEFAULT_ICON_COLOR} from 'components/table/columns/icons-cell/icon-cell.constants';
import {FieldMeta} from 'modules/metadata';
import {fieldStructureType} from 'modules/metadata/metadata-types';
import {ReactComponent as Lock} from 'shared/assets/forms/lock.svg';
import './input-tooltip.less';

interface InputTooltipProps {
    disabled?: boolean;
    field?: FieldMeta;
    value?: any;
    defaultValue?: string;
    placeholder?:string;
    fieldStructure?: fieldStructureType[];
}

export const InputTooltip: React.FunctionComponent<InputTooltipProps> = (
    {
        disabled,
        field,
        value,
        fieldStructure,
        ...props
    }: InputTooltipProps,
) => {
    const suffix = disabled ? <Lock /> : null;
    return (
        <div className="input-tooltip">
            <Input
                disabled={disabled}
                suffix={suffix}
                value={value || field?.defaultValue}
                {...props}
            />
            <div className="tooltip-list">
                {fieldStructure?.map(fieldStructureEl => (
                    <Tooltip
                        key={fieldStructureEl?.fieldKey}
                        placement="top"
                        title={value}
                    >
                        <DynamicIcon
                            style={
                                {
                                    fontSize: fieldStructureEl?.iconSize || 16,
                                    color: fieldStructureEl?.iconColor || DEFAULT_ICON_COLOR,
                                }
                            }
                            type={fieldStructureEl?.iconName as TIconType}
                        />
                    </Tooltip>
                ))}
            </div>
        </div>

    );
};
