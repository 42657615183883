import get from 'lodash/get';
import React from 'react';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';

import {FieldMeta} from 'modules/metadata';
import {URL_AIS3_DOCUMENT_CODES} from 'shared/constants/urls';
import {openModal} from 'store/slices/modals-slice';

interface OpenDocumentCodesModalActionProps {
    url: string;
    entityName: string;
    record: any;
    field: FieldMeta;
}

export const OpenDocumentCodesModalAction: React.FunctionComponent<OpenDocumentCodesModalActionProps> = ({
    url,
    entityName,
    record,
    field,
}: OpenDocumentCodesModalActionProps) => {
    const dispatch = useDispatch();
    const handleClick = () => {
        if (field?.modalName) {
            dispatch(openModal({
                modalName: field?.modalName,
                entityName,
                modalParameters: {
                    key: field.key,
                    recordId: record.id,
                    entityName,
                    url,
                },
            }));
        }
    };

    return (
        <Link
            to={URL_AIS3_DOCUMENT_CODES}
            onClick={handleClick}
        >
            {get(record, field.key)}
        </Link>
    );
};
