import {PlusOutlined} from '@ant-design/icons';
import {Upload} from 'antd';
import {UploadFile} from 'antd/es/upload/interface';
import cn from 'classnames';
import React, {CSSProperties} from 'react';

import {ReactComponent as CloseIcon} from 'shared/assets/forms/delete-x.svg';
import {ReactComponent as FileIcon} from 'shared/assets/forms/file.svg';
import {ReactComponent as LinkIcon} from 'shared/assets/link.svg';
import {FileDto, FileList} from 'shared/types/files';

import {SimpleActionButton} from '../../components';
import {generateUploadFileList, getHandleBeforeUpload, getHandleRemove} from './uploader-list.utils';

import './uploader-list.less';

interface UploadListProps {
    buttonText?: string;
    fileList?: FileList[];
    disabled?: boolean;
    onChange?: (fileList: UploadFile<Blob>[]) => void;
    url?: string;
    dataKeyForDownload?: string;
    classNames?: {
        resetDefaultWrapperClassname?: boolean;
        uploadClassName?: cn.Argument;
        buttonClassName?: cn.Argument;
    };
    styles?: {
        buttonStyle?: CSSProperties;
    };
    dropzoneVariant?: 'dropzone' | 'button';
}

export const UploaderList: React.FC<UploadListProps> = ({
    fileList = [],
    buttonText = 'Выбрать Файл',
    disabled,
    onChange,
    url,
    dataKeyForDownload,
    classNames = {},
    styles = {},
    dropzoneVariant = 'button',

}: UploadListProps) => {
    const handleBeforeUpload = getHandleBeforeUpload(fileList, onChange);
    const handleRemove = getHandleRemove(fileList, onChange);
    const {buttonClassName, uploadClassName, resetDefaultWrapperClassname} = classNames;
    const {buttonStyle} = styles;

    if (!disabled) {
        return (
            <Upload
                className={cn({
                    'upload-list-wrapper': !resetDefaultWrapperClassname,
                    'upload-list-wrapper_with-dropzone': dropzoneVariant === 'dropzone',
                }, uploadClassName)}
                fileList={generateUploadFileList(fileList as FileDto[], {url, dataKeyForDownload})}
                showUploadList={{
                    showDownloadIcon: true,
                    showRemoveIcon: true,
                    downloadIcon: <LinkIcon />,
                    removeIcon: <CloseIcon />,
                }}
                iconRender={() => (
                    <FileIcon
                        width={18}
                        height={21}
                    />
                )}
                onRemove={handleRemove}
                beforeUpload={handleBeforeUpload}
                multiple
            >
                {dropzoneVariant === 'button' ? (
                    <SimpleActionButton
                        style={buttonStyle}
                        className={cn(buttonClassName)}
                        type="primary"
                        title={buttonText}
                    />
                ) : (
                    <div className={cn('upload-list-wrapper__dropzone')}>
                        <div className={cn('upload-list-wrapper__dropzone__icon')}><PlusOutlined /></div>
                        <div>Перетащите файлы или нажмите, чтобы добавить вложение</div>
                    </div>
                )}
            </Upload>
        );
    }
    return (
        <Upload
            fileList={generateUploadFileList(fileList as FileDto[])}
            iconRender={() => (
                <FileIcon
                    width={13}
                    height={16}
                />
            )}
            showUploadList={{
                showDownloadIcon: true,
                showRemoveIcon: false,
                downloadIcon: <LinkIcon />,
            }}
        />
    );
};
