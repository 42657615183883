import {Button, Form, Input} from 'antd';
import cn from 'classnames';
import React from 'react';

import {ModalOpenerComponent} from 'components/modal-opener-component';
import {DefaultModalOpenerFormListProps} from 'components/report-configuration/report-configuration.types';
import {ReactComponent as SquareInnerX} from 'shared/assets/square-inner-x.svg';

import './format-settings-modal-opener.less';
import {
    SettingTemplateTransferTargetFormFields,
} from '../../constants/setting-template-transfer-target/setting-template-transfer-target';

interface FormatSettingsModalOpenerProps extends DefaultModalOpenerFormListProps{
    isRequired: boolean;
}
export const FormatSettingsModalOpener = ({
    form,
    name,
    disabled,
    isRequired,
}: FormatSettingsModalOpenerProps) => (
    <ModalOpenerComponent
        controlLabels={{
            save: 'Cохранить',
        }}
        handleCancel={setIsModalOpen => {
            setIsModalOpen(false);
        }}
        handleSave={setIsModalOpen => {
            form.validateFields();
            setIsModalOpen(false);
        }}
        component={(
            <Button
                type="primary"
                className={cn('button-only-icon')}
                disabled={disabled}
            >
                <SquareInnerX className={cn('path-stroke-change-color')} />
            </Button>
        )}
        modalProps={{
            forceRender: true,
            destroyOnClose: true,
            centered: true,
            title: 'Правила форматирования',
        }}
    >
        <div className="format-settings-content">
            <Form.Item
                label="Формат"
                name={[name, SettingTemplateTransferTargetFormFields.fieldFormat]}
                rules={[{required: isRequired}]}
            >
                <Input
                    autoComplete="off"
                    placeholder="Введите формат"
                />
            </Form.Item>
            <Form.Item
                label="Регулярное выражение"
                name={[name, SettingTemplateTransferTargetFormFields.fieldRegex]}
                rules={[{required: isRequired}]}
            >
                <Input
                    autoComplete="off"
                    placeholder="Введите формат"
                />
            </Form.Item>
        </div>
    </ModalOpenerComponent>
);
