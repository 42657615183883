import {
    Form as AntForm, Input, Button, Space,
} from 'antd';
import {useForm} from 'antd/es/form/Form';
import React from 'react';

export interface FormTooltipProps {
    name: string;
    value?: string;
    onClose: () => void;
    onChange?: (value: any) => void;
    placeholder?: string;
    isRequired?: boolean;
}

export const FormTooltip = ({
    name, onClose, onChange, value, placeholder, isRequired,
}: FormTooltipProps) => {
    const [form] = useForm();
    const handleSubmit = (values: any) => {
        onClose();
        onChange?.(values[name]);
    };

    return (
        <AntForm
            form={form}
            onFinish={handleSubmit}
            className="form-tooltip"
            layout="vertical"
        >
            <AntForm.Item
                name={name}
                initialValue={value}
                rules={[{required: isRequired, message: 'Поле обязательно для заполнения'}]}
            >
                <Input
                    autoFocus
                    autoComplete="off"
                    placeholder={placeholder || 'Введите значение'}
                />
            </AntForm.Item>
            <Space className="buttons">
                <Button
                    htmlType="submit"
                    type="primary"
                    className="form-tooltip-button"
                >Сохранить
                </Button>
                <Button
                    onClick={onClose}
                    type="default"
                    className="form-tooltip-button"
                >Сбросить
                </Button>
            </Space>
        </AntForm>
    );
};
