import React from 'react';

import './cr-rules-table-entry-selector.less';

export interface CrRulesTableEntrySelectorProps {
    value?: string;
    record: Record<string, any>;
    selectTableEntry: (entry : number | null) => void;
}

export const CrRulesTableEntrySelector = ({value, selectTableEntry, record}: CrRulesTableEntrySelectorProps) => (
    <div
        className="cr-rules-entry-selector"
        onClick={() => record.id && selectTableEntry(record.id)}
    >
        {value}
    </div>
);
