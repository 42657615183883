import {Watcher, WatcherFieldFlag} from 'components/report-configuration/hooks/use-form-fields-manager';

export interface CreateDocumentUploadTemplateWatchersProps{
    isEditingForm: boolean;
    mainFields: string[];
    disabledByEditingModeCondition?: {condition: () => boolean; flag: WatcherFieldFlag};
}

export const createDocumentUploadTemplateWatchers = ({
    isEditingForm,
    mainFields,
}: CreateDocumentUploadTemplateWatchersProps) => ({
    ...(() => Object.fromEntries([
        ...mainFields,
    ].map(fieldName => [fieldName, [{
        condition: () => !isEditingForm,
        flag: WatcherFieldFlag.isDisabled,
    }]])))(),
} as {[index: string]: Watcher[]});

export const createDffSegmentValues = (values: [string, unknown][]) => {
    const dffSegmentValues: {
        [columnName: string] : {
            segmentColumnName: string;
            segmentValue: any;
        };
    } = {};
    values.forEach(value => {
        if (!Array.isArray(value[1])) {
            dffSegmentValues[value[0]] = {
                segmentColumnName: value[0],
                segmentValue: value[1],
            };
        }
    });
    return dffSegmentValues;
};
