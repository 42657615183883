import {Table as AntTable} from 'antd';
import {
    Key,
    TablePaginationConfig,
    TableRowSelection,
} from 'antd/lib/table/interface';
import {isEqual} from 'lodash';
import React, {useContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {resetData, setData} from 'modules/data/data-actions';
import {
    DefaultTableStateFlat,
    Entity,
    TableEntityData,
} from 'modules/data/data-types';
import {setMetadata} from 'modules/metadata/metadata-actions';
import {selectTableEntityMetadata} from 'modules/metadata/metadata-selectors';
import {EntityMeta, TableEntityMeta} from 'modules/metadata/metadata-types';
import {ReactComponent as ArrowRightIcon} from 'shared/assets/arrow-right.svg';
import {EntityType} from 'shared/constants/entities';
import {convertWidthData} from 'shared/utils/convert-width-data';
import {useQueryParams} from 'shared/utils/query-params';
import {useAppSelector} from 'store/config/hooks';
import {selectUserInfo} from 'store/slices/auth-slice';
import {openModal} from 'store/slices/modals-slice';
import {normalizeBoolValue, normalizeBoolValueString} from 'utils/common';

import {disabledFields} from './action/button-action-edit-field/button-action-edit-fields';
import {ColumnSelectAllCheckbox} from './columns/column-select-all-checkBox/column-select-all-checkbox';
import {TableMode, TableModeContext} from './context';
import {createTableColumnResolver} from './table-column-resolver';
import {callRedirect, convertFlatObjectToTableState, createDefaultSorter} from './utils/table.utils';

import './table.less';

const {Column} = AntTable;

export interface TableProps {
    tableData: TableEntityData;
    url: string;
    metadata: TableEntityMeta;
    isFormTable?: boolean;
    rowSelection?: TableRowSelection<any>;
    onChange?: (
        pagination: TablePaginationConfig,
        filters: Record<string, Key[] | null>,
        sorter: any,
        url: string,
    ) => void;
    loading?: boolean;
    entityName: string;
    entityType?: EntityType;
    handleUrl?: (url: string) => void;
    scroll?: TableScroll;
}

export interface TableScroll {
    x?: number | true | string;
    y?: number | string;
}

export const Table: React.FunctionComponent<TableProps> = ({
    tableData,
    url,
    metadata,
    isFormTable,
    rowSelection,
    onChange,
    loading,
    entityType,
    entityName,
    scroll,
}: TableProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {updateQueryParams} = useQueryParams();

    const {
        rows,
        initialRows,
        params,
        editRows,
    } = tableData;
    const {
        fields, linkField, isSelectable, dependentLinkField,
    } = metadata;

    const user = useAppSelector(selectUserInfo);
    const [checkKey, setCheckKey] = useState<any>({});
    const tableState = convertFlatObjectToTableState(params as DefaultTableStateFlat);
    const {resetTableMode, tableMode} = useContext(TableModeContext);
    const metaTableData = useAppSelector(selectTableEntityMetadata(entityName));

    const handleBreadcrumbsEntityName = (newEntityName: string, newMeta: EntityMeta) => {
        dispatch(setMetadata({
            entityName,
            entityType: entityType || EntityType.TABLE,
            metadata: newMeta,
        }));
        dispatch(resetData({
            entityName,
            entityType: EntityType.TABLE,
            loading: false,
        }));
    };

    const handleChangeIndexRow = (newRow: Entity, key: string, dependenceFieldKey?: string) => {
        const initialRowsValue: Entity[] = initialRows || [];
        const copyRows: Entity[] = rows || [];
        const copyEditRows: Entity[] = editRows || [];

        const index: number = copyRows.findIndex(row => (row?.id !== null
            ? row?.id === newRow?.id
            : row?.uniqId === newRow?.uniqId));
        const indexEditRow: number = copyEditRows.findIndex(row => (row?.id !== null
            ? row?.id === newRow?.id
            : row?.uniqId === newRow?.uniqId));
        const isEditRowInNewRow = copyEditRows.some(editRow => ((editRow?.id !== null
            ? editRow?.id === newRow?.id
            : editRow?.uniqId === newRow?.uniqId)));
        const isEditRowHasInitialValue = isEqual(newRow, initialRowsValue[index]);

        if (dependenceFieldKey && key) {
            if (normalizeBoolValue(newRow[key])) {
                const item = {...newRow, [dependenceFieldKey]: normalizeBoolValueString(false)};
                copyRows.splice(index, 1, item);
            } else {
                copyRows.splice(index, 1, newRow);
            }
            if (isEditRowInNewRow && isEditRowHasInitialValue) {
                copyEditRows.splice(indexEditRow, 1, copyRows[index]);
            } else {
                copyEditRows.push(newRow);
            }
            dispatch(setData({
                entityName,
                entityType: EntityType.TABLE,
                data: {
                    rows: [...copyRows],
                    editRows: [...copyEditRows || []],
                },
            }));
            return;
        }
        if (isEditRowInNewRow && isEditRowHasInitialValue) {
            copyEditRows.splice(indexEditRow, 1);
        } else {
            copyEditRows.push(newRow);
        }
        copyRows.splice(index, 1, newRow);

        dispatch(setData({
            entityName,
            entityType: EntityType.TABLE,
            data: {
                rows: [...copyRows],
                editRows: [...copyEditRows || []],
            },
        }));
    };

    const onRow = (record: Record<string, any>) => ({
        onClick: () => {
            callRedirect(record, updateQueryParams, linkField, history, url, user?.userId);
        },
    });

    const openModalForm = (modalName: string, record: Record<string, any>, parentEntityName?: string) => {
        dispatch(openModal({modalName, entityName: parentEntityName, modalParameters: record}));
    };

    let tableProps = {};

    if (!isFormTable) {
        tableProps = {
            onRow,
            rowClassName: linkField && 'ant-table__row-link',
        };
    }
    const handleTablePaginationChange = () => {
        if (metadata?.allowEditTableInterface) {
            const newMetaData = disabledFields(metaTableData, true);
            dispatch(setMetadata({
                entityName,
                entityType: EntityType.TABLE,
                metadata: newMetaData,
            }));
        }
        resetTableMode();
    };
    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, Key[] | null>,
        sorter: any,
    ) => {
        if (onChange) onChange(pagination, filters, sorter, url);
    };
    useEffect(() => {
        const defaultCheckKey: any = {};
        tableData.rows?.forEach((row: any) => {
            const keys = Object.keys(row);
            keys.forEach(key => {
                if (row[key] === 'Y') {
                    defaultCheckKey[row.id] = key;
                }
            });
        });
        setCheckKey({...checkKey, ...defaultCheckKey});
    }, []);
    return (
        <AntTable
            rowKey={linkField || dependentLinkField || 'id'}
            dataSource={rows}
            showSorterTooltip={false}
            rowSelection={isSelectable
                ? {
                    type: 'checkbox',
                    ...rowSelection,
                } : undefined}
            pagination={metadata?.disabledPagination ? false : {
                ...(tableState.pagination),
                position: ['bottomLeft'],
                locale: {items_per_page: 'на странице', jump_to: ''},
                showQuickJumper: metadata?.isPageJumperEnabled
                    ? {goButton: <ArrowRightIcon className="pagination_jumper" />} : false,
                showSizeChanger: metadata?.isPageSizeChangerEnabled,
                onChange: handleTablePaginationChange,
            }}
            onChange={handleTableChange}
            loading={loading}
            scroll={scroll || {
                x: 'max-content',
            }}
            {...tableProps}
        >
            {fields.map(field => !field.isHidden
                && (
                    <Column
                        title={field?.isChecked && tableMode === TableMode.EDIT
                            ? (() => (
                                <ColumnSelectAllCheckbox
                                    fieldMeta={field}
                                    rows={rows}
                                    editRows={editRows}
                                    entityName={entityName}
                                />
                            ))
                            : field.label}
                        dataIndex={field.key}
                        sorter={field.isSortable && createDefaultSorter(field.key, field.stringStructure)}
                        render={createTableColumnResolver(
                            field.key,
                            field,
                            url,
                            {linkField},
                            entityName,
                            entityType,
                            checkKey,
                            openModalForm,
                            handleBreadcrumbsEntityName,
                            handleChangeIndexRow,
                            metadata?.prefix,
                            params,
                        )}
                        key={field.key}
                        width={convertWidthData(field.width) || 10}
                    />
                ))}
        </AntTable>
    );
};
