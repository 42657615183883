import {Form, Input} from 'antd';
import cn from 'classnames';
import React, {useState} from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {
    ATTRIBUTE_CANNOT_BE_ASSIGNED,
    ATTRIBUTE_IS_NOT_UNIQUE,
    TITLE_FROM_ATTRIBUTE_MESSAGE,
} from 'components/report-configuration/tabs/report-table-settings/modals/excel-header-structure-loader-modal/excel-header-structure-loader-modal.constants';
import {
    checkAttributeForUniqueness,
    filterHiddenChildren,
} from 'components/report-configuration/tabs/report-table-settings/modals/excel-header-structure-loader-modal/excel-header-structure-loader-modal.utils';
import {ReactComponent as ArrowDown} from 'shared/assets/arrow-down-filled.svg';
import {StateSetter} from 'shared/types/generics';
import {useAppSelector} from 'store/config/hooks';
import {
    selectReportConfigurationTableAttributesAsSelectEntries,
} from 'store/slices/report-configuration-slice/report-configuration-slice';

import {TableHeaderCell} from '../../excel-header-structure-loader-modal.types';
import './header-tree-mapper-row.less';

interface HeaderTreeMapperRowProps {
    node: TableHeaderCell | null;
    hasChildren?: boolean;
    areChildrenHidden?: boolean;
    handleHideChildrenClick?: StateSetter<boolean>;
}

export const HeaderTreeMapperRow = ({
    node,
    hasChildren,
    areChildrenHidden,
    handleHideChildrenClick,
}: HeaderTreeMapperRowProps) => {
    const tableAttributesEntries = useAppSelector(selectReportConfigurationTableAttributesAsSelectEntries);
    const [isTitleDisabled, setTitleDisabled] = useState<boolean>(!!node?.attribute);
    const filteredChildren = filterHiddenChildren(node);
    const isAttributeInputDisabled = !!filteredChildren?.length && filteredChildren.length > 1;

    const [message, setMessage] = useState<string | undefined>(
        node?.attribute
            ? TITLE_FROM_ATTRIBUTE_MESSAGE
            : isAttributeInputDisabled
                ? ATTRIBUTE_CANNOT_BE_ASSIGNED
                : undefined,
    );

    const handleAttributeValueChanged = (value: string) => {
        setTitleDisabled(!!value);
        setMessage(value ? TITLE_FROM_ATTRIBUTE_MESSAGE : undefined);
    };

    return node && (
        <div className="header-tree-mapper-row">
            <div className="header-tree-mapper-row__wrapper">
                {hasChildren && (
                    <div
                        className="header-tree-mapper-row__icon"
                        onClick={() => handleHideChildrenClick?.(!areChildrenHidden)}
                    >
                        <ArrowDown className={cn({closed: areChildrenHidden})} />
                    </div>

                )}
                <Form.Item
                    className="header-tree-mapper-row-title"
                    label="Наименование"
                    name={[node?.key, 'title']}
                    initialValue={node?.title}
                    tooltip={!isTitleDisabled ? node.title : undefined}
                >
                    <Input
                        className="header-tree-mapper-row-title__value"
                        disabled={isTitleDisabled}
                    />
                </Form.Item>
                {tableAttributesEntries?.length && (
                    <Form.Item
                        className="header-tree-mapper-row-attribute"
                        label="Атрибут"
                        name={[node?.key, 'attribute']}
                        initialValue={node?.attribute}
                        rules={[
                            ({getFieldValue, getFieldsValue}) => ({
                                validator: () => {
                                    const isUnique = checkAttributeForUniqueness(
                                        getFieldsValue(), getFieldValue([node?.key, 'attribute']),
                                    );
                                    if (!isUnique) {
                                        setMessage(ATTRIBUTE_IS_NOT_UNIQUE);
                                        return Promise.reject();
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <CustomSelect
                            entries={tableAttributesEntries}
                            settings={{
                                placeholder: 'Выберите атрибут',
                                isClearable: true,
                                isDisabled: isAttributeInputDisabled,
                                showSearch: true,
                            }}
                            onChange={value => handleAttributeValueChanged(value)}
                        />
                    </Form.Item>
                )}
                <Form.Item
                    hidden
                    name={[node?.key, 'key']}
                    initialValue={node?.key}
                />
            </div>
            {message && (
                <div
                    className="header-tree-mapper-row__message"
                    style={{
                        paddingLeft: hasChildren ? '17px' : '0',
                        color: message === ATTRIBUTE_IS_NOT_UNIQUE ? '#ff4d4f' : '#959DAC',
                    }}
                >
                    {message}
                </div>
            )}
        </div>
    );
};
