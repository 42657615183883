import React from 'react';

import {SimpleActionButton} from 'components/form/components';
import {useAppSelector} from 'store/config/hooks';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';
import {loadTableReportPageData} from 'store/slices/table-report-slice/table-report-slice-thunks';

interface RefreshButtonProps {
    handleRefreshReportPage?: () => void;
}

export const RefreshButton: React.FC<RefreshButtonProps> = ({
    handleRefreshReportPage,
}: RefreshButtonProps) => {
    const handleRefresh = () => {
        handleRefreshReportPage?.();
    };

    const isLoadingPageData = useAppSelector(s => selectIsThunkPending(s, loadTableReportPageData.typePrefix));

    return (
        <div>
            <SimpleActionButton
                type="default"
                disabled={isLoadingPageData}
                onClick={handleRefresh}
                title="Обновить"
                icon="ReloadOutlined"
            />
        </div>
    );
};
