import React from 'react';

import {ModalComponentInterface} from 'store/slices/modals-slice';

import {Request} from './request';

interface RequestContainerProps extends ModalComponentInterface {
    id: string;
    entityName?: string;
    referenceUrl?: string;
}

export const RequestContainer: React.FunctionComponent<RequestContainerProps> = ({
    id,
    onClose,
    entityName,
    referenceUrl,
}: RequestContainerProps) => (
    <Request
        id={id}
        entityName={entityName || ''}
        url={referenceUrl || ''}
        onClose={onClose}
    />
);
