import {createSelector} from '@reduxjs/toolkit';

import {AppState} from '../../config/types';
import {documentUploadTemplateSliceName} from './document-upload-template-slice-constants';

const selectDocumentUploadTemplateSliceState = (state: AppState) => state[documentUploadTemplateSliceName];

export const selectDocumentUploadTemplateData = createSelector(
    selectDocumentUploadTemplateSliceState,
    state => state?.documentUploadTemplateData,
);

export const selectSettingTemplateTargetColumns = createSelector(
    selectDocumentUploadTemplateSliceState,
    state => state?.settingTemplateTransferTargetColumns,
);
