import React, {useRef} from 'react';

import {URL_REQUESTS_ATTACH, URL_REQUESTS_SIGNATURE} from 'shared/constants/urls';
import {
    FileDto, FileList, UploadFile, UploadFileExtended,
} from 'shared/types/files';
import {DEFAULT_REQUEST_CONFIG} from 'shared/utils/request';

import {SignFilesErrors} from './sign-and-file-list-uploader/sign-and-file-list-uploader.utils';

export const getFileName = (file?: Record<string, any>) => file?.name || file?.fileName || '';
export const getSignName = (
    file?: Record<string, any>,
) => file?.signatureFileName || file?.signName || file?.sign || file?.signFileName || '';

const getFileWithSign = (file?: FileList, sign: Record<string, any> = {}) => ({
    ...file,
    ...sign,
});

export const getUrlForUpload = (url?: string, id?: string, param?: string) => (url && id
    ? `${DEFAULT_REQUEST_CONFIG.baseURL}${url?.startsWith('/') ? url : `/${url}`}${param}/${id}`
    : undefined);

export const generateUploadFileList = (
    fileList?: FileList[], options?: {
        url?: string; dataKeyForDownload?: string; signUrl?: string;
    },
): UploadFileExtended[] => fileList
    ?.filter((file: Record<string, any>) => !file.removedFile)
    .map((file: Record<string, any>, index: number) => {
        const {
            url = '', dataKeyForDownload = '', signUrl = '',
        } = options || {};
        const id = file?.[dataKeyForDownload];
        return ({
            uid: `${index}`,
            ...file as Omit<UploadFile, 'uid'>,
            name: getFileName(file),
            url: getUrlForUpload(url, id, URL_REQUESTS_ATTACH),
            signName: getSignName(file),
            signUrl: getUrlForUpload(signUrl, id, URL_REQUESTS_SIGNATURE),
        });
    }) as UploadFile[];

const normalizedUploadList = (uploadList: UploadFile<Blob>[], fileList: FileList[]) => uploadList
    .filter(newFile => !fileList
        ?.some((oldFile: any) => oldFile?.name === newFile?.name))
    ?.map((newFile: UploadFile<Blob>) => ({
        uid: newFile?.uid,
        name: newFile?.name,
        status: newFile?.status,
        file: newFile,
    }));

const normalizedSignUploadList = (uploadSignList: UploadFile<Blob>[], fileList: FileList[]) => uploadSignList
    ?.map((newSign: UploadFile<Blob>) => ({
        signName: newSign?.name,
        sign: newSign,
    }))?.filter(newSign => !fileList?.some((oldFile: any) => oldFile?.singName === newSign?.signName));

export const getHandleBeforeUpload = (
    fileList: FileList[], onChange?: (fileList: UploadFile<Blob>[]) => void,
) => (file: UploadFile<Blob>, uploadFileList: UploadFile<Blob>[]) => {
    const resolveFiles = normalizedUploadList(uploadFileList, fileList);
    if (!resolveFiles.length) return false;

    onChange?.([...(fileList || []), ...resolveFiles] as UploadFile[]);
    return false;
};
const normalizeSignList = (
    uploadList: UploadFile<Blob>[],
    fileList: FileDto[],
) => {
    const matchingSignFileNames: string[] = [];
    const uploadSinListName: string[] = uploadList.map(sign => sign?.name);
    const newFileList = fileList.map(file => {
        const foundSignFile = uploadList.find(signFile => {
            if (signFile?.name.startsWith(file.name)) {
                matchingSignFileNames.push(signFile?.name);
            }
            return signFile?.name.startsWith(file.name);
        });
        if (file?.sign) {
            return file;
        }
        return {
            ...file,
            sign: foundSignFile && foundSignFile,
            signName: foundSignFile?.name,
            signRemovedFile: false,
        };
    });
    const errorSignFilesNames = uploadSinListName.filter(value => !matchingSignFileNames.includes(value));
    return {
        newFileList,
        errorSignFilesNames,
    };
};

export const getSignsUploadHandler = (
    fileList: FileDto[],
    setErrorSingsInfo: React.Dispatch<React.SetStateAction<SignFilesErrors>>,
    onChange?: (fileList: UploadFile<Blob>[]) => void,
) => (file: UploadFile<Blob>, uploadFileList: UploadFile<Blob>[]) => {
    const {newFileList, errorSignFilesNames} = normalizeSignList(uploadFileList, fileList);

    const resolveFiles = newFileList;
    if (errorSignFilesNames && !!errorSignFilesNames?.length) {
        setErrorSingsInfo({
            isConfirmVisible: true,
            errorSignFilesNames,
        });
    }

    if (!resolveFiles?.length) return false;

    onChange?.(resolveFiles as unknown as UploadFile[]);
    return false;
};
export const getMatchingNameAndSignature = <I extends Record<string, any>>(signs: I[]): Record<string, I> => signs
    .reduce((acc: Record<string, I>, sign: I) => {
        const lastFileExtension = /\.[^.$]+$/;
        const resolvedName = getSignName(sign).replace(lastFileExtension, '');
        if (resolvedName) {
            acc[resolvedName] = sign;
        }
        return acc;
    }, {});

export const getSignHandleBeforeUpload = (
    signList: FileList[] = [], onChange?: (signList: UploadFile<Blob>[]) => void, fileList?: FileList[],
) => (file: UploadFile<Blob>, uploadSignList: UploadFile<Blob>[]) => {
    const resolveSigns = normalizedSignUploadList(uploadSignList, signList);

    if (!resolveSigns.length) return false;

    const matchingNameAndSignature = getMatchingNameAndSignature(resolveSigns);

    const sortedSignForFiles = fileList
        ?.map(item => getFileWithSign(item, matchingNameAndSignature[getFileName(item)])) as UploadFile[];

    onChange?.(sortedSignForFiles || []);

    return false;
};

export const getHandleRemove = (
    fileList: FileList[] = [],
    onChange?: (fileList: UploadFile<Blob>[]) => void,
) => (removedFile: UploadFile<Blob>) => {
    const resolveFiles = (fileList as UploadFile<Blob>[])
        ?.map(file => {
            if (getFileName(file) === getFileName(removedFile)) {
                return {...file, removedFile: true};
            }
            return file;
        });
    onChange?.(resolveFiles);
};

export const getHandleFileRemove = (
    fileList: UploadFile[],
    onChange?: ((fileList: UploadFile<Blob>[]) => void) | undefined,
    onDelete?: (attachment: FileDto) => void,
) => async (removedFile: FileDto) => {
    const preservedFiles = fileList.filter(file => file.uid !== removedFile.uid);

    if (onDelete) {
        try {
            await onDelete(removedFile);
            onChange?.(preservedFiles as UploadFile<Blob>[]);
        } catch (error) {
            console.error('Ошибка при удалении файла:', error);
        }
    }
};

export const useButtonsUploadImitation = () => {
    const ref = useRef<HTMLButtonElement>(null);
    const handleClick = () => {
        const {current} = ref;
        current?.click();
    };
    return {
        ref,
        handleClick,
    };
};

export const isArrayNull = (arr: (Blob | null)[]) => arr.every(element => element === null);
