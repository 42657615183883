import {PlusCircleOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import {
    Badge, Button, Form, Tooltip,
} from 'antd';
import {FormInstance} from 'antd/es/form';
import {FormListFieldData} from 'antd/es/form/FormList';
import cn from 'classnames';
import {xor} from 'lodash';
import React, {useEffect, useRef, useState} from 'react';

import {CheckboxWithToggle} from 'components/@common/specific/сheckbox-with-toggle/checkbox-with-toggle';
import {ColorPicker} from 'components/form/inputs/color-picker';
import {
    CustomSelect, CustomSelectMode,
} from 'components/form/inputs/custom-select';
import {FormListOperationsRef} from 'components/report-configuration/report-configuration.types';
import {StateSetter} from 'shared/types/generics';
import {useAppSelector} from 'store/config/hooks';
import {
    selectReportConfigurationTableAttributesAsSelectEntries,
} from 'store/slices/report-configuration-slice/report-configuration-slice';

import {
    FormatItemConditions,
} from '../format-item-conditions';

interface FormatFormItemProps {
    fields: FormListFieldData[];
    field: FormListFieldData;
    selectedRows: number[];
    setSelectedRows: StateSetter<number[]>;
    form: FormInstance;
}

export const FormatFormItem: React.FC<FormatFormItemProps> = ({
    setSelectedRows,
    selectedRows,
    field,
    form,
    fields,
}: FormatFormItemProps) => {
    const tableAttributesEntries = useAppSelector(selectReportConfigurationTableAttributesAsSelectEntries);

    const conditionsRef = useRef<FormListOperationsRef | null>(null);

    const [fieldsCount, setFieldsCount] = useState<number | undefined>(
        form.getFieldValue(['rowFormats', field.name, 'conditions'])?.length,
    );

    useEffect(() => {
        setFieldsCount(form.getFieldValue(['rowFormats', field.name, 'conditions'])?.length);
    }, [fields]);

    const [areConditionsHidden, setAreConditionsHidden] = useState(true);

    return (
        <div
            key={field.name}
            className={cn('row-formats-modal-content__form__row')}
        >
            <div className={cn('row-formats-modal-content__form__main')}>
                <div className={cn('row-formats-modal-content__form__row__top')}>
                    <CheckboxWithToggle
                        selected={selectedRows.includes(field.name)}
                        onToggleSelect={() => setSelectedRows(xor(selectedRows, [field.name]))}
                        fieldsCount={fieldsCount}
                        areConditionsHidden={areConditionsHidden}
                        setAreConditionsHidden={setAreConditionsHidden}
                    />
                    <Form.Item
                        rules={[{required: true}]}
                        label="Стиль"
                        name={[field.name, 'style']}
                        style={{width: 252}}
                    >
                        <CustomSelect
                            settings={{
                                url: 'lookupValue/TABLE_REPORT_CELL_STYLE',
                                valuePath: 'lookupCode',
                                labelPath: 'meaning',
                                placeholder: 'Выберите стиль',
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        style={{width: 110}}
                        label="Цвет шрифта"
                        name={[field.name, 'color']}
                    >
                        <ColorPicker mode="font" />
                    </Form.Item>
                    <Form.Item
                        style={{width: 110}}
                        label="Заливка"
                        name={[field.name, 'filling']}
                    >
                        <ColorPicker mode="fill" />
                    </Form.Item>
                    <Form.Item
                        name={[field.name, 'columns']}
                        label={(
                            <div>
                                Атрибуты
                                <Tooltip
                                    title="Если поле не заполнено,
                                    форматирование будет применено ко всей строке"
                                >
                                    <QuestionCircleOutlined style={{
                                        marginLeft: 6,
                                        color: '#B7C1D5',
                                    }}
                                    />
                                </Tooltip>
                            </div>
                        )}
                        style={{width: 296}}
                    >
                        <CustomSelect
                            entries={tableAttributesEntries}
                            settings={{
                                mode: CustomSelectMode.multiple,
                                placeholder: 'Выберите атрибуты',
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label={<>&nbsp;</>}
                    >
                        <Tooltip
                            title="Добавить условие"
                            placement="right"
                            mouseEnterDelay={0.8}
                        >
                            <Badge
                                count={fieldsCount}
                                size="small"
                            >
                                <Button
                                    type="primary"
                                    className="btn-only-icon"
                                    onClick={() => {
                                        conditionsRef.current?.add();
                                        setFieldsCount(p => (p ?? 0) + 1);
                                        setAreConditionsHidden(false);
                                    }}
                                >
                                    <PlusCircleOutlined />
                                </Button>
                            </Badge>
                        </Tooltip>
                    </Form.Item>
                </div>
                <div className={cn('row-formats-modal-content__form__row__bottom')}>
                    <div className={cn('row-formats-modal-content__form__row__bottom__line')} />
                    <div className={cn(
                        'row-formats-modal-content__form__row__bottom__conditions',
                        {'row-formats-modal-content__form__row__bottom__conditions_hidden': areConditionsHidden},
                    )}
                    >
                        <Form.List
                            name={[field.name, 'conditions']}
                        >
                            {(conditionsFields, conditionsListOperations) => (
                                <FormatItemConditions
                                    form={form}
                                    currentFieldPath={
                                        ['rowFormats', field.name, 'conditions']
                                    }
                                    ref={conditionsRef}
                                    conditionsFields={conditionsFields}
                                    conditionsListOperations={conditionsListOperations}
                                    setFieldsCount={setFieldsCount}
                                />
                            )}
                        </Form.List>
                    </div>
                </div>
            </div>
        </div>
    );
};
