import {CaretLeftOutlined, CaretRightOutlined} from '@ant-design/icons';
import {
    Button, Card, Layout, Menu,
} from 'antd';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {loadReferenceData} from 'modules/data/data-actions';
import {selectReferenceEntityData} from 'modules/data/data-selectors';
import {loadMetadata, selectMetadata} from 'modules/metadata';
import {EntityType} from 'shared/constants/entities';
import {useAppSelector} from 'store/config/hooks';

import {PROPERTY_SETTINGS_ENTITY_NAME} from '../report-configuration/report-configuration.constants';
import {Spinner} from '../spinner';
import {PropertySettingsForm} from './components/property-settings-form/property-settings-form';
import {URL_MENU} from './property-settings.constants';

import '../tabs-with-references/tabs-with-references.less';
import './property-settings.less';

const {Sider, Content} = Layout;

export const PropertySettings = () => {
    const dispatch = useDispatch();

    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<string>();
    const [isEdit, setIsEdit] = useState<boolean>(false);

    const menuData = useAppSelector(selectReferenceEntityData(URL_MENU));
    const metadata = useAppSelector(selectMetadata(PROPERTY_SETTINGS_ENTITY_NAME, EntityType.FORM));

    useEffect(() => {
        (async () => {
            if (!menuData?.length) {
                dispatch(loadReferenceData(URL_MENU));
            }
            if (!metadata) {
                dispatch(loadMetadata(PROPERTY_SETTINGS_ENTITY_NAME, EntityType.FORM));
            }
        })();
    }, []);

    useEffect(() => { if (menuData?.length) setSelectedItem(menuData[0]?.lookupCode as string); }, [menuData]);

    return (
        <Card
            title="Настройки приложения"
            className="tabs-with-references property-settings"
        >
            {menuData?.length ? (
                <Layout
                    className="sider-menu"
                    hasSider
                >
                    <Sider
                        theme="light"
                        collapsible
                        collapsed={isCollapsed}
                        width={405}
                        collapsedWidth={40}
                        trigger={null}
                    >
                        <Menu
                            mode="inline"
                            selectedKeys={[selectedItem || '']}
                        >
                            {menuData?.map(child => (
                                <Menu.Item
                                    key={child?.lookupCode as string}
                                    onClick={() => {
                                        setSelectedItem(child?.lookupCode as string);
                                        setIsEdit(false);
                                    }}
                                >
                                    {child.meaning}
                                </Menu.Item>
                            ))}
                        </Menu>
                    </Sider>
                    <Button
                        className="trigger"
                        type="text"
                        icon={isCollapsed
                            ? <CaretRightOutlined className="trigger_icon" />
                            : <CaretLeftOutlined className="trigger_icon" />}
                        onClick={() => setIsCollapsed(!isCollapsed)}
                    />

                    <Content className="content">
                        <PropertySettingsForm
                            selectedItem={selectedItem}
                            entityName={PROPERTY_SETTINGS_ENTITY_NAME}
                            actions={metadata?.actions}
                            isEdit={isEdit}
                            handleEdit={setIsEdit}
                        />
                    </Content>
                </Layout>
            ) : (<Spinner />)}

        </Card>
    );
};
