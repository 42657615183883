import {Popover} from 'antd';
import cn from 'classnames';
import React from 'react';

import {StatisticsDataEntryObject} from 'pages/svc-widget-page/hooks/use-statistics-initialization';

import {ProgressListStatisticsSettings} from '../progress-list-statistics-types';
import {ProgressBar} from './progress-bar';

import './progress-list-item.less';

interface ProgressListItemProps {
    entry: StatisticsDataEntryObject;
    settings: ProgressListStatisticsSettings;
}

export const ProgressListItem: React.FC<ProgressListItemProps> = ({entry, settings}: ProgressListItemProps) => {
    const {key, value, label} = entry;
    const {barColor} = settings;

    return (
        <div className={cn('progress-list-statistics__body__item')}>
            <Popover
                content={label}
                placement="right"
                overlayClassName={cn('progress-list-statistics__body__item__popover', {hidden: !label})}
            >
                <div className={cn('progress-list-statistics__body__item__title')}>{key}</div>
            </Popover>

            <ProgressBar
                value={value}
                maximum={100}
                barColor={barColor}
            />
            <div className={cn('progress-list-statistics__body__item__value')}>{value}</div>
        </div>
    );
};
