import {Button} from 'antd';
import cn from 'classnames';
import React, {
    useContext,
} from 'react';

import {DrillDownRuleInfo} from 'components/report-configuration/components/drill-down-rule-info';
import {ReactComponent as ArrowBackOutlined} from 'shared/assets/arrow-back-outlined.svg';
import {useQueryParams} from 'shared/utils/query-params';
import {useAppSelector} from 'store/config/hooks';
import {ddReferenceRulesSelectors} from 'store/slices/report-configuration-slice/report-configuration-slice';

import {ButtonChangeHistory} from '../../components/button-change-history';
import {DRILLDOWN_ID_PARAM_KEY, ReportConfigurationTabKey} from '../../report-configuration.constants';
import {ReportConfigurationContext} from '../../report-configuration.context';
import {DdParametersList} from './dd-parameters-list';
import {DdParametersUpdateModalOpener} from './dd-parameters-update-modal-opener';

import './report-dd-parameters.less';

interface ReportDdParametersProps {}

export const ReportDdParameters: React.FC<ReportDdParametersProps> = () => {
    const {setSelectedTabKey} = useContext(ReportConfigurationContext);

    const {query} = useQueryParams();

    const drillDownId = Number(query.get(DRILLDOWN_ID_PARAM_KEY));
    const drillDown = useAppSelector(state => ddReferenceRulesSelectors.selectById(state, drillDownId));

    return (
        <div className={cn('report-dd-parameters')}>

            {drillDown && (
                <div className={cn('report-dd-parameters__dd-rule')}>
                    <div className={cn('report-dd-parameters__dd-rule__label')}>
                        Правило связи:
                    </div>
                    <DrillDownRuleInfo drillDownRule={drillDown} />
                </div>
            )}

            <div className={cn('report-dd-parameters__buttons-bar')}>
                <DdParametersUpdateModalOpener />
                <ButtonChangeHistory />
                <Button
                    type="default"
                    icon={<ArrowBackOutlined />}
                    onClick={() => {
                        setSelectedTabKey?.(ReportConfigurationTabKey.REPORT_DD_FILTERS);
                    }}
                >
                    Вернуться на вкладку «Настройка правил фильтрации данных (ДД)»
                </Button>
            </div>
            <div className={cn('report-dd-parameters__section-title')} />
            <div className={cn('report-dd-parameters__body')}>
                <DdParametersList />
            </div>
        </div>
    );
};
