import {Button} from 'antd';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {selectEntityData} from 'modules/data';
import {saveEditableData} from 'modules/data/data-actions';
import {TableEntityData} from 'modules/data/data-types';
import {RequestType} from 'modules/metadata';
import {EntityType} from 'shared/constants/entities';
import {AppState} from 'store/config/types';

import {TableActionProps} from '../table-action-types';

import './button-action-save-table-rows.less';

export const ButtonActionSaveTableRow: React.FunctionComponent<TableActionProps> = ({
    meta,
    entityName,
    onClick,
    ...props
}: TableActionProps) => {
    const dispatch = useDispatch();
    const [sentData, setSentData] = useState<any[]>([]);
    const {rows} = useSelector((state: AppState) => (
        selectEntityData(entityName,
            EntityType.TABLE)(state) || {}) as TableEntityData);
    const handleClick = async () => {
        if (onClick) onClick();
        if (sentData?.length === 0 && rows) {
            const newSentData: any[] = [];
            await Promise.all(rows.map(item => {
                if (!item.isError) {
                    dispatch(saveEditableData(
                        entityName,
                        EntityType.TABLE,
                        RequestType.PUT,
                        item,
                        true,
                    ));
                    newSentData.push(item);
                }
                setSentData(newSentData);
                return undefined;
            }));
            return;
        }

        if (rows) {
            const newSentData = rows.filter(
                rowItem => (sentData.every(
                    sentItem => JSON.stringify(sentItem) !== JSON.stringify(rowItem),
                )
                    ? rowItem
                    : undefined
                ),
            );
            await Promise.all(newSentData.map(item => {
                if (!item.isError) {
                    dispatch(saveEditableData(
                        entityName,
                        EntityType.TABLE,
                        RequestType.PUT,
                        item,
                        true,
                    ));
                }
                return undefined;
            }));
            setSentData(prevState => {
                const newData = prevState;
                newSentData.forEach(sentItem => {
                    const index = prevState.findIndex(item => sentItem.id === item?.id);
                    const item = newData[index];
                    newData.splice(index, 1, {
                        ...item,
                        ...sentItem,
                    });
                });
                return [...newData];
            });
        }
    };

    return (
        <Button
            className="button-save-rows"
            {...props}
            onClick={handleClick}
        >
            {meta.actionTitle}
        </Button>
    );
};
