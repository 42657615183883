import {PlusCircleOutlined} from '@ant-design/icons';
import {
    Badge, Button, Form, Tooltip,
} from 'antd';
import {FormInstance} from 'antd/es/form';
import {FormListFieldData} from 'antd/es/form/FormList';
import cn from 'classnames';
import {xor} from 'lodash';
import React, {
    ReactElement,
    useEffect, useMemo,
    useRef,
    useState,
} from 'react';

import {CheckboxWithToggle} from 'components/@common/specific/сheckbox-with-toggle/checkbox-with-toggle';
import {DynamicIcon} from 'components/dynamic-icon';
import {ColorPicker} from 'components/form/inputs/color-picker';
import {CustomSelectEntry} from 'components/form/inputs/custom-select';
import {GenericCustomSelect} from 'components/form/inputs/custom-select/custom-select';
import {FormListOperationsRef} from 'components/report-configuration/report-configuration.types';
import {iconOptions} from 'components/report-configuration/tabs/report-table-settings/modals/icon-format-settings-modal-opener/icon-format-settings-modal-content/icon-format-form-item/icon-format-form-item.constants';
import {IconFormatItemConditions} from 'components/report-configuration/tabs/report-table-settings/modals/icon-format-settings-modal-opener/icon-format-settings-modal-content/icon-format-item-conditions';
import {StateSetter} from 'shared/types/generics';

interface FormatFormItemProps {
    form: FormInstance;
    name: number;
    fields: FormListFieldData[];
    field: FormListFieldData;
    selectedRows: number[];
    setSelectedRows: StateSetter<number[]>;
}

export const IconFormatFormItem: React.FC<FormatFormItemProps> = ({
    form,
    name,
    setSelectedRows,
    selectedRows,
    field,
    fields,
}: FormatFormItemProps) => {
    const conditionsRef = useRef<FormListOperationsRef | null>(null);

    const [fieldsCount, setFieldsCount] = useState<number | undefined>(
        form.getFieldValue(
            ['reportTableColumns', 'customColumns', name, 'cases', field.name, 'conditions'],
        )?.length,
    );

    const iconEntries: CustomSelectEntry<ReactElement, string>[] = useMemo(() => (
        iconOptions.map(opt => ({
            label: (
                <DynamicIcon
                    className="icon-options"
                    type={opt}
                />
            ),
            value: opt,
        }))
    ), []);

    useEffect(() => {
        setFieldsCount(form.getFieldValue(
            ['reportTableColumns', 'customColumns', name, 'cases', field.name, 'conditions'],
        )?.length);
    }, [fields]);

    const [areConditionsHidden, setAreConditionsHidden] = useState(true);

    return (
        <div
            key={field.name}
            className={cn('icon-format-modal-content__form__row')}
        >
            <div className={cn('icon-format-modal-content__form__main')}>
                <div className={cn('icon-format-modal-content__form__row__top')}>
                    <CheckboxWithToggle
                        selected={selectedRows.includes(field.name)}
                        onToggleSelect={() => setSelectedRows(xor(selectedRows, [field.name]))}
                        fieldsCount={fieldsCount}
                        areConditionsHidden={areConditionsHidden}
                        setAreConditionsHidden={setAreConditionsHidden}
                    />
                    <Form.Item
                        rules={[{required: true}]}
                        label="Иконка"
                        name={[field.name, 'icon']}
                        style={{width: 80}}
                    >
                        <GenericCustomSelect<ReactElement, string>
                            entries={iconEntries}
                            settings={{
                                placeholder: 'Нет',
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        style={{width: 110}}
                        label="Цвет"
                        name={[field.name, 'color']}
                    >
                        <ColorPicker mode="fill" />
                    </Form.Item>
                    <Form.Item
                        label={<>&nbsp;</>}
                    >
                        <Tooltip
                            title="Добавить условие"
                            placement="right"
                            mouseEnterDelay={0.8}
                        >
                            <Badge
                                count={fieldsCount}
                                size="small"
                            >
                                <Button
                                    type="primary"
                                    className="btn-only-icon"
                                    onClick={() => {
                                        conditionsRef.current?.add();
                                        setFieldsCount(p => (p ?? 0) + 1);
                                        setAreConditionsHidden(false);
                                    }}
                                >
                                    <PlusCircleOutlined />
                                </Button>
                            </Badge>
                        </Tooltip>
                    </Form.Item>
                </div>
                <div className={cn('icon-format-modal-content__form__row__bottom')}>
                    <div className={cn('icon-format-modal-content__form__row__bottom__line')} />
                    <div className={cn(
                        'icon-format-modal-content__form__row__bottom__conditions',
                        {'icon-format-modal-content__form__row__bottom__conditions_hidden': areConditionsHidden},
                    )}
                    >
                        <Form.List
                            name={[field.name, 'conditions']}
                        >
                            {(conditionsFields, conditionsListOperations) => (
                                <IconFormatItemConditions
                                    ref={conditionsRef}
                                    form={form}
                                    currentFieldPath={
                                        ['reportTableColumns', 'customColumns', name, 'cases', field.name, 'conditions']
                                    }
                                    conditionsFields={conditionsFields}
                                    conditionsListOperations={conditionsListOperations}
                                    setFieldsCount={setFieldsCount}
                                />
                            )}
                        </Form.List>
                    </div>
                </div>
            </div>
        </div>
    );
};
