import React, {createContext} from 'react';

export interface SettingProgramContextArgs {
    entityName: string;
    selectedTabKey: React.Key | null;
    setSelectedTabKey: React.Dispatch<React.SetStateAction<React.Key>> | null;
}

export const SettingProgramContext = createContext<SettingProgramContextArgs>({
    entityName: '',
    selectedTabKey: null,
    setSelectedTabKey: null,
});
