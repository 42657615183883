import {CreateTransportContainerRequest} from '../transport-container.api';

export const transformCallTCServiceDataRequest = (
    tc: Omit<CreateTransportContainerRequest, 'containerId' | 'stage'>,
) => {
    const formData = new FormData();
    Object.entries(tc)
        .forEach(([key, value]) => {
            if (Array.isArray(value) && value.some(el => el instanceof Blob)) {
                value.forEach((file:any) => {
                    formData.append(key, file);
                });
                return;
            }

            if (value instanceof Blob) {
                formData.append(`${key}Path`, value.name);
            }

            if (value) formData.append(key, value as string | Blob);
        });

    return formData;
};
