import {Button, message} from 'antd';
import {FormInstance} from 'antd/es/form';
import cn from 'classnames';
import {debounce, set, union} from 'lodash';
import React, {useEffect, useState} from 'react';
import {v4 as uid} from 'uuid';

import {FileUploadModal} from 'components/documents/components/file-upload-modal';
import {FormsFileModalContext} from 'components/documents/file-modal/file-modal.context';
import {IconsMap} from 'components/dynamic-icon';
import {setDocumentScans} from 'modules/data/data-actions';
import {loadDocumentsForUploadAttach} from 'modules/documents/documents-actions';
import {
    fetchDocumentAttachInfo, fetchDocumentScansById, uploadAttachedToDocument,
} from 'modules/documents/documents-api';
import {selectDocumentsForUploadAttach} from 'modules/documents/documents-selectors';
import {DocumentUploadScan, FileUploadStatus} from 'modules/documents/documents-types';
import {removeExtraPartFromEntityName} from 'modules/documents/documents-utils';
import {EntityType} from 'shared/constants/entities';
import {StateSetter} from 'shared/types/generics';
import {showMessage} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';

import {TableActionProps} from '../table-action-types';
import {SCAN_UPLOAD_UID, getUploadScanFilesColumnsMeta} from './button-upload-scan-files-constants';
import {updateFileInScanFilesList} from './button-upload-scan-files-utils';

interface ButtonUploadScanFilesProps extends TableActionProps {}

const scanFiles: {[entityName: string]: DocumentUploadScan[]} = {}; // Для восстановления
// текущих загрузок, если компонент сделал unmount -> mount.
const scanFilesSetters: {
    [entityName: string]: StateSetter<DocumentUploadScan[]>;
} = {}; // Для корректного обновления состояний загрузок, если компонент сделал unmount -> mount.

interface UseFilesListArgs {
    entityName: string;
}

const useScanFilesList = ({entityName}: UseFilesListArgs) => {
    const [scanFilesList, setScanFilesList] = useState<DocumentUploadScan[]>(scanFiles[entityName] ?? []);

    useEffect(() => {
        set(scanFilesSetters, [entityName], setScanFilesList);

        return () => {
            delete scanFilesSetters[entityName];
        };
    }, []);

    useEffect(() => {
        scanFiles[entityName] = scanFilesList;
    }, [scanFilesList]);

    return {
        scanFilesList,
        setScanFilesList,
    };
};

export const ButtonUploadScanFiles: React.FC<ButtonUploadScanFilesProps> = (
    {entityName}: ButtonUploadScanFilesProps,
) => {
    const dispatch = useAppDispatch();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [submitErrorMessage, setSubmitErrorMessage] = useState<string | null>(null);
    const [modalForms, setModalForms] = useState<FormInstance[]>([]);

    const {scanFilesList, setScanFilesList} = useScanFilesList({entityName});

    const sublistCode = `app.${removeExtraPartFromEntityName(entityName)}`;
    const documentsForUploadAttach = useAppSelector(s => selectDocumentsForUploadAttach(s, sublistCode));

    const generateColumnsMeta = () => getUploadScanFilesColumnsMeta({
        handleDelete: record => {
            setScanFilesList(p => p.filter(file => file[SCAN_UPLOAD_UID] !== (record as any)[SCAN_UPLOAD_UID]));
        },
        documentsForUploadAttach,
    });

    const [columns, setColumns] = useState(generateColumnsMeta());

    useEffect(() => {
        if (!isModalVisible) return;
        dispatch(loadDocumentsForUploadAttach({sublistCode}));
    }, [isModalVisible]);

    useEffect(() => {
        setColumns(generateColumnsMeta());
    }, [documentsForUploadAttach]);

    const handleUpload = debounce((_, files: File[]) => {
        const newFiles: DocumentUploadScan[] = files.map(f => ({
            file: f,
            fileName: f.name,
            fileSize: `${f.size}`,
            uploadDate: new Date().toString(),
            title: f.name,
            [SCAN_UPLOAD_UID]: uid(),
        }));

        setScanFilesList(p => [
            ...p,
            ...newFiles,
        ]);
    }, 0);

    const handleSubmit = async () => {
        try {
            await Promise.all(modalForms.map(form => form.validateFields()));
            setSubmitErrorMessage(null);

            const filesToUpload = scanFilesList
                .filter((scan: DocumentUploadScan) => !scan.isSubmitted && scan.documentFileId);

            if (!filesToUpload.length) {
                showMessage({
                    message: 'Нет доступных для загрузки файлов',
                    isError: true,
                });
                return;
            }

            const uploadMessageId = uid();
            message.loading({
                key: uploadMessageId,
                content: `Загрузка файлов-приложений... (Выбрано файлов: ${filesToUpload.length})`,
                duration: 0,
            });

            setIsModalVisible(false);

            try {
                await Promise.all(filesToUpload
                    .map((scan: DocumentUploadScan) => {
                        if (!scan.documentFileId) return undefined;

                        setScanFilesList(p => updateFileInScanFilesList(p, scan[SCAN_UPLOAD_UID], {
                            isSubmitted: true,
                        }));

                        return uploadAttachedToDocument({
                            docId: `${scan.documentFileId}`,
                            file: scan.file as File,
                            handleProgress: progress => {
                                const {loaded, total} = progress;
                                const percent = Math.trunc((loaded / total) * 100);
                                const setScansFileList = scanFilesSetters?.[entityName];

                                setScansFileList?.(p => updateFileInScanFilesList(p, scan[SCAN_UPLOAD_UID], {
                                    progress: {
                                        percent,
                                        status: percent === 100 ? FileUploadStatus.SUCCESS : undefined,
                                    },
                                }));

                                if (percent === 100) {
                                    setTimeout(() => {
                                        setScansFileList?.(p => p.filter(
                                            _scan => _scan[SCAN_UPLOAD_UID] !== scan[SCAN_UPLOAD_UID],
                                        ));
                                    }, 1000);
                                }
                            },
                        });
                    }));

                union(filesToUpload
                    .map(file => file.documentFileId))
                    .filter(docId => docId !== undefined)
                    .forEach(async docId => {
                        const {data: scans} = await fetchDocumentScansById({docId: `${docId}`});
                        dispatch(setDocumentScans({
                            entityName,
                            entityType: EntityType.TABLE,
                            scans,
                            docId: `${docId}`,
                        }));
                    });

                message.success({
                    key: uploadMessageId,
                    content: 'Файлы-приложения успешно загружены',
                    duration: 2,
                });
            } catch {
                message.error({
                    key: uploadMessageId,
                    content: 'Ошибка при загрузке файлов',
                    duration: 2,
                });
            }
        } catch (e) {
            setSubmitErrorMessage('Необходимо заполнить все обязательные поля');
        }
    };

    const handleEdit = (async (data: DocumentUploadScan) => {
        if (!data.documentFileId) return;

        const chosenDocument = (await fetchDocumentAttachInfo({
            attachId: `${data.documentFileId}`,
        })).data;

        setScanFilesList(p => p.map(uploadedFile => {
            if (uploadedFile[SCAN_UPLOAD_UID] === data[SCAN_UPLOAD_UID]) {
                return {
                    ...data,
                    documentName: chosenDocument.docName,
                    documentTypeName: chosenDocument.docType,
                    sectionCode: chosenDocument.docSection,
                };
            }
            return uploadedFile;
        }));
    }) as any;

    return (
        <div className={cn('table-action')}>
            <Button
                type="primary"
                onClick={() => {
                    setIsModalVisible(true);
                }}
            >
                <IconsMap.File />
                Загрузка документов
            </Button>

            {isModalVisible && (
                <FormsFileModalContext.Provider
                    value={{
                        forms: modalForms,
                        setForms: setModalForms,
                    }}
                >
                    <FileUploadModal
                        modalTitle="Загрузка документов"
                        columns={columns}
                        columnsFiltered
                        errorMsg={submitErrorMessage}
                        fileList={scanFilesList}
                        handleEdit={handleEdit}
                        onClose={() => {
                            setIsModalVisible(false);
                        }}
                        onSubmit={handleSubmit}
                        onUpload={handleUpload}
                        isSubmitButtonDisabled={
                            scanFilesList.some(file => file.documentFileId === undefined)
                        }
                        isPageJumperEnabled
                        isPageSizeChangerEnabled
                        onClear={() => {
                            setScanFilesList([]);
                        }}
                    />
                </FormsFileModalContext.Provider >
            )}
        </div>
    );
};
