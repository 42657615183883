import React, {useState} from 'react';
import {useDispatch} from 'react-redux';

import {selectEntityData} from 'modules/data';
import {
    filterNonRenderableFieldsAndUpdateState as filterNonRenderableFieldsAndUpdateStateAction,
    loadFormPageData,
} from 'modules/data/data-actions';
import {ListEntityData} from 'modules/data/data-types';
import {selectMetadata, loadMetadata as loadMetadataAction} from 'modules/metadata';
import {resetMetadata} from 'modules/metadata/metadata-actions';
import {ListEntityMeta} from 'modules/metadata/metadata-types';
import {EntityType} from 'shared/constants/entities';
import {useAppSelector} from 'store/config/hooks';
import {selectUserInfo} from 'store/slices/auth-slice';

import {Spinner} from '../spinner';
import {List} from './List';

interface ListComponentProps {
    entityName: string;
}

export const ListContainer: React.FunctionComponent<ListComponentProps> = ({
    entityName,
}: ListComponentProps) => {
    const dispatch = useDispatch();

    const userInfo = useAppSelector(selectUserInfo);
    const metadata = useAppSelector(selectMetadata(entityName, EntityType.LIST)) as ListEntityMeta;
    const listData = useAppSelector(selectEntityData(entityName, EntityType.FORM)) as ListEntityData;

    const [isLoading, setIsLoading] = React.useState(false);
    const [fieldsFiltered, setFieldsFiltered] = useState<boolean>(false);

    React.useEffect(() => {
        (async () => {
            if (!metadata) {
                setIsLoading(true);
                await dispatch(loadMetadataAction(entityName, EntityType.LIST));
                setIsLoading(false);
            }

            if (!listData) {
                setIsLoading(true);
                await dispatch(loadFormPageData(entityName, entityName));
                setIsLoading(false);
            }
        })();
        return () => {
            dispatch(resetMetadata({
                entityType: EntityType.LIST,
                loading: false,
                entityName: entityName || '',
            }));
        };
    }, []);

    React.useEffect(() => {
        if (metadata?.fields && !fieldsFiltered) {
            dispatch(
                filterNonRenderableFieldsAndUpdateStateAction(
                    metadata,
                    metadata.name,
                    EntityType.LIST,
                    setFieldsFiltered,
                ),
            );
        }
    }, [metadata?.fields, userInfo]);

    return (
        isLoading
            ? <div className="list-modal__spinner"><Spinner /></div>
            : (
                <List
                    listData={listData}
                    meta={metadata}
                />
            )
    );
};
