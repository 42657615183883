import React from 'react';

import {DirectoryForm} from 'components/directory-form';
import {PageTemplateProps} from 'pages';

export interface DirectoryFormPageProps extends PageTemplateProps {
}

export const DirectoryFormPage = ({...props}: DirectoryFormPageProps) => (
    <DirectoryForm {...props} />
);
