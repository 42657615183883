import {Popover} from 'antd';
import cn from 'classnames';
import React from 'react';

import {DynamicIcon} from 'components/dynamic-icon';
import {ModalOpenerComponent} from 'components/modal-opener-component';
import {ReportDdReferenceRule} from 'store/slices/report-configuration-slice/dd-references-configuration';

import {ExtraAttributesModalOpenerContent} from './extra-attributes-modal-opener-content';

import './extra-attributes-modal-opener.less';

interface ExtraAttributesModalOpenerProps {
    referenceRuleRecord: ReportDdReferenceRule;
}

export const ExtraAttributesModalOpener: React.FC<ExtraAttributesModalOpenerProps> = ({
    referenceRuleRecord,
}: ExtraAttributesModalOpenerProps) => (
    <div className={cn('extra-attributes-modal-opener')}>
        <ModalOpenerComponent
            handleSave={setIsModalOpen => {
                setIsModalOpen(false);
            }}
            modalProps={{
                destroyOnClose: true,
                forceRender: false,
                title: 'Дополнительные атрибуты',
            }}
            hideControls={{save: true}}
            controlLabels={{cancel: 'Закрыть'}}
            component={(
                <Popover
                    overlayClassName="extra-attributes-modal-opener__popover"
                    placement="bottomRight"
                    content={() => 'Дополнительные атрибуты'}
                >
                    <DynamicIcon
                        type="Settings"
                        className="extra-attributes-modal-opener__settings-icon"
                        style={{cursor: 'pointer'}}
                    />
                </Popover>
            )}
        >
            <ExtraAttributesModalOpenerContent referenceRuleRecord={referenceRuleRecord} />
        </ModalOpenerComponent>
    </div>

);
