export const SETTING_TEMPLATE_TRANSFER_TARGET_FORM_DATA_KEY = 'excelDataMappings';
export const SETTING_TEMPLATE_TRANSFER_TARGET_FIELD_TYPE = 281;
export const SettingTemplateTransferTargetMainFields: string[] = [
    'attribute',
    'sourceField',
    'targetField',
    'sourceFieldDynamicSql',
    'sourceFieldConstant',
    'fieldTypeId',
    'description',
    'fieldFormat',
    'fieldRegex',
];
export enum SettingTemplateTransferTargetFormFields {
    attribute = 'attribute',
    sourceField = 'sourceField',
    targetField = 'targetField',
    sourceFieldDynamicSql = 'sourceFieldDynamicSql',
    sourceFieldConstant = 'sourceFieldConstant',
    fieldTypeId = 'fieldTypeId',
    description = 'description',
    fieldFormat = 'fieldFormat',
    fieldRegex = 'fieldRegex',
    attribute1 = 'attribute1',
    attribute2 = 'attribute2',
}
