import {Checkbox, Select} from 'antd';
import _ from 'lodash';
import React, {useMemo, useState} from 'react';

import {SearchableDropdown} from 'components/form/inputs/reference-select/searchable-dropdown';

import {
    CustomSelectEntryHighlighter,
} from '../../components/custom-select-entry-highlighter/custom-select-entry-highlighter';
import {CustomSelectEntry, CustomSelectMode, CustomSelectProps} from '../../custom-select';

interface CustomSelectConfigProps<L, V> {
    mode?: CustomSelectMode;
    showSearch?: boolean;
    entriesToRender: CustomSelectEntry<L, V>[];
    formatFieldValueForSelect: (value: any) => string;
    optionsFilterCallback?: CustomSelectProps<L, V>['optionsFilterCallback'];
    optionsLabelFormatter?: (label: L, value: V) => any;
}

export const useCustomSelectConfig = <L, V>({
    mode,
    showSearch,
    entriesToRender,
    optionsFilterCallback,
    formatFieldValueForSelect,
    optionsLabelFormatter,
}: CustomSelectConfigProps<L, V>) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownInputValue, setDropdownInputValue] = useState<string>('');

    const filterEntry = (input: string, option: any) => option?.children?.toLowerCase?.()
        .includes(input?.toLowerCase?.());

    const filteredEntries = useMemo(() => {
        const entriesFilteredRaw = (() => {
            let entriesToFilter = entriesToRender;
            if (dropdownInputValue) {
                entriesToFilter = entriesToRender
                    .filter(entry => (_.isString(entry.label)) && (entry.label.toLowerCase()
                        .search(dropdownInputValue.toLowerCase()) >= 0));
            }
            entriesToFilter = entriesToFilter
                .filter(entry => optionsFilterCallback?.({entry, entriesToRender}) !== false);
            return entriesToFilter;
        })();

        const entriesFilteredJSX = entriesFilteredRaw.map(entry => (
            <Select.Option
                key={formatFieldValueForSelect(entry.value)}
                value={formatFieldValueForSelect(entry.value)}
                className="select-field__option"
            >
                {mode === CustomSelectMode.multiple && showSearch && typeof entry.label === 'string' ? (
                    <CustomSelectEntryHighlighter
                        text={entry.label}
                        search={dropdownInputValue}
                    />
                ) : optionsLabelFormatter ? optionsLabelFormatter(entry.label, entry.value) : entry.label}
            </Select.Option>
        ));

        return {
            entriesFilteredJSX,
            entriesFilteredRaw,
        };
    }, [dropdownInputValue, entriesToRender]);

    const multipleModeDropdownWithSearch = (menu: React.ReactElement) => (
        <SearchableDropdown
            menu={menu}
            inputValue={dropdownInputValue}
            setInputValue={setDropdownInputValue}
        />
    );

    const setDropdownSelectedIcon = (p: any) => (p?.isSelected ? <Checkbox checked />
        : <Checkbox checked={false} />);

    return {
        dropdownOpen,
        setDropdownOpen,
        dropdownInputValue,
        setDropdownInputValue,
        filterEntry,
        filteredEntries,
        setDropdownSelectedIcon,
        multipleModeDropdownWithSearch,
    };
};
