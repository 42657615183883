import {FormInstance} from 'antd/es/form';
import {isNumber} from 'lodash';
import React, {ReactText} from 'react';

import {useDefaultValueByRule} from 'components/form/hooks/field-default-value.hook';
import {selectContextRawData} from 'modules/context/context-selector';
import {selectEntityData, Entity} from 'modules/data';
import {loadReferenceData} from 'modules/data/data-actions';
import {
    selectReferenceDataLoading, selectReferenceEntityData, selectReferencePlaceholder,
} from 'modules/data/data-selectors';
import {
    FilterEntityData,
} from 'modules/data/data-types';
import {
    FieldMeta,
    IAdditionalInputOptions,
    ReferenceResolver,
} from 'modules/metadata/metadata-types';
import {EntityType} from 'shared/constants/entities';
import {createQueryParams} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';

export const getReferenceUrl = (
    referenceUrl: string,
    params?: Record<string, any>,
    defaultParams?: Record<string, any>,
    filterData?: Record<string, any>,
    useContext?: boolean,
    context?: Record<string, any>,
    form?: FormInstance,
) => {
    let allParams = {};
    let isAllParams = false;
    const needParams = params && Object.keys(params).length;
    const needDefaultParams = defaultParams && Object.keys(defaultParams).length;
    const needFilterData = filterData && Object.keys(filterData).length;
    // используется для запроса valueLists
    const sublistId = filterData && Object.values(filterData).length && Object.values(filterData)[0]?.id;
    const sublistCode = form?.getFieldValue('numberId');

    const contextIds = {
        organizationId: context?.organizationId?.id,
        taxPeriodId: context?.taxPeriodId?.id,
        taxTypeId: context?.taxTypeId,
    };

    if (needParams) {
        allParams = {
            ...allParams,
            ...params,
        };
        isAllParams = true;
    }

    if (needDefaultParams) {
        allParams = {
            ...allParams,
            ...defaultParams,
        };
        isAllParams = true;
    }

    if (needFilterData) {
        allParams = {
            ...allParams,
            ...(sublistCode && {
                sublistCode: sublistCode?.id || sublistCode,
            }),
            sublistId,
        };
        isAllParams = true;
    }

    if (useContext) {
        allParams = {
            ...allParams,
            ...contextIds,
        };
        isAllParams = true;
    }
    const queryParams = isAllParams ? createQueryParams(allParams, referenceUrl) : '';
    return `${referenceUrl}${queryParams}`;
};

export const useReferenceSelectOptions = (
    props: {
        entityName: string;
        referenceUrl: string;
        onNotCreateKey?: boolean;
        displayFieldKey?: string;
        requestFieldKey?: string;
        onChange?: (value: any) => void;
        dependentInputKey?: string;
        additionalInputOptions?: IAdditionalInputOptions[];
        referenceResolver: ReferenceResolver;
        value?: Entity;
        useContext?: boolean;
        isDependsOnMainFilter?: string;
        referenceParamsDefault?: Record<string, any>;
        referenceParamsValues?: Record<string, any>;
        isSetFilter?: boolean;
        useFirstOptionAsDefaultValue?: boolean;
        isFieldWithoutValue?: boolean;
        dependentFieldValues?: any;
        defaultValue?: string;
        form?: any;
        field?: FieldMeta;
        defaultValueByRule?: string;
    },
) => {
    const {
        entityName,
        useContext,
        additionalInputOptions,
        isSetFilter,
        referenceResolver,
        referenceUrl,
        referenceParamsValues,
        referenceParamsDefault,
        useFirstOptionAsDefaultValue,
        value,
        onChange,
        displayFieldKey,
        requestFieldKey,
        dependentInputKey,
        onNotCreateKey,
        isDependsOnMainFilter,
        isFieldWithoutValue,
        defaultValue,
        form,
        field,
        defaultValueByRule,
    } = props;
    const dispatch = useAppDispatch();
    const [options, setOptions] = React.useState<Entity[]>([]);
    const [useDefaultValue, setUseDefaultValue] = React.useState(useFirstOptionAsDefaultValue);
    const placeholder = useAppSelector(selectReferencePlaceholder(referenceUrl)) || field?.placeholder;
    const context = useAppSelector(selectContextRawData);
    const filterData = useAppSelector(
        state => selectEntityData(entityName, EntityType.FILTER)(state) as FilterEntityData,
    );

    const filter = isDependsOnMainFilter ? filterData : undefined;
    // параметры для запроса который получает опции
    const resolvedReferenceParams = getReferenceUrl(
        referenceUrl,
        referenceParamsValues,
        referenceParamsDefault,
        filter?.data,
        useContext,
        context,
        form,
    );
    const getSelectedValuesFromOptions = (selectedValue: ReactText | ReactText[]) => (Array.isArray(selectedValue)
        ? options.filter(option => selectedValue.includes(option.value as ReactText))
        : options.find(option => option.value === selectedValue));

    const data = useAppSelector(selectReferenceEntityData(resolvedReferenceParams));
    const loading = useAppSelector(selectReferenceDataLoading(resolvedReferenceParams));

    const {getDefaultValue} = useDefaultValueByRule(entityName, field);
    const handleChange = (selectedValue: ReactText | ReactText[]) => {
        const newValue: any = getSelectedValuesFromOptions(selectedValue);
        setUseDefaultValue(false);
        if (isSetFilter && newValue?.id && dependentInputKey) {
            onChange?.({...newValue, dependentInputKey});
            return;
        }
        onChange?.(newValue);
    };

    React.useEffect(() => {
        let shouldUpdateAdditionalOptions;
        if (data && additionalInputOptions) {
            shouldUpdateAdditionalOptions = data[0].meaning !== additionalInputOptions[0].label;
        }
        if ((!data?.length && data?.length !== 0 && !loading && resolvedReferenceParams !== 'undefined')
            || shouldUpdateAdditionalOptions) {
            dispatch(loadReferenceData(
                resolvedReferenceParams,
                {
                    onNotCreateKey,
                    displayFieldKey,
                    dependentInputKey,
                    additionalInputOptions,
                    isSetFilter,
                    requestFieldKey,
                },
            ));
        }
    }, [resolvedReferenceParams, additionalInputOptions]);

    const normalizeValue = (
        currentValue: any,
    ) => {
        let resValue: string | string[] | undefined;
        if (typeof currentValue === 'string') {
            resValue = currentValue.split(/,\s*/);
        } else if (Array.isArray(currentValue)) {
            resValue = currentValue.map(record => referenceResolver(record)?.value || record);
            return resValue?.length === 1 ? resValue[0] : resValue;
        } else if (currentValue) {
            resValue = referenceResolver(currentValue)?.value
                || referenceResolver(currentValue)?.label
                || referenceResolver(currentValue)?.meaning
                || currentValue;
        }
        return resValue;
    };

    const valueReferenceSelect = () => {
        if (data?.length && isNumber(value) && field?.key) {
            const currentValue = data?.find(item => item?.id === value);
            if (currentValue) {
                form?.setFieldsValue({
                    [field?.key]: currentValue,
                });
            }
            return normalizeValue(currentValue);
        }
        if (useDefaultValue && options[0]?.value) {
            const normalizedValue = normalizeValue(value || options[0]);
            handleChange(normalizedValue as string);
            return normalizedValue;
        }
        if (!value && isFieldWithoutValue) {
            return String(options[0]?.label);
        }
        if (field?.referenceValueKey && typeof value === 'object') {
            const currentValue = data?.find(
                item => item?.[field.referenceValueKey || ''] === value[field.referenceValueKey || ''],
            );
            return normalizeValue(currentValue);
        }
        return normalizeValue(value);
    };

    const defaultValueOption = () => {
        if (defaultValue && field) {
            const resValue = options.find(option => option.lookupCode === defaultValue || option.id === defaultValue);
            form?.setFieldsValue({
                [field?.key]: resValue,
            });
            return normalizeValue(resValue);
        }
        if (defaultValueByRule && field) {
            const defaultRuleValue = getDefaultValue();
            if (defaultRuleValue) {
                form?.setFieldsValue({
                    [field?.key]: defaultRuleValue,
                });
                return normalizeValue(defaultRuleValue);
            }
        }
        if (useDefaultValue && options[0]?.value) {
            return normalizeValue(options[0]);
        }
        return undefined;
    };

    React.useEffect(() => {
        const allData = data?.map(referenceResolver) || [];

        if (allData && allData.length) {
            setOptions(allData);
        }
    }, [data]);

    React.useEffect(() => {
        defaultValueOption();
    }, []);

    return {
        normalizeValue,
        options,
        getSelectedValuesFromOptions,
        valueReferenceSelect,
        handleChange,
        data,
        defaultValueOption,
        loading,
        placeholder,
    };
};
