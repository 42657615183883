import {InfoCircleOutlined} from '@ant-design/icons';
import {Button, Tooltip} from 'antd';
import React from 'react';

import {createActionGetFile} from 'modules/data/data-actions';
import {
    MonitoringProgram, MonitoringResultLog,
} from 'modules/documents/documents-upload-monitoring-types';
import {RequestType} from 'modules/metadata';
import {useAppDispatch} from 'store/config/hooks';

import './documents-upload-log.less';

export interface DocumentsUploadLogProps {
    program: MonitoringProgram;
    log: MonitoringResultLog;
}

export const DocumentsUploadLog = ({program, log}: DocumentsUploadLogProps) => {
    const dispatch = useAppDispatch();
    const handleLogDownload = async (monitorProgramRequest: number) => {
        await dispatch(createActionGetFile({
            isMessage: true,
            requestType: RequestType.GET,
            referenceUrl: `administration.monitor.programs/download/${monitorProgramRequest}`,
        }));
    };

    return (
        <div className="monitoring-log">
            <div className="program-name">{program.name}</div>
            <Tooltip
                placement="top"
                title={log?.description || 'Для данной программы нет лога'}
            >
                <InfoCircleOutlined />
            </Tooltip>
            {log?.monitorProgramRequest && log?.nameFile && (
                <Button
                    type="default"
                    onClick={() => handleLogDownload(log.monitorProgramRequest)}
                >Выгрузить лог
                </Button>
            )}
        </div>
    );
};
