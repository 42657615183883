import React from 'react';
import {
    Route, Switch, useHistory, useLocation,
} from 'react-router-dom';

import {DocumentComparison} from 'components/document-comparison/document-comparison';
import {JasperReport} from 'components/jasper-report/jasper-report';
import {RequestContainer} from 'components/request';
import {Table} from 'components/table';
import {PageTemplateProps} from 'pages';
import {NotFoundPage} from 'pages/not-found-page';
import {REPORT_TYPE_NOT_FOUND, URL_COMPARISON, URL_REQUESTS} from 'shared/constants/urls';

import {QueryParamsAwareTable} from './query-params-aware-table/query-params-aware-table';
import {TableWithOpenForm} from './table-with-open-form';

export interface TablePageProps extends PageTemplateProps {
    hideTitle?: boolean;
}

export const TablePage: React.FunctionComponent<TablePageProps> = ({
    entityName,
    url,
    hideTitle,
    entityType,
    scroll,
}: TablePageProps) => {
    const location = useLocation();
    const history = useHistory();
    const handleClose = () => {
        history.go(-1);
    };

    return (
        <Switch>
            <Route
                path={url}
                exact
                render={() => (
                    <QueryParamsAwareTable
                        url={url}
                        hideTitle={hideTitle}
                        entityName={entityName}
                        referenceUrl={location.pathname.slice(1)}
                        scroll={scroll}
                    />
                )}
            />
            <Route
                path={`${url}/${URL_COMPARISON}`}
                exact
                render={() => (
                    <DocumentComparison
                        entityName={URL_COMPARISON}
                    />
                )}
            />
            <Route
                path={`${url}/reports/:id/jasper`}
                exact
                render={props => (
                    <JasperReport
                        docId={props?.match.params.id}
                        entityName={entityName}
                    />
                )}
            />
            <Route
                path={`${url}${REPORT_TYPE_NOT_FOUND}`}
                exact
                render={() => (
                    <NotFoundPage
                        message="Не удалось сформировать ссылку на отчет - тип отчета не найден"
                    />
                )}
            />
            <Route
                path={`${url}/:id`}
                render={props => {
                    const id = props?.match.params.id;
                    if (url.includes('.subtables')) {
                        return (
                            <Table
                                hideTitle={hideTitle}
                                entityName={entityName}
                                url={url}
                                entityType={entityType}
                                scroll={scroll}
                            />
                        );
                    }
                    if (url.includes(URL_REQUESTS)) {
                        return (
                            <RequestContainer
                                id={String(id)}
                                entityName={entityName}
                                referenceUrl={url}
                                onClose={handleClose}
                            />
                        );
                    }

                    return (
                        <TableWithOpenForm
                            url={url}
                            hideTitle={hideTitle}
                            entityName={entityName}
                            onClose={handleClose}
                            referenceUrl={location.pathname.slice(1)}
                            scroll={scroll}
                        />
                    );
                }}
            />
        </Switch>
    );
};
