import {MonitorServicesApi} from 'store/api/monitor-services/monitor-services.api';

import {AppControlProceduresResultApi} from './app-control-procedures-result/app-control-procedures-result.api';
import {AppPropertiesApi} from './app-properties/app-properties.api';
import {rtkQueryErrorLogger} from './error-logger';
import {FormAdditionalOptionsApi} from './form-additional-options/form-additional-options';
import {GeneralSettingsAPI} from './general-settings/general-settings.api';
import {ReclaimingInformationApi} from './reclaiming-information/reclaiming-information.api';
import {DashboardAPI} from './settings-dashboards/settings-dashboards.api';
import {TransportContainerApi} from './transport-container/transport-container.api';

export const apiReducers = {
    [AppPropertiesApi.reducerPath]: AppPropertiesApi.reducer,
    [TransportContainerApi.reducerPath]: TransportContainerApi.reducer,
    [AppControlProceduresResultApi.reducerPath]: AppControlProceduresResultApi.reducer,
    [FormAdditionalOptionsApi.reducerPath]: FormAdditionalOptionsApi.reducer,
    [MonitorServicesApi.reducerPath]: MonitorServicesApi.reducer,
    [DashboardAPI.reducerPath]: DashboardAPI.reducer,
    [GeneralSettingsAPI.reducerPath]: GeneralSettingsAPI.reducer,
    [ReclaimingInformationApi.reducerPath]: ReclaimingInformationApi.reducer,
};

export const apiMiddlewares = [
    rtkQueryErrorLogger,
    AppPropertiesApi.middleware,
    TransportContainerApi.middleware,
    AppControlProceduresResultApi.middleware,
    FormAdditionalOptionsApi.middleware,
    MonitorServicesApi.middleware,
    DashboardAPI.middleware,
    GeneralSettingsAPI.middleware,
    ReclaimingInformationApi.middleware,
];
