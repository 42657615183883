import {InputNumber as AntInputNumber} from 'antd';
import React from 'react';

import './input-number.less';

export const InputNumber = ({...props}: any) => (
    <AntInputNumber
        className="input__number"
        {...props}
    />
);
