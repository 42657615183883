import {SettingOutlined} from '@ant-design/icons';
import {
    Button, Empty, Form, Input, InputNumber, Tooltip,
} from 'antd';
import {FormInstance} from 'antd/lib/form';
import {NamePath} from 'antd/lib/form/interface';
import cn from 'classnames';
import React, {useContext} from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {CustomSelectValueTriggerEvent} from 'components/form/inputs/custom-select/custom-select';
import {
    ReportConfigurationTabKey, TypeGroup,
} from 'components/report-configuration/report-configuration.constants';
import {ReportConfigurationContext} from 'components/report-configuration/report-configuration.context';
import {DD_LINK_COLUMN_KEY} from 'components/report-configuration/tabs/report-dd-references/report-dd-references-constants';
import {StateSetter} from 'shared/types/generics';
import {useQueryParams} from 'shared/utils/query-params/use-query-params.hook';
import {ReportConfigurationReportColumnDto} from 'store/slices/report-configuration-slice/report-configuration-dto';

import {FormatSettingsModalOpener} from '../../modals/format-settings-modal-opener';
import {SecuritySettingsModal} from '../../modals/security-settings-modal';

interface ReportTableSettingsFieldsProps {
    form: FormInstance;
    isDisabled: boolean;
    name: NamePath;
    isHidden?: boolean;
    hiddenFields: Record<string, boolean>;
    setHiddenFields: StateSetter<Record<string, boolean>>;
}

export const ReportTableSettingsFields = ({
    isDisabled, form, name, isHidden,
    hiddenFields, setHiddenFields,
}: ReportTableSettingsFieldsProps) => {
    const {updateQueryParams} = useQueryParams();
    const {setSelectedTabKey} = useContext(ReportConfigurationContext);

    return (
        <Form.List name={name}>
            {(fields, operations: {}, {errors}) => (
                <>
                    <Form.ErrorList errors={errors} />
                    {
                        !fields?.length && !isHidden && (
                            <Empty
                                description="Атрибуты источника данных табличной части не найдены"
                            />
                        )
                    }
                    <div className={cn('form-list', {isHidden})}>
                        {fields?.map(field => (
                            <div
                                key={field.fieldKey}
                                className={cn('form-list__items')}
                            >
                                <Form.Item
                                    style={{width: 280}}
                                    label="Атрибут"
                                    name={[field.name, 'keyName']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Пожалуйста, выберите атрибут',
                                        },
                                    ]}
                                >
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item
                                    style={{width: 130}}
                                    label="Выводить в отчёт"
                                    name={[field.name, 'hidden']}
                                >
                                    <CustomSelect
                                        entries={[
                                            {label: 'Да', value: false},
                                            {label: 'Нет', value: true},
                                        ]}
                                        settings={{
                                            placeholder: 'Выберите опцию',
                                            useFirstOptionAfterEntriesChanged: true,
                                            noUseFirstOptionWhenValueIsSet: true,
                                            isDisabled,
                                        }}
                                        handleTriggerEvent={(value, event) => {
                                            if (event === CustomSelectValueTriggerEvent.valueHasBeenChangedManually
                                            ) {
                                                setHiddenFields({
                                                    ...hiddenFields,
                                                    [form.getFieldValue(
                                                        ['reportTableColumns', 'reportColumns', field.name, 'keyName'],
                                                    )]: value,
                                                });
                                            }
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    required={
                                        hiddenFields?.[field.name] === false
                                        || !form.getFieldValue(
                                            ['reportTableColumns', 'reportColumns', field.name, 'hidden'],
                                        )
                                    }
                                    rules={[{
                                        required: hiddenFields?.[field.name] === false
                                            || !form.getFieldValue(
                                                ['reportTableColumns', 'reportColumns', field.name, 'hidden'],
                                            ),
                                    }]}
                                    style={{width: 350}}
                                    label="Пользовательское наименование"
                                    name={[field.name, 'reportTitle']}
                                >
                                    <Input disabled={isDisabled} />
                                </Form.Item>
                                <Form.Item
                                    style={{width: 140}}
                                    label="Порядок вывода"
                                    name={[field.name, 'columnOrdinal']}
                                    required={
                                        hiddenFields?.[field.name] === false
                                        || !form.getFieldValue(
                                            ['reportTableColumns', 'reportColumns', field.name, 'hidden'],
                                        )
                                    }
                                    rules={[
                                        {
                                            required: hiddenFields?.[field.name] === false
                                                || !form.getFieldValue(
                                                    ['reportTableColumns', 'reportColumns', field.name, 'hidden'],
                                                ),
                                        },
                                        ({getFieldValue}) => ({
                                            validator: (_, value) => {
                                                const sameOrdinals = Object
                                                    .entries(getFieldValue(name) ?? [])
                                                    .map(([, val]: any) => val?.columnOrdinal)
                                                    ?.filter(colOrdinal => !!value && colOrdinal === value);

                                                const sameCustomColumnOrdinals = Object
                                                    .entries(getFieldValue(
                                                        ['reportTableColumns', 'customColumns'],
                                                    ) ?? [])
                                                    .map(([, val]: any) => val?.columnOrdinal)
                                                    ?.filter(colOrdinal => !!value && colOrdinal === value);

                                                if (sameOrdinals?.length > 1) {
                                                    return Promise.reject(new Error(
                                                        'Уже существует',
                                                    ));
                                                }
                                                if (sameCustomColumnOrdinals?.length > 0) {
                                                    return Promise.reject(new Error(
                                                        'Уже существует в кастомных атрибутах',
                                                    ));
                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <InputNumber disabled={isDisabled} />
                                </Form.Item>
                                <Form.Item
                                    style={{width: 140}}
                                    label="Тип данных"
                                    name={[field.name, 'fieldType']}
                                >
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item
                                    style={{width: 140}}
                                    label="Тип поля"
                                    name={[field.name, 'fieldFunction']}
                                >
                                    <CustomSelect<string, ReportConfigurationReportColumnDto['fieldFunction']>
                                        entries={[{
                                            value: 'URL',
                                            label: 'Ссылка',
                                        }, {
                                            value: 'DD',
                                            label: 'Переход (ДД)',
                                        }]}
                                        settings={{
                                            placeholder: 'Не задано',
                                            isClearable: true,
                                            isDisabled,
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={<>&nbsp;</>}
                                >
                                    <Tooltip
                                        title="Настройки правил связи"
                                        mouseEnterDelay={0.7}
                                    >
                                        <Button
                                            type="primary"
                                            className={cn('btn-only-icon')}
                                            onClick={() => {
                                                const linkColumn = form.getFieldValue(
                                                    ['reportTableColumns', 'reportColumns', field.name, 'keyName'],
                                                );
                                                if (linkColumn) {
                                                    updateQueryParams([{
                                                        name: DD_LINK_COLUMN_KEY,
                                                        value: linkColumn,
                                                    }], {action: 'replace'});
                                                    setSelectedTabKey?.(
                                                        ReportConfigurationTabKey.REPORT_DD_REFERENCES,
                                                    );
                                                }
                                            }}
                                        >
                                            <SettingOutlined />
                                        </Button>
                                    </Tooltip>
                                </Form.Item>
                                <Form.Item
                                    label={<>&nbsp;</>}
                                >
                                    <FormatSettingsModalOpener
                                        formInstance={form}
                                        name={field.name}
                                        disabled={isDisabled}
                                        entry={form.getFieldValue(
                                            ['reportTableColumns', 'reportColumns', field.name],
                                        )}
                                        typeGroup={form.getFieldValue(
                                            ['reportTableColumns', 'reportColumns', field.name, 'typeGroup'],
                                        )}
                                        isFormatRequired={
                                            !form.getFieldValue(
                                                ['reportTableColumns', 'reportColumns', field.name, 'hidden'],
                                            )
                                            && (
                                                form.getFieldValue(['reportTableColumns', 'reportColumns',
                                                    field.name, 'typeGroup']) !== TypeGroup.OTHER
                                                || hiddenFields?.[field.name] === false
                                            )
                                        }
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={<>&nbsp;</>}
                                    name={[field.name]}
                                >
                                    <SecuritySettingsModal
                                        formInstance={form}
                                        name={field.name}
                                        disabled={isDisabled}
                                    />
                                </Form.Item>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </Form.List>
    );
};
