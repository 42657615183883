import React, {useMemo} from 'react';

import {DashboardComponent} from 'components/dashboard/dashboard-component/dashboard-component';
import {selectContextRawData} from 'modules/context/context-selector';
import {FieldMeta, SvcLayout} from 'modules/metadata/metadata-types';
import {useAppSelector} from 'store/config/hooks';

import {WidgetLayout} from '../../widget-layout';
import {createButtonProps} from './widget-layout-item.utils';

interface WidgetLayoutItemProps {
    widget: SvcLayout;
    field?: FieldMeta;
    data?: any;
    entityName: string;
}

export const WidgetLayoutItem: React.FC<WidgetLayoutItemProps> = ({
    widget, field, data, entityName,
}: WidgetLayoutItemProps) => {
    const contextData = useAppSelector(selectContextRawData);
    const {buttonsBottom, buttonsTop} = useMemo(() => createButtonProps(widget?.buttons), [widget?.buttons]);

    return (
        <WidgetLayout
            title={widget.title}
            buttons={{
                bottom: buttonsBottom,
                top: buttonsTop,
            }}
        >
            {field && (
                <DashboardComponent
                    key={field?.key}
                    entityName={entityName}
                    meta={field}
                    data={data ? data[field.key] : undefined}
                    context={contextData}
                />
            )}

        </WidgetLayout>

    );
};
