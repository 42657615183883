import {createSelector} from '@reduxjs/toolkit';

import {TableColumnFilters} from 'components/@common/widgets/custom-table/table-column-filter/use-table-column-filter/use-table-column-filter-types';
import {adaptFiltersForRequest} from 'components/@common/widgets/custom-table/table-column-filter/use-table-column-filter/use-table-column-filter-utils';
import {AppState} from 'store/config/types';

import {tableReportSliceName} from '../table-report-slice-constants';

const rootSelector = (state: AppState) => state[tableReportSliceName];

export const selectTableReportColumnFiltersData = createSelector(
    createSelector(rootSelector, ({reportOptions}) => reportOptions),
    reportOptions => {
        const {filters} = reportOptions;
        return filters as TableColumnFilters;
    },
);

export const selectTableReportColumnFiltersDataAdaptedForRequest = createSelector(
    selectTableReportColumnFiltersData, tableColumnFilters => adaptFiltersForRequest(tableColumnFilters),
);
