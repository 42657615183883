import {FormInstance} from 'antd/es/form';
import {
    isArray, isEqual,
} from 'lodash';
import {
    useEffect, useRef, useState,
} from 'react';

import {MetaActionInfo} from 'modules/metadata';

import {
    FormButtonWatcher,
    FormButtonWatcherActions,
    FormButtonWatcherConditions, FormButtonWatcherConnective,
} from './use-form-button-watcher-types';
import {createConnective} from './use-form-button-watcher.utils';

interface formValuesChangeEventDetail {
    values: { [i: string]: any };
    targetForm?: any;
}

interface dispatchFormWatcherEventArgs extends formValuesChangeEventDetail {
}

const FORM_WATCHER_EVENT = 'form-changed';

export const dispatchFormWatcherEvent = (args: dispatchFormWatcherEventArgs) => {
    window.dispatchEvent(new CustomEvent(FORM_WATCHER_EVENT, {detail: args}));
};

type createActionHandlersArgs = {
    detail: formValuesChangeEventDetail;
    watcher: FormButtonWatcher;
}

interface createConditionCallbacksArgs {
    condition: FormButtonWatcherConditions;
    targetValue: any;
    ownValue?: any;
}

interface useFormButtonWatcherArgs {
    actionsForm: MetaActionInfo;
    form?: FormInstance<any>;
}

export const useFormButtonWatcher = ({actionsForm, form}: useFormButtonWatcherArgs) => {
    const [isHidden, setIsHidden] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [shouldDispatch, setShouldDispatch] = useState<boolean>(false);

    const formValues = form?.getFieldsValue();
    const lastValues = useRef<{ [i: string]: any }>(formValues);

    const setLastValues = (newValues: { [i: string]: any }) => {
        lastValues.current = newValues;
        setShouldDispatch(true);
    };

    if (formValues && !isEqual(lastValues.current, formValues)) {
        setLastValues(formValues);
    }

    const checkIfWatcherIsTriggered = ({
        condition,
        targetValue, // значение поля, которое таргетит watcher
    }: createConditionCallbacksArgs) => {
        const callbacks = {
            [FormButtonWatcherConditions.unset]: !targetValue,
        };
        return callbacks[condition];
    };

    const createActionHandlers = ({
        watcher, detail,
    }: createActionHandlersArgs) => {
        const {
            conditions,
            perform: actions,
        } = watcher;
        const {values} = detail;
        let isWatcherTriggered: boolean | null = null;

        conditions?.forEach(({
            target,
            when: condition,
            withConnective,
        }) => {
            const targetValue = target ? values[target] : undefined;
            const isTriggered = checkIfWatcherIsTriggered({
                condition,
                targetValue,
            });
            const joinCondition = createConnective(withConnective);
            if (isWatcherTriggered === null) {
                isWatcherTriggered = withConnective === FormButtonWatcherConnective.NOT
                    ? !isTriggered : isTriggered;
            } else isWatcherTriggered = joinCondition(isWatcherTriggered, isTriggered);
        });
        const handlers = {
            [FormButtonWatcherActions.disable]: () => {
                if (isWatcherTriggered) setIsDisabled(true);
                else setIsDisabled(false);
            },
            [FormButtonWatcherActions.hide]: () => {
                if (isWatcherTriggered) setIsHidden(true);
                else setIsHidden(false);
            },
        };

        setLastValues(values);

        if (isArray(actions)) return actions.map(action => handlers?.[action]);
        return [handlers?.[actions]];
    };

    useEffect(() => {
        const handler = ({detail}: any) => {
            if (!form) return;

            const {targetForm} = detail as formValuesChangeEventDetail;
            if (targetForm && form !== targetForm) return;

            actionsForm?.watchers?.forEach(watcher => {
                createActionHandlers({
                    watcher,
                    detail,
                }).forEach(actionHandler => {
                    actionHandler?.();
                });
            });
        };
        window.addEventListener(FORM_WATCHER_EVENT, handler);
        return () => {
            window.removeEventListener(FORM_WATCHER_EVENT, handler);
        };
    }, []);

    useEffect(() => {
        if (shouldDispatch) {
            dispatchFormWatcherEvent({values: formValues, targetForm: form});
            setShouldDispatch(false);
        }
    }, [shouldDispatch]);

    return {
        isHidden,
        isDisabled,
    };
};
