import React from 'react';

import {useAppSelector} from 'store/config/hooks';
import {selectModalData, ModalComponentInterface} from 'store/slices/modals-slice';

import {FormModal} from './form-modal';

export const FormModalContainer: React.FunctionComponent<ModalComponentInterface> = ({
    onClose,
}: ModalComponentInterface) => {
    const entityName = useAppSelector(state => selectModalData(state)?.entityName) || '';
    return (
        <FormModal
            entityName={entityName}
            onClose={onClose}
        />
    );
};
