import React, {useState} from 'react';

import {performRequest, showMessageFromResponse} from 'shared/utils';
import {useQueryParams} from 'shared/utils/query-params/use-query-params.hook';

import {ActionButton} from '../action-button';
import {TableActionProps} from '../table-action-types';
import {OpenTransportContainerCardModal} from './modals/open-tc-card';

export interface GenerateTcResponseProps extends TableActionProps {
}

export const GenerateTcResponse = ({
    wrapper,
    meta,
}: GenerateTcResponseProps) => {
    const [showRedirectToTcModal, setShowRedirectToTcModal] = useState(false);

    const {
        actionTitle,
        actionIcon,
        referenceUrl,
        requestType,
        additionalInfoKeys,
    } = meta;

    const {getQueryParams} = useQueryParams();
    const requestQueryParams = getQueryParams(additionalInfoKeys || []);

    const handleClick = () => {
        performRequest({
            method: requestType,
            url: referenceUrl,
            params: requestQueryParams,
        })
            .then(data => {
                if (data.status === 200) {
                    setShowRedirectToTcModal(true);
                    showMessageFromResponse({response: data, isError: false});
                }
            })
            .catch(error => {
                showMessageFromResponse({response: error?.response, isError: true});
            });
    };
    const Component = wrapper || ActionButton;
    return (
        <>
            <Component
                onClick={handleClick}
                actionIcon={actionIcon}
            >
                {actionTitle}
            </Component>
            {showRedirectToTcModal && requestQueryParams.docId && (
                <OpenTransportContainerCardModal
                    docId={requestQueryParams.docId}
                    setShowRedirectToTcModal={setShowRedirectToTcModal}
                />
            )}
        </>
    );
};
