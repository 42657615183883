import cn from 'classnames';
import React from 'react';

import {JSXContent} from 'shared/types';

import './action-bar.less';

interface ActionBarProps {
    children?: JSXContent;
    rightSide?: JSXContent;
}

export const ActionBar: React.FC<ActionBarProps> = (
    {
        children,
        rightSide,
    }: ActionBarProps,
) => (
    <div className={cn('action-bar')}>
        <div className={cn('action-bar__left-side')}>
            {children}
        </div>
        <div className={cn('action-bar__right-side')}>
            {rightSide}
        </div>
    </div>
);
