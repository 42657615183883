import {createAsyncThunk} from '@reduxjs/toolkit';

import {ais3SliceName} from '../ais-slice-constants';
import {FetchAisDashboardDataParams, FetchAisDashboardDataResponseDto, fetchAisDashboardData} from './ais-dashboards-api';
import {AisDashboardCode} from './ais-dashboards-constants';

type GetAisS4S5DashboardDataArgs = Omit<FetchAisDashboardDataParams, 'code'> & {
    code: AisDashboardCode.REQUEST_STATISTIC_S4_S5;
}
export const getAisS4S5DashboardData = createAsyncThunk<FetchAisDashboardDataResponseDto, GetAisS4S5DashboardDataArgs>(
    `${ais3SliceName}/getAisS4S5DashboardData`,
    async (arg, {rejectWithValue}) => {
        try {
            const response = await fetchAisDashboardData(arg ?? undefined);
            const {data} = response;
            return data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

type GetAisSystemUnavailabilityDashboardDataArgs = Omit<FetchAisDashboardDataParams, 'code'> & {
    code: AisDashboardCode.SYSTEM_UNAVAILABILITY;
}
export const getAisSystemUnavailabilityDashboardData = createAsyncThunk<
FetchAisDashboardDataResponseDto, GetAisSystemUnavailabilityDashboardDataArgs>(
    `${ais3SliceName}/getAisSystemUnavailabilityDashboardData`,
    async (arg, {rejectWithValue}) => {
        try {
            const response = await fetchAisDashboardData(arg ?? undefined);
            const {data} = response;
            return data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

type GetAisTokenStatusDashboardDataArgs = Omit<FetchAisDashboardDataParams, 'code'> & {
    code: AisDashboardCode.TOKEN_STATISTIC;
}
export const getAisTokenStatusDashboardData = createAsyncThunk<
    FetchAisDashboardDataResponseDto,
    GetAisTokenStatusDashboardDataArgs
>(
    `${ais3SliceName}/getAisTokenStatusDashboardData`,
    async (arg, {rejectWithValue}) => {
        try {
            const response = await fetchAisDashboardData(arg ?? undefined);
            const {data} = response;
            return data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

type GetAisSystemAvailabilityStatusDashboardDataArgs = Omit<FetchAisDashboardDataParams, 'code'> & {
    code: AisDashboardCode.SYSTEM_AVAILABILITY_STATUS;
}
export const getAisSystemAvailabilityStatusDashboardData = createAsyncThunk<
    FetchAisDashboardDataResponseDto,
    GetAisSystemAvailabilityStatusDashboardDataArgs
>(
    `${ais3SliceName}/getAisSystemAvailabilityStatusDashboardData`,
    async (arg, {rejectWithValue}) => {
        try {
            const response = await fetchAisDashboardData(arg ?? undefined);
            const {data} = response;
            return data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

type GetAisSummaryTransactionsProtocolDashboardDataArgs = Omit<FetchAisDashboardDataParams, 'code'> & {
    code: AisDashboardCode.SUMMARY_TRANSACTIONS_PROTOCOL_STATISTIC;
}
export const getAisSummaryTransactionsProtocolDashboardData = createAsyncThunk<
FetchAisDashboardDataResponseDto, GetAisSummaryTransactionsProtocolDashboardDataArgs >(
    `${ais3SliceName}/getAisSummaryTransactionsProtocolDashboardData`,
    async (arg, {rejectWithValue}) => {
        try {
            const response = await fetchAisDashboardData(arg ?? undefined);
            const {data} = response;
            return data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

type GetAisRequestStatisticS3S6S12Args = Omit<FetchAisDashboardDataParams, 'code'> & {
    code: AisDashboardCode.REQUEST_STATISTIC_S3_S6_S12;
}
export const getAisRequestStatisticS3S6S12 = createAsyncThunk<
    FetchAisDashboardDataResponseDto,
    GetAisRequestStatisticS3S6S12Args
>(
    `${ais3SliceName}/getAisRequestStatisticS3S6S12`,
    async (arg, {rejectWithValue}) => {
        try {
            const response = await fetchAisDashboardData(arg ?? undefined);
            const {data} = response;
            return data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

type GetAisRegisterDocumentsArgs = Omit<FetchAisDashboardDataParams, 'code'> & {
    code: AisDashboardCode.DOC_JOURNAL;
}
export const getAisRegisterDocuments = createAsyncThunk<FetchAisDashboardDataResponseDto, GetAisRegisterDocumentsArgs>(
    `${ais3SliceName}/getAisRegisterDocuments`,
    async (arg, {rejectWithValue}) => {
        try {
            const response = await fetchAisDashboardData(arg ?? undefined);
            const {data} = response;
            return data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);
