/**
   * Извлекает значение между первых двух "/" во входной строке.
   * Если второго "/" нет, то возвращает все что было после первого "/".
   * @param path Url из history.location.pathname
   * @returns Значение, находящееся между первых двух "/", или null, если совпадение не найдено.
   */
export const extractPathName = (path: string): string | null => {
    const matches = path.match(/^\/([^/]+)\/?/);
    return matches ? matches[1] : null;
};
