import {SvcLayout} from 'modules/metadata/metadata-types';
import {MenuStructure} from 'store/slices/menu-slice/menu-slice-types';

export const mapDirectionLayout = (svcLayouts?: Array<SvcLayout>[], menu?: MenuStructure | null) => svcLayouts?.map(
    layouts => layouts.filter(layout => {
        const url = layout.url && layout.url.slice(1);
        if (url) {
            const inMenu = menu?.filter(menuItem => menuItem.entityName === url);
            return Array.isArray(inMenu) && inMenu.length;
        }
        return url;
    }),
);

export const mapWidgetsLayout = (svcLayouts?: Array<SvcLayout>[], menu?: MenuStructure | null) => svcLayouts?.map(
    layouts => layouts.map(layout => {
        const buttons = layout.buttons?.filter(button => menu?.filter(
            menuItem => (!button.link ? button.link : menuItem.entityName === (button.link && button.link.slice(1))),
        ).length);
        return {...layout, buttons};
    }),
);
