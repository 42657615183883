import {Typography} from 'antd';
import cn from 'classnames';
import {omit} from 'lodash';
import React from 'react';

import {useAppHistory} from 'shared/hooks/use-app-history';
import {HistoryLocationState} from 'shared/hooks/use-app-history/use-app-history-types';

import {BreadcrumbItem} from './breadcrumb-types';

const {Text} = Typography;

interface BreadcrumbProps extends BreadcrumbItem {}

export const Breadcrumb: React.FunctionComponent<BreadcrumbProps> = (breadcrumb: BreadcrumbProps) => {
    const {
        name,
        path,
        isLast,
        extraData,
        onClickCallback,
    } = breadcrumb;

    const history = useAppHistory();

    const PathLink = () => {
        if (onClickCallback) {
            return (
                <div onClick={() => {
                    if (!isLast) {
                        onClickCallback?.(omit(breadcrumb, 'onClickCallback'));
                    }
                }}
                >
                    <Text className={cn({
                        'breadcrumb-link': !isLast,
                        'breadcrumb-link_text-link': !isLast,
                    })}
                    >
                        {name}
                    </Text>
                </div>
            );
        }

        if (!path) return <Text>{name}</Text>;

        return (
            <div
                className="d-inline"
                onClick={() => {
                    history.push(path, {
                        tableReportLocationDefaults: extraData?.tableReportLocationDefaults,
                        tableReportPushUniqueId: extraData?.tableReportPushUniqueId,
                        tableReportParams: extraData?.tableReportDrilldownParams,
                    } as HistoryLocationState);
                }}
            >
                <Text className={cn({
                    'breadcrumb-link': !isLast,
                    'breadcrumb-link_text-link': !isLast,
                })}
                >
                    {name}
                </Text>
            </div>

        );
    };

    return (
        <>
            <PathLink />
            {isLast ? '' : <span className="breadcrumb-link__divider">&nbsp;/&nbsp;</span>}
        </>
    );
};
