import React from 'react';
import {useDispatch} from 'react-redux';

import {
    resetData,
} from 'modules/data/data-actions';
import {EntityType} from 'shared/constants/entities';
import {normalizeEntityName} from 'utils/common';

import {ActionButton} from '../action-button';
import {TableActionProps} from '../table-action-types';

import './button-action-update-table.less';

const ButtonsActionUpdateComponent: React.FunctionComponent<TableActionProps> = ({
    wrapper,
    meta,
    entityName,
    ...props
}: TableActionProps) => {
    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch(resetData({
            entityType: meta?.parentEntityType || EntityType.TABLE,
            entityName: normalizeEntityName(entityName),
            loading: false,
        }));
    };
    const Component = wrapper || ActionButton;
    return (
        <Component
            {...props}
            onClick={handleClick}
            actionIcon={meta.actionIcon}
            className="update-button"
        >
            {meta.actionTitle}
        </Component>
    );
};

export const ButtonsActionUpdate = ButtonsActionUpdateComponent;
