import {Modal} from 'antd';
import cn from 'classnames';
import React, {ReactNode} from 'react';

import {selectMetadata} from 'modules/metadata';
import {FormEntityMeta} from 'modules/metadata/metadata-types';
import {ReactComponent as CloseIcon} from 'shared/assets/close.svg';
import {EntityType} from 'shared/constants/entities';
import {DEFAULT_FORM_MODAL_WIDTH, DEFAULT_FORM_WITH_TABS_MODAL_WIDTH} from 'shared/constants/layout';
import {convertWidthData} from 'shared/utils/convert-width-data';
import {useAppSelector} from 'store/config/hooks';
import {ModalComponentInterface} from 'store/slices/modals-slice';

import {FormCloseWarning} from '../form-close-warning';

import './form-modal.less';

interface FormModalWrapperProps extends ModalComponentInterface {
    children: (handleClose: () => void) => ReactNode;
    hasTitle?: boolean;
    entityName: string;
}

export const FormModalWrapper: React.FunctionComponent<FormModalWrapperProps> = ({
    onClose,
    children,
    hasTitle = true,
    entityName,
}: FormModalWrapperProps) => {
    const metadata: FormEntityMeta = useAppSelector(selectMetadata(entityName, EntityType.FORM));

    const modalWidth = convertWidthData(metadata?.width);
    const defaultWidth = metadata?.tabs?.length ? DEFAULT_FORM_WITH_TABS_MODAL_WIDTH : DEFAULT_FORM_MODAL_WIDTH;

    return (
        <FormCloseWarning
            entityName={entityName}
            onClose={onClose}
            metadata={metadata}
        >
            {(handleClose, visible: boolean) => (
                <Modal
                    className="form-modal"
                    footer={null}
                    destroyOnClose={false}
                    visible={visible}
                    width={modalWidth || defaultWidth}
                    onCancel={handleClose}
                    maskClosable={false}
                    closeIcon={<CloseIcon className={cn('modal-close-icon', hasTitle && 'with-title')} />}
                    centered
                >
                    {children(handleClose)}
                </Modal>
            )}
        </FormCloseWarning>
    );
};
