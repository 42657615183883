import {Menu} from 'antd';
import React, {useEffect, useState, FC} from 'react';

import {KebabMenu} from 'components/@common/widgets/kebab-menu';
import {ContextLookupValue} from 'modules/context/context-types';
import {fetchReferenceData} from 'modules/data/data-api';

interface ActionSettingButtonProps {
    templateCode: string;
}

export const ActionSettingButton: FC<ActionSettingButtonProps> = ({
    templateCode,
}: ActionSettingButtonProps) => {
    const [actions, setActions] = useState<ContextLookupValue[]>([]);

    useEffect(() => {
        const fetchActions = async () => {
            const {data: fetchedActions} = await fetchReferenceData<ContextLookupValue[]>(
                '/valueLists/TABLE_REPORT_ACTIONS',
                {sublistCode: templateCode},
            );

            setActions(fetchedActions);
        };

        fetchActions().catch(console.error);
    }, []);

    return (
        <KebabMenu>
            <Menu>
                {actions.map(action => (
                    <Menu.Item key={action.id}>{action.meaning}</Menu.Item>
                ))}
            </Menu>
        </KebabMenu>
    );
};
