export const CONTEXT_FILTER_LOOKUPS = {
    organizationId: 'ORGANIZATION_OPEN',
    taxPeriodId: 'TAX_PERIOD',
} as const;

export const CONTEXT_RESET_EVENT_NAME = 'CONTEXT_RESET_EVENT';

export const TAX_PERIOD_QUERY_PARAM_NAME = 'taxPeriod';

export type ContextFilterLookupType = typeof CONTEXT_FILTER_LOOKUPS[keyof typeof CONTEXT_FILTER_LOOKUPS];
