import {
    DocumentsUploadMonitoringResult, MonitoringResult, monitoringStatusCode,
    ProgramSetProgram,
    ProgramSetStage, stageStatus,
} from 'modules/documents/documents-upload-monitoring-types';

export const stagesDisplaySequenceComparator = (a: ProgramSetStage, b: ProgramSetStage) => (
    a?.displaySequence && b.displaySequence ? a.displaySequence - b.displaySequence : 0
);
export const getStageProgramsCorrespondingMonitoringResult = (
    programSet: ProgramSetProgram[], monitoringResults: DocumentsUploadMonitoringResult[],
) => {
    const programIdArray = programSet.map(set => set.program?.id);
    const filteredMonitoringResults = monitoringResults
        .filter(monitoringResult => programIdArray.includes(monitoringResult?.program?.id));

    return filteredMonitoringResults.map(monitoringResult => monitoringResult.request);
};

export const getMonitoringStatus = (monitoringResults: DocumentsUploadMonitoringResult[]) => {
    const lastMonitoringResult = monitoringResults[monitoringResults.length - 1];
    if (lastMonitoringResult) {
        return lastMonitoringResult?.parentRequest?.status?.lookupCode;
    }
    return undefined;
};

// warning and normal are both success
const successResultResolver = (status?: monitoringStatusCode) => [
    monitoringStatusCode.warning, monitoringStatusCode.normal].includes(status as monitoringStatusCode);

const generateLogObject = (monitoringResult: MonitoringResult) => ({
    programId: monitoringResult.programId,
    log: monitoringResult.monitorProgramLog,
});

export const getStageStatus = (stage: ProgramSetStage, stageResults?: MonitoringResult[]) => {
    const {programSetPrograms: programs} = stage;

    // хотя бы одна программа еще выполняется status = running
    if (stageResults
        && stageResults.some(monitoringResult => monitoringResult.status.lookupCode === monitoringStatusCode.running)) {
        return {status: stageStatus.pending};
    }
    // часть программ завершилась успешно
    if (stageResults && programs?.length > stageResults?.length
        && stageResults.some(monitoringResult => successResultResolver(monitoringResult?.status?.lookupCode))) {
        return {status: stageStatus.pending};
    }

    // хотя бы одна программа завершилась с ошибкой
    if (stageResults && programs?.length === stageResults?.length
        && stageResults?.find(monitoringResult => monitoringResult.status.lookupCode === monitoringStatusCode.error)) {
        return {
            status: stageStatus.reject,
            logs: stageResults.map(monitoringResult => generateLogObject(monitoringResult)),
        };
    }

    // все программы завершились успешно
    if (programs?.length === stageResults?.length
        && stageResults?.every(monitoringResult => successResultResolver(monitoringResult?.status?.lookupCode))) {
        return {
            status: stageStatus.success,
            logs: stageResults.map(monitoringResult => generateLogObject(monitoringResult)),
        };
    }

    return {status: stageStatus.initial};
};
