import {Input, Space} from 'antd';
import {InputProps} from 'antd/es/input';
import React, {useState} from 'react';

import {InputDefaultValueSetter} from './input-default-value-setter/input-default-value-setter';

import './input-with-default-value-setter.less';

export interface InputWithDefaultValueSetterProps extends InputProps {
    disabled: boolean;
    autoComplete: string;
    value: any;
    label: string;
    defaultValue?: string;
    onChange?: (value: any) => void;
}

export const InputWithDefaultValueSetter = ({
    disabled,
    autoComplete,
    value,
    label,
    defaultValue,
    onChange,
    ...props
}: InputWithDefaultValueSetterProps) => {
    const [isDefaultValueSet, setIsDefaultValueSet] = useState(false);

    return (
        <Space direction="vertical">
            <InputDefaultValueSetter
                label={label}
                onChange={onChange}
                defaultValue={defaultValue}
                isDefaultValueSet={isDefaultValueSet}
                setIsDefaultValueSet={setIsDefaultValueSet}
            />
            <Input
                disabled={disabled || isDefaultValueSet}
                autoComplete={autoComplete}
                value={value}
                onChange={onChange}
                {...props}
            />
        </Space>
    );
};
