import {
    Divider, Empty, Form,
} from 'antd';
import {useForm} from 'antd/lib/form/Form';
import cn from 'classnames';
import React, {useContext, useEffect, useState} from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {LargeStringInput} from 'components/form/inputs/large-string-input';
import {ModalCloseConfirmation} from 'components/modal-close-confirmation';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {ReportConfigurationDataConverted} from 'store/slices/report-configuration-slice';
import {
    selectReportConfigurationTableAttributesAsSelectEntries,
    selectTableReportConfigurationData,
} from 'store/slices/report-configuration-slice/report-configuration-slice';
import {updateTableReportConfiguration} from 'store/slices/report-configuration-slice/report-configuration/report-configuration-thunks';

import {ButtonsBar} from '../../components/buttons-bar/buttons-bar';
import {ReportConfigurationTabKey, URL_TABLE_REPORT_MENU_ITEMS} from '../../report-configuration.constants';
import {ReportConfigurationContext} from '../../report-configuration.context';
import {CrColumn} from './report-ks-settings-types';
import {omitUndefinedValues} from './report-ks-settings-utils';
import {KSSettingsFields} from './report-ks-settings.constants';

import './report-ks-settings.less';

interface ReportKsSettingsProps {}

export const ReportKsSettings: React.FC<ReportKsSettingsProps> = () => {
    const [form] = useForm();

    const dispatch = useAppDispatch();

    const {
        setSelectedTabKey,
        entityName,
        templateCode,
        isCreatingNewTemplate,
    } = useContext(ReportConfigurationContext);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const reportConfigurationData = useAppSelector(
        selectTableReportConfigurationData,
    );

    const isMenuEnabled = !!reportConfigurationData?.enabledMenu;

    const getCrColumnsFormValue = () => form
        .getFieldValue('crColumns') as ReportConfigurationDataConverted['crColumns'];

    const sheetCodeExistsInForm = (sheetCode?: string) => {
        const crColumns = getCrColumnsFormValue();
        return crColumns?.map(rc => rc?.sheetCode).includes(sheetCode);
    };

    const getCrSettings = () => {
        const crSettings = reportConfigurationData?.crColumns;
        if (!crSettings) return [{sheetCode: undefined}];
        if (!crSettings?.map(cr => cr?.sheetCode).includes(undefined)) {
            return [
                ...crSettings,
                {sheetCode: undefined},
            ];
        }
        return crSettings;
    };

    const tableAttributesEntries = useAppSelector(selectReportConfigurationTableAttributesAsSelectEntries);

    const [isEditingForm, setIsEditingForm] = useState<boolean>(isCreatingNewTemplate);
    const [initialValues, setInitialValues] = useState<ReportConfigurationDataConverted['crColumns']>(
        getCrSettings(),
    );
    const [selectedSheetCode, setSelectedSheetCode] = useState<string>();

    const isDataPresents = !!(initialValues?.length);

    const clearFormHandler = () => {
        form.setFieldsValue({crColumns: [{sheetCode: undefined}]});
        setSelectedSheetCode(undefined);
    };

    const handleClose = () => {
        setSelectedTabKey?.(ReportConfigurationTabKey.REPORT_CONFIGURATOR);
    };

    const handleBack = () => {
        if (form.isFieldsTouched() && isEditingForm) {
            setIsModalOpen(true);
        } else { setSelectedTabKey?.(ReportConfigurationTabKey.REPORT_CONFIGURATOR); }
    };

    useEffect(() => {
        const initialData = getCrSettings();
        if (initialData) setInitialValues(initialData);
    }, [reportConfigurationData]);

    useEffect(() => {
        if (initialValues) form.resetFields();
        if (!sheetCodeExistsInForm(selectedSheetCode)) setSelectedSheetCode(undefined);
    }, [initialValues]);

    const isDisabled = isDataPresents && !isEditingForm;

    const handleFinish = (values: any) => {
        if (templateCode) {
            dispatch(updateTableReportConfiguration({
                templateCode,
                data: {crColumns: omitUndefinedValues(values)},
            }));
        }
        setIsEditingForm(false);
    };

    return (
        <div className="report-ks-settings">
            <ButtonsBar
                entityName={entityName}
                templateCode={templateCode}
                isCreating={isCreatingNewTemplate}
                isCreatingDisabled={(!isEditingForm && isDataPresents)}
                onClearForm={clearFormHandler}
                isEditing={isEditingForm}
                setIsEditing={setIsEditingForm}
                hideEditButton={!isDataPresents}
                hideDeleteButton
                onBack={handleBack}
                onCancelEdit={() => {
                    form.resetFields();
                    setIsEditingForm(false);
                    if (!sheetCodeExistsInForm(selectedSheetCode)) setSelectedSheetCode(undefined);
                }}
                onSubmit={() => {
                    form.submit();
                }}
            />
            <div>
                <Divider />
            </div>
            <div>
                <Form
                    initialValues={{crColumns: initialValues}}
                    layout="vertical"
                    form={form}
                    onFinish={handleFinish}
                >
                    <div>

                        {tableAttributesEntries?.length ? (
                            <Form.List name="crColumns">
                                {(fields, {add}, {errors}) => (
                                    <>
                                        {isMenuEnabled && (
                                            <div
                                                style={{width: 322, marginBottom: 20}}
                                            >
                                                <CustomSelect
                                                    optionsLabelFormatter={(label, value) => {
                                                        if (reportConfigurationData
                                                            ?.crColumns
                                                            ?.map(cr => cr?.sheetCode)
                                                            .includes(value?.lookupCode)) {
                                                            return (
                                                                <span className="fw-500">
                                                                    {label}
                                                                </span>
                                                            );
                                                        }
                                                        return label;
                                                    }}
                                                    onChange={value => {
                                                        setSelectedSheetCode(value);
                                                        if (value) {
                                                            const crColumns = form
                                                                .getFieldValue('crColumns') as CrColumn[];
                                                            if (!crColumns
                                                                .map(c => c
                                                                    ?.sheetCode)
                                                                .includes(value)) {
                                                                const rc: CrColumn = {
                                                                    sheetCode: value,
                                                                };
                                                                add(rc);
                                                            }
                                                        }
                                                    }}
                                                    value={selectedSheetCode}
                                                    settings={{
                                                        showSearch: true,
                                                        placeholder: 'Выберите лист',
                                                        url: URL_TABLE_REPORT_MENU_ITEMS,
                                                        referenceUrlQueryParams: {
                                                            sublistCode: templateCode || '',
                                                        },
                                                        labelPath: 'lookupCode',
                                                        valuePath: 'lookupCode',
                                                        isClearable: true,
                                                    }}
                                                />
                                            </div>
                                        )}

                                        {fields.map(field => (
                                            (
                                                <>
                                                    <Form.ErrorList errors={errors} />
                                                    {field && (
                                                        <div
                                                            className={cn({
                                                                'd-none': selectedSheetCode
                                                                    !== getCrColumnsFormValue()
                                                                        ?.[field.name]?.sheetCode,
                                                            })}
                                                            key={field.fieldKey}
                                                        >
                                                            <Form.Item
                                                                name={[field.name, KSSettingsFields.SheetCode]}
                                                                hidden
                                                            />
                                                            <div className="row">
                                                                <Form.Item
                                                                    label="Атрибут"
                                                                    name={[field.name, KSSettingsFields.ColumnName]}
                                                                >

                                                                    <CustomSelect
                                                                        settings={{
                                                                            showSearch: true,
                                                                            isDisabled,
                                                                            placeholder: 'Введите атрибут',
                                                                            triggerOnValueSet: true,
                                                                            formInstance: form,
                                                                            isClearable: true,
                                                                        }}
                                                                        entries={tableAttributesEntries}
                                                                    />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name={[field.name, KSSettingsFields.SQL]}
                                                                    label="Правило КС"
                                                                >
                                                                    <LargeStringInput
                                                                        disabled={isDisabled}
                                                                        name={KSSettingsFields.SQL}
                                                                        label="Правило КС"
                                                                        placeholder="Введите sql запрос"
                                                                        saveButtonLabel="Подтвердить"
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            )
                                        ))}
                                    </>
                                )}
                            </Form.List>
                        ) : <Empty description="Атрибуты источника данных табличной части не найдены" />}
                    </div>
                </Form>
                <ModalCloseConfirmation
                    isOpen={isModalOpen}
                    onClose={handleClose}
                    onBack={() => setIsModalOpen(false)}
                    closeTitle="Закрыть форму"
                    backTitle="Вернуться к редактированию"
                />
            </div>
        </div>
    );
};
