import React, {useContext} from 'react';
import {useDispatch} from 'react-redux';

import {setMetadata} from 'modules/metadata/metadata-actions';
import {selectTableEntityMetadata} from 'modules/metadata/metadata-selectors';
import {TableEntityMeta} from 'modules/metadata/metadata-types';
import {EntityType} from 'shared/constants/entities';
import {useAppSelector} from 'store/config/hooks';

import {invertTableMode, TableMode, TableModeContext} from '../../context';
import {ActionButton} from '../action-button';
import {TableAction} from '../table-action';
import {TABLE_ACTION_MAP} from '../table-action-constants';
import {TableActionProps} from '../table-action-types';

export const disabledFields = (metaTable: TableEntityMeta, isDisabled: boolean): TableEntityMeta => {
    const fields = metaTable?.fields.map(field => ({...field, isDisabled}));
    return {...metaTable, fields};
};
export const disabledButtonSave = (disabled = true) => disabled;
export const ButtonActionEditFields: React.FunctionComponent<TableActionProps> = ({
    meta,
    wrapper,
    entityName,
    ...props
}: TableActionProps) => {
    const dispatch = useDispatch();
    const {actionIcon, actionTitle} = meta;
    const {tableMode, setTableMode} = useContext(TableModeContext);
    const metaTableData = useAppSelector(selectTableEntityMetadata(entityName));
    const handleClick = () => {
        const newMetaData = disabledFields(metaTableData, false);
        setTableMode(invertTableMode(tableMode));
        dispatch(setMetadata({
            entityName,
            entityType: EntityType.TABLE,
            metadata: newMetaData,
        }));
        disabledButtonSave(false);
    };
    const Component = wrapper || ActionButton;
    if (meta?.childrens && tableMode === TableMode.EDIT) {
        return (
            <>
                {meta.childrens?.map(child => (
                    TABLE_ACTION_MAP[child.actionType] ? (
                        <TableAction
                            key={child.actionTitle}
                            meta={child}
                            entityName={entityName}
                        />

                    ) : null
                ))}
            </>
        );
    }

    return (
        <Component
            {...props}
            actionIcon={actionIcon}
            onClick={handleClick}
        >
            {actionTitle}
        </Component>
    );
};
