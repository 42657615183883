import cn from 'classnames';
import React from 'react';
import {useDispatch} from 'react-redux';

import {useAppSelector} from 'store/config/hooks';
import {tableReportSliceActions} from 'store/slices/table-report-slice/table-report-slice';
import {selectTableReportActiveMenu} from 'store/slices/table-report-slice/table-report-slice-selectors';
import {TableReportMenu} from 'store/slices/table-report-slice/table-report-slice-types';

import {AsideNode} from './aside-node';

import './aside.less';

export interface AsideProps {
    collapsed?: boolean;
    menuStructure: TableReportMenu[];
    handleChangePage: (page: number) => void;
    currentPage: number;
}

export const Aside: React.FC<AsideProps> = ({
    menuStructure,
    collapsed,
    handleChangePage,
}: AsideProps) => {
    const dispatch = useDispatch();

    const activeMenu = useAppSelector(selectTableReportActiveMenu);

    const {setGlobalSearchLine} = tableReportSliceActions;

    const setActiveMenuAndChangePage = (menu: TableReportMenu) => {
        dispatch(setGlobalSearchLine(''));
        handleChangePage(menu.pageNumber ?? 0);
    };

    const depthLevel = 0;

    return (
        <div className={`aside${cn(collapsed && ' collapsed')}`}>
            <div className="wrapper">
                {menuStructure?.map(menu => {
                    const menuItem = (() => {
                        if (menu?.children) {
                            return {
                                ...menu,
                                children: menu.children?.map(c => ({
                                    ...c,
                                    sheetNumber: c?.sheetNumber || menu?.sheetNumber,
                                })),
                            };
                        }
                        return menu;
                    })();
                    return (
                        <AsideNode
                            key={menuItem.name}
                            menu={menuItem}
                            name={menuItem.name}
                            activeMenu={activeMenu}
                            onRowClick={setActiveMenuAndChangePage}
                            depth={depthLevel}
                        />
                    );
                })}
            </div>
        </div>
    );
};
