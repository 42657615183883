import {performRequest, RequestPromise} from 'shared/utils';

import {
    DocumentsUploadMonitoringResult,
    ExecuteMonitoringData,
} from '../../documents/documents-upload-monitoring-types';

export const markDownLoadedList = (data: number[]): RequestPromise => performRequest({
    url: 'monitor/rpc/mark-downloaded-list',
    method: 'POST',
    data: {
        ids: data,
    },
});

export const markDownLoadedAll = (data: any): RequestPromise => performRequest({
    url: 'monitor/rpc/mark-downloaded-all',
    method: 'POST',
    data,
});

export const fetchRequestListInfo = (params?: {
    paginationCurrent?: number;
    paginationPageSize?: number;
    search?: string;
}): RequestPromise<any> => performRequest({
    url: 'monitor/list-request-info',
    method: 'GET',
    params,
});

export const fetchDocumentsExecuteMonitoringData = (monitorProgramId: any, data: ExecuteMonitoringData):
    RequestPromise<string> => performRequest(
    {
        url: `/monitor/execute/${monitorProgramId}`,
        method: 'POST',
        data,
    },
);

export const fetchDocumentsUploadMonitoringData = (monitoringSetId: number):
    RequestPromise<DocumentsUploadMonitoringResult[]> => performRequest({
    url: `/monitor/set/${monitoringSetId}`,
    method: 'GET',
});

export const downloadMonitoringProgramLog = (monitorProgramRequestId: number): RequestPromise<void> => performRequest({
    url: `administration.monitor.programs/download/${monitorProgramRequestId}`,
    method: 'GET',
});
