import cn from 'classnames';
import React, {ReactNode} from 'react';

import {ButtonsBar} from '../buttons-bar';
import {LinkButtonProps} from '../link-button';
import {SvcDictionaryIcons} from './layout-icons.constants';

import './widget-layout.less';

interface WidgetLayoutProps {
    children?: ReactNode;
    title?: string;
    buttons?: {
        top?: LinkButtonProps[];
        bottom?: LinkButtonProps[];
    };
    className?: string;
    iconName?: string;
    properties?: {
        isWithWrap?: boolean;
        isJustified?: boolean;
        isWithAutoOverflow?: boolean;
    };
}
export const WidgetLayout: React.FC<WidgetLayoutProps> = ({
    children, title, buttons, iconName, className, properties = {},
}: WidgetLayoutProps) => {
    const {isWithWrap, isJustified, isWithAutoOverflow} = properties;

    const Icon = iconName ? SvcDictionaryIcons[iconName] : null;

    return (
        <div className={cn('widget-layout', className)}>
            { Icon && (
                <div className={cn('widget-layout__icon')}><Icon /></div>
            )}
            <div className={cn('widget-layout__up-bar')}>
                <div className={cn('widget-layout__up-bar__title')}>
                    {title}
                </div>
                {buttons?.top && <div className={cn('widget-layout__up-bar__bar')} />}
                <div className={cn('widget-layout__up-bar__buttons')}>
                    <ButtonsBar buttons={buttons?.top} />
                </div>
            </div>
            <div className={cn('widget-layout__body', {
                'widget-layout__body_with-wrap': isWithWrap,
                'widget-layout__body_justified': isJustified,
                'widget-layout__body_with-auto-overflow': isWithAutoOverflow,
            })}
            >
                {children}
            </div>
            <div className={cn('widget-layout__footer')}>
                <ButtonsBar buttons={buttons?.bottom} />
            </div>
        </div>
    );
};
