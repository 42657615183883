import React from 'react';

import {TIconType} from 'components/dynamic-icon/dynamic-icon';
import {ActionConfirmModal} from 'components/table/action/action-confirm-modal/action-confirm-modal';

import {SimpleActionButton} from '../../components';
import {CLEAR_FORM_CONFIRM_TEXT} from './constants/clear-form-constants';

export interface ButtonClearFormProps {
    actionTitle: string;
    actionIcon?: string;
    handleClearForm?: () => void;
    confirmText?: string;
    approveText?: string;
}

export const ButtonClearForm = ({
    actionIcon, actionTitle, handleClearForm, confirmText, approveText,
}: ButtonClearFormProps) => (
    <ActionConfirmModal
        cancelText="Отменить"
        approveText={approveText || 'Очистить'}
        modalTitle="Предупреждение"
        confirmModalContent={confirmText || CLEAR_FORM_CONFIRM_TEXT}
        confirmAction={handleClearForm}
    >
        {
            handleClick => (
                <SimpleActionButton
                    type="default"
                    onClick={handleClick}
                    title={actionTitle || 'Очистить форму'}
                    icon={actionIcon as TIconType}
                />
            )
        }
    </ActionConfirmModal>
);
