import {FormInstance, Rule} from 'antd/es/form';
import {isEmpty, isNil} from 'lodash';
import React from 'react';

import {EntityValue} from 'modules/data';
import {ValidationRules} from 'modules/metadata/metadata-types';
import {isArray, isString} from 'shared/utils';

import {FieldStateType} from './editable-table-types';

enum maskValidation {
    sumOfFields = 'sumOfFields',
    checkMore = 'checkMore',
    checkEqual = 'checkEqual',
    checkNumericalValue = 'checkNumericalValue',
    checkIntegerValue = 'checkIntegerValue',
    checkSum = 'checkSum',
    // Используется, когда у поля нет поля для ввода, а ввод данных происходит при помощи другой формы
    checkValueSet = 'checkValueSet'
}

const DEFAULT_ERROR_MESSAGE = 'Неверное значение';
const REQUIRED_FIELD_ERROR_MESSAGE = 'Поле должно быть заполнено';

export const cellValidationResolver = (
    form: FormInstance,
    cellState: FieldStateType,
    title?: React.ReactNode,
    record?: Record<string, EntityValue>,
    validationRules?: ValidationRules[],
    isRequiredByWatcher?: boolean,
): Rule[] => {
    const {
        activated,
        required,
        dependencies,
    } = cellState;
    const isRequired = required || isRequiredByWatcher;

    return (validationRules || [])?.map(item => {
        const {
            mask,
            errorMessage,
            regExp,
            minNumericalValue,
            maxNumericalValue,
        } = item;
        const requiredValidatorFunction = (
            validatorFunction: (rule: Rule, value: string) => Promise<void>,
        ) => async (rule: Rule, value: string): Promise<void> => {
            if (isNil(value) && isRequired && activated) {
                return Promise.reject(REQUIRED_FIELD_ERROR_MESSAGE);
            }
            return validatorFunction(rule, value);
        };
        const generateValidator = (validatorFunction: (rule: Rule, value: string) => Promise<void>): Rule => ({
            validator: requiredValidatorFunction(validatorFunction),
        });

        if (mask === maskValidation.sumOfFields) {
            return generateValidator(async (_, value) => {
                const mainValue = Number(value);
                let dependenciesValue = 0;
                if (isArray(dependencies)) {
                    dependencies.forEach(depend => {
                        dependenciesValue += Number(record?.[depend]);
                    });
                }
                if (mainValue !== dependenciesValue) {
                    dependenciesValue = 0;
                    return Promise.reject(new Error(errorMessage));
                }
                return Promise.resolve();
            });
        }
        if (mask === maskValidation.checkMore) {
            return generateValidator(async (_, value) => {
                const mainValue = Number(value);
                let dependsValue: number = 0;
                if (isString(dependencies)) {
                    dependsValue += Number(record?.[dependencies]);
                }

                if (mainValue >= dependsValue) {
                    return Promise.resolve();
                }
                return Promise.reject(new Error(errorMessage));
            });
        }
        if (mask === maskValidation.checkEqual) {
            return generateValidator(async (_, value) => {
                const mainValue = Number(value);
                let dependsValue: number = 0;
                if (value !== '' && mainValue === 0) {
                    return Promise.reject(new Error(errorMessage));
                }
                if (isString(dependencies)) {
                    dependsValue += Number(record?.[dependencies]);
                }
                if (mainValue <= dependsValue) {
                    return Promise.resolve();
                }
                return Promise.reject(new Error(errorMessage));
            });
        }
        if (mask === maskValidation.checkNumericalValue) {
            return generateValidator(async (_, value) => {
                if (!isNil(minNumericalValue) && Number(value) >= minNumericalValue) {
                    return Promise.resolve();
                }
                if (!isNil(maxNumericalValue) && Number(value) <= maxNumericalValue) {
                    return Promise.resolve();
                }
                return Promise.reject(
                    new Error(errorMessage ?? DEFAULT_ERROR_MESSAGE),
                );
            });
        }
        if (mask === maskValidation.checkIntegerValue) {
            return generateValidator(async (_, value) => {
                if (/^-?\d+$/.test(value)) {
                    return Promise.resolve();
                }
                return Promise.reject(new Error(errorMessage ?? DEFAULT_ERROR_MESSAGE));
            });
        }
        if (mask === maskValidation.checkSum) {
            return generateValidator(async (_, value) => {
                const values = form.getFieldsValue();
                // TODO: позже добавят поле в метаданных
                if (!isNil(value) && Number(value) === Number(values?.failedCP) + Number(values?.successCP)) {
                    return Promise.resolve();
                }
                return Promise.reject(new Error(errorMessage ?? DEFAULT_ERROR_MESSAGE));
            });
        }
        if (mask === maskValidation.checkValueSet) {
            return generateValidator(async (_, value) => {
                if ((value && isRequired) || !isRequired) {
                    return Promise.resolve();
                }
                return Promise.reject(
                    new Error(errorMessage ?? DEFAULT_ERROR_MESSAGE),
                );
            });
        }
        return generateValidator(async (_, value) => {
            const valueStr = value?.toString();
            if ((isEmpty(valueStr) && (!isRequired || (isRequired && !activated)))
                    || (valueStr && valueStr.match(regExp || ''))) {
                return Promise.resolve();
            }
            return Promise.reject(
                new Error(errorMessage ?? DEFAULT_ERROR_MESSAGE),
            );
        });
    });
};
