import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {EditableColumn} from 'components/editable-table/editable-table-types';
import {EntityValue} from 'modules/data';
import {
    selectDocumentBranchNames,
    selectDocumentTypeOnUpload,
    selectDocumentFileUpload,
    getSubsectionFromLocation,
} from 'modules/documents';
import {FILE_UNIQUE_FIELD_NAME} from 'modules/documents/documents-constants';
import {EntityFileUploadState} from 'modules/documents/documents-types';
import {selectMetadata} from 'modules/metadata';
import {EntityMeta} from 'modules/metadata/metadata-types';
import {getRegionFromSubsectionCode, selectRegions} from 'modules/regions';
import {RegionResponse} from 'modules/regions/regions-types';
import {EntityType} from 'shared/constants/entities';
import {AppState} from 'store/config/types';

import {EditFormArgs, generateFileColumnsByMetaData} from '../documents-file-upload-modal-utils';

export const useDocumentsUploadColumns = (
    entityName: string,
    fileSizeFormat: string | null,
    useUploadDate: boolean | undefined,
    handleDelete: (tableRow: Record<string, EntityValue>) => void,
    handleEdit: (tableRow: Record<string, EntityValue>, args?: (EditFormArgs | undefined)) => void,
) => {
    const currentLocation = useLocation<History>();
    const [columnsMeta, setColumnsMeta] = React.useState<EditableColumn[] | undefined>();
    const [defaultRegion, setDefaultRegion] = React.useState<RegionResponse | undefined>();

    const metadataSelector = selectMetadata(entityName, EntityType.EDITABLE_TABLE);
    const {
        regions, documentTypesOnUpload, branchNames, fileUpload, metadata,
    } = useSelector((state: AppState) => ({
        regions: selectRegions(state),
        documentTypesOnUpload: selectDocumentTypeOnUpload(state),
        branchNames: selectDocumentBranchNames(state),
        fileUpload: selectDocumentFileUpload(state),
        metadata: metadataSelector(state) as EntityMeta,
    }));
    useEffect(() => {
        if (!regions || !Array.isArray(documentTypesOnUpload) || !Array.isArray(branchNames)) {
            return;
        }
        if (!defaultRegion) {
            const subsection = getSubsectionFromLocation(currentLocation);
            const defaultRegionData = getRegionFromSubsectionCode(subsection, regions);
            setDefaultRegion(defaultRegionData);
        }
        let fields = metadata?.fields;
        // exclude uploadDate column if app settings says this
        if (useUploadDate === false) {
            fields = fields?.filter(item => item.key !== 'uploadDate');
        }

        const arr = fields?.map(item => generateFileColumnsByMetaData(
            item,
            regions,
            documentTypesOnUpload,
            branchNames,
            {
                handleDelete,
                handleEdit,
                fileSizeFormat,
                progress: {
                    fileUploadSlice: fileUpload[entityName] as EntityFileUploadState,
                    fieldKey: FILE_UNIQUE_FIELD_NAME,
                },
            },
        ));
        setColumnsMeta(arr);
    }, [currentLocation, regions, documentTypesOnUpload, branchNames, fileUpload, fileSizeFormat, useUploadDate]);

    return {columnsMeta};
};
