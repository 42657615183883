import {SearchOutlined} from '@ant-design/icons';
import {Input} from 'antd';
import cn from 'classnames';
import React, {useEffect, useRef} from 'react';

import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {selectIsMenuOpen} from 'store/slices/menu-slice';
import {menuSliceActions} from 'store/slices/menu-slice/menu-slice';
import {selectMenuItemsFilteredBySearchLine, selectMenuSearchLine} from 'store/slices/menu-slice/menu-slice-selectors';

import {NewSideMenuFooter} from '../new-side-menu-footer';
import {NewSideMenuList} from '../new-side-menu-list/new-side-menu-list';

export const NewSideMenuBody = () => {
    const isMenuOpen = useAppSelector(selectIsMenuOpen);

    const dispatch = useAppDispatch();

    const {setSearchLine} = menuSliceActions;

    const searchInputRef = useRef<Input>(null);

    const menuItems = useAppSelector(selectMenuItemsFilteredBySearchLine);

    const searchLine = useAppSelector(selectMenuSearchLine);

    const handleSearchChange = (newLine: string) => {
        dispatch(setSearchLine(newLine));
    };

    useEffect(() => {
        if (isMenuOpen) searchInputRef.current?.focus();
    }, [isMenuOpen]);

    return (
        <div className={cn('new-side-menu__body', {
            'new-side-menu__body_closed': !isMenuOpen,
        })}
        >
            <div className={cn('new-side-menu__input')}>
                <Input
                    value={searchLine}
                    ref={searchInputRef}
                    allowClear
                    onChange={v => {
                        v.persist();
                        if (!v.target.value) {
                            dispatch(setSearchLine(''));
                            return;
                        }
                        handleSearchChange(v.target.value);
                    }}
                    prefix={<SearchOutlined />}
                    placeholder="Поиск раздела"
                />
            </div>

            <NewSideMenuList
                items={menuItems}
                depth={0}
                className={cn('new-side-menu__list_main')}
            />
            <NewSideMenuFooter />
        </div>
    );
};
