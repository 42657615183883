import React, {useContext} from 'react';

import {TIconType} from 'components/dynamic-icon/dynamic-icon';
import {
    SimpleActionButton,
} from 'components/form/components';
import {RequestCardContext} from 'components/request/replies/context/request-card-context';
import {ButtonType} from 'modules/metadata/metadata-types';

interface CollapseButtonProps {
    buttonType?: ButtonType;
    actionIcon?: TIconType;
}

export const CollapseButton = ({actionIcon, buttonType}: CollapseButtonProps) => {
    const {text, handleCollapse} = useContext(RequestCardContext);
    return (
        <SimpleActionButton
            type={buttonType || 'primary'}
            onClick={handleCollapse}
            icon={actionIcon}
            title={text}
        />
    );
};
