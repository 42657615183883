import {NotFoundPage} from 'pages/not-found-page';

import {PAGE_TEMPLATES} from './pages-constants';

export const getPageTemplate = (entityType: string) => {
    const component = PAGE_TEMPLATES[entityType];

    if (!component) {
        return NotFoundPage;
    }

    return component;
};
