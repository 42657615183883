import get from 'lodash/get';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {DATA_MODULE} from 'modules/data';
import {createActionGetFile, setData} from 'modules/data/data-actions';
import {FieldMeta, RequestType} from 'modules/metadata';
import {ReactComponent as ComparisonIcon} from 'shared/assets/comparison.svg';
import {EntityType} from 'shared/constants/entities';
import {AppState} from 'store/config/types';
import {openModal} from 'store/slices/modals-slice';

import {StatusCodeIcons} from './additional-information-cell.constants';

import './additional-information-cell.less';

interface AdditionalInformationCellProps {
    record: any;
    entityName: string;
    field?: FieldMeta;
}

export const AdditionalInformationCell: React.FunctionComponent<AdditionalInformationCellProps> = ({
    field,
    record,
    entityName,
}: AdditionalInformationCellProps) => {
    const dispatch = useDispatch();
    const data = useSelector(
        (state: AppState) => state[DATA_MODULE]?.[entityName]?.[EntityType.TABLE]?.rows,
    );

    const statusCode = field?.dependentsFieldKey && get(record, field.dependentsFieldKey);
    const attachId = field?.requestKey && get(record, field?.requestKey);
    const Icon = statusCode ? StatusCodeIcons[statusCode] : null;

    const handleDownloadLog = () => {
        const referenceUrl = `${field?.referenceUrl}/${attachId}`;
        dispatch(
            createActionGetFile({referenceUrl, requestType: RequestType.GET}),
        );
    };

    const handlerClick = () => {
        const newData = data || [];
        if (field?.modalName) {
            dispatch(openModal({modalName: field?.modalName, entityName}));
        }

        if (!newData || !data?.length) {
            dispatch(setData({
                entityName,
                entityType: EntityType.TABLE,
                loading: false,
                data: {
                    rows: [record],
                },
            }));
            return;
        }

        const row: any = record;
        const index = newData?.findIndex((item: any) => row.id === item?.id);
        if (index === -1 && data) {
            dispatch(setData({
                entityName,
                entityType: EntityType.TABLE,
                loading: false,
                data: {
                    rows: [...data, record],
                },
            }));
        }
    };
    return (
        <div className="additional-information">
            {field?.key && get(record, field.key) && (
                <ComparisonIcon
                    onClick={handlerClick}
                    className="comparison-icon"
                />
            )}
            {Icon && attachId && (
                <Icon
                    onClick={handleDownloadLog}
                    className="download-log-icon"
                />
            )}
        </div>
    );
};
