import {useEffect, useState} from 'react';

export interface PaginationInfo {
    page?: number;
    pageSize?: number;
    shouldTriggerOnChange?: boolean;
}

export interface UsePaginationArgs {
    pageDefault?: number;
    pageSizeDefault?: number;
    onChange?: (page?: number, pageSize?: number) => void;
}

export interface PaginationArgs {
    current?: number;
    pageSize?: number;
    handleChange: (newPage?: number, newPageSize?: number) => void;
}

export const usePagination = (args = {} as UsePaginationArgs) => {
    const {onChange} = args;

    const [pagination, setPagination] = useState<PaginationInfo>({
        page: args?.pageDefault,
        pageSize: args?.pageSizeDefault,
        shouldTriggerOnChange: false,
    });

    useEffect(() => {
        const {page, pageSize, shouldTriggerOnChange} = pagination;
        if (page && pageSize && shouldTriggerOnChange) { onChange?.(page, pageSize); }
    }, [pagination]);

    const {page, pageSize} = pagination;

    const handleChange = (newPage?: number, newPageSize?: number) => {
        setPagination({page: newPage, pageSize: newPageSize, shouldTriggerOnChange: true});
    };

    const setPaginationManually = (newPagination: PaginationInfo) => {
        if (newPagination) {
            setPagination({...newPagination, shouldTriggerOnChange: false});
        }
    };

    const setCurrentManually = (newCurrent?: number) => {
        if (newCurrent) {
            setPagination(prev => ({...prev, page: newCurrent, shouldTriggerOnChange: false}));
        }
    };

    return {
        current: page,
        pageSize,
        shouldTriggerPaginationFetch: pagination.shouldTriggerOnChange,
        setPagination,
        setPaginationManually,
        setCurrentManually,
        handleChange,
    };
};
