import {createAsyncThunk} from '@reduxjs/toolkit';

import {mapServerFieldMetaToAppFieldMeta} from 'modules/data/actions/field-data-actions';
import {fetchFormDataFields} from 'modules/data/data-api';
import {FieldMeta} from 'modules/metadata';
import {fetchEntityMetadata} from 'modules/metadata/metadata-api';
import {EntityType} from 'shared/constants/entities';

import {
    FetchRequestArgs,
    fetchRequestData,
    GetActionStatusByIdAndActionCodeRequestArgs,
    getActionStatusByIdAndActionCodeRequest,
    PostRequestArgs,
    postRequestCommentStatus,
    postRequestData,
    PostRequestDataArgs,
    postRequestStatus,
    removeRequestData,
    RunActionByIdAndActionCodeRequestArgs,
    runActionByIdAndActionCodeRequest,
    markRequestRead,
} from './request-slice-api';
import {requestSliceName} from './request-slice-constants';
import {RequestData, RequestStatus} from './request-slice-type';

export const loadRequestData = createAsyncThunk<
    RequestData, FetchRequestArgs
>(
    `${requestSliceName}/loadRequestData`,
    async (args, {rejectWithValue}) => {
        try {
            const response = await fetchRequestData(args);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);
export const readRequest = createAsyncThunk<
    string, {ids: string[]}
>(
    `${requestSliceName}/readRequest`,
    async (args, {rejectWithValue}) => {
        try {
            const response = await markRequestRead(args.ids);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);
interface RequestStatusesProps{
    commentId: string;
    requestFormKey: string;
}

interface RequestStatusesData{
    statuses: RequestStatus[];
    requestFormKey: string;
}
export const loadRequestStatusesData = createAsyncThunk<
    RequestStatusesData, RequestStatusesProps
>(
    `${requestSliceName}/loadRequestStatusesDate`,
    async ({requestFormKey, commentId}, {rejectWithValue}) => {
        try {
            const response = await postRequestStatus({commentId});
            return {
                statuses: response.data,
                requestFormKey,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);
export const loadRequestStatusesCommentData = createAsyncThunk<
    RequestStatusesData, RequestStatusesProps
>(
    `${requestSliceName}/loadRequestStatusesCommentData`,
    async ({requestFormKey, commentId}, {rejectWithValue}) => {
        try {
            const response = await postRequestCommentStatus({commentId});
            return {
                statuses: response.data,
                requestFormKey,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const removeRequest = createAsyncThunk<
    string, PostRequestArgs
>(
    `${requestSliceName}/removeRequest`,
    async ({commentId}, {rejectWithValue}) => {
        try {
            const response = await removeRequestData({commentId});
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

interface RequestFieldsMetadata{
    entityName: string;
    referenceUrl: string;
    id?: number;
}
export const loadRequestFieldsMetadata = createAsyncThunk<
  FieldMeta[],
  RequestFieldsMetadata
>(
    `${requestSliceName}/loadRequestFieldsMetadata`,
    async ({entityName, referenceUrl, id}, {rejectWithValue}) => {
        try {
            const response = await fetchEntityMetadata(entityName, EntityType.FORM);
            const {data} = await fetchFormDataFields(referenceUrl, id);
            const metadata = response?.data;
            const additionalFields = metadata?.additionalOptions?.optionsField?.additionalFields || [];
            const newAdditionalFields = mapServerFieldMetaToAppFieldMeta(
                additionalFields,
                undefined,
                data,
            );
            return [...metadata?.fields, ...newAdditionalFields];
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);
export const saveRequestData = createAsyncThunk<RequestStatusesData, PostRequestDataArgs
>(
    `${requestSliceName}/loadRequestStatusesDate`,
    async (args, {rejectWithValue}) => {
        try {
            const response = await postRequestData(args);
            return response?.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const getActionStatusByIdAndActionCode = createAsyncThunk<
    string, GetActionStatusByIdAndActionCodeRequestArgs
    >(
        `${requestSliceName}/getActionStatusByIdAndActionCode`,
        async (args, {rejectWithValue}) => {
            try {
                const response = await getActionStatusByIdAndActionCodeRequest(args);
                return response.data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

export const runActionByIdAndActionCode = createAsyncThunk<
    string, RunActionByIdAndActionCodeRequestArgs
    >(
        `${requestSliceName}/runActionByIdAndActionCode`,
        async (args, {rejectWithValue}) => {
            try {
                const response = await runActionByIdAndActionCodeRequest(args);
                return response.data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );
