import {
    Button, Card, Form, Space,
} from 'antd';
import useForm from 'antd/lib/form/hooks/useForm';
import React, {FunctionComponent} from 'react';

import {Fields} from 'components/form/fields/fields';
import {FieldMeta} from 'modules/metadata';

import '../metadata-constructor.less';

interface IOwnProps {
    data: {
        key: number|string;
        fields: FieldMeta[];
    };
    onValuesChange: (changedValues: any, values: any) => void;
    onReset?: () => void;
    showReset?: boolean;
}

type TProps = IOwnProps;

export const Filter: FunctionComponent<TProps> = ({
    data, onValuesChange, onReset, showReset,
}: TProps) => {
    const [form] = useForm();

    const handleFormReset = () => {
        form.resetFields();
        if (onReset) onReset();
    };

    return (
        <Card
            size="small"
            className="metadata-constructor-filter"
            title="Форма для выбора метаданных"
        >
            <Space direction="vertical">
                <Form
                    key={data.key}
                    form={form}
                    layout="inline"
                    onValuesChange={onValuesChange}
                >
                    <Fields
                        list={data.fields}
                    />
                    <Form.Item>
                        {showReset && (
                            <Button
                                onClick={handleFormReset}
                            >
                                Сбросить
                            </Button>
                        )}
                    </Form.Item>
                </Form>
            </Space>
        </Card>
    );
};
