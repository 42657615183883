import {Spin} from 'antd';
import React from 'react';
import {Link} from 'react-router-dom';

import {CustomTable, customTableUtils} from 'components/@common/widgets/custom-table';
import {ModalOpenerComponent, ModalOpenerComponentRef} from 'components/modal-opener-component';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {aisSelectors, aisSliceActions} from 'store/slices/ais-slice/ais-slice';
import {loadAisRequestsToPublish} from 'store/slices/ais-slice/ais-slice-thunks';
import {AisRegisterDocumentDto} from 'store/slices/ais-slice/ais-slice-types';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

export interface PlacementRequestModalProps {
    registerDocument: AisRegisterDocumentDto;
}

export const PlacementRequestModal = React.forwardRef<
ModalOpenerComponentRef, PlacementRequestModalProps>(({
    registerDocument,
}: PlacementRequestModalProps, ref) => {
    const dispatch = useAppDispatch();

    const aisRequestsToPublish = useAppSelector(aisSelectors.requestsToPublishSelectors.selectAll);

    const {id} = registerDocument ?? {};

    const isLoadingRequestsToPublish = useAppSelector(
        s => selectIsThunkPending(s, loadAisRequestsToPublish.typePrefix),
    );

    const loadRequestsToPublish = () => {
        if (id) {
            dispatch(loadAisRequestsToPublish({
                id,
            }));
        }
    };

    return (
        <ModalOpenerComponent
            ref={ref}
            onShow={() => {
                loadRequestsToPublish();
            }}
            afterModalClose={() => {
                dispatch(aisSliceActions.resetRequestsToPublish());
            }}
            hideControls={{
                all: true,
            }}
            controlLabels={{
                cancel: 'Закрыть',
            }}
            modalProps={{
                title: 'Запросы на доразмещение',
                width: '80vw',
                centered: true,
                forceRender: false,
                destroyOnClose: true,
            }}
        >
            <Spin spinning={isLoadingRequestsToPublish}>
                <CustomTable
                    dataSource={aisRequestsToPublish}
                    onColumn={col => ({
                        ...customTableUtils.setHeaderStyles({
                            whiteSpace: 'pre',
                            textAlign: 'center',
                        }),
                        ...col,
                        render: col.render ?? (cellValue => <div style={{textAlign: 'center'}}>{cellValue}</div>),
                    })}
                    columns={[
                        {
                            title: 'Идентификатор',
                            dataIndex: 'requestNumber',
                            render: (v, row) => (
                                <div style={{textAlign: 'center'}}>
                                    <Link to={`/requests/${row.topicId}`}>
                                        {v}
                                    </Link>
                                </div>
                            ),
                        },
                        {title: 'Дата и время получения\nзапроса', dataIndex: 'requestDate'},
                        {title: 'Срок ответа', dataIndex: 'responseDeadline'},
                        {title: 'Статус запроса', dataIndex: 'responseStatus'},
                        {title: 'Дата ответа', dataIndex: 'responseDate'},
                        {title: 'Ответственный', dataIndex: 'responseBy'},
                    ]}
                />
            </Spin>
        </ModalOpenerComponent>
    );
});
