import React from 'react';

import {stageStatus, monitoringStatusCode} from 'modules/documents/documents-upload-monitoring-types';
import {ReactComponent as Canceled} from 'shared/assets/cross-outlined.svg';
import {ReactComponent as Error} from 'shared/assets/error.svg';
import {ReactComponent as Reject} from 'shared/assets/minus.svg';
import {ReactComponent as OnHold} from 'shared/assets/on-hold.svg';
import {ReactComponent as Pending} from 'shared/assets/pending-icon.svg';
import {ReactComponent as Running} from 'shared/assets/running.svg';
import {ReactComponent as Success} from 'shared/assets/tick.svg';
import {ReactComponent as Warning} from 'shared/assets/warning.svg';

export interface DocumentsUploadStageProps {
    status: stageStatus | monitoringStatusCode;
}

export const DocumentsUploadStatusIcon = ({status}: DocumentsUploadStageProps) => {
    switch (status) {
    case stageStatus.pending:
        return <Pending />;
    case stageStatus.reject:
        return <Reject />;
    case stageStatus.success:
    case monitoringStatusCode.normal:
        return <Success />;
    case monitoringStatusCode.onHold:
        return <OnHold />;
    case monitoringStatusCode.warning:
        return <Warning />;
    case monitoringStatusCode.error:
        return <Error />;
    case monitoringStatusCode.running:
        return <Running />;
    default:
        return <Canceled />;
    }
};
