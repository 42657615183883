import get from 'lodash/get';
import moment from 'moment/moment';

import {EntityValue} from 'modules/data';

import {pathInStringStructure} from '../regular-expressions';

export const combineString = (stringStructure: string, value: EntityValue, dateFormat?: string) => {
    const valueKeys = stringStructure.match(pathInStringStructure('g'));
    return valueKeys?.reduce((finalString: string, valueKey: string) => {
        let finalText = get(value, valueKey, '');
        if (finalText == null || (typeof finalText === 'string' && !finalText.length)) {
            return finalString.replace(valueKey, '');
        }
        if (Date.parse(finalText) && dateFormat) {
            finalText = moment(finalText).format(dateFormat);
        }
        return finalString.replace(valueKey, finalText);
    }, stringStructure);
};
