import {Modal} from 'antd';
import cn from 'classnames';
import React from 'react';

import {Entity} from 'modules/data';
import {MetaActionInfo} from 'modules/metadata';
import {ModalComponentInterface} from 'store/slices/modals-slice';

import {EditableTable} from '../editable-table';
import {EditableColumn} from '../editable-table/editable-table-types';
import {TableAction} from '../table/action';

import './editable-table-modal.less';

interface EditableModalProps extends ModalComponentInterface {
    entityName: string;
    disabledPagination?: boolean;
    handleEdit: (row: Entity, args?: any) => void;
    columns?: EditableColumn[];
    onClose: () => void;
    modalTitle?: string;
    rowKey?: string;
    actions?: MetaActionInfo[];
    data?: any;
    width?: number | string;
}

export const EditableTableModal: React.FunctionComponent<EditableModalProps> = ({
    entityName,
    onClose,
    handleEdit,
    columns,
    modalTitle,
    rowKey,
    data,
    actions,
    disabledPagination,
    width,
}: EditableModalProps) => (
    <Modal
        className={cn('editable-table-modal')}
        title={modalTitle}
        visible
        width={width || '95vw'}
        onCancel={onClose}
        maskClosable={false}
        footer={null}
    >
        {columns && (
            <EditableTable
                disabledPagination={disabledPagination}
                handleSave={handleEdit}
                columns={columns}
                dataSource={data}
                rowKey={rowKey}
            />
        )}

        <div className="editable-container__actions">
            {actions?.map(action => (
                <TableAction
                    onClose={onClose}
                    meta={action}
                    key={action.actionTitle}
                    entityName={entityName}
                />
            ))}
        </div>

    </Modal>
);
