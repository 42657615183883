import React from 'react';

import docx from 'shared/assets/docx.png';
import {ReactComponent as Excel} from 'shared/assets/excel.svg';
import {ReactComponent as File} from 'shared/assets/forms/file.svg';
import json from 'shared/assets/json.png';
import {ReactComponent as Xml} from 'shared/assets/xml.svg';

export interface DocumentsUploadExtensionIconProps {
    fileName: string;
}

export const DocumentsUploadExtensionIcon = ({fileName}: DocumentsUploadExtensionIconProps) => {
    const fileExtension = fileName.split('.').pop();

    if (fileExtension === 'doc' || fileExtension === 'docx') {
        return (
            <img
                width={20}
                src={docx}
                alt="doc"
            />
        );
    }
    if (fileExtension === 'json') {
        return (
            <img
                width={20}
                src={json}
                alt="json"
            />
        );
    }
    if (fileExtension === 'xml') {
        return <Xml />;
    }
    if (fileExtension === 'xlsx') {
        return <Excel />;
    }
    return <File className="file-icon" />;
};
