import {Row} from 'antd';
import Form, {FormInstance} from 'antd/lib/form';
import {FormListFieldData} from 'antd/lib/form/FormList';
import React from 'react';

import {Field} from 'components/form/field/field';
import {FormEntityData} from 'modules/data/data-types';
import {FieldMeta} from 'modules/metadata';

interface FieldsTreeGeneratorProps {
    meta: FieldMeta;
    fieldData: FormListFieldData;
    formData?: FormEntityData;
    form?: FormInstance;
}

export const FieldsTreeGenerator = ({
    fieldData,
    meta,
    form,
    formData,
}: FieldsTreeGeneratorProps) => {
    const getFieldKeys = (fieldMeta: FieldMeta) => fieldMeta.key?.split?.('.') ?? [fieldMeta.key];

    return (
        <Row
            className="row"
            key={fieldData.key}
        >
            {meta?.children?.map(field => (
                !field?.children && !field?.children?.length
                    && (
                        <>
                            <Form.Item
                                {...fieldData}
                                key={field.key}
                                className="form-fields"
                                name={[fieldData.name, ...getFieldKeys(field)]}
                                dependencies={field?.dependentsFieldKey
                                    ? [meta.key, fieldData.name, field?.dependentsFieldKey]
                                    : undefined}
                            >
                                <Field
                                    key={field.key}
                                    fieldMeta={field}
                                    form={form}
                                    placeholder={field.placeholder}
                                    formData={formData}
                                    name={[fieldData.name, ...getFieldKeys(field)]}
                                    fieldKey={[fieldData.fieldKey, ...getFieldKeys(field)]}
                                />
                            </Form.Item>
                        </>
                    )
            ))}
        </Row>
    );
};
