import {FieldType} from 'modules/metadata';

export interface UseFieldLabelProps {
    type: FieldType;
    label?: string;
    hideLabel?: boolean;
}

export const useFieldLabel = ({type, hideLabel, label}: UseFieldLabelProps) => {
    const isFieldWithoutLabel = [
        FieldType.BOOLEAN,
        FieldType.FIELD_BLOCK,
        FieldType.FILE_LIST_WITH_SIGNS,
        FieldType.EXTERNAL_EMPLOYEE_BOOL,
        FieldType.TIME_WITH_LABEL_INSIDE,
        FieldType.INPUT_WITH_DEFAULT_VALUE_SETTER,
    ].includes(type);

    const computedLabel = (hideLabel || isFieldWithoutLabel) ? undefined : label;

    return {label: computedLabel};
};
