import React, {ReactText} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {selectEntityData} from 'modules/data';
import {createActionGetFile, createActionHandlerDownloadFile} from 'modules/data/data-actions';
import {TableEntityData} from 'modules/data/data-types';
import {RequestType} from 'modules/metadata';
import {EntityType} from 'shared/constants/entities';
import {AppState} from 'store/config/types';

import {ActionButton} from '../action-button';
import {TableActionProps} from '../table-action-types';

const ButtonActionForDownloadFileRowsComponent: React.FunctionComponent<TableActionProps> = ({
    wrapper,
    meta,
    entityName,
    ...props
}: TableActionProps) => {
    const referenceUrl = meta?.referenceUrl || entityName;
    const {requestType} = meta;
    const {selectedRowKeys = []} = useSelector((state: AppState) => (
        selectEntityData(entityName,
            EntityType.TABLE)(state) || {}) as TableEntityData);
    const dispatch = useDispatch();
    const actionsForRows = (ids: ReactText[]) => {
        if (requestType && requestType === RequestType.GET) {
            const newUrl = `${referenceUrl}?${selectedRowKeys.map(id => `ids=${id}`).join('&')}`;
            dispatch(createActionGetFile({referenceUrl: newUrl, requestType}));
            return;
        }
        dispatch(
            createActionHandlerDownloadFile(
                entityName,
                referenceUrl,
                RequestType.POST,
                {ids},
            ),
        );
    };
    const handleClick = () => {
        actionsForRows(selectedRowKeys);
    };
    const Component = wrapper || ActionButton;

    return (
        <Component
            {...props}
            onClick={handleClick}
            actionIcon={meta.actionIcon}
            disabled={!selectedRowKeys.length}
        >
            {meta.actionTitle}
        </Component>
    );
};

export const ButtonActionForDownloadFileRows = ButtonActionForDownloadFileRowsComponent;
