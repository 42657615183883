import {DraggableFrameUniqueTypeIds} from 'components/draggable-frame';
import {useAppDispatch} from 'store/config/hooks';
import {apiRequestProgressSliceActions} from 'store/slices/api-request-progress-slice/api-request-progress-slice';
import {createRequestProgress} from 'store/slices/api-request-progress-slice/api-request-progress-slice-thunks';
import {DraggableFrameType, draggableFramesActions} from 'store/slices/draggable-frames-slice';

interface CreateProgressFrameArgs {
    title?: string;
    extra?: any;
}

export const useApiRequestProgressFrame = () => {
    const dispatch = useAppDispatch();

    const {openDraggableFrame} = draggableFramesActions;
    const {
        updateRequestProgressPercent,
        removeRequestProgress,
    } = apiRequestProgressSliceActions;

    const createProgressFrame = async (args?: CreateProgressFrameArgs) => {
        dispatch(openDraggableFrame({
            type: DraggableFrameType.ApiRequestProgressFrame,
            id: DraggableFrameUniqueTypeIds.ApiRequestProgressFrame,
        }));

        const requestProgressId = await dispatch(createRequestProgress({
            title: args?.title,
            extra: args?.extra,
        })).unwrap();

        const handleProgress = (event: ProgressEvent<EventTarget>) => {
            const {loaded, total} = event;

            const uploadProgressPercent = Math.trunc((loaded / total) * 100);
            dispatch(updateRequestProgressPercent({
                id: requestProgressId,
                progressPercent: uploadProgressPercent,
            }));
        };

        const rollbackProgress = () => {
            dispatch(removeRequestProgress(requestProgressId));
        };

        return {
            handleProgress,
            rollbackProgress,
            requestProgressId,
        };
    };

    return {
        createProgressFrame,
    };
};
