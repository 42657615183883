import {FieldType} from 'modules/metadata';

import {COMMON_FIELDS_ITEMS} from '../common/common.fields-items';

export const BUTTON_SAVE_FORM = [
    COMMON_FIELDS_ITEMS.ACTIONS.REFERENCE_URL,
    {
        key: 'requestType',
        label: 'Тип запрос',
        type: FieldType.STATIC_SELECT,
        isRequired: true,
        options: [
            {
                label: 'POST',
                value: 'POST',
            },
        ],
    },
];

export const FORM_ACTIONS = {
    BUTTON_SAVE_FORM,
};
