import {
    Button,
} from 'antd';
import {FormInstance} from 'antd/es/form/Form';
import React, {
    useRef,
} from 'react';

import {SimpleActionButton} from 'components/form/components';
import {ModalOpenerComponent, ModalOpenerComponentRef} from 'components/modal-opener-component';

import {setOrdinalNumber} from '../../utils/report-table-settings.utils';
import s from './autofill-ordinal-number-modal-opener.module.less';

interface AutofillOrdinalNumberModalOpenerProps {
    disabled: boolean;
    form: FormInstance<any>;
}

export const AutofillOrdinalNumberModalOpener: React.FC<AutofillOrdinalNumberModalOpenerProps> = ({
    disabled,
    form,
}: AutofillOrdinalNumberModalOpenerProps) => {
    const modalOpenerRef = useRef<ModalOpenerComponentRef>(null);

    return (
        <ModalOpenerComponent
            ref={modalOpenerRef}
            hideControls={{
                all: true,
            }}
            footer={[
                <div className={s['button-group']}>
                    <Button
                        className={s.button}
                        key="submit"
                        type="primary"
                        onClick={() => {
                            const {reportColumns, customColumns} = setOrdinalNumber(
                                form.getFieldValue('reportTableColumns').reportColumns,
                                form.getFieldValue('reportTableColumns').customColumns,
                                true,
                            );
                            form.setFieldsValue({
                                reportTableColumns: {
                                    reportColumns,
                                    customColumns,
                                },
                            });
                            form.submit();
                            if (modalOpenerRef.current) modalOpenerRef.current.closeModal();
                        }}
                    >
                        Да
                    </Button>
                    <Button
                        className={s.button}
                        key="submit"
                        onClick={() => {
                            const {reportColumns, customColumns} = setOrdinalNumber(
                                form.getFieldValue('reportTableColumns').reportColumns,
                                form.getFieldValue('reportTableColumns').customColumns,
                                false,
                            );
                            form.setFieldsValue({
                                reportTableColumns: {
                                    reportColumns,
                                    customColumns,
                                },
                            });
                            form.submit();
                            if (modalOpenerRef.current) modalOpenerRef.current.closeModal();
                        }}
                    >
                        Нет
                    </Button>
                </div>,
            ]}
            component={(
                <SimpleActionButton
                    type="primary"
                    title="Заполнить порядок вывода"
                    icon="Settings"
                    disabled={disabled}
                />
            )}
            modalProps={{
                title: 'Параметры заполнения',
                width: '85%',
                style: {maxWidth: '500px'},
                forceRender: false,
                destroyOnClose: true,
                centered: true,
            }}
        >
            <section>
                <p className={s.modal__text}>Заполнить только поля, выводимые в отчет?</p>
            </section>
        </ModalOpenerComponent>
    );
};
