import {Card} from 'antd';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {Form as FormComponent, FormMode} from 'components/form';
import {FormModalWrapper} from 'components/form-modal/form-modal-wrapper';
import {Spinner} from 'components/spinner';
import {loadFormData, resetLoadedData} from 'modules/data';
import {performActionForForm} from 'modules/data/data-actions';
import {selectFormEntityData} from 'modules/data/data-selectors';
import {loadMetadata, RequestType} from 'modules/metadata';
import {selectFormEntityMetadata} from 'modules/metadata/metadata-selectors';
import {EntityType} from 'shared/constants/entities';
import {useAppSelector} from 'store/config/hooks';
import {ModalComponentInterface} from 'store/slices/modals-slice';

export const DocumentCodesFormModal = ({
    onClose,
    parameters,
}: ModalComponentInterface) => {
    const dispatch = useDispatch();
    const {entityName, url, recordId} = parameters || {};
    const metadata = useAppSelector(selectFormEntityMetadata(entityName));
    const data = useAppSelector(selectFormEntityData(entityName));
    const isLoaded = !!metadata && !!data;

    const actionForForm = (
        name: string,
        referenceUrl: string,
        requestType: RequestType,
        newData: Record<string, any>,
        isJsonRequest?: boolean,
        urlParamKey?: string,
    ) => dispatch(performActionForForm()(
        name, url || referenceUrl, requestType, newData, isJsonRequest, urlParamKey,
    ));

    useEffect(() => {
        if (!metadata) {
            dispatch(loadMetadata(entityName, EntityType.FORM));
        }
        if (recordId) {
            dispatch(loadFormData(entityName, recordId, url));
        }
        return () => {
            dispatch(resetLoadedData(entityName, EntityType.FORM));
        };
    }, []);
    return (
        <FormModalWrapper
            entityName={entityName}
            onClose={onClose}
        >
            {handleClose => (
                isLoaded ? (
                    <FormComponent
                        onClose={handleClose}
                        formData={data}
                        entityName={entityName}
                        mode={recordId ? FormMode.EDIT : FormMode.CREATE}
                        meta={metadata}
                        actionForForm={actionForForm}
                    />
                ) : (<Card style={{paddingTop: 20}}> <Spinner /></Card>)
            )}
        </FormModalWrapper>
    );
};
