import {unwrapResult} from '@reduxjs/toolkit';
import {Empty, Space, Spin} from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import React, {useEffect, useState} from 'react';
import {
    BarChart as BarChartRecharts,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    TooltipProps, Cell, ResponsiveContainer,
} from 'recharts';

import {StaticSelect} from 'components/form/inputs/static-select';
import {Spinner} from 'components/spinner';
import {ContextRawData} from 'modules/context/context-types';
import {showRequestErrorMessage} from 'shared/utils/show-message-from-response';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';
import {
    loadNobComponentsCorrNumList,
    loadNobComponentsData,
    selectWidgetsNOBComponentsCorrNumList,
    selectWidgetsNobComponentsData,
} from 'store/slices/widgets-slice';

import {Card} from '../../components/card';

import './nob-components-bar-chart.less';

export interface CorrNum {
    docId: number;
    corrNum: string;
}

export interface NobComponent {
    name: string;
    shortValue: number;
    value: number;
}

const RUB = '₽';

interface CustomTooltipProps extends TooltipProps<number | string | Array<number | string>, number | string> {
}

const CustomTooltip: React.FunctionComponent<CustomTooltipProps> = ({active, payload, label}: CustomTooltipProps) => {
    if (active && payload && payload.length) {
        const number = `${payload[0].payload.value}`.replace(/(?<=\d)(?=(\d{3})+(?!\d))/g, ' ');
        const value = `${number} ${RUB}`;

        return (
            <div className="custom-tooltip">
                <p className="label">{`${label}\n${value}`}</p>
            </div>
        );
    }

    return null;
};

const calculateDomain = (nobComponentsData: NobComponent[] | undefined) => (nobComponentsData ? undefined : [0, 0]);

interface BarChartProps {
    contextRawData: ContextRawData | undefined;
}

export const NobComponentsBarChart: React.FunctionComponent<BarChartProps> = ({
    contextRawData,
}: BarChartProps) => {
    const dispatch = useAppDispatch();
    const barChartStyleProps = {
        width: 700,
        height: 400,
        margin: {
            top: 5,
            right: 30,
            left: 10,
            bottom: 5,
        },
    };

    const nobComponentsCorrNumList = useAppSelector(state => selectWidgetsNOBComponentsCorrNumList(state));
    const nobComponentsData = useAppSelector(state => selectWidgetsNobComponentsData(state));

    const isLoadingCorrNumList = useAppSelector(s => selectIsThunkPending(s, loadNobComponentsCorrNumList.typePrefix));
    const isLoadingData = useAppSelector(s => selectIsThunkPending(s, loadNobComponentsData.typePrefix));
    const isLoading = isLoadingCorrNumList || isLoadingData;

    useEffect(() => {
        if (contextRawData && contextRawData?.taxPeriodId && contextRawData?.organizationId) {
            dispatch(loadNobComponentsCorrNumList({
                taxPeriodId: contextRawData.taxPeriodId,
                organizationId: contextRawData.organizationId,
            })).then(unwrapResult).catch(showRequestErrorMessage);
        }
    }, [contextRawData]);

    const [docId, setDocId] = useState<number | null>(null);

    useEffect(() => {
        if (docId) { dispatch(loadNobComponentsData({docId})); }
    }, [docId]);

    const handleCorrNumListChange = (value: number) => {
        setDocId(value);
    };

    return (
        <Card className="nob-components-bar-chart-widget">
            <Space align="baseline">
                <Title
                    className="nob-components-bar-chart-widget__title"
                    level={2}
                >
                    Компоненты НОБ
                </Title>
                <Text
                    type="secondary"
                    strong
                >
                    млн {RUB}
                </Text>
            </Space>
            <br />
            {(() => {
                if ((!nobComponentsCorrNumList || !nobComponentsCorrNumList?.length) && !isLoadingCorrNumList) {
                    return <Empty description="Нет данных по номеру корректировки" />;
                }
                if (!(nobComponentsCorrNumList && contextRawData)) {
                    return <Spinner style={{marginTop: 60}} />;
                }
                return (
                    <>
                        <Paragraph className="nob-components-bar-chart-widget__select-label">
                            Номер корректировки
                        </Paragraph>
                        <div className="nob-components-bar-chart-widget__select">
                            <StaticSelect
                                onChange={handleCorrNumListChange}
                                options={nobComponentsCorrNumList.map(num => ({
                                    label: num.corrNum,
                                    value: num.docId.toString(),
                                }))}
                                useFirstOptionAsDefaultValue
                            />
                        </div>
                        {nobComponentsData ? (
                            <Spin
                                spinning={isLoading}
                                tip="Загрузка..."
                            >
                                <ResponsiveContainer
                                    width="100%"
                                    height="100%"
                                    minHeight="400px"
                                >
                                    <BarChartRecharts
                                        className="nob-components-bar-chart-widget__chart"
                                        layout="vertical"
                                        data={nobComponentsData}
                                        {...barChartStyleProps}
                                    >
                                        <CartesianGrid
                                            strokeDasharray="1 1"
                                        />
                                        <XAxis
                                            type="number"
                                            interval="preserveStartEnd"
                                            tickCount={11}
                                        />
                                        <YAxis
                                            width={175}
                                            dataKey="name"
                                            type="category"
                                            tickLine={false}
                                            domain={calculateDomain(nobComponentsData)}
                                        />
                                        <Tooltip content={CustomTooltip} />
                                        <Legend />
                                        <Bar
                                            dataKey="shortValue"/*  */
                                            barSize={24}
                                            legendType="none"
                                        >
                                            {nobComponentsData?.map?.(entry => (
                                                <Cell
                                                    key={entry.value}
                                                    fill={entry.shortValue > 0 ? '#67D89F' : '#FF7C84'}
                                                />
                                            ))}
                                        </Bar>
                                    </BarChartRecharts>
                                </ResponsiveContainer>
                            </Spin>
                        ) : <Spinner />}
                    </>
                );
            })()}
        </Card>
    );
};
