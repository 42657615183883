import {SearchOutlined} from '@ant-design/icons';
import {
    Button, Form, Input, Popover,
} from 'antd';
import {useForm} from 'antd/es/form/Form';
import cn from 'classnames';
import {flatten, pick} from 'lodash';
import React, {
    MutableRefObject, useEffect, useMemo, useRef, useState,
} from 'react';

import {HIDDEN_COLUMNS_LOCAL_STORAGE_KEY} from 'components/table-report/hooks/use-hidden-columns';
import {ReactComponent as ArrowLeftOutlined} from 'shared/assets/arrow-left-outlined.svg';
import {ReactComponent as ArrowRightOutlined} from 'shared/assets/arrow-right-outlined.svg';
import {ErrorMessages} from 'shared/constants/messages';
import {useAfterEffect} from 'shared/hooks/use-after-effect';
import {showMessage, showMessageFromResponse} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {
    selectTableReportGlobalPages,
    selectTableReportGlobalSearchCurrentPage,
    selectTableReportGlobalSearchIsLoading,
    selectTableReportGlobalSearchLine,
    selectTableReportLocalSearch,
} from 'store/slices/table-report-slice/table-report-search/table-report-search-selectors';
import {performGlobalSearch} from 'store/slices/table-report-slice/table-report-search/table-report-search-thunks';
import {tableReportSliceActions} from 'store/slices/table-report-slice/table-report-slice';
import {selectLastTableReportLoadPageRequestArgs} from 'store/slices/table-report-slice/table-report-slice-selectors';
import {loadTableReportPageData} from 'store/slices/table-report-slice/table-report-slice-thunks';
import {TableReportBodyItem, TableReportColumn} from 'store/slices/table-report-slice/table-report-slice-types';

import {TABLE_SEARCH_NEW_HIDDEN_COLUMNS_SET_EVENT} from './table-report-search-constants';
import {createSearchRegex} from './table-report-search-utils';

import './table-report-search.less';

interface TableReportSearchProps {
    dataSource: TableReportBodyItem[] | undefined;
    columns: (TableReportColumn & {dataIndex: string})[] | undefined;
}

export const TableReportSearch: React.FC<TableReportSearchProps> = ({
    dataSource: tableReportData,
    columns,
}: TableReportSearchProps) => {
    const dispatch = useAppDispatch();
    const [form] = useForm();

    const reportColumnKeys = columns?.map(c => c.dataIndex) ?? [];

    const lastRequestParams = useAppSelector(selectLastTableReportLoadPageRequestArgs);

    const {
        setLocalSearchData,
        updateLocalSearchData,
        dropSearch,
        setGlobalSearchCurrentPage,
        setGlobalSearchIsLoading,
        setGlobalSearchPages,
        setGlobalSearchLine,
    } = tableReportSliceActions;

    const hiddenColumnsInStorage = localStorage.getItem(HIDDEN_COLUMNS_LOCAL_STORAGE_KEY);
    const [hiddenColumnsByStore, setHiddenColumnsByStore] = useState(
        hiddenColumnsInStorage ? JSON.parse(hiddenColumnsInStorage) : {},
    );

    useEffect(() => {
        const handler = () => {
            const newHiddenColumnsInStorage = localStorage.getItem(HIDDEN_COLUMNS_LOCAL_STORAGE_KEY);
            setHiddenColumnsByStore(newHiddenColumnsInStorage ? JSON.parse(newHiddenColumnsInStorage) : {});
        };
        window.addEventListener(TABLE_SEARCH_NEW_HIDDEN_COLUMNS_SET_EVENT, handler);
        return () => {
            window.removeEventListener(TABLE_SEARCH_NEW_HIDDEN_COLUMNS_SET_EVENT, handler);
        };
    }, []);

    const columnContentFlat = useMemo(() => flatten((tableReportData ?? [])
        .map(entry => Object.values(pick(entry, reportColumnKeys ?? [])))), [
        tableReportData, reportColumnKeys,
    ]);

    const searchData = useAppSelector(selectTableReportLocalSearch);
    const {currentSelection, count: totalMatchesCount} = searchData;

    const submitRef = useRef<ReturnType<typeof setTimeout>>(null) as MutableRefObject<ReturnType<typeof setTimeout>>;

    const isLoading = useAppSelector(selectTableReportGlobalSearchIsLoading);
    const globalSearchPages = useAppSelector(selectTableReportGlobalPages);
    const globalSearchLine = useAppSelector(selectTableReportGlobalSearchLine);
    const globalSearchCurrent = useAppSelector(selectTableReportGlobalSearchCurrentPage);
    const {
        page: globalSearchCurrentPage,
        index: globalSearchCurrentPageIndex,
    } = globalSearchCurrent;

    const isGettingBackRef = useRef(false);
    // ref нужен для перемещения на последний найденный элемент на странице,
    // когда переходим на страницу назад

    const handleLocalSearch = (searchLine?: string, selectLast?: boolean) => {
        const matchResults = (() => {
            if (!searchLine) return {count: 0, results: []};
            const results: Array<{columnIndex: number; rowIndex: number}> = [];
            columnContentFlat.forEach((tableValue, index) => {
                const matches = String(tableValue).match(createSearchRegex(searchLine));
                if (matches?.length) {
                    const columnIndex = index % reportColumnKeys.length;
                    const rowIndex = Math.trunc((index / reportColumnKeys.length));
                    results.push({
                        columnIndex,
                        rowIndex,
                    });
                }
            });
            return {count: results.length, results};
        })();
        const {count, results} = matchResults;

        dispatch(setLocalSearchData({
            newData: {
                count,
                line: searchLine ?? '',
                results,
                currentSelection: selectLast ? (results?.length ?? 1) - 1 : 0,
            },
        }));
    };

    useAfterEffect(() => {
        handleLocalSearch(form.getFieldValue('search'));
    }, [hiddenColumnsByStore]);

    const paramsForSearchRequest = (() => {
        if (!lastRequestParams) return undefined;
        return {
            templateCode: lastRequestParams.templateCode,
            ...lastRequestParams.dataParams,
            lookupType: undefined,
            searchValue: globalSearchLine,
        } as any;
    })();

    useAfterEffect(() => {
        if (globalSearchPages.length) {
            const [firstFoundPage] = globalSearchPages;
            dispatch(setGlobalSearchCurrentPage({
                page: firstFoundPage,
                index: 0,
            }));
        } else {
            handleLocalSearch(form.getFieldValue('search'));
            dispatch(setGlobalSearchIsLoading(false));
        }
    }, [globalSearchPages]);

    useAfterEffect(() => {
        if (!lastRequestParams) return;
        if (globalSearchCurrentPage < 0) {
            handleLocalSearch('');
            isGettingBackRef.current = false;
            dispatch(setGlobalSearchIsLoading(false));
            return;
        }
        if (lastRequestParams) {
            dispatch(loadTableReportPageData({
                ...lastRequestParams,
                dataParams: {
                    ...lastRequestParams.dataParams,
                    page: globalSearchCurrentPage,
                },
                useConfigCache: true,
            })).finally(() => {
                dispatch(setGlobalSearchIsLoading(false));
            });
        }
    }, [globalSearchCurrent]);

    useAfterEffect(() => {
        if (!paramsForSearchRequest) return;
        if (!globalSearchLine) {
            dispatch(setGlobalSearchPages([]));
            dispatch(setGlobalSearchCurrentPage({
                index: -1,
                page: -1,
            }));
            handleLocalSearch('');
            form.setFieldsValue({search: globalSearchLine ?? ''});
        } else {
            dispatch(setGlobalSearchIsLoading(true));
            dispatch(performGlobalSearch(paramsForSearchRequest)).unwrap()
                .then(res => {
                    if (!res.length) showMessage({message: ErrorMessages.NOT_FOUND, isError: true});
                })
                .catch(err => {
                    showMessageFromResponse({response: err, isError: true});
                    dispatch(setGlobalSearchIsLoading(false));
                });
        }
    }, [globalSearchLine]);

    useEffect(() => {
        if (globalSearchLine && globalSearchPages.length) {
            handleLocalSearch(globalSearchLine, isGettingBackRef.current);
            isGettingBackRef.current = false;
        }
    }, [tableReportData]);

    const onFinish = (v: any) => {
        isGettingBackRef.current = false;
        dispatch(setGlobalSearchLine(v?.search ?? ''));
    };

    const onFinishFailed = () => {};

    useEffect(() => {
        dispatch(dropSearch());
        return () => {
            clearTimeout(submitRef.current);
        };
    }, []);

    return (
        <div className="table-report-search">
            <Form
                form={form}
                name="table-report"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                onValuesChange={dif => {
                    if (!dif?.search) dispatch(setGlobalSearchLine(''));
                }}
                onChange={() => {
                    clearTimeout(submitRef.current);
                    submitRef.current = setTimeout(() => {
                        form.submit();
                    }, 700);
                }}
            >
                <Form.Item
                    name="search"
                    shouldUpdate
                    style={{flexGrow: 1}}
                >
                    <Input
                        allowClear
                        placeholder="Поиск"
                        prefix={
                            (
                                <SearchOutlined
                                    style={{fontSize: '18px', color: '#BDC0C3'}}
                                />
                            )
                        }
                    />
                </Form.Item>
            </Form>
            {!!globalSearchPages.length && searchData.currentSelection !== null && (
                <div className="table-report-search__controls">
                    <div className="table-report-search__controls__buttons">
                        <Button
                            disabled={
                                isLoading
                                    || (currentSelection === 0 && (
                                        globalSearchCurrentPageIndex === 0
                                    ))
                            }
                            onClick={() => {
                                if (currentSelection === null) return;
                                if (globalSearchCurrentPageIndex && (currentSelection === 0
                                    || totalMatchesCount === 0)) {
                                    const newPageIndex = globalSearchCurrentPageIndex - 1;
                                    const newPage = globalSearchPages[newPageIndex];
                                    dispatch(setGlobalSearchIsLoading(true));
                                    dispatch(setGlobalSearchCurrentPage({
                                        index: newPageIndex,
                                        page: newPage,
                                    }));
                                    isGettingBackRef.current = true;
                                } else {
                                    dispatch(updateLocalSearchData({
                                        newData: {
                                            currentSelection: currentSelection - 1,
                                        },
                                    }));
                                }
                            }}
                        >
                            <ArrowLeftOutlined style={{color: '#BDC0C3'}} />
                        </Button>
                        <Button
                            disabled={
                                isLoading
                                    || (currentSelection !== null
                                    && (currentSelection + 1 === totalMatchesCount
                                        && globalSearchCurrentPageIndex + 1 === globalSearchPages.length))
                            }
                            onClick={() => {
                                if (currentSelection === null) return;
                                if ((!totalMatchesCount || currentSelection + 1 === totalMatchesCount)
                                        && globalSearchCurrentPageIndex + 1 !== globalSearchPages.length) {
                                    const newPageIndex = globalSearchCurrentPageIndex + 1;
                                    const newPage = globalSearchPages[newPageIndex];
                                    dispatch(setGlobalSearchIsLoading(true));
                                    dispatch(setGlobalSearchCurrentPage({
                                        index: newPageIndex,
                                        page: newPage,
                                    }));
                                } else {
                                    dispatch(updateLocalSearchData({
                                        newData: {
                                            currentSelection: currentSelection + 1,
                                        },
                                    }));
                                }
                            }}
                        >
                            <ArrowRightOutlined style={{color: '#BDC0C3'}} />
                        </Button>
                    </div>
                    <Popover
                        placement="bottom"
                        content={(
                            <div className={cn('table-report-search__controls__selection-info')}>
                                <div>
                                    <span>Текущая страница</span>: {globalSearchCurrentPageIndex + 1}
                                </div>
                                <div>
                                    <span>Всего страниц с совпадениями</span>: {globalSearchPages.length}
                                </div>

                                <div style={{marginTop: 8}}>
                                    {totalMatchesCount ? (
                                        <>
                                            {
                                                currentSelection !== null && (
                                                    <div>
                                                        <span>Текущая ячейка</span>: {currentSelection + 1}
                                                    </div>
                                                )
                                            }
                                            <div>
                                                <span>Ячеек с совпадениями на странице:</span> {totalMatchesCount}
                                            </div>
                                        </>
                                    ) : 'Нет совпадений в ячейках'}
                                </div>
                            </div>
                        )}
                    >
                        <div className="table-report-search__controls__selection">
                            {globalSearchCurrentPageIndex + 1}/{globalSearchPages.length}
                        </div>
                    </Popover>
                </div>
            )}
        </div>
    );
};
