import {
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
import {uniqBy} from 'lodash';

import {AppState} from 'store/config/types';

import {externalSystemsSliceName} from './external-systems-slice-constants';
import {
    loadAllExternalSystemsDataForUser,
    loadAvailableExternalSystemsForUser,
    loadExternalSystemsForUser,
    loadUseExternalSystemsPropertyFlag,
} from './external-systems-slice-thunks';
import {
    ExternalSystemUserMappingDto,
    ExternalSystemsSliceState,
} from './external-systems-slice-types';

export const externalSystemsSlice = createSlice({
    initialState: {
        availableExternalSystems: {},
        externalSystems: {},
        areExternalSystemsEnabled: undefined,
    } as ExternalSystemsSliceState,
    name: externalSystemsSliceName,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(loadAvailableExternalSystemsForUser.fulfilled, (state, action) => {
            const {meta, payload} = action;
            const {arg} = meta;
            if (arg.userId) {
                state.availableExternalSystems[arg.userId] = payload;
            }
        });
        builder.addCase(loadExternalSystemsForUser.fulfilled, (state, action) => {
            const {meta, payload} = action;
            const {arg} = meta;
            if (arg.userId) {
                state.externalSystems[arg.userId] = payload;
            }
        });
        builder.addCase(loadAllExternalSystemsDataForUser.fulfilled, (state, action) => {
            const {meta, payload} = action;
            const {arg} = meta;
            if (arg.userId) {
                state.externalSystems[arg.userId] = payload.externalSystems;
                state.availableExternalSystems[arg.userId] = payload.availableExternalSystems;
            }
        });
        builder.addCase(loadUseExternalSystemsPropertyFlag.fulfilled, (state, action) => {
            state.areExternalSystemsEnabled = action.payload === 'Y';
        });
    },
});

const selectExternalSystemsSliceState = (state: AppState) => state[externalSystemsSliceName];

const selectAllAvailableExternalSystems = createSelector(
    selectExternalSystemsSliceState, state => state.availableExternalSystems,
);

const selectAllExternalSystems = createSelector(selectExternalSystemsSliceState, state => state.externalSystems);

export const selectAreExternalSystemsEnabled = createSelector(
    selectExternalSystemsSliceState, state => state.areExternalSystemsEnabled,
);

export const selectUserAvailableExternalSystems = createSelector([
    selectAllAvailableExternalSystems,
    (_, userId?: number) => userId,
], (availableExternalSystems, userId) => {
    if (!userId) return undefined;
    return availableExternalSystems[userId];
});

export const selectUserExternalSystems = createSelector([
    selectAllExternalSystems,
    (_, userId?: number) => userId,
], (externalSystems, userId) => {
    if (!userId) return undefined;
    return externalSystems[userId];
});

export const selectExternalSystemsDataForForm = createSelector([
    selectUserAvailableExternalSystems,
    selectUserExternalSystems,
], (
    availableExternalSystems,
    externalSystems,
) => {
    const systems = availableExternalSystems?.map(availableExtSyst => {
        const {
            id: availableExtSystId,
            lookupCode: availableExtSystName,
        } = availableExtSyst;

        const foundExternalSystem = externalSystems?.find(extSyst => extSyst.externalSystemId === availableExtSystId);

        if (foundExternalSystem) return foundExternalSystem;
        return {
            externalSystemName: availableExtSystName,
            externalSystemId: availableExtSystId,
            externalMappingId: undefined,
            externalSystemUserID: undefined,
        } as Partial<ExternalSystemUserMappingDto>;
    });

    return uniqBy(systems, s => s.externalSystemId);
});

export const externalSystemsSelectors = {
    selectExternalSystemsSliceState,
    selectUserAvailableExternalSystems,
    selectUserExternalSystems,
};

export const {
    reducer: externalSystemsSliceReducer,
    actions: externalSystemsSliceActions,
} = externalSystemsSlice;
