import {FormInstance} from 'antd/lib/form';
import {pick} from 'lodash';

import {
    ReportPropertiesFieldTypes,
    ReportPropertiesSettingsFieldsResetDependencies,
    ReportPropertiesSettingsFormFields,
} from '../constants/report-properties-settings';

export const ReportPropertiesDefaultValueToTypeMap = {
    [ReportPropertiesFieldTypes.INTEGER]: ReportPropertiesSettingsFormFields.defaultValueInteger,
    [ReportPropertiesFieldTypes.NUMBER]: ReportPropertiesSettingsFormFields.defaultValueInteger,
    [ReportPropertiesFieldTypes.STRING]: ReportPropertiesSettingsFormFields.defaultValueString,
    [ReportPropertiesFieldTypes.DATE]: ReportPropertiesSettingsFormFields.defaultValueDate,
    [ReportPropertiesFieldTypes.DATETIME]: ReportPropertiesSettingsFormFields.defaultValueDateTime,
    [ReportPropertiesFieldTypes.BOOLEAN]: ReportPropertiesSettingsFormFields.defaultValueBoolean,
    [ReportPropertiesFieldTypes.VALUE_SET]: ReportPropertiesSettingsFormFields.defaultValueValueSet,
    [ReportPropertiesFieldTypes.MULTI_VALUE_SET]: ReportPropertiesSettingsFormFields.defaultValueMultiValueSet,
    [ReportPropertiesFieldTypes.SQLVALUESET]: ReportPropertiesSettingsFormFields.defaultValueSqlValueSet,
    [ReportPropertiesFieldTypes.SQLMULTIVALUESET]: ReportPropertiesSettingsFormFields.defaultValueSqlValueSet,
};

const reportPropertiesDefaultValueMapper = (values: any) => {
    if (!values.defaultType || values.defaultType === 'UNSET') return undefined;

    if (values?.defaultType === 'CONTEXT') {
        return values?.defaultValueContext;
    }
    const field = ReportPropertiesDefaultValueToTypeMap[values?.type as ReportPropertiesFieldTypes];

    if (field && values?.type === ReportPropertiesFieldTypes.MULTI_VALUE_SET) {
        if (Array.isArray(values?.[field])) {
            return values[field].join(', ');
        }
        return undefined;
    }

    if (field) return values?.[field];
    return undefined;
};

export const convertPropertiesFormDataToRequestData = (values: any) => {
    if (!values) return values;
    if (!values && !Array.isArray(values) && !values?.length) return [];
    const dtoMappedValues = values.map((v: any) => ({
        ...v,
        defaultValue: reportPropertiesDefaultValueMapper(v),
        defaultType: v.defaultType === 'UNSET' ? undefined : v.defaultType,
    }));
    return dtoMappedValues.map((v: any) => pick(v, Object.values(ReportPropertiesSettingsFormFields)));
};

export const getDataFromReportPropertiesSettingsRequest = (values: unknown[]) => values.map((v: any) => {
    const defaultValue = (() => {
        if (!v.defaultType || v.defaultType === 'UNSET') return undefined;
        return v.defaultValue;
    })();

    return {
        ...v,
        [ReportPropertiesDefaultValueToTypeMap[v.type as ReportPropertiesFieldTypes]]: defaultValue,
        defaultValue,
        defaultValueContext: v.defaultType === 'CONTEXT' ? defaultValue : undefined,
        defaultValueMultiValueSet: (() => {
            if (v.type !== 'MULTIVALUESET') return undefined;
            if (v.defaultType === 'CONTEXT') return defaultValue;
            if (typeof defaultValue === 'string') defaultValue?.split(', ');
            if (Array.isArray(defaultValue)) return v.defaultValue;
            return undefined;
        })(),
    };
});

export const resetReportPropertiesSettingsFormFields = (
    form: FormInstance,
    fieldName: number,
    field: keyof typeof ReportPropertiesSettingsFieldsResetDependencies,
) => {
    const {queryParameters} = form.getFieldsValue();
    const queryParametersCopy = [...queryParameters];

    queryParametersCopy[fieldName] = {
        ...queryParameters[fieldName],
        ...(ReportPropertiesSettingsFieldsResetDependencies[field].reduce((acc, curr) => ({
            ...acc,
            [curr]: curr === ReportPropertiesSettingsFormFields.defaultType ? 'UNSET' : undefined,
        }), {})),
    };
    form.setFieldsValue({
        queryParameters: queryParametersCopy,
    });
};
