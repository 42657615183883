import {Radio} from 'antd';
import {RadioChangeEvent} from 'antd/lib/radio';
import React, {useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {resetLoadedData} from 'modules/data';
import {resetMetadata} from 'modules/metadata/metadata-actions';
import {SubsectionMenu} from 'modules/metadata/metadata-types';
import {EntityType} from 'shared/constants/entities';

import './subsection-switcher.less';

export interface SubsectionSwitcherProps {
    entityName: string;
    location: string;
    subsections: SubsectionMenu[];
    changeSubsection?: (e: RadioChangeEvent) => void;
    checkedSubsectionValue?: string;
}

export const SubsectionSwitcher: React.FunctionComponent<SubsectionSwitcherProps> = (
    {
        entityName, location, subsections, changeSubsection, checkedSubsectionValue,
    }: SubsectionSwitcherProps,
) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const defaultValue = checkedSubsectionValue || subsections.find(
        sub => sub.key === location?.replaceAll('/', ''),
    )?.key;
    const [subsection, setSubsection] = useState<string | undefined>(defaultValue);
    const handleSubsectionChange = (e: RadioChangeEvent) => {
        dispatch(resetLoadedData(entityName));
        dispatch(resetMetadata({entityName, entityType: EntityType.TABLE, loading: true}));
        dispatch(resetMetadata({entityName, entityType: EntityType.FILTER, loading: true}));
        dispatch(resetMetadata({entityName, entityType: EntityType.EDITABLE_TABLE, loading: true}));
        if (changeSubsection) {
            changeSubsection(e);
            return;
        }
        setSubsection(e.target.value);
        history.push(e.target.value);
    };
    const sortedSubsections = useMemo(() => subsections.sort(
        (a, b) => (a.order && b.order ? a.order - b.order : 0),
    ), [subsections]);

    return (
        <Radio.Group
            className="subsections"
            value={checkedSubsectionValue || subsection}
            onChange={e => handleSubsectionChange(e)}
        >
            {sortedSubsections.map(sub => (
                <Radio.Button
                    key={sub.key}
                    value={sub.key}
                >
                    {sub.title}
                </Radio.Button>
            ))}
        </Radio.Group>
    );
};
