import cn from 'classnames';
import React from 'react';

import {DashboardEntry} from 'modules/data/data-types';

import {WidgetWrapper} from '../../components/widget-wrapper';
import {useStatisticsInitialization} from '../../hooks/use-statistics-initialization';
import {StatisticsEmptyPlaceholder} from '../statistics-empty-placeholder';
import {HalfCircleChart} from './half-circle-chart';

import './half-circle-statistics.less';

interface HalfCircleStatisticsProps {
    entries: DashboardEntry;
    title?: string;
}

export const HalfCircleStatistics: React.FC<HalfCircleStatisticsProps> = ({
    entries, title,
}: HalfCircleStatisticsProps) => {
    const {statisticsData} = useStatisticsInitialization({
        entries,
        settings: {lowercaseEntityKeys: true, useReducedAvailableBarColors: false},
    });

    return (
        <WidgetWrapper title={title}>
            <div className={cn('half-circle-statistics')}>
                <div className={cn('half-circle-statistics__description')}>
                    {
                        statisticsData.map(({key, value, color}) => (
                            <div
                                key={key}
                                className={cn('half-circle-statistics__description__entry')}
                            >
                                <div
                                    style={{backgroundColor: color}}
                                    className={cn('half-circle-statistics__description__entry__list-marker')}
                                />
                                <span className={cn('half-circle-statistics__description__entry__key')}>{key}</span>
                                <span>{value}</span>
                            </div>
                        ))
                    }
                </div>
                <div className={cn('half-circle-statistics__chart')}>
                    {statisticsData?.length
                        ? <HalfCircleChart {...{statisticsData}} /> : <StatisticsEmptyPlaceholder />}
                </div>
            </div>
        </WidgetWrapper>
    );
};
