import React, {useEffect, useState} from 'react';

import {flattenDeepArrayByField} from 'shared/utils';
import {TableReportMenu} from 'store/slices/table-report-slice/table-report-slice-types';

import {AsideDropdown} from '../aside-dropdown/aside-dropdown';
import {AsideRow} from '../aside-row/aside-row';

import './aside-node.less';

interface AsideNodeProps {
    menu: TableReportMenu;
    name: string;
    depth: number;
    activeMenu: TableReportMenu | undefined;
    onRowClick: (menu: TableReportMenu) => void;
}

export const AsideNode = ({
    menu,
    name,
    depth,
    activeMenu,
    onRowClick,
}: AsideNodeProps) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

    const handleDropdownClick = (event: React.MouseEvent<SVGSVGElement>) => {
        event.stopPropagation();
        setIsDropdownOpen(prev => !prev);
    };

    const isActive = (
        menu.name === activeMenu?.name
            && menu.caption === activeMenu?.caption
            && menu?.sheetCode === activeMenu?.sheetCode
    );

    useEffect(() => {
        const submenus = flattenDeepArrayByField<TableReportMenu, 'children'>({
            array: [menu],
            fieldName: 'children',
            shouldOmit: true,
        });
        const submenusWithoutRoot = submenus?.slice(1, submenus.length);
        const atLeastSubmenuOneIsActive = submenusWithoutRoot?.some(
            sm => sm.name === activeMenu?.name && sm.caption === activeMenu?.caption,
        );
        if (atLeastSubmenuOneIsActive) setIsDropdownOpen(true);
    }, [activeMenu]);

    if (menu.children && !menu.children.every(m => m?.hidden)) {
        return (
            <>
                <AsideRow
                    name={name}
                    active={isActive}
                    onClick={() => { onRowClick(menu); }}
                    open={isDropdownOpen}
                    onDropdown={handleDropdownClick}
                    depth={depth}
                    hasChildren
                />
                <AsideDropdown
                    parent={menu}
                    submenus={menu.children}
                    depth={depth}
                    open={isDropdownOpen}
                    activeMenu={activeMenu}
                    onRowClick={onRowClick}
                />
            </>
        );
    }
    if (!menu.hidden) {
        return (
            <>
                <AsideRow
                    name={name}
                    depth={depth}
                    active={isActive}
                    onClick={() => {
                        onRowClick(menu);
                    }}
                />
            </>

        );
    }
    return null;
};
