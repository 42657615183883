type ModalParameters = Record<string, any>;

export type ModalData = {
    modalName?: string | null;
    entityName?: string;
    modalParameters?: ModalParameters;
    parentEntityName?: string;
}

export type ModalsSliceState = {
    modalData: ModalData;
}

export type ModalComponentInterface = {
    parameters?: ModalParameters;
    onClose: () => void;
}

export enum ModalType {
    documentsFileUpload = 'documentsFileUpload',
    documentsUploadStage = 'documentsUploadStage',
    documentsSignatureUpload = 'documentsSignatureUpload',
    editableTableModal = 'editableTableModal',
    formCreateItem = 'formCreateItem',
    formModal = 'formModal',
    formDrawer = 'formDrawer',
    filterDrawer = 'filterDrawer',
    formEditEntityModal = 'formEditEntityModal',
    filterModal = 'filterModal',
    requestFormModal = 'requestFormModal',
    listModal = 'listModal',
    listGridModal = 'listGridModal',
    documentComparisonModal = 'documentComparisonModal',
    downloadReportDocumentsModal = 'downloadReportDocumentsModal',
    documentCodesFormModal = 'documentCodesFormModal',
    settingProgramMappingModal = 'settingProgramMappingModal',
    reportColumnsSetting = 'reportColumnsSetting',
    historyModal = 'historyModal',
}
