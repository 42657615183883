import {
    CheckCircleOutlined, CloseCircleOutlined, CloseOutlined, LoadingOutlined,
} from '@ant-design/icons';
import Button from 'antd/es/button';
import cn from 'classnames';
import {sum} from 'lodash';
import React, {useMemo, useState} from 'react';

import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {DraggableFrameEntity, DraggableFrameType} from 'store/slices/draggable-frames-slice';
import {
    draggableFramesActions,
} from 'store/slices/draggable-frames-slice/draggable-frames-slice';
import {ATTACHMENT_IS_LOADED_PERCENT, tableReportRowAttachmentsSelectors} from 'store/slices/table-report-slice/table-report-row-attachments';
import {tableReportSliceActions} from 'store/slices/table-report-slice/table-report-slice';

import {DraggableFrame} from '../../draggable-frame';
import {TableReportAttachmentUploadProgressItem} from './table-report-attachment-upload-progress-item';

import './table-report-attachment-upload-frame.less';

type TableReportAttachmentUploadFrameProps = {
    frameEntity?: Partial<DraggableFrameEntity>;
}

export const TableReportAttachmentUploadFrame: React.FC<TableReportAttachmentUploadFrameProps> = (
    {frameEntity}: TableReportAttachmentUploadFrameProps,
) => {
    const dispatch = useAppDispatch();
    const [isBodyHidden, setIsBodyHidden] = useState(false);

    const {closeDraggableFrame} = draggableFramesActions;
    const {resetUploadProgress} = tableReportSliceActions;
    const {selectTableReportUploadAttachmentsFilesProgress} = tableReportRowAttachmentsSelectors;

    const uploadProgressList = useAppSelector(selectTableReportUploadAttachmentsFilesProgress);

    const frameEntityToPassToFrame: DraggableFrameEntity = {
        type: DraggableFrameType.TableReportAttachmentUploadFrame,
        isOpen: !!uploadProgressList.length && (frameEntity?.isOpen ?? true),
        data: frameEntity?.data,
        id: frameEntity?.id,
    };

    const isEveryFileNotPending = useMemo(() => !uploadProgressList
        .some(progress => progress.uploadData.isRequestPending === true), [uploadProgressList]);

    const isEveryFileUploaded = useMemo(() => uploadProgressList
        .every(progress => progress.uploadData.loadProgressPercent
                === ATTACHMENT_IS_LOADED_PERCENT), [uploadProgressList]);

    const globalProgressPercent = (() => {
        const progresses = uploadProgressList
            .map(progress => progress.uploadData.loadProgressPercent);
        if (!progresses.length) return 100;
        const avg = sum(progresses) / progresses.length;
        return Math.trunc(avg);
    })();

    return (
        <div className={cn('table-report-attachment-upload-frame')}>
            <DraggableFrame
                frameEntity={frameEntityToPassToFrame}
                classNames={{
                    wrapClassNames: ['table-report-attachment-upload-frame__wrap',
                        {'table-report-attachment-upload-frame__wrap_open': !isBodyHidden},
                    ],
                }}
                modalProps={{
                    className: 'table-report-attachment-upload-frame__modal',
                    transitionName: '',
                    title: (
                        <div className={cn('table-report-attachment-upload-frame__title')}>
                            {
                                (() => {
                                    if (uploadProgressList.length && isEveryFileNotPending) {
                                        return <CheckCircleOutlined style={{color: '#43A047'}} />;
                                    }
                                    if (!uploadProgressList.length) {
                                        return <CloseCircleOutlined style={{color: '#ED1C24'}} />;
                                    }
                                    return <LoadingOutlined style={{color: '#3083FF'}} />;
                                })()
                            }
                            <span>{
                                (() => {
                                    if (uploadProgressList.length && isEveryFileNotPending) {
                                        return 'Файлы успешно загружены';
                                    }
                                    if (!uploadProgressList.length) return 'Нет файлов для загрузки';

                                    if (isEveryFileUploaded) return 'Завершение загрузки...';
                                    return `Загрузка файлов вложений... (${globalProgressPercent}%)`;
                                })()
                            }
                            </span>
                        </div>
                    ),
                    bodyStyle: {display: isBodyHidden ? 'none' : 'block'},
                    closeIcon: (
                        <>
                            <div className={cn('table-report-attachment-upload-frame__controls', {
                                'table-report-attachment-upload-frame__controls_loaded': isEveryFileNotPending,
                            })}
                            >
                                <Button
                                    style={{marginLeft: -60, marginTop: 9, minWidth: 103}}
                                    type="primary"
                                    onClick={() => setIsBodyHidden(!isBodyHidden)}
                                >
                                    {isBodyHidden ? 'Подробнее' : 'Скрыть'}
                                </Button>

                                <CloseOutlined
                                    onClick={() => {
                                        if (isEveryFileNotPending) {
                                            dispatch(resetUploadProgress());
                                            dispatch(closeDraggableFrame({
                                                type: DraggableFrameType.TableReportAttachmentUploadFrame,
                                                id: frameEntity?.id,
                                            }));
                                        }
                                    }}
                                    className={cn('table-report-attachment-upload-frame__controls_close', {
                                        'table-report-attachment-upload-frame__controls_close_not-loaded':
                                                !isEveryFileNotPending,
                                    })}
                                />
                            </div>
                        </>
                    ),
                }}
            >
                <div>
                    {!uploadProgressList.length && <div>Список состояний активных загрузок пуст</div>}
                    {uploadProgressList.map(progressDataItem => (
                        <TableReportAttachmentUploadProgressItem
                            key={progressDataItem.reportRowId}
                            progressItem={progressDataItem}
                            frameEntity={frameEntityToPassToFrame}
                        />
                    ))}
                </div>
            </DraggableFrame>
        </div>
    );
};
