import get from 'lodash/get';

import {combineString} from 'shared/utils/combine-string';

import {FormMode} from '../../components/form';
import {Entity} from '../data';
import {ActionVisibility} from './metadata-constants';
import {
    FieldType,
    MetaActionInfo,
    MetaActionType,
    ReferenceResolver,
    TVisibleMetaActionProp,
} from './metadata-types';

export const getFormInitValue = (type: FieldType) => {
    switch (type) {
    case FieldType.DATE:
        return null;
    case FieldType.TEXTAREA:
    case FieldType.STRING:
        return '';
    case FieldType.BOOLEAN:
        return 'N';
    case FieldType.BOOLEAN_CLASSIC:
        return false;
    case FieldType.SIGNATURE:
    case FieldType.FILE:
    case FieldType.ICON:
    case FieldType.NUMBER:
    case FieldType.COUNTER:
    case FieldType.REFERENCE:
    case FieldType.LIST:
    case FieldType.FIELDS_TREE:
    default:
        return undefined;
    }
};

export const getActionByType = (type: MetaActionType) => (
    actions?: MetaActionInfo[],
): MetaActionInfo | undefined => actions
    ?.find(action => action.actionType === type);

export const getReferenceResolverFunction = (
    labelPath: string,
    valuePath: string,
    reserveLabelPath?: string,
    lookupCode?: string,
    stringStructure?: string,
): ReferenceResolver => (record: Entity) => {
    const onNotCreateKey = record?.onNotCreateKey;
    const isSetFilter = record?.isSetFilter;
    const displayFieldKey = record?.displayFieldKey;
    const dependentInputKey = record?.dependentInputKey;
    const additionalInputOptions = record?.additionalInputOptions;
    let label = stringStructure
        ? combineString(stringStructure, {lookupValue: {...record}}) || ''
        : get(record, labelPath) as string;
    let value = get(record, valuePath) as string | number;

    if (displayFieldKey) {
        value = record?.[displayFieldKey as string] as string | number;
    }

    if (!value) {
        value = record?.value as string | number;
    }

    if (!label && reserveLabelPath) {
        label = get(record, reserveLabelPath) as string;
    }
    return {
        ...record,
        label,
        value,
        onNotCreateKey,
        isSetFilter,
        displayFieldKey,
        dependentInputKey,
        additionalInputOptions,
    };
};

export const createVisibleEqualityPredicate = (currentState: TVisibleMetaActionProp) => (
    visibleState: TVisibleMetaActionProp = 'default',
) => (
    visibleState === currentState
);
/**
 * Возвращает видимость кнопок в зависимости от текущего состояния формы по следующим правилам:
 * default - все кнопки видны
 * edit - видны только кнопки редактирования (c visible = edit)
 * viewing - видны только кнопки просмотра (c visible = viewing)
 * @param currentVisible
 * @param visible
 * @param formMode в теории может быть использован для показа кнопок в зависимости от режима формы
 */
export const isVisibleAction = (
    currentVisible: TVisibleMetaActionProp = ActionVisibility.DEFAULT,
    visible: TVisibleMetaActionProp = ActionVisibility.DEFAULT,
    formMode?: FormMode,
) => (visible === currentVisible
    || (currentVisible === ActionVisibility.DEFAULT || visible === ActionVisibility.DEFAULT) // default = always show
    || (currentVisible === ActionVisibility.EDITING && visible === ActionVisibility.EDITING) // edit = show edit
    || (currentVisible === ActionVisibility.VIEWING && visible === ActionVisibility.VIEWING) // view = show view
    || (visible === ActionVisibility.EDITING && formMode === 'edit' && currentVisible === ActionVisibility.DEFAULT)
    || (visible === ActionVisibility.CREATION && formMode === 'create' && currentVisible === ActionVisibility.DEFAULT));
