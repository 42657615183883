import {createSelector} from '@reduxjs/toolkit';
import moment from 'moment';

import {DATE_WITH_TIME_DOTS_SECONDS_24, MONTHS} from 'shared/constants/date-format';
import {AppState} from 'store/config/types';

import {ais3SliceName} from '../ais-slice-constants';
import {FetchAisDashboardDataResponseDto} from './ais-dashboards-api';
import {
    AisSystemAvailabilityStatusData,
    AisDashboardChildDto,
    AisS4S5StatisticsDashboardDataForChart, UnavailabilityDashboardData,
    AisTokenStatusData,
} from './ais-dashboards-types';

const selectAisSliceState = (state: AppState) => state[ais3SliceName];

export const selectAisDashboardsData = createSelector(
    selectAisSliceState,
    ({aisDashboards}) => aisDashboards,
);

export const selectS4S5StatisticsDashboardData = createSelector(
    selectAisSliceState,
    ({aisDashboards}) => aisDashboards.s4s5RequestsStatistics,
);

export const selectSummaryTransactionsProtocolDashboardData = createSelector(
    selectAisSliceState,
    ({aisDashboards}) => aisDashboards.summaryTransactionsProtocol,
);

export const selectSummaryTransactionsProtocolDashboardDataTransformed = createSelector(
    selectSummaryTransactionsProtocolDashboardData,
    dashboardData => {
        if (!dashboardData) return undefined;
        const {elements} = dashboardData;

        const allCountOperation = elements?.find(el => el.code === 'ALL_COUNT_OPERATION')?.countLong;
        const percentagePUDOperation = elements?.find(el => el.code === 'PERCENTAGE_PUD_OPERATION')?.percentage;
        const differenceCountOperation = elements?.find(el => el.code === 'DIFFERENCE_COUNT_OPERATION')?.countLong;
        const differencePUDOperation = elements?.find(el => el.code === 'DIFFERENCE_PUD_OPERATION')?.percentage;

        return {
            allCountOperation,
            percentagePUDOperation,
            differenceCountOperation,
            differencePUDOperation,
        };
    },
);

export const selectS4S5StatisticsDashboardDataForChart = createSelector(
    selectS4S5StatisticsDashboardData,
    dashboardData => {
        if (!dashboardData) return undefined;
        const {elements} = dashboardData;

        let scenario4Children: AisDashboardChildDto[];
        let scenario5Children: AisDashboardChildDto[];

        const [firstElement, secondElement] = elements;
        if (firstElement.code === 'scenario4') {
            [scenario4Children, scenario5Children] = [firstElement.child, secondElement.child];
        } else {
            [scenario4Children, scenario5Children] = [secondElement.child, firstElement.child];
        }

        const scenario4RequestsCount = scenario4Children.reduce((acc, v) => acc + v.count ?? 0, 0);
        const scenario5RequestsCount = scenario5Children.reduce((acc, v) => acc + v.count ?? 0, 0);

        const allScenariosCount = scenario4RequestsCount + scenario5RequestsCount;

        let maxPercent = 0;

        const data: AisS4S5StatisticsDashboardDataForChart[] = Array(12).fill(0).map((_, i) => {
            const scenario4Child = scenario4Children[i];
            const scenario5Child = scenario5Children[i];

            // const countForAllScenarios = (scenario4Child?.count ?? 0) + (scenario5Child?.count ?? 0);

            const scenario4Percent = ((scenario4Child?.count ?? 0) / (allScenariosCount || 1)) * 100;
            const scenario5Percent = ((scenario5Child?.count ?? 0) / (allScenariosCount || 1)) * 100;

            if (scenario4Percent > maxPercent) maxPercent = scenario4Percent;
            if (scenario5Percent > maxPercent) maxPercent = scenario5Percent;

            return {
                scenario4: {
                    count: scenario4Child?.count ?? 0,
                    percent: scenario4Percent,
                },
                scenario5: {
                    count: scenario5Child?.count ?? 0,
                    percent: scenario5Percent,
                },
                month: scenario4Child?.meaning ?? MONTHS[i]?.label ?? 'Н/Д',

            };
        });

        const scale = 100 / (maxPercent || 100);

        const dataScaled: AisS4S5StatisticsDashboardDataForChart[] = data.map(v => ({
            ...v,
            scenario4: {
                ...v.scenario4,
                percent: v.scenario4.percent * scale,
            },
            scenario5: {
                ...v.scenario5,
                percent: v.scenario5.percent * scale,
            },
        }));

        return dataScaled;
    },
);

export const selectUnavailabilityChartDashboardData = createSelector(
    selectAisSliceState,
    ({aisDashboards}) => aisDashboards.unavailabilityChart,
);

export const selectUnavailabilityDashboardDataForChart = createSelector(
    selectUnavailabilityChartDashboardData,
    (dashboardData: FetchAisDashboardDataResponseDto | undefined): UnavailabilityDashboardData | undefined => {
        if (!dashboardData) return undefined;
        const {total, elements} = dashboardData;
        const [firstElement] = elements;

        return {
            totalWorkingHours: total,
            unavailableHours: firstElement.count,
            availableHours: total - firstElement.count,
        };
    },
);

export const selectTokenStatusDashboardRawData = createSelector(
    selectAisSliceState,
    ({aisDashboards}) => aisDashboards.tokenStatus,
);

export const selectTokenStatusDashboardData = createSelector(
    selectTokenStatusDashboardRawData,
    dashboardData => {
        if (!dashboardData) return undefined;
        const {statusFlag: isActive, eventDate: eventDateRaw} = dashboardData?.elements?.[0] ?? {};

        const data: AisTokenStatusData = {
            isActive,
            eventDate: moment(eventDateRaw).format(DATE_WITH_TIME_DOTS_SECONDS_24),
        };

        return data;
    },
);

export const selectRegisterDocumentsDashboardData = createSelector(
    selectAisSliceState,
    ({aisDashboards}) => aisDashboards.register,
);
export const selectRequestStatisticS3S6S12 = createSelector(
    selectAisSliceState,
    ({aisDashboards}) => aisDashboards.s3s6s12requestStatistic,
);

export const selectSystemAvailabilityStatusRawData = createSelector(
    selectAisSliceState,
    ({aisDashboards}) => aisDashboards.availabilityStatus,
);

export const selectSystemAvailabilityStatusDashboardData = createSelector(
    selectSystemAvailabilityStatusRawData,
    dashboardData => {
        if (!dashboardData) return undefined;
        const {meaning, date} = dashboardData ?? {};

        const data: AisSystemAvailabilityStatusData = {
            meaning,
            isAvailable: !meaning?.includes('не'), // todo - заменить проверку на что-то более надежное
            statusDate: moment(date).format(DATE_WITH_TIME_DOTS_SECONDS_24),
        };

        return data;
    },
);
