import {
    Button, Card, Form, Form as AntForm, Space,
} from 'antd';
import useForm from 'antd/lib/form/hooks/useForm';
import debounce from 'lodash/debounce';
import React, {FunctionComponent, useEffect, useMemo} from 'react';
import {useDispatch} from 'react-redux';

import {Fields} from 'components/form/fields/fields';
import {selectMetadata} from 'modules/metadata';
import {metadataConstructorForm} from 'modules/metadata-constructor/metadata-constructor.actions';
import {metadataConstructorApi} from 'modules/metadata-constructor/metadata-constructor.api';
import {selectConstructorAllEntityName} from 'modules/metadata-constructor/metadata-constructor.selectors';
import {convertActionFieldsForMeta} from 'modules/metadata-constructor/metadata/types/table/table.utils';
import {FieldType, FormEntityMeta} from 'modules/metadata/metadata-types';
import {EntityType} from 'shared/constants/entities';
import {showMessageFromResponse} from 'shared/utils';
import {useAppSelector} from 'store/config/hooks';

import {Filter} from './filter/filter';
import {getDataForFilter} from './metadata-constructor.utils';

import './metadata-constructor.less';

interface MetadataConstructorProps {
    entityName: string;
}

export const MetadataConstructor: FunctionComponent<MetadataConstructorProps> = ({
    entityName,
}: MetadataConstructorProps) => {
    const dispatch = useDispatch();
    const [form] = useForm();
    const [formUpload] = useForm();
    const meta = useAppSelector(selectMetadata(entityName, EntityType.FORM)) as FormEntityMeta;
    const allEntityNames = useAppSelector(selectConstructorAllEntityName());

    useEffect(() => {
        dispatch(metadataConstructorForm.initPage(form));
    }, []);

    const dataForFilter = useMemo(() => getDataForFilter(allEntityNames), [allEntityNames]);

    const handleValuesChange = (changedFields: any, allFields: any) => {
        dispatch(metadataConstructorForm.initEditForm(allFields, form));
    };

    const handleFieldsChange = debounce(async (changedFields: any, allFields: any[]) => {
        const field = changedFields[0];
        if (!field?.name) return;

        const fieldName = field.name.length === 1 ? field.name[0] : field.name;
        const fieldValue = field.value;
        const shouldChangeMeta = changedFields.length === 1 && typeof fieldName === 'string';
        if (shouldChangeMeta) {
            await dispatch(metadataConstructorForm.changeFields(entityName, form, fieldName, fieldValue));
        }

        const shouldUpdateListMeta = Array.isArray(fieldName)
            && fieldName.length > 2
            // TODO генерировать массив на основе значения dataKeyFilter в метаданных.
            //  Сейчас обновлять его в ручную при появлении новых полей конструктора.
            //  Нужно для того что бы работал динамический список.
            && ['viewType', 'type', 'actionType'].includes(fieldName[fieldName.length - 1]);
        if (shouldUpdateListMeta) {
            form.resetFields();
            form.setFields(allFields);
            form.scrollToField(field.name);
        }
    }, 500);

    const handleFinish = async () => {
        const allFormData = form.getFieldsValue(true);
        await dispatch(metadataConstructorForm.saveFormData(entityName, allFormData));
    };

    const handleFinishUploadFile = async () => {
        try {
            const allFormData = formUpload.getFieldsValue(true);
            const response = await metadataConstructorApi.saveMetadataFile(allFormData.file);
            showMessageFromResponse({response});
        } catch (e) {
            showMessageFromResponse({response: e.response, isError: true});
        }
    };

    const handleFinishFailed = () => {
        console.error('Форма не прошла проверку', convertActionFieldsForMeta(form.getFieldsValue()));
    };

    const handleFileDownload = () => dispatch(metadataConstructorForm.downloadAllMeta());

    const handleResetFilter = async () => {
        form.resetFields();
        await dispatch(metadataConstructorForm.initBlankForm(form));
    };

    const uploadMetadataFile = (
        <AntForm
            component={false}
            colon={false}
            layout="vertical"
            form={formUpload}
            onValuesChange={handleFinishUploadFile}
        >
            <Fields
                list={[
                    {
                        key: 'file',
                        label: '',
                        type: FieldType.FILE,
                        buttonText: 'Загрузить все метаданные',
                        shouldFastUpdate: true,
                        accept: '.json',
                    },
                ]}
                form={formUpload}
            />
        </AntForm>
    );

    const formButtons = (
        <Form.Item>
            <Space>
                <Button
                    type="primary"
                    htmlType="submit"
                >
                    Сохранить
                </Button>
                <Button
                    type="primary"
                    onClick={handleFileDownload}
                >
                    Скачать все метаданные
                </Button>
            </Space>
        </Form.Item>
    );

    return meta ? (
        <Card title={meta?.title}>
            {uploadMetadataFile}
            <Filter
                data={dataForFilter}
                onValuesChange={handleValuesChange}
                showReset
                onReset={handleResetFilter}
            />
            <AntForm
                layout="vertical"
                form={form}
                onFinish={handleFinish}
                onFinishFailed={handleFinishFailed}
                onFieldsChange={handleFieldsChange}
            >
                {formButtons}
                <Fields
                    list={meta.fields}
                    form={form}
                />
                {formButtons}
            </AntForm>
        </Card>
    ) : null;
};
