import {useEffect, useMemo, useRef} from 'react';

import {Interval} from 'shared/types';
import {useWebsocketInstance} from 'websockets/core/use-websocket-instance';
import {WebsocketMessageHandlers, WebsocketResponseMessage} from 'websockets/core/websockets-types';

interface CreateWebsocketSubscriberInstanceArgs {
    handlers: WebsocketMessageHandlers;
}

export const createWebsocketSubscriberInstance = ({
    handlers,
}: CreateWebsocketSubscriberInstanceArgs) => {
    const send = (
        messageType: WebsocketResponseMessage<any>['type'],
        messageParsed: WebsocketResponseMessage<any>,
    ) => {
        if (handlers?.[messageType]) handlers[messageType]?.(messageParsed);
    };
    return {
        send,
    };
};

export type WebsocketSubscriberInstance = ReturnType<typeof createWebsocketSubscriberInstance>;

type WebsocketSubscriberFallback = {
    execute: () => void;
    interval: number;
}

export const useWebsocketSubscriber = (
    handlers: WebsocketMessageHandlers,
    fallback?: {
        fallbacks?: WebsocketSubscriberFallback[];
        deps?: any[];
        onError?: () => void;
    },
) => {
    // Subscription ↴
    const {wsInstance} = useWebsocketInstance();

    const wsSubscriber = useMemo(() => createWebsocketSubscriberInstance({
        handlers,
    }), [handlers]);

    useEffect(() => {
        wsInstance?.subscribe(wsSubscriber);
        return () => {
            wsInstance?.unsubscribe(wsSubscriber);
        };
    }, [handlers]);

    // Fallback ↴
    const {deps, fallbacks, onError} = fallback ?? {};
    const fallbackIntervalsRef = useRef<Interval[]>([]);

    useEffect(() => {
        if (!wsInstance?.isWithError) return () => {};
        onError?.();

        fallbacks?.forEach(fb => {
            fallbackIntervalsRef.current.push(setInterval(() => {
                fb.execute();
            }, fb.interval));
        });

        return () => {
            fallbackIntervalsRef.current.forEach(fb => {
                clearInterval(fb);
            });
            fallbackIntervalsRef.current = [];
        };
    }, [wsInstance?.isWithError, ...(deps ?? [])]);
};
