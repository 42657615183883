import {Checkbox, Form} from 'antd';
import React from 'react';

export interface CheckboxSimpleProps {
    name: string;
    label?: string;
}
export const CheckboxSimple: React.FC<CheckboxSimpleProps> = ({name, label}: CheckboxSimpleProps) => (
    <Form.Item
        name={name}
        style={{marginBottom: 0}}
    >
        <Checkbox>{label}</Checkbox>
    </Form.Item>
);
