import {Form} from 'antd';
import {useForm} from 'antd/es/form/Form';
import cn from 'classnames';
import React, {useEffect} from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {SegmentResolverComponent} from 'components/report-configuration/components/segment-resolver-component';
import {transformSegmentToInitialValues} from 'pages/ais/documents-register-page/modals/document-card-modal/document-card-modal-utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {selectDDContextSegments} from 'store/slices/descriptive-flexfields-slice/descriptive-flexfields-slice';
import {loadDDContextSegments} from 'store/slices/descriptive-flexfields-slice/descriptive-flexfields-thunks';

import './extra-attributes-modal-opener-content.less';

interface ExtraAttributesModalOpenerContentProps {
    referenceRuleRecord?: any;
}

export const ExtraAttributesModalOpenerContent: React.FC<ExtraAttributesModalOpenerContentProps> = (
    {referenceRuleRecord}: ExtraAttributesModalOpenerContentProps,
) => {
    const [form] = useForm();

    const dispatch = useAppDispatch();
    const {context} = referenceRuleRecord;

    const segments = useAppSelector(!context
        ? () => undefined
        : s => selectDDContextSegments(s, {contextCode: context}));

    useEffect(() => {
        if (context) {
            dispatch(loadDDContextSegments({contextCode: context}));
        }
    }, []);

    return (
        <div>
            <Form
                form={form}
                className={cn('extra-attributes-modal-opener-content__form')}
                layout="vertical"
                initialValues={{
                    ...referenceRuleRecord,
                    ...transformSegmentToInitialValues(referenceRuleRecord.dffSegmentValues),
                }}
            >
                <Form.Item
                    name="context"
                    label="Контекст атрибута"
                >
                    <CustomSelect settings={{
                        url: 'valueLists/TABLE_REPORT_DRILL_DOWN_CONTEXT',
                        labelPath: 'meaning',
                        valuePath: 'lookupCode',
                        placeholder: 'Контекст не выбран',
                        isClearable: true,
                        showSearch: true,
                        isDisabled: true,
                    }}
                    />
                </Form.Item>

                <div className="extra-attributes-modal-opener-content__form__attributes">
                    {segments?.map(segment => (
                        <Form.Item key={segment.id}>
                            <SegmentResolverComponent
                                segment={segment}
                                options={{
                                    isDisabled: true,
                                }}
                            />
                        </Form.Item>
                    ))}
                </div>
            </Form>

        </div>
    );
};
