export enum InitialValuesActionType {
    ADD = 'ADD',
    CHANGE = 'CHANGE',
    INIT = 'INIT',
    CLEAR = 'CLEAR',
}

export enum KSSettingsFields {
    CrColumns = 'crColumns',
    SheetCode = 'sheetCode',
    ColumnName = 'columnName',
    SQL = 'sql'
}
