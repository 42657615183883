import {Card, Input, Row} from 'antd';
import FormItem from 'antd/es/form/FormItem';
import React from 'react';

import {DateInput} from 'components/@common/inputs/date-input';

import './common-info.less';

export const CommonInfo = () => (
    <div className="common-info">
        <Card
            className="common-info-col"
            title="Основная информация"
        >
            <FormItem
                name="name"
                label="Наименование"
                rules={[
                    {required: true},
                    {
                        pattern: /^[а-яА-ЯёЁ0-9 !@#$'\\"%^&*?:;№/.,<>|«»\\-_—]+$/,
                        message: 'Данное поле может содержать только кириллицу и символы',
                    },
                    {
                        pattern: /^.{1,300}$/,
                        message: 'Количество знаков в данном поле должно быть не более 300',
                    },
                ]}
            >
                <Input placeholder="Введите наименование" />
            </FormItem>
            <Row>
                <FormItem
                    name="organizationCode"
                    label="Код"
                    rules={[
                        {required: true},
                        {
                            pattern: /^.{1,20}$/,
                            message: 'Количество знаков в данном поле должно быть не более 20',
                        },
                    ]}
                >
                    <Input placeholder="Введите код" />
                </FormItem>
                <FormItem
                    name="externalCode"
                    label="Внешний код"
                    rules={[
                        {
                            pattern: /^.{1,70}$/,
                            message: 'Количество знаков в данном поле должно быть не более 70',
                        },
                    ]}
                >
                    <Input placeholder="Введите внешний код" />
                </FormItem>
            </Row>
            <Row>
                <FormItem
                    name="inn"
                    label="ИНН"
                    rules={[
                        {required: true},
                        {
                            pattern: /^.{1,10}$/,
                            message: (
                                'Количество знаков в данном поле должно быть не более 10. Формат ввода: 4581562555'
                            ),
                        },
                        {
                            pattern: /^[0-9]+$/,
                            message: 'Данное поле может содержать только цифры',
                        },
                    ]}
                >
                    <Input placeholder="Введите ИНН" />
                </FormItem>
                <FormItem
                    name="kpp"
                    label="КПП"
                    rules={[
                        {
                            pattern: /^.{1,9}$/,
                            message: 'Количество знаков в данном поле должно быть не более 9. Формат ввода: 772101001',
                        },
                        {
                            pattern: /^[0-9]+$/,
                            message: 'Данное поле может содержать только цифры. Формат ввода: 455756255',
                        },
                    ]}
                >
                    <Input placeholder="Введите КПП" />
                </FormItem>
            </Row>
            <Row
                justify="space-between"
            >
                <FormItem
                    name="kpp2"
                    label="КПП2"
                    rules={[
                        {
                            pattern: /^.{1,30}$/,
                            message: 'Количество знаков в данном поле должно быть не более 30',
                        },
                        {
                            pattern: /^[0-9]+$/,
                            message: 'Данное поле должно содержать только цифры',
                        },
                    ]}
                >
                    <Input placeholder="Введите код" />
                </FormItem>
                <FormItem
                    name="okved"
                    label="ОКВЭД"
                    rules={[
                        {
                            pattern: /^.{1,30}$/,
                            message: 'Количество знаков в данном поле должно быть не более 30',
                        },
                    ]}
                >
                    <Input placeholder="Введите ОКВЭД" />
                </FormItem>
            </Row>
            <Row
                justify="space-between"
            >
                <FormItem
                    name="okpo"
                    label="ОКПО"
                    rules={[
                        {
                            pattern: /^.{1,10}$/,
                            message: 'Количество знаков в данном поле должно быть не более 10',
                        },
                    ]}
                >
                    <Input placeholder="Введите ОКПО" />
                </FormItem>
                <FormItem
                    name="ogrn"
                    label="ОГРН"
                    rules={[
                        {
                            pattern: /^.{1,18}$/,
                            message: 'Количество знаков в данном поле должно быть не более 18',
                        },
                        {
                            pattern: /^[0-9][-][0-9]{2}[-][0-9]{2}[-][0-9]{2}[-][0-9]{5}[-][0-9]$/,
                            message: 'Данное поле должно содержать 13 цифр и 5 "-". Формат ввода: 1-02-66-05-60662-05',
                        },
                    ]}
                >
                    <Input placeholder="Введите ОГРН" />
                </FormItem>
            </Row>
            <FormItem
                name="organizationFnsName"
                label="Наименование НО"
                rules={[
                    {
                        pattern: /^.{1,2000}$/,
                        message: 'Количество знаков в данном поле должно быть не более 2000',
                    },
                ]}
            >
                <Input placeholder="Введите наименование НО" />
            </FormItem>
            <FormItem
                name="endDate"
                label="Закрыто с"
                style={{width: '50%'}}
            >
                <DateInput format="DD.MM.YYYY" />
            </FormItem>
        </Card>
        <Card
            className="common-info-col"
            title="Контактные данные"
        >
            <FormItem
                name="contactPerson"
                label="Представитель"
                rules={[
                    {
                        pattern: /^.{1,300}$/,
                        message: 'Количество знаков в данном поле должно быть не более 300',
                    },
                ]}
            >
                <Input placeholder="Введите значение" />
            </FormItem>
            <FormItem
                name="contactPhone"
                label="Телефон"
                rules={[
                    {
                        pattern: /^.{1,10}$/,
                        message: 'Количество знаков в данном поле должно быть не более 10',
                    },
                    {
                        pattern: /^[0-9]+$/,
                        message: (
                            'Данное поле должно содержать только цифры и символы. Формат ввода: +7 (999) 999 99 99'
                        ),
                    },
                ]}
            >
                <Input placeholder="Введите телефон" />
            </FormItem>
            <FormItem
                name="postAddress"
                label="Почтовый адрес"
                rules={[
                    {
                        pattern: /^.{1,1000}$/,
                        message: 'Количество знаков в данном поле должно быть не более 1000',
                    },
                ]}
            >
                <Input placeholder="Введите почтовый адрес" />
            </FormItem>
            <FormItem
                name="address"
                label="Адрес"
                rules={[
                    {
                        pattern: /^.{1,1000}$/,
                        message: 'Количество знаков в данном поле должно быть не более 1000',
                    },
                ]}
            >
                <Input placeholder="Введите адрес" />
            </FormItem>
        </Card>
    </div>
);
