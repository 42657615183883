import {performRequest} from 'shared/utils';

import {TableReportDataRequestParams} from '../table-report-slice-types';

export const searchTableReportData = <T=any>({
    templateCode,
    docId,
    page,
    size,
    filters,
    sort,
    includeCR,
    parameters,
    searchValue,
    lookupType,
}: TableReportDataRequestParams) => performRequest<T>({
    url: `table-reports/${templateCode}/data/search`,
    data: {
        DOC_ID: docId,
        page,
        size,
        filters,
        sort,
        includeCR,
        parameters,
        searchValue,
        lookupType,
    },
    method: 'POST',
});
