import React from 'react';

import {ReactComponent as MainLogoIcon} from 'shared/assets/main-logo.svg';

import './empty-widgets-greetings.less';

export const EmptyWidgetsGreetings = () => (
    <div className="empty-widget-greeting">
        <div className="empty-widget-greeting-logo d-flex align-items-center">
            <MainLogoIcon className="empty-widget-greeting-logo__icon" />
            <div className="empty-widget-greeting-logo__name">
                Витрина<br />
                налогового<br />
                мониторинга
            </div>
        </div>
    </div>
);
