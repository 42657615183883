import {Checkbox} from 'antd';
import {CheckboxValueType} from 'antd/lib/checkbox/Group';
import React from 'react';
import {useDispatch} from 'react-redux';

import {setData} from 'modules/data/data-actions';
import {selectEntityData, selectFormEntityData, selectUsedAdditionalOptions} from 'modules/data/data-selectors';
import {FormEntityData} from 'modules/data/data-types';
import {FieldMeta} from 'modules/metadata';
import {EntityType} from 'shared/constants/entities';
import {useAppSelector} from 'store/config/hooks';

import './checkbox-group.less';

export interface ICheckboxOption {
    active: string;
    docTemplateId: number;
    metaTableName: number;
    metaFieldKey: string;
    fieldLabel: string;
    isRequired: string;
}

interface CheckboxGroupProps {
    referenceUrl: string;
    onChange: (value: any) => void;
    entityName: string;
    fieldMeta: FieldMeta;
}

export const CheckboxGroup: React.FunctionComponent<CheckboxGroupProps> = ({
    referenceUrl,
    entityName,
    onChange,
    fieldMeta,
}: CheckboxGroupProps) => {
    const dispatch = useDispatch();
    const data: any = useAppSelector(selectUsedAdditionalOptions(entityName, referenceUrl));
    const formData = useAppSelector(selectEntityData(entityName, EntityType.FORM)) as FormEntityData;
    const mappingDtoList: ICheckboxOption[] | undefined = useAppSelector(
        selectFormEntityData(entityName),
    )?.data?.settingDocumentTemplateMappingDtoList;
    const [options, setOptions] = React.useState<ICheckboxOption[]>([]);
    const [checkedList, setCheckedList] = React.useState<CheckboxValueType[]>([]);
    const [indeterminate, setIndeterminate] = React.useState(false);
    const [checkAll, setCheckAll] = React.useState(true);

    const renderOptions = () => options.map(option => (
        <Checkbox
            key={option.metaFieldKey}
            value={option.metaFieldKey}
            disabled={option.isRequired === 'Y'}
        >
            {option.fieldLabel}
        </Checkbox>
    ));

    const normalizeValue = (currentValue: ICheckboxOption[]) => {
        let resultValue: string[] = [];
        if (Array.isArray(currentValue)) {
            resultValue = currentValue?.map(record => (record.metaFieldKey));
        }
        return resultValue;
    };

    const initializeDefaultValue = (
        currentValue: ICheckboxOption[],
    ) => {
        const resultValue = currentValue.filter(record => record.active === 'Y')
            .map(record => (
                record.metaFieldKey
            ));
        setCheckedList(resultValue);
        onChange(resultValue);
        if (!formData?.data[fieldMeta.key] && resultValue) {
            formData.data[fieldMeta.key] = resultValue;
            dispatch(setData({
                entityName,
                entityType: EntityType.FORM,
                data: formData.data,
            }));
        }
    };

    const handleChange = (list: CheckboxValueType[]) => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < options.length);
        setCheckAll(list.length === options.length);
        onChange(list);
    };

    const onCheckAllChange = () => {
        setCheckAll(prevCheckAll => !prevCheckAll);
        const boolValue = (checkedList.length !== mappingDtoList?.length) && !checkAll ? 'Y' : 'N';

        const changedOptions = options.map(option => ({
            ...option,
            active: option.isRequired === 'Y' ? option.active : boolValue,
        }));
        const filteredOptionsChecked = changedOptions.filter(option => option.active === 'Y');
        setCheckedList(normalizeValue(filteredOptionsChecked));
        setIndeterminate(filteredOptionsChecked.length < options.length);
        onChange(normalizeValue(filteredOptionsChecked));
    };

    React.useEffect(() => {
        if (mappingDtoList?.length) {
            setOptions(mappingDtoList);
            initializeDefaultValue(mappingDtoList);
        }
    }, [mappingDtoList]);

    React.useEffect(() => {
        if (data?.length) {
            setOptions(data);
        }
    }, [data]);

    if (options.length === 0) return null;

    return (
        <div className="scroll-container">
            <div className="checkbox-group">
                <div className="checked-all-indicator">
                    <Checkbox
                        indeterminate={indeterminate}
                        onChange={onCheckAllChange}
                        checked={checkAll}
                    >
                        Выбрать все
                    </Checkbox>
                </div>
                <Checkbox.Group
                    onChange={handleChange}
                    value={checkedList}
                >
                    {renderOptions()}
                </Checkbox.Group>
            </div>
        </div>
    );
};
