import {Table} from 'antd';
import {TableProps} from 'antd/es/table';
import {Key, TablePaginationConfig, TableRowSelection} from 'antd/lib/table/interface';
import cn from 'classnames';
import React, {useContext} from 'react';

import {Entity} from 'modules/data';
import {DefaultTableStateFlat, TableEntityData} from 'modules/data/data-types';
import {TableEntityMeta} from 'modules/metadata/metadata-types';
import {ReactComponent as ArrowRightIcon} from 'shared/assets/arrow-right.svg';
import {convertWidthData} from 'shared/utils/convert-width-data';

import {EditableCell, EditableCellProps} from '../../editable-table/components/editable-cell';
import {EditableRow} from '../../editable-table/components/editable-row';
import {EditableColumn} from '../../editable-table/editable-table-types';
import {TableModeContext} from '../context';
import './editable-table.less';
import '../table.less';
import {convertFlatObjectToTableState} from '../utils/table.utils';

interface EditableTableProps extends Exclude<TableProps<any>, ['columns']> {
    columns: EditableColumn[];
    handleSave: (row: Entity) => void;
    tableData: TableEntityData;
    entityName?: string;
    params?: DefaultTableStateFlat;
    rowSelection?: TableRowSelection<any>;
    onChangeTable?: (
        pagination: TablePaginationConfig,
        filters: Record<string, Key[] | null>,
        sorter: any,
        url: string,
    ) => void;
    url: string;
    metadata: TableEntityMeta;
    loading?: boolean;
    isSelectable?: boolean;
    linkField?: string;
    dependentLinkField?: string;
}

export const EditableTableComponent: React.FunctionComponent<EditableTableProps> = ({
    columns,
    handleSave,
    entityName,
    tableData,
    metadata,
    onChangeTable,
    loading,
    url,
    isSelectable,
    rowSelection,
    linkField,
    dependentLinkField,
    scroll,
    ...props
}: EditableTableProps) => {
    const {
        rows,
        params,
    } = tableData;
    const {resetTableMode} = useContext(TableModeContext);
    const entityClassName = entityName?.split('.').join('-');
    const tableState = convertFlatObjectToTableState(params as DefaultTableStateFlat);

    const editableClassNames = cn(
        {
            [String(entityClassName)]: entityName,
        },
    );

    const resultColumns = columns?.map(column => ({
        ...column,
        onCell: (record: Entity) => ({
            record,
            editable: column.editable,
            alwaysEditable: column.alwaysEditable,
            required: column.required,
            defaultValue: column.defaultValue,
            dataIndex: column.dataIndex,
            title: column.title,
            inputType: column.inputType,
            inputProps: column.inputProps,
            getFieldState: column.getFieldState,
            disabled: column.isDisabled,
            key: column.key,
            placeholder: column.placeholder,
            validationRules: column?.validationRules,
            field: column,
            handleSave,
            generateInputProps: column.generateInputProps,
            entityName,
            width: convertWidthData(column.width),
        } as EditableCellProps),
    }));

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, Key[] | null>,
        sorter: any,
    ) => {
        if (onChangeTable) {
            onChangeTable(pagination, filters, sorter, url);
        }
    };

    return (
        <Table
            className={cn(editableClassNames, 'editable-table')}
            rowKey={linkField || dependentLinkField || 'id'}
            scroll={scroll ?? {
                x: 'max-content',
            }}
            components={{
                body: {
                    row: EditableRow,
                    cell: EditableCell,
                },
            }}
            rowSelection={isSelectable
                ? {
                    type: 'checkbox',
                    ...rowSelection,
                } : undefined}
            columns={resultColumns as EditableColumn[]}
            dataSource={rows}
            pagination={metadata?.disabledPagination ? false : {
                ...(tableState.pagination),
                position: ['bottomLeft'],
                locale: {items_per_page: 'на странице', jump_to: ''},
                showQuickJumper: metadata?.isPageJumperEnabled
                    ? {goButton: <ArrowRightIcon className="pagination_jumper" />} : false,
                showSizeChanger: metadata?.isPageSizeChangerEnabled,
                onChange: () => resetTableMode(),
            }}
            onChange={handleTableChange}
            loading={loading}
            {...props}
        />
    );
};
