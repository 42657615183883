import Form from 'antd/es/form';
import Input from 'antd/es/input';
import cn from 'classnames';
import React from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {LargeStringInput} from 'components/form/inputs/large-string-input';
import {useAppSelector} from 'store/config/hooks';
import {ReportConfigurationQueryParameterDto} from 'store/slices/report-configuration-slice/report-configuration-dto';
import {selectDDChildReportQueryParameters, selectReportConfigurationTableAttributesAsSelectEntries, selectTableReportConfigurationData} from 'store/slices/report-configuration-slice/report-configuration-slice';

import {DdParameterRuleItemType, DdParameterRuleItemSourceType} from './dd-parameters-rule-item-types';

import './dd-parameters-rule-item.less';

interface DdParametersRuleItemProps {
    layout?: 'vertical' | 'horizontal';
    type?: DdParameterRuleItemType;
    isInEditMode?: boolean;
    sourceType?: DdParameterRuleItemSourceType | undefined;
}

export const DdParametersRuleItem: React.FC<DdParametersRuleItemProps> = (
    {
        isInEditMode,
        layout,
        type = DdParameterRuleItemType.parent,
        sourceType = DdParameterRuleItemSourceType.row,
    }: DdParametersRuleItemProps,
) => {
    const reportConfiguration = useAppSelector(selectTableReportConfigurationData) ?? {};

    const tableAttributesEntries = useAppSelector(
        selectReportConfigurationTableAttributesAsSelectEntries,
    );

    const ddChildReportQueryParameters = useAppSelector(selectDDChildReportQueryParameters);
    const {queryParameters: ddParentReportQueryParameters} = reportConfiguration;

    const [
        sourceTypeFieldName,
        sourceValueFieldName,
    ] = type === DdParameterRuleItemType.child
        ? ['childSourceType', 'childValue']
        : ['parentSourceType', 'parentSourceValue'];

    return (
        <div className={cn('dd-parameters-rule-item')}>
            <div className={cn('dd-parameters-rule-item__title', {
                'dd-parameters-rule-item__title_vertical': layout === 'vertical',
            })}
            >
                <div>{type === DdParameterRuleItemType.parent
                    ? 'Контекст исходного документа' : 'Контекст конечного документа'}
                </div>
            </div>
            <div className={cn('dd-parameters-rule-item__body', {
                'dd-parameters-rule-item__body_vertical': layout === 'vertical',
            })}
            >
                <Form.Item
                    label={
                        type === DdParameterRuleItemType.parent
                            ? 'Тип источника значения' : 'Тип преемника значения'
                    }
                    required
                    rules={[
                        {required: true},
                    ]}
                    name={sourceTypeFieldName}
                >
                    <CustomSelect
                        settings={{
                            url: `/valueLists/TABLE_REPORT_DD_${type}_SOURCE_TYPE`,
                            labelPath: 'meaning',
                            valuePath: 'lookupCode',
                            placeholder: 'Выберите тип источника',
                            isDisabled: !isInEditMode,
                        }}
                    />
                </Form.Item>

                <Form.Item
                    label="Значение"
                    required
                    rules={[
                        {required: true},
                        ...(sourceType === DdParameterRuleItemSourceType.filter) ? [{
                            pattern: /^[\wЁёА-я]*$/,
                            message: 'Допустимы только символы "а-я", "А-Я", "a-z", "A-Z", "0-9" и "_"',
                        }] : [],
                    ]}
                    name={sourceValueFieldName}
                >
                    {(() => {
                        if (sourceType === DdParameterRuleItemSourceType.row) {
                            return (
                                <CustomSelect
                                    settings={{
                                        placeholder: 'Выберите атрибут',
                                        isDisabled: !isInEditMode,
                                    }}
                                    entries={tableAttributesEntries}
                                />
                            );
                        }
                        if (sourceType === DdParameterRuleItemSourceType.SQL) {
                            return (
                                <LargeStringInput
                                    name={sourceValueFieldName}
                                    saveButtonLabel="Подтвердить"
                                    disabled={!isInEditMode}
                                    placeholder="Динамический SQL"
                                    label="Динамический SQL"
                                />
                            );
                        }
                        if (sourceType === DdParameterRuleItemSourceType.parameter) {
                            return (
                                <CustomSelect
                                    settings={{
                                        placeholder: 'Выберите атрибут',
                                        isDisabled: !isInEditMode,
                                    }}
                                    entries={
                                        (((type === DdParameterRuleItemType.child
                                            ? ddChildReportQueryParameters
                                            : ddParentReportQueryParameters
                                        ) ?? []) as ReportConfigurationQueryParameterDto[])
                                            .map(param => {
                                                const {columnName, paramName, reportTitle} = param;
                                                const value = columnName || paramName;
                                                const label = reportTitle;
                                                return {value, label};
                                            })
                                    }
                                />
                            );
                        }
                        return (
                            <Input
                                placeholder="Введите значение"
                                disabled={!isInEditMode}
                            />
                        );
                    })()}
                </Form.Item>
            </div>
        </div>
    );
};
