import {EntityMeta} from '../metadata/metadata-types';
import {
    TMetaOptions,
} from './metadata-constructor.types';

/**
 * Переписать реализацию
 * Теперь вместо resolvedMeta, actionsOptions и fieldsOptions, нужно передавать
 * объект, в котором ключ это значения поля, а значение это нужные метаданные.
 * getMetaByValue - будет возвращать нам нужные метаданные с учетом содержимого defaultMeta.
 */
export class MetadataConstructor {
    /**
     * Общие метаданные
     */
    defaultMeta: EntityMeta;

    /**
     * Содержит информацию по отображению метаданных
     */
    optionsMeta: TMetaOptions;

    emptyMeta: Pick<EntityMeta, 'actions' | 'fields'>;

    constructor(
        defaultMeta: EntityMeta,
        optionsMeta: TMetaOptions,
    ) {
        this.defaultMeta = defaultMeta;
        this.optionsMeta = optionsMeta;
        this.emptyMeta = {
            fields: [],
            actions: [],
        };
    }

    getDefaultMeta = () => this.defaultMeta;

    private getItemFromMetaProp = <T = any>(allItems: Record<string, T>, itemList: string[]) => itemList
        .map(itemKey => allItems[itemKey])
        .filter(item => item);

    private getMetaFromOptions = (value: string) => this.optionsMeta[value] || this.emptyMeta

    /**
     * Получение метаданных в зависимости от значения поля
     */
    getMetaByValue = (value?: string) => {
        if (value) {
            const {fields, actions} = this.getMetaFromOptions(value);

            return ({
                ...this.defaultMeta,
                fields: [
                    ...this.defaultMeta.fields,
                    ...fields,
                ],
                actions: [
                    ...(this.defaultMeta.actions || []),
                    ...(actions || []),
                ],
            });
        }

        return this.defaultMeta;
    }
}
