import {Button} from 'antd';
import React, {useEffect} from 'react';

import {DynamicIcon, TIconType} from 'components/dynamic-icon/dynamic-icon';
import {setDataIsEditable} from 'modules/data/data-actions';
import {selectIsFormEntityDataEditable} from 'modules/data/data-selectors';
import {EntityType} from 'shared/constants/entities';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';

interface IOwnProps {
    entityName: string;
    actionTitle?: string;
    actionIcon?: TIconType;
}

export const ButtonEditForm: React.FunctionComponent<IOwnProps> = ({
    entityName,
    actionTitle,
    actionIcon,
}: IOwnProps) => {
    const dispatch = useAppDispatch();
    const editableSelector = selectIsFormEntityDataEditable(entityName);
    const isEditable = useAppSelector(state => editableSelector(state));

    const setIsEditable = (editable: boolean) => {
        dispatch(setDataIsEditable({entityName, entityType: EntityType.FORM, isEditable: editable}));
    };

    useEffect(() => {
        setIsEditable(false);
    }, []);

    return (
        <Button
            onClick={() => setIsEditable(!isEditable)}
            type="primary"
            style={{width: '100%'}}
        >
            {actionIcon && <DynamicIcon type={actionIcon} />}
            {actionTitle}
        </Button>
    );
};
