import React from 'react';

import {
    IComparison, TableEntityData,
} from 'modules/data/data-types';
import {TableEntityMeta} from 'modules/metadata/metadata-types';

export function numberWithSpaces(x: number) {
    if (x == null) return '';
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
}

export function testIfNumber(x: string) {
    return /^-?\d*\.?\d*$/.test(x) && x !== '';
}

export function arrayContainsNumbers(array: any[]) {
    return array.some(
        element => /^-?\d*\.?\d*$/.test(element != null ? element.replace(/\s/g, '').replace(/,/g, '.') : element),
    );
}

export function normalizeComparisonData(
    data: IComparison[],
    metaData: TableEntityMeta,
    setNewMetaData: React.Dispatch<React.SetStateAction<TableEntityMeta | undefined>>,
    setNewTableData: React.Dispatch<React.SetStateAction<TableEntityData | undefined>>,
    setAddition: React.Dispatch<React.SetStateAction<string[]>>,
    setSheet: React.Dispatch<React.SetStateAction<string[]>>,
) {
    const sumColIds = [];
    const colSize = 0;
    let firstColId = 0;
    let secondColId = 0;
    const diffAttrCount = data[0]?.differenceAttributes.length;
    const fields: any[] = [];
    const rows: any[] = [];
    const newSheet: string[] = [];
    const newAddition: string[] = [];
    if (data.length) {
        data[0].comparisonAttributes.forEach((value: string, index: number) => fields.push({
            label: 'Показатель',
            key: index,
            isHidden: false,
            isSortable: true,
            type: 'STRING',
        }));
        data[0]?.differenceAttributes.slice(0, diffAttrCount / 2).forEach((value: string, index: number) => {
            fields.push({
                label: `Сумма документа 1.${index}`,
                key: fields.length,
                isSortable: true,
                type: 'STRING',
            });
            firstColId = fields.length - 1;
            sumColIds.push(firstColId);
            fields.push({
                label: `Сумма документа 2.${index}`,
                key: fields.length,
                isSortable: true,
                type: 'STRING',
            });
            secondColId = fields.length - 1;
            sumColIds.push(secondColId);
            fields.push({
                label: `Разница ${index}`,
                key: fields.length,
                isSortable: true,
                type: 'STRING',
            });
        });
        data.forEach((value, index) => {
            let row: any[] = [];
            if (value.sheet && value.sheet !== '' && !newSheet.includes(value.sheet)) {
                newSheet.push(value.sheet);
            }
            if (value.addition && value.addition !== '' && !newAddition.includes(value.addition)) {
                newAddition.push(value.addition);
            }
            row = row.concat(data[index].comparisonAttributes);
            for (let i = 0; i < diffAttrCount; i += 1) {
                row.push(data[index].differenceAttributes[i] != null
                    ? data[index].differenceAttributes[i].replace(/\s/g, '').replace(/,/g, '.')
                    : '');
            }
            if (!testIfNumber(row[firstColId]) && testIfNumber(row[secondColId])) {
                row.push(numberWithSpaces(row[secondColId]));
            }
            if (testIfNumber(row[firstColId]) && !testIfNumber(row[secondColId])) {
                row.push(numberWithSpaces(row[firstColId]));
            }
            if (!testIfNumber(row[firstColId]) && !testIfNumber(row[secondColId])) {
                row.push('');
            }
            row.push(testIfNumber(row[firstColId]) && testIfNumber(row[secondColId])
                ? numberWithSpaces(Math.abs(row[firstColId] - row[secondColId]))
                : '');
            row.push(data[index].sheet);
            row.push(data[index].addition);
            if (arrayContainsNumbers(data[index].differenceAttributes)) rows.push(row);
        });
    }
    setNewMetaData(
        {
            ...metaData,
            fields,
        },
    );
    setAddition((newAddition));
    setSheet((newSheet));
    setNewTableData({
        page: colSize,
        limit: 1,
        offset: 10,
        rows,
    });
}
