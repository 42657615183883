import {createSelector} from '@reduxjs/toolkit';
import {groupBy} from 'lodash';

import {FilterValueToDffSegmentMapping, FilterValueType} from 'components/report-configuration/report-configuration.constants';
import {AppState} from 'store/config/types';

import {ReportConfigurationReportColumnDto} from '../report-configuration-slice/report-configuration-dto';
import {tableReportSliceName} from './table-report-slice-constants';
import {TableReportColumn, TableReportFormattedHeaders} from './table-report-slice-types';
import {addCRColumns, addComparisonColumns, addLinkColumns} from './table-report-slice-utils';

const rootSelector = (state: AppState) => state[tableReportSliceName];

export const selectTableReportTemplateConfig = createSelector(rootSelector, ({templateConfig}) => templateConfig);
export const selectTableReportColumns = createSelector(rootSelector, ({columns}) => columns);
export const selectTableReportTemplateName = createSelector(
    selectTableReportTemplateConfig, config => config?.templateName,
);

export const selectTableReportData = createSelector(rootSelector, ({tableReportData}) => tableReportData);
export const selectTableReportExtraInfo = createSelector(selectTableReportData, data => data?.extraInfo);
export const selectTableReportRowAttachmentsGroupedById = createSelector(
    selectTableReportExtraInfo,
    extraInfo => {
        if (!extraInfo) return undefined;
        return Object.fromEntries(Object.entries(
            groupBy(extraInfo, v => v.reportRowId),
        ).map(([rowId, [entry]]) => [rowId, entry]));
    },
);

export const selectLastTableReportLoadPageRequestArgs = createSelector(
    rootSelector, ({lastLoadRequestArgs}) => lastLoadRequestArgs,
);

export const selectLinkDtoToColumnMapping = createSelector(rootSelector, (
    {linkDtoToColumnMapping},
) => linkDtoToColumnMapping);

export const selectTableReportOptions = createSelector(rootSelector, ({reportOptions}) => reportOptions);
export const selectTableReportPageSize = createSelector(selectTableReportOptions, ({pageSize}) => pageSize);

export const selectTableReportDataSource = createSelector(
    [selectTableReportData, selectTableReportPageSize, selectTableReportTemplateConfig],
    (tableReportData, pageSize, templateConfig) => {
        const dataSource = tableReportData?.page?.content;
        if (templateConfig?.paginationPosition?.toLowerCase() !== 'bottom') return dataSource;
        return dataSource?.slice(0, pageSize);
    },
);

export const selectTableReportCurrentColumns = createSelector([
    rootSelector,
    (_, hiddenColumnsKeys?: string[]) => hiddenColumnsKeys,
    selectTableReportDataSource,
],
({
    columns,
    reportOptions,
    templateConfig,
    menu: asideMenuStructure,
    comparisonOptions,
}, hiddenColumnsKeys, dataSource) => {
    let currentColumns: {[columnName: string]: ReportConfigurationReportColumnDto} | undefined;

    const {activeMenu} = reportOptions;
    if (activeMenu && asideMenuStructure?.length) {
        currentColumns = activeMenu?.reportColumns || columns || templateConfig?.reportColumns;
    } else currentColumns = columns || templateConfig?.reportColumns || undefined;

    if (!currentColumns) return undefined;

    const currentCustomColumns = (() => {
        const cColumns = activeMenu?.customColumns || templateConfig?.customColumns;
        if (!cColumns) return undefined;
        return Object.fromEntries(cColumns.map(c => ([c.columnName, c])));
    })();

    const allColumns = {
        ...currentColumns,
        ...currentCustomColumns,
    };

    const reportColumns: TableReportColumn[] = Object.entries(allColumns).map(([columnKey, value]) => {
        const newValues = Object.entries(value).map(([fieldKey, fieldValue]) => {
            if (fieldKey === 'columnOrdinal') return ['key', fieldValue];
            if (fieldKey === 'hidden') return ['permanentlyHidden', fieldValue];
            if (fieldKey === 'reportTitle') return ['title', fieldValue];
            return [fieldKey, fieldValue];
        });
        newValues.push(['dataIndex', columnKey]);
        newValues.push(['hidden', hiddenColumnsKeys?.includes(columnKey) ?? false]);
        return Object.fromEntries(newValues);
    });

    const reportColumnsFilteredAndSorted = (() => {
        let finalColumns = reportColumns;

        if (comparisonOptions.includeComparison) {
            const columnsWithComparisions = addComparisonColumns(reportColumns, dataSource);
            if (columnsWithComparisions) finalColumns = columnsWithComparisions;
        }

        if (comparisonOptions.includeCR) {
            const columnsWithCR = addCRColumns(reportColumns, dataSource);
            if (columnsWithCR) finalColumns = columnsWithCR;
        }

        finalColumns = addLinkColumns(finalColumns, dataSource);

        const filteredColumns = finalColumns.filter(c => !c?.hidden && !c.permanentlyHidden);
        const sortedColumns = filteredColumns
            .sort((c1, c2) => {
                const v1 = c1.dataIndex ?? 0;
                const v2 = c2.dataIndex ?? 0;
                if (v1 < v2) return -1;
                if (v2 > v1) return 1;
                return 0;
            })
            .sort((c1, c2) => {
                if (c1.key === undefined || c2.key === undefined) return 0;
                return c1.key - c2.key;
            });

        return sortedColumns;
    })();

    return reportColumnsFilteredAndSorted;
});

export const selectTableReportAsideMenu = createSelector(rootSelector, ({menu}) => menu);
export const selectTableReportActiveMenu = createSelector(selectTableReportOptions, ({activeMenu}) => activeMenu);

export const selectTableReportExcelTableHeaderStructure = createSelector([
    selectTableReportTemplateConfig,
    selectTableReportActiveMenu,
], (config, activeMenu) => activeMenu?.excelTableHeaderStructure || config?.excelTableHeaderStructure);

export const selectTableReportRawHeaders = createSelector(
    selectTableReportTemplateConfig, config => config?.reportHeaders,
);

export const selectTableReportComparisonOptions = createSelector(
    rootSelector, ({comparisonOptions}) => comparisonOptions,
);

export const selectTableReportHeaders = createSelector(
    [
        selectTableReportRawHeaders,
        selectTableReportActiveMenu,
    ], (rawHeaders, activeMenu) => {
        // У некоторых заголовков в value приходит "#MENU"
        // такие значения нужно брать из активного пункта меню
        const headers = !rawHeaders ? undefined : Object.fromEntries(
            Object.entries(rawHeaders).filter(([, v]) => !v.hidden),
        );

        const {name, ...others} = headers ?? {};
        const caption = headers?.caption ? {...headers?.caption} : undefined;
        const {caption: menuItemCaption, annexNumber, sheetNumber} = activeMenu ?? {};

        if (caption?.value === '#MENU') caption.value = menuItemCaption;

        const othersArray = !headers ? undefined : Object.entries(others).map(([key, v]) => {
            if (key === 'sheet_number' && v.value === '#MENU') return {...v, value: sheetNumber, key};
            if (key === 'annex_number' && v.value === '#MENU') return {...v, value: annexNumber, key};
            return {...v, key};
        })?.sort((a, b) => Number(a.ordinal) - Number(b.ordinal));

        return {
            name: name && {key: 'name', ...name},
            caption: caption && {key: 'caption', ...caption},
            others: othersArray,
        } as TableReportFormattedHeaders;
    },
);
export const selectTableReportFilterConditions = createSelector(rootSelector, ({filterConditions}) => filterConditions);
export const selectTableReportAttributes = createSelector(rootSelector, ({tableAttributes}) => tableAttributes);
export const selectTableReportAttributesAsRecords = createSelector(rootSelector, ({tableAttributes}) => {
    if (!tableAttributes) return tableAttributes;
    return Object.fromEntries(tableAttributes.map(({name, type}) => ([name, type])));
});

export const selectTableReportFiltersByFieldType = createSelector(
    [
        selectTableReportFilterConditions,
        (_, arg: {fieldType: FilterValueType}) => arg,
    ], (filterConditions, {fieldType}) => {
        const filterConditionsFiltered = filterConditions?.filter(filterConditon => {
            const dffSegmentValue = FilterValueToDffSegmentMapping[fieldType];
            if (filterConditon?.dffSegmentValues?.[dffSegmentValue]?.segmentValue !== 'Y') return false;
            return true;
        });

        return filterConditionsFiltered;
    },
);
