import {useForm} from 'antd/es/form/Form';
import {FormInstance} from 'antd/lib/form';
import React from 'react';

import {SimpleActionButton} from 'components/form/components';
import {useAppSelector} from 'store/config/hooks';
import {
    selectReportConfigurationTableAttributesAsSelectEntries,
} from 'store/slices/report-configuration-slice/report-configuration-slice';

import {AutofillOrdinalNumberModalOpener} from '../../modals/autofill-ordinal-number-modal-opener';
import {
    ExcelHeaderStructureLoaderModal,
} from '../../modals/excel-header-structure-loader-modal/excel-header-structure-loader-modal';
import {GroupingSettingsModalOpener} from '../../modals/grouping-settings-modal-opener';
import {RowFormatsModalOpener} from '../../modals/row-formats-modal-opener';
import {SortingSettingsModalOpener} from '../../modals/sorting-settings-modal-opener';

import './table-settings-functional-buttons.less';

interface TableSettingsFunctionalButtonsProps {
    disabled?: boolean;
    form: FormInstance<any>;
    customColumnsRef?: React.MutableRefObject<any>;
}

export const TableSettingsFunctionalButtons = ({
    disabled = false,
    form,
    customColumnsRef,
}: TableSettingsFunctionalButtonsProps) => {
    const [sortingSettingsForm] = useForm();

    const tableAttributesEntries = useAppSelector(selectReportConfigurationTableAttributesAsSelectEntries);

    return (
        <div className="report-table-settings__functional-buttons">
            <SortingSettingsModalOpener
                form={sortingSettingsForm}
                attributesEntries={tableAttributesEntries}
                disabled={disabled}
            />
            <GroupingSettingsModalOpener
                attributesEntries={tableAttributesEntries}
                disabled={disabled}
            />
            <RowFormatsModalOpener />
            <AutofillOrdinalNumberModalOpener
                form={form}
                disabled={disabled}
            />
            <ExcelHeaderStructureLoaderModal
                disabled={disabled}
            />
            <SimpleActionButton
                type="primary"
                icon="PlusCircleOutlined"
                title="Добавить кастомный атрибут"
                onClick={() => customColumnsRef?.current?.add()}
                disabled={disabled}
            />
        </div>
    );
};
