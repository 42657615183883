import {Checkbox} from 'antd';
import {CheckboxChangeEvent} from 'antd/es/checkbox';
import React, {useState} from 'react';

import {Entity, EntityValue} from 'modules/data';
import {FieldMeta} from 'modules/metadata';
import {normalizeBoolValue, normalizeBoolValueString} from 'utils/common';

interface BoolCellProps {
    value: EntityValue;
    record: Entity;
    field?: FieldMeta;
    handleChangeIndexRow?: (value: Entity, key: string, dependentsFieldKey?: string) => void;
}

export const BoolCell: React.FunctionComponent<BoolCellProps> = ({
    field,
    value,
    record,
    handleChangeIndexRow,
}: BoolCellProps) => {
    const {booleanValues = []} = field || {};
    const normalizedValue = normalizeBoolValue(value);
    const [boolValue, setBoolValue] = useState(normalizeBoolValue(value));
    const index = +normalizedValue;

    const handleChange = (e: CheckboxChangeEvent) => {
        const newRow = {...record, [String(field?.key)]: normalizeBoolValueString(e.target.checked)};
        setBoolValue(e.target.checked);
        if (field?.dependentsFieldKey && handleChangeIndexRow) {
            handleChangeIndexRow(newRow, field?.key, field.dependentsFieldKey);
            return;
        }
        if (handleChangeIndexRow) {
            handleChangeIndexRow(newRow, (field?.key || ''));
        }
    };
    React.useEffect(() => {
        setBoolValue(normalizeBoolValue(value));
    }, [value]);

    if (field?.checkForDisabledMetaFields || field?.dependentsFieldKey) {
        return (
            <Checkbox
                checked={boolValue}
                onChange={handleChange}
                disabled={field?.isDisabled}
            />
        );
    }

    return !booleanValues?.length ? (
        <Checkbox
            checked={normalizedValue}
            disabled
        />
    ) : (
        <span>{booleanValues[index]}</span>
    );
};
