import {Empty, Form} from 'antd';
import {FormInstance} from 'antd/es/form';
import Spin from 'antd/es/spin';
import React, {useEffect, useMemo, useState} from 'react';

import {SegmentResolverComponent} from 'components/report-configuration/components/segment-resolver-component';
import {UIBlocker} from 'components/ui-blocker';
import {AIS_DOC_REGISTERS_DFF_NAME} from 'pages/ais/documents-register-page/modals/document-card-modal/document-card-modal-constants';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {loadAisDocumentAdditionalFieldsByDocId} from 'store/slices/ais-slice/ais-slice-thunks';
import {selectContextSegments} from 'store/slices/descriptive-flexfields-slice/descriptive-flexfields-slice';
import {GLOBAL_CONTEXT} from 'store/slices/descriptive-flexfields-slice/descriptive-flexfields-slice-constants';
import {DescriptiveFlexFieldData} from 'store/slices/descriptive-flexfields-slice/descriptive-flexfields-slice-types';
import {loadContextSegments} from 'store/slices/descriptive-flexfields-slice/descriptive-flexfields-thunks';
import './additional-fields.less';
import {selectIsThunkPending, selectThunkError} from 'store/slices/loading-state-slice';

import {transformDtoToFlexFieldInitValues} from '../../document-card-modal-utils';

/**
 Не путать с атрибутами записи из таблицы реестра документов и AisRegisterDocumentDto -
 там они настраиваются в отдельной таблице, здесь - через flexValues
 */

interface AdditionalFieldsProps {
    id?: number;
    form: FormInstance;
    isDisabled?: boolean;
}

export const AdditionalFields: React.FC<AdditionalFieldsProps> = ({
    id, form, isDisabled,
}: AdditionalFieldsProps) => {
    const dispatch = useAppDispatch();

    const dffParams = {
        contextCode: GLOBAL_CONTEXT,
        dffName: AIS_DOC_REGISTERS_DFF_NAME,
    };

    const segments = useAppSelector(s => selectContextSegments(s, dffParams));
    const displayedSegments = useMemo(
        () => segments?.filter(s => s?.displayed),
        [segments],
    );
    const [initialValues, setInitialValues] = useState<DescriptiveFlexFieldData>({});

    const isSegmentsLoading = useAppSelector(s => selectIsThunkPending(s, loadContextSegments.typePrefix));
    const segmentsLoadingError = useAppSelector(s => selectThunkError(s, loadContextSegments.typePrefix));

    const loadSegments = () => dispatch(loadContextSegments({params: dffParams, prefix: 'ais'}));
    const loadSegmentsData = (docId: number) => dispatch(loadAisDocumentAdditionalFieldsByDocId({id: docId}));

    useEffect(() => {
        if (!segments) loadSegments();
    }, []);

    useEffect(() => {
        if (!id) {
            return;
        }
        loadSegmentsData(id)
            .unwrap()
            .then(data => setInitialValues(data));
    }, [id]);

    useEffect(() => {
        form.resetFields();
    }, [initialValues]);

    return (
        <UIBlocker
            thunkError={{
                error: segmentsLoadingError,
                retryCallback: () => loadSegments(),
                verbose: true,
            }}
            label="Во время загрузки полей произошла ошибка"
        >
            <Spin
                spinning={isSegmentsLoading}
                tip="Загрузка дополнительных полей..."
            >
                <div className="additional-fields">
                    <Form
                        name="flexFieldMap"
                        className="additional-fields-form"
                        layout="vertical"
                        form={form}
                        initialValues={transformDtoToFlexFieldInitValues(initialValues)}
                    >
                        {displayedSegments?.length ? (
                            <div className="additional-fields-form__segments">
                                {displayedSegments?.map(s => (
                                    <SegmentResolverComponent
                                        key={s.id}
                                        segment={s}
                                        options={{
                                            isDisabled,
                                        }}
                                    />
                                ))}
                            </div>
                        ) : <Empty description="Нет отображаемых полей" />}
                    </Form>
                </div>
            </Spin>
        </UIBlocker>
    );
};
