import cn from 'classnames';
import React, {useEffect, useImperativeHandle, useRef} from 'react';

import {StateSetter} from 'shared/types/generics';

import {DDFilterExpression} from './dd-filter-expression-creator-types';
import {DDFilterExpressionGroupRef, DDFilterExpressionGroup} from './dd-filter-expression-group';

import './dd-filter-expression-creator.less';

export interface DDFilterExpressionCreatorProps {
    className?: cn.Argument;
    isInEditMode: boolean;
    setIsInEditMode?: StateSetter<boolean>;
    initialExpression?: DDFilterExpression;
}

export interface DDFilterExpressionCreatorRef {
    getValuesToSave: () => Promise<DDFilterExpression> | undefined;
    setExpression: ((exp: DDFilterExpression) => void) | undefined;
}

export const DDFilterExpressionCreator = React.forwardRef<
DDFilterExpressionCreatorRef, DDFilterExpressionCreatorProps>((
    {
        className, isInEditMode, initialExpression,
    }: DDFilterExpressionCreatorProps,
    ref,
) => {
    const expressionRef = useRef<DDFilterExpressionGroupRef>(null);

    const getValuesToSave = () => expressionRef.current?.getValues() as Promise<DDFilterExpression>;
    const setExpression = expressionRef.current?.setExpression;

    useImperativeHandle(ref, () => ({
        getValuesToSave,
        setExpression,
    }));

    useEffect(() => {
        if (initialExpression) setExpression?.(initialExpression);
    }, [initialExpression]);

    return (
        <div className={cn('dd-filter-expression-creator')}>
            <DDFilterExpressionGroup
                ref={expressionRef}
                initialExpression={initialExpression ?? [{connectBy: 'OR'}]}
                className={className}
                isInEditMode={isInEditMode}
                depth={0}
                itemIndex="1"
            />
        </div>
    );
});
