import {Table as AntTable} from 'antd';
import {TablePaginationConfig} from 'antd/es/table';
import Column from 'antd/es/table/Column';
import React, {useEffect, useMemo, useState} from 'react';

import {FormEditModal} from 'components/form-edit-modal/form-edit-modal';
import {SimpleActionButton} from 'components/form/components';
import {CustomSelect} from 'components/form/inputs/custom-select';
import {loadTableData} from 'modules/data';
import {resetData} from 'modules/data/data-actions';
import {selectTableEntityData} from 'modules/data/data-selectors';
import {ReactComponent as ArrowRightIcon} from 'shared/assets/arrow-right.svg';
import {EntityType} from 'shared/constants/entities';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {controlRatioActions} from 'store/slices/control-ratio-slice';

import {Search} from '../../@common/inputs/search';
import {Table} from '../../table';
import {
    ControlRatiosSettingRuleSections,
    ControlRatioSettingsSection,
    CRIndicatorsSettingsSection,
    CrRulesTableDataUrl,
    ControlRatioSettingsRulesSection,
} from './constants/control-ratio-settings-rules';
import {CrRulesTableEntrySelector} from './cr-rules-table-entry-selector/cr-rules-table-entry-selector';

import './control-ratio-settings-rules.less';

export interface ControlRatioSettingsRulesProps {
    entity: string | null;
    entityName: string;
    handleFormClose: () => void;
    referenceUrlWithoutSlash: string;
}

export const ControlRatioSettingsRules = ({
    entity,
    entityName,
    handleFormClose,
    referenceUrlWithoutSlash,
}: ControlRatioSettingsRulesProps) => {
    const dispatch = useAppDispatch();

    const [section, setSection] = useState<ControlRatioSettingsRulesSection>(ControlRatioSettingsSection);
    const [selectedEntryId, setSelectedEntryId] = useState<number | null>(null);
    const [search, setSearch] = useState<string | null>(null);

    const crData = useAppSelector(selectTableEntityData(ControlRatioSettingsSection));
    const indicatorsData = useAppSelector(selectTableEntityData(CRIndicatorsSettingsSection));

    const fetchData = (searchValue?: string | null, pagination?: any) => {
        dispatch(loadTableData(
            ControlRatioSettingsSection,
            {paramsToBeConverted: {search: searchValue}, params: pagination},
            ControlRatioSettingsSection,
        ));
        dispatch(loadTableData(
            CRIndicatorsSettingsSection,
            {paramsToBeConverted: {search: searchValue}, params: pagination},
            CRIndicatorsSettingsSection,
        ));
    };

    useEffect(() => {
        fetchData();
    }, []);

    // save selected entry id for form
    useEffect(() => {
        if (!selectedEntryId) return;
        if (section === ControlRatioSettingsSection) {
            dispatch(
                controlRatioActions.setFormAdditionalParameters({settingRulesControlRatio: {id: selectedEntryId}}),
            );
        } else {
            dispatch(controlRatioActions.setFormAdditionalParameters({settingRulesObjectItem: {id: selectedEntryId}}));
        }
    }, [section, selectedEntryId]);

    const data = useMemo(() => {
        const rows = section === ControlRatioSettingsSection ? crData?.rows : indicatorsData?.rows;
        if (selectedEntryId) return rows?.filter(row => row.id === selectedEntryId);
        return rows;
    }, [crData, indicatorsData, selectedEntryId, section]);

    const handleEntrySelect = (entry: number | null) => {
        dispatch(resetData({
            entityName,
            entityType: EntityType.TABLE,
        }));
        setSelectedEntryId(entry);
    };

    const handlePaginationChange = (page: number, pageSize?: number | undefined) => {
        if (!selectedEntryId) {
            fetchData(search, {
                paginationCurrent: page,
                paginationPageSize: pageSize,
            });
        }
    };

    // state cleanup on unmount
    useEffect(() => () => {
        dispatch(controlRatioActions.resetFormAdditionalParameters());
    }, []);

    const pagination: TablePaginationConfig = {
        position: ['bottomLeft'],
        showQuickJumper: {goButton: <ArrowRightIcon className="pagination_jumper" />},
        locale: {items_per_page: 'на странице', jump_to: ''},
        pageSizeOptions: ['5', '10', '20', '50', '100'],
        showSizeChanger: true,
        total: crData?.params?.paginationTotal,
        onChange: handlePaginationChange,
    };

    return (
        <>
            <div className="control-ratio-settings-rule">
                <div className="control-ratio-settings-rule__buttons">
                    <SimpleActionButton
                        icon="ArrowLeft"
                        type="primary"
                        title="Назад"
                        disabled={!selectedEntryId}
                        onClick={() => setSelectedEntryId(null)}
                    />
                    <div className="section-switcher">
                        <CustomSelect
                            value={section}
                            entries={ControlRatiosSettingRuleSections}
                            onChange={value => {
                                setSection(value);
                                setSelectedEntryId(null);
                            }}
                        />
                    </div>
                    <Search
                        setSearch={setSearch}
                        fetchCallback={fetchData}
                    />
                </div>
                <AntTable
                    rowKey="id"
                    scroll={{x: 'max-content'}}
                    dataSource={data}
                    pagination={selectedEntryId ? false : pagination}
                    loading={!data}
                >
                    <Column
                        key="code"
                        dataIndex="code"
                        title="Тип показателя"
                        width={320}
                    />
                    <Column
                        key="name"
                        dataIndex="name"
                        title="Наименование"
                        render={(value, record: Record<string, any>) => (
                            <CrRulesTableEntrySelector
                                value={value}
                                record={record}
                                selectTableEntry={handleEntrySelect}
                            />
                        )}
                    />
                </AntTable>
            </div>
            <div className="cr-rules-table-wrapper">
                {selectedEntryId && (
                    <Table
                        entityName={entityName}
                        url={`${CrRulesTableDataUrl[section]}/${selectedEntryId}`}
                    />
                )}
            </div>
            {entity && !Number.isNaN(parseInt(entity, 10)) && (
                <FormEditModal
                    entityName={entityName}
                    entityType={EntityType.FORM}
                    onClose={handleFormClose}
                    referenceUrl={`${referenceUrlWithoutSlash}/${entity}`}
                />
            )}
        </>
    );
};
