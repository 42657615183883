import {createSelector, Selector} from '@reduxjs/toolkit';

import {AppState} from 'store/config/types';

import {LookupValue} from '../data/data-types';
import {DOCUMENTS_MODULE} from './documents-constants';
import {
    DocumentProperty,
    DocumentsState,
    DocumentTypeResponse,
    FileUploadState,
    SignatureUploadState,
} from './documents-types';

const rootSelector: Selector<AppState, DocumentsState> = (state: AppState) => state[DOCUMENTS_MODULE];

export const selectDocumentsForUploadAttach = createSelector(
    [rootSelector, (_, sublistCode: string) => sublistCode],
    ({documentsForUploadAttach}, sublistCode: string) => documentsForUploadAttach?.[sublistCode],
);

export const selectDocumentTypeOnUpload: Selector<AppState, DocumentTypeResponse[] | undefined> = createSelector(
    rootSelector,
    ({documentTypesOnUpload}: DocumentsState) => documentTypesOnUpload,
);

export const selectDocumentTypes: Selector<AppState, DocumentTypeResponse[] | undefined> = createSelector(
    rootSelector,
    ({documentTypes}: DocumentsState) => documentTypes,
);

export const selectDocumentBranchNames: Selector<AppState, DocumentTypeResponse[] | undefined> = createSelector(
    rootSelector,
    ({branchNames}: DocumentsState) => branchNames,
);

export const selectDocumentProperties: Selector<AppState, DocumentProperty | undefined> = createSelector(
    rootSelector,
    ({documentProperties}: DocumentsState) => documentProperties,
);

export const selectDocumentsSigningMethod = createSelector(
    rootSelector,
    ({signingMethod}: DocumentsState) => signingMethod,
);

export const selectDocumentFileUpload: Selector<AppState, FileUploadState> = createSelector(
    rootSelector,
    ({fileUpload}: DocumentsState) => fileUpload,
);

export const selectDocumentSignatureUpload: Selector<AppState, SignatureUploadState> = createSelector(
    rootSelector,
    ({signatureUpload}: DocumentsState) => signatureUpload,
);

export const selectDocumentExtensions = createSelector(
    rootSelector,
    ({signatureExtensions}: DocumentsState) => signatureExtensions,
);

export const selectDocumentList: Selector<AppState, LookupValue[] | undefined> = createSelector(
    rootSelector,
    ({documentList}: DocumentsState) => documentList,
);

export const selectDocumentFileUploadByEntityName = (entityName: string) => createSelector(
    rootSelector,
    ({fileUpload}: DocumentsState) => fileUpload[entityName],
);

export const selectDocumentSignatureUploadByEntityName = (entityName: string) => createSelector(
    rootSelector,
    ({signatureUpload}: DocumentsState) => signatureUpload[entityName],
);

export const selectDocumentsUploadMonitoring = () => createSelector(
    rootSelector,
    ({documentsUploadMonitoring}: DocumentsState) => documentsUploadMonitoring,
);

export const selectDocumentsPagination = createSelector(
    rootSelector,
    ({pagination}: DocumentsState) => pagination,
);
