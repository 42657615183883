import omit from 'lodash/omit';

import {ReportConfigurationDataConverted} from 'store/slices/report-configuration-slice';

type OmitEmptyAndUndefinedValuesArgs = {
    valuesFromSubmit: Pick<ReportConfigurationDataConverted, 'reportComparisons'>;
    hasSheets: boolean;
}

export const omitEmptyAndUndefinedValues = ({
    valuesFromSubmit, hasSheets,
}: OmitEmptyAndUndefinedValuesArgs) => {
    const {reportComparisons: values} = valuesFromSubmit;

    if (!values) return values;

    const clearArray = (array?: (string | undefined)[]) => {
        const filteredArray = array?.filter(v => !!v);
        return !filteredArray?.length ? undefined : filteredArray;
    };

    const withCleanValues: typeof values = values.map(rc => {
        const cleanAttrs = clearArray(rc?.attr);
        const cleanKeys = clearArray(rc?.key);

        const rcValue = {
            sheetCode: rc?.sheetCode,
            attr: cleanAttrs,
            key: cleanKeys,
        };

        // убираем sheetCode: undefined в случаях, когда листов нет.
        if (!hasSheets) {
            const rcValueWithOmittedSheetCode = omit(rcValue, 'sheetCode');
            return rcValueWithOmittedSheetCode;
        }
        return rcValue;
    });

    const withCleanValuesFiltered = withCleanValues.filter(rc => {
        // в случае, когда есть листы, не пропускаем дефолтную настройку (без sheetCode`а)
        if (hasSheets && !rc?.sheetCode) return false;
        if (!rc?.attr || !rc?.key) return false;
        if (!rc.attr?.length && !rc.key?.length) return false;

        return true;
    });

    return withCleanValuesFiltered;
};
