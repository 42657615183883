import {Button, Form} from 'antd';
import {useForm} from 'antd/es/form/Form';
import cn from 'classnames';
import {get} from 'lodash';
import moment from 'moment';
import React, {useEffect, useState} from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {loadReferenceData} from 'modules/data/data-actions';
import {selectReferenceEntityData} from 'modules/data/data-selectors';
import {ReactComponent as PillarsIcon} from 'shared/assets/pillars.svg';
import {DATE_WITH_TIME_DOTS} from 'shared/constants/date-format';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';
import {tableReportSliceActions} from 'store/slices/table-report-slice/table-report-slice';
import {loadTableReportPageData} from 'store/slices/table-report-slice/table-report-slice-thunks';

import './comparison-form.less';

interface ComparisonFormProps {
    docId: string | null;
}

export const ComparisonForm = ({
    docId,
}: ComparisonFormProps) => {
    const dispatch = useAppDispatch();
    const [form] = useForm();

    const isLoadingPageData = useAppSelector(s => selectIsThunkPending(s, loadTableReportPageData.typePrefix));

    const {
        updateComparisonOptions,
    } = tableReportSliceActions;

    const docAttributesUrl = `/table-reports/lookup/comparison?DOC_ID=${docId}`;
    const docAttributesData = useAppSelector(selectReferenceEntityData(docAttributesUrl));
    const docAttributesEntries = docAttributesData?.map(dataEntry => ({
        label: [
            `${get(dataEntry, 'meaning')}`,
            get(dataEntry, 'attribute1') ? `, кор.${get(dataEntry, 'attribute1')}` : '',
            get(dataEntry, 'description') ? `, версия: ${get(dataEntry, 'description')}` : '',
            get(dataEntry, 'attribute2') ? `, период: ${get(dataEntry, 'attribute2')}` : '',
            get(dataEntry, 'createdDate') ? (
                `, дата создания: ${moment(get(dataEntry, 'createdDate') as string).format(DATE_WITH_TIME_DOTS)}`
            )
                : '',
        ].join(''),
        value: get(dataEntry, 'id') as string,
    }));
    const sourceAttribute = docAttributesEntries
        ?.find(e => String(e.value) === docId)
        || {label: 'Запись для исходного документа не найдена', value: null};

    const [isLoading, setIsLoading] = useState(false);

    // initial attributes load
    useEffect(() => {
        if (!docAttributesData && docId) {
            setIsLoading(true);
            dispatch(loadReferenceData(docAttributesUrl)).finally(() => {
                setIsLoading(false);
            });
        }
    }, [docAttributesData]);

    const handleSubmit = (values: any) => {
        if (values.comparedDocId) {
            dispatch(updateComparisonOptions({
                includeComparison: true,
                includeCR: false,
                comparedToDocId: {id: `${values?.comparedDocId}`},

                noFetchWithCR: true,
            }));
        }
    };

    return (
        // <div className={cn('table-report-actions-comparison', 'comparison-form', {hidden})}>
        <div className={cn('table-report-actions-comparison', 'comparison-form')}>
            <Form
                form={form}
                className="comparison-form__form"
                onFinish={handleSubmit}
            >
                <Form.Item
                    label="Исходный документ"
                    name="sourceDocId"
                >
                    <CustomSelect
                        entries={[sourceAttribute]}
                        settings={{
                            showSearch: true,
                            useFirstOptionAfterEntriesChanged: true,
                            isDisabled: true,
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="Документ для сравнения"
                    name="comparedDocId"
                    rules={[{required: true}]}
                >
                    <CustomSelect
                        entries={docAttributesEntries?.filter(entry => entry.value !== sourceAttribute.value)}
                        settings={{
                            showSearch: true,
                            isClearable: true,
                            placeholder: 'Выберите второй документ для сравнения',
                            isLoading,
                            isDisabled: isLoading,
                        }}
                    />
                </Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    className="comparison-form__submit-btn"
                    disabled={isLoadingPageData}
                    icon={<PillarsIcon />}
                >
                    Сравнить
                </Button>
            </Form>
        </div>
    );
};
