import {
    createEntityAdapter, createSelector, createSlice,
} from '@reduxjs/toolkit';
import {omit, union} from 'lodash';

import {CustomSelectEntry} from 'components/form/inputs/custom-select';
import {DDFilterExpression} from 'components/report-configuration/tabs/report-dd-filters/dd-filter-expression-creator/dd-filter-expression-creator-types';
import {DdParameterRuleItemSourceType} from 'components/report-configuration/tabs/report-dd-parameters/dd-parameters-rule/dd-parameters-rule-item/dd-parameters-rule-item-types';
import {AppState} from 'store/config/types';

import {createDdFiltersConfigurationExtraReducer, createDdFiltersConfigurationReducer} from './dd-filters-configuration/dd-filters-configuration-reducers';
import {createDdLookupsExtraReducer} from './dd-lookups';
import {createDdParametersExtraReducer} from './dd-parameters-configuration/dd-parameters-configuration-reducers';
import {ReportDdParameter} from './dd-parameters-configuration/dd-parameters-configuration-types';
import {
    createDdReferenceRulesExtraReducer,
    createDdReferenceRulesReducer,
    ReportDdReferenceRule,
} from './dd-references-configuration';
import {
    DD_REFERENCES_TABLE_DEFAULT_PAGINATION,
    reportConfigurationSliceName,
} from './report-configuration-constants';
import {ReportConfigurationSliceState} from './report-configuration-types';
import {
    createTableReportConfigurationExtraReducer,
    createTableReportConfigurationExtraReducerMatchers,
} from './report-configuration/report-configuration-reducers';
import {
    createTableReportDepenceniesExtraReducer,
    createTableReportDependenciesReducer,
} from './report-dependencies';

export const ddReferenceRulesAdapter = createEntityAdapter<ReportDdReferenceRule>();
export const ddParametersAdapter = createEntityAdapter<ReportDdParameter>();

const reportConfigurationInitialState: ReportConfigurationSliceState = {
    reportConfigurationData: undefined,

    reportDependencies: {
        headerAttributes: undefined,
        tableAttributes: undefined,
        templateBlocks: undefined,
    },

    drilldownSettings: {
        ddReferenceRules: ddReferenceRulesAdapter.getInitialState([]),
        ddReferenceRulesTotalCount: undefined,
        ddReferenceRulesFilter: {
            paginationCurrent: DD_REFERENCES_TABLE_DEFAULT_PAGINATION.page,
            paginationPageSize: DD_REFERENCES_TABLE_DEFAULT_PAGINATION.pageSize,
        },

        ddParameters: ddParametersAdapter.getInitialState([]),
        ddChildReportQueryParameters: undefined,

        ddFilter: undefined,
    },
    ddLookups: {
        tableColumns: {},
        filters: undefined,
    },
};

export const selectDdParametersState = (
    state: ReportConfigurationSliceState,
) => state.drilldownSettings.ddParameters;

export const selectDdReferenceRulesState = (
    state: ReportConfigurationSliceState,
) => state.drilldownSettings.ddReferenceRules;

export const reportConfigurationSlice = createSlice({
    name: reportConfigurationSliceName,
    initialState: reportConfigurationInitialState as ReportConfigurationSliceState,
    extraReducers: builder => {
        createDdReferenceRulesExtraReducer(builder);
        createDdParametersExtraReducer(builder);
        createDdLookupsExtraReducer(builder);
        createTableReportConfigurationExtraReducer(builder);
        createTableReportDepenceniesExtraReducer(builder);
        createDdFiltersConfigurationExtraReducer(builder);
        // -- matchers
        createTableReportConfigurationExtraReducerMatchers(builder);
    },
    reducers: {
        ...createDdReferenceRulesReducer(),
        ...createTableReportDependenciesReducer(),
        ...createDdFiltersConfigurationReducer(),

        resetReportConfigurationData(state) {
            state.reportConfigurationData = undefined;
        },
    },
});

export const selectReportConfigurationSliceState = (state: AppState) => state[reportConfigurationSliceName];
export const selectTableReportConfigurationData = (state: AppState) => selectReportConfigurationSliceState(
    state,
).reportConfigurationData;
export const selectTableReportConfigurationDependencies = (state: AppState) => selectReportConfigurationSliceState(
    state,
).reportDependencies;
export const selectTableReportDrilldownSettings = (state: AppState) => selectReportConfigurationSliceState(
    state,
).drilldownSettings;

export const selectDDChildReportQueryParameters = createSelector(selectTableReportDrilldownSettings,
    ddSettings => ddSettings.ddChildReportQueryParameters);

export const selectDrilldownFilterExpression = createSelector(
    selectTableReportDrilldownSettings,
    ddSettings => ddSettings.ddFilter as DDFilterExpression,
);

export const selectUsedVariablesInDdFilterExpression = createSelector(
    selectTableReportDrilldownSettings,
    ddSettings => {
        const filter = ddSettings.ddFilter as DDFilterExpression;
        if (!filter) return undefined;

        const variables: unknown[] = [];

        const getVariables = (exp: DDFilterExpression) => {
            const [, ...operands] = exp;
            operands.forEach(oper => {
                if (Array.isArray(oper)) {
                    getVariables(oper);
                } else if (oper.parametrized && oper.enabled) variables.push(oper.comparisonValue);
            });
        };

        getVariables(filter);

        return union(variables);
    },
);

export const selectReportConfigurationHeaderAttributes = createSelector(
    selectTableReportConfigurationDependencies,
    ({headerAttributes}) => headerAttributes,
);

export const selectReportConfigurationTemplateBlocksCount = createSelector(
    selectTableReportConfigurationDependencies,
    ({templateBlocks}) => templateBlocks,
);

export const selectReportConfigurationHeaderAttributesAsSelectEntries = createSelector(
    [selectReportConfigurationHeaderAttributes, selectTableReportConfigurationData],
    headerAttributes => (!headerAttributes ? undefined : headerAttributes.map(({name, type, typeGroup}) => ({
        label: name,
        value: name,
        type: type ?? 'Тип не найден',
        typeGroup,
        name,
    }))),
);

export const selectReportConfigurationTableAttributes = createSelector(
    selectTableReportConfigurationDependencies,
    ({tableAttributes}) => tableAttributes,
);

export const selectReportConfigurationTableAttributesAsTypesRecords = createSelector(
    selectTableReportConfigurationDependencies,
    ({tableAttributes}) => (!tableAttributes ? tableAttributes
        : Object.fromEntries(tableAttributes?.map(attr => ([attr.name, attr.type])))),
);

export const selectReportConfigurationTableAttributesAsSelectEntries = createSelector(
    [(
        _, isFilteredByHidden?: boolean,
    ) => isFilteredByHidden,
    selectReportConfigurationTableAttributes,
    selectTableReportConfigurationData],
    (isFilteredByHidden, tableAttributes, configurationData) => {
        const {reportColumns} = configurationData ?? {};

        return !tableAttributes ? undefined : tableAttributes
            .filter(({name}) => {
                if (!isFilteredByHidden) return true;
                const hidden = reportColumns?.find(column => column?.keyName === name)?.hidden;
                return !hidden;
            })
            .map(({
                name,
                type,
                typeGroup,
                comment,
            }) => {
                const title = reportColumns?.find(column => column?.keyName === name)?.reportTitle;
                return {
                    label: title ?? name,
                    value: name,
                    type: type ?? 'Тип не найден',
                    typeGroup,
                    name,
                    comment,
                };
            });
    },
);

export const selectReportConfigurationMenuAsList = createSelector(
    selectTableReportConfigurationData,
    reportConfigurationData => {
        const reportMenu = reportConfigurationData?.reportMenu;
        const list: typeof reportMenu = [];
        const collectData = (menu: typeof reportMenu) => {
            if (!menu) return;
            menu.forEach(item => {
                const {children} = item ?? {};
                list.push(omit(item, ['children']));
                if (children) collectData(children);
            });
        };
        collectData(reportMenu);
        return list;
    },
);

export const selectReportConfigurationMenuAsListFilteredByReportColumns = createSelector(
    selectReportConfigurationMenuAsList, list => list.filter(item => !!item?.reportColumns),
);

export const selectReportConfigurationMenuReportAndCustomColumnsBySheetCode = createSelector([
    selectReportConfigurationMenuAsListFilteredByReportColumns,
    (_, sheetCode?: string) => sheetCode,
], (menuItems, sheetCode) => {
    if (!sheetCode) return undefined;
    const menuItem = menuItems.find(item => item?.sheetCode === sheetCode);
    return {
        reportColumns: menuItem?.reportColumns,
        customColumns: menuItem?.customColumns,
    };
});

const createSelectMenuAsEntriesList = (withReportColumnsFilter: boolean) => createSelector(
    withReportColumnsFilter
        ? selectReportConfigurationMenuAsListFilteredByReportColumns
        : selectReportConfigurationMenuAsList,
    list => list
        .filter(item => {
            const {sheetCode, name} = item ?? {};
            return !!sheetCode && !!name;
        })
        .map(item => {
            const {sheetCode, name} = item ?? {};
            return {
                label: name as string,
                value: sheetCode,
            };
        }) as CustomSelectEntry<string, string>[],
);

export const selectReportConfigurationMenuAsEntriesList = createSelectMenuAsEntriesList(false);
export const selectReportConfigurationMenuAsEntriesListFilteredByReportColumns = createSelectMenuAsEntriesList(true);

export const selectDdParametersAsEntries = createSelector(selectTableReportDrilldownSettings, state => {
    const {ids, entities} = state.ddParameters;
    const parametersList = ids.map(id => entities[id]).filter(
        parameter => parameter?.childSourceType === DdParameterRuleItemSourceType.filter && parameter.childValue,
    ).map(parameter => ({
        value: parameter!.childValue,
        label: parameter!.childValue,
    }));
    return parametersList;
});

export const ddParametersSelectors = {
    ...ddParametersAdapter.getSelectors<AppState>(state => selectTableReportDrilldownSettings(state).ddParameters),
};

export const ddReferenceRulesSelectors = {
    ...ddReferenceRulesAdapter.getSelectors<AppState>(
        state => selectTableReportDrilldownSettings(state).ddReferenceRules,
    ),
    selectDdReferenceRulesFilter: createSelector(selectTableReportDrilldownSettings,
        settings => settings.ddReferenceRulesFilter),
    selectDdReferenceRulesTotalCount: createSelector(selectTableReportDrilldownSettings,
        settings => settings.ddReferenceRulesTotalCount),
};

export const selectReportConfigurationMenuExcelTableHeaderStructureBySheetCode = createSelector([
    selectReportConfigurationMenuAsListFilteredByReportColumns,
    (_, sheetCode?: string) => sheetCode,
], (menuItems, sheetCode) => {
    if (!sheetCode) return undefined;
    const menuItem = menuItems.find(item => item?.sheetCode === sheetCode);
    return menuItem?.excelTableHeaderStructure;
});

export const {
    reducer: reportConfigurationSliceReducer,
    actions: reportConfigurationActions,
} = reportConfigurationSlice;
