import React from 'react';

import {BreadcrumbItem} from 'components/breadcrumbs';

export interface BreadcrumbsContextState {
    breadcrumbs: BreadcrumbItem[] | undefined;
}

export const BreadcrumbsContext = React.createContext<BreadcrumbsContextState>({
    breadcrumbs: undefined,
});
