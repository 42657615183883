import {FieldType} from 'modules/metadata';
import {TMetaOption} from 'modules/metadata-constructor/metadata-constructor.types';

import {COMMON_FIELDS_ITEMS} from '../common/common.fields-items';
import {FILTER_ACTIONS} from './filter.actions';
import {FILTER_FIELDS} from './filter.fields-for-type-fields';
import {FILTER_OPTIONS} from './filter.options';

export const METADATA_FILTER: TMetaOption = {
    fields: [
        {
            key: 'fields',
            label: 'Поля фильтра',
            type: FieldType.LIST,
            dataKeyForFilter: 'type',
            defaultItems: [
                COMMON_FIELDS_ITEMS.FIELDS.KEY,
                COMMON_FIELDS_ITEMS.FIELDS.LABEL,
                COMMON_FIELDS_ITEMS.FIELDS.IS_HIDDEN,
                {
                    key: 'type',
                    label: 'Тип',
                    type: FieldType.STATIC_SELECT,
                    options: FILTER_OPTIONS.FIELD_TYPES,
                    isRequired: true,
                },
            ],
            customItems: FILTER_FIELDS,
        },
        {
            key: 'actions',
            label: 'Действия фильтра',
            type: FieldType.LIST,
            dataKeyForFilter: 'actionType',
            defaultItems: [
                {
                    ...COMMON_FIELDS_ITEMS.ACTIONS.ACTION_TYPE,
                    options: FILTER_OPTIONS.ACTION_TYPES,
                },
                COMMON_FIELDS_ITEMS.ACTIONS.ACTION_TITLE,
                COMMON_FIELDS_ITEMS.ACTIONS.ACTION_ICON,
            ],
            customItems: FILTER_ACTIONS,
        },
    ],
};
