import {Card, Form, message} from 'antd';
import {useForm} from 'antd/lib/form/Form';
import cn from 'classnames';
import {isEmpty} from 'lodash';
import React, {useEffect, useMemo} from 'react';
import {useDispatch} from 'react-redux';

import {SimpleActionButton} from 'components/form/components';
import {Spinner} from 'components/spinner';
import {
    createActionHandlerForPropertySettingsSave,
    loadPropertyFormData,
    setNotificationDuration,
} from 'modules/data/data-actions';
import {selectFormEntityData} from 'modules/data/data-selectors';
import {MetaActionInfo} from 'modules/metadata';
import {useAppSelector} from 'store/config/hooks';

import {Properties} from '../../propperty-settings.types';
import {PropertySettingsField} from '../property-settings-field/property-settings-field';
import {getInitialValues, getPropertyDifferentValues} from './property-settings-form.utils';

import './property-settings-form.less';

interface PropertySettingsProps {
    selectedItem?: string;
    entityName: string;
    isEdit: boolean;
    handleEdit: (edit: boolean) => void;
    actions?: MetaActionInfo[];
}

export const PropertySettingsForm: React.FC<PropertySettingsProps> = ({
    selectedItem,
    entityName,
    isEdit,
    handleEdit,
    actions,
}: PropertySettingsProps) => {
    const [form] = useForm();
    const dispatch = useDispatch();

    const formData = useAppSelector(selectFormEntityData(`${entityName}/${selectedItem}`));

    const isButtonVisible = useMemo(() => (!!actions?.map(action => action.actionCode === 'CREATE').length),
        [actions]);

    const initialData = useMemo(() => (formData?.data
        ? getInitialValues(formData.data as unknown as Array<Properties>) : {}),
    [formData]);

    useEffect(() => {
        (async () => {
            if (entityName && selectedItem && !formData) {
                dispatch(loadPropertyFormData(entityName, selectedItem));
            }
        })();
    }, [selectedItem]);

    useEffect(() => { form.resetFields(); }, [initialData]);

    const handleCancel = () => {
        form.setFieldsValue(initialData);
        handleEdit(false);
    };

    const handleSubmit = () => {
        const values = form?.getFieldsValue();
        const data = getPropertyDifferentValues(values, initialData);
        if (!isEmpty(data)) {
            (async () => {
                if (data.TIMEOUT_NOTY_SETTING) {
                    dispatch(setNotificationDuration(
                        {notificationDuration: data.TIMEOUT_NOTY_SETTING},
                    ));
                }
                await dispatch(createActionHandlerForPropertySettingsSave({data}));
                if (selectedItem) {
                    dispatch(loadPropertyFormData(entityName, selectedItem));
                }
            })();
        }
        handleEdit(false);
    };
    return (
        <div className="property-settings-form">
            <Form
                layout="vertical"
                form={form}
                name={entityName}
                initialValues={initialData}
                onFinish={handleSubmit}
                onFinishFailed={() => message.error('Заполните форму корректно')}
            >
                {isButtonVisible && formData && (
                    <div className="property-settings-button">
                        <SimpleActionButton
                            type="primary"
                            icon="EditWithLine"
                            title="Редактировать"
                            className={cn(isEdit && 'no-display')}
                            onClick={() => handleEdit(true)}
                        />
                        <SimpleActionButton
                            type="primary"
                            icon="Save"
                            title="Сохранить"
                            className={cn(!isEdit && 'no-display')}
                            htmlType="submit"
                        />
                        <SimpleActionButton
                            type="primary"
                            icon="CloseXOutlined"
                            className={cn(!isEdit && 'no-display')}
                            onClick={handleCancel}
                            title="Отмена"
                        />
                    </div>
                )}
                {formData
                    ? (((formData?.data as unknown) as Array<Properties>)?.map(block => (
                        <Card
                            className="field-block"
                            title={block.groupName}
                        >
                            {block.properties.map(property => (
                                <PropertySettingsField
                                    property={property}
                                    isEdit={isEdit}
                                    form={form}
                                />
                            ))}
                        </Card>
                    )))
                    : <Spinner />}
            </Form>
        </div>
    );
};
