import {PostgresColumnType, PostgresDateType, PostgresNumberType} from 'shared/types/postgres';
import {ReportConfigurationDataConverted} from 'store/slices/report-configuration-slice';
import {
    ReportConfigurationCustomColumnDto,
    ReportConfigurationAlign,
    ReportConfigurationExcelHeaderTableStructureDto,
    ReportConfigurationReportColumnDto, ReportConfigurationFontStyle,
} from 'store/slices/report-configuration-slice/report-configuration-dto';

import {TableAttributesCustomSelectEntry} from '../report-table-settings';

interface DefaultTableAttributesValues {
  align: ReportConfigurationAlign;
  style: ReportConfigurationFontStyle;
  format?: string;
}

export const getDefaultTableAttributesValues = (
    fieldType: PostgresColumnType,
): DefaultTableAttributesValues => {
    switch (fieldType) {
    case PostgresNumberType.numeric:
        return {align: 'RIGHT_MIDDLE', style: 'FONT_NORMAL', format: '#,##0.00'};
    case PostgresDateType.date:
    case PostgresDateType.timestamp:
        return {align: 'LEFT_MIDDLE', style: 'FONT_NORMAL', format: 'dd.MM.yyyy'};
    case PostgresNumberType.int:
    case PostgresNumberType.int2:
    case PostgresNumberType.int4:
    case PostgresNumberType.int8:
        return {align: 'RIGHT_MIDDLE', style: 'FONT_NORMAL', format: '#'};
    default:
        return {align: 'LEFT_MIDDLE', style: 'FONT_NORMAL'};
    }
};

export const mergeTableAttributesAndReportColumns = (
    attributesEntries: TableAttributesCustomSelectEntry[],
    reportColumns: ReportConfigurationDataConverted['reportColumns'],
) => attributesEntries?.map(attribute => {
    const entryFromData = reportColumns?.find(col => col?.keyName === attribute?.value);
    const {align, style, format} = getDefaultTableAttributesValues(attribute?.type as PostgresColumnType);
    return {
        keyName: attribute?.value,
        fieldType: attribute?.type,
        typeGroup: attribute?.typeGroup,
        reportTitle: entryFromData?.reportTitle ?? attribute?.comment,
        columnOrdinal: entryFromData?.columnOrdinal,
        hidden: entryFromData?.hidden,
        fieldFunction: entryFromData?.fieldFunction,
        // formatting form fields
        align: entryFromData?.align ?? align,
        style: entryFromData?.style ?? style,
        format: entryFromData?.format ?? format,
        width: entryFromData?.width,
        enabledTextWrapping: entryFromData?.enabledTextWrapping,
        // security modal fields
        blockedForRoleAndFunctionNames: entryFromData?.blockedForRoleAndFunctionNames,
    };
});

/**
 * Устанавливает Порядок вывода начиная с 1
 * для каждого столбца в массивах основных атрибутов и кастомных (пользовательских) атрибутов.
 *
 * @param reportColumnsToModify - Массив атрибутов, которые нужно модифицировать.
 * @param customColumnsToModify - Массив пользовательских атрибутов, которые нужно модифицировать.
 * @param skipHidden - Если `true`, то столбцы которые не выводятся в отчет (`hidden: true`) пропускаются.
 *
 * @returns Объект с массивами `reportColumns` и `customColumns`,
 * в которых для каждого столбца установлен `columnOrdinal`.
 */
export function setOrdinalNumber(
    reportColumnsToModify: ReportConfigurationReportColumnDto[] | undefined,
    customColumnsToModify: ReportConfigurationCustomColumnDto[] | undefined,
    skipHidden: boolean,
) {
    let acc = 1;

    if (reportColumnsToModify) {
        for (let i = 0; i < reportColumnsToModify.length; i += 1) {
            if (!(skipHidden && reportColumnsToModify[i].hidden)) {
                reportColumnsToModify[i].columnOrdinal = acc;
                acc += 1;
            }
        }
    }

    if (customColumnsToModify) {
        for (let i = 0; i < customColumnsToModify.length; i += 1) {
            if (!(skipHidden && customColumnsToModify[i].hidden)) {
                customColumnsToModify[i].columnOrdinal = acc;
                acc += 1;
            }
        }
    }

    return {reportColumns: reportColumnsToModify, customColumns: customColumnsToModify};
}

interface DataToJoin {
    reportColumns?: ReportConfigurationDataConverted['reportColumns'];
    customColumns?: ReportConfigurationDataConverted['customColumns'];
    excelTableHeaderStructure?: ReportConfigurationExcelHeaderTableStructureDto;
}

interface JoinReportTableDataToMenuConfigurationDataArgs {
    reportMenu: ReportConfigurationDataConverted['reportMenu'];
    dataToJoin: DataToJoin;
    sheetCode?: string;
}

type ReportMenu = ReportConfigurationDataConverted['reportMenu'];
type ReportConfigurationMenuItem = Exclude<ReportMenu, undefined>[0];

/* Рекурсивно проходим по всей структуре меню, к нужному объекту [при совпадении sheetCode]
   прикрепляем переданные reportColumns. */
export const joinReportTableDataToMenuConfigurationData = ({
    reportMenu,
    dataToJoin,
    sheetCode,
}: JoinReportTableDataToMenuConfigurationDataArgs) => {
    if (!sheetCode) return reportMenu;
    const join: (menu: ReportMenu) => ReportMenu = menu => (
        menu?.map((item: ReportConfigurationMenuItem) => {
            if (!item) return item;
            if (item.sheetCode === sheetCode) {
                return {
                    ...item,
                    ...dataToJoin,
                } as ReportConfigurationMenuItem;
            }
            if (item.children) {
                return {
                    ...item,
                    children: join(item.children),
                } as ReportConfigurationMenuItem;
            }
            return item;
        })
    );
    return join(reportMenu);
};
