import {createApi} from '@reduxjs/toolkit/dist/query/react';

import {LookupValue} from 'modules/data/data-types';
import {RequestType} from 'modules/metadata';
import {Attachment} from 'shared/types/files';
import {axiosBaseQuery} from 'store/api/base-query';

export interface ReclaimingInformationAudit {
    id: string;
    statusCode: number;
    statusText: string;
    stage: LookupValue;
    ticketId: string;
    createdDateMainDocument: string;
    lastModifiedDateMainDocument: string;
    fileList: Attachment[];
    createdDate: string;
    lastModifiedDate: string;
}

export type ReclaimingInformationEntity = {
    id: string;
    mainDocNumber?: string;
    downloadDate?: string;
    fileListMainDocument?: Attachment[];
    fileListOrderDocument?: Attachment[];
    docTypeName?: string;
    downloadingAudit?: ReclaimingInformationAudit;
    processingAudit?: ReclaimingInformationAudit;
    responseRequirementAudit?: ReclaimingInformationAudit;
    incomingProcessingAudit?: ReclaimingInformationAudit;
    notificationAudit?: ReclaimingInformationAudit;
    solutionAudit?: ReclaimingInformationAudit;
    audits?: ReclaimingInformationAudit[];
};

export interface GetReclaimingInformationByDocIdArgs {
    id: string;
}

export interface RunSubmitInabilityInfoServiceByDocIdArgs {
    id: string;
}

export const ReclaimingInformationApi = createApi({
    reducerPath: 'reclaimingInformationApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['ReclaimingInformation'],
    endpoints: builder => ({
        getReclaimingInformation: builder.query<ReclaimingInformationEntity, GetReclaimingInformationByDocIdArgs>({
            query: ({id}) => ({
                url: `integration.module/reclaiming.information/${id}`,
            }),
            providesTags: ['ReclaimingInformation'],
        }),
        runSubmitProtocolService: builder.mutation<void, void>({
            query: () => ({
                url: '/v3/submitProtocol',
                method: RequestType.POST,
            }),
        }),
        runSubmitInabilityInfoService: builder.mutation<void, RunSubmitInabilityInfoServiceByDocIdArgs>({
            query: ({id}) => ({
                url: '/v3/submitInabilityInfo',
                params: {inabilityInfoDocId: id},
                method: RequestType.GET,
            }),
        }),
        runSubmitResponseToDocRequestService: builder.mutation<void, void>({
            query: () => {
                const formData = new FormData();
                formData.append('externalServiceName', 'submitResponseToDocRequest');
                formData.append('externalServiceVersion', 'v3');

                return {
                    url: '/integration.module/monitor.services/execute',
                    data: formData,
                    method: RequestType.POST,
                    formData: true,
                };
            },
        }),
    }),
});

export const {
    useGetReclaimingInformationQuery,
    useLazyGetReclaimingInformationQuery,
    useRunSubmitProtocolServiceMutation,
    useRunSubmitInabilityInfoServiceMutation,
    useRunSubmitResponseToDocRequestServiceMutation,
} = ReclaimingInformationApi;
