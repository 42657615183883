import React, {useEffect} from 'react';

import {selectContextRawData} from 'modules/context/context-selector';
import {selectMetadata, loadMetadata} from 'modules/metadata';
import {EntityType} from 'shared/constants/entities';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';

import {Dashboard} from './dashboard';

export interface WidgetSectionProps {
    entityName: string;
    parentEntityName: string;
}

export const DashboardContainer = ({entityName}: WidgetSectionProps) => {
    const dispatch = useAppDispatch();
    const meta = useAppSelector(state => selectMetadata(entityName, EntityType.DASHBOARD)(state));
    const context = useAppSelector(selectContextRawData);

    useEffect(() => {
        (async () => {
            if (!meta) {
                await dispatch(loadMetadata(entityName, EntityType.DASHBOARD));
            }
        })();
    }, []);

    return meta && meta.fields ? (
        <Dashboard
            meta={meta}
            entityName={entityName}
            context={context}
        />
    ) : <></>;
};
