import {useRef} from 'react';

export const useRandomBlueGenerator = () => {
    const colorsCacheRef = useRef<{[color: string]: string}>({});

    const generateBlueShade = () => {
        const rInt = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1) + min);
        const h = 214;
        const s = Math.floor(rInt(60, 80));
        const l = Math.floor(rInt(40, 60));
        return `hsl(${h}, ${s}%, ${l}%)`;
    };

    const generate = (key?: string) => {
        if (!key) return generateBlueShade();
        if (!colorsCacheRef.current[key]) colorsCacheRef.current[key] = generateBlueShade();
        return colorsCacheRef.current[key];
    };

    return {generate};
};
