import {configureStore} from '@reduxjs/toolkit';

import {apiMiddlewares} from 'store/api';
import {authSliceName} from 'store/slices/auth-slice/auth-slice-constants';

import {rootReducer} from './reducers';

const createInitialState = () => {
    const token = localStorage.getItem('token');

    return {
        [authSliceName]: {
            token,
        },
    };
};

export const store = configureStore({
    reducer: rootReducer,
    preloadedState: createInitialState(),
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: {
            ignoreActions: true,
        },
    }).concat(apiMiddlewares),
});
