import React from 'react';

import {useQueryParams} from 'shared/utils/query-params/use-query-params.hook';

import './query-param-link.less';

export interface QueryParamLinkProps {
    params: { name: string; value: string }[];
    children: React.ReactNode;
}

export const QueryParamLink = ({params, children}: QueryParamLinkProps) => {
    const {updateQueryParams} = useQueryParams();
    const handleLinkClick = () => {
        updateQueryParams(params);
    };

    return (
        <div
            className="query-param-link"
            onClick={() => handleLinkClick()}
        >
            {children}
        </div>
    );
};
