import {unwrapResult} from '@reduxjs/toolkit';
import {
    Button, Checkbox, Form, Input,
} from 'antd';
import {useForm} from 'antd/es/form/Form';
import cn from 'classnames';
import {pick} from 'lodash';
import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {DOCUMENT_UPLOAD_TEMPLATES_NAME} from 'components/control-ratio-settings';
import {DocumentUploadTemplateFormName, DocumentUploadTemplateTabKey} from 'components/document-upload-template/document-upload-template-constants';
import {DocumentUploadTemplateContext} from 'components/document-upload-template/document-upload-template-context';
import {createDocumentUploadTemplateWatchers} from 'components/document-upload-template/document-upload-template-utils';
import {CustomSelect, CustomSelectMode} from 'components/form/inputs/custom-select';
import {CustomSelectValueTriggerEvent} from 'components/form/inputs/custom-select/custom-select';
import {fieldValidationResolver} from 'components/form/utils/field-validation-resolver';
import {useFormFieldsManager, WatcherFieldFlag} from 'components/report-configuration/hooks/use-form-fields-manager';
import {DateCell} from 'components/table/columns/date-cell/date-cell';
import {createStringDate} from 'components/table/utils/table.utils';
import {FieldType} from 'modules/metadata';
import {DATE_WITH_TIME_DOTS_SECONDS_12} from 'shared/constants/date-format';
import {ErrorMessages} from 'shared/constants/messages';
import {showMessage} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {
    selectDocumentUploadTemplateData,
} from 'store/slices/document-upload-template/document-upload-template-selectors';
import {
    createDocumentUploadTemplateConfiguration,
    loadDocumentUploadTemplateData, removeDocumentUploadTemplateConfiguration,
    updateDocumentUploadTemplateConfiguration,
} from 'store/slices/document-upload-template/document-upload-template-slice-thunks';
import {normalizeBoolValue} from 'utils/common';

import './document-upload-template-configurator.less';
import {
    ChangeHistoryData,
} from '../../../report-configuration/components/button-change-history/modal-change-history/modal-change-history';
import {ButtonsBar} from '../../../report-configuration/components/buttons-bar';
import {
    DocumentUploadTemplateConfiguratorFormFields,
    documentUploadTemplateConfiguratorMainFields,
} from './document-upload-template-configurator-constansts';
import {convertDocumentUploadTemplateData} from './document-upload-template-configurator-utils';

export const DocumentUploadTemplateConfigurator = () => {
    const [form] = useForm();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const documentUploadTemplateData = useAppSelector(selectDocumentUploadTemplateData);
    const {
        setSelectedTabKey,
        entityName,
        isCreatingNewTemplate,
        templateCode,
    } = useContext(DocumentUploadTemplateContext);

    const [isEditingForm, setIsEditingForm] = useState(isCreatingNewTemplate);
    const [settingTemplateButton, setSettingTemplateButton] = useState(false);
    const [historyData, setHistoryData] = useState<ChangeHistoryData>({});
    const [isUploadTemplateActive, setIsUploadTemplateActive] = useState<boolean>(false);

    const {checkWatcherFlag, applyFieldWatchers} = useFormFieldsManager({
        formInstance: form,
        watchers: {
            [DocumentUploadTemplateConfiguratorFormFields.programSetCode]: [
                {
                    condition: ({formValues}) => {
                        const programCode = formValues?.[DocumentUploadTemplateConfiguratorFormFields.programCode];
                        return (!isEditingForm || programCode !== undefined);
                    },
                    flag: WatcherFieldFlag.isDisabled,
                },
            ],
            [DocumentUploadTemplateConfiguratorFormFields.programCode]: [
                {
                    condition: ({formValues}) => {
                        const programSetCode = formValues?.[
                            DocumentUploadTemplateConfiguratorFormFields.programSetCode
                        ];
                        return (!isEditingForm || programSetCode !== undefined);
                    },
                    flag: WatcherFieldFlag.isDisabled,
                },
            ],
            ...createDocumentUploadTemplateWatchers({
                isEditingForm,
                mainFields: documentUploadTemplateConfiguratorMainFields,
            }),
        },
        triggerDeps: [
            isEditingForm,
        ],
    });

    useEffect(() => {
        if (!documentUploadTemplateData) {
            return;
        }
        const {
            createdBy, createdDate, lastModifiedDate, lastModifiedBy,
        } = documentUploadTemplateData ?? {};
        const dataToSetToForm = pick(
            documentUploadTemplateData,
            [...documentUploadTemplateConfiguratorMainFields,
                DocumentUploadTemplateConfiguratorFormFields.programCode,
                DocumentUploadTemplateConfiguratorFormFields.programSetCode,
            ],
        );
        setHistoryData({
            createdBy: createdBy?.fullName,
            createdDate: createStringDate(createdDate, DATE_WITH_TIME_DOTS_SECONDS_12) as string,
            updatedDate: createStringDate(lastModifiedDate, DATE_WITH_TIME_DOTS_SECONDS_12) as string,
            updatedBy: lastModifiedBy?.fullName,
        });
        setIsUploadTemplateActive(documentUploadTemplateData.active);
        form.setFieldsValue(convertDocumentUploadTemplateData(dataToSetToForm));
        applyFieldWatchers();
    }, [documentUploadTemplateData]);

    const handleFinish = (values: any) => {
        if (!templateCode) return;
        if (isCreatingNewTemplate) {
            const {templateCode: newTemplateCode} = values;
            dispatch(createDocumentUploadTemplateConfiguration({
                entityName,
                data: values,
            }))
                .then(unwrapResult)
                .then(() => {
                    history.replace(`/${DOCUMENT_UPLOAD_TEMPLATES_NAME}?entity=${newTemplateCode}`);
                    showMessage({message: `Шаблон «${newTemplateCode}» успешно создан`});
                }, error => {
                    showMessage({
                        message: error?.response?.data ?? ErrorMessages.UNEXPECTED_ERROR_OCCURRED,
                        isError: true,
                    });
                });
        } else {
            dispatch(
                updateDocumentUploadTemplateConfiguration({
                    entityName,
                    templateCode,
                    data: values,
                }),
            ).then(() => {
                dispatch(loadDocumentUploadTemplateData({
                    entityName,
                    templateCode,
                }));
            });
        }
        setIsEditingForm(false);
    };
    const handleDelete = async () => {
        if (!templateCode) return;
        await dispatch(removeDocumentUploadTemplateConfiguration({
            entityName,
            templateCode,
        })).then(unwrapResult)
            .then(() => {
                showMessage({message: `Шаблон «${templateCode}» успешно удалён`});
                history.push(`/${entityName}`);
            }, error => {
                showMessage({message: error, isError: true});
            });
    };
    return (
        <>
            <ButtonsBar
                templateCode={templateCode}
                isCreating={isCreatingNewTemplate}
                isCreatingDisabled={
                    !documentUploadTemplateData
            && (!isEditingForm
              || (!documentUploadTemplateData && !isCreatingNewTemplate))
                }
                isEditing={isEditingForm}
                setIsEditing={setIsEditingForm}
                titleBack="Назад"
                isEditingDisabled={
                    !documentUploadTemplateData && !isCreatingNewTemplate
                }
                onSubmit={() => {
                    form.validateFields().then(() => {
                        form.submit();
                        setIsEditingForm(false);
                    });
                }}
                onBack={() => history.push(DOCUMENT_UPLOAD_TEMPLATES_NAME)}
                entityName={entityName}
                handleDeleteTemplate={handleDelete}
                changeHistoryData={historyData}
                isUploadTemplateActive={isUploadTemplateActive}
                setIsUploadTemplateActive={setIsUploadTemplateActive}
            />
            <Form
                form={form}
                name={DocumentUploadTemplateFormName.CONFIGURATOR}
                onFinish={handleFinish}
                layout="vertical"
            >
                <div className={cn('configurator-template__section')}>
                    <div className={cn('configurator-template__section__body')}>
                        <div className={cn('configurator-template__section__body__row')}>
                            <Form.Item
                                style={{width: 350}}
                                name={
                                    DocumentUploadTemplateConfiguratorFormFields.templateCode
                                }
                                label="Код шаблона"
                                rules={fieldValidationResolver({
                                    validationRules: [
                                        {
                                            regExp: '^.{0,2048}$',
                                            errorMessage: 'Превышено число символов',
                                        },
                                    ],
                                    fieldMeta: {
                                        type: FieldType.STRING,
                                        label: 'Код шаблона',
                                        isRequired: true,
                                    },
                                })}
                            >
                                <Input
                                    disabled={checkWatcherFlag(
                                        DocumentUploadTemplateConfiguratorFormFields.templateCode,
                                        WatcherFieldFlag.isDisabled,
                                    )}
                                    placeholder="Введите код шаблона"
                                />
                            </Form.Item>
                            <Form.Item
                                style={{width: 350}}
                                name={DocumentUploadTemplateConfiguratorFormFields.templateName}
                                label="Наименование шаблона"
                                rules={fieldValidationResolver({
                                    validationRules: [
                                        {
                                            regExp: '^.{0,2048}$',
                                            errorMessage: 'Превышено число символов',
                                        },
                                    ],
                                    fieldMeta: {
                                        type: FieldType.STRING,
                                        label: 'Наименование шаблона',
                                        isRequired: true,
                                    },
                                })}
                            >
                                <Input
                                    disabled={checkWatcherFlag(
                                        DocumentUploadTemplateConfiguratorFormFields.templateName,
                                        WatcherFieldFlag.isDisabled,
                                    )}
                                    placeholder="Наименование шаблона"
                                />
                            </Form.Item>
                            <Form.Item
                                style={{width: 350}}
                                name={DocumentUploadTemplateConfiguratorFormFields.programCode}
                                label="Исполняемая программа / Набор программ"
                                rules={[
                                    {
                                        required: !checkWatcherFlag(
                                            DocumentUploadTemplateConfiguratorFormFields.programCode,
                                            WatcherFieldFlag.isDisabled,
                                        ),
                                    },
                                ]}
                                validateTrigger="onFocus"
                            >
                                <CustomSelect
                                    handleTriggerEvent={() => {
                                        applyFieldWatchers();
                                    }}
                                    settings={{
                                        showSearch: true,
                                        isDisabled: checkWatcherFlag(
                                            DocumentUploadTemplateConfiguratorFormFields.programCode,
                                            WatcherFieldFlag.isDisabled,
                                        ),
                                        placeholder: 'Выберите тип поля',
                                        url: 'lookupValue/EXECUTABLES_AND_PROGRAM_SETS',
                                        labelPath: 'meaning',
                                        valuePath: 'lookupCode',
                                        triggerOnValueSet: true,
                                        formFieldKey: 'id',
                                        isClearable: true,
                                        formInstance: form,
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                style={{width: 350}}
                                name={DocumentUploadTemplateConfiguratorFormFields.templatePurpose}
                                label="Назначение шаблона"
                                rules={[{required: true}]}
                            >
                                <CustomSelect
                                    handleTriggerEvent={(value, event) => {
                                        setSettingTemplateButton(
                                            normalizeBoolValue(value?.attribute2),
                                        );
                                        if (event === CustomSelectValueTriggerEvent.valueWasSet) {
                                            applyFieldWatchers();
                                        }
                                    }}
                                    settings={{
                                        showSearch: true,
                                        isDisabled: checkWatcherFlag(
                                            DocumentUploadTemplateConfiguratorFormFields.templatePurpose,
                                            WatcherFieldFlag.isDisabled,
                                        ),
                                        placeholder: 'Выберите тип документа',
                                        url: '/lookupValue/TEMPLATE_PURPOSE',
                                        labelPath: 'meaning',
                                        valuePath: 'lookupCode',
                                        triggerOnValueSet: true,
                                        formFieldKey: 'templatePurpose',
                                        formInstance: form,
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                style={{width: 716}}
                                name={DocumentUploadTemplateConfiguratorFormFields.documentTypesCodes}
                                label="Тип документа"
                                rules={[{required: true}]}
                            >
                                <CustomSelect
                                    settings={{
                                        mode: CustomSelectMode.multiple,
                                        isDisabled: checkWatcherFlag(
                                            DocumentUploadTemplateConfiguratorFormFields.documentTypesCodes,
                                            WatcherFieldFlag.isDisabled,
                                        ),
                                        placeholder: 'Выберите тип документа',
                                        url: '/lookupValue/DOCUMENT_TYPE',
                                        labelPath: 'meaning',
                                        valuePath: 'lookupCode',
                                        triggerOnValueSet: true,
                                        formInstance: form,
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                style={{width: 350}}
                                name={DocumentUploadTemplateConfiguratorFormFields.startDate}
                                label="Действия с "
                                rules={[{required: true}]}
                            >
                                <DateCell
                                    format="DD.MM.YYYY"
                                    disabled={checkWatcherFlag(
                                        DocumentUploadTemplateConfiguratorFormFields.startDate,
                                        WatcherFieldFlag.isDisabled,
                                    )}
                                />
                            </Form.Item>
                            <Form.Item
                                style={{width: 350}}
                                name={DocumentUploadTemplateConfiguratorFormFields.endDate}
                                label="Действия по"
                                rules={[{required: true}]}
                            >
                                <DateCell
                                    format="DD.MM.YYYY"
                                    disabled={checkWatcherFlag(
                                        DocumentUploadTemplateConfiguratorFormFields.endDate,
                                        WatcherFieldFlag.isDisabled,
                                    )}
                                />
                            </Form.Item>
                            <Form.Item
                                style={{width: 350, margin: '30px 0'}}
                                name={DocumentUploadTemplateConfiguratorFormFields.active}
                            >
                                <Checkbox
                                    disabled
                                    checked={isUploadTemplateActive}
                                >
                                    Активность
                                </Checkbox>
                            </Form.Item>
                        </div>
                        <div className={cn('configurator-template__section__body__row')}>
                            <Button
                                type="primary"
                                disabled={
                                    !isEditingForm
                    || !settingTemplateButton
                    || isCreatingNewTemplate
                                }
                                onClick={() => {
                                    setSelectedTabKey?.(
                                        DocumentUploadTemplateTabKey.SETTINGS_TEMPLATE_TARGET,
                                    );
                                }}
                            >
                                Шаблон разбора Excel
                            </Button>
                            <Button
                                type="primary"
                                disabled={
                                    !isEditingForm
                                }
                                onClick={() => {
                                    setSelectedTabKey?.(
                                        DocumentUploadTemplateTabKey.SETTING_TEMPLATE_TRANSFER_TARGET,
                                    );
                                }}
                            >
                                Шаблон переноса в целевую таблицу
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    );
};
