import {Button} from 'antd';
import cn from 'classnames';
import React, {useEffect, useState} from 'react';

import {DynamicIcon} from 'components/dynamic-icon';
import {setFilterLoading} from 'modules/data/data-actions';
import {selectFilterEntityData} from 'modules/data/data-selectors';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {selectModalData, openModal} from 'store/slices/modals-slice';

import {TableActionProps} from '../table-action-types';

export const OpenFilterDrawerAction: React.FunctionComponent<TableActionProps> = ({
    meta,
    entityName,
}: TableActionProps) => {
    const {modalName, actionIcon, actionTitle} = meta;
    const dispatch = useAppDispatch();
    const filter = useAppSelector(selectFilterEntityData(entityName));
    const modalData = useAppSelector(selectModalData);
    const [active, setActive] = useState(false);
    useEffect(() => {
        // не нужно ждать пока фильтр в модалке загрузится, так как его изначально нет на странице,
        // и его значения отправляются по кнопке
        if (filter && filter?.loading) {
            dispatch(setFilterLoading({entityName, loading: false}));
        }
    }, [filter?.loading]);
    useEffect(() => {
        if (!modalData?.modalName) setActive(false);
    }, [modalData]);
    const handleClick = () => {
        if (modalName) {
            dispatch(openModal({modalName, entityName: meta?.modalEntityName || entityName}));
            setActive(true);
        }
    };

    return (
        <Button
            className={
                cn(
                    'table-action',
                    'filter-button',
                    meta?.buttonType,
                    active && 'active',
                )
            }
            onClick={handleClick}
        >
            {actionIcon && ((
                <div className="action-icon">
                    <DynamicIcon
                        type={actionIcon}
                        style={{fontSize: 16}}
                    />
                </div>
            ))}
            {actionTitle}
        </Button>
    );
};
