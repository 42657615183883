import {performRequest} from 'shared/utils';

import {UserRoleFunction} from './user-slice-role-functions';

export interface GetUserFunctionsArgs {
    section: string;
}

export interface GetUserFunctionsByDocIdArgs {
    tableReportDocId: string;
    templateCode: string;
}

export const getUserFunctions = ({
    section,
}: GetUserFunctionsArgs) => performRequest<UserRoleFunction[]>({
    url: `user/functions/${section}`,
});

export const getUserFunctionsByDocId = ({
    tableReportDocId,
}: GetUserFunctionsByDocIdArgs) => performRequest<UserRoleFunction[]>({
    url: `user/functions-by-doc/${tableReportDocId}`,
});
