export const AIS_ROUTES = {
    FILE_EXCHANGE_ENTITY_NAME: 'ais3.file.exchange',
    MONITOR_SERVICES: 'ais3.monitor.services',
    DOCUMENTS_REGISTER: 'ais3.doc.journal',
    DOCUMENTS_BY_TAX_REGISTER: 'ais3.doc.journal.by.tax',
    RECLAIMING_INFORMATION: 'ais3.reclaiming.information',
    DOCUMENT_CODES: 'ais3.document.codes',
};
export const AisDocumentTypeCodesEntityName = {
    DOCUMENT_TYPE_CODES_DIRECTORY: 'ais3.document.type.codes.directory',
    PRIMARY_DOCUMENTS_CODES_SETTINGS: 'ais3.primary.documents.codes.settings',
};
export const AisDocumentDocumentCodesTabKey = {
    REFERENCE_BOOK_DOCUMENT_CODES: 'referenceBookDocumentCodes',
    SETTING_DOCUMENT_CODES: 'settingDocumentCodes',
};

export const DocumentCodesFormTitles = {
    [AisDocumentDocumentCodesTabKey.REFERENCE_BOOK_DOCUMENT_CODES]: 'Справочник кодов видов документов',
    [AisDocumentDocumentCodesTabKey.SETTING_DOCUMENT_CODES]: 'Настройка кодов для первичных документов',
} as const;

export const AIS_FILE_EXCHANGE_ENTITY_NAME_SECTION_CODE = `app.${AIS_ROUTES.FILE_EXCHANGE_ENTITY_NAME}.title`;
export const AIS_ENTITY_NAME = 'ais3';
