import {TIconType} from 'components/dynamic-icon/dynamic-icon';
import {EntityType} from 'shared/constants/entities';

import {IS_MENU_ITEM_HIDDEN_KEY} from './menu-slice-constants';

export interface MenuSliceState {
    selectedMenuItem: MenuStructureItem | undefined;

    isMenuOpen?: boolean;
    searchLine: string | undefined;

    structure: MenuStructure | null;
    structureIdMap: Record<string, MenuStructureItem> | null;

    meta: {
        openId: string | undefined;
    };
}

export enum MenuItemType {
    FUNCTION_ITEM = 'FUNCTION_ITEM',
    MENU_ITEM = 'MENU_ITEM'
}

export interface MenuResponseItem {
    itemTitle: string;
    itemKey: string;
    parentId: string | null;
    id: string;
    itemType: MenuItemType;
    icon: TIconType | null;
    entityName: string;
    entityType?: EntityType;
    url: string;
    docId: string;
    templateCode: string;
    parameters: any;
}

export type MenuResponse = MenuResponseItem[];

export interface MenuStructureItem extends MenuResponseItem {
    [IS_MENU_ITEM_HIDDEN_KEY]?: boolean;
    children?: MenuStructureItem[];
}

export interface GenerateRouteArgs extends MenuStructureItem {
    parentEntityName?: string;
    childEntityName?: string;
}

export type MenuStructure = MenuStructureItem[];
