import {RequestType} from 'modules/metadata';
import {performRequest} from 'shared/utils';

import {AisDashboardCode} from './ais-dashboards-constants';
import {AisDashboardElementDto} from './ais-dashboards-types';

export type FetchAisDashboardDataParams = {
    code: AisDashboardCode;
    year?: number;
    month?: number | null;
    day?: number | null;
    periodId?: number;
    counterparty?: string;
    hasAvailableFNS?:boolean;
    startDate?: string;
    endDate?: string;
    taxPeriodId?: number;
    organizationId?: number;
}

export type FetchAisDashboardDataResponseDto = {
    code: AisDashboardCode;
    meaning: string;
    total: number;
    elements: AisDashboardElementDto[];
}

export const fetchAisDashboardData = (params: FetchAisDashboardDataParams) => performRequest<
FetchAisDashboardDataResponseDto>({
    url: 'ais/dashboard',
    method: RequestType.GET,
    params,
});
