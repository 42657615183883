import React, {ComponentType} from 'react';
import {Redirect, RouteProps, Route} from 'react-router-dom';

import {URL_LOGIN} from 'shared/constants/urls';
import {useAppSelector} from 'store/config/hooks';
import {selectAuthToken} from 'store/slices/auth-slice';

interface ProtectedRouteProps extends RouteProps {
    component: ComponentType<any>;
}

export const ProtectedRoute: React.FunctionComponent<ProtectedRouteProps> = ({
    component: Component,
    ...props
}: ProtectedRouteProps) => {
    const isAuthorized = useAppSelector(state => !!selectAuthToken(state));

    return (
        <Route
            {...props}
            render={componentProps => (isAuthorized
                ? <Component {...componentProps} />
                : <Redirect to={URL_LOGIN} />)}
        />
    );
};
