import {ActionReducerMapBuilder} from '@reduxjs/toolkit';

import {ReportConfigurationSliceState} from '../report-configuration-types';
import {loadTableReportColumnsLookup, loadTableReportConfigurationFilterConditionsLookup} from './dd-lookups-thunks';

export const createDdLookupsExtraReducer = (
    builder: ActionReducerMapBuilder<ReportConfigurationSliceState>,
) => {
    builder.addCase(loadTableReportColumnsLookup.fulfilled, (state, {payload, meta}) => {
        state.ddLookups.tableColumns[meta.arg.templateCode] = payload;
    });
    builder.addCase(loadTableReportConfigurationFilterConditionsLookup.fulfilled, (state, {payload}) => {
        state.ddLookups.filters = payload;
    });
};
