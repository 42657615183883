import {PushpinFilled, PushpinOutlined} from '@ant-design/icons';
import {Badge, Button, Tooltip} from 'antd';
import Column from 'antd/es/table/Column';
import ColumnGroup from 'antd/es/table/ColumnGroup';
import {SortOrder} from 'antd/es/table/interface';
import cn from 'classnames';
import React from 'react';

import {TableColumnFilter} from 'components/@common/widgets/custom-table/table-column-filter/table-column-filter';
import {
    GetTableColumnFilterDataFunction,
    GetTableColumnFilterInfoFunction,
    SetTableColumnFilterDataFunction,
} from 'components/@common/widgets/custom-table/table-column-filter/use-table-column-filter';
import {TableColumnMenu} from 'components/@common/widgets/custom-table/table-column-menu';
import {GetSortDataFunction, SetSortDataFunction} from 'components/@common/widgets/custom-table/table-column-menu/hooks/use-table-column-sort';
import {
    TableHeaderCell,
    TableHeaderStructure,
} from 'components/report-configuration/tabs/report-table-settings/modals/excel-header-structure-loader-modal/excel-header-structure-loader-modal.types';
import {
    filterHiddenChildren,
} from 'components/report-configuration/tabs/report-table-settings/modals/excel-header-structure-loader-modal/excel-header-structure-loader-modal.utils';
import {ReportColumnContent} from 'components/table-report/components/table-report-column-content';
import {LoadTableReportPageDataFunction} from 'components/table-report/hooks/use-control';
import {TableRowAttachements} from 'components/table-report/table-row-attachments/table-row-attachments';
import {
    ApplyFormatSettingsToRowArgs,
    FormatSettingsApplicationResult,
} from 'components/table-report/utils/table-report-utils';
import {ReactComponent as MoreIcon} from 'shared/assets/more.svg';
import {StateSetter} from 'shared/types/generics';
import {store} from 'store/config';
import {AppState} from 'store/config/types';
import {
    ReportConfigurationRowFormat,
} from 'store/slices/report-configuration-slice/report-configuration-dto';
import {TABLE_REPORT_URL_FIELD_NAME} from 'store/slices/table-report-slice/table-report-slice-constants';
import {selectLinkDtoToColumnMapping} from 'store/slices/table-report-slice/table-report-slice-selectors';
import {
    TableReportColumn,
    TableReportDrillDown,
    TableReportExtendedConfiguration,
} from 'store/slices/table-report-slice/table-report-slice-types';

import {PushWithBreadcrumbsFunction} from '../hooks/use-breadcrumbs-controller';
import {
    NonbreakingSpaces,
    extractIndexesFromKey,
    getColumnHeaderWidthStyles,
    getColumnWidthStyles,
    isColumnTypeOfString,
    isCustomColumn,
    getMultiRowHeaderCellStyle,
    getTreeMaxColumnIndex,
    addLinkColumnsToHeaderStructure,
} from './table-report-columns-utils';

import './table-report-columns.less';

interface TableReportColumnProps {
    docId: string | null;
    enabledRowAttachments: boolean | undefined;
    enabledRowComments: boolean | undefined;
    enabledRowRequests: boolean | undefined;

    createTableColumnFilterDataGetter: (columnKey: string) => GetTableColumnFilterDataFunction;
    createTableColumnFilterDataSetter: (columnKey: string) => SetTableColumnFilterDataFunction;
    getTableColumnFilterInfo: GetTableColumnFilterInfoFunction;

    createSortDataGetter: (columnKey: string) => GetSortDataFunction;
    createSortDataSetter: (columnKey: string) => SetSortDataFunction;

    headerFontSize: string;
    tableFontSize: string;
    hideColumn: (columnKey: string) => void;
    applyFormatSettingsToRow: (
        {dataIndex, record, rowFormats}: ApplyFormatSettingsToRowArgs,
    ) => FormatSettingsApplicationResult;
    drillDowns: TableReportDrillDown[] | undefined;
    pushWithBreadcrumbs: PushWithBreadcrumbsFunction;
    setIsDDModalVisible: StateSetter<boolean>;
    setDDModalEntries: StateSetter<TableReportDrillDown[] | undefined>;
    rowFormats: ReportConfigurationRowFormat[];
    loadTableReportData: LoadTableReportPageDataFunction;
    tableReportAttributes?: Record<string, string>;
    reportGroups?: TableReportExtendedConfiguration['reportGroups'];
    handlePinButtonClick?: () => void;
    isTableHeaderPinned?: boolean;
    enabledPinHeaderButton?: boolean;
    enabledMenu?: boolean;
}

interface TableReportHeaderProps {
    headerStructure: TableHeaderStructure | undefined;
    columns: TableReportColumn[] | undefined;
    columnProps: TableReportColumnProps;
}

type SingleRowHeaderProps = Pick<TableReportHeaderProps, 'columns' | 'columnProps'>;

type MultiRowHeaderArgs = {
    node: TableHeaderCell;
    parentDataIndex?: string;
    firstRowLastColumnIndex: number | undefined;
    maxColumnIndex: number | undefined;
} & Pick<TableReportHeaderProps, 'columns' | 'columnProps'>;

export const convertHeaderRowIntoColumnGroup = ({
    node,
    columns,
    columnProps,
    parentDataIndex,
    firstRowLastColumnIndex,
    maxColumnIndex,
}: MultiRowHeaderArgs) => {
    const rowSpan = Number(node?.cellHeight) > 1 ? Number(node?.cellHeight) : undefined;
    const cellColumn = columns?.find(c => c.dataIndex === (node?.attribute ?? parentDataIndex));
    const dataIndex = (
        node?.attribute ? cellColumn?.dataIndex : parentDataIndex
    ) ?? undefined;
    const title = node?.attribute
        ? cellColumn?.title
        : node?.title;

    // todo: find a better way to extract indexes
    const {row: cellRowIndex, column: cellColumnIndex} = extractIndexesFromKey(node.key) ?? {};

    const {
        createSortDataGetter,
        createSortDataSetter,
        createTableColumnFilterDataGetter,
        createTableColumnFilterDataSetter,
        getTableColumnFilterInfo,

        headerFontSize,
        tableFontSize,
        hideColumn,
        applyFormatSettingsToRow,
        drillDowns,
        pushWithBreadcrumbs,
        setIsDDModalVisible,
        setDDModalEntries,
        rowFormats,
        tableReportAttributes,
        reportGroups,
    } = columnProps;

    const cellStyle = getMultiRowHeaderCellStyle({
        cellRowIndex,
        cellColumnIndex,
        firstRowLastColumnIndex,
        maxColumnIndex,
    });

    const linkDtoToColumnMapping = selectLinkDtoToColumnMapping(store.getState() as AppState);

    const commonRenderProps = {
        className: cellStyle.borderClassname,
        key: node?.key,
        dataIndex,
        rowSpan: Number(node?.cellHeight) === -1 ? 0 : rowSpan,
        title: (() => {
            const styles = getColumnHeaderWidthStyles(cellColumn);

            return (
                <div
                    className={cn('table-report__table__column-title')}
                    style={{
                        fontSize: headerFontSize,
                    }}
                >
                    <div style={{...styles, flexGrow: 1}}>
                        <NonbreakingSpaces>
                            {title}
                        </NonbreakingSpaces>
                    </div>
                    {(() => {
                        if (!dataIndex) return undefined;

                        const filterInfo = getTableColumnFilterInfo(dataIndex);

                        return !parentDataIndex && (
                            <Badge
                                count={filterInfo.rulesCount}
                                size="small"
                            >
                                <TableColumnFilter
                                    columnName={dataIndex}
                                    getTableColumnFilterData={createTableColumnFilterDataGetter(dataIndex)}
                                    setTableColumnFilterData={createTableColumnFilterDataSetter(dataIndex)}
                                    isApplied={filterInfo.isApplied}
                                />
                            </Badge>
                        );
                    })()}
                </div>
            );
        })(),
        onHeaderCell: () => ({style: cellStyle?.style}),
        sortDirections: ['ascend', 'descend', 'ascend'] as SortOrder[],
    };

    const dataIndexDependentRenderProps = {
        sorter: (dataIndex && !parentDataIndex
            ? (() => {
                const columnSortInfo = createSortDataGetter(dataIndex)();
                if (columnSortInfo.order && columnSortInfo.multiple) {
                    return {
                        // compare: createDefaultSorter(column.dataIndex),
                        compare: () => 0,
                        multiple: columnSortInfo.multiple,
                    };
                }
                return undefined;
            })()
            : undefined
        ),
        sortOrder: (dataIndex && !parentDataIndex
            ? (() => {
                const columnSortInfo = createSortDataGetter(dataIndex)();
                return columnSortInfo.order ? columnSortInfo.order : null;
            })()
            : undefined
        ),
        filterIcon: (dataIndex && !parentDataIndex && !isCustomColumn(dataIndex)
            ? (
                <span className="anticon anticon-filter">
                    <MoreIcon
                        height={14}
                        width={14}
                        fill="currentColor"
                    />
                </span>
            )
            : undefined
        ),
        filterDropdown: (dataIndex && !parentDataIndex && !isCustomColumn(dataIndex)
            ? (() => (
                <TableColumnMenu
                    setSortData={createSortDataSetter(dataIndex)}
                    getSortData={createSortDataGetter(dataIndex)}
                    handleHideColumnClick={() => hideColumn(dataIndex)}
                    hideOptions={
                        dataIndex.includes(TABLE_REPORT_URL_FIELD_NAME)
                         && !linkDtoToColumnMapping?.[dataIndex]
                    }
                />
            ))
            : undefined
        ),
        render: dataIndex
            ? ((val: any, record: any, rowIndex: number) => {
                const formatSettingsApplicationResult = applyFormatSettingsToRow({
                    dataIndex,
                    record,
                    rowFormats,
                    reportGroups,
                });

                return {
                    props: {
                        className: cn({
                            'table-report-string-column': isColumnTypeOfString(
                                tableReportAttributes,
                                cellColumn,
                            ),
                        }),
                        style: {
                            background: formatSettingsApplicationResult.background,
                            ...getColumnWidthStyles(
                                tableReportAttributes,
                                cellColumn,
                            ),
                        },
                    },
                    children: cellColumnIndex !== undefined && (
                        <div
                            className="table-report__column"
                            style={{fontSize: tableFontSize}}
                        >
                            <ReportColumnContent
                                {...{
                                    record,
                                    value: val,
                                    columnIndex: cellColumnIndex,
                                    rowIndex,
                                    formatSettingsApplicationResult,
                                    column: cellColumn,
                                    tableFontSize,
                                    pushWithBreadcrumbs,
                                    drillDowns,
                                    setDDModalEntries,
                                    setIsDDModalVisible,
                                }}
                            />
                        </div>
                    ),
                };
            }) : undefined,
    };

    if (node?.children?.length) {
        return (
            <ColumnGroup
                {...commonRenderProps}
                {...dataIndexDependentRenderProps}
                render={undefined}
            >
                {node?.children?.map(child => (
                    convertHeaderRowIntoColumnGroup({
                        node: child,
                        columns,
                        columnProps,
                        parentDataIndex: dataIndex,
                        firstRowLastColumnIndex,
                        maxColumnIndex,
                    })))}
            </ColumnGroup>
        );
    }
    return (
        <Column
            {...commonRenderProps}
            {...dataIndexDependentRenderProps}
        />
    );
};

const renderMultiRowHeader = ({headerStructure, columns, columnProps}: TableReportHeaderProps) => {
    if (!headerStructure) {
        return undefined;
    }

    const {column: firstRowLastColumnIndex} = (
        extractIndexesFromKey(headerStructure[headerStructure.length - 1]?.key) ?? {}
    );
    const maxColumnIndex = getTreeMaxColumnIndex(headerStructure);

    const headerStructureWithLinks = addLinkColumnsToHeaderStructure(headerStructure, columns);

    return headerStructureWithLinks?.map(node => {
        const innerVisibleChildren = filterHiddenChildren(node);
        // проверяем что столбец в columns, на который ссылается node, не скрыт
        const visibleColumnsDataIndexes = columns?.map(c => c.dataIndex);
        const isNodeVisible = !!(
            (node.attribute && visibleColumnsDataIndexes?.includes(node.attribute))
            || innerVisibleChildren?.find(ch => ch.attribute && visibleColumnsDataIndexes?.includes(ch.attribute))
        );

        if (isNodeVisible || (innerVisibleChildren && innerVisibleChildren?.length >= 2)) {
            return convertHeaderRowIntoColumnGroup({
                node,
                columns,
                columnProps,
                firstRowLastColumnIndex,
                maxColumnIndex,
            });
        }

        return null;
    });
};

const renderSingleRowHeader = ({columns, columnProps}: SingleRowHeaderProps) => {
    const {
        createTableColumnFilterDataGetter,
        createTableColumnFilterDataSetter,
        getTableColumnFilterInfo,

        createSortDataGetter,
        createSortDataSetter,

        headerFontSize,
        tableFontSize,
        hideColumn,
        applyFormatSettingsToRow,
        drillDowns,
        pushWithBreadcrumbs,
        setIsDDModalVisible,
        setDDModalEntries,
        rowFormats,
        tableReportAttributes,
        reportGroups,
        enabledMenu = false,
    } = columnProps;

    const linkDtoToColumnMapping = selectLinkDtoToColumnMapping(store.getState() as AppState);

    return (
        <>
            {columns?.map((column, columnIndex) => (
                <>
                    <Column
                        title={(() => {
                            const {title} = column;

                            const styles = getColumnHeaderWidthStyles(column);

                            const filterInfo = getTableColumnFilterInfo(column.dataIndex);

                            return (
                                <div
                                    className={cn('table-report__table__column-title')}
                                    style={{
                                        fontSize: headerFontSize,
                                    }}
                                >
                                    <div style={{...styles, flexGrow: 1}}>
                                        <NonbreakingSpaces>
                                            {title}
                                        </NonbreakingSpaces>
                                    </div>
                                    {!enabledMenu && (
                                        <Badge
                                            count={filterInfo.rulesCount}
                                            size="small"
                                        >
                                            <TableColumnFilter
                                                columnName={column.dataIndex}
                                                getTableColumnFilterData={createTableColumnFilterDataGetter(
                                                    column.dataIndex,
                                                )}
                                                setTableColumnFilterData={createTableColumnFilterDataSetter(
                                                    column.dataIndex,
                                                )}
                                                isApplied={filterInfo.isApplied}
                                            />
                                        </Badge>
                                    )}
                                </div>
                            );
                        })()}
                        sortDirections={['ascend', 'descend', 'ascend']}
                        sorter={(() => {
                            const columnSortInfo = createSortDataGetter(column.dataIndex)();
                            if (columnSortInfo.order && columnSortInfo.multiple) {
                                return {
                                    compare: () => 0,
                                    multiple: columnSortInfo.multiple,
                                };
                            }
                            return undefined;
                        })()}
                        sortOrder={(() => {
                            const columnSortInfo = createSortDataGetter(column.dataIndex)();
                            return columnSortInfo.order ? columnSortInfo.order : null;
                        })()}
                        dataIndex={column.dataIndex}
                        key={column.key || column.dataIndex}
                        filterIcon={!isCustomColumn(column.dataIndex)
                          && column.dataIndex
                          && (
                              <span className="anticon anticon-filter">
                                  <MoreIcon
                                      height={14}
                                      width={14}
                                      fill="currentColor"
                                  />
                              </span>
                          )}
                        filterDropdown={!isCustomColumn(column.dataIndex)
                          && column.dataIndex
                          && (
                              () => (
                                  <TableColumnMenu
                                      setSortData={createSortDataSetter(column.dataIndex)}
                                      getSortData={createSortDataGetter(column.dataIndex)}
                                      hideOptions={
                                          column.dataIndex.includes(TABLE_REPORT_URL_FIELD_NAME)
                                        && !linkDtoToColumnMapping?.[column.dataIndex]
                                      }
                                      handleHideColumnClick={() => hideColumn(column.dataIndex)}
                                  />
                              )
                          )}
                        render={(val, record, rowIndex) => {
                            const formatSettingsApplicationResult = applyFormatSettingsToRow({
                                dataIndex: column.dataIndex,
                                record,
                                rowFormats,
                                reportGroups,
                            });

                            return {
                                props: {
                                    className: cn({
                                        'table-report-string-column': isColumnTypeOfString(
                                            tableReportAttributes,
                                            column,
                                        ),
                                    }),
                                    style: {
                                        background: formatSettingsApplicationResult.background,
                                        ...getColumnWidthStyles(
                                            tableReportAttributes,
                                            column,
                                        ),
                                    },
                                },
                                children: (
                                    <div
                                        className="table-report__column"
                                    >
                                        <ReportColumnContent
                                            {...{
                                                record,
                                                value: val,
                                                columnIndex,
                                                rowIndex,
                                                formatSettingsApplicationResult,
                                                column,
                                                tableFontSize,
                                                pushWithBreadcrumbs,
                                                drillDowns,
                                                setDDModalEntries,
                                                setIsDDModalVisible,
                                            }}
                                        />
                                    </div>
                                ),
                            };
                        }}
                    />
                </>
            ))}
        </>
    );
};

export const renderColumns = ({headerStructure, columns, columnProps}: TableReportHeaderProps) => {
    const {
        enabledRowAttachments,
        enabledRowComments,
        enabledRowRequests,
        loadTableReportData,
        docId,
        enabledPinHeaderButton,
        handlePinButtonClick,
        isTableHeaderPinned,
    } = columnProps;

    const rowAttachmentsComponent = (enabledRowAttachments || enabledRowComments || enabledRowRequests) && (
        <Column
            width={80}
            render={(val, record) => (
                <TableRowAttachements
                    loadReportPageData={loadTableReportData}
                    rowRecord={record}
                    docId={docId}
                />
            )}
        />
    );

    const pinHeaderButton = enabledPinHeaderButton && (
        <Column
            fixed="left"
            width={0}
            title={(() => (
                <Button
                    onClick={handlePinButtonClick}
                    type="link"
                    className="pin-button"
                >
                    <Tooltip
                        placement="rightTop"
                        trigger="hover"
                        title={isTableHeaderPinned ? 'Открепить шапку' : 'Закрепить шапку'}
                        getPopupContainer={t => t.parentElement as HTMLElement}
                    >
                        {isTableHeaderPinned ? (
                            <PushpinFilled
                                rotate={-90}
                                style={{fontSize: '140%'}}
                            />
                        ) : (
                            <PushpinOutlined
                                rotate={-45}
                                style={{fontSize: '140%'}}
                            />
                        )}
                    </Tooltip>
                </Button>
            ))()}
        />
    );

    if (headerStructure?.length) {
        return (
            <>
                {pinHeaderButton}
                {rowAttachmentsComponent}
                {renderMultiRowHeader({headerStructure, columns, columnProps})}
            </>
        );
    }

    return (
        <>
            {pinHeaderButton}
            {rowAttachmentsComponent}
            {renderSingleRowHeader({columns, columnProps})}
        </>
    );
};
