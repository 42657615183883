import {FieldType, RequestType} from 'modules/metadata';

import {DEFAULT_ACTIONS} from '../form/form.actions';
import {FORM_FIELDS} from '../form/form.fields';
import {DATA_KEY_FOR_FILTER_FIELDS} from '../metadata.constants';

export const APPOINTMENT = {
    title: 'Назначить ответственного',
    name: 'appointment-user-meta',
    preFillingOptions: {
        shouldUpdateParentFormAfterSaving: true,
        parentEntityName: 'requests',
        matchingFields: [
            {
                from: 'id',
                to: 'topicId',
            },
        ],
    },
    fields: [
        {
            key: 'userId',
            label: 'ID пользователя',
            type: FieldType.REFERENCE,
            referenceUrl: '/userList',
            referenceParamsKeys: [{
                from: 'topicId',
                to: 'requestId',
            }],
            path: {
                label: 'fullName',
                value: 'id',
            },
        },
        {
            key: 'topicId',
            label: 'ID раздела',
            type: FieldType.STRING,
            isHidden: true,
        },
    ],
    actions: [
        {
            ...DEFAULT_ACTIONS.BUTTON_SAVE,
            visible: 'default',
            shouldDefaultRequestSave: false,
            requestType: RequestType.POST,
            referenceUrl: '/appointment/topic',
        },
    ],
};

export const UPDATE_RESPONSE_DATE = {
    title: 'Исправление даты срока ответа',
    name: 'update-response-date',
    preFillingOptions: {
        parentEntityName: 'requests',
        matchingFields: [
            {
                from: 'id',
                to: 'id',
            },
        ],
    },
    fields: [
        {
            key: 'dataStr',
            label: 'ID пользователя',
            type: FieldType.DATE,
        },
        {
            key: 'id',
            label: 'ID запроса',
            type: FieldType.STRING,
            isHidden: true,
        },
    ],
    actions: [
        {
            ...DEFAULT_ACTIONS.BUTTON_SAVE,
            visible: 'default',
            shouldDefaultRequestSave: false,
            requestType: RequestType.POST,
            referenceUrl: '/update-response-date',
        },
    ],
};

export const EXTENSION_REQUEST = {
    title: 'Запрос на продление срока',
    name: 'extension-request',
    preFillingOptions: {
        parentEntityName: 'requests',
        shouldUpdateParentFormAfterSaving: true,
        shouldRemoteData: true,
        matchingFields: [
            {
                from: 'idRequestComment',
                to: 'parentId',
            },
            {
                from: 'topicRequestType',
                to: 'topicRequestType',
            },
        ],
    },
    fields: [
        {
            ...FORM_FIELDS.REQUESTS.subject,
            disabled: true,
        },
        {
            ...FORM_FIELDS.REQUESTS.category,
            editableViewField: true,
        },
        {
            key: 'reasonType',
            label: 'Причина',
            type: FieldType.REFERENCE,
            referenceUrl: '/lookupValue/REASON_TYPE',
            editableViewField: true,
            isRequired: true,
        },
        {
            ...FORM_FIELDS.REQUESTS.topicRequestType,
            isHidden: true,
            disabled: true,
        },
        {
            ...FORM_FIELDS.REQUESTS.addressees,
            editableViewField: true,
        },
        {
            key: 'prolongDate',
            label: 'Запрашиваемый срок для предоставления ответа',
            type: FieldType.DATE,
            editableViewField: true,
        },
        {
            ...FORM_FIELDS.REQUESTS.prolongRequest,
            defaultValue: 'Y',
            isHidden: true,
        },
        {
            ...FORM_FIELDS.REQUESTS.parentId,
            isHidden: true,
        },
        {
            ...FORM_FIELDS.REQUESTS.content,
            editableViewField: true,
        },
        {
            ...FORM_FIELDS.REQUESTS.files,
            editableViewField: true,
        },
    ],
    actions: [
        {
            ...DEFAULT_ACTIONS.BUTTON_SAVE,
            requestType: RequestType.POST,
            referenceUrl: '/requests/form',
            shouldDefaultRequestSave: true,
        },
    ],
};

export const ANSWER = {
    title: 'Сформировать ответ',
    name: 'answer',
    dataKeyForFilterFields: DATA_KEY_FOR_FILTER_FIELDS,
    isEditable: false,
    preFillingOptions: {
        parentEntityName: 'requests',
        shouldRemoteData: true,
        shouldUpdateParentFormAfterSaving: true,
    },
    fields: [],
    actions: [
        {
            ...DEFAULT_ACTIONS.BUTTON_SAVE,
            requestType: RequestType.POST,
            referenceUrl: '/requests/form',
            shouldDefaultRequestSave: true,
        },
    ],
};

export const EDIT_ANSWER = {
    ...ANSWER,
    title: 'Редактировать ответ',
    name: 'edit-answer',
    preFillingOptions: {
        parentEntityName: 'requests',
        shouldRemoteData: true,
        shouldUpdateParentFormAfterSaving: true,
    },
    actions: [
        {
            ...DEFAULT_ACTIONS.BUTTON_SAVE,
            requestType: RequestType.POST,
            referenceUrl: '/requests/form',
            shouldDefaultRequestSave: true,
        },
    ],
};

export const EDIT_ANSWER_RESPONSE = {
    ...ANSWER,
    title: 'Редактировать ответ',
    name: 'edit-answer-response',
    preFillingOptions: {
        parentEntityName: 'requests',
        shouldRemoteData: true,
        shouldUpdateParentFormAfterSaving: true,
    },
    actions: [
        {
            ...DEFAULT_ACTIONS.BUTTON_SAVE,
            requestType: RequestType.POST,
            referenceUrl: '/requests/form',
            shouldDefaultRequestSave: true,
        },
    ],
};

export const LEAVE_COMMENT = {
    title: 'Оставить комментарий',
    name: 'leave-comment',
    preFillingOptions: {
        parentEntityName: 'requests',
        shouldRemoteData: true,
        fieldNameForParamsRequest: 'idRequestComment',
        shouldUpdateParentFormAfterSaving: true,
    },
    fields: [
        {
            ...FORM_FIELDS.REQUESTS.topicRequestType,
            editableViewField: false,
            disabled: true,
        },
        {
            ...FORM_FIELDS.REQUESTS.subject,
            editableViewField: false,
            disabled: true,
        },
        {
            ...FORM_FIELDS.REQUESTS.category,
            editableViewField: true,
            disabled: true,
        },
        {
            ...FORM_FIELDS.REQUESTS.addressees,
            editableViewField: true,
        },
        {
            ...FORM_FIELDS.REQUESTS.content,
            editableViewField: true,
        },
        {
            ...FORM_FIELDS.REQUESTS.files,
            editableViewField: true,
        },
    ],
    actions: [
        {
            ...DEFAULT_ACTIONS.BUTTON_SAVE,
            requestType: RequestType.POST,
            referenceUrl: '/requests/form',
            shouldDefaultRequestSave: true,
        },
    ],
};

export const LEAVE_COMMENT_RESPONSE = {
    title: 'Оставить комментарий',
    name: 'leave-comment-response',
    preFillingOptions: {
        parentEntityName: 'response',
        shouldRemoteData: true,
        fieldNameForParamsRequest: 'id',
        shouldUpdateParentFormAfterSaving: true,
    },
    fields: [
        {
            ...FORM_FIELDS.REQUESTS.requestType,
            editableViewField: false,
            disabled: true,
        },
        {
            ...FORM_FIELDS.REQUESTS.messageSubject,
            editableViewField: false,
            disabled: true,
        },
        {
            ...FORM_FIELDS.REQUESTS.categoryCode,
            editableViewField: true,
            disabled: true,
        },
        {
            ...FORM_FIELDS.REQUESTS.addressees,
            editableViewField: true,
        },
        {
            ...FORM_FIELDS.REQUESTS.content,
            editableViewField: true,
        },
        {
            ...FORM_FIELDS.REQUESTS.files,
            editableViewField: true,
        },
    ],
    actions: [
        {
            ...DEFAULT_ACTIONS.BUTTON_SAVE,
            requestType: RequestType.POST,
            referenceUrl: '/requests/form',
            shouldDefaultRequestSave: true,
        },
    ],
};

export const MODAL_META_FOR_ENTITY_NAME = {
    [APPOINTMENT.name]: APPOINTMENT,
    [UPDATE_RESPONSE_DATE.name]: UPDATE_RESPONSE_DATE,
    [EXTENSION_REQUEST.name]: EXTENSION_REQUEST,
    [ANSWER.name]: ANSWER,
    [EDIT_ANSWER.name]: EDIT_ANSWER,
    [EDIT_ANSWER_RESPONSE.name]: EDIT_ANSWER_RESPONSE,
    [LEAVE_COMMENT.name]: LEAVE_COMMENT,
    [LEAVE_COMMENT_RESPONSE.name]: LEAVE_COMMENT_RESPONSE,
};
