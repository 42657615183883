import {LeftOutlined, RightOutlined} from '@ant-design/icons/lib';
import {Button} from 'antd';
import React from 'react';

import {PageJumper} from 'components/@common/widgets/page-jumper';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';
import {ReportConfigurationPosition} from 'store/slices/report-configuration-slice/report-configuration-dto';
import {tableReportSliceActions} from 'store/slices/table-report-slice/table-report-slice';
import {loadTableReportPageData} from 'store/slices/table-report-slice/table-report-slice-thunks';
import {ReportPagination} from 'store/slices/table-report-slice/table-report-slice-types';

import './table-report-pagination.less';

interface TableReportPaginationProps {
    pagination: ReportPagination;
    paginationPosition?: ReportConfigurationPosition;
    atLeastOneFilterIsActive?: boolean;
}

export const TableReportPagination = ({
    pagination,
    paginationPosition,
    atLeastOneFilterIsActive,
}: TableReportPaginationProps) => {
    const dispatch = useAppDispatch();
    const {current: currentPage, total: totalPages} = pagination;

    const {setGlobalSearchLine} = tableReportSliceActions;

    const isLoadingPageData = useAppSelector(s => selectIsThunkPending(s, loadTableReportPageData.typePrefix));

    if (paginationPosition === ReportConfigurationPosition.BOTTOM) return null;
    if (totalPages === undefined && atLeastOneFilterIsActive) return null;
    if (currentPage !== undefined && totalPages) {
        const isLeftButtonDisabled = isLoadingPageData || (totalPages ? currentPage <= 0 : false);
        const isRightButtonDisabled = isLoadingPageData || (totalPages ? currentPage + 1 >= totalPages : false);

        return (
            <div className="table-report-pagination border-left pl-16 pr-16">
                <span>
                    {currentPage + 1} из {totalPages}
                </span>

                <Button
                    className="table-report-pagination__button"
                    icon={<LeftOutlined className="table-report-pagination__button-icon" />}
                    onClick={() => {
                        if (isLeftButtonDisabled) return;
                        if (currentPage !== undefined) {
                            pagination.onChange(currentPage - 1);
                            dispatch(setGlobalSearchLine(''));
                        }
                    }}
                    disabled={isLeftButtonDisabled}
                />
                <Button
                    className="table-report-pagination__button"
                    icon={<RightOutlined className="table-report-pagination__button-icon" />}
                    onClick={() => {
                        if (isRightButtonDisabled) return;
                        if (currentPage !== undefined) {
                            pagination.onChange(currentPage + 1);
                            dispatch(setGlobalSearchLine(''));
                        }
                    }}
                    disabled={isRightButtonDisabled}
                />
                <PageJumper
                    inputWidth="42px"
                    size="36px"
                    pagination={pagination}
                    startsFromZero
                />
            </div>
        );
    }
    return null;
};
