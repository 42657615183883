import {Card} from 'antd';
import cn from 'classnames';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {useAppSelector} from 'store/config/hooks';
import {
    documentUploadTemplateSliceActions,
} from 'store/slices/document-upload-template/document-upload-template-slice';
import {
    createDocumentUploadTemplateConfiguration,
    loadDocumentUploadTemplateData, loadSettingTemplateTargetColumns,
    removeDocumentUploadTemplateConfiguration,
    updateDocumentUploadTemplateConfiguration,
} from 'store/slices/document-upload-template/document-upload-template-slice-thunks';
import {selectThunkStatus} from 'store/slices/loading-state-slice';

import {CustomTabs} from '../custom-tabs';
import {documentUploadTemplateFormTitles, DocumentUploadTemplateTabKey} from './document-upload-template-constants';
import {DocumentUploadTemplateContext} from './document-upload-template-context';
import {
    DocumentUploadTemplateConfigurator,
} from './tabs/document-upload-template-configurator/document-upload-template-configurator';
import {SettingTemplateTargetTable} from './tabs/setting-template-target-table/setting-template-target-table';
import {SettingTemplateTransferTarget} from './tabs/setting-template-transfer-target/setting-template-transfer-target';

interface DocumentUploadTemplateProps {
    entityName: string;
    templateCode: string;
}

export const DocumentUploadTemplate: React.FC<DocumentUploadTemplateProps> = (
    {
        entityName,
        templateCode,
    }: DocumentUploadTemplateProps,
) => {
    const dispatch = useDispatch();
    const [selectedTabKey, setSelectedTabKey] = useState<React.Key>(DocumentUploadTemplateTabKey.CONFIGURATOR);
    const isCreatingNewTemplate = templateCode === '-1';

    const {
        isPending: isLoadingDocumentTemplateConfigurator,
    } = useAppSelector(s => selectThunkStatus(s, loadDocumentUploadTemplateData.typePrefix));
    const {
        isPending: isLoadSettingTemplateTargetColumns,
    } = useAppSelector(s => selectThunkStatus(s, loadSettingTemplateTargetColumns.typePrefix));
    const {
        isPending: isUpdatingDocumentTemplateConfigurator,
    } = useAppSelector(s => selectThunkStatus(s, updateDocumentUploadTemplateConfiguration.typePrefix));
    const {
        isPending: isCreatingDocumentTemplateConfigurator,
    } = useAppSelector(s => selectThunkStatus(s, createDocumentUploadTemplateConfiguration.typePrefix));
    const {
        isPending: isDeletingDocumentTemplateConfigurator,
    } = useAppSelector(s => selectThunkStatus(s, removeDocumentUploadTemplateConfiguration.typePrefix));

    const {resetDocumentUploadTemplateData} = documentUploadTemplateSliceActions;

    useEffect(() => {
        if (templateCode && !isCreatingNewTemplate) {
            dispatch(loadDocumentUploadTemplateData({entityName, templateCode}));
        }
    }, [templateCode]);

    useEffect(() => () => {
        dispatch(resetDocumentUploadTemplateData());
    }, []);

    return (
        <div className={cn('document-upload-template')}>
            <DocumentUploadTemplateContext.Provider
                value={{
                    entityName,
                    selectedTabKey,
                    templateCode,
                    setSelectedTabKey,
                    isCreatingNewTemplate,
                }}
            >
                <Card
                    key={templateCode}
                    title="Настройка шаблонов для загрузки документов"
                    className={cn('document-upload-templates__card')}
                >
                    <CustomTabs
                        selectedTabKey={selectedTabKey}
                        contentSpinner={{
                            isSpinning: isLoadingDocumentTemplateConfigurator
                                || isUpdatingDocumentTemplateConfigurator
                                || isCreatingDocumentTemplateConfigurator
                                || isLoadSettingTemplateTargetColumns
                                || isDeletingDocumentTemplateConfigurator,
                            tip: isUpdatingDocumentTemplateConfigurator
                                ? 'Сохранение данных шаблона...' : 'Загрузка данных шаблона...',
                        }}
                        tabs={[{
                            key: DocumentUploadTemplateTabKey.CONFIGURATOR,
                            title: documentUploadTemplateFormTitles.configurator,
                            content: <DocumentUploadTemplateConfigurator />,
                        }, {
                            key: DocumentUploadTemplateTabKey.SETTINGS_TEMPLATE_TARGET,
                            title: documentUploadTemplateFormTitles.settingTemplateTarget,
                            content: <SettingTemplateTargetTable entityName={entityName} />,
                        }, {
                            key: DocumentUploadTemplateTabKey.SETTING_TEMPLATE_TRANSFER_TARGET,
                            title: documentUploadTemplateFormTitles.settingTemplateTransferTarget,
                            content: <SettingTemplateTransferTarget entityName={entityName} />,
                        },
                        ]}
                    />
                </Card>
            </DocumentUploadTemplateContext.Provider>
        </div>
    );
};
