import {FormInstance} from 'antd/es/form';
import React, {FunctionComponent} from 'react';
import {useDispatch} from 'react-redux';

import {TIconType} from 'components/dynamic-icon/dynamic-icon';
import {setInitialFormData} from 'modules/data/data-actions';
import {selectIsFormEntityDataEditable} from 'modules/data/data-selectors';
import {ButtonType} from 'modules/metadata/metadata-types';
import {useAppSelector} from 'store/config/hooks';

import {SimpleActionButton} from '../../components';

interface ButtonSetInitialDataProps {
    actionTitle: string;
    actionIcon: TIconType;
    entityName: string;
    type?: ButtonType;
    form: FormInstance;
}

interface ButtonProps extends ButtonSetInitialDataProps {
}

export const ButtonSetInitialData: FunctionComponent<ButtonProps> = ({
    actionTitle,
    actionIcon,
    entityName,
    type,
    form,
    ...props
}: ButtonProps) => {
    const dispatch = useDispatch();
    const isEditable = useAppSelector(state => selectIsFormEntityDataEditable(entityName)(state));
    const handleClick = () => {
        // form state не в redux, нужно его там обновить чтобы форма обновилась если initialData === formData
        form.resetFields();
        dispatch(setInitialFormData({
            entityName,
            // reset isEditable если форма в edit mode
            isEditable: isEditable === true ? false : undefined,
        }));
    };

    return (
        <SimpleActionButton
            type={type || 'default'}
            className="button-back"
            onClick={handleClick}
            title={actionTitle || 'Назад'}
            icon={actionIcon || ''}
            style={{width: '100%'}}
            {...props}
        />
    );
};
