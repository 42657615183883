export enum FieldTooltipType {
    LIST = 'LIST',
    STRING = 'STRING',
    DYNAMIC_STRING = 'DYNAMIC_STRING',
    CURRENT_USER_INFO_LIST = 'CURRENT_USER_INFO_LIST',
}

export interface FieldTooltipMetadata {
    width: string;
    type: FieldTooltipType;
    requestUrl: string;
    contentEntityName: string;
    contentStructure: string;
}
