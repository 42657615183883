import {UndoOutlined} from '@ant-design/icons';
import {
    Button, message,
} from 'antd';
import Spin from 'antd/es/spin';
import cn from 'classnames';
import React, {
    useContext, useEffect, useRef, useState,
} from 'react';
import {v4 as uid} from 'uuid';

import {IconsMap} from 'components/dynamic-icon';
import {DDFilterExpressionCreator} from 'components/report-configuration/tabs/report-dd-filters/dd-filter-expression-creator';
import {ReactComponent as ArrowBackOutlined} from 'shared/assets/arrow-back-outlined.svg';
import {showMessage} from 'shared/utils';
import {useQueryParams} from 'shared/utils/query-params/use-query-params.hook';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';
import {loadDrilldownFilter, updateDrilldownFilter} from 'store/slices/report-configuration-slice/dd-filters-configuration/dd-filters-configuration-thunks';
import {loadDrilldownParameters} from 'store/slices/report-configuration-slice/dd-parameters-configuration/dd-parameters-configuration-thunks';
import {
    ddReferenceRulesSelectors,
    reportConfigurationActions,
    selectDrilldownFilterExpression,
} from 'store/slices/report-configuration-slice/report-configuration-slice';

import {ButtonChangeHistory} from '../../components/button-change-history';
import {DrillDownRuleInfo} from '../../components/drill-down-rule-info';
import {DRILLDOWN_ID_PARAM_KEY, ReportConfigurationTabKey} from '../../report-configuration.constants';
import {ReportConfigurationContext} from '../../report-configuration.context';
import {DDFilterExpressionCreatorRef} from './dd-filter-expression-creator/dd-filter-expression-creator';
import {DDFilterExpression} from './dd-filter-expression-creator/dd-filter-expression-creator-types';
import {
    transformExpressionArrayToFilterExpressionDtoFunction,
} from './dd-filter-expression-creator/dd-filter-expression-creator-utils';
import {ReportDdFilterRulesContext} from './report-dd-filters-utils';

import './report-dd-filters.less';

interface ReportDdFiltersProps {}

export const ReportDdFilters: React.FC<ReportDdFiltersProps> = () => {
    const dispatch = useAppDispatch();

    const {setSelectedTabKey} = useContext(ReportConfigurationContext);

    const [isInEditMode, setIsInEditMode] = useState(false);

    const filterExpressionCreatorRef = useRef<DDFilterExpressionCreatorRef>(null);
    const [filterInitialExpression, setFilterInitialExpression] = useState<DDFilterExpression>([{
        connectBy: 'OR', enabled: true, uid: uid(), ordinal: 0,
    }]);

    const {query, removeQueryParams} = useQueryParams();

    const drillDownId = Number(query.get(DRILLDOWN_ID_PARAM_KEY));
    const drillDown = useAppSelector(state => ddReferenceRulesSelectors.selectById(state, drillDownId));

    const {childReportTemplateCode} = drillDown ?? {};

    const ddFilterExpression = useAppSelector(selectDrilldownFilterExpression);

    const isUpdatingDrilldownFilter = useAppSelector(s => selectIsThunkPending(s, updateDrilldownFilter.typePrefix));
    const [isLoadingDrilldownFilter, setIsLoadingDrilldownFilter] = useState(false);

    useEffect(() => {
        if (!ddFilterExpression) {
            setIsLoadingDrilldownFilter(true);

            dispatch(loadDrilldownParameters({
                drillId: `${drillDownId}`,
            }));

            const loadDrilldownFilterThunk = dispatch(loadDrilldownFilter({
                drillId: drillDownId,
                childReportTemplateCode,
            }));

            loadDrilldownFilterThunk.unwrap().then(() => {}, () => {}).finally(() => {
                setIsLoadingDrilldownFilter(false);
            });
        }

        return () => {
            // loadDrilldownFilterThunk.abort();
        };
    }, []);

    useEffect(() => {
        if (ddFilterExpression) {
            setFilterInitialExpression(ddFilterExpression);
        }
    }, [ddFilterExpression]);

    const handleSave = async () => {
        try {
            const valuesToSave = await filterExpressionCreatorRef.current?.getValuesToSave();
            setIsInEditMode(false);
            if (valuesToSave) {
                try {
                    const filterExpressionDtoToSave = transformExpressionArrayToFilterExpressionDtoFunction(
                        valuesToSave,
                    );

                    await dispatch(updateDrilldownFilter({
                        drillId: drillDownId,
                        filter: filterExpressionDtoToSave,
                    }));

                    showMessage({
                        message: 'Правила фильтрации успешно обновлены',
                    });
                } catch (e) {
                    showMessage({
                        message: 'Ошибка при сохранении фильтров',
                        isError: true,
                    });
                }
            }
        } catch (e) {
            message.error({
                content: 'Пожалуйста, проверьте корректность ввода данных фильтрации',
                duration: 2,
            });
        }
    };

    const handleCancel = () => {
        setIsInEditMode(false);
        setFilterInitialExpression([...filterInitialExpression]);
    };

    return (
        <ReportDdFilterRulesContext.Provider value={{childReportTemplateCode, filterInitialExpression}} >
            <div className={cn('report-dd-filters')}>

                {drillDown && (
                    <div className={cn('report-dd-filters__dd-rule')}>
                        <div className={cn('report-dd-filters__dd-rule__label')}>
                            Правило связи:
                        </div>
                        <DrillDownRuleInfo drillDownRule={drillDown} />
                    </div>
                )}

                <div className={cn('report-dd-filters__upbar', 'report-dd-filters__buttons-bar')}>
                    {!isInEditMode && (
                        <Button
                            disabled={isLoadingDrilldownFilter || isUpdatingDrilldownFilter}
                            type="primary"
                            onClick={() => {
                                setIsInEditMode(true);
                            }}
                        >
                            <IconsMap.EditWithLine />
                            Редактировать
                        </Button>
                    )}
                    {isInEditMode && (
                        <Button
                            type="primary"
                            onClick={handleSave}
                        >
                            <IconsMap.SaveOutlined />
                            Сохранить
                        </Button>
                    )}
                    {isInEditMode && (
                        <Button
                            type="default"
                            onClick={handleCancel}
                        >
                            <UndoOutlined />
                            Отменить
                        </Button>
                    )}
                    <Button
                        type="primary"
                        onClick={() => {
                            setSelectedTabKey?.(ReportConfigurationTabKey.REPORT_DD_PARAMETERS);
                        }}
                    >
                        <IconsMap.DynamicValue />
                        Настройка передачи значений
                    </Button>
                    <ButtonChangeHistory />
                    <Button
                        type="default"
                        icon={<ArrowBackOutlined />}
                        onClick={() => {
                            removeQueryParams(['drillDownId'], {action: 'replace'});
                            dispatch(reportConfigurationActions.purgeDrilldownFilter());
                            setSelectedTabKey?.(ReportConfigurationTabKey.REPORT_DD_REFERENCES);
                        }}
                    >
                        Вернуться на вкладку «Настройка правил связей документов (ДД)»
                    </Button>
                </div>

                <div className={cn('report-dd-filters__section-title')} />

                <div className={cn('report-dd-filters__body')}>
                    <Spin
                        tip={isUpdatingDrilldownFilter ? 'Обновление фильтра...' : 'Загрузка...'}
                        spinning={isLoadingDrilldownFilter || isUpdatingDrilldownFilter}
                    >
                        <DDFilterExpressionCreator
                            ref={filterExpressionCreatorRef}
                            isInEditMode={isInEditMode}
                            setIsInEditMode={setIsInEditMode}
                            initialExpression={filterInitialExpression}
                        />
                    </Spin>
                </div>
            </div>
        </ReportDdFilterRulesContext.Provider>
    );
};
