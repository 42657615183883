import {Space, Tooltip, Typography} from 'antd';
import React from 'react';

import {DynamicIcon} from 'components/dynamic-icon';
import {FieldMeta} from 'modules/metadata';

export interface FieldLabelProps {
    fieldMeta: FieldMeta;
    label: string;
}

export const FieldLabel = ({fieldMeta, label}: FieldLabelProps) => (
    <Space>
        <Typography.Text>{label}</Typography.Text>
        {fieldMeta?.hint && (
            <Tooltip title={fieldMeta.hint}>
                <DynamicIcon type="QuestionCircleOutlined" />
            </Tooltip>
        )}
    </Space>
);
