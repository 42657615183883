import {FormInstance} from 'antd/es/form';
import React, {
    Dispatch, FunctionComponent, SetStateAction, useMemo,
} from 'react';

import {actionsTypeResolver} from 'components/form/actions-type-resolver';
import {MetaActionInfo} from 'modules/metadata';

import {useFormButtonWatcher} from '../use-form-button-watcher/use-form-button-watcher';

interface ActionsItemProps {
    action: MetaActionInfo;
    form?: FormInstance;
    onClose?: () => void;
    onClearForm?: () => void;
    setFormErrorMessage?: Dispatch<SetStateAction<string | null>>;
    onBack?: () => void;
    formMode?: string;
    handleOpenConfirmModal?: () => void;
}

type TProps = ActionsItemProps;

export const ActionsItem: FunctionComponent<TProps> = ({
    action,
    form,
    onClose,
    setFormErrorMessage,
    onBack,
    onClearForm,
    formMode,
    handleOpenConfirmModal,
}: TProps) => {
    const {
        isDisabled: isDisabledByWatcher,
    } = useFormButtonWatcher({actionsForm: action, form});

    const Component = useMemo(() => actionsTypeResolver(
        action, form, onClose, setFormErrorMessage, onBack, onClearForm, formMode, handleOpenConfirmModal,
    ),
    [action, isDisabledByWatcher]);
    return (
        <Component
            disabled={isDisabledByWatcher}
        />
    );
};
