import {FieldType} from 'modules/metadata';

import {COMMON_OPTIONS} from '../common/common.options';

const FIELD_TYPES = [
    ...COMMON_OPTIONS.FIELD_TYPES,
    {
        label: 'PASSWORD',
        value: FieldType.PASSWORD,
    },
    {
        label: 'REFERENCE',
        value: FieldType.REFERENCE,
    },
    {
        label: 'TEXTAREA',
        value: FieldType.TEXTAREA,
    },
];

const ACTION_TYPES = [
    {
        label: 'BUTTON_SAVE_FORM',
        value: 'BUTTON_SAVE_FORM',
    },
];

export const FORM_OPTIONS = {
    FIELD_TYPES,
    ACTION_TYPES,
};
