import {useMemo, useState} from 'react';

import {fetchDocumentsUploadMonitoringData} from 'modules/data/api/documents-upload-monitoring-api';
import {
    DocumentsUploadMonitoring, MonitoringResult, monitoringStatusCode,
    ProgramSetStage,
} from 'modules/documents/documents-upload-monitoring-types';
import {useInterval} from 'shared/hooks/use-interval.hook';
import {showMessage} from 'shared/utils';

import {DOCUMENTS_MONITORING_POLLING_DELAY} from '../constants/documents-upload-monitoring-constants';
import {
    getMonitoringStatus,
    getStageProgramsCorrespondingMonitoringResult, stagesDisplaySequenceComparator,
} from '../utils/document-upload-monitoring-utils';

export const useDocumentsUploadMonitoring = (
    monitoring: DocumentsUploadMonitoring,
) => {
    const [monitoringIsCompleted, setMonitoringIsCompleted] = useState(false);
    const [monitoringStages] = useState<ProgramSetStage[]>(
        monitoring?.monitorProgramSet?.programSetStages || [],
    );
    const [stageMonitoringResults, setStageMonitoringResults] = useState<Record<string, MonitoringResult[]>>({});

    const sortedStages = useMemo(() => monitoringStages.sort(stagesDisplaySequenceComparator), [monitoringStages]);
    useInterval(() => {
        try {
            (async () => {
                if (monitoring?.parentRequestId) {
                    const parentMonitoringData = await fetchDocumentsUploadMonitoringData(monitoring.parentRequestId);
                    const {data: monitoringResults} = parentMonitoringData;
                    if (!monitoringResults) {
                        showMessage({message: 'Невозможно получить информацию о мониторинге', isError: true});
                        setMonitoringIsCompleted(true);
                        return;
                    }
                    // информация о родительском мониторе (статус всего мониторинга)
                    const monitoringStatus = getMonitoringStatus(monitoringResults);
                    if ([monitoringStatusCode.normal,
                        monitoringStatusCode.cancelled,
                        monitoringStatusCode.error,
                        monitoringStatusCode.warning]
                        .includes(monitoringStatus as monitoringStatusCode)) {
                        setMonitoringIsCompleted(true);
                    }
                    // находим нужную информацию о мониторинге для каждой стадии
                    monitoringStages.forEach(stage => {
                        setStageMonitoringResults(v => ({
                            ...v,
                            [stage.id]: getStageProgramsCorrespondingMonitoringResult(
                                stage.programSetPrograms, monitoringResults,
                            ),
                        }));
                    });
                }
            })();
        } catch (e) {
            setMonitoringIsCompleted(true);
            console.error(e);
        }
    }, monitoringIsCompleted ? null : DOCUMENTS_MONITORING_POLLING_DELAY);

    return {monitoringStages: sortedStages, stageMonitoringResults, setMonitoringIsCompleted};
};
