import {Tooltip} from 'antd';
import get from 'lodash/get';
import React from 'react';

import {DynamicIcon} from 'components/dynamic-icon';
import {FileList} from 'modules/data';
import {CommonMessages} from 'shared/constants/messages';
import {FileType} from 'shared/types/files';
import {downloadFile, showMessageFromResponse} from 'shared/utils';
import {generateUrlByFileType} from 'shared/utils/file';

import {ColumnFormatterProps} from '../../table-types';

import './download-file-link.less';

export interface DownloadFileLinkProps extends ColumnFormatterProps {
    value: any;
    stringStructure?: string;
    icon?: string;
    fileType?: FileType;
}

export const DownloadFileLink = ({
    value,
    stringStructure,
    icon,
    fileType = FileType.FILE,
}: DownloadFileLinkProps) => {
    const stringStructureValue = stringStructure && get(value, stringStructure);
    const stringStructureFileList = stringStructureValue?.length
        ? stringStructureValue
        : [stringStructureValue]; // emulate array

    const files: FileList = stringStructure
        ? stringStructureFileList
        : value;

    if (!files) return <></>;

    const handleFileDownload = (attachment: any, filename: any) => {
        if (filename && attachment) {
            const url = generateUrlByFileType({type: fileType, id: attachment});
            if (!url) return;

            downloadFile(url, filename)
                .then(data => {
                    if (data.status === 200) {
                        showMessageFromResponse({
                            response: data,
                            isError: false,
                            customMessage: CommonMessages.UPLOAD_FILE_SUCCESS,
                        });
                    }
                })
                .catch(error => {
                    showMessageFromResponse({response: error?.response, isError: true});
                });
        }
    };

    const getComponent = (file: any) => {
        const fileName = fileType === FileType.SIGNATURE
            ? file?.signatureFileName
            : file?.fileName;

        if (!fileName) return null;

        return (icon ? (
            <Tooltip title={fileName}>
                <DynamicIcon
                    className="download-file-link-icon"
                    type={icon}
                    onClick={() => handleFileDownload(file.attachmentId, fileName)}
                />
            </Tooltip>
        ) : (
            <div
                onClick={() => handleFileDownload(file.attachmentId, fileName)}
                className="download-file-link"
            >
                {fileName}
            </div>
        ));
    };

    return (
        <>
            {files.map((file: any) => getComponent(file))}
        </>
    );
};
