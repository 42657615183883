import {CaretLeftOutlined, CaretRightOutlined} from '@ant-design/icons';
import {
    Button,
    Card, Layout, Menu,
} from 'antd';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';

import {getPageTemplate, PageTemplateProps} from 'pages';
import {URL_AIS3_DOCUMENT_CODES} from 'shared/constants/urls';
import {generateRoute} from 'store/slices/menu-slice';

import {AIS_ENTITY_NAME} from '../ais/ais.constants';
import {DocumentCodes} from '../ais/document-codes/document-codes';
import './tabs-with-references.less';
import {Dashboards} from '../personal-account/tabs/dashboards';
import {DASHBOARD_ENTITY_NAME} from '../personal-account/tabs/dashboards/dashboards.constants';
import {GeneralSettings} from '../personal-account/tabs/general-settings';
import {GENERAL_SETTINGS_ENTITY_NAME} from '../personal-account/tabs/general-settings/general-settings.constants';
import {PropertySettings} from '../property-settings';
import {PROPERTY_SETTINGS_ENTITY_NAME} from '../report-configuration/report-configuration.constants';
import {SETTINGS_PROGRAMS_ENTITY_NAME} from '../settings-programs/constants/setting-program.constants';
import {SettingsPrograms} from '../settings-programs/settings-programs';

const {Sider, Content} = Layout;

export const TabsWithReferences: React.FunctionComponent<PageTemplateProps> = ({
    entityName,
    url,
    parentItem,
    entityType,
}: PageTemplateProps) => {
    const TemplateComponent = getPageTemplate(entityType as string);
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const shouldDisplayPropertySettings = parentItem?.entityName === PROPERTY_SETTINGS_ENTITY_NAME;
    const shouldDisplaySettingsPrograms = parentItem?.entityName === SETTINGS_PROGRAMS_ENTITY_NAME;
    const shouldDisplayDocumentCodes = parentItem?.entityName === URL_AIS3_DOCUMENT_CODES
        || parentItem?.entityName === AIS_ENTITY_NAME;

    if (shouldDisplayPropertySettings) {
        return (<PropertySettings />);
    }

    if (shouldDisplaySettingsPrograms) {
        return (
            <SettingsPrograms
                url={url}
                entityName={entityName}
            />
        );
    }

    if (shouldDisplayDocumentCodes) {
        return (
            <DocumentCodes
                entityName={entityName}
                url={url}
            />
        );
    }
    return (
        <Card
            title={parentItem?.itemTitle}
            className="tabs-with-references"
        >
            <Layout
                className="sider-menu"
                hasSider
            >
                <Sider
                    theme="light"
                    collapsible
                    collapsed={collapsed}
                    width={405}
                    collapsedWidth={40}
                    trigger={null}
                >
                    <Menu
                        mode="inline"
                        selectedKeys={[entityName]}
                    >
                        {parentItem?.children?.map(child => (
                            <Menu.Item
                                key={child.entityName}
                            >
                                <Link to={generateRoute({...child, parentEntityName: parentItem?.entityName})}>
                                    {child.itemTitle}
                                </Link>
                            </Menu.Item>
                        ))}
                    </Menu>
                </Sider>
                <Button
                    className="trigger"
                    type="text"
                    icon={collapsed
                        ? <CaretRightOutlined className="trigger_icon" />
                        : <CaretLeftOutlined className="trigger_icon" />}
                    onClick={() => setCollapsed(!collapsed)}
                />

                <Content className="content">
                    {(() => {
                        switch (entityName) {
                        case GENERAL_SETTINGS_ENTITY_NAME:
                            return <GeneralSettings />;
                        case DASHBOARD_ENTITY_NAME:
                            return <Dashboards />;
                        default:
                            return (
                                <TemplateComponent
                                    url={url}
                                    scroll={{}}
                                    entityName={entityName}
                                />
                            );
                        }
                    })()}
                </Content>
            </Layout>
        </Card>
    );
};
