import {FormInstance, Rule} from 'antd/es/form';

export interface PropertySettingsValidationResolverArgs {
    form: FormInstance;
    validateRegexp?: string | null;
    validateMessage?: string | null;
    propertyCode: string;
}

export const propertySettingsValidationResolver = (
    {
        form,
        validateRegexp,
        validateMessage,
        propertyCode,
    }: PropertySettingsValidationResolverArgs,
): Rule[] => {
    const rules = [
        () => ({
            validator: (_: any, value: string) => {
                const regex = validateRegexp;
                const valueToStr = value ?? '';
                if (!regex || valueToStr.match(regex)) {
                    return Promise.resolve();
                }
                return Promise.reject(new Error(validateMessage || 'Введите данные корректно'));
            },
        }),
    ];
    switch (propertyCode) {
    case 'PASSWORD_LENGTH_MIN':
    case 'PASSWORD_LENGTH_MAX':
        rules.push(
            () => ({
                validator: (_: any, value: string) => {
                    const regex = '^.{0,250}$';
                    const valueToStr = value ? value.toString() : '';
                    if (valueToStr && valueToStr.match(regex)) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error('Длина данного поля должна быть не более 250'));
                },
            }),
            () => ({
                validator() {
                    const maxValue = Number(form?.getFieldValue('PASSWORD_LENGTH_MAX'));
                    const minValue = Number(form?.getFieldValue('PASSWORD_LENGTH_MIN'));
                    if (maxValue >= minValue || (Number.isNaN(maxValue) || Number.isNaN(minValue))) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error(
                        '\'Минимальная длина пароля\' не может быть больше \'Максимальной длины пароля\'',
                    ));
                },
            }),
        );
        break;
    case 'ALLOWED_CHARACTERS_IN_PASSWORD':
        rules.push(
            () => ({
                validator: (_: any, value: string) => {
                    const regex = '^.{0,100}$';
                    const valueToStr = value ? value.toString() : '';
                    if (valueToStr && valueToStr.match(regex)) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error('Длина данного поля должна быть не более 100'));
                },
            }),
        );
        break;
    case 'PASSWORD_SYMBOLS':
        rules.push(
            () => ({
                validator: (_: any, value: string) => {
                    const regex = '^.{0,2000}$';
                    const valueToStr = value ? value.toString() : '';
                    if (valueToStr && valueToStr.match(regex)) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error('Длина данного поля должна быть не более 2000'));
                },
            }),
        );
        break;
    default:
    }
    return rules;
};

export const getValidationDependencies = (code: string) => {
    switch (code) {
    case 'PASSWORD_LENGTH_MIN':
        return ['PASSWORD_LENGTH_MAX'];
    case 'PASSWORD_LENGTH_MAX':
        return ['PASSWORD_LENGTH_MIN'];
    default:
        return undefined;
    }
};
