import {Form, Input, InputNumber} from 'antd';
import {FormInstance} from 'antd/es/form';
import {FormListFieldData, FormListOperation} from 'antd/es/form/FormList';
import cn from 'classnames';
import omit from 'lodash/omit';
import React, {
    forwardRef,
    ReactNode,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';

import {SimpleActionButton} from 'components/form/components';
import {CustomSelect} from 'components/form/inputs/custom-select';
import {CustomSelectValueTriggerEvent} from 'components/form/inputs/custom-select/custom-select';
import {undefineTypeDependentFields} from 'components/report-configuration/tabs/report-table-settings/components/report-table-custom-fields/report-table-custom-fields.utils';
import {TypeDependentFormatSettingsModalResolver} from 'components/report-configuration/tabs/report-table-settings/components/type-dependent-format-settings-modal-resolver/type-dependent-format-settings-modal-resolver';
import {SecuritySettingsModal} from 'components/report-configuration/tabs/report-table-settings/modals/security-settings-modal';
import {StateSetter} from 'shared/types/generics';
import {CustomColumnType} from 'store/slices/report-configuration-slice/report-configuration-dto';

interface CustomFieldInputsProps {
    form: FormInstance;
    fields: FormListFieldData[];
    operations: FormListOperation;
    errors: ReactNode[];
    isHidden?: boolean;
    isDisabled: boolean;
    hiddenFields: Record<string, boolean>;
    setHiddenFields: StateSetter<Record<string, boolean>>;
    columnsTypes: Record<string, CustomColumnType | undefined>;
    setColumnsTypes: StateSetter<Record<string, CustomColumnType | undefined>>;
    isCustomColumnsDisplaying: boolean;
    setCustomColumnsDisplaying: StateSetter<boolean>;
}

export const CustomFieldsInputs = forwardRef(({
    form,
    fields,
    operations,
    errors,
    isHidden,
    isDisabled,
    hiddenFields,
    setHiddenFields,
    columnsTypes,
    setColumnsTypes,
    isCustomColumnsDisplaying,
    setCustomColumnsDisplaying,
}: CustomFieldInputsProps, ref) => {
    const [fieldNameCounter, setFieldNameCounter] = useState<number>(fields?.length || 0);

    const {add, remove} = operations;

    useImperativeHandle(ref, () => ({
        add: () => {
            add();
            setFieldNameCounter(p => p + 1);
        },
        removeAllAndHide: () => {
            remove(fields.map(f => f.name));
            setCustomColumnsDisplaying(false);
        },
    }));

    useEffect(() => {
        if (fields?.length > 0 && !isCustomColumnsDisplaying) {
            setCustomColumnsDisplaying(true);
        }
    }, [fields?.length]);

    return (
        <>
            <Form.ErrorList errors={errors} />
            <div className={cn('form-list', {isHidden})}>
                {fields?.map((field, index) => (
                    <div
                        key={field.fieldKey}
                        className={cn('form-list__items')}
                    >
                        <Form.Item
                            style={{width: 278}}
                            label="Атрибут"
                            name={[field.name, 'columnName']}
                            initialValue={`custom_attribute_${fieldNameCounter}`}
                        >
                            <Input disabled />
                        </Form.Item>
                        <Form.Item
                            style={{width: 140}}
                            label="Выводить в отчёт"
                            name={[field.name, 'hidden']}
                        >
                            <CustomSelect
                                entries={[
                                    {label: 'Да', value: false},
                                    {label: 'Нет', value: true},
                                ]}
                                settings={{
                                    placeholder: 'Выберите опцию',
                                    useFirstOptionAfterEntriesChanged: true,
                                    noUseFirstOptionWhenValueIsSet: true,
                                    isDisabled,
                                }}
                                handleTriggerEvent={(value, event) => {
                                    if (event === CustomSelectValueTriggerEvent.valueHasBeenChangedManually
                                    ) {
                                        setHiddenFields({
                                            ...hiddenFields,
                                            [form.getFieldValue(
                                                ['reportTableColumns', 'customColumns', field.name, 'columnName'],
                                            )]: value,
                                        });
                                    }
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            required={
                                hiddenFields?.[field.name] === false
                                || !form.getFieldValue(
                                    ['reportTableColumns', 'customColumns', field.name, 'hidden'],
                                )
                            }
                            rules={[{
                                required: hiddenFields?.[field.name] === false
                                    || !form.getFieldValue(
                                        ['reportTableColumns', 'customColumns', field.name, 'hidden'],
                                    ),
                            }]}
                            style={{width: 410}}
                            label="Пользовательское наименование"
                            name={[field.name, 'reportTitle']}
                        >
                            <Input disabled={isDisabled} />
                        </Form.Item>
                        <Form.Item
                            style={{width: 140}}
                            label="Порядок вывода"
                            name={[field.name, 'columnOrdinal']}
                            required={
                                hiddenFields?.[field.name] === false
                                || !form.getFieldValue(
                                    ['reportTableColumns', 'customColumns', field.name, 'hidden'],
                                )
                            }
                            rules={[
                                {
                                    required: hiddenFields?.[field.name] === false
                                        || !form.getFieldValue(
                                            ['reportTableColumns', 'customColumns', field.name, 'hidden'],
                                        ),
                                },
                                ({getFieldValue}) => ({
                                    validator: (_, value) => {
                                        const sameReportColumnOrdinals = Object
                                            .entries(getFieldValue(['reportTableColumns', 'reportColumns']) ?? [])
                                            .map(([, val]: any) => val?.columnOrdinal)
                                            ?.filter(colOrdinal => !!value && colOrdinal === value);

                                        const sameOrdinals = Object
                                            .entries(getFieldValue(['reportTableColumns', 'customColumns']) ?? [])
                                            .map(([, val]: any) => val?.columnOrdinal)
                                            ?.filter(colOrdinal => !!value && colOrdinal === value);

                                        if (sameOrdinals?.length > 1) {
                                            return Promise.reject(new Error(
                                                'Уже существует',
                                            ));
                                        }
                                        if (sameReportColumnOrdinals?.length > 0) {
                                            return Promise.reject(new Error(
                                                'Уже существует в основных атрибутах',
                                            ));
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <InputNumber disabled={isDisabled} />
                        </Form.Item>
                        <Form.Item
                            label="Тип поля"
                            style={{width: '220px'}}
                            name={[field.name, 'type']}
                        >
                            <CustomSelect
                                settings={{
                                    url: 'valueLists/TABLE_REPORT_CUSTOM_COLUMN_TYPE',
                                    valuePath: 'lookupCode',
                                    labelPath: 'meaning',
                                    placeholder: 'Выберите тип',
                                    isClearable: true,
                                    isDisabled,
                                }}
                                handleTriggerEvent={(value, event) => {
                                    if (event === CustomSelectValueTriggerEvent.valueHasBeenChangedManually
                                    ) {
                                        // erase previous type-modal fields data
                                        undefineTypeDependentFields({
                                            form,
                                            fieldsPaths: [
                                                ['reportTableColumns', 'customColumns', field.name, 'align'],
                                                ['reportTableColumns', 'customColumns', field.name, 'style'],
                                                ['reportTableColumns', 'customColumns', field.name, 'cases'],
                                            ],
                                        });
                                        const columnName = form.getFieldValue(
                                            ['reportTableColumns', 'customColumns', field.name, 'columnName'],
                                        );
                                        if (!value && !!(columnsTypes?.[columnName])) {
                                            setColumnsTypes(prev => omit(prev, columnName));
                                        } else {
                                            setColumnsTypes({
                                                ...columnsTypes,
                                                [columnName]: value?.lookupCode,
                                            });
                                        }
                                    }
                                }}
                            />
                        </Form.Item>
                        {
                            !!(columnsTypes?.[form.getFieldValue(
                                ['reportTableColumns', 'customColumns', field.name, 'columnName'],
                            )] || form.getFieldValue(
                                ['reportTableColumns', 'customColumns', field.name, 'type'],
                            )) && (
                                <Form.Item
                                    label={<>&nbsp;</>}
                                    name={[field.name]}
                                >
                                    <TypeDependentFormatSettingsModalResolver
                                        form={form}
                                        name={field.name}
                                        type={form.getFieldValue(
                                            ['reportTableColumns', 'customColumns', field.name, 'type'],
                                        )}
                                        disabled={isDisabled}
                                    />
                                </Form.Item>
                            )
                        }
                        <Form.Item
                            label={<>&nbsp;</>}
                            name={[field.name]}
                        >
                            <SecuritySettingsModal
                                formInstance={form}
                                name={field.name}
                                disabled={isDisabled}
                            />
                        </Form.Item>
                        <Form.Item
                            label={<>&nbsp;</>}
                            name={[field.name]}
                        >
                            <SimpleActionButton
                                className="button-only-icon"
                                type="default"
                                icon="DeleteOutlined"
                                onClick={() => {
                                    if (fields.length === 1) {
                                        setCustomColumnsDisplaying(false);
                                    }
                                    const columnName = form.getFieldValue(
                                        ['reportTableColumns', 'customColumns', field.name, 'columnName'],
                                    );
                                    if (Object.keys(hiddenFields).includes(columnName)) {
                                        setHiddenFields(prev => omit(prev, columnName));
                                    }
                                    if (Object.keys(columnsTypes).includes(columnName)) {
                                        setColumnsTypes(prev => omit(prev, columnName));
                                    }
                                    remove(index);
                                }}
                            />
                        </Form.Item>
                    </div>
                ))}
            </div>
        </>
    );
});
