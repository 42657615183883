import React, {createContext} from 'react';

import {RequestFormFormKey} from './request-form.constants';

export interface RequestFormContextArgs {
    selectedTabKey: RequestFormFormKey | React.Key;
    setSelectedTabKey: React.Dispatch<React.SetStateAction<React.Key | RequestFormFormKey>> | null;
    entityName: string;
}

export const RequestFormContext = createContext<RequestFormContextArgs>({
    selectedTabKey: RequestFormFormKey.REQUESTS,
    setSelectedTabKey: null,
    entityName: '',
});
