import React from 'react';

import {ActionVisibility} from 'modules/metadata/metadata-constants';

import {FormMode} from '../../form-types';
import {useFormElementsAvailability} from '../../hooks/check-form-elements-availability.hook';
import {ButtonEditForm} from '../button-edit-form/button-edit-form';
import {ButtonSave} from '../button-save';

interface ButtonSaveOrEditFormOwnProps {
    entityName?: any;
    buttonType?: any;
    formMode?: FormMode;
    action?: any;
}

interface ButtonSaveOrEditFormProps extends ButtonSaveOrEditFormOwnProps {}

export const ButtonSaveOrEditForm: React.FunctionComponent<ButtonSaveOrEditFormProps> = ({
    formMode, buttonType, action, ...props
}: ButtonSaveOrEditFormProps) => {
    const {currentActionsVisibleState} = useFormElementsAvailability(
        formMode || FormMode.REPRESENTATION,
        action.modalEntityName,
    );
    if ((formMode === 'create' && [ActionVisibility.CREATION, ActionVisibility.DEFAULT]
        .includes(currentActionsVisibleState))
        || (formMode === 'edit' && currentActionsVisibleState === ActionVisibility.EDITING)) {
        return (
            <ButtonSave
                actionTitle="Сохранить"
                buttonType={buttonType || 'primary'}
                {...props}
            />
        );
    }
    if (((formMode === 'edit' && currentActionsVisibleState === ActionVisibility.DEFAULT)
        || (formMode === 'edit' && currentActionsVisibleState === ActionVisibility.VIEWING))
        && currentActionsVisibleState !== ActionVisibility.EDITING) {
        return (
            <ButtonEditForm
                entityName={action.modalEntityName}
                actionTitle="Редактировать"
                {...props}
            />
        );
    }
    return null;
};
