import React from 'react';

import {RequestFromD365} from 'components/request/actions/request-from-d365/request-from-d365';
import {ButtonType} from 'modules/metadata/metadata-types';
import {
    URL_REQUESTS_CLOSE,
    URL_REQUESTS_APPROVE,
    URL_REQUESTS_CREATE_APPROVE,
    URL_REQUESTS_REJECT,
    URL_REQUESTS_REQUIREMENT,
    URL_REQUESTS_SEND,
    URL_TAKE_TO_WORK_REQUESTS_NOT_DATE,
} from 'shared/constants/urls';
import {RequestStatus} from 'store/slices/request-slice/request-slice-type';

import {RequestFormFormKey} from '../request-form/request-form.constants';
import {DeleteButton} from './delete-button/request-delete-button';
import {EditingAndSaveFormButton} from './editing-and-save-form-button/editing-and-save-form-button';
import {OpenModalRequest} from './open-modal-request/open-modal-request';
import {RequestSaveButton} from './request-save-button/request-save-button';

/**
 * Функция для получения нужного действия
 */
export const RequestActionTypeResolver = (
    requestStatus: RequestStatus,
    requestFormKey: string,
    requestCommentId?: string,
    entityName?: string,
    onSubmit?: () => void,
) => {
    switch (requestStatus) {
    /**
         * Закрыть запрос
         */
    case RequestStatus.CLOSE_REQUEST:
        return () => (
            <RequestSaveButton
                url={URL_REQUESTS_CLOSE}
                requestFormKey={requestFormKey}
                actionTitle="Закрыть запрос"
                actionIcon="Confirm"
                status={RequestStatus.CLOSE_REQUEST}
                entityName={entityName}
                buttonType={ButtonType.primary}
            />
        );
        /**
         * Оставить комментарий
     */
    case RequestStatus.COMMENT_REQUEST:
    case RequestStatus.REQUEST_RESPONSE:
        return () => (
            <OpenModalRequest
                requestCommentId={requestCommentId}
                requestFormKey={requestFormKey}
                actionTitle="Оставить комментарий"
                actionIcon="PlusCircleOutlined"
                modalName="requestFormModal"
                modalEntityName="requests.comment"
                status={RequestStatus.COMMENT_REQUEST}
                buttonType={ButtonType.primary}
            />
        );
        /**
         * Взять в работу
         */
    case RequestStatus.TAKE_TO_WORK_REQUEST_NOT_DATE:
        return () => (
            <RequestSaveButton
                url={URL_TAKE_TO_WORK_REQUESTS_NOT_DATE}
                requestFormKey={RequestFormFormKey.REQUESTS}
                actionTitle="Взять в работу"
                actionIcon="Case"
                status={RequestStatus.TAKE_TO_WORK_REQUEST_NOT_DATE}
                entityName={entityName}
                buttonType={ButtonType.primary}
            />
        );
    case RequestStatus.TAKE_TO_WORK_REQUEST:
        return () => (
            <OpenModalRequest
                requestCommentId={requestCommentId}
                requestFormKey={requestFormKey}
                actionTitle="Взять в работу"
                modalName="requestFormModal"
                modalEntityName="requests.take.work"
                status={RequestStatus.TAKE_TO_WORK_REQUEST}
                buttonType={ButtonType.primary}
            />
        );
        /**
         * Назначить ответственного
         */
    case RequestStatus.ASSIGN_RESPONSIBLE:
        return () => (
            <OpenModalRequest
                requestCommentId={requestCommentId}
                requestFormKey={requestFormKey}
                actionTitle="Назначить ответственного сотрудника"
                actionIcon="AddUser"
                modalName="requestFormModal"
                modalEntityName="requests.assigning.person"
                status={RequestStatus.ASSIGN_RESPONSIBLE}
                buttonType={ButtonType.primary}
            />
        );
    case RequestStatus.REQUEST_PROLONGATION:
        return () => (
            <OpenModalRequest
                requestCommentId={requestCommentId}
                requestFormKey={requestFormKey}
                actionTitle="Запросить продление"
                actionIcon="ClockOutlined"
                modalName="requestFormModal"
                modalEntityName="requests.prolongation"
                status={RequestStatus.REQUEST_PROLONGATION}
                buttonType={ButtonType.primary}
                isFileComponent
            />
        );
        /**
         * Сформировать ответ
         */
    case RequestStatus.GENERATE_RESPONSE:
        return () => (
            <OpenModalRequest
                requestCommentId={requestCommentId}
                requestFormKey={requestFormKey}
                actionTitle="Сформировать ответ"
                actionIcon="Envelop"
                modalName="requestFormModal"
                modalEntityName="generate.response"
                status={RequestStatus.GENERATE_RESPONSE}
                buttonType={ButtonType.primary}
                isFileComponent
            />
        );
        /**
         * Отправить
         */
    case RequestStatus.REPLY_REQUEST:
        return () => (
            <RequestSaveButton
                url={URL_REQUESTS_REQUIREMENT}
                requestFormKey={requestFormKey}
                actionTitle="Согласовать"
                actionIcon="TickOutlined"
                status={RequestStatus.REPLY_REQUEST}
                buttonType={ButtonType.primary}
            />
        );
    case RequestStatus.APPROVE_REQUEST:
        return () => (
            <RequestSaveButton
                url={URL_REQUESTS_APPROVE}
                requestFormKey={requestFormKey}
                actionTitle="Согласовать"
                actionIcon="TickOutlined"
                status={RequestStatus.APPROVE_REQUEST}
                buttonType={ButtonType.primary}
            />
        );
    case RequestStatus.REJECT:
    case RequestStatus.REJECT_REQUEST:
        return () => (
            <RequestSaveButton
                url={URL_REQUESTS_REJECT}
                requestFormKey={requestFormKey}
                actionTitle="Отклонить"
                actionIcon="TickOutlined"
                status={RequestStatus.REJECT}
                buttonType={ButtonType.primary}
            />
        );
        /**
         * Отправить на согласование
         */
    case RequestStatus.SEND_FOR_APPROVAL_REQUEST:
        return () => (
            <RequestSaveButton
                url={URL_REQUESTS_CREATE_APPROVE}
                requestFormKey={requestFormKey}
                actionTitle="Отправить на согласование"
                actionIcon="TickOutlined"
                status={RequestStatus.SEND_FOR_APPROVAL_REQUEST}
                buttonType={ButtonType.primary}
            />
        );
    case RequestStatus.REQUEST_RESPONSE_SEND:
        return () => (
            <RequestSaveButton
                url={URL_REQUESTS_SEND}
                requestFormKey={requestFormKey}
                actionTitle="Закрыть запрос"
                actionIcon="Confirm"
                status={RequestStatus.REQUEST_RESPONSE_SEND}
                buttonType={ButtonType.primary}
            />
        );
    case RequestStatus.PROLONGATION_REQUEST:
        return () => (
            <OpenModalRequest
                requestCommentId={requestCommentId}
                requestFormKey={requestFormKey}
                actionTitle="Запрос на продление"
                actionIcon="ClockOutlined"
                modalName="requestFormModal"
                modalEntityName="request.prolongation"
                status={RequestStatus.REQUEST_PROLONGATION}
                buttonType={ButtonType.primary}
                isFileComponent
            />
        );
    case RequestStatus.GENERATE_ADD_PLACEMENT_DOC_RESPONSE:
        return () => (
            <OpenModalRequest
                requestCommentId={requestCommentId}
                requestFormKey={requestFormKey}
                actionTitle="Сформировать ответ"
                actionIcon="ClockOutlined"
                modalName="requestFormModal"
                modalEntityName="request.generate.response.additional.placement"
                status={RequestStatus.GENERATE_ADD_PLACEMENT_DOC_RESPONSE}
                buttonType={ButtonType.primary}
            />
        );
    case RequestStatus.GENERATE_REJECTION_RESPONSE:
        return () => (
            <OpenModalRequest
                requestCommentId={requestCommentId}
                requestFormKey={requestFormKey}
                actionTitle="Отказ в доразмещении"
                actionIcon="ClockOutlined"
                modalName="requestFormModal"
                modalEntityName="request.generate.refusal"
                status={RequestStatus.GENERATE_REJECTION_RESPONSE}
                buttonType={ButtonType.primary}
            />
        );
    case RequestStatus.PROLONGATION_RESPONSE:
        return () => (
            <OpenModalRequest
                requestCommentId={requestCommentId}
                requestFormKey={requestFormKey}
                actionTitle="Сформировать решение о продлении"
                actionIcon="Selection"
                modalName="requestFormModal"
                modalEntityName="response.prolongation"
                status={RequestStatus.PROLONGATION_RESPONSE}
                buttonType={ButtonType.primary}
                isFileComponent
            />
        );
    case RequestStatus.EDIT_RESPONSE:
        return () => (
            <EditingAndSaveFormButton
                actionIcon="EditWithLine"
                text="Редактировать"
                onSubmit={onSubmit}
            />
        );
    case RequestStatus.SEND_MESSAGE:
        return () => (
            <RequestSaveButton
                url={URL_REQUESTS_SEND}
                requestFormKey={requestFormKey}
                actionTitle="Отправить"
                actionIcon="Confirm"
                status={RequestStatus.SEND_MESSAGE}
                buttonType={ButtonType.primary}
            />
        );
    case RequestStatus.DELETE_REQUEST:
        return () => (
            <DeleteButton
                requestFormKey={requestFormKey}
                actionTitle="Удалить запрос"
                actionIcon="TrashXOutlined"
                status={RequestStatus.DELETE_REQUEST}
                buttonType={ButtonType.default}
            />
        );
    case RequestStatus.REQUEST_FROM_D365:
        return () => (
            <RequestFromD365 />
        );
    default:
        return () => null;
    }
};
