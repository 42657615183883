import {FieldType} from 'modules/metadata';
import {TMetaOption} from 'modules/metadata-constructor/metadata-constructor.types';

import {COMMON_FIELDS_ITEMS} from '../common/common.fields-items';
import {FORM_ACTIONS} from './form.actions';
import {FORM_FIELDS} from './form.fields-for-type-fields';
import {FORM_OPTIONS} from './form.options';

export const METADATA_FORM: TMetaOption = {
    fields: [
        {
            key: 'fields',
            label: 'Поля формы',
            type: FieldType.LIST,
            dataKeyForFilter: 'type',
            hint: 'Отображаемые поля',
            defaultItems: [
                COMMON_FIELDS_ITEMS.FIELDS.KEY,
                COMMON_FIELDS_ITEMS.FIELDS.LABEL,
                COMMON_FIELDS_ITEMS.FIELDS.IS_HIDDEN,
                COMMON_FIELDS_ITEMS.FIELDS.EDIT_OPTIONS,
                {
                    key: 'type',
                    label: 'Тип',
                    type: FieldType.STATIC_SELECT,
                    options: FORM_OPTIONS.FIELD_TYPES,
                    isRequired: true,
                },
            ],
            customItems: FORM_FIELDS,
        },
        {
            key: 'actions',
            label: 'Действия формы',
            type: FieldType.LIST,
            dataKeyForFilter: 'actionType',
            defaultItems: [
                {
                    ...COMMON_FIELDS_ITEMS.ACTIONS.ACTION_TYPE,
                    options: FORM_OPTIONS.ACTION_TYPES,
                },
                COMMON_FIELDS_ITEMS.ACTIONS.ACTION_TITLE,
                COMMON_FIELDS_ITEMS.ACTIONS.ACTION_ICON,
            ],
            customItems: FORM_ACTIONS,
        },
    ],
};
