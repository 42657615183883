import React from 'react';

import {ReactComponent as DownloadIcon} from 'shared/assets/download.svg';
import {Attachment} from 'shared/types/files';
import {downloadFileById} from 'shared/utils/file';

import 'components/form/fields/download-document-list-item/download-document-list-item.less';

export interface DownloadDocumentListItemProps {
    fileList: Attachment[] | undefined;
}

export const DownloadDocumentListItem = ({fileList}: DownloadDocumentListItemProps) => (
    fileList ? (
        <>
            {fileList.map(file => (
                <div className="download-document-list-item">
                    <span>{file.fileName || '-'}</span>
                    {file && file.fileName && file.attachmentId
                        && <DownloadIcon onClick={() => downloadFileById(file.fileName, file.attachmentId)} />}
                </div>
            ))}
        </>
    ) : <span>-</span>
);
