import {createReducer} from 'store/config/creators';
import {Action} from 'store/config/types';

import {CONTEXT_ACTIONS} from './context-constants';
import {
    ContextSetLookupsPayload,
    ContextSetRawDataPayload,
    ContextState,
} from './context-types';

const initialState = {
    fetchedWithContext: [],
};

export const contextReducer = createReducer(initialState, {
    [CONTEXT_ACTIONS.SET_CONTEXT_LOOKUPS]: (
        state: ContextState,
        {payload}: Action<ContextSetLookupsPayload>,
    ) => ({
        ...state,
        lookups: payload,
    }),
    [CONTEXT_ACTIONS.SET_CONTEXT_RAW_DATA]: (
        state: ContextState,
        {payload}: Action<ContextSetRawDataPayload>,
    ) => ({
        ...state,
        rawData: payload.rawData,
    }),
    [CONTEXT_ACTIONS.UPDATE_CONTEXT_RAW_DATA]: (
        state: ContextState,
        {payload}: Action<ContextSetRawDataPayload>,
    ) => ({
        ...state,
        rawData: {
            ...state.rawData,
            ...payload.rawData,
        },
    }),
    [CONTEXT_ACTIONS.RESET_CONTEXT]: (
        state: ContextState,
    ) => ({
        ...state,
        rawData: undefined,
    }),
    [CONTEXT_ACTIONS.ADD_TO_FETCHED_WITH_CONTEXT]: (
        state: ContextState,
        {payload}: Action<string>,
    ) => {
        const currentFetchedWithContextList = [...state.fetchedWithContext];
        if (!currentFetchedWithContextList.includes(payload)) { currentFetchedWithContextList.push(payload); }
        return {
            ...state,
            fetchedWithContext: currentFetchedWithContextList,
        };
    },
    [CONTEXT_ACTIONS.SET_FETCHED_WITH_CONTEXT]: (
        state: ContextState,
        {payload}: Action<string[]>,
    ) => ({
        ...state,
        fetchedWithContext: payload,
    }),
});
