import compact from 'lodash/compact';

import {MetaActionInfo, MetaActionType} from 'modules/metadata';
import {normalizeBoolValue} from 'utils/common';

export const convertActionFieldsForMeta = (actions?: MetaActionInfo[]) => {
    const simpleActions: MetaActionInfo[] = [];
    const submenuActions: MetaActionInfo[] = [];
    if (Array.isArray(actions)) {
        compact(actions).forEach((item: MetaActionInfo) => {
            const normalizedItem = {...item};
            delete normalizedItem?.isSubmenu;

            if (normalizeBoolValue(item?.isSubmenu)) {
                submenuActions.push(normalizedItem);
            } else {
                simpleActions.push(normalizedItem);
            }
        });
    }

    return actions ? [
        ...simpleActions,
        {
            actionType: 'SUBMENU',
            actionTitle: 'Действия',
            childrens: submenuActions,
        },
    ] : undefined;
};

export const convertActionMetaForFields = (actions?: MetaActionInfo[]) => {
    const simpleActions: MetaActionInfo[] = [];
    const submenuActions: MetaActionInfo[] = [];
    actions?.forEach((item: MetaActionInfo) => {
        if (item.actionType === MetaActionType.SUBMENU) {
            item.childrens?.forEach(child => submenuActions.push({
                ...child,
                isSubmenu: true,
            }));
        } else {
            simpleActions.push(item);
        }
    });

    return actions ? [
        ...simpleActions,
        ...submenuActions,
    ] : undefined;
};
