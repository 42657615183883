import {createSelector, Selector} from '@reduxjs/toolkit';

import {CONTEXT_FILTER_LOOKUPS} from 'components/context-filter/context-filter.constants';
import {AppState} from 'store/config/types';

import {CONTEXT_MODULE} from './context-constants';
import {ContextData, ContextRawData, ContextState} from './context-types';

const rootSelector: Selector<AppState, ContextState> = (state: AppState) => state[CONTEXT_MODULE];

export const selectContextRawData = createSelector(
    rootSelector,
    (dataState: ContextState) => dataState.rawData as ContextRawData | undefined,
);

export const selectContextLookups = createSelector(
    rootSelector,
    (dataState: ContextState) => dataState.lookups,
);

export const selectEntityNamesFetchedWithContext = createSelector(
    rootSelector,
    (dataState: ContextState) => dataState.fetchedWithContext,
);

export const selectContextDataWithLookups: Selector<AppState, ContextData> = createSelector(
    selectContextRawData,
    selectContextLookups,
    (rawData, lookups) => {
        if (!rawData || !lookups) return {};
        const lookupsWithValues = (() => {
            const values = Object.keys(CONTEXT_FILTER_LOOKUPS).map(key => {
                const keyToPickValue = key as keyof ContextRawData;
                const id = rawData[keyToPickValue];
                const value = lookups[keyToPickValue]?.find(v => v.id === id) ?? id;
                return [keyToPickValue, value];
            });
            return Object.fromEntries(values);
        })();
        return {...rawData, ...lookupsWithValues} as ContextData;
    },
);
