import React, {useEffect, useMemo} from 'react';

import {CustomLink} from 'components/@common/widgets/custom-link';
import {ButtonHistoryBack} from 'components/form/actions/button-history-back';
import {ButtonType} from 'modules/metadata/metadata-types';
import {LinkType} from 'shared/types/links';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {selectRequestStatus} from 'store/slices/request-slice/request-selectors';
import {loadRequestStatusesCommentData, loadRequestStatusesData} from 'store/slices/request-slice/request-slice-thunks';
import {RequestInfo} from 'store/slices/request-slice/request-slice-type';

import {URL_REQUESTS} from '../../../shared/constants/urls';
import {CollapseButton} from '../actions/collapse-button';
import {Buttons} from '../buttons/buttons';
import {RequestTypeCode} from '../request-form/request-form.constants';

import './status-model-aware-actions.less';

export interface StatusModelAwareActionsProps {
  removeControlCollapseAction?: boolean;
  requestCommentId?: string;
  requestFormKey: string;
  onClose?: () => void;
  entityName?: string;
  isHidden?: boolean;
  onSubmit?: () => void;
  requestType?: string;
  requestInfo?: RequestInfo;
  linkExtraData?: Record<string, any>;
}

export const StatusModelAwareActions = ({
    requestCommentId,
    removeControlCollapseAction,
    entityName,
    requestFormKey,
    isHidden,
    onClose,
    onSubmit,
    requestType,
    requestInfo,
    linkExtraData,
}: StatusModelAwareActionsProps) => {
    const statusesData = useAppSelector(selectRequestStatus(requestFormKey));
    const statuses = statusesData?.statuses;
    const dispatch = useAppDispatch();

    const {
        ddUrlDto: links,
    } = requestInfo ?? {};

    useEffect(() => {
        if (!statuses && requestCommentId) {
            if (requestType === RequestTypeCode.REQUEST_DIT
                || requestType === RequestTypeCode.MESSAGE
            ) {
                dispatch(
                    loadRequestStatusesCommentData({commentId: requestCommentId, requestFormKey}),
                );
                return;
            }
            dispatch(
                loadRequestStatusesData({commentId: requestCommentId, requestFormKey}),
            );
        }
    }, [requestCommentId, statuses, requestFormKey, requestType]);

    const actions = useMemo(
        () => statuses?.filter(action => statuses?.includes(action)), [statuses, requestCommentId],
    );
    return (
        <div className="buttons">
            {!isHidden && (
                <Buttons
                    requestCommentId={String(requestCommentId)}
                    requestFormKey={requestFormKey}
                    onClose={onClose}
                    actions={actions}
                    entityName={entityName}
                    onSubmit={onSubmit}
                />
            )}
            {removeControlCollapseAction && <CollapseButton />}
            <ButtonHistoryBack
                actionIcon="ArrowBackOutlined"
                buttonType={ButtonType.default}
                url={URL_REQUESTS}
            />
            {links && (
                links.map(link => (
                    <CustomLink
                        key={link.url}
                        link={{
                            url: link?.url ?? '/ais3.doc.journal',
                            urlType: link?.urlType ?? LinkType.INTERNAL,
                            filterParameters: link?.filterParameters,
                            ignoreRequiredParameters: link?.ignoreRequiredParameters ?? true,
                            linkProperties: link?.linkProperties ?? {
                                title: 'Перейти в реестр документов',
                                icon: 'ContainerOutlined',
                                type: 'button',
                            },
                        }}
                        extraStateData={{
                            aisRegister: {
                                requests: {
                                    requestId: requestInfo?.id,
                                    requestData: {
                                        ...linkExtraData,
                                    },
                                },
                            },
                        }}
                    />
                ))
            )}
        </div>
    );
};
