import {DatePicker, Form, Spin} from 'antd';
import Table from 'antd/es/table';
import cn from 'classnames';
import moment from 'moment';
import {Moment} from 'moment/moment';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {AisMainPageDashboardProps} from 'pages/ais/ais-main-page/ais-main-page-types';
import {DATE_DMY} from 'shared/constants/date-format';
import {useAppSelector} from 'store/config/hooks';
import {AisDashboardCode} from 'store/slices/ais-slice/ais-dashboards/ais-dashboards-constants';
import {selectRequestStatisticS3S6S12} from 'store/slices/ais-slice/ais-dashboards/ais-dashboards-selectors';
import {
    getAisRequestStatisticS3S6S12,
} from 'store/slices/ais-slice/ais-dashboards/ais-dashboards-thunks';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

import {AisDashboardCard} from '../@common/ais-dashboard-card';
import {
    convertAisDashboardElementDtoToRequestStatusesData,
    convertMomentToDate,
} from './ais-request-statuses-dashboard.utils';

import './ais-request-statuses-dashboard.less';
import 'components/table/columns/date-cell/date-cell.less';

export const AisRequestStatusesDashboard: React.FunctionComponent<AisMainPageDashboardProps> = ({
    context,
}: AisMainPageDashboardProps) => {
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState<Moment | null>(
        moment(`${new Date().getFullYear()}-01-01`),
    );
    const [endDate, setEndDate] = useState<Moment | null>(moment());
    const {elements} = useAppSelector(selectRequestStatisticS3S6S12) || {};
    const currentDate = moment()?.format(DATE_DMY);
    const {taxPeriodId, organizationId} = context || {};

    useEffect(() => {
        if (!taxPeriodId && !organizationId) return;
        dispatch(getAisRequestStatisticS3S6S12({
            code: AisDashboardCode.REQUEST_STATISTIC_S3_S6_S12,
            startDate: convertMomentToDate(startDate),
            endDate: convertMomentToDate(endDate),
            taxPeriodId,
            organizationId,
        }));
    }, [startDate, endDate, taxPeriodId, organizationId]);

    const isLoading = useAppSelector(s => selectIsThunkPending(s, getAisRequestStatisticS3S6S12.typePrefix));

    const renderMeaningCell = (value: string, record: any) => (
        <div className={cn('title', {parent: record?.isParentElement})}>
            {value}
        </div>
    );

    return (
        <AisDashboardCard
            className={cn('ais-request-statuses-dashboard')}
            title="Статус запросов по сценариям, связанным с предоставлением документов/информации"
            footer={(
                <div className={cn('ais-register-dashboard__footer')}>
                    <div>
                        По состоянию на:
                        <span className={cn('text-gray-600')}>
                            {' '}{currentDate}
                        </span>
                    </div>
                </div>
            )}
        >
            <div>
                <Form
                    layout="horizontal"
                    colon={false}
                    className="d-flex gap-2"
                >
                    <Form.Item label="Период с">
                        <DatePicker
                            className="date-picker"
                            value={startDate}
                            placeholder="Дата"
                            onChange={v => setStartDate(v)}
                            format={DATE_DMY}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Период по"
                    >
                        <DatePicker
                            className="date-picker"
                            value={endDate}
                            placeholder="Дата"
                            onChange={v => setEndDate(v)}
                            format={DATE_DMY}
                        />
                    </Form.Item>
                </Form>
            </div>
            <Spin
                spinning={isLoading}
                tip="Загрузка..."
            >
                <Table
                    pagination={{hideOnSinglePage: true}}
                    className={cn('ais-request-statuses-dashboard__table', 'widget-table')}
                    scroll={{y: 380}}
                    columns={[
                        {
                            title: 'Сценарий',
                            dataIndex: 'meaning',
                            key: 'meaning',
                            width: 250,
                            render: (v, r) => renderMeaningCell(v, r),
                        },
                        {title: 'Новые', dataIndex: 'new', key: 'new'},
                        {title: 'В работе', dataIndex: 'inProgress', key: 'inProgress'},
                        {title: 'Завершено', dataIndex: 'completed', key: 'completed'},
                        {title: 'Всего', dataIndex: 'result', key: 'result'},
                    ]}
                    dataSource={convertAisDashboardElementDtoToRequestStatusesData(elements)}
                />
            </Spin>
        </AisDashboardCard>
    );
};
