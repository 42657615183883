import {createAsyncThunk} from '@reduxjs/toolkit';

import {LookupEntry} from 'shared/types/lookups';

import {
    GetExternalSystemsForUserArgs,
    PostExternalSystemsForUserArgs,
    getAvailableExternalSystemsForUser,
    getExternalSystemsForUser,
    getUseExternalSystemsPropertyFlag,
    postExternalSystemsForUser,
} from './external-systems-slice-api';
import {externalSystemsSliceName} from './external-systems-slice-constants';
import {ExternalSystemUserMappingDto} from './external-systems-slice-types';

export const loadAllExternalSystemsDataForUser = createAsyncThunk<
{
    externalSystems: ExternalSystemUserMappingDto[];
    availableExternalSystems: LookupEntry[];
}, GetExternalSystemsForUserArgs
>(
    `${externalSystemsSliceName}/loadAllExternalSystemsDataForUser`,
    async (args, {rejectWithValue}) => {
        try {
            const [
                externalSystemsResponse,
                availableExternalSystemsResponse,
            ] = await Promise.all([
                getExternalSystemsForUser(args),
                getAvailableExternalSystemsForUser(args),
            ]);

            return {
                availableExternalSystems: availableExternalSystemsResponse.data,
                externalSystems: externalSystemsResponse.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const loadExternalSystemsForUser = createAsyncThunk<
    ExternalSystemUserMappingDto[], GetExternalSystemsForUserArgs
>(
    `${externalSystemsSliceName}/loadExternalSystemsForUser`,
    async (args, {rejectWithValue}) => {
        try {
            const response = await getExternalSystemsForUser(args);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const loadAvailableExternalSystemsForUser = createAsyncThunk<
    LookupEntry[], GetExternalSystemsForUserArgs
>(
    `${externalSystemsSliceName}/loadAvailableExternalSystemsForUser`,
    async (args, {rejectWithValue}) => {
        try {
            const response = await getAvailableExternalSystemsForUser(args);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const saveExternalSystemsForUser = createAsyncThunk<
    string, PostExternalSystemsForUserArgs
>(
    `${externalSystemsSliceName}/saveExternalSystemsForUser`,
    async (args, {rejectWithValue}) => {
        try {
            const response = await postExternalSystemsForUser(args);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const loadUseExternalSystemsPropertyFlag = createAsyncThunk<
'Y' | 'N'
>(
    `${externalSystemsSliceName}/loadUseExternalSystemsPropertyFlag`,
    async (args, {rejectWithValue}) => {
        try {
            const response = await getUseExternalSystemsPropertyFlag();
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);
