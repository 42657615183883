import {Form} from 'antd';
import cn from 'classnames';
import React from 'react';

import {fieldValidationResolver} from 'components/form/utils/field-validation-resolver';
import {useTableReportParameters} from 'components/table-report/hooks/use-table-report-parameters';
import {FieldType} from 'modules/metadata';
import {useAppSelector} from 'store/config/hooks';
import {ReportConfigurationQueryParameterDto} from 'store/slices/report-configuration-slice/report-configuration-dto';
import {selectTableReportTemplateConfig} from 'store/slices/table-report-slice/table-report-slice-selectors';
import {TableReportFilterFieldType} from 'store/slices/table-report-slice/table-report-slice-types';

import {TableReportFieldResolver} from '../fileld-resolver/table-report-field-resolver';
import {
    filterDefaultWidthResolver,
    tableReportFilterFieldValidationResolver,
} from '../fileld-resolver/utils/report-filter-field-resolver.utils';
import {getTableReportFilterLookupReferenceRequestData} from '../utils/table-report-filter.utils';

export interface TableReportFilterFieldProps {
    filterKeyPath: 'paramName' | 'columnName';
    queryParameter: ReportConfigurationQueryParameterDto;
    defaultValues?: Record<string, any>;
    areParametersApplied: boolean;
}

export const TableReportFilterField = ({
    queryParameter,
    filterKeyPath,
    defaultValues,
    areParametersApplied,
}: TableReportFilterFieldProps) => {
    const {
        type,
        defaultValue,
        valuesetLookupType,
        format,
        defaultType,
        reportTitle,
        required,
        paramName,
        columnName,
    } = queryParameter;

    const {templateCode} = useAppSelector(selectTableReportTemplateConfig) ?? {};
    const {url, queryParams} = getTableReportFilterLookupReferenceRequestData({
        defaultType,
        defaultValue,
        type,
        valuesetLookupType,
        templateCode,
    });

    const {isDrillDownParameterUsedInFilter} = useTableReportParameters();

    return (
        <Form.Item
            key={queryParameter[filterKeyPath]}
            name={queryParameter[filterKeyPath]}
            label={type !== TableReportFilterFieldType.BOOLEAN ? reportTitle : <>&nbsp;</>}
            required={type !== TableReportFilterFieldType.BOOLEAN && required}
            initialValue={defaultValues}
            style={{minWidth: filterDefaultWidthResolver(type)}}
            className={cn(type === TableReportFilterFieldType.BOOLEAN && 'without-label')}
            rules={fieldValidationResolver({
                validationRules: tableReportFilterFieldValidationResolver(type),
                fieldMeta: {
                    type: type as unknown as FieldType, label: reportTitle, isRequired: required,
                },
            })}
            valuePropName={type === TableReportFilterFieldType.BOOLEAN ? 'checked' : undefined}
        >
            <TableReportFieldResolver
                showSearch
                isClearable
                type={type}
                format={format}
                label={reportTitle}
                referenceUrl={url}
                referenceUrlQueryParams={queryParams}
                paramName={paramName}
                areParametersApplied={areParametersApplied}
                disabled={[columnName, paramName].some(v => isDrillDownParameterUsedInFilter(v))}
            />
        </Form.Item>
    );
};
