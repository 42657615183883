export enum PostgresNumberType {
    smallint = 'smallint',
    integer = 'integer',
    bigint = 'bigint',
    bigserial = 'bigserial',
    numeric = 'numeric',
    int = 'int',
    int2 = 'int2',
    int4 = 'int4',
    int8 = 'int8',
    serial ='serial',
    serial4='serial4',
    serial8 = 'serial8',
    float4 = 'float4',
    float8 = 'float8',
    decimal = 'decimal',
    real = 'real',
}

export enum PostgresStringType {
    varchar = 'varchar',
    varbit = 'varbit',
    char = 'char',
    bit = 'bit',
    text = 'text',
    bpchar = 'bpchar',
}

export enum PostgresDateType {
    date = 'date',
    time = 'time',
    timestamp = 'timestamp',
    timetz = 'timetz'
}

export type PostgresColumnType =
  | PostgresNumberType
  | PostgresStringType
  | PostgresDateType;
