import {createApi} from '@reduxjs/toolkit/query/react';

import {axiosBaseQuery} from '../base-query';

export interface GetAdditionalFieldsRequest {
    referenceUrl?: string;
    id?: string | number;
}

export const FormAdditionalOptionsApi = createApi({
    reducerPath: 'formAdditionalOptions',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['AdditionalFields'],
    endpoints: builder => ({
        getAdditionalFields: builder.query<any, GetAdditionalFieldsRequest>({
            query: ({referenceUrl, id}) => ({
                url: `${referenceUrl}/${id}`,
                method: 'GET',
            }),
            providesTags: ['AdditionalFields'],
        }),
    }),
});

export const {useGetAdditionalFieldsQuery, useLazyGetAdditionalFieldsQuery} = FormAdditionalOptionsApi;
