import React from 'react';

import {DocumentCodesFormModal} from 'components/ais/document-codes/components/document-codes-form-modal/document-codes-form-modal';
import {DocumentComparisonModal} from 'components/document-comparison-modal';
import {DocumentsFileUploadModal} from 'components/documents/file-modal';
import {DocumentsUploadStageModal} from 'components/documents/file-modal/upload-stage-modal';
import {DocumentsSignatureUploadModal} from 'components/documents/signature-modal';
import {EditableTableModal} from 'components/editable-table-modal';
import {FilterDrawer} from 'components/filter-drawer';
import {FilterModal} from 'components/filter-modal';
import {FormDrawer} from 'components/form-drawer';
import {FormEditEntityModal} from 'components/form-edit-modal';
import {FormModal} from 'components/form-modal';
import {ListGridModal} from 'components/list-grid-modal';
import {ListModal} from 'components/list-modal';
import {RequestFormModal} from 'components/request/request-form-modal';

import {ModalComponentInterface, ModalType} from '../../store/slices/modals-slice/modals-slice-types';
import {
    SettingProgramMappingModal,
} from '../settings-programs/components/setting-program-mapping-modal/setting-program-mapping-modal';

export const AppModalsMapping: Record<string, React.FC<ModalComponentInterface>> = {
    [ModalType.documentsFileUpload]: DocumentsFileUploadModal,
    [ModalType.documentsUploadStage]: DocumentsUploadStageModal,
    [ModalType.documentsSignatureUpload]: DocumentsSignatureUploadModal,
    [ModalType.editableTableModal]: EditableTableModal,
    // TODO убрать formCreateItem после обновления metatable
    [ModalType.formCreateItem]: FormModal,
    [ModalType.formModal]: FormModal,
    [ModalType.formDrawer]: FormDrawer,
    [ModalType.filterDrawer]: FilterDrawer,
    [ModalType.formEditEntityModal]: FormEditEntityModal,
    [ModalType.filterModal]: FilterModal,
    [ModalType.listModal]: ListModal,
    [ModalType.listGridModal]: ListGridModal,
    [ModalType.documentComparisonModal]: DocumentComparisonModal,
    [ModalType.requestFormModal]: RequestFormModal,
    [ModalType.documentCodesFormModal]: DocumentCodesFormModal,
    [ModalType.settingProgramMappingModal]: SettingProgramMappingModal,
};
