import React, {useMemo, useState} from 'react';

import {DynamicIcon} from 'components/dynamic-icon';

import '../text-modal.less';

interface TextTooltipInterface {
    value: string;
    handleEdit: () => void;
}
const MAX_LENGTH = 50;

export const TextTooltip = ({value, handleEdit}: TextTooltipInterface) => {
    const text = useMemo(() => (value.length > MAX_LENGTH ? `${value.substring(0, MAX_LENGTH + 1)}...` : value),
        [value]);
    const [isAllText, setIsAllText] = useState(false);
    return (
        <div className="text-tooltip">
            <div
                className="text-tooltip__content"
                onMouseEnter={() => setIsAllText(true)}
                onMouseLeave={() => setIsAllText(false)}
            >{isAllText ? value : text}
            </div>
            <DynamicIcon
                type="EditWithLine"
                onClick={handleEdit}
            />
        </div>
    );
};
