import cn from 'classnames';
import React from 'react';

import {MenuStructureItem} from 'store/slices/menu-slice/menu-slice-types';

import {NewSideMenuListItem} from './new-side-menu-list-item';

interface NewSideMenuListProps {
    items?: MenuStructureItem[];
    depth: number;
    path?: string;
    className?: cn.Argument;
}

export const NewSideMenuList: React.FC<NewSideMenuListProps> = (
    {
        items, path = '', depth, className,
    }: NewSideMenuListProps,
) => (
    <div className={cn('new-side-menu__list', className)}>
        {items?.map(item => (
            <NewSideMenuListItem
                key={item.id}
                item={item}
                path={path}
                depth={depth}
            />
        ))}
    </div>
);
