import {Input} from 'antd';
import debounce from 'lodash/debounce';
import React, {Dispatch, SetStateAction} from 'react';
import './search.less';

import {ReactComponent as SearchIcon} from 'shared/assets/search.svg';

type FetchCallback = (searchValue: string) => void;

interface TableSearchProps {
    fetchCallback: FetchCallback;
    setSearch?: Dispatch<SetStateAction<string | null>>;
}

export const Search: React.FunctionComponent<TableSearchProps> = ({
    fetchCallback,
    setSearch,
}: TableSearchProps) => {
    const handleSearchAction = debounce(event => {
        if (setSearch) setSearch(event.target.value);
        fetchCallback(event.target.value);
    }, 500);

    return (
        <div className="table-search">
            <Input
                allowClear
                placeholder="Поиск"
                onChange={event => {
                    event.persist();
                    handleSearchAction(event);
                }}
                prefix={<SearchIcon className="table-search__icon" />}
            />
        </div>
    );
};
