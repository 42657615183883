import {Button, Input, Upload} from 'antd';
import {RcFile} from 'antd/es/upload';
import cn from 'classnames';
import React from 'react';

import {ReactComponent as ClearIcon} from 'shared/assets/forms/delete-x.svg';
import {ReactComponent as FileIcon} from 'shared/assets/forms/file.svg';
import {FormItemProps} from 'shared/types';

import './file-uploader.less';

interface FileUploaderProps extends FormItemProps{
    accept?: string;
    disabled?: boolean;
    multiple?: boolean;
}

export const FileUploader = ({
    accept,
    disabled,
    multiple = false,
    value: fieldValue,
    onChange,
}: FileUploaderProps) => {
    const handleUpload = (file: RcFile, fileList: RcFile[]) => {
        const value = multiple ? fileList : file;
        if (!fieldValue || !multiple) onChange?.(value, undefined);
        if (multiple && Array.isArray(fieldValue)) {
            const uploadedFileNames = fieldValue.map(uploadedFile => uploadedFile.name);
            const uniqueFiles = fileList.filter(newFile => !uploadedFileNames.includes(newFile.name));
            onChange?.(fieldValue.concat(uniqueFiles), undefined);
        }

        return false;
    };

    const handleRemove = (index?: number) => {
        if (index === undefined) onChange?.(undefined, undefined);
        else if (Array.isArray(fieldValue)) {
            onChange?.((() => {
                const filteredArray = fieldValue.filter((v, i) => i !== index);
                if (!filteredArray.length) return undefined;
                return filteredArray;
            })(), undefined);
        }
    };

    return (
        <Upload
            accept={accept}
            disabled={disabled}
            beforeUpload={handleUpload}
            showUploadList={false}
            className="upload-field"
            multiple={multiple}
        >
            <div className={cn('upload-field-wrapper')}>
                {Array.isArray(fieldValue) ? (
                    <div className={cn('file-uploader__multiple-fields')}>
                        {fieldValue.map((file: RcFile, index) => (
                            <Input
                                key={file.uid}
                                disabled
                                prefix={<FileIcon />}
                                className="ant-input--soft-disabled"
                                suffix={(
                                    <ClearIcon
                                        className="upload-field__clear-icon"
                                        onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
                                            event.stopPropagation();
                                            handleRemove(index);
                                        }}
                                    />
                                )}
                                value={file.name ?? 'Файлы не прикреплен'}
                            />
                        ))}
                    </div>
                ) : (
                    <Input
                        disabled
                        prefix={<FileIcon />}
                        className="ant-input--soft-disabled"
                        suffix={(
                            <ClearIcon
                                className="upload-field__clear-icon"
                                onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
                                    event.stopPropagation();
                                    handleRemove();
                                }}
                            />
                        )}
                        value={fieldValue?.name ?? (multiple ? 'Файлы не прикреплены' : 'Файл не прикреплён')}
                    />
                )}
                <Button className={cn('upload-field__btn', {
                    'file-uploader__multiple-choose-btn': multiple,
                })}
                >
                    {`Выберите файл${multiple ? 'ы' : ''}`}
                </Button>
            </div>
        </Upload>
    );
};
