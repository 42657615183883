import React from 'react';

import {CustomCardTitle, Grade} from 'components/form/components';

import {CustomCardStyleProps} from '../../../../form/components/custom-card-title/custom-card-title';

interface RequestHeaderProps {
    text: string;
    requestFormKey: string;
    value?: number;
    commentId?: string;
    url?: string;
    customCardStyleProps?: CustomCardStyleProps;

}
export const RequestHeader = ({
    customCardStyleProps,
    text,
    value,
    commentId,
    requestFormKey,
    url,
}:RequestHeaderProps) => (
    <CustomCardTitle
        customCardStyleProps={customCardStyleProps}
        text={text}
        extra={(
            <Grade
                value={value}
                commentId={commentId}
                requestFormKey={requestFormKey}
                url={url}
            />
        )}
    />
);
