import {History} from 'history';
import {isEmpty} from 'lodash';
import moment from 'moment';
import {Moment} from 'moment/moment';

import {EntityValue} from 'modules/data';
import {DefaultTableState, DefaultTableStateFlat} from 'modules/data/data-types';
import {FieldAction, FieldActionType} from 'modules/metadata/metadata-types';
import {DATE, RESOLVED_FORMATS} from 'shared/constants/date-format';
import {performRequest} from 'shared/utils';
import {combineString} from 'shared/utils/combine-string';
import {QueryParam} from 'shared/utils/query-params/query-params-types';

export const createTableCellClassNameResolver = (entityName: string, fieldKey: string) => {
    const entityNameWithDashes = entityName.split('.').join('-');
    const fieldKeyWithDashes = fieldKey.split('.').join('-');
    return `${entityNameWithDashes}__table-cell__${fieldKeyWithDashes}`;
};

export const createDefaultSorter = (field: string, stringStructure?: string) => (
    itemA: Record<string, any>,
    itemB: Record<string, any>,
) => {
    const structure = stringStructure?.split(/[.\n]+/);
    if (structure) {
        let entryA = itemA?.[field] || '';
        let entryB = itemB?.[field] || '';
        if (!entryA) {
            structure.forEach(elem => {
                entryA += `${itemA?.[elem]}`;
                entryB = entryA;
            });
        }
        let entryAValue = '';
        let entryBValue = '';
        if (structure.length === 1) {
            entryAValue = entryA?.[structure[0]] || '';
            entryBValue = entryB?.[structure[0]] || '';
            return entryAValue.localeCompare(entryBValue);
        }
        if (structure.length === 2) {
            entryAValue = itemA?.[structure[0]]?.[structure[1]] || '';
            entryBValue = itemB?.[structure[0]]?.[structure[1]] || '';
            return entryAValue.localeCompare(entryBValue);
        }
        if (structure) {
            entryAValue = (entryA[0] && entryA[0]?.[structure[0]]) ? entryA[0]?.[structure[0]] : '';
            entryBValue = (entryB[0] && entryB[0]?.[structure[0]]) ? entryB[0]?.[structure[0]] : '';
            return entryAValue.localeCompare(entryBValue);
        }

        if (typeof entryA === 'string') {
            return entryA.localeCompare(entryB);
        }

        if (typeof entryA === 'number') {
            return entryA - entryB;
        }
    }

    return 0;
};

export const createStringDate = (value: EntityValue, dateFormat?: string) => {
    const date = (value && moment(value as string | Moment, RESOLVED_FORMATS)) as Moment;

    return !isEmpty(date) && date?.isValid()
        ? date?.format(dateFormat || DATE)
        : value;
};

export const callRedirect = (
    record: Record<string, any>,
    updateQueryParams: (params: QueryParam[]) => void,
    linkField?: string,
    history?: History,
    url?: string,
    userId?: number,
) => {
    if (linkField === 'notificationId') {
        const value = record?.notificationAssignments?.find(
            (item: {
                assigneeId: number;
                read: boolean;
            }) => item?.assigneeId === userId && !item.read,
        );
        if (value) {
            performRequest({
                url: `${url}/mark-as-read/notification`,
                data: JSON.stringify({notificationId: record?.notificationId, userId}),
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(data => {
                if (data.status === 200 && history) {
                    updateQueryParams([{name: 'entity', value: record[linkField]}]);
                }
            });
        } else if (linkField && history) {
            updateQueryParams([{name: 'entity', value: record[linkField]}]);
        }
    } else if (linkField && history) {
        updateQueryParams([{name: 'entity', value: record[linkField]}]);
    }
};
export const createStringCell = (value: any[], stringStructure?: string) => {
    if (stringStructure) {
        return value.map(item => combineString(stringStructure, item))?.join(', ');
    }
    return value.join(', ');
};

export const getAllowedActions = (actions?: FieldAction[]) => {
    const allowedActions: FieldActionType[] = [];
    if (actions && actions.length > 0) {
        actions.forEach(action => {
            if (action.name) {
                allowedActions.push(action.name);
            }
        });
    }
    return allowedActions;
};

export const convertFlatObjectToTableState = (
    tableStateFlat: DefaultTableStateFlat, total?: number,
): DefaultTableState => {
    const {
        paginationPageSize, paginationCurrent, paginationTotal, sorterField, sorterOrder,
    } = tableStateFlat || {};

    return {
        pagination: {
            pageSize: paginationPageSize,
            current: paginationCurrent,
            total: total ?? paginationTotal,
        },
        sorter: {
            field: sorterField,
            order: sorterOrder,
        },
    };
};
