import cn from 'classnames';
import React from 'react';

import {SvcLayout} from 'modules/metadata/metadata-types';

import {createLinkButtonPropsWithPreset} from '../link-button';
import {WidgetLayout} from '../widget-layout';

interface DictionaryLayoutItemProps {
    dictionary: SvcLayout;
}

export const DictionaryLayoutItem: React.FC<DictionaryLayoutItemProps> = ({dictionary}: DictionaryLayoutItemProps) => {
    const className = dictionary.icon?.toString().toLowerCase();

    return (
        <WidgetLayout
            title={dictionary.title}
            iconName={dictionary.icon}
            className={cn(className)}
            buttons={dictionary.url ? {
                bottom: [
                    createLinkButtonPropsWithPreset({link: dictionary.url, preset: 'PASS_TO_SECTION'}),
                ],
            } : undefined}
        />

    );
};
