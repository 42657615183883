import React, {useState} from 'react';

import {MonitorServiceContentDisplayerModal} from 'components/ais/monitor-services-card/monitor-service-content-displayer-modal/monitor-service-content-displayer-modal';
import {MonitorServiceContentDisplayerType} from 'components/table/columns/monitor-services-content-displayer/monitor-services-content-displayer.types';
import {ColumnFormatterProps} from 'components/table/table-types';

import 'components/table/columns/monitor-services-content-displayer/monitor-services-content-displayer.less';

type MonitorServicesContentDisplayerProps = {
    type: MonitorServiceContentDisplayerType;
} & Pick<ColumnFormatterProps, 'record'>;

export const MonitorServicesContentDisplayer = ({
    type, record,
}: MonitorServicesContentDisplayerProps) => {
    const [isVisible, setVisible] = useState(false);

    const handleClose = () => setVisible(false);

    const title = type === MonitorServiceContentDisplayerType.request
        ? 'Содержание запроса'
        : 'Содержание ответа';

    return (
        <>
            <MonitorServiceContentDisplayerModal
                visible={isVisible}
                onClose={handleClose}
                type={type}
                entityId={record?.id ? String(record.id) : undefined}
                modalTitle={title}
            />
            <div
                className="monitor-services-request-content"
                onClick={() => setVisible(true)}
            >
                {title}
            </div>
        </>
    );
};
