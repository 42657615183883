import {Card} from 'antd';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {Form as FormComponent, FormMode} from 'components/form';
import {FormModalWrapper} from 'components/form-modal/form-modal-wrapper';
import {Spinner} from 'components/spinner';
import {loadFormData, resetLoadedData} from 'modules/data';
import {performActionForForm} from 'modules/data/data-actions';
import {selectFilterEntityData, selectFormEntityData} from 'modules/data/data-selectors';
import {loadMetadata, RequestType} from 'modules/metadata';
import {resetMetadata, setMetadata} from 'modules/metadata/metadata-actions';
import {selectFormEntityMetadata} from 'modules/metadata/metadata-selectors';
import {EntityType} from 'shared/constants/entities';
import {LookupEntry} from 'shared/types/lookups';
import {useAppSelector} from 'store/config/hooks';
import {ModalComponentInterface, selectModalData} from 'store/slices/modals-slice';

import {
    MONITOR_PROGRAM_KEY,
    SettingMappingHeaderCsvField,
} from '../../constants/setting-program.constants';

export const SettingProgramMappingModal = ({
    onClose,
    parameters,
}: ModalComponentInterface) => {
    const dispatch = useDispatch();
    const [isInitForm, setInitForm] = useState<boolean>(false);
    const entityName = useAppSelector(state => selectModalData(state)?.entityName) || '';
    const filter = useAppSelector(selectFilterEntityData(entityName));
    const paramMonitorProgram: LookupEntry = filter?.data?.paramMonitorProgramId as LookupEntry;
    const isMonitorParsingCsv = paramMonitorProgram?.attribute1 === MONITOR_PROGRAM_KEY;
    const {url, recordId} = parameters || {};
    const metadata = useAppSelector(selectFormEntityMetadata(entityName));
    const data = useAppSelector(selectFormEntityData(entityName));
    const actionForForm = (
        name: string,
        referenceUrl: string,
        requestType: RequestType,
        newData: Record<string, any>,
        isJsonRequest?: boolean,
        urlParamKey?: string,
    ) => dispatch(performActionForForm()(
        name,
        referenceUrl,
        requestType,
        {programId: paramMonitorProgram?.id, ...newData},
        isJsonRequest,
        urlParamKey,
    ));

    useEffect(() => {
        if (!metadata) {
            dispatch(loadMetadata(entityName, EntityType.FORM));
        }
        if (recordId) {
            dispatch(loadFormData(entityName, recordId, url));
        }
        return () => {
            dispatch(resetLoadedData(entityName, EntityType.FORM));
            dispatch(resetMetadata({entityName, entityType: EntityType.FORM, loading: false}));
        };
    }, []);

    useEffect(() => {
        if (metadata && !isInitForm) {
            setInitForm(true);
            if (isMonitorParsingCsv) {
                dispatch(setMetadata({
                    entityName,
                    entityType: EntityType.FORM,
                    metadata: {
                        ...metadata,
                        fields: [...metadata?.fields?.slice(0, -1), SettingMappingHeaderCsvField],
                    },
                }));
                setInitForm(true);
            }
        }
    }, [metadata, isMonitorParsingCsv]);

    return (
        <FormModalWrapper
            entityName={entityName}
            onClose={onClose}
        >
            {handleClose => (
                isInitForm ? (
                    <FormComponent
                        onClose={handleClose}
                        formData={data}
                        entityName={entityName}
                        mode={recordId ? FormMode.EDIT : FormMode.CREATE}
                        meta={metadata}
                        actionForForm={actionForForm}
                    />
                ) : (<Card style={{paddingTop: 20}}> <Spinner /></Card>)
            )}
        </FormModalWrapper>
    );
};
