import {Tooltip, Typography} from 'antd';
import React from 'react';
import {useDispatch} from 'react-redux';

import {EntityValue} from 'modules/data';
import {downloadFileForUrl} from 'modules/data/data-actions';
import {FieldMeta, FieldType} from 'modules/metadata';

import {DynamicIcon} from '../../dynamic-icon';

const {Link} = Typography;

interface TypeValueProps {
    type: FieldType;
    value: EntityValue;
    referenceUrl: string | undefined;
}

const TypeValue: React.FunctionComponent<TypeValueProps> = ({
    type,
    value,
    referenceUrl,
}: TypeValueProps) => {
    const dispatch = useDispatch();
    switch (type) {
    case FieldType.EMAIL:
        return <Link href={`mailto:${value}`}>{value}</Link>;

    case FieldType.PHONE:
        return <Link href={`tel:${value}`}>{value}</Link>;

    case FieldType.ICON_LINK: {
        const onFileDownload = () => {
            dispatch(downloadFileForUrl(referenceUrl));
        };
        return (
            <Tooltip title="">
                <DynamicIcon
                    className="list-modal__download-icon"
                    type="DownloadOutlined"
                    onClick={onFileDownload}
                />
            </Tooltip>
        );
    }

    default:
        return <>{value}</>;
    }
};

interface ListItemProps {
    meta: FieldMeta;
    value?: EntityValue;
}

export const ListItem: React.FunctionComponent<ListItemProps> = ({
    meta,
    value,
}: ListItemProps) => (
    <div className="list__item">
        <span className="list__item__label">{meta.label}</span>
        {
            (value || meta.type === FieldType.ICON_LINK) && (
                <TypeValue
                    type={meta.type}
                    value={value}
                    referenceUrl={meta.referenceUrl}
                />
            )
        }
    </div>
);
