import {Badge, Button} from 'antd';
import React from 'react';

import {TABLE_COLUMN_FILTER_CLEAR_EVENT_KEY} from 'components/@common/widgets/custom-table/table-column-filter/table-column-filter-constants';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {tableReportSliceActions} from 'store/slices/table-report-slice';
import {selectTableReportColumnFiltersDataAdaptedForRequest} from 'store/slices/table-report-slice/table-report-column-filters/table-report-column-filters-selectors';

import './clear-table-column-filters-button.less';

interface ClearTableColumnFiltersButtonProps {}

export const ClearTableColumnFiltersButton: React.FC<ClearTableColumnFiltersButtonProps> = () => {
    const dispatch = useAppDispatch();
    const tableColumnFilters = useAppSelector(selectTableReportColumnFiltersDataAdaptedForRequest);

    const showClearButton = !!(tableColumnFilters?.groups.length || tableColumnFilters?.rules.length);

    const handleClearFilters = () => {
        dispatch(tableReportSliceActions.setTableColumnFilters({filters: undefined}));
        window.dispatchEvent(new Event(TABLE_COLUMN_FILTER_CLEAR_EVENT_KEY));
    };

    return (
        <>
            {showClearButton && (
                <Badge
                    className="clear-table-column-filters-button-badge"
                    size="small"
                    count={tableColumnFilters?.groups.length}
                >
                    <Button
                        type="default"
                        onClick={handleClearFilters}
                    >
                        Очистить фильтры
                    </Button>
                </Badge>

            )}
        </>
    );
};
