import cn from 'classnames';
import React from 'react';

import './progress-bar.less';

interface ProgressBarProps {
    value: number;
    maximum: number;
    barColor?: string;
}
export const ProgressBar: React.FC<ProgressBarProps> = ({maximum, value, barColor}: ProgressBarProps) => {
    const width = !maximum ? 0 : `${(value / maximum) * 100}%`;
    return (
        <div className={cn('progress-list-statistics__body__item__progress-bar')}>
            <div
                style={{width, backgroundColor: barColor ?? '#1E72D7'}}
                className={cn('progress-list-statistics__body__item__progress-bar__bar')}
            />
        </div>
    );
};
