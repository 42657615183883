import {Button, Dropdown, Menu} from 'antd';
import React from 'react';

import {ReactComponent as MoreIcon} from 'shared/assets/more.svg';

import {TableAction} from '../table-action';
import {TABLE_ACTION_MAP} from '../table-action-constants';
import {TableActionProps} from '../table-action-types';

import './table-submenu-action.less';

export const TableSubmenuAction: React.FunctionComponent<TableActionProps> = ({meta, entityName}: TableActionProps) => {
    const generateMenu = () => (
        <Menu>
            {meta.childrens?.map(child => (
                TABLE_ACTION_MAP[child.actionType] ? (
                    <TableAction
                        key={child.actionTitle}
                        meta={child}
                        wrapper={Menu.Item}
                        entityName={entityName}
                    />
                ) : (
                    <Menu.Item
                        key={child.actionTitle}
                    >
                        {child.actionTitle}
                    </Menu.Item>
                )
            ))}
        </Menu>
    );

    return (meta?.childrens?.length ? (
        <Dropdown
            className="dropdown"
            overlay={generateMenu()}
        >
            <Button className="table-action submenu-button">
                <MoreIcon className="dropdown__icon" />
            </Button>
        </Dropdown>
    ) : <></>);
};
