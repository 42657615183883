import {SaveOutlined, UndoOutlined} from '@ant-design/icons/lib';
import {unwrapResult} from '@reduxjs/toolkit';
import {Button} from 'antd';
import cn from 'classnames';
import React, {Dispatch, SetStateAction} from 'react';
import {useHistory} from 'react-router-dom';

import {ButtonClearForm} from 'components/form/actions/button-clear-form/button-clear-form';
import {SimpleActionButton} from 'components/form/components';
import {ModalOpenerComponent} from 'components/modal-opener-component';
import {showMessage} from 'shared/utils';
import {useAppDispatch} from 'store/config/hooks';
import {
    removeTableReportConfiguration,
} from 'store/slices/report-configuration-slice/report-configuration/report-configuration-thunks';

import {activateUploadTemplate} from '../../../document-upload-template/document-upload-template-api';
import {ButtonChangeHistory} from '../button-change-history';
import {ChangeHistoryData} from '../button-change-history/modal-change-history/modal-change-history';

interface ButtonsBarProps {
    entityName: string;
    templateCode?: string;
    isCreating: boolean;
    isCreatingDisabled?: boolean;
    isEditing: boolean;
    setIsEditing: Dispatch<SetStateAction<boolean>>;
    isUploadTemplateActive?: boolean;
    setIsUploadTemplateActive?: Dispatch<SetStateAction<boolean>>;
    isEditingDisabled?: boolean;
    isChangeHistoryButton?: boolean;
    onSubmit?: () => void;
    onBack?: () => void;
    titleBack?: string;
    hideEditButton?: boolean;
    hideDeleteButton?: boolean;
    onClearForm?: () => void;
    extraButtons?: {
        position: 'left' | 'right';
        buttons: React.ReactNode[];
    };
    onCancelEdit?: () => void;
    handleDeleteTemplate?: () => void;
    changeHistoryData?: ChangeHistoryData;
}

export const ButtonsBar = ({
    entityName,
    templateCode,
    isCreating,
    isCreatingDisabled,
    isEditing,
    setIsEditing,
    isEditingDisabled,
    isUploadTemplateActive,
    setIsUploadTemplateActive,
    isChangeHistoryButton = true,
    titleBack = 'Вернуться на главную вкладку',
    hideEditButton,
    hideDeleteButton,
    extraButtons,
    onClearForm,
    onCancelEdit,
    onSubmit,
    onBack,
    handleDeleteTemplate,
    changeHistoryData,
}: ButtonsBarProps) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const {buttons, position} = extraButtons || {};

    const handleDelete = async () => {
        if (handleDeleteTemplate) {
            handleDeleteTemplate();
            return;
        }
        if (!templateCode) return;
        await dispatch(removeTableReportConfiguration({
            templateCode,
        })).then(unwrapResult)
            .then(() => {
                showMessage({message: `Шаблон «${templateCode}» успешно удалён`});
                history.push(`/${entityName}`);
            }, error => {
                showMessage({message: error, isError: true});
            });
    };

    return (
        <div className={cn('report-configurator__buttons-bar')}>
            {extraButtons && position === 'left' && buttons}
            {onSubmit && (
                <Button
                    disabled={isCreatingDisabled}
                    type="primary"
                    htmlType="submit"
                    onClick={onSubmit}
                    icon={<SaveOutlined />}
                >
                    {isCreating ? 'Создать шаблон' : 'Сохранить'}
                </Button>
            )}
            {!isCreating && !isEditing && !hideEditButton && (
                <SimpleActionButton
                    disabled={isEditingDisabled}
                    type="primary"
                    icon="EditWithLine"
                    onClick={() => setIsEditing(true)}
                    title="Редактировать"
                />
            )}
            {setIsUploadTemplateActive && !isCreating && !isEditing && (
                <Button
                    disabled={isCreating && isEditing}
                    type="primary"
                    onClick={() => {
                        activateUploadTemplate(
                            setIsUploadTemplateActive,
                            isUploadTemplateActive,
                            templateCode,
                        );
                    }}
                >
                    {isUploadTemplateActive ? 'Деактивировать' : 'Активировать'}
                </Button>
            )}
            {
                isEditing && onCancelEdit && (
                    <Button
                        icon={<UndoOutlined />}
                        type="default"
                        onClick={() => {
                            onCancelEdit?.();
                        }}
                    >Отменить
                    </Button>
                )
            }
            {!isCreating && isChangeHistoryButton && (
                <ButtonChangeHistory changeHistoryData={changeHistoryData} />
            )}
            {!isCreating && !hideDeleteButton && (
                <ModalOpenerComponent
                    component={(
                        <SimpleActionButton
                            type="default"
                            icon="TrashXOutlined"
                            title="Удалить"
                            className="button-critic"
                        />
                    )}
                    modalProps={{
                        title: 'Предупреждение',
                        centered: true,
                    }}
                    handleDelete={setIsModalOpen => {
                        handleDelete();
                        setIsModalOpen(false);
                    }}
                >
                    Вы уверены, что хотите удалить этот шаблон?
                </ModalOpenerComponent>

            )}
            {!isCreating && isEditing && onClearForm && (
                <ButtonClearForm
                    handleClearForm={onClearForm}
                    actionTitle="Очистить форму"
                    actionIcon="TrashXOutlined"
                    confirmText="Вы действительно хотите очистить форму?"
                    approveText="Очистить"
                />
            )}
            <SimpleActionButton
                type="default"
                icon="ArrowBackOutlined"
                onClick={() => onBack?.()}
                title={titleBack || 'Назад'}
            />
            {extraButtons && position === 'right' && buttons}
        </div>
    );
};
