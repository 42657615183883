import {InfoCircleOutlined} from '@ant-design/icons';
import {
    Button, Checkbox, Form, Input, InputNumber, Popconfirm, Switch, Tooltip,
} from 'antd';
import {useForm} from 'antd/lib/form/Form';
import cn from 'classnames';
import React, {
    useContext,
    useEffect,
    useImperativeHandle, useMemo, useRef, useState,
} from 'react';
import {v4 as uid} from 'uuid';

import {IconsMap} from 'components/dynamic-icon';
import {CustomSelect} from 'components/form/inputs/custom-select';
import {FilterValueType} from 'components/report-configuration/report-configuration.constants';
import {getFilterValueTypeByColumnType} from 'components/report-configuration/report-configuration.utils';
import {DateCell} from 'components/table/columns/date-cell/date-cell';
import {DATE, DATE_DMY} from 'shared/constants/date-format';
import {useAppSelector} from 'store/config/hooks';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';
import {ReportTableColumn, loadTableReportColumnsLookup} from 'store/slices/report-configuration-slice/dd-lookups';
import {
    selectReportTableColumnsByTemplateCode,
    selectTableReportConfigurationFilterConditions,
    selectTableReportConfigurationFiltersByFieldType,
} from 'store/slices/report-configuration-slice/dd-lookups/dd-lookups-selectors';
import {loadTableReportConfigurationFilterConditionsLookup} from 'store/slices/report-configuration-slice/dd-lookups/dd-lookups-thunks';
import {selectDdParametersAsEntries} from 'store/slices/report-configuration-slice/report-configuration-slice';

import {ReportDdFilterRulesContext} from '../report-dd-filters-utils';
import {DDFilterExpression, DDFilterOperand} from './dd-filter-expression-creator-types';
import {findReportColumnByFieldName, findTableReportConditionByOperatorCode} from './dd-filter-expression-creator-utils';
import {
    DDFilterExpressionGroup, DDFilterExpressionGroupRef,
} from './dd-filter-expression-group';

interface DDFilterExpressionOperandProps {
    operand?: DDFilterOperand;
    isInEditMode: boolean;
    remove: () => void;
    depth: number;
    itemIndex: string;
}

export interface DDFilterExpressionOperandRef {
    getValues: (skipValidation?: boolean) => Promise<DDFilterOperand | DDFilterExpression>;
}

export const DDFilterExpressionOperand = React.forwardRef<
    DDFilterExpressionOperandRef,
    DDFilterExpressionOperandProps>((
        {
            operand, remove, isInEditMode, depth, itemIndex,
        }: DDFilterExpressionOperandProps,
        ref,
    ) => {
        const [form] = useForm();

        const expressionRef = useRef<DDFilterExpressionGroupRef>(null);

        const [isParametrizedInputVisible, setIsParametrizedFieldVisible] = useState(
            Array.isArray(operand) ? false : !!operand?.parametrized,
        );

        const ddParametersEntries = useAppSelector(selectDdParametersAsEntries);

        const [selectedReportColumn, setSelectedReportColumn] = useState<ReportTableColumn>();
        const [isComparisonValueAllowedEmpty, setIsComparisonValueAllowedEmpty] = useState(false);

        const comparisonValueFieldType = getFilterValueTypeByColumnType(selectedReportColumn?.type);

        const {
            childReportTemplateCode,
        } = useContext(ReportDdFilterRulesContext);

        const isLoadingColumns = useAppSelector(s => selectIsThunkPending(s, loadTableReportColumnsLookup.typePrefix));
        const childReportColumns = useAppSelector(s => (!childReportTemplateCode
            ? undefined : selectReportTableColumnsByTemplateCode(s, childReportTemplateCode)));

        const tableReportFilterConditions = useAppSelector(selectTableReportConfigurationFilterConditions);
        const tableReportFilterConditionsByFieldType = useAppSelector(
            s => selectTableReportConfigurationFiltersByFieldType(s, {
                fieldType: comparisonValueFieldType,
            }),
        );

        const isLoadingTableReportFilterConditions = useAppSelector(s => selectIsThunkPending(
            s, loadTableReportConfigurationFilterConditionsLookup.typePrefix,
        ));

        const getValues = async (skipValidation = false) => {
            if (!Array.isArray(operand)) {
                const isValid = await (async () => {
                    let result = true;
                    try {
                        await form.validateFields();
                    } catch (e) {
                        if (e?.errorFields?.length) result = false;
                    }
                    return result;
                })();

                if (!isValid && !skipValidation) return Promise.reject(new Error('Form validation error'));

                const values: DDFilterOperand = {
                    fieldName: form.getFieldValue('fieldName'),
                    comparisonValue: form.getFieldValue('comparisonValue'),
                    parametrized: form.getFieldValue('parametrized'),
                    enabled: form.getFieldValue('enabled'),
                    operatorCode: form.getFieldValue('operatorCode'),
                };

                return values;
            }
            return expressionRef.current?.getValues?.(skipValidation);
        };

        useImperativeHandle(ref, () => ({getValues}));

        useEffect(() => {
            form.resetFields();
            if (!Array.isArray(operand)) {
                setIsParametrizedFieldVisible(!!operand?.parametrized);
                setSelectedReportColumn(findReportColumnByFieldName({
                    childReportColumns,
                    fieldName: operand?.fieldName,
                }));
                setIsComparisonValueAllowedEmpty(
                    operand?.operatorCode === 'EMPTY' || operand?.operatorCode === 'NOT_EMPTY',
                );
            }
        }, [operand]);

        const groupIdentificator = useMemo(() => {
            if (!Array.isArray(operand)) return undefined;
            let ident = operand[0];
            if (!ident) return {connectBy: 'OR' as 'OR' | 'AND', enabled: false, uid: uid()};
            if (!ident.uid) ident = {...ident, uid: uid()};
            if (!ident.enabled) ident = {...ident, enabled: false};
            return ident;
        }, [operand]);

        if (!Array.isArray(operand)) {
            return (
                <Form
                    layout="vertical"
                    form={form}
                    onValuesChange={changed => {
                        if (changed.parametrized !== undefined) {
                            setIsParametrizedFieldVisible(changed.parametrized);
                            form.setFieldsValue({comparisonValue: undefined});
                        }
                        if (changed.operatorCode !== undefined) {
                            const nextReportCondition = findTableReportConditionByOperatorCode({
                                filterLookups: tableReportFilterConditions,
                                operatorCode: changed?.operatorCode,
                            });

                            const {lookupCode: nextConditionLookupCode} = nextReportCondition ?? {};
                            if (nextConditionLookupCode === 'EMPTY' || nextConditionLookupCode === 'NOT_EMPTY') {
                                setIsComparisonValueAllowedEmpty(true);
                                form.setFieldsValue({comparisonValue: undefined});
                            } else setIsComparisonValueAllowedEmpty(false);
                        }
                        if (changed.fieldName !== undefined) {
                            const nextFieldName = changed.fieldName;
                            const nextSelectedReportColumn = findReportColumnByFieldName({
                                childReportColumns,
                                fieldName: nextFieldName,
                            });

                            setSelectedReportColumn(nextSelectedReportColumn);
                            form.setFieldsValue({comparisonValue: undefined, operatorCode: undefined});
                        }
                    }}
                >
                    <div
                        className={cn('dd-filter-expression-operand__item')}
                    >
                        <div className={cn('dd-filter-expression-operand__item__body')}>
                            <div className={cn('dd-filter-expression-operand__item__body__title', 'fw-500')}>
                                Правило №{itemIndex}
                            </div>
                            <div className={cn('dd-filter-expression-operand__item__body__form')}>
                                <Form.Item
                                    label="Поле конечного документа"
                                    required
                                    name="fieldName"
                                    rules={[{required: true, message: 'Пожалуйста, выберите поле'}]}
                                    initialValue={operand?.fieldName}
                                    style={{flexBasis: '10%'}}
                                >
                                    <CustomSelect
                                        style={{minWidth: 236}}
                                        entries={(childReportColumns ?? []).map((col => ({
                                            label: col.name,
                                            value: col.name,
                                        })))}
                                        settings={{
                                            isLoading: isLoadingColumns,
                                            isDisabled: !isInEditMode || isLoadingColumns,
                                            placeholder: 'Выберите поле',
                                            showSearch: true,
                                        }}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Условие фильтрации"
                                    required
                                    name="operatorCode"
                                    rules={[{required: true, message: 'Пожалуйста, выберите условие'}]}
                                    initialValue={operand?.operatorCode}
                                    style={{flexBasis: '10%'}}
                                >
                                    <CustomSelect
                                        entries={(tableReportFilterConditionsByFieldType ?? []).map((filterLookup => ({
                                            label: filterLookup.meaning,
                                            value: filterLookup.lookupCode,
                                        })))}
                                        settings={{
                                            isDisabled: !isInEditMode || isLoadingTableReportFilterConditions,
                                            placeholder: 'Выберите условие',
                                        }}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label={<>Дин. передача</>}
                                    style={{maxWidth: 100}}
                                >
                                    <div className={cn(
                                        'dd-filter-expression-operand__item__switch',
                                        {'dd-filter-expression-operand__item__switch_disabled': !isInEditMode},
                                    )}
                                    >
                                        <IconsMap.DynamicValue style={{color: '#1E72D7'}} />
                                        <Form.Item
                                            name="parametrized"
                                            valuePropName="checked"
                                            initialValue={operand?.parametrized}
                                        >
                                            <Switch
                                                size="small"
                                                disabled={!isInEditMode}
                                            />
                                        </Form.Item>
                                    </div>
                                </Form.Item>

                                {!isParametrizedInputVisible && (
                                    <Form.Item
                                        label="Значение"
                                        required={!isComparisonValueAllowedEmpty}
                                        name="comparisonValue"
                                        rules={[{
                                            required: !isComparisonValueAllowedEmpty,
                                            message: 'Пожалуйста, введите значение',
                                        }]}
                                        initialValue={operand?.comparisonValue}
                                        style={{maxWidth: 340}}
                                    >
                                        {(() => {
                                            const isDisabled = !isInEditMode || isComparisonValueAllowedEmpty;

                                            if (comparisonValueFieldType === FilterValueType.number) {
                                                return (
                                                    <InputNumber
                                                        style={{width: '100%'}}
                                                        disabled={isDisabled}
                                                        placeholder="Введите значение"
                                                        decimalSeparator=","
                                                    />
                                                );
                                            }
                                            if (comparisonValueFieldType === FilterValueType.date) {
                                                return (
                                                    <DateCell
                                                        format={DATE_DMY}
                                                        requestFormat={DATE}
                                                        disabled={isDisabled}
                                                    />
                                                );
                                            }
                                            return (
                                                <Input
                                                    disabled={isDisabled}
                                                    placeholder="Введите значение"
                                                />
                                            );
                                        })()}
                                    </Form.Item>
                                )}

                                {isParametrizedInputVisible && (
                                    <Form.Item
                                        label={(
                                            <div
                                                className="d-flex gap-0-75 align-items-center"
                                            >
                                                Переменная
                                                <Tooltip
                                                    title={'Для вывода данных в поле необходимо'
                                                    + ' настроить правила передачи динамических значений'}
                                                >
                                                    <InfoCircleOutlined style={{color: '#3083FF', marginTop: -2}} />
                                                </Tooltip>
                                            </div>
                                        )}
                                        required
                                        name="comparisonValue"
                                        rules={[{required: true, message: 'Пожалуйста, выберите переменную'}]}
                                        initialValue={operand?.comparisonValue}
                                        style={{flexBasis: '10%', maxWidth: 340}}
                                    >
                                        <CustomSelect
                                            entries={ddParametersEntries}
                                            settings={{
                                                placeholder: 'Выберите переменную',
                                                isDisabled: !isInEditMode || isComparisonValueAllowedEmpty,
                                            }}
                                        />
                                    </Form.Item>
                                )}

                                <Form.Item
                                    label={<>&nbsp;</>}
                                    name="enabled"
                                    initialValue={operand?.enabled}
                                    valuePropName="checked"
                                    className="ml-1"
                                    style={{maxWidth: 100}}
                                >
                                    <Checkbox disabled={!isInEditMode}>Активно</Checkbox>
                                </Form.Item>

                                <Popconfirm
                                    title={(
                                        <span>Вы действительно хотите удалить правило?</span>
                                    )}
                                    placement="bottomLeft"
                                    okText="Удалить"
                                    disabled={!isInEditMode}
                                    onConfirm={() => {
                                        remove?.();
                                    }}
                                >
                                    <div>
                                        <Button
                                            type="default"
                                            className="btn-only-icon"
                                            style={{marginTop: 30}}
                                            disabled={!isInEditMode}
                                        >
                                            <IconsMap.TrashXOutlined />
                                        </Button>
                                    </div>
                                </Popconfirm>
                            </div>
                        </div>
                    </div>
                </Form>
            );
        }

        return (
            <div className={cn('dd-filter-expression-operand__group')}>
                <DDFilterExpressionGroup
                    isInEditMode={isInEditMode}
                    initialExpression={
                        [groupIdentificator ?? {connectBy: 'OR', uid: uid()}, ...operand.slice(1) as DDFilterOperand[]]
                    }
                    ref={expressionRef}
                    isChild
                    remove={() => {
                        remove?.();
                    }}
                    depth={depth + 1}
                    itemIndex={itemIndex}
                />
            </div>
        );
    });
