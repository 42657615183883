import {FormOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import {FormInstance} from 'antd/es/form';
import React, {useRef, useState} from 'react';

import {ModalOpenerComponent} from 'components/modal-opener-component';

import {RowFormatsModalContent} from './row-formats-modal-content';

interface RowFormatsModalOpenerProps {}

export const RowFormatsModalOpener: React.FC<RowFormatsModalOpenerProps> = () => {
    const formRef = useRef<FormInstance>(null);

    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <ModalOpenerComponent
            shouldConfirm={() => !!formRef?.current?.isFieldsTouched()}
            isOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            handleCancel={() => {
                setIsModalOpen(false);
            }}
            handleSave={() => {
                formRef.current?.submit();
            }}
            component={(
                <Button
                    type="primary"
                >
                    <FormOutlined />
                    Настройка форматирования строк
                </Button>
            )}
            modalProps={{
                forceRender: false,
                destroyOnClose: true,
                title: 'Настройка форматирования строк',
                centered: true,
            }}
        >
            <RowFormatsModalContent
                ref={formRef}
                setIsModalOpen={setIsModalOpen}
            />
        </ModalOpenerComponent>
    );
};
