import {EntityState} from '@reduxjs/toolkit';

export type DraggableFramesSliceState = {
    draggableFrames: EntityState<DraggableFrameEntity>;
    lastFrameId: number;
}

export enum DraggableFrameType {
    TableReportAttachmentUploadFrame = 'TableReportAttachmentUploadFrame',
    ApiRequestProgressFrame = 'ApiRequestProgressFrame'
}

export interface DraggableFrameEntity<T=any> {
    type: DraggableFrameType;
    id?: number;
    isOpen?: boolean;
    data?: T;
}
