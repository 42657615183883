import {Tag} from 'antd';
import React from 'react';

import {ReactComponent as ClearIcon} from 'shared/assets/close.svg';

type RawValueType = string | number;
type Key = string | number;

interface LabelValueType {
    key?: Key;
    value?: RawValueType;
    label?: React.ReactNode;
}

type DefaultValueType = RawValueType | RawValueType[] | LabelValueType | LabelValueType[];

interface TagRendererProps {
    label: DefaultValueType;
    onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    closable: boolean;
}

export const TagRenderer = (props: TagRendererProps) => {
    const {
        label, closable, onClose,
    } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };
    return (
        <Tag
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            className="select-field__tag"
            closeIcon={<ClearIcon className="select-field__clear-icon" />}
        >
            {label}
        </Tag>
    );
};
