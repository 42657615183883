export enum FileExtensions {
  XML = '.xml',
  JSON = '.json',
  P7S = '.p7s',
  SIG = '.sig',
  SIGN = '.sign',
  SGN = '.sgn',
  PKCS = '.pkcs',
  BIN = '.bin',
}
