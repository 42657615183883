import {useEffect, useMemo} from 'react';

import {cachedThunk} from 'shared/cache';
import {ThunkCacheTag} from 'shared/cache/thunk-cache';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {UserRoleFunction} from 'store/slices/user-slice/user-slice-role-functions';
import {selectIsLoadingUserFunctions, selectUserFunctions} from 'store/slices/user-slice/user-slice-selectors';
import {loadUserFunctions} from 'store/slices/user-slice/user-slice-thunks';

export interface UseUserFunctionsArgs {
    sections: string[];
}

const loading: {[index: string]: boolean} = {};

export const useUserRoleFunctions = ({
    sections,
}: UseUserFunctionsArgs) => {
    const dispatch = useAppDispatch();

    const userFunctionsLoadingStatuses = useAppSelector(s => selectIsLoadingUserFunctions(s, sections));
    const userFunctions = useAppSelector(s => selectUserFunctions(s, sections));

    const userFunctionsMerged = useMemo(() => {
        if (!userFunctions) return undefined;
        let functions: UserRoleFunction[] = [];
        Object.entries(userFunctions).forEach(([, funcs]) => {
            functions = [...functions, ...(funcs ?? [])];
        });
        return functions;
    }, [userFunctions]);

    const isLoadingUserFunctions = Object.entries(userFunctionsLoadingStatuses).some(([, status]) => status);

    const createUserFunctionsValidator = (section: string) => {
        const hasUserRoleFunction = (userFunction: UserRoleFunction) => userFunctions[section]?.includes(userFunction);

        return {
            hasUserRoleFunction,
        };
    };

    useEffect(() => {
        if (sections[0] !== '') {
            sections.forEach(section => {
                if (!loading[section]) {
                    loading[section] = true;
                    dispatch(cachedThunk(loadUserFunctions, {
                        section,
                    }, {expire: 'never', providesTags: [ThunkCacheTag.USER_FUNCTIONS]})).finally(() => {
                        delete loading[section];
                    });
                }
            });
        }
    }, []);

    const hasUserRoleFunction = (func: UserRoleFunction) => !!userFunctionsMerged?.includes(func);

    return {
        userFunctions,
        userFunctionsLoadingStatuses,
        isLoadingUserFunctions,
        createUserFunctionsValidator,
        hasUserRoleFunction,
    };
};
