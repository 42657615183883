import {DDFilterExpressionDto} from 'components/report-configuration/tabs/report-dd-filters/dd-filter-expression-creator/dd-filter-expression-creator-types';
import {performRequest} from 'shared/utils';

const ddFilterConstructorApiEndpoint = '/table-reports/drill-filter';

export interface FetchDrillDownFilterByIdArgs {
    drillId: number;
}

export const fetchDrillDownFilterById = (
    {drillId}: FetchDrillDownFilterByIdArgs,
) => performRequest<DDFilterExpressionDto>({
    url: [ddFilterConstructorApiEndpoint, drillId].join('/'),
    method: 'GET',
});

export interface PostDrillDownFilterByIdArgs {
    drillId: number;
    filter: DDFilterExpressionDto;
}

export const postDrillDownFilterById = ({drillId, filter}: PostDrillDownFilterByIdArgs) => performRequest<string>({
    url: [ddFilterConstructorApiEndpoint, drillId].join('/'),
    method: 'POST',
    data: filter,
});

export interface DeleteDrillDownFilterByIdArgs {
    drillId: number;
}

export const deleteDrillDownFilterById = ({drillId}: DeleteDrillDownFilterByIdArgs) => performRequest<string>({
    url: [ddFilterConstructorApiEndpoint, drillId].join('/'),
    method: 'DELETE',
});
