import {CloseCircleFilled, PlusCircleOutlined} from '@ant-design/icons/lib';
import {Button, Empty, Form} from 'antd';
import cn from 'classnames';
import React, {useState} from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {DefaultModalOpenerFormListProps} from 'components/report-configuration/report-configuration.types';
import {SwitchFormatFormItem} from 'components/report-configuration/tabs/report-table-settings/modals/switch-format-settings-modal-opener/switch-format-settings-modal-content/switch-format-form-item';
import {ReactComponent as TrashX} from 'shared/assets/trash-x.svg';
import {useAppSelector} from 'store/config/hooks';
import './switch-format-settings-modal-content.less';
import {selectReportConfigurationTableAttributesAsSelectEntries} from 'store/slices/report-configuration-slice/report-configuration-slice';

export const SwitchFormatSettingsModalContent = ({
    form,
    name,
}: DefaultModalOpenerFormListProps) => {
    const tableAttributesEntries = useAppSelector(selectReportConfigurationTableAttributesAsSelectEntries);

    const [selectedRows, setSelectedRows] = useState<number[]>([]);

    return (
        <div className={cn('switch-format-modal-content')}>
            <div className={cn('switch-format-modal-content__inputs-row')}>
                <Form.Item
                    label="Размещение"
                    style={{width: 240}}
                    name={[name, 'align']}
                    rules={[{required: true}]}
                >
                    <CustomSelect
                        settings={{
                            url: 'lookupValue/TABLE_REPORT_CELL_ALIGN',
                            valuePath: 'lookupCode',
                            labelPath: 'meaning',
                            placeholder: 'Выберите вариант',
                            isClearable: true,
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="Стиль"
                    style={{width: 220}}
                    name={[name, 'style']}
                >
                    <CustomSelect
                        settings={{
                            url: 'lookupValue/TABLE_REPORT_CELL_STYLE',
                            valuePath: 'lookupCode',
                            labelPath: 'meaning',
                            placeholder: 'Выберите стиль',
                            isClearable: true,
                        }}
                    />
                </Form.Item>
            </div>
            <Form.List name={[name, 'cases']}>
                {(fields, operations) => (
                    <div>
                        <div className={cn('switch-format-modal-content__buttons')}>
                            <Button
                                disabled={!tableAttributesEntries?.length}
                                type="primary"
                                onClick={() => operations.add()}
                            >
                                <PlusCircleOutlined />
                                Добавить
                            </Button>
                            <Button
                                disabled={!selectedRows.length}
                                type="primary"
                                className="button-critic"
                                onClick={() => {
                                    operations.remove(selectedRows);
                                    setSelectedRows([]);
                                }}
                            >
                                <TrashX />
                                Удалить выбранное
                            </Button>
                            <Button
                                disabled={!fields.length}
                                type="primary"
                                onClick={() => {
                                    operations.remove(fields.map(f => f.name));
                                }}
                            >
                                <CloseCircleFilled />
                                Удалить все
                            </Button>
                        </div>

                        {(() => {
                            if (!fields.length) {
                                return <Empty description="Данные отсутствуют" />;
                            }
                            return null;
                        })()}

                        {fields.map(field => (
                            <SwitchFormatFormItem
                                key={field.name}
                                form={form}
                                name={name}
                                fields={fields}
                                field={field}
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                            />
                        ))}
                    </div>
                )}
            </Form.List>
        </div>
    );
};
