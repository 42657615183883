import {Typography} from 'antd';
import React from 'react';
import './custom-card-title.less';

export interface CustomCardStyleProps {
    titleClassName?: string;
    cardClassName?: string;
}
interface CustomCardTitleProps {
    text?: string;
    extra?: React.ReactNode;
    customCardStyleProps?: CustomCardStyleProps;
}

export const CustomCardTitle: React.FunctionComponent<CustomCardTitleProps> = ({
    text, extra, customCardStyleProps,
}: CustomCardTitleProps) => (
    text ? (
        <div className={customCardStyleProps?.cardClassName
            ? customCardStyleProps?.cardClassName
            : 'custom-card-title'}
        >
            <Typography.Title className={customCardStyleProps?.titleClassName
                ? customCardStyleProps?.titleClassName
                : 'title'}
            >{text}
            </Typography.Title>
            {extra && extra}
        </div>
    ) : null
);
