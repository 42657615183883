import {FieldMeta, FieldType} from 'modules/metadata';

import {COMMON_FIELDS_ITEMS} from '../common/common.fields-items';
import {COMMON_OPTIONS} from '../common/common.options';

const IS_SORTABLE_FIELD = {
    key: 'isSortable',
    label: 'Сортировать',
    type: FieldType.BOOLEAN_CLASSIC,
};

const DATE = [
    COMMON_FIELDS_ITEMS.DATE_FORMAT,
];

const STRING = [
    IS_SORTABLE_FIELD,
];

const NUMBER = [
    IS_SORTABLE_FIELD,
];

const ICON: FieldMeta[] = [
    {
        key: 'fieldStructure',
        label: 'Структура поля',
        hint: 'Определяет, что отображать и в какой последовательности для поля данного типа',
        type: FieldType.LIST,
        defaultItems: [
            {
                key: 'prefixForField',
                label: 'Префикс для поля',
                type: FieldType.STRING,
                hint: 'Подпись, которая будет появляться перед данными, которые отображаются при наведении курсора',
                isRequired: false,
            },
            {
                key: 'fieldKey',
                label: 'Ключ поля',
                type: FieldType.STRING,
                hint: 'Поле значение которого будет отображаться при наведении на иконку',
                isRequired: true,
            },
            {
                key: 'iconName',
                label: 'Название иконки',
                type: FieldType.STATIC_SELECT,
                hint: 'Иконка, которая будет отображаться вместо данных в поле',
                isRequired: true,
                options: COMMON_OPTIONS.ICON_NAMES,
            },
        ],
    },
];

const FILE = [
    IS_SORTABLE_FIELD,
];

const SIGNATURE = [
    IS_SORTABLE_FIELD,
];

const COMBINED_STRING = [
    {
        key: 'stringStructure',
        label: 'Структура строки',
        type: FieldType.TEXTAREA,
        hint: 'В этом поле задается структура строки.\n'
            + 'Строится она так:\n'
            + '1. Название поля для подписи\n'
            + '2. Любой разделитель (не обязательно)\n'
            + '3. Повторить пункт с 1 по 2 пункты по необходимости',
        isRequired: true,
    },
];

const BOOLEAN: FieldMeta[] = [
    {
        key: 'booleanValues',
        label: 'Логические значения',
        type: FieldType.ARRAY_STRING,
        hint: `Если не заданно, то по умолчанию отображается чек-бокс.
        Первое значение отображается для полей с отрицательным значением,
        второе для поля с положительным значением.`,
        isHidden: false,
        isRequired: false,
    },
    IS_SORTABLE_FIELD,
];

export const TABLE_FIELDS = {
    DATE,
    STRING,
    NUMBER,
    ICON,
    FILE,
    SIGNATURE,
    COMBINED_STRING,
    BOOLEAN,
};
