export enum DocumentUploadTemplateMessages {
    CLEAR_FORM_FIELDS_CONFIRM_TEXT = 'Вы уверены, что хотите очистить эту форму? ',
    CLOSE_FORM_CONFIRM_TEXT = 'У вас есть несохраненные изменения, '
        + 'при закрытии формы изменения не будут сохранены. Закрыть форму?',
    ERROR_VALIDATE_FIELD_TEXT = 'Пожалуйста, проверьте корректность ввода данных в таблице'
}
export enum DocumentUploadTemplateTabKey {
    CONFIGURATOR = 'configurator',
    SETTINGS_TEMPLATE_TARGET = 'settingTemplateTarget',
    SETTING_TEMPLATE_TRANSFER_TARGET = 'settingTemplateTransferTarget'
}
export enum DocumentUploadTemplateFormName {
    CONFIGURATOR = 'configurator',
    SETTINGS_TEMPLATE_TARGET = 'settingTemplateTarget',
    SETTING_TEMPLATE_TRANSFER_TARGET= 'settingTemplateTransferTarget'
}

export const documentUploadTemplateFormTitles = {
    [DocumentUploadTemplateFormName.CONFIGURATOR]: 'Конфигуратор шаблонов',
    [DocumentUploadTemplateFormName.SETTINGS_TEMPLATE_TARGET]: 'Настройка шаблона для разбора excel',
    [DocumentUploadTemplateFormName.SETTING_TEMPLATE_TRANSFER_TARGET]:
        'Настройка шаблона для переноса в целевую таблицу',
} as const;
