import React, {useEffect} from 'react';

import {selectEntityData} from 'modules/data';
import {createActionHandlerForDelete} from 'modules/data/data-actions';
import {DirectoryFormEntityData} from 'modules/data/data-types';
import {selectMetadata, loadMetadata as loadMetadataAction, RequestType} from 'modules/metadata';
import {EntityType} from 'shared/constants/entities';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';

import {ActionButton} from '../action-button';
import {ActionConfirmModal} from '../action-confirm-modal/action-confirm-modal';
import {TableActionProps} from '../table-action-types';
import {DEFAULT_DELETE_CONFIRM_TEXT} from './constants/button-delete-tab-constants';

export interface ButtonActionDeleteWithWarningProps extends TableActionProps {
}

export const ButtonActionDeleteTab = ({
    meta,
    entityName,
    wrapper,
    ...props
}: ButtonActionDeleteWithWarningProps) => {
    const dispatch = useAppDispatch();
    const referenceUrl = meta?.referenceUrl || entityName;
    const requestType = meta?.requestType || RequestType.DELETE;
    const entityType = meta?.parentEntityType as EntityType || EntityType.TABS;

    const selectedTab = useAppSelector(state => selectEntityData(
        entityName, entityType,
    )(state) as DirectoryFormEntityData)?.tab;

    const modalMeta = useAppSelector(
        state => selectMetadata(meta?.modalEntityName || '', EntityType.FORM)(state),
    );

    useEffect(() => {
        if (!modalMeta && meta?.modalEntityName) {
            dispatch(loadMetadataAction(meta?.modalEntityName || '', EntityType.FORM));
        }
    }, []);

    const handleDelete = async () => {
        if (selectedTab) {
            await dispatch(createActionHandlerForDelete(
                entityName,
                `${referenceUrl}/${selectedTab}`,
                requestType,
                undefined,
                undefined,
                [entityType],
            ));
        }
    };

    const Component = wrapper || ActionButton;

    return (
        <ActionConfirmModal
            cancelText="Отмена"
            approveText="Удалить"
            modalTitle="Предупреждение"
            confirmModalContent={modalMeta?.title || DEFAULT_DELETE_CONFIRM_TEXT}
            confirmAction={handleDelete}
        >
            {handleClick => (
                <Component
                    {...props}
                    onClick={handleClick}
                    actionIcon={meta.actionIcon}
                >
                    {meta.actionTitle}
                </Component>
            )}
        </ActionConfirmModal>
    );
};
