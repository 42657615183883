import {Form} from 'antd';
import {FormInstance} from 'antd/lib/form';
import {NamePath} from 'antd/lib/form/interface';
import React, {forwardRef, useState} from 'react';

import {CustomFieldsInputs} from 'components/report-configuration/tabs/report-table-settings/components/report-table-custom-fields/custom-fields-inputs/custom-fields-inputs';
import {StateSetter} from 'shared/types/generics';
import {CustomColumnType} from 'store/slices/report-configuration-slice/report-configuration-dto';

interface ReportTableCustomFieldsProps {
    form: FormInstance;
    isDisabled: boolean;
    name: NamePath;
    isHidden?: boolean;
    hiddenFields: Record<string, boolean>;
    setHiddenFields: StateSetter<Record<string, boolean>>;
    isCustomColumnsDisplaying: boolean;
    setCustomColumnsDisplaying: StateSetter<boolean>;
}

export const ReportTableCustomFields = forwardRef(({
    form,
    isDisabled,
    name,
    isHidden,
    hiddenFields,
    setHiddenFields,
    isCustomColumnsDisplaying,
    setCustomColumnsDisplaying,
}: ReportTableCustomFieldsProps, ref) => {
    const [columnsTypes, setColumnsTypes] = useState<Record<string, CustomColumnType | undefined>>({});

    return (
        <Form.List name={name}>
            {(fields, operations, {errors}) => (
                <CustomFieldsInputs
                    ref={ref}
                    form={form}
                    fields={fields}
                    operations={operations}
                    errors={errors}
                    isHidden={isHidden}
                    isDisabled={isDisabled}
                    hiddenFields={hiddenFields}
                    setHiddenFields={setHiddenFields}
                    columnsTypes={columnsTypes}
                    setColumnsTypes={setColumnsTypes}
                    isCustomColumnsDisplaying={isCustomColumnsDisplaying}
                    setCustomColumnsDisplaying={setCustomColumnsDisplaying}
                />
            )}
        </Form.List>
    );
});
