import {Input} from 'antd';
import {PasswordProps} from 'antd/es/input';
import React from 'react';

import {ReactComponent as EyeClosed} from 'shared/assets/forms/eye-closed.svg';
import {ReactComponent as Lock} from 'shared/assets/forms/lock.svg';

import './password.less';

export interface PasswordFieldProps extends PasswordProps {
}

export const Password = ({disabled, ...props}: PasswordFieldProps) => (
    <Input.Password
        autoComplete="off"
        className="input-password"
        iconRender={() => (disabled ? <Lock /> : <EyeClosed />)}
        disabled={disabled}
        {...props}
    />
);
