import {generateAttachmentLink, generateSignatureLink} from 'components/table/columns/file/file-column-utils';
import {CommonMessages} from 'shared/constants/messages';
import {FileType} from 'shared/types/files';
import {downloadFile} from 'shared/utils/request';
import {showMessageFromResponse} from 'shared/utils/show-message-from-response';

export const downloadFileById = (filename: string, attachmentId: string) => {
    downloadFile(`attachment/${attachmentId}`, filename)
        .then(data => {
            if (data.status === 200) {
                showMessageFromResponse({
                    response: data,
                    isError: false,
                    customMessage: CommonMessages.UPLOAD_FILE_SUCCESS,
                });
            }
        })
        .catch(error => {
            showMessageFromResponse({response: error?.response, isError: true});
        });
};

interface GenerateUrlByFileTypeArgs {
    id: string | number;
    type: FileType;
}
export const generateUrlByFileType = ({id, type}: GenerateUrlByFileTypeArgs) => {
    switch (type) {
    case FileType.FILE:
        return generateAttachmentLink(id);
    case FileType.SIGNATURE:
        return generateSignatureLink(id);
    default:
        return null;
    }
};
