import {createApi} from '@reduxjs/toolkit/query/react';

import {axiosBaseQuery} from '../base-query';

export interface GetStatisticsConfirmedRequest {
    entityName?: string;
    params?: Record<string, any>;
}

export const AppControlProceduresResultApi = createApi({
    reducerPath: 'appControlProceduresResult',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['AppStatisticsConfirmed'],
    endpoints: builder => ({
        getAppStatisticsConfirmed: builder.query<any, GetStatisticsConfirmedRequest>({
            query: ({entityName, params}) => ({
                url: `${entityName}/is-statistics-confirmed`,
                method: 'GET',
                params,
            }),
            providesTags: ['AppStatisticsConfirmed'],
        }),
    }),
});

export const {useGetAppStatisticsConfirmedQuery} = AppControlProceduresResultApi;
