import {Input, Popover} from 'antd';
import cn from 'classnames';
import React from 'react';
import {HexColorPicker} from 'react-colorful';

import {ReactComponent as ArrowDown} from 'shared/assets/arrow-down-filled.svg';
import {FormItemProps} from 'shared/types';

import './color-picker.less';

interface ColorPickerProps extends FormItemProps {
    mode?: 'input' | 'font' | 'fill';
}

interface ColorPresetProps {
    color: string;
    setColor: (color: string) => void;
}

const ColorPreset: React.FC<ColorPresetProps> = ({color, setColor}: ColorPresetProps) => (
    <div
        style={{background: color}}
        onClick={() => setColor(color)}
    />
);

enum PopoverPresetColor {
    red = '#FF0000',
    green = '#228B22',
    cyan = '#20B2AA',
    brown = '#8B4513',
    orange = '#FFA500'
}

export const ColorPicker: React.FC<ColorPickerProps> = (
    {
        value,
        onChange,
        mode = 'input',
    }: ColorPickerProps,
) => {
    const setColor = (color: string) => {
        onChange?.(color, undefined);
    };

    return (
        <div
            className={cn('color-picker')}
        >
            <Popover
                overlayClassName="color-picker__popover"
                placement="bottom"
                destroyTooltipOnHide
                trigger="click"
                content={(
                    <div>
                        <HexColorPicker
                            color={value}
                            onChange={newColor => {
                                setColor(newColor.toUpperCase());
                            }}
                        />
                        <div className={cn('color-picker__popover__presets')}>
                            {Object.entries(PopoverPresetColor).map(([, color]) => (
                                <ColorPreset
                                    color={color}
                                    setColor={setColor}
                                    key={color}
                                />
                            ))}
                        </div>

                        <Input
                            placeholder="Введите цвет"
                            value={value}
                            onChange={e => {
                                e.persist();
                                setColor(e.target.value);
                            }}
                        />
                    </div>
                )}
            >

                {(() => {
                    if (mode === 'input') {
                        return (
                            <Input
                                value={value}
                                onChange={e => {
                                    e.persist();
                                    setColor(e.target.value);
                                }}
                            />
                        );
                    }

                    const previewElement = (() => {
                        if (!value) return <div className={cn('color-picker__preview__empty')}>Не задано</div>;
                        if (mode === 'fill') {
                            return (
                                <>
                                    <div
                                        style={{backgroundColor: value}}
                                        className={cn('color-picker__preview__fill')}
                                    />
                                </>
                            );
                        }
                        if (mode === 'font') {
                            return (
                                <>
                                    <div>A</div>
                                    <div
                                        style={{backgroundColor: value}}
                                        className={cn('color-picker__preview__underline')}
                                    />
                                </>
                            );
                        }
                        return null;
                    })();

                    return (
                        <div
                            className={cn('color-picker__preview')}
                        >
                            <div className={cn(
                                'color-picker__preview__main',
                                {'color-picker__preview__main_empty': !value},
                            )}
                            >
                                {previewElement}
                            </div>
                            <div className={cn('color-picker__preview__arrow')}>
                                <ArrowDown style={{
                                    fill: 'rgb(183, 193, 213)', height: 9, width: 9, marginBottom: -1,
                                }}
                                />
                            </div>
                        </div>
                    );
                })()}

            </Popover>
        </div>
    );
};
