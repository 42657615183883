import React from 'react';

import {TIconType} from 'components/dynamic-icon/dynamic-icon';
import {SimpleActionButton} from 'components/form/components';
import {ButtonType} from 'modules/metadata/metadata-types';
import {useAppDispatch} from 'store/config/hooks';
import {openModal} from 'store/slices/modals-slice';
import {RequestStatus} from 'store/slices/request-slice/request-slice-type';

export interface OpenModalRequestProps {
    actionTitle: string;
    requestCommentId?: string;
    requestFormKey: string;
    buttonType: ButtonType;
    modalName: string;
    modalEntityName: string;
    status: RequestStatus;
    actionIcon?: TIconType;
    isFileComponent?: boolean;
}

export const OpenModalRequest = ({
    actionTitle,
    actionIcon,
    modalName,
    modalEntityName,
    buttonType,
    status,
    requestFormKey,
    requestCommentId,
    isFileComponent,
}: OpenModalRequestProps) => {
    const dispatch = useAppDispatch();
    const handleOpenChildModal = () => {
        dispatch(openModal({
            modalName,
            entityName: modalEntityName,
            modalParameters: {
                status, requestFormKey, requestCommentId, isFileComponent,
            },
        }));
    };

    return (
        <SimpleActionButton
            type={buttonType || 'primary'}
            icon={actionIcon}
            title={actionTitle}
            onClick={handleOpenChildModal}
        />
    );
};
