import {useEffect, useRef, useState} from 'react';

export const useStickyTableHeader = (isAsideMenuEnabled = false, initialPinned = true) => {
    const [isTableHeaderPinned, setIsTableHeaderPinned] = useState(initialPinned);
    const [theadWidth, setTheadWidth] = useState(0);
    const [scrollY, setScrollY] = useState(0);
    const [actionsHeight, setActionsHeight] = useState(0);
    const [collapseHeight, setCollapseHeight] = useState(0);
    const tableRef = useRef<HTMLDivElement>(null);

    /**
     * useEffect hook для обработки прокрутки страницы, вычисление позиции скролла
     *  и добавления класса 'sticky-header' к заголовку таблицы.
     *
     * @function
     * @returns {void}
     */
    useEffect(() => {
        let scrollTimeout: number | null = null;
        const handleScroll = () => {
            if (scrollTimeout) {
                cancelAnimationFrame(scrollTimeout);
            }
            scrollTimeout = requestAnimationFrame(() => {
                setScrollY(window.scrollY);
                const table = tableRef.current;

                if (table) {
                    const thead = table.querySelector('.ant-table-thead');
                    const pinButton = table.querySelector('.pin-button');

                    const tableActions = table.querySelector('.table-report-actions__wrapper') as HTMLElement;
                    if (tableActions) setActionsHeight(tableActions.offsetHeight);

                    const collapse = table.querySelector('.ant-collapse-item') as HTMLElement;
                    if (collapse) setCollapseHeight(collapse.offsetHeight);

                    if (window.scrollY > 320) {
                        thead?.classList.add('sticky-header');
                        pinButton?.classList.add('pin-button_sticky');
                    } else {
                        thead?.classList.remove('sticky-header');
                        pinButton?.classList.remove('pin-button_sticky');
                    }
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            if (scrollTimeout) {
                cancelAnimationFrame(scrollTimeout);
            }
        };
    }, []);

    /**
     * Эффект для наблюдения за изменением ширины таблицы и динамического обновления размера заголовка таблицы.
     *
     * @function
     * @returns {void}
     */
    useEffect(() => {
        if (!tableRef.current) {
            return () => {};
        }

        const table = tableRef.current.querySelector('.table-report__table-container') as HTMLElement;

        const resizeObserver = new ResizeObserver(() => {
            if (table !== null && table.offsetWidth !== theadWidth) {
                setTheadWidth(table.offsetWidth);
            }
        });

        resizeObserver.observe(tableRef.current);

        return () => {
            resizeObserver.disconnect();
        };
    },
    [tableRef.current]);

    const handlePinButtonClick = () => {
        if (isTableHeaderPinned) {
            const table = tableRef.current;
            if (table) {
                const tooltip = table.querySelector('.ant-tooltip-inner') as HTMLDivElement;

                const mouseOverEvent = new MouseEvent('mouseover', {
                    bubbles: true,
                    cancelable: true,
                });

                const mouseOutEvent = new MouseEvent('mouseout', {
                    bubbles: true,
                    cancelable: true,
                });

                tooltip.dispatchEvent(mouseOverEvent);
                setTimeout(() => {
                    tooltip.dispatchEvent(mouseOutEvent);
                }, 10);
            }
        }

        setIsTableHeaderPinned(!isTableHeaderPinned);
    };

    const theadWidthBreakpoint = isAsideMenuEnabled ? 1264 : 1434;

    const stickyTheadStyles = `
    .ant-table-thead.sticky-header {
        top: ${(scrollY - actionsHeight - collapseHeight - (
        theadWidth >= theadWidthBreakpoint ? 116 : 56
    )).toFixed(1)}px;
    }
    .ant-table-thead.sticky-header::before {
        width: ${theadWidth + 2}px;
        height: ${theadWidth >= theadWidthBreakpoint ? 60 : 80}px;
    }
    `;

    return {
        tableRef,
        isTableHeaderPinned,
        handlePinButtonClick,
        setIsTableHeaderPinned,
        stickyTheadStyles,
    };
};
