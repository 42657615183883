import React, {useMemo} from 'react';

import {FileUploadStatus} from 'modules/documents/documents-types';
import {ReactComponent as UploadFail} from 'shared/assets/fail.svg';
import {ReactComponent as UploadSuccess} from 'shared/assets/success.svg';

import './progress-cell.less';

interface IOwnProps {
    percent: number;
    status?: FileUploadStatus;
}

export const ProgressCell = ({percent, status}: IOwnProps) => {
    const renderNode = useMemo(() => {
        switch (status) {
        case FileUploadStatus.SUCCESS:
            return <UploadSuccess />;
        case FileUploadStatus.EXCEPTION:
            return <UploadFail />;
        default:
            return undefined;
        }
    }, [status]);

    return (
        <div className="progress-cell">{
            renderNode || (percent === 99 ? <UploadSuccess /> : `${percent}%`)
        }
        </div>
    );
};
