import React from 'react';

import {ModalOpenerComponent, ModalOpenerComponentRef} from 'components/modal-opener-component';
import {StateSetter} from 'shared/types/generics';
import {showMessage} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {removeSinglePUDDocument} from 'store/slices/ais-slice/ais-slice-thunks';
import {AisRegisterDocumentDto} from 'store/slices/ais-slice/ais-slice-types';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

interface PUDDeleteConfirmationModalProps {
    registerDocument: AisRegisterDocumentDto;
}

export const PUDDeleteConfirmationModal = React.forwardRef<ModalOpenerComponentRef, PUDDeleteConfirmationModalProps>((
    {registerDocument}: PUDDeleteConfirmationModalProps,
    ref,
) => {
    const dispatch = useAppDispatch();

    const isDeleting = useAppSelector(s => selectIsThunkPending(s, removeSinglePUDDocument.typePrefix));

    const handleDelete = (setIsModalOpen: StateSetter<boolean>) => {
        const {aisDocId, id} = registerDocument;
        dispatch(removeSinglePUDDocument({
            aisDocId,
            id,
        }))
            .unwrap()
            .then(() => {
                setIsModalOpen(false);
                showMessage({message: 'ПУД успешно удалён'});
            }, () => {
                showMessage({
                    message: 'Во время удаления ПУД произошла ошибка',
                    isError: true,
                });
            });
    };

    return (
        <ModalOpenerComponent
            ref={ref}
            modalProps={{
                forceRender: false,
                destroyOnClose: true,
                title: 'Подтверждение',
                centered: true,
            }}
            controlLabels={{
                save: 'Удалить',
            }}
            loadingControls={{
                save: isDeleting,
            }}
            disabledControls={{
                save: isDeleting,
            }}
            handleSave={setIsModalOpen => {
                handleDelete(setIsModalOpen);
            }}
        >
            Вы действительно хотите удалить ПУД ({registerDocument.aisDocId})?
        </ModalOpenerComponent>
    );
});
