import {FormInstance} from 'antd/es/form';
import React from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {InputString} from 'components/form/inputs/input-string';
import {Password} from 'components/form/inputs/password/password';
import {StaticSelect} from 'components/form/inputs/static-select';
import {TimePickerField} from 'components/form/inputs/time-picker/time-picker-field';
import {DateCell} from 'components/table/columns/date-cell/date-cell';
import {DATE_DMY} from 'shared/constants/date-format';

import {ENTRIES_LABEL, PropertyType} from './property-settings-field.constants';

interface PropertySettingsField {
    defaultValue?: string | number | null;
    selectValue?: Array<string> | null;
    disabled?: boolean;
    validateRegexp?: string | null;
    additional?: Record<string, any>;
    propertyCode: string;
    form?: FormInstance;
    propertyType?: string| null;
    propertySource?: string| null;
}

export const propertySettingsFieldResolver = ({
    selectValue, disabled, additional, propertyCode, form, propertyType, propertySource,
} : PropertySettingsField) => {
    switch (propertyType) {
    case PropertyType.MULTIPLE_SELECT:
        return (props: any) => (
            <CustomSelect
                settings={
                    {
                        showSearch: true,
                        url: propertySource,
                        labelPath: 'meaning',
                        valuePath: 'id',
                        isDisabled: disabled,
                        formFieldKey: propertyCode,
                        formInstance: form,
                        mode: 'multiple',
                    }
                }
                {...props}
            />
        );
    case PropertyType.SELECT:
        return ({value, ...props}: any) => (
            <CustomSelect
                additionalEntries={additional?.additionalEntries}
                settings={{
                    showSearch: true,
                    url: propertySource,
                    labelPath: 'meaning',
                    valuePath: 'id',
                    isDisabled: disabled,
                    formFieldKey: propertyCode,
                    formInstance: form,
                }}
                value={value ? Number(value) : value}
                {...props}
            />
        );
    case PropertyType.PASSWORD:
        return (props: Record<string, any>) => (
            <Password
                disabled={disabled}
                {...props}
            />
        );
    case PropertyType.CLOCK:
        return (props: any) => (
            <TimePickerField
                {...props}
                disabled={disabled}
            />
        );
    case PropertyType.DATE:
        return (props: any) => (
            <DateCell
                {...props}
                defaultValue={disabled}
                disabled={disabled}
                format={DATE_DMY}
                requestFormat={DATE_DMY}
            />
        );
    case PropertyType.MAP_SELECT: {
        const entries = propertySource ? JSON.parse(propertySource) : {};
        const normaliseEntries = Object.keys(entries).map(key => ({value: key, label: entries[key]}));
        return ({value, ...props}: any) => (
            <StaticSelect
                options={normaliseEntries}
                value={value}
                disabled={disabled}
                {...props}
            />
        );
    }

    default:
        if (selectValue?.length && selectValue?.length > 1) {
            const entries = selectValue.map(value => ({value, label: ENTRIES_LABEL[value] || value}));
            return ({value, ...props}: any) => (
                <StaticSelect
                    options={entries}
                    value={value}
                    disabled={disabled}
                    {...props}
                />
            );
        }
        return ({value, ...props}: any) => (
            <InputString
                disabled={disabled}
                value={value}
                autoComplete="off"
                {...props}
            />
        );
    }
};
