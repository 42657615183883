import {isTruthy, performRequest} from 'shared/utils';

import {DescriptiveFlexfieldSegment} from './descriptive-flexfields-slice-types';

const DFFEndpoint = 'dff';

export interface FetchDFFColumnsRequestParams {
    contextCode: string;
    dffName: string;
}

export interface FetchDFFColumnsArgs {
    params: FetchDFFColumnsRequestParams;
    prefix?: string;
}

export type FetchDDContextDFFColumnsRequestParams = Omit<FetchDFFColumnsRequestParams, 'dffName'>;

export const fetchDFFColumns = (
    {prefix, params}: FetchDFFColumnsArgs,
) => performRequest<DescriptiveFlexfieldSegment[]>({
    url: [prefix, DFFEndpoint, 'columns'].filter(isTruthy).join('/'),
    method: 'GET',
    params,
});
