import {ValidationRules} from 'modules/metadata/metadata-types';
import {ReportConfigurationQueryParameterVariableType} from 'store/slices/report-configuration-slice/report-configuration-dto';
import {TableReportFilterFieldType} from 'store/slices/table-report-slice/table-report-slice-types';

export const filterDefaultWidthResolver = (type: ReportConfigurationQueryParameterVariableType) => {
    switch (type) {
    case TableReportFilterFieldType.STRING:
        return 292;
    case TableReportFilterFieldType.INTEGER:
    case TableReportFilterFieldType.NUMBER:
        return 140;
    case TableReportFilterFieldType.DATE:
        return 140;
    case TableReportFilterFieldType.DATETIME:
        return 200;
    case TableReportFilterFieldType.VALUE_SET:
    case TableReportFilterFieldType.MULTI_VALUE_SET:
    case TableReportFilterFieldType.SQL_VALUE_SET:
    case TableReportFilterFieldType.SQL_MULTI_VALUE_SET:
        return 250;
    default:
        return undefined;
    }
};

export const tableReportFilterFieldValidationResolver = (type: ReportConfigurationQueryParameterVariableType) => {
    const rules: ValidationRules[] = [];
    if (type === TableReportFilterFieldType.INTEGER) {
        rules.push({regExp: '^-{0,1}[\\d]+$', errorMessage: 'Поле должно содержать только цифры'});
    }
    if (type === TableReportFilterFieldType.NUMBER) {
        rules.push({
            regExp: '^[-]?([0-9]*[.])?[0-9]+$',
            errorMessage: 'Поле должно содержать только числовое значение',
        });
    }
    return rules;
};
