import {FormInstance} from 'antd/es/form';
import {flatten} from 'lodash';

import {DocumentToSignInDatabase} from './crypto-pro-signing-input-types';

interface GetOverlappingFilesArgs {
    fileFieldNames?: string[];
    currentValue?: File[];
    form?: FormInstance;
    documentsToSignInDB?: DocumentToSignInDatabase[];
    fileList?: File[];
}

export const getOverlappingFiles = ({
    currentValue,
    fileFieldNames,
    form,
    documentsToSignInDB,
    fileList,
}: GetOverlappingFilesArgs) => {
    const filesToSign: File[] | undefined = (() => {
        const filesFromForm = fileFieldNames
            ?.map(fieldName => form?.getFieldValue(fieldName)).filter(v => !!v) ?? [];

        if (!fileList) return filesFromForm;
        return [...filesFromForm, ...fileList];
    })();

    const filesToSignFlat = flatten(filesToSign);
    const fileNamesInDBToSign = (documentsToSignInDB ?? []).map(doc => doc.documentName);

    const currentFileNames = (currentValue ?? []).map(file => file.name);

    const overlappingFiles = filesToSignFlat?.filter(file => currentFileNames.includes(`${file.name}.sig`));
    const overlappingFileNamesInDB = fileNamesInDBToSign
        ?.filter(fileName => currentFileNames.includes(`${fileName}.sig`));

    return {
        overlappingFiles,
        overlappingFileNamesInDB,
    };
};
