import {Collapse} from 'antd';
import cn from 'classnames';
import React from 'react';

import {DesignTemplates} from 'components/table-report/table-report-types';
import {ReactComponent as AccordionIcon} from 'shared/assets/accordion-icon.svg';
import {useAppSelector} from 'store/config/hooks';
import {
    selectTableReportHeaders,
    selectTableReportTemplateConfig,
} from 'store/slices/table-report-slice/table-report-slice-selectors';

import './collapsible-header.less';

interface CollapsibleHeaderProps {
    reportName?: string;
    designTemplate?: string;
    children?: React.ReactNode;
}

export const CollapsibleHeader: React.FC<CollapsibleHeaderProps> = (
    {children, reportName, designTemplate}: CollapsibleHeaderProps,
) => {
    const headers = useAppSelector(selectTableReportHeaders);
    const {queryParameters} = useAppSelector(selectTableReportTemplateConfig) || {};
    const hasVisibleParameters: boolean = !!queryParameters && queryParameters.some(param => param.visible);
    const hasHeaders = !!(headers.caption || headers.others?.length);
    const isCollapsible = (
        (designTemplate === DesignTemplates.TABLE_REPORT_DESIGN_TEMPLATE_WITH_PARAMETER && hasVisibleParameters)
        || (designTemplate !== DesignTemplates.TABLE_REPORT_DESIGN_TEMPLATE_WITH_PARAMETER && hasHeaders)
    );

    return (
        <Collapse
            className={cn('collapsible-header', {collapsible: isCollapsible})}
            ghost
            defaultActiveKey={isCollapsible ? ['1'] : undefined}
            expandIcon={() => <AccordionIcon />}
        >
            <Collapse.Panel
                key="1"
                header={reportName}
                disabled={!isCollapsible}
                showArrow={isCollapsible}
            >
                {children}
            </Collapse.Panel>
        </Collapse>
    );
};
