import Column from 'antd/es/table/Column';
import ColumnGroup from 'antd/es/table/ColumnGroup';
import cn from 'classnames';
import React from 'react';
import {WorkSheet, utils as xlsxUtils} from 'xlsx';

import {HIDDEN_CELL_VALUE} from 'components/report-configuration/tabs/report-table-settings/modals/excel-header-structure-loader-modal/excel-header-structure-loader-modal.constants';
import {
    extractIndexesFromKey,
    getMultiRowHeaderCellStyle,
    getTreeMaxColumnIndex,
} from 'components/table-report/table-report-columns/table-report-columns-utils';

import {
    ExcelRawData,
    FlattenedHeaderCell,
    FlattenedHeaderData,
    FormHeaderCell,
    HeaderStructureFormData,
    PreviewTableData,
    TableHeaderCell,
    TableHeaderStructure,
} from './excel-header-structure-loader-modal.types';

/**
 * Формтрует объекты с вычисленной длинной объедененных ячеек, добавляет скрытые ячейки
 * для правильного отображения высоты ячеек фреймворком
 * @param headersData - таблица данных из файла
 * @returns - двумерный массив объектов, готовых для построения дерева шапки
 */
export const flattenHeaderData = (headersData: ExcelRawData): FlattenedHeaderData => (
    headersData.reduce<FlattenedHeaderData>((acc, row, rowIndex) => {
        row?.forEach((cellValue, colIndex) => {
            const generatedKey = `${rowIndex}-${colIndex}-${cellValue}`;
            const flattenedCell: FlattenedHeaderCell = {
                title: cellValue,
                key: generatedKey,
                cellHeight: 1,
            };
            if (acc[colIndex]) {
                if (acc[colIndex]?.some(o => o.title === cellValue)) { // vertically-nested cell
                    const nestedCell = acc[colIndex]?.find(o => o?.title === cellValue);
                    if (nestedCell?.cellHeight) {
                        nestedCell.cellHeight += 1;
                    }
                    acc[colIndex]?.push({
                        ...flattenedCell,
                        cellHeight: -1, // cell is hidden
                    });
                } else {
                    acc[colIndex]?.push(flattenedCell);
                }
            } else if (!acc[colIndex]) {
                acc[colIndex] = [flattenedCell];
            }
        });
        return acc;
    }, [])
);

/**
 * Из подготовленных к конвертации сырых данных формирует вложенность объедененных ячеек
 * @param flattenedHeaderData - подготовленные для конвертации сырые данные из файла
 * @returns - дерево столбцов шапки таблицы для Antd.Table
 */
export const convertFlattenedDataToHeaderTree = (flattenedHeaderData: FlattenedHeaderData): TableHeaderStructure => (
    flattenedHeaderData.reduce<TableHeaderStructure>((acc, row) => {
        if (row?.length > 1) {
            let currentArr = acc;
            row.forEach((cell, colIndex, arr) => {
                let parentNode = currentArr.find(h => h?.title === cell?.title);
                if (!parentNode) {
                    parentNode = {
                        title: cell?.title,
                        key: cell?.key,
                        cellHeight: cell?.cellHeight,
                    };
                    currentArr.push(parentNode);
                }
                if (colIndex < arr.length - 1) {
                    if (!parentNode.children) {
                        parentNode.children = [];
                    }
                    currentArr = parentNode.children;
                }
            });
        } else {
            const topCell = row[0];
            acc.push({
                ...topCell,
                key: topCell?.key,
                cellHeight: topCell?.cellHeight,
            });
        }
        return acc;
    }, [])
);

/**
 * Конвертирует сырые данные их файла в дерево, пригодное для отображение шапки таблицы в Antd.Table
 * @param headerData - двумерный массив строк - данных файла
 * @returns - дерево столбцов шапки таблицы для Antd.Table
*/
export const constructHeaderTree = (headerData: ExcelRawData | undefined): TableHeaderStructure => {
    if (!headerData) {
        return [];
    }
    const flattenedHeaderData = flattenHeaderData(headerData);
    return convertFlattenedDataToHeaderTree(flattenedHeaderData);
};

export const convertExcelRawDataToTemporaryTableData = (rawData: string[][]): PreviewTableData => {
    const generatedColumns = rawData[0]?.map((el, index) => ({
        title: `temp_col_${index}`,
        dataIndex: `temp_col_${index}`,
        key: `temp_col_${index}`,
    }));

    const dataSource = rawData
        ?.filter(row => row?.filter(el => el !== null)?.length > 0)
        ?.map((row, index) => {
            const convertedRow = row?.map((el, i) => ({
                [`temp_col_${i}`]: el,
            }));

            const mergedRow = Object.assign({}, ...convertedRow);

            return {
                key: index,
                ...mergedRow,
            };
        });

    return {
        dataSource,
        columns: generatedColumns,
    };
};

export const markMergedCells = (sheet: WorkSheet): WorkSheet => {
    const modifiedSheet = {...sheet};
    if (modifiedSheet['!merges']) {
        modifiedSheet['!merges']?.forEach(merge => {
            const value = xlsxUtils.encode_range(merge).split(':')[0];
            for (let col = merge.s.c; col <= merge.e.c; col += 1) {
                for (let row = merge.s.r; row <= merge.e.r; row += 1) {
                    modifiedSheet[xlsxUtils.encode_col(col) + (row + 1)] = modifiedSheet[value];
                }
            }
        });
    }
    return modifiedSheet;
};

interface ConvertHeaderRowIntoColumnGroupArgs {
    node: TableHeaderCell;
    firstRowLastColumnIndex: number | undefined;
    maxColumnIndex: number | undefined;
}

const convertHeaderRowIntoColumnGroup = ({
    node, firstRowLastColumnIndex, maxColumnIndex,
}: ConvertHeaderRowIntoColumnGroupArgs) => {
    const rowSpan = Number(node?.cellHeight) > 1 ? Number(node?.cellHeight) : undefined;
    const {row: cellRowIndex, column: cellColumnIndex} = extractIndexesFromKey(node.key) ?? {};

    const cellStyle = getMultiRowHeaderCellStyle({
        cellRowIndex,
        cellColumnIndex,
        firstRowLastColumnIndex,
        maxColumnIndex,
        borderColor: '#A9A9A9',
    });

    const columnProps = {
        className: cn('selected-header-rows-preview__column', cellStyle?.borderClassname),
        title: node?.title,
        key: node?.key,
        ellipsis: true,
        rowSpan: Number(node?.cellHeight) === -1 ? 0 : rowSpan,
        onHeaderCell: () => ({
            style: {
                className: 'borderless',
                ...cellStyle?.style,
            },
        }),
    };

    if (node?.children?.length) {
        return (
            <ColumnGroup {...columnProps}>
                {node?.children?.map(child => (
                    convertHeaderRowIntoColumnGroup({
                        node: child,
                        firstRowLastColumnIndex,
                        maxColumnIndex,
                    })
                ))}
            </ColumnGroup>
        );
    }
    return (
        <Column
            {...columnProps}
            width={150}
        />
    );
};

export const renderColumns = (tree: TableHeaderStructure | undefined) => {
    if (!tree) {
        return undefined;
    }

    const {column: firstRowLastColumnIndex} = extractIndexesFromKey(tree[tree.length - 1]?.key) ?? {};
    const maxColumnIndex = getTreeMaxColumnIndex(tree);

    return tree.map(node => convertHeaderRowIntoColumnGroup({node, firstRowLastColumnIndex, maxColumnIndex}));
};

export const findNodeAndModify = (tree: TableHeaderStructure, keyToFind: string, modifiedValue: FormHeaderCell) => (
    JSON.stringify(tree, (_, nestedValue) => {
        if (nestedValue && String(nestedValue?.key) === keyToFind) {
            nestedValue.title = modifiedValue?.title;
            nestedValue.attribute = modifiedValue?.attribute;
        }
        return nestedValue;
    })
);

export const mapFormDataToTree = (tree: TableHeaderStructure | undefined, formData: HeaderStructureFormData) => {
    if (!tree) {
        return null;
    }
    const mappedTree = JSON.parse(JSON.stringify(tree));
    Object.keys(formData)
        .forEach(k => findNodeAndModify(mappedTree, k, formData[k]));
    return mappedTree;
};

// скрытый узел (cellHeight = -1) это, по сути, костыль для фреймворка antd и его Table
// у него нет значащих полей, он просто добавляет новый уровень вложенности и нужен чтобы увеличить высоту ячейки
// у скрытого узла может быть либо 1 внутренний скрытый потомок, либо неограниченное кол-во видимых
export const getFirstHiddenNodeWithVisibleChildrens = (node: TableHeaderCell): TableHeaderCell | null => {
    const hasVisibleChildrens = node?.children?.some(ch => Number(ch?.cellHeight) !== -1);
    if (Number(node?.cellHeight) === HIDDEN_CELL_VALUE && hasVisibleChildrens) {
        return node;
    }
    if (Number(node?.cellHeight) === HIDDEN_CELL_VALUE && node?.children?.length === 1) {
        return getFirstHiddenNodeWithVisibleChildrens(node?.children[0]);
    }
    return null;
};

export const checkAttributeForUniqueness = (formData: any, valueToCheck: string) => {
    if (!valueToCheck) {
        return true;
    }

    let attributeMatchesCount = 0;

    Object.keys(formData)
        .forEach((k: any) => {
            if (formData[k]?.attribute === valueToCheck) {
                attributeMatchesCount += 1;
            }
        });
    return attributeMatchesCount <= 1;
};

// возвращает видимых потомков узла, если есть
export const filterHiddenChildren = (node: TableHeaderCell | null): TableHeaderCell[] | null => {
    if (!node || !node?.children) {
        return null;
    }

    let currentNode = node;
    // у скрытого узла может быть либо 1 внутренний скрытый потомок, либо неограниченное кол-во видимых
    while (currentNode?.children?.length === 1 && currentNode.children[0]?.cellHeight === HIDDEN_CELL_VALUE) {
        [currentNode] = currentNode.children;
    }

    return currentNode
        ? currentNode?.children ?? null
        : null;
};
