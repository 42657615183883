import cn from 'classnames';
import get from 'lodash/get';
import React from 'react';
import {useHistory} from 'react-router-dom';

import {DynamicIcon} from 'components/dynamic-icon';
import {FieldMeta} from 'modules/metadata';

import {ColumnFormatterProps} from '../../table-types';

import './report-watcher.less';

interface ReportWatcherProps extends ColumnFormatterProps {
    meta: FieldMeta;
}

export const ReportWatcher = ({meta, value: url, record}: ReportWatcherProps) => {
    const history = useHistory();
    const fieldStructureEl = meta?.fieldStructure && meta.fieldStructure[0];
    return (
        <div
            className={cn('report-watcher', {disabled: !url})}
            onClick={() => {
                if (url) {
                    history.push(url);
                }
            }}
        >
            {fieldStructureEl?.iconName && (
                <div className="report-watcher__icon">
                    <DynamicIcon
                        type={fieldStructureEl.iconName}
                        style={{fontSize: 16}}
                    />
                </div>
            )}
            {fieldStructureEl?.fieldKey ? get(record, fieldStructureEl.fieldKey) : 'Просмотреть документ'}
        </div>
    );
};
