import {Form, Input} from 'antd';
import React from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {DescriptiveFlexfieldSegment} from 'store/slices/descriptive-flexfields-slice/descriptive-flexfields-slice-types';

interface SegmentResolverComponentProps {
    segment?: DescriptiveFlexfieldSegment;
    options?: {
        isDisabled?: boolean;
        mode?: 'create' | 'read';
    };
}

export const SegmentResolverComponent: React.FC<SegmentResolverComponentProps> = (
    {segment, options = {}}: SegmentResolverComponentProps,
) => {
    const {isDisabled, mode} = options;

    if (!segment?.displayed) return null;
    return (
        <Form.Item
            rules={[{required: segment?.required}]}
            label={segment.segmentUserName}
            name={segment.segmentColumnName.toLowerCase()}
        >
            {
                (() => {
                    const {valuesetLookup} = segment;
                    if (valuesetLookup && valuesetLookup.simpleListFlag) {
                        return (
                            <CustomSelect
                                settings={{
                                    isDisabled: isDisabled || !segment.enabled,
                                    placeholder: mode === 'create' ? 'Выберите значение' : 'Значение не задано',
                                    isClearable: !segment?.required,
                                    showNullValueAsUndefined: true,
                                    showEmptyStringAsUndefined: true,
                                    setNullInsteadOfUndefinedOnChange: true,
                                }}
                                entries={valuesetLookup
                                    .values
                                    .map(({meaning, lookupCode}) => ({
                                        label: meaning,
                                        value: lookupCode,
                                    })) ?? []}
                            />
                        );
                    }
                    return (
                        <Input
                            disabled={isDisabled || !segment.enabled}
                            placeholder={mode === 'create' ? 'Введите значение' : 'Значение не задано'}
                        />
                    );
                })()
            }

        </Form.Item>
    );
};
