import {Watcher, WatcherFieldFlag} from '../../hooks/use-form-fields-manager';

export const createReportConfiguratorWatchers = ({
    isEditingForm,
    valuesReferenceUrls,
}: {
    isEditingForm: boolean;
    valuesReferenceUrls: {[index: string]: string | undefined | null};
}) => ({
    paginationSheetCode: [{
        condition: ({formValues}) => formValues.enabledMenu,
        flag: WatcherFieldFlag.isRequired,
    }],
    buttonPaginationSettings: [{
        isNotInput: true,
        condition: () => false,
        flag: WatcherFieldFlag.isDisabled,
    }],
    buttonHeaderSettings: [{
        isNotInput: true,
        condition: ({formValues}) => !formValues.enabledMenu,
        flag: WatcherFieldFlag.isDisabled,
    }],
    buttonEnabledDownloadBtn: [{
        isNotInput: true,
        condition: ({formValues}) => !formValues.enabledDownloadBtn,
        flag: WatcherFieldFlag.isDisabled,
    }],
    buttonEnabledGroupingSettingsBtn: [{
        isNotInput: true,
        condition: ({formValues}) => !formValues.enabledGroupingSettingsBtn,
        flag: WatcherFieldFlag.isDisabled,
    }],
    buttonActionsEnabled: [{
        isNotInput: true,
        condition: ({formValues}) => !formValues.actionsEnabled,
        flag: WatcherFieldFlag.isDisabled,
    }],
    buttonEnabledCrSettings: [{
        isNotInput: true,
        condition: ({formValues}) => !formValues.enabledCr,
        flag: WatcherFieldFlag.isDisabled,
    }],
    buttonEnabledComparison: [{
        isNotInput: true,
        condition: ({formValues}) => !formValues.enabledComparison,
        flag: WatcherFieldFlag.isDisabled,
    }],
    tableDatasourceSqlValue: [{
        condition: ({formValues}) => formValues.tableDatasourceType !== 'SQL',
        flag: WatcherFieldFlag.isHidden,
    },
    {
        condition: () => !isEditingForm,
        flag: WatcherFieldFlag.isDisabled,
    }],
    headerDatasourceSqlValue: [{
        condition: ({formValues}) => formValues.headerDatasourceType !== 'SQL',
        flag: WatcherFieldFlag.isHidden,
    },
    {
        condition: () => !isEditingForm,
        flag: WatcherFieldFlag.isDisabled,
    }],
    tableDatasourceValue: [{
        condition: ({formValues}) => formValues.tableDatasourceType === 'SQL'
                || !formValues.tableDatasourceType,
        flag: WatcherFieldFlag.isHidden,
    },
    {
        condition: () => !isEditingForm,
        flag: WatcherFieldFlag.isDisabled,
    },
    {
        condition: () => !valuesReferenceUrls.tableDatasourceValue,
        flag: WatcherFieldFlag.isLoading,
    }],
    headerDatasourceValue: [{
        condition: ({formValues}) => formValues.headerDatasourceType === 'SQL'
                || !formValues.headerDatasourceType,
        flag: WatcherFieldFlag.isHidden,
    },
    {
        condition: () => !isEditingForm,
        flag: WatcherFieldFlag.isDisabled,
    },
    {
        condition: () => !valuesReferenceUrls.headerDatasourceValue,
        flag: WatcherFieldFlag.isLoading,
    }],
    // ---
    ...(() => Object.fromEntries([
        'templateName',
        'templateCode',
        'reportName',
        'designTemplate',
        'paginationPosition',
        'enabledMenu',
        'withDocumentId',
        'enabledComparisonBtn',
        'enabledCrBtn',
        'enabledColumnsSettingsBtn',
        'enabledRefreshBtn',
        'enabledSearch',
        'enabledDownloadBtn',
        'enabledGroupingSettingsBtn',
        'actionsEnabled',
        'tableDatasourceType',
        'headerDatasourceType',
        'enabledRowComments',
        'enabledRowAttachments',
        'enabledRowRequests',
        'enabledComparison',
        'enabledCr',
        'enabledColumnTitleOrdinals',
        'enabledPrint',
    ].map(fieldName => [fieldName, [{
        condition: () => !isEditingForm,
        flag: WatcherFieldFlag.isDisabled,
    }]])))(),
} as {[index: string]: Watcher[]});
