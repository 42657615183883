import {PayloadAction} from '@reduxjs/toolkit';

import {ColumnSortData} from 'components/@common/widgets/custom-table/table-column-menu';

import {TableReportSliceState} from '../table-report-slice-types';

export const createTableReportSortReducer = <S extends TableReportSliceState>() => ({
    setSortData(state: S, {payload}: PayloadAction<ColumnSortData>) {
        state.reportOptions.sort = payload.sort;
        state.reportOptions.noFetchWithSort = !!payload.noFetch;
    },
    resetSort(state: S, {payload}: PayloadAction<{noFetch: boolean}>) {
        state.reportOptions.sort = {};
        state.reportOptions.noFetchWithSort = !!payload.noFetch;
    },
});
