import {ActionReducerMapBuilder, isFulfilled} from '@reduxjs/toolkit';

import {ReportConfigurationSliceState} from '../report-configuration-types';
import {
    loadTableReportConfiguration,
    updateTableReportConfiguration,
} from './report-configuration-thunks';

export const createTableReportConfigurationExtraReducer = (
    builder: ActionReducerMapBuilder<ReportConfigurationSliceState>,
) => {
    builder.addCase(loadTableReportConfiguration.fulfilled, (state, {payload}) => {
        const {
            headerAttributes,
            reportConfigurationConvertedData,
            tableAttributes,
        } = payload;

        state.reportConfigurationData = reportConfigurationConvertedData;

        if (!state.reportConfigurationData.designTemplate) {
            state.reportDependencies.templateBlocks = 0;
        }
        state.reportDependencies.headerAttributes = headerAttributes;
        state.reportDependencies.tableAttributes = tableAttributes;
    });
};

export const createTableReportConfigurationExtraReducerMatchers = (
    builder: ActionReducerMapBuilder<ReportConfigurationSliceState>,
) => {
    builder.addMatcher(isFulfilled(
        updateTableReportConfiguration,
    ), (state, {payload}) => {
        state.reportConfigurationData = {
            ...state.reportConfigurationData,
            ...payload,
        };
    });
};
