import {Drawer, Form as AntForm} from 'antd';
import {useForm} from 'antd/es/form/Form';
import omitBy from 'lodash/omitBy';
import React, {useMemo, useState} from 'react';

import {SimpleActionButton} from 'components/form/components';
import {TableReportFilter} from 'components/table-report/components/table-report-header/headers/filter-header/filters/table-report-filter';
import {
    convertFilterFormValuesToQueryParameters,
} from 'components/table-report/components/table-report-header/headers/filter-header/filters/utils/table-report-filter.utils';
import {ReactComponent as CloseIcon} from 'shared/assets/close.svg';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {tableReportSliceActions} from 'store/slices/table-report-slice/table-report-slice';
import {selectTableReportTemplateConfig} from 'store/slices/table-report-slice/table-report-slice-selectors';

import './table-report-side-filter.less';

export const TableReportSideFilter = () => {
    const dispatch = useAppDispatch();
    const [visible, setVisible] = useState(false);
    const [form] = useForm<Record<string, any>>();

    const {queryParameters, tableDatasourceType} = useAppSelector(selectTableReportTemplateConfig) ?? {};
    // ключ (name), для каждого поля в фильтрах
    const filterKeyPath = tableDatasourceType === 'TABLE' ? 'columnName' : 'paramName';

    const {
        setTableReportQueryParams,
    } = tableReportSliceActions;

    const sortedQueryParameters = useMemo(() => queryParameters
        ?.sort((a, b) => a.ordinal - b.ordinal),
    [queryParameters]);

    const handleFilterSubmit = (values: Record<string, any>) => {
        const preparedValues = omitBy(values, v => v === '' || v === null);
        dispatch(setTableReportQueryParams({
            params: convertFilterFormValuesToQueryParameters(
                preparedValues, queryParameters, filterKeyPath,
            ),
        }));
        setVisible(false);
    };

    const resetFormValue = () => {
        form.resetFields();
        dispatch(setTableReportQueryParams({params: {}}));
        setVisible(false);
    };

    return (
        <>
            <SimpleActionButton
                key={1}
                type="primary"
                onClick={() => {
                    setVisible(true);
                }}
                title="Фильтры"
                icon="Filter"
            />
            <Drawer
                title="Параметры фильтрации"
                visible={visible}
                width={416}
                onClose={() => setVisible(false)}
                className="table-report-side-filter"
                closeIcon={<CloseIcon className="close-icon" />}
                footer={(
                    <div className="table-report-side-filter__buttons">
                        <SimpleActionButton
                            type="primary"
                            className="button_active"
                            onClick={() => form.submit()}
                            title="Применить"
                        />
                        <SimpleActionButton
                            type="default"
                            className="button_cancel"
                            onClick={resetFormValue}
                            title="Сбросить"
                        />
                        <SimpleActionButton
                            type="default"
                            className="button_cancel"
                            onClick={() => setVisible(false)}
                            title="Отмена"
                        />
                    </div>
                )}
            >
                {queryParameters?.length ? (
                    <AntForm
                        layout="vertical"
                        form={form}
                        onFinish={handleFilterSubmit}
                    >
                        <div className="table-report-side-filter__parameters">
                            <TableReportFilter
                                filterKeyPath={filterKeyPath}
                                queryParameters={sortedQueryParameters}
                                areParametersApplied={false}
                            />
                        </div>
                    </AntForm>
                ) : <div className="table-report-side-filter__text">Фильтры для отчета отсутствуют</div>}
            </Drawer>
        </>
    );
};
