import {Card as CardAnt} from 'antd';
import React from 'react';

import './card.less';

interface CardProps {
    className: string;
}

export const Card: React.FunctionComponent<CardProps> = ({className, ...restProps}: CardProps) => (
    <CardAnt
        {...restProps}
        className={`card-widget ${className}`}
    />
);
