import {Button, Empty, Form} from 'antd';
import {useForm} from 'antd/lib/form/Form';
import cn from 'classnames';
import React, {
    useContext, useEffect, useRef, useState,
} from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {ModalOpenerComponent} from 'components/modal-opener-component';
import {ModalOpenerComponentRef} from 'components/modal-opener-component/modal-opener-component';
import {ReportConfigurationContext} from 'components/report-configuration/report-configuration.context';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {ReportConfigurationDataConverted} from 'store/slices/report-configuration-slice';
import {
    selectReportConfigurationTableAttributesAsSelectEntries,
    selectTableReportConfigurationData,
} from 'store/slices/report-configuration-slice/report-configuration-slice';
import {
    updateTableReportConfiguration,
} from 'store/slices/report-configuration-slice/report-configuration/report-configuration-thunks';

import './table-reserved-columns-modal-opener.less';

interface TableReservedColumnsModalOpenerProps {
    isDisabled?: boolean;
    isSheetCodeRequired?: boolean;
}

const RESERVED_SETTINGS_PREFIX = 'reservedColumns' as const;

type TableReservedColumnsFormData = Pick<ReportConfigurationDataConverted, typeof RESERVED_SETTINGS_PREFIX>;

export const TableReservedColumnsModalOpener: React.FC<TableReservedColumnsModalOpenerProps> = (
    {
        isDisabled,
        isSheetCodeRequired,
    }: TableReservedColumnsModalOpenerProps,
) => {
    const {
        isCreatingNewTemplate,
        templateCode,
    } = useContext(ReportConfigurationContext);

    const dispatch = useAppDispatch();
    const [form] = useForm();

    const reportConfigurationData = useAppSelector(selectTableReportConfigurationData);

    const tableAttributesEntries = useAppSelector(selectReportConfigurationTableAttributesAsSelectEntries);

    const [initialValues, setInitialValues] = useState<TableReservedColumnsFormData>(
        {[RESERVED_SETTINGS_PREFIX]: reportConfigurationData?.[RESERVED_SETTINGS_PREFIX]},
    );

    const modalRef = useRef<ModalOpenerComponentRef>(null);

    useEffect(() => {
        const reservedColumns = reportConfigurationData?.[RESERVED_SETTINGS_PREFIX];
        if (reservedColumns) {
            setInitialValues({
                reservedColumns,
            });
        }
    }, [reportConfigurationData]);

    useEffect(() => {
        if (initialValues) form.resetFields();
    }, [initialValues]);

    const handleFormFinish = (values: TableReservedColumnsFormData) => {
        if (templateCode) {
            dispatch(updateTableReportConfiguration({
                data: values,
                templateCode,
            }));
        }
        modalRef.current?.setIsModalOpen(false);
    };

    return (
        <ModalOpenerComponent
            ref={modalRef}
            shouldConfirm={() => form.isFieldsTouched()}
            handleCancel={setIsModalOpen => {
                form.resetFields();
                setIsModalOpen(false);
            }}
            handleSave={() => {
                form.submit();
            }}
            component={(
                <Button
                    disabled={isCreatingNewTemplate || isDisabled}
                    type="primary"
                >Настройка служебных столбцов
                </Button>
            )}
            modalProps={{
                forceRender: false,
                title: 'Настройка служебных столбцов',
            }}
        >
            {tableAttributesEntries && tableAttributesEntries?.length > 0 ? (
                <div className={cn('table-pagination-settings-modal-opener')}>
                    <Form<TableReservedColumnsFormData>
                        form={form}
                        layout="vertical"
                        onFinish={handleFormFinish}
                        initialValues={initialValues}
                        className={cn('table-pagination-settings-modal-opener__form')}
                    >
                        <div className={cn('table-pagination-settings-modal-opener__form__row',
                            'table-pagination-settings-modal-opener__form__row_grow',
                            'table-pagination-settings-modal-opener__form__row_align-start')}
                        >
                            <Form.Item
                                rules={[{required: reportConfigurationData?.useHeader || isSheetCodeRequired}]}
                                label="Столбец для определения раздела"
                                style={{width: 220}}
                                name={[RESERVED_SETTINGS_PREFIX, 'sheetCode']}
                            >
                                <CustomSelect
                                    entries={tableAttributesEntries}
                                    settings={{
                                        placeholder: 'Выберите вариант ',
                                        useFirstOptionAfterReceivedFromFetcher: isCreatingNewTemplate,
                                        isClearable: true,
                                        showSearch: true,
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Нумерация страниц"
                                style={{width: 220}}
                                name={[RESERVED_SETTINGS_PREFIX, 'pageNumber']}
                            >
                                <CustomSelect
                                    entries={tableAttributesEntries}
                                    settings={{
                                        placeholder: 'Выберите вариант ',
                                        useFirstOptionAfterReceivedFromFetcher: isCreatingNewTemplate,
                                        isClearable: true,
                                        showSearch: true,
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Нумерация строк"
                                style={{width: 220}}
                                name={[RESERVED_SETTINGS_PREFIX, 'rowNumber']}
                            >
                                <CustomSelect
                                    entries={tableAttributesEntries}
                                    settings={{
                                        placeholder: 'Выберите вариант ',
                                        useFirstOptionAfterReceivedFromFetcher: isCreatingNewTemplate,
                                        isClearable: true,
                                        showSearch: true,
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Уникальный идентификатор строки"
                                style={{width: 220}}
                                name={[RESERVED_SETTINGS_PREFIX, 'rowIdColumnName']}
                            >
                                <CustomSelect
                                    entries={tableAttributesEntries}
                                    settings={{
                                        placeholder: 'Выберите вариант ',
                                        useFirstOptionAfterReceivedFromFetcher: isCreatingNewTemplate,
                                        isClearable: true,
                                        showSearch: true,
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            ) : <Empty description="Атрибуты табличной части отсутствуют" />}
        </ModalOpenerComponent>
    );
};
