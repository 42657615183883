import {RegionResponse} from './regions-types';

export const getRegionFromSubsectionCode = (
    subsectionCode: string,
    regions: RegionResponse[],
) => regions && regions
    ?.find(({subregionList}) => subregionList
        ?.some(subregion => subregion.code === subsectionCode));

export const getRegionFromRegionCode = (
    regionCode?: string,
    regions?: RegionResponse[],
) => regions && regions?.find(region => region.regionCode === regionCode);
