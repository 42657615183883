import {createSelector} from '@reduxjs/toolkit';

import {AppState} from 'store/config/types';

import {tableReportSliceName} from '../table-report-slice-constants';

const rootSelector = (state: AppState) => state[tableReportSliceName];

export const selectDownloadDocumentsReports = createSelector(
    rootSelector,
    ({reportDownloadDocuments}) => reportDownloadDocuments,
);

export const selectDocumentUploadInfo = createSelector(rootSelector,
    ({documentUploadInfo}) => documentUploadInfo);

export const selectModalReportDownloadDocument = createSelector(
    rootSelector,
    ({reportDownloadDocuments}) => reportDownloadDocuments?.modalReportDownloadDocument,
);
