import {Form, Input} from 'antd';
import cn from 'classnames';
import React from 'react';

import './setting-tamplate-target-table-query.less';
import {WatcherFieldFlag} from 'components/report-configuration/hooks/use-form-fields-manager';
import {isEmptyArray} from 'shared/utils';
import {useAppSelector} from 'store/config/hooks';
import {selectDocumentUploadTemplateData} from 'store/slices/document-upload-template/document-upload-template-selectors';

import {SettingTemplateTargetFormQueryFields} from '../../constants/setting-template-target-table-constats';

interface SettingTemplateTargetTableQueryProps{
    checkArrayIndependentWatcherFlag: (fieldKey: string, flag: WatcherFieldFlag) => boolean;

}

export const SettingTemplateTargetTableQuery = (
    {
        checkArrayIndependentWatcherFlag,
    }: SettingTemplateTargetTableQueryProps,
) => {
    const documentUploadTemplate = useAppSelector(selectDocumentUploadTemplateData);

    const getInitialValue = (name: string) => {
        const {dffSegmentValues} = documentUploadTemplate ?? {};

        if (dffSegmentValues) {
            const defaultValues = Object
                .entries(dffSegmentValues)
                .map(value => ({
                    name: value[1].segmentColumnName,
                    value: value[1].segmentValue,
                }));

            if (!isEmptyArray(defaultValues)) return defaultValues.filter(value => value.name === name)[0]?.value || '';
        }
        return null;
    };

    return (
        <div className={cn('setting-template-target-query__body')}>
            <Form.Item
                name={SettingTemplateTargetFormQueryFields.attribute3}
                style={{width: 220}}
                label="Загружать с номера строки"
                rules={[{required: true}]}
                initialValue={getInitialValue(SettingTemplateTargetFormQueryFields.attribute3)}
            >
                <Input
                    disabled={checkArrayIndependentWatcherFlag(
                        SettingTemplateTargetFormQueryFields.attribute3,
                        WatcherFieldFlag.isDisabled,
                    )}
                    autoComplete="off"
                    placeholder="Введите номер строки"
                />
            </Form.Item>
            <Form.Item
                name={SettingTemplateTargetFormQueryFields?.attribute4}
                style={{width: 220}}
                label="Формат даты"
                rules={[{required: true}]}
                initialValue={getInitialValue(SettingTemplateTargetFormQueryFields.attribute4)}
            >
                <Input
                    disabled={checkArrayIndependentWatcherFlag(
                        SettingTemplateTargetFormQueryFields.attribute4,
                        WatcherFieldFlag.isDisabled,
                    )}
                    autoComplete="off"
                    placeholder="Введите формат даты"
                />
            </Form.Item>
        </div>
    );
};
