import {Space, Upload} from 'antd';
import {RcFile, UploadProps} from 'antd/es/upload/interface';
import get from 'lodash/get';
import React, {FC} from 'react';

import {resetLoadedData} from 'modules/data';
import {FieldMeta} from 'modules/metadata';
import {ReactComponent as PaperClip} from 'shared/assets/clip.svg';
import {ReactComponent as DownloadIcon} from 'shared/assets/download.svg';
import {ReactComponent as DeleteIcon} from 'shared/assets/forms/delete-x.svg';
import {EntityType} from 'shared/constants/entities';
import {CommonMessages} from 'shared/constants/messages';
import {convertToFormData, downloadFile, showMessageFromResponse} from 'shared/utils';
import {useAppDispatch} from 'store/config/hooks';

import {deleteAttachment, uploadAttachment} from './utils/file-upload.utils';

import './file-upload-cell.less';

export interface FileDownloadCellProps {
    entityName: string;
    value: any;
    fieldMeta: FieldMeta;
    record: Record<string, any>;
    isSignUpload: boolean;
}

export const FileUploadCell: FC<FileDownloadCellProps> = ({
    value,
    record,
    entityName,
    fieldMeta,
    isSignUpload,
}: FileDownloadCellProps) => {
    const dispatch = useAppDispatch();
    const {
        accept,
        dependentsFieldKey: attachmentPath,
        referenceUrl,
    } = fieldMeta;

    const attachment = attachmentPath ? get(record, attachmentPath) : undefined;
    const handleBeforeUpload = (file: RcFile) => {
        const formData = new FormData();
        formData.append('file', file as RcFile);
        return false;
    };

    const handleUploadChange: UploadProps['onChange'] = info => {
        if (info.file && referenceUrl) {
            uploadAttachment(referenceUrl, convertToFormData({
                docId: record?.docId,
                reportRow: isSignUpload ? record?.attachmentId : record?.sourceDocId,
                reportRowId: isSignUpload ? record?.attachmentId : record?.sourceDocId,
                attach: info.file as unknown as File,
            }))
                .then(data => {
                    if (data.status === 200) {
                        dispatch(resetLoadedData(entityName, EntityType.TABLE));
                        showMessageFromResponse({response: data, isError: false});
                    }
                })
                .catch(error => {
                    showMessageFromResponse({response: error?.response, isError: true});
                });
        }
    };

    const handleDelete = () => {
        if (referenceUrl && attachment) {
            deleteAttachment(`${referenceUrl}/${attachment}`)
                .then(data => {
                    if (data.status === 200) {
                        dispatch(resetLoadedData(entityName, EntityType.TABLE));
                        showMessageFromResponse({response: data, isError: false});
                    }
                })
                .catch(error => {
                    showMessageFromResponse({response: error?.response, isError: true});
                });
        }
    };

    const handleDownload = () => {
        const filename = value;
        const downloadPath = isSignUpload ? 'signature' : 'attachment';
        if (filename && attachment) {
            downloadFile(`${downloadPath}/${attachment}`, filename)
                .then(data => {
                    if (data.status === 200) {
                        showMessageFromResponse({
                            response: data,
                            isError: false,
                            customMessage: CommonMessages.UPLOAD_FILE_SUCCESS,
                        });
                    }
                })
                .catch(error => {
                    showMessageFromResponse({response: error?.response, isError: true});
                });
        }
    };

    return (
        <div className="file-upload-cell">
            {value ? (
                <div className="action">
                    <Space size={4}>
                        <DeleteIcon onClick={handleDelete} />
                        <PaperClip onClick={handleDownload} />
                    </Space>
                </div>
            ) : (
                <Upload
                    onChange={handleUploadChange}
                    beforeUpload={handleBeforeUpload}
                    showUploadList={false}
                    accept={accept}
                >
                    <div className="action">
                        <DownloadIcon /> Загрузить
                    </div>
                </Upload>
            )}
        </div>
    );
};
