import cn from 'classnames';
import React, {useEffect} from 'react';

import {SuspenseTrigger} from 'components/@common/technical/suspense-trigger';
import {ReactComponent as Logo} from 'shared/assets/main-logo.svg';
import {ReactComponent as RoundBorder} from 'shared/assets/round-border.svg';
import {useAppHistory} from 'shared/hooks/use-app-history';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {selectIsMenuOpen, selectMenuStructureObject} from 'store/slices/menu-slice';
import {menuSliceActions} from 'store/slices/menu-slice/menu-slice';

import {NewSideMenuBody} from './new-side-menu-body';
import {NewSideMenuExpand} from './new-side-menu-expand';
import {NewSideMenuFooter} from './new-side-menu-footer';
import {NewSideMenuItem} from './new-side-menu-item';

import './new-side-menu.less';

export const NewSideMenu = () => {
    const dispatch = useAppDispatch();
    const {
        setIsMenuOpen,
        setSelectedMenuItem,
    } = menuSliceActions;

    const history = useAppHistory();

    const isMenuOpen = useAppSelector(selectIsMenuOpen);

    const menuStructure = useAppSelector(selectMenuStructureObject);
    const {menuItems} = menuStructure;

    useEffect(() => {
        if (!isMenuOpen) dispatch(setSelectedMenuItem(undefined));
    }, [isMenuOpen]);

    if (!menuItems) return <SuspenseTrigger />;

    return (
        <>
            <RoundBorder className={cn('new-side-menu-round-border')} />
            <div className={cn('new-side-menu__logo')}>
                <Logo
                    className="cursor-pointer"
                    onClick={() => {
                        history.push('/widgets');
                    }}
                />
            </div>
            <div
                className={cn('new-side-menu')}
            >
                <NewSideMenuBody />
                <div className={cn('new-side-menu__content', {
                    'new-side-menu__content_open': isMenuOpen,
                })}
                >
                    <NewSideMenuExpand
                        onClick={() => {
                            dispatch(setIsMenuOpen(!isMenuOpen));
                        }}
                        isOpen={isMenuOpen}
                    />
                    {menuItems?.map(item => (
                        <NewSideMenuItem
                            key={item.id}
                            item={item}
                        />
                    ))}
                </div>
                <NewSideMenuFooter />
            </div>
            {isMenuOpen && (
                <div
                    className={cn('new-side-menu__dark-bg')}
                    onClick={() => {
                        dispatch(setIsMenuOpen(false));
                    }}
                />
            )}
        </>

    );
};
