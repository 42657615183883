import {ExclamationCircleOutlined} from '@ant-design/icons';
import {Tooltip} from 'antd';
import {TooltipProps} from 'antd/es/tooltip';
import cn from 'classnames';
import React from 'react';

import {JSXContent} from 'shared/types';

import './with-tooltip-error.less';

interface WithTooltipErrorProps {
    errors: TooltipProps['title'] | undefined;
    visible: boolean;
    children: JSXContent;
    className?: cn.Argument;
    withExclamation?: boolean;
}

export const WithTooltipError = ({
    errors, visible, children, className, withExclamation,
}: WithTooltipErrorProps) => (
    <Tooltip
        overlayClassName={cn('with-tooltip-error', className)}
        title={
            withExclamation ? (
                <>
                    <ExclamationCircleOutlined />
                    {errors}
                </>
            ) : errors
        }
        visible={visible}
        placement="right"
    >
        {children}
    </Tooltip>
);
