import {CaretDownOutlined} from '@ant-design/icons/lib';
import {Select} from 'antd';
import cn from 'classnames';
import React, {useState} from 'react';

import {SCALES} from 'components/table-report/mocks/data';

import './table-report-scale-changer.less';

interface TableReportScaleChangerProps {
    handleScaleChange: (value: number) => void;
}

export const TableReportScaleChanger = ({handleScaleChange}: TableReportScaleChangerProps) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [currentScale, setCurrentScale] = useState(100);

    const scaleOptions = SCALES?.map(scale => (
        <Select.Option
            value={scale}
            key={scale}
            className="table-report-scale-changer__option"
        >
            {`${scale}%`}
        </Select.Option>
    ));

    return (
        <div className="table-report-scale-changer border-left pl-16">
            <Select
                className="table-report-scale-changer__select"
                onChange={scale => {
                    setCurrentScale(scale);
                    handleScaleChange(scale / 100);
                }}
                value={currentScale}
                open={dropdownOpen}
                onDropdownVisibleChange={v => setDropdownOpen(v)}
                suffixIcon={
                    (
                        <CaretDownOutlined
                            onClick={() => setDropdownOpen(v => !v)}
                            className={cn('caret-icon', dropdownOpen && 'open')}
                        />
                    )
                }
            >
                {scaleOptions}
            </Select>
        </div>
    );
};
