import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

import {selectContextRawData} from 'modules/context/context-selector';
import {loadBranchNames} from 'modules/documents';
import {selectMetadata} from 'modules/metadata';
import {TableEntityMeta} from 'modules/metadata/metadata-types';
import {EntityType} from 'shared/constants/entities';
import {useAppSelector} from 'store/config/hooks';

import {FileUploadModal} from '../components/file-upload-modal';
import {FormsFileModalContext} from './file-modal.context';
import {useDocumentUploadActions} from './hooks/documents-upload-actions-hook';
import {useDocumentsUploadColumns} from './hooks/documents-upload-columns-hook';

interface DocumentsFileUploadModalContainerProps {
    onClose: () => void;
}

export const DocumentsFileUploadModalContainerComponent = ({onClose}: DocumentsFileUploadModalContainerProps) => {
    const {entityName} = useParams<{ entityName: string }>();
    const dispatch = useDispatch();
    const contextRawData = useAppSelector(selectContextRawData);

    const {
        useUploadDate,
        fileList,
        errorMsg,
        isPageJumperEnabled,
        isPageSizeChangerEnabled,
        actions,
        forms,
        setForms,
        fileSizeFormat,
        fieldsFiltered,
        handleEdit,
        handleUpload,
        handleClear,
        handleDelete,
        handleSubmit,
    } = useDocumentUploadActions(entityName);

    const {columnsMeta} = useDocumentsUploadColumns(
        entityName, fileSizeFormat, useUploadDate, handleDelete, handleEdit,
    );

    const metadata = (useAppSelector(selectMetadata(entityName, EntityType.EDITABLE_TABLE))) as TableEntityMeta;

    useEffect(() => {
        if (metadata && metadata?.useContext) {
            dispatch(loadBranchNames(true));
        }
    }, [contextRawData]);

    return (
        <FormsFileModalContext.Provider
            value={{
                forms,
                setForms,
            }}
        >
            <FileUploadModal
                rowKey="fileName"
                onClose={onClose}
                onClear={handleClear}
                onSubmit={handleSubmit}
                handleEdit={handleEdit}
                modalTitle="Загрузка документов"
                onUpload={handleUpload}
                columns={columnsMeta}
                columnsFiltered={fieldsFiltered}
                fileList={fileList}
                errorMsg={errorMsg}
                actions={actions}
                isPageJumperEnabled={isPageJumperEnabled}
                isPageSizeChangerEnabled={isPageSizeChangerEnabled}
            />
        </FormsFileModalContext.Provider>
    );
};

export const DocumentsFileUploadModalContainer = DocumentsFileUploadModalContainerComponent;
