import React from 'react';

import {DashboardEntry} from 'modules/data/data-types';
import {FieldMeta, FieldType} from 'modules/metadata';
import {BarStatistics} from 'pages/svc-widget-page/statistics-widgets/bar-statistics';
import {CircleStatistics} from 'pages/svc-widget-page/statistics-widgets/circle-statistics';
import {HalfCircleStatistics} from 'pages/svc-widget-page/statistics-widgets/half-circle-statistics';
import {ProgressListStatistics} from 'pages/svc-widget-page/statistics-widgets/progress-list-statistics';

export const dashboardTypeResolver = (meta: FieldMeta, entries: DashboardEntry) => {
    switch (meta.type) {
    case FieldType.BAR_STATISTICS:
        return (
            <BarStatistics
                title={meta.title}
                entries={entries}
                label={meta.label}
            />
        );
    case FieldType.CIRCLE_STATISTICS:
        return (
            <CircleStatistics
                title={meta.title || ''}
                entries={entries}
            />
        );
    case FieldType.HALF_CIRCLE_STATISTICS:
        return (
            <HalfCircleStatistics
                title={meta.title}
                entries={entries}
            />
        );
    case FieldType.PROGRESS_LIST_STATISTICS:
        return (
            <ProgressListStatistics
                title={meta.title}
                entries={entries}
            />
        );
    case FieldType.PROGRESS_LIST_STATISTICS_SECONDARY:
        return (
            <ProgressListStatistics
                title={meta.title}
                entries={entries}
                settings={{barColor: '#6BAAFF'}}

            />
        );
    default:
        return <></>;
    }
};
