import {BreadcrumbItem} from 'components/breadcrumbs';
import {
    TableReportPassedDrilldownParams,
    TableReportRequestTopicParams,
    TableReportLocationDefaults,
} from 'components/table-report/table-report-types';
import {AisRegisterLocationState} from 'pages/ais/documents-register-page/ais-documents-register-types';
import {LinkMeta, LinkParameters} from 'shared/types/links';

import {TableReportRequestParameters} from '../../../store/slices/table-report-slice/table-report-slice-types';

export enum LocationStateKey {
    TABLE_REPORT_BREADCRUMBS = 'tableReportBreadcrumbs',
    TABLE_REPORT_PARAMS = 'tableReportParams',
    TABLE_REPORT_REQUEST_TOPIC_PARAM = 'tableReportRequestTopicParams',
    TABLE_REPORT_LOCATION_DEFAULTS = 'tableReportLocationDefaults',
    TABLE_REPORT_PUSH_UNIQUE_ID = 'tableReportPushUniqueId',
    DOCUMENT_JOURNAL_PIE_CART = 'docJournalPieChart',
    AIS_REGISTER = 'aisRegister',
    AIS_DOCUMENT_JOURNAL_PIE_CART = 'docJournalPieChart',
    LINK_FILTER_PARAMETERS = 'linkFilterParameters',
    LINK_META = 'linkMeta',
    PASSED_BREADCRUMBS = 'passedBreadcrumbs'
}

export interface HistoryLocationState {
    // === @COMMON ===
    [LocationStateKey.PASSED_BREADCRUMBS]?: BreadcrumbItem[];
    // === TABLE REPORTS ===
    [LocationStateKey.TABLE_REPORT_BREADCRUMBS]?: BreadcrumbItem[];
    [LocationStateKey.TABLE_REPORT_PARAMS]?: TableReportPassedDrilldownParams;
    [LocationStateKey.TABLE_REPORT_REQUEST_TOPIC_PARAM]?: TableReportRequestTopicParams;
    [LocationStateKey.TABLE_REPORT_LOCATION_DEFAULTS]?: TableReportLocationDefaults;
    [LocationStateKey.TABLE_REPORT_PUSH_UNIQUE_ID]?: string;
    // === AIS ===
    [LocationStateKey.AIS_REGISTER]?: AisRegisterLocationState;
    [LocationStateKey.AIS_DOCUMENT_JOURNAL_PIE_CART]?: TableReportRequestParameters;
    // === LINK ===
    [LocationStateKey.LINK_FILTER_PARAMETERS]?: LinkParameters | null;
    [LocationStateKey.LINK_META]?: LinkMeta | null;
}
