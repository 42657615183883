import {DEFAULT_REQUEST_CONFIG, performRequest} from 'shared/utils/request';

export const generateAttachmentLink = (
    attachmentId: string | number,
) => `${DEFAULT_REQUEST_CONFIG.baseURL}/attachment/${String(attachmentId)}`;

export const generateSignatureLink = (
    attachmentId: string | number,
) => `${DEFAULT_REQUEST_CONFIG.baseURL}/signature/${String(attachmentId)}`;

export const generateAttorneyLink = (
    attachmentId: string | number,
) => `${DEFAULT_REQUEST_CONFIG.baseURL}/loa-signature/${String(attachmentId)}`;

export const generateAttachmentUploadLink = (
    docId: number,
) => `${DEFAULT_REQUEST_CONFIG.baseURL}/attachment/upload/${String(docId)}`;

export const generateSignatureUploadLink = (
    attachmentId: number | string,
) => `${DEFAULT_REQUEST_CONFIG.baseURL}/signature/upload/${String(attachmentId)}`;

export const generateAttorneyUploadLink = (
    docId: number,
) => `${DEFAULT_REQUEST_CONFIG.baseURL}/loa-signature/upload/${String(docId)}`;

export const uploadFile = (url: string, file: FormData) => performRequest({
    method: 'POST',
    url,
    headers: {'Content-Type': 'multipart/form-data'},
    data: file,
});

export const deleteFile = (url: string) => performRequest({
    method: 'DELETE',
    url,
});

export const signatureUpload = (files: File, attachmentId: number | string) => {
    const signatureUrl = generateSignatureUploadLink(attachmentId);
    const formData = new FormData();
    formData.append('file', files);
    return uploadFile(signatureUrl, formData);
};

export const attachUpload = (files: File, id: number) => {
    const attachUrl = generateAttachmentUploadLink(id);
    const formData = new FormData();
    formData.append('file', files);
    return uploadFile(attachUrl, formData);
};

export const attorneyUpload = (files: File, attachId: number) => {
    const attorneyUrl = generateAttorneyUploadLink(attachId);
    const formData = new FormData();
    formData.append('file', files);
    return uploadFile(attorneyUrl, formData);
};
