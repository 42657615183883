import {DownloadOutlined, LoadingOutlined} from '@ant-design/icons';
import {
    Button, Popconfirm, Spin, message,
} from 'antd';
import Tooltip from 'antd/es/tooltip';
import Upload, {UploadChangeParam} from 'antd/es/upload';
import {UploadFile} from 'antd/es/upload/interface';
import {RcFile} from 'antd/lib/upload';
import cn from 'classnames';
import {debounce} from 'lodash';
import moment from 'moment';
import React, {useState} from 'react';
import {v4 as uuid} from 'uuid';

import {DynamicIcon, IconsMap} from 'components/dynamic-icon';
import {ModalOpenerComponent} from 'components/modal-opener-component';
import {ColumnFormatterProps} from 'components/table/table-types';
import {FileInfo} from 'modules/data';
import {setDocumentScans} from 'modules/data/data-actions';
import {uploadAttachedToDocument} from 'modules/documents';
import {
    deleteDocumentScansByDocumentId, downloadScansArchiveByDocumentId, fetchDocumentScansById,
} from 'modules/documents/documents-api';
import {removeExtraPartFromEntityName} from 'modules/documents/documents-utils';
import {DATE_DMY} from 'shared/constants/date-format';
import {EntityType} from 'shared/constants/entities';
import {useUserRoleFunctions} from 'shared/hooks/use-user-role-functions';
import {showMessage} from 'shared/utils';
import {useAppDispatch} from 'store/config/hooks';
import {UserRoleFunction} from 'store/slices/user-slice/user-slice-role-functions';

import {UploadScanFilesModal} from './upload-scan-files-modal';

import './upload-scan-files-cell.less';

interface UploadScanFilesCellProps extends ColumnFormatterProps {
    fieldType: string;
    entityName: string;
    entityType: EntityType | undefined;
}

export const UploadScanFilesCell: React.FC<UploadScanFilesCellProps> = (
    props: UploadScanFilesCellProps,
) => {
    const dispatch = useAppDispatch();

    const [scanFilesList, setScanFilesList] = useState<RcFile[]>([]);

    const [isDeleting, setIsDeleting] = useState(false);
    const [isStartedDownloading, setIsStartedDownloading] = useState(false);

    const {record, entityName, entityType} = props;

    const scanDocumentAttachments = (record?.scanDocumentAttachments ?? []) as FileInfo[];

    const sublistCode = `app.${removeExtraPartFromEntityName(entityName)}.title`;

    const {hasUserRoleFunction, isLoadingUserFunctions} = useUserRoleFunctions({
        sections: [sublistCode],
    });

    const handleUploadFiles = debounce(async (files: UploadChangeParam<UploadFile<any>>) => {
        if (files.fileList && record.id) {
            const uploadAttachedPromises = files.fileList
                .filter((file: UploadFile) => !!file.originFileObj)
                .map((file: UploadFile) => uploadAttachedToDocument({
                    docId: record.id as string,
                    file: file.originFileObj as File,
                }));

            const messageKey = uuid();

            message.loading({
                content: <span className="ml-1">Загрузка файлов-приложений...</span>,
                key: messageKey,
                duration: 0,
            });

            try {
                await Promise.all(uploadAttachedPromises);

                const scans = (await fetchDocumentScansById({docId: record.id as string})).data;
                dispatch(setDocumentScans({
                    entityName,
                    entityType,
                    scans,
                    docId: record.id as string,
                }));

                message.success({
                    content: <span className="ml-1">Файлы успешно загружены</span>,
                    key: messageKey,
                    duration: 2,
                });
            } catch (e) {
                message.error({
                    content: <span className="ml-1">Ошибка загрузки файлов</span>,
                    key: messageKey,
                    duration: 2,
                });
            }
        }
        setScanFilesList([]);
    }, 0);

    const handleDelete = async () => {
        if (record.id) {
            setIsDeleting(true);
            try {
                await deleteDocumentScansByDocumentId({
                    docId: record.id as string,
                });
                dispatch(setDocumentScans({
                    docId: record.id as string,
                    entityName,
                    entityType,
                    scans: [],
                }));

                showMessage({message: 'Все файлы были успешно удалены'});
            } catch {
                showMessage({
                    message: 'Ошибка удаления файлов',
                    isError: true,
                });
            }
            setIsDeleting(false);
        }
    };

    const handleDownload = async () => {
        setIsStartedDownloading(true);
        setTimeout(() => {
            setIsStartedDownloading(false);
        }, 1000);

        const fileName = moment(new Date()).format(DATE_DMY);

        downloadScansArchiveByDocumentId({
            docId: record.id as string,
            fileName: `Приложение ${fileName}.zip`,
        });
    };

    return (
        <Spin
            size="small"
            spinning={isLoadingUserFunctions}
        >
            <div className={cn('upload-scan-files-cell', {
                'upload-scan-files-cell__empty': !scanDocumentAttachments?.length
                    && !hasUserRoleFunction(UserRoleFunction.UPLOAD_PDF),
            })}
            >
                {!scanDocumentAttachments?.length ? (
                    hasUserRoleFunction(UserRoleFunction.UPLOAD_PDF) ? (
                        <Upload
                            fileList={scanFilesList}
                            onChange={handleUploadFiles}
                            beforeUpload={() => false}
                            showUploadList={false}
                            multiple
                        >
                            <div
                                className="upload-scan-files-cell"
                                onClick={() => {}}
                            >
                                <DynamicIcon
                                    className="upload-scan-files-cell__icon"
                                    type="download"
                                />
                                <span>Загрузить</span>
                            </div>
                        </Upload>
                    ) : <span>Отсутствует</span>
                ) : (
                    <ModalOpenerComponent
                        modalProps={{
                            centered: true,
                            title: 'Приложение',
                            forceRender: false,
                            destroyOnClose: true,
                        }}
                        extraControls={(
                            <>
                                {hasUserRoleFunction(UserRoleFunction.DOWNLOAD_PDF) && (
                                    <Button
                                        type="primary"
                                        style={{maxWidth: 150, minWidth: 150}}
                                        disabled={isDeleting || isStartedDownloading}
                                        onClick={handleDownload}
                                    >
                                        {isStartedDownloading ? <LoadingOutlined /> : <DownloadOutlined />}
                                        Скачать все
                                    </Button>
                                )}

                                {hasUserRoleFunction(UserRoleFunction.UPLOAD_PDF) && (
                                    <Popconfirm
                                        title="Вы действительно хотите удалить все файлы?"
                                        placement="top"
                                        okText="Удалить"
                                        onConfirm={handleDelete}
                                        disabled={isDeleting}
                                    >
                                        <Button
                                            type="primary"
                                            style={{minWidth: 150, maxWidth: 150}}
                                            disabled={isDeleting}
                                        >
                                            {isDeleting ? <LoadingOutlined /> : <IconsMap.TrashXOutlined />}
                                            Удалить все
                                        </Button>
                                    </Popconfirm>
                                )}
                            </>
                        )}
                        hideControls={{
                            cancel: true,
                        }}
                        component={(
                            <div
                                className="upload-scan-files-cell"
                                onClick={() => {}}
                            >
                                <Tooltip
                                    mouseEnterDelay={0.5}
                                    title={`Приложено файлов: ${scanDocumentAttachments.length}`}
                                >
                                    <IconsMap.LinkOutlined
                                        className="upload-scan-files-cell__icon"
                                    />
                                </Tooltip>
                            </div>
                        )}
                    >
                        <UploadScanFilesModal
                            {...{...props, hasUserRoleFunction}}
                        />
                    </ModalOpenerComponent>
                )}
            </div>
        </Spin>
    );
};
