import {AxiosRequestConfig} from 'axios';

import {RequestType} from 'modules/metadata';
import {
    URL_REQUESTS_ACTION,
    URL_REQUESTS_DELETE,
    URL_REQUESTS_READ,
    URL_REQUESTS_GET_REQUEST_ACTION_STATUS,
    URL_REQUESTS_RUN_REQUEST_ACTION,
    URL_REQUESTS_STATUS_COMMENT_ACTION,
    URL_REQUEST_RATING,
} from 'shared/constants/urls';
import {convertToFormData, performRequest} from 'shared/utils';

import {RequestData, RequestStatus} from './request-slice-type';

export type FetchRequestArgs = {
  id: string;
  url: string;
};
export const fetchRequestData = ({
    id,
    url,
}: FetchRequestArgs) => (
    performRequest<RequestData>({
        url: `${url}/${id}`,
        method: RequestType.GET,
    })
);

export type RatingData = {
    commentId: string;
    rating: number;
    url?: string;
};

export const toggleRating = async ({commentId, rating, url}: RatingData) => performRequest({
    url: url ?? URL_REQUEST_RATING,
    method: RequestType.POST,
    data: convertToFormData({
        commentId,
        rating,
    }),
});

export type PostRequestArgs = {
    commentId: string;
};
export const postRequestStatus = ({commentId}: PostRequestArgs) => performRequest<RequestStatus[]>({
    url: URL_REQUESTS_ACTION,
    method: RequestType.POST,
    params: {commentId},
});

export const postRequestCommentStatus = ({commentId}: PostRequestArgs) => performRequest<RequestStatus[]>({
    url: URL_REQUESTS_STATUS_COMMENT_ACTION,
    method: RequestType.POST,
    params: {commentId},
});
export const markRequestRead = (ids: string[]) => performRequest({
    url: URL_REQUESTS_READ,
    method: RequestType.POST,
    data: {ids},
});

export const removeRequestData = ({commentId}: PostRequestArgs) => performRequest<string>({
    url: URL_REQUESTS_DELETE,
    method: RequestType.POST,
    params: {commentId},
});

export type PostRequestDataArgs = {
    url: string;
    commentId: string;
    data: any;
};
export const postRequestData = (
    {url, commentId, data}: PostRequestDataArgs,
) => performRequest({
    url: `${url}`,
    method: 'POST',
    params: {commentId},
    data: convertToFormData(data, {shouldUsedFileIndexName: true}),
});

export interface GetActionStatusByIdAndActionCodeRequestArgs {
    actionCode: string;
    topicId: string;
}
export const getActionStatusByIdAndActionCodeRequest = (
    args: GetActionStatusByIdAndActionCodeRequestArgs,
) => performRequest({
    url: URL_REQUESTS_GET_REQUEST_ACTION_STATUS,
    method: 'POST',
    data: convertToFormData({...args}),
});

export interface RunActionByIdAndActionCodeRequestArgs {
    actionCode: string;
    topicId: string;
}
export const runActionByIdAndActionCodeRequest = (
    args: GetActionStatusByIdAndActionCodeRequestArgs,
) => performRequest({
    url: URL_REQUESTS_RUN_REQUEST_ACTION,
    method: 'POST',
    data: convertToFormData({...args}),
});

export const createRequestStatus = () => {
    const createPost = (
        isJsonRequest?: boolean,
    ) => (params: AxiosRequestConfig['params'], referenceUrl: string, data?: any) => {
        if (!isJsonRequest) {
            return (
                performRequest({
                    url: `${referenceUrl}`,
                    method: RequestType.POST,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    params: {...params},
                    data,
                })
            );
        }
        return (
            performRequest({
                url: `/${referenceUrl}`,
                method: RequestType.POST,
                params: {...params},
                data: data && convertToFormData(data),
            })
        );
    };

    const apiList = {
        [RequestStatus.CLOSE_REQUEST]: createPost(),
        [RequestStatus.TAKE_TO_WORK_REQUEST]: createPost(),
        [RequestStatus.TAKE_TO_WORK_REQUEST_NOT_DATE]: createPost(false),
        [RequestStatus.ASSIGN_RESPONSIBLE]: createPost(),
        [RequestStatus.REQUEST_PROLONGATION]: createPost(true),
        [RequestStatus.GENERATE_RESPONSE]: createPost(true),
        [RequestStatus.COMMENT_REQUEST]: createPost(true),
        [RequestStatus.BUTTON_CONTROL_COLLAPSE]: () => null,
        [RequestStatus.REQUEST_RESPONSE]: () => null,
        [RequestStatus.REPLY_REQUEST]: createPost(),
        [RequestStatus.SEND_FOR_APPROVAL_REQUEST]: createPost(),
        [RequestStatus.REQUEST_RESPONSE_SEND]: () => null,
        [RequestStatus.PROLONGATION_REQUEST]: createPost(),
        [RequestStatus.EDIT_RESPONSE]: () => null,
        [RequestStatus.SEND_MESSAGE]: createPost(),
        [RequestStatus.PROLONGATION_RESPONSE]: createPost(true),
        [RequestStatus.DELETE_REQUEST]: createPost(),
        [RequestStatus.APPROVE_REQUEST]: createPost(),
        [RequestStatus.REJECT]: createPost(),
        [RequestStatus.REJECT_REQUEST]: createPost(),
        [RequestStatus.GENERATE_REJECTION_RESPONSE]: createPost(true),
        [RequestStatus.GENERATE_ADD_PLACEMENT_DOC_RESPONSE]: createPost(true),
        [RequestStatus.RATE_REQUEST]: () => null,
        [RequestStatus.FINALLY_CLOSED]: () => null,
        [RequestStatus.REQUEST_FROM_D365]: createPost(),
    };

    return {
        changeStatus: (
            newStatus: RequestStatus,
            commentId: number | string,
            url: string,
            data?: any,
        ) => apiList[newStatus]({commentId}, url, data),
    };
};
