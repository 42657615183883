import React from 'react';

import {ReclaimingInformationCard} from 'components/ais/reclaiming-information-card/reclaiming-information-card';
import {Table} from 'components/table';
import {TableScroll} from 'components/table/table';
import {EntityType} from 'shared/constants/entities';

export interface ReclaimingInformationProps {
    url: string;
    hideTitle?: boolean;
    entityType?: EntityType;
    entityName: string;
    scroll?: TableScroll;
    entity: string | null;

}

export const ReclaimingInformation = ({
    entity: id, scroll, url, hideTitle, entityType, entityName,
}: ReclaimingInformationProps) => (
    <>
        <Table
            hideTitle={hideTitle}
            entityName={entityName}
            url={url}
            entityType={entityType}
            scroll={scroll}
        />
        {id && (
            <ReclaimingInformationCard
                id={id}
            />
        )}
    </>
);
