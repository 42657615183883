import React from 'react';
import {useHistory, Switch, Route} from 'react-router-dom';

import {Entity} from 'modules/data';
import {DirectoryEntityMeta} from 'modules/metadata';
import {EntityType} from 'shared/constants/entities';

import {FormEditModal} from '../../form-edit-modal/form-edit-modal';
import {DirectoryItemDescription} from './directory-item-description';

import './directory-item.less';

interface DirectoryItemOwnProps {
    url: string;
    data: Entity;
    metadata: DirectoryEntityMeta;
    referenceUrl: string;
}

export const DirectoryItem: React.FunctionComponent<DirectoryItemOwnProps> = ({
    url,
    data,
    metadata,
    referenceUrl,
}: DirectoryItemOwnProps) => {
    const history = useHistory();

    const handleClose = () => {
        history.go(-1);
    };

    return (
        <Switch>
            <div className="directory-item">
                <Route
                    path={url}
                    render={() => (
                        <DirectoryItemDescription
                            data={data}
                            metadata={metadata}
                        />

                    )}
                />
                <Route
                    path={`${url}/:id`}
                    render={() => (
                        <FormEditModal
                            entityName={metadata.name}
                            onClose={handleClose}
                            referenceUrl={referenceUrl}
                            resetEntityName={metadata.name}
                            resetEntityType={EntityType.TABS}
                        />
                    )}
                />
            </div>
        </Switch>
    );
};
