import cn from 'classnames';
import React from 'react';

import {FieldMeta, SvcLayout} from 'modules/metadata/metadata-types';

import {WidgetLayoutItem} from './widget-layout-item/widget-layout-item';

interface WidgetLayoutRowProps {
    widgetLayout?: Array<SvcLayout>;
    fields?: FieldMeta[];
    data?: any;
    entityName: string;
}

export const WidgetLayoutRow: React.FC<WidgetLayoutRowProps> = ({
    widgetLayout, fields, data, entityName,
}: WidgetLayoutRowProps) => (
    <div className={cn('svc-main-page__widget-row')}>
        {widgetLayout?.map(widget => (
            <WidgetLayoutItem
                widget={widget}
                field={fields?.filter(field => field.key === widget.statisticsFieldKey)[0]}
                data={data}
                entityName={entityName}
            />
        ))}
    </div>
);
