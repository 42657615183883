import {
    Checkbox, Form,
} from 'antd';
import {FormInstance} from 'antd/es/form';
import {useForm} from 'antd/lib/form/Form';
import cn from 'classnames';
import React, {
    useEffect,
    useState,
} from 'react';

import {DdParametersRule} from '../../dd-parameters-rule';
import {DdParameterRuleItemSourceType} from '../../dd-parameters-rule/dd-parameters-rule-item/dd-parameters-rule-item-types';

import './dd-parameters-update-modal-form.less';

interface DdParametersUpdateModalFormProps {
    handleFinish: (values: any) => void;
}

export const DdParametersUpdateModalForm = React.forwardRef<
FormInstance, DdParametersUpdateModalFormProps
>(({handleFinish}: DdParametersUpdateModalFormProps, ref) => {
    const [form] = useForm();

    const [initialValues] = useState({
        enabled: true,
    });

    useEffect(() => {
        form.resetFields();
    }, [initialValues]);

    const [parentSourceType, setParentSourceType] = useState<DdParameterRuleItemSourceType>();
    const [childSourceType, setChildSourceType] = useState<DdParameterRuleItemSourceType>();

    useEffect(() => {
        setParentSourceType(form.getFieldValue('parentSourceType'));
        setChildSourceType(form.getFieldValue('childSourceType'));
    }, [initialValues]);

    return (
        <div>
            <Form
                initialValues={initialValues}
                ref={ref}
                name="dd-parameters-edit"
                form={form}
                layout="vertical"
                className={cn('dd-parameters-edit-modal-form')}
                onFinish={handleFinish}
                onValuesChange={changed => {
                    if (changed.parentSourceType) {
                        setParentSourceType(changed.parentSourceType);
                        form.setFieldsValue({
                            parentSourceValue: undefined,
                        });
                    }
                    if (changed.childSourceType) {
                        setChildSourceType(changed.childSourceType);
                        form.setFieldsValue({childValue: undefined});
                    }
                }}
            >
                <div className="mb-1">
                    <Form.Item
                        name="enabled"
                        valuePropName="checked"
                    >
                        <Checkbox>Активно</Checkbox>
                    </Form.Item>

                </div>

                <DdParametersRule
                    isInEditMode
                    layout="vertical"
                    childSourceType={childSourceType}
                    parentSourceType={parentSourceType}
                />
            </Form>
        </div>
    );
});
