import {PlusCircleOutlined} from '@ant-design/icons';
import {Button, Popconfirm} from 'antd';
import cn from 'classnames';
import {omitBy} from 'lodash';
import React, {
    createRef, useEffect, useImperativeHandle, useRef, useState,
} from 'react';
import {v4 as uid} from 'uuid';

import {CustomRadio} from 'components/@common/widgets/custom-radio';
import {IconsMap} from 'components/dynamic-icon';
import {isEmptyObject} from 'shared/utils';

import {TableColumnFilterExpressionOperand, TableColumnFilterExpressionOperandRef} from './table-column-filter-operand';
import {TableColumnFilterExpessionGroupIdentificator, TableColumnFilterExpression, TableColumnFilterOperand} from './table-column-filter-types';

interface TableColumnFilterExpressionGroupProps {
    initialExpression: TableColumnFilterExpression;
    className?: cn.Argument;
    isChild?: boolean;
    remove?: () => void;
    isInEditMode: boolean;
    depth: number;
    itemIndex: string;
    columnName: string;
}

export interface TableColumnFilterExpressionGroupRef {
    getValues: () => any;
    setExpression: (exp: TableColumnFilterExpression) => void;
}

export const TableColumnFilterExpressionGroup = React.forwardRef<
TableColumnFilterExpressionGroupRef, TableColumnFilterExpressionGroupProps>((
    {
        initialExpression,
        className,
        isChild,
        remove,
        isInEditMode,
        depth,
        itemIndex,
        columnName,
    }: TableColumnFilterExpressionGroupProps,
    ref,
) => {
    const fillOperandsWithUid = (operands: TableColumnFilterOperand[]) => operands.map(oper => {
        if (!Array.isArray(oper)) {
            return {
                uid: oper.uid ?? uid(),
                ...oper,
            };
        }

        return oper;
    });

    const [identificator, setIdentificator] = useState({
        uid: uid(),
        ...initialExpression[0],
    } as TableColumnFilterExpessionGroupIdentificator);

    const [operands, setOperands] = useState<TableColumnFilterOperand[]>(
        fillOperandsWithUid(initialExpression.slice(1) as TableColumnFilterOperand[]),
    );

    useEffect(() => {
        setOperands(fillOperandsWithUid(initialExpression.slice(1) as TableColumnFilterOperand[]));
        setIdentificator(p => ({...p, ...initialExpression[0]}));
    }, [initialExpression]);

    const operandRefs = useRef<{current: TableColumnFilterExpressionOperandRef}[]>([]);
    operandRefs.current = operands.map((element, i) => operandRefs.current[i] ?? createRef());

    const getValues = async () => {
        const list = operandRefs.current ?? [];

        const valuesFromOperand = await Promise.all(list.map(item => item.current.getValues()));

        const values = valuesFromOperand.filter(vals => {
            if (typeof vals === 'object' && isEmptyObject(omitBy(vals, v => !v))) return false;
            if (Array.isArray(vals) && vals.length === 1) return false;
            return true;
        });

        return [
            {connectBy: identificator.connectBy},
            ...values,
        ];
    };

    const setExpression = (exp: TableColumnFilterExpression) => {
        setIdentificator({
            uid: exp[0]?.uid ?? uid(),
            ...exp[0],
        });
        setOperands(fillOperandsWithUid(exp.slice(1) as TableColumnFilterOperand[]));
    };

    useImperativeHandle(ref, () => ({getValues, setExpression}));

    return (
        <div
            className={cn('table-column-filter-expression-group', {
                'table-column-filter-expression-group_main': !isChild,
            }, className)}
        >
            <div
                className={cn('table-column-filter-expression-group__upbar')}
            >
                <div className="d-flex gap-1-5 align-items-center">
                    <CustomRadio
                        disabled={!isInEditMode}
                        value={identificator.connectBy}
                        size="small"
                        onChange={e => {
                            setIdentificator(p => ({
                                ...p,
                                connectBy: e.target.value,
                            }));
                        }}
                        items={[{
                            label: '«ИЛИ»',
                            value: 'OR',
                        }, {
                            label: '«И»',
                            value: 'AND',
                        }]}
                    />
                </div>

                <div className="d-flex gap-1 align-items-center">
                    <Button
                        disabled={!isInEditMode}
                        type="default"
                        size="small"
                        onClick={async () => {
                            setOperands(p => [...p, {
                                columnName: undefined,
                                operator: undefined,
                                value: '',
                                uid: uid(),
                            }]);
                        }}
                    >
                        <PlusCircleOutlined />
                        Правило
                    </Button>
                    <Button
                        disabled={!isInEditMode}
                        type="default"
                        size="small"
                        onClick={() => {
                            setOperands(p => [...p, [{
                                connectBy: 'OR',
                                uid: uid(),
                                enabled: true,
                            }]]);
                        }}
                    >
                        <PlusCircleOutlined />
                        Группа
                    </Button>
                    {isChild && (
                        <Popconfirm
                            title={(
                                <span>Вы действительно хотите удалить группу?</span>
                            )}
                            placement="bottomLeft"
                            okText="Удалить"
                            disabled={!operands.length || !isChild}
                            onConfirm={() => {
                                remove?.();
                            }}
                        >
                            <Button
                                size="small"
                                disabled={!isChild || !isInEditMode}
                                type="default"
                                className="btn-only-icon"
                                onClick={() => {
                                    if (!operands.length) remove?.();
                                }}
                            >
                                <IconsMap.TrashXOutlined />
                            </Button>
                        </Popconfirm>
                    )}
                </div>
            </div>
            <div
                className={cn(
                    'table-column-filter-expression-group__operands',
                )}
            >
                <div className={cn({
                    'table-column-filter-expression-group__operands__border': operands.length,
                })}
                />
                <div className={cn('table-column-filter-expression-group__operands__body')}>
                    {operands.map((oper, index) => (
                        <TableColumnFilterExpressionOperand
                            isInEditMode={isInEditMode}
                            ref={operandRefs.current[index]}
                            operand={oper}
                            key={(() => {
                                const key = !Array.isArray(oper) ? oper.uid : (oper?.[0].uid ?? uid());
                                return key;
                            })()}
                            remove={() => {
                                setOperands(() => {
                                    const newOpers = operands.filter((v, i) => i !== index);
                                    return newOpers;
                                });
                            }}
                            depth={depth + 1}
                            itemIndex={!depth && itemIndex === '1' ? `${index + 1}` : `${itemIndex}.${index + 1}`}
                            columnName={columnName}
                        />
                    ))}
                    {!operands.length && (
                        <div className={cn('table-column-filter-expression-group__operands__body__no-rules')}>
                            Необходимо добавить правило или группу
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
});

export const MemoizedNewColumnFilterExpressionGroup = React.memo(TableColumnFilterExpressionGroup);
