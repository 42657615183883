import {createSelector, Selector} from '@reduxjs/toolkit';

import {AppState} from 'store/config/types';

import {WARNING_MODULE} from './warning-constants';
import {WarningModuleState} from './warning-types';

const rootSelector: Selector<AppState, WarningModuleState> = (state: AppState) => state[WARNING_MODULE];

export const selectWarningVisibilitySelector = (entityName: string) => createSelector(
    rootSelector,
    (warningState: WarningModuleState) => warningState[entityName]?.visible,
);
