export enum ChangelogStagesLookupCode {
    create = 'CREATE',
    download = 'DOWNLOAD',
    responseRequirement = 'RESPONSE_REQUIREMENT',
    processing = 'PROCESSING',
}

export const StatusMessage = 'Получен статус';
export const ProcessingAction = 'Отправлен протокол обработки документов';
export const DemandAction = 'Отправлен ответ на требование';
