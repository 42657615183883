import {DEFAULT_REQUEST_CONFIG} from 'shared/utils/request';

export const generateDownloadLink = (
    key: string,
    url?: string,
) => `${DEFAULT_REQUEST_CONFIG.baseURL}/${url}/download/${key}`;

export const generateDeleteLink = (
    key: string,
    url?: string,
) => `${DEFAULT_REQUEST_CONFIG.baseURL}/${url}/delete/${key}`;
