import {Button, Form, Input} from 'antd';
import Spin from 'antd/es/spin';
import Tooltip from 'antd/es/tooltip';
import {FormInstance} from 'antd/lib/form';
import React, {useEffect} from 'react';

import {IconsMap} from 'components/dynamic-icon';
import {CustomSelect, getCustomSelectDefaultPreset} from 'components/form/inputs/custom-select';
import {useFormInitializer} from 'shared/hooks/use-form-initializer';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {aisSelectors} from 'store/slices/ais-slice/ais-slice';
import {loadAisIncludedDocumentsByTax, updateAisIncludedDocumentsByTaxList} from 'store/slices/ais-slice/ais-slice-thunks';
import {AisRegisterDocumentByTaxDto} from 'store/slices/ais-slice/ais-slice-types';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

export interface TaxInfoProps {
    form: FormInstance;
    documentMappingId?: number;
    isDisabled?: boolean;
}

export interface FormIncludedDocumentsByTaxValues {
    taxes: AisRegisterDocumentByTaxDto[];
}

export const TaxInfo: React.FC<TaxInfoProps> = ({
    form,
    documentMappingId,
    isDisabled,
}: TaxInfoProps) => {
    const dispatch = useAppDispatch();

    const includedDocumentsByTax = useAppSelector(aisSelectors.includedDocumentsByTaxSelectors.selectAll);

    const {initialValues} = useFormInitializer<FormIncludedDocumentsByTaxValues>({
        form,
        data: includedDocumentsByTax,
        onDataSetFormatter: data => ({
            taxes: data,
        }),
    });

    const isLoadingIncludedDocumentsByTax = useAppSelector(s => selectIsThunkPending(
        s, loadAisIncludedDocumentsByTax.typePrefix,
    ));

    const isSavingIncludedDocumentsByTax = useAppSelector(s => selectIsThunkPending(
        s, updateAisIncludedDocumentsByTaxList.typePrefix,
    ));

    useEffect(() => {
        if (documentMappingId) {
            dispatch(loadAisIncludedDocumentsByTax({
                id: documentMappingId,
            }));
        }
    }, [documentMappingId]);

    return (
        <Spin
            spinning={isLoadingIncludedDocumentsByTax || isSavingIncludedDocumentsByTax}
            tip={(() => {
                if (isLoadingIncludedDocumentsByTax) return 'Загрузка налогов...';
                return 'Сохранение налогов...';
            })()}
        >
            <div>
                <Form<FormIncludedDocumentsByTaxValues>
                    layout="vertical"
                    form={form}
                    initialValues={initialValues}
                >
                    <h4>Информация по налогам, которые входят в документ</h4>
                    <Form.List name="taxes">
                        {/* todo: inline-стили переписать на классы */}
                        {(fields, {add, remove}) => (
                            <>
                                <div
                                    className="d-flex flex-column gap-3 scroll"
                                    style={{
                                        maxHeight: 'calc(min(526px, 64vh))',
                                        height: 600,
                                        overflow: 'auto',
                                        padding: '8px 12px 8px 0',
                                    }}
                                >
                                    {!fields.length && <div>Не указан ни один налог</div>}
                                    {fields.map(f => (
                                        <div
                                            key={f.fieldKey}
                                            className="form-flex"
                                            style={{
                                                border: '1px solid #E0E4F0',
                                                padding: 20,
                                                borderRadius: 5,
                                                position: 'relative',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    right: 13,
                                                    top: 9,
                                                    zIndex: 1,
                                                }}
                                                onClick={() => {
                                                    remove(f.name);
                                                }}
                                            >
                                                <Tooltip
                                                    title="Удалить"
                                                    placement="left"
                                                    mouseEnterDelay={0.5}
                                                >
                                                    <IconsMap.TrashXOutlined
                                                        color="#A9A9A9"
                                                        style={{cursor: 'pointer'}}
                                                    />
                                                </Tooltip>
                                            </div>
                                            <div className="form-flex-row">
                                                <Form.Item
                                                    label="Код налога"
                                                    name={[
                                                        f.name, 'taxCode',
                                                    ]}
                                                    required
                                                    rules={[{required: true}]}
                                                >
                                                    <CustomSelect
                                                        settings={{
                                                            ...getCustomSelectDefaultPreset(
                                                                '/valueLists/SVC_TAX',
                                                            ),
                                                            isDisabled,
                                                            placeholder: 'Выберите код налога',
                                                            isClearable: true,
                                                        }}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Код налогового (отчётного) периода"
                                                    name={[
                                                        f.name, 'taxPeriod',
                                                    ]}
                                                    required
                                                    rules={[{required: true}]}
                                                >
                                                    <CustomSelect settings={{
                                                        ...getCustomSelectDefaultPreset(
                                                            '/valueLists/SVC2_PERIOD_CODES',
                                                        ),
                                                        isDisabled,
                                                        placeholder: 'Выберите код налогового периода',
                                                        isClearable: true,
                                                    }}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className="form-flex-row">
                                                <Form.Item
                                                    label="Код декларации"
                                                    name={[
                                                        f.name, 'kndCode',
                                                    ]}
                                                    required
                                                    rules={[{required: true}]}
                                                >
                                                    <CustomSelect settings={{
                                                        ...getCustomSelectDefaultPreset('/valueLists/KND_CODES'),
                                                        placeholder: 'Выберите код декларации',
                                                        isClearable: true,
                                                        isDisabled,
                                                    }}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Отчётный год декларации"
                                                    name={[
                                                        f.name, 'reportingYear',
                                                    ]}
                                                    required
                                                    rules={[{required: true}]}
                                                >
                                                    <CustomSelect
                                                        entries={Array(30).fill(0).map((v, i) => {
                                                            const year = new Date().getFullYear() - i;
                                                            return {
                                                                label: year,
                                                                value: year,
                                                            };
                                                        })}
                                                        settings={{
                                                            placeholder: 'Выберите отчётный год',
                                                            isClearable: true,
                                                            showSearch: true,
                                                            isDisabled,
                                                        }}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className="form-flex-row">
                                                <Form.Item
                                                    label="Номер раздела декларации"
                                                    name={[
                                                        f.name, 'section',
                                                    ]}
                                                >
                                                    <Input
                                                        placeholder="Введите номер раздела"
                                                        disabled={isDisabled}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Номер строки декларации"
                                                    name={[
                                                        f.name, 'subsection',
                                                    ]}
                                                >
                                                    <Input
                                                        placeholder="Введите номер строки"
                                                        disabled={isDisabled}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className="form-flex-row">
                                                <Form.Item
                                                    label="Номер корректировки декларации"
                                                    name={[
                                                        f.name, 'corrNum',
                                                    ]}
                                                    required
                                                    rules={[{required: true}]}
                                                >
                                                    <Input
                                                        placeholder="Введите номер корректировки"
                                                        disabled={isDisabled}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <Button
                                    className="mt-2"
                                    style={{width: '100%'}}
                                    type="primary"
                                    disabled={isDisabled}
                                    onClick={() => {
                                        add();
                                    }}
                                >
                                    <IconsMap.PlusCircleOutlined />
                                    Добавить налог
                                </Button>
                            </>
                        )}
                    </Form.List>
                </Form>
            </div>
        </Spin>
    );
};
