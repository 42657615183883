import {ReferenceResolver} from '../metadata/metadata-types';
import {
    DefaultTableStateFlat, Entity, optionsTableType, optionsTabsType, TableEntityData,
} from './data-types';

export const DATA_MODULE = 'DATA_MODULE';

export const DataActions = {
    SET_DATA: 'SET_DATA',
    RESET_DATA: 'RESET_DATA',
    RESET_ALL_DATA_WITH_EXCEPTION: 'RESET_ALL_DATA_WITH_EXCEPTION',
    SET_IS_EDITABLE: 'SET_IS_EDITABLE',
    UPDATE_FORM_DATA: 'UPDATE_FORM_DATA',
    RESET_FORM_DATA: 'RESET_FORM_DATA',
    SET_FORM_INSTANCE: 'SET_FORM_INSTANCE',
    SET_INITIAL_FORM_DATA: 'SET_INITIAL_FORM_DATA',
    SET_FILTER_LOADING_STATE: 'SET_FILTER_LOADING_STATE',
    SET_FORM_USED_ADDITIONAL_OPTION_ID: 'SET_FORM_USED_ADDITIONAL_OPTION_ID',
    SET_FORM_USED_ADDITIONAL_OPTIONS: 'SET_FORM_USED_ADDITIONAL_OPTIONS',
    RESET_FORM_USED_ADDITIONAL_OPTIONS: 'RESET_FORM_USED_ADDITIONAL_OPTIONS',
    SET_FORM_MODE: 'SET_FORM_MODE',
    SET_NOTIFICATION_DURATION: 'SET_NOTIFICATION_DURATION',
    RESET_INITIAL_DATA: 'RESET_INITIAL_DATA',
    SET_DOCUMENT_SCANS_DATA: 'SET_DOCUMENTS_SCAN_DATA',
    REMOVE_DOCUMENT_SCANS: 'DELETE_DOCUMENTS_FROM_SCANS',
    SET_SELECTED_SUBSECTION: 'SET_SELECTED_SUBSECTION',
};

export const TABS_DEFAULT_DATA: Entity = {
    id: -1,
};

export const BOOLEAN_MATCH_TABLE: Record<string, string | boolean> = {
    Y: 'N',
    N: 'Y',
    y: 'n',
    n: 'y',
    yes: 'not',
    not: 'yes',
    true: 'false',
    undefined: 'Y',
    false: 'true',
    0: '1',
    1: '0',
    null: 'Y',
    '': 'Y',
};

export const ALLOWED_BOOL_VALUES: Record<string, boolean> = {
    y: true,
    true: true,
    n: false,
    false: false,
    undefined: false,
    Y: true,
    N: false,
    0: false,
    1: true,
};

export const BOOL_VALUE_WITH_TABLE: Record<any, string> = {
    true: 'Y',
    false: 'N',
    undefined: 'N',
    null: 'N',
};

export const REFERENCE_RESOLVERS: Record<string, ReferenceResolver> = {};

export const DATA_RESOLVERS: Record<string, (referenceUrl: Entity) => Entity> = {};

export enum RequestGrade {
    DISLIKE = 0,
    LIKE = 1,
}

export const DEFAULT_TABLE_DATA: TableEntityData = {
    page: 0,
    limit: 10,
    offset: 0,
};

export const DEFAULT_TABLE_STATE: DefaultTableStateFlat = {
    paginationPageSize: 10,
    paginationCurrent: 1,
};

export const DEFAULT_SEARCH_PARAMS = {
    search: '',
};

export const OPTIONS_TABLE_DEFAULT: optionsTableType = {
    useContext: false,
    useSearch: false,
};

export const OPTIONS_TABS_DEFAULT: optionsTabsType = {
    useContext: false,
};
