import {notification} from 'antd';
import axios from 'axios';
import {useEffect, useRef} from 'react';
import {useHistory} from 'react-router-dom';

import {URL_LOGIN} from 'shared/constants/urls';
import {useAppDispatch} from 'store/config/hooks';
import {
    authSliceActions,
    AuthResponseTypes,
    SESSION_EXPIRED_NOTIFICATION,
} from 'store/slices/auth-slice';

export const AxiosInterceptorsInjector = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const {resetToken} = authSliceActions;

    const interceptorId = useRef<number | null>(null);

    useEffect(() => {
        axios.defaults.headers.common['x-react-req-id'] = 1;

        return () => {
            axios.defaults.headers.common['x-react-req-id'] = null;
        };
    }, []);

    useEffect(() => {
        interceptorId.current = axios.interceptors.response.use(
            response => response,
            error => {
                const status = error.response?.status;
                const type = error.response?.data?.type;

                if (status === 401) {
                    if (type === AuthResponseTypes.UNAUTHORIZED) {
                        dispatch(resetToken());
                        notification.info({
                            message: SESSION_EXPIRED_NOTIFICATION.MESSAGE,
                            duration: null,
                            key: SESSION_EXPIRED_NOTIFICATION.KEY,
                            description: SESSION_EXPIRED_NOTIFICATION.DESCRIPTION,
                        });
                        history.push(URL_LOGIN);
                    }

                    if (type === AuthResponseTypes.PASSWORD_EXPIRED) {
                        dispatch(authSliceActions.setPasswordExpired(true));
                    }
                }

                return Promise.reject(error);
            },
        );

        return () => {
            axios.interceptors.response.eject(interceptorId.current as number);
        };
    }, [history]);

    return null;
};
