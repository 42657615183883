import {showMessageFromResponse} from 'shared/utils';
import {createAction} from 'store/config/creators';
import {Dispatch} from 'store/config/types';

import {fetchReferenceData} from '../data/data-api';
import {BreadcrumbsActions} from './breadcrumbs-constants';
import {
    BreadcrumbPushSetPayload,
    BreadcrumbReplaceSetPayload, BreadcrumbsSetPayload, IBreadcrumbs,
} from './breadcrumbs-types';
import {normalizeCrumbChildrenValue} from './breadcrumbs-utils';

export const setBreadcrumbs = createAction<BreadcrumbsSetPayload>(BreadcrumbsActions.SET_BREADCRUMBS);
export const replaceCrumb = createAction<BreadcrumbReplaceSetPayload>(BreadcrumbsActions.REPLACE_BREADCRUMBS);
export const pushCrumb = createAction<BreadcrumbPushSetPayload>(BreadcrumbsActions.PUSH_BREADCRUMBS);

export const loadBreadcrumbsAction = (
    breadcrumbs: IBreadcrumbs[],
    entityName: string,
) => async (dispatch: Dispatch) => {
    try {
        await Promise.all(
            breadcrumbs.map(async (crumb: IBreadcrumbs) => {
                if (crumb?.referenceUrl) {
                    await fetchReferenceData(crumb?.referenceUrl).then(res => {
                        crumb.itemsBreadcrumb = res.data;
                        crumb.children = normalizeCrumbChildrenValue([res.data[0]], crumb.key, crumb?.displayFieldKey);
                    });
                }
                return crumb;
            }),
        ).then((bread: IBreadcrumbs[]) => {
            dispatch(setBreadcrumbs({
                breadcrumbs: [...bread],
                entityName,
            }));
        });
    } catch (e) {
        console.error(e);
        showMessageFromResponse({response: e.response, isError: true});
    }
};
