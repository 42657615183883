import {Form, Input, InputNumber} from 'antd';
import Spin from 'antd/es/spin';
import {useForm} from 'antd/lib/form/Form';
import cn from 'classnames';
import {isNil} from 'lodash';
import React, {useEffect, useState} from 'react';

import {ModalOpenerComponent} from 'components/modal-opener-component';
import {FORM_QUERY_PARAMS} from 'pages/table-page/query-params-aware-table/constants/table-query-params';
import {cachedThunk} from 'shared/cache';
import {showMessage} from 'shared/utils';
import {useQueryParams} from 'shared/utils/query-params';
import {showRequestErrorMessage} from 'shared/utils/show-message-from-response';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {
    selectAreExternalSystemsEnabled,
    selectExternalSystemsDataForForm,
    selectUserAvailableExternalSystems,
} from 'store/slices/external-systems-slice/external-systems-slice';
import {
    loadAllExternalSystemsDataForUser,
    loadUseExternalSystemsPropertyFlag,
    saveExternalSystemsForUser,
} from 'store/slices/external-systems-slice/external-systems-slice-thunks';
import {ExternalSystemUserMappingDto} from 'store/slices/external-systems-slice/external-systems-slice-types';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

import './external-systems-settings.less';

export const ExternalSystemsSettings = () => {
    const dispatch = useAppDispatch();
    const {getQueryParam} = useQueryParams();

    const userId = isNil(
        getQueryParam(FORM_QUERY_PARAMS.entity),
    ) ? undefined : Number(getQueryParam(FORM_QUERY_PARAMS.entity));

    const userAvailableExternalSystems = useAppSelector(s => selectUserAvailableExternalSystems(s, userId));
    const externalSystems = useAppSelector(s => selectExternalSystemsDataForForm(s, userId));

    const isLoadingData = useAppSelector(s => selectIsThunkPending(s, loadAllExternalSystemsDataForUser.typePrefix));
    const isSavingData = useAppSelector(s => selectIsThunkPending(s, saveExternalSystemsForUser.typePrefix));

    const areExternalSystemsEnabled = useAppSelector(selectAreExternalSystemsEnabled);

    const [formInitialValues, setFormInitialValues] = useState<
        Partial<ExternalSystemUserMappingDto>[] | undefined>(undefined);
    const [formInstance] = useForm();

    useEffect(() => {
        const cachedGetPropertyThunk = cachedThunk(
            loadUseExternalSystemsPropertyFlag,
            undefined,
        );
        dispatch(cachedGetPropertyThunk);
    }, []);

    useEffect(() => {
        setFormInitialValues([
            ...externalSystems,
        ]);
    }, [userAvailableExternalSystems]);

    useEffect(() => {
        formInstance.resetFields();
    }, [formInitialValues]);

    const handleFinish = (values: {
        systems: Partial<ExternalSystemUserMappingDto>[];
    }) => {
        const {systems} = values;

        if (userId && systems) {
            (async () => {
                try {
                    await dispatch(saveExternalSystemsForUser({
                        userId,
                        data: systems,
                    })).unwrap();
                    showMessage({
                        message: 'Идентификаторы успешно обновлены',
                    });
                } catch (e) {
                    showRequestErrorMessage(e);
                }
            })();
        }
    };

    if (!areExternalSystemsEnabled) return null;
    return (
        <div>
            <ModalOpenerComponent
                onShow={() => {
                    if (userId) {
                        dispatch(loadAllExternalSystemsDataForUser({
                            userId,
                        }));
                    }
                }}
                modalProps={{
                    centered: true,
                    title: 'Информация об идентификаторе пользователя в СИ',
                    width: 640,
                }}
                disabledControls={{
                    save: isLoadingData || isSavingData || !userAvailableExternalSystems?.length,
                }}
                component={(
                    <div className={cn('external-systems-settings__link')}>
                        Информация об идентификаторе пользователя в СИ
                    </div>
                )}
                handleSave={() => {
                    formInstance.submit();
                }}
                controlLabels={{
                    save: 'Сохранить',
                    cancel: 'Закрыть',
                }}
            >
                {isLoadingData ? (
                    <div
                        style={{height: 50}}
                        className="d-flex justify-content-center"
                    >
                        <Spin
                            tip="Загрузка данных..."
                        />
                    </div>
                ) : (
                    <Spin
                        tip="Сохранение данных..."
                        spinning={isSavingData}
                    >
                        {!userAvailableExternalSystems?.length && <div>Внешние системы отсутствуют</div>}
                        <Form
                            form={formInstance}
                            initialValues={{
                                systems: formInitialValues,
                            }}
                            className={cn('form-flex')}
                            layout="vertical"
                            onFinish={handleFinish}
                        >
                            <Form.List name="systems">
                                {fields => fields.map(field => (
                                    <div className={cn('form-flex-row')}>
                                        <Form.Item
                                            label={!field.name ? 'Внешняя система' : ''}
                                            name={[field.name, 'externalSystemName']}
                                        >
                                            <Input disabled />
                                        </Form.Item>
                                        <Form.Item
                                            label={!field.name ? 'Идентификатор пользователя' : ''}
                                            name={[field.name, 'externalSystemUserID']}
                                            rules={[{
                                                validator: (_, value) => {
                                                    if (value?.toString?.().length >= 10) {
                                                        return Promise
                                                            .reject(
                                                                new Error(
                                                                    'Длина идентификатора не'
                                                                    + ' должна превышать 10 символов',
                                                                ),
                                                            );
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }]}
                                        >
                                            <InputNumber placeholder="Идентификатор пользователя" />
                                        </Form.Item>
                                    </div>
                                ))}
                            </Form.List>
                        </Form>
                    </Spin>
                )}
            </ModalOpenerComponent>
        </div>
    );
};
