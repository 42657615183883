import downloadFile from 'js-file-download';
import {xor} from 'lodash';
import {useEffect, useRef, useState} from 'react';

import {showMessage} from 'shared/utils';

type UseFileViewerArgs = ({
    baseUrl: string;
    url?: never;
} | {
    baseUrl?: never;
    url: string;
}) & {
    locale?: {
        downloadError?: string;
    };
}

type LoadFileArgs = {
    id?: string;
    defaultTitle?: string;
    headers?: Array<[string, string | undefined]>;
}

export const useFileViewer = (args: UseFileViewerArgs) => {
    const {baseUrl, url: strictUrl, locale = {}} = args;

    const {
        downloadError = 'Произошла ошибка скачивания файла',
    } = locale ?? {};

    const [urlsBeingLoaded, setUrlsBeingLoaded] = useState<string[]>([]);
    const isUnmountedRef = useRef(false);

    useEffect(() => {
        isUnmountedRef.current = false;
        return () => {
            isUnmountedRef.current = true;
        };
    }, []);

    const getUrl = (id?: string) => {
        if (strictUrl) return strictUrl;

        const url = `${baseUrl}/${id}`;
        return url;
    };

    const checkIsLoading = (id?: string) => {
        if (urlsBeingLoaded.includes(getUrl(id))) return true;
        return false;
    };

    const loadFile = (loadFileArgs?: LoadFileArgs) => {
        const {
            defaultTitle,
            id,
            headers,
        } = loadFileArgs ?? {};

        const xhr = new XMLHttpRequest();
        const url = getUrl(id);

        xhr.open('GET', url, true);
        xhr.responseType = 'arraybuffer';
        (headers ?? []).forEach(headerTuple => {
            const [name, value] = headerTuple;
            if (name && value) xhr.setRequestHeader(name, value);
        });
        xhr.onerror = () => {
            setUrlsBeingLoaded(p => {
                if (p.includes(url)) return xor(p, [url]);
                return p;
            });
        };
        xhr.onload = () => {
            if (xhr.status === 200) {
                let fileName = defaultTitle;

                const contentDisposition = xhr.getResponseHeader('content-disposition');
                const contentType = xhr.getResponseHeader('Content-Type');

                if (contentDisposition) {
                    const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const match = fileNameRegex.exec(contentDisposition);
                    if (match) fileName = match[1].replace(/['"]/g, '');
                }

                if (!isUnmountedRef.current) {
                    if (contentType !== 'application/pdf;charset=UTF-8') {
                        downloadFile(xhr.response, fileName ?? defaultTitle ?? 'ПУД');
                        setUrlsBeingLoaded(p => {
                            if (p.includes(url)) return xor(p, [url]);
                            return p;
                        });
                        return;
                    }

                    const blob = new Blob([xhr.response], {type: 'application/pdf'});
                    const pdfObjectUrl = URL.createObjectURL(blob);
                    const newWindow = window.open(pdfObjectUrl, '_blank');
                    URL.revokeObjectURL(pdfObjectUrl);

                    if (newWindow && defaultTitle) {
                        newWindow.onload = () => {
                            setTimeout(() => {
                                try {
                                    newWindow.document.title = fileName ?? defaultTitle;
                                } catch {
                                    // pass
                                }
                            }, 500);
                        };
                    }
                }
            }
            if (xhr.status === 404) {
                showMessage({message: downloadError, isError: true});
            }

            setUrlsBeingLoaded(p => {
                if (p.includes(url)) return xor(p, [url]);
                return p;
            });
        };
        xhr.send();

        setUrlsBeingLoaded(p => {
            if (!p.includes(url)) return [...p, url];
            return p;
        });
    };

    return {
        loadFile,
        checkIsLoading,
        urlsBeingLoaded,
    };
};
