import cn from 'classnames';
import React from 'react';

import {TimePickerField} from '../time-picker-field';

import './time-picker-label-inside.less';

export interface TimePickerLabelInsideProps {
    value: any;
    format?: string;
    label?: string;
    onChange: (value: string) => void;
    disabled?: boolean;
}

export const TimePickerLabelInside = ({label, disabled, ...props}: TimePickerLabelInsideProps) => (
    <div className={cn('time-picker-with-label', disabled && 'disabled')}>
        {label && (<span className={cn('time-picker-label', disabled && 'disabled')}>{label}</span>)}
        <TimePickerField
            {...props}
            disabled={disabled}
        />
    </div>
);
