import {useForm} from 'antd/es/form/Form';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import React, {useEffect, useState} from 'react';

import {TabbedForm} from 'components/@common/widgets/tabbed-form';
import {DirectoriesCompanyFormHeader} from 'components/directories-company-form/directories-company-form-header/directories-company-form-header';
import {DIRECTORIES_COMPANY_NAME} from 'components/directories-company-form/directories-company-form.constants';
import {DirectoriesCompanyEntity} from 'components/directories-company-form/directories-company-form.types';
import {CommonInfo} from 'components/directories-company-form/tabs/common-info';
import {isDataChanged} from 'components/form/utils/form.utils';
import {loadFormData, resetLoadedData} from 'modules/data';
import {actionSaveData} from 'modules/data/data-actions';
import {selectFormEntityData} from 'modules/data/data-selectors';
import {RequestType} from 'modules/metadata';
import {PageTemplateProps} from 'pages';
import {EntityType} from 'shared/constants/entities';
import {useAppHistory} from 'shared/hooks/use-app-history';
import {isEmptyObject} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';

import './directories-company-form.less';

type DirectoriesCompanyFormProps = Pick<PageTemplateProps, 'entityName' | 'entity'>;

export const DirectoriesCompanyForm = ({entityName, entity}: DirectoriesCompanyFormProps) => {
    const history = useAppHistory();
    const dispatch = useAppDispatch();
    const initialValues = useAppSelector(selectFormEntityData(entityName))?.data as unknown as DirectoriesCompanyEntity;

    const isEditingMode = entity !== '-1';

    const [isLoading, setLoading] = useState<boolean>(isEditingMode);
    const [isDirty, setDirty] = useState<boolean>(false);

    const [form] = useForm<DirectoriesCompanyEntity>();

    useEffect(() => {
        if (entity && isEditingMode && !initialValues) {
            setLoading(true);
            dispatch(loadFormData(entityName, entity))
                .finally(() => setLoading(false));
        }

        return () => {
            if (isEditingMode) {
                dispatch(resetLoadedData(entityName, EntityType.FORM));
            }
        };
    }, []);

    useEffect(() => {
        form.resetFields();
    }, [initialValues]);

    const handleSubmit = (values: DirectoriesCompanyEntity) => {
        setLoading(true);
        actionSaveData({
            requestUrl: `/${DIRECTORIES_COMPANY_NAME}${isEditingMode ? `/${entity}` : ''}`,
            requestType: isEditingMode ? RequestType.PUT : RequestType.POST,
            data: values,
        }).then(() => {
            if (!isEditingMode) history.go(-1);
            else if (isDirty) setDirty(false);
        }).finally(() => setLoading(false));
    };

    const handleChange = () => {
        const omittedFormValues = omitBy(form.getFieldsValue(), isEmpty);
        if (!form.isFieldsTouched()) {
            if (isDirty) setDirty(false);
        } else if (!isEditingMode && !isEmptyObject(omittedFormValues)) {
            setDirty(true);
        } else if (isDataChanged(omittedFormValues, initialValues)) {
            setDirty(true);
        } else if (isDirty) {
            setDirty(false);
        }
    };

    return (
        <div className="directories-company-form">
            <TabbedForm<DirectoriesCompanyEntity>
                classNames={{
                    card: 'directories-company-form-card',
                    tabsWrapper: 'directories-company-form-tabs-wrapper',
                }}
                title={(
                    <DirectoriesCompanyFormHeader
                        form={form}
                        isDirty={isDirty}
                        setDirty={setDirty}
                    />
                )}
                defaultTabKey="common"
                isLoading={isLoading}
                tip="Загрузка данных..."
                tabs={[
                    {
                        key: 'common',
                        title: 'Общие',
                        content: <CommonInfo />,
                    },
                ]}
                formProps={{
                    layout: 'vertical',
                    formInstance: form,
                    initialValues,
                    onSubmit: handleSubmit,
                    onChange: handleChange,
                }}
            />
        </div>
    );
};
