import {Checkbox} from 'antd';
import React, {FunctionComponent, useState} from 'react';
import {useDispatch} from 'react-redux';

import {Entity, EntityValue} from 'modules/data';
import {setData} from 'modules/data/data-actions';
import {FieldMeta} from 'modules/metadata';
import {EntityType} from 'shared/constants/entities';
import {normalizeBoolValue, normalizeBoolValueString} from 'utils/common';

interface ColumnSelectAllCheckboxProps {
    entityName: string;
    fieldMeta: FieldMeta;
    rows: Record<string, EntityValue>[] | undefined;
    editRows: Record<string, EntityValue>[] | undefined;
}

export const ColumnSelectAllCheckbox: FunctionComponent<ColumnSelectAllCheckboxProps> = ({
    entityName,
    fieldMeta,
    rows,
    editRows,
}: ColumnSelectAllCheckboxProps) => {
    const dispatch = useDispatch();
    const checkIsAllSelected = !!rows?.every(el => normalizeBoolValue(el[fieldMeta.key]));
    const [isAllSelected, setIsAllSelected] = useState<boolean>(checkIsAllSelected);
    const {key, label} = fieldMeta;

    const changeCheckboxesState = () => {
        setIsAllSelected(!isAllSelected);
        const copyEditRows: Entity[] = editRows || [];
        const copyRows = rows?.map(row => {
            if (!isAllSelected === normalizeBoolValue(row[key])) {
                return row;
            }
            const newRow = {...row, [key]: normalizeBoolValueString(!isAllSelected)};
            if (copyEditRows.some(editRow => ((editRow?.id !== null
                ? editRow?.id === row?.id
                : editRow?.uniqId === newRow?.uniqId)))) {
                const indexEditRow: number = copyEditRows.findIndex(editRow => (editRow?.id !== null
                    ? editRow?.id === row?.id
                    : editRow?.uniqId === newRow?.uniqId));
                copyEditRows.splice(indexEditRow, 1);
            } else {
                copyEditRows.push(newRow);
            }
            return newRow;
        });

        dispatch(setData({
            entityName,
            entityType: EntityType.TABLE,
            data: {
                rows: [...copyRows || []],
                editRows: [...copyEditRows || []],
            },
        }));
    };

    return (
        <Checkbox
            checked={isAllSelected}
            onClick={changeCheckboxesState}
        >
            {label}
        </Checkbox>
    );
};
