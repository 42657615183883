import React, {FunctionComponent} from 'react';
import {useDispatch} from 'react-redux';

import {TIconType} from 'components/dynamic-icon/dynamic-icon';
import {formButtonsActions} from 'modules/request-form/request-form.actions';
import {ERequestStatuses} from 'modules/request-form/request-form.constants';

import {SimpleActionButton} from '../../components';

interface IOwnProps {
    entityName: string;
    icon: TIconType;
    status: ERequestStatuses;
    title: string;
    linkField: string;
}

type TProps = IOwnProps;

export const ButtonChangeRequestStatus: FunctionComponent<TProps> = ({
    icon,
    title,
    status,
    linkField,
    entityName,
    ...props
}: TProps) => {
    const dispatch = useDispatch();
    const handleClick = () => dispatch(formButtonsActions
        .changeStatusAndUpdateForm(entityName, status, linkField));
    return (
        <SimpleActionButton
            type="primary"
            title={title}
            icon={icon}
            onClick={handleClick}
            {...props}
        />
    );
};
