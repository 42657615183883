import {FormInstance} from 'antd/es/form';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {Entity} from 'modules/data';
import {loadAdditionalFormData, loadEditableData} from 'modules/data/data-actions';
import {selectEditableTableEntityData} from 'modules/data/data-selectors';
import {
    selectMetadata, loadMetadata,
} from 'modules/metadata';
import {TableEntityMeta} from 'modules/metadata/metadata-types';
import {EntityType} from 'shared/constants/entities';
import {useAppSelector} from 'store/config/hooks';
import {selectModalData} from 'store/slices/modals-slice';

export const useEditableTableActions = (
    entityName: string,
) => {
    const dispatch = useDispatch();
    const currentLocation = useLocation<History>();
    const [initData, setInitData] = useState<Entity[]>([]);
    const [form, setForm] = useState<FormInstance[]>([]);
    const tableData = useAppSelector(selectEditableTableEntityData(entityName))?.rows;

    const modalData = useAppSelector(selectModalData);
    const metaData: TableEntityMeta = useAppSelector(selectMetadata(
        entityName,
        EntityType.EDITABLE_TABLE,
    ));
    const modalTitle = metaData?.title;
    const disabledPagination = metaData?.disabledPagination;
    const tableDataOptions = {
        useContext: metaData?.useContext,
        useSearch: metaData?.isSearchable,
        disabledPagination: metaData?.disabledPagination,
        paramsToBeConverted: {...modalData.modalParameters},
    };
    React.useEffect(() => {
        const shouldLoadMetadata = !metaData;
        const referenceUrl = metaData?.additionalOptions?.optionsField.referenceUrl;
        if (shouldLoadMetadata) {
            dispatch(loadMetadata(entityName, EntityType.EDITABLE_TABLE));
            return;
        }
        if (metaData) {
            const url = modalData.modalParameters?.referenceUrl;
            dispatch(loadEditableData(entityName, tableDataOptions, url));
        }
        if (referenceUrl) {
            dispatch(loadAdditionalFormData(entityName, referenceUrl));
        }
    }, [metaData]);

    const handleEdit = (tableRow: Entity) => {
        const index = initData?.findIndex(element => element.id === tableRow?.id);
        if (index !== -1) {
            initData.splice(index, 1);
            setInitData([...initData, tableRow]);
            return;
        }
        setInitData([...initData, tableRow]);
    };
    return {
        width: metaData?.width,
        modalTitle,
        initData,
        form,
        setForm,
        tableData,
        handleEdit,
        currentLocation,
        disabledPagination,
        actions: metaData?.actions,
    };
};
