import {isRejectedWithValue} from '@reduxjs/toolkit';
import type {MiddlewareAPI, Middleware} from '@reduxjs/toolkit';

import {showMessageFromResponse} from 'shared/utils';

import {apiReducers} from '..';

/**
 * Log a warning and show a toast!
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => next => action => {
    if (isRejectedWithValue(action)) {
        if (Object.keys(apiReducers).some(v => action.type.includes(v))) {
            showMessageFromResponse({response: action.payload, isError: true});
        }
    }
    return next(action);
};
