import React from 'react';

import {LinkButton, LinkButtonProps} from '../link-button';

interface ButtonsBarProps {
    buttons?: LinkButtonProps[];
}
export const ButtonsBar: React.FC<ButtonsBarProps> = ({buttons}: ButtonsBarProps) => (
    <>
        {buttons?.map(btn => (
            <LinkButton
                key={`${btn.title}_${btn.link}_${btn.type}`}
                type={btn.type}
                title={btn.title}
                link={btn.link}
            />
        ))}
    </>
);
