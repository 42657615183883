import {
    Button, Upload,
} from 'antd';
import {RcFile} from 'antd/lib/upload';
import React, {useState} from 'react';

import {SIGN_UPLOAD_EXTENSIONS} from 'components/ais/monitor-services-card/monitor-service-dynamic-fields/monitor-service-dynamic-fields.constants';
import {signatureUpload} from 'components/table/columns/file/file-column-utils';
import {ReactComponent as DownloadIcon} from 'shared/assets/download.svg';
import {downloadFileById} from 'shared/utils/file';
import {showMessage} from 'shared/utils/notifications';

import 'components/ais/reclaiming-information-card/fields/signature-item/signature-item.less';

interface SignatureItemProps {
    signatureName: string | undefined;
    attachmentId: string | undefined;
    reloadData?: () => void;
}

export const SignatureItem = ({
    signatureName, attachmentId, reloadData,
}: SignatureItemProps) => {
    const [isUploading, setIsUploading] = useState(false);

    const handleUploadFile = (file: RcFile) => {
        if (!attachmentId) {
            showMessage({
                message: 'Ошибка при загрузке подписи',
                description: 'Идентификатор родительского документа отсутствует',
                isError: true,
            });
            return false;
        }

        setIsUploading(true);
        signatureUpload(file, attachmentId)
            .then(() => reloadData?.())
            .catch(error => {
                showMessage({
                    message: 'Не удалось загрузить подпись',
                    description: `Текст ошибки «${error?.response?.data ?? 'Обратитесь к администратору'}»`,
                    isError: true,
                });
            })
            .finally(() => {
                setIsUploading(false);
            });

        return false;
    };

    return (
        <div
            className="signature-item"
        >
            <div className="signature-item-info">
                {signatureName ?? '-'}
                {!!signatureName && (
                    <DownloadIcon
                        onClick={() => attachmentId && downloadFileById(signatureName, attachmentId)}
                    />
                )}
            </div>
            <div className="signature-item-actions">
                <Upload
                    beforeUpload={handleUploadFile}
                    accept={SIGN_UPLOAD_EXTENSIONS}
                    showUploadList={false}
                    disabled={!attachmentId}
                >
                    <Button
                        type="primary"
                        loading={isUploading}
                        disabled={!attachmentId}
                    >
                        {`${signatureName ? 'Загрузить новую' : 'Загрузить'} ЭП`}
                    </Button>
                </Upload>
                <Button
                    type="primary"
                    // onClick={handleSign} todo
                    disabled // todo, temporary
                >
                    Подписать документы
                </Button>
            </div>
        </div>
    );
};
