import {Button} from 'antd';
import React, {FunctionComponent, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {createLoadTableData} from 'modules/data/data-actions';
import {selectFilterEntityData} from 'modules/data/data-selectors';
import {setMetadata} from 'modules/metadata/metadata-actions';
import {selectTableEntityMetadata} from 'modules/metadata/metadata-selectors';
import {EntityType} from 'shared/constants/entities';
import {useAppSelector} from 'store/config/hooks';

import {TableMode, TableModeContext} from '../../context';
import {disabledFields} from '../button-action-edit-field/button-action-edit-fields';
import {TableActionProps} from '../table-action-types';

export const ButtonCancelChanges: FunctionComponent<TableActionProps> = ({
    meta,
    entityName,
    ...props
}: TableActionProps) => {
    const dispatch = useDispatch();
    const {setTableMode} = useContext(TableModeContext);
    const filterData = useAppSelector(selectFilterEntityData(entityName))?.data;
    const metadata = useAppSelector(selectTableEntityMetadata(entityName));

    const {actionTitle} = meta;
    const tableDataOptions = {
        useContext: !!metadata?.useContext,
        useSearch: !!metadata?.isSearchable,
        disabledPagination: metadata?.disabledPagination,
    };

    const handleClick = () => {
        setTableMode(TableMode.VIEW);
        const newMetaData = disabledFields(metadata, true);

        dispatch(setMetadata({
            entityName,
            entityType: EntityType.TABLE,
            metadata: newMetaData,
        }));

        dispatch(createLoadTableData(EntityType.TABLE)(
            entityName,
            {...tableDataOptions, paramsToBeConverted: filterData},
            entityName,
            meta?.referenceUrl,
        ));
    };

    return (
        <Button
            {...props}
            type="default"
            onClick={handleClick}
        >
            {actionTitle}
        </Button>
    );
};
