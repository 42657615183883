import React, {ReactNode, useState} from 'react';

import {ConfirmModal} from 'components/confirm-modal/confirm-modal';

export interface ActionConfirmModalProps {
    confirmModalContent: ReactNode;
    modalTitle: string;
    cancelText?: string;
    approveText?: string;
    children: (handleClick: () => void) => ReactNode;
    confirmAction?: () => void;
    skipToConfirm?: boolean;
    skipToConfirmCallback?: () => boolean;
    isNotInContainer?: boolean;
}

export const ActionConfirmModal = ({
    children,
    confirmModalContent,
    cancelText,
    approveText,
    modalTitle,
    confirmAction,
    skipToConfirm,
    skipToConfirmCallback,
    isNotInContainer = true,
}: ActionConfirmModalProps) => {
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);

    const handleConfirm = () => {
        setConfirmModalVisible(false);
        confirmAction?.();
    };

    const handleClick = () => {
        if (skipToConfirm || skipToConfirmCallback?.()) {
            confirmAction?.();
        } else {
            setConfirmModalVisible(true);
        }
    };

    return (
        <>
            <ConfirmModal
                title={modalTitle}
                visible={confirmModalVisible}
                content={confirmModalContent}
                onCancel={() => setConfirmModalVisible(false)}
                cancelText={cancelText}
                approveText={approveText}
                onConfirm={handleConfirm}
                useWrapperDivElementAsContainer={!isNotInContainer}
            />
            {children(handleClick)}
        </>
    );
};
