/*
Если после использования openDraggableFrame не нужно открывать новое окно (например, если оно уже открыто),
то необходимо использовать уникальный идентификатор.
(!) У уникальный идентификаторов должны быть отрицательные значения в порядке убывания.
*/

export enum DraggableFrameUniqueTypeIds {
    TableReportAttachmentUploadFrame = -1,
    ApiRequestProgressFrame = -2
}
