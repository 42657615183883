import React, {useEffect} from 'react';

import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {selectRequestData} from 'store/slices/request-slice/request-selectors';
import {requestSliceActions} from 'store/slices/request-slice/request-slice';
import {loadRequestData, readRequest} from 'store/slices/request-slice/request-slice-thunks';

import {RequestForm} from './request-form/request-form';

import './request.less';

interface RequestProps {
    id: string;
    entityName: string;
    url: string;
    onClose: () => void;
}

export const Request = ({
    id, entityName, onClose, url,
}: RequestProps) => {
    const dispatch = useAppDispatch();
    const requestsData = useAppSelector(selectRequestData);
    const {resetRequestData} = requestSliceActions;

    useEffect(() => {
        if (!requestsData) {
            dispatch(loadRequestData({id, url}));
        }
    }, [url, requestsData]);

    useEffect(() => {
        dispatch(readRequest({ids: [id]}));
    }, []);

    useEffect(() => () => {
        dispatch(resetRequestData());
    }, []);

    return (
        <>
            {requestsData && (
                <RequestForm
                    entityName={entityName}
                    onClose={onClose}
                    requestsData={requestsData}
                />
            )}
        </>
    );
};
