export const reportConfiguratorMainFields = [
    'templateName',
    'templateCode',
    'reportName',
    'designTemplate',
    'paginationPosition',
    'enabledMenu',
    'withDocumentId',
    'enabledComparisonBtn',
    'enabledCrBtn',
    'enabledColumnsSettingsBtn',
    'enabledRefreshBtn',
    'enabledSearch',
    'enabledDownloadBtn',
    'enabledGroupingSettingsBtn',
    'actionsEnabled',
    'tableDatasourceType',
    'enabledRowComments',
    'enabledRowAttachments',
    'enabledRowRequests',
    'enabledComparison',
    'enabledCr',
    'enabledColumnTitleOrdinals',
    'enabledPrint',
    'tableDatasourceSqlValue',
    'tableDatasourceValue',
    'headerDatasourceType',
    'headerDatasourceSqlValue',
    'headerDatasourceValue',
] as const;
export type ReportConfiguratorMainFields = (typeof reportConfiguratorMainFields)[number];

export const reportConfiguratorReservedColumnsField = 'reservedColumns' as const;
export type ReportConfiguratorReservedColumnsFields = typeof reportConfiguratorReservedColumnsField;

export const reportConfiguratorDownloadSettingsField = 'downloadSettings' as const;
export type ReportConfiguratorDownloadSettingsField = typeof reportConfiguratorDownloadSettingsField
