import {Button} from 'antd';
import {FormInstance} from 'antd/es/form';
import cn from 'classnames';
import React, {useState} from 'react';

import {FormEntityData} from 'modules/data/data-types';
import {ReactComponent as Dislike} from 'shared/assets/forms/grade/dislike.svg';
import {ReactComponent as Like} from 'shared/assets/forms/grade/like.svg';
import {OracleBoolType} from 'shared/types';
import {useAppDispatch} from 'store/config/hooks';
import {requestSliceActions} from 'store/slices/request-slice/request-slice';
import {toggleRating} from 'store/slices/request-slice/request-slice-api';
import './grade.less';

export type GradeValue = number | OracleBoolType;

interface GradeProps {
    /**
     * Для метода нужно возвращать true при успешном завершении события
     * @param isLike
     */
    value?: GradeValue;
    data?: FormEntityData;
    form?: FormInstance;
    commentId?: string;
    requestFormKey?: string;
    url?: string;
}

enum GradeStatus {
    LIKE = 'LIKE',
    DISLIKE = 'DISLIKE',
    NORMAL = 'NORMAL',
}

const resolveDefaultStatus = (status: number | string | OracleBoolType = ''): GradeStatus => {
    let result = GradeStatus.NORMAL;

    const isLike = [1, 'Y'].includes(status);
    const isDislike = [0, 'N'].includes(status);

    if (isLike) {
        result = GradeStatus.LIKE;
    } else if (isDislike) {
        result = GradeStatus.DISLIKE;
    }

    return result;
};

export const Grade: React.FunctionComponent<GradeProps> = ({
    value,
    form,
    data,
    commentId,
    requestFormKey,
    url,
}: GradeProps) => {
    const dispatch = useAppDispatch();
    const [status, setStatus] = useState<GradeStatus>(resolveDefaultStatus(value));
    const {resetRequestStatusesData} = requestSliceActions;

    const isNormal = status === GradeStatus.NORMAL;
    const showLike = isNormal || status === GradeStatus.LIKE;
    const showDislike = isNormal || status === GradeStatus.DISLIKE;
    const disabledLike = status === GradeStatus.LIKE;
    const disabledDislike = status === GradeStatus.DISLIKE;

    const handleChange = (isLike: boolean) => async () => {
        if (!isNormal || !commentId) return;

        const isSuccess = await (async () => {
            const key = 'rating';
            const keyForId = 'id';
            const newValue = +isLike;
            try {
                // todo get this as param
                await toggleRating({
                    commentId: data ? data[keyForId] : commentId,
                    rating: newValue,
                    url,
                });

                if (requestFormKey) {
                    dispatch(resetRequestStatusesData);
                }

                if (form) {
                    form.setFieldsValue({[key]: newValue});
                }
                return true;
            } catch (e) {
                console.error(e);
                return false;
            }
        })();

        if (isSuccess) {
            setStatus(isLike ? GradeStatus.LIKE : GradeStatus.DISLIKE);
        } else {
            setStatus(GradeStatus.NORMAL);
        }
    };

    const buttonLike = (
        <Button
            className={cn('grade-button', {disabled: disabledLike})}
            icon={<Like />}
            onClick={handleChange(true)}
        />
    );

    const buttonDislike = (
        <Button
            className={cn('grade-button', {disabled: disabledDislike})}
            icon={<Dislike />}
            onClick={handleChange(false)}
        />
    );

    return (
        <div className="grade">
            {showLike && buttonLike}
            {showDislike && buttonDislike}
        </div>
    );
};
