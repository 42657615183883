import {useEffect, useMemo} from 'react';

import {selectEntityData} from 'modules/data';
import {loadReferenceData} from 'modules/data/data-actions';
import {selectIsEntityDataLoading} from 'modules/data/data-selectors';
import {Entity, ReferenceEntityData} from 'modules/data/data-types';
import {EntityType} from 'shared/constants/entities';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';

import {CustomSelectEntry} from '../../custom-select';
import {createFieldFormatterForSelect} from '../../custom-select-utils';

export interface CustomSelectFetchResult {
    data: Entity[] | null;
    error: any;
}

interface UseCustomSelectReferenceOptionsFetcherArgs<L, V> {
    referenceUrl?: string;
    referenceUrlQueryParams?: { [index: string]: string };
    labelPath?: string;
    isReferenceNonStorable?: boolean;
    additionalEntries?: CustomSelectEntry<L, V>[];
    rawDataInStoreFormatter?: (data: any) => any;
}

export const useCustomSelectReferenceOptionsFetcher = <L, V>({
    referenceUrl,
    labelPath,
    isReferenceNonStorable,
    additionalEntries,
    rawDataInStoreFormatter,
}: UseCustomSelectReferenceOptionsFetcherArgs<L, V>) => {
    const dispatch = useAppDispatch();

    const formatFieldLabelForSelect = createFieldFormatterForSelect(labelPath);

    const isFetchingEntries = useAppSelector(state => (!referenceUrl
        ? false : selectIsEntityDataLoading(referenceUrl)(state)));

    const dataInStore = useAppSelector(state => (!referenceUrl ? null : (
        selectEntityData(referenceUrl, EntityType.REFERENCE)(state) as ReferenceEntityData
    )?.rows));

    const dataInStoreMutated: Entity[] | null | undefined = useMemo(() => {
        if (!rawDataInStoreFormatter) return dataInStore;
        return rawDataInStoreFormatter(dataInStore);
    }, [rawDataInStoreFormatter, dataInStore]);

    const entriesInStore = useMemo(() => dataInStoreMutated?.map(dataEntry => ({
        label: formatFieldLabelForSelect(dataEntry),
        value: dataEntry as any,
    })), [dataInStoreMutated]);

    useEffect(() => {
        (async () => {
            if (!isFetchingEntries && !isReferenceNonStorable && !dataInStoreMutated && !!referenceUrl) {
                await dispatch(loadReferenceData(
                    referenceUrl,
                ));
            }
        })();
    }, [referenceUrl]);

    return {
        entriesFromFetcher: additionalEntries && entriesInStore
            ? additionalEntries.concat(entriesInStore)
            : entriesInStore,
        isFetchingEntries,
    };
};

export type UseCustomSelectReferenceOptionsFetcher = ReturnType<typeof useCustomSelectReferenceOptionsFetcher>;
