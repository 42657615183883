import {createAsyncThunk} from '@reduxjs/toolkit';

import {LookupEntry} from 'shared/types/lookups';

import {reportConfigurationSliceName} from '../report-configuration-constants';
import {FetchTableReportColumnsRequestArgs, fetchTableReportColumns, fetchTableReportFilterConditions} from './dd-lookups-api';
import {ReportTableColumn} from './dd-lookups-types';

export const loadTableReportColumnsLookup = createAsyncThunk<
    ReportTableColumn[],
    FetchTableReportColumnsRequestArgs>(
        `${reportConfigurationSliceName}/loadTableReportColumnsLookup`,
        async (args, {rejectWithValue}) => {
            try {
                const {data} = await fetchTableReportColumns(args);
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

export const loadTableReportConfigurationFilterConditionsLookup = createAsyncThunk<
    LookupEntry[],
    void>(
        `${reportConfigurationSliceName}/loadTableReportConfigurationFilterConditionsLookup`,
        async (args, {rejectWithValue}) => {
            try {
                const {data} = await fetchTableReportFilterConditions();
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );
