import React from 'react';

import {EntityValue} from 'modules/data';
import {FieldMeta} from 'modules/metadata';
import {normalizeBoolValue} from 'utils/common';

interface BoolCellStringProps {
    field?: FieldMeta;
    value?: EntityValue;
}

export const BoolCellString: React.FunctionComponent<BoolCellStringProps> = ({field, value}: BoolCellStringProps) => {
    const {booleanValues = []} = field || {};
    const normalizedValue = normalizeBoolValue(value);
    const index = +normalizedValue;
    switch (value) {
    case 'Y':
    case 'N':
        return (<span>{booleanValues[index]}</span>);
    default:
        return <span>{value}</span>;
    }
};
