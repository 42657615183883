import cn from 'classnames';
import React, {ReactNode} from 'react';
import './widget-wrapper.less';

interface WidgetWrapperProps {
    title?: string;
    children?: ReactNode;
    className?: string | cn.ArgumentArray;
}

export const WidgetWrapper: React.FC<WidgetWrapperProps> = ({children, title, className}: WidgetWrapperProps) => (
    <div className={cn('widget-wrapper', className)}>
        {title && <div className={cn('widget-title')}>{title}</div>}
        {children}
    </div>
);
