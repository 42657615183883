import {FieldMeta} from 'modules/metadata';
import {UserInfo} from 'store/slices/auth-slice/auth-slice-types';

const shouldRenderField = (field: FieldMeta, user?: UserInfo) => !(field?.renderOnCondition?.authorized && !user);

export const filterNonRenderableFields = (
    fields: FieldMeta[], user?: UserInfo,
): FieldMeta[] => fields
    .map(field => {
        if (!shouldRenderField(field, user)) {
            field.nonRenderable = true;
        }
        return field;
    })
    .filter(field => !field.nonRenderable);
