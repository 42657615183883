import React, {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import {TableContainer, TableContainerProps} from 'components/table/table-container';
import {TableWithOpenForm} from 'pages/table-page/table-with-open-form';
import {EntityType} from 'shared/constants/entities';

import './setting-program-component.less';

export const SettingProgramComponent = ({entityName, url}: TableContainerProps) => {
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const entityId = searchParams.get('entity');
    const entityUrl = `${url}/${entityName}`;

    useEffect(() => {
        history.replace(entityUrl);
    }, [entityName, history]);

    const handleClose = () => {
        history.go(-1);
    };

    return (
        <>
            {entityId ? (
                <TableWithOpenForm
                    url={entityUrl}
                    entityName={entityName}
                    onClose={handleClose}
                    referenceUrl={`${entityUrl}/${entityId}`}
                />
            ) : (
                <div className="setting-program-template">
                    <TableContainer
                        entityName={entityName}
                        entityType={EntityType.TABLE}
                        url={entityUrl}
                        hideTitle
                    />
                </div>
            )}
        </>
    );
};
