import {Button, Form as AntForm} from 'antd';
import {FormInstance} from 'antd/lib/form';
import {isEmpty} from 'lodash';
import React, {useEffect} from 'react';

import {DynamicIcon} from 'components/dynamic-icon';
import {FormEntityData} from 'modules/data/data-types';
import {FieldMeta} from 'modules/metadata';

import {SimpleActionButton} from '../../components';
import {Field} from '../../field/field';

import './inline-fields-delete.less';

interface InlineFieldsDeleteProps {
    fields?: FieldMeta[];
    formData?: FormEntityData;
    form?: FormInstance;
    name: string;
    title? : string;
}

export const InlineFieldsDelete: React.FunctionComponent<InlineFieldsDeleteProps> = ({
    fields,
    form,
    formData,
    name,
    title,
}: InlineFieldsDeleteProps) => {
    useEffect(() => {
        if (!form?.getFieldValue(name)?.length) {
            form?.setFieldsValue({
                [name]: (Array.isArray(formData?.initialData?.[name]) && !isEmpty(formData?.initialData[name])
                    && formData?.initialData[name]) || [''],
            });
        }
    }, []);

    return (
        <AntForm.List
            name={name}
        >
            {(fieldsList, {add, remove}) => (
                <div className="inline-fields-delete">
                    {fieldsList.map((fieldList, index) => (
                        <div
                            className="form-fields"
                            key={fieldList.key}
                        >
                            {fields?.map(field => (
                                <Field
                                    formData={formData}
                                    key={field.key}
                                    fieldMeta={field}
                                    fieldLabel={`${field.label} ${index + 1}`}
                                    form={form}
                                    name={[fieldList.name, field.key]}
                                />
                            ))}
                            {fieldsList?.length && fieldsList?.length > 0 && (
                                <Button
                                    disabled={fieldsList?.length === 1}
                                    type="primary"
                                    icon={<DynamicIcon type="TrashXOutlined" />}
                                    onClick={() => remove(fieldList.name)}
                                    className="delete-action-btn 'simple-action-btn'"
                                />
                            )}
                        </div>
                    ))}
                    <AntForm.Item>
                        <SimpleActionButton
                            type="link"
                            onClick={() => add()}
                            className="add-action-btn"
                            icon="PlusCircleOutlined"
                            title={title || 'Добавить'}
                        />
                    </AntForm.Item>
                </div>
            )}
        </AntForm.List>
    );
};
