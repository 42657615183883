import {Radio} from 'antd';
import React, {ReactText, useEffect, useState} from 'react';

import {EntityValue} from 'modules/data';
import {useQueryParams} from 'shared/utils/query-params/use-query-params.hook';

import {useReferenceSelectOptions} from '../../form/inputs/reference-select/hooks/reference-select-options-hook';
import {ReferenceSelectProps} from '../../form/inputs/reference-select/reference-select';
import {
    getReferenceParamsValues,
    getReferenceResolver,
} from '../../form/inputs/reference-select/utils/reference-select.utils';

export interface SubsectionReferenceSwitcherProps extends ReferenceSelectProps {
    queryParamName: string;
}

export const SubsectionReferenceSwitcher = ({
    queryParamName = 'subsection',
    referenceParamsKeys,
    form,
    referenceUrl,
    path,
    stringStructure,
    useFirstOptionAsDefaultValue,
    ...props
}: SubsectionReferenceSwitcherProps) => {
    const {query, updateQueryParams} = useQueryParams();
    const [useDefaultValue, setUseDefaultValue] = useState(useFirstOptionAsDefaultValue);
    const [subsection, setSubsection] = useState<EntityValue | undefined>();
    const referenceParamsValues = getReferenceParamsValues(referenceParamsKeys, form);
    const referenceResolver = getReferenceResolver({referenceUrl, path}, stringStructure);
    const {
        options,
        handleChange,
    } = useReferenceSelectOptions({
        ...props,
        referenceResolver,
        referenceUrl,
        referenceParamsValues,
        form,
    });

    const handleSubsectionChange = (value: unknown) => {
        if (typeof value === 'string' || typeof value === 'number') {
            updateQueryParams([{name: queryParamName, value: value.toString()}]);
        }
    };

    useEffect(() => {
        if (useDefaultValue && options && options[0]?.value) {
            setUseDefaultValue(false);
            handleSubsectionChange(options[0].value);
        }
    }, [options]);

    useEffect(() => {
        const value = query.get(queryParamName);
        if (value) {
            setSubsection(value);
            handleChange(value as ReactText);
        }
    }, [query]);

    return options?.length ? (
        <Radio.Group
            className="subsections reference-switcher"
            value={subsection}
        >
            {options.map(sub => (
                <Radio.Button
                    key={sub?.value?.toString()}
                    value={sub?.value?.toString()}
                >
                    <span onClick={() => handleSubsectionChange(sub?.value)}>{sub.label}</span>
                </Radio.Button>
            ))}
        </Radio.Group>
    ) : <></>;
};
