import {DefaultValueRuleType} from '../components/form/hooks/field-default-value.hook';
import {ContextRawData} from '../modules/context/context-types';
import {Entity, EntityValue, selectEntityData} from '../modules/data';
import {ALLOWED_BOOL_VALUES, BOOL_VALUE_WITH_TABLE} from '../modules/data/data-constants';
import {FieldMeta, FieldType, getFormInitValue} from '../modules/metadata';
import {EntityType} from '../shared/constants/entities';
import {AppState} from '../store/config/types';

export const normalizeEntityName = (
    entityName: string,
): string => entityName.replace(/^app\./, '');

export const normalizeBoolValue = (value?: EntityValue): boolean => {
    const normalizedString: string = String(value?.toString().toLowerCase());
    return ALLOWED_BOOL_VALUES[normalizedString];
};

export const normalizeBoolValueString = (value?: boolean): string => {
    const normalizedString: string = String(value?.toString().toLowerCase());
    return BOOL_VALUE_WITH_TABLE[normalizedString];
};

export const getContextDataValueByRule = (defaultValueByRule: string, contextData: ContextRawData) => {
    switch (defaultValueByRule) {
    case DefaultValueRuleType.USER_TAX_PERIOD:
        return contextData?.taxPeriodId || undefined;

    case DefaultValueRuleType.USER_ORGANIZATION:
        return contextData?.organizationId || undefined;

    default:
        return undefined;
    }
};
export const getBlankForm = (fields: FieldMeta[], getState?: () => AppState, contextData?: ContextRawData) => fields
    .reduce((acc: Entity, field: FieldMeta) => {
        if (field?.key) {
            acc[field.key] = field.defaultValue || getFormInitValue(field.type);
        }
        if (Array.isArray(field?.additionalInputOptions)) {
            field?.additionalInputOptions?.forEach(input => {
                if (!input?.isFiltrationEnabled && input.key) acc[input.key] = input?.value;
            });
        }
        if (
            field?.key
            && field?.defaultValueByRule
            && contextData
        ) {
            acc[field.key] = getContextDataValueByRule(field?.defaultValueByRule, contextData);
        }
        if (
            field?.key && field.type === FieldType.REFERENCE
            && field?.defaultValue === 'Y'
            && field?.referenceUrl
            && getState
        ) {
            const referenceData: any = selectEntityData(field.referenceUrl, EntityType.REFERENCE)(getState());
            acc[field.key] = referenceData?.rows[0];
        }
        return acc;
    }, {});
