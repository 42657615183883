export const SETTING_TEMPLATE_TARGET_FORM_DATA_KEY = 'programMappings';

export enum SettingTemplateTargetFormQueryFields {
    attribute3 = 'LINE_NUM',
    attribute4 = 'OUTPUT_FORMAT_DATE',
}
export enum SettingTemplateTargetFormFields {
    numberId = 'numberId',
    typeId = 'typeId',
    excelColumn = 'excelColumn',
    description = 'description',
    sourceField= 'sourceField',
    name = 'name',
}
export const settingTemplateTargetMainFields = [
    'numberId',
    'typeId',
    'excelColumn',
    'name',
    'description',
];
