export enum StatusCode {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE'
}

export const StatusMapping: Record<string, StatusCode> = {
    y: StatusCode.ACTIVE,
    true: StatusCode.ACTIVE,
    Y: StatusCode.ACTIVE,

    n: StatusCode.INACTIVE,
    false: StatusCode.INACTIVE,
    undefined: StatusCode.INACTIVE,
    N: StatusCode.INACTIVE,
};

export const StatusTitle:Record<StatusCode, string> = {
    [StatusCode.ACTIVE]: 'Активен',
    [StatusCode.INACTIVE]: 'Неактивен',
};

export const StatusColor: Record<StatusCode, string> = {
    [StatusCode.ACTIVE]: '#43A047',
    [StatusCode.INACTIVE]: '#959DAC',
};
