import {FormInstance} from 'antd/es/form';
import React, {useEffect, useState} from 'react';

import {Entity} from 'modules/data';
import {FieldMeta} from 'modules/metadata';

import {EditableFormTableContext} from '../../editable-table-modal/editable-form-table-data';
import {generateEditableMetaData} from '../../editable-table-modal/editable-table-modal-utils';
import {EditableColumn} from '../../editable-table/editable-table-types';
import {TableProps} from '../table';
import {TableActions} from '../table-actions/table-actions';
import {EditableTableComponent} from './editable-table';

interface EditableTableContainerProps extends TableProps{
}

export const EditableTableContainer = ({
    entityName, tableData, metadata, onChange, url, loading, rowSelection,
}: EditableTableContainerProps) => {
    const [initData, setInitData] = useState<Entity[]>([]);
    const [form, setForm] = useState<FormInstance[]>([]);
    const [columnsMeta, setColumnsMeta] = React.useState<EditableColumn[] | undefined>();

    const fields = metadata?.fields;

    useEffect(() => {
        if (fields) {
            const arr = (fields as FieldMeta[])?.map(item => generateEditableMetaData(item));
            setColumnsMeta(arr);
        }
    }, [fields]);

    const handleEdit = (tableRow: Entity) => {
        const index = initData?.findIndex(element => element.id === tableRow?.id);
        if (index !== -1) {
            initData.splice(index, 1);
            setInitData([...initData, tableRow]);
            return;
        }
        setInitData([...initData, tableRow]);
    };

    return (
        <EditableFormTableContext.Provider
            value={{
                initData,
                form,
                setForm,
            }}
        >
            <TableActions
                url={url}
                entityName={entityName}
                metadata={metadata}
            />
            {columnsMeta && (
                <EditableTableComponent
                    entityName={entityName}
                    handleSave={handleEdit}
                    columns={columnsMeta}
                    tableData={tableData}
                    url={url}
                    metadata={metadata}
                    rowSelection={rowSelection}
                    onChangeTable={onChange}
                    loading={loading}
                    isSelectable={metadata?.isSelectable}
                    linkField={metadata?.linkField}
                    dependentLinkField={metadata?.dependentLinkField}
                />
            )}
        </EditableFormTableContext.Provider>
    );
};
