import {FormInstance} from 'antd/es/form';
import {useEffect} from 'react';

import {setFormUsedAdditionalOptionId} from 'modules/data/data-actions';
import {selectUsedAdditionalOptionId} from 'modules/data/data-selectors';
import {setMetadata} from 'modules/metadata/metadata-actions';
import {EntityMeta, FieldMeta} from 'modules/metadata/metadata-types';
import {EntityType} from 'shared/constants/entities';
import {useGetAdditionalFieldsQuery} from 'store/api/form-additional-options/form-additional-options';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';

const createFields = (additionalFields: FieldMeta[], metaFields: FieldMeta[]) => {
    const newAdditionalFields: FieldMeta[] = [];
    additionalFields?.forEach(field => {
        if (metaFields.some(item => field.key === item.key)) {
            const appAdditionalField = metaFields.find(item => field.key === item.key);
            const newAdditionalData = appAdditionalField
                ? Object.keys(appAdditionalField).reduce(
                    // @ts-ignore
                    (acc, curr) => ({...acc, [curr]: field[curr] || appAdditionalField[curr]}), {},
                )
                : field;
            newAdditionalFields.push({...field, ...newAdditionalData});
        }
    });
    return newAdditionalFields;
};

export const useAdditionalOptions = (props: {
    entityName?: string;
    form?: FormInstance;
    meta?: EntityMeta;
    id?: string | number;
    setErrorFormMessage?: (value: string) => void;
}) => {
    const {
        entityName = '',
        id,
        form,
        meta,
    } = props;
    const dispatch = useAppDispatch();
    const additionalReferenceUrl = meta?.additionalOptions?.optionsField?.referenceUrl;
    const isChangingFormStructure = meta?.additionalOptions?.optionsField?.isChangingFormStructure;
    const additionalFields = meta?.additionalOptions?.optionsField.additionalFields;
    const {data} = useGetAdditionalFieldsQuery(
        {referenceUrl: additionalReferenceUrl, id}, {skip: !additionalReferenceUrl || !id || !isChangingFormStructure},
    );

    const lastUsedAdditionalOptionId = useAppSelector(state => selectUsedAdditionalOptionId(entityName || '',
        additionalReferenceUrl || '')(state));

    useEffect(() => {
        (async () => {
            try {
                if (meta && isChangingFormStructure && additionalReferenceUrl
                    && id && lastUsedAdditionalOptionId !== id && additionalFields) {
                    if (id) {
                        dispatch(setFormUsedAdditionalOptionId(
                            {entityName, entityNameChild: additionalReferenceUrl, optionId: id},
                        ));
                    }

                    const newAdditionalFields = createFields(additionalFields, data);
                    dispatch(setMetadata(
                        {
                            entityName,
                            entityType: EntityType.FORM,
                            metadata: {
                                ...meta,
                                fields: [...meta?.fields, ...newAdditionalFields],
                            },
                        },
                    ));
                    // reset all form fields except field which is triggering additional data loading
                    form?.resetFields(newAdditionalFields.map((field: FieldMeta) => field.key));
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [id]);
};
