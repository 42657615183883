import get from 'lodash/get';
import {useEffect, useState} from 'react';

import {FieldAvailabilityCondition} from 'modules/metadata/metadata-types';

export const useFieldAvailability = (
    fieldNonAvailabilityConditions: FieldAvailabilityCondition[],
    formValues?: any,
) => {
    const [available, setIsAvailable] = useState(true);

    useEffect(() => {
        fieldNonAvailabilityConditions.forEach(condition => {
            if (condition.fieldValues.includes(get(formValues, condition.fieldName))) {
                setIsAvailable(false);
            }
        });
    }, [formValues, fieldNonAvailabilityConditions]);
    return {available};
};
