import {useEffect, useState} from 'react';

import {CustomSelectEntry} from 'components/form/inputs/custom-select/custom-select';
import {useAppSelector} from 'store/config/hooks';
import {
    selectReportConfigurationMenuAsEntriesListFilteredByReportColumns,
} from 'store/slices/report-configuration-slice/report-configuration-slice';

export const useTableReportSettingsSheets = () => {
    const existingSheetsEntries = useAppSelector(selectReportConfigurationMenuAsEntriesListFilteredByReportColumns);

    const [selectedSheet, setSelectedSheet] = useState<string | undefined>();
    const [sheets, setSheets] = useState<CustomSelectEntry<string, any>[]>([]);

    const removeSheet = (sheetCode: string) => {
        setSheets(p => p.filter(({value}) => value !== sheetCode));
    };

    useEffect(() => {
        if (existingSheetsEntries?.length) {
            setSheets(existingSheetsEntries);
        }
    }, [existingSheetsEntries]);

    return {
        selectedSheet,
        setSelectedSheet,
        sheets,
        setSheets,
        removeSheet,
    };
};
