import {Entity} from 'modules/data';

import {DefaultTableState, DefaultTableStateFlat} from '../../../modules/data/data-types';

export const normalizeFormTableData = (
    newData: Entity | any = {},
    additionalData: Record<string, any>,
    entityLinkField: string = 'id',
) => {
    const keys = Object.keys(newData);
    const normilizedFormTableValues: Record<string, any> = [];

    keys.forEach((item: string) => {
        const [fieldName, id] = item.split('_');
        normilizedFormTableValues.push({
            [entityLinkField]: id,
            [fieldName]: newData[item],
        });
    });

    const values: Record<string, any> = [];
    normilizedFormTableValues.forEach((item: Record<string, any>) => {
        values.push({...item, ...additionalData});
    });
    return values;
};

export const convertObjectToTableStateFlat = (tableState: DefaultTableState): DefaultTableStateFlat => {
    const {pagination, sorter} = tableState || {};

    return {
        paginationPageSize: pagination.pageSize,
        paginationCurrent: pagination.current,
        paginationTotal: pagination.total,
        sorterField: sorter.field,
        sorterOrder: sorter.order,
    };
};
