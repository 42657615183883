import {createReducer} from 'store/config/creators';
import {Action} from 'store/config/types';

import {PageInfoActions} from './page-info.constants';
import {PageInfoState} from './page-info.types';

const pageInitialState: PageInfoState = {
    currentEntityName: undefined,
    currentAdditionalEntityName: undefined,
};

export const pageInfoReducer = createReducer(pageInitialState, {
    [PageInfoActions.SET_CURRENT_PAGE_INFO]: (
        state: PageInfoState,
        {payload}: Action<PageInfoState>,
    ) => ({...state, ...payload}),
    [PageInfoActions.RESET_CURRENT_PAGE_INFO]: (
        state: PageInfoState,
    ) => ({...state, currentEntityName: undefined, currentAdditionalEntityName: undefined}),
});
