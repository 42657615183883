import {Card} from 'antd';
import cn from 'classnames';
import React, {useState} from 'react';

import {CustomTabs} from '../../custom-tabs';
import {
    AisDocumentDocumentCodesTabKey,
    AisDocumentTypeCodesEntityName,
    DocumentCodesFormTitles,
} from '../ais.constants';
import {DocumentCodesTemplateContext} from './document-codes-context';
import {DocumentTypeCodes} from './tabs/document-types-codes/document-type-codes';

import './document-codes.less';

interface DocumentCodesProps {
    entityName: string;
    url: string;
}

export const DocumentCodes: React.FC<DocumentCodesProps> = (
    {
        entityName,
        url,
    }: DocumentCodesProps,
) => {
    const [selectedTabKey, setSelectedTabKey] = useState<React.Key>(
        AisDocumentDocumentCodesTabKey.REFERENCE_BOOK_DOCUMENT_CODES,
    );
    return (
        <div className={cn('document-codes')}>
            <DocumentCodesTemplateContext.Provider
                value={{
                    selectedTabKey,
                    setSelectedTabKey,
                    entityName,
                }}
            >
                <Card
                    title="Настройка кодов первичных документов"
                    className={cn('document-codes__card')}
                >
                    <CustomTabs
                        selectedTabKey={selectedTabKey}
                        setSelectedTabKey={setSelectedTabKey}
                        defaultRenderBehaviourOnSwitchingTabs="unmount"
                        tabs={[{
                            key: AisDocumentDocumentCodesTabKey.REFERENCE_BOOK_DOCUMENT_CODES,
                            title: DocumentCodesFormTitles.referenceBookDocumentCodes,
                            content: <DocumentTypeCodes
                                entityName={AisDocumentTypeCodesEntityName.DOCUMENT_TYPE_CODES_DIRECTORY}
                                url={url}
                            />,
                        }, {
                            key: AisDocumentDocumentCodesTabKey.SETTING_DOCUMENT_CODES,
                            title: DocumentCodesFormTitles.settingDocumentCodes,
                            content: <DocumentTypeCodes
                                entityName={AisDocumentTypeCodesEntityName.PRIMARY_DOCUMENTS_CODES_SETTINGS}
                                url={url}
                            />,
                            noRenderWhileOnAnotherTab: true,
                        }]}
                    />
                </Card>
            </DocumentCodesTemplateContext.Provider>
        </div>
    );
};
