import React from 'react';
import {useHistory} from 'react-router-dom';

import {TIconType} from 'components/dynamic-icon/dynamic-icon';
import {ButtonType} from 'modules/metadata/metadata-types';

import {SimpleActionButton} from '../../components';

export interface HistoryBackButtonProps {
    actionTitle?: string;
    actionIcon?: string;
    buttonType?: ButtonType;
    url?: string;

}

export const ButtonHistoryBack = (
    {
        actionTitle = 'Вернуться ко всем запросам', actionIcon, buttonType, url,
    }: HistoryBackButtonProps,
) => {
    const history = useHistory();
    const handleClick = () => {
        if (url) {
            history.push(url);
            return;
        }
        history.go(-1);
    };
    return (
        <SimpleActionButton
            type={buttonType || 'text'}
            title={actionTitle}
            onClick={handleClick}
            icon={actionIcon as TIconType}
        />
    );
};
