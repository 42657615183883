import {NetDirectoryFileData} from 'store/slices/net-directory-file-slice/net-directory-file-slice-type';

export const convertNetDirectoryFileDataToString = (fileDataList: NetDirectoryFileData[]) => `   
    <p>
        ${fileDataList.map(fileData => `
                <p>
                    <strong>Файл ${fileData?.filename}</strong>
                </p>
                <p>&nbsp;</p>
                <p>
                   <span>${fileData?.messageList.join('. ')}</span>
                </p>
                <p>&nbsp;</p>  
        `).join('\n')}
    </p>
`;
