import {combineReducers} from 'redux';

import {BREADCRUMBS_MODULE} from 'modules/breadcrumbs/breadcrumbs-constants';
import {breadcrumbsReducer} from 'modules/breadcrumbs/breadcrums-reducer';
import {CONTEXT_MODULE} from 'modules/context/context-constants';
import {contextReducer} from 'modules/context/context-reducer';
import {DATA_MODULE, dataReducer} from 'modules/data';
import {DOCUMENTS_MODULE, documentsReducer} from 'modules/documents';
import {METADATA_MODULE, metadataReducer} from 'modules/metadata';
import {METADATA_CONSTRUCTOR_MODULE} from 'modules/metadata-constructor/metadata-constructor.constants';
import {metadataConstructorReducer} from 'modules/metadata-constructor/metadata-constructor.reducer';
import {REGIONS_MODULE, regionsReducer} from 'modules/regions';
import {WarningReducer} from 'modules/warning';
import {WARNING_MODULE} from 'modules/warning/warning-constants';
import {aisSliceReducer} from 'store/slices/ais-slice/ais-slice';
import {ais3SliceName} from 'store/slices/ais-slice/ais-slice-constants';
import {apiRequestProgressSliceReducer} from 'store/slices/api-request-progress-slice/api-request-progress-slice';
import {apiRequestProgressSliceName} from 'store/slices/api-request-progress-slice/api-request-progress-slice-constants';
import {logout} from 'store/slices/auth-slice';
import {authSliceReducer} from 'store/slices/auth-slice/auth-slice';
import {authSliceName} from 'store/slices/auth-slice/auth-slice-constants';
import {controlRatioReducer, controlRatioSliceName} from 'store/slices/control-ratio-slice';
import {descriptiveFlexfieldsReducer} from 'store/slices/descriptive-flexfields-slice/descriptive-flexfields-slice';
import {descriptiveFlexfieldsSliceName} from 'store/slices/descriptive-flexfields-slice/descriptive-flexfields-slice-constants';
import {
    draggableFramesReducer,
    draggableFramesSliceName,
} from 'store/slices/draggable-frames-slice';
import {externalSystemsSliceReducer} from 'store/slices/external-systems-slice/external-systems-slice';
import {externalSystemsSliceName} from 'store/slices/external-systems-slice/external-systems-slice-constants';
import {jasperReportSliceReducer} from 'store/slices/jasper-report-slice/jasper-report-slice';
import {jasperReportSliceName} from 'store/slices/jasper-report-slice/jasper-report-slice-constants';
import {
    loadingStateSliceName,
    loadingStateSliceReducer,
} from 'store/slices/loading-state-slice';
import {menuSliceReducer} from 'store/slices/menu-slice/menu-slice';
import {menuSliceName} from 'store/slices/menu-slice/menu-slice-constants';
import {modalsSliceReducer} from 'store/slices/modals-slice/modals-slice';
import {modalsSliceName} from 'store/slices/modals-slice/modals-slice-constants';
import {
    reportConfigurationSliceName,
    reportConfigurationSliceReducer,
} from 'store/slices/report-configuration-slice';
import {tableReportSliceReducer} from 'store/slices/table-report-slice/table-report-slice';
import {tableReportSliceName} from 'store/slices/table-report-slice/table-report-slice-constants';
import {userSliceReducer} from 'store/slices/user-slice/user-slice';
import {userSliceName} from 'store/slices/user-slice/user-slice-constants';
import {widgetsSliceReducer} from 'store/slices/widgets-slice/widgets-slice';
import {widgetsSliceName} from 'store/slices/widgets-slice/widgets-slice-constants';

import {apiReducers} from '../api';
import {
    documentUploadTemplateSliceReducer,
} from '../slices/document-upload-template/document-upload-template-slice';
import {
    documentUploadTemplateSliceName,
} from '../slices/document-upload-template/document-upload-template-slice-constants';
import {netDirectoryFileSliceReducer} from '../slices/net-directory-file-slice/net-directory-file-slice';
import {
    netDirectoryFileSliceName,
} from '../slices/net-directory-file-slice/net-directory-file-slice-constants';
import {requestSliceReducer} from '../slices/request-slice/request-slice';
import {requestSliceName} from '../slices/request-slice/request-slice-constants';
import {Action} from './types';

import {PAGE_INFO_MODULE} from '.deprecated/modules/page-info';
import {pageInfoReducer} from '.deprecated/modules/page-info/page-info.reducer';

const appReducer = combineReducers({
    ...apiReducers,
    [METADATA_MODULE]: metadataReducer,
    [DATA_MODULE]: dataReducer,
    [REGIONS_MODULE]: regionsReducer,
    [DOCUMENTS_MODULE]: documentsReducer,
    [PAGE_INFO_MODULE]: pageInfoReducer, // deprecated
    [METADATA_CONSTRUCTOR_MODULE]: metadataConstructorReducer,
    [BREADCRUMBS_MODULE]: breadcrumbsReducer,
    [WARNING_MODULE]: WarningReducer,
    [CONTEXT_MODULE]: contextReducer,
    // slices
    [draggableFramesSliceName]: draggableFramesReducer,
    [controlRatioSliceName]: controlRatioReducer,
    [reportConfigurationSliceName]: reportConfigurationSliceReducer,
    [loadingStateSliceName]: loadingStateSliceReducer,
    [descriptiveFlexfieldsSliceName]: descriptiveFlexfieldsReducer,
    [widgetsSliceName]: widgetsSliceReducer,
    [documentUploadTemplateSliceName]: documentUploadTemplateSliceReducer,
    [requestSliceName]: requestSliceReducer,
    [tableReportSliceName]: tableReportSliceReducer,
    [modalsSliceName]: modalsSliceReducer,
    [menuSliceName]: menuSliceReducer,
    [ais3SliceName]: aisSliceReducer,
    [authSliceName]: authSliceReducer,
    [apiRequestProgressSliceName]: apiRequestProgressSliceReducer,
    [userSliceName]: userSliceReducer,
    [netDirectoryFileSliceName]: netDirectoryFileSliceReducer,
    [jasperReportSliceName]: jasperReportSliceReducer,
    [externalSystemsSliceName]: externalSystemsSliceReducer,
});

export const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: Action<string>) => {
    if (action.type === `${logout.typePrefix}/fulfilled`) {
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};
