import {TableHeaderStructure} from 'components/report-configuration/tabs/report-table-settings/modals/excel-header-structure-loader-modal/excel-header-structure-loader-modal.types';
import {LookupEntry} from 'shared/types/lookups';

export type ReportConfigurationDatasourceType = 'SQL' | 'TABLE' | 'FUNCTION';
export enum ReportConfigurationPosition {
    TOP = 'TOP',
    BOTTOM = 'BOTTOM',
    TOP_AND_BOTTOM = 'TOP_AND_BOTTOM',
}
export type ReportConfigurationSortOrder = 'ASC' | 'DESC';
export type ReportConfigurationDocumentFormat = 'XLS' | 'XLSX' | 'DOC' | 'DOCX' | 'CSV' | 'TXT' | 'XML' | 'PDF';
export type ReportConfigurationTypeGroup = 'NUMBER' | 'DATE' | 'OTHER';
export type ReportConfigurationAlign = 'LEFT_TOP' | 'LEFT_MIDDLE' | 'LEFT_BOTTOM'
    | 'RIGHT_TOP' | 'RIGHT_MIDDLE' | 'RIGHT_BOTTOM' | 'MIDDLE_TOP' | 'MIDDLE_MIDDLE' | 'MIDDLE_BOTTOM';
export type ReportConfigurationFontStyle = 'FONT_NORMAL' | 'FONT_BOLD' | 'FONT_CURSIVE' | 'FONT_UNDERLINE'
    | 'FONT_BOLD_CURSIVE' | 'FONT_BOLD_UNDERLINE' | 'text-blue' | 'text-bold'
    | 'FONT_CURSIVE_UNDERLINE' | 'FONT_BOLD_UNDERLINE_CURSIVE' | 'FONT_MONOSPACE';
export type ReportConfigurationQueryParameterVariableType = 'STRING' | 'INTEGER' | 'NUMBER'
    | 'DATE' | 'DATETIME' | 'BOOLEAN' | 'VALUESET' | 'MULTIVALUESET' | 'SQLVALUESET' | 'SQLMULTIVALUESET'
export type ReportConfigurationQueryParameterDefaultType = 'CONST' | 'CURRENT_DATETIME' | 'CONTEXT';

export interface ReportConfigurationCrColumnDto {
    columnName: string;
    sheetCode: string;
    sql: string;
}

export interface ReportConfigurationSortColumnDto {
    columnName: string;
    index: number;
    order: ReportConfigurationSortOrder;
}

export interface ReportConfigurationExportToDto {
    enabled: boolean;
    format: ReportConfigurationDocumentFormat;
    monitorProgramCode: string;
    monitorProgramId: number;
}

export interface ReportConfigurationDatasourceColumnDto {
    name: string;
    type: string;
    typeGroup: ReportConfigurationTypeGroup;
}

export interface ReportConfigurationReservedColumnsDto {
    pageNumber: string;
    rowNumber: string;
    sheetCode: string;
    rowIdColumnName: string;
}

export interface ReportConfigurationQueryParameterDto {
    columnName: string;
    defaultType: ReportConfigurationQueryParameterDefaultType;
    defaultValue: string;
    format: string;
    ordinal: number;
    paramName: string;
    reportTitle: string;
    required: boolean;
    sqlValueset: string;
    type: ReportConfigurationQueryParameterVariableType;
    valuesetLookupType: string;
    visible: boolean;
}
interface BlockedForRoleAndFunctionNames{
    roleNames: string[];
    functionName: string;
    description: string;
    conditions: CustomColumnCondition[] | null;
}

export interface ReportConfigurationReportColumnDto {
    align: ReportConfigurationAlign;
    columnOrdinal: number;
    fieldType: string;
    typeGroup: string;
    format: string;
    hidden: boolean;
    reportTitle: string;
    style: ReportConfigurationFontStyle;
    blockedForRoleAndFunctionNames: BlockedForRoleAndFunctionNames[];
    width: string;
    enabledTextWrapping: boolean;
    fieldFunction: 'DD' | 'URL';
}

interface CustomColumnCondition {
    columnName: string;
    operator: string;
    value: string;
}

export interface CustomColumnCase {
    color: string;
    icon: string;
    value: string;
    conditions: CustomColumnCondition[] | null;
}

export type CustomColumnType = 'ICON' | 'SWITCH';

export type ReportConfigurationCustomColumnDto = {
    columnName: string;
    type: CustomColumnType;
    cases: CustomColumnCase[];
} & Pick<ReportConfigurationReportColumnDto,
    'columnOrdinal'
    | 'hidden'
    | 'reportTitle'
    | 'align'
    | 'style'
    | 'enabledTextWrapping'
    | 'width'
    | 'fieldFunction'>

export interface ReportConfigurationReportComparisionDto {
    attr: string[];
    key: string[];
    sheetCode: string;
}

export interface ReportConfigurationReportGroupDto {
    aggFunction: 'MIN' | 'MAX' | 'SUM' | 'COUNT' | 'AVG';
    align: ReportConfigurationAlign;
    groupLabel: string;
    groupedColumnNames: string[];
    hiddenFlag: boolean;
    hierarchyLevel: number;
    placeholderColumnName: string;
    sortingDirection: ReportConfigurationSortOrder;
    style: ReportConfigurationFontStyle;
    subtotalLocation: ReportConfigurationPosition;
}

export interface ReportConfigurationTableHeaderDto {
    block: number;
    hidden: boolean;
    ordinal: number;
    reportTitle: string;
}

export interface ReportConfigurationRowFormat {
    color: string;
    columns: string[];
    conditions: {
        columnName: string;
        operator: string;
        value: string;
    }[];
    filling: string;
    style: string;
}

export interface ReportConfigurationReportMenuItemDto {
    annexNumber: string;
    caption: string;
    children: ReportConfigurationReportMenuItemDto[];
    hidden: boolean;
    name: string;
    sheetCode: string;
    sheetNumber: string;
    reportColumns: {[reportColumnName: string]: ReportConfigurationReportColumnDto};
    excelTableHeaderStructure?: ReportConfigurationExcelHeaderTableStructureDto;
}

export type ReportConfigurationExcelHeaderTableStructureDto = TableHeaderStructure;

export interface ReportConfigurationDataDto {
    createdBy: LookupEntry;
    createdDate: string;
    updatedBy: LookupEntry;
    updatedDate: string;
    templateCode: string;
    templateName: string;
    reportName: string;
    crColumns: ReportConfigurationCrColumnDto[];
    defaultSortColumns: ReportConfigurationSortColumnDto[];
    designTemplate: string;
    enabledColumnTitleOrdinals: boolean;
    enabledColumnsSettingsBtn: boolean;
    enabledComparison: boolean;
    enabledComparisonBtn: boolean;
    enabledCr: boolean;
    enabledCrBtn: boolean;
    enabledDownloadBtn: boolean;
    actionsEnabled: boolean;
    actions: string[];
    enabledMenu: boolean;
    enabledRefreshBtn: boolean;
    enabledRowAttachments: boolean;
    enabledRowComments: boolean;
    enabledRowRequests: boolean;
    enabledSearch: boolean;
    enabledPrint: boolean;
    enabledGroupingSettingsBtn: boolean;
    excelTableHeaderStructure: ReportConfigurationExcelHeaderTableStructureDto;
    exportTo: ReportConfigurationExportToDto[];
    headerDatasourceColumns: ReportConfigurationDatasourceColumnDto[];
    headerDatasourceType: ReportConfigurationDatasourceType;
    headerDatasourceValue: string;
    headerDatasourceSqlValue: string;
    reservedColumns: ReportConfigurationReservedColumnsDto;
    paginationPosition: ReportConfigurationPosition;
    queryParameters: ReportConfigurationQueryParameterDto[];
    reportComparisons: ReportConfigurationReportComparisionDto[];
    reportColumns: {[reportColumnName: string]: ReportConfigurationReportColumnDto};
    customColumns: ReportConfigurationCustomColumnDto[];
    reportGroups: {[reportGroupName: string]: ReportConfigurationReportGroupDto};
    reportHeaders: {[reportHeaderName: string]: ReportConfigurationTableHeaderDto};
    reportMenu: ReportConfigurationReportMenuItemDto[];
    sheetCodeDatasourceColumnName: string;
    sheetCodeDatasourceType: ReportConfigurationDatasourceType;
    sheetCodeDatasourceValue: string;
    tableDatasourceColumns: ReportConfigurationDatasourceColumnDto[];
    tableDatasourceType:ReportConfigurationDatasourceType;
    tableDatasourceValue: string;
    tableDatasourceSqlValue: string;
    withDocumentId: boolean;
    rowFormats: ReportConfigurationRowFormat[];
    groupAfterUserFilter: boolean;
    // deprecated ↴
    useHeader?: boolean;
}
