import {
    max,
} from 'lodash';
import {
    useMemo,
} from 'react';

import {ColumnDropdownSort, ColumnSortData} from '../../table-column-menu-types';
import {adaptSortDataForRequest} from './use-table-column-sort-utils';

const DEFAULT_SORT_DATA = {
    order: undefined,
    multiple: undefined,
} as const;

export type UseTableColumnSortArgs = {
    tableColumnSortData: ColumnSortData;
    setTableColumnSortData: (data: ColumnSortData) => void;
}

export const useTableColumnSort = (args: UseTableColumnSortArgs) => {
    const {
        tableColumnSortData,
        setTableColumnSortData,
    } = args;

    const resetAllFiltersSortData = () => {
        setTableColumnSortData({
            sort: {},
        });
    };

    const createSortDataGetter = (columnKey: string) => () => tableColumnSortData.sort
        ?.[columnKey] ?? DEFAULT_SORT_DATA;

    const createSortDataSetter = (columnKey: string) => (newSortData:
        Partial<Exclude<ColumnDropdownSort, undefined>>) => {
        const prevSortData = tableColumnSortData.sort;
        const prevColumnSortData = prevSortData?.[columnKey] ?? DEFAULT_SORT_DATA;

        const sortEntriesFiltered = Object.entries(tableColumnSortData.sort ?? {})
            .filter(([, value]) => !!value?.order);

        const multiple = !sortEntriesFiltered.length ? 1 : (max(
            sortEntriesFiltered.filter(([key]) => key !== columnKey)
                .map(([, d]) => d?.multiple ?? 0),
        ) ?? 0) + 1;

        setTableColumnSortData({
            ...tableColumnSortData,
            sort: {
                ...prevSortData,
                [columnKey]: {
                    ...prevColumnSortData,
                    ...{
                        ...newSortData,
                        multiple,
                    },
                },
            },
        });
    };

    const tableColumnSortDataAdaptedForRequest = useMemo(() => adaptSortDataForRequest(
        tableColumnSortData.sort,
    ), [tableColumnSortData]);

    return {
        tableColumnSortData,
        tableColumnSortDataAdaptedForRequest,
        createSortDataGetter,
        createSortDataSetter,
        resetAllFiltersSortData,
    };
};

export type SetSortDataFunction = ReturnType<ReturnType<typeof useTableColumnSort>['createSortDataSetter']>;
export type GetSortDataFunction = ReturnType<ReturnType<typeof useTableColumnSort>['createSortDataGetter']>;
export type SortDataAdaptedForRequest = ReturnType<
     typeof useTableColumnSort
>['tableColumnSortDataAdaptedForRequest'];
