import {isArray, isEqual} from 'lodash';

import {DefaultValueRuleType} from 'components/form/hooks/field-default-value.hook';
import {store} from 'store/config';
import {getContextDataValueByRule} from 'utils/common';

import {normalizeFormDataForRequest} from '../data/utils/data.utils';
import {FieldType, FormEntityMeta} from '../metadata/metadata-types';

/**
 * checks if there has been any modifications to form data
 * @param entityName
 * @param currentData
 * @param initialData
 * @param metadata
 */
export const isFormDirty = (
    entityName: string,
    currentData?: Record<string, any>,
    initialData?: Record<string, any>,
    metadata?: FormEntityMeta,
) => {
    if (!currentData || !initialData) {
        return false;
    }
    let isDirty = false;

    if (!metadata) {
        return Object.keys(currentData).some(key => currentData[key] !== initialData[key]);
    }

    const normalizedData = normalizeFormDataForRequest(currentData, metadata?.fields || []);

    Object.keys(normalizedData).forEach(key => {
        const fieldMeta = metadata?.fields.find(field => field.key === key);
        const keyWithoutId = key.slice(0, -2);
        const fieldMetaWithoutId = metadata?.fields.find(v => v.key === keyWithoutId);
        if (fieldMeta && fieldMeta.isHidden) {
            return;
        }

        if (!Object.keys(initialData).includes(key)) {
            // it could be reference value, that is normalized with id
            if (!fieldMeta && key.endsWith('Id')) {
                // it's a reference and its default value is saved without id
                if (fieldMetaWithoutId && fieldMetaWithoutId?.type === FieldType.REFERENCE
                    && Object.keys(initialData).includes(keyWithoutId)) {
                    if (isArray(currentData[keyWithoutId]) && currentData[keyWithoutId] !== initialData[keyWithoutId]) {
                        isDirty = true;
                    }
                    if (fieldMetaWithoutId?.useFirstOptionAsDefaultValue && fieldMetaWithoutId.referenceUrl) {
                        const refData = store.getState().DATA_MODULE[fieldMetaWithoutId.referenceUrl].REFERENCE.rows;
                        if (currentData[keyWithoutId].id !== refData[0].id) isDirty = true;
                        return;
                    }
                    if (fieldMetaWithoutId.defaultValueByRule) {
                        if (fieldMetaWithoutId.defaultValueByRule === DefaultValueRuleType.USER_TAX_PERIOD) {
                            const contextData = getContextDataValueByRule(
                                fieldMetaWithoutId.defaultValueByRule,
                                store.getState().CONTEXT_MODULE.rawData,
                            );
                            if (currentData[keyWithoutId].id !== contextData) isDirty = true;
                        }
                        return;
                    }
                    // reference value are stored as objects, so we need to check their id equality
                    if (currentData[keyWithoutId]?.id !== initialData[keyWithoutId]?.id) isDirty = true;
                }
                return;
            }
            if (currentData[key] && fieldMetaWithoutId && !fieldMetaWithoutId.defaultValueByRule) isDirty = true;
            return;
        }

        if (Object.keys(initialData).includes(key) && Object.keys(initialData).includes(keyWithoutId)
            && fieldMetaWithoutId?.type === FieldType.REFERENCE) {
            if (isArray(initialData[keyWithoutId])) {
                const initialDataIds = (initialData[keyWithoutId] as Array<any>).map(elem => elem.id).sort();
                const currentDataIds = (currentData[keyWithoutId] as Array<any>).map(elem => elem.id).sort();

                if (initialDataIds.length !== currentDataIds.length) {
                    isDirty = true;
                }

                for (let i = 0; i < initialDataIds.length; i += 1) {
                    if (initialDataIds[i] !== currentDataIds[i]) {
                        isDirty = true;
                    }
                }
            }

            if (typeof initialData[keyWithoutId] === 'object'
                ? currentData[keyWithoutId].id !== initialData[keyWithoutId].id
                : currentData[keyWithoutId].id !== initialData[keyWithoutId]) {
                isDirty = true;
            }
            return;
        }

        // reference value are stored as objects, so we need to check their id equality
        if (fieldMeta && fieldMeta?.type === FieldType.REFERENCE) {
            if (fieldMeta.defaultValueByRule) {
                if (fieldMeta.defaultValueByRule === DefaultValueRuleType.DEFAULT_LOOK_UP_VALUE && !initialData[key]) {
                    // attribute1 - дефолтное значение для поля monitorProgramId в directories.type.documents
                    if (!initialData[key] && currentData[key].attribute1 !== 'Y') isDirty = true;
                    if (initialData[key] && currentData[key].id !== initialData[key]) isDirty = true;
                }
                if ((fieldMeta.defaultValueByRule === DefaultValueRuleType.USER_ORGANIZATION
                    || fieldMeta.defaultValueByRule === DefaultValueRuleType.USER_TAX_PERIOD) && !initialData[key]) {
                    const contextData = getContextDataValueByRule(
                        fieldMeta.defaultValueByRule,
                        store.getState().CONTEXT_MODULE.rawData,
                    );
                    if (currentData[key].id !== contextData) isDirty = true;
                }
                if (initialData[key] && currentData[key].id !== initialData[key]) isDirty = true;
                return;
            }

            if (fieldMeta.defaultValue) {
                if (typeof initialData[key] === 'string' && currentData[key].lookupCode !== initialData[key]) {
                    isDirty = true;
                }
                if (typeof initialData[key] === 'number' && currentData[key].id !== initialData[key]) {
                    isDirty = true;
                }
                return;
            }

            if (isArray(currentData[key]) && currentData[key] !== initialData[key]) {
                isDirty = true;
            }

            if (fieldMeta.useFirstOptionAsDefaultValue && fieldMeta.referenceUrl) {
                const referenceData = store.getState().DATA_MODULE[fieldMeta.referenceUrl].REFERENCE.rows;
                if (!initialData[key] && currentData[key].id !== referenceData[0].id) {
                    isDirty = true;
                }
                if (initialData[key] && !initialData[key]?.id) {
                    if (initialData[key] && fieldMeta.additionalInputOptions
                        && currentData[key].id !== initialData[key]) {
                        isDirty = true;
                    }
                    if (initialData[key] && currentData[key].id && currentData[key].id !== initialData[key]) {
                        isDirty = true;
                    }
                    if (initialData[key] && !currentData[key].id && currentData[key] !== initialData[key]) {
                        isDirty = true;
                    }
                    return;
                }
                if (initialData[key]?.id && currentData[key].id && currentData[key].id !== initialData[key]?.id) {
                    isDirty = true;
                }

                return;
            }
            if (initialData[key]?.id) {
                if (currentData[key]?.id !== initialData[key]?.id) isDirty = true;
                return;
            }
            if (currentData[key]?.id) {
                if (currentData[key]?.id !== initialData[key]) isDirty = true;
                return;
            }
            if (currentData[key] !== initialData[key]) isDirty = true;
            return;
        }

        if (fieldMeta && fieldMeta?.type === FieldType.STATIC_SELECT) {
            if (!initialData[key] && fieldMeta.defaultValue !== currentData[key]) isDirty = true;
            if (initialData[key] && currentData[key] !== initialData[key]) isDirty = true;
            return;
        }

        if (fieldMeta && fieldMeta?.type === FieldType.COMBINED_FIELD_WITH_DELETE && fieldMeta.children) {
            if (!initialData[key] && !fieldMeta.children.some(field => currentData[key][field.key])) {
                return;
            }
            if (fieldMeta?.children.some(field => initialData[key][field.key] !== currentData[key][field.key])) {
                isDirty = true;
            }
            return;
        }

        if (fieldMeta && fieldMeta?.type === FieldType.DATE) {
            if (currentData[key] !== initialData[key]) isDirty = true;
            return;
        }

        if (fieldMeta && fieldMeta?.type === FieldType.FIELDS_TREE) {
            currentData[key].forEach((program: any, index: number) => {
                if (initialData[key] && initialData[key][index]) {
                    if (program.program.id && initialData[key][index].program) {
                        if (program.program.id !== initialData[key][index].program.id) {
                            isDirty = true;
                        }
                    }
                    return;
                }
                if (program) isDirty = true;
            });
            return;
        }

        const initialFormData = store.getState().DATA_MODULE[entityName].FORM.initialData;
        if (metadata.additionalOptions ? !isEqual(normalizedData[key].toString(), initialFormData[key].toString())
            : !isEqual(normalizedData[key].toString(), initialData[key].toString())) {
            isDirty = true;
        }
    });

    return isDirty;
};
