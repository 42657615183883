import {InputNumber} from 'antd';
import React, {ForwardedRef} from 'react';

import {selectFormEntityData} from 'modules/data/data-selectors';
import {useAppSelector} from 'store/config/hooks';

import './input-number.less';

export const InputNumberCell = React.forwardRef(({
    value, onChange, onBlur, entityName, ...props
}: any, ref: ForwardedRef<any>) => {
    const formData = useAppSelector(state => selectFormEntityData(entityName)(state))?.data;
    return (
        <InputNumber
            className="input-number"
            value={value < 0 ? 0 : value}
            min={formData?.minScoreValue}
            max={formData?.maxScoreValue}
            disabled={formData?.enabledClosedPeriod}
            onChange={onChange}
            onBlur={onBlur}
            ref={ref}
            {...props}
        />
    );
});
