import {
    Button, Form, Input,
} from 'antd';
import {FormInstance} from 'antd/es/form';
import {FormListFieldData} from 'antd/es/form/FormList';
import cn from 'classnames';
import React, {useEffect, useState} from 'react';

import {SimpleActionButton} from 'components/form/components';
import {CustomSelect} from 'components/form/inputs/custom-select';
import {ModalCloseConfirmation} from 'components/modal-close-confirmation';
import {ReactComponent as TrashXOutlined} from 'shared/assets/trash-x.svg';

import {ChildrenList} from './report-table-of-contents-children/report-table-of-contents-children';

import '../report-table-of-content.less';

interface TableOfContentsRowData {
    isEditingForm: boolean;
    field: FormListFieldData;
    remove: (name: number) => void;
    form: FormInstance;
    fieldsLength?: number;
    childStructure: Record<string, any>;
    handleChildStructure: () => void;
}

export const TableOfContentsRow = ({
    isEditingForm,
    field,
    remove,
    form,
    fieldsLength,
    childStructure,
    handleChildStructure,
}: TableOfContentsRowData) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [childLength, setChildLength] = useState<number>();
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

    useEffect(() => { handleChildStructure(); }, [childLength]);

    useEffect(() => {
        const values = form.getFieldsValue();
        setChildLength(values.reportMenu[field.name]?.children);
    }, [fieldsLength]);

    const handleDelete = () => {
        remove(field.name);
        handleChildStructure();
        setChildLength(0);
    };

    const handleBackModal = () => {
        setIsOpenModal(false);
        handleDelete();
    };

    return (
        <>
            <div
                key={field.fieldKey}
                className={cn('form-list__container', !isOpen && 'with-margin-bottom',
                    !childLength && childStructure.isChildNested && 'with-margin-left')}
            >
                <div className={cn('form-list__items', childLength ? 'items-row-large' : 'items-row')}>
                    { !!childLength && (
                        <Form.Item label="">
                            <SimpleActionButton
                                type="link"
                                onClick={() => setIsOpen(!isOpen)}
                                className="collapse-action-btn"
                                icon={isOpen ? 'MinusCircleOutlined' : 'PlusCircleOutlined'}
                                key={field.key}
                            />
                        </Form.Item>
                    )}
                    <Form.Item
                        style={{width: 375}}
                        label="Название"
                        name={[field.name, 'name']}
                        rules={[
                            {required: true},
                            {max: 1000, message: 'Длина должна быть менее 1000 символов'},
                        ]}
                    >
                        <Input
                            disabled={!isEditingForm}
                            placeholder="Введите название"
                        />
                    </Form.Item>
                    <Form.Item
                        style={{width: 375}}
                        label="Заголовок"
                        name={[field.name, 'caption']}
                        rules={[
                            {required: true},
                            {max: 1000, message: 'Длина должна быть менее 1000 символов'},
                        ]}
                    >
                        <Input
                            disabled={!isEditingForm}
                            placeholder="Введите заголовок"
                        />
                    </Form.Item>
                    <Form.Item
                        style={{width: 137}}
                        label="Раздел"
                        name={[field.name, 'sheetCode']}
                        rules={[
                            {required: true, message: 'Введите раздел'},
                            {max: 100, message: 'Длина должна быть менее 100 символов'},
                        ]}
                    >
                        <Input
                            disabled={!isEditingForm}
                            placeholder="Введите раздел"
                        />
                    </Form.Item>
                    <Form.Item
                        style={{width: 82}}
                        label="Скрыт"
                        name={[field.name, 'hidden']}
                    >
                        <CustomSelect
                            entries={[
                                {label: 'Нет', value: false},
                                {label: 'Да', value: true},
                            ]}
                            settings={{
                                formFieldKey: 'reportMenu',
                                formInstance: form,
                                formFieldPathName: [field.name, 'hidden'],
                                isDisabled: !isEditingForm,
                                useFirstOptionAfterEntriesChanged: true,
                                noUseFirstOptionWhenValueIsSet: true,
                                setFirstOptionUntouched: true,
                                placeholder: 'Да/нет',
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        style={{width: 107}}
                        label="Лист"
                        name={[field.name, 'sheetNumber']}
                        rules={[{max: 100, message: 'Длина должна быть менее 100 символов'}]}
                    >
                        <Input
                            disabled={!isEditingForm}
                            placeholder="Введите лист"
                        />
                    </Form.Item>
                    <Form.Item
                        style={{width: 137}}
                        label="Приложение"
                        name={[field.name, 'annexNumber']}
                        rules={[{max: 100, message: 'Длина должна быть менее 100 символов'}]}
                    >
                        <Input
                            disabled={!isEditingForm}
                            placeholder="Введите приложение"
                        />
                    </Form.Item>
                    <Form.Item
                        hidden
                        name={[field.name, 'reportColumns']}
                    />
                    <Form.Item label={<>&nbsp;</>}>
                        <Button
                            className="button-only-icon"
                            type="primary"
                            disabled={!isEditingForm}
                            icon={<TrashXOutlined />}
                            onClick={() => {
                                if (childLength) setIsOpenModal(true); else handleDelete();
                            }}
                        />
                    </Form.Item>
                </div>
                <ModalCloseConfirmation
                    isOpen={isOpenModal}
                    onClose={handleBackModal}
                    onBack={handleBackModal}
                    key={field.fieldKey}
                    message="При удалении записи, будут удалены также дочерние записи. Удалить?"
                    closeTitle="Удалить"
                />
                <ChildrenList
                    form={form}
                    isEditingForm={isEditingForm}
                    fieldName={field.name}
                    isOpen={isOpen}
                    handleIsOpen={setIsOpen}
                    handleChildLength={setChildLength}
                    path={[field.name]}
                    childStructure={childStructure}
                    handleChildStructure={handleChildStructure}
                    fullPath={[field.name, 'children']}
                />
            </div>
        </>

    );
};
