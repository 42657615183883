import {unwrapResult} from '@reduxjs/toolkit';
import {Spin} from 'antd';
import Title from 'antd/lib/typography/Title';
import React, {ReactText, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {
    PieChart as PieChartRecharts,
    Pie,
    Cell,
} from 'recharts';

import {Spinner} from 'components/spinner';
import {ContextRawData} from 'modules/context/context-types';
import {showRequestErrorMessage} from 'shared/utils/show-message-from-response';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';
import {loadDocJournalData, selectWidgetsDocJournalData} from 'store/slices/widgets-slice';

import {LocationStateKey, useAppHistory} from '../../../../shared/hooks/use-app-history';
import {Card} from '../../components/card';
import './doc-journal-pie-chart.less';

export interface DocJournalData {
    status: string;
    statusDesc: string;
    statusAmount: number;
    statusAmountPercent: number;
}

const COLORS = ['#FF7C84', '#FFE068', '#67D89F'];

interface DocumentsPublicationChartProps {
    title?: string;
    contextRawData: ContextRawData | undefined;
    backLink?: {
        url: string;
        title: string;
    };
}

export const DocJournalPieChart: React.FunctionComponent<DocumentsPublicationChartProps> = ({
    title = 'Публикация документов',
    contextRawData,
    backLink = {
        title: 'Журнал загрузки документов →',
        url: 'table-reports/doc_load_journal',
    },
}: DocumentsPublicationChartProps) => {
    const dispatch = useAppDispatch();
    const appHistory = useAppHistory();
    const docJournalData = useAppSelector(selectWidgetsDocJournalData);

    useEffect(() => {
        if (contextRawData && contextRawData.taxPeriodId && contextRawData.organizationId) {
            dispatch(loadDocJournalData({
                taxPeriodId: contextRawData.taxPeriodId,
                organizationId: contextRawData.organizationId,
            })).then(unwrapResult).catch(showRequestErrorMessage);
        }
    }, [contextRawData]);

    const pieData: { name: string; value: ReactText; status: string }[] | undefined = docJournalData?.map(row => (
        {name: row.statusDesc, value: row.statusAmount, status: row.status}
    ));

    const isLoading = useAppSelector(s => selectIsThunkPending(s, loadDocJournalData.typePrefix));

    const handleClick = (event: React.MouseEvent<HTMLElement>, status: string) => {
        event.preventDefault();
        const breadcrumbs = [
            {
                id: '1',
                name: 'Главная страница',
                path: '/widgets',
            },
            {
                id: '2',
                name: 'Журнал загрузки документов',
                path: `/${backLink?.url}`,
            },
        ];
        appHistory.pushWithStateUpdate('table-reports/doc_load_journal', {
            [LocationStateKey.TABLE_REPORT_BREADCRUMBS]: breadcrumbs,
            [LocationStateKey.AIS_DOCUMENT_JOURNAL_PIE_CART]: {
                p_doc_load_status: status,
            },
        });
    };

    const renderCustomizedLabel = (url: string) => ({
        payload,
        percent,
        x, y, cx, cy, stroke, fill, name, textAnchor,
    }: any) => {
        const textProps = {
            x, y, cx, cy, stroke, fill, name, textAnchor,
        };
        const label = `${payload.name} ${(percent * 100).toFixed(0)}%`;

        return (
            <Link
                to={url}
                onClick={(event: React.MouseEvent<HTMLElement>) => handleClick(event, payload.status)}
            >
                <text {...textProps}>
                    <tspan className="doc-journal-pie-chart-widget__chart-label">{label}</tspan>
                </text>
            </Link>
        );
    };

    return (
        <Card className="doc-journal-pie-chart-widget">
            <div className="doc-journal-pie-chart-widget__header-wrapper">
                <Title
                    className="doc-journal-pie-chart-widget__title"
                    level={2}
                >
                    {title}
                </Title>
                <Link to={backLink.url}>
                    {backLink.title}
                </Link>
            </div>

            {
                !(pieData && contextRawData) ? <Spinner style={{marginTop: 60}} />
                    : (
                        <Spin
                            spinning={isLoading}
                            tip="Загрузка..."
                        >
                            <PieChartRecharts
                                className="doc-journal-pie-chart-widget__chart"
                                width={700}
                                height={400}
                                margin={{
                                    top: 15,
                                    left: 150,
                                    right: 150,
                                    bottom: 15,
                                }}
                            >
                                <Pie
                                    dataKey="value"
                                    nameKey="name"
                                    data={pieData ?? []}
                                    cx={200}
                                    cy={200}
                                    outerRadius={160}
                                    label={renderCustomizedLabel(backLink?.url)}
                                >
                                    {pieData?.map((entry, index) => (
                                        <Cell
                                            key={entry.value}
                                            fill={COLORS[index % COLORS.length]}
                                        />
                                    ))}
                                </Pie>
                            </PieChartRecharts>
                        </Spin>
                    )
            }
        </Card>
    );
};
