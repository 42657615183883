import {FieldMeta, FieldType} from 'modules/metadata';

export const settingProgramTitle = ' Настройки программ';
export const SETTINGS_PROGRAMS_ENTITY_NAME = 'administration.settings.programs';
export const MONITOR_PROGRAM_KEY = 'CUSTOM_PARSING_CSV';

export const SettingsProgramsCodesTabKey = {
    SETTING_PROGRAM: 'SETTING_PROGRAM',
    PROGRAMS_MAPPING: 'PROGRAMS_MAPPING',
    PROGRAMS_SETS: 'PROGRAMS_SETS',
    SET_STAGES: 'SET_STAGES',
    STAGES_LINK: 'STAGES_LINK',
};
export const SettingsProgramsFormTitles = {
    [SettingsProgramsCodesTabKey.SETTING_PROGRAM]: 'Настройка программ',
    [SettingsProgramsCodesTabKey.PROGRAMS_MAPPING]: 'Настройки маппинга для программ',
    [SettingsProgramsCodesTabKey.PROGRAMS_SETS]: 'Настройки работы программ',
    [SettingsProgramsCodesTabKey.SET_STAGES]: 'Определение этапов для наборов программ',
    [SettingsProgramsCodesTabKey.STAGES_LINK]: 'Связь этапов для наборов программ',
};
export const SettingsProgramCodesEntityName = {
    [SettingsProgramsCodesTabKey.SETTING_PROGRAM]: 'administration.settings.programs.programs',
    [SettingsProgramsCodesTabKey.PROGRAMS_MAPPING]: 'administration.settings.programs.mappings',
    [SettingsProgramsCodesTabKey.PROGRAMS_SETS]: 'administration.settings.programs.sets',
    [SettingsProgramsCodesTabKey.SET_STAGES]: 'administration.program.set.stages',
    [SettingsProgramsCodesTabKey.STAGES_LINK]: 'administration.program.set.stages.link',
};

export const SettingMappingHeaderCsvField: FieldMeta = {
    key: 'namesHeaderCsv',
    isRequired: true,
    label: 'Наименование из шапки csv',
    placeholder: 'Введите наименование из шапки csv',
    type: FieldType.STRING,
    width: 620,
};
