import {useEffect, useState} from 'react';
import {DraggableLocation, DroppableId, DropResult} from 'react-beautiful-dnd';

import {useAppSelector} from 'store/config/hooks';
import {selectUserInfo} from 'store/slices/auth-slice';

/**
 * Moves an items inside of list
 */
const reorder = <DraggableComponentProps, >(list: DraggableComponentProps[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = <DraggableComponentProps, >(
    source: DraggableComponentProps[],
    destination: DraggableComponentProps[],
    droppableSource: DraggableLocation,
    droppableDestination: DraggableLocation,
) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result: Record<DroppableId, DraggableComponentProps[]> = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

/**
 * Moves an item from one list to new empty list.
 */
const moveToNewColumn = <DraggableComponentProps, >(
    source: DraggableComponentProps[],
    droppableSource: DraggableLocation,
    droppableDestinationId: number,
) => {
    const sourceClone = Array.from(source);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    const result: Record<DroppableId, DraggableComponentProps[]> = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestinationId] = [removed];
    return result;
};

const enrichDraggableComponentsWithId = <DraggableComponentProps extends DraggableComponent, >(
    draggableComponents: DraggableComponentProps[][],
) => draggableComponents.map((draggableBlock, offset) => (
        draggableBlock.map((draggableProps, idx) => ({
            id: `item-${offset}-${idx}-${new Date().getTime()}`,
            ...draggableProps,
        }))));

export interface DraggableComponent {
    component: string;
}

interface DraggableComponentWithId extends DraggableComponent {
    id: string;
}

export const useDraggableComponents = <
    DraggableComponentProps extends DraggableComponent,
    ReturningDraggableComponent extends DraggableComponentProps & DraggableComponentWithId
>(
        draggableArray: DraggableComponentProps[][],
        persistKey?: string,
    ) => {
    const user = useAppSelector(selectUserInfo);
    const persistUserAwareKey = persistKey && user ? `${persistKey}-${user?.userName}-${user?.userId}` : null;
    const persistedComponents = persistUserAwareKey ? localStorage.getItem(persistUserAwareKey) : null;

    const [components, setComponents] = useState<ReturningDraggableComponent[][]>(
        persistedComponents
            ? JSON.parse(persistedComponents)
            : enrichDraggableComponentsWithId(draggableArray) as unknown as ReturningDraggableComponent[][],
    );

    // persist current draggable state
    useEffect(() => {
        if (persistUserAwareKey) {
            localStorage.setItem(persistUserAwareKey, JSON.stringify(components));
        }
    }, [components]);

    // for mac cursor)
    const handleDragStart = () => {
        document?.querySelector('body')?.classList.add('dragging');
    };

    const handleDragEnd = (result: DropResult) => {
        const {destination, source} = result;
        document?.querySelector('body')?.classList.remove('dragging');

        // if dropped outside the droppable area
        if (!destination) {
            // create new column
            if (components.length >= components.flat(2).length) {
                // колонок уже ровно столько сколько в них могут поместиться компоненты по одному
                return;
            }
            const sourceIndex = +source.droppableId;
            const destinationIndex = components.length + 1;
            const newPosition: Record<string, ReturningDraggableComponent[]> = moveToNewColumn(
                components[sourceIndex], source, destinationIndex,
            );

            const newState = [...components];
            newState[sourceIndex] = newPosition[sourceIndex];
            newState[destinationIndex] = newPosition[destinationIndex];
            setComponents(newState.filter(el => el.length));

            return;
        }
        const sourceIndex = +source.droppableId;
        const destinationIndex = +destination.droppableId;

        if (sourceIndex === destinationIndex) {
            const items = reorder(components[sourceIndex], source.index, destination.index);
            const newState = [...components];
            newState[sourceIndex] = items;
            setComponents(newState);
        } else {
            const newPosition: Record<string, ReturningDraggableComponent[]> = move(
                components[sourceIndex],
                components[destinationIndex],
                source,
                destination,
            );
            const newState = [...components];
            newState[sourceIndex] = newPosition[sourceIndex];
            newState[destinationIndex] = newPosition[destinationIndex];
            setComponents(newState.filter(el => el.length));
        }
    };

    return {components, handleDragStart, handleDragEnd};
};
