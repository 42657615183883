import {FormInstance, Rule} from 'antd/es/form';
import {isNil} from 'lodash';
import React, {Dispatch} from 'react';

import {getRegExpPassword} from 'components/form/utils/form.utils';
import {FormEntityData} from 'modules/data/data-types';
import {FieldMeta, FieldType, ValidationRules} from 'modules/metadata/metadata-types';

import {FormMode} from '../form-types';

export enum maskValidation {
    dynamic = 'dynamic',
    checkValueLength = 'checkValueLength',
    checkNumericalValue = 'checkNumericalValue',
    checkIntegerValue = 'checkIntegerValue',
    checkSpecialSymbols = 'checkSpecialSymbols',
    email = 'email',
    fromProperty = 'fromProperty',
    addictiveness = 'addictiveness',
    requiredMatchPasswordField = 'requiredMatchPasswordField',
    onlyWords = 'onlyWords',
    wordsWithDots = 'wordsWithDots',
    shortUrl = 'shortUrl',
    checkByDateLater = 'checkByDateLater',
    errorWatcher = 'errorWatcher',
    checkFieldUniqueness = 'checkFieldUniqueness',
    fromPropertyListPassword = 'fromPropertyListPassword',
    fromPropertyListPasswordLength = 'fromPropertyListPasswordLength',
    checkDateAfterNow = 'checkDateAfterNow',
}

const defaultErrorMessage = 'Введите корректное значение';

export interface FieldValidationResolverProps {
    validationRules?: ValidationRules[];
    fieldMeta: Pick<FieldMeta, 'type' | 'label' | 'isDisabled' | 'isRequired' | 'dependentsFieldKey' | 'dateFormat'>;
    validationRulesByWatcher?: ValidationRules[];
    formData?: FormEntityData | undefined;
    initialValue?: any;
    form?: FormInstance | undefined;
    requiredMatchPasswordField?: string | undefined;
    propertyCode?: string | undefined;
    regularFromProperty?: string;
    setRegularFromProperty?: React.Dispatch<React.SetStateAction<string>>;
    isRequiredByWatcher?: boolean;
    isAllowedEmptyByWatcher?: boolean;
    settings?: {
        checkFieldUniquenessArrayPath?: string;
        checkFieldUniquenessFieldKey?: string;
    };
    dispatch?: Dispatch<unknown>;
    propertySettings?: Record<string, any>;
    formMode?: FormMode;
}

export const fieldValidationResolver = (
    {
        form,
        fieldMeta,
        validationRules = [],
        validationRulesByWatcher = [],
        isAllowedEmptyByWatcher,
        isRequiredByWatcher,
        requiredMatchPasswordField,
        propertyCode,
        regularFromProperty,
        formData,
        settings = {},
        propertySettings,
        formMode,
    }: FieldValidationResolverProps,
): Rule[] => {
    const {
        isDisabled, isRequired: isRequiredMeta, type,
    } = fieldMeta;
    const {checkFieldUniquenessArrayPath, checkFieldUniquenessFieldKey} = settings;

    const isRequired = !isAllowedEmptyByWatcher && (isRequiredMeta || isRequiredByWatcher);

    const rules: Rule[] = [{
        required: isRequired,
        message: isRequired
            ? `Поле '${fieldMeta.label}' должно быть заполнено` : '',
    }];

    if (type !== FieldType.FILE && type !== FieldType.MULTI_VALUE_SET) {
        rules.push({
            pattern: isRequired ? /^(?!\s*$).+/ : /^/,
            message: isRequired
                ? `Поле '${fieldMeta.label}' не должно быть пустым` : '',
        });
    }

    const newRule: Rule[] = [...validationRules, ...validationRulesByWatcher].map(item => {
        const {
            mask,
            regExpKey,
            minValueLength,
            maxValueLength,
            minNumericalValue,
            maxNumericalValue,
            errorMessage,
            regExp,
        } = item;
        if (mask === maskValidation.dynamic) {
            const regExpValue = regExpKey && formData?.data[regExpKey]?.toString();
            return (() => ({
                validator(_: any, value: string) {
                    if (value && value.match(String(regExpValue))) {
                        return Promise.resolve();
                    }
                    return Promise.reject(
                        new Error(
                            (errorMessage && formData?.data[errorMessage]?.toString())
                            || defaultErrorMessage,
                        ),
                    );
                },
            }));
        }
        if (mask === maskValidation.shortUrl) {
            return (() => ({
                validator(_: any, value: string) {
                    const regex = /^(\/.+)*$/;
                    if (((!value && !isRequired) || (value && value.match(regex)))) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error(errorMessage ?? 'Недопустимый формат URL'));
                },
            }));
        }
        if (mask === maskValidation.onlyWords) {
            return (() => ({
                validator(_: any, value: string) {
                    const regex = /^[\w]*$/;
                    if ((!value && !isRequired) || (value && value.match(regex))) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error(
                        errorMessage ?? 'Допустимы только символы "a-z", "A-Z", "0-9" и "_"',
                    ));
                },
            }));
        }
        if (mask === maskValidation.wordsWithDots) {
            return (() => ({
                validator(_: any, value: string) {
                    const regex = /^[\w.]*$/;
                    if ((!value && !isRequired) || (value && value.match(regex))) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error(
                        errorMessage ?? 'Допустимы только символы "a-z", "A-Z", "0-9", "_" и "."',
                    ));
                },
            }));
        }
        if (mask === maskValidation.checkValueLength) {
            return (() => ({
                validator(_: any, value: string) {
                    if (!isNil(maxValueLength) && !isNil(minValueLength)) {
                        if ((!value && !isRequired)
                            || (value && value.toString().length <= maxValueLength
                                && value.toString().length >= minValueLength)) {
                            return Promise.resolve();
                        }
                    }
                    return Promise.reject(new Error(errorMessage ?? defaultErrorMessage));
                },
            }));
        }
        if (mask === maskValidation.checkSpecialSymbols) {
            return (() => ({
                validator(_: any, value: string) {
                    if ((!value && !isRequired)
                        || (value && value.toString().match(regExp || '') && !isDisabled)) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error(errorMessage ?? defaultErrorMessage));
                },
            }));
        }
        if (mask === maskValidation.checkNumericalValue) {
            return (() => ({
                validator(_: any, value: string) {
                    const leadingZerosRegex = /^0[0-9].*$/;
                    if (leadingZerosRegex.test(value)) {
                        return Promise.reject(
                            new Error(errorMessage ?? defaultErrorMessage),
                        );
                    }
                    if (!isNil(maxNumericalValue) && !isNil(minNumericalValue)) {
                        if (Number(value) <= maxNumericalValue && Number(value) >= minNumericalValue) {
                            return Promise.resolve();
                        }
                        return Promise.reject(
                            new Error(errorMessage ?? defaultErrorMessage),
                        );
                    }
                    if (!isNil(minNumericalValue) && Number(value) >= minNumericalValue) {
                        return Promise.resolve();
                    }
                    if (!isNil(maxNumericalValue) && Number(value) <= maxNumericalValue) {
                        return Promise.resolve();
                    }
                    return Promise.reject(
                        new Error(errorMessage ?? defaultErrorMessage),
                    );
                },
            }));
        }
        if (mask === maskValidation.checkIntegerValue) {
            return (() => ({
                validator(_: any, value: string) {
                    if (/^-?\d+$/.test(value)) {
                        return Promise.resolve();
                    }
                    return Promise.reject(
                        new Error(errorMessage ?? defaultErrorMessage),
                    );
                },
            }));
        }
        if (mask === maskValidation.email) {
            return (
                {
                    type: 'email',
                    message: errorMessage,
                }
            );
        }
        if (mask === maskValidation.addictiveness) {
            return (({getFieldValue}) => ({
                validator() {
                    if (getFieldValue(fieldMeta?.dependentsFieldKey || '') === undefined) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error(errorMessage || 'Error'));
                },
            }));
        }
        if (mask === maskValidation.fromPropertyListPassword) {
            return (() => ({
                validator(_: any, value: string) {
                    // Символы полученные из "Настройки приложения"
                    const specialSymbols = propertySettings?.ALLOWED_CHARACTERS_IN_PASSWORD;
                    const regExpPassword = getRegExpPassword(specialSymbols);
                    if ((!value && !isRequired)
                        || (value && value.toString().match(regExpPassword || '') && !isDisabled)
                        || (formMode === FormMode.EDIT && value.length === 1)) {
                        return Promise.resolve();
                    }
                    const passwordErrorMessage = errorMessage && `${errorMessage} ${specialSymbols}`;
                    return Promise.reject(new Error(passwordErrorMessage ?? defaultErrorMessage));
                },
            }));
        }
        if (mask === maskValidation.fromPropertyListPasswordLength) {
            return (() => ({
                validator(_: any, value: string) {
                    // Длина пароля, полученная из "Настройки приложения"
                    const passwordLengthMax = propertySettings?.PASSWORD_LENGTH_MAX;
                    const passwordLengthMin = propertySettings?.PASSWORD_LENGTH_MIN;
                    if (formMode === FormMode.EDIT && !value && !isRequired) {
                        return Promise.resolve();
                    }
                    if (passwordLengthMin && value && value.toString().length >= Number(passwordLengthMin)
                        && passwordLengthMax && value && value.toString().length <= Number(passwordLengthMax)) {
                        return Promise.resolve();
                    }
                    const passwordErrorMessage = errorMessage?.replace(
                        'passwordLengthMax', passwordLengthMax,
                    )?.replace('passwordLengthMin', passwordLengthMin);
                    return Promise.reject(
                        new Error(passwordErrorMessage ?? defaultErrorMessage),
                    );
                },
            }));
        }
        if (mask === maskValidation.requiredMatchPasswordField) {
            if (requiredMatchPasswordField) {
                return (() => ({
                    validator(_: any, value: string) {
                        const otherPasswordFieldValue = form?.getFieldValue(requiredMatchPasswordField);
                        if (otherPasswordFieldValue === value || (!otherPasswordFieldValue && !value)) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error(errorMessage));
                    },
                }));
            }
        }
        if (mask === maskValidation.fromProperty) {
            if (propertyCode) {
                const regValue = new RegExp(String(regularFromProperty));
                return (() => ({
                    validator(_: any, value: string) {
                        if (value && value.match(regValue)) {
                            return Promise.resolve();
                        }
                        return Promise.reject(
                            new Error(
                                (errorMessage && formData?.data[errorMessage]?.toString())
                                || defaultErrorMessage,
                            ),
                        );
                    },
                }));
            }
        }
        if (mask === maskValidation.checkByDateLater) {
            return (({getFieldValue}) => ({
                validator(_: any, value: string) {
                    const endDate = getFieldValue(fieldMeta?.dependentsFieldKey || '');
                    if (endDate <= value) {
                        return Promise.resolve();
                    }
                    return Promise.reject(
                        new Error(errorMessage || defaultErrorMessage),
                    );
                },
            }));
        }
        if (mask === maskValidation.errorWatcher) {
            return (() => ({
                validator() {
                    return Promise.reject(
                        new Error(errorMessage || defaultErrorMessage),
                    );
                },
            }));
        }
        if (mask === maskValidation.checkFieldUniqueness) {
            return ({getFieldValue}) => ({
                validator: (_, value) => {
                    const values = getFieldValue(checkFieldUniquenessArrayPath || '');
                    const valuesConverted = Object
                        .entries(values ?? [])
                        .map(([key, val]) => ({
                            keyName: key,
                            ...(val as Object),
                        }));
                    const sameKeys = valuesConverted
                        ?.filter((entry: any) => !!value
                            && entry?.[checkFieldUniquenessFieldKey || ''] === value);
                    if (sameKeys?.length > 1) {
                        return Promise.reject(new Error(errorMessage));
                    }
                    return Promise.resolve();
                },
            });
        }
        if (mask === maskValidation.checkDateAfterNow) {
            return (() => ({
                validator(_: any, value: string) {
                    if (new Date(value) > new Date()) {
                        return Promise.resolve();
                    }
                    return Promise.reject(
                        new Error(errorMessage ?? defaultErrorMessage),
                    );
                },
            }));
        }
        return (() => ({
            validator(_: any, value: any) {
                let valueStr = value?.toString();

                if (fieldMeta.type === FieldType.RICH_TEXT) {
                    valueStr = value?.replace(/<(.|\n)*?>/g, '').replace('&lt;', '<').replace('&gt;', '>');
                }
                if ((!valueStr && !isRequired) || (valueStr && valueStr.match(regExp || ''))) {
                    return Promise.resolve();
                }
                return Promise.reject(
                    new Error(errorMessage ?? defaultErrorMessage),
                );
            },
        }));
    });
    return [...rules, ...newRule];
};

export interface LengthErrorProps {
    min?: number;
    max?: number;
}

const getSymbolWord = (length: number) => (
    length === 1 ? `${length} символа` : `${length} символов`
);

export const constructLengthErrorMessage = ({min, max}: LengthErrorProps) => {
    const isMinValid = min && min > 0;
    const isMaxValid = max && max > 0;

    if (isMinValid && isMaxValid) {
        return `Данное поле должно содержать не менее ${getSymbolWord(min)} и не более ${getSymbolWord(max)}`;
    }
    if (isMinValid) {
        return `Данное поле должно содержать не менее ${getSymbolWord(min)}`;
    }
    if (isMaxValid) {
        return `Данное поле должно содержать не более ${getSymbolWord(max)}`;
    }

    return '';
};
