import {SaveOutlined, UndoOutlined} from '@ant-design/icons';
import {Button, message} from 'antd';
import {FormInstance} from 'antd/es/form';
import cn from 'classnames';
import React, {useContext} from 'react';

import {ButtonChangeHistory} from 'components/report-configuration/components/button-change-history';
import {ReportConfigurationMessages} from 'components/report-configuration/constants/report-configuration-constants';
import {WatcherFieldFlag} from 'components/report-configuration/hooks/use-form-fields-manager';
import {ReportConfigurationTabKey} from 'components/report-configuration/report-configuration.constants';
import {ReportConfigurationContext} from 'components/report-configuration/report-configuration.context';
import {ActionConfirmModal} from 'components/table/action/action-confirm-modal/action-confirm-modal';
import {ReactComponent as ArrowBackOutlined} from 'shared/assets/arrow-back-outlined.svg';
import {ReactComponent as CloseXOutlined} from 'shared/assets/close-x-outlined.svg';
import {ReactComponent as EditWithLine} from 'shared/assets/edit-with-line.svg';
import {setFieldsValueUntouched} from 'shared/utils';

import {ReportPropertiesFormState} from '../report-properties-settings';

export interface ReportPropertiesSettingsButtonsProps {
    form: FormInstance;
    formState: ReportPropertiesFormState;
    setFormState: React.Dispatch<React.SetStateAction<ReportPropertiesFormState>>;
    checkArrayIndependentWatcherFlag?: (key: string, flag: WatcherFieldFlag) => boolean;
    isClearButtonDisabled?: boolean;
    name: string;
}

export const ReportPropertiesSettingsButtons = ({
    form,
    formState,
    setFormState,
    checkArrayIndependentWatcherFlag,
    isClearButtonDisabled,
    name,
}: ReportPropertiesSettingsButtonsProps) => {
    const {setSelectedTabKey, isCreatingNewTemplate} = useContext(ReportConfigurationContext);
    return (
        <div className={cn('report-properties-settings__buttons-bar')}>
            <Button
                disabled={!formState?.isEditingForm}
                type="primary"
                htmlType="submit"
                onClick={() => {
                    form.validateFields()
                        .then(() => {
                            form.submit();
                            setFormState(v => ({...v, isEditingForm: false}));
                        })
                        .catch(() => message.error('Пожалуйста, проверьте корректность ввода данных в таблице', 2));
                }}
                icon={<SaveOutlined />}
            >Сохранить
            </Button>
            {
                !formState.isEditingForm && (
                    <Button
                        type="primary"
                        icon={<EditWithLine />}
                        onClick={() => {
                            setFormState(v => ({...v, isEditingForm: !formState.isEditingForm}));
                        }}
                    >Редактировать
                    </Button>
                )
            }
            {
                formState.isEditingForm
                && (
                    <Button
                        icon={<UndoOutlined />}
                        type="default"
                        onClick={() => {
                            setFieldsValueUntouched(form, formState.initialValues);
                            setFormState(v => ({...v, isEditingForm: false}));
                        }}
                    >Отменить
                    </Button>
                )
            }
            {!isCreatingNewTemplate && (
                <ButtonChangeHistory />
            )}
            <ActionConfirmModal
                cancelText="Отменить"
                approveText="Очистить"
                modalTitle="Предупреждение"
                confirmModalContent={ReportConfigurationMessages.CLEAR_FORM_FIELDS_CONFIRM_TEXT}
                confirmAction={() => {
                    form.setFields([{name, value: []}]);
                    setFormState(v => ({...v, isDirty: true}));
                }}
                isNotInContainer
            >
                {
                    handleClick => (
                        <Button
                            type="default"
                            onClick={handleClick}
                            disabled={(checkArrayIndependentWatcherFlag && checkArrayIndependentWatcherFlag(
                                'buttonClearFormFields',
                                WatcherFieldFlag.isDisabled,
                            )) || isClearButtonDisabled || !formState.isEditingForm}
                            icon={<CloseXOutlined />}
                        >
                            Очистить форму
                        </Button>
                    )
                }
            </ActionConfirmModal>
            <ActionConfirmModal
                cancelText="Вернуться к редактированию"
                approveText="Закрыть форму"
                modalTitle="Предупреждение"
                confirmModalContent={ReportConfigurationMessages.CLOSE_FORM_CONFIRM_TEXT}
                confirmAction={() => {
                    setSelectedTabKey?.(ReportConfigurationTabKey.REPORT_CONFIGURATOR);
                }}
                skipToConfirmCallback={(() => !form.isFieldsTouched())}
                isNotInContainer
            >
                {
                    handleClick => (
                        <Button
                            type="default"
                            icon={<ArrowBackOutlined />}
                            onClick={handleClick}
                        >
                            Вернуться на главную вкладку
                        </Button>
                    )
                }
            </ActionConfirmModal>
        </div>
    );
};
