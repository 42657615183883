import {ValidationRules} from 'modules/metadata/metadata-types';

export enum FormFieldWatcherActions {
    clear = 'clear', // очистить поле
    disable = 'disable', // сделать поле disabled
    hide = 'hide', // скрыть поле
    require = 'require', // сделать поле обязательным
    setOwnValue = 'setOwnValue', // установить значение поля, на котором стоит watcher (собственное значение)
    validate = 'validate', // провалидировать поле
    allowEmpty = 'allowEmpty', // сделать необязательным
    addValidationRules = 'addValidationRules', // добавить правило валидации
    addFieldError = 'addFieldError', // добавить кастомную ошибку на срабатываемое событие
    enableField = 'enableField', // убрать флажок отключен

    fetchDataToCustomSelect = 'fetchDataToCustomSelect'
}

export enum FormFieldWatcherConnective {
    OR = 'OR',
    AND = 'AND',
    NOT = 'NOT',
    AND_NOT = 'AND_NOT',
    OR_NOT = 'OR_NOT'
}

export enum FormFieldWatcherConditions {
    formEditing = 'formEditing', // если поле с watcher'ом в модальном окне с редактированием записи
    formCreating = 'formCreating', // если поле с watcher'ом в модальном окне с созданием записи
    unset = 'unset', // если target-поле имеет falsy-значение (false, undefined, null, "", 0 и т.д.)
    set = 'set', // если target-поле не falsy
    equals = 'equals', // если собственное значение поля равно значению target-поля
    fieldEqualsToValue = 'fieldEqualsToValue',
    compareFieldValues = 'compareFieldValues',
    /*
      fieldEqualsToValue = если значение по ключу в target-поле равно "withValue"
    */
    equalsToValue = 'equalsToValue', // если значение в target-поле равно "withValue"
    notEqualsToValue = 'notEqualsToValue', // если значение в target-поле НЕ равно "withValue"
    equalsByKeyOrToValue = 'equalsByKeyOrToValue', /*
        попытаться сравнить по "fieldEqualsToValue", при неудаче сравнить по "equalsToValue"
    */

    refererenceValueHasBeenChanged = 'refererenceValueHasBeenChanged',
    isFieldSet = 'isFieldSet',
}

export interface FormFieldWatcherCondition {
    target?: string; // ключ поля, которое отслеживается watcher'ом
    when: FormFieldWatcherConditions; // событие для срабатывания условия
    withValue?: string; // значение target поля, чтобы watcher сработал
    withKey?: string; // используется, если when === ("equalsToValue" или "fieldEqualsToValue")
    withConnective?: FormFieldWatcherConnective; // принцип, по которому условие добавляется к предыдущему
    withValueAsNumber?: boolean;
}

export interface FormFieldWatcher {
    conditions: FormFieldWatcherCondition[]; // настройка условий, при которых срабатывает watcher
    perform: FormFieldWatcherActions[]; // действия при срабатывании

    withOwnValueToSet?: string; // используется, если perform === "setOwnValue"
    withOwnValueAsNumber?: boolean; // устанавливать значение числом
    withErrorToSet?: string; // текст для ошибки поля для addFieldError
    withValidationRules?: ValidationRules[]; // добавление валидации при срабатывании события

    withTargetToPickAttributeFrom?: string;
    withTargetAttributeKey?: string;
    withMapping?: { [index: string]: string };
}
