import {FORM_OPTIONS} from '../form/form.options';

export const FILTER_OPTIONS = {
    FIELD_TYPES: FORM_OPTIONS.FIELD_TYPES,
    ACTION_TYPES: [
        {
            label: 'BUTTON_SEND_FILTER',
            value: 'BUTTON_SEND_FILTER',
        },
    ],
};
