import get from 'lodash/get';
import React from 'react';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';

import {FieldMeta} from 'modules/metadata';
import {openModal} from 'store/slices/modals-slice';

interface OpenModalActionProps {
    url: string;
    entityName: string;
    record: any;
    field: FieldMeta;
}

export const OpenModalAction: React.FunctionComponent<OpenModalActionProps> = ({
    url,
    entityName,
    record,
    field,
}: OpenModalActionProps) => {
    const dispatch = useDispatch();
    const dependentsFieldKey = record?.[field?.dependentsFieldKey || ''];
    const handlerClick = () => {
        if (field.modalName) {
            dispatch(openModal({
                modalName: field.modalName,
                entityName,
                modalParameters: {
                    filterSystemComponent: record[field.key],
                    key: field.key,
                    referenceUrl: field.referenceUrl,
                },
            }));
        }
    };

    return (
        <>
            {
                dependentsFieldKey
                    ? (
                        <Link
                            to={`${url}`}
                            onClick={handlerClick}
                        >
                            {get(record, field.key)}
                        </Link>
                    )
                    : (
                        <>
                            {get(record, field.key)}
                        </>
                    )
            }
        </>
    );
};
