import {Card, Form as AntForm, Modal} from 'antd';
import cn from 'classnames';
import React, {useEffect, useState} from 'react';

import {FormMode} from 'components/form';
import {AlertErrorMessage} from 'components/form/alert-error-message/alert-error-message';
import {Buttons} from 'components/form/buttons';
import {Fields} from 'components/form/fields/fields';
import {useFormElementsAvailability} from 'components/form/hooks/check-form-elements-availability.hook';
import {dispatchFormWatcherEvent} from 'components/form/hooks/use-form-watcher';
import {Spinner} from 'components/spinner';
import {resetLoadedData} from 'modules/data';
import {fetchPermissionDisplayFileComponent} from 'modules/data/data-api';
import {normalizeFormDataForRequest} from 'modules/data/utils/data.utils';
import {loadMetadata as loadMetadataAction, MetaActionType} from 'modules/metadata';
import {setFormDraftMetadata} from 'modules/metadata/metadata-actions';
import {selectFormDraftMetadata, selectFormEntityMetadata} from 'modules/metadata/metadata-selectors';
import {getActionByType} from 'modules/metadata/metadata-utils';
import {EntityType} from 'shared/constants/entities';
import {DEFAULT_FORM_MODAL_WIDTH} from 'shared/constants/layout';
import {URL_REQUESTS} from 'shared/constants/urls';
import {showMessageFromResponse} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {ModalComponentInterface, selectModalData} from 'store/slices/modals-slice';
import {selectRequestData} from 'store/slices/request-slice/request-selectors';
import {requestSliceActions} from 'store/slices/request-slice/request-slice';
import {createRequestStatus} from 'store/slices/request-slice/request-slice-api';
import {
    loadRequestData, loadRequestStatusesCommentData,
    loadRequestStatusesData,
} from 'store/slices/request-slice/request-slice-thunks';
import {RequestStatus} from 'store/slices/request-slice/request-slice-type';

import {REQUESTS_DISPLAY_FILES_URL} from '../constants/requests.constants';
import {REQUEST_FIELD_FILES, RequestFormFormKey} from '../request-form/request-form.constants';
import {convertRequestFormData} from '../utils/request-api-utils';

import './request-form-modal.less';

export const RequestFormModal: React.FunctionComponent<ModalComponentInterface> = ({
    onClose,
}: ModalComponentInterface) => {
    const dispatch = useAppDispatch();
    const mode = FormMode.CREATE;
    const [form] = AntForm.useForm();
    const [errorFormMessage, setErrorFormMessage] = React.useState<string | null>(null);
    const [isSubmitDisabled, setSubmitDisabled] = React.useState(false);
    const [hasPermissionDisplayFileComponent, setPermissionDisplayFileComponent] = useState(false);
    const entityName = useAppSelector(state => selectModalData(state)?.entityName) || '';
    const metadata = useAppSelector(selectFormEntityMetadata(entityName));
    const modalParameters = useAppSelector(state => selectModalData(state))?.modalParameters;
    const isDraft = !!(useAppSelector(selectFormDraftMetadata(entityName)));
    const requestData = useAppSelector(selectRequestData);
    const {resetRequestStatusesData} = requestSliceActions;
    const entityClassName = entityName?.split('.').join('-');
    const {currentActionsVisibleState} = useFormElementsAvailability(mode, entityName);
    const newStatus = modalParameters?.status;
    const isFileComponent = modalParameters?.isFileComponent;
    const fieldList = isFileComponent && hasPermissionDisplayFileComponent
        ? [...metadata?.fields, REQUEST_FIELD_FILES]
        : metadata?.fields;
    const requestCommentId: string = modalParameters?.requestCommentId;
    const requestFormKey: string = modalParameters?.requestFormKey;
    const requestTypeId = requestData?.requests?.topicRequestType?.id;
    const requestType = requestData?.requests?.requestType || requestData?.requests?.topicRequestType?.typeCode;
    const isRequestAdditionalPlacementType = requestType === RequestFormFormKey.REQUEST_ADDITIONAL_PLACEMENT;
    const prolongationDate: Date = form?.getFieldValue('prolongationDate');
    const numberRequest: string = form?.getFieldValue('numberRequest');
    const convertedRequestFormData = convertRequestFormData(
        requestData,
        requestCommentId,
        prolongationDate,
        isRequestAdditionalPlacementType,
        numberRequest,
    );
    const formClassNames = cn(
        'form-body',
        {
            [String(entityClassName)]: entityName,
        },
    );

    useEffect(() => {
        const checkPermissionDisplayFileComponent = async () => {
            const hasPermission = await fetchPermissionDisplayFileComponent(
                REQUESTS_DISPLAY_FILES_URL,
                requestTypeId,
            );
            setPermissionDisplayFileComponent(hasPermission?.data);
        };
        checkPermissionDisplayFileComponent().catch(console.error);
    }, []);

    const buttons = (
        <Buttons
            onClose={onClose}
            actions={metadata?.actions}
            form={form}
            setFormErrorMessage={setErrorFormMessage}
            currentVisibleState={currentActionsVisibleState}
            formMode={mode}
        />
    );
    const handleFinish = async () => {
        try {
            const requestId = requestData?.requests?.id;
            if (requestCommentId && requestId) {
                const saveAction = isDraft ? getActionByType(MetaActionType.BUTTON_SAVE_FORM_DRAFT)(metadata.actions)
                    : getActionByType(MetaActionType.BUTTON_SAVE_FORM)(metadata.actions);
                const newData = normalizeFormDataForRequest(form.getFieldsValue(), fieldList);
                setSubmitDisabled(true);
                if (saveAction) {
                    await createRequestStatus()?.changeStatus(
                        newStatus,
                        requestCommentId,
                        saveAction?.referenceUrl || URL_REQUESTS,
                        newData,
                    )?.then(res => {
                        if (res.status === 200) {
                            if (newStatus === RequestStatus.COMMENT_REQUEST
                                || newStatus === RequestStatus.REQUEST_RESPONSE) {
                                dispatch(loadRequestStatusesCommentData({commentId: requestCommentId, requestFormKey}));
                                dispatch(loadRequestData({id: String(requestId), url: URL_REQUESTS}));
                                setSubmitDisabled(false);
                                onClose();
                                return;
                            }
                            if (entityName) dispatch(resetLoadedData(entityName, EntityType.TABLE));
                            dispatch(loadRequestData({id: String(requestId), url: URL_REQUESTS}));
                            dispatch(resetRequestStatusesData);
                            dispatch(loadRequestStatusesData({commentId: requestCommentId, requestFormKey}));
                            setSubmitDisabled(false);
                            onClose();
                        }
                    });
                }
                dispatch(setFormDraftMetadata({entityName, isDraft: false}));
                onClose();
            }
        } catch (e) {
            console.error(e.error);
            setErrorFormMessage(e.response?.data);
            showMessageFromResponse({response: e.response, isError: true});
        }
    };

    const handleFinishFailed = () => {
        setErrorFormMessage('Форма не прошла проверку');
    };

    const fields = (
        <Fields
            formData={convertedRequestFormData}
            parentEntityName={entityName}
            entityName={entityName}
            list={fieldList}
            formMode={mode}
            form={form}
        />
    );
    React.useEffect(() => {
        if (!metadata) {
            dispatch(loadMetadataAction(entityName, EntityType.FORM));
        }
    }, []);

    React.useEffect(() => {
        form.setFieldsValue(
            convertedRequestFormData?.data,
        );
    }, [convertedRequestFormData]);
    return (
        <Modal
            title={metadata?.title}
            className="request-form-modal"
            width={DEFAULT_FORM_MODAL_WIDTH}
            onCancel={onClose}
            maskClosable={false}
            footer={null}
            visible
        >
            <Card
                className={formClassNames}
            >
                <AntForm
                    layout="vertical"
                    className="form"
                    form={form}
                    onFinish={handleFinish}
                    onFinishFailed={handleFinishFailed}
                    onValuesChange={(changed: any, values: any) => {
                        dispatchFormWatcherEvent({values, targetForm: form});
                    }}
                >
                    {isSubmitDisabled
                        ? <Spinner />
                        : (
                            <>
                                <AlertErrorMessage
                                    message={errorFormMessage}
                                    closeErrorMessage={() => setErrorFormMessage(null)}
                                />
                                <div className={formClassNames}>
                                    <div className="form-fields">
                                        {fields}
                                    </div>
                                    {buttons}
                                </div>
                            </>
                        )}
                </AntForm>
            </Card>
        </Modal>
    );
};
