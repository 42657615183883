import {Button, Dropdown} from 'antd';
import {ButtonProps} from 'antd/es/button';
import cn from 'classnames';
import React, {ReactElement} from 'react';

import {IconsMap} from 'components/dynamic-icon';

import './kebab-menu.less';

interface KebabMenuProps {
    children: ReactElement;
    button?: ButtonProps;
    placeholder?: ReactElement;
}

export const KebabMenu: React.FC<KebabMenuProps> = (
    {
        children,
        button,
        placeholder,
    }: KebabMenuProps,
) => {
    const {className, ...restButtonProps} = button ?? {};

    return (
        <Dropdown
            overlay={() => {
                if (placeholder) {
                    return (
                        <div className="kebab-menu__placeholder">
                            {placeholder}
                        </div>
                    );
                }

                return children;
            }}
            className={cn('kebab-menu')}
            forceRender={false}
        >
            <Button
                type="default"
                className={cn('kebab-menu__button', 'btn-only-icon', className)}
                {...restButtonProps}
            >
                <IconsMap.MoreIcon />
            </Button>
        </Dropdown>
    );
};
