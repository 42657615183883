import {RightOutlined} from '@ant-design/icons/lib';
import {Button, Input, message} from 'antd';
import React, {ChangeEvent, KeyboardEvent, useState} from 'react';

import {validatePageValue} from 'components/@common/widgets/page-jumper/page-jumper.utils';
import {ReportPagination} from 'store/slices/table-report-slice/table-report-slice-types';

import './page-jumper.less';

interface PageJumperProps {
    inputWidth?: string;
    size?: string;
    placeholder?: string;
    pagination: ReportPagination;
    startsFromZero?: boolean;
}

export const PageJumper = ({
    inputWidth = '40px',
    size = '32px',
    placeholder = '№',
    pagination,
    startsFromZero,
}: PageJumperProps) => {
    const {
        onChange: onPageJump,
        total: totalPages,
        current,
    } = pagination;
    const [page, setPage] = useState<string | undefined>();
    const startPage = startsFromZero ? 0 : 1;

    const handlePageChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPage(event.target.value);
    };

    const handlePageJump = () => {
        // сброс
        if (!page && current && current > startPage) {
            onPageJump(startPage);
            return;
        }

        if (!totalPages || !page) {
            return;
        }

        const error = validatePageValue(page, totalPages);

        if (error) {
            message.error(error);
            setPage(undefined);
            return;
        }

        onPageJump(startsFromZero ? +page - 1 : +page);
    };

    const handleEnterKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handlePageJump();
        }
    };

    return (
        <div className="page-jumper">
            <Input
                className="page-jumper__input"
                value={page}
                onChange={handlePageChange}
                onKeyDown={handleEnterKeyDown}
                placeholder={placeholder}
                style={{
                    width: inputWidth,
                    height: size,
                }}
            />
            <Button
                className="page-jumper__jump-button"
                type="primary"
                icon={<RightOutlined className="page-jumper__jump-button-icon" />}
                onClick={handlePageJump}
                style={{
                    width: size,
                    height: size,
                }}
            />
        </div>
    );
};
