import cn from 'classnames';
import React from 'react';

interface CustomSelectEntryHighlighterProps {
    text: string;
    search: string;
}

export const CustomSelectEntryHighlighter = ({text, search}: CustomSelectEntryHighlighterProps) => {
    if (!search) {
        return <>{text}</>;
    }
    const parts = text.split(new RegExp(`(${search})`, 'gi'));
    return (
        <>
            {parts.map(part => (
                <span className={cn(part.toLowerCase().includes(search.toLowerCase()) && 'highlighted')}>
                    {part}
                </span>
            ))}
        </>
    );
};
