import Form, {FormInstance} from 'antd/es/form';
import {ColProps} from 'antd/es/grid';
import React, {FunctionComponent} from 'react';

import {IGroupFieldMeta} from 'modules/metadata/metadata-types';

import {Field} from '../../field/field';

interface IOwnProps {
    fieldMeta?: IGroupFieldMeta;
    form?: FormInstance;
    wrapperCol?: ColProps;
    labelCol?: ColProps;
    name?: React.Key | React.Key[];
}

type TProps = IOwnProps;

export const GroupFields: FunctionComponent<TProps> = ({
    form, fieldMeta, wrapperCol, labelCol, name,
}: TProps) => {
    const {items, label = '', key = ''} = fieldMeta || {};
    const fieldsLayout = {
        wrapperCol: {
            offset: 2,
        },
        labelCol: {
            offset: 2,
        },
    };
    return (
        <Form.Item
            label={label}
            wrapperCol={wrapperCol}
            labelCol={labelCol}
            style={{marginBottom: 0}}
        >
            {items
                ?.map(formItem => {
                    let fieldName = [key, formItem.key];

                    if (Array.isArray(name)) {
                        fieldName = [...name, formItem.key] as string[];
                    }

                    if (typeof name === 'string') {
                        fieldName = [name, formItem.key];
                    }
                    return (
                        <Field
                            {...fieldsLayout}
                            fieldMeta={formItem}
                            name={fieldName}
                            key={formItem.key}
                            form={form}
                        />
                    );
                })}
        </Form.Item>
    );
};
