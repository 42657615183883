import axios, {AxiosPromise, AxiosRequestConfig} from 'axios';
import FileDownload from 'js-file-download';

import {RequestType} from 'modules/metadata';

import {isProduction} from './is-production';

export type RequestPromise<T = any> = AxiosPromise<T>;

export const DEFAULT_REQUEST_CONFIG: AxiosRequestConfig = {
    baseURL: isProduction() ? `${window.location.origin}/nalmon/api`
        : `${process.env.REACT_APP_BASE_URL}/nalmon/api`,
};

export const performRequest = <T = any>(config: AxiosRequestConfig, ignoreMethodCheck = false): RequestPromise<T> => {
    const requestConfig: AxiosRequestConfig = {
        ...DEFAULT_REQUEST_CONFIG,
        ...config,
    };

    if (config.data && config.method === 'GET' && !ignoreMethodCheck) {
        // TODO: Tell backend to make this request 'POST'
        const overrideConfig: AxiosRequestConfig = {
            ...requestConfig,
            method: 'POST',
        };
        return axios(overrideConfig);
    }

    return (
        axios(requestConfig)
    );
};

export const downloadFile = async (url: string, filename: string) => {
    const fileResponse = await performRequest({
        method: 'GET',
        url,
        responseType: 'blob',
    });

    FileDownload(fileResponse.data, filename);
    return fileResponse;
};

export const fetchDocumentStatus = (referenceUrl: string, funcName: string, docId: string) => (
    performRequest({
        url: 'documents/check-doc-status',
        method: 'GET',
        params: {
            docId,
            funcName,
        },
    })
);
export const editTableRows = (
    requestType: RequestType,
    referenceUrl: string,
    itemList: any,
) => (
    performRequest({
        url: referenceUrl,
        method: requestType,
        data: itemList,
    })
);
export const fetchProperty = (propertyCode: string) => (
    performRequest({
        url: `/property/${propertyCode}`,
        method: 'GET',
        params: {
            propertyCode,
        },
    })
);

export const checkDisabledActions = (referenceUrl: string, functionName: string, params?: any) => (
    performRequest({
        url: referenceUrl,
        method: 'GET',
        params: {
            functionName,
            ...params,
        },
    })
);
