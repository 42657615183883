import React from 'react';

import {Spinner} from 'components/spinner';
import {TableReport} from 'components/table-report/table-report';
import {TableReportProps} from 'components/table-report/table-report-types';

export const TableReportPage = ({entityName, docId, templateCode}: TableReportProps) => (
    <>
        {templateCode ? (
            <TableReport
                entityName={entityName}
                docId={docId}
                templateCode={templateCode}
            />
        ) : <Spinner />}
    </>
);
