import {performRequest} from 'shared/utils';

import {ReportConfigurationAttribute} from '../report-configuration-types';

export interface GetTableAttributesArgs {
    templateCode: string;
}
export const getTableAttributes = async (args: GetTableAttributesArgs) => {
    const {templateCode} = args;
    const response = await performRequest<ReportConfigurationAttribute[]>({
        url: `/table-reports/lookup/columns?templateCode=${templateCode}&sourceForBlock=TABLE`,
        method: 'GET',
    });
    return response;
};

export interface GetHeaderAttributesArgs {
    templateCode: string;
}
export const getHeaderAttributes = async (args: GetHeaderAttributesArgs) => {
    const {templateCode} = args;
    const response = await performRequest<ReportConfigurationAttribute[]>({
        url: `/table-reports/lookup/columns?templateCode=${templateCode}&sourceForBlock=HEADER`,
        method: 'GET',
    });
    return response.data;
};
