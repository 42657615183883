// TODO author раскомментировать, когда будет приходить примитив
export const DEFAULT_FIELDS_RESPONSES = [
    // 'author',
    'createDate',
    'content',
    'files',
];

// Поле влияющее на вариант отображения данных
export const DATA_KEY_FOR_FILTER_FIELDS = 'topicRequestType';
// Списки доступных действий
export const ACTIONS_NAMES = {
    /**
     * Сохранить
     */
    BUTTON_SAVE: 'BUTTON_SAVE',
    /**
     * Назначить ответственного
     */
    APPOINTMENT: 'SELECTED_APPOINT_PERSON',
    /**
     * Исправление даты срока ответа
     */
    UPDATE_RESPONSE_DATE: 'UPDATE_RESPONSE_DATE',
    /**
     * Запрос на продление срока
     */
    EXTENSION_REQUEST: 'PROLONGATION_REQUEST',
    /**
     * Сформировать ответ
     */
    ANSWER: 'REQUEST_RESPONSE',
    /**
     * Редактировать ответ
     */
    EDIT_ANSWER: 'EDIT_ANSWER',
    /**
     * Редактировать ответ ответе
     */
    EDIT_ANSWER_RESPONSE: 'EDIT_ANSWER_RESPONSE',
    /**
     * Оставить комментарий
     */
    LEAVE_COMMENT: 'COMMENT_REQUEST',
    /**
     * Оставить комментарий для ответа
     */
    LEAVE_COMMENT_RESPONSE: 'COMMENT_RESPONSE',
    /**
     * Перейти к запросу
     */
    INIT_REQUEST: 'INIT_REQUEST',
};

const DEFAULT_ACTIONS = [
    ACTIONS_NAMES.BUTTON_SAVE,
];

const DEFAULT_ACTIONS_REQUESTS = [...DEFAULT_ACTIONS];
const DEFAULT_ACTIONS_RESPONSES = [...DEFAULT_ACTIONS];

// Тип запроса и соответствующий id
export const requestType = {
    default: 'default',
    notification: 'notification',
    prolongation: 'prolongation',
    requestDit: 'requestDit',
    request: 'request',
    message: 'message',
    requestPUD: 'requestPUD',
};

// Информация о разрешенных полях и действиях
export const REQUESTS_RESOLVED_META = {
    [requestType.default]: {
        fields: [],
        actions: [...DEFAULT_ACTIONS_REQUESTS],
    },
    /**
     * Оповещение
     */
    [requestType.notification]: {
        fields: [],
        actions: [...DEFAULT_ACTIONS_REQUESTS],
    },
    /**
     * Запрос на продление срока ответа (требование)
     */
    [requestType.prolongation]: {
        fields: [],
        actions: [...DEFAULT_ACTIONS_REQUESTS],
    },
    /**
     * Запрос в тех.поддержку
     */
    [requestType.requestDit]: {
        fields: [],
        actions: [...DEFAULT_ACTIONS_REQUESTS],
    },
    /**
     * Запрос (требование)
     */
    [requestType.request]: {
        fields: [],
        actions: [...DEFAULT_ACTIONS_REQUESTS],
    },
    /**
     * Сообщение (чат)
     */
    [requestType.message]: {
        fields: [],
        actions: [...DEFAULT_ACTIONS_REQUESTS],
    },
    /**
     * Запрос на предоставление ПУД/Пояснений
     */
    [requestType.requestPUD]: {
        fields: [],
        actions: [...DEFAULT_ACTIONS_REQUESTS],
    },
};

export const RESPONSES_RESOLVED_META = {
    [requestType.default]: {
        fields: [],
        actions: [...DEFAULT_ACTIONS_RESPONSES, ACTIONS_NAMES.EDIT_ANSWER_RESPONSE],
    },
};
