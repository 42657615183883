import {Spin} from 'antd';
import cn from 'classnames';
import React, {useEffect} from 'react';

import {DRILLDOWN_ID_PARAM_KEY} from 'components/report-configuration/report-configuration.constants';
import {useQueryParams} from 'shared/utils/query-params';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';
import {loadDDChildReportQueryParameters, loadDrilldownParameters} from 'store/slices/report-configuration-slice/dd-parameters-configuration/dd-parameters-configuration-thunks';
import {ddParametersSelectors, ddReferenceRulesSelectors} from 'store/slices/report-configuration-slice/report-configuration-slice';

import {DdParametersListItem} from './dd-parameters-list-item';

import './dd-parameters-list.less';

export const DdParametersList = () => {
    const dispatch = useAppDispatch();

    const {query} = useQueryParams();

    const isLoadingParameters = useAppSelector(s => selectIsThunkPending(s, loadDrilldownParameters.typePrefix));

    const drillDownId = Number(query.get(DRILLDOWN_ID_PARAM_KEY));
    const drillDown = useAppSelector(state => ddReferenceRulesSelectors.selectById(state, drillDownId));

    const ddParameters = useAppSelector(ddParametersSelectors.selectAll);

    useEffect(() => {
        if (drillDown?.childReportTemplateCode) {
            dispatch(loadDDChildReportQueryParameters({
                templateCode: drillDown.childReportTemplateCode,
            }));
        }
    }, []);

    return (
        <div className={cn('dd-parameters-list')}>
            <Spin
                tip="Загрузка правил..."
                spinning={isLoadingParameters}
            >
                {!ddParameters.length && (
                    <div className={cn('dd-parameters-list__empty')}>
                        Необходимо добавить правило
                    </div>
                )}
                <div className={cn('dd-parameters-list__list')}>
                    {ddParameters?.map((ddParameter, index) => (
                        <DdParametersListItem
                            key={ddParameter.id}
                            ruleIndex={index}
                            ddParameter={ddParameter}
                        />
                    ))}
                </div>
            </Spin>
        </div>
    );
};
