import {Input} from 'antd';
import {FormInstance} from 'antd/es/form';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {DATA_MODULE} from 'modules/data';
import {EntityType} from 'shared/constants/entities';
import {AppState} from 'store/config/types';

export interface DependentsFieldProps {
    onChange?: (value: any) => void;
    form?: FormInstance;
    value: string;
    dependFieldKey: string;
    maskField?: string;
    fieldKey: string;
    parentEntityName: string;
    isDependsOnMainFilter?: string;
    isDisplay?: boolean;

}

export const DependentsField: React.FunctionComponent<DependentsFieldProps> = (
    {
        form,
        value,
        onChange,
        dependFieldKey,
        maskField,
        parentEntityName,
        isDependsOnMainFilter,
        ...props
    }: DependentsFieldProps,
) => {
    const [mainFieldValue, setMainFieldValue] = useState<string | number | undefined>(value);
    const data: any = useSelector(
        (state: AppState) => state[DATA_MODULE][parentEntityName]?.[EntityType.FILTER]?.data[dependFieldKey],
    );

    const setFilePathValue = (mainValue: string | null = null) => {
        form?.setFieldsValue({
            [dependFieldKey]: [mainValue, maskField].join(''),
        });
    };
    useEffect(() => {
        if (data && isDependsOnMainFilter === 'Y') {
            setMainFieldValue(data.value);
            if (onChange) onChange(data.value);
            return;
        }
        setMainFieldValue(value);
    }, [value, data]);

    const handleBlur = (e: React.FormEvent<HTMLInputElement>) => {
        const newValue = e.currentTarget.value;
        if (newValue) setFilePathValue(newValue);
        if (onChange) onChange(newValue);
    };

    return (
        <Input
            onChange={(e: React.FormEvent<HTMLInputElement>) => setMainFieldValue(e.currentTarget.value)}
            value={mainFieldValue}
            onBlur={handleBlur}
            {...props}
        />
    );
};
