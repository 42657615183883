export const undefinedDelete = (array: Array<Record<string, any>>) => array?.map((element: Record<string, any>) => {
    const result: Record<string, any> = {};
    if (element !== undefined) {
        Object.keys(element).forEach((key: string) => {
            if (Array.isArray(element[key])) {
                result[key] = undefinedDelete(element[key]);
            } else if (element[key] !== undefined) {
                result[key] = element[key];
            }
        });
    }
    return result;
});

export const getChildrenLength = (array: Array<Record<string, any>>) => {
    const result: Array<Record<string, any>> = [];
    let isChildNested: boolean = false;
    array.forEach(element => {
        const obj: Record<string, any> = {};
        if (Array.isArray(element?.children) && (element?.children.length)) {
            const childResult = getChildrenLength(element?.children);
            obj.childrenLength = childResult.childrenLength;
            obj.children = childResult.children;
            obj.isChildNested = childResult.isChildNested;
            isChildNested = true;
        } else {
            obj.childrenLength = 0;
        }
        result.push(obj);
    });
    return {children: result, isChildNested, childrenLength: array.length};
};
