import {Spin} from 'antd';
import React, {CSSProperties} from 'react';
import './spinner.less';

interface SpinnerProps {
    tip?: string;
    style?: CSSProperties;
}

const DEFAULT_TIP = 'Загрузка...';

export const Spinner: React.FunctionComponent<SpinnerProps> = ({
    tip = DEFAULT_TIP,
    style,
}: SpinnerProps) => (
    <div className="spinner-container">
        <Spin
            style={style}
            tip={tip}
        />
    </div>
);
