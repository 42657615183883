import {
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';

import {AppState} from 'store/config/types';

import {draggableFramesSliceName} from './draggable-frames-slice-constants';
import {DraggableFrameEntity, DraggableFramesSliceState} from './draggable-frames-slice-types';

const getId = (type: DraggableFrameEntity['type'], id?: DraggableFrameEntity['id']) => `${type}_${id ?? 'no-id'}`;

const draggableFramesEntityAdapter = createEntityAdapter<DraggableFrameEntity>({
    selectId: model => getId(model.type, model.id),
});

export const draggableFramesSlice = createSlice({
    initialState: {
        draggableFrames: draggableFramesEntityAdapter.getInitialState(),
        lastFrameId: 0,
    } as DraggableFramesSliceState,
    name: draggableFramesSliceName,
    reducers: {
        openDraggableFrame(state, {
            payload,
        }: PayloadAction<Omit<DraggableFrameEntity, 'id'> & {id?: number}>) {
            const nextFrameId = state.lastFrameId + 1;
            draggableFramesEntityAdapter.upsertOne(state.draggableFrames, {
                ...payload,
                id: payload.id ?? nextFrameId,
                isOpen: payload.isOpen ?? true,
            });
            if (!payload.id) {
                state.lastFrameId = nextFrameId;
            }
        },
        destroyDraggableFrame(state, {payload}: PayloadAction<{
            type: DraggableFrameEntity['type'];
            id?: number;
        }>) {
            const {type, id} = payload;
            draggableFramesEntityAdapter.removeOne(state.draggableFrames, getId(type, id));
        },
        closeDraggableFrame(state, {payload}: PayloadAction<{
            type: DraggableFrameEntity['type'];
            id?: number;
        }>) {
            const {type, id} = payload;

            draggableFramesEntityAdapter.updateOne(state.draggableFrames, {
                changes: {isOpen: false},
                id: getId(type, id),
            });
        },
    },
});

const selectDraggableFramesSliceState = (state: AppState) => state[draggableFramesSliceName];

const draggableFramesAdapterSelectors = draggableFramesEntityAdapter.getSelectors(
    (state: AppState) => selectDraggableFramesSliceState(state).draggableFrames,
);

export const draggableFramesSelectors = {
    ...draggableFramesAdapterSelectors,
    selectDraggableFrameEntityData: <T=any>(state: AppState,
        type: DraggableFrameEntity['type']) => (draggableFramesAdapterSelectors
        .selectById(state, type) as DraggableFrameEntity<T>)?.data,
};

export const {
    reducer: draggableFramesReducer,
    actions: draggableFramesActions,
} = draggableFramesSlice;
