import {Badge, Table} from 'antd';
import {ColumnType, TableProps} from 'antd/es/table';
import {SortOrder} from 'antd/es/table/interface';
import {ColumnGroupType} from 'antd/lib/table';
import cn from 'classnames';
import React, {
    ReactElement, useMemo,
} from 'react';

import {IconsMap} from 'components/dynamic-icon';
import {StateSetter} from 'shared/types/generics';

import {TableColumnFilter} from './table-column-filter';
import {useTableColumnFilters, TableColumnFilters} from './table-column-filter/use-table-column-filter';
import {ColumnSortData, TableColumnMenu, useTableColumnSort} from './table-column-menu';

import './custom-table.less';

type CustomColumn<RecordType extends object = any> = (ColumnGroupType<RecordType> | ColumnType<RecordType>) & {
    withCustomFilters?: boolean;
    dataIndex?: string;
}

export interface UseCustomTableArgs<RecordType extends object = any> extends Omit<TableProps<RecordType>, 'columns'> {
    onColumn?: <T extends CustomColumn<RecordType>>(column: T, index: number) => T;
    columns?: CustomColumn<RecordType>[];

    tableFiltersData?: TableColumnFilters;
    setTableFiltersData?: (data: TableColumnFilters | undefined) => void;

    tableColumnSortData?: ColumnSortData;
    setTableColumnSortData?: StateSetter<ColumnSortData>;
}

interface CustomTableProps<RecordType extends object = any> extends Omit<TableProps<RecordType>, 'columns'> {
    onColumn?: <T extends ColumnType<RecordType>>(column: T, index: number) => T;
    columns?: ColumnType<RecordType>[];
}

export const CustomTable = <RecordType extends object = any, >(
    props: CustomTableProps<RecordType>,
): ReactElement => {
    const {
        className, children, onColumn, columns, ...restProps
    } = props;

    const modifiedColumns = useMemo(() => columns?.map((col, index) => {
        if (!onColumn) return col;
        return onColumn(col, index);
    }), [columns]);

    return (
        <Table
            className={cn('custom-table', className)}
            columns={modifiedColumns}
            {...restProps}
        >
            {children}
        </Table>
    );
};

export const useCustomTable = <RecordType extends object = any, >(args: UseCustomTableArgs<RecordType>) => {
    const {
        columns,
        onColumn,
        setTableColumnSortData = () => {},
        tableColumnSortData = {filters: {}, sort: {}, noFetch: false},
        tableFiltersData,
        setTableFiltersData,
        ...restProps
    } = args;

    const {
        createTableColumnFilterDataGetter,
        createTableColumnFilterDataSetter,
        getTableColumnFilterInfo,
    } = useTableColumnFilters({
        tableFiltersData,
        setTableFiltersData,
    });

    const {
        tableColumnSortDataAdaptedForRequest,
        createSortDataGetter,
        createSortDataSetter,
    } = useTableColumnSort({
        tableColumnSortData,
        setTableColumnSortData({sort}) {
            setTableColumnSortData({
                sort,
                noFetch: false,
            });
        },
    });

    const modifiedColumns = useMemo(() => columns?.map((col, index) => {
        if (!onColumn) return col;
        return onColumn(col, index);
    }), [columns]);

    const columnsWithFilters = modifiedColumns?.map(col => {
        if (col.withCustomFilters) {
            return {
                ...col,
                title: (
                    <div className="d-flex align-items-center">
                        {col.title}
                        {(() => {
                            const {dataIndex} = col;

                            if (!dataIndex) return undefined;

                            const filterInfo = getTableColumnFilterInfo(dataIndex);

                            return (
                                <Badge
                                    count={filterInfo.rulesCount}
                                    size="small"
                                >
                                    <TableColumnFilter
                                        columnName={dataIndex}
                                        getTableColumnFilterData={createTableColumnFilterDataGetter(dataIndex)}
                                        setTableColumnFilterData={createTableColumnFilterDataSetter(dataIndex)}
                                        isApplied={filterInfo.isApplied}
                                    />
                                </Badge>
                            );
                        })()}
                    </div>
                ),
                sortDirections: ['ascend', 'descend', 'ascend'] as SortOrder[],
                sorter: (() => {
                    if (!col.dataIndex) return undefined;
                    const columnSortInfo = createSortDataGetter(col.dataIndex)();
                    if (columnSortInfo.order && columnSortInfo.multiple) {
                        return {
                            compare: () => 0,
                            multiple: columnSortInfo.multiple,
                        };
                    }
                    return undefined;
                })(),
                sortOrder: (() => {
                    if (!col.dataIndex) return null;
                    const columnSortInfo = createSortDataGetter(col.dataIndex)();
                    return columnSortInfo.order ? columnSortInfo.order : null;
                })(),
                filterIcon: col.dataIndex && (
                    <span className="anticon anticon-filter">
                        <IconsMap.MoreIcon
                            height={14}
                            width={14}
                            fill="currentColor"
                        />
                    </span>
                ),
                filterDropdown: col.dataIndex ? () => (
                    col.dataIndex && (
                        <TableColumnMenu
                            setSortData={createSortDataSetter(col.dataIndex)}
                            getSortData={createSortDataGetter(col.dataIndex)}
                            handleHideColumnClick={() => {}}
                        />
                    )
                ) : undefined,
            };
        }

        return col;
    });

    return {
        customTableJSX: <CustomTable
            columns={columnsWithFilters}
            {...restProps}
        />,
        tableColumnSortDataAdaptedForRequest,
        tableColumnSortData,
    };
};
