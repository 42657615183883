import {Button} from 'antd';
import React from 'react';
import {Link} from 'react-router-dom';

import {DynamicIcon} from 'components/dynamic-icon';
import {TableActionProps} from 'components/table/action';

interface IOwnProps extends TableActionProps {
}

export const ButtonOpenLink = ({meta, link}: IOwnProps) => {
    const {actionIcon, actionTitle} = meta;
    return (
        <Link
            style={{marginLeft: 12}}
            to={link}
        >
            <Button className="table-action">
                <DynamicIcon
                    className="table-action__svg-icon"
                    type={actionIcon}
                />
                {actionTitle}
            </Button>
        </Link>
    );
};
