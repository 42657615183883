import {FileExtensions} from 'shared/constants/file-extensions';
import {MimeTypes} from 'shared/constants/mime-types';

export const IGNORABLE_FIELDS_KEYS: string[] = [
    'externalServiceId',
];

export const SIGN_UPLOAD_EXTENSIONS = [
    FileExtensions.BIN,
    FileExtensions.SGN,
].toString();

export const FILE_UPLOAD_EXTENSIONS = [
    MimeTypes.MS_EXCEL,
    MimeTypes.MS_EXCEL_OPEN_XML,
    MimeTypes.MS_WORD,
    MimeTypes.MS_WORD_OPEN_XML,
    MimeTypes.PDF,
    MimeTypes.RTF,
    MimeTypes.JPEG,
    MimeTypes.TIFF,
    MimeTypes.XML,
].toString();
