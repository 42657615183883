import {createReducer} from 'store/config/creators';
import {Action} from 'store/config/types';

import {BreadcrumbsActions} from './breadcrumbs-constants';
import {
    BreadcrumbPushSetPayload,
    BreadcrumbReplaceSetPayload,
    BreadcrumbsSetPayload, initBreadcrumbsState,
} from './breadcrumbs-types';

const initialState: initBreadcrumbsState = {};

export const breadcrumbsReducer = createReducer(initialState, {
    [BreadcrumbsActions.SET_BREADCRUMBS]: (
        state: initBreadcrumbsState,
        {payload}: Action<BreadcrumbsSetPayload>,
    ) => ({
        ...state,
        [payload.entityName]: {
            breadcrumbs: [...(payload.breadcrumbs || [])],
        },
    }),
    [BreadcrumbsActions.REPLACE_BREADCRUMBS]: (
        state: initBreadcrumbsState,
        {payload}: Action<BreadcrumbReplaceSetPayload>,
    ) => {
        const newBreadcrumbsData = state[payload?.entityName]?.breadcrumbs || [];
        const index = newBreadcrumbsData.findIndex(item => item.key === payload.crumb.key);
        newBreadcrumbsData.splice(index, newBreadcrumbsData.length, {
            ...payload.crumb,
        });
        return ({
            ...state,
            [payload.entityName]: {
                breadcrumbs: [...newBreadcrumbsData],
            },
        });
    },
    [BreadcrumbsActions.PUSH_BREADCRUMBS]: (
        state: initBreadcrumbsState,
        {payload}: Action<BreadcrumbPushSetPayload>,
    ) => {
        const crumbId = state[payload.entityName]?.breadcrumbs.findIndex(
            crumbItem => crumbItem.key === payload.crumb?.key,
        );
        return crumbId === -1 ? {
            ...state,
            [payload.entityName]: {
                breadcrumbs: [...state[payload.entityName]?.breadcrumbs, payload.crumb],
            },
        }
            : state;
    },

});
