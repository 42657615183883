import {MenuStructureItem, generateRoute} from 'store/slices/menu-slice';

export const findTopLevelParent: (
    menuStructure: MenuStructureItem[] | undefined | null, it: MenuStructureItem
) => MenuStructureItem | undefined = (menuStructure, it) => {
    if (!menuStructure) return undefined;
    if (!it.parentId) return it;
    const parent = menuStructure?.find(mItem => mItem.id === it.parentId);
    if (!parent) return undefined;
    return findTopLevelParent(menuStructure, parent);
};

interface CheckIfIsTopLevelMenuItemSelectedArgs {
    menuStructure: MenuStructureItem[] | null;
    pathname: string;
    item: MenuStructureItem | undefined;
}
export const checkIfIsTopLevelMenuItemSelected = ({
    item,
    menuStructure,
    pathname,
}: CheckIfIsTopLevelMenuItemSelectedArgs) => {
    const childEntityName = pathname.substring(1).split('/');
    const currentItem = menuStructure?.find(it => pathname === generateRoute({
        ...it,
        childEntityName: childEntityName[1],
    }));
    if (!currentItem) return false;
    if (!item) return false;
    const topLevelParent = findTopLevelParent(menuStructure, currentItem);
    return item?.id === topLevelParent?.id;
};
