import {
    Button, Form, Input,
} from 'antd';
import {useForm} from 'antd/es/form/Form';
import React, {useEffect} from 'react';

import {CryptoProSigningInput} from 'components/@common/inputs/crypto-pro-signing-input';
import {FileUploader} from 'components/ais/monitor-services-card/monitor-service-dynamic-fields/components/file-uploader/file-uploader';
import {TCAuditStagesLookupCode, TCSendServiceV3LookupCode} from 'components/ais/transport-container-card/constants/transport-container-card.contants';
import {CustomFileField} from 'components/form/inputs/custom-file-field';
import {CustomSelect} from 'components/form/inputs/custom-select';
import {ModalOpenerComponent} from 'components/modal-opener-component';
import {selectReferenceEntityData} from 'modules/data/data-selectors';
import {selectDocumentsSigningMethod} from 'modules/documents/documents-selectors';
import {DocumentsSigningMethod} from 'modules/documents/documents-types';
import {ReactComponent as FileIcon} from 'shared/assets/forms/file.svg';
import {
    useCallServiceForTransportContainerMutation,
} from 'store/api/transport-container/transport-container.api';
import {useAppSelector} from 'store/config/hooks';
import 'components/ais/transport-container-card/modals/create-transport-container/create-transport-container.less';

export interface CreateTransportContainerModalProps {
    stage: TCAuditStagesLookupCode;
    transportContainerId: string;
    mode?: 'processingFileUpload' | 'errorFileUpload';
    disabled?: boolean;
    values?: {
        mainDocumentName?: string;
        errorDocumentName?: string;
        mainDocumentId?: string;
        controlSupervisoryActivities?: string;
    };
    isSigningFunctionEnabled: boolean;
    entityName: string;
}

export const CreateTransportContainer = ({
    mode,
    stage,
    values,
    disabled,
    isSigningFunctionEnabled,
    transportContainerId,
    entityName,
}: CreateTransportContainerModalProps) => {
    const [form] = useForm();
    const [callServiceForTransportContainer, {isSuccess}] = useCallServiceForTransportContainerMutation();

    const kndLookups = useAppSelector(selectReferenceEntityData('valueLists/FLEX_VALUE?sublistCode=KND_CODES'));
    const aisServicesLookups = useAppSelector(selectReferenceEntityData('ais/valueLists/AIS_SERVICES'));

    useEffect(() => {
        if (!kndLookups || !values?.controlSupervisoryActivities) return;

        const knd = kndLookups?.find(lookup => lookup.lookupCode === values?.controlSupervisoryActivities);
        if (knd && knd.id) {
            form?.setFieldsValue({controlSupervisoryActivities: knd.id});
        }
    }, [kndLookups, values?.controlSupervisoryActivities]);

    useEffect(() => {
        if (!aisServicesLookups?.length) return;

        const tcServiceV3 = aisServicesLookups.find(lookup => lookup.lookupCode === TCSendServiceV3LookupCode);
        if (tcServiceV3?.id) {
            form?.setFieldsValue({
                externalServiceId: tcServiceV3?.id,
            });
        }
    }, [aisServicesLookups]);

    const signingMethod = useAppSelector(selectDocumentsSigningMethod);
    const isSigningWithCryptoPro = signingMethod === DocumentsSigningMethod.CRYPTO_PRO_PLUGIN;

    return (
        <ModalOpenerComponent
            component={(
                <Button
                    type="primary"
                    disabled={disabled}
                >
                    Создать ТК
                </Button>
            )}
            modalProps={{
                title: 'Вызвать сервис',
                destroyOnClose: true,
            }}
            handleSave={setIsModalOpen => {
                callServiceForTransportContainer({
                    ...form.getFieldsValue(),
                    stage,
                    containerId: transportContainerId,
                })
                    .then(() => {
                        if (isSuccess) setIsModalOpen(false);
                    });
            }}
            controlLabels={{
                save: 'Запустить',
                cancel: 'Назад',
            }}
        >
            <Form
                form={form}
                layout="vertical"
                className="form create-tc-form"
            >
                <Form.Item
                    name="externalServiceId"
                    label="Наименование сервиса"
                >
                    <CustomSelect
                        settings={{
                            isDisabled: true,
                            url: 'ais/valueLists/AIS_SERVICES',
                            valuePath: 'id',
                            labelPath: 'description',
                        }}
                    />
                </Form.Item>
                {mode === 'processingFileUpload' && (
                    <>
                        <Form.Item
                            name="periodTM"
                            label="Период проведения НМ"
                        >
                            <Input placeholder="Укажите период проведения НМ" />
                        </Form.Item>
                        <Form.Item
                            name="controlSupervisoryActivities"
                            label="КНД основного документа"
                        >
                            <CustomSelect settings={{
                                isDisabled: !!values?.controlSupervisoryActivities,
                                showSearch: true,
                                url: 'valueLists/FLEX_VALUE?sublistCode=KND_CODES',
                                valuePath: 'id',
                                labelPath: 'description',
                            }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="mainDocument"
                            label="Основной документ"
                        >
                            {values?.mainDocumentName ? (
                                // основной документ предзаполнен
                                <div className="upload-field-wrapper">
                                    <Input
                                        disabled
                                        prefix={<FileIcon />}
                                        value={values?.mainDocumentName}
                                    />
                                    <Button
                                        disabled
                                        type="primary"
                                    >
                                        Выбрать файл
                                    </Button>
                                </div>
                            ) : (
                                <CustomFileField />
                            )}
                        </Form.Item>
                        <Form.Item
                            name="powerOfAttorney"
                            label="Доверенность"
                        >
                            <CustomFileField />
                        </Form.Item>
                        <Form.Item
                            name="files"
                            label="Приложения"
                        >
                            <FileUploader
                                multiple
                            />
                        </Form.Item>
                        <Form.Item
                            name="signs"
                            label="Файлы ЭП"
                        >
                            {isSigningWithCryptoPro ? (
                                <CryptoProSigningInput
                                    form={form}
                                    fileFieldNames={[
                                        'files',
                                        'powerOfAttorney',
                                        'mainDocument',
                                    ]}
                                    showSignComponent={isSigningFunctionEnabled}
                                    documentsToSignInDB={values ? [{
                                        documentName: values?.mainDocumentName ?? 'document',
                                        documentId: values?.mainDocumentId ?? '',
                                    }] : undefined}
                                    entityName={entityName}
                                />
                            ) : (
                                <FileUploader
                                    multiple
                                />
                            )}
                        </Form.Item>
                    </>
                )}
                {mode === 'errorFileUpload' && (
                    <Form.Item
                        name="errorDocument"
                        label="Сообщение об ошибке"
                    >
                        {values?.errorDocumentName ? (
                            // файл с ошибкой предзаполнен
                            <div className="upload-field-wrapper">
                                <Input
                                    disabled
                                    prefix={<FileIcon />}
                                    value={values?.errorDocumentName}
                                />
                                <Button
                                    disabled
                                    type="primary"
                                >
                                    Выбрать файл
                                </Button>
                            </div>
                        ) : (
                            <CustomFileField />
                        )}

                    </Form.Item>
                )}
            </Form>
        </ModalOpenerComponent>
    );
};
