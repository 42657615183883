import {
    Empty, Form, Input, InputNumber,
} from 'antd';
import {useForm} from 'antd/lib/form/Form';
import cn from 'classnames';
import React, {useContext, useEffect, useState} from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {CustomSelectEntry, CustomSelectValueTriggerEvent} from 'components/form/inputs/custom-select/custom-select';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {
    selectReportConfigurationHeaderAttributesAsSelectEntries,
    selectReportConfigurationTemplateBlocksCount,
    selectTableReportConfigurationData,
} from 'store/slices/report-configuration-slice/report-configuration-slice';
import {
    updateTableReportConfiguration,
} from 'store/slices/report-configuration-slice/report-configuration/report-configuration-thunks';

import {ButtonsBar} from '../../components/buttons-bar/buttons-bar';
import {ReportConfigurationTabKey} from '../../report-configuration.constants';
import {ReportConfigurationContext} from '../../report-configuration.context';

import './report-header-settings.less';

interface ReportHeaderSettingsProps {}

export const ReportHeaderSettings: React.FC<ReportHeaderSettingsProps> = () => {
    const [form] = useForm();

    const reportConfigurationData = useAppSelector(
        selectTableReportConfigurationData,
    );

    const headerAttributesEntries = useAppSelector(selectReportConfigurationHeaderAttributesAsSelectEntries);
    const templateBlocksCount = useAppSelector(selectReportConfigurationTemplateBlocksCount);

    const {
        entityName,
        setSelectedTabKey,
        isCreatingNewTemplate,
        templateCode,
    } = useContext(ReportConfigurationContext);

    const [isEditingForm, setIsEditingForm] = useState(false);

    const dispatch = useAppDispatch();

    const [reportHeadersHiddenFields, setReportHeadersHiddenFields] = useState<
        Record<number, boolean>>({});

    const getInitialData = () => {
        const {reportHeaders} = reportConfigurationData ?? {};
        const values = headerAttributesEntries?.map(attribute => {
            const entryFromData = reportHeaders?.find(col => col?.keyName === attribute?.value);
            return {
                keyName: attribute?.value,
                reportTitle: entryFromData?.reportTitle ?? undefined,
                block: entryFromData?.block ?? undefined,
                hidden: entryFromData?.hidden ?? undefined,
                ordinal: entryFromData?.ordinal ?? undefined,
            };
        });
        return values ?? [];
    };

    const [initialValues, setInitialValues] = useState<any>({
        reportHeaders: getInitialData(),
    });

    const setInitialData = () => {
        if (initialValues?.reportHeaders?.length) return;
        const values = getInitialData();
        setInitialValues({reportHeaders: values});
        const initialHiddenFields = values?.map((columnReport: any) => (columnReport?.hidden));
        setReportHeadersHiddenFields({...initialHiddenFields});
    };

    useEffect(() => {
        if (headerAttributesEntries && reportConfigurationData) {
            setInitialData();
        }
    }, [headerAttributesEntries, reportConfigurationData]);

    useEffect(() => {
        if (initialValues) form.resetFields();
    }, [initialValues]);

    const [blockOptions, setBlockOptions] = useState<CustomSelectEntry<string, number>[] | undefined>();
    useEffect(() => {
        if (templateBlocksCount) {
            const options = [];
            for (let i = 1; i <= +templateBlocksCount; i += 1) {
                options?.push({label: String(i), value: i});
            }
            setBlockOptions(options);
        }
    }, [templateBlocksCount]);

    const isDataPresents = !!(initialValues.reportHeaders?.length);
    const isDisabled = isDataPresents && !isEditingForm;

    const handleBack = () => {
        setSelectedTabKey?.(ReportConfigurationTabKey.REPORT_CONFIGURATOR);
    };

    const handleSubmit = () => {
        form.submit();
    };

    const handleFinish = (values: any) => {
        if (templateCode) {
            dispatch(updateTableReportConfiguration({
                templateCode,
                data: values,
            }));
        }
        setIsEditingForm(false);
    };

    return (
        <div className={cn('report-header-settings')}>
            <ButtonsBar
                entityName={entityName}
                isCreating={isCreatingNewTemplate}
                isCreatingDisabled={!isDataPresents || !isEditingForm}
                isEditing={isEditingForm}
                setIsEditing={setIsEditingForm}
                hideEditButton={!isDataPresents}
                hideDeleteButton
                onSubmit={handleSubmit}
                onBack={handleBack}
                onCancelEdit={() => {
                    setIsEditingForm(false);
                    form.resetFields();
                }}
            />
            <div className={cn('report-header-settings__section-title')} />
            <div className={cn('report-header-settings__body')}>
                <Form
                    initialValues={initialValues}
                    layout="vertical"
                    form={form}
                    onFinish={handleFinish}
                >
                    <Form.List name="reportHeaders">
                        {(fields, operations: {}, {errors}) => (
                            <>
                                <Form.ErrorList errors={errors} />
                                {
                                    !fields?.length && (
                                        <Empty description="Атрибуты источника данных заголовка не найдены" />
                                    )
                                }
                                <div className={cn('form-list')}>
                                    {fields?.map(field => (
                                        <div
                                            key={field.fieldKey}
                                            className={cn('form-list__items')}
                                        >
                                            <Form.Item
                                                style={{width: 398}}
                                                label="Атрибут источника"
                                                name={[field.name, 'keyName']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Пожалуйста, выберите атрибут',
                                                    },
                                                ]}
                                            >
                                                <Input disabled />
                                            </Form.Item>
                                            <Form.Item
                                                style={{width: 162}}
                                                label="Блок"
                                                name={[field.name, 'block']}
                                            >
                                                <CustomSelect
                                                    entries={blockOptions}
                                                    settings={{
                                                        placeholder: 'Выберите блок',
                                                        isDisabled,
                                                    }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                style={{width: 180}}
                                                label="Выводить в отчёт"
                                                name={[field.name, 'hidden']}
                                            >
                                                <CustomSelect
                                                    entries={[
                                                        {label: 'Да', value: false},
                                                        {label: 'Нет', value: true},
                                                    ]}
                                                    settings={{
                                                        placeholder: 'Выберите опцию',
                                                        useFirstOptionAfterEntriesChanged: true,
                                                        noUseFirstOptionWhenValueIsSet: true,
                                                        isDisabled,
                                                    }}
                                                    handleTriggerEvent={(value, event) => {
                                                        if (
                                                            event === CustomSelectValueTriggerEvent
                                                                .valueHasBeenChangedManually
                                                        ) {
                                                            setReportHeadersHiddenFields({
                                                                ...reportHeadersHiddenFields,
                                                                [field.name]: value,
                                                            });
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                style={{width: 156}}
                                                label="Номер"
                                                name={[field.name, 'ordinal']}
                                            >
                                                <InputNumber disabled={isDisabled} />
                                            </Form.Item>
                                            <Form.Item
                                                rules={[{
                                                    required: reportHeadersHiddenFields?.[field.name] === false
                                                      || !form.getFieldValue([
                                                          'reportHeaders',
                                                          field.name,
                                                          'hidden',
                                                      ]),
                                                }]}
                                                required={reportHeadersHiddenFields?.[field.name] === false
                                                    || !form.getFieldValue([
                                                        'reportHeaders',
                                                        field.name,
                                                        'hidden',
                                                    ])}
                                                style={{width: 420}}
                                                label="Пользовательское наименование"
                                                name={[field.name, 'reportTitle']}
                                            >
                                                <Input disabled={isDisabled} />
                                            </Form.Item>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </Form.List>
                </Form>
            </div>
        </div>
    );
};
