import React from 'react';

import {useAppSelector} from 'store/config/hooks';
import {selectModalData, ModalComponentInterface} from 'store/slices/modals-slice';

import {FormEditModal} from './form-edit-modal';

interface FormModalContainerProps extends ModalComponentInterface {
}

export const FormEditEntityComponent: React.FunctionComponent<
    FormModalContainerProps
> = ({
    onClose,
}: FormModalContainerProps) => {
    const entityName = useAppSelector(state => selectModalData(state)?.entityName);
    const referenceUrl = useAppSelector(state => selectModalData(state)?.modalParameters?.referenceUrl);
    return (
        <FormEditModal
            entityName={entityName || ''}
            onClose={onClose}
            referenceUrl={referenceUrl}
            forceHasTitle
        />
    );
};
