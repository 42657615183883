import {Button} from 'antd';
import React from 'react';
import {useHistory} from 'react-router-dom';

import {ListArrayFactory} from 'components/@common/specific/list-array-factory';
import {TCAuditStagesLookupCode} from 'components/ais/transport-container-card/constants/transport-container-card.contants';
import {CreateTransportContainer} from 'components/ais/transport-container-card/modals/create-transport-container';
import {
    getCreateTransportContainerModalInitialValues,
    resolveProcessingResultCreateTCMode,
} from 'components/ais/transport-container-card/utils/transport-contaner-card.utils';
import {CombinedStringListItem} from 'components/form/fields/combined-string-list-item';
import {DateListItem} from 'components/form/fields/date-list-item';
import {DownloadDocumentListItem} from 'components/form/fields/download-document-list-item';
import {URL_AIS3_REQUIREMENT_RESPONSE} from 'shared/constants/urls';
import {useUserRoleFunctions} from 'shared/hooks/use-user-role-functions';
import {TransportContainerDescription} from 'store/api/transport-container/transport-container.api';
import {UserRoleFunction} from 'store/slices/user-slice/user-slice-role-functions';

import {AIS_FILE_EXCHANGE_ENTITY_NAME_SECTION_CODE} from '../../../ais.constants';

export interface TransportContainerInformationProps {
    transportContainerId: string;
    transportContainer: TransportContainerDescription;
    entityName: string;
}

export const TransportContainerInformation = ({
    transportContainer,
    transportContainerId,
    entityName,
}: TransportContainerInformationProps) => {
    const history = useHistory();

    const handleCreateDemandResponseClick = () => {
        const docId = transportContainer.mainDocument?.id;
        const docIdParam = docId ? `?docId=${docId}` : '';
        history.push(`${URL_AIS3_REQUIREMENT_RESPONSE}${docIdParam}`);
    };
    const {hasUserRoleFunction} = useUserRoleFunctions({
        sections: [AIS_FILE_EXCHANGE_ENTITY_NAME_SECTION_CODE],
    });

    return (
        <ListArrayFactory
            listArrayDescription={[{
                listTitle: 'Общие сведения о транспортном контейнере',
                list: [{
                    title: 'Наименование:',
                    description: transportContainer?.name || '-',
                },
                {
                    title: 'Файл:',
                    description: <DownloadDocumentListItem
                        fileList={transportContainer.tcDocument?.fileList}
                    />,
                },
                {
                    title: 'Основной документ:',
                    description: <DownloadDocumentListItem
                        fileList={transportContainer.mainDocument?.fileList}
                    />,
                },
                {
                    title: 'Номер требования:',
                    description: transportContainer?.numRequirements || '-',
                },
                {
                    title: 'Дата получения:',
                    description: <DateListItem
                        date={transportContainer.downloadingAudit?.createdDate}
                    />,
                },
                {
                    title: 'Результаты скачивания ТК:',
                    description: <CombinedStringListItem
                        stringStructure="statusCode - statusText"
                        data={transportContainer.downloadingAudit}
                    />,
                },
                ],
            }, {
                listTitle: 'Результаты обработки транспортного контейнера',
                list: [{
                    title: 'Файл по результатам обработки:',
                    description: <DownloadDocumentListItem
                        fileList={transportContainer.processingResultDocument?.fileList}
                    />,
                },
                {
                    title: 'Дата отправки результатов обработки:',
                    description: <DateListItem
                        date={transportContainer.processingAudit?.createdDate}
                    />,
                },
                {
                    title: 'Статус результатов обработки:',
                    description: <CombinedStringListItem
                        stringStructure="statusCode - statusText"
                        data={transportContainer.processingAudit}
                    />,
                },
                {
                    title: 'Дата получения НО результатов обработки:',
                    description: <DateListItem
                        date={transportContainer.processingAudit?.updatedDate}
                    />,
                },
                ],
                useButtonsBottomMargin: true,
                buttons: [
                    <CreateTransportContainer
                        stage={TCAuditStagesLookupCode.processing}
                        transportContainerId={transportContainerId}
                        mode={resolveProcessingResultCreateTCMode(transportContainer)}
                        isSigningFunctionEnabled={hasUserRoleFunction(UserRoleFunction.SIGN_FILE_DOC)}
                        values={getCreateTransportContainerModalInitialValues(transportContainer, 'processing')}
                        entityName={entityName}
                    />,
                ],
            },
            {
                listTitle: 'Ответ на требование',
                list: [{
                    title: 'Файл ответа на требование:',
                    description: <DownloadDocumentListItem
                        fileList={transportContainer.responseRequirementDocument?.fileList}
                    />,
                },
                {
                    title: 'Дата отправки ответа на требование:',
                    description: <DateListItem
                        date={transportContainer.responseRequirementAudit?.createdDate}
                    />,
                },
                {
                    title: 'Статус ответа на требование:',
                    description: <CombinedStringListItem
                        stringStructure="statusCode - statusText"
                        data={transportContainer.responseRequirementAudit}
                    />,
                },
                {
                    title: 'Дата получения НО ответа на требование:',
                    description: <DateListItem
                        date={transportContainer.responseRequirementAudit?.updatedDate}
                    />,
                },
                ],
                buttons: [
                    <Button
                        type="primary"
                        onClick={handleCreateDemandResponseClick}
                    >
                        Создать опись
                    </Button>,
                    <CreateTransportContainer
                        stage={TCAuditStagesLookupCode.responseRequirement}
                        transportContainerId={transportContainerId}
                        mode="processingFileUpload"
                        isSigningFunctionEnabled={hasUserRoleFunction(UserRoleFunction.SIGN_FILE_DOC)}
                        values={getCreateTransportContainerModalInitialValues(transportContainer, 'demand')}
                        entityName={entityName}
                    />,
                ],
            }]}
        />
    );
};
