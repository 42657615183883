import {
    Checkbox, Form, Spin,
} from 'antd';
import {FormInstance} from 'antd/es/form';
import {useForm} from 'antd/lib/form/Form';
import cn from 'classnames';
import React, {
    useContext, useEffect, useMemo, useState,
} from 'react';

import {createDffSegmentValues} from 'components/document-upload-template/document-upload-template-utils';
import {CustomSelect} from 'components/form/inputs/custom-select';
import {SegmentResolverComponent} from 'components/report-configuration/components/segment-resolver-component';
import {ReportConfigurationContext} from 'components/report-configuration/report-configuration.context';
import {transformSegmentToInitialValues} from 'pages/ais/documents-register-page/modals/document-card-modal/document-card-modal-utils';
import {useAfterEffect} from 'shared/hooks/use-after-effect';
import {setFieldsValueUntouched} from 'shared/utils';
import {useQueryParams} from 'shared/utils/query-params/use-query-params.hook';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {selectDDContextSegments} from 'store/slices/descriptive-flexfields-slice/descriptive-flexfields-slice';
import {loadDDContextSegments} from 'store/slices/descriptive-flexfields-slice/descriptive-flexfields-thunks';
import {ReportDdConnectionRulesForm} from 'store/slices/report-configuration-slice/dd-references-configuration/dd-references-configuration-types';

import {DD_LINK_COLUMN_KEY} from '../../report-dd-references-constants';

import './dd-references-edit-modal-form.less';

interface DdReferencesEditModalFormProps {
    handleFinish: (values: any) => void;
    mode?: 'edit' | 'create';
    defaultInitialValues?: {[index: string]: any};
}

export const DdReferencesEditModalForm = React.forwardRef<
FormInstance, DdReferencesEditModalFormProps
>(({handleFinish, defaultInitialValues, mode}: DdReferencesEditModalFormProps, ref) => {
    const [form] = useForm();
    const dispatch = useAppDispatch();

    const {templateCode} = useContext(ReportConfigurationContext);

    const {getQueryParams} = useQueryParams();
    const {linkColumn} = getQueryParams([DD_LINK_COLUMN_KEY]);

    const [initialValues] = useState<typeof defaultInitialValues>({
        enabled: true,
        linkColumnName: linkColumn,
        ...transformSegmentToInitialValues(defaultInitialValues?.dffSegmentValues),
        ...defaultInitialValues,
    });

    const [selectedContext, setSelectedContext] = useState<string | null>(initialValues?.context);
    const [isLoadingSegments, setIsLoadingSegments] = useState(false);

    const contextSegments = useAppSelector(
        !selectedContext ? () => undefined : s => selectDDContextSegments(s, {contextCode: selectedContext}),
    );

    const contextSegmentsDefaultValues = useMemo(() => {
        if (!contextSegments) return undefined;
        return Object.fromEntries(
            contextSegments
                ?.map(segment => [segment.attributeColumnName.toLocaleLowerCase(), segment.defaultValue])
                ?.filter(([, defaultValue]) => !!defaultValue),
        );
    }, [contextSegments]);

    useEffect(() => {
        if (contextSegmentsDefaultValues && mode === 'create') {
            setFieldsValueUntouched(form, contextSegmentsDefaultValues);
        }
    }, [contextSegmentsDefaultValues]);

    const loadSegments = () => {
        if (selectedContext) {
            setFieldsValueUntouched(form, {context: selectedContext});
            setIsLoadingSegments(true);
            dispatch(loadDDContextSegments({
                contextCode: selectedContext,
            })).finally(() => {
                setIsLoadingSegments(false);
            });
        }
    };

    useEffect(() => {
        if (!contextSegments) loadSegments();
    }, []);

    useAfterEffect(() => {
        loadSegments();
    }, [selectedContext]);

    const handleFormFinish = (values: ReportDdConnectionRulesForm) => {
        type Accumulator = {
          filteredValues: Record<string, unknown>;
          valuesToTransform: Record<string, unknown>;
        };

        const {filteredValues, valuesToTransform} = Object.keys(values).reduce((acc: Accumulator, key: string) => {
            if (contextSegments?.some(segment => segment.segmentColumnName === key)) {
                acc.valuesToTransform[key] = values[key as keyof ReportDdConnectionRulesForm];
            } else {
                acc.filteredValues[key] = values[key as keyof ReportDdConnectionRulesForm];
            }
            return acc;
        }, {filteredValues: {}, valuesToTransform: {}});

        handleFinish({
            ...filteredValues,
            dffSegmentValues: createDffSegmentValues(Object.entries(valuesToTransform)),
        });
    };

    return (
        <div>
            <Form
                initialValues={initialValues}
                ref={ref}
                name="dd-references-edit"
                form={form}
                layout="vertical"
                className={cn('dd-references-edit-modal-form')}
                onFinish={handleFormFinish}
            >

                <Form.Item
                    name="parentDocumentTypeCode"
                    label="Исходный документ"
                    rules={[{required: true}]}
                >
                    <CustomSelect
                        settings={{
                            placeholder: 'Выберите документ',
                            url: 'valueLists/DOCUMENT_TYPES_FOR_TEMPLATE',
                            labelPath: 'meaning',
                            valuePath: 'lookupCode',
                            referenceUrlQueryParams: !templateCode ? {} : {
                                sublistCode: templateCode,
                            },
                            useFirstOptionAfterReceivedFromFetcher: mode === 'create',
                            setFirstOptionUntouched: mode === 'create',
                            formInstance: form,
                            formFieldKey: 'parentDocumentTypeCode',
                        }}
                        handleTriggerEvent={value => {
                            setSelectedContext(value?.lookupCode);
                        }}
                    />
                </Form.Item>

                <Form.Item
                    label="Поле для перехода"
                    name="linkColumnName"
                >
                    <CustomSelect
                        settings={{
                            isDisabled: true,
                            placeholder: 'Выберите поле',
                        }}
                    />
                </Form.Item>

                <Form.Item
                    name="childDocumentTypeCode"
                    label="Конечный документ"
                    rules={[{required: true}]}
                >
                    <CustomSelect settings={{
                        url: 'valueLists/ACTIVE_DOCUMENT_TYPES',
                        labelPath: 'meaning',
                        valuePath: 'lookupCode',
                        placeholder: 'Документ не выбран',
                        isClearable: true,
                        showSearch: true,
                        useFirstOptionAfterReceivedFromFetcher: mode === 'create',
                        setFirstOptionUntouched: mode === 'create',
                        formInstance: form,
                        formFieldKey: 'childDocumentTypeCode',
                    }}
                    />
                </Form.Item>

                <div className={cn('dd-references-edit-modal-form__attributes-block')}>
                    <Form.Item
                        label="Контекст атрибутов"
                        name="context"
                    >
                        <CustomSelect settings={{
                            url: 'valueLists/TABLE_REPORT_DRILL_DOWN_CONTEXT',
                            labelPath: 'meaning',
                            valuePath: 'lookupCode',
                            placeholder: 'Контекст не выбран',
                            isClearable: true,
                            showSearch: true,
                            isDisabled: true,
                        }}
                        />
                    </Form.Item>
                    {isLoadingSegments ? (
                        <div style={{display: 'grid'}}>
                            <Spin style={{alignSelf: 'center'}} />
                        </div>
                    ) : (
                        <div className="dd-references-edit-modal-form__attributes-block__attributes">
                            {contextSegments?.map(segment => (
                                <SegmentResolverComponent
                                    key={segment.id}
                                    segment={segment}
                                />
                            ))}
                        </div>
                    )}
                </div>

                <div className={cn(
                    'dd-references-edit-modal-form__row',
                    'dd-references-edit-modal-form__row_align-end',
                )}
                >
                    {/* <Form.Item label="Дата начала действия">
                        <DateCell format={DATE_DMY} />
                    </Form.Item>
                    <Form.Item label="Дата окончания действия">
                        <DateCell format={DATE_DMY} />
                    </Form.Item> */}
                    <Form.Item
                        valuePropName="checked"
                        name="enabled"
                    >
                        <Checkbox>
                            Признак активности
                        </Checkbox>
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
});
