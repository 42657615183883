import {FileOutlined} from '@ant-design/icons';
import {Popover} from 'antd';
import {UploadFile} from 'antd/es/upload/interface';
import cn from 'classnames';
import React from 'react';

import {IconsMap} from 'components/dynamic-icon';
import {StateSetter} from 'shared/types/generics';

import './upload-dropzone-file.less';

interface UploadDropzoneFileProps {
    file: UploadFile;
    setFileList: StateSetter<UploadFile[]>;
}

export const UploadDropzoneFile: React.FC<UploadDropzoneFileProps> = (
    {file, setFileList}: UploadDropzoneFileProps,
) => (
    <div className={cn('upload-dropzone-file')}>
        <div className={cn('upload-dropzone-file__left')}>
            <div>
                <FileOutlined style={{color: '#A9A9A9'}} />
            </div>
            <div>
                <Popover
                    mouseEnterDelay={0.5}
                    placement="bottom"
                    content={(
                        <div>
                            Размер файла:{' '}
                            {(() => {
                                const tf = (n: number) => n.toFixed(2);
                                if (file.size > 1024) {
                                    return `${tf(file.size / 1024)} КБ`;
                                }
                                if (file.size > (1024 * 1024)) {
                                    return `${tf(
                                        file.size / (1024 * 1024),
                                    )} МБ`;
                                }
                                return `${file.size} байт`;
                            })()}
                        </div>
                    )}
                >
                    {file.name}
                </Popover>
            </div>
        </div>
        <div className={cn('upload-dropzone-file__right')}>
            <div
                className={cn('upload-dropzone-file__right__trash')}
                onClick={() => {
                    setFileList(p => p.filter(f => f.uid !== file.uid));
                }}
            >
                <IconsMap.TrashXOutlined />
            </div>
        </div>
    </div>
);
