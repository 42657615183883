import {CloudUploadOutlined, StopOutlined} from '@ant-design/icons/lib';
import {unwrapResult} from '@reduxjs/toolkit';
import {Button, Modal} from 'antd';
import React, {useEffect, useState} from 'react';

import {ErrorMessages} from 'shared/constants/messages';
import {showMessage} from 'shared/utils/notifications';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {selectIsThunkPending, selectIsThunkRejected} from 'store/slices/loading-state-slice';
import {selectRequestData} from 'store/slices/request-slice/request-selectors';
import {
    getActionStatusByIdAndActionCode,
    runActionByIdAndActionCode,
} from 'store/slices/request-slice/request-slice-thunks';
import {RequestStatus} from 'store/slices/request-slice/request-slice-type';
import {normalizeBoolValue} from 'utils/common';

import './request-from-d365.less';

export const RequestFromD365 = () => {
    const dispatch = useAppDispatch();

    const requestId = useAppSelector(selectRequestData)?.requests?.id;
    const isStatusLoading = useAppSelector(s => selectIsThunkPending(s, getActionStatusByIdAndActionCode.typePrefix));
    const isStatusRejected = useAppSelector(s => selectIsThunkRejected(s, getActionStatusByIdAndActionCode.typePrefix));
    const isRunningLoading = useAppSelector(s => selectIsThunkPending(s, runActionByIdAndActionCode.typePrefix));

    const [visible, setVisible] = useState<boolean>(false);
    const [hasBeenStarted, setHasBeenStarted] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        if (requestId) {
            dispatch(getActionStatusByIdAndActionCode({
                topicId: requestId,
                actionCode: RequestStatus.REQUEST_FROM_D365,
            })).then(unwrapResult)
                .then(hasBeenStartedFlag => {
                    setHasBeenStarted(normalizeBoolValue(hasBeenStartedFlag));
                })
                .catch(() => {
                    showMessage({message: 'Не удалось получить статус о запросе в D365', isError: true});
                });
        }
    }, [requestId]);

    const runRequestAction = () => {
        if (requestId) {
            dispatch(runActionByIdAndActionCode({
                topicId: requestId,
                actionCode: RequestStatus.REQUEST_FROM_D365,
            })).then(unwrapResult)
                .then(() => setHasBeenStarted(true))
                .catch(e => (
                    showMessage({
                        message: e?.response?.data ?? ErrorMessages.QUERY_EXECUTION,
                        isError: true,
                    })
                ));
        }
    };

    const buttonTitle = (() => {
        if (hasBeenStarted === undefined && !isStatusRejected) {
            return undefined;
        }

        return hasBeenStarted ? 'Передано в D365' : 'Запросить из D365';
    })();

    const handleModalClose = () => setVisible(false);
    const handleModalConfirm = () => {
        runRequestAction();
        setVisible(false);
    };

    return (
        <>
            <Modal
                className="request-from-d365-modal"
                visible={visible}
                title="Подтвердите действие"
                onCancel={handleModalClose}
                centered
                footer={null}
            >
                <>
                    Запросить документы повторно?
                    <div
                        className="request-from-d365-modal__footer"
                    >
                        <Button
                            type="primary"
                            onClick={handleModalConfirm}
                        >
                            Запросить
                        </Button>
                        <Button
                            type="default"
                            onClick={handleModalClose}
                        >
                            Отмена
                        </Button>
                    </div>
                </>
            </Modal>
            <Button
                type="primary"
                loading={isRunningLoading || isStatusLoading}
                icon={!isStatusRejected ? <CloudUploadOutlined /> : <StopOutlined />}
                disabled={hasBeenStarted === undefined || isStatusRejected}
                onClick={() => {
                    if (!hasBeenStarted) {
                        runRequestAction();
                    } else {
                        setVisible(true);
                    }
                }}
            >
                {buttonTitle}
            </Button>
        </>
    );
};
