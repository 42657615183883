import {RpcDeleteResponse} from 'shared/types/rpc';
import {performRequest} from 'shared/utils';

import {ReportConfigurationDataDto} from '../report-configuration-dto';
import {ReportDdParameter} from './dd-parameters-configuration-types';

const ddParametersEndpoint = 'table-reports/drill-down-parameters';

export interface FetchDDChildReportQueryParametersArgs {
    templateCode: string;
}
export const fetchDDChildReportQueryParameters = (
    params: FetchDDChildReportQueryParametersArgs,
) => performRequest<
    {queryParameters: ReportConfigurationDataDto['queryParameters']}
>({
    url: `table-reports/${params?.templateCode}/config/field/queryParameters`,
    method: 'GET',
    params,
});

export interface FetchDrillDownParametersArgs {
    drillId: string;
}
export const fetchDrillDownParameters = (
    params: FetchDrillDownParametersArgs,
) => performRequest<ReportDdParameter[]>({
    url: `${ddParametersEndpoint}/${params?.drillId}`,
    method: 'GET',
});

export type DrillDownParameterPostRequestArgs = Partial<ReportDdParameter>;
export const postDrillDownOarameter = (
    args: DrillDownParameterPostRequestArgs,
) => performRequest<ReportDdParameter>({
    url: [ddParametersEndpoint].join('/'),
    method: 'POST',
    data: args,
});

export type DeleteDrillDownParameterRequestArgs = {id: number};
export const deleteDrillDownParameterRequest = (
    args: DeleteDrillDownParameterRequestArgs,
) => performRequest<RpcDeleteResponse>({
    url: [ddParametersEndpoint, args.id].join('/'),
    method: 'DELETE',
});
