import {Form, Input} from 'antd';
import React from 'react';

import {CustomSelect, getCustomSelectDefaultPreset} from 'components/form/inputs/custom-select';
import {DateCell} from 'components/table/columns/date-cell/date-cell';
import {DATE, DATE_DMY} from 'shared/constants/date-format';

interface AisDocumentsParametersFilterProps {
    isFilterApplied?: boolean;
}

export const AisDocumentsByTaxParametersFilter = (
    {isFilterApplied}: AisDocumentsParametersFilterProps,
) => (
    <>

        <Form.Item
            label="Код налога"
            name="taxCode"
            required
            rules={[{required: true, message: 'Пожалуйста, выберите код налога'}]}
        >
            <CustomSelect
                settings={{
                    ...getCustomSelectDefaultPreset(
                        '/valueLists/SVC_TAX',
                    ),
                    placeholder: 'Выберите код налога',
                    isClearable: true,
                    isDisabled: isFilterApplied,
                }}
            />
        </Form.Item>

        <Form.Item
            label="КНД декларации"
            name="kndCode"
            required
            rules={[{required: true}]}
            style={{width: 200}}
        >
            <CustomSelect settings={{
                ...getCustomSelectDefaultPreset('/valueLists/KND_CODES'),
                placeholder: 'Выберите код декларации',
                isClearable: true,
                isDisabled: isFilterApplied,
            }}
            />
        </Form.Item>

        <Form.Item
            label="Код налогового (отчётного) периода"
            name="taxPeriod"
            required
            rules={[{required: true}]}
        >
            <CustomSelect settings={{
                ...getCustomSelectDefaultPreset(
                    '/valueLists/SVC2_PERIOD_CODES',
                ),
                placeholder: 'Выберите код налогового периода',
                isClearable: true,
                isDisabled: isFilterApplied,
            }}
            />
        </Form.Item>

        <Form.Item
            name="corrNum"
            label="Номер корректировки декларации"
            required
            rules={[{required: true}]}
        >
            <Input
                placeholder="Введите номер корректировки"
                disabled={isFilterApplied}
            />
        </Form.Item>

        <Form.Item
            label="Отчётный год декларации"
            name="reportingYear"
            required
            rules={[{required: true}]}
        >
            <CustomSelect
                entries={Array(30).fill(0).map((v, i) => {
                    const year = new Date().getFullYear() - i;
                    return {
                        label: year,
                        value: year,
                    };
                })}
                settings={{
                    placeholder: 'Выберите отчётный год',
                    isClearable: true,
                    showSearch: true,
                    isDisabled: isFilterApplied,
                }}
            />
        </Form.Item>

        <Form.Item
            name="aisDocId"
            label="Уникальный идентификатор документа"
        >
            <Input
                placeholder="Введите идентификатор"
                disabled={isFilterApplied}
            />
        </Form.Item>

        <Form.Item
            label="Номер раздела декларации"
            name="section"
        >
            <Input
                placeholder="Введите номер раздела"
                disabled={isFilterApplied}
            />
        </Form.Item>

        <Form.Item
            label="Номер строки декларации"
            name="subsection"
        >
            <Input
                placeholder="Введите номер строки"
                disabled={isFilterApplied}
            />
        </Form.Item>

        <Form.Item
            label="Признак наличия электронного образа"
            name="contentAvailability"
        >
            <CustomSelect settings={{placeholder: 'Выберите признак', isDisabled: isFilterApplied}} />
        </Form.Item>

        <Form.Item
            label="Код вида документа по SPVDOC"
            name="docCode"
        >
            <CustomSelect settings={{placeholder: 'Выберите код', isDisabled: isFilterApplied}} />
        </Form.Item>

        <Form.Item
            label="Наименование документа"
            name="docName"
        >
            <Input
                placeholder="Введите наименование"
                disabled={isFilterApplied}
            />
        </Form.Item>

        <Form.Item
            label="Дата документа"
            name="docDate"
            data-type="date" // для css
        >
            <DateCell
                disabled={isFilterApplied}
                format={DATE_DMY}
                requestFormat={DATE}
            />
        </Form.Item>

        <Form.Item
            label="Сумма по документу всего, руб"
            name="docSumGross"
        >
            <Input
                placeholder="Введите сумму"
                disabled={isFilterApplied}
            />
        </Form.Item>

        <Form.Item
            label="Сумма НДС документу всего, руб"
            name="docTaxSum"
        >
            <Input
                placeholder="Введите сумму"
                disabled={isFilterApplied}
            />
        </Form.Item>

        <Form.Item
            label="ИНН контрагента"
            name="cpartyInn"
        >
            <Input
                placeholder="Введите ИНН"
                disabled={isFilterApplied}
            />
        </Form.Item>

        <Form.Item
            label="КПП организации-контрагента"
            name="cpartyInn"
        >
            <Input
                placeholder="Введите КПП"
                disabled={isFilterApplied}
            />
        </Form.Item>

        <Form.Item
            label="Наименование (ФИО) контрагента"
            name="cpartyName"
        >
            <Input
                placeholder="Введите наименование"
                disabled={isFilterApplied}
            />
        </Form.Item>

        <Form.Item
            label="Регистрационный номер контрагента в стране регистрации"
            name="cpartyForeignRegNum"
        >
            <Input
                placeholder="Введите регистрационный номер"
                disabled={isFilterApplied}
            />
        </Form.Item>

        <Form.Item
            label="Код страны регистрации контрагента-нерезидента"
            name="cpartyCountryCode"
        >
            <Input
                placeholder="Введите код"
                disabled={isFilterApplied}
            />
        </Form.Item>

        <Form.Item
            label="Код вида документа-основания по справочнику документов SPVDOC"
            name="baseDocCode"
        >
            <Input
                placeholder="Введите код"
                disabled={isFilterApplied}
            />
        </Form.Item>

        <Form.Item
            label="Наименование документа-основания"
            name="baseDocName"
        >
            <Input
                placeholder="Введите наименование документа"
                disabled={isFilterApplied}
            />
        </Form.Item>

        <Form.Item
            label="Номер документа-основания"
            name="baseDocNumber"
        >
            <Input
                placeholder="Введите номер документа"
                disabled={isFilterApplied}
            />
        </Form.Item>

        <Form.Item
            label="Дата документа-основания"
            name="baseDocDate"
            data-type="date"
        >
            <DateCell
                disabled={isFilterApplied}
                requestFormat={DATE}
                format={DATE_DMY}
            />
        </Form.Item>

        <Form.Item /> {/* для разметки */}
    </>
);
