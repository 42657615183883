import React, {ReactNode} from 'react';

import {FormEntityMeta} from 'modules/metadata/metadata-types';
import {selectWarningVisibilitySelector, setWarningVisibility} from 'modules/warning';
import {resolveModalDirtiness} from 'modules/warning/warning-actions';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {ModalComponentInterface} from 'store/slices/modals-slice';

import {ConfirmModal} from '../confirm-modal/confirm-modal';

const WARNING_MESSAGE = 'У вас есть несохраненные изменения, при закрытии формы изменения не будут сохранены.\n'
    + 'Закрыть форму?';

interface FormCloseWarningWrapperProps extends ModalComponentInterface {
    entityName: string;
    metadata: FormEntityMeta;
    children: (handleClose: () => void, visible: boolean) => ReactNode;
}

/**
 * Добавляет в форму модальное окно с предупреждением если форма dirty (ее редактировали) при закрытии формы
 */
export const FormCloseWarning: React.FunctionComponent<FormCloseWarningWrapperProps> = ({
    entityName,
    onClose,
    children,
    metadata,
}: FormCloseWarningWrapperProps) => {
    const dispatch = useAppDispatch();
    const warningVisible = useAppSelector(state => selectWarningVisibilitySelector(entityName)(state));
    const handleClose = () => {
        dispatch(resolveModalDirtiness(entityName, metadata, onClose));
    };

    const handleCloseCancel = () => {
        dispatch(setWarningVisibility({entityName, warningVisible: false}));
    };

    const handleWarningClose = () => {
        dispatch(setWarningVisibility({entityName, warningVisible: false}));
        onClose();
    };

    return (
        <>
            {children(handleClose, !warningVisible)}
            <ConfirmModal
                title="Предупреждение"
                visible={warningVisible || false}
                content={WARNING_MESSAGE}
                onCancel={handleWarningClose}
                cancelText="Закрыть форму"
                onConfirm={handleCloseCancel}
                approveText="Вернуться к редактированию"
            />
        </>
    );
};
