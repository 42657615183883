import {Form} from 'antd';
import {FormInstance} from 'antd/lib/form';
import React from 'react';

import {ReportConfigurationFormName} from 'components/report-configuration/report-configuration.constants';

import {TableHeaderStructure} from '../excel-header-structure-loader-modal.types';
import {HeaderTreeMapperNode} from './header-tree-mapper-node/header-tree-mapper-node';

interface HeaderTreeMapperProps {
    form: FormInstance;
    tree: TableHeaderStructure | undefined;
}

export const HeaderTreeMapper = ({form, tree}: HeaderTreeMapperProps) => (
    <div className="header-tree-mapper">
        <Form
            className="header-tree-mapper__form"
            form={form}
            name={ReportConfigurationFormName.EXCEL_TABLE_HEADER_STRUCTURE_LOADER}
        >
            {tree?.map(node => (
                <HeaderTreeMapperNode
                    key={node.key}
                    node={node}
                />
            ))}
        </Form>
    </div>
);
