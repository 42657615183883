import {createReducer} from 'store/config/creators';
import {Action} from 'store/config/types';

import {RegionsActions} from './regions-constants';
import {RegionResponse, RegionsState} from './regions-types';

const regionsInitialState: RegionsState = {};

export const regionsReducer = createReducer(regionsInitialState, {
    [RegionsActions.SET_REGIONS]: (state: RegionsState, {payload: data}: Action<RegionResponse[]>) => ({
        data,
    }),
});
