import cn from 'classnames';
import React, {CSSProperties} from 'react';

import {DashboardEntry} from 'modules/data/data-types';

import {WidgetWrapper} from '../../components/widget-wrapper';
import {useStatisticsInitialization} from '../../hooks/use-statistics-initialization';

import './bar-statistics.less';

interface BarStatisticsProps {
    entries: DashboardEntry;
    title?: string;
    label?: string;
    settings?: {
        gridRowsLimit?: number;
        noWrapDescriptions?: boolean;
        styles?: CSSProperties;
    };
}
export const BarStatistics: React.FC<BarStatisticsProps> = ({
    entries, settings = {}, title, label,
}: BarStatisticsProps) => {
    const {
        entriesTotal, statisticsData, statisticsLength,
    } = useStatisticsInitialization({
        entries,
        settings: {lowercaseEntityKeys: false},
    });

    const {
        gridRowsLimit = 3,
        noWrapDescriptions = true,
        styles = {},
    } = settings;

    return (
        <WidgetWrapper title={title}>
            <div
                style={styles}
                className={cn('bar-statistics')}
            >
                <div className={cn('bar-statistics__up-bar')}>
                    <div className={cn('bar-statistics__up-bar__total')}>
                        {entries.recordsTotal || entriesTotal}
                    </div>
                    <div className={cn('bar-statistics__up-bar__label')}>
                        {label ?? 'всего'}
                    </div>
                </div>
                <div className={cn('bar-statistics__bar-wrapper')}>
                    {statisticsData.map(data => (
                        <div
                            key={data.key}
                            style={{width: `${data.percent}%`, backgroundColor: data.color}}
                            className={cn('bar-statistics__bar-wrapper__bar')}
                        />
                    ))}
                </div>

                <div
                    style={{
                        gridTemplateRows: `repeat(${statisticsLength >= gridRowsLimit
                            ? gridRowsLimit : statisticsLength}, ${noWrapDescriptions ? '28px' : 'auto'})`,
                    }}
                    className={cn('bar-statistics__description')}
                >
                    {statisticsData.map(data => (
                        <div
                            key={data.key}
                            className={cn('bar-statistics__description__block', {
                                'bar-statistics__description__block_no-wrap': noWrapDescriptions,
                            })}
                        >
                            <div
                                style={{backgroundColor: data.color}}
                                className={cn('bar-statistics__description__block__border')}
                            />
                            <span className={cn('bar-statistics__description__block__key', {
                                'bar-statistics__description__block__key_no-wrap': noWrapDescriptions,
                            })}
                            >
                                {data.key}
                            </span>
                            <span className={cn('bar-statistics__description__block__value')}>{data.value}</span>
                        </div>
                    ))}
                </div>
            </div>
        </WidgetWrapper>
    );
};
