import {FormInstance} from 'antd/es/form';
import React from 'react';

export type TFormsFormTable = {
    form: FormInstance|null;
}

const FormsFormTableInitState = {
    form: null,
};

export const FormsFormTableContext = React.createContext<TFormsFormTable>(FormsFormTableInitState);
