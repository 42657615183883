import {
    TableColumnFilterExpessionGroupIdentificator,
    TableColumnFilterExpression,
    TableColumnFilterExpressionDto,
    TableColumnFilterOperand,
    TableColumnFilterOperandObject,
} from '../table-column-filter-types';
import {TableColumnFilters} from './use-table-column-filter-types';

type TransformFilterExpressionToFilterDtoFunction = (
    exp: TableColumnFilterExpression) => TableColumnFilterExpressionDto;
export const transformFilterExpressionToFilterDto:
    TransformFilterExpressionToFilterDtoFunction = (expression: TableColumnFilterExpression) => {
        const [identifier, ...operands] = expression;

        const expressionArrays = operands.filter(oper => Array.isArray(oper)) as TableColumnFilterExpression[];
        const rules = operands.filter(oper => !Array.isArray(oper)) as TableColumnFilterOperandObject[];

        const groups: TableColumnFilterExpressionDto[] = [];

        (expressionArrays ?? []).forEach(expArray => {
            groups.push(transformFilterExpressionToFilterDto(expArray));
        });

        return {
            connectBy: identifier.connectBy,
            groups,
            rules,
        };
    };

type TransformFilterDtoToFilterExpressionFunction = (
        dto: TableColumnFilterExpressionDto) => TableColumnFilterExpression;
export const transformFilterDtoToFilterExpression:
    TransformFilterDtoToFilterExpressionFunction = (dto: TableColumnFilterExpressionDto) => {
        const {connectBy, groups, rules} = dto;

        const identifier: TableColumnFilterExpessionGroupIdentificator = {
            connectBy,
        };

        const operands: TableColumnFilterOperand[] = rules?.length ? rules.map(
            rule => ({...rule}),
        ) as TableColumnFilterOperand[] : [];

        if (groups?.length) {
            groups.forEach(groupDto => {
                operands.push(transformFilterDtoToFilterExpression(groupDto));
            });
        }

        return [
            identifier,
            ...operands,
        ];
    };

export const transformFiltersFromRequestToColumnFiltersObject = (
    filtersFromRequest: TableColumnFilterExpressionDto,
) => {
    const {groups} = filtersFromRequest;

    const extractColumnNameFromExpression: (expGroups: TableColumnFilterExpression) => string | undefined = (
        expGroups: TableColumnFilterExpression,
    ) => {
        let name;
        const [, ...operands] = expGroups;
        operands.forEach(operand => {
            if (Array.isArray(operand)) name = extractColumnNameFromExpression(operand);
            else {
                const {columnName} = operand;
                name = columnName;
            }
        });
        return name;
    };

    const tableColumnFilters: TableColumnFilters = Object.fromEntries(groups.map(group => {
        const filterDto = transformFilterDtoToFilterExpression(group);
        return [
            extractColumnNameFromExpression(filterDto),
            filterDto,
        ];
    }).filter(([key]) => !!key));

    return tableColumnFilters;
};

export const adaptFiltersForRequest = (filters: TableColumnFilters | undefined) => {
    if (!filters) return undefined;

    const filtersTransformedToDto = transformFilterExpressionToFilterDto(
        Object.entries(filters).reduce((acc, [, filterValue]) => {
            if (Array.isArray(filterValue) && filterValue.length === 1) return acc;
            return [
                ...acc, filterValue,
            ] as TableColumnFilterExpression;
        }, [{connectBy: 'AND'}] as TableColumnFilterExpression),
    );

    if (!filtersTransformedToDto.rules.length && !filtersTransformedToDto.groups.length) return undefined;

    return filtersTransformedToDto;
};
