import {
    Checkbox,
    List,
    Modal,
} from 'antd';
import {CheckboxChangeEvent} from 'antd/es/checkbox';
import cn from 'classnames';
import React, {useEffect, useState} from 'react';

import {SimpleActionButton} from 'components/form/components';
import './columns-setting-button.less';
import {useAppSelector} from 'store/config/hooks';
import {selectTableReportCurrentColumns} from 'store/slices/table-report-slice/table-report-slice-selectors';

interface ColumnsSettingButtonProps {
    hiddenColumnsKeys?: string[];
    hideColumns?: (cols: string[]) => void;
}

export const ColumnsSettingButton = ({
    hiddenColumnsKeys,
    hideColumns,
}: ColumnsSettingButtonProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const columns = useAppSelector(s => selectTableReportCurrentColumns(s, []));

    const [initialVisibleColumns, setInitialVisibleColumns] = useState<string[]>([]);
    const [checked, setChecked] = useState<string[]>([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);

    useEffect(() => {
        if (isModalOpen) {
            const visibleColumnsKeys = columns
                ?.filter(col => !hiddenColumnsKeys?.includes(col?.dataIndex))
                ?.map(filteredCol => filteredCol?.dataIndex);
            if (visibleColumnsKeys) {
                setChecked(visibleColumnsKeys);
                setInitialVisibleColumns(visibleColumnsKeys);
            }
        }
    }, [isModalOpen]);

    useEffect(() => {
        setIndeterminate(!!(checked?.length && checked.length !== columns?.length));
        setCheckAll(checked?.length === columns?.length);
    }, [checked]);

    const handleCheckAllChange = (e: CheckboxChangeEvent) => {
        const colsList = columns?.map(col => col?.dataIndex) ?? [];
        setChecked(e.target.checked ? colsList : []);
        setCheckAll(e.target.checked);
    };

    const handleSubmitClick = () => {
        const columnsToHide = columns
            ?.filter(col => !checked?.includes(col?.dataIndex))
            ?.map(filteredCol => filteredCol?.dataIndex);
        if (columnsToHide) {
            hideColumns?.(columnsToHide);
        }
        setIsModalOpen(false);
    };

    return (
        <>
            <SimpleActionButton
                key={1}
                type="default"
                onClick={() => {
                    setIsModalOpen(v => !v);
                }}
                title="Настройка полей"
                icon="SettingsGear"
            />
            <Modal
                key={2}
                className="columns-setting-modal"
                title="Выбор полей для отображения"
                visible={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                width={980}
                footer={null}
            >
                <div className="columns-setting-modal__content">
                    <Checkbox
                        className={
                            cn('columns-list__row', 'check-all', {checked: checked?.length || indeterminate})
                        }
                        indeterminate={indeterminate}
                        onChange={handleCheckAllChange}
                        checked={checkAll}
                    >
                        Выбрать всё
                    </Checkbox>
                    <Checkbox.Group
                        style={{width: '100%'}}
                        value={checked}
                        onChange={checkedValues => setChecked(checkedValues as string[])}
                    >
                        <List
                            className={cn('columns-list', {overflow: columns?.length && columns.length > 10})}
                            itemLayout="horizontal"
                            dataSource={columns}
                            renderItem={item => (
                                <List.Item
                                    className={
                                        cn('columns-list__row', {checked: checked?.includes(item?.dataIndex)})
                                    }
                                >
                                    <Checkbox value={item?.dataIndex}>
                                        {item?.title}
                                    </Checkbox>
                                </List.Item>
                            )}
                        />
                    </Checkbox.Group>
                </div>
                <div className="columns-setting-modal__actions">
                    <SimpleActionButton
                        key={3}
                        type="primary"
                        onClick={handleSubmitClick}
                        title="Применить"
                    />
                    <SimpleActionButton
                        key={4}
                        type="default"
                        onClick={() => setChecked(initialVisibleColumns)}
                        title="Сбросить выбор"
                    />
                </div>
            </Modal>
        </>
    );
};
