export const ENTRIES_LABEL: Record<string, string> = {
    Y: 'Да',
    N: 'Нет',
    manual: 'Ручной',
    automatic: 'Автоматический',
    turn_on: 'Включить отправку уведомлений',
    turn_of: 'Выключить отправку уведомлений',
    122: 'ИС НП доступна для запросов',
    123: 'ИС НП недоступна для запросов',
};

export const PROPERTY_WIDTH:Record<string, number> = {
    NEWS_BODY_TEXT: 1356,
};

export const ADDITIONAL_FIELD_PROPERTIES: Record<string, any> = {
    TYPE_PERIOD_FOR_LOADING: {additionalEntries: [{value: null, label: 'Не выбрано'}]},
};

export enum PropertyType {
    SELECT = 'SELECT',
    MULTIPLE_SELECT = 'MULTIPLE_SELECT',
    PASSWORD = 'PASSWORD',
    CLOCK = 'CLOCK',
    DATE = 'DATE',
    MAP_SELECT = 'MAP_SELECT',
}
