import {unwrapResult} from '@reduxjs/toolkit';
import {Form as AntForm} from 'antd';
import cn from 'classnames';
import React, {useEffect, useState} from 'react';

import {dispatchFormWatcherEvent} from 'components/form/hooks/use-form-watcher';
import {
    SignAndFileListUploader,
} from 'components/form/inputs/uploader-list/sign-and-file-list-uploader/sign-and-file-list-uploader';
import {RequestCardContext} from 'components/request/replies/context/request-card-context';
import {RequestFormFields} from 'components/request/request-form/components/request-from-fields/request-form-fields';
import {StatusModelAwareActions} from 'components/request/status-model-aware-actions/status-model-aware-actions';
import {convertRequestData} from 'components/request/utils/request-api-utils';
import {fetchPermissionDisplayFileComponent} from 'modules/data/data-api';
import {normalizeFormDataForRequest} from 'modules/data/utils/data.utils';
import {
    loadMetadata, selectMetadata,
} from 'modules/metadata';
import {EntityType} from 'shared/constants/entities';
import {CommonMessages, ErrorMessages} from 'shared/constants/messages';
import {
    URL_REQUESTS,
    URL_REQUESTS_REQUIREMENT_PROLONGATION_MESSAGE_UPDATE,
} from 'shared/constants/urls';
import {FileDto} from 'shared/types/files';
import {showMessage} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {selectRequestData} from 'store/slices/request-slice/request-selectors';
import {requestSliceActions} from 'store/slices/request-slice/request-slice';
import {
    loadRequestData,
    loadRequestStatusesData,
    saveRequestData,
} from 'store/slices/request-slice/request-slice-thunks';

import {REQUESTS_DISPLAY_FILES_URL} from '../../../constants/requests.constants';
import {requestMainFields} from '../../request-form.constants';

import './response-request.less';

interface ResponseRequestProps {
    entityName: string;
    data?: any;
    requestFormKey: string;
    requestCommentId?: string;
    isRequestAdditionalPlacementType?: boolean;
    requestType?: string;
    onClose?: () => void;
}

export const ResponseRequest: React.FC<ResponseRequestProps> = ({
    entityName,
    data,
    onClose,
    requestFormKey,
    requestCommentId,
    isRequestAdditionalPlacementType,
    requestType,
}:ResponseRequestProps) => {
    const dispatch = useAppDispatch();
    const [isEditingForm, setEditingForm] = useState(false);
    const [fileList, setFileList] = useState<FileDto[] | undefined>(data?.attachments);
    const [hasPermissionDisplayFileComponent, setPermissionDisplayFileComponent] = useState(false);
    const [messageCollapseOpen, setMessageCollapseOpen] = useState(false);
    const requestData = useAppSelector(selectRequestData);
    const metadata = useAppSelector(selectMetadata(entityName, EntityType.FORM));
    const requestId = requestData?.requests?.id;
    const requestTypeId = requestData?.requests?.topicRequestType?.id;
    const [form] = AntForm.useForm();
    const attachmentList = !!data?.attachments?.length;
    const formData = convertRequestData(requestData, data);
    const entityClassName = entityName?.split('.').join('-');
    const {resetRequestStatusesData} = requestSliceActions;
    const hasDisplayFileListUploader = hasPermissionDisplayFileComponent && (
        !isRequestAdditionalPlacementType && (attachmentList || isEditingForm)
    );
    const formClassNames = cn(
        'form-body',
        {
            [String(entityClassName)]: entityName,
        },
    );

    useEffect(() => {
        form.setFieldsValue({...data, ...formData});
        setFileList(data?.attachments);
    }, [form, data]);

    useEffect(() => {
        const checkPermissionDisplayFileComponent = async () => {
            const hasPermissionDisplay = await fetchPermissionDisplayFileComponent(
                REQUESTS_DISPLAY_FILES_URL,
                requestTypeId,
            );
            setPermissionDisplayFileComponent(hasPermissionDisplay?.data);
        };
        checkPermissionDisplayFileComponent().catch(console.error);
    }, []);

    useEffect(() => {
        (async () => {
            if (!metadata && data) {
                await dispatch(loadMetadata(entityName, EntityType.FORM));
            }
        })();
    }, [metadata, data]);
    const handleFinish = (values: any) => {
        if (requestCommentId && requestId) {
            form.validateFields().then(() => {
                dispatch(saveRequestData({
                    commentId: requestCommentId,
                    url: URL_REQUESTS_REQUIREMENT_PROLONGATION_MESSAGE_UPDATE,
                    data: normalizeFormDataForRequest(values, [...metadata?.fields, ...requestMainFields]),
                })).then(unwrapResult).then(() => {
                    form.resetFields();
                    setEditingForm(!isEditingForm);
                    showMessage({message: CommonMessages.SAVE_SUCCESS});
                    dispatch(resetRequestStatusesData);
                    dispatch(loadRequestStatusesData({commentId: requestCommentId, requestFormKey}));
                    dispatch(loadRequestData({id: requestId, url: URL_REQUESTS}));
                }, error => {
                    showMessage({
                        message: error?.response?.data ?? ErrorMessages.UNEXPECTED_ERROR_OCCURRED,
                        isError: true,
                    });
                });
            });
        }
    };

    return (
        <>
            <div className="request-form-container__actions">
                <RequestCardContext.Provider
                    value={{
                        text: messageCollapseOpen ? 'Свернуть всю переписку' : 'Развернуть всю переписку',
                        isEditing: isEditingForm || false,
                        handleCollapse: () => setMessageCollapseOpen(v => !v),
                        setEditing: () => setEditingForm(v => !v),
                    }}
                >
                    <StatusModelAwareActions
                        requestFormKey={requestFormKey}
                        requestCommentId={requestCommentId}
                        onClose={onClose}
                        requestType={requestType}
                        entityName={entityName}
                        onSubmit={() => {
                            form.submit();
                        }}
                        requestInfo={requestData?.requests}
                    />
                </RequestCardContext.Provider>
            </div>
            {
                data && requestCommentId && (
                    <AntForm
                        layout="vertical"
                        form={form}
                        className="form"
                        onFinish={handleFinish}
                        onValuesChange={(_, values: any) => {
                            dispatchFormWatcherEvent({values, targetForm: form});
                        }}
                    >
                        <div className={formClassNames}>
                            <RequestFormFields
                                isEditable={isEditingForm}
                                entityName={entityName}
                                data={formData}
                                fields={metadata?.fields}
                                form={form}

                            />
                            <AntForm.Item
                                name="files"
                                style={{width: '100%'}}
                            >
                                {hasDisplayFileListUploader && (
                                    <SignAndFileListUploader
                                        disabled={!isEditingForm}
                                        fileList={fileList}
                                        onChange={setFileList}
                                        requestCommentId={requestCommentId}
                                        dataKeyForDownload="id"
                                        url={URL_REQUESTS}
                                        signUrl={URL_REQUESTS}
                                        isDownloadDocumentList={!!data?.attachments?.length}
                                    />
                                )}
                            </AntForm.Item>
                        </div>
                    </AntForm>
                )
            }
        </>
    );
};
