import cn from 'classnames';
import React from 'react';

import {SvcLayout} from 'modules/metadata/metadata-types';

import {DictionaryLayoutItem} from './dictionary-layout-item';

interface WidgetLayoutRowProps {
    dictionaryLayout?: Array<SvcLayout>;
}

export const DictionaryLayoutRow: React.FC<WidgetLayoutRowProps> = ({dictionaryLayout}: WidgetLayoutRowProps) => (
    <div className={cn('svc-main-page__widget-row', 'widget-directories')}>
        {dictionaryLayout?.map(dictionary => (
            <DictionaryLayoutItem
                dictionary={dictionary}
            />
        ))}
    </div>
);
