import {Form} from 'antd';
import React, {useContext, useEffect} from 'react';

import {FormsFileModalContext} from 'components/documents/file-modal/file-modal.context';
import {EditableFormTableContext} from 'components/editable-table-modal/editable-form-table-data';

import {EditableTableContext} from '../../editable-table-context';

interface EditableRowProps {
    index: number;
}

export const EditableRow: React.FunctionComponent<EditableRowProps> = ({
    index,
    ...props
}: EditableRowProps) => {
    const [form] = Form.useForm();

    const {setForms, forms} = useContext(FormsFileModalContext);
    const fileModalContext = useContext(FormsFileModalContext);
    const editableFormTableContext = useContext(EditableFormTableContext);

    useEffect(() => {
        if (fileModalContext) setForms?.(prevForms => [...prevForms, form]);
        if (editableFormTableContext) editableFormTableContext?.setForm?.(prevForms => [...prevForms, form]);

        return () => {
            if (editableFormTableContext?.form) editableFormTableContext?.setForm?.([]);

            if (fileModalContext?.forms) fileModalContext?.setForms?.([]);
            if (!forms.length) return;
            const newForms = forms.filter(formsItem => formsItem !== form);
            setForms?.(newForms);
        };
    }, [form]);

    return (
        <Form
            key={index}
            form={form}
            component={false}
            className="form"
        >
            <EditableTableContext.Provider value={form}>
                <tr {...props} />
            </EditableTableContext.Provider>
        </Form>
    );
};
