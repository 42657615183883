import {Spin} from 'antd';
import React from 'react';

import './custom-select-spinner.less';

interface CustomSelectSpinnerProps {}

export const CustomSelectSpinner: React.FC<CustomSelectSpinnerProps> = () => (
    <div
        className="custom-select-spinner"
    >
        <Spin
            className="custom-select-spinner__spin"
            size="small"
        />
        <div
            className="custom-select-spinner__label"
        >
            Загрузка...
        </div>
    </div>
);
