import {Button} from 'antd';
import React from 'react';
import {useDispatch} from 'react-redux';

import {EditableFormTableContext} from 'components/editable-table-modal/editable-form-table-data';
import {resetData, saveEditableData} from 'modules/data/data-actions';
import {RequestType} from 'modules/metadata';
import {EntityType} from 'shared/constants/entities';

import {TableActionProps} from '../table-action-types';

import './button-action-save-editable-modal.less';

export const ButtonActionSaveEditableModal: React.FunctionComponent<TableActionProps> = ({
    meta,
    entityName,
    onClose,
    ...props
}: TableActionProps) => {
    const dispatch = useDispatch();
    const {initData, form} = React.useContext(EditableFormTableContext);

    const handleClick = async () => {
        await Promise.all(form.map(item => item.validateFields())).then(() => {
            if (form && initData) {
                initData.forEach(item => {
                    dispatch(saveEditableData(
                        entityName,
                        EntityType.EDITABLE_TABLE,
                        RequestType.PUT,
                        item,
                        true,
                        meta.referenceUrl,
                    ));
                });
                dispatch(resetData({entityName, entityType: EntityType.EDITABLE_TABLE, loading: false}));
                if (onClose) {
                    onClose();
                }
            }
        });
    };

    return (
        <Button
            className="button-save-editable"
            {...props}
            onClick={handleClick}
        >
            {meta.actionTitle}
        </Button>
    );
};
