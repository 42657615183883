import {FormInstance} from 'antd/es/form';
import {get} from 'lodash';

import {ReferenceParamKey} from 'modules/metadata/metadata-types';

export const getReferenceURLQueryParamsFromForm = (form?: FormInstance, referenceParamsKeys?: ReferenceParamKey[]) => {
    const result: Record<string, string> = {};
    referenceParamsKeys?.forEach(({from, to}) => {
        const value = get(form?.getFieldsValue(), from);
        if (to && value) result[to] = value;
    });
    return result;
};
