import {Button} from 'antd';
import {FormInstance} from 'antd/es/form';
import React, {useState} from 'react';

import {DynamicIcon} from 'components/dynamic-icon';
import {ModalOpenerComponent} from 'components/modal-opener-component';
import {useAppHistory} from 'shared/hooks/use-app-history';
import {StateSetter} from 'shared/types/generics';

import './directories-company-form-header.less';

interface DirectoriesCompanyFormHeaderProps {
    form: FormInstance;
    isDirty: boolean;
    setDirty: StateSetter<boolean>;
}

export const DirectoriesCompanyFormHeader = ({form, isDirty, setDirty}: DirectoriesCompanyFormHeaderProps) => {
    const history = useAppHistory();
    const [isCancelModalOpen, setCancelModalOpen] = useState<boolean>(false);
    const [isBackModalOpen, setBackModalOpen] = useState<boolean>(false);

    const handleResetChanges = () => {
        form.resetFields();
        setDirty(false);
        setCancelModalOpen(false);
    };

    const handleBack = () => {
        if (!isDirty) {
            history.go(-1);
            return;
        }
        if (!isBackModalOpen) {
            setBackModalOpen(true);
        } else {
            setBackModalOpen(false);
            history.go(-1);
        }
    };

    const handleSubmit = () => {
        form.validateFields()
            .then(() => form.submit());
    };

    return (
        <div className="directories-company-form-header">
            <div className="directories-company-form-header-title">
                Параметры организации
            </div>
            <div className="directories-company-form-header-buttons">
                <ModalOpenerComponent
                    component={(
                        <Button
                            type="default"
                            onClick={handleBack}
                        >
                            <DynamicIcon type="ArrowBackOutlined" />
                            Вернуться назад
                        </Button>
                    )}
                    isOpen={isBackModalOpen}
                    handleSave={handleBack}
                    handleCancel={() => setBackModalOpen(false)}
                    modalProps={{
                        title: 'Предупреждение',
                        centered: true,
                        width: '680px',
                    }}
                    controlLabels={{
                        save: 'Да',
                        cancel: 'Нет',
                    }}
                >
                    <div className="text-center">
                        Вы действительно хотите покинуть форму?
                        <br />Несохраненные данные будут потеряны.
                    </div>
                </ModalOpenerComponent>
                <Button
                    type="primary"
                    onClick={handleSubmit}
                >
                    <DynamicIcon type="SaveOutlined" />
                    Сохранить
                </Button>
                <ModalOpenerComponent
                    component={(
                        <Button
                            className="button-critic"
                            type="default"
                            onClick={() => setCancelModalOpen(true)}
                            disabled={!isDirty}
                        >
                            <DynamicIcon type="CloseXOutlined" />
                            Отменить изменения
                        </Button>
                    )}
                    isOpen={isCancelModalOpen}
                    handleSave={handleResetChanges}
                    handleCancel={() => setCancelModalOpen(false)}
                    modalProps={{
                        title: 'Предупреждение',
                        centered: true,
                        width: '680px',
                    }}
                    controlLabels={{
                        save: 'Да',
                        cancel: 'Нет',
                    }}
                >
                    <div className="text-center">
                        Вы действительно хотите отменить изменения?
                        <br />Внесенные данные будут потеряны.
                    </div>
                </ModalOpenerComponent>
            </div>
        </div>
    );
};
