import {
    PayloadAction,
    createSlice,
} from '@reduxjs/toolkit';

import {userSliceName} from './user-slice-constants';
import {loadUserFunctions, loadUserFunctionsByDocId} from './user-slice-thunks';
import {UserSliceState} from './user-slice-types';

export const userSlice = createSlice({
    initialState: {
        functions: {},
        loading: {},
    } as UserSliceState,
    name: userSliceName,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(loadUserFunctions.fulfilled, (state, {payload, meta}) => {
            state.functions[meta.arg.section] = payload;
        });

        builder.addCase(loadUserFunctionsByDocId.fulfilled, (state, {payload, meta}) => {
            state.functions[`${meta.arg.tableReportDocId}${meta.arg.templateCode}`] = payload;
        });

        builder.addMatcher((action: PayloadAction<any, any, any>) => {
            if (typeof action?.type === 'string' && action.type.startsWith(userSliceName)) {
                return true;
            }
            return false;
        }, (state, action: PayloadAction<any, any, any>) => {
            const {type, meta} = action ?? {};
            const typeSplit = type.split('/');
            const [
                sliceName,
                thunkName,
            ] = [typeSplit?.[0], typeSplit?.[1]];

            if (`${sliceName}/${thunkName}` === loadUserFunctions.typePrefix) {
                const {section} = meta.arg;
                const {requestStatus} = meta;
                state.loading[section] = requestStatus === 'pending';
            }
        });
    },
});

export const {
    reducer: userSliceReducer,
    actions: userSliceActions,
} = userSlice;
