import {useEffect} from 'react';

import {cachedThunk} from 'shared/cache';
import {ThunkCacheTag} from 'shared/cache/thunk-cache';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';
import {UserRoleFunction} from 'store/slices/user-slice/user-slice-role-functions';
import {selectUserFunctionsByDocId} from 'store/slices/user-slice/user-slice-selectors';
import {loadUserFunctionsByDocId} from 'store/slices/user-slice/user-slice-thunks';

interface UseUserRoleFunctionsByDocIdArgs {
    tableReportDocId: string | null;
    templateCode: string | null;
}

export const useUserRoleFunctionsByDocId = ({
    tableReportDocId,
    templateCode,
}: UseUserRoleFunctionsByDocIdArgs) => {
    const dispatch = useAppDispatch();

    const isLoading = useAppSelector(
        s => selectIsThunkPending(s, loadUserFunctionsByDocId.typePrefix),
    );
    const userFunctions = useAppSelector(s => selectUserFunctionsByDocId(s, {tableReportDocId, templateCode}));

    useEffect(() => {
        if (tableReportDocId !== null && templateCode !== null) {
            dispatch(cachedThunk(loadUserFunctionsByDocId, {
                tableReportDocId,
                templateCode,
            }, {expire: 'never', providesTags: [ThunkCacheTag.USER_FUNCTIONS]}));
        }
    }, []);

    const hasUserRoleFunction = (func: UserRoleFunction) => !!userFunctions?.includes(func);

    return {
        userFunctions,
        isLoading,
        hasUserRoleFunction,
    };
};
