import {performRequest, RequestPromise} from 'shared/utils';

import {ContextRawData} from './context-types';

export const fetchContextData = (): RequestPromise<ContextRawData> => performRequest(
    {
        url: '/context',
    },
);

export const fetchContextLookupValues = ({
    lookupType,
    id,
}: {lookupType: string; id?: string | number}) => performRequest(
    {
        url: ['/lookupValue', lookupType, id].filter(v => !!v).join('/'),
    },
);

export const postContextData = (
    data?: Record<string, any>,
): RequestPromise<ContextRawData> => performRequest({
    url: '/context',
    method: 'POST',
    data,
});
