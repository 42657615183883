import {
    Button, Input, Upload,
} from 'antd';
import {FormInstance} from 'antd/es/form';
import {RcFile, UploadFile} from 'antd/es/upload/interface';
import React from 'react';
import {useDispatch} from 'react-redux';

import {loadAdditionalDataFile} from 'modules/data/data-actions';
import {FormEntityData} from 'modules/data/data-types';
import {IAdditionalOptions} from 'modules/metadata/metadata-types';
import {ReactComponent as ClearIcon} from 'shared/assets/forms/delete-x.svg';
import {ReactComponent as FileIcon} from 'shared/assets/forms/file.svg';

import './file-field.less';

export interface FileFieldProps {
    onChange?: (value: any) => void;
    fieldKey: string;
    form?: FormInstance;
    formData?: FormEntityData;
    buttonText?: string;
    shouldFastUpdate?: boolean;
    accept?: string;
    dependentsFieldKey?: string;
    additionalOptions?: IAdditionalOptions;
    entityName: string;
}

/**
 * Поле реализовано на основе текущих нужд серверной части
 * fieldKey - обязательное поле с названием файла (отправляется всегда)
 *
 * fieldMeta.dependentsFieldKey - поле, в котором хранится сам файл
 * (не отправляется при редактировании, если не был изменен т.к. не приходит)
 */

export const FileField: React.FunctionComponent<FileFieldProps> = (
    {
        form,
        onChange,
        fieldKey,
        buttonText = 'Выбрать файл',
        shouldFastUpdate = false,
        accept,
        additionalOptions,
        entityName,
        dependentsFieldKey,
        ...props
    }: FileFieldProps,
) => {
    const dispatch = useDispatch();
    const dependentsFieldsKey = dependentsFieldKey || '';
    const filePathValue = form?.getFieldValue(fieldKey);
    const filesExists = Array.isArray(filePathValue) ? !!filePathValue.length : !!filePathValue;
    const showPath = !shouldFastUpdate && filesExists;
    const additionalReferenceUrl = additionalOptions?.optionsField?.referenceUrl;
    const setFileValue = (value: UploadFile<Blob> | null = null) => {
        form?.setFieldsValue({
            [dependentsFieldsKey]: value,
        });
    };

    const handleRemove = () => {
        setFileValue();
        if (onChange) onChange([]);
    };

    const beforeUpload = (file: UploadFile<Blob>) => {
        setFileValue(file);
        const formUploadData = new FormData();
        formUploadData.append('file', file as RcFile);
        if (additionalReferenceUrl) {
            dispatch(loadAdditionalDataFile(entityName, additionalReferenceUrl, formUploadData));
        }
        if (onChange) onChange([file.name]);
        return false;
    };
    return (
        <Upload
            {...props}
            accept={accept}
            disabled={!onChange}
            onRemove={handleRemove}
            beforeUpload={beforeUpload}
            showUploadList={false}
            className="upload-field"
        >
            <div className="upload-field-wrapper">
                <Input
                    disabled
                    prefix={<FileIcon />}
                    className="ant-input--soft-disabled"
                    suffix={showPath && (
                        <ClearIcon
                            className="upload-field__clear-icon"
                            onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
                                event.stopPropagation();
                                handleRemove();
                            }}
                        />
                    )}
                    value={showPath ? filePathValue : 'Файл не прикреплен'}
                />
                <Button className="upload-field__btn">
                    {buttonText}
                </Button>
            </div>
        </Upload>
    );
};
