import React from 'react';

import {TransportContainerCard} from 'components/ais/transport-container-card';
import {Table} from 'components/table';
import {TableScroll} from 'components/table/table';
import {EntityType} from 'shared/constants/entities';

export interface FileSharingCardProps {
    url: string;
    hideTitle?: boolean;
    entityType?: EntityType;
    entityName: string;
    scroll?: TableScroll;
    entity: string | null;
}

export const FileSharingCard = ({
    entity: id, scroll, url, hideTitle, entityType, entityName,
}: FileSharingCardProps) => (
    <>
        <Table
            hideTitle={hideTitle}
            entityName={entityName}
            url={url}
            entityType={entityType}
            scroll={scroll}
        />
        {id && (
            <TransportContainerCard
                id={id}
                entityName={entityName}
            />
        )}
    </>
);
