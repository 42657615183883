import get from 'lodash/get';
import React from 'react';

import {TIconType} from 'components/dynamic-icon/dynamic-icon';
import {selectEntityData} from 'modules/data';
import {FormEntityData} from 'modules/data/data-types';
import {EntityType} from 'shared/constants/entities';
import {useQueryParams} from 'shared/utils/query-params';
import {useAppSelector} from 'store/config/hooks';

import {SimpleActionButton} from '../../components';

export interface ButtonOpenModalUsingParamsProps {
    entityName?: string;
    modalEntityName?: string;
    title: string;
    icon?: TIconType;
    linkField?: string;
    modalName?: string;
}

export const ButtonOpenModalUsingParams = ({
    entityName,
    modalName,
    modalEntityName,
    linkField,
    title,
    icon,
    ...props
}: ButtonOpenModalUsingParamsProps) => {
    const {updateQueryParams} = useQueryParams();
    const dataSelector = selectEntityData(entityName || '', EntityType.FORM);
    const data = useAppSelector(state => dataSelector(state) as FormEntityData)?.data;
    const handleClick = () => {
        const entity = get(data, linkField!);
        if (entity && modalName && modalEntityName && entityName && !Number.isNaN(entity)) {
            updateQueryParams([
                {name: 'entity', value: entity.toString()},
                {name: 'modalName', value: modalName},
                {name: 'modalEntityName', value: modalEntityName}]);
        }
    };

    return (
        <SimpleActionButton
            type="primary"
            title={title}
            icon={icon}
            onClick={handleClick}
            {...props}
        />
    );
};
