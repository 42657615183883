import cn from 'classnames';
import React from 'react';

import {TableReportMenu} from 'store/slices/table-report-slice/table-report-slice-types';

import {AsideNode} from '../aside-node';

import './aside-dropdown.less';

interface AsideDropdownProps {
    parent: TableReportMenu;
    submenus: TableReportMenu[];
    depth: number;
    open: boolean;
    activeMenu: TableReportMenu | undefined;
    onRowClick: (menu: TableReportMenu) => void;
}

export const AsideDropdown = ({
    parent,
    submenus,
    depth,
    open,
    activeMenu,
    onRowClick,
}: AsideDropdownProps) => {
    const newDepth = depth + 1;

    return (
        <div
            className={`aside-dropdown${cn(open && ' open')}`}
        >
            {submenus.map(submenu => (
                <AsideNode
                    key={submenu.name}
                    menu={submenu}
                    name={submenu?.name || parent.name}
                    depth={newDepth}
                    activeMenu={activeMenu}
                    onRowClick={onRowClick}
                />
            ))}
        </div>
    );
};
