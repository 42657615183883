import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';

import {isEnumInstance} from 'shared/utils';
import {TableReportFormattedHeaders} from 'store/slices/table-report-slice/table-report-slice-types';

import {DesignTemplates} from '../../table-report-types';

export const GET_VALUE_FROM_MENU_INDICATOR = '#MENU';

const dataSensitiveDesignTemplates = [
    DesignTemplates.DECLARATION,
    DesignTemplates.REGISTERS,
    DesignTemplates.POSTING_CARD,
    DesignTemplates.REGISTER_WITH_ROW_HEADERS,
];

export const shouldRenderHeader = (
    designTemplate: string | undefined,
    headers: TableReportFormattedHeaders | undefined,
) => {
    // wrong designTemplate
    if (!designTemplate || !isEnumInstance(designTemplate, DesignTemplates)) {
        return false;
    }
    // some templates don`t need headers to render
    if (!dataSensitiveDesignTemplates?.includes(designTemplate as DesignTemplates)) {
        return true;
    }

    if (!headers) {
        return false;
    }

    return !isEmpty(omitBy(headers, isEmpty));
};
