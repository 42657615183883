export enum MimeTypes {
    JPEG = 'image/jpeg',
    MS_EXCEL = 'application/vnd.ms-excel',
    MS_EXCEL_OPEN_XML = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    MS_WORD = 'application/msword',
    MS_WORD_OPEN_XML = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    XML = 'application/xml',
    PDF = 'application/pdf',
    TIFF = 'image/tiff',
    RTF = 'application/rtf',
}
