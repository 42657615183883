import {useForm} from 'antd/es/form/Form';
import React, {useContext, useEffect, useState} from 'react';

import {SimpleActionButton} from 'components/form/components';
import {ModalOpenerComponent} from 'components/modal-opener-component';
import {
    ReportConfigurationFormName, reportConfigurationFormTitles,
} from 'components/report-configuration/report-configuration.constants';
import {ReportConfigurationContext} from 'components/report-configuration/report-configuration.context';
import {useTableReportSettingsSheets} from 'components/report-configuration/tabs/report-table-settings/hooks/use-table-report-settings-sheets';
import {HeaderPreview} from 'components/report-configuration/tabs/report-table-settings/modals/excel-header-structure-loader-modal/header-preview/header-preview';
import {joinReportTableDataToMenuConfigurationData} from 'components/report-configuration/tabs/report-table-settings/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {
    selectReportConfigurationMenuExcelTableHeaderStructureBySheetCode,
    selectTableReportConfigurationData,
} from 'store/slices/report-configuration-slice/report-configuration-slice';
import {
    updateTableReportConfiguration,
} from 'store/slices/report-configuration-slice/report-configuration/report-configuration-thunks';

import {stepIndex, StepTitle} from './excel-header-structure-loader-modal.constants';
import {
    DataSourcePreview,
    HeaderStructureFormData,
    PreviewTableData,
    TableHeaderStructure,
} from './excel-header-structure-loader-modal.types';
import {mapFormDataToTree} from './excel-header-structure-loader-modal.utils';
import {ExcelHeaderStructureLoader} from './excel-header-structure-loader/excel-header-structure-loader';

import './excel-header-structure-loader-modal.less';

interface ExcelHeaderStructureLoaderModalProps {
    disabled: boolean;
}

export const ExcelHeaderStructureLoaderModal = ({disabled}: ExcelHeaderStructureLoaderModalProps) => {
    const dispatch = useAppDispatch();

    const {templateCode} = useContext(ReportConfigurationContext);
    const [form] = useForm<HeaderStructureFormData>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCreatingNewStructure, setIsCreatingNewStructure] = useState(false);

    const [headerTree, setHeaderTree] = useState<TableHeaderStructure | undefined>();
    const [previewTableData, setPreviewTableData] = useState<PreviewTableData | undefined>();
    const [selectedHeaderRows, setSelectedHeaderRows] = useState<DataSourcePreview[] | undefined>();
    const [currentStep, setCurrentStep] = useState(stepIndex[StepTitle.FILE_LOADING]);

    const reportConfigurationData = useAppSelector(selectTableReportConfigurationData);

    const {
        sheets,
        setSelectedSheet,
        selectedSheet,
    } = useTableReportSettingsSheets();

    const excelHeaderDataForSelectedSheetCode = useAppSelector(
        s => selectReportConfigurationMenuExcelTableHeaderStructureBySheetCode(s, selectedSheet),
    );

    const initialExcelHeaderData = selectedSheet
        ? excelHeaderDataForSelectedSheetCode
        : reportConfigurationData?.[ReportConfigurationFormName.EXCEL_TABLE_HEADER_STRUCTURE_LOADER];

    const [initialValue, setInitialValue] = useState<any>(initialExcelHeaderData);

    useEffect(() => {
        if (reportConfigurationData) {
            setInitialValue(initialExcelHeaderData);
        }
    }, [reportConfigurationData, selectedSheet]);

    useEffect(() => {
        if (initialValue) {
            form.resetFields();
        }
    }, [initialValue]);

    const resetParsedData = () => {
        setPreviewTableData(undefined);
        setSelectedHeaderRows(undefined);
        setHeaderTree(undefined);
        setCurrentStep(stepIndex[StepTitle.FILE_LOADING]);
    };

    const handleSave = () => {
        const dataToSubmit = mapFormDataToTree(headerTree || initialValue, form.getFieldsValue());

        if (templateCode && dataToSubmit) {
            if (selectedSheet) {
                const reportMenuToBeUpdated = joinReportTableDataToMenuConfigurationData({
                    dataToJoin: {
                        excelTableHeaderStructure: dataToSubmit,
                    },
                    reportMenu: reportConfigurationData?.reportMenu,
                    sheetCode: selectedSheet,
                });
                dispatch(updateTableReportConfiguration({
                    templateCode,
                    data: {
                        reportMenu: reportMenuToBeUpdated,
                    },
                }));
            } else {
                dispatch(updateTableReportConfiguration({
                    templateCode,
                    data: {[ReportConfigurationFormName.EXCEL_TABLE_HEADER_STRUCTURE_LOADER]: dataToSubmit},
                }));
            }
        }
        resetParsedData();
        setIsCreatingNewStructure(false);
        setIsModalOpen(false);
    };

    const handleReset = () => {
        resetParsedData();
        setIsCreatingNewStructure(true);
    };

    const handleDelete = () => {
        handleReset();

        if (templateCode) {
            if (selectedSheet) {
                const reportMenuToBeUpdated = joinReportTableDataToMenuConfigurationData({
                    dataToJoin: {
                        excelTableHeaderStructure: undefined,
                    },
                    reportMenu: reportConfigurationData?.reportMenu,
                    sheetCode: selectedSheet,
                });
                dispatch(updateTableReportConfiguration({
                    templateCode,
                    data: {
                        reportMenu: reportMenuToBeUpdated,
                    },
                }));
            } else {
                dispatch(updateTableReportConfiguration({
                    templateCode,
                    data: {[ReportConfigurationFormName.EXCEL_TABLE_HEADER_STRUCTURE_LOADER]: []},
                }));
            }
        }
        setIsCreatingNewStructure(false);
        setIsModalOpen(false);
    };

    const handleCreate = () => {
        resetParsedData();
        setIsCreatingNewStructure(true);
    };

    const handleSheetChange = (value: string | undefined) => {
        setPreviewTableData(undefined);
        setSelectedHeaderRows(undefined);
        setHeaderTree(undefined);
        setSelectedSheet(value);
    };

    return (
        <ModalOpenerComponent
            isOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            handleCancel={() => {
                resetParsedData();
                form.resetFields();
                setIsCreatingNewStructure(false);
                setIsModalOpen(false);
            }}
            handleSave={() => {
                form.validateFields()
                    .then(() => {
                        handleSave();
                    });
            }}
            component={(
                <SimpleActionButton
                    type="primary"
                    title={reportConfigurationFormTitles.excelTableHeaderStructure}
                    icon="Excel"
                    disabled={disabled}
                />
            )}
            modalProps={{
                title: reportConfigurationFormTitles.excelTableHeaderStructure,
                centered: true,
                forceRender: false,
                destroyOnClose: true,
            }}
            disabledControls={{
                save: !initialValue && !headerTree,
            }}
        >
            <div className="excel-header-structure-loader-modal">
                {
                    !isCreatingNewStructure ? (
                        <HeaderPreview
                            headerStructure={initialValue}
                            sheets={sheets}
                            selectedSheet={selectedSheet}
                            form={form}
                            isEditing
                            onReset={handleReset}
                            onDelete={handleDelete}
                            onCreate={handleCreate}
                            onSheetChange={handleSheetChange}
                        />
                    ) : (
                        <ExcelHeaderStructureLoader
                            form={form}
                            currentStep={currentStep}
                            setCurrentStep={setCurrentStep}
                            previewTableData={previewTableData}
                            setPreviewTableData={setPreviewTableData}
                            selectedHeaderRows={selectedHeaderRows}
                            setSelectedHeaderRows={setSelectedHeaderRows}
                            headerTree={headerTree}
                            setHeaderTree={setHeaderTree}
                            resetData={resetParsedData}
                        />
                    )
                }
            </div>
        </ModalOpenerComponent>
    );
};
