import {FormInstance} from 'antd/es/form';

import {EntityType} from 'shared/constants/entities';
import {showMessageFromResponse} from 'shared/utils';
import {AppState, Dispatch} from 'store/config/types';

import {FormMode} from '../../../components/form';
import {selectContextRawData} from '../../context/context-selector';
import {FieldMeta, MetaActionType, selectMetadata} from '../../metadata';
import {setMetadata} from '../../metadata/metadata-actions';
import {fetchEntityMetadata} from '../../metadata/metadata-api';
import {getActionByType} from '../../metadata/metadata-utils';
import {setData, setFormUsedAdditionalOptionId, setFormUsedAdditionalOptions} from '../data-actions';
import {fetchFormDataFields, fetchReferenceData} from '../data-api';
import {EntityData, EntityValue, FormEntityData} from '../data-types';
import {getAdditionalFields} from './utils/actions.utils';

/**
 * Сравнивает по полям метаданные fields из запроса сервера и из приложения,
 * если поле есть в мете сервера, то оно считается приоритетным
 * @param appFields
 * @param fieldsFromRequest
 * @param serverFields
 * @param fieldsMeta
 * @param contextData
 */
export const mapServerFieldMetaToAppFieldMeta = (
    appFields: FieldMeta[],
    fieldsFromRequest: any,
    serverFields?: FieldMeta[],
    fieldsMeta?: any,
    contextData?: any,
) => {
    const newAdditionalFields: FieldMeta[] = [];
    if (fieldsFromRequest) {
        const fieldKeys = Object.keys(appFields[0]);
        const additionalKeys: (string[] | undefined)[] = [];
        let additionalFieldsFromResponse : any = {};
        let fieldArrName : string = '';
        fieldKeys.forEach(field => {
            additionalKeys.push(appFields[0][field as keyof FieldMeta]?.toString().split('.'));
        });
        additionalKeys.forEach(key => {
            if (key) {
                [fieldArrName] = key;
            }
            key?.shift();
        });
        if (serverFields) {
            additionalFieldsFromResponse = getAdditionalFields(
                // @ts-ignore
                serverFields[fieldArrName],
                additionalKeys,
                fieldKeys,
                contextData,
            );
        }
        return additionalFieldsFromResponse;
    }

    serverFields?.forEach(field => {
        if (appFields.some(item => field.key === item.key)) {
            const appAdditionalField = appFields.find(item => field.key === item.key);
            const newAdditionalData = appAdditionalField
                ? Object.keys(appAdditionalField).reduce(
                    // @ts-ignore
                    (acc, curr) => ({...acc, [curr]: field[curr] || appAdditionalField[curr]}), {},
                )
                : field;
            newAdditionalFields.push({...field, ...newAdditionalData});
        }
    });
    return newAdditionalFields;
};

export const loadAdditionalData = (
    key: string,
    referenceUrl: string,
    additionalReferenceUrl: string,
    docTemplateId?: string,
    menuId?: EntityValue | any,
    changingFormStructure?: boolean,
    id?: string | number,
    entityName?: string,
    paramKey?: string,
    form?: FormInstance,
    formMode?: FormMode,
    lastUsedAdditionalOptionId?: number,
    formData?: FormEntityData,
) => async (dispatch: Dispatch, getState: () => AppState) => {
    const contextData = selectContextRawData(getState()) ?? {};
    const referenceParams = {docTemplateId, menuId};
    const urlParam = paramKey ? menuId[paramKey] : '';

    const setAdditionalData = (loading?: boolean, rows?: EntityData) => {
        dispatch(setFormUsedAdditionalOptions({
            entityName: entityName || '',
            entityType: additionalReferenceUrl,
            loading,
            data: rows,
        }));
    };

    try {
        if (changingFormStructure && entityName) {
            const response = await fetchEntityMetadata(entityName, EntityType.FORM);
            if (response.status === 200) {
                const metadata = {
                    ...response.data,
                    isFilterable: !!getActionByType(MetaActionType.FILTER)(response.data.actions),
                    isSearchable: !!getActionByType(MetaActionType.SEARCH)(response.data.actions),
                };
                const additionalFields = selectMetadata(
                    entityName, EntityType.FORM,
                )(getState())?.additionalOptions?.optionsField?.additionalFields || [];

                const fieldsFromRequest = selectMetadata(
                    entityName, EntityType.FORM,
                )(getState())?.additionalOptions?.optionsField?.isAdditionalFieldsFromRequest;

                const {data} = await fetchFormDataFields(additionalReferenceUrl, id, urlParam);

                if (id) {
                    dispatch(setFormUsedAdditionalOptionId(
                        {entityName, entityNameChild: additionalReferenceUrl, optionId: id},
                    ));
                }
                const newAdditionalFields = mapServerFieldMetaToAppFieldMeta(
                    additionalFields,
                    fieldsFromRequest,
                    data,
                    metadata?.fields,
                    contextData,
                );

                dispatch(setMetadata(
                    {
                        entityName,
                        entityType: EntityType.FORM,
                        metadata: {
                            ...metadata,
                            fields: [...metadata?.fields, ...newAdditionalFields],
                        },
                    },
                ));
                const fieldsMeta = selectMetadata(
                    entityName, EntityType.FORM,
                )(getState())?.fields;

                const changingFormDataRefKeys = fieldsMeta.filter(
                    field => !!field?.additionalOptions?.optionsField?.isChangingFormStructure,
                ).map(field => field.key);
                const currentData = form?.getFieldsValue(changingFormDataRefKeys);
                const isFormStructureChanging = changingFormDataRefKeys.some(
                    refKey => formData?.initialData[refKey] !== currentData[refKey],
                );
                const newData = formData?.initialData;

                // reset all form fields except field which is triggering additional data loading
                if (formMode === FormMode.CREATE || (formMode === FormMode.EDIT && isFormStructureChanging)) {
                    changingFormDataRefKeys.forEach(refKey => {
                        if (newData) newData[refKey] = currentData[refKey];
                    });
                    if (formData && formData.initialData) {
                        dispatch(setData({
                            entityName,
                            entityType: EntityType.FORM,
                            data: newData || formData.initialData,
                        }));
                    }
                }
            }
            return;
        }
        const {data: rows} = await fetchReferenceData(additionalReferenceUrl, referenceParams);
        setAdditionalData(false, rows);
    } catch (e) {
        if (e.response) {
            setAdditionalData(false, []);
        }
        showMessageFromResponse({response: e.response});
        console.error(e);
    }
};
