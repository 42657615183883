import {isNil, omitBy} from 'lodash';
import {useEffect} from 'react';

import {selectContextRawData} from 'modules/context/context-selector';
import {ContextRawData} from 'modules/context/context-types';
import {setFilterLoading} from 'modules/data/data-actions';
import {selectReferencesAsEntityDataArray} from 'modules/data/data-selectors';
import {FilterEntityData} from 'modules/data/data-types';
import {FieldMeta, FieldType} from 'modules/metadata';
import {EntityType} from 'shared/constants/entities';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';

interface UseFilterInitializationProps {
    entityName: string;
    filter?: FilterEntityData;
    fields?: FieldMeta[];
}

const generatePath = (url?: string, useContext?: boolean, contextData?: ContextRawData) => {
    const params = omitBy(contextData, v => isNil(v));
    const querySuffix = useContext && new URLSearchParams(params as Record<string, string>).toString();
    return querySuffix ? `${url}?${querySuffix}` : url;
};

/**
 * Проверяет все ли данные для фильтра загружены
 */
export const useFilterInitialization = ({
    entityName,
    fields,
    filter,
}: UseFilterInitializationProps) => {
    const dispatch = useAppDispatch();
    const contextData = useAppSelector(selectContextRawData);
    const neededReferences = (fields?.map(field => generatePath(field.referenceUrl, field.useContext, contextData))
        .filter(reference => reference) || []) as string[];
    const storedReferenceData = useAppSelector(selectReferencesAsEntityDataArray(neededReferences));

    useEffect(() => {
        const referencesWithDefaultValues = fields?.filter(
            field => [FieldType.REFERENCE, FieldType.REFERENCE_SWITCHER]
                .includes(field.type) && field.useFirstOptionAsDefaultValue,
        );

        // каждый reference как минимум присутствует в store и имеет статус загружен (loading = false)
        const isAllFilterDataLoaded = neededReferences
            .every(reference => storedReferenceData[reference] && !storedReferenceData[reference].loading);

        // каждый reference со значением по умолчанию выставил свое значение в данных фильтра
        const isAllReferenceDataSet = referencesWithDefaultValues?.every(field => {
            // не получилось загрузить данные для выпадашки либо данных для нее просто нет => пропускаем
            if (field.referenceUrl && (storedReferenceData[field.referenceUrl]?.isError === true
                || storedReferenceData[field.referenceUrl]?.[EntityType.REFERENCE]?.rows?.length === 0)) {
                return true;
            }
            return filter?.data[field.key] || (field.dependentInputKey && filter?.data[field.dependentInputKey]);
        });
        if (fields?.length && isAllFilterDataLoaded && isAllReferenceDataSet) {
            if (filter?.loading || filter?.loading === undefined) {
                // все значения загружены и выставлены если это необходимо => фильтр прогрузился
                dispatch(setFilterLoading({entityName, loading: false}));
            }
        }
    }, [storedReferenceData, neededReferences]);
};
