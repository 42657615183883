import React from 'react';

import {AuthPage} from 'pages/auth-page/auth-page';

import {TwoFactorAuthPageForm} from './two-factor-auth-page-form';

import 'pages/login-page/login-page.less';

export const TwoFactorAuthPage = () => (
    <div className="login-page">
        <AuthPage
            className="d-flex flex-direction-row justify-content-center align-items-center"
        >
            <TwoFactorAuthPageForm />
        </AuthPage>
    </div>
);
