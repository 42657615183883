import React, {
    FunctionComponent, useMemo,
} from 'react';

import {RequestStatus} from 'store/slices/request-slice/request-slice-type';

import {RequestActionTypeResolver} from './request-action-type-resolver';

interface RequestActionItemProps {
    requestFormKey: string;
    requestCommentId?: string;
    id?: string | number;
    action: RequestStatus;
    entityName?: string;
    onSubmit?: () => void;
}

export const RequestActionItem: FunctionComponent<RequestActionItemProps> = ({
    id,
    action,
    entityName,
    requestFormKey,
    requestCommentId,
    onSubmit,
}: RequestActionItemProps) => {
    const Component = useMemo(() => RequestActionTypeResolver(
        action,
        requestFormKey,
        requestCommentId,
        entityName,
        onSubmit,
    ),
    [action, id]);
    return (
        <Component key={action} />
    );
};
