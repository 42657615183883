import {Empty, Table} from 'antd';
import {FormInstance} from 'antd/es/form';
import React, {ReactNode} from 'react';

import {SimpleActionButton} from 'components/form/components';
import {CustomSelect, CustomSelectEntry} from 'components/form/inputs/custom-select';
import {
    CREATING_TABLE_PREVIEW_DESCRIPTION,
    CREATING_TREE_PREVIEW_DESCRIPTION,
    EDITING_TABLE_PREVIEW_DESCRIPTION,
    EDITING_TREE_PREVIEW_DESCRIPTION,
    NO_DATA_FOR_CURRENT_SHEET,
} from 'components/report-configuration/tabs/report-table-settings/modals/excel-header-structure-loader-modal/excel-header-structure-loader-modal.constants';
import {TableHeaderStructure} from 'components/report-configuration/tabs/report-table-settings/modals/excel-header-structure-loader-modal/excel-header-structure-loader-modal.types';
import {renderColumns} from 'components/report-configuration/tabs/report-table-settings/modals/excel-header-structure-loader-modal/excel-header-structure-loader-modal.utils';
import {HeaderTreeMapper} from 'components/report-configuration/tabs/report-table-settings/modals/excel-header-structure-loader-modal/header-tree-mapper/header-tree-mapper';

import './header-preview.less';

type HeaderPreviewCommonProps = {
    headerStructure: TableHeaderStructure | undefined;
    form: FormInstance;
}

type HeaderPreviewEditingProps = HeaderPreviewCommonProps & {
    isEditing: true;
    onReset: () => void;
    onDelete: () => void;
    onCreate: () => void;
    onSheetChange: (value: string | undefined) => void;
    stepBackButton?: never;

    sheets: CustomSelectEntry<string, any>[];
    selectedSheet: string | undefined;
}
type HeaderPreviewCreatingProps = HeaderPreviewCommonProps & {
    isEditing?: false;
    stepBackButton: ReactNode;
    onReset?: never;
    onDelete?: never;
    onCreate?: never;
    onSheetChange?: never;

    sheets?: never;
    selectedSheet?: never;
}

type HeaderPreviewProps = HeaderPreviewEditingProps | HeaderPreviewCreatingProps;

export const HeaderPreview = ({
    headerStructure,
    sheets,
    selectedSheet,
    form,
    isEditing,
    onReset,
    onDelete,
    onCreate,
    onSheetChange,
    stepBackButton,
}: HeaderPreviewProps) => (
    <div className="header-preview">
        <div className="header-preview__actions">
            {isEditing ? (
                <>
                    {!!sheets?.length && (
                        <CustomSelect
                            style={{width: '200px'}}
                            value={selectedSheet}
                            onChange={value => {
                                onSheetChange?.(value);
                            }}
                            entries={sheets}
                            settings={{
                                isClearable: true,
                                showSearch: true,
                                placeholder: 'Выберите лист',
                            }}
                        />
                    )}
                    {!!headerStructure?.length && (
                        <>
                            <SimpleActionButton
                                type="default"
                                icon="ReloadOutlined"
                                title="Пересоздать"
                                onClick={onReset}
                            />
                            <SimpleActionButton
                                type="default"
                                icon="TrashXOutlined"
                                title="Удалить настроенную структуру"
                                onClick={onDelete}
                            />
                        </>
                    )}
                </>
            ) : stepBackButton}
        </div>
        {headerStructure?.length ? (
            <>
                <div className="header-preview__description">
                    {isEditing ? EDITING_TABLE_PREVIEW_DESCRIPTION : CREATING_TABLE_PREVIEW_DESCRIPTION}
                </div>
                <Table
                    className="header-preview__table"
                    dataSource={[]}
                    scroll={{x: '100%'}}
                    bordered={false}
                >
                    {renderColumns(headerStructure)}
                </Table>
                <div className="header-preview__description tree-description">
                    {isEditing ? EDITING_TREE_PREVIEW_DESCRIPTION : CREATING_TREE_PREVIEW_DESCRIPTION}
                </div>
                <HeaderTreeMapper
                    form={form}
                    tree={headerStructure}
                />
            </>
        ) : (
            <div className="header-preview__empty">
                <Empty
                    imageStyle={{
                        height: 200,
                    }}
                    description={(
                        <span>
                            {NO_DATA_FOR_CURRENT_SHEET}
                        </span>
                    )}
                >
                    <SimpleActionButton
                        type="primary"
                        icon="PlusCircleOutlined"
                        title="Создать"
                        onClick={onCreate}
                    />
                </Empty>
            </div>
        )}
    </div>
);
