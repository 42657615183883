import {AuthProfile} from 'store/slices/auth-slice/auth-slice-types';

export const authSliceName = 'authSlice';

export const AuthResponseTypes = {
    PASSWORD_EXPIRED: 'PASSWORD EXPIRED',
    UNAUTHORIZED: 'UNAUTHORIZED',
};

export const AuthErrors = {
    DEFAULT_ERROR_MESSAGE: 'Произошла непредвиденная ошибка. Обратитесь к администратору',
    USER_INFO_ERROR_MESSAGE: 'Не удалось получить информацию о пользователе',
    CHANGE_PASSWORD_SETTINGS_REQUEST_FAILED: 'Не удалось получить информацию о составе регулярного выражения '
        + 'для пароля. Обратитесь к администратору',
    PROFILE_NOT_FOUND: 'Не удалось получить информацию о профиле входа. Обратитесь к администратору',
};

export const SESSION_EXPIRED_NOTIFICATION = {
    KEY: 'sessionClosedKey',
    MESSAGE: 'Сеанс завершён',
    DESCRIPTION: 'Сеанс был завершён в связи с отсутствием пользовательской активности',
} as const;

export const EXTERNAL_AUTHENTICATION_PROFILES: AuthProfile[] = [
    AuthProfile.SAML,
    AuthProfile.KEYCLOAK,
];

export const CAPITAL_CASE_DISPLAYED_PROFILES: AuthProfile[] = [
    AuthProfile.SAML,
];
