import {performRequest} from 'shared/utils';

import {JasperReportInfo} from './jasper-report-slice-types';

export const getJasperReportUrl = (entityName: string) => (
    performRequest<string>({
        url: '/report/getUrl',
        method: 'GET',
        params: {name: entityName},
    })
);

export interface GetJasperReportInfoData extends JasperReportInfo {}

export const getJasperReportInfo = (url: string) => (
    performRequest<GetJasperReportInfoData>({
        url,
        method: 'GET',
    })
);
