import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {selectEntityData, DATA_MODULE, EntityValue} from 'modules/data';
import {createActionHandlerDownloadFile, setData, updateDocumentsStatus} from 'modules/data/data-actions';
import {PropertyCode, TableEntityData} from 'modules/data/data-types';
import {MetaActionType, RequestType} from 'modules/metadata';
import {EntityType} from 'shared/constants/entities';
import {CommonMessages} from 'shared/constants/messages';
import {showMessage} from 'shared/utils';
import {fetchProperty} from 'shared/utils/request';
import {AppState} from 'store/config/types';
import {openModal} from 'store/slices/modals-slice';

import {ActionButton} from '../action-button';
import {TableActionProps} from '../table-action-types';

const ButtonsActionDocumentsComponent: React.FunctionComponent<TableActionProps> = ({
    wrapper,
    meta,
    entityName,
    ...props
}: TableActionProps) => {
    const dispatch = useDispatch();
    const {selectedRowKeys = []} = useSelector(
        (state: AppState) => (
            selectEntityData(entityName, EntityType.TABLE)(state) || {}
        ) as TableEntityData,
    );
    const documentDate = useSelector(
        (state: AppState) => state[DATA_MODULE]?.[entityName]?.[EntityType.TABLE]?.rows,
    );
    const handleClick = async () => {
        const idsList: string[] = [];
        const newData: string[] = selectedRowKeys.map((row: number | string) => String(row));
        if (selectedRowKeys?.length === 0) {
            showMessage({message: CommonMessages.SELECT_DOCUMENT, isError: true});
        }
        if (!!selectedRowKeys?.length && meta?.referenceUrl) {
            if (meta?.actionType === MetaActionType.UPDATE_DOCUMENTS_STATUS) {
                dispatch(
                    updateDocumentsStatus(entityName, meta.referenceUrl, newData, meta?.actionCode, meta?.requestType),
                );
            }
            if (meta?.actionType === MetaActionType.PUBLISH_LIST) {
                const {modalName, modalEntityName} = meta;
                const selectedDocuments: Record<string, EntityValue>[] = documentDate?.filter(
                    document => newData.includes(String(document.id)),
                ) || [];
                const requiredEDS = await fetchProperty(PropertyCode?.REQUIRED_EDS_WHEN_DOC_PUBLISH)
                    .then(res => res?.data !== 'N');
                const requiredLoaSign = await fetchProperty(PropertyCode?.REQUIRED_LOA_SGN_WHEN_DOC_PUBLISH)
                    .then(res => res?.data !== 'N');

                const isSigned = (fileList: any): boolean => !!fileList[0]?.signatureFileName;
                const hasLoaSign = (fileList: any): boolean => !!fileList[0]?.loaSignFileName;

                selectedDocuments.forEach((item: any) => {
                    const isDocumentSigned = isSigned(item?.fileList);
                    const hasDocumentLoaSign = hasLoaSign(item?.fileList);

                    if (requiredEDS && !isDocumentSigned && requiredLoaSign && !hasDocumentLoaSign) {
                        showMessage({message: `Документ: ${item.docName} не подписан и не имеет МЧД`, isError: true});
                    } else if (requiredEDS && !isDocumentSigned) {
                        showMessage({message: `Документ: ${item.docName} не подписан`, isError: true});
                    } else if (requiredLoaSign && !hasDocumentLoaSign) {
                        showMessage({message: `Документ: ${item.docName} не имеет МЧД`, isError: true});
                    } else {
                        idsList.push(item?.id);
                    }
                });

                if (idsList?.length === selectedDocuments.length) {
                    dispatch(
                        openModal({
                            modalName: modalName || '',
                            entityName: modalEntityName,
                        }),
                    );
                    dispatch(setData({
                        entityName,
                        entityType: EntityType.TABLE,
                        loading: false,
                        data: {
                            publishDocumentIds: idsList,
                        },
                    }));
                }
            }
            if (meta?.actionType === MetaActionType.DOWNLOAD_ZIP_TABLE_ROWS) {
                dispatch(
                    createActionHandlerDownloadFile(
                        entityName,
                        meta.referenceUrl,
                        meta.requestType || RequestType.POST,
                        {ids: [...selectedRowKeys]},
                    ),
                );
            }
        }
    };
    const Component = wrapper || ActionButton;
    return (
        <Component
            {...props}
            onClick={handleClick}
            actionIcon={meta.actionIcon}
            disabled={!selectedRowKeys.length}
        >
            {meta.actionTitle}
        </Component>
    );
};

export const ButtonsActionDocuments = ButtonsActionDocumentsComponent;
