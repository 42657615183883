import React from 'react';

import {TIconType} from 'components/dynamic-icon/dynamic-icon';
import {convertRequestFormData} from 'components/request/utils/request-api-utils';
import {setData} from 'modules/data/data-actions';
import {ButtonType} from 'modules/metadata/metadata-types';
import {EntityType} from 'shared/constants/entities';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {openModal} from 'store/slices/modals-slice';
import {selectRequestData} from 'store/slices/request-slice/request-selectors';

import {SimpleActionButton} from '../../components';

interface ButtonOpenChildModalProps {
    actionTitle: string;
    actionIcon: TIconType;
    modalName: string;
    modalEntityName?: string;
    parentEntityName?: string;
    buttonType?: ButtonType;
}

export const ButtonOpenChildModal = ({
    modalName,
    modalEntityName = '',
    parentEntityName = '',
    actionIcon,
    actionTitle,
    buttonType,
}: ButtonOpenChildModalProps) => {
    const dispatch = useAppDispatch();
    const parentFormData = useAppSelector(selectRequestData);

    const handleOpenChildModal = () => {
        // use parent form data as child form data if parentEntityName is present
        if (parentEntityName) {
            dispatch(setData({
                entityName: modalEntityName,
                data: convertRequestFormData(parentFormData),
                loading: false,
                entityType: EntityType.FORM,
            }));
        }
        dispatch(openModal({modalName, entityName: modalEntityName}));
    };

    return (
        <SimpleActionButton
            type={buttonType || 'primary'}
            icon={actionIcon}
            title={actionTitle}
            onClick={handleOpenChildModal}
        />
    );
};
