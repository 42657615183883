import {createAsyncThunk} from '@reduxjs/toolkit';

import {DDFilterExpressionDto} from 'components/report-configuration/tabs/report-dd-filters/dd-filter-expression-creator/dd-filter-expression-creator-types';
import {LookupEntry} from 'shared/types/lookups';
import {AppState} from 'store/config/types';

import {ReportTableColumn} from '../dd-lookups';
import {fetchTableReportColumns, fetchTableReportFilterConditions} from '../dd-lookups/dd-lookups-api';
import {selectReportTableColumnsByTemplateCode, selectTableReportConfigurationFilterConditions} from '../dd-lookups/dd-lookups-selectors';
import {reportConfigurationSliceName} from '../report-configuration-constants';
import {
    DeleteDrillDownFilterByIdArgs,
    FetchDrillDownFilterByIdArgs,
    PostDrillDownFilterByIdArgs,
    deleteDrillDownFilterById,
    fetchDrillDownFilterById,
    postDrillDownFilterById,
} from './dd-filters-configuration-api';

export interface LoadDrilldownFilterData {
    ddFilterExpressionDto: DDFilterExpressionDto | undefined;
    tableReportFilterLookups: LookupEntry[];
    childReportColumns: ReportTableColumn[] | undefined;
}

export interface LoadDrilldownFilterArgs extends FetchDrillDownFilterByIdArgs {
    childReportTemplateCode: string | null | undefined;
}

export const loadDrilldownFilter = createAsyncThunk<LoadDrilldownFilterData, LoadDrilldownFilterArgs>(
    `${reportConfigurationSliceName}/loadDrilldownFilter`, async (args, {rejectWithValue, getState}) => {
        try {
            const {childReportTemplateCode} = args;

            const tableReportFiltersInStore = selectTableReportConfigurationFilterConditions(getState() as AppState);
            const childReportColumnsInStore = !childReportTemplateCode ? undefined
                : selectReportTableColumnsByTemplateCode(
                    getState() as AppState, childReportTemplateCode,
                );

            const fetchDrilldownFilter = async () => {
                try {
                    const fetchResult = await fetchDrillDownFilterById(args);
                    return fetchResult;
                } catch (e) {
                    if (e.response.status === 404) return {data: undefined};
                    throw new Error(e);
                }
            };

            const loadRequests = await Promise.all([
                await fetchDrilldownFilter(),
                await (tableReportFiltersInStore ? Promise.resolve(
                    {data: tableReportFiltersInStore},
                ) : fetchTableReportFilterConditions()),
                await (childReportColumnsInStore ? Promise.resolve({data: childReportColumnsInStore})
                    : childReportTemplateCode ? fetchTableReportColumns({
                        templateCode: childReportTemplateCode,
                    }) : Promise.resolve({data: undefined as ReportTableColumn[] | undefined})),
            ]);

            const [
                ddFilterExpressionDtoResponse,
                tableReportFiltersResponse,
                childReportColumnsResponse,
            ] = loadRequests;

            return {
                ddFilterExpressionDto: ddFilterExpressionDtoResponse.data,
                tableReportFilterLookups: tableReportFiltersResponse.data,
                childReportColumns: childReportColumnsResponse.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const updateDrilldownFilter = createAsyncThunk<string, PostDrillDownFilterByIdArgs>(
    `${reportConfigurationSliceName}/updateDrilldownFilter`, async (args, {rejectWithValue}) => {
        try {
            const {data} = await postDrillDownFilterById(args);
            return data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const deleteDrilldownFilter = createAsyncThunk<string, DeleteDrillDownFilterByIdArgs>(
    `${reportConfigurationSliceName}/deleteDrilldownFilter`, async (args, {rejectWithValue}) => {
        try {
            const {data} = await deleteDrillDownFilterById(args);
            return data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);
