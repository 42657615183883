import {Button, Spin} from 'antd';
import React from 'react';
import {useHistory} from 'react-router-dom';

import {ListArrayFactory} from 'components/@common/specific/list-array-factory';
import {SignatureItem} from 'components/ais/reclaiming-information-card/fields/signature-item/signature-item';
import {DateListItem} from 'components/form/fields/date-list-item';
import {DownloadDocumentListItem} from 'components/form/fields/download-document-list-item';
import {URL_AIS3_DOCUMENT_INVENTORY} from 'shared/constants/urls';
import {
    ReclaimingInformationEntity,
    useLazyGetReclaimingInformationQuery,
    useRunSubmitProtocolServiceMutation,
    useRunSubmitInabilityInfoServiceMutation,
    useRunSubmitResponseToDocRequestServiceMutation,
} from 'store/api/reclaiming-information/reclaiming-information.api';

interface ReclaimingMainInfoProps {
    id: string;
    initialLoadedData: ReclaimingInformationEntity;
    onClose: () => void;
}

export const ReclaimingMainInfo = ({id, initialLoadedData, onClose}: ReclaimingMainInfoProps) => {
    const history = useHistory();

    const [runSubmitProtocolService, {isLoading: isSubmitProtocolLoading}] = useRunSubmitProtocolServiceMutation();
    const [
        runSubmitInabilityInfoService, {isLoading: isSubmitInabilityInfoLoading},
    ] = useRunSubmitInabilityInfoServiceMutation();
    const [
        runSubmitResponseToDocRequestService, {isLoading: isSubmitResponseToDocRequestLoading},
    ] = useRunSubmitResponseToDocRequestServiceMutation();

    const [
        getReclaimingInformationQuery,
        {
            currentData,
            isFetching: isDataRefetching,
        },
    ] = useLazyGetReclaimingInformationQuery();

    const reloadData = () => {
        getReclaimingInformationQuery({id});
    };

    const handleCreateInventoryClick = () => {
        const docIdParam = `?docId=${id}`;
        history.push(`${URL_AIS3_DOCUMENT_INVENTORY}${docIdParam}`);
    };

    const reclaimingInfo = currentData ?? initialLoadedData;

    return (
        <Spin
            spinning={isDataRefetching}
            tip="Подпись загружена, обновляем данные..."
        >
            <ListArrayFactory
                listArrayDescription={[
                    {
                        listTitle: 'Основная информация о требовании',
                        list: [
                            {
                                title: 'Номер требования:',
                                description: reclaimingInfo?.mainDocNumber || '-',
                            },
                            {
                                title: 'Дата получения:',
                                description: <DateListItem
                                    date={reclaimingInfo?.downloadDate}
                                />,
                            },
                            {
                                title: 'Файл требования:',
                                description: <DownloadDocumentListItem
                                    fileList={
                                        reclaimingInfo?.fileListMainDocument?.[0]
                                            ? [reclaimingInfo?.fileListMainDocument?.[0]]
                                            : undefined
                                    }
                                />,
                            },
                            {
                                title: 'Подпись:',
                                description: <DownloadDocumentListItem
                                    fileList={
                                        reclaimingInfo?.fileListMainDocument?.[1]
                                            ? [reclaimingInfo?.fileListMainDocument?.[1]]
                                            : undefined
                                    }
                                />,
                            },
                        ],
                    },
                    {
                        listTitle: 'Протокол обработки требования',
                        list: [{
                            title: 'Файл протокола:',
                            description: <DownloadDocumentListItem
                                fileList={reclaimingInfo?.processingAudit?.fileList}
                            />,
                        },
                        {
                            title: 'Подпись:',
                            description: <SignatureItem
                                signatureName={reclaimingInfo?.processingAudit?.fileList?.[0]?.signatureFileName}
                                attachmentId={reclaimingInfo?.processingAudit?.fileList?.[0]?.attachmentId}
                                reloadData={reloadData}
                            />,
                        },
                        {
                            title: 'Дата отправки:',
                            description: <DateListItem
                                date={reclaimingInfo?.processingAudit?.createdDate}
                            />,
                        },
                        ],
                        useButtonsBottomMargin: true,
                        buttons: [
                            <Button
                                type="primary"
                                disabled={
                                    !reclaimingInfo?.processingAudit?.fileList?.[0]?.attachmentId
                                        || !reclaimingInfo?.processingAudit?.fileList?.[0]?.signatureFileName
                                }
                                onClick={() => {
                                    runSubmitProtocolService()
                                        .unwrap()
                                        .then(onClose);
                                }}
                                loading={isSubmitProtocolLoading}
                            >
                                Отправить
                            </Button>,
                        ],
                    },
                    {
                        listTitle: 'Ответ на требование',
                        list: [{
                            title: 'Файл ответа на требование:',
                            description: <DownloadDocumentListItem
                                fileList={reclaimingInfo?.responseRequirementAudit?.fileList}
                            />,
                        },
                        {
                            title: 'Подпись:',
                            description: <SignatureItem
                                signatureName={reclaimingInfo?.responseRequirementAudit?.fileList[0]?.signatureFileName}
                                attachmentId={reclaimingInfo?.responseRequirementAudit?.fileList[0]?.attachmentId}
                                reloadData={reloadData}
                            />,
                        },
                        {
                            title: 'Дата отправки:',
                            description: <DateListItem
                                date={reclaimingInfo?.responseRequirementAudit?.createdDate}
                            />,
                        },
                        ],
                        useButtonsBottomMargin: true,
                        buttons: [
                            <Button
                                type="primary"
                                disabled={!reclaimingInfo?.responseRequirementAudit?.fileList[0]?.attachmentId}
                                onClick={handleCreateInventoryClick}
                            >
                                Сформировать
                            </Button>,
                            <Button
                                type="primary"
                                disabled={
                                    !reclaimingInfo?.responseRequirementAudit?.fileList[0]?.attachmentId
                                    || !reclaimingInfo?.responseRequirementAudit?.fileList[0]?.signatureFileName
                                }
                                onClick={() => {
                                    runSubmitResponseToDocRequestService()
                                        .unwrap()
                                        .then(onClose);
                                }}
                                loading={isSubmitResponseToDocRequestLoading}
                            >
                                Отправить
                            </Button>,
                        ],
                    },
                    {
                        listTitle: 'Протокол обработки ответа на требование',
                        list: [{
                            title: 'Файл протокола:',
                            description: <DownloadDocumentListItem
                                fileList={reclaimingInfo?.incomingProcessingAudit?.fileList}
                            />,
                        },
                        {
                            title: 'Подпись:',
                            description: <SignatureItem
                                signatureName={
                                    reclaimingInfo?.incomingProcessingAudit?.fileList?.[0]?.signatureFileName
                                }
                                attachmentId={reclaimingInfo?.incomingProcessingAudit?.fileList?.[0]?.attachmentId}
                                reloadData={reloadData}
                            />,
                        },
                        {
                            title: 'Дата получения:',
                            description: <DateListItem
                                date={reclaimingInfo?.incomingProcessingAudit?.createdDate}
                            />,
                        },
                        ],
                    },
                    {
                        listTitle: 'Уведомление о невозможности предоставить документ',
                        list: [{
                            title: 'Файл уведомления:',
                            description: <DownloadDocumentListItem
                                fileList={reclaimingInfo?.notificationAudit?.fileList}
                            />,
                        },
                        {
                            title: 'Подпись:',
                            description: <SignatureItem
                                signatureName={reclaimingInfo?.notificationAudit?.fileList?.[0]?.signatureFileName}
                                attachmentId={reclaimingInfo?.notificationAudit?.fileList?.[0]?.attachmentId}
                                reloadData={reloadData}
                            />,
                        },
                        {
                            title: 'Дата отправки:',
                            description: <DateListItem
                                date={reclaimingInfo?.notificationAudit?.createdDate}
                            />,
                        },
                        ],
                        useButtonsBottomMargin: true,
                        buttons: [
                            <Button
                                type="primary"
                                disabled={
                                    !reclaimingInfo?.notificationAudit?.fileList?.[0]?.attachmentId
                                    || !reclaimingInfo?.notificationAudit?.fileList?.[0]?.signatureFileName
                                }
                                onClick={() => {
                                    const attachId = reclaimingInfo?.notificationAudit?.fileList?.[0]?.attachmentId;
                                    if (!attachId) {
                                        return;
                                    }
                                    runSubmitInabilityInfoService({id: attachId})
                                        .unwrap()
                                        .then(onClose);
                                }}
                                loading={isSubmitInabilityInfoLoading}
                            >
                                Отправить
                            </Button>,
                        ],
                    },
                    {
                        listTitle: 'Решение о продлении сроков предоставления документов',
                        list: [{
                            title: 'Файл решения:',
                            description: <DownloadDocumentListItem
                                fileList={reclaimingInfo?.solutionAudit?.fileList}
                            />,
                        },
                        {
                            title: 'Подпись:',
                            description: <DownloadDocumentListItem
                                fileList={reclaimingInfo?.solutionAudit?.fileList}
                            />,
                        },
                        {
                            title: 'Дата отправки:',
                            description: <DateListItem
                                date={reclaimingInfo?.solutionAudit?.createdDate}
                            />,
                        },
                        {
                            title: 'Дата предоставления документов:',
                            description: <DateListItem
                                date={reclaimingInfo?.solutionAudit?.lastModifiedDateMainDocument}
                            />,
                        },
                        ],
                    },
                ]}
            />
        </Spin>
    );
};
