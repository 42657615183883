import {LoadingOutlined} from '@ant-design/icons/lib';
import {Checkbox, message, Spin} from 'antd';
import {isEmpty} from 'lodash';
import omit from 'lodash/omit';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {EditableFormTableContext} from 'components/editable-table-modal/editable-form-table-data';
import {selectContextRawData} from 'modules/context/context-selector';
import {saveEditableData} from 'modules/data/data-actions';
import {RequestType} from 'modules/metadata';
import {EntityType} from 'shared/constants/entities';
import {
    AppControlProceduresResultApi,
    useGetAppStatisticsConfirmedQuery,
} from 'store/api/app-control-procedures-result/app-control-procedures-result.api';
import {useAppSelector} from 'store/config/hooks';

import {TableActionProps} from '../table-action-types';

import './button-action-save-editable-table.less';

export const ButtonActionSaveEditableTable: React.FunctionComponent<TableActionProps> = ({
    meta,
    entityName,
}: TableActionProps) => {
    const dispatch = useDispatch();
    const [value, setValue] = useState<boolean>(false);
    const {initData, form} = React.useContext(EditableFormTableContext);
    const context = useAppSelector(selectContextRawData);

    const {data: isStatisticsConfirmed, isFetching} = useGetAppStatisticsConfirmedQuery(
        {entityName, params: context}, {skip: !entityName, refetchOnMountOrArgChange: true},
    );

    useEffect(() => {
        if (initData?.length) setValue(false);
        else setValue(!!isStatisticsConfirmed);
    }, [initData, isStatisticsConfirmed]);

    const handleClick = async () => {
        const dataToSend = initData?.length
            ? initData
            : form?.map(f => f.getFieldsValue());
        if (form && dataToSend?.length) {
            let isError = false;
            await Promise.all(form.map(async item => {
                try {
                    const id = item.getFieldValue('id');
                    await item.validateFields();
                    const formData = omit(dataToSend?.find((data: Record<string, any>) => data?.id === id), 'files');
                    const isFormValid = !(item.getFieldsError().some(i => i.errors.length > 0));
                    if (isFormValid && !isEmpty(formData)) {
                        await dispatch(saveEditableData(
                            entityName,
                            EntityType.TABLE,
                            RequestType.PUT,
                            formData,
                            true,
                            meta.referenceUrl,
                            true,
                            true,
                        ));
                    }
                } catch (e) {
                    isError = true;
                    console.error(e);
                }
            }));
            if (!isError) {
                setValue(true);
                await dispatch(AppControlProceduresResultApi.util.invalidateTags(['AppStatisticsConfirmed']));
                setValue(isStatisticsConfirmed);
            } else {
                setValue(false);
                message.error('Заполните все поля корректно');
            }
        }
    };

    return (
        isFetching ? (
            <Spin
                className="button-save-editable-spin d-flex justify-content-center"
                indicator={<LoadingOutlined spin />}
                size="small"
                tip={meta.actionTitle}
            />
        ) : (
            <Checkbox
                onClick={handleClick}
                checked={value}
                value={value}
                disabled={value}
            >
                {meta.actionTitle}
            </Checkbox>
        )

    );
};
