import {MetaActionInfo, MetaActionType} from 'modules/metadata';

export const generateActionsForFilter = (entityName: string): MetaActionInfo[] => [
    {
        actionType: MetaActionType.BUTTON_SEND_FILTER,
        actionCode: 'SEND_FILTER',
        actionTitle: 'Применить',
        referenceUrl: entityName,
    },
    {
        actionType: MetaActionType.REMOVE_FILTER,
        actionCode: 'REMOVE_FILTER',
        actionTitle: 'Снять фильтры',
        parentEntityName: entityName,
    },
    {
        actionType: MetaActionType.BUTTON_BACK,
        actionCode: 'CANCEL_PUBLIC',
        actionTitle: 'Отменить',
    },
];

export interface ApplyCustomConditionsArgs {
    entityName: string;
    formValues: any;
}
