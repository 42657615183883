import {Button} from 'antd';
import {ButtonType} from 'antd/lib/button';
import React from 'react';

import {DynamicIcon} from 'components/dynamic-icon';
import {TIconType} from 'components/dynamic-icon/dynamic-icon';

interface ButtonSaveOwnProps {
    actionTitle: string;
    actionIcon?: TIconType;
    buttonType: ButtonType;
}

interface ButtonSaveProps extends ButtonSaveOwnProps {}

export const ButtonSave: React.FunctionComponent<ButtonSaveProps> = ({
    actionTitle,
    actionIcon,
    buttonType,
    ...props
}: ButtonSaveProps) => (
    <Button
        {...props}
        type={buttonType}
        className="save-action-btn"
        htmlType="submit"
    >
        {actionIcon && <DynamicIcon type={actionIcon} />}
        {actionTitle}
    </Button>
);
