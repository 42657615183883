import {Spin} from 'antd';
import cn from 'classnames';
import React, {useEffect} from 'react';

import {AisMainPageDashboardProps} from 'pages/ais/ais-main-page/ais-main-page-types';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {AisDashboardCode} from 'store/slices/ais-slice/ais-dashboards/ais-dashboards-constants';
import {selectTokenStatusDashboardData} from 'store/slices/ais-slice/ais-dashboards/ais-dashboards-selectors';
import {getAisTokenStatusDashboardData} from 'store/slices/ais-slice/ais-dashboards/ais-dashboards-thunks';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

import {AisDashboardCard} from '../@common/ais-dashboard-card';

import './ais-token-status-dashboard.less';

export const AisTokenStatusDashboard: React.FunctionComponent<AisMainPageDashboardProps> = ({
    context,
}: AisMainPageDashboardProps) => {
    const dispatch = useAppDispatch();

    const {isActive, eventDate} = useAppSelector(selectTokenStatusDashboardData) ?? {};
    const isLoading = useAppSelector(s => selectIsThunkPending(s, getAisTokenStatusDashboardData.typePrefix));
    const {taxPeriodId, organizationId} = context || {};

    useEffect(() => {
        if (!taxPeriodId && !organizationId) return;
        dispatch(getAisTokenStatusDashboardData({
            code: AisDashboardCode.TOKEN_STATISTIC,
            taxPeriodId,
            organizationId,
        }));
    }, [taxPeriodId, organizationId]);

    return (
        <AisDashboardCard
            title="Статус токена аутентификации"
            style={{flexBasis: 0}}
            bodyClassName={cn('ais-token-status-dashboard')}
        >
            <Spin
                spinning={isLoading}
                tip="Загрузка..."
            >
                <div className={cn('ais-token-status-dashboard__body')}>
                    <div className={cn('d-flex gap-2 align-items-center')}>
                        <div>{isActive ? 'Актуальный, активный' : 'Неактуальный'}</div>
                        <div>
                            <div
                                className={cn(
                                    'ais-token-status-dashboard__body-icon',
                                    isLoading
                                        ? 'idle'
                                        : isActive ? 'active' : 'inactive'
                                    ,
                                )}
                            />
                        </div>
                    </div>
                    <div className={cn('d-flex gap-2 align-items-baseline')}>
                        <div>Дата обновления</div>
                        <div className={cn('text-gray-600 text-size-14')}>
                            {eventDate}
                        </div>
                    </div>
                </div>
            </Spin>
        </AisDashboardCard>
    );
};
