import {Button, Card} from 'antd';
import React from 'react';

import {PageTemplateProps} from 'pages';
import {useAppHistory} from 'shared/hooks/use-app-history';

import './not-found-page.less';

type NotFoundPageProps = {
    entityName?: string;
    message?: string;
} & Omit<PageTemplateProps, 'entityName' | 'url'>;

export const NotFoundPage: React.FunctionComponent<NotFoundPageProps> = ({
    entityName, message,
}: NotFoundPageProps) => {
    const history = useAppHistory();
    const errorMessage = entityName
        ? `Подходящий тип страницы для сущности ${entityName} не найден`
        : 'Страница не найдена';

    return (
        <Card className="not-found-page">
            <div className="not-found-page__header d-flex align-items-center">
                <Button
                    type="primary"
                    onClick={() => history.go(-1)}
                >
                    Назад
                </Button>
            </div>
            <div className="not-found-page__content d-flex align-items-center justify-content-center">
                {message ?? errorMessage}
            </div>
        </Card>
    );
};
