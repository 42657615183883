import {DeleteOutlined} from '@ant-design/icons';
import {Popover} from 'antd';
import Progress from 'antd/es/progress';
import cn from 'classnames';
import React from 'react';

import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {apiRequestProgressSelectors, apiRequestProgressSliceActions} from 'store/slices/api-request-progress-slice/api-request-progress-slice';
import {ApiRequestProgressEntity} from 'store/slices/api-request-progress-slice/api-request-progress-slice-types';
import {DraggableFrameEntity, DraggableFrameType, draggableFramesActions} from 'store/slices/draggable-frames-slice';

interface ApiRequestProgressItemProps {
    progressEntity: ApiRequestProgressEntity;
    frameEntity?: Partial<DraggableFrameEntity>;
}

export const ApiRequestProgressItem: React.FC<ApiRequestProgressItemProps> = (
    {progressEntity, frameEntity}: ApiRequestProgressItemProps,
) => {
    const dispatch = useAppDispatch();

    const requestsProgress = useAppSelector(apiRequestProgressSelectors.selectAll);

    const {closeDraggableFrame} = draggableFramesActions;
    const {removeRequestProgress} = apiRequestProgressSliceActions;

    return (
        <div className={cn('table-report-attachment-upload-progress-item')}>
            <div className={cn('table-report-attachment-upload-progress-item__title')}>
                {
                    progressEntity.progressPercent === 100 && (
                        <Popover
                            mouseEnterDelay={1}
                            placement="left"
                            content={(
                                <div>
                                    Удалить
                                </div>
                            )}
                        >
                            <DeleteOutlined
                                onClick={() => {
                                    if (requestsProgress.length === 1) {
                                        dispatch(closeDraggableFrame({
                                            type: DraggableFrameType.TableReportAttachmentUploadFrame,
                                            id: frameEntity?.id,
                                        }));
                                    }
                                    dispatch(removeRequestProgress(progressEntity.id));
                                }}
                                className={cn('table-report-attachment-upload-progress-item__title__icon')}
                            />
                        </Popover>
                    )
                }

                {progressEntity.title ?? `Запрос ${progressEntity.id}`}
            </div>
            <Progress percent={progressEntity.progressPercent} />
        </div>
    );
};
