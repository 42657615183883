import {Space, Tooltip} from 'antd';
import get from 'lodash/get';
import React from 'react';

import {DynamicIcon} from 'components/dynamic-icon';
import {TIconType} from 'components/dynamic-icon/dynamic-icon';
import {FieldMeta} from 'modules/metadata';
import {fieldStructureType} from 'modules/metadata/metadata-types';
import {downloadFile, showMessageFromResponse} from 'shared/utils';

import {CommonMessages} from '../../../../shared/constants/messages';
import {DEFAULT_ICON_COLOR} from './icon-cell.constants';
import './icon-cell.less';

interface IconsCellProps {
    field: FieldMeta;
    record: Record<string, any>;
}

export const IconsCell: React.FunctionComponent<IconsCellProps> = ({
    field, record,
}: IconsCellProps) => {
    const {fieldStructure} = field;
    const renderIcons = () => fieldStructure?.map(({
        fieldKey, iconName, prefixForField = '', hideLabel = false, referenceUrl = '', iconColor, iconSize,
    }: fieldStructureType) => {
        const content = get(record, fieldKey, '');
        const title = prefixForField + content;
        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            if (referenceUrl) {
                const link = `${referenceUrl}/${record.id}`;
                downloadFile(link, content).then(data => {
                    if (data.status === 200) {
                        showMessageFromResponse({
                            response: data,
                            isError: false,
                            customMessage: CommonMessages.DOWNLOAD_FILE_SUCCESS,
                        });
                    }
                }).catch(error => {
                    showMessageFromResponse({response: error?.response, isError: true});
                });
            }
        };
        return content && (!hideLabel ? (
            <Tooltip
                key={fieldKey}
                placement="top"
                title={title}
            >
                <DynamicIcon
                    style={{fontSize: iconSize || 16, color: iconColor || DEFAULT_ICON_COLOR}}
                    type={iconName as TIconType}
                    onClick={handleClick}
                />
            </Tooltip>
        ) : (
            <DynamicIcon
                style={{fontSize: iconSize || 16, color: iconColor || DEFAULT_ICON_COLOR}}
                type={iconName as TIconType}
            />
        ));
    });

    return (
        <Space>
            {renderIcons()}
        </Space>
    );
};
