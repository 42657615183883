import React, {useEffect, useState} from 'react';

import {List} from 'components/list/List';
import {Spinner} from 'components/spinner';
import {EntityValue} from 'modules/data';
import {selectMetadata, loadMetadata as loadMetadataAction} from 'modules/metadata';
import {EntityType} from 'shared/constants/entities';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {selectUserInfo} from 'store/slices/auth-slice';

import '../tooltip-list/tooltip-list.less';

interface TooltipListProps {
    entityName?: string;
}

export const CurrentUserInfoTooltip = ({entityName = ''}: TooltipListProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();
    const listMeta = useAppSelector(state => selectMetadata(entityName, EntityType.LIST)(state));
    const currentUser = useAppSelector(selectUserInfo);

    useEffect(() => {
        (async () => {
            if (!listMeta) {
                setIsLoading(true);
                await dispatch(loadMetadataAction(entityName, EntityType.LIST));
                setIsLoading(false);
            }
        })();
    }, []);

    return (
        isLoading ? <Spinner /> : (
            <div className="tooltip-list">
                <List
                    listData={{data: currentUser as unknown as Record<string, EntityValue>, id: 'currentUser'}}
                    meta={listMeta}
                    showTitle={false}
                />
            </div>
        )
    );
};
