import {Input, InputNumber as AntInputNumber} from 'antd';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {selectEntityData} from 'modules/data';
import {loadNumberData} from 'modules/data/data-actions';
import {selectReferenceDataLoading} from 'modules/data/data-selectors';
import {NumberEntityData} from 'modules/data/data-types';
import {EntityType} from 'shared/constants/entities';
import {useAppSelector} from 'store/config/hooks';

import {FormMode} from '../../form-types';
import {CustomSelectSpinner} from '../custom-select/components/custom-select-spinner';

import '../input-number/input-number.less';

interface InputNumberDefaultValueProps {
    referenceUrl?: string;
    disabled?: boolean;
    placeholder?: string;
    formMode?: string;
    entityName?: string;
    key: string;
}

export const InputNumberDefaultValue = ({
    referenceUrl, formMode, entityName, key, ...props
}: InputNumberDefaultValueProps) => {
    const isCreate = formMode === FormMode.CREATE;
    const dispatch = useDispatch();

    const defaultValue = useAppSelector(selectEntityData(entityName || '', EntityType.NUMBER)) as NumberEntityData;
    const isLoading = useAppSelector(selectReferenceDataLoading(entityName || ''));

    useEffect(() => {
        (async () => {
            if (referenceUrl && !isLoading && !defaultValue && isCreate) {
                await dispatch(loadNumberData(referenceUrl, entityName || '', key));
            }
        })();
    }, []);

    if (isLoading && isCreate) {
        return (
            <Input
                className="input__number"
                disabled={isLoading}
                prefix={<CustomSelectSpinner />}
            />
        );
    } if (isCreate) {
        return (
            <AntInputNumber
                className="input__number"
                defaultValue={defaultValue?.[key] as number}
                {...props}
            />
        );
    }

    return (
        <AntInputNumber
            className="input__number"
            {...props}
        />
    );
};
