import {Card} from 'antd';
import React from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {
    selectEntityData,
    DirectoryEntityData,
    loadDirectoryData as loadDirectoryDataAction,
} from 'modules/data';
import {setData} from 'modules/data/data-actions';
import {
    selectMetadata,
    DirectoryEntityMeta,
    loadMetadata as loadMetadataAction,
} from 'modules/metadata';
import {EntityType} from 'shared/constants/entities';
import {useAppSelector} from 'store/config/hooks';

import {Spinner} from '../spinner';
import {SubsectionSwitcher} from '../subsection-switcher/subsection-switcher';
import {TableAction} from '../table/action';
import {Directory} from './directory';

interface DirectoryContainerProps {
    entityName: string;
    url: string;
}

export const DirectoryContainer: React.FunctionComponent<DirectoryContainerProps> = ({
    entityName,
    url,
}: DirectoryContainerProps) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const metadata = useAppSelector(selectMetadata(entityName, EntityType.TABS)) as DirectoryEntityMeta;
    const data = useAppSelector(selectEntityData(entityName, EntityType.TABS)) as DirectoryEntityData;
    const isLoaded = metadata?.fields && data?.rows;

    const setCurrentTab = (tab: string) => {
        dispatch(setData({entityName, entityType: EntityType.TABS, data: {tab}}));
    };

    React.useEffect(() => {
        if (!metadata) {
            dispatch(loadMetadataAction(entityName, EntityType.TABS));
        } else if (!data || !data?.rows) {
            dispatch(loadDirectoryDataAction(entityName));
        }
    }, [data, entityName, metadata]);

    React.useEffect(() => {
        if (data?.rows && data.rows.length) {
            setCurrentTab(data.rows[0][metadata.linkField || 'id'] as string);
        }
    }, [data?.rows]);

    return isLoaded ? (
        <Card
            title={metadata.title}
            className="directory-container"
        >
            {metadata?.actions?.length || metadata?.subsections?.length
                ? (
                    <div className="directory-container__actions">
                        {Boolean(metadata?.actions?.length) && metadata?.actions && metadata.actions.map(action => (
                            <TableAction
                                meta={action}
                                key={action.actionTitle}
                                entityName={entityName}
                                url={url}
                                link={`${metadata.name}/${data?.tab || ''}`}
                            />
                        ))}
                        {metadata?.subsections && Boolean(metadata?.subsections?.length) && (
                            <SubsectionSwitcher
                                entityName={entityName}
                                location={location.pathname}
                                subsections={metadata.subsections}
                            />
                        )}
                    </div>
                ) : null}
            <Directory
                url={url}
                metadata={metadata}
                data={data}
                currentTab={data?.tab || ''}
                setCurrentTab={setCurrentTab}
            />
        </Card>
    ) : <Spinner />;
};
