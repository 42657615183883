import {PlusCircleOutlined} from '@ant-design/icons';
import {
    Button,
    Form,
} from 'antd';
import cn from 'classnames';
import React from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {ReportConfigurationFormName} from 'components/report-configuration/report-configuration.constants';
import {ReactComponent as TrashXOutlined} from 'shared/assets/trash-x.svg';
import {useAppSelector} from 'store/config/hooks';
import {
    selectReportConfigurationTableAttributesAsSelectEntries,
} from 'store/slices/report-configuration-slice/report-configuration-slice';

import '../report-comparision.less';

interface ReportComparisonListProps {
    name: string;
    parentFieldName: number;
    nameCheckUniqueness?: string;
    messageCheckUniqueness?: string;
    isDisabled?: boolean;
    buttonTitle?: string;
    fieldTitle?: string;
    fieldPlaceholder?: string;
    emptyFieldsLabel?: string;
    className?: cn.Argument;
}

export const ReportComparisonList: React.FC<ReportComparisonListProps> = (
    {
        name,
        isDisabled,
        buttonTitle,
        fieldTitle,
        fieldPlaceholder,
        nameCheckUniqueness,
        messageCheckUniqueness,
        parentFieldName,
        emptyFieldsLabel,
        className,
    }: ReportComparisonListProps,
) => {
    const tableAttributesEntries = useAppSelector(selectReportConfigurationTableAttributesAsSelectEntries);

    return (
        <Form.List name={[parentFieldName, name]} >
            {(fields, {add, remove}, {errors}) => (
                <>
                    <Button
                        className={cn('button-add-field', className)}
                        type="primary"
                        onClick={() => add()}
                        disabled={isDisabled}
                        icon={(<PlusCircleOutlined className="svg-icon-size-16" />)}
                    >{buttonTitle || 'Добавить'}
                    </Button>
                    <Form.ErrorList errors={errors} />
                    <div className="report-comparison-form-list">
                        {!fields.length && emptyFieldsLabel && (
                            <div className="report-comparison-form-list__empty-fields">
                                {emptyFieldsLabel}
                            </div>
                        )}
                        {fields?.map(field => (
                            <div
                                key={field.fieldKey}
                                className={cn('form-list__items')}
                            >
                                <Form.Item
                                    style={{width: 300}}
                                    label={fieldTitle}
                                    name={[field.name]}
                                    rules={[
                                        {required: true},
                                        ({getFieldValue}) => ({
                                            validator: (_, value) => {
                                                const listValues = getFieldValue(
                                                    [ReportConfigurationFormName.REPORT_COMPARISON,
                                                        parentFieldName, name],
                                                );

                                                const listOtherFieldValues = nameCheckUniqueness && getFieldValue(
                                                    [ReportConfigurationFormName.REPORT_COMPARISON,
                                                        parentFieldName, nameCheckUniqueness],
                                                );

                                                const sameOrdinals = listValues
                                                    ?.filter((entry: any) => !!value
                                                        && entry === value);
                                                const sameOtherFieldOriginals = listOtherFieldValues
                                                    ?.filter((entry: any) => !!value
                                                        && entry === value);
                                                if (sameOrdinals?.length > 1) {
                                                    return Promise.reject(new Error(
                                                        'Уже существует',
                                                    ));
                                                }
                                                if (sameOtherFieldOriginals && sameOtherFieldOriginals?.length >= 1) {
                                                    return Promise.reject(new Error(
                                                        messageCheckUniqueness || 'Уже существует',
                                                    ));
                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <CustomSelect
                                        entries={tableAttributesEntries}
                                        settings={{
                                            placeholder: fieldPlaceholder,
                                            isDisabled,
                                            showSearch: true,
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label={<>&nbsp;</>}>
                                    <Button
                                        className="button-only-icon"
                                        type="primary"
                                        disabled={isDisabled}
                                        icon={<TrashXOutlined />}
                                        onClick={() => remove(field.name)}
                                    />
                                </Form.Item>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </Form.List>

    );
};
