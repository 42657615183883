import {PlusCircleOutlined} from '@ant-design/icons';
import {unwrapResult} from '@reduxjs/toolkit';
import {
    Button,
} from 'antd';
import {FormInstance} from 'antd/es/form';
import {NamePath} from 'antd/lib/form/interface';
import React, {
    useContext, useRef, useState,
} from 'react';

import {ModalOpenerComponent, ModalOpenerComponentRef} from 'components/modal-opener-component';
import {showMessage, showMessageFromResponse} from 'shared/utils';
import {useAppDispatch} from 'store/config/hooks';
import {
    createDrilldownReferenceRule,
    DrillDownReferenceRulePostRequestArgs,
    editDrilldownReferenceRule,
} from 'store/slices/report-configuration-slice/dd-references-configuration';
import {
    reportConfigurationActions,
} from 'store/slices/report-configuration-slice/report-configuration-slice';

import {ReportDdReferenceRulesContext} from '../report-dd-references-utils';
import {DdReferencesEditModalForm} from './dd-references-edit-modal-form';

interface DdReferencesEditModalOpenerProps {
   defaultInitialValues?: {[index: string]: any};
   component?: {
        type: 'link' | 'button';
        text: string;
   };
   extraPathsToPickData?: NamePath[];
   mode?: 'edit' | 'create';
}

export const DdReferencesEditModalOpener: React.FC<DdReferencesEditModalOpenerProps> = (
    {
        defaultInitialValues,
        component = {
            text: 'Создать правило',
            type: 'button',
        },
        extraPathsToPickData,
        mode = 'create',
    }: DdReferencesEditModalOpenerProps,
) => {
    const dispatch = useAppDispatch();

    const {updateDdReferenceRulesFilter} = reportConfigurationActions;

    const {isLoading} = useContext(ReportDdReferenceRulesContext);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const formRef = useRef<FormInstance>(null); // форма через ref для корректного mount/unmount и сброса данных
    const modalOpenerRef = useRef<ModalOpenerComponentRef>(null);

    const handleFinish = (values: any) => {
        const valuesForRequest: DrillDownReferenceRulePostRequestArgs = {
            ...values,
            ...formRef.current?.getFieldsValue(extraPathsToPickData ?? []),
        };

        setIsSubmitting(true);

        const implementStrategy = mode === 'create' ? createDrilldownReferenceRule : editDrilldownReferenceRule;

        dispatch(implementStrategy(valuesForRequest))
            .then(unwrapResult)
            .then(() => {
                const id = formRef.current?.getFieldValue('id');
                if (mode === 'create') {
                    dispatch(updateDdReferenceRulesFilter({
                        paginationCurrent: 1,
                    }));
                    showMessage({message: 'Правило связи успешно создано'});
                } else {
                    showMessage({message: `Правило связи #${id} успешно изменено`});
                }
                modalOpenerRef.current?.setIsModalOpen(false);
            }, err => {
                showMessageFromResponse({response: err, isError: true});
            }).finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <div>
            <ModalOpenerComponent
                ref={modalOpenerRef}
                shouldConfirm={() => !!formRef.current?.isFieldsTouched?.()}
                handleSave={() => {
                    formRef.current?.submit();
                }}
                component={(() => {
                    const {text, type} = component;
                    if (type === 'button') {
                        return (
                            <Button
                                disabled={isLoading}
                                type="primary"
                                icon={<PlusCircleOutlined />}
                            >
                                {text}
                            </Button>
                        );
                    }
                    return <Button type="link">{text}</Button>;
                })()}
                modalProps={{
                    title: mode === 'create' ? 'Создание правила связи' : 'Редактирование правила связи',
                    forceRender: false,
                    destroyOnClose: true,
                    centered: true,
                }}
                disabledControls={{
                    save: isSubmitting,
                }}
            >
                <DdReferencesEditModalForm
                    ref={formRef}
                    handleFinish={handleFinish}
                    defaultInitialValues={defaultInitialValues}
                    mode={mode}
                />
            </ModalOpenerComponent>
        </div>
    );
};
