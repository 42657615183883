import {
    createSelector,
    Selector,
} from '@reduxjs/toolkit';

import {AppState} from 'store/config/types';

import {BREADCRUMBS_MODULE} from './breadcrumbs-constants';
import {initBreadcrumbsState} from './breadcrumbs-types';

const rootSelector: Selector<AppState, initBreadcrumbsState> = (state: AppState) => state[BREADCRUMBS_MODULE];

export const selectBreadcrumbsData = (entityName: string) => createSelector(
    rootSelector,
    (dataState: initBreadcrumbsState) => dataState[entityName]?.breadcrumbs,
);
