import {
    EntityMeta, FieldType, MetaActionType, RequestType, ViewType,
} from '../../metadata/metadata-types';
import {TMetaOptions} from '../metadata-constructor.types';
import {COMMON_OPTIONS} from './types/common/common.options';
import {METADATA_FILTER} from './types/filter/filter.meta';
import {METADATA_FORM_TABLE} from './types/form-table/form-table.meta';
import {METADATA_FORM} from './types/form/form.meta';
import {METADATA_TABLE} from './types/table/table.meta';
import {METADATA_TABS} from './types/tabs/tabs.meta';

export const METADATA_DEFAULT: EntityMeta = {
    name: 'metadata.constructor',
    title: 'Конструктор метаданных',
    linkField: 'id',
    viewType: ViewType.FORM,
    dataKeyForFilterFields: 'viewType',
    fields: [
        {
            key: 'viewType',
            label: 'Тип отображения данных',
            type: FieldType.STATIC_SELECT,
            isRequired: true,
            options: COMMON_OPTIONS.VIEW_TYPES,
            hint: 'Определяет к какому entityType относятся метаданные.',
        },
        {
            key: 'name',
            label: 'Название сущности',
            type: FieldType.STRING,
            hint: `Название сущности(entityName),
            к которой относится метаданные.
            Должно соответствовать имеющимся сущностям в БД.`,
            isRequired: true,
        },
        {
            key: 'title',
            label: 'Заголовок',
            type: FieldType.STRING,
            hint: 'Заголовок страницы',
        },
        {
            key: 'linkField',
            label: 'Название поля с индивидуальным значением',
            type: FieldType.STRING,
            hint: `Значение которого будет использоваться для получения данных при редактировании записи таблиц.
            Если поле для таблицы не заданно, то редактирование записей таблицы не доступно.
            Для вкладок использует значение в качестве ключа.
            Для форм это поле определяет, какое значение поля использовать в качестве идентификатора`,
        },
    ],
    actions: [
        {
            referenceUrl: 'directories.type.documents',
            requestType: RequestType.POST,
            actionType: MetaActionType.BUTTON_SAVE_FORM,
            actionTitle: 'Сохранить',
            actionCode: 'SAVE_FORM',
        },
    ],
};

export const METADATA_OPTIONS: TMetaOptions = {
    [ViewType.FORM]: METADATA_FORM,
    [ViewType.TABLE]: METADATA_TABLE,
    [ViewType.FORM_TABLE]: METADATA_FORM_TABLE,
    [ViewType.TABS]: METADATA_TABS,
    [ViewType.FILTER]: METADATA_FILTER,
};
