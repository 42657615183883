import {showMessageFromResponse} from 'shared/utils/show-message-from-response';
import {createAction} from 'store/config/creators';
import {AppState, Dispatch} from 'store/config/types';

import {fetchRegions} from './regions-api';
import {RegionsActions} from './regions-constants';
import {selectRegions} from './regions-selectors';
import {RegionResponse} from './regions-types';

const setRegions = createAction<RegionResponse[]>(RegionsActions.SET_REGIONS);

export const loadRegions = () => async (dispatch: Dispatch, getState: () => AppState) => {
    const regions = selectRegions(getState());
    if (!regions) {
        try {
            const {data: regionsData} = await fetchRegions();

            dispatch(setRegions(regionsData));
        } catch (e) {
            dispatch(setRegions([]));
            showMessageFromResponse({response: e.response, isError: true});
        }
    }
};
