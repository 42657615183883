import {SettingOutlined} from '@ant-design/icons';
import {
    Button, Checkbox, Popover, Spin, Table,
} from 'antd';
import Column, {ColumnProps} from 'antd/es/table/Column';
import cn from 'classnames';
import React, {useContext} from 'react';

import {ReportConfigurationTabKey} from 'components/report-configuration/report-configuration.constants';
import {ReportConfigurationContext} from 'components/report-configuration/report-configuration.context';
import {PaginationArgs} from 'shared/hooks/use-pagination';
import {useQueryParams} from 'shared/utils/query-params/use-query-params.hook';
import {useAppSelector} from 'store/config/hooks';
import {ReportDdReferenceRule} from 'store/slices/report-configuration-slice/dd-references-configuration';
import {ddReferenceRulesSelectors} from 'store/slices/report-configuration-slice/report-configuration-slice';

import {DdReferencesEditModalOpener} from '../dd-references-edit-modal-opener';
import {ReportDdReferenceRulesContext} from '../report-dd-references-utils';
import {ExtraAttributesModalOpener} from './extra-attributes-modal-opener';

import './dd-references-table.less';

interface DdReferenceTableColumn<T=string> {
    key: T;
    title: string; formatter?:
    ColumnProps<any>['render'];
    width?: string | number;
}
interface DdReferencesTableProps {
    pagination: PaginationArgs;
    selection: {
        selectedRowKeys: number[];
        setSelectedRowKeys: React.Dispatch<React.SetStateAction<number[]>>;
    };
}

export const DdReferencesTable: React.FC<DdReferencesTableProps> = ({
    pagination,
    selection,
}: DdReferencesTableProps) => {
    const {current, handleChange, pageSize} = pagination;
    const {selectedRowKeys, setSelectedRowKeys} = selection;

    const drilldownReferenceRules = useAppSelector(ddReferenceRulesSelectors.selectAll);
    const drilldownReferenceRulesTotalCount = useAppSelector(
        ddReferenceRulesSelectors.selectDdReferenceRulesTotalCount,
    );

    const {isLoading} = useContext(ReportDdReferenceRulesContext);
    const {setSelectedTabKey} = useContext(ReportConfigurationContext);

    const {updateQueryParams} = useQueryParams();

    const columns: DdReferenceTableColumn<string | keyof ReportDdReferenceRule>[] = [{
        key: 'id',
        title: 'Правило связи (ID)',
        formatter: (value, record) => (
            <div>
                <DdReferencesEditModalOpener
                    defaultInitialValues={record}
                    component={{
                        text: value,
                        type: 'link',
                    }}
                    mode="edit"
                    extraPathsToPickData={['id']}
                />
            </div>
        ),
    },
    {
        key: 'Rules',
        title: '',
        formatter: (_, record) => (
            <Popover content="Настройка правил фильтрации">
                <Button
                    className="button-only-icon"
                    type="default"
                    style={{maxWidth: 36}}
                    onClick={() => {
                        const {id} = record;
                        if (id) {
                            updateQueryParams([{name: 'drillDownId', value: id}], {action: 'replace'});
                        }
                        setSelectedTabKey?.(ReportConfigurationTabKey.REPORT_DD_FILTERS);
                    }}
                >
                    <SettingOutlined />
                </Button>
            </Popover>
        ),
    },
    {
        key: 'parentDocumentType',
        title: 'Исходный документ',
        formatter: value => value?.meaning ?? '-',
    },
    // {
    //     key: 'linkColumnName',
    //     title: 'Исходное поле для перехода',
    // },
    {
        key: 'childDocumentType',
        title: 'Конечный документ',
        formatter: value => value?.meaning ?? '-',
    },
    {
        key: 'context',
        title: 'Доп. атрибуты',
        formatter: (_, record: ReportDdReferenceRule) => <ExtraAttributesModalOpener referenceRuleRecord={record} />,
    },
    // {
    //     key: 'startDate',
    //     title: 'Дата начала действия',
    // },
    // {
    //     key: 'endDate',
    //     title: 'Дата окончания действия',
    // },
    {
        key: 'enabled',
        title: 'Признак активности',
        formatter: value => (
            <Checkbox
                disabled
                checked={value}
            />
        ),
    },
    ];

    return (
        <Spin spinning={isLoading}>
            <Table
                className={cn('dd-references-table')}
                rowKey="id"
                scroll={{x: 'max-content'}}
                rowSelection={{
                    selectedRowKeys,
                    onChange: (newSelectedRowKeys: React.Key[]) => {
                        setSelectedRowKeys(newSelectedRowKeys as number[]);
                    },
                }}
                pagination={{
                    position: ['bottomLeft'],
                    total: drilldownReferenceRulesTotalCount,
                    current,
                    pageSize,
                    onChange: handleChange,
                }}
                dataSource={drilldownReferenceRules}
            >
                {
                    columns.map(({
                        key, title, formatter, width,
                    }) => (
                        <Column
                            dataIndex={key}
                            key={key}
                            title={title}
                            render={formatter}
                            width={width}
                        />
                    ))
                }
            </Table>
        </Spin>

    );
};
