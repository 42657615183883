import {Popover} from 'antd';
import React from 'react';

import {DynamicIcon} from 'components/dynamic-icon';
import {FORM_QUERY_PARAMS} from 'pages/table-page/query-params-aware-table/constants/table-query-params';
import {useQueryParams} from 'shared/utils/query-params/use-query-params.hook';

import './open-report-configuration-form.less';

interface OpenReportConfigurationFormProps {
    templateCode: string;
}

export const OpenReportConfigurationForm = ({templateCode}: OpenReportConfigurationFormProps) => {
    const {updateQueryParams} = useQueryParams();
    const handleClick = () => {
        updateQueryParams([{name: FORM_QUERY_PARAMS.entity, value: templateCode}]);
    };

    return (
        <Popover
            overlayClassName="open-report-configuration-form__popover"
            placement="left"
            content={() => 'Перейти к настройке'}
        >
            <DynamicIcon
                type="Settings"
                className="open-report-configuration-form__settings-icon"
                onClick={() => handleClick()}
            />
        </Popover>
    );
};
