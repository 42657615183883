import cn from 'classnames';
import React from 'react';

import {ReactComponent as ArrowDown} from 'shared/assets/arrow-down-filled.svg';

import './aside-row.less';

interface AsideRowProps {
    name: string;
    hasChildren?: boolean;
    depth?: number;
    active?: boolean;
    onClick?: () => void;
    open?: boolean;
    onDropdown?: (event: React.MouseEvent<SVGSVGElement>) => void;
}

export const AsideRow = ({
    name,
    hasChildren,
    depth,
    active,
    onClick,
    open,
    onDropdown,
}: AsideRowProps) => (
    <div
        className={cn('aside-row', {active}, {bold: hasChildren})}
        style={{paddingLeft: `calc(${depth} * 16px + 8px)`}}
        onClick={onClick}
    >
        {name}
        {hasChildren && (
            <ArrowDown
                className={`aside-row__icon${cn({' open': open, ' inactive': !hasChildren})}`}
                onClick={onDropdown ? e => onDropdown(e) : undefined}
            />
        )}
    </div>
);
