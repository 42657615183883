import {selectContextRawData} from 'modules/context/context-selector';
import {selectEntityData, EntityValue} from 'modules/data';
import {selectReferenceEntityData} from 'modules/data/data-selectors';
import {FilterEntityData} from 'modules/data/data-types';
import {FieldMeta} from 'modules/metadata';
import {EntityType} from 'shared/constants/entities';
import {useAppSelector} from 'store/config/hooks';
import {selectUserInfo} from 'store/slices/auth-slice';

export enum DefaultValueRuleType {
    USER_TAX_PERIOD = 'userTaxPeriod',
    USER_ORGANIZATION = 'userOrganization',
    CURRENT_DATE = 'currentDate',
    CURRENT_USER = 'currentUser',
    FILTER_VALUE = 'filterValue',
    DEFAULT_LOOK_UP_VALUE = 'defaultLookUpValue'
}

export const useDefaultValueByRule = (
    entityName: string,
    fieldMeta: FieldMeta | undefined,
) => {
    const currentUser = useAppSelector(selectUserInfo);
    const context: EntityValue = useAppSelector(selectContextRawData);
    const filter = useAppSelector(selectEntityData(entityName, EntityType.FILTER)) as FilterEntityData;
    const referenceOptions = useAppSelector(selectReferenceEntityData(fieldMeta?.referenceUrl || ''));

    const selectedFilterData: any = filter?.data?.[fieldMeta?.dependentInputKey || ''];

    const getDefaultValue = () => {
        switch (fieldMeta?.defaultValueByRule) {
        case DefaultValueRuleType.USER_TAX_PERIOD:
            return context?.taxPeriodId || undefined;

        case DefaultValueRuleType.USER_ORGANIZATION:
            return context?.organizationId || undefined;

        case DefaultValueRuleType.CURRENT_DATE:
            return new Date();

        case DefaultValueRuleType.CURRENT_USER:
            return currentUser?.fullName;

        case DefaultValueRuleType.FILTER_VALUE:
            return referenceOptions?.find(
                option => (option?.lookupCode === selectedFilterData?.lookupCode
                    && option?.id === selectedFilterData?.id) || option?.id === selectedFilterData,
            );

        case DefaultValueRuleType.DEFAULT_LOOK_UP_VALUE:
            // attribute1 - дефолтное значение для поля monitorProgramId в directories.type.documents
            return referenceOptions?.find(option => !!option?.isDefault
                || (option?.attribute1 && option?.attribute1 === 'Y'));

        default:
            return undefined;
        }
    };

    return {
        getDefaultValue,
    };
};
