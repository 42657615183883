import {PlusCircleOutlined} from '@ant-design/icons';
import {Button, Empty} from 'antd';
import Form, {FormInstance} from 'antd/es/form';
import cn from 'classnames';
import {get, set} from 'lodash';
import React, {useContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {SimpleActionButton} from 'components/form/components';
import {ModalOpenerComponent} from 'components/modal-opener-component';
import {loadReferenceData} from 'modules/data/data-actions';
import {selectReferenceEntityData} from 'modules/data/data-selectors';
import {ReactComponent as TrashX} from 'shared/assets/trash-x.svg';
import {useAppSelector} from 'store/config/hooks';

import {ReportConfigurationContext} from '../../../../report-configuration.context';
import {FormatFormItem} from './components/format-form-item/format-form-item';
import {getLookupCodeValue} from './components/format-form-item/utils/format-form.item.utils';
import {LOOKUP_VALUE_CODES} from './constants/security-settings-modal-constants';
import {generateKey} from './utils/security-settings-modal.utils';
import '../row-formats-modal-opener/row-formats-modal-content/row-formats-modal-content.less';

interface SecuritySettingsModalProps {
    name: number;
    disabled: boolean;
    formInstance: FormInstance;
}

export const SecuritySettingsModal = ({
    name,
    disabled,
    formInstance,
}: SecuritySettingsModalProps) => {
    const dispatch = useDispatch();
    const {templateCode} = useContext(ReportConfigurationContext);
    const connectionRulesCode : string = getLookupCodeValue(templateCode);

    const [rowValuesOnModalOpen, setRowValuesOnModalOpen] = useState<any>();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedRows, setSelectedRows] = useState<number[]>([]);

    const selectAllRoles = useAppSelector(selectReferenceEntityData(LOOKUP_VALUE_CODES.ALL_ROLE));
    const selectLimitFunctions = useAppSelector(selectReferenceEntityData(LOOKUP_VALUE_CODES.LIMIT_FUNCTIONS));
    const selectDrillDown = useAppSelector(selectReferenceEntityData(connectionRulesCode));
    const selectTableReportFilterView = useAppSelector(
        selectReferenceEntityData(LOOKUP_VALUE_CODES.TABLE_REPORT_FILTER_VIEW),
    );

    useEffect(() => {
        const dataToCheck = [
            {value: selectAllRoles, key: LOOKUP_VALUE_CODES.ALL_ROLE},
            {value: selectLimitFunctions, key: LOOKUP_VALUE_CODES.LIMIT_FUNCTIONS},
            {value: selectTableReportFilterView, key: LOOKUP_VALUE_CODES.TABLE_REPORT_FILTER_VIEW},
            {value: selectDrillDown, key: connectionRulesCode},
        ];

        dataToCheck.forEach(async ({value, key}) => {
            if (!value) {
                await dispatch(loadReferenceData(key));
            }
        });
    }, []);

    return (
        <ModalOpenerComponent
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onShow={() => {
                setRowValuesOnModalOpen(formInstance.getFieldValue('reportColumns')?.[name]);
            }}
            handleCancel={setIsModalOpen => {
                const {blockedForRoleAndFunctionNames} = rowValuesOnModalOpen ?? {};
                const reportColumns = formInstance.getFieldValue('reportColumns');
                const reportColumnRow = get(reportColumns, name);
                set(reportColumns, name, {
                    ...reportColumnRow, blockedForRoleAndFunctionNames,
                });
                formInstance.setFieldsValue({
                    reportColumns,
                });
                setIsModalOpen(false);
            }}
            handleSave={setIsModalOpen => {
                setIsModalOpen(false);
            }}
            component={(
                <>
                    <SimpleActionButton
                        type="primary"
                        className={cn('button-only-icon')}
                        disabled={disabled}
                        icon="SafetyCertificateOutlined"
                    />
                </>
            )}
            modalProps={{
                title: 'Настройки ограничения доступа',
                forceRender: true,
                destroyOnClose: false,
                centered: true,
            }}
        >
            <div className={cn('security-settings-modal')}>
                <Form.List name={[name, 'blockedForRoleAndFunctionNames']}>
                    {(fields, {add, remove}) => (
                        <div className={cn('security-settings-modal')}>
                            <div className={cn('security-settings-modal__buttons')}>
                                <Button
                                    type="primary"
                                    onClick={() => add()}
                                >
                                    <PlusCircleOutlined />
                                    Добавить
                                </Button>
                                <Button
                                    disabled={!selectedRows.length}
                                    type="primary"
                                    onClick={() => {
                                        remove(selectedRows);
                                        setSelectedRows([]);
                                    }}
                                >
                                    <TrashX />
                                    Удалить выбранное
                                </Button>
                            </div>
                            {(() => {
                                if (!fields.length) {
                                    return <Empty description="Данные отсутствуют" />;
                                }
                                return null;
                            })()}

                            {fields.map((field, index) => (
                                <div
                                    key={generateKey([name, 'blockedForRoleAndFunctionNames'].toString(), index)}
                                    className={cn('security-settings-modal__form__row')}
                                >
                                    <FormatFormItem
                                        name={name}
                                        field={field}
                                        fields={fields}
                                        form={formInstance}
                                        selectedRows={selectedRows}
                                        setSelectedRows={setSelectedRows}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                </Form.List>
            </div>
        </ModalOpenerComponent>
    );
};
