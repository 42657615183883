import {Button, Popover} from 'antd';
import cn from 'classnames';
import React, {
    useEffect,
    useImperativeHandle, useMemo, useRef,
    useState,
} from 'react';

import {ReactComponent as FilterIcon} from 'shared/assets/filter.svg';

import {TABLE_COLUMN_FILTER_CLEAR_EVENT_KEY} from './table-column-filter-constants';
import {TableColumnFilterExpressionGroup, TableColumnFilterExpressionGroupRef} from './table-column-filter-expression-group';
import {TableColumnFilterExpression} from './table-column-filter-types';
import {GetTableColumnFilterDataFunction, SetTableColumnFilterDataFunction} from './use-table-column-filter/use-table-column-filter';

import './table-column-filter.less';

export interface TableColumnFilterExpressionCreatorProps {
    className?: cn.Argument;
    columnName: string;
    getTableColumnFilterData: GetTableColumnFilterDataFunction;
    setTableColumnFilterData: SetTableColumnFilterDataFunction;
    isApplied?: boolean;
}

export interface TableColumnFilterExpressionCreatorRef {
    getValuesToSave: () => Promise<TableColumnFilterExpression> | undefined;
    handleClear: () => void;
}

export const TableColumnFilter = React.forwardRef<
TableColumnFilterExpressionCreatorRef, TableColumnFilterExpressionCreatorProps>((
    {
        className,
        columnName,
        setTableColumnFilterData,
        getTableColumnFilterData,
        isApplied,
    }: TableColumnFilterExpressionCreatorProps,
    ref,
) => {
    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const expressionRef = useRef<TableColumnFilterExpressionGroupRef>(null);

    const getValuesToSave = () => expressionRef.current?.getValues() as Promise<TableColumnFilterExpression>;

    const [initialExpression, setInitialExpression] = useState<TableColumnFilterExpression>(
        getTableColumnFilterData() ?? [{connectBy: 'OR'}, {value: '', columnName: undefined}],
    );

    const columnFilter = useMemo(() => (
        <TableColumnFilterExpressionGroup
            ref={expressionRef}
            initialExpression={initialExpression}
            className={className}
            isInEditMode
            depth={0}
            itemIndex="1"
            columnName={columnName}
        />
    ), [initialExpression]);

    const handleClear = (withSet = true) => {
        const initialExpr: TableColumnFilterExpression = [{connectBy: 'OR'}, {value: '', columnName: undefined}];
        setInitialExpression(initialExpr);
        if (withSet) setTableColumnFilterData([{connectBy: 'OR'}]);
    };

    const handleSubmit = async () => {
        try {
            const filterValues = await getValuesToSave();
            setTableColumnFilterData(filterValues);
            setInitialExpression(filterValues);
            setIsPopupVisible(false);
        } catch (e) {
            // pass
        }
    };

    useEffect(() => {
        const handler = () => {
            handleClear(false);
        };
        window.addEventListener(TABLE_COLUMN_FILTER_CLEAR_EVENT_KEY, handler);
        return () => window.removeEventListener(TABLE_COLUMN_FILTER_CLEAR_EVENT_KEY, handler);
    }, []);

    useImperativeHandle(ref, () => ({
        getValuesToSave,
        handleClear,
    }));

    return (
        <Popover
            overlayClassName={cn('table-report__table__filter-popover-overlay')}
            className={cn('table-report__table__filter-popover')}
            placement="bottomLeft"
            trigger="click"
            visible={isPopupVisible}
            onVisibleChange={isVisible => {
                setIsPopupVisible(isVisible);
            }}
            content={(
                <div className={cn('table-column-filter')}>
                    <div className={cn('table-column-filter-expression-creator')}>
                        {columnFilter}
                    </div>
                    <div className={cn('table-column-filter-controls')}>
                        <Button
                            type="primary"
                            size="small"
                            onClick={handleSubmit}
                        >Применить
                        </Button>
                        <Button
                            type="default"
                            size="small"
                            onClick={() => handleClear()}
                        >Очистить
                        </Button>
                    </div>
                </div>
            )}
        >
            <div className="table-report__table__filter-icon">
                <FilterIcon
                    height={16}
                    width={16}
                    style={{color: isApplied ? '#1E72D7' : undefined}}
                />
            </div>
        </Popover>
    );
});
