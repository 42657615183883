import {Button} from 'antd';
import {FormInstance} from 'antd/es/form';
import {AxiosResponse} from 'axios';
import {omitBy} from 'lodash';
import React, {Dispatch, SetStateAction} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {createActionGetFile} from 'modules/data/data-actions';
import {selectEntityData, selectTableEntityData} from 'modules/data/data-selectors';
import {TableEntityData} from 'modules/data/data-types';
import {RequestType} from 'modules/metadata';
import {EntityType} from 'shared/constants/entities';
import {performRequest, showMessageFromResponse} from 'shared/utils';
import {useAppSelector} from 'store/config/hooks';
import {AppState} from 'store/config/types';
import {closeModal} from 'store/slices/modals-slice';

import {DynamicIcon} from '../../../dynamic-icon';
import {TIconType} from '../../../dynamic-icon/dynamic-icon';

interface ButtonDownloadDocumentFileProps {
    actionTitle: string;
    referenceUrl: string;
    requestType: RequestType;
    actionIcon?: TIconType;
    form?: FormInstance;
    onClose?: () => void;
    setFormErrorMessage?: Dispatch<SetStateAction<string | null>>;
    linkedEntityName?: string;
    mainReferenceUrl?: string;
    parentEntityName?: string;
}

interface ButtonDownloadFileProps extends ButtonDownloadDocumentFileProps {
}

export const ButtonDownloadDocumentFile: React.FunctionComponent<ButtonDownloadFileProps> = ({
    actionTitle,
    referenceUrl,
    requestType,
    actionIcon,
    form,
    onClose,
    setFormErrorMessage,
    linkedEntityName,
    mainReferenceUrl,
    parentEntityName,
    ...props
}: ButtonDownloadFileProps) => {
    const dispatch = useDispatch();

    const {selectedRowKeys = []} = useSelector((state: AppState) => (
        selectEntityData(parentEntityName || '',
            EntityType.TABLE)(state) || {}
    ) as TableEntityData);
    const docId = selectedRowKeys[0];

    const rows = useAppSelector(selectTableEntityData(parentEntityName || ''))?.rows;
    const sectionCodeContext: string = String(rows?.slice(0, 1).shift()?.sectionCode);

    const handleClick = async () => {
        if (!form || !onClose) {
            return;
        }
        try {
            if (mainReferenceUrl) {
                const response = await performRequest({
                    url: mainReferenceUrl,
                    method: 'GET',
                    params: {sectionCodeContext, docId},
                });
                if (response.status === 200) {
                    form.validateFields().then(async data => {
                        if (setFormErrorMessage) {
                            setFormErrorMessage(null);
                        }

                        const requestData = {...data, docId};
                        const responseFile = await dispatch(
                            createActionGetFile({
                                referenceUrl,
                                requestType,
                                data: {},
                                isMessage: true,
                                linkedEntityName,
                                body: omitBy(requestData, v => v === '' || v === null),
                            }),
                        ) as unknown as AxiosResponse;
                        const blob = response?.data;
                        if (responseFile?.status === 200) {
                            dispatch(closeModal());
                        } else if (blob instanceof Blob && blob.type === 'text/plain' && setFormErrorMessage) {
                            setFormErrorMessage(await blob.text());
                        } else {
                            showMessageFromResponse({response: responseFile, isError: true});
                        }
                    });
                }
            }
        } catch (e) {
            console.error(e);
            showMessageFromResponse({response: e.response, isError: true});
        }
    };

    return (
        <Button
            {...props}
            type="primary"
            style={{width: '100%'}}
            onClick={handleClick}
        >
            {actionIcon && <DynamicIcon type={actionIcon} />}
            {actionTitle}
        </Button>
    );
};
