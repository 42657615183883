import {StateSetter} from '../../shared/types/generics';
import {performRequest, showMessage} from '../../shared/utils';

export const activateUploadTemplate = async (
    setIsActive: StateSetter<boolean>,
    isActive?: boolean,
    templateCode?: string,
) => {
    if (templateCode) {
        try {
            await performRequest({
                method: 'GET',
                url: `/administration.documentupload.templates/${templateCode}/activate`,
            });
            setIsActive(active => !active);
            showMessage({
                message: `Валидация пройдена, шаблон успешно ${isActive ? 'деактивирован' : 'активирован'}`,
            });
        } catch {
            showMessage({
                message: `Валидация не пройдена, ${isActive ? 'деактивация' : 'активация'} невозможна`,
                isError: true,
            });
        }
    }
};
