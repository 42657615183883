export const getRegexpFunction = (pattern: string|RegExp) => (flags: string = '') => new RegExp(pattern, flags);

export const pathInStringStructure = getRegexpFunction(/\w[\w.]*\w/);
export const titleNameAndIconName = getRegexpFunction(/\w*[/]\w*/);

export const widthRegExp = new RegExp('^(auto|max-content|min-content|fit-content|inherit)'
+ '|(\\d+((em)|(vh)|(vw)|(px)|(mm)|(cm)|(rem)|%))$');
export const isNumberRegExp = /^\d+$/;

// eslint-disable-next-line max-len
export const urlRegExp = new RegExp('https?:\\/\\/(?:w{1,3}\\.)?[^\\s.]+(?:\\.[a-z]+)*(?::\\d+)?(?![^<]*(?:<\\/\\w+>|\\/?>))');
