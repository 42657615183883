import {InboxOutlined} from '@ant-design/icons/lib';
import {
    message,
    Steps,
    Table,
} from 'antd';
import {FormInstance} from 'antd/es/form';
import Column from 'antd/es/table/Column';
import {RcFile} from 'antd/es/upload';
import Dragger from 'antd/es/upload/Dragger';
import cn from 'classnames';
import React, {useState} from 'react';
import {utils as xlsxUtils, read as xlsxRead} from 'xlsx';

import {SimpleActionButton} from 'components/form/components';
import {HeaderPreview} from 'components/report-configuration/tabs/report-table-settings/modals/excel-header-structure-loader-modal/header-preview/header-preview';
import {StateSetter} from 'shared/types/generics';

import {
    ACCEPTABLE_FILE_EXTENSIONS,
    StepTitle,
    stepIndex,
    XLSX_OPTIONS,
} from '../excel-header-structure-loader-modal.constants';
import {
    DataSourcePreview,
    ExcelRawData,
    PreviewTableData,
    TableHeaderStructure,
} from '../excel-header-structure-loader-modal.types';
import {
    constructHeaderTree,
    convertExcelRawDataToTemporaryTableData,
    markMergedCells,
} from '../excel-header-structure-loader-modal.utils';

import './excel-header-structure-loader.less';

interface ExcelHeaderStructureLoaderProps {
    form: FormInstance;
    currentStep: number;
    setCurrentStep: StateSetter<number>;
    previewTableData: PreviewTableData | undefined;
    setPreviewTableData: StateSetter<PreviewTableData | undefined>;
    selectedHeaderRows: DataSourcePreview[] | undefined;
    setSelectedHeaderRows: StateSetter<DataSourcePreview[] | undefined>;
    headerTree: TableHeaderStructure | undefined;
    setHeaderTree: StateSetter<TableHeaderStructure | undefined>;
    resetData: () => void;
}

export const ExcelHeaderStructureLoader = ({
    form,
    currentStep,
    setCurrentStep,
    previewTableData,
    setPreviewTableData,
    selectedHeaderRows,
    setSelectedHeaderRows,
    headerTree,
    setHeaderTree,
    resetData,
}: ExcelHeaderStructureLoaderProps) => {
    const [excelRawData, setExcelRawData] = useState<ExcelRawData>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const handleHeaderRowsSelectedClick = () => {
        const selectedRows = previewTableData?.dataSource
            ?.filter((el, index) => selectedRowKeys.includes(index));
        const extractedData = excelRawData
            ?.map((row, index) => (selectedRowKeys.includes(index) ? row : null))
            ?.filter(row => row?.length);

        form.resetFields(); // перед созданием нового дерева чистим форму
        const headerStructureTree = constructHeaderTree(extractedData);
        setHeaderTree(headerStructureTree);

        if (selectedRows) {
            setSelectedHeaderRows(selectedRows);
        }
        setCurrentStep(c => c + 1);
    };

    const parseFileHeader = (file: RcFile) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = (e: any) => {
            const data = e.target.result;

            if (!data) {
                message.error('Невозможно получить содержимое');
                return;
            }

            const workbook = xlsxRead(data, {type: 'buffer'});
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            // копия листа с помеченными объедененными ячейками
            const markedMergedCellsSheet = markMergedCells(sheet);

            const rawData: string[][] = xlsxUtils.sheet_to_json(sheet, XLSX_OPTIONS);
            const markedExcelRawData: string[][] = xlsxUtils.sheet_to_json(markedMergedCellsSheet, XLSX_OPTIONS);
            if (rawData && markedExcelRawData) {
                // удаляем пустые строки
                const filteredExcelData = rawData?.filter(row => row?.filter(el => el !== null)?.length > 0);
                // создаем временную таблицу для отображения
                const convertedData = convertExcelRawDataToTemporaryTableData(filteredExcelData);

                setExcelRawData(markedExcelRawData);
                setPreviewTableData(convertedData);
                setCurrentStep(curr => curr + 1);
            }
        };
    };

    const stepBackButton = (
        <SimpleActionButton
            type="default"
            icon="ArrowBackOutlined"
            title="Назад"
            onClick={() => {
                setSelectedRowKeys([]);
                setHeaderTree(undefined);
                setCurrentStep(c => c - 1);
            }}
        />
    );

    return (
        <div className={cn('excel-header-structure-loader')}>
            <Steps
                className="excel-header-structure-loader__steps"
                current={currentStep}
                size="small"
            >
                <Steps.Step title={StepTitle.FILE_LOADING} />
                <Steps.Step title={StepTitle.ROW_SETTING} />
                <Steps.Step title={StepTitle.PREVIEW_AND_TREE_MAPPING} />
            </Steps>

            {currentStep === stepIndex[StepTitle.FILE_LOADING] && (
                <div
                    className={cn(
                        'excel-header-structure-loader__step',
                        'step-content',
                    )}
                >
                    <div className="step-content__description">
                        Загрузите excel файл с готовой шапкой таблицы
                    </div>
                    <Dragger
                        name="file"
                        accept={ACCEPTABLE_FILE_EXTENSIONS}
                        beforeUpload={file => {
                            parseFileHeader(file);
                            return false;
                        }}
                        onRemove={resetData}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Нажмите или перетащите excel файл в эту область</p>
                    </Dragger>
                </div>
            )}

            {previewTableData && currentStep === stepIndex[StepTitle.ROW_SETTING] && (
                <div
                    className={cn(
                        'excel-header-structure-loader',
                        'step-content',
                    )}
                >
                    <div className="step-content__actions">
                        {stepBackButton}
                    </div>
                    <div className="step-content__description">
                        Выделите строки, которые относятся к шапке таблицы
                        и нажмите кнопку &quot;Подтвердить выбор&quot;
                    </div>
                    <SimpleActionButton
                        type="primary"
                        icon="Confirm"
                        title="Подтвердить выбор"
                        onClick={handleHeaderRowsSelectedClick}
                        disabled={selectedRowKeys?.length < 2}
                    />
                    <Table
                        className="parsed-file-preview-table"
                        dataSource={previewTableData?.dataSource}
                        showHeader={false}
                        pagination={false}
                        rowSelection={{
                            type: 'checkbox',
                            columnWidth: 40,
                            onChange: onSelectChange,
                        }}
                        bordered={false}
                    >
                        {previewTableData?.columns && previewTableData?.columns?.map(col => (
                            <Column
                                className="parsed-file-preview-table__column"
                                key={col?.key}
                                dataIndex={col?.dataIndex}
                                width={100}
                                ellipsis
                            />
                        ))}
                    </Table>
                </div>
            )}

            {selectedHeaderRows && currentStep === stepIndex[StepTitle.PREVIEW_AND_TREE_MAPPING] && headerTree && (
                <div
                    className={cn(
                        'excel-header-structure-loader__step',
                        'step-content',
                        'parsed-header-preview',
                    )}
                >
                    <HeaderPreview
                        headerStructure={headerTree}
                        form={form}
                        stepBackButton={stepBackButton}
                    />
                </div>
            )}
        </div>
    );
};
