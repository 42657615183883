import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import {pushCrumb} from 'modules/breadcrumbs/breadcrumbs-actions';
import {BREADCRUMBS_MODULE} from 'modules/breadcrumbs/breadcrumbs-constants';
import {FieldMeta, loadMetadata, METADATA_MODULE} from 'modules/metadata';
import {EntityMeta} from 'modules/metadata/metadata-types';
import {EntityType} from 'shared/constants/entities';
import {AppState} from 'store/config/types';

interface BreadcrumbItemProps {
    field: FieldMeta;
    record: Record<string, any>;
    entityName: string;
    entityType?: EntityType;
    handleBreadcrumbsEntityName?: (newEntityName: string, metaTable: EntityMeta) => void;
}

export const BreadcrumbItem: React.FunctionComponent<BreadcrumbItemProps> = ({
    field,
    record,
    entityType,
    entityName,
    handleBreadcrumbsEntityName,
}: BreadcrumbItemProps) => {
    const dispatch = useDispatch();
    const metaTable = useSelector(
        (state: AppState) => state[METADATA_MODULE]?.[field?.breadcrumbsEntityName || '']
            ?.[entityType || EntityType.TABLE],
    );
    const breadcrumbs = useSelector((state: AppState) => state[BREADCRUMBS_MODULE]?.[entityName]?.breadcrumbs);
    let newEntityTypeData = {};
    breadcrumbs?.forEach(crumbItem => {
        if (crumbItem?.children) {
            crumbItem.children.forEach(childrenItem => {
                newEntityTypeData = {...newEntityTypeData, [childrenItem.key]: childrenItem?.params};
            });
        }
    });
    React.useEffect(() => {
        if (field?.breadcrumbsEntityName) {
            dispatch(loadMetadata(field.breadcrumbsEntityName, EntityType.TABLE));
        }
    }, [metaTable]);

    const handlerChange = () => {
        dispatch(pushCrumb({
            entityName,
            crumb: {
                key: record?.id,
                label: record[field?.key],
                path: record.id,
            },
        }));

        if (field?.breadcrumbsEntityName && metaTable && handleBreadcrumbsEntityName) {
            handleBreadcrumbsEntityName(field?.breadcrumbsEntityName, metaTable);
        }
    };
    return (
        <Link
            to={entityName}
            onClick={handlerChange}
        >
            {record[field?.key]}
        </Link>
    );
};
