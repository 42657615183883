import {EntityType} from 'shared/constants/entities';
import {AppState, Dispatch} from 'store/config/types';
import {getBlankForm} from 'utils/common';

import {selectContextRawData} from '../../context/context-selector';
import {selectMetadata} from '../../metadata';
import {setMetadata} from '../../metadata/metadata-actions';
import {fetchEntityMetadata} from '../../metadata/metadata-api';
import {setData} from '../data-actions';
import {selectFilterEntityData} from '../data-selectors';

export const initBlankFilter = (entityName: string) => async (
    dispatch: Dispatch, getState: () => AppState,
) => {
    try {
        let filterMetadata = selectMetadata(entityName, EntityType.FILTER)(getState());
        const context = selectContextRawData(getState());
        const shouldUpdateMetadata = !filterMetadata;

        if (shouldUpdateMetadata) {
            filterMetadata = (await fetchEntityMetadata(entityName, EntityType.FILTER))?.data;
            dispatch(setMetadata({entityName, entityType: EntityType.FILTER, metadata: filterMetadata}));
        }

        const filter = selectFilterEntityData(entityName)(getState());
        const shouldUpdateData = !filter?.data;
        if (shouldUpdateData) {
            const {fields = []} = filterMetadata;
            const filterData = getBlankForm(fields, getState, context);
            dispatch(setData({
                entityName,
                entityType: EntityType.FILTER,
                data: {
                    id: null,
                    data: filterData,
                    initialData: filterData,
                    loading: true,
                },
            }));
        }
    } catch (e) {
        console.error(e);
    }
};
