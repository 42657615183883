import {Spin} from 'antd';
import React from 'react';

import {CustomTable, customTableUtils} from 'components/@common/widgets/custom-table';
import {ModalOpenerComponent, ModalOpenerComponentRef} from 'components/modal-opener-component';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {aisSelectors, aisSliceActions} from 'store/slices/ais-slice/ais-slice';
import {loadAisIncludedDocumentsByTax} from 'store/slices/ais-slice/ais-slice-thunks';
import {AisRegisterDocumentDto} from 'store/slices/ais-slice/ais-slice-types';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

export interface TaxSliceModalProps {
    registerDocument: AisRegisterDocumentDto;
}

export const TaxSliceModal = React.forwardRef<ModalOpenerComponentRef, TaxSliceModalProps>((
    props: TaxSliceModalProps, ref,
) => {
    const dispatch = useAppDispatch();

    const {registerDocument} = props;

    const includedDocumentsByTax = useAppSelector(aisSelectors.includedDocumentsByTaxSelectors.selectAll);

    const isLoadingIncludedDocumentsByTax = useAppSelector(s => selectIsThunkPending(
        s, loadAisIncludedDocumentsByTax.typePrefix,
    ));

    return (
        <ModalOpenerComponent
            ref={ref}
            controlLabels={{
                cancel: 'Закрыть',
            }}
            hideControls={{
                all: true,
            }}
            modalProps={{
                title: `Документ в разрезе налогов (${registerDocument.aisDocId})`,
                width: '80vw',
                centered: true,
                forceRender: false,
                destroyOnClose: true,
            }}
            onShow={() => {
                dispatch(loadAisIncludedDocumentsByTax({
                    id: registerDocument.id,
                }));
            }}
            afterModalClose={() => {
                dispatch(aisSliceActions.resetIncludedDocumentsByTax());
            }}
        >
            <Spin
                spinning={isLoadingIncludedDocumentsByTax}
                tip="Загрузка..."
            >
                <CustomTable
                    onColumn={col => ({
                        ...customTableUtils.setHeaderStyles({
                            whiteSpace: 'pre',
                            textAlign: 'center',
                        }),
                        render: customTableUtils.getDefaultRender,
                        ...col,
                    })}
                    dataSource={includedDocumentsByTax}
                    columns={[
                        {title: 'Код налога', dataIndex: 'taxCode'},
                        {title: 'КНД декларации', dataIndex: 'kndCode'},
                        {title: 'Код налогового (отчетного) периода', dataIndex: 'taxPeriod'},
                        {title: 'Номер корректировки декларации', dataIndex: 'corrNum'},
                        {title: 'Отчетный год декларации', dataIndex: 'reportingYear'},
                        {
                            title: 'Номер раздела декларации',
                            dataIndex: 'section',
                        },
                        {
                            title: 'Номер строки декларации',
                            dataIndex: 'subsection',
                        },
                    ]}
                />
            </Spin>
        </ModalOpenerComponent>
    );
});
