import {PlusCircleOutlined} from '@ant-design/icons';
import {
    Button,
} from 'antd';
import {FormInstance} from 'antd/es/form';
import React, {
    useRef, useState,
} from 'react';

import {ModalOpenerComponent, ModalOpenerComponentRef} from 'components/modal-opener-component';
import {DRILLDOWN_ID_PARAM_KEY} from 'components/report-configuration/report-configuration.constants';
import {useQueryParams} from 'shared/utils/query-params';
import {showRequestErrorMessage} from 'shared/utils/show-message-from-response';
import {useAppDispatch} from 'store/config/hooks';
import {updateDrilldownParameter} from 'store/slices/report-configuration-slice/dd-parameters-configuration/dd-parameters-configuration-thunks';
import {ReportDdParameter} from 'store/slices/report-configuration-slice/dd-parameters-configuration/dd-parameters-configuration-types';

import {DdParametersUpdateModalForm} from './dd-parameters-update-modal-form';

interface DdParametersUpdateModalOpenerProps {}

export const DdParametersUpdateModalOpener: React.FC<DdParametersUpdateModalOpenerProps> = () => {
    const dispatch = useAppDispatch();

    const {query} = useQueryParams();

    const drillDownId = Number(query.get(DRILLDOWN_ID_PARAM_KEY));

    const [isSubmitting, setIsSubmitting] = useState(false);

    const formRef = useRef<FormInstance>(null); // форма через ref для корректного mount/unmount и сброса данных
    const modalOpenerRef = useRef<ModalOpenerComponentRef>(null);

    const handleFinish = async (values: Partial<ReportDdParameter>) => {
        setIsSubmitting(true);
        try {
            await dispatch(updateDrilldownParameter({
                ...values,
                drillId: drillDownId,
            })).unwrap();
            modalOpenerRef.current?.setIsModalOpen(false);
        } catch (e) {
            showRequestErrorMessage(e);
        }
        setIsSubmitting(false);
    };

    return (
        <div>
            <ModalOpenerComponent
                ref={modalOpenerRef}
                shouldConfirm={() => !!formRef.current?.isFieldsTouched?.()}
                handleSave={() => {
                    formRef.current?.submit();
                }}
                component={(() => (
                    <Button
                        type="primary"
                        icon={<PlusCircleOutlined />}
                    >
                        Добавить правило
                    </Button>
                ))()}
                modalProps={{
                    title: 'Создание правила передачи значения',
                    forceRender: false,
                    destroyOnClose: true,
                    centered: true,
                }}
                disabledControls={{
                    save: isSubmitting,
                }}
            >
                <DdParametersUpdateModalForm
                    ref={formRef}
                    handleFinish={handleFinish}
                />
            </ModalOpenerComponent>
        </div>
    );
};
