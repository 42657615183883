import {Tooltip} from 'antd';
import {isArray} from 'lodash';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';

import {DynamicIcon} from 'components/dynamic-icon';
import {createActionGetFile} from 'modules/data/data-actions';
import {RequestType} from 'modules/metadata';
import {performRequest, showMessageFromResponse} from 'shared/utils';

import {ModalFilesValue} from './modal/modal';

import '../file/file-column.less';
import './files-modal.less';

export const FilesModalCell = React.forwardRef(({
    value, onChange, key, title, entityName, valueId,
}: any) => {
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();

    const handleDelete = () => {
        (async () => {
            try {
                if (entityName && valueId) {
                    const response = await performRequest({
                        url: `${entityName}/${valueId}`,
                        method: RequestType.DELETE,
                        data: undefined,
                    });
                    if (response.status === 200) {
                        onChange?.([]);
                    }
                }
            } catch (e) {
                showMessageFromResponse({response: e.response});
            }
        })();
    };

    const downloadDocumentList = () => {
        dispatch(
            createActionGetFile({
                referenceUrl: `${entityName}/download-all-files/${valueId}`,
                requestType: RequestType.GET,
            }),
        );
    };

    return (
        <>
            {isArray(value) && value.length > 0 ? (
                <div className="file-column file files-cell">
                    <span className="file-column__title">{value.length} вложения</span>
                    <div className="line-break" />
                    <div className="file-column__icons">
                        <DynamicIcon
                            className="file-column__icon"
                            type="CloseOutlined"
                            onClick={handleDelete}
                        />
                        <Tooltip title="Скачать">
                            <DynamicIcon
                                className="file-column__icon"
                                type="LinkOutlined"
                                onClick={downloadDocumentList}
                            />
                        </Tooltip>
                        <Tooltip title="Добавить файлы">
                            <DynamicIcon
                                className="file-column__icon icon-add-files"
                                type="PlusCircleOutlined"
                                onClick={() => setIsOpen(true)}
                            />
                        </Tooltip>
                    </div>
                </div>
            ) : (
                <div
                    className="file-column file-column__empty"
                    onClick={() => setIsOpen(true)}
                >
                    <DynamicIcon
                        className="file-column__icon empty"
                        type="download"
                    />
                    <span>Загрузить</span>
                </div>
            )}
            {isOpen && (
                <ModalFilesValue
                    name={key || 'files'}
                    value={value}
                    label={title || 'Вложения'}
                    visible={isOpen}
                    onChange={onChange}
                    closeModal={() => setIsOpen(false)}
                    entityName={entityName}
                    id={valueId}
                />
            )}
        </>
    );
});
