import React from 'react';

import {useAppSelector} from 'store/config/hooks';
import {ModalComponentInterface, selectModalData} from 'store/slices/modals-slice';

import {EditableFormTableContext} from './editable-form-table-data';
import {EditableTableModal} from './editable-table-modal';
import {useEditableTableActions} from './hooks/use-editable-table-actions';
import {useEditableTableColumns} from './hooks/use-editable-table-colums';

export const EditableTableModalContainer: React.FunctionComponent<ModalComponentInterface> = ({
    onClose,
}: ModalComponentInterface) => {
    const entityName = useAppSelector(selectModalData)?.entityName || '';
    const {
        width,
        modalTitle,
        initData,
        form,
        setForm,
        tableData,
        actions,
        handleEdit,
        disabledPagination,
    } = useEditableTableActions(entityName);

    const {columnsMeta} = useEditableTableColumns(entityName);

    return (
        <EditableFormTableContext.Provider
            value={{
                initData,
                form,
                setForm,
            }}
        >
            <EditableTableModal
                width={width}
                disabledPagination={disabledPagination}
                entityName={entityName}
                modalTitle={modalTitle}
                onClose={onClose}
                handleEdit={handleEdit}
                columns={columnsMeta}
                data={tableData || []}
                actions={actions}
            />
        </EditableFormTableContext.Provider>
    );
};
