import {
    PayloadAction,
} from '@reduxjs/toolkit';
import {get, setWith} from 'lodash';

import {TableReportSliceState} from '../table-report-slice-types';
import {TableReportUploadAttachmentsProgress, TableReportSetUploadAttachmentsProgressArgs} from './table-report-row-attachments-types';

export const createTableReportRowAttachmentsReducer = <S extends TableReportSliceState>() => ({
    resetUploadProgress(state: S) {
        const progressData: TableReportUploadAttachmentsProgress = {
            documents: {},
            templates: {},
        };
        state.uploadAttachmentsProgress = progressData;
    },
    setUploadProgress(state: S, {payload}: PayloadAction<TableReportSetUploadAttachmentsProgressArgs>) {
        const {
            reportRowId,
            templateCode,
            uploadData,
            docId,
        } = payload;

        const progressData = state.uploadAttachmentsProgress ?? {
            documents: {},
            templates: {},
        };

        if (docId) {
            setWith(progressData, ['documents', templateCode, docId, reportRowId], uploadData, Object);
        } else {
            setWith(progressData, ['templates', templateCode, reportRowId], uploadData, Object);
        }
        state.uploadAttachmentsProgress = progressData;
    },
    updateUploadProgress(state: S, {payload}: PayloadAction<TableReportSetUploadAttachmentsProgressArgs>) {
        const {
            reportRowId,
            templateCode,
            uploadData,
            docId,
        } = payload;

        const progressData = state.uploadAttachmentsProgress ?? {
            documents: {},
            templates: {},
        };

        if (docId) {
            const currentUploadData = get(progressData, ['documents', templateCode, docId, reportRowId]);
            setWith(progressData, ['documents', templateCode, docId, reportRowId], {
                ...currentUploadData,
                ...uploadData,
            }, Object);
        } else {
            const currentUploadData = get(progressData, ['templates', templateCode, reportRowId]);
            setWith(progressData, ['templates', templateCode, reportRowId], {
                ...currentUploadData,
                ...uploadData,
            }, Object);
        }
        state.uploadAttachmentsProgress = progressData;
    },
    updateDocIdsForRequest: (state: S, action: PayloadAction<string | undefined>) => {
        if (action.payload === undefined) return;

        const index = state.selectedDocIds.indexOf(action.payload);
        if (index === -1) {
            state.selectedDocIds.push(action.payload);
        } else {
            state.selectedDocIds.splice(index, 1);
        }
    },
    resetDocIdsForRequest: (state: S) => {
        state.selectedDocIds = [];
    },
});
