import {createReducer} from 'store/config/creators';
import {Action} from 'store/config/types';

import {WarningActions} from './warning-constants';
import {SetWarningVisibilityPayload, WarningModuleState} from './warning-types';

const WaringModuleInitialState: WarningModuleState = {};

export const WarningReducer = createReducer(WaringModuleInitialState, {
    [WarningActions.SET_WARNING_VISIBILITY]: (
        state: WarningModuleState, {payload}: Action<SetWarningVisibilityPayload>,
    ) => ({
        ...state,
        [payload.entityName]: {
            ...state[payload.entityName],
            visible: payload.warningVisible,
        },
    }),
});
