import {
    Modal, Form as AntForm, Input, Button, Space,
} from 'antd';
import {useForm} from 'antd/es/form/Form';
import cn from 'classnames';
import React from 'react';

import {ReactComponent as CloseIcon} from 'shared/assets/close.svg';

import './set-input-value-modal.less';

export interface SetInputValueModalProps {
    name: string;
    value?: string;
    label: string;
    visible: boolean;
    closeModal: () => void;
    onChange?: (value: any) => void;
    saveButtonLabel?: string;
}

export const SetInputValueModal = ({
    name, visible, closeModal, label, onChange, value,
    saveButtonLabel = 'Сохранить',
}: SetInputValueModalProps) => {
    const [form] = useForm();
    const handleSubmit = (values: any) => {
        onChange?.(values[name]);
        closeModal();
    };

    return (
        <Modal
            centered
            width={980}
            footer={null}
            title={label}
            destroyOnClose
            visible={visible}
            maskClosable={false}
            onCancel={closeModal}
            className="set-input-value-modal modal-header"
            closeIcon={<CloseIcon className={cn('modal-close-icon', 'with-title')} />}
        >
            <AntForm
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
            >
                <AntForm.Item
                    label={label}
                    name={name}
                    initialValue={value}
                >
                    <Input.TextArea
                        autoFocus
                        autoComplete="off"
                        placeholder="Введите содержание"
                    />
                </AntForm.Item>
                <Space className="buttons">
                    <Button
                        htmlType="submit"
                        type="primary"
                    >{saveButtonLabel}
                    </Button>
                    <Button
                        onClick={closeModal}
                        type="default"
                    >Отменить
                    </Button>
                </Space>
            </AntForm>
        </Modal>
    );
};
