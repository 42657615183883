export const TCSendServiceV3LookupCode = 'registerAvailableFile-v3-EXTERNAL';

export enum TCFileExchangeTabs {
    main = 'main',
    changes = 'changes',
}

export enum TCFileExchangeTabTitles {
    main = 'Основная информация',
    changes = 'Журнал изменений'
}

export const TCProcessingKndCode = '1184037';
export const TCDemandKndCode = '1184032';
export enum TCProcessingFileNames {
    processing = 'ON_PROTOBRNM',
    error = 'TR_ERRORNM',
}

export const TCNotFoundMessage = 'Транспортный контейнер не найден';

export enum TCAuditStagesLookupCode {
    create = 'CREATE',
    download = 'DOWNLOAD',
    responseRequirement = 'RESPONSE_REQUIREMENT',
    processing = 'PROCESSING',
}

export const TCAuditStatusCode = {
    success: [200, 406, 416],
    error: [401, 411, 500, 405, 407, 408, 417, 418],
};
