import React from 'react';

import {ModalOpenerComponent, ModalOpenerComponentRef} from 'components/modal-opener-component';
import {StateSetter} from 'shared/types/generics';
import {showMessage} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {removeAisDocumentsInRegister} from 'store/slices/ais-slice/ais-slice-thunks';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

interface DeleteConfirmationModalProps {
    selectedRowKeys: React.Key[];
    setSelectedRowKeys: StateSetter<React.Key[]>;
    handleRefresh: () => void;
}

export const DeleteConfirmationModal = React.forwardRef<ModalOpenerComponentRef, DeleteConfirmationModalProps>((
    {selectedRowKeys, handleRefresh, setSelectedRowKeys}: DeleteConfirmationModalProps,
    ref,
) => {
    const dispatch = useAppDispatch();

    const isDeleting = useAppSelector(s => selectIsThunkPending(s, removeAisDocumentsInRegister.typePrefix));

    const handleDelete = (setIsModalOpen: StateSetter<boolean>) => {
        dispatch(removeAisDocumentsInRegister({
            ids: selectedRowKeys as number[],
        })).unwrap().then(() => {
            showMessage({message: 'Записи успешно удалены'});
            setSelectedRowKeys([]);
            setIsModalOpen(false);
            handleRefresh();
        }, () => {
            showMessage({message: 'Во время удаления записей произошла ошибка', isError: true});
        });
    };

    return (
        <ModalOpenerComponent
            ref={ref}
            modalProps={{
                forceRender: false,
                destroyOnClose: true,
                title: 'Подтверждение',
                centered: true,
            }}
            controlLabels={{
                save: 'Удалить',
            }}
            loadingControls={{
                save: isDeleting,
            }}
            disabledControls={{
                save: isDeleting,
            }}
            handleSave={setIsModalOpen => {
                handleDelete(setIsModalOpen);
            }}
        >
            Вы действительно хотите удалить выбранные записи?
        </ModalOpenerComponent>
    );
});
