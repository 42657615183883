import {FormInstance} from 'antd/es/form';

import {FieldPath} from 'components/report-configuration/report-configuration.types';

interface UndefineTypeDependentFieldsArgs {
    form: FormInstance;
    fieldsPaths: FieldPath[];
}

export const undefineTypeDependentFields = ({
    form,
    fieldsPaths,
}: UndefineTypeDependentFieldsArgs) => {
    const updatedFieldsValues = fieldsPaths.map(path => ({
        name: path,
        value: undefined,
    }));
    form.setFields([
        ...updatedFieldsValues,
    ]);
};
