import {
    Card,
    Form,
    Modal,
} from 'antd';
import {useForm} from 'antd/es/form/Form';
import cn from 'classnames';
import React, {useEffect} from 'react';

import {MonitorServiceDynamicFields} from 'components/ais/monitor-services-card/monitor-service-dynamic-fields/monitor-service-dynamic-fields';
import {SimpleActionButton} from 'components/form/components';
import {CustomSelect} from 'components/form/inputs/custom-select';
import {CustomSelectValueTriggerEvent as TriggerEvent} from 'components/form/inputs/custom-select/custom-select';
import {resetLoadedData} from 'modules/data';
import {EntityType} from 'shared/constants/entities';
import {
    MonitorServicesApi,
    useLazyGetAdditionalFormFieldsByMonitorServiceIdQuery,
    useRunMonitorServiceMutation,
} from 'store/api/monitor-services/monitor-services.api';
import {useAppDispatch} from 'store/config/hooks';

interface MonitorServicesModalProps {
    entityName: string;
    visible: boolean;
    onFormClose: () => void;
}

export const MonitorServicesModal = ({
    entityName, visible, onFormClose,
}: MonitorServicesModalProps) => {
    const dispatch = useAppDispatch();
    const [form] = useForm();
    const handleFormClose = () => {
        dispatch(MonitorServicesApi.util.resetApiState());
        form.resetFields();
        onFormClose();
    };

    const [
        getAdditionalFormFieldsByMonitorServiceId,
        {
            currentData: dynamicFields,
            isFetching: isDynamicFieldsFetching,
        },
    ] = useLazyGetAdditionalFormFieldsByMonitorServiceIdQuery();

    const [runMonitorService, {isSuccess: isFormSubmitted}] = useRunMonitorServiceMutation();

    useEffect(() => {
        if (isFormSubmitted) {
            handleFormClose();
            dispatch(resetLoadedData(entityName, EntityType.TABLE));
        }
    }, [isFormSubmitted]);

    return (
        <Modal
            visible={visible}
            centered
            width="980px"
            title="Вызвать сервис"
            onCancel={handleFormClose}
            className={cn('form-modal', 'monitor-services-modal')}
            footer={null}
        >
            {visible && (
                <Card>
                    <Form
                        className={cn('form', 'monitor-services-modal-form')}
                        form={form}
                        layout="vertical"
                        onFinish={() => {
                            const data = form.getFieldsValue();
                            form.validateFields()
                                .then(() => {
                                    runMonitorService(data);
                                });
                        }}
                    >
                        <div className="form-fields">
                            <Form.Item
                                style={{width: '100%'}}
                                name="externalServiceId"
                                label="Наименование сервиса"
                                required
                            >
                                <CustomSelect
                                    handleTriggerEvent={(value, event) => {
                                        if (event === TriggerEvent.valueWasSet
                                                || event === TriggerEvent.firstOptionWasSet
                                                || event === TriggerEvent.valueHasBeenChangedManually) {
                                            getAdditionalFormFieldsByMonitorServiceId({id: value?.id});
                                        }
                                    }}
                                    settings={{
                                        url: '/ais/valueLists/AIS_SERVICES',
                                        placeholder: 'Выберите сервис',
                                        labelPath: 'meaning',
                                        valuePath: 'id',
                                        formFieldKey: 'externalServiceId',
                                        formInstance: form,
                                        showSearch: true,
                                        isDisabled: isDynamicFieldsFetching,
                                    }}
                                />
                            </Form.Item>

                            <MonitorServiceDynamicFields
                                form={form}
                                fields={dynamicFields}
                                entityName={entityName}
                            />
                        </div>

                        <SimpleActionButton
                            title="Запуск"
                            type="primary"
                            style={{width: '100%'}}
                            disabled={isDynamicFieldsFetching}
                            onClick={() => form.submit()}
                        />
                    </Form>
                </Card>
            )}
        </Modal>
    );
};
