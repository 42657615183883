import {Button, Spin} from 'antd';
import cn from 'classnames';
import React, {ReactElement} from 'react';

import {ReactComponent as ArrowBackOutlined} from 'shared/assets/arrow-back-outlined.svg';
import {ReactComponent as ReloadOutlined} from 'shared/assets/reload.svg';
import {ThunkRejectionError} from 'store/slices/loading-state-slice';

import './ui-blocker.less';

interface UIBlockerProps {
    children: ReactElement;
    content?: ReactElement;
    thunkError?: {
        error?: ThunkRejectionError;
        retryCallback?: () => void;
        goBackCallback?: () => void;
        verbose?: boolean;
    };
    label?: string;
    abortedLabel?: string;
    extra?: ReactElement;
    childrenClassName?: cn.Argument;
}

export const UIBlocker: React.FC<UIBlockerProps> = (
    {
        children,
        content,
        thunkError,
        label,
        abortedLabel,
        extra,
        childrenClassName,
    }: UIBlockerProps,
) => {
    const isUIBlocked = content || (thunkError?.error);
    const isAborted = thunkError?.error?.isAborted;
    return (
        <div className={cn('ui-blocker')}>
            {isUIBlocked && (
                <>
                    <div className={cn('ui-blocker__backdrop')} />
                    <div className={cn('ui-blocker__content')}>{
                        (() => {
                            if (content) return content;
                            if (thunkError?.error) {
                                return (
                                    <div
                                        className={cn(
                                            'd-flex align-items-center',
                                            'justify-content-center flex-column text-center',
                                        )}
                                    >
                                        {(() => {
                                            if (isAborted) return null;
                                            return label ?? 'Во время работы компонента произошла ошибка';
                                        })()}

                                        {isAborted && (
                                            <div
                                                className={cn('ui-blocker__content__verbose-error',
                                                    'd-flex align-items-center')}
                                                style={{gap: 8, marginTop: 10}}
                                            >
                                                <Spin
                                                    wrapperClassName="d-flex"
                                                    tip={abortedLabel ?? 'Загрузка данных...'}
                                                />
                                            </div>
                                        )}

                                        {thunkError?.verbose && thunkError?.error?.data && (
                                            <div className={cn('ui-blocker__content__verbose-error')}>
                                                {thunkError.error.data}
                                            </div>
                                        )}
                                        {!isAborted && (thunkError?.retryCallback
                                            || thunkError?.goBackCallback || extra) && (
                                            <div
                                                style={{marginTop: 10, gap: 6}}
                                                className="d-flex align-items-center"
                                            >
                                                {thunkError?.retryCallback && (
                                                    <Button
                                                        type="primary"
                                                        onClick={() => {
                                                            thunkError.retryCallback?.();
                                                        }}
                                                        icon={<ReloadOutlined />}

                                                    >Попробовать ещё раз
                                                    </Button>
                                                )}
                                                {thunkError?.goBackCallback && (
                                                    <Button
                                                        type="default"
                                                        icon={<ArrowBackOutlined />}
                                                        onClick={() => {
                                                            thunkError.goBackCallback?.();
                                                        }}
                                                    >Вернуться назад
                                                    </Button>
                                                )}
                                                {extra}
                                            </div>
                                        )}
                                    </div>
                                );
                            }
                            return null;
                        })()
                    }
                    </div>
                </>
            )}
            <div className={cn(
                'ui-blocker__children',
                {'ui-blocker__children_with-opacity': isUIBlocked},
                childrenClassName,
            )}
            >
                {children}
            </div>
        </div>
    );
};
