import {Select} from 'antd';
import {FormInstance} from 'antd/es/form';
import React, {ReactText, useEffect} from 'react';

import {GenerateOptionsRulesProps} from 'modules/metadata/metadata-types';

import {useReferenceSelectConfig} from '../reference-select/hooks/reference-select-config-hook';
import {SearchableDropdown} from '../reference-select/searchable-dropdown';
import {TagRenderer} from '../reference-select/tag-renderer';
import {useGenerateOptions} from './hooks/useGenerateOptions';

export interface StaticSelectProps {
    options?: {
        label: string;
        value: string;
    }[];
    value?: string;
    onChange: (value: any) => void;
    multipleMode?: boolean;
    isFilterable?: boolean;
    isClearable?: boolean;
    disabled?: boolean;
    fixedDropdown?: boolean;
    placeholder?: string;
    defaultValue?: string;
    useFirstOptionAsDefaultValue?: boolean;
    generateOptionsRules?: GenerateOptionsRulesProps;
    form?: FormInstance;
}

export const StaticSelect: React.FunctionComponent<StaticSelectProps> = ({
    options,
    value,
    multipleMode,
    isFilterable,
    isClearable,
    disabled,
    onChange,
    fixedDropdown = true,
    useFirstOptionAsDefaultValue,
    placeholder,
    defaultValue,
    generateOptionsRules,
    form,
    ...props
}: StaticSelectProps) => {
    const {
        // isGenerating,
        generatedOptions,
    } = useGenerateOptions({options, generateOptionsRules, form});

    const {
        dropdownOpen,
        setDropdownOpen,
        renderOptions,
        suffixIcon,
        dropdownInputValue,
        setDropdownInputValue,
        setDropdownSelectedIcon,
    } = useReferenceSelectConfig(options, disabled, multipleMode, isFilterable);

    const mode = multipleMode ? 'multiple' : undefined;
    const handleChange = (selectedValue: ReactText | ReactText[]) => {
        const newValue = Array.isArray(selectedValue)
            ? selectedValue
            : options?.find(option => option.value === selectedValue)?.value;

        onChange(newValue);
    };

    useEffect(() => {
        if (useFirstOptionAsDefaultValue) {
            onChange(options?.[0]?.value);
        }
        if (!value && defaultValue) {
            onChange(defaultValue);
        }
    }, []);

    useEffect(() => {
        if (value && options && options.length === 0) {
            onChange(undefined);
        }
    }, [options]);

    return (
        <Select
            {...props}
            showArrow
            placeholder={placeholder}
            allowClear={isClearable}
            mode={mode}
            optionFilterProp="children"
            className="select-field"
            filterOption={(input: string, option) => option
                ?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={handleChange}
            value={value}
            open={dropdownOpen}
            defaultValue={useFirstOptionAsDefaultValue ? options?.[0]?.value : undefined}
            showSearch={!multipleMode && isFilterable}
            disabled={disabled}
            tagRender={TagRenderer}
            onDropdownVisibleChange={v => setDropdownOpen(v)}
            // чтобы dropdown сохранял позицию
            getPopupContainer={fixedDropdown ? trigger => trigger.parentNode : undefined}
            menuItemSelectedIcon={mode && setDropdownSelectedIcon}
            suffixIcon={suffixIcon}
            dropdownRender={isFilterable && multipleMode ? (menu: React.ReactElement) => (
                <SearchableDropdown
                    menu={menu}
                    inputValue={dropdownInputValue}
                    setInputValue={setDropdownInputValue}
                />
            ) : undefined}
        >
            {generatedOptions || renderOptions}
        </Select>
    );
};
