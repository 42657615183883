import {Typography} from 'antd';
import React, {ReactNode} from 'react';

export interface HighlightedProps {
    pattern?: string;
    children?: string;
    matcher?: (pattern: string) => RegExp;
}

const escapeRegex = (string: string) => string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
const defaultSearchRegex = (searchLine: string) => new RegExp(escapeRegex(searchLine.trim()), 'ig');

export const Highlighted = React.forwardRef<
HTMLSpanElement, HighlightedProps>(({
    children = '',
    pattern,
    matcher = defaultSearchRegex,
}: HighlightedProps, ref) => {
    if (!pattern) return <>{children}</>;

    const separatorForPattern = (() => {
        const matches = children.match(matcher(pattern));
        if (matches?.length) return matches[0];
        return pattern;
    })();

    const splittedComponents = children.split(separatorForPattern).map(word => <>{word}</>);
    const joinedComponents = (() => {
        const joined: ReactNode[] = [];
        splittedComponents.forEach((component, index) => {
            joined.push(component);
            if (index !== splittedComponents.length - 1) {
                joined.push(<Typography.Text mark>{separatorForPattern}</Typography.Text>);
            }
        });
        return joined;
    })();

    return (
        <span ref={ref}>
            {joinedComponents.map((component, index) => {
                const Component = () => <>{component}</>;
                return <Component key={index} />; // eslint-disable-line
            })}
        </span>
    );
});
