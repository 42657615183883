import {Button} from 'antd';
import {FormInstance} from 'antd/es/form';
import React from 'react';
import {useDispatch} from 'react-redux';

import {DynamicIcon} from 'components/dynamic-icon';
import {TIconType} from 'components/dynamic-icon/dynamic-icon';
import {publishListDocuments} from 'modules/data/data-actions';
import {selectTableEntityData} from 'modules/data/data-selectors';
import {useAppSelector} from 'store/config/hooks';
import {closeModal} from 'store/slices/modals-slice';

interface ButtonPublishDocumentsProps {
    parentEntityName: string;
    actionTitle: string;
    actionCode: string;
    actionType: string;
    referenceUrl: string;
    actionIcon?: TIconType;
    form: FormInstance;
}

export const ButtonPublishDocuments: React.FunctionComponent<ButtonPublishDocumentsProps> = ({
    actionTitle,
    actionIcon,
    parentEntityName,
    form,
    referenceUrl,
    ...props
}: ButtonPublishDocumentsProps) => {
    const dispatch = useDispatch();
    const publishDocumentIds = useAppSelector(selectTableEntityData(parentEntityName))?.publishDocumentIds;
    const rows = useAppSelector(selectTableEntityData(parentEntityName))?.rows;
    const sectionCodeCtx: string = String(rows?.slice(0, 1).shift()?.sectionCode);
    const handleClick = () => {
        form.validateFields().then(() => {
            const {createNews, publishDate, delayed} = form.getFieldsValue();
            if (publishDocumentIds && sectionCodeCtx) {
                dispatch(
                    publishListDocuments(
                        parentEntityName,
                        referenceUrl,
                        publishDocumentIds,
                        !!createNews,
                        publishDate,
                        sectionCodeCtx,
                        delayed,
                    ),
                );
            }
            dispatch(closeModal());
        });
    };
    return (
        <Button
            {...props}
            className="save-action-btn"
            htmlType="submit"
            onClick={handleClick}
        >
            {actionIcon && <DynamicIcon type={actionIcon} />}
            {actionTitle}
        </Button>
    );
};
