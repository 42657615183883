import {TIconType} from 'components/dynamic-icon/dynamic-icon';

export const iconOptions: TIconType[] = [
    'CircleFilled',
    'PlusCircleOutlined',
    'PlusSquareOutlined',
    'PlusOutlined',
    'MinusCircleOutlined',
    'MinusSquareOutlined',
    'MinusOutlined',
    'QuestionCircleOutlined',
    'QuestionOutlined',
    'PauseCircleOutlined',
    'PauseOutlined',
    'InfoCircleOutlined',
    'InfoOutlined',
    'ExclamationCircleOutlined',
    'ExclamationOutlined',
    'CheckCircleOutlined',
    'CheckSquareOutlined',
    'CheckOutlined',
    'ClockCircleOutlined',
    'WarningOutlined',
    'IssuesCloseOutlined',
    'StopOutlined',
];
