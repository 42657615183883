import React from 'react';

import {combineString} from 'shared/utils/combine-string';

export interface CombinedStringListItemProps {
    data: Record<string, any> | null;
    stringStructure: string;
}

export const CombinedStringListItem = ({
    data,
    stringStructure,
}: CombinedStringListItemProps) => {
    if (!data) return <span>-</span>;
    return (
        <span>
            {combineString(stringStructure, data)}
        </span>
    );
};
