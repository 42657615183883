import {Radio} from 'antd';
import {RadioGroupProps} from 'antd/lib/radio';
import {RadioButtonProps} from 'antd/lib/radio/radioButton';
import cn from 'classnames';
import React from 'react';

import {JSXContent} from 'shared/types';

import './custom-radio.less';

interface CustomRadioItem extends RadioButtonProps {
    label: JSXContent;
}

interface CustomRadioProps extends RadioGroupProps {
    items?: CustomRadioItem[];
}

export const CustomRadio: React.FC<CustomRadioProps> = (
    props: CustomRadioProps,
) => {
    const {items = [], ...restProps} = props;

    return (
        <Radio.Group
            className={cn('custom-radio')}
            {...restProps}
        >
            {items.map((item, index) => {
                const {label, value, ...restItemProps} = item;
                return (
                    <Radio.Button
                        key={index} // eslint-disable-line
                        value={value}
                        {...restItemProps}
                    >
                        {label}
                    </Radio.Button>
                );
            })}
        </Radio.Group>
    );
};
