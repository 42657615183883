import React from 'react';
import {useSelector} from 'react-redux';

import {setData} from 'modules/data/data-actions';
import {FieldMeta, METADATA_MODULE} from 'modules/metadata';
import {EntityType} from 'shared/constants/entities';
import {useAppDispatch} from 'store/config/hooks';
import {AppState} from 'store/config/types';

export interface UseFieldDisabledStateProps {
    entityName?: string;
    isDisabled?: boolean;
    dependentKeys?: string[];
}

export const useFieldDisabling = ({entityName, isDisabled, dependentKeys}: UseFieldDisabledStateProps) => {
    const dispatch = useAppDispatch();
    const [isDisabledField, setDisabledField] = React.useState(isDisabled);
    const data: FieldMeta[] = useSelector(
        (state: AppState) => state[METADATA_MODULE]?.[entityName!]
            ?.[EntityType.FORM]?.fields,
    );

    const handleDisabledField = () => {
        dependentKeys?.forEach((key: string) => {
            const newData = data || [];
            const index = data.findIndex(item => key === item?.key);
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                isDisabled: isDisabledField,
            });

            dispatch(setData({
                entityName: entityName!,
                entityType: EntityType.FORM,
                loading: false,
                data: {
                    fields: newData,
                },
            }));
        });
        setDisabledField(!isDisabledField);
    };

    return {handleDisabledField};
};
