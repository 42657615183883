import {Popover} from 'antd';
import cn from 'classnames';
import React from 'react';

import {FieldTooltipMetadata} from 'modules/metadata/tooltip-metadata-types';
import {ReactComponent as QuestionOutlined} from 'shared/assets/question-outlined.svg';

import {tooltipTypeResolver} from './tooltip-type-resolver';

import './field-tooltip.less';

interface TooltipFieldProps {
    meta: FieldTooltipMetadata;
}

export const FieldTooltip = ({meta}: TooltipFieldProps) => {
    const Component = tooltipTypeResolver(meta);

    return (
        <div className="tooltip-field">
            <Popover
                placement="bottomRight"
                align={{offset: [12, -2]}}
                overlayStyle={{width: meta.width}}
                content={Component}
                overlayClassName={cn('tooltip-field__content')}
            >
                <QuestionOutlined />
            </Popover>
        </div>
    );
};
