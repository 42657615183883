import React, {useState} from 'react';

import {SimpleActionButton} from 'components/form/components';

import {ChangeHistoryData, ModalChangeHistory} from './modal-change-history/modal-change-history';

interface ButtonChangeHistoryProps{
    changeHistoryData?: ChangeHistoryData;
}
export const ButtonChangeHistory = ({changeHistoryData}: ButtonChangeHistoryProps) => {
    const [changeHistoryModalVisible, setChangeHistoryModalVisible] = useState(false);

    const handleClick = () => {
        setChangeHistoryModalVisible(true);
    };

    return (
        <>
            <ModalChangeHistory
                visible={changeHistoryModalVisible}
                changeHistoryData={changeHistoryData}
                onClose={() => setChangeHistoryModalVisible(false)}
            />
            <SimpleActionButton
                type="ghost"
                icon="ClockCircleOutlined"
                title="История изменений"
                onClick={handleClick}
            />
        </>
    );
};
