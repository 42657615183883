import {FieldMeta} from 'modules/metadata';

import {EditableColumn, FieldStateType} from '../editable-table/editable-table-types';

const createStateCell = (activityList?: any) => (): FieldStateType => activityList || {} as FieldStateType;
export const generateEditableMetaData = (
    cols: FieldMeta,
): EditableColumn => ({
    ...cols,
    dataIndex: cols.dataIndex || cols.key,
    getFieldState: createStateCell({
        required: cols.isRequired,
        dependencies: cols.dependentsFieldKey,
    }),
    inputProps: undefined,
});
