import {LocationStateKey, useAppHistory} from 'shared/hooks/use-app-history';

import {BreadcrumbItem} from '../breadcrumb';

interface UseLocationStateBreadcrumbsArgs {
    breadcrumbs?: BreadcrumbItem[];
}

type UseLocationStateBreadcrumbs = (args: UseLocationStateBreadcrumbsArgs) => BreadcrumbItem[] | null;

export const useLocationStateBreadcrumbs: UseLocationStateBreadcrumbs = ({
    breadcrumbs,
}: UseLocationStateBreadcrumbsArgs) => {
    const history = useAppHistory();

    if (!breadcrumbs) return null;

    if (history.currentState?.[LocationStateKey.PASSED_BREADCRUMBS]) {
        const lastBreadcrumb = breadcrumbs.length ? breadcrumbs[breadcrumbs.length - 1] : undefined;
        if (!lastBreadcrumb) return breadcrumbs;
        return [
            ...history.currentState?.[LocationStateKey.PASSED_BREADCRUMBS] ?? [],
            lastBreadcrumb,
        ];
    }

    if (history.currentState?.[LocationStateKey.AIS_REGISTER]?.requests?.requestId) {
        const requestId = history.currentState?.[LocationStateKey.AIS_REGISTER]?.requests?.requestId;
        return [
            {name: 'Главная страница', path: '/'},
            {name: 'Запросы', path: '/requests'},
            {name: 'Запрос на доразмещение по идентификатору документа', path: `/requests/${requestId}`},
            {name: 'Реестр документов'},
        ];
    }

    return breadcrumbs;
};
