import {groupBy} from 'lodash';

import {EntityValue} from 'modules/data';
import {
    ReportConfigurationQueryParameterDefaultType,
    ReportConfigurationQueryParameterDto,
    ReportConfigurationQueryParameterVariableType,
} from 'store/slices/report-configuration-slice/report-configuration-dto';
import {TableReportFilterFieldDefaultType, TableReportFilterFieldType, TableReportQueryParameters} from 'store/slices/table-report-slice/table-report-slice-types';

export interface ConvertQueryParametersToFilterFormValuesArgs {
    values: TableReportQueryParameters | undefined;
    queryParameters: ReportConfigurationQueryParameterDto[] | undefined;
    filterKeyPath: 'paramName' | 'columnName';
}

export const convertQueryParametersToFilterFormValues = (
    {filterKeyPath, queryParameters, values = {}}: ConvertQueryParametersToFilterFormValuesArgs,
) => Object.keys(values).reduce((acc, key) => {
    const rawValue = values[key];

    const queryParameter = queryParameters?.find(param => param[filterKeyPath] === key);
    if (!queryParameter) return acc;

    switch (queryParameter.type) {
    case TableReportFilterFieldType.BOOLEAN:
        if (rawValue === 'true') {
            return {
                ...acc,
                [key]: true,
            };
        }
        if (rawValue === 'false') {
            return {
                ...acc,
                [key]: false,
            };
        }
        return acc;
    case TableReportFilterFieldType.SQL_MULTI_VALUE_SET:
    case TableReportFilterFieldType.MULTI_VALUE_SET:
        if (Array.isArray(rawValue)) {
            return {
                ...acc,
                [key]: rawValue,
            };
        }
        if (typeof rawValue === 'string') {
            return {
                ...acc,
                [key]: rawValue.split(',').map(v => v.trim()),
            };
        }
        return acc;
    default:
        return {
            ...acc,
            [key]: rawValue,
        };
    }
}, {});

export const convertFilterFormValuesToQueryParameters = (
    values: Record<string, any>,
    queryParameters: ReportConfigurationQueryParameterDto[] | undefined,
    filterKeyPath: 'paramName' | 'columnName',
) => Object.keys(values).reduce((acc, key) => {
    const rawValue = values[key];

    const queryParameter = queryParameters?.find(param => param[filterKeyPath] === key);
    if (!queryParameter) return acc;

    switch (queryParameter.type) {
    case TableReportFilterFieldType.BOOLEAN:
        if (rawValue === true) {
            return {
                ...acc,
                [key]: 'true',
            };
        }
        if (rawValue === false) {
            return {
                ...acc,
                [key]: 'false',
            };
        }
        return acc;
    case TableReportFilterFieldType.SQL_MULTI_VALUE_SET:
    case TableReportFilterFieldType.MULTI_VALUE_SET:
        if (Array.isArray(rawValue)) {
            return {
                ...acc,
                [key]: rawValue
                    .reduce((prev, cur, index) => (index ? `${prev}, ${cur}` : cur), ''),
            };
        }
        return acc;
    default:
        return {
            ...acc,
            [key]: rawValue,
        };
    }
}, {});

export const formatReferenceDataForSqlValueSet = (data: any, paramName: string) => {
    // возвращаем из объекта со всеми параметрами для sqlValueSet только тот набор значений, который
    // относится к paramName
    const dataGroupedByParamName = groupBy(data, 'param');
    const dataMutated = dataGroupedByParamName?.[paramName]?.[0]?.listValue ?? null;
    return dataMutated;
};

export interface GetTableReportFilterLookupReferenceUrlArgs {
    type: ReportConfigurationQueryParameterVariableType;
    defaultType: ReportConfigurationQueryParameterDefaultType;
    defaultValue: unknown;
    valuesetLookupType?: string;
    templateCode?: string;
}

export interface GetTableReportFilterLookupReferenceData {
    url: string;
    queryParams?: { [index: string]: any };
}

export const getTableReportFilterLookupReferenceRequestData: (
    args: GetTableReportFilterLookupReferenceUrlArgs) => GetTableReportFilterLookupReferenceData = ({
        defaultType,
        defaultValue,
        type,
        valuesetLookupType,
        templateCode,
    }) => {
        if (defaultType === TableReportFilterFieldDefaultType.CONTEXT) {
            if (defaultValue === 'TAX_PERIOD_ID') return {url: '/lookupValue/TAX_PERIOD'};
            if (defaultValue === 'ORG_ID') return {url: '/lookupValue/ORGANIZATION'};
        }
        if (type === TableReportFilterFieldType.SQL_VALUE_SET
            || type === TableReportFilterFieldType.SQL_MULTI_VALUE_SET) {
            return {
                url: '/table-reports/lookup/query-param-values',
                queryParams: {
                    templateCode,
                },
            };
        }
        return {url: `/valueLists/${valuesetLookupType}`};
    };

export const resolveLookup = (value: EntityValue, referenceData?: Record<string, EntityValue>[]) => {
    const valueByLookupCode = referenceData?.find(reference => reference.lookupCode === value)?.meaning;
    if (valueByLookupCode) {
        return valueByLookupCode;
    }
    return referenceData?.find(reference => reference?.id === value)?.meaning;
};
