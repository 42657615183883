import {
    Button,
    Checkbox, Drawer, Form,
} from 'antd';
import {useForm} from 'antd/lib/form/Form';
import cn from 'classnames';
import React, {useContext, useEffect} from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {ReportConfigurationContext} from 'components/report-configuration/report-configuration.context';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {
    ddReferenceRulesSelectors,
    reportConfigurationActions,
} from 'store/slices/report-configuration-slice/report-configuration-slice';

import {ReportDdReferenceRulesContext} from '../report-dd-references-utils';

import './dd-references-filter.less';

interface DdReferencesFilterProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DdReferencesFilter: React.FC<DdReferencesFilterProps> = (
    {
        isOpen,
        setIsOpen,
    }: DdReferencesFilterProps,
) => {
    const dispatch = useAppDispatch();
    const [form] = useForm();

    const ddReferenceRulesFilter = useAppSelector(ddReferenceRulesSelectors.selectDdReferenceRulesFilter);

    const {updateDdReferenceRulesFilter} = reportConfigurationActions;

    const {templateCode} = useContext(ReportConfigurationContext);
    const {isLoading} = useContext(ReportDdReferenceRulesContext);

    useEffect(() => {
        if (isOpen) {
            form.setFieldsValue(ddReferenceRulesFilter);
        }
    }, [isOpen]);

    const handleSubmit = (values: any) => {
        dispatch(updateDdReferenceRulesFilter({
            ...values,
            paginationCurrent: 1,
        }));
    };

    const onClose = () => {
        setIsOpen(false);
    };

    return (
        <Drawer
            className="dd-references-filter"
            title="Параметры фильтрации"
            placement="right"
            onClose={onClose}
            visible={isOpen}
            width={380}
        >
            <div className={cn('dd-references-filter__body')}>
                <Form
                    initialValues={{enabled: true}}
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                >
                    <Form.Item
                        label="Контекст атрибутов"
                        name="context"
                    >
                        <CustomSelect settings={{
                            url: 'valueLists/TABLE_REPORT_DRILL_DOWN_CONTEXT',
                            labelPath: 'meaning',
                            valuePath: 'lookupCode',
                            placeholder: 'Контекст не выбран',
                            isClearable: true,
                            showSearch: true,
                        }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Исходный документ"
                        name="parentDocumentTypeCode"
                    >
                        <CustomSelect settings={{
                            url: 'valueLists/DOCUMENT_TYPES_FOR_TEMPLATE',
                            labelPath: 'meaning',
                            valuePath: 'lookupCode',
                            placeholder: 'Документ не выбран',
                            referenceUrlQueryParams: !templateCode ? {} : {
                                sublistCode: templateCode,
                            },
                            isClearable: true,
                            showSearch: true,
                        }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Конечный документ"
                        name="childDocumentTypeCode"
                    >
                        <CustomSelect settings={{
                            url: 'valueLists/ACTIVE_DOCUMENT_TYPES',
                            labelPath: 'meaning',
                            valuePath: 'lookupCode',
                            placeholder: 'Документ не выбран',
                            isClearable: true,
                            showSearch: true,
                        }}
                        />
                    </Form.Item>
                    {/* <Form.Item
                        label="Дата начала действия"
                        name="startDt"
                    >
                        <DateCell
                            format={DATE_DMY}
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item
                        label="Дата окончания действия"
                        name="endDt"
                    >
                        <DateCell
                            format={DATE_DMY}
                            allowClear
                        />
                    </Form.Item> */}
                    <Form.Item
                        valuePropName="checked"
                        name="enabled"
                    >
                        <Checkbox>
                            Скрыть неактивные
                        </Checkbox>
                    </Form.Item>
                </Form>
            </div>

            <div className="dd-references-filter__footer">
                <Button
                    disabled={isLoading}
                    type="primary"
                    onClick={() => {
                        form.submit();
                    }}
                >Применить
                </Button>
                <Button
                    disabled={isLoading}
                    type="default"
                    onClick={() => {
                        form.resetFields();
                        form.submit();
                    }}
                >Снять фильтры
                </Button>
                <Button
                    type="default"
                    onClick={onClose}
                >Отменить
                </Button>
            </div>
        </Drawer>
    );
};
