import {createAsyncThunk} from '@reduxjs/toolkit';

import {LookupEntry} from 'shared/types/lookups';
import {AppState} from 'store/config/types';
import {ReportConfigurationAttribute} from 'store/slices/report-configuration-slice';
import {fetchTableReportFilterConditions} from 'store/slices/report-configuration-slice/dd-lookups/dd-lookups-api';
import {getTableAttributes} from 'store/slices/report-configuration-slice/report-dependencies';

import {fetchTableReportFiltersConditions} from '../table-report-slice-api';
import {tableReportSliceName} from '../table-report-slice-constants';
import {selectTableReportAttributes, selectTableReportFilterConditions} from '../table-report-slice-selectors';

export interface LoadTableReportConfigurationFilterConditionsWithColumnLookupsArgs {
    templateCode: string;
}

export const loadTableReportFilterConditions = createAsyncThunk<
    LookupEntry[], void
>(
    `${tableReportSliceName}/loadTableReportFilterConditions`,
    async (args, {rejectWithValue}) => {
        try {
            const response = await fetchTableReportFiltersConditions();
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const loadTableReportFilterConditionsWithAttributes = createAsyncThunk<
    {
        filterConditions: LookupEntry[];
        reportAttributes: ReportConfigurationAttribute[] | undefined;
    },
    {templateCode: string}>(
        `${tableReportSliceName}/loadTableReportFilterConditionsWithColumns `,
        async (args, {rejectWithValue, getState}) => {
            try {
                const {templateCode} = args;

                const tableReportFiltersInStore = selectTableReportFilterConditions(
                    getState() as AppState,
                );
                const reportColumnsInStore = !templateCode ? undefined
                    : selectTableReportAttributes(
                        getState() as AppState,
                    );

                const loadRequests = await Promise.all([
                    await (tableReportFiltersInStore ? Promise.resolve(
                        {data: tableReportFiltersInStore},
                    ) : fetchTableReportFilterConditions()),
                    await (reportColumnsInStore ? Promise.resolve({data: reportColumnsInStore})
                        : templateCode ? getTableAttributes({
                            templateCode,
                        }) : Promise.resolve({data: undefined as ReportConfigurationAttribute[] | undefined})),
                ]);

                const [
                    tableReportFiltersResponse,
                    childReportColumnsResponse,
                ] = loadRequests;

                return {
                    filterConditions: tableReportFiltersResponse.data,
                    reportAttributes: childReportColumnsResponse.data,
                };
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );
