import {ActionReducerMapBuilder, PayloadAction} from '@reduxjs/toolkit';

import {TableColumnFilters} from 'components/@common/widgets/custom-table/table-column-filter/use-table-column-filter/use-table-column-filter-types';

import {TableReportSliceState} from '../table-report-slice-types';
import {loadTableReportFilterConditions, loadTableReportFilterConditionsWithAttributes} from './table-report-column-filters-thunks';

export const createTableReportColumnFiltersReducer = <S extends TableReportSliceState>() => ({
    setTableColumnFilters(state: S, {payload}: PayloadAction<{
        filters: TableColumnFilters | undefined; noFetch?: boolean;}>) {
        state.reportOptions.filters = payload.filters;
        state.reportOptions.noFetchWithFilters = !!payload.noFetch;
    },
});

export const createTableReportColumnFiltersExtraReducer = (
    builder: ActionReducerMapBuilder<TableReportSliceState>,
) => {
    builder.addCase(loadTableReportFilterConditions.fulfilled, (state, {payload}) => {
        state.filterConditions = payload;
    });
    builder.addCase(loadTableReportFilterConditionsWithAttributes.fulfilled, (state, {payload}) => {
        state.filterConditions = payload.filterConditions;
        state.tableAttributes = payload.reportAttributes;
    });
};
