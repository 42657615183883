import {MetaActionType} from 'modules/metadata';

export const SIGNS_INCORRECT = 'Файлы подписи не верны';

interface ConfirmContentData {
    [key: string]: string;
}
export const ConfirmContent: ConfirmContentData = {
    [MetaActionType.BUTTON_SAVE_FORM_PERSONAL_ACCOUNT]:
        'Требуется подтвердить изменения',
    [MetaActionType.BUTTON_SEND_FOR_APPROVAL_REQUEST]:
        'Вы действительно хотите отправить запрос?',
};
