import {
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';

import {AppState} from 'store/config/types';

import {jasperReportSliceName} from './jasper-report-slice-constants';
import {loadJasperReportInfo} from './jasper-report-slice-thunks';
import {JasperReportSliceState} from './jasper-report-slice-types';
import {getJasperInfoKey} from './jasper-report-slice-utils';

export const jasperReportSlice = createSlice({
    initialState: {
        reportsInfo: {},
    } as JasperReportSliceState,
    name: jasperReportSliceName,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(loadJasperReportInfo.fulfilled, (state, action) => {
            const {meta, payload} = action;

            if (payload?.status === 200 && payload?.data) {
                const {reportBreadcrumbs, reportUrl} = payload.data ?? {};
                const {entityName, docId} = meta.arg;

                state.reportsInfo[getJasperInfoKey({entityName, docId})] = {
                    reportBreadcrumbs,
                    reportUrl,
                };
            }
        });
    },
});

const selectJasperReportSliceState = (state: AppState) => state[jasperReportSliceName];

export const selectJasperReportInfo = createSelector([
    selectJasperReportSliceState,
    (_, data: {entityName: string; docId?: string}) => data], (
    state, {entityName, docId},
) => state.reportsInfo[getJasperInfoKey({entityName, docId})]);

export const jasperReportSelectors = {
    selectJasperReportSliceState,
    selectJasperReportInfo,
};

export const {
    reducer: jasperReportSliceReducer,
    actions: jasperReportSliceActions,
} = jasperReportSlice;
