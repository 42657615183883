import {
    PlusCircleOutlined,
} from '@ant-design/icons';
import {unwrapResult} from '@reduxjs/toolkit';
import {
    Button, Empty, Form,
} from 'antd';
import {FormInstance} from 'antd/es/form';
import {useForm} from 'antd/lib/form/Form';
import cn from 'classnames';
import React, {
    useContext,
    useEffect, useState,
} from 'react';

import {ReportConfigurationContext} from 'components/report-configuration/report-configuration.context';
import {ReactComponent as TrashX} from 'shared/assets/trash-x.svg';
import {StateSetter} from 'shared/types/generics';
import {showMessageFromResponse} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {
    ReportConfigurationDataConverted,
    selectTableReportConfigurationData,
} from 'store/slices/report-configuration-slice';
import {
    selectReportConfigurationTableAttributesAsSelectEntries,
} from 'store/slices/report-configuration-slice/report-configuration-slice';
import {
    updateTableReportConfiguration,
} from 'store/slices/report-configuration-slice/report-configuration/report-configuration-thunks';

import {FormatFormItem} from './format-form-item';

import './row-formats-modal-content.less';

interface RowFormatsModalContentProps {
  setIsModalOpen: StateSetter<boolean>;
}

export const RowFormatsModalContent = React.forwardRef<FormInstance, RowFormatsModalContentProps>(({
    setIsModalOpen,
}: RowFormatsModalContentProps, ref) => {
    const dispatch = useAppDispatch();

    const {templateCode} = useContext(ReportConfigurationContext);

    const reportConfigurationData = useAppSelector(selectTableReportConfigurationData);
    const tableAttributesEntries = useAppSelector(selectReportConfigurationTableAttributesAsSelectEntries);

    const [form] = useForm();

    const [initialValues, setInitialValues] = useState<Pick<ReportConfigurationDataConverted, 'rowFormats'>>();

    const [selectedRows, setSelectedRows] = useState<number[]>([]);

    useEffect(() => {
        if (reportConfigurationData?.rowFormats) {
            const {rowFormats} = reportConfigurationData;
            setInitialValues({rowFormats});
        }
    }, [reportConfigurationData]);

    useEffect(() => {
        if (initialValues) {
            form.resetFields();
        }
    }, [initialValues]);

    const handleFinish = (values: Pick<ReportConfigurationDataConverted, 'rowFormats'>) => {
        if (!templateCode) return;
        setIsModalOpen(false);
        dispatch(updateTableReportConfiguration({
            data: values,
            templateCode,
        })).then(unwrapResult).catch(err => {
            showMessageFromResponse({response: err, isError: true});
        });
    };

    return (
        <div className={cn('row-formats-modal-content')}>
            <Form<Pick<ReportConfigurationDataConverted, 'rowFormats'>>
                form={form}
                className={cn('row-formats-modal-content__form')}
                ref={ref}
                layout="vertical"
                initialValues={initialValues}
                onFinish={handleFinish}
            >
                <Form.List name="rowFormats">
                    {(fields, operations) => (
                        <div>
                            <div className={cn('row-formats-modal-content__buttons')}>
                                <Button
                                    disabled={!tableAttributesEntries?.length}
                                    type="primary"
                                    onClick={() => operations.add()}
                                >
                                    <PlusCircleOutlined />
                                    Добавить
                                </Button>
                                <Button
                                    disabled={!selectedRows.length}
                                    type="primary"
                                    onClick={() => {
                                        operations.remove(selectedRows);
                                        setSelectedRows([]);
                                    }}
                                >
                                    <TrashX />
                                    Удалить выбранное
                                </Button>
                            </div>

                            {(() => {
                                if (!fields.length && !tableAttributesEntries?.length) {
                                    return (
                                        <Empty
                                            description="Атрибуты источника данных табличной части не найдены"
                                        />
                                    );
                                }
                                if (!fields.length) {
                                    return <Empty description="Данные отсутствуют" />;
                                }
                                return null;
                            })()}

                            {fields.map(field => (
                                <FormatFormItem
                                    form={form}
                                    fields={fields}
                                    field={field}
                                    selectedRows={selectedRows}
                                    setSelectedRows={setSelectedRows}
                                />
                            ))}
                        </div>
                    )}
                </Form.List>
            </Form>
        </div>
    );
});
