import {FieldMeta, FieldType} from 'modules/metadata';

export enum RequestHeaderStyle {
    CUSTOM_TITLE = 'custom-title',
    CUSTOM_CARD_TITLE = 'custom-card-title'
}
export enum RequestFormFormKey {
    REQUESTS = 'requests',
    REQUEST_RESPONSE = 'requestResponse',
    REQUEST_PROLONGATION = 'prolongationRequest',
    RESPONSE_PROLONGATION = 'prolongationResponse',
    REQUEST_ADDITIONAL_PLACEMENT = 'requestAdditionalPlacement',
    REQUEST_REJECTION = 'requestRejection'
}
export enum RequestTypeCode {
    REQUEST = 'request',
    MESSAGE = 'message',
    REQUEST_DIT = 'requestDit',
    RESPONSE = 'response',
    COMMENT_REQUEST = 'comment_request',
    REQUEST_PUD = 'requestPUD',
}

export const RequestFormTitles = {
    [RequestFormFormKey.REQUESTS]: 'Информация по запросу',
    [RequestFormFormKey.REQUEST_RESPONSE]: 'Ответ на запрос ',
    [RequestFormFormKey.REQUEST_PROLONGATION]: 'Запрос на продление',
    [RequestFormFormKey.RESPONSE_PROLONGATION]: 'Решение о продлении ФНС',
    [RequestFormFormKey.REQUEST_REJECTION]: 'Отказ в доразмещении',
} as const;

export enum RequestStatus{
    IN_PREPARATION_RESPONSE = 'IN_PREPARATION_RESPONSE',
    DRAFT='DRAFT',
}
export const REQUEST_FIELD_CONTENT: FieldMeta = {
    key: 'content',
    label: 'Комментарий',
    type: FieldType.STRING,
    referenceUrl: '/lookupValue/REQUEST_TYPE',
    isRequired: true,
    path: {
        label: 'description',
        value: 'id',
    },
};

export const REQUEST_FIELD_FILES: FieldMeta = {
    key: 'files',
    label: 'Файлы',
    type: FieldType.FILE_LIST_WITH_SIGNS,
    width: 920,
};

export const requestMainFields: FieldMeta[] = [
    REQUEST_FIELD_CONTENT,
    REQUEST_FIELD_FILES,
];
