import React from 'react';

import {selectDocumentsSigningMethod} from 'modules/documents/documents-selectors';
import {DocumentsSigningMethod} from 'modules/documents/documents-types';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {openModal} from 'store/slices/modals-slice';

import {ActionButton} from '../action-button';
import {TableActionProps} from '../table-action-types';

interface TableOpenModalActionProps extends TableActionProps {
}

export const TableOpenModalAction: React.FunctionComponent<TableOpenModalActionProps> = ({
    meta,
    wrapper,
    entityName,
    ...props
}: TableOpenModalActionProps) => {
    const {modalName, actionCode} = meta;

    const documentsSigningMethod = useAppSelector(selectDocumentsSigningMethod);

    const dispatch = useAppDispatch();
    const onClick = () => {
        if (modalName) {
            dispatch(openModal({
                modalName,
                entityName: meta?.modalEntityName || entityName,
                parentEntityName: entityName,
            }));
        }
    };

    if (actionCode === 'UPLOAD_SGN' && documentsSigningMethod === DocumentsSigningMethod.CRYPTO_PRO_PLUGIN) return null;
    const Component = wrapper || ActionButton;
    return (
        <Component
            {...props}
            onClick={onClick}
            actionIcon={meta.actionIcon}
        >
            {meta.actionTitle}
        </Component>
    );
};
