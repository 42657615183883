import {LookupValue} from '../data/data-types';

export interface ExecuteMonitoringData {
    // eslint-disable-next-line camelcase
    org_id: string;
    // eslint-disable-next-line camelcase
    template_code: string;
     // eslint-disable-next-line camelcase
    table_report_page_request_dto: any;
}

export interface MonitoringProgram {
    id: number;
    code: string;
    name: string;
    description: string;
}

export interface ProgramSetProgram {
    id: number;
    program: MonitoringProgram;
}

export interface ProgramSetStage {
    id: number;
    displaySequence: number;
    code: string;
    name: string;
    description: string;
    programSetPrograms: ProgramSetProgram[];
}

export interface MonitorProgramSet {
    id: number;
    code: string;
    name: string;
    description: string;
    programSetStages: ProgramSetStage[];
}

export interface DocumentsUploadMonitoring {
    parentRequestId: number; // монитор который следит за выполнением всего набора программ
    monitorProgramSet: MonitorProgramSet; // информация о наборе программ
}

export interface MonitoringResultLog {
    id: number;
    monitorProgramRequest: number;
    description: string;
    nameFile: string;
}

export interface MonitoringResult {
    id: number;
    programId: number;
    phase: LookupValue<phaseStatus>;
    status: LookupValue<monitoringStatusCode>;
    monitorProgramLog?: MonitoringResultLog;
}

export interface DocumentsUploadMonitoringResult { // результат мониторинга программы
    id: number;
    parentRequest: MonitoringResult; // информация о статусе и фазе всех стадий
    program: MonitoringProgram; // текущая программа которая выполняется
    request: MonitoringResult; // информация о статусе и программы
}
export enum extensionName {
    docxIcon = 'docx',
    xmlIcon = 'xml',
    jsonIcon = 'json',
    docIcon = 'doc',
    xlsxIcon = 'xlsx',
    fileIcon = 'file'
}

export enum stageStatus {
    success = 'success',
    reject = 'reject',
    initial = 'initial',
    pending = 'pending'
}

export enum phaseStatus {
    completed = 'C',
    inactive = 'I',
    pending = 'P',
    running = 'R',
    warning = 'W',
    cancelled = 'D',
    error = 'E',
    onHold = 'G',
}

export enum monitoringStatusCode {
    normal = 'C',
    cancelled = 'D',
    error = 'E',
    onHold = 'G',
    running = 'R',
    warning = 'W'
}
