import {
    CloseOutlined, InfoCircleOutlined,
} from '@ant-design/icons';
import {Button} from 'antd';
import cn from 'classnames';
import React, {useEffect, useState} from 'react';

import {DraggableFrame} from 'components/draggable-frame/draggable-frame';
import {DraggableFrameUniqueTypeIds} from 'components/draggable-frame/draggable-frame-constants';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {apiRequestProgressSelectors, apiRequestProgressSliceActions} from 'store/slices/api-request-progress-slice/api-request-progress-slice';
import {DraggableFrameEntity, DraggableFrameType, draggableFramesActions} from 'store/slices/draggable-frames-slice';

import {ApiRequestProgressItem} from './api-request-progress-item';

import './api-request-progress-frame.less';

interface ApiRequestProgressFrameProps {
    frameEntity?: Partial<DraggableFrameEntity>;
}

const TOP_MARGIN = 80;

export const ApiRequestProgressFrame: React.FC<ApiRequestProgressFrameProps> = (
    {frameEntity}: ApiRequestProgressFrameProps,
) => {
    const dispatch = useAppDispatch();
    const [isBodyHidden, setIsBodyHidden] = useState(false);

    const requestsProgress = useAppSelector(apiRequestProgressSelectors.selectAll);

    const frameEntityToPassToFrame: DraggableFrameEntity = {
        type: DraggableFrameType.TableReportAttachmentUploadFrame,
        isOpen: !!requestsProgress.length && frameEntity?.isOpen,
        id: frameEntity?.id,
        data: frameEntity?.data,
    };

    const [topMargin, setTopMargin] = useState(document.documentElement.scrollTop + TOP_MARGIN);
    useEffect(() => {
        if (frameEntity?.isOpen) {
            const topMarginHandler = () => {
                const modalElement: HTMLDivElement | null = document
                    .querySelector('.api-request-progress-frame__modal');
                if (modalElement) {
                    // если делать через всё стейт - лагает сильнее
                    modalElement.style.top = `${document.documentElement.scrollTop + TOP_MARGIN}px`;
                }
            };

            setTopMargin(document.documentElement.scrollTop + TOP_MARGIN);

            document.addEventListener('scroll', topMarginHandler);
            return () => {
                document.removeEventListener('scroll', topMarginHandler);
            };
        }

        return () => {};
    }, [frameEntity?.isOpen]);

    const {
        closeDraggableFrame,
    } = draggableFramesActions;

    const {purgeRequestProgress} = apiRequestProgressSliceActions;

    return (
        <div>
            <DraggableFrame
                frameEntity={frameEntityToPassToFrame}
                classNames={{
                    wrapClassNames: ['api-request-progress-frame__wrap',
                        {'api-request-progress-frame__wrap_open': !isBodyHidden},
                    ],
                }}
                modalStyle={{
                    top: topMargin,
                }}
                modalProps={{
                    className: 'api-request-progress-frame__modal',
                    transitionName: '',
                    title: (
                        <div className={cn('api-request-progress-frame__title')}>
                            <InfoCircleOutlined />
                            <span>
                                Информация о загрузках
                            </span>
                        </div>
                    ),
                    bodyStyle: {display: isBodyHidden ? 'none' : 'block'},
                    closeIcon: (
                        <>
                            <div className={cn('api-request-progress-frame__controls')}>
                                <Button
                                    style={{marginLeft: -60, marginTop: 9, minWidth: 103}}
                                    type="primary"
                                    onClick={() => setIsBodyHidden(!isBodyHidden)}
                                >
                                    {isBodyHidden ? 'Подробнее' : 'Скрыть'}
                                </Button>

                                <CloseOutlined
                                    onClick={() => {
                                        dispatch(purgeRequestProgress());
                                        dispatch(closeDraggableFrame({
                                            type: DraggableFrameType.ApiRequestProgressFrame,
                                            id: DraggableFrameUniqueTypeIds.ApiRequestProgressFrame,
                                        }));
                                    }}
                                    className={cn('api-request-progress-frame__controls_close')}
                                />
                            </div>
                        </>
                    ),
                }}
            >

                <div>
                    {requestsProgress.map(progressEntity => (
                        <ApiRequestProgressItem
                            frameEntity={frameEntity}
                            progressEntity={progressEntity}
                            key={progressEntity.id}
                        />
                    ))}
                </div>
            </DraggableFrame>

        </div>
    );
};
