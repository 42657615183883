import {Card} from 'antd';
import cn from 'classnames';
import React, {useState} from 'react';

import './settings-programs.less';

import {CustomTabs} from '../custom-tabs';
import {SettingProgramComponent} from './components/setting-program-component/setting-program-component';
import {SettingProgramMapping} from './components/setting-program-mapping/setting-program-mapping';
import {
    settingProgramTitle, SettingsProgramCodesEntityName,
    SettingsProgramsCodesTabKey,
    SettingsProgramsFormTitles,
} from './constants/setting-program.constants';
import {SettingProgramContext} from './context/setting-program.context';

interface SettingsProgramsProps{
    url: string;
    entityName: string;
}
export const SettingsPrograms:React.FC<SettingsProgramsProps> = (
    {
        url,
        entityName,
    }: SettingsProgramsProps,
) => {
    const [selectedTabKey, setSelectedTabKey] = useState<React.Key>(SettingsProgramsCodesTabKey.SETTING_PROGRAM);
    return (
        <div className={cn('settings-programs')}>
            <SettingProgramContext.Provider
                value={{
                    entityName,
                    selectedTabKey,
                    setSelectedTabKey,
                }}
            >
                <Card
                    title={settingProgramTitle}
                    className={cn('settings-programs__card')}
                >
                    <CustomTabs
                        selectedTabKey={selectedTabKey}
                        setSelectedTabKey={setSelectedTabKey}
                        defaultRenderBehaviourOnSwitchingTabs="unmount"
                        tabs={[
                            {
                                key: SettingsProgramsCodesTabKey.SETTING_PROGRAM,
                                title: SettingsProgramsFormTitles[SettingsProgramsCodesTabKey.SETTING_PROGRAM],
                                content: <SettingProgramComponent
                                    entityName={
                                        SettingsProgramCodesEntityName[SettingsProgramsCodesTabKey.SETTING_PROGRAM]
                                    }
                                    url={url}
                                />,
                                noRenderWhileOnAnotherTab: true,
                            },
                            {
                                key: SettingsProgramsCodesTabKey.PROGRAMS_MAPPING,
                                title: SettingsProgramsFormTitles[SettingsProgramsCodesTabKey.PROGRAMS_MAPPING],
                                content: <SettingProgramMapping
                                    entityName={
                                        SettingsProgramCodesEntityName[SettingsProgramsCodesTabKey.PROGRAMS_MAPPING]
                                    }
                                    url={url}
                                />,
                                noRenderWhileOnAnotherTab: true,
                            },
                            {
                                key: SettingsProgramsCodesTabKey.PROGRAMS_SETS,
                                title: SettingsProgramsFormTitles[SettingsProgramsCodesTabKey.PROGRAMS_SETS],
                                content: <SettingProgramComponent
                                    entityName={
                                        SettingsProgramCodesEntityName[SettingsProgramsCodesTabKey.PROGRAMS_SETS]
                                    }
                                    url={url}
                                />,
                                noRenderWhileOnAnotherTab: true,
                            },
                            {
                                key: SettingsProgramsCodesTabKey.SET_STAGES,
                                title: SettingsProgramsFormTitles[SettingsProgramsCodesTabKey.SET_STAGES],
                                content: <SettingProgramComponent
                                    entityName={SettingsProgramCodesEntityName[SettingsProgramsCodesTabKey.SET_STAGES]}
                                    url={url}
                                />,
                                noRenderWhileOnAnotherTab: true,
                            },
                            {
                                key: SettingsProgramsCodesTabKey.STAGES_LINK,
                                title: SettingsProgramsFormTitles[SettingsProgramsCodesTabKey.STAGES_LINK],
                                content: <SettingProgramComponent
                                    entityName={SettingsProgramCodesEntityName[SettingsProgramsCodesTabKey.STAGES_LINK]}
                                    url={url}
                                />,
                                noRenderWhileOnAnotherTab: true,
                            },
                        ]}
                    />
                </Card>
            </SettingProgramContext.Provider>
        </div>
    );
};
