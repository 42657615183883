import {
    PayloadAction,
} from '@reduxjs/toolkit';

import {TableReportRequestParameters, TableReportSliceState} from '../table-report-slice-types';

export const createTableReportQueryParamsReducer = <S extends TableReportSliceState>() => ({
    setTableReportQueryParams(state: S, {payload}: PayloadAction<{
        params: TableReportRequestParameters | undefined;
        noFetch?: boolean;
    }>) {
        state.queryParameters.parameters = payload.params;
        state.reportOptions.noFetchWithParameters = !!payload.noFetch;
    },
    resetTableReportQueryParams(state: S, {payload}: PayloadAction<{noFetch?: boolean} | void>) {
        state.queryParameters.parameters = undefined;
        state.reportOptions.noFetchWithParameters = !!payload?.noFetch;
    },
});
