import {AxiosRequestConfig} from 'axios';

import {convertToFormData, createQueryParams, performRequest} from 'shared/utils';

import {RequestType} from '../metadata';
import {ERequestStatuses} from './request-form.constants';

export type TRatingData = { id: string; rating: number };

const toggleRating = async (object: TRatingData) => performRequest({
    url: '/request/sendRating',
    method: RequestType.POST,
    data: convertToFormData(object),
});

export type TFieldsViewType = 'new' | 'detail';

const getFields = async (id: string, viewType: TFieldsViewType = 'new') => performRequest({
    url: '/requests/fields',
    method: RequestType.GET,
    params: {
        requestType: id,
        viewType,
    },
});

/**
 * request - для запроса
 * response - для ответа
 * comment_request - для комментария
 */

const getDataForPreFilling = async (id: string) => performRequest({
    url: '/request/sendReplay',
    method: RequestType.GET,
    params: {
        id,
    },
});

const validRole = async (addressees?: string[]) => {
    const params = addressees ? createQueryParams({addreses: addressees}) : '';

    return performRequest({
        url: `/requests/validRole${params}`,
        method: RequestType.POST,
    }).then(response => !!Number(response.data));
};

const markAsRead = async (id: string) => performRequest({
    url: '/request/mark-as-read',
    method: RequestType.POST,
    params: {
        id,
    },
});

function createRequestStatus() {
    const createPost = (path: string) => (params: AxiosRequestConfig['params']) => (
        performRequest({
            url: `/${path}`,
            method: RequestType.POST,
            params,
        })
    );

    const apiList = {
        /**
         * Продление ответа (Согласование или отклонение нового срока)
         */
        [ERequestStatuses.ANSWER_PROLONGATION]: createPost('answer-prolongation'),
        /**
         * Согласовать
         */
        [ERequestStatuses.APPROVE]: createPost('approve'),
        /**
         * Закрыть
         */
        [ERequestStatuses.CLOSE_REQUEST]: createPost('close-request'),
        /**
         * Пометить запрос как прочитанный
         */
        [ERequestStatuses.MARK_AS_READ]: createPost('mark-as-read'),
        /**
         * Отменить
         */
        [ERequestStatuses.REJECT]: createPost('reject'),
        /**
         * Ответить
         */
        [ERequestStatuses.REPLY]: createPost('reply'),
        /**
         * Отправить на согласование
         */
        [ERequestStatuses.SEND_FOR_APPROVAL]: createPost('send-for-approval'),
        /**
         * Взять запрос в работу
         */
        [ERequestStatuses.TAKE_TO_WORK]: createPost('take-to-work'),
    };

    return {
        changeStatus: (newStatus: ERequestStatuses, id: string) => apiList[newStatus]({id}),
        getAvailableStatuses: async (idRequestComment: string) => (
            await createPost('requests-requirement/status-action')({commentId: idRequestComment})
        )?.data,
    };
}

const getDataForChildForm = (params?: Record<string, any>) => performRequest({url: 'getDataForChildForm', params})
    .then(response => response.data);

export const requestFormApi = {
    status: createRequestStatus(),
    getDataForChildForm,
    toggleRating,
    getFields,
    getDataForPreFilling,
    validRole,
    markAsRead,
};
