import {createSlice} from '@reduxjs/toolkit';

import {documentUploadTemplateSliceName} from './document-upload-template-slice-constants';
import {
    loadDocumentUploadTemplateData,
    loadSettingTemplateTargetColumns,
} from './document-upload-template-slice-thunks';
import {DocumentUploadTemplateSliceState} from './document-upload-template-slice-type';

export const documentUploadTemplateSlice = createSlice({
    name: documentUploadTemplateSliceName,
    initialState: {
        documentUploadTemplateData: {},
    } as DocumentUploadTemplateSliceState,
    reducers: {
        resetDocumentUploadTemplateData(state) {
            state.documentUploadTemplateData = undefined;
        },
    },
    extraReducers: builder => {
        builder.addCase(loadDocumentUploadTemplateData.fulfilled, (state, action) => {
            state.documentUploadTemplateData = action.payload;
        });
        builder.addCase(loadSettingTemplateTargetColumns.fulfilled, (state, action) => {
            state.settingTemplateTransferTargetColumns = [...action.payload];
        });
    },
});

export const {
    reducer: documentUploadTemplateSliceReducer,
    actions: documentUploadTemplateSliceActions,
} = documentUploadTemplateSlice;
