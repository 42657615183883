import {WebsocketMessageHandlers} from 'websockets/core/websockets-types';

export interface UseWebsocketNewsHandlersArgs {}

export const websocketNewsStaticHandlers: WebsocketMessageHandlers = {
    newsAdded: () => {
        // console.log("Fires when news added.")
        // dispatch(setUnreadNews({...}))
    },
};
